import React, { FC, ReactElement } from 'react';

import './TalentMatchingQuote.scss';

interface TalentMatchingQuoteProps {
    quote: string;
    className?: string;
}

const TalentMatchingQuote: FC<TalentMatchingQuoteProps> = ({
    quote,
    className = '',
}): ReactElement => (
    <div className={`talent-matching-quote ${className}`}>
        <q className="talent-matching-quote__quote">
            {quote}
        </q>

        <svg viewBox="0 0 100 59.4" className="talent-matching-quote__arrow">
            <path className="talent-matching-quote__arrow-swirl" d="M2.7,45.1c25.5-32.7,52-24.4,58-0.8c3.3,12.8-15.4,16.5-20.5,7.7c-9.5-16.3,19.3-28.8,52.2-30.3" />
            <path className="talent-matching-quote__arrow-head" d="M78.3,2.5c2.6,19.9,19.1,18.3,19.1,18.3s-19.4,5.3-13.7,25.8" />
        </svg>
    </div>
);

export default TalentMatchingQuote;
