import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { Application, ApplicationResource, transformJapiDocumentToApplication } from '../models/Applications';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getVacancyApplicationsApiCall = async (vacancyUuid: string): Promise<FetchResult<Application[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies/${vacancyUuid}/applicants`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ApplicationResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const applications = doc.data.map(applicationResource => transformJapiDocumentToApplication(doc, applicationResource));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: applications,
        };
    } catch (error) {
        console.error('[getVacancyApplicationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
