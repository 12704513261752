import { transformMetaToPagination } from '../_old/app_talentz/models/Pagination';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { convertToQueryParams } from '../helpers/params';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { NewestVacancyResource, transformToNewestVacancy, VacancyOverview } from '../models/NewestVacancy';
import { CompanyVacancyOverviewFilterValues, transformToCompanyVacancyOverviewFilterParams } from '../models/VacancyOverview';

export const getCompanyVacanciesApiCall = async (filterValues: CompanyVacancyOverviewFilterValues): Promise<FetchResult<VacancyOverview, string>> => {
    const filterParams = transformToCompanyVacancyOverviewFilterParams(filterValues);
    const queryParams = convertToQueryParams({
        ...filterParams,
        sort: '-vacancyPublicationDate',
    });

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/vacancies/search?${queryParams}`);

        if (!response.ok) {
            const errorDoc = await response.json();
            const error = errorDoc.errors[0];

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<NewestVacancyResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const vacancies = doc.data.map(transformToNewestVacancy);
        const pagination = doc.meta ? transformMetaToPagination(doc.meta) : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                vacancies,
                pagination,
            },
        };
    } catch (error) {
        console.error('[getCompanyVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
