import React, { FC, ReactElement } from 'react';

import { Card } from '../../components';
import { trans } from '../../helpers/trans';
import { CompetenciesFormData, Competency } from '../../models/Competencies';
import { Skill, SkillsFormData } from '../../models/Skills';
import { InternshipVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { TalentMatchingCompetencies, TalentMatchingSkills } from './subcomponents';

import './TalentMatchingCard.scss';

interface InternshipTalentMatchingCardProps {
    hasToLearnOptions?: boolean;
    isLoading: boolean;
    isValidCandidate: boolean;
    activeFilterValues: InternshipVacancyOverviewFilterValues;
    skillOptions: Skill[];
    skillsError: string;
    competencyOptions: Competency[];
    onSubmitSkills: (skillsFormData: SkillsFormData) => void;
    onSubmitSkillsToProfile: (skillsFormData: SkillsFormData) => void;
    onSubmitCompetencies: (competenciesFormData: CompetenciesFormData) => void;
    onSubmitCompetenciesToProfile: (competenciesFormData: CompetenciesFormData) => void;
    onSubmitSkillsToLearn: (skillsFormData: SkillsFormData) => void;
    onSubmitSkillsToLearnToProfile: (skillsFormData: SkillsFormData) => void;
    onSubmitCompetenciesToLearn: (competenciesFormData: CompetenciesFormData) => void;
    onSubmitCompetenciesToLearnToProfile: (competenciesFormData: CompetenciesFormData) => void;
    className?: string;
}

const InternshipTalentMatchingCard: FC<InternshipTalentMatchingCardProps> = ({
    isLoading,
    isValidCandidate,
    activeFilterValues,
    skillOptions,
    skillsError,
    competencyOptions,
    onSubmitSkills,
    onSubmitSkillsToProfile,
    onSubmitCompetencies,
    onSubmitCompetenciesToProfile,
    onSubmitSkillsToLearn,
    onSubmitSkillsToLearnToProfile,
    onSubmitCompetenciesToLearn,
    onSubmitCompetenciesToLearnToProfile,
    className = '',
}): ReactElement => {
    const sharedProperties = {
        isLoading,
        isValidCandidate,
    };

    const skillsProperties = {
        ...sharedProperties,
        skillOptions,
        skillsError,
    };

    const competenciesProperties = {
        ...sharedProperties,
        competencyOptions,
    };

    const skills = skillOptions.filter(option => activeFilterValues.skills.includes(option.id));
    const competencies = competencyOptions.filter(option => activeFilterValues.competencies.includes(option.id));
    const skillsToLearn = skillOptions.filter(option => activeFilterValues.skillsToLearn.includes(option.id));
    const competenciesToLearn = competencyOptions.filter(option => activeFilterValues.competenciesToLearn.includes(option.id));

    return (
        <Card className={`talent-matching-card ${className}`}>
            <h2 className="talent-matching-card__title">
                {trans('vacancies.overview.talentMatch.title')}
            </h2>

            <p className="talent-matching-card__description">
                {trans('vacancies.overview.talentMatch.description')}
            </p>

            <div className="talent-matching-card__wrapper">
                <TalentMatchingSkills
                    {...skillsProperties}
                    value={skills}
                    onSubmitToProfile={onSubmitSkillsToProfile}
                    onSubmit={onSubmitSkills}
                    className="talent-matching-card__button"
                />

                <TalentMatchingCompetencies
                    {...competenciesProperties}
                    value={competencies}
                    onSubmitToProfile={onSubmitCompetenciesToProfile}
                    onSubmit={onSubmitCompetencies}
                    className="talent-matching-card__button"
                />
            </div>

            <div className="talent-matching-card__wrapper">
                <TalentMatchingSkills
                    {...skillsProperties}
                    isToLearn
                    value={skillsToLearn}
                    onSubmitToProfile={onSubmitSkillsToLearnToProfile}
                    onSubmit={onSubmitSkillsToLearn}
                    className="talent-matching-card__button"
                />

                <TalentMatchingCompetencies
                    {...competenciesProperties}
                    isToLearn
                    value={competenciesToLearn}
                    onSubmitToProfile={onSubmitCompetenciesToLearnToProfile}
                    onSubmit={onSubmitCompetenciesToLearn}
                    className="talent-matching-card__button"
                />
            </div>
        </Card>
    );
};

export default InternshipTalentMatchingCard;
