import styled, { css } from 'styled-components';
import {
    alignItems,
    backgroundColor,
    display,
    flexDirection,
    height,
    justifyContent,
    minHeight,
    position,
    size,
    space,
} from 'styled-system';

const Container = styled('div')(
    props => css`
    width: 100%;
    max-width: ${props.centered ? (props.maxWidth || `${props.theme.variables.maxContainerWidth}px`) : '100%'};
    margin: ${props.centered ? '0 auto' : ''};
    padding: 0 ${(props.centered && !props.noPadding) ? props.theme.space.sm : 0};
    
    @media ${props.theme.mediaQueries.tablet} {
        padding: 0 ${(props.centered && !props.noPadding) ? props.theme.space.md : 0};
        ${space} 
    }
        
    @media ${props.theme.mediaQueries.laptop} {
        padding: 0 ${(props.centered && !props.noPadding) ? props.theme.space.md : 0};
        ${space} 
    }
    
    @media ${props.theme.mediaQueries.desktop} {
        padding: 0 ${(props.centered) ? props.theme.space.md : 0};
        ${space} 
    }
    
    ${props.hasPreviousNextLinks && css`
        @media ${props.theme.mediaQueries.tablet} {
            padding: 0 60px;
        }
    `}
    
    ${space}
    ${display}
    ${position}
    ${flexDirection}
    ${justifyContent}
    ${alignItems}
    ${minHeight}
    ${backgroundColor}
    ${size}
    ${height}
`,
    props => props.css,
);

export default Container;
