/* eslint-disable react/no-danger */
import React, { FC, ReactElement } from 'react';

import { DateInput } from '../../compositions';
import { trans } from '../../helpers/trans';

import './PublicationAndExpirationDates.scss';

interface PublicationAndExpirationDatesProps {
    isLoading?: boolean;
    isEditing: boolean;
    id?: string;
    publicationDateErrors?: string[];
    expirationDateErrors?: string[];
    publicationDate: Date | null;
    expirationDate: Date | null;
    onPublicationChange: (date: Date | null) => void;
    onExpirationChange: (date: Date | null) => void;
    maxVacancyLifeTimeInDays: number;
    className?: string;
}

const PublicationAndExpirationDates: FC<PublicationAndExpirationDatesProps> = ({
    isLoading,
    isEditing,
    id,
    publicationDate,
    expirationDate,
    onPublicationChange,
    onExpirationChange,
    maxVacancyLifeTimeInDays,
    publicationDateErrors = [],
    expirationDateErrors = [],
    className = '',
}): ReactElement => (
    <div className={`publication-and-expiration-dates ${className}`} id={id}>
        <div className="publication-and-expiration-dates__inputs">
            <DateInput
                required
                label={trans('forms.vacancy.publishDate.label')}
                value={publicationDate}
                minDate={(isEditing && publicationDate) ? publicationDate : new Date()}
                disabled={isLoading || isEditing}
                errors={publicationDateErrors}
                onChange={onPublicationChange}
                className="publication-and-expiration-dates__date-input"
            />
            <DateInput
                required
                label={trans('forms.vacancy.expirationDate.label')}
                value={expirationDate}
                minDate={new Date()}
                disabled={isLoading}
                errors={expirationDateErrors}
                onChange={onExpirationChange}
                className="publication-and-expiration-dates__date-input"
            />
        </div>
        <div
            className="publication-and-expiration-dates__life-time"
            dangerouslySetInnerHTML={{
                __html: trans('forms.vacancy.publishDate.until', {
                    vacancyLifetimeInDays: maxVacancyLifeTimeInDays,
                }),
            }}
        />
    </div>
);

export default PublicationAndExpirationDates;
