import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Company } from '../../models/Company';
import { SbbCompanyInfo } from '../../models/SbbTrainingCompany';
import { AsyncReduxState } from '../../types';

export type CompanyState = AsyncReduxState<{
    company?: Company;
    sbbCompanyInfo?: SbbCompanyInfo;
    sbbCompanyInfoIsLoading: boolean;
    sbbCompanyInfoHasUpdated: boolean;
}>;

const initialState: CompanyState = {
    ...initialAsyncReduxState,
    company: undefined,
    sbbCompanyInfo: undefined,
    sbbCompanyInfoIsLoading: false,
    sbbCompanyInfoHasUpdated: false,
};

const companiesSlice = createSlice({
    name: 'companyReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CompanyState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CompanyState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCompany(state, action: PayloadAction<Company | undefined>): CompanyState {
            return {
                ...state,
                company: action.payload,
            };
        },
        setSbbCompanyInfo(state, action: PayloadAction<SbbCompanyInfo | undefined>): CompanyState {
            return {
                ...state,
                sbbCompanyInfo: action.payload,
            };
        },
        setSbbCompanyInfoIsLoading(state, action: PayloadAction<boolean>): CompanyState {
            return {
                ...state,
                sbbCompanyInfoIsLoading: action.payload,
            };
        },
        setSbbCompanyInfoHasUpdated(state, action: PayloadAction<boolean>): CompanyState {
            return {
                ...state,
                sbbCompanyInfoHasUpdated: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCompany,
    setSbbCompanyInfo,
    setSbbCompanyInfoIsLoading,
    setSbbCompanyInfoHasUpdated,
} = companiesSlice.actions;

export default companiesSlice;
