import React, { FC, ReactElement } from 'react';

import {
    LogoCreate,
    LogoEndeavour,
    LogoLLOCompact,
    LogoTalentzCompact,
    LogoTalentzDefault,
} from './set';

import './Logo.scss';

export interface SvgLogoProps {
    className?: string;
}

type LogoSet = Record<string, FC<SvgLogoProps>>;

interface LogoProps extends SvgLogoProps {
    name?: string;
}

const logo: LogoSet = {
    create: LogoCreate,
    endeavour: LogoEndeavour,
    'llo-compact': LogoLLOCompact,
    'talentz-compact': LogoTalentzCompact,
    'talentz-default': LogoTalentzDefault,
};

const Logo: FC<LogoProps> = ({
    name = 'talentz-default',
    className = '',
}): ReactElement | null => {
    const LogoComponent = logo[name];

    return LogoComponent
        ? <LogoComponent className={className} />
        : null;
};

export default Logo;
