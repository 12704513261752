import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { WorkRemoteOption } from '../../models/WorkRemote';
import { AsyncReduxState } from '../../types';

export type WorkRemoteState = AsyncReduxState<{
    workRemoteOptions: WorkRemoteOption[];
}>;

const initialState: WorkRemoteState = {
    ...initialAsyncReduxState,
    workRemoteOptions: [],
};

export const workRemoteSlice = createSlice({
    name: 'workRemoteReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): WorkRemoteState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): WorkRemoteState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setWorkRemoteOptions(state, action: PayloadAction<WorkRemoteOption[]>): WorkRemoteState {
            return {
                ...state,
                workRemoteOptions: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setWorkRemoteOptions,
} = workRemoteSlice.actions;

export default workRemoteSlice;
