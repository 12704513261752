import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getApplicationAppointmentApiCall } from '../../services/ApplicationAppointmentService';
import { TypedDispatch } from '..';
import {
    setAppointment,
    setError,
    setIsLoading,
    setStatusCode,
} from './applicationAppointment';

export const clearApplicationAppointment = () => (dispatch: TypedDispatch): void => {
    dispatch(setAppointment(undefined));
};

export const fetchApplicationAppointment = (appointmentUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const applicationAppointmentResponse = await getApplicationAppointmentApiCall(appointmentUuid);

        if (!isFetchResultSuccessful(applicationAppointmentResponse)) {
            console.error('[fetchApplicationAppointment]', applicationAppointmentResponse.error);
            dispatch(setStatusCode(applicationAppointmentResponse.status));
            dispatch(setError(applicationAppointmentResponse.error));

            return;
        }

        dispatch(setAppointment(applicationAppointmentResponse.data));
    } catch (error) {
        console.error('[fetchApplicationAppointment]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
