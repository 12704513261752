import axios from 'axios';

import { Address as OldAddress, AddressResponseApiParams, transformToAddress } from '../_old/app_talentz/models/Address';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { Address, AddressResource, transformToCreateAddressRequestBody } from '../models/Address';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { CompanyDataFormErrors } from './CompanyService';

export interface AddressFormValues {
    zipcode: string;
    streetNumber: string;
    extension: string;
    city: string;
    address: string;
}

export interface AddressFormErrors extends Partial<CompanyDataFormErrors> {
    address?: string;
    zipcode?: string;
    streetNumber?: string;
    extension?: string;
}

export const getAddressApiCall = async (
    zipCode: string,
    streetNumber: string,
    streetNumberExtension?: string,
): Promise<FetchResult<OldAddress, string>> => {
    try {
        const response = await axios.get(`addresses/search/${zipCode}/${streetNumber}/${streetNumberExtension || ''}`);
        const responseData = response.data.data as unknown as AddressResponseApiParams;
        const address = transformToAddress(responseData);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: address,
        };
    } catch (error) {
        console.error('[getAddressApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postAddressApiCall = async (address: Address): Promise<FetchResult<AddressResource, string>> => {
    const body = transformToCreateAddressRequestBody(address);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/addresses`, {
            method: 'POST',
            body: JSON.stringify({ data: body }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<AddressResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data,
        };
    } catch (error) {
        console.error('[postAddressApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchAddressApiCall = async (addressUuid: string, address: Address): Promise<FetchResult<AddressResource, string>> => {
    const body = {
        id: addressUuid,
        ...transformToCreateAddressRequestBody(address),
    };

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/addresses/${addressUuid}`, {
            method: 'PATCH',
            body: JSON.stringify({ data: body }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<AddressResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data,
        };
    } catch (error) {
        console.error('[patchAddressApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
