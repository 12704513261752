import React, { FC, ReactElement } from 'react';

import { alphabet } from '../../../../constants';
import { trans } from '../../../../helpers/trans';
import { EducationLevel } from '../../../../models/EducationLevels';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexLink } from '..';

import './VacancyIndexEducationLevelLetter.scss';

interface VacancyIndexEducationLevelLetterProps {
    isLoading: boolean;
    educationLevel: EducationLevel;
    activeLetters?: string[];
    className?: string;
}

const VacancyIndexEducationLevelLetter: FC<VacancyIndexEducationLevelLetterProps> = ({
    isLoading,
    educationLevel,
    activeLetters = [],
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.educationLevel.letter', {
            educationLevel: educationLevel.name,
        })}
        className={`vacancy-index-education-level-letter ${className}`}
    >
        <ul className="vacancy-index-education-level-letter__list">
            {alphabet.map(letter => {
                const encodedEducationLevel = encodeURIComponent(educationLevel.slug);
                const encodedLetter = encodeURIComponent(letter);

                const route = activeLetters?.includes(letter)
                    ? RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel, encodedLetter)
                    : undefined;

                return (
                    <li key={letter} className="vacancy-index-education-level-letter__letter">
                        <VacancyIndexLink to={route} label={letter} />
                    </li>
                );
            })}
        </ul>
    </VacancyIndexCategory>
);

export default VacancyIndexEducationLevelLetter;
