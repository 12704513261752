import React, { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { createMetadataTitle } from '../../../../_old/app_talentz/services/MetadataService';
import { Tab } from '../../../../components';
import { trans } from '../../../../helpers/trans';

import './VacancyIndexTab.scss';

interface VacancyIndexTabProps {
    isActive: boolean;
    title: string;
    className?: string;
}

const VacancyIndexTab: FC<PropsWithChildren<VacancyIndexTabProps>> = ({
    isActive,
    title,
    className = '',
    children,
}): ReactElement => {
    const helmetTitle = trans('vacancyIndex.helmet.title', { title });

    return (
        <Tab isActive={isActive} className={`vacancy-index-tab ${className}`}>
            <Helmet>
                <title>{helmetTitle}</title>
                <meta property="og:title" content={createMetadataTitle(helmetTitle)} />
            </Helmet>

            <h1 className="vacancy-index-tab__title">
                {title}
            </h1>

            <div className="vacancy-index-tab__content-wrapper">
                {children}
            </div>
        </Tab>
    );
};

export default VacancyIndexTab;
