import React, { FC, ReactElement, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { ConnectedRegisterCompanyData } from '../../../../../connectors';
import { hotjarEvent } from '../../../../../helpers/hotjar';
import { trans } from '../../../../../helpers/trans';
import { RoleType } from '../../../../../models/User';
import { RoutePaths } from '../../../../../routes';
import { useLegacyDispatch, useLegacySelector, useTypedSelector } from '../../../../../store';
import Button from '../../../../components/Button';
import MultiStepOverlay from '../../../../components/MultiStepOverlay';
import { webPushSubscribe } from '../../../store/notifications/notificationsActions';
import { setStep as setRegisterStep } from '../../../store/register/register';
import { setPasswordAndAuthenticate } from '../../../store/register/registerActions';
import { updatePersonSettings } from '../../../store/userPersonSettings/userPersonSettingsActions';
import PasswordForm from '../../components/forms/PasswordForm';
import RegisterNotificationsForm from '../../components/forms/RegisterNotificationsForm';

const RegisterEmployee: FC = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const navigate = useNavigate();

    const isLoading = useLegacySelector(state => state.register.loading);
    const errors = useLegacySelector(state => state.register.errors);
    const completeStep = useLegacySelector(state => state.register.completeStep);

    const notificationsIsLoading = useTypedSelector(state => state.notificationsReducer.isLoading);

    useEffect((): void => {
        hotjarEvent('vpv', `/registreren/wachtwoord/${RoleType.company}`);
    }, []);

    const handlePasswordSubmit = (data: any): void => {
        legacyDispatch(setPasswordAndAuthenticate(data));

        hotjarEvent('vpv', `/registreren/profiel/${RoleType.company}`);
    };

    const handleUpdatePersonSettings = async (data: any): Promise<void> => {
        await legacyDispatch(updatePersonSettings(data));

        if (data.notifications) {
            await legacyDispatch(webPushSubscribe());
        }

        navigate(RoutePaths.myCompanyProfile(), {
            state: { firstVisit: true },
        });
    };

    const handleClearRegisterStep = (): void => {
        legacyDispatch(setRegisterStep(null));
    };

    const registerSteps = [
        {
            id: 'register-user-employee-password',
            message: {
                title: trans('basic.password.introTitle'),
                body: trans('basic.password.introText'),
            },
            component: (
                <PasswordForm
                    loading={isLoading}
                    errors={errors}
                    onSubmit={handlePasswordSubmit}
                />
            ),
        },
        {
            id: 'register-user-employee-account',
            message: {
                title: trans('basic.registerUserProfile.introTitle'),
                body: trans('basic.registerUserProfile.introText'),
            },
            component: (
                <ConnectedRegisterCompanyData />
            ),
        },
        {
            id: 'register-user-employee-notifications',
            message: {
                title: trans('basic.subscribeToNotifications.introTitle'),
                body: trans('basic.subscribeToNotifications.introText'),
            },
            component: (
                <RegisterNotificationsForm
                    isLoading={notificationsIsLoading}
                    onSubmit={handleUpdatePersonSettings}
                />
            ),
        },
    ];

    const notFoundStep = {
        id: 'register-user-employee-notFound',
        message: {
            title: trans('register.activationNotFound.introTitle'),
            body: trans('register.activationNotFound.introText'),
            button: (
                <Button
                    as={Link}
                    to={RoutePaths.home()}
                    onClick={handleClearRegisterStep}
                    color="white"
                    mt="lg"
                    icon="backward"
                    iconSize={14}
                >
                    {trans('actions.backToHome')}
                </Button>
            ),
        },
        component: null,
    };

    let registerStep: any = registerSteps[completeStep] || null;

    if (errors && errors.token) {
        registerStep = notFoundStep;
    }

    return (
        <MultiStepOverlay
            step={completeStep}
            message={registerStep.message}
            id="register-employee"
        >
            {registerStep && registerStep.component}
        </MultiStepOverlay>
    );
};

export default RegisterEmployee;
