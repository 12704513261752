import React, { FC, ReactElement } from 'react';

import Tooltip from '../../_old/components/Tooltip';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '../../components';
import Card from '../../components/Card/Card';
import { trans } from '../../helpers/trans';
import { SbbRecognition } from '../../models/SbbTrainingCompany';
import { transformRecognitionsToPeople } from './helpers/transformRecognitionsToPeople';
import { SbbPersonRecognitions } from './subcomponents';

import './SbbPeopleCard.scss';

interface SbbPeopleCardProps {
    recognitions: SbbRecognition[];
    className?: string;
}

const SbbPeopleCard: FC<SbbPeopleCardProps> = ({
    recognitions,
    className = '',
}): ReactElement => {
    const people = transformRecognitionsToPeople(recognitions);

    return (
        <Card className={`sbb-people-card ${className}`}>
            <h2 className="sbb-people-card__title">
                {trans('userProfile.sbb.persons')}
            </h2>

            <Table className="sbb-people-card__table">
                <TableHead className="sbb-people-card__table-head">
                    <TableRow>
                        <TableCell className="sbb-people-card__table-cell">
                            {trans('forms.user.name.label')}
                        </TableCell>
                        <TableCell className="sbb-people-card__table-cell">
                            {trans('userProfile.sbb.role')}
                        </TableCell>
                        <TableCell className="sbb-people-card__table-cell">
                            {trans('forms.user.email.label')}
                        </TableCell>
                        <TableCell className="sbb-people-card__table-cell sbb-people-card__table-cell--phone">
                            {trans('forms.phonenumber.label')}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {people.map(person => (
                        <TableRow key={person.id}>
                            <TableCell className="sbb-people-card__table-cell">
                                {person.name}
                            </TableCell>
                            <TableCell className="sbb-people-card__table-cell">
                                {person.role.name}

                                {!!person.role.recognitions.length && (
                                    <Tooltip
                                        id={`sbb-person-role-qualifications-${person.name}`}
                                        content={<SbbPersonRecognitions recognitions={person.role.recognitions} />}
                                    />
                                )}
                            </TableCell>
                            <TableCell className="sbb-people-card__table-cell">
                                {person.email}
                            </TableCell>
                            <TableCell className="sbb-people-card__table-cell sbb-people-card__table-cell--phone">
                                {person.phone}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default SbbPeopleCard;
