import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import ucFirst from '../../_old/helpers/ucFirst';
import { Page } from '../../components';
import { BlogBreadcrumbs } from '../../compositions';
import { ConnectedBlogOverview } from '../../connectors';
import { trans } from '../../helpers/trans';
import { RouteParams } from '../../routes';

import './Blog.scss';

interface BlogParams extends RouteParams {
    category?: string;
}

const Blog: FC = (): ReactElement => {
    const { category = '' } = useParams<BlogParams>();

    const capitalizedCategory = ucFirst(category);
    const metaTitle = category
        ? trans('blog.overview.meta.categoryTitle', { category: capitalizedCategory })
        : trans('blog.overview.meta.title');

    return (
        <Page className="blog">
            <Helmet>
                <title>{metaTitle}</title>
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={trans('blog.overview.meta.description')} />
                <meta name="description" content={trans('blog.overview.meta.description')} />
            </Helmet>

            <header className="blog__content-wrapper">
                {category && (
                    <BlogBreadcrumbs
                        category={category}
                        className="blog__breadcrumbs"
                    />
                )}

                <h1 className="blog__title">
                    {trans('blog.overview.title')}
                </h1>
            </header>

            {/* <ConnectedFeaturedBlogArticles */}
            {/*    category={category} */}
            {/*    className="blog__featured-wrapper" */}
            {/* /> */}

            <div className="blog__content-wrapper">
                <ConnectedBlogOverview
                    category={category}
                    className="blog__blog-overview"
                />
            </div>
        </Page>
    );
};

export default Blog;
