import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, SafeHtml } from '../../components';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { CompanyOverviewTab } from '../../types/pageTabs';
import { roleInfoHeaderContent } from './content';

import './RoleInfoHeader.scss';

interface RoleInfoHeaderProps {
    role: RoleType;
    template: RoleType;
    onCallToActionClick: (role: RoleType) => void;
    className?: string;
}

const RoleInfoHeader: FC<RoleInfoHeaderProps> = ({
    role,
    template,
    onCallToActionClick,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const [userIsAuthenticated, setUserIsAuthenticated] = useState<boolean>(false);

    useEffect((): void => {
        setUserIsAuthenticated(role !== RoleType.none && role === template);
    }, [role, template]);

    const handleCallToActionClick = (): void => {
        if (userIsAuthenticated) {
            if (template === RoleType.student) navigate(RoutePaths.internships());
            if (template === RoleType.jobSeeker) navigate(RoutePaths.jobs());
            if (template === RoleType.company) navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));

            return;
        }

        onCallToActionClick(role);
    };

    const content = roleInfoHeaderContent(template);

    return (
        <header className={`role-info-header ${className}`}>
            <div className="role-info-header__wrapper">
                <div className="role-info-header__text-wrapper">
                    <h1 className="role-info-header__title">
                        {content.title}
                    </h1>

                    <SafeHtml
                        html={content.description}
                        className="role-info-header__description"
                    />

                    <Button
                        text={userIsAuthenticated ? content.authenticatedButtonText : content.buttonText}
                        onClick={handleCallToActionClick}
                        className="role-info-header__button"
                    />
                </div>

                <div className="role-info-header__image-wrapper">
                    <img
                        src={content.image}
                        alt={content.imageAlt}
                        className="role-info-header__image"
                    />
                </div>
            </div>
        </header>
    );
};

export default RoleInfoHeader;
