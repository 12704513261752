import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { SkillsToLearnCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { EscoOccupation } from '../../../models/Esco';
import { SkillsFormData } from '../../../models/Skills';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate, patchCandidate } from '../../../store/candidate/candidateActions';
import { clearEscoSuggestions, fetchSuggestedSkills } from '../../../store/escoOccupations/escoOccupationsActions';
import { fetchEscoSkills } from '../../../store/escoSkills/escoSkillsActions';
import { fetchSkills } from '../../../store/skills/skillsActions';

interface ConnectedSkillsToLearnCardProps {
    activationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedSkillsToLearnCard: FC<ConnectedSkillsToLearnCardProps> = ({
    activationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skills = useTypedSelector(state => state.skillsReducer.skills);
    const skillsError = useTypedSelector(state => state.skillsReducer.error);
    const escoSkills = useTypedSelector(state => state.escoSkillsReducer.escoSkills);
    const escoSkillsError = useTypedSelector(state => state.escoSkillsReducer.error);

    const escoOccupations = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const suggestionsIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const suggestedSkills = useTypedSelector(state => state.escoOccupationsReducer.suggestedSkills);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());

            dispatch(clearEscoSuggestions());
        };
    }, []);

    useEffect((): void => {
        if (isEditable) {
            if (skills.length === 0) dispatch(fetchSkills());
            if (escoSkills.length === 0) dispatch(fetchEscoSkills());
        }
    }, [isEditable]);

    const handleSelectOccupation = (occupation: EscoOccupation): void => {
        dispatch(fetchSuggestedSkills(occupation.id));
    };

    const handleClearSuggestions = (): void => {
        dispatch(clearEscoSuggestions());
    };

    const handleEditSkillsToLearn = (skillsFormData: SkillsFormData): void => {
        if (candidate) {
            dispatch(patchCandidate({
                uuid: candidate.uuid,
                skillsToLearn: skillsFormData.skills,
                escoSkillsToLearn: skillsFormData.escoSkills,
            }));
        }
    };

    return (
        <SkillsToLearnCard
            activationRef={activationRef}
            isLoading={isLoading}
            suggestionsIsLoading={suggestionsIsLoading}
            isEditable={isEditable}
            skills={candidate ? [...candidate.skillsToLearn, ...candidate.escoSkillsToLearn] : []}
            skillOptions={[...skills, ...escoSkills]}
            suggestedSkills={suggestedSkills}
            occupationOptions={escoOccupations}
            error={skillsError || escoSkillsError}
            onSelectOccupation={handleSelectOccupation}
            onClearSuggestions={handleClearSuggestions}
            onEdit={handleEditSkillsToLearn}
            className={className}
        />
    );
};

export default ConnectedSkillsToLearnCard;
