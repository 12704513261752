import React, { FC, ReactElement } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { SbbRecognition } from '../../../../models/SbbTrainingCompany';
import { SbbRecognitionTableRow } from '..';

import './SbbRecognitionTable.scss';

interface SbbRecognitionTableProps {
    recognitions: SbbRecognition[];
    className?: string;
}

const SbbRecognitionTable: FC<SbbRecognitionTableProps> = ({
    recognitions,
    className = '',
}): ReactElement => (
    <Table className={`sbb-recognition-table ${className}`}>
        <TableHead className="sbb-recognition-table__head">
            <TableRow>
                <TableCell className="sbb-recognition-table__cell sbb-recognition-table__cell--crebo">
                    {trans('userProfile.sbb.crebo')}
                </TableCell>
                <TableCell className="sbb-recognition-table__cell sbb-recognition-table__cell--title">
                    {trans('userProfile.sbb.title')}
                </TableCell>
                <TableCell className="sbb-recognition-table__cell sbb-recognition-table__cell--level">
                    {trans('forms.vacancy.educationLevel.label')}
                </TableCell>
                <TableCell className="sbb-recognition-table__cell sbb-recognition-table__cell--date">
                    {trans('userProfile.sbb.startDate')}
                </TableCell>

                <TableCell className="sbb-recognition-table__cell sbb-recognition-table__cell--has-expand-button" />
            </TableRow>
        </TableHead>

        <TableBody>
            {recognitions.map(recognition => (
                <SbbRecognitionTableRow key={recognition.crebo} recognition={recognition} />
            ))}
        </TableBody>
    </Table>
);

export default SbbRecognitionTable;
