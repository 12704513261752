import React, { FC, ReactElement } from 'react';

import { DetailCard } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';

import './ApplicationStatusHired.scss';

interface ApplicationStatusHiredProps {
    isCandidate?: boolean;
    className?: string;
}

const ApplicationStatusHired: FC<ApplicationStatusHiredProps> = ({
    isCandidate,
    className = '',
}): ReactElement => {
    const description = isCandidate
        ? trans('containers.applicationStatusCard.hired.description.candidate')
        : trans('containers.applicationStatusCard.hired.description.company');

    return (
        <DetailCard
            title={trans('containers.applicationStatusCard.hired.title')}
            className={`application-status-hired ${className}`}
        >
            <p className="application-status-hired__description">
                {description}
            </p>
        </DetailCard>
    );
};

export default ApplicationStatusHired;
