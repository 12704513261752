import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { SchoolExperience } from '../../_old/app_talentz/models/SchoolExperience';
import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { FilterButton, IconButton } from '../../compositions';
import { InternshipTypeOption } from '../../compositions/@filters/InternshipTypesFilter/InternshipTypesFilter';
import { getActiveFilterValues } from '../../helpers/filter';
import { getAmountOfDefinedValues } from '../../helpers/object';
import { trans } from '../../helpers/trans';
import { defaultInternshipVacancyOverviewFilterValues, InternshipVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { WorkRemoteOption } from '../../models/WorkRemote';
import { FormOption } from '../../types';
import { VacancySearchForm } from '..';
import { VacancySearchFormValues } from '../@forms/VacancySearchForm/VacancySearchForm';
import { InternshipVacancyOverviewFilterSidebar } from './subcomponents';

import './InternshipVacancyOverviewFilter.scss';

interface InternshipVacancyOverviewFilterProps {
    isLoading: boolean;
    isSbbUser: boolean;
    activeFilterValues: InternshipVacancyOverviewFilterValues;
    filterValues: InternshipVacancyOverviewFilterValues;
    schoolExperience?: SchoolExperience;
    whatOptions: string[];
    whereOptions: string[];
    applyTypeOptions: FormOption[];
    sectorOptions: FormOption[];
    educationLevelOptions: FormOption[];
    workRemoteOptions: WorkRemoteOption[];
    internshipTypeOptions: InternshipTypeOption[];
    gradeOptions: FormOption[];
    amountOfEmployeesOptions: FormOption[];
    sbbApprovalOptions: FormOption[];
    onSearchSubmit: (value: VacancySearchFormValues) => void;
    onFilterOpen: () => void;
    onFilterChange: (value: Partial<InternshipVacancyOverviewFilterValues>) => void;
    onFilterReset: () => void;
    onFilterSubmit: () => void;
    className?: string;
}

const InternshipVacancyOverviewFilter: FC<InternshipVacancyOverviewFilterProps> = ({
    isLoading,
    isSbbUser,
    activeFilterValues,
    filterValues,
    schoolExperience,
    whatOptions,
    whereOptions,
    applyTypeOptions,
    sectorOptions,
    educationLevelOptions,
    workRemoteOptions,
    internshipTypeOptions,
    gradeOptions,
    amountOfEmployeesOptions,
    sbbApprovalOptions,
    onSearchSubmit,
    onFilterOpen,
    onFilterChange,
    onFilterReset,
    onFilterSubmit,
    className = '',
}): ReactElement => {
    const [searchValues, setSearchValues] = useState<VacancySearchFormValues>();
    const [sidebarIsExpanded, setSidebarIsExpanded] = useState<boolean>(false);

    const openSidebar = (): void => {
        onFilterOpen();
        setSidebarIsExpanded(true);
    };

    const closeSidebar = (): void => setSidebarIsExpanded(false);

    useEffect((): void => {
        setSearchValues({
            what: filterValues.what,
            where: filterValues.where,
            type: VacancyType.internship,
        });
    }, [filterValues]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
        pageNumber,
        pageSize,
        what,
        where,
        skills,
        competencies,
        skillsToLearn,
        competenciesToLearn,
        province,
        ...sidebarFilters
    } = activeFilterValues;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const defaultFilters = defaultInternshipVacancyOverviewFilterValues();
    const activeFilters = getActiveFilterValues(sidebarFilters, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    return (
        <section className={`internship-vacancy-overview-filter ${className}`}>
            <VacancySearchForm
                value={searchValues}
                whatOptions={whatOptions}
                whereOptions={whereOptions}
                onSubmit={onSearchSubmit}
                className="internship-vacancy-overview-filter__search-form"
            />

            <div className="internship-vacancy-overview-filter__button-wrapper">
                <FilterButton
                    hasIcon
                    activeFilters={amountOfActiveFilters}
                    disabled={isLoading}
                    onClick={openSidebar}
                    className="internship-vacancy-overview-filter__filter-button"
                />

                {amountOfActiveFilters > 0 && (
                    <IconButton
                        icon="refresh"
                        text={trans('actions.reset')}
                        disabled={isLoading}
                        onClick={onFilterReset}
                        className="internship-vacancy-overview-filter__reset-button"
                    />
                )}
            </div>

            <InternshipVacancyOverviewFilterSidebar
                isLoading={isLoading}
                isExpanded={sidebarIsExpanded}
                isSbbUser={isSbbUser}
                hasLocation={!!where}
                filterValues={filterValues}
                schoolExperience={schoolExperience}
                applyTypeOptions={applyTypeOptions}
                sectorOptions={sectorOptions}
                educationLevelOptions={educationLevelOptions}
                workRemoteOptions={workRemoteOptions}
                internshipTypeOptions={internshipTypeOptions}
                gradeOptions={gradeOptions}
                amountOfEmployeesOptions={amountOfEmployeesOptions}
                sbbApprovalOptions={sbbApprovalOptions}
                onChange={onFilterChange}
                onReset={onFilterReset}
                onSubmit={onFilterSubmit}
                onClose={closeSidebar}
            />
        </section>
    );
};

export default InternshipVacancyOverviewFilter;
