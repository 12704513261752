import React, { FC, ReactElement, useEffect } from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { majorCitiesNL } from '../../constants/residences-nl';
import { HomeVacancyIndex } from '../../containers';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { fetchSectors } from '../../store/sectors/sectorsActions';

interface ConnectedHomeVacancyIndexProps {
    className?: string;
}

const ConnectedHomeVacancyIndex: FC<ConnectedHomeVacancyIndexProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const role = useLegacySelector(state => state.legacyUser.role);
    const person = useLegacySelector(state => state.userPerson.data);

    const sectorIsLoading = useTypedSelector(state => state.sectorsReducer.isLoading);
    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);
    const provinceOptions = useTypedSelector(state => state.provincesReducer.provinces);

    useEffect((): void => {
        if (sectorOptions.length === 0) dispatch(fetchSectors());
    }, []);

    const vacancyType = role === RoleType.student
        ? VacancyType.internship
        : VacancyType.job;

    const userProvince = person?.address?.province;
    const activeProvince = userProvince
        ? provinceOptions.find(province => province.name === userProvince)
        : undefined;

    const cityOptions = activeProvince
        ? activeProvince.majorCities
        : majorCitiesNL;

    return (
        <HomeVacancyIndex
            isLoading={sectorIsLoading}
            vacancyType={vacancyType}
            provinceOptions={provinceOptions}
            cityOptions={cityOptions}
            sectorOptions={sectorOptions}
            className={className}
        />
    );
};

export default ConnectedHomeVacancyIndex;
