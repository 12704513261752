import React, { ReactElement, useEffect, useState } from 'react';

import { RecentVacancies } from '../../containers';
import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { patchFavourites } from '../../store/candidate/candidateActions';
import { fetchRecentInternshipVacancies, fetchRecentJobVacancies, fetchRecentVacancies } from '../../store/recentVacancies/recentVacanciesActions';

const ConnectedRecentVacancies = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const [favouriteIds, setFavouriteIds] = useState<string[]>([]);

    const role = useLegacySelector(state => state.legacyUser.role);

    const isLoading = useTypedSelector(state => state.recentVacanciesReducer.isLoading);
    const error = useTypedSelector(state => state.recentVacanciesReducer.error);
    const recentJobVacancies = useTypedSelector(state => state.recentVacanciesReducer.jobVacancies);
    const recentInternshipVacancies = useTypedSelector(state => state.recentVacanciesReducer.internshipVacancies);
    const totalVacancies = useTypedSelector(state => state.recentVacanciesReducer.totalVacancies);

    const favouritesIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const userRole = role || RoleType.none;

    useEffect((): void => {
        if (userRole === RoleType.student) {
            dispatch(fetchRecentInternshipVacancies());

            return;
        }

        if (userRole === RoleType.jobSeeker) {
            dispatch(fetchRecentJobVacancies());

            return;
        }

        dispatch(fetchRecentVacancies());
    }, [userRole]);

    useEffect((): void => {
        if (candidate) {
            setFavouriteIds(candidate.favourites.map(favourite => favourite.uuid));
        }
    }, [candidate]);

    const handleFavouriteClick = (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean): void => {
        if (candidate) {
            const newFavourites = shouldAddFavourite
                ? [...candidate.favourites, favouriteVacancy]
                : candidate.favourites.filter(favourite => favourite.uuid !== favouriteVacancy.uuid);

            dispatch(patchFavourites(candidate.uuid, newFavourites));
        }
    };

    return (
        <RecentVacancies
            isLoading={isLoading}
            userRole={userRole}
            userFieldsOfStudy={candidate?.schoolExperiences.map(schoolExperience => schoolExperience.fieldOfStudy)}
            jobVacancies={recentJobVacancies}
            internshipVacancies={recentInternshipVacancies}
            totalVacancies={totalVacancies}
            error={error}
            favouriteIds={favouriteIds}
            favouriteIsLoading={favouritesIsLoading}
            onFavouriteClick={handleFavouriteClick}
        />
    );
};

export default ConnectedRecentVacancies;
