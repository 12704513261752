import React, { FC, ReactElement } from 'react';

import './TableBody.scss';

type HTMLTableBodyProps = JSX.IntrinsicElements['tbody'];

interface TableBodyProps extends HTMLTableBodyProps {
    className?: string;
}

const TableBody: FC<TableBodyProps> = ({
    className = '',
    children,
}): ReactElement => (
    <tbody className={`table-body ${className}`}>
        {children}
    </tbody>
);

export default TableBody;
