import React, {
    FC,
    FormEvent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { AddressFormValues } from '../../../../_old/app_talentz/models/Address';
import { ValidationErrors } from '../../../../_old/app_talentz/models/ValidationErrors';
import TextEditor from '../../../../_old/components/form/TextEditor';
import { isEmptyObject } from '../../../../_old/helpers/objectHelper';
import { Card, ExternalLinkButton } from '../../../../components';
import { SelectOption } from '../../../../components/@inputs/Select/Select';
import {
    EducationLevelSelector,
    InformationBox,
    LocationCheck,
    RadioList,
    RangeInput,
    SalaryInput,
    SearchableMultiValueInput,
    SelectInput,
    StepFooter,
    StepHeader,
    TextInput,
} from '../../../../compositions';
import { escoInformationUrl, maxEmploymentHoursPerWeek, maxVacancyLifetimeInDays } from '../../../../constants';
import { addHourUnit } from '../../../../helpers/string';
import { trans } from '../../../../helpers/trans';
import { EducationSubLevel, transformEducationSubLevelToFormOption } from '../../../../models/EducationLevels';
import { EscoOccupation, transformEscoOccupationToSearchableOption } from '../../../../models/Esco';
import { FieldOfStudy, transformFieldOfStudyToSearchableOption } from '../../../../models/FieldsOfStudy';
import { Sector } from '../../../../models/Sectors';
import { sectorsToSelectBoxOptions } from '../../../../models/VacancyOptions';
import { RoutePaths } from '../../../../routes';
import { PlaceJobVacancyFormValues } from '../../../../services/PlaceVacancyService';
import {
    FormOption,
    MinMaxValue,
    SalaryTimeframe,
    SearchableOption,
} from '../../../../types';
import { CompanyOverviewTab } from '../../../../types/pageTabs';
import { PublicationAndExpirationDates } from '../../..';
import { retrieveEducationLevelSpecificOptions } from '../../../@forms/SchoolExperienceForm/helpers';
import animateToJobVacancyErrorElement from '../../helpers/animateToJobVacancyErrorElement';
import { validateJobVacancyDataStep } from '../../helpers/placeJobVacancyStepsValidator';

import './JobVacancyDataStep.scss';

interface JobVacancyDataStepProps {
    isLoading: boolean;
    isEditing?: boolean;
    values: PlaceJobVacancyFormValues;
    educationLevelId: string;
    educationSubLevelId?: string;
    fieldsOfStudy: FieldOfStudy[];
    occupations: EscoOccupation[];
    salaryTimeframe: SalaryTimeframe;
    maxVacancyLifeTimeInDays?: number;
    educationLevelOptions: FormOption[];
    educationSubLevelOptions: EducationSubLevel[];
    fieldsOfStudyOptions: FieldOfStudy[];
    sectorOptions: Sector[];
    jobTypeOptions: FormOption[];
    workRemoteOptions: FormOption[];
    occupationOptions: EscoOccupation[];
    onChange: (values: Partial<PlaceJobVacancyFormValues>) => void;
    onNextClick: () => void;
    onEducationLevelChange: (educationLevel: FormOption) => void;
    onEducationSubLevelChange: (educationSubLevel?: FormOption) => void;
    onFieldOfStudyChange: (fieldOfStudyOptions: FieldOfStudy[]) => void;
    onEmploymentHourRangeChange: (employmentHourRange: MinMaxValue) => void;
    onJobTypeChange: (jobType: string) => void;
    onWorkRemotePreferenceChange: (workRemotePreference: string) => void;
    onOccupationsChange: (occupations: EscoOccupation[]) => void;
    onSalaryTimeframeChange: (timeframe: SalaryTimeframe) => void;
}

const JobVacancyDataStep: FC<JobVacancyDataStepProps> = ({
    isLoading,
    isEditing = false,
    values,
    educationLevelId,
    educationSubLevelId,
    fieldsOfStudy,
    occupations,
    salaryTimeframe,
    educationLevelOptions,
    educationSubLevelOptions,
    fieldsOfStudyOptions,
    jobTypeOptions,
    workRemoteOptions,
    sectorOptions,
    occupationOptions,
    maxVacancyLifeTimeInDays = maxVacancyLifetimeInDays,
    onChange,
    onNextClick,
    onEducationLevelChange,
    onEducationSubLevelChange,
    onFieldOfStudyChange,
    onEmploymentHourRangeChange,
    onJobTypeChange,
    onWorkRemotePreferenceChange,
    onOccupationsChange,
    onSalaryTimeframeChange,
}): ReactElement => {
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [isSubmittedOnce, setSubmittedOnce] = useState(false);
    const navigate = useNavigate();

    const [fieldsOfStudyValue, setFieldsOfStudyValue] = useState<SearchableOption[]>([]);
    const [escoOccupations, setEscoOccupations] = useState<SearchableOption[]>([]);
    const [hasSelectedOccupation, setHasSelectedOccupation] = useState<boolean>(false);
    const [hasMadeSuggestion, setHasMadeSuggestion] = useState<boolean>(false);

    const hasSelectedHourlySalary = salaryTimeframe === SalaryTimeframe.hour;

    const stepHeaderTitle = isEditing
        ? trans('placeVacancyPage.job.dataStep.editTitle')
        : trans('placeVacancyPage.job.dataStep.title');

    const applyTypeOptions = [
        { label: trans('forms.vacancy.applyRegular.label'), value: 'false' },
        { label: trans('forms.vacancy.applyAnonymously.label'), value: 'true' },
    ];

    const salaryTimeframeOptions = [
        { label: trans('forms.salary.input.timeframe.perHour'), value: SalaryTimeframe.hour },
        { label: trans('forms.salary.input.timeframe.perMonth'), value: SalaryTimeframe.month },
    ];

    const educationLevelSubLevelOptions = useMemo((): FormOption[] => {
        const filteredEducationSubLevels = retrieveEducationLevelSpecificOptions<EducationSubLevel>(educationSubLevelOptions, educationLevelId);

        return filteredEducationSubLevels.map(transformEducationSubLevelToFormOption);
    }, [educationSubLevelOptions, educationLevelId]);

    const educationLevelFieldOfStudyOptions = useMemo((): SearchableOption[] => {
        const filteredEducationFieldsOfStudy = retrieveEducationLevelSpecificOptions<FieldOfStudy>(fieldsOfStudyOptions, educationLevelId);

        return filteredEducationFieldsOfStudy.map(transformFieldOfStudyToSearchableOption);
    }, [fieldsOfStudyOptions, educationLevelId]);

    useEffect((): void => {
        setFieldsOfStudyValue(fieldsOfStudy.map(transformFieldOfStudyToSearchableOption));
    }, [fieldsOfStudy]);

    useEffect((): void => {
        if (occupations.length > 0) {
            setEscoOccupations(occupations.map(transformEscoOccupationToSearchableOption));
            setHasSelectedOccupation(true);
        }
    }, [occupations]);

    const validateForm = useCallback(() => {
        const validationErrors = {
            ...validateJobVacancyDataStep(values, true),
            ...(!hasSelectedOccupation ? {
                occupations: [trans('validation.occupation.noSelected')],
            } : {}),
        };

        setErrors(validationErrors);

        return validationErrors;
    }, [values, hasSelectedOccupation]);

    // Re validate all values when the user corrects itself
    useEffect((): void => {
        if (isSubmittedOnce) {
            validateForm();
        }
    }, [values, validateForm]);

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmittedOnce(true);
        const validationErrors = validateForm();

        if (isEmptyObject(validationErrors)) {
            onNextClick();
        } else {
            animateToJobVacancyErrorElement(validationErrors);
        }
    }, [onNextClick, validateForm]);

    const handleApplyTypeChange = useCallback((applyAnonymous: string) => {
        onChange({
            ...values,
            applyAnonymous: applyAnonymous === 'true',
        });
    }, [values, onChange]);

    const handleTitleChange = useCallback((title: string) => {
        onChange({
            title,
        });
    }, [onChange]);

    const handleZipCodeCheckChange = useCallback((address: AddressFormValues | null) => {
        onChange({
            address,
        });
    }, [onChange]);

    const handleSectorChange = useCallback((options: SelectOption[]) => {
        const sectorIds = options.map(option => option.value);

        onChange({
            sectorId: sectorIds[0] || '',
        });
    }, [onChange]);

    const handleExpirationDateChange = useCallback((date: Date | null) => {
        onChange({
            expirationDate: date,
        });
    }, [onChange]);

    const handlePublicationDateChange = useCallback((date: Date | null) => {
        onChange({
            publicationDate: date,
        });
    }, [onChange]);

    const handleDescriptionChange = useCallback((description: string) => {
        onChange({
            description,
        });
    }, [onChange]);

    const handleFieldsOfStudyChange = (fieldsOfStudyValues: SearchableOption[]): void => {
        setFieldsOfStudyValue(fieldsOfStudyValues);

        const selectedFieldsOfStudyIds = fieldsOfStudyValues.map(fieldOfStudy => fieldOfStudy.value);

        const selectedFieldsOfStudy = fieldsOfStudyValues.length
            ? fieldsOfStudyOptions.filter(option => selectedFieldsOfStudyIds.includes(option.id))
            : [];

        const orderedSelectedFieldsOfStudy = selectedFieldsOfStudyIds.map(fieldOfStudyValue => {
            const selectedFieldOfStudy = selectedFieldsOfStudy.filter(fieldOfStudy => fieldOfStudy.id === fieldOfStudyValue);

            return selectedFieldOfStudy[0];
        }).filter(Boolean);

        if (selectedFieldsOfStudy.length) {
            onFieldOfStudyChange(orderedSelectedFieldsOfStudy);
        }
    };

    const handleOccupationsChange = (occupationValues: SearchableOption[]): void => {
        setEscoOccupations(occupationValues);

        const selectedOccupationIds = occupationValues.map(occupation => occupation.value);

        const selectedOccupations = occupationValues.length
            ? occupationOptions.filter(option => selectedOccupationIds.includes(option.id))
            : [];

        const orderedSelectedOccupations = selectedOccupationIds.map(occupationValue => {
            const selectedOccupation = selectedOccupations.filter(occupation => occupation.id === occupationValue);

            return selectedOccupation[0];
        }).filter(Boolean);

        setHasSelectedOccupation(!!selectedOccupationIds.length);

        if (selectedOccupations.length) {
            onOccupationsChange(orderedSelectedOccupations);

            setHasMadeSuggestion(true);
        }
    };

    const handleSalaryChange = (min: number | '', max: number | ''): void => {
        if (hasSelectedHourlySalary) {
            onChange({
                minHourSalary: min,
                maxHourSalary: max,
                minMonthSalary: '',
                maxMonthSalary: '',
            });
        } else {
            onChange({
                minHourSalary: '',
                maxHourSalary: '',
                minMonthSalary: min,
                maxMonthSalary: max,
            });
        }
    };

    const handleBackToOverviewClick = (): void => {
        navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));
    };

    return (
        <form onSubmit={handleSubmit} className="job-vacancy-data-step">
            <Helmet>
                <title>{stepHeaderTitle}</title>
            </Helmet>

            <Card className="job-vacancy-data-step__card">
                <StepHeader
                    title={stepHeaderTitle}
                    currentStep={1}
                    totalSteps={2}
                />

                <section className="job-vacancy-data-step__form-elements">
                    <h2>
                        {trans('placeVacancyPage.steps.applicationType')}
                    </h2>

                    <InformationBox
                        descriptions={[
                            trans('forms.vacancy.applyIntro.description'),
                            trans('forms.vacancy.applyAnonymously.information'),
                        ]}
                        className="job-vacancy-data-step__apply-anonymous-disclaimer"
                    />

                    <RadioList
                        label={trans('forms.vacancy.applyIntro.label')}
                        name="apply_anonymous"
                        onChange={handleApplyTypeChange}
                        options={applyTypeOptions}
                        value={values.applyAnonymous.toString()}
                        disabled={isLoading || isEditing}
                        required
                        className="job-vacancy-data-step__apply-anonymous"
                    />
                </section>
            </Card>

            <Card className="job-vacancy-data-step__card">
                <h2 className="job-vacancy-data-step__card-title">
                    {trans('forms.vacancy.generalData')}
                </h2>

                <section className="job-vacancy-data-step__form-elements">
                    <TextInput
                        id="title"
                        label={trans('forms.vacancy.title.label')}
                        className="job-vacancy-data-step__form-input"
                        placeholder={trans('forms.vacancy.title.placeholder')}
                        value={values.title}
                        disabled={isLoading}
                        onChange={handleTitleChange}
                        error={errors.title && errors.title[0]}
                        required
                        name="title"
                    />

                    <LocationCheck
                        error={errors.zipcode && errors.zipcode[0]}
                        userAddress={values.address}
                        disabled={isLoading}
                        onChange={handleZipCodeCheckChange}
                    />

                    <SelectInput
                        id="sectors"
                        onSelected={handleSectorChange}
                        options={sectorsToSelectBoxOptions(sectorOptions)}
                        className="job-vacancy-data-step__form-input"
                        label={trans('forms.vacancy.job.sector.label')}
                        placeholder={trans('forms.vacancy.job.sector.placeholder')}
                        value={values.sectorId}
                        disabled={isLoading}
                        error={errors.sectors && errors.sectors[0]}
                        name="sectors"
                        required
                        clearable
                    />

                    <PublicationAndExpirationDates
                        isLoading={isLoading}
                        isEditing={isEditing}
                        id="publication-date"
                        expirationDate={values.expirationDate}
                        publicationDate={values.publicationDate}
                        expirationDateErrors={errors.expirationDate}
                        onExpirationChange={handleExpirationDateChange}
                        onPublicationChange={handlePublicationDateChange}
                        publicationDateErrors={errors.publicationDate}
                        maxVacancyLifeTimeInDays={maxVacancyLifeTimeInDays}
                        className="job-vacancy-data-step__form-input"
                    />

                    <div id="education-levels">
                        <EducationLevelSelector
                            educationLevelLabel={trans('forms.vacancy.minimumEducationLevel.label')}
                            educationLevel={educationLevelId}
                            educationSubLevel={educationSubLevelId}
                            educationLevelOptions={educationLevelOptions}
                            educationSubLevelOptions={educationLevelSubLevelOptions}
                            subLevelsIsRequired={false}
                            formErrors={{ educationLevel: errors.educationLevels?.[0] }}
                            otherOptionLabel={trans('basic.radio.none')}
                            disabled={isLoading}
                            onEducationLevelChange={onEducationLevelChange}
                            onEducationSubLevelChange={onEducationSubLevelChange}
                            className="job-vacancy-data-step__form-input"
                        />
                    </div>

                    {educationLevelFieldOfStudyOptions.length > 0 && (
                        <SearchableMultiValueInput
                            isSearchable
                            showResultsOnClick
                            id="fields-of-study"
                            label={trans('forms.vacancy.fieldOfStudy.label')}
                            value={fieldsOfStudyValue}
                            disabled={isLoading || !educationLevelId}
                            error={errors.fieldsOfStudy && errors.fieldsOfStudy[0]}
                            options={educationLevelFieldOfStudyOptions}
                            placeholder={trans('forms.vacancy.fieldOfStudy.placeholder')}
                            onChange={handleFieldsOfStudyChange}
                            className="job-vacancy-data-step__form-input"
                        />
                    )}

                    <div id="employment-hour-range">
                        <RangeInput
                            required
                            label={trans('forms.vacancy.employment.label')}
                            step={2}
                            max={maxEmploymentHoursPerWeek}
                            value={values.employmentHourRange}
                            marks={5}
                            disabled={isLoading}
                            error={errors.employmentHourRange && errors.employmentHourRange[0]}
                            valueFormat={addHourUnit}
                            onChange={onEmploymentHourRangeChange}
                            className="job-vacancy-data-step__employment-hour-range"
                        />
                    </div>

                    <RadioList
                        label={trans('forms.vacancy.job.type.label')}
                        id="job-type"
                        name="jobType"
                        required
                        options={jobTypeOptions}
                        value={values.jobTypeId}
                        disabled={isLoading}
                        error={errors.jobType && errors.jobType[0]}
                        onChange={onJobTypeChange}
                        className="job-vacancy-data-step__form-input"
                    />

                    <RadioList
                        label={trans('forms.vacancy.workRemote.label')}
                        id="work-remote-preference"
                        name="work-remote-preference"
                        required
                        options={workRemoteOptions}
                        value={values.workRemotePreferenceId}
                        disabled={isLoading}
                        error={errors.workRemotePreference && errors.workRemotePreference[0]}
                        onChange={onWorkRemotePreferenceChange}
                        className="job-vacancy-data-step__form-input"
                    />
                </section>
            </Card>

            <Card className="job-vacancy-data-step__card">
                <h2 className="job-vacancy-data-step__card-title">
                    {trans('forms.occupation.title')}
                </h2>

                <InformationBox
                    descriptions={[
                        trans('forms.occupation.description.1'),
                        trans('forms.occupation.description.2'),
                        trans('forms.occupation.description.3'),
                    ]}
                    className="job-vacancy-data-step__occupation-esco-disclaimer"
                />

                <ExternalLinkButton
                    href={escoInformationUrl}
                    text={trans('forms.occupation.hyperlink.esco')}
                    className="job-vacancy-data-step__occupation-link"
                />

                <SearchableMultiValueInput
                    isSearchable
                    showResultsOnClick
                    required
                    id="occupations"
                    label={trans('forms.occupation.input.positions')}
                    value={escoOccupations}
                    disabled={isLoading}
                    error={errors.occupations && errors.occupations[0]}
                    options={occupationOptions.map(transformEscoOccupationToSearchableOption)}
                    placeholder={trans('forms.occupation.placeholder.position')}
                    onChange={handleOccupationsChange}
                    className="job-vacancy-data-step__occupation-input"
                />

                {(hasMadeSuggestion && escoOccupations.length > 0) && (
                    <InformationBox
                        descriptions={[trans('forms.occupation.suggestionDisclaimer', {
                            occupation: escoOccupations[0].label,
                        })]}
                        className="job-vacancy-data-step__occupation-disclaimer"
                    />
                )}
            </Card>

            <Card className="job-vacancy-data-step__card">
                <h2 className="job-vacancy-data-step__card-title">
                    {trans('forms.vacancy.salary.title')}
                </h2>

                <SalaryInput
                    id="salary"
                    label={trans('forms.salary.input.grossSalary')}
                    ctaText={trans('forms.salary.cta.job')}
                    timeframe={salaryTimeframe}
                    timeframeOptions={salaryTimeframeOptions}
                    minValue={hasSelectedHourlySalary ? values.minHourSalary : values.minMonthSalary}
                    maxValue={hasSelectedHourlySalary ? values.maxHourSalary : values.maxMonthSalary}
                    disabled={isLoading}
                    error={errors.salary && errors.salary[0]}
                    onTimeframeChange={onSalaryTimeframeChange}
                    onMinMaxChange={handleSalaryChange}
                />
            </Card>

            <Card className="job-vacancy-data-step__card">
                <h2 className="job-vacancy-data-step__card-title">
                    {trans('forms.vacancy.description.title')}
                </h2>

                <TextEditor
                    required
                    id="description"
                    name="description"
                    label={trans('forms.vacancy.description.label')}
                    defaultValue={values.description}
                    placeholder={trans('forms.vacancy.job.description.jobPlaceholder')}
                    disabled={isLoading}
                    errors={errors.description}
                    onChange={handleDescriptionChange}
                />
            </Card>

            <Card className="job-vacancy-data-step__card">
                <StepFooter
                    loading={isLoading}
                    backButtonText={trans('placeVacancyPage.steps.backTo.myVacancies')}
                    onBackButtonClick={handleBackToOverviewClick}
                />
            </Card>
        </form>
    );
};

export default JobVacancyDataStep;
