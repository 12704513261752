import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, RadioList } from '../../../compositions';
import { defaultFormOption } from '../../../constants';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { WorkRemotePreferenceFormData } from '../../../models/WorkRemote';
import { FormOption, FormProps } from '../../../types';
import { validateWorkRemotePreferenceFormData, WorkRemotePreferenceFormErrors } from './validations';

import './WorkRemotePreferenceForm.scss';

interface WorkRemotePreferenceFormProps extends FormProps<WorkRemotePreferenceFormData> {
    workRemotePreference: FormOption;
    workRemotePreferenceOptions: FormOption[];
    className?: string;
}

const WorkRemotePreferenceForm: FC<WorkRemotePreferenceFormProps> = ({
    workRemotePreference,
    workRemotePreferenceOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<WorkRemotePreferenceFormErrors>({});

    const [selectedPreference, setSelectedPreference] = useState<string>(workRemotePreference.value);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            workRemotePreference: workRemotePreferenceOptions
                .find(option => option.value === selectedPreference) || defaultFormOption(),
        };

        const [errors, hasErrors] = validateWorkRemotePreferenceFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`work-remote-preference-form ${className}`}>
            <RadioList
                label={trans('forms.workRemotePreference.input.preference')}
                name="work-remote-preference"
                options={workRemotePreferenceOptions}
                value={selectedPreference}
                error={formErrors.workRemotePreference}
                onChange={setSelectedPreference}
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="work-remote-preference-form__action-buttons"
            />
        </form>
    );
};

export default WorkRemotePreferenceForm;
