import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { trans } from '../../../helpers/trans';
import { Row } from '../../styledComponents/components/Grid';
import { Heading } from '../../styledComponents/components/Typography';
import ButtonFooter from '../form/ButtonFooter';
import Overlay from '../Overlay';
import RichText from '../RichText';

const AppUpdates = props => {
    const navigate = useNavigate();

    const [latestUpdate, setLatestUpdate] = useState(null);
    const {
        updates,
        clearUpdates,
        onUpdateRead,
    } = props;

    const onRedirect = () => {
        clearUpdates();
        navigate(latestUpdate.link);
    };

    useEffect(() => {
        setLatestUpdate(updates[0]);
        onUpdateRead(updates[0].uuid);
    }, []);

    return (
        <Overlay dark onClose={() => clearUpdates()}>
            {latestUpdate && (
                <>
                    <Heading as="h2">
                        {latestUpdate.title}
                    </Heading>
                    <Row mb="xl">
                        <RichText content={latestUpdate.description} />
                    </Row>
                    <ButtonFooter
                        onSubmit={() => onRedirect()}
                        saveLabel={latestUpdate.link_label}
                        onCancel={() => clearUpdates()}
                        cancelLabel={trans('actions.close')}
                    />
                </>
            )}
        </Overlay>
    );
};

AppUpdates.propTypes = {
    updates: PropTypes.array.isRequired,
    clearUpdates: PropTypes.func.isRequired,
    onUpdateRead: PropTypes.func.isRequired,
};

export default AppUpdates;
