import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getVacancyMatchesApiCall } from '../../services/VacancyMatchesService';
import { getVacancyApiCall } from '../../services/VacancyService';
import { ReducerGetter, TypedDispatch } from '..';
import {
    setActiveVacancy,
    setCandidates,
    setError,
    setIsLoading,
    setPagination,
} from './vacancyMatches';

export const clearVacancyMatches = () => (dispatch: TypedDispatch): void => {
    dispatch(setActiveVacancy(undefined));
    dispatch(setCandidates([]));
    dispatch(setPagination(undefined));
};

export const fetchVacancyMatches = (vacancyUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyResponse = await getVacancyApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(vacancyResponse)) {
            console.error('[fetchVacancyMatches]', vacancyResponse.error);
            dispatch(setError(vacancyResponse.error));

            return;
        }

        const vacancy = vacancyResponse.data;
        const { vacancyMatchesReducer } = getState();
        const { activeFilterValues } = vacancyMatchesReducer;

        const vacancyMatchesResponse = await getVacancyMatchesApiCall(vacancyUuid, vacancy.type, activeFilterValues);

        if (!isFetchResultSuccessful(vacancyMatchesResponse)) {
            console.error('[fetchVacancyMatches]', vacancyMatchesResponse.error);
            dispatch(setError(vacancyMatchesResponse.error));

            return;
        }

        const { candidates, pagination } = vacancyMatchesResponse.data;

        dispatch(setActiveVacancy(vacancy));
        dispatch(setPagination(pagination));
        dispatch(setCandidates(candidates));
    } catch (error) {
        console.error('[fetchVacancyMatches]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
