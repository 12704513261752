import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ProgressRefButton, Skeleton } from '../../../components';
import {
    DetailCard,
    IconButton,
    Modal,
    ModalContent,
    Tag,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { CompetenciesFormData, Competency } from '../../../models/Competencies';
import { EscoOccupation } from '../../../models/Esco';
import { CompetenciesForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';

import './CompetenciesCard.scss';

interface CompetenciesCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    suggestionsIsLoading: boolean;
    isEditable?: boolean;
    competencies?: Competency[];
    competencyOptions: Competency[];
    suggestedCompetencies: Competency[];
    occupationOptions: EscoOccupation[];
    error?: string;
    onSelectOccupation?: (occupation: EscoOccupation) => void;
    onClearSuggestions?: () => void;
    onEdit?: (competenciesFormData: CompetenciesFormData) => void;
    className?: string;
}

const CompetenciesCard: FC<CompetenciesCardProps> = ({
    activationRef,
    isLoading,
    suggestionsIsLoading,
    isEditable,
    competencies = [],
    competencyOptions,
    suggestedCompetencies,
    occupationOptions,
    error,
    onSelectOccupation,
    onClearSuggestions,
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    useEffect((): void => {
        if (!editModalIsOpen && onClearSuggestions) {
            onClearSuggestions();
        }
    }, [editModalIsOpen]);

    const handleEditCompetencies = (competenciesFormData: CompetenciesFormData): void => {
        if (onEdit) {
            onEdit(competenciesFormData);
        }

        closeEditModal();
    };

    const handleDeleteCompetencies = (): void => {
        if (onEdit) {
            onEdit({ competencies: [] });
        }
    };

    const description = isEditable
        ? trans('candidateProfile.competencies.description.candidate')
        : trans('candidateProfile.competencies.description.company');

    const nullState = isEditable
        ? trans('candidateProfile.competencies.nullState.candidate')
        : trans('candidateProfile.competencies.nullState.company');

    return (
        <DetailCard
            isLoading={isLoading}
            isEditable={isEditable && competencies.length > 0}
            title={trans('candidateProfile.competencies.title')}
            description={description}
            onEditClick={openEditModal}
            onDeleteClick={handleDeleteCompetencies}
            className={`competencies-card ${className}`}
        >
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            {isLoading && (
                <Skeleton className="competencies-card__skeleton" />
            )}

            {!isLoading && competencies.length === 0 && (
                <div className="competencies-card__null-state">
                    <p>{nullState}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('actions.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="competencies-card__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && competencies.length > 0 && (
                <ul className="competencies-card__tag-list">
                    {competencies.map(competency => (
                        <li key={competency.id} className="competencies-card__tag-item">
                            <Tag isActive text={competency.name} />
                        </li>
                    ))}
                </ul>
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.competencies.modal.title')}>
                        <CompetenciesForm
                            isLoading={suggestionsIsLoading}
                            competencies={competencies}
                            options={competencyOptions}
                            suggestedCompetencies={suggestedCompetencies}
                            occupationOptions={occupationOptions}
                            error={error}
                            onSelectOccupation={onSelectOccupation}
                            onCancel={closeEditModal}
                            onSubmit={handleEditCompetencies}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default CompetenciesCard;
