import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { FieldOfStudy } from '../../models/FieldsOfStudy';
import { AsyncReduxState } from '../../types';

export type FieldsOfStudyState = AsyncReduxState<{
    fieldsOfStudy: FieldOfStudy[];
    manualFieldsOfStudy: FieldOfStudy[];
}>;

const initialState: FieldsOfStudyState = {
    ...initialAsyncReduxState,
    fieldsOfStudy: [],
    manualFieldsOfStudy: [],
};

export const fieldsOfStudySlice = createSlice({
    name: 'fieldsOfStudyReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): FieldsOfStudyState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): FieldsOfStudyState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFieldsOfStudy(state, action: PayloadAction<FieldOfStudy[]>): FieldsOfStudyState {
            return {
                ...state,
                fieldsOfStudy: action.payload,
            };
        },
        setManualFieldsOfStudy(state, action: PayloadAction<FieldOfStudy[]>): FieldsOfStudyState {
            return {
                ...state,
                manualFieldsOfStudy: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setFieldsOfStudy,
    setManualFieldsOfStudy,
} = fieldsOfStudySlice.actions;

export default fieldsOfStudySlice;
