import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '../../../components';
import { trans } from '../../../helpers/trans';
import { ButtonList, ModalContent } from '../..';

import './ConfirmModal.scss';

interface ConfirmModalProps {
    isNegative?: boolean;
    title: string;
    description: string;
    confirmLabel?: string;
    onCancel: () => void;
    onConfirm: () => void;
    className?: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
    isNegative,
    title,
    description,
    confirmLabel = trans('actions.confirm'),
    onCancel,
    onConfirm,
    className = '',
}): ReactElement => {
    const confirmButtonClassName = classNames('confirm-modal__button', {
        'confirm-modal__button--is-negative': isNegative,
    });

    return (
        <ModalContent title={title} className={`confirm-modal ${className}`}>
            <p className="confirm-modal__description">
                {description}
            </p>

            <ButtonList className="confirm-modal__button-list">
                <Button
                    text={confirmLabel}
                    onClick={onConfirm}
                    className={confirmButtonClassName}
                />

                <Button
                    text={trans('actions.cancel')}
                    onClick={onCancel}
                    className="confirm-modal__button confirm-modal__button--for-cancel"
                />
            </ButtonList>
        </ModalContent>
    );
};

export default ConfirmModal;
