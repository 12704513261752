import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Checkbox } from '../../../components';
import { FormActionButtons, MinMaxInput, NumberInput } from '../../../compositions';
import { maxEmploymentHoursPerWeek } from '../../../constants';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { EmploymentPreferenceFormData } from '../../../models/Employment';
import { FormProps, HorizontalAlignment, MinMaxValue } from '../../../types';
import { EmploymentPreferenceFormErrors, validateEmploymentPreferenceFormData } from './validations';

import './EmploymentPreferenceForm.scss';

interface EmploymentPreferenceFormProps extends FormProps<EmploymentPreferenceFormData> {
    employmentPreference: MinMaxValue;
    className?: string;
}

const EmploymentPreferenceForm: FC<EmploymentPreferenceFormProps> = ({
    employmentPreference,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<EmploymentPreferenceFormErrors>({});

    const [prefersFixedHours, setPrefersFixedHours] = useState<boolean>(employmentPreference.min !== 0 && employmentPreference.min === employmentPreference.max);
    const [minHours, setMinHours] = useState<number | ''>(employmentPreference.min);
    const [maxHours, setMaxHours] = useState<number | ''>(employmentPreference.max);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (prefersFixedHours) {
            setMinHours(maxHours);
        }
    }, [prefersFixedHours]);

    const handleChangeFixedHours = (value: number): void => {
        setMinHours(value || '');
        setMaxHours(value || '');
    };

    const handleMinChange = (value: number): void => {
        setMinHours(value || '');
    };

    const handleMaxChange = (value: number): void => {
        setMaxHours(value || '');
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            min: minHours || 0,
            max: maxHours || 0,
        };

        const optionalValidation = {
            min: !prefersFixedHours,
            max: !prefersFixedHours,
        };

        const [errors, hasErrors] = validateEmploymentPreferenceFormData(formData, optionalValidation);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const inputProps = {
        min: 1,
        max: maxEmploymentHoursPerWeek,
    };

    const minInput = {
        ...inputProps,
        label: trans('forms.employmentPreference.input.min'),
        value: minHours,
        onChange: handleMinChange,
    };

    const maxInput = {
        ...inputProps,
        label: trans('forms.employmentPreference.input.max'),
        value: maxHours,
        onChange: handleMaxChange,
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`employment-preference-form ${className}`}>
            <Checkbox
                label={trans('forms.employmentPreference.input.preferFixedAmountOfHours')}
                checked={prefersFixedHours}
                onChange={setPrefersFixedHours}
                className="employment-preference-form__input"
            />

            {prefersFixedHours ? (
                <NumberInput
                    {...inputProps}
                    label={trans('forms.employmentPreference.input.amountOfHours')}
                    value={maxHours}
                    error={formErrors.min}
                    onChange={handleChangeFixedHours}
                    className="employment-preference-form__input employment-preference-form__fixed-hours-input"
                />
            ) : (
                <MinMaxInput
                    labelAlignment={HorizontalAlignment.right}
                    label={trans('forms.employmentPreference.input.amountOfHours')}
                    minInput={minInput}
                    maxInput={maxInput}
                    unit={trans('basic.unit.hour')}
                    error={formErrors.min || formErrors.max}
                    className="employment-preference-form__input"
                />
            )}

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="employment-preference-form__action-buttons"
            />
        </form>
    );
};

export default EmploymentPreferenceForm;
