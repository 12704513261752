import React, {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';
import { FormOption } from '../../../types';
import { RadioList, SearchInput } from '../..';
import { getUniqueApplications } from './helpers';
import { ApplicationSelectorOption } from './subcomponents';

import './ApplicationSelector.scss';

enum HasMatch {
    yes = 'yes',
    no = 'no',
}

interface ApplicationSelectorProps {
    applications: Application[];
    value: Application[];
    onChangeHasFoundMatch: (hasFoundMatch: boolean) => void;
    onChangeApplication: (applications: Application[]) => void;
    className?: string;
}

const ApplicationSelector: FC<ApplicationSelectorProps> = ({
    applications,
    value,
    onChangeHasFoundMatch,
    onChangeApplication,
    className = '',
}): ReactElement => {
    const [filteredApplications, setFilteredApplications] = useState<Application[]>(getUniqueApplications(applications));

    const [selectedMatchOption, setSelectedMatchOption] = useState<string>(HasMatch.no);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const matchOptions: FormOption[] = [
        { label: trans('actions.no'), value: HasMatch.no },
        { label: trans('actions.yes'), value: HasMatch.yes },
    ];

    const handleChangeHasMatch = (hasMatchValue: string): void => {
        setSelectedMatchOption(hasMatchValue);
        onChangeHasFoundMatch(hasMatchValue === HasMatch.yes);
        onChangeApplication([]);
    };

    const handleSearch = (query: string): void => {
        const searchResults = [...applications].filter(application => {
            const name = application.applicant.fullName.toLowerCase();
            return name.includes(query.toLowerCase());
        });

        const uniqueApplications = getUniqueApplications(searchResults);

        setSearchQuery(query);
        setFilteredApplications(uniqueApplications);
    };

    const handleChangeSelectedApplications = (application: Application, isChecked: boolean): void => {
        const newSelectedApplications = isChecked
            ? [...value, application]
            : [...value.filter(app => app.uuid !== application.uuid)];

        onChangeApplication(newSelectedApplications);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit} className={`application-selector ${className}`}>
            <RadioList
                label={trans('forms.applicationSelector.input.match')}
                name="has-match"
                options={matchOptions}
                value={selectedMatchOption}
                onChange={handleChangeHasMatch}
            />

            {selectedMatchOption === HasMatch.yes && (
                <div className="application-selector__application-wrapper">
                    <SearchInput
                        label={trans('forms.applicationSelector.input.search')}
                        value={searchQuery}
                        onChange={handleSearch}
                    />

                    {filteredApplications.length === 0 && (
                        <div className="application-selector__result-wrapper">
                            <p className="application-selector__no-results">
                                {trans('forms.applicationSelector.noResults')}
                            </p>
                        </div>
                    )}

                    {filteredApplications.length > 0 && (
                        <div className="application-selector__result-wrapper">
                            {filteredApplications.map(application => (
                                <ApplicationSelectorOption
                                    key={application.uuid}
                                    application={application}
                                    selectedApplicationUuids={value.map(app => app.uuid)}
                                    onChange={handleChangeSelectedApplications}
                                    className="application-selector__option"
                                />
                            ))}
                        </div>
                    )}

                    <p className="application-selector__footnote">
                        {trans('forms.applicationSelector.footnote')}
                    </p>
                </div>
            )}
        </form>
    );
};

export default ApplicationSelector;
