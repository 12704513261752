export enum UserSettingsTab {
    personalData = 'mijn-gegevens',
    account = 'account',
    notificationsAndPrivacy = 'meldingen-en-privacy',
}

export enum CandidateOverviewTab {
    applications = 'sollicitaties',
    messages = 'berichten',
}

export enum CompanyOverviewTab {
    vacancies = 'vacatures',
    applicants = 'sollicitanten',
    matches = 'matches-zoeken',
    messages = 'berichten',
}

export enum CompanySettingsTab {
    myCompany = 'mijn-bedrijf',
    personalData = 'mijn-gegevens',
    account = 'mijn-account',
    notificationsAndPrivacy = 'meldingen-en-privacy',
}
