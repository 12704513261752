import React, { FC, ReactElement } from 'react';

import { ErrorPageContent } from '../../containers';
import { useLegacySelector } from '../../store';
import { StatusCode } from '../../types';

interface ConnectedErrorPageContentProps {
    status: StatusCode;
    className?: string;
}

const ConnectedErrorPageContent: FC<ConnectedErrorPageContentProps> = ({
    status,
    className = '',
}): ReactElement => {
    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const userIsLoading = useLegacySelector(state => state.legacyUser.loading);

    return (
        <ErrorPageContent
            isLoading={userIsLoading && !isAuthenticated}
            status={status}
            className={className}
        />
    );
};

export default ConnectedErrorPageContent;
