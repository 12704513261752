import { transformMetaToPagination } from '../_old/app_talentz/models/Pagination';
import { isZipcode } from '../_old/helpers/isZipcode';
import { defaultTravelDistance } from '../constants';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { getActiveFilterValues } from '../helpers/filter';
import { getAmountOfDefinedValues } from '../helpers/object';
import { convertToQueryParams } from '../helpers/params';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { NewestVacancyResource, transformToNewestVacancy, VacancyOverview } from '../models/NewestVacancy';
import { defaultJobVacancyOverviewFilterValues, JobVacancyOverviewFilterValues, transformToJobVacancyOverviewFilterParams } from '../models/VacancyOverview';

export const getMatchingJobVacanciesApiCall = async (filterValues: JobVacancyOverviewFilterValues): Promise<FetchResult<VacancyOverview, string>> => {
    const locationIsPostalCode = filterValues.where && isZipcode(filterValues.where);

    const filterParams = transformToJobVacancyOverviewFilterParams({
        ...filterValues,
        where: filterValues.where && !locationIsPostalCode
            ? filterValues.where
            : '',
        postalCode: filterValues.where && locationIsPostalCode
            ? filterValues.where
            : '',
        distance: filterValues.where
            ? filterValues.distance || defaultTravelDistance
            : 0,
    });

    const queryParams = convertToQueryParams(filterParams);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/matching-job-vacancies?${queryParams}`);

        if (!response.ok) {
            const errorDoc = await response.json();
            const error = errorDoc.errors[0];

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<NewestVacancyResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const vacancies = doc.data.map(transformToNewestVacancy);
        const pagination = doc.meta ? transformMetaToPagination(doc.meta) : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                vacancies,
                pagination,
            },
        };
    } catch (error) {
        console.error('[getMatchingJobVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getJobVacanciesApiCall = async (filterValues: JobVacancyOverviewFilterValues): Promise<FetchResult<VacancyOverview, string>> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pageNumber, pageSize, ...filters } = filterValues;

    const defaultFilters = defaultJobVacancyOverviewFilterValues();
    const activeFilters = getActiveFilterValues(filters, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    const sortValue = amountOfActiveFilters === 0
        ? '-vacancyPublicationDate'
        : undefined;

    const locationIsPostalCode = filterValues.where && isZipcode(filterValues.where);

    const filterParams = transformToJobVacancyOverviewFilterParams({
        ...filterValues,
        where: filterValues.where && !locationIsPostalCode
            ? filterValues.where
            : '',
        postalCode: filterValues.where && locationIsPostalCode
            ? filterValues.where
            : '',
        distance: filterValues.where
            ? filterValues.distance || defaultTravelDistance
            : 0,
    });

    const queryParams = sortValue
        ? convertToQueryParams({ ...filterParams, sort: sortValue })
        : convertToQueryParams(filterParams);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/vacancies/search?${queryParams}`);

        if (!response.ok) {
            const errorDoc = await response.json();
            const error = errorDoc.errors[0];

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<NewestVacancyResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const vacancies = doc.data.map(transformToNewestVacancy);
        const pagination = doc.meta ? transformMetaToPagination(doc.meta) : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                vacancies,
                pagination,
            },
        };
    } catch (error) {
        console.error('[getJobVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
