import axios from 'axios';
import { Dispatch } from 'redux';

import { transformMatchingCandidate } from '../../models/MatchingCandidate';
import { setCurrentStudent, setLoading } from './students';

/**
 * @param vacancy
 * @param studentUuid
 * @returns {function(*): Promise<{type: string} | {type: string} | never>}
 */
export function fetchStudentForVacancy(vacancy: any, studentUuid: string) {
    return async (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await axios.get(`/me/company/vacancies/${vacancy.uuid}/matching-candidates/${studentUuid}`, {
                params: {
                    with: ''.concat(
                        'job_preferences_set,',
                        'internship_preferences_set,',
                        'skills,',
                        'skills_to_learn,',
                        'competencies,',
                        'competencies_to_learn,',
                        'student_profile.school,',
                        'student_profile.education_level,',
                        'student_profile.field_of_study,',
                        'address,',
                    ),
                },
            });

            const student = transformMatchingCandidate(response.data.data, vacancy.type, true);

            dispatch(setCurrentStudent(student));
            dispatch(setLoading(false));
        } catch (error) {
            console.error('[fetchStudentForVacancy]', error);
        }
    };
}
