import React, { FC, ReactElement } from 'react';

import { Button } from '../../../../components';
import { trans } from '../../../../helpers/trans';

import './VacancyMatchesNullState.scss';

interface VacancyMatchesNullStateProps {
    onFilterReset: () => void;
    className?: string;
}

const VacancyMatchesNullState: FC<VacancyMatchesNullStateProps> = ({
    onFilterReset,
    className = '',
}): ReactElement => (
    <div className={`vacancy-matches-null-state ${className}`}>
        <h2 className="vacancy-matches-null-state__title">
            {trans('containers.vacancyMatches.nullState.title')}
        </h2>

        <p className="vacancy-matches-null-state__description">
            {trans('containers.vacancyMatches.nullState.description')}
        </p>

        <Button
            text={trans('containers.vacancyMatches.nullState.button.reset')}
            onClick={onFilterReset}
            className="vacancy-matches-null-state__button"
        />
    </div>
);

export default VacancyMatchesNullState;
