import React, { FC, ReactElement } from 'react';

import { Card } from '../../components';
import { trans } from '../../helpers/trans';
import { SbbRecognition } from '../../models/SbbTrainingCompany';
import { SbbRecognitionTable } from './subcomponents';

import './SbbRecognitionsCard.scss';

interface SbbRecognitionsCardProps {
    recognitions: SbbRecognition[];
    className?: string;
}

const SbbRecognitionsCard: FC<SbbRecognitionsCardProps> = ({
    recognitions = [],
    className = '',
}): ReactElement => (
    <Card className={`sbb-education-card ${className}`}>
        <h3 className="sbb-education-card__title">
            {trans('userProfile.sbb.recognitions')}
        </h3>

        <SbbRecognitionTable recognitions={recognitions} className="sbb-education-card__table" />
    </Card>
);

export default SbbRecognitionsCard;
