import React, { FC, PropsWithChildren, ReactElement } from 'react';

interface TabProps {
    isActive?: boolean;
    className?: string;
}

const Tab: FC<PropsWithChildren<TabProps>> = ({
    isActive,
    className = '',
    children,
}): ReactElement | null => (isActive ? (
    <div className={`tab ${className}`}>
        {children}
    </div>
) : null);

export default Tab;
