import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    FormActionButtons,
    InformationBox,
    Modal,
    ModalContent,
    SubmitButton,
    TextInput,
} from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { EmailAddressChange, EmailAddressChangeFormData } from '../../../models/EmailAddress';
import { OptionalFormValidation } from '../../../services/ValidationService';
import { FormProps } from '../../../types';
import { EmailAddressChangeFormErrors, validateEmailAddressChangeFormData } from './validations';

import './EmailAddressChangeForm.scss';

interface EmailAddressChangeFormProps extends Omit<FormProps<EmailAddressChangeFormData>, 'onCancel'> {
    currentEmailAddress?: string;
    emailAddressChange?: EmailAddressChange;
    className?: string;
}

const EmailAddressChangeForm: FC<EmailAddressChangeFormProps> = ({
    isLoading,
    currentEmailAddress,
    emailAddressChange,
    onSubmit,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<EmailAddressChangeFormErrors>({});

    const [newEmailAddress, setNewEmailAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [authenticateModalIsOpen, setAuthenticateModalIsOpen] = useState<boolean>(false);

    const openAuthenticateModal = (): void => setAuthenticateModalIsOpen(true);
    const closeAuthenticateModal = (): void => setAuthenticateModalIsOpen(false);

    const formRef = useRef<HTMLFormElement>(null);

    const validateForm = (formData: EmailAddressChangeFormData, optionalValidation: OptionalFormValidation<EmailAddressChangeFormData>): boolean => {
        const [errors, hasErrors] = validateEmailAddressChangeFormData(formData, optionalValidation);

        setFormErrors(errors);

        if (hasErrors) {
            scrollIntoView<HTMLFormElement>(formRef);
        }

        return !hasErrors;
    };

    const handleNewEmailAddressSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = { newEmailAddress, password };

        const formIsValid = validateForm(formData, {
            newEmailAddress: true,
            password: false,
        });

        if (formIsValid) {
            openAuthenticateModal();
        }
    };

    const handleAuthenticateSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = { newEmailAddress, password };

        const formIsValid = validateForm(formData, {
            newEmailAddress: false,
            password: true,
        });

        if (formIsValid) {
            onSubmit(formData);

            setNewEmailAddress('');
            setPassword('');
            closeAuthenticateModal();
        }
    };

    return (
        <div className={`email-address-change-form ${className}`}>
            <form ref={formRef} onSubmit={handleNewEmailAddressSubmit}>
                {currentEmailAddress && (
                    <p className="email-address-change-form__current-email-address">
                        {trans('forms.emailAddressChange.currentEmailAddress', {
                            currentEmailAddress,
                        })}
                    </p>
                )}

                {!isLoading && emailAddressChange && (
                    <InformationBox
                        descriptions={[
                            trans('forms.emailAddressChange.validationDisclaimer.emailAddressToValidate', {
                                emailAddress: emailAddressChange.newEmailAddress,
                            }),
                            trans('forms.emailAddressChange.validationDisclaimer.confirmDescription'),
                        ]}
                        className="email-address-change-form__validation-disclaimer"
                    />
                )}

                <TextInput
                    label={trans('forms.emailAddressChange.input.newEmailAddress')}
                    type="email"
                    value={newEmailAddress}
                    placeholder={trans('forms.emailAddressChange.placeholder.newEmailAddress')}
                    error={formErrors.newEmailAddress}
                    disabled={isLoading}
                    onChange={setNewEmailAddress}
                    className="email-address-change-form__email-input"
                />

                <SubmitButton
                    isLoading={isLoading}
                    icon="check"
                    text={trans('forms.emailAddressChange.button.submit')}
                    disabled={!newEmailAddress}
                    className="email-address-change-form__submit-button"
                />
            </form>

            {authenticateModalIsOpen && (
                <Modal onClose={closeAuthenticateModal}>
                    <ModalContent title={trans('forms.emailAddressChange.authenticateModal.title')}>
                        <form onSubmit={handleAuthenticateSubmit}>
                            <p className="email-address-change-form__modal-description">
                                {trans('forms.emailAddressChange.authenticateModal.description')}
                            </p>

                            <TextInput
                                label={trans('forms.emailAddressChange.input.password')}
                                type="password"
                                value={password}
                                placeholder={trans('forms.emailAddressChange.placeholder.password')}
                                error={formErrors.password}
                                onChange={setPassword}
                                className="email-address-change-form__password-input"
                            />

                            <FormActionButtons
                                submitIcon="check"
                                submitText={trans('forms.emailAddressChange.button.submit')}
                                onCancelClick={closeAuthenticateModal}
                                className="email-address-change-form__modal-action-buttons"
                            />
                        </form>
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default EmailAddressChangeForm;
