import { addDays } from 'date-fns';

import { Address, transformAddressToAddressFormValues } from '../../../_old/app_talentz/models/Address';
import { defaultVacancyLifetimeInDays, maxEmploymentHoursPerWeek, otherFormOptionKey } from '../../../constants';
import { PlaceJobVacancyFormValues } from '../../../services/PlaceVacancyService';

export default function createEmptyPlaceJobVacancyFormValues(address: Address | undefined): PlaceJobVacancyFormValues {
    return {
        applyAnonymous: false,
        title: '',
        address: address ? transformAddressToAddressFormValues(address) : null,
        expirationDate: addDays(new Date(), defaultVacancyLifetimeInDays),
        publicationDate: new Date(),
        educationLevelIds: [otherFormOptionKey],
        escoOccupationIds: [],
        fieldsOfStudyIds: [],
        employmentHourRange: {
            min: 0,
            max: maxEmploymentHoursPerWeek,
        },
        minMonthSalary: '',
        maxMonthSalary: '',
        minHourSalary: '',
        maxHourSalary: '',
        description: '',
        jobTypeId: '',
        workRemotePreferenceId: '',
        skillIds: [],
        escoSkillIds: [],
        competencyIds: [],
        sectorId: '',
    };
}
