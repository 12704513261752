import React, {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useLocation } from 'react-use';

import {
    ConnectedFavouritesSidebar,
    ConnectedFooter,
    ConnectedMenu,
    ConnectedNotificationsSidebar,
} from '../../../../connectors';
import { CookieConsentToast } from '../../../../containers';
import { hotjarEvent } from '../../../../helpers/hotjar';
import { useLegacyDispatch } from '../../../../store';
import Overlay from '../../../components/Overlay';
import ieVersion from '../../../helpers/ieVersion';
import { logIn } from '../../store/legacyUser/legacyUserActions';
import { setStep as setRegisterStep } from '../../store/register/register';
import { setStep as setResetPasswordStep } from '../../store/resetPassword/resetPassword';
import BrowserNotSupported from '../components/BrowserNotSupported';
import Login from './Login';
import PasswordForget from './PasswordForget';
import Register from './Register/Register';

interface AppContainerProps {
    authIsLoading: boolean;
}

const AppContainer: FC<PropsWithChildren<AppContainerProps>> = ({ authIsLoading, children }): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const location = useLocation();
    const locationState = location.state as { type?: string; noLinkedInUser?: boolean };

    const ieV = ieVersion();
    const [showInternetExplorerMessage, setShowInternetExplorerMessage] = useState<boolean>(ieV !== 0);

    const closeInternetExplorerMessage = (): void => setShowInternetExplorerMessage(false);

    const handleRegister = (): void => {
        legacyDispatch(logIn(false));
        legacyDispatch(setResetPasswordStep(null));
        legacyDispatch(setRegisterStep(0));

        hotjarEvent('vpv', '/registreren');
        hotjarEvent('trigger', 'register_start');
    };

    const handleResetPassword = (): void => {
        legacyDispatch(setRegisterStep(null));
        legacyDispatch(logIn(false));
        legacyDispatch(setResetPasswordStep(0));
    };

    useEffect((): void => {
        if (locationState) {
            if (locationState.type === 'unauthenticated') {
                legacyDispatch(logIn(false));
            }

            if (locationState.noLinkedInUser) {
                legacyDispatch(setRegisterStep(0));
            }
        }
    }, [legacyDispatch, locationState]);

    return (
        <>
            <ConnectedMenu authIsLoading={authIsLoading} />
            <ConnectedFavouritesSidebar />
            <ConnectedNotificationsSidebar />

            {children}

            <Register />
            <PasswordForget />

            <Login
                onRegisterClick={handleRegister}
                onPasswordForgetClick={handleResetPassword}
            />

            <CookieConsentToast />
            <ConnectedFooter />

            <Overlay dark onClose={closeInternetExplorerMessage}>
                {showInternetExplorerMessage && (
                    <BrowserNotSupported close={closeInternetExplorerMessage} />
                )}
            </Overlay>
        </>
    );
};

export default AppContainer;
