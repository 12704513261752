import { isValidDateString } from '../helpers/date';
import { sortByCreatedAtDateDesc } from '../helpers/sort';
import getIncluded from '../japi/getIncluded';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { ApplicationInvite, ApplicationInvitesResource, transformJapiDocumentToApplicationInvite } from './ApplicationInvites';
import { ApplicationRejection, ApplicationRejectionsResource, transformToApplicationRejection } from './ApplicationRejections';
import {
    FileModel,
    FileRequest,
    FilesResource,
    transformToFileModel,
    transformToFileRequest,
} from './File';
import {
    defaultPersonInfo,
    PersonInfo,
    PersonInfoResource,
    transformToPersonInfo,
} from './info/PersonInfo';
import {
    defaultVacanciesInfo,
    transformToVacanciesInfo,
    VacanciesInfo,
    VacanciesInfoResource,
} from './info/VacanciesInfo';

export enum ApplicationStatus {
    waiting = 'WAITING',
    accepted = 'ACCEPTED',
    declined = 'DECLINED',
    hired = 'HIRED',
    invited = 'INVITED',
}

export interface ApplicationResource extends Resource {
    type: 'applications';
    id: string;
    attributes: {
        isRead: boolean;
        status: ApplicationStatus;
        content: string;
        createdAt: string;
        updatedAt?: string;
        deletedAt?: string;
    };
    relationships: {
        applicant: SingleRelationship<'person-info'>;
        employee: SingleRelationship<'person-info'>;
        files: MultipleRelationship<'files'>;
        invites: MultipleRelationship<'application-invites'>;
        rejections: MultipleRelationship<'application-rejections'>;
        vacancy: SingleRelationship<'vacancies-info'>;
    };
}

export interface ApplicationRequest {
    data: {
        type: 'applications';
        attributes: {
            content: string;
            files: FileRequest[];
        };
        relationships: {
            vacancy: SingleRelationship<'vacancies'>;
        };
    };
}

export interface ApplicationStatusRequest {
    data: {
        type: 'applications';
        id: string;
        attributes: {
            status: ApplicationStatus;
        };
    };
}

export interface Application {
    uuid: string;
    isRead: boolean;
    status: ApplicationStatus;
    motivation: string;
    employee: PersonInfo;
    applicant: PersonInfo;
    vacancy: VacanciesInfo;
    files: FileModel[];
    invites: ApplicationInvite[];
    rejections: ApplicationRejection[];
    createdAt: Date;
    updatedAt?: Date;
    deletedAt?: Date;
}

export interface ApplicationFormData {
    vacancyUuid: string;
    bodyText: string;
    attachments: File[];
}

export interface ApplicationVacancyIds {
    applicationUuid: string;
    vacancyUuid: string;
}

export const transformJapiDocumentToApplication = (
    doc: JapiDocument,
    applicationResource: ApplicationResource,
): Application => {
    const includedEmployee = getIncluded<PersonInfoResource>(doc, applicationResource, 'employee');
    const includedApplicant = getIncluded<PersonInfoResource>(doc, applicationResource, 'applicant');
    const includedVacancy = getIncluded<VacanciesInfoResource>(doc, applicationResource, 'vacancy');
    const includedFiles = getMultipleIncluded<FilesResource>(doc, applicationResource, 'files');
    const includedInvites = getMultipleIncluded<ApplicationInvitesResource>(doc, applicationResource, 'invites');
    const includedRejections = getMultipleIncluded<ApplicationRejectionsResource>(doc, applicationResource, 'rejections');

    const employee = includedEmployee
        ? transformToPersonInfo(includedEmployee)
        : defaultPersonInfo();

    const applicant = includedApplicant
        ? transformToPersonInfo(includedApplicant)
        : defaultPersonInfo();

    const vacancy = includedVacancy
        ? transformToVacanciesInfo(includedVacancy)
        : defaultVacanciesInfo();

    const files = includedFiles?.length
        ? includedFiles.map(transformToFileModel)
        : [];

    const invites = includedInvites?.length
        ? includedInvites
            .map(invite => transformJapiDocumentToApplicationInvite(doc, invite))
            .sort(sortByCreatedAtDateDesc)
        : [];

    const rejections = includedRejections?.length
        ? includedRejections
            .map(transformToApplicationRejection)
            .sort(sortByCreatedAtDateDesc)
        : [];

    return {
        uuid: applicationResource.id,
        status: applicationResource.attributes.status,
        isRead: applicationResource.attributes.isRead,
        motivation: applicationResource.attributes.content,
        employee,
        applicant,
        vacancy,
        files,
        invites,
        rejections,
        createdAt: isValidDateString(applicationResource.attributes.createdAt)
            ? new Date(applicationResource.attributes.createdAt)
            : new Date(),
        updatedAt: applicationResource.attributes.updatedAt && isValidDateString(applicationResource.attributes.updatedAt)
            ? new Date(applicationResource.attributes.updatedAt)
            : undefined,
        deletedAt: applicationResource.attributes.deletedAt && isValidDateString(applicationResource.attributes.deletedAt)
            ? new Date(applicationResource.attributes.deletedAt)
            : undefined,
    };
};

export const transformToApplicationRequest = async (formData: ApplicationFormData): Promise<ApplicationRequest> => {
    const base64Files = await Promise.all(formData.attachments.map(transformToFileRequest));

    return {
        data: {
            type: 'applications',
            attributes: {
                content: formData.bodyText,
                files: base64Files,
            },
            relationships: {
                vacancy: {
                    data: {
                        id: formData.vacancyUuid,
                        type: 'vacancies',
                    },
                },
            },
        },
    };
};

export const transformToApplicationStatusRequest = (applicationUuid: string, status: ApplicationStatus): ApplicationStatusRequest => ({
    data: {
        type: 'applications',
        id: applicationUuid,
        attributes: {
            status,
        },
    },
});

export const transformToApplicationVacancyIds = (
    application: Application,
): ApplicationVacancyIds => ({
    applicationUuid: application.uuid,
    vacancyUuid: application.vacancy.uuid,
});
