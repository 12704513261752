import React, { FC, ReactElement } from 'react';

import { MatchProperty } from '../../_old/app_talentz/models/MatchProperty';
import { trans } from '../../helpers/trans';
import { EmployeeDataFormErrors, EmployeeDataFormValues, Gender } from '../../services/EmployeeService';
import { BlockRadioList, TextInput } from '..';

import './EmployeeDataForm.scss';

interface EmployeeDataFormProps {
    errors: EmployeeDataFormErrors;
    formValues: EmployeeDataFormValues;
    onChange: (formValues: EmployeeDataFormValues) => void;
    className?: string;
}

const EmployeeDataForm: FC<EmployeeDataFormProps> = ({
    errors,
    formValues,
    onChange,
    className = '',
}): ReactElement => {
    const genderOptions = [
        {
            name: trans('forms.user.gender.male'),
            value: Gender.male,
            uuid: Gender.male, // TODO: Make BlockRadioList generic, or at least not use MatchProperty by default…
        },
        {
            name: trans('forms.user.gender.female'),
            value: Gender.female,
            uuid: Gender.female, // TODO: Make BlockRadioList generic, or at least not use MatchProperty by default…
        },
    ];

    const onFormChange = (newValues: Partial<EmployeeDataFormValues>) => {
        onChange({
            ...formValues,
            ...newValues,
        });
    };

    const onGenderChange = (value: string) => {
        onChange({
            ...formValues,
            gender: value === Gender.male ? Gender.male : Gender.female,
        });
    };

    return (
        <div className={`employee-data-form ${className}`}>
            <h3 className="employee-data-form__title">
                {trans('forms.register.employee.employee.title')}
            </h3>
            <p className="employee-data-form__intro">
                {trans('forms.register.employee.employee.intro')}
            </p>
            <div className="employee-data-form__form-row employee-data-form__firstname-insertion-row">
                <TextInput
                    required
                    className="employee-data-form__first-name-input"
                    error={errors.firstName}
                    label={trans('forms.user.firstname.label')}
                    placeholder={trans('forms.user.firstname.placeholder')}
                    value={formValues.firstName}
                    onChange={(value: string) => onFormChange({ firstName: value })}
                />
                <TextInput
                    className="employee-data-form__insertion-input"
                    error={errors.insertion}
                    label={trans('forms.user.insertion.label')}
                    placeholder={trans('forms.user.insertion.placeholder')}
                    value={formValues.insertion}
                    onChange={(value: string) => onFormChange({ insertion: value })}
                />
            </div>
            <div className="employee-data-form__form-row employee-data-form__lastname-gender-row">
                <TextInput
                    required
                    className="employee-data-form__last-name-input"
                    error={errors.lastName}
                    label={trans('forms.user.lastname.label')}
                    placeholder={trans('forms.user.lastname.placeholder')}
                    value={formValues.lastName}
                    onChange={(value: string) => onFormChange({ lastName: value })}
                />
                <BlockRadioList
                    required
                    name="candidate-list-items"
                    label={trans('forms.user.gender.label')}
                    options={genderOptions as MatchProperty[]}
                    value={formValues.gender}
                    onChange={onGenderChange}
                />
            </div>
            <div className="employee-data-form__form-row">
                <TextInput
                    optional
                    type="tel"
                    className="employee-data-form__phone-number"
                    error={errors.phoneNumber}
                    label={trans('forms.phonenumber.label')}
                    placeholder={trans('forms.phonenumber.placeholder')}
                    value={formValues.phoneNumber}
                    onChange={(value: string) => onFormChange({ phoneNumber: value })}
                />
            </div>
            <div className="employee-data-form__form-row">
                <TextInput
                    required
                    className="employee-data-form__position"
                    error={errors.position}
                    label={trans('forms.user.position.label')}
                    placeholder={trans('forms.user.position.placeholder')}
                    value={formValues.position}
                    onChange={(value: string) => onFormChange({ position: value })}
                />
            </div>
        </div>
    );
};

export default EmployeeDataForm;
