import React, { FC, ReactElement } from 'react';

import { HomeCallToAction } from '../../containers';
import { RoleType } from '../../models/User';
import { useLegacySelector } from '../../store';

interface ConnectedHomeCallToActionProps {
    onCallToActionClick: () => void;
    className?: string;
}

const ConnectedHomeCallToAction: FC<ConnectedHomeCallToActionProps> = ({
    onCallToActionClick,
    className = '',
}): ReactElement | null => {
    const role = useLegacySelector(state => state.legacyUser.role);

    const isAllowed = [RoleType.company, RoleType.none].includes(role ?? RoleType.none);

    return isAllowed ? (
        <HomeCallToAction
            userRole={role || RoleType.none}
            onCallToActionClick={onCallToActionClick}
            className={className}
        />
    ) : null;
};

export default ConnectedHomeCallToAction;
