import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel } from '../../../components';
import { FormOption } from '../../../types';
import { BlockRadioItem } from './subcomponents';

import './NewBlockRadioList.scss';

interface NewBlockRadioListProps {
    isVertical?: boolean;
    label: string;
    hideLabel?: boolean;
    required?: boolean;
    name: string;
    options: FormOption[];
    value: string;
    error?: string;
    onChange: (value: string) => void;
    className?: string;
    listClassName?: string;
}

// TODO: Rename to 'BlockRadioList', once the old component has been eradicated
const NewBlockRadioList: FC<NewBlockRadioListProps> = ({
    isVertical,
    label,
    hideLabel = false,
    required = false,
    name,
    options,
    value,
    error = '',
    onChange,
    className = '',
    listClassName = '',
}): ReactElement => {
    const blockRadioListClassNames = classNames('new-block-radio-list', {
        'new-block-radio-list--is-vertical': isVertical,
    }, className);

    const listClassNames = classNames('new-block-radio-list__list', {
        'new-block-radio-list__list--is-vertical': isVertical,
        'new-block-radio-list__list--has-error': !!error,
    }, listClassName);

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={blockRadioListClassNames}
        >
            {!hideLabel && (
                <InputLabel text={label} required={required} />
            )}

            <div className={listClassNames}>
                {options.map(option => (
                    <BlockRadioItem
                        key={option.value}
                        label={option.label}
                        name={name}
                        value={option.value}
                        selected={option.value === value}
                        onChange={onChange}
                        className="new-block-radio-list__item"
                    />
                ))}
            </div>

            {error && (
                <ErrorLabel text={error} className="new-block-radio-list__error" />
            )}
        </label>
    );
};

export default NewBlockRadioList;
