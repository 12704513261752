import React, { FC, ReactElement } from 'react';

import { ErrorLabel } from '../../../../../components';
import { IconButton } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { AppointmentDate, defaultAppointmentDate } from '../../../../../models/ApplicationAppointments';
import { AppointmentInput } from '..';

import './AppointmentsInput.scss';

interface AppointmentsInputProps {
    appointments: AppointmentDate[];
    error?: string;
    onChange: (appointments: AppointmentDate[]) => void;
    className?: string;
}

const AppointmentsInput: FC<AppointmentsInputProps> = ({
    appointments,
    error,
    onChange,
    className = '',
}): ReactElement => {
    const handleAddAppointment = (): void => {
        const newAppointments = [...appointments, defaultAppointmentDate()];

        onChange(newAppointments);
    };

    return (
        <div className={`appointments-input ${className}`}>
            {appointments.map((appointment, index) => {
                const handleChange = (value: AppointmentDate): void => {
                    const newAppointments = [...appointments];
                    newAppointments[index] = value;

                    onChange(newAppointments);
                };

                const handleDeleteAppointment = (): void => {
                    const newAppointments = [...appointments];
                    newAppointments.pop();

                    onChange(newAppointments);
                };

                return (
                    <AppointmentInput
                        key={JSON.stringify({ index })}
                        isFirst={index === 0}
                        isLast={index === appointments.length - 1}
                        value={appointment}
                        onChange={handleChange}
                        onDeleteClick={handleDeleteAppointment}
                        className="appointments-input__input"
                    />
                );
            })}

            {error && (
                <ErrorLabel text={error} className="appointments-input__error-label" />
            )}

            {appointments.length < 5 && (
                <IconButton
                    icon="plus"
                    text={trans('forms.appointment.button.addAppointment')}
                    onClick={handleAddAppointment}
                    className="appointments-input__add-button"
                />
            )}
        </div>
    );
};

export default AppointmentsInput;
