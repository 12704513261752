import React, { FC, PropsWithChildren } from 'react';

import './StickyComponent.scss';

// Temporary replacement function, before this legacy code will be replaced
const StickyComponent: FC<PropsWithChildren> = ({ children }) => (
    <div className="sticky-component">
        {children}
    </div>
);

export default StickyComponent;
