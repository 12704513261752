import React, { FC, ReactElement } from 'react';

import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../models/NewestVacancy';
import { RoleType } from '../../models/User';
import { VacancyOverview } from '..';

import './RelatedVacancies.scss';

interface RelatedVacanciesProps {
    isLoading: boolean;
    userRole: RoleType;
    title: string;
    vacancies: NewestVacancy[];
    error: string;
    favouriteIsLoading?: boolean;
    favouriteIds?: string[];
    onFavouriteVacancy?: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    className?: string;
}

const RelatedVacancies: FC<RelatedVacanciesProps> = ({
    isLoading,
    userRole,
    title,
    vacancies,
    error,
    favouriteIsLoading,
    favouriteIds,
    onFavouriteVacancy,
    className = '',
}): ReactElement => (
    <section className={`related-vacancies ${className}`}>
        <h1 className="related-vacancies__title">
            {title}
        </h1>

        <VacancyOverview
            isLoading={isLoading}
            userRole={userRole}
            vacancies={vacancies}
            error={error}
            favouriteIds={favouriteIds}
            favouriteIsLoading={favouriteIsLoading}
            onFavouriteVacancy={onFavouriteVacancy}
            className="related-vacancies__list"
        />
    </section>
);

export default RelatedVacancies;
