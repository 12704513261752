import React from 'react';

import MediaQuery from 'react-responsive';

import { mediaQueries as mq } from '../theme/mediaQueries';

export const Mobile = props => <MediaQuery {...props} query={mq.smallMobile} />;
export const SmallTablet = props => <MediaQuery {...props} query={mq.smallTablet} />;
export const Tablet = props => <MediaQuery {...props} query={mq.tablet} />;
export const Laptop = props => <MediaQuery {...props} query={mq.laptop} />;
export const Desktop = props => <MediaQuery {...props} query={mq.desktop} />;
export const LargeScreen = props => <MediaQuery {...props} query={mq.largeScreen} />;

export const MaxSmallTablet = props => <MediaQuery {...props} query={mq.maxSmallTablet} />;
export const MaxTablet = props => <MediaQuery {...props} query={mq.maxTablet} />;
export const MaxLaptop = props => <MediaQuery {...props} query={mq.maxLaptop} />;
export const MaxDesktop = props => <MediaQuery {...props} query={mq.maxDesktop} />;
export const MaxLargeScreen = props => <MediaQuery {...props} query={mq.maxLargeScreen} />;
