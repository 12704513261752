import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { KvkSearchResult } from '../../models/KvkSearchResult';
import { AsyncReduxState } from '../../types';

export type KvkSearchResultsState = AsyncReduxState<{
    kvkSearchResults: KvkSearchResult[];
}>;

const initialState: KvkSearchResultsState = {
    ...initialAsyncReduxState,
    kvkSearchResults: [],
};

export const kvkSearchResultsSlice = createSlice({
    name: 'kvkSearchResultsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): KvkSearchResultsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): KvkSearchResultsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setKvkSearchResults(state, action: PayloadAction<KvkSearchResult[]>): KvkSearchResultsState {
            return {
                ...state,
                kvkSearchResults: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setKvkSearchResults,
} = kvkSearchResultsSlice.actions;

export default kvkSearchResultsSlice;
