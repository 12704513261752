import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getSkillsApiCall } from '../../services/SkillsService';
import { TypedDispatch } from '..';
import { setError, setIsLoading, setSkills } from './skills';

export const fetchSkills = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const skillsResponse = await getSkillsApiCall();

        if (!isFetchResultSuccessful(skillsResponse)) {
            dispatch(setError(skillsResponse.error));
            return;
        }

        const skills = skillsResponse.data;

        dispatch(setSkills(skills));
    } catch (error) {
        console.error('[fetchSkills]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
