import React, { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { RoleType } from '../../../../models/User';
import { RoutePaths } from '../../../../routes';
import { CompanySettingsTab, UserSettingsTab } from '../../../../types/pageTabs';

import './VacancyOverviewCompleteRegistration.scss';

interface VacancyOverviewCompleteRegistrationProps {
    userRole?: RoleType;
    className?: string;
}

const VacancyOverviewCompleteRegistration: FC<VacancyOverviewCompleteRegistrationProps> = ({
    userRole = RoleType.none,
    className = '',
}): ReactElement => {
    const settingsPage = userRole === RoleType.company
        ? RoutePaths.companySettings(CompanySettingsTab.myCompany)
        : RoutePaths.userSettings(UserSettingsTab.personalData);

    return (
        <div className={`vacancy-overview-complete-registration ${className}`}>
            <div className="vacancy-overview-complete-registration__text-wrapper">
                <h2 className="vacancy-overview-complete-registration__title">
                    {trans('vacancies.completeRegistration.title')}
                </h2>

                <p className="vacancy-overview-complete-registration__description">
                    {trans('vacancies.completeRegistration.description')}
                </p>
            </div>

            <LinkIconButton
                to={settingsPage}
                iconPos="right"
                icon="arrow-right"
                text={trans('vacancies.completeRegistration.buttonLabel')}
                className="vacancy-overview-complete-registration__button"
            />
        </div>
    );
};

export default VacancyOverviewCompleteRegistration;
