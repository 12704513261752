import React, { FC, FormEvent, ReactElement } from 'react';

import classNames from 'classnames';

import './BlockRadioItem.scss';

interface BlockRadioItemProps {
    name: string;
    label: string;
    value: string;
    selected?: boolean;
    onChange: (value: string) => void;
}

const BlockRadioItem: FC<BlockRadioItemProps> = ({
    name,
    label,
    value,
    selected = false,
    onChange,
}): ReactElement => {
    const handleOnRadioItemChange = (event: FormEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const blockRadioItemClassNames = classNames('block-radio-item', {
        'block-radio-item--is-selected': selected,
    });

    return (
        <label className={blockRadioItemClassNames}>
            <input
                id={label}
                type="radio"
                name={name}
                value={value}
                onChange={handleOnRadioItemChange}
                className="block-radio-item__input"
            />
            <span>{label}</span>
        </label>
    );
};

export default BlockRadioItem;
