import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconHeart: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50.6,81.8l-0.5,0.5l-0.5-0.5C25.9,60.3,10.2,46.1,10.2,31.7c0-10,7.5-17.5,17.5-17.5c7.7,0,15.2,5,17.8,11.8h9.3c2.6-6.8,10.1-11.8,17.8-11.8c10,0,17.5,7.5,17.5,17.5C90,46.1,74.4,60.3,50.6,81.8z M72.6,4.2c-8.7,0-17,4-22.4,10.4C44.7,8.3,36.4,4.2,27.7,4.2c-15.4,0-27.4,12-27.4,27.4c0,18.8,17,34.2,42.6,57.5l7.2,6.6l7.2-6.6C83,65.9,100,50.5,100,31.7C100,16.3,87.9,4.2,72.6,4.2z" />
    </svg>
);

export default IconHeart;
