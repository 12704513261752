import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../../../components';
import { DetailCard } from '../../../../../compositions';
import { formatAppointment } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { ApplicationInvite } from '../../../../../models/ApplicationInvites';
import { Application } from '../../../../../models/Applications';

import './ApplicationStatusInviteSent.scss';

interface ApplicationStatusInviteSentProps {
    isCandidate?: boolean;
    application: Application;
    activeInvite: ApplicationInvite;
    className?: string;
}

const ApplicationStatusInviteSent: FC<ApplicationStatusInviteSentProps> = ({
    isCandidate,
    application,
    activeInvite,
    className = '',
}): ReactElement => {
    const description = isCandidate
        ? trans('containers.applicationStatusCard.inviteSent.description.candidate', {
            company: application?.vacancy.companyName,
        })
        : trans('containers.applicationStatusCard.inviteSent.description.company');

    return (
        <DetailCard
            title={trans('containers.applicationStatusCard.inviteSent.title')}
            className={`application-status-invite-sent ${className}`}
        >
            <p className="application-status-invite-sent__description">
                {description}
            </p>

            <ul className="application-status-invite-sent__appointment-list">
                {activeInvite.appointments.map(appointment => (
                    <li key={appointment.uuid} className="application-status-invite-sent__appointment-item">
                        <Icon name="calendar" className="application-status-invite-sent__appointment-icon" />

                        <p className="application-status-invite-sent__appointment-label">
                            {formatAppointment(appointment.startDate, appointment.endDate)}
                        </p>
                    </li>
                ))}
            </ul>
        </DetailCard>
    );
};

export default ApplicationStatusInviteSent;
