import { trans } from '../../../helpers/trans';
import { RoleType } from '../../../models/User';

interface RoleInfoHeaderContent {
    title: string;
    description: string;
    buttonText: string;
    authenticatedButtonText: string;
    image: string;
    imageAlt: string;
}

export const roleInfoHeaderContent = (role: RoleType): RoleInfoHeaderContent => {
    switch (role) {
        case RoleType.company:
            return {
                title: trans('infoPage.company.header.title'),
                description: trans('infoPage.company.header.description'),
                buttonText: trans('infoPage.company.callToAction'),
                authenticatedButtonText: trans('infoPage.company.callToActionAuthenticated'),
                image: './images/company-header.svg',
                imageAlt: trans('infoPage.company.header.imageAlt'),
            };
        case RoleType.jobSeeker:
            return {
                title: trans('infoPage.jobSeeker.header.title'),
                description: trans('infoPage.jobSeeker.header.description'),
                buttonText: trans('infoPage.jobSeeker.callToAction'),
                authenticatedButtonText: trans('infoPage.jobSeeker.callToActionAuthenticated'),
                image: './images/company-header.svg',
                imageAlt: trans('infoPage.jobSeeker.header.imageAlt'),
            };
        case RoleType.student:
        default:
            return {
                title: trans('infoPage.student.header.title'),
                description: trans('infoPage.student.header.description'),
                buttonText: trans('infoPage.student.callToAction'),
                authenticatedButtonText: trans('infoPage.student.callToActionAuthenticated'),
                image: './images/student-header.svg',
                imageAlt: trans('infoPage.student.header.imageAlt'),
            };
    }
};
