import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { VacancyType } from '../../../_old/app_talentz/models/VacancyType';
import { Card } from '../../../components';
import { getAgeInDays } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';
import { VacanciesInfo } from '../../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoleType } from '../../../models/User';
import { RoutePaths } from '../../../routes';
import {
    Avatar,
    VacancyControls,
    VacancyPropertyTags,
    VacancySbbFieldsOfStudy,
} from '../..';
import { VacancyCardMeta } from './subcomponents';

import './VacancyCard.scss';

interface VacancyCardProps {
    isSmall?: boolean;
    isAuthor?: boolean;
    isArchived?: boolean;
    isSbbUser?: boolean;
    userRole?: RoleType;
    userFieldsOfStudy?: string[];
    vacancy: NewestVacancy;
    favouriteIds?: string[];
    favouriteIsLoading?: boolean;
    onFavouriteVacancy?: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    applications?: Application[];
    applicationsIsLoading?: boolean;
    onFetchApplications?: (vacancyUuid: string) => void;
    onClearApplications?: () => void;
    onArchiveVacancy?: (vacancyUuid: string, applicationUuids: string[]) => void;
    onUnarchiveVacancy?: (vacancyUuid: string) => void;
    onDeleteVacancy?: (vacancyUuid: string) => void;
    className?: string;
}

const VacancyCard: FC<VacancyCardProps> = ({
    isSmall,
    isAuthor,
    isArchived,
    isSbbUser,
    userRole = RoleType.none,
    userFieldsOfStudy,
    vacancy,
    favouriteIds = [],
    favouriteIsLoading,
    onFavouriteVacancy,
    applications,
    applicationsIsLoading,
    onFetchApplications,
    onClearApplications,
    onArchiveVacancy,
    onUnarchiveVacancy,
    onDeleteVacancy,
    className = '',
}): ReactElement => {
    const vacancyLink = vacancy.type === VacancyType.internship
        ? RoutePaths.internshipDetail(vacancy.uuid)
        : RoutePaths.jobDetail(vacancy.uuid);

    const { approvedFieldsOfStudy = [] } = vacancy.properties;

    const isFavourite = favouriteIds.includes(vacancy.uuid);

    const cardClassNames = classNames('vacancy-card__card', {
        'vacancy-card__card--is-small': isSmall,
    });

    return (
        <div className={`vacancy-card ${className}`}>
            <Card className={cardClassNames}>
                <div className="vacancy-card__content-wrapper">
                    <Avatar
                        src={vacancy.company.logo}
                        alt={vacancy.title}
                        fallbackString={vacancy.company.name}
                        className="vacancy-card__logo"
                    />

                    <div className="vacancy-card__text-wrapper">
                        {!isArchived ? (
                            <Link to={vacancyLink} className="vacancy-card__link">
                                <h2 className="vacancy-card__title">
                                    {vacancy.title}
                                </h2>
                            </Link>
                        ) : (
                            <h2 className="vacancy-card__title">
                                {vacancy.title}
                            </h2>
                        )}

                        <p className="vacancy-card__company">
                            {trans('vacancies.atCompany', {
                                company: vacancy.company.name,
                                city: vacancy.address.city || vacancy.company.city,
                            })}
                        </p>

                        <p className="vacancy-card__date">
                            {getAgeInDays(vacancy.publicationDate)}
                        </p>

                        {!isSmall && (
                            <VacancyPropertyTags
                                isAnonymous={vacancy.isAnonymous}
                                isSbbUser={isSbbUser}
                                type={vacancy.type}
                                vacancyUuid={vacancy.uuid}
                                properties={vacancy.properties}
                                userFieldsOfStudy={userFieldsOfStudy}
                                className="vacancy-card__property-tags"
                            />
                        )}

                        {!isSmall && isSbbUser && approvedFieldsOfStudy.length > 0 && (
                            <VacancySbbFieldsOfStudy
                                vacancyUuid={vacancy.uuid}
                                fieldsOfStudy={approvedFieldsOfStudy}
                                className="vacancy-card__sbb-fields-of-study"
                            />
                        )}
                    </div>

                    <VacancyControls
                        isArchived={isArchived}
                        userRole={userRole}
                        userFromCompany={isAuthor}
                        vacancy={vacancy}
                        isFavourite={isFavourite}
                        favouriteIsLoading={favouriteIsLoading}
                        onFavouriteClick={onFavouriteVacancy}
                        applications={applications}
                        applicationsIsLoading={applicationsIsLoading}
                        onFetchApplications={onFetchApplications}
                        onClearApplications={onClearApplications}
                        onArchiveClick={onArchiveVacancy}
                        onUnarchiveClick={onUnarchiveVacancy}
                        onDeleteClick={onDeleteVacancy}
                        className="vacancy-card__controls"
                    />
                </div>

                {isAuthor && vacancy.meta && (
                    <VacancyCardMeta
                        vacancyMeta={vacancy.meta}
                        className="vacancy-card__meta"
                    />
                )}
            </Card>
        </div>
    );
};

export default VacancyCard;
