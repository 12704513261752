interface SearchQueryOptions {
    what?: string;
    province?: string;
    where?: string;
}

const dash = '-';
const searchIn = 'in';
const searchProvince = 'provincie';

export const searchQueryInSplit = searchIn + dash;
export const searchQueryProvinceSplit = searchProvince + dash;

export const encodeSearchQuery = (params: SearchQueryOptions): string => {
    const { what, province, where } = params;

    const searchQuery: string[] = [];

    if (what) {
        searchQuery.push(what);
    }

    if (province) {
        searchQuery.push(searchIn);
        searchQuery.push(searchProvince);
        searchQuery.push(province);
    }

    if (where) {
        searchQuery.push(searchIn);
        searchQuery.push(where);
    }

    return encodeURIComponent(searchQuery.join(dash));
};

export const decodeSearchQuery = (query: string): SearchQueryOptions => {
    let what = '';
    let province = '';
    let where = '';

    const inSplit = searchQueryInSplit;
    const provinceSplit = searchQueryProvinceSplit;

    const hasLocation = query.includes(inSplit);
    const hasProvince = query.includes(inSplit + provinceSplit);
    const hasWhatAndLocation = !query.startsWith(inSplit) && query.includes(dash + inSplit);

    const onlyWhat = query && !hasLocation;
    const onlyProvince = hasProvince && !hasWhatAndLocation;
    const onlyWhere = hasLocation && !hasWhatAndLocation && !hasProvince;

    what = onlyWhat ? query : what;
    province = onlyProvince ? query.replace(provinceSplit, '') : province;
    where = onlyWhere ? query.replace(inSplit, '') : where;

    if (hasLocation) {
        if (hasWhatAndLocation) {
            const [queryWhat, ...queryLocation] = query.split(dash + inSplit);
            const amountOfLocations = queryLocation.length;

            what = queryWhat;

            if (amountOfLocations === 2) {
                const [queryProvince, queryWhere] = queryLocation;

                province = queryProvince.replace(provinceSplit, '');
                where = queryWhere;
            } else if (hasProvince) {
                const [queryProvince] = queryLocation;

                province = queryProvince.replace(provinceSplit, '');
            } else {
                const [queryWhere] = queryLocation;

                where = queryWhere;
            }
        } else if (hasProvince) {
            const fullQuery = dash + query;
            const [queryProvince, queryWhere] = fullQuery.split(dash + inSplit)
                .filter(Boolean);

            province = queryProvince.replace(provinceSplit, '');
            where = queryWhere || where;
        } else {
            where = query.replace(inSplit, '');
        }
    }

    return {
        what: decodeURIComponent(what),
        province: decodeURIComponent(province),
        where: decodeURIComponent(where),
    };
};
