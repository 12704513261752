import React, { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import './BlockRadioItem.scss';

interface BlockRadioItemProps {
    name: string;
    label: string;
    value: string;
    selected: boolean;
    onChange: (value: string) => void;
    className?: string;
}

const BlockRadioItem: FC<BlockRadioItemProps> = ({
    label,
    selected,
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const blockRadioItemClassNames = classNames('new-block-radio-item', {
        'new-block-radio-item--is-selected': selected,
    }, className);

    return (
        <label className={blockRadioItemClassNames}>
            <input
                {...inputProps}
                id={label}
                type="radio"
                onChange={handleChange}
                className="new-block-radio-item__input"
            />

            <span className="new-block-radio-item__label">
                {label}
            </span>
        </label>
    );
};

export default BlockRadioItem;
