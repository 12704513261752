import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { Province } from '../../../../models/Provinces';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexProvince.scss';

interface VacancyIndexProvinceProps {
    isLoading: boolean;
    vacancyType: VacancyType;
    shouldSearchDirectly?: boolean;
    provinceOptionColumns: Province[][];
    className?: string;
}

const VacancyIndexProvince: FC<VacancyIndexProvinceProps> = ({
    isLoading,
    vacancyType,
    shouldSearchDirectly,
    provinceOptionColumns,
    className = '',
}): ReactElement => {
    const getProvinceRoute = (province: Province): string => {
        if (shouldSearchDirectly) {
            const searchQuery = encodeSearchQuery({
                province: province.slug,
            });

            return vacancyType === VacancyType.internship
                ? RoutePaths.internshipsQuery(searchQuery)
                : RoutePaths.jobsQuery(searchQuery);
        }

        const encodedProvince = encodeURIComponent(province.slug);
        return vacancyType === VacancyType.internship
            ? RoutePaths.internshipVacancyIndexProvince(encodedProvince)
            : RoutePaths.jobVacancyIndexProvince(encodedProvince);
    };

    return (
        <VacancyIndexCategory
            isLoading={isLoading}
            title={trans('vacancyIndex.category.province.main')}
            className={`vacancy-index-province ${className}`}
        >
            <VacancyIndexColumnList>
                {provinceOptionColumns.map(column => {
                    const columnIndex = column.map(province => province.slug).join('-');

                    return (
                        <li key={columnIndex}>
                            <ul className="vacancy-index-province__province-list">
                                {column.map(province => {
                                    const route = getProvinceRoute(province);

                                    return (
                                        <li key={province.slug} className="vacancy-index-province__province">
                                            <VacancyIndexLink to={route} label={province.name} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </VacancyIndexColumnList>
        </VacancyIndexCategory>
    );
};

export default VacancyIndexProvince;
