/* eslint-disable camelcase */
import { EscoOccupation, EscoSkillsMatchProperty, transformToEscoSkillWithMatch } from '../../../models/Esco';
import { NewestVacancy } from '../../../models/NewestVacancy';
import transformApiCompensationToJobVacancyGrossSalary from '../../helpers/transformApiCompensationToJobVacancyGrossSalary';
import { InternshipVacancyApiParams } from './InternshipVacancy';
import { MatchProperty, transformToPropertyWithMatch } from './MatchProperty';
import { transformVacancy, Vacancy, VacancyApiParams } from './Vacancy';
import { VacancyType } from './VacancyType';

export interface JobVacancyApiParams extends VacancyApiParams {
    match_details?: JobVacancyMatchDetails;
    job_vacancy: JobVacancyDetailApiParams;
    esco_occupations: EscoOccupation[];
    type: VacancyType.job;
}

export interface JobVacancy extends Vacancy {
    matching?: number;
    properties: JobVacancyProperties;
    property_groups: any[];
    type: VacancyType.job;
}

export interface JobVacancyDetailApiParams {
    compensation: MatchProperty;
    job_type: MatchProperty;
    professions: MatchProperty[];
    employment: MatchProperty;
    gross_salary: {
        is_matched?: boolean;
        min: MatchProperty;
        max: MatchProperty;
    } | null;
    education_levels: MatchProperty[];
    sector: MatchProperty;
}

export interface JobVacancyMatchDetails {
    match_percentage: number;
    matching_attributes?: JobVacancyMatchingAttributes;
}

export interface JobVacancyMatchingAttributes {
    field_of_study: string[];
    skills: string[];
    amount_of_employees: string[];
    professions: string[];
    job_types?: string[];
    gross_salary: boolean;
    employments?: string[];
    education_levels?: string[];
    competencies: string[];
    esco_skills?: string[];
}

export interface JobVacancyProperties {
    amount_of_employees: MatchProperty;
    fields_of_study: MatchProperty[];
    gross_salary: {
        is_matched?: boolean;
        min: MatchProperty;
        max: MatchProperty;
        name: string;
    } | null;
    skills: MatchProperty[];
    professions: MatchProperty[];
    employment: MatchProperty;
    education_levels: MatchProperty[];
    competencies: MatchProperty[];
    job_type: MatchProperty;
    esco_occupations: EscoOccupation[];
    esco_skills: EscoSkillsMatchProperty[];
    sector?: MatchProperty;
}

export function getJobVacancyProperties(vacancy: JobVacancyApiParams): JobVacancyProperties {
    return {
        amount_of_employees: vacancy.company.amount_of_employees,
        fields_of_study: vacancy.fields_of_study,
        skills: vacancy.skills,
        professions: vacancy.job_vacancy.professions,
        gross_salary: transformApiCompensationToJobVacancyGrossSalary(vacancy.job_vacancy.gross_salary),
        employment: vacancy.job_vacancy.employment,
        education_levels: vacancy.education_levels,
        competencies: vacancy.competencies,
        job_type: vacancy.job_vacancy.job_type,
        sector: vacancy.sector,
        esco_occupations: vacancy.esco_occupations || [],
        esco_skills: vacancy.esco_skills || [],
    };
}

export function getJobVacancyPropertiesWithMatch(vacancy: JobVacancyApiParams): JobVacancyProperties {
    return {
        amount_of_employees: transformToPropertyWithMatch(vacancy.company.amount_of_employees, vacancy.match_details?.matching_attributes?.amount_of_employees),
        fields_of_study: vacancy.fields_of_study.map(fieldOfStudy => transformToPropertyWithMatch(fieldOfStudy, vacancy.match_details?.matching_attributes?.field_of_study)),
        professions: vacancy.job_vacancy.professions ? vacancy.job_vacancy.professions.map(profession => transformToPropertyWithMatch(profession, vacancy.match_details?.matching_attributes?.professions)) : [],
        skills: vacancy.skills.map(skill => transformToPropertyWithMatch(skill, vacancy.match_details?.matching_attributes?.skills)),
        gross_salary: transformApiCompensationToJobVacancyGrossSalary(vacancy.job_vacancy.gross_salary),
        employment: transformToPropertyWithMatch(vacancy.job_vacancy.employment, vacancy.match_details?.matching_attributes?.employments),
        education_levels: vacancy.education_levels.map(level => transformToPropertyWithMatch(level, vacancy.match_details?.matching_attributes?.education_levels)),
        competencies: vacancy.competencies.map(competency => transformToPropertyWithMatch(competency, vacancy.match_details?.matching_attributes?.competencies)),
        job_type: transformToPropertyWithMatch(vacancy.job_vacancy.job_type, vacancy.match_details?.matching_attributes?.job_types),
        sector: vacancy.sector,
        esco_occupations: vacancy.esco_occupations || [],
        esco_skills: transformToEscoSkillWithMatch(vacancy.esco_skills, vacancy.match_details?.matching_attributes?.esco_skills) || [],
    };
}

export function transformJobVacancy(vacancy: JobVacancyApiParams): JobVacancy {
    const properties = !vacancy.match_details ? getJobVacancyProperties(vacancy) : getJobVacancyPropertiesWithMatch(vacancy);
    const grossSalaryValue = properties.gross_salary && properties.gross_salary.min.value;

    return {
        ...transformVacancy(vacancy),
        type: VacancyType.job,
        properties,
        matching: vacancy?.match_details?.match_percentage || undefined,
        property_groups: [
            [
                'education_levels',
                'vacancy_type',
                'amount_of_employees',
                'employment',
                ...(grossSalaryValue && grossSalaryValue !== '0') ? ['gross_salary'] : [],
            ],
            [
                'professions',
            ],
            [
                'fields_of_study',
            ],
        ],
    };
}

export function transformLegacyVacancyToNewestVacancy(vacancy: InternshipVacancyApiParams | JobVacancyApiParams): NewestVacancy {
    return {
        type: VacancyType.internship,
        isAnonymous: vacancy.apply_anonymous,
        isArchived: !!vacancy.deleted_at,
        isFavourite: vacancy.is_favourite,
        uuid: vacancy.uuid,
        title: vacancy.title,
        slug: vacancy.slug,
        description: vacancy.description || '',
        company: {
            uuid: vacancy.company.uuid,
            name: vacancy.company.name,
            logo: vacancy.company.logo
                ? `${process.env.REACT_APP_API_V1_URL}/storage/logos/${vacancy.company.logo}`
                : '',
            city: vacancy.company?.address?.city || '',
            contactPerson: vacancy.company.contact_person,
        },
        address: vacancy.company.address,
        publicationDate: new Date(vacancy.date),
        expirationDate: new Date('01-01-2100'),
        skills: vacancy.skills.map(skill => skill.name),
        skillsToLearn: vacancy.skills_to_learn.map(skill => skill.name),
        competencies: vacancy.competencies.map(skill => skill.name),
        competenciesToLearn: vacancy.competencies_to_learn.map(skill => skill.name),
        employmentHourRange: { min: 0, max: 0 },
        compensation: { min: 0, max: 0 },
        grossHourlyWageInCents: { min: 0, max: 0 },
        meta: {
            applicants: (vacancy.applications || []).length,
            views: vacancy.page_views,
            favourites: vacancy.favourite_count,
        },
        properties: {
            vacancyTypeName: VacancyType.internship,
            amountOfEmployees: vacancy.company.amount_of_employees,
            educationLevels: vacancy.education_levels.map(educationLevel => educationLevel.name),
            employmentHourRange: '',
            compensation: '',
            sector: vacancy.sector,
            grades: [],
            fieldsOfStudy: vacancy.fields_of_study.map(fieldOfStudy => fieldOfStudy.value || ''),
            workingRemoteName: '',
            approvedFieldsOfStudy: vacancy.fields_of_study.map(fieldsOfStudy => fieldsOfStudy.value || ''),
        },
    };
}

export function transformJobVacancyToNewestVacancy(resource: JobVacancyApiParams): NewestVacancy {
    const vacancy = transformLegacyVacancyToNewestVacancy(resource);
    const compensation = transformApiCompensationToJobVacancyGrossSalary(resource.job_vacancy.gross_salary);

    return {
        ...vacancy,
        type: VacancyType.job,
        compensation: {
            min: compensation?.min?.value ? parseInt(compensation?.min?.value, 10) : 0,
            max: compensation?.max?.value ? parseInt(compensation?.max?.value, 10) : 0,
        },
        properties: {
            ...vacancy.properties,
            compensation: compensation ? compensation.name : '',
        },
    };
}
/* eslint-enable camelcase */
