import React, { FC, ReactElement } from 'react';

import { Checkbox } from '../../../../../components';
import { Application } from '../../../../../models/Applications';
import { Avatar } from '../../../..';

import './ApplicationSelectorOption.scss';

interface ApplicationSelectorOptionProps {
    application: Application;
    selectedApplicationUuids: string[];
    onChange: (application: Application, isChecked: boolean) => void;
    className?: string;
}

const ApplicationSelectorOption: FC<ApplicationSelectorOptionProps> = ({
    application,
    selectedApplicationUuids,
    onChange,
    className = '',
}): ReactElement => {
    const handleChange = (isChecked: boolean): void => {
        onChange(application, isChecked);
    };

    return (
        <label className={`application-selector-option ${className}`}>
            <Avatar
                src={application.applicant.avatar}
                alt={application.applicant.fullName}
                fallbackString={application.applicant.fullName}
                className="application-selector-option__avatar"
            />

            <Checkbox
                name="applicant"
                label={application.applicant.fullName}
                checked={selectedApplicationUuids.includes(application.uuid)}
                onChange={handleChange}
                className="application-selector-option__input"
            />
        </label>
    );
};

export default ApplicationSelectorOption;
