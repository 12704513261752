import React, { FC, ReactElement, useState } from 'react';

import { Button, Icon } from '../../../../../components';
import { DetailCard, Modal, ModalContent } from '../../../../../compositions';
import { formatAppointment } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { ApplicationAppointmentFormData } from '../../../../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../../../../models/ApplicationInvites';
import { ApplicationRejectionFormData } from '../../../../../models/ApplicationRejections';
import { Application } from '../../../../../models/Applications';
import { ApplicationRejectionForm, AppointmentForm } from '../../../..';

import './ApplicationStatusInviteRejected.scss';

interface ApplicationStatusInviteRejectedProps {
    isLoading: boolean;
    isCandidate?: boolean;
    application: Application;
    activeInvite: ApplicationInvite;
    onChangeInvite?: (applicationUuid: string, formData: ApplicationAppointmentFormData) => void;
    onRejectApplication?: (applicationUuid: string, formData: ApplicationRejectionFormData) => void;
    className?: string;
}

const ApplicationStatusInviteRejected: FC<ApplicationStatusInviteRejectedProps> = ({
    isLoading,
    isCandidate,
    application,
    activeInvite,
    onChangeInvite,
    onRejectApplication,
    className = '',
}): ReactElement => {
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState<boolean>(false);
    const [rejectionModalIsOpen, setRejectionModalIsOpen] = useState<boolean>(false);

    const openInviteModal = (): void => setInviteModalIsOpen(true);
    const closeInviteModal = (): void => setInviteModalIsOpen(false);

    const openRejectionModal = (): void => setRejectionModalIsOpen(true);
    const closeRejectionModal = (): void => setRejectionModalIsOpen(false);

    const description = isCandidate
        ? trans('containers.applicationStatusCard.inviteRejected.description.candidate', {
            company: application?.vacancy.companyName,
        })
        : trans('containers.applicationStatusCard.inviteRejected.description.company');

    const handleInvite = (formData: ApplicationAppointmentFormData): void => {
        closeInviteModal();

        if (onChangeInvite) {
            onChangeInvite(application.uuid, formData);
        }
    };

    const handleRejectApplication = (formData: ApplicationRejectionFormData): void => {
        closeRejectionModal();

        if (onRejectApplication) {
            onRejectApplication(application.uuid, formData);
        }
    };

    return (
        <DetailCard
            title={trans('containers.applicationStatusCard.inviteRejected.title')}
            className={`application-status-invite-rejected ${className}`}
        >
            <p className="application-status-invite-rejected__description">
                {description}
            </p>

            <ul className="application-status-invite-rejected__appointment-list">
                {activeInvite.appointments.map(appointment => (
                    <li key={appointment.uuid} className="application-status-invite-rejected__appointment-item">
                        <Icon name="dislike" className="application-status-invite-rejected__appointment-icon" />

                        <p className="application-status-invite-rejected__appointment-label">
                            {formatAppointment(appointment.startDate, appointment.endDate)}
                        </p>
                    </li>
                ))}
            </ul>

            {!isCandidate && (
                <div className="application-status-invite-rejected__button-wrapper">
                    <Button
                        text={trans('containers.applicationStatusCard.inviteRejected.button.change')}
                        disabled={isLoading}
                        onClick={openInviteModal}
                        className="application-status-invite-rejected__button"
                    />

                    <Button
                        text={trans('containers.applicationStatusCard.inviteRejected.button.reject')}
                        disabled={isLoading}
                        onClick={openRejectionModal}
                        className="application-status-invite-rejected__button application-status-invite-rejected__button--for-rejection"
                    />
                </div>
            )}

            {inviteModalIsOpen && (
                <Modal onClose={closeInviteModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.inviteRejected.changeAppointmentModal.title')}>
                        <AppointmentForm
                            onSubmit={handleInvite}
                            onCancel={closeInviteModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {rejectionModalIsOpen && (
                <Modal onClose={closeRejectionModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.inviteRejected.rejectApplicationModal.title')}>
                        <ApplicationRejectionForm
                            onSubmit={handleRejectApplication}
                            onCancel={closeRejectionModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default ApplicationStatusInviteRejected;
