import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { VacancyType } from '../../../../../_old/app_talentz/models/VacancyType';
import ucFirst from '../../../../../_old/helpers/ucFirst';
import { fallbackAvatar } from '../../../../../constants';
import { trimToMaxLength } from '../../../../../helpers/string';
import { trans } from '../../../../../helpers/trans';
import { Company } from '../../../../../models/Company';
import { NewestVacancy } from '../../../../../models/NewestVacancy';
import { generateStructuredBreadcrumbs, generateStructuredJobPosting } from './helpers';

interface VacancyHelmetProps {
    vacancy: NewestVacancy;
    company: Company;
}

const VacancyHelmet: FC<VacancyHelmetProps> = ({
    vacancy,
    company,
}): ReactElement => {
    const location = useLocation();

    const titlePrefix = vacancy.type === VacancyType.internship
        ? trans('vacancyTypes.internship')
        : trans('vacancyTypes.vacancy');

    const vacancyTitle = ucFirst(vacancy.title);
    const vacancyDescription = vacancy.description
        .replace(/<[^>]+>/g, ' ')
        .replace('&nbsp;', ' ');
    const vacancySummary = trimToMaxLength(vacancyDescription, 160);
    const vacancyCompany = vacancy.company.name;
    const vacancyCity = vacancy.address.city || company.address.city;
    const vacancyImage = company.logo || company.coverImage || fallbackAvatar;

    const shareableUrl = `${process.env.REACT_APP_URL}${location.pathname}`;

    const structuredData = [
        generateStructuredJobPosting(vacancy, company),
        ...generateStructuredBreadcrumbs(vacancy),
    ];

    return (
        <Helmet>
            <title>{`${titlePrefix} ${vacancyTitle} | ${vacancyCompany} | ${vacancyCity}`}</title>
            <meta name="description" content={vacancySummary} />

            <link rel="canonical" href={shareableUrl} />

            <meta property="og:url" content={shareableUrl} />
            <meta property="og:title" content={vacancyTitle} />
            <meta property="og:description" content={vacancySummary} />
            <meta property="og:image" content={vacancyImage} />
            <meta property="og:type" content="website" />

            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
        </Helmet>
    );
};

export default VacancyHelmet;
