import React, { FC, ReactElement } from 'react';

import { AllCheckerCheckbox } from '@createnl/grouped-checkboxes';
import classNames from 'classnames';

import { FormOption } from '../../../types';
import { Icon } from '../..';

import './Checkbox.scss';

export type HTMLInputProps = JSX.IntrinsicElements['input'];

interface CheckAllInGroupCheckboxProps extends HTMLInputProps {
    option: FormOption;
    checked?: boolean;
    className?: string;
}

const CheckAllInGroupCheckbox: FC<CheckAllInGroupCheckboxProps> = ({
    option,
    checked = false,
    className = '',
    ...inputProps
}): ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ref, ...otherProps } = inputProps;

    const checkboxClassNames = classNames('checkbox', {
        'checkbox--is-checked': checked,
    }, className);

    return (
        <label className={checkboxClassNames}>
            <AllCheckerCheckbox
                {...otherProps}
                id={option.label}
                value={option.value}
                className="checkbox__input"
            />

            <div className="checkbox__box">
                <Icon name="check" className="checkbox__icon" />
            </div>

            <span className="checkbox__label">
                {option.label}
            </span>
        </label>
    );
};

export default CheckAllInGroupCheckbox;
