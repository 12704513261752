export const provincesNL = [
    {
        name: 'Drenthe',
        slug: 'drenthe',
        majorCities: [
            'Assen',
            'Beilen',
            'Borger',
            'Coevorden',
            'Dwingeloo',
            'Eelde',
            'Emmen',
            'Havelte',
            'Hoogeveen',
            'Klazinaveen',
            'Meppel',
            'Norg',
            'Roden',
            'Schoonoord',
            'Witteveen',
            'Zuidlaren',
        ],
    },
    {
        name: 'Flevoland',
        slug: 'flevoland',
        majorCities: [
            'Almere',
            'Biddinghuizen',
            'Dronten',
            'Emmeloord',
            'Lelystad',
            'Rutten',
            'Urk',
            'Zeewolde',
        ],
    },
    {
        name: 'Friesland',
        slug: 'friesland',
        majorCities: [
            'Akkrum',
            'Appelscha',
            'Bakkeveen',
            'Bolsward',
            'Dokkum',
            'Drachten',
            'Franeker',
            'Harlingen',
            'Heerenveen',
            'Hindeloopen',
            'Hollum',
            'Holwerd',
            'IJlst',
            'Kollum',
            'Leeuwarden',
            'Nes',
            'Schiermonnikoog',
            'Sloten',
            'Sneek',
            'Stavoren',
            'Vlieland',
            'West-Terschelling',
            'Wolvega',
            'Workum',
        ],
    },
    {
        name: 'Gelderland',
        slug: 'gelderland',
        majorCities: [
            'Apeldoorn',
            'Arnhem',
            'Barneveld',
            'Bredevoort',
            'Borculo',
            'Culemborg',
            'Dieren',
            'Doetinchem',
            'Ede',
            'Elburg',
            'Gendt',
            'Groenlo',
            'Harderwijk',
            'Hattem',
            'Heukelum',
            'Nijkerk',
            'Nijmegen',
            'Staverden',
            'Tiel',
            'Ulft',
            'Winterswijk',
            'Zaltbommel',
            'Zevenaar',
            'Zutphen',
        ],
    },
    {
        name: 'Groningen',
        slug: 'groningen',
        majorCities: [
            'Appingedam',
            'Bourtange',
            'Eemshaven',
            'Delftzijl',
            'Grijpskerk',
            'Groningen',
            'Hoogezand',
            'Lauwersoog',
            'Leek',
            'Nieuwolda',
            'Pieterburen',
            'Stadskanaal',
            'Ter Apel',
            'Veendam',
            'Winschoten',
            'Winsum',
        ],
    },
    {
        name: 'Limburg',
        slug: 'limburg',
        majorCities: [
            'Echt',
            'Geleen',
            'Gennep',
            'Heerlen',
            'Kerkrade',
            'Tegelen',
            'Kessel',
            'Landgraaf',
            'Maastricht',
            'Meijel',
            'Montfort',
            'Nieuwstadt',
            'Roermond',
            'Schin op Geul',
            'Sevenum',
            'Sittard',
            'Stein',
            'Susteren',
            'Thorn',
            'Vaals',
            'Valkenburg',
            'Venlo',
            'Venray',
            'Weert',
        ],
    },
    {
        name: 'Noord-Brabant',
        slug: 'noord-brabant',
        majorCities: [
            "'s-Hertogenbosch",
            'Bergen op Zoom',
            'Bladel',
            'Boxtel',
            'Breda',
            'Eindhoven',
            'Geertruidenberg',
            'Geldrop',
            'Grave',
            'Helmond',
            'Klundert',
            'Mill',
            'Oosterhout',
            'Oss',
            'Overloon',
            'Ravenstein',
            'Roosendaal',
            'Sint-Oedenrode',
            'Tilburg',
            'Valkenswaard',
            'Veldhoven',
            'Waalwijk',
            'Willemstad',
            'Woudrichem',
        ],
    },
    {
        name: 'Noord-Holland',
        slug: 'noord-holland',
        majorCities: [
            'Alkmaar',
            'Amsterdam',
            'Castricum',
            'Den Burg',
            'Den Helder',
            'Egmond aan Zee',
            'Enkhuizen',
            'Haarlem',
            'Heerhugowaard',
            'Hilversum',
            'Hoofddorp',
            'Hoorn',
            'Laren',
            'Medemblik',
            'Monnickendam',
            'Muiden',
            'Naarden',
            'Purmerend',
            'Schagen',
            'Uithoorn',
            'Velsen',
            'Volendam',
            'Weesp',
            'Zaandam',
        ],
    },
    {
        name: 'Overijssel',
        slug: 'overijssel',
        majorCities: [
            'Almelo',
            'Blokzijl',
            'Deventer',
            'Enschede',
            'Genemuiden',
            'Hardenberg',
            'Hasselt',
            'Hengelo',
            'Kampen',
            'Oldenzaal',
            'Ommen',
            'Raalte',
            'Rijssen',
            'Steenwijk',
            'Vollenhove',
            'Zwolle',
        ],
    },
    {
        name: 'Utrecht',
        slug: 'utrecht',
        majorCities: [
            'Abcoude',
            'Amersfoort',
            'Baarn',
            'Breukelen',
            'Bunschoten',
            'Eemnes',
            'Hagestein',
            'Houten',
            'Leerdam',
            'Meerkerk',
            'Mijdrecht',
            'Montfoort',
            'Nieuwegein',
            'Oudewater',
            'Rhenen',
            'Soest',
            'Utrecht',
            'Veenendaal',
            'Vianen',
            'Wijk bij Duurstede',
            'Woerden',
            'Woudenberg',
            'IJsselstein',
            'Zeist',
        ],
    },
    {
        name: 'Zeeland',
        slug: 'zeeland',
        majorCities: [
            'Arnemuiden',
            'Borssele',
            'Brouwershaven',
            'Goes',
            'Hulst',
            'Kloosterzande',
            'Krabbendijke',
            'Middelburg',
            'Renesse',
            'Sint-Maartensdijk',
            'Sluis',
            'Terneuzen',
            'Veere',
            'Vlissingen',
            'Zierikzee',
            'Zoutelande',
        ],
    },
    {
        name: 'Zuid-Holland',
        slug: 'zuid-holland',
        majorCities: [
            "'s-Gravenhage",
            'Alphen aan den Rijn',
            'Capelle aan den IJssel',
            'Delft',
            'Dordrecht',
            'Gorinchem',
            'Gouda',
            'Hellevoetsluis',
            'Hoek van Holland',
            'Katwijk aan Zee',
            'Leiden',
            'Maassluis',
            'Nieuwveen',
            'Noordwijk',
            'Ouddorp',
            'Oude-Tonge',
            'Rotterdam',
            'Schiedam',
            'Schoonhoven',
            'Spijkenisse',
            'Strijen',
            'Vlaardingen',
            'Voorburg',
            'Zoetermeer',
        ],
    },
];
