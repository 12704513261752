import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLogout: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon points="77.8,27.8 69.9,35.6 78.7,44.4 33.3,44.4 33.3,55.6 78.7,55.6 69.9,64.3 77.8,72.2 100,50" />
        <path d="M11.1,11.1H50V0H11.1C5,0,0,5,0,11.1v77.8C0,95,5,100,11.1,100H50V88.9H11.1V11.1z" />
    </svg>
);

export default IconLogout;
