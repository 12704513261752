export enum WebSocketReadyState {
    connecting = 0,
    open = 1,
    closing = 2,
    closed = 3,
}

export enum WebSocketEvent {
    open = 'open',
    close = 'close',
    message = 'message',
    chatReport = 'chat-report',
}

export interface WebSocketMessage {
    type: WebSocketEvent;
    message?: string;
}
