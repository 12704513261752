import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Person } from '../../../../_old/app_talentz/models/Person';
import { Button, Icon } from '../../../../components';
import { Avatar, ExpandableNavigation } from '../../../../compositions';
import { MenuItem } from '../../../../connectors/ConnectedMenu/helpers';
import { trans } from '../../../../helpers/trans';
import useHandleClickOutside from '../../../../hooks/useHandleClickOutside';
import { Company } from '../../../../models/Company';
import { RoleType } from '../../../../models/User';
import { generateProfileName } from '../../helpers';
import { ProfileControlsSkeletons } from '../../skeletons';

import './ProfileControls.scss';

interface ProfileControlsProps {
    isLoading: boolean;
    role: RoleType;
    person?: Person;
    company?: Company;
    controls: MenuItem[];
    className?: string;
}

const ProfileControls: FC<ProfileControlsProps> = ({
    isLoading,
    role,
    person,
    company,
    controls,
    className = '',
}): ReactElement => {
    const location = useLocation();
    const [profileControlsIsExpanded, toggleProfileNavigationIsExpanded] = useToggle(false);

    const closeProfileNavigation = (): void => toggleProfileNavigationIsExpanded(false);

    const profileControlsRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(profileControlsRef, closeProfileNavigation);

    useEffect(closeProfileNavigation, [location]);

    const name = generateProfileName({ role, person, company });

    const profileControlsToggleButtonLabel = profileControlsIsExpanded
        ? trans('navigation.profileControls.closeLabel')
        : trans('navigation.profileControls.openLabel');

    return (
        <section ref={profileControlsRef} className={`profile-controls ${className}`}>
            {isLoading ? (
                <ProfileControlsSkeletons className="profile-controls__skeletons" />
            ) : (
                <Button
                    text={profileControlsToggleButtonLabel}
                    onClick={toggleProfileNavigationIsExpanded}
                    className="profile-controls__toggle-button"
                >
                    <Avatar
                        src={role === RoleType.company ? company?.logo : person?.avatar}
                        alt={trans('navigation.profileControls.avatar')}
                        fallbackString={role === RoleType.company ? company?.name : person?.full_name}
                        className="profile-controls__avatar"
                    />

                    <p data-hj-suppress="true" className="profile-controls__name">
                        {name}
                    </p>

                    <Icon name="vertical-ellipsis" className="profile-controls__toggle-icon" />
                </Button>
            )}

            <ExpandableNavigation
                isExpanded={profileControlsIsExpanded}
                name={trans('navigation.profileControls.ariaLabel')}
                navigationItems={controls}
                onClose={closeProfileNavigation}
                className="profile-controls__expandable-nav"
            />
        </section>
    );
};

export default ProfileControls;
