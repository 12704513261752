import { getLocaleISOString, isValidDateString } from '../helpers/date';
import { SingleRelationship } from '../japi/types/Relationships';

export interface JobExperienceResource {
    type: 'job-experiences';
    id: string;
    attributes: {
        function: string;
        company: string;
        description: string;
        startDate: string;
        endDate?: string;
        createdAt: string;
        updatedAt: string;
    };
}

export interface JobExperience {
    id: string;
    position: string;
    company: string;
    description: string;
    isCurrentJob?: boolean;
    startDate: Date;
    endDate?: Date;
}

export interface JobExperienceRequest {
    data: {
        type: 'job-experiences';
        id?: string;
        attributes: {
            function: string;
            company: string;
            description: string;
            startDate: string;
            endDate?: string;
        };
        relationships: {
            person: SingleRelationship<'people'>;
        };
    };
}

export type JobExperienceFormData = JobExperience;

export const defaultJobExperience = (): JobExperience => ({
    id: '',
    position: '',
    company: '',
    description: '',
    isCurrentJob: false,
    startDate: new Date(),
    endDate: undefined,
});

export const transformToJobExperience = (
    jobExperienceResource: JobExperienceResource,
): JobExperience => {
    const startDate = isValidDateString(jobExperienceResource.attributes.startDate)
        ? new Date(jobExperienceResource.attributes.startDate)
        : new Date();

    const endDate = jobExperienceResource.attributes.endDate && isValidDateString(jobExperienceResource.attributes.endDate)
        ? new Date(jobExperienceResource.attributes.endDate)
        : undefined;

    const isCurrentJob = !!startDate && !endDate;

    return {
        id: jobExperienceResource.id,
        position: jobExperienceResource.attributes.function,
        company: jobExperienceResource.attributes.company,
        description: jobExperienceResource.attributes.description,
        isCurrentJob,
        startDate,
        endDate,
    };
};

export const transformToJobExperienceRequest = (jobExperience: JobExperience, personUuid: string): JobExperienceRequest => ({
    data: {
        type: 'job-experiences',
        ...(jobExperience.id && { id: jobExperience.id }),
        attributes: {
            function: jobExperience.position,
            company: jobExperience.company,
            description: jobExperience.description,
            startDate: getLocaleISOString(jobExperience.startDate),
            endDate: jobExperience.endDate
                ? getLocaleISOString(jobExperience.endDate)
                : undefined,
        },
        relationships: {
            person: {
                data: {
                    id: personUuid,
                    type: 'people',
                },
            },
        },
    },
});
