import styled, { css } from 'styled-components';

import Button from '../../components/Button';

export default styled(Button)(props => css`
     position: absolute;
     bottom: -${props.theme.space.xl};
     left: 0;
     color: ${props.theme.colors.white};
     padding-left: 0;
     
     &:hover {
         color: ${props.theme.colors.white};
         
         svg {
            fill: ${props.theme.colors.white};
         }
     }
     
     svg{
        fill: ${props.theme.colors.white};
     }
`);
