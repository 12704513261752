import { formatDate, isValidDateString, isValidTimeString } from '../helpers/date';
import { objectContainsValues } from '../helpers/object';
import { trans } from '../helpers/trans';
import {
    arrayHasMinimumLength,
    dateIsEqualOrAfterComparison,
    dateIsEqualOrBeforeComparison,
    dateIsValid,
    numberDoesNotExceedComparison,
    numberDoesNotPrecedeComparison,
    stringContainsValue,
    stringMatchesRegEx,
} from '../helpers/validation';
import { AppointmentDate } from '../models/ApplicationAppointments';

export type FormError<Value = string> = Value | undefined;
export type FormErrors<FormData, FormSubData = string> = Partial<Record<keyof FormData, FormError<FormSubData>>>;
export type FormValidation<Errors> = [Errors, boolean];
export type OptionalFormValidation<FormData> = Partial<Record<keyof FormData, boolean>>;

export const validateForm = <Errors extends Record<string, any>>(errors: Errors): FormValidation<Errors> => {
    const hasErrors = objectContainsValues<Errors>(errors);

    return [
        errors,
        hasErrors,
    ];
};

export const validateRequiredString = (attribute: string, string?: string): FormError => {
    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateMinStringLength = (attribute: string, string: string, minLength: number): FormError => {
    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!numberDoesNotPrecedeComparison(string.length, minLength)) {
        return trans('errors.stringPrecedesMinLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            minimum: String(minLength),
        });
    }

    return undefined;
};

export const validateMaxStringLength = (attribute: string, string: string, maxLength: number): FormError => {
    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!numberDoesNotExceedComparison(string.length, maxLength)) {
        return trans('errors.stringExceedsMaxLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            maximum: String(maxLength),
        });
    }

    return undefined;
};

export const validateRequiredOption = (attribute: string, optionId?: string): FormError => {
    if (!stringContainsValue(optionId)) {
        return trans('errors.requiredSelectedOption', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateMinimumArrayLength = <Type>(attribute: string, array: Type[], minimumLength: number): FormError => {
    if (!arrayHasMinimumLength(array, minimumLength)) {
        return trans('errors.minimumRequired', {
            attribute: trans(`errors.attributes.${attribute}`),
            amount: String(minimumLength),
        });
    }

    return undefined;
};

export const validateEmailAddress = (email: string): FormError => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!stringContainsValue(email)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.email'),
        });
    }

    if (!stringMatchesRegEx(email, emailRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.email'),
            example: trans('errors.examples.email'),
        });
    }

    return undefined;
};

export const validateStartDate = (startDate?: Date, endDate?: Date, dateNotation?: string): FormError => {
    if (!startDate) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    if (!dateIsValid(startDate)) {
        return trans('errors.invalidDate', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    if (endDate && !dateIsValid(endDate)) {
        return trans('errors.incomparableDate', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    if (endDate && !dateIsEqualOrBeforeComparison(startDate, endDate)) {
        return trans('errors.dateIsAfter', {
            attribute: trans('errors.attributes.startDate'),
            comparisonDate: formatDate(endDate, dateNotation),
        });
    }

    return undefined;
};

export const validateEndDate = (endDate?: Date, startDate?: Date, dateNotation?: string): FormError => {
    if (!endDate) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.endDate'),
        });
    }

    if (!dateIsValid(endDate)) {
        return trans('errors.invalidDate', {
            attribute: trans('errors.attributes.endDate'),
        });
    }

    if (startDate && !dateIsValid(startDate)) {
        return trans('errors.incomparableDate', {
            attribute: trans('errors.attributes.endDate'),
        });
    }

    if (startDate && !dateIsEqualOrAfterComparison(endDate, startDate)) {
        return trans('errors.dateIsBefore', {
            attribute: trans('errors.attributes.endDate'),
            comparisonDate: formatDate(startDate, dateNotation),
        });
    }

    return undefined;
};

export const validateNumberRangeStart = (attribute: string, number: number, comparisonNumber: number): FormError => {
    if (!numberDoesNotExceedComparison(number, comparisonNumber)) {
        return trans('errors.numberExceedsComparison', {
            attribute: trans(`errors.attributes.${attribute}`),
            comparison: String(comparisonNumber),
        });
    }

    return undefined;
};

export const validateNumberRangeEnd = (attribute: string, number: number, comparisonNumber: number): FormError => {
    if (!numberDoesNotPrecedeComparison(number, comparisonNumber)) {
        return trans('errors.numberPrecedesComparison', {
            attribute: trans(`errors.attributes.${attribute}`),
            comparison: String(comparisonNumber),
        });
    }

    return undefined;
};

export const validateImageWidth = (width: number, options: { minimum?: number; maximum?: number }): FormError => {
    const { minimum, maximum } = options;

    if (minimum && !numberDoesNotPrecedeComparison(width, minimum)) {
        return trans('errors.imageWidthTooSmall', {
            minimum: String(minimum),
        });
    }

    if (maximum && !numberDoesNotExceedComparison(width, maximum)) {
        return trans('errors.imageWidthTooLarge', {
            maximum: String(maximum),
        });
    }

    return undefined;
};

export const validateImageHeight = (width: number, options: { minimum?: number; maximum?: number }): FormError => {
    const { minimum, maximum } = options;

    if (minimum && !numberDoesNotPrecedeComparison(width, minimum)) {
        return trans('errors.imageHeightTooSmall', {
            minimum: String(minimum),
        });
    }

    if (maximum && !numberDoesNotExceedComparison(width, maximum)) {
        return trans('errors.imageHeightTooLarge', {
            maximum: String(maximum),
        });
    }

    return undefined;
};

export const validateFileAmount = (fileAmount: number, limit = 10): FormError => {
    if (!numberDoesNotExceedComparison(fileAmount, limit)) {
        return trans('errors.fileAmountLimitExceeded', {
            limit: String(limit),
        });
    }

    return undefined;
};

export const validateFileType = (fileType: string, acceptedFileTypes: string[] = []): FormError => {
    if (!acceptedFileTypes.includes(fileType)) {
        return trans('errors.fileNotAccepted', {
            accepted: acceptedFileTypes.join(', '),
        });
    }

    return undefined;
};

export const validateFileSize = (bytes: number, sizeLimit = 0): FormError => {
    if (sizeLimit > 0 && !numberDoesNotExceedComparison(bytes, sizeLimit)) {
        if (sizeLimit >= 10 ** 6) {
            const size = (sizeLimit / 10 ** 6).toFixed(1);

            return trans('errors.fileSizeTooLarge', {
                sizeLimit: trans('basic.fileSize.megabytes', { size }),
            });
        }

        if (sizeLimit >= 10 ** 3) {
            const size = (sizeLimit / 10 ** 3).toFixed(1);

            return trans('errors.fileSizeTooLarge', {
                sizeLimit: trans('basic.fileSize.kilobytes', { size }),
            });
        }

        return trans('errors.fileSizeTooLarge', {
            sizeLimit: trans('basic.fileSize.bytes', {
                size: `${sizeLimit}`,
            }),
        });
    }

    return undefined;
};

export const validateYouTubeEmbedId = (embedId?: string): FormError => {
    if (!stringContainsValue(embedId)) {
        return trans('errors.invalidYouTubeEmbedId');
    }

    return undefined;
};

export const validateAppointmentDate = (appointmentDate: AppointmentDate): FormError => {
    if (!isValidDateString(appointmentDate.date)) {
        return trans('errors.invalidDate', {
            attribute: trans('errors.attributes.chosenDate'),
        });
    }

    if (!isValidTimeString(appointmentDate.startTime)) {
        return trans('errors.invalidTime', {
            attribute: trans('errors.attributes.startTime'),
        });
    }

    if (!isValidTimeString(appointmentDate.endTime)) {
        return trans('errors.invalidTime', {
            attribute: trans('errors.attributes.endTime'),
        });
    }

    const now = new Date();
    const [startHH, startMM] = appointmentDate.startTime.split(':').map(Number);
    const [endHH, endMM] = appointmentDate.endTime.split(':').map(Number);

    const startDate = new Date(appointmentDate.date);
    startDate.setHours(startHH);
    startDate.setMinutes(startMM);

    const endDate = new Date(appointmentDate.date);
    endDate.setHours(endHH);
    endDate.setMinutes(endMM);

    if (!dateIsEqualOrAfterComparison(startDate, now)) {
        return trans('errors.dateIsInPast', {
            attribute: trans('errors.attributes.chosenDate'),
        });
    }

    if (!dateIsEqualOrBeforeComparison(startDate, endDate)) {
        return trans('errors.dateIsAfter', {
            attribute: trans('errors.attributes.endTime'),
            comparisonDate: trans('errors.attributes.startTime'),
        });
    }

    return undefined;
};
