export const generateFullName = (names: {
    firstName?: string;
    lastNamePrefix?: string;
    lastName?: string;
}): string => {
    const { firstName, lastNamePrefix, lastName } = names;

    if (!lastName) return firstName || '';

    if (!firstName) {
        return lastNamePrefix
            ? `${lastNamePrefix} ${lastName}`
            : lastName;
    }

    return lastNamePrefix
        ? `${firstName} ${lastNamePrefix} ${lastName}`
        : `${firstName} ${lastName}`;
};
