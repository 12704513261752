import React, { FC, ReactElement, useMemo } from 'react';

import { SafeHtml, Skeleton } from '../../../components';
import { DetailCard, FileList } from '../../../compositions';
import { generateIdArray } from '../../../helpers/array';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';

import './ApplicationMotivationCard.scss';

interface ApplicationMotivationCardProps {
    isLoading: boolean;
    application?: Application;
    className?: string;
}

const ApplicationMotivationCard: FC<ApplicationMotivationCardProps> = ({
    isLoading,
    application,
    className = '',
}): ReactElement => {
    const skeletonAmount = 6;
    const skeletons = generateIdArray(skeletonAmount);

    const motivation = useMemo((): string => {
        if (!application) return '';

        const salutation = trans('basic.application.salutation');
        const valediction = trans('basic.application.valediction');
        const applicantName = application.vacancy.isAnonymous
            ? trans('basic.anonymousCandidate')
            : application.applicant.fullName;

        return [
            `<p><em>${salutation}</em></p><br />`,
            `${application.motivation}<br />`,
            `<p><em>${valediction}</em></p>`,
            `<p><em>${applicantName}</em></p>`,
        ].join('');
    }, [application]);

    return (
        <DetailCard
            title={trans('containers.applicationMotivationCard.title')}
            className={`application-motivation-card ${className}`}
        >
            {isLoading && (
                <div className="application-motivation-card__skeleton-wrapper">
                    {skeletons.map(skeleton => (
                        <Skeleton key={skeleton} className="application-motivation-card__skeleton" />
                    ))}
                </div>
            )}

            {!isLoading && motivation && (
                <SafeHtml
                    html={motivation}
                    className="application-motivation-card__motivation"
                />
            )}

            {!isLoading && application && application.files.length > 0 && (
                <FileList
                    files={application.files}
                    className="application-motivation-card__files"
                />
            )}
        </DetailCard>
    );
};

export default ApplicationMotivationCard;
