import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getJobTypesApiCall } from '../../services/JobTypesService';
import { TypedDispatch } from '..';
import { setError, setIsLoading, setJobTypes } from './jobTypes';

export const fetchJobTypes = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const jobTypesResponse = await getJobTypesApiCall();

        if (!isFetchResultSuccessful(jobTypesResponse)) {
            dispatch(setError(jobTypesResponse.error));
            return;
        }

        dispatch(setJobTypes(jobTypesResponse.data));
    } catch (error) {
        console.error('[fetchJobTypes]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
