import styled, { css } from 'styled-components';

export const SlideImageContainer = styled.div(props => css`
    position: relative;
    width: 100%;
    background-color: ${props.theme.colors.white};
    box-shadow: 3px 5px 20px 0 rgba(231,231,231,0.5);
    margin-bottom: ${props.theme.space.lg};
    
    &:before {
        content: '';
        display: block;
        padding-bottom: 120%;
    }
    
    @media ${props.theme.mediaQueries.tablet} { 
        &:before {
            padding-bottom: 135%;
        }
    }
`);

export const SlideImage = styled.img`
    position: absolute;
    bottom: 0;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%);
    transition: height 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
`;

export const SlideBackground = styled.div(props => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: -200px;
        left: -200px;
        width: 400px;
        height: 400px;
        transition: transform 0.35s ease-out 0.4s;
        background-color: ${props.theme.colors.secondary};
        transform: scale(0);
        border-radius: 50%;
    }
`);

export const Slide = styled.div`
    display: block;
    position: relative;
    height: 100%;
`;
