import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';
import { Application } from '../../models/Applications';
import { NewestVacancy } from '../../models/NewestVacancy';
import { SearchableOption } from '../../types';
import { ApplicationOverview } from '..';
import { VacancyApplicantsFilter, VacancyApplicantsMatchesCta, VacancyApplicantsNullState } from './subcomponents';

import './VacancyApplicants.scss';

interface VacancyApplicantsProps {
    isLoading: boolean;
    vacancyUuidFromUrl?: string;
    activeVacancy?: NewestVacancy;
    vacancyOptions: SearchableOption[];
    applications: Application[];
    error: string;
    className?: string;
}

const VacancyApplicants: FC<VacancyApplicantsProps> = ({
    isLoading,
    vacancyUuidFromUrl,
    activeVacancy,
    vacancyOptions,
    applications,
    error,
    className = '',
}): ReactElement => (
    <section className={`vacancy-applicants ${className}`}>
        <header className="vacancy-applicants__header">
            <h1 className="vacancy-applicants__title">
                {trans('containers.vacancyApplicants.title')}
            </h1>
        </header>

        <VacancyApplicantsFilter
            isLoading={isLoading}
            vacancyUuidFromUrl={vacancyUuidFromUrl}
            activeVacancy={activeVacancy}
            vacancyOptions={vacancyOptions}
            className="vacancy-applicants__filters"
        />

        {!isLoading && applications.length === 0 && (
            <VacancyApplicantsNullState
                vacancyUuid={vacancyUuidFromUrl || activeVacancy?.uuid}
                className="vacancy-applicants__null-state"
            />
        )}

        <ApplicationOverview
            isLoading={isLoading}
            applications={applications}
            error={error}
            className="vacancy-applicants__overview"
        />

        {activeVacancy && applications.length > 0 && (
            <VacancyApplicantsMatchesCta
                activeVacancy={activeVacancy}
                className="vacancy-applicants__matches-cta"
            />
        )}
    </section>
);

export default VacancyApplicants;
