/* eslint-disable camelcase */
import { fromISODate, getISODateFormat } from '../services/DateService';

export interface JobExperienceApiParams {
    uuid?: string;
    function: string;
    company: string;
    description: string;
    start_date: string;
    end_date?: string;
}

export interface JobExperience {
    id?: string;
    title: string;
    company: string;
    description: string;
    beginDate: Date;
    endDate?: Date;
}

export function transformToJobExperience(params: JobExperienceApiParams): JobExperience {
    return {
        beginDate: fromISODate(params.start_date),
        company: params.company,
        description: params.description,
        title: params.function,
        endDate: params.end_date ? fromISODate(params.end_date) : undefined,
        id: params.uuid,
    };
}

export function transformToApiJobExperienceRequest(jobExperience: JobExperience): JobExperienceApiParams {
    return {
        uuid: jobExperience.id,
        company: jobExperience.company,
        description: jobExperience.description,
        end_date: jobExperience.endDate ? getISODateFormat(jobExperience.endDate) : undefined,
        function: jobExperience.title,
        start_date: jobExperience.beginDate ? getISODateFormat(jobExperience.beginDate) : '',
    };
}
/* eslint-enable camelcase */
