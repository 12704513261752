import React, {
    FC,
    ReactElement,
    UIEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { IconButton } from '../../../../compositions';
import { scrollToElementBottom } from '../../../../helpers/scroll';
import { trans } from '../../../../helpers/trans';
import { ChatMessage } from '../../../../models/ChatMessages';
import { getMessageGroupPosition } from '../../helpers';
import { ChatEventListSkeletons } from '../../skeletons';
import { ChatEventMessage } from '..';

import './ChatEventList.scss';

interface ChatEventListProps {
    isLoading: boolean;
    userUuid: string;
    messages: ChatMessage[];
    className?: string;
}

const ChatEventList: FC<ChatEventListProps> = ({
    isLoading,
    userUuid,
    messages,
    className = '',
}): ReactElement => {
    const [isWithinRecent, setIsWithinRecent] = useState<boolean>(false);
    const [scrollButtonIsVisible, setScrollButtonIsVisible] = useState<boolean>(false);
    const [amountOfNewEvents, setAmountOfNewEvents] = useState<number>(0);

    const listRef = useRef<HTMLUListElement>(null);

    const scrollToRecent = (): void => {
        scrollToElementBottom<HTMLUListElement>(listRef, 'smooth');
    };

    const checkScrollIsWithinRecent = (currentTarget: Element): void => {
        const { scrollTop, scrollHeight } = currentTarget;
        const { height: boxHeight } = currentTarget.getBoundingClientRect();

        const recentThreshold = scrollHeight - (boxHeight / 2);
        const scrollBottom = scrollTop + boxHeight;

        setIsWithinRecent(scrollBottom >= recentThreshold);
    };

    const handleListScroll = (event: UIEvent<HTMLUListElement>): void => {
        checkScrollIsWithinRecent(event.currentTarget);
    };

    useEffect((): void => {
        if (!isLoading) {
            scrollToElementBottom<HTMLUListElement>(listRef);
        }
    }, [isLoading]);

    useEffect((): void => {
        const lastMessage = messages[messages.length - 1];
        const isAuthor = userUuid === lastMessage?.author.uuid;

        if (isAuthor || isWithinRecent) {
            scrollToRecent();
        } else if (listRef.current) {
            setAmountOfNewEvents(amountOfNewEvents + 1);
            checkScrollIsWithinRecent(listRef.current);
        }
    }, [userUuid, messages]);

    useEffect((): void => {
        setScrollButtonIsVisible(!isWithinRecent);

        if (isWithinRecent) {
            setAmountOfNewEvents(0);
        }
    }, [isWithinRecent]);

    const scrollButtonClassNames = classNames('chat-event-list__scroll-button', {
        'chat-event-list__scroll-button--is-visible': scrollButtonIsVisible,
    });

    const scrollButtonLabel = useMemo((): string => {
        if (amountOfNewEvents === 0) return trans('containers.messageOverview.button.scrollToRecent');

        return trans('containers.messageOverview.button.newEvents', {
            amount: amountOfNewEvents,
        });
    }, [amountOfNewEvents]);

    return (
        <main className={`chat-event-list ${className}`}>
            {isLoading && (
                <ChatEventListSkeletons className="chat-event-list__skeletons" />
            )}

            {!isLoading && (
                <ul
                    ref={listRef}
                    onScroll={handleListScroll}
                    className="chat-event-list__list"
                >
                    {messages.map((message, index) => {
                        const isAuthor = userUuid === message.author.uuid;
                        const [isFirst, isLast] = getMessageGroupPosition(messages, index);

                        return (
                            <ChatEventMessage
                                key={message.uuid}
                                isAuthor={isAuthor}
                                isFirstOfGroup={isFirst}
                                isLastOfGroup={isLast}
                                message={message}
                                className="chat-event-list__item"
                            />
                        );
                    })}
                </ul>
            )}

            <IconButton
                icon="arrow-down"
                text={scrollButtonLabel}
                tabIndex={scrollButtonIsVisible ? 0 : -1}
                onClick={scrollToRecent}
                className={scrollButtonClassNames}
            />
        </main>
    );
};

export default ChatEventList;
