import React, {
    createRef,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

interface CheckIntersectionProps {
    once?: boolean;
    alwaysVisible?: boolean;
    shouldBeFullyInView?: boolean;
    className?: string;
    children: (isVisible: boolean) => ReactElement;
}

const CheckIntersection: FC<CheckIntersectionProps> = ({
    once,
    alwaysVisible,
    shouldBeFullyInView,
    className = '',
    children,
}): ReactElement => {
    const [isVisible, setVisibility] = useState(false);
    const childRef = createRef<HTMLDivElement>();

    useEffect((): () => void => {
        let observer: IntersectionObserver;
        const subject = childRef.current;

        if ('IntersectionObserver' in window && subject) {
            observer = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
                for (let i = 0; i < entries.length; i += 1) {
                    if (entries[i].isIntersecting) {
                        setVisibility(true);
                    } else if (!once) {
                        setVisibility(false);
                    }
                }
            }, { threshold: shouldBeFullyInView ? 1 : 0.5 });

            observer.observe(subject);
        } else {
            setVisibility(true);
        }

        return (): void => {
            if (subject) {
                observer.unobserve(subject);
            }
        };
    }, [childRef]);

    return (
        <div ref={childRef} className={className}>
            {children ? children(alwaysVisible || isVisible) : null}
        </div>
    );
};

export default CheckIntersection;
