import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { InputLabel } from '../../../../components';
import ieVersion from '../../../helpers/ieVersion';
import TextArea from '../TextArea';
import { EditorWrapper, Error, Requirement } from './style';

let CKEditor;
let ClassicEditor;

if (typeof window !== 'undefined') {
    // eslint-disable-next-line
    import('@ckeditor/ckeditor5-build-classic/build/translations/nl');
    // eslint-disable-next-line global-require
    import('@ckeditor/ckeditor5-react').then(C => { CKEditor = C.CKEditor; });
    // eslint-disable-next-line global-require
    import('@ckeditor/ckeditor5-build-classic').then(D => { ClassicEditor = D.default; });
}

const ieV = ieVersion();

const TextEditor = props => {
    const {
        name,
        label,
        required,
        optional,
        defaultValue,
        disabled,
        errors,
        placeholder,
        requirement,
        onChange,
        id,
    } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue || '');

    return (
        <>
            {label && (
                <InputLabel
                    id={`${id}-label`}
                    text={label}
                    optional={optional}
                    required={required}
                />
            )}
            <EditorWrapper data-hj-suppress errors={!!errors}>
                {ieV === 0
                    ? (
                        <>
                            <CKEditor
                                editor={ClassicEditor}
                                data={defaultValue}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setCurrentValue(data);
                                    onChange(data);
                                }}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'Undo', 'Redo'],
                                    heading: {
                                        options: [
                                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                            {
                                                model: 'heading1',
                                                view: 'h2',
                                                title: 'Kop',
                                                class: 'ck-heading_heading1',
                                            },
                                        ],
                                    },
                                    placeholder,
                                    disabled,
                                    language: 'nl',
                                }}
                            />
                            <textarea
                                id={id || name}
                                name={name}
                                value={currentValue}
                                readOnly
                                hidden
                                disabled={disabled}
                            />
                        </>
                    )
                    : (
                        <TextArea
                            id={id || name}
                            name={name}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            onChange={data => onChange(data)}
                        />
                    )
                }
            </EditorWrapper>
            {requirement && (
                <Requirement>
                    {requirement}
                </Requirement>
            )}
            <Error errors={!!errors}>
                {(!!errors) && <span key={`${name} - ${errors[0]}`}>{errors[0]}<br /></span>}
            </Error>
        </>
    );
};

TextEditor.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    optional: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    requirement: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
};

TextEditor.defaultProps = {
    label: '',
    required: false,
    optional: false,
    defaultValue: '',
    disabled: false,
    errors: null,
    placeholder: '',
    requirement: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    id: null,
};

export default TextEditor;
