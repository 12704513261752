import React, {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { clamp } from '../../helpers/number';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import { CarouselArrowControls, CarouselBulletControls } from './subcomponents';

import './Carousel.scss';

interface CarouselProps {
    title?: string;
    columnAmount?: number;
    itemAmount: number;
    className?: string;
}

const Carousel: FC<PropsWithChildren<CarouselProps>> = ({
    title,
    columnAmount = 2,
    itemAmount,
    className = '',
    children,
}): ReactElement => {
    const { isMobile } = useDeviceWidth();

    const [columns, setColumns] = useState<number>(2);
    const [slideAmount, setSlideAmount] = useState<number>(1);
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    useEffect((): void => {
        const maxColumns = Math.max(1, itemAmount);
        const amountOfColumns = isMobile ? 1 : clamp(columnAmount, 1, maxColumns);

        setColumns(amountOfColumns);
        setSlideAmount(Math.ceil(itemAmount / amountOfColumns));
        setCurrentSlide(0);
    }, [isMobile, columnAmount, itemAmount]);

    const handlePrevSlideClick = (): void => setCurrentSlide(currentSlide - 1);
    const handleNextSlideClick = (): void => setCurrentSlide(currentSlide + 1);

    const cssVariables = {
        '--carousel-column-amount': columns,
        '--carousel-item-amount': itemAmount,
        '--carousel-current-slide': currentSlide,
    } as CSSProperties;

    return (
        <section style={cssVariables} className={`carousel ${className}`}>
            <header className="carousel__header">
                {title && (
                    <h1 className="carousel__title">
                        {title}
                    </h1>
                )}

                <CarouselArrowControls
                    slideAmount={slideAmount}
                    currentSlide={currentSlide}
                    onPrevClick={handlePrevSlideClick}
                    onNextClick={handleNextSlideClick}
                    className="carousel__arrow-controls"
                />
            </header>

            <div className="carousel__slide-wrapper">
                <div className="carousel__slide-content">
                    {children}
                </div>
            </div>

            <CarouselBulletControls
                slideAmount={slideAmount}
                currentSlide={currentSlide}
                onBulletClick={setCurrentSlide}
                className="carousel__bullet-controls"
            />
        </section>
    );
};

export default Carousel;
