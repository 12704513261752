import { trans } from '../../helpers/trans';
import { FetchResultType, isFetchResultSuccessful } from '../../models/FetchResult';
import { SkillType } from '../../models/Skills';
import {
    createSkillApiCall,
    fetchAllGradeOptions,
    fetchAllInternshipTypeOptions,
    getAllEducationLevelsApiCall,
} from '../../services/VacancyOptionsService';
import { ReducerGetter, TypedDispatch } from '..';
import { setSkills } from '../skills/skills';
import { addNegativeToast } from '../toast/toastActions';
import {
    setEducationLevelOptions,
    setGradeOptions,
    setInternshipTypeOptions,
    setVacancyOptionsLoading,
} from './vacancyOptions';

export function getAllVacancyOptions() {
    return async (dispatch: TypedDispatch): Promise<void> => {
        try {
            dispatch(setVacancyOptionsLoading(true));
            const educationLevelsRequest = getAllEducationLevelsApiCall();
            const internshipTypesRequest = fetchAllInternshipTypeOptions();
            const gradesRequest = fetchAllGradeOptions();

            const educationLevels = await educationLevelsRequest;

            if (educationLevels.type === FetchResultType.Error) {
                console.error('[getAllVacancyOptions]', educationLevels.error);
                return;
            }

            dispatch(setEducationLevelOptions(educationLevels.data));

            const internshipTypes = await internshipTypesRequest;

            if (internshipTypes.type === FetchResultType.Error) {
                console.error('[getAllVacancyOptions]', internshipTypes.error);
                return;
            }

            dispatch(setInternshipTypeOptions(internshipTypes.data));

            const grades = await gradesRequest;

            if (grades.type === FetchResultType.Error) {
                console.error('[getAllVacancyOptions]', grades.error);
                return;
            }

            dispatch(setGradeOptions(grades.data));

            dispatch(setVacancyOptionsLoading(false));
        } catch (error) {
            console.error('[getAllVacancyOptions]', error);
        }
    };
}

export function createSkillOption(skill: string) {
    return async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
        try {
            const response = await createSkillApiCall(skill);
            const currentState = getState();

            if (!isFetchResultSuccessful(response)) {
                console.error('[createSkillOption]', response.error);

                dispatch(addNegativeToast({
                    title: trans('errors.unknownError'),
                    description: response.error,
                }));

                return;
            }

            const newSkill = {
                id: response.data.uuid,
                type: SkillType.skill,
                name: response.data.name,
                slug: response.data.slug,
                isSelectable: true,
            };

            dispatch(setSkills([
                ...currentState.skillsReducer.skills,
                newSkill,
            ]));
        } catch (error) {
            console.error('[createSkillOption]', error);
        }
    };
}
