import { NavLinkProps } from 'react-router-dom';

import { trans } from '../../../helpers/trans';
import { RoutePaths } from '../../../routes';
import { CompanyOverviewTab, CompanySettingsTab, UserSettingsTab } from '../../../types/pageTabs';

export enum MenuKey {
    home = 'home',
    jobs = 'jobs',
    internships = 'internships',
    createVacancy = 'create-vacancy',
    candidateOverview = 'candidate-overview',
    companyOverview = 'company-overview',
    myVacancies = 'my-vacancies',
    infoStudent = 'info-student',
    infoJobSeeker = 'info-job-seeker',
    infoCompany = 'info-company',
    infoEducation = 'info-education',
    faq = 'faq',
    aboutUs = 'about-us',
    blog = 'blog',
    userProfile = 'user-profile',
    userSettings = 'user-settings',
    companyProfile = 'company-profile',
    companyUserSettings = 'company-user-settings',
    login = 'login',
    logout = 'logout',
    registration = 'registration',
    completeRegistration = 'complete-registration',
    favourites = 'favourites',
    messages = 'messages',
    notifications = 'notifications',
}

export interface MenuItem extends Omit<NavLinkProps, 'to'> {
    key: MenuKey;
    themeColor?: string;
    icon?: string;
    label: string;
    to?: string;
    onClick?: () => void;
    isTextual?: boolean;
    isImportant?: boolean;
    counter?: number;
}

interface RoleSpecificConfig {
    allowed: MenuKey[];
    items: MenuItem[];
}

export const getRoleSpecificItems = (config: RoleSpecificConfig): MenuItem[] => {
    const { allowed, items } = config;

    return allowed
        .map(allowedKey => items.find(item => item.key === allowedKey))
        .filter(item => item) as MenuItem[];
};

export const menuItems = {
    internships: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.internships,
        icon: 'academic-cap',
        label: trans('navigation.items.internshipVacancies'),
        to: RoutePaths.internships(),
        ...menuItem,
    }),
    jobs: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.jobs,
        icon: 'briefcase',
        label: trans('navigation.items.jobVacancies'),
        to: RoutePaths.jobs(),
        ...menuItem,
    }),
    createVacancy: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.createVacancy,
        icon: 'article-add',
        label: trans('navigation.items.createVacancy'),
        to: RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies),
        ...menuItem,
    }),
    candidateOverview: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.candidateOverview,
        icon: 'home',
        label: trans('navigation.items.candidateOverview'),
        to: RoutePaths.candidateOverview(),
        ...menuItem,
    }),
    companyOverview: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.companyOverview,
        icon: 'home',
        label: trans('navigation.items.companyOverview'),
        to: RoutePaths.companyOverview(),
        ...menuItem,
    }),
    infoStudent: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.infoStudent,
        icon: 'academic-cap',
        themeColor: '#02c615',
        label: trans('navigation.items.infoStudents'),
        to: RoutePaths.infoStudent(),
        ...menuItem,
    }),
    infoJobSeeker: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.infoJobSeeker,
        icon: 'briefcase',
        themeColor: '#ec796f',
        label: trans('navigation.items.infoJobSeekers'),
        to: RoutePaths.infoJobSeeker(),
        ...menuItem,
    }),
    infoCompany: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.infoCompany,
        icon: 'company',
        themeColor: '#2a50da',
        label: trans('navigation.items.infoCompany'),
        to: RoutePaths.infoCompany(),
        ...menuItem,
    }),
    infoEducation: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.infoEducation,
        icon: 'academic-cap',
        themeColor: '#ec981a',
        label: trans('navigation.items.infoEducation'),
        ...menuItem,
    }),
    faq: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.faq,
        icon: 'question-mark',
        themeColor: '#8a3ffc',
        label: trans('navigation.items.faq'),
        to: RoutePaths.faq(),
        ...menuItem,
    }),
    aboutUs: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.aboutUs,
        icon: 'information',
        themeColor: '#23dcf5',
        label: trans('navigation.items.aboutUs'),
        to: RoutePaths.aboutUs(),
        ...menuItem,
    }),
    blog: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.blog,
        icon: 'article',
        themeColor: '#ec981a',
        label: trans('navigation.items.blog'),
        to: RoutePaths.blog(),
        ...menuItem,
    }),
    userProfile: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.userProfile,
        label: trans('navigation.items.userProfile'),
        icon: 'profile',
        to: RoutePaths.userProfile(),
        ...menuItem,
    }),
    userSettings: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.userSettings,
        label: trans('navigation.items.settings'),
        icon: 'settings',
        to: RoutePaths.userSettings(UserSettingsTab.personalData),
        ...menuItem,
    }),
    companyProfile: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.companyProfile,
        label: trans('navigation.items.companyProfile'),
        icon: 'company',
        to: RoutePaths.myCompanyProfile(),
        ...menuItem,
    }),
    companyUserSettings: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.companyUserSettings,
        label: trans('navigation.items.settings'),
        icon: 'settings',
        to: RoutePaths.companySettings(CompanySettingsTab.personalData),
        ...menuItem,
    }),
    registration: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.registration,
        label: trans('navigation.items.registration'),
        ...menuItem,
    }),
    completeRegistration: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.completeRegistration,
        label: trans('navigation.items.completeRegistration'),
        ...menuItem,
    }),
    login: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.login,
        label: trans('navigation.items.login'),
        icon: 'login',
        ...menuItem,
    }),
    logout: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.logout,
        label: trans('navigation.items.logout'),
        icon: 'logout',
        ...menuItem,
    }),
    favourites: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.favourites,
        label: trans('navigation.items.favourites'),
        icon: 'heart',
        ...menuItem,
    }),
    messages: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.messages,
        label: trans('navigation.items.messages'),
        icon: 'chat-balloon',
        ...menuItem,
    }),
    notifications: (menuItem?: Partial<MenuItem>): MenuItem => ({
        key: MenuKey.notifications,
        label: trans('navigation.items.notifications'),
        icon: 'bell',
        ...menuItem,
    }),
};
