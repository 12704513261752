import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { FavouritesSidebar } from '../../containers';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { patchFavourites } from '../../store/candidate/candidateActions';
import { toggleExpandFavourites } from '../../store/favourites/favouritesActions';

interface ConnectedFavouritesSidebarProps {
    className?: string;
}

const ConnectedFavouritesSidebar: FC<ConnectedFavouritesSidebarProps> = ({
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const role = useLegacySelector(state => state.legacyUser.role);

    const isExpanded = useTypedSelector(state => state.favouritesReducer.isExpanded);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const userRole = role || RoleType.none;
    const allowedRoles = [RoleType.student, RoleType.jobSeeker];
    const allowedToFavourite = allowedRoles.includes(userRole);
    const isValidUser = isAuthenticated && allowedToFavourite;

    useEffect((): void => {
        dispatch(toggleExpandFavourites(false));
    }, [location]);

    const handleRemoveFavouriteVacancy = (id: string): void => {
        if (candidate) {
            const newFavourites = candidate.favourites.filter(favourite => favourite.uuid !== id);

            dispatch(patchFavourites(candidate.uuid, newFavourites));
        }
    };

    const handleClose = (): void => {
        dispatch(toggleExpandFavourites(false));
    };

    return isValidUser && candidate ? (
        <FavouritesSidebar
            isLoading={isLoading}
            isExpanded={isExpanded}
            favourites={candidate.favourites}
            onRemoveFavourite={handleRemoveFavouriteVacancy}
            onClose={handleClose}
            className={className}
        />
    ) : null;
};

export default ConnectedFavouritesSidebar;
