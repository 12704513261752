import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { EducationLevel, EducationSubLevel } from '../../models/EducationLevels';
import { AsyncReduxState } from '../../types';

export type EducationLevelsState = AsyncReduxState<{
    educationLevels: EducationLevel[];
    educationSubLevels: EducationSubLevel[];
}>;

const initialState: EducationLevelsState = {
    ...initialAsyncReduxState,
    educationLevels: [],
    educationSubLevels: [],
};

export const educationLevelsSlice = createSlice({
    name: 'educationLevelsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): EducationLevelsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EducationLevelsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEducationLevels(state, action: PayloadAction<EducationLevel[]>): EducationLevelsState {
            return {
                ...state,
                educationLevels: action.payload,
            };
        },
        setEducationSubLevels(state, action: PayloadAction<EducationSubLevel[]>): EducationLevelsState {
            return {
                ...state,
                educationSubLevels: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setEducationLevels,
    setEducationSubLevels,
} = educationLevelsSlice.actions;

export default educationLevelsSlice;
