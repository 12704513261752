const desktop = {
    xs: '0.75rem', // 12px
    rg: '.875rem', // 14px
    sm: '1rem', // 16px
    md: '1.125rem', // 18px
    lg: '1.5rem', // 24px
    xl: '2rem', // 32px
};

export default desktop;
