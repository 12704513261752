import { AsyncReduxState, FormOption } from '../types';

export const siteUrl = 'https://talentz.nl';
export const edukadoUrl = 'https://edukado.nl/';
export const jitsiScriptUrl = 'https://meet.jit.si/external_api.js';
export const escoInformationUrl = 'https://esco.ec.europa.eu/nl/classification/occupation_main';

export const fallbackAvatar = `${process.env.REACT_APP_URL}/images/profile-placeholder.png`;

export const userTriedTalentMatchingKey = 'userTriedTalentMatching';
export const userConsentCookieKey = 'userConsentCookies';

export const defaultTravelDistance = 25;

export const maxShortTextLength = 256;
export const maxLongTextLength = 24000;
export const maxFileUploadSize = 8 * (10 ** 6);

export const socketReconnectDelay = 2500;

export const fulltimeThreshold = 32;
export const maxEmploymentYears = 100;
export const maxEmploymentHoursPerWeek = 40;

export const maxGrossMonthlySalary = 10000;
export const maxInternshipCompensation = 1000;

export const defaultVacancyLifetimeInDays = 730;
export const maxVacancyLifetimeInDays = 3650;

export const otherFormOptionKey = 'other';

export const alphabetNumber = '0-9';
export const alphabet = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G',
    'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U',
    'V', 'W', 'X', 'Y', 'Z', alphabetNumber,
];

export const defaultFormOption = (): FormOption => ({
    label: '',
    value: '',
});

export const initialAsyncReduxState: AsyncReduxState = {
    isLoading: false,
    isSuccessful: false,
    error: '',
    statusCode: undefined,
};
