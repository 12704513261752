import React, { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../components';
import { LinkIconButton, Tag } from '../../../compositions';
import { formatDate } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';
import { RoutePaths } from '../../../routes';
import { UserSettingsTab } from '../../../types/pageTabs';

import './CurrentStudyCard.scss';

interface CurrentStudyCardProps {
    isLoading: boolean;
    isEditable: boolean;
    candidate?: Candidate;
    className?: string;
}

const CurrentStudyCard: FC<CurrentStudyCardProps> = ({
    isLoading,
    isEditable,
    candidate,
    className = '',
}): ReactElement => {
    const currentStudy = candidate && candidate.schoolExperiences.length > 0
        ? candidate.schoolExperiences[candidate.schoolExperiences.length - 1]
        : undefined;

    return (
        <Card className={`current-study-card ${className}`}>
            {!isLoading && (
                <Tag
                    isActive
                    text={trans('candidateProfile.tag.student')}
                    className="current-study-card__tag"
                />
            )}

            {isLoading && (
                <div className="current-study-card__skeleton-wrapper">
                    <Skeleton className="current-study-card__skeleton" />
                    <Skeleton className="current-study-card__skeleton" />
                    <Skeleton className="current-study-card__skeleton" />
                </div>
            )}

            {!isLoading && currentStudy && (
                <div className="current-study-card__wrapper">
                    <h2 className="current-study-card__field-of-study">
                        {currentStudy.fieldOfStudy}
                    </h2>

                    <p className="current-study-card__school">
                        {trans('candidateProfile.currentStudy.atSchool', {
                            school: currentStudy.school,
                        })}
                    </p>

                    <p className="current-study-card__period">
                        {trans('candidateProfile.currentStudy.period', {
                            from: formatDate(currentStudy.startDate, 'MMMM yyyy'),
                            to: formatDate(currentStudy.endDate, 'MMMM yyyy'),
                        })}
                    </p>
                </div>
            )}

            {isEditable && (
                <LinkIconButton
                    to={RoutePaths.userSettings(UserSettingsTab.personalData)}
                    icon="pencil"
                    text={trans('actions.edit')}
                    hideLabel
                    disabled={isLoading}
                    className="current-study-card__edit-button"
                />
            )}
        </Card>
    );
};

export default CurrentStudyCard;
