import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Logo } from '../../components';
import { ScrollHinter } from '../../compositions';
import { edukadoUrl } from '../../constants';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import {
    ContactDetailList,
    FooterEnd,
    FooterLinkList,
    NewsletterForm,
    SocialLinks,
} from './subcomponents';
import { SocialPlatform } from './subcomponents/SocialLinks/SocialLinks';

import './Footer.scss';

interface FooterProps {
    userRole: RoleType;
    className?: string;
}

const Footer: FC<FooterProps> = ({
    userRole,
    className = '',
}): ReactElement => {
    const socialPlatforms: SocialPlatform[] = [
        {
            label: trans('footer.social.facebook'),
            url: 'https://www.facebook.com/Talentz-2285410088384496',
            icon: '/images/icons/facebook.svg',
        },
        {
            label: trans('footer.social.linkedin'),
            url: 'https://www.linkedin.com/company/talentz-bv',
            icon: '/images/icons/linkedin.svg',
        },
        {
            label: trans('footer.social.instagram'),
            url: 'https://www.instagram.com/talentz.nl/?hl=nl',
            icon: '/images/icons/instagram.svg',
        },
    ];

    const infoLinks = [
        { label: trans('footer.faq'), path: RoutePaths.faq() },
        { label: trans('footer.aboutTeam'), path: RoutePaths.aboutUs() },
        { label: trans('footer.blog'), path: RoutePaths.blog() },
        { label: trans('footer.cookieSettings'), path: RoutePaths.cookieSettings() },
    ];

    const quickLinks = [
        { label: trans('footer.forStudent'), path: RoutePaths.infoStudent() },
        { label: trans('footer.forJobSeekers'), path: RoutePaths.infoJobSeeker() },
        { label: trans('footer.forEmployee'), path: RoutePaths.infoCompany() },
        { label: trans('footer.forEducation'), externalUrl: edukadoUrl },
        { label: trans('footer.allInternshipVacancies'), path: RoutePaths.internshipVacancyIndex() },
        { label: trans('footer.allJobVacancies'), path: RoutePaths.jobVacancyIndex() },
    ];

    return (
        <footer className={`footer ${className}`}>
            <div className="footer__scroll-hint-wrapper">
                <ScrollHinter
                    scrollToTop
                    className="footer__scroll-hint"
                />
            </div>

            <div className="footer__wrapper">
                <div className="footer__column footer__column--info">
                    <Link
                        aria-label={trans('basic.logoLinkLabel')}
                        to={RoutePaths.home()}
                    >
                        <Logo className="footer__logo" />
                    </Link>

                    <SocialLinks
                        socialPlatforms={socialPlatforms}
                        className="footer__social-link-list"
                    />

                    <FooterLinkList
                        links={infoLinks}
                        className="footer__info-link-list"
                    />
                </div>

                <div className="footer__column footer__column--quick-links">
                    <h2 className="footer__title">
                        {trans('footer.quickTo')}
                    </h2>

                    <FooterLinkList
                        links={quickLinks}
                        className="footer__quick-link-list"
                    />
                </div>

                <div className="footer__column footer__column--contact">
                    <h2 className="footer__title">
                        {trans('footer.contact')}
                    </h2>

                    <div className="footer__address-wrapper">
                        <p>
                            {trans('footer.coc', { number: 74259199 })}
                        </p>
                        {/* <address className="footer__address"> */}
                        {/*     <p>C.N. Appelstraat 8</p> */}
                        {/*     <p>1715GG  SPANBROEK</p> */}
                        {/* </address> */}
                    </div>

                    <ContactDetailList
                        emailAddress="info@talentz.nl"
                        phoneNumber="+3172-202-9359"
                        readablePhoneNumber="072 202 93 59"
                        className="footer__contact-details"
                    />
                </div>

                {userRole === RoleType.none && (
                    <div className="footer__column footer__column--newsletter">
                        <h2 className="footer__title">
                            {trans('forms.newsletter.heading')}
                        </h2>

                        <NewsletterForm className="footer__newsletter-form" />
                    </div>
                )}
            </div>

            <FooterEnd />
        </footer>
    );
};

export default Footer;
