import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';
import { Tag } from '..';

import './VacancySbbFieldsOfStudy.scss';

interface VacancySbbFieldsOfStudyProps {
    vacancyUuid: string;
    fieldsOfStudy: string[];
    className?: string;
}

const VacancySbbFieldsOfStudy: FC<VacancySbbFieldsOfStudyProps> = ({
    vacancyUuid,
    fieldsOfStudy,
    className = '',
}): ReactElement => (
    <div className={`vacancy-sbb-fields-of-study ${className}`}>
        <div className="vacancy-sbb-fields-of-study__title-wrapper">
            <h2 className="vacancy-sbb-fields-of-study__title">
                {trans('basic.sbbApproved.fieldsOfStudy')}
            </h2>
        </div>

        <ul className="vacancy-sbb-fields-of-study__list">
            {fieldsOfStudy.map(fieldOfStudy => (
                <li key={`${vacancyUuid}-${fieldOfStudy}`} className="vacancy-sbb-fields-of-study__item">
                    <Tag text={fieldOfStudy} />
                </li>
            ))}
        </ul>
    </div>
);

export default VacancySbbFieldsOfStudy;
