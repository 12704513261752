export interface ApplicationRejectionApiParams {
    uuid: string;
    content: string;
}

export interface ApplicationRejection {
    uuid: string;
    content: string;
}

export function transformApplicationRejection(params: ApplicationRejectionApiParams): ApplicationRejection {
    return { ...params };
}
