export interface ContactPersonResponse {
    uuid: string;
    fullName: string;
    functionName: string;
    phoneNumber?: string;
}

export interface ContactPerson {
    uuid: string;
    fullName: string;
    position: string;
    phoneNumber: string;
}

export const defaultContactPerson = (): ContactPerson => ({
    uuid: '',
    fullName: '',
    phoneNumber: '',
    position: '',
});

export const transformToContactPerson = (
    contactPersonResponse: ContactPersonResponse,
): ContactPerson => ({
    uuid: contactPersonResponse.uuid,
    fullName: contactPersonResponse.fullName,
    position: contactPersonResponse.functionName,
    phoneNumber: contactPersonResponse.phoneNumber || '',
});
