import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { transformToLegacyJobVacanciesFilterParams } from '../../_old/app_talentz/store/jobVacancies/jobVacancies';
import { updateJobPreferencesSet } from '../../_old/app_talentz/store/userPerson/userPersonActions';
import { defaultTravelDistance } from '../../constants';
import { JobVacancyOverviewFilter } from '../../containers';
import { VacancySearchFormValues } from '../../containers/@forms/VacancySearchForm/VacancySearchForm';
import { retrieveUniqueValues } from '../../helpers/array';
import { decodeSearchQuery, encodeSearchQuery } from '../../helpers/search';
import { trans } from '../../helpers/trans';
import useQuery from '../../hooks/useQuery';
import { transformSectorToSlugFormOption } from '../../models/Sectors';
import { defaultJobVacancyOverviewFilterValues, JobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { RouteParams, RoutePaths } from '../../routes';
import { useLegacyDispatch, useTypedSelector } from '../../store';
import { fetchAmountOfEmployeesOptions } from '../../store/amountOfEmployees/amountOfEmployeesActions';
import { fetchEducationLevels } from '../../store/educationLevels/educationLevelsActions';
import { fetchEscoOccupations } from '../../store/escoOccupations/escoOccupationsActions';
import { fetchJobTypes } from '../../store/jobTypes/jobTypesActions';
import { setActiveFilterValues } from '../../store/jobVacancyOverview/jobVacancyOverview';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { fetchWorkRemoteOptions } from '../../store/workRemote/workRemoteActions';

interface ConnectedJobVacancyOverviewFilterParams extends RouteParams {
    query?: string;
}

interface ConnectedJobVacancyOverviewFilterProps {
    currentPage: number;
}

const ConnectedJobVacancyOverviewFilter: FC<ConnectedJobVacancyOverviewFilterProps> = ({
    currentPage,
}): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const currentQuery = useQuery();
    const { query = '' } = useParams<ConnectedJobVacancyOverviewFilterParams>();
    const navigate = useNavigate();

    const isLoading = useTypedSelector(state => state.jobVacancyOverviewReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.jobVacancyOverviewReducer.activeFilterValues);

    const escoOccupationOptions = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const residenceOptions = useTypedSelector(state => state.residencesReducer.residences);
    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);
    const educationLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationLevels);
    const educationSubLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationSubLevels);
    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);
    const jobTypeOptions = useTypedSelector(state => state.jobTypesReducer.jobTypes);
    const amountOfEmployeesOptions = useTypedSelector(state => state.amountOfEmployeesReducer.amountOfEmployeesOptions);

    const applyTypeOptions = [
        { label: trans('forms.preferences.applyType.regular'), value: 'apply_regular' },
        { label: trans('forms.preferences.applyType.anonymous'), value: 'apply_anonymous' },
    ];

    const [filterValues, setFilterValues] = useState<JobVacancyOverviewFilterValues>(activeFilterValues);
    const talentMatchingFilterValues = {
        skills: activeFilterValues.skills,
        competencies: activeFilterValues.competencies,
    };

    useEffect((): void => {
        if (escoOccupationOptions.length === 0) legacyDispatch(fetchEscoOccupations());
        if (sectorOptions.length === 0) legacyDispatch(fetchSectors());
        if (educationLevelOptions.length === 0) legacyDispatch(fetchEducationLevels());
        if (workRemoteOptions.length === 0) legacyDispatch(fetchWorkRemoteOptions());
        if (jobTypeOptions.length === 0) legacyDispatch(fetchJobTypes());
        if (amountOfEmployeesOptions.length === 0) legacyDispatch(fetchAmountOfEmployeesOptions());
    }, []);

    useEffect((): void => {
        const { what, province, where } = decodeSearchQuery(query);

        const getSectorParams = (): string[] => {
            const querySector = currentQuery.get('sector') || '';

            if (activeFilterValues.sectors && activeFilterValues.sectors.length > 0) {
                const newSectors = querySector
                    ? [...activeFilterValues.sectors, querySector]
                    : [...activeFilterValues.sectors];

                return retrieveUniqueValues(newSectors);
            }

            return querySector ? [querySector] : [];
        };

        const newFilterValues = {
            ...activeFilterValues,
            pageNumber: currentPage,
            what: what || '',
            where: where || '',
            province: province || '',
            sectors: getSectorParams(),
            distance: where
                ? activeFilterValues.distance || defaultTravelDistance
                : 0,
        };

        setFilterValues(newFilterValues);
        legacyDispatch(setActiveFilterValues(newFilterValues));
    }, [currentPage]);

    useEffect((): void => {
        const {
            pageNumber,
            what,
            province,
            where,
            sectors,
        } = activeFilterValues;

        currentQuery.set('page', String(pageNumber));

        sectors.length > 0
            ? currentQuery.set('sector', sectors[sectors.length - 1])
            : currentQuery.delete('sector');

        const searchQuery = encodeSearchQuery({ what, province, where });

        if (searchQuery) {
            navigate(`${RoutePaths.jobsQuery(searchQuery)}?${currentQuery}`, { replace: true });
        } else {
            navigate(`${RoutePaths.jobs()}?${currentQuery}`, { replace: true });
        }
    }, [activeFilterValues]);

    const handleSearchSubmit = (value: VacancySearchFormValues): void => {
        const { what, where } = value;

        const newFilterValues = {
            ...talentMatchingFilterValues,
            pageNumber: 1,
            what,
            where,
            distance: where ? defaultTravelDistance : 0,
        };

        setFilterValues({
            ...filterValues,
            ...newFilterValues,
        });

        legacyDispatch(setActiveFilterValues({
            ...activeFilterValues,
            ...newFilterValues,
        }));
    };

    const handleFilterChange = (value: Partial<JobVacancyOverviewFilterValues>): void => {
        setFilterValues({
            ...filterValues,
            ...value,
        });
    };

    const handleFilterReset = (): void => {
        const defaultFilters = defaultJobVacancyOverviewFilterValues();

        const newFilterValues = {
            ...defaultFilters,
            ...talentMatchingFilterValues,
        };

        setFilterValues(newFilterValues);
        legacyDispatch(setActiveFilterValues(newFilterValues));

        legacyDispatch(updateJobPreferencesSet({}));

        navigate(RoutePaths.jobs());
    };

    const handleFilterSubmit = (): void => {
        const newFilterValues = {
            ...filterValues,
            ...talentMatchingFilterValues,
            pageNumber: 1,
        };

        legacyDispatch(setActiveFilterValues(newFilterValues));

        const newPreferencesSet = transformToLegacyJobVacanciesFilterParams(newFilterValues);
        legacyDispatch(updateJobPreferencesSet(newPreferencesSet));
    };

    return (
        <JobVacancyOverviewFilter
            isLoading={isLoading}
            activeFilterValues={activeFilterValues}
            filterValues={filterValues}
            whatOptions={escoOccupationOptions.map(escoOccupation => escoOccupation.name)}
            whereOptions={residenceOptions}
            applyTypeOptions={applyTypeOptions}
            sectorOptions={sectorOptions.map(transformSectorToSlugFormOption)}
            educationLevelOptions={educationLevelOptions}
            educationSubLevelOptions={educationSubLevelOptions}
            workRemoteOptions={workRemoteOptions}
            jobTypeOptions={jobTypeOptions}
            amountOfEmployeesOptions={amountOfEmployeesOptions}
            onSearchSubmit={handleSearchSubmit}
            onFilterChange={handleFilterChange}
            onFilterReset={handleFilterReset}
            onFilterSubmit={handleFilterSubmit}
        />
    );
};

export default ConnectedJobVacancyOverviewFilter;
