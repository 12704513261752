import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { Sector, SectorsResource, transformToSector } from '../models/Sectors';

export const getSectorsApiCall = async (): Promise<FetchResult<Sector[], string>> => {
    try {
        const sectorsResponse = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/sectors`);

        if (!sectorsResponse.ok) {
            return {
                status: sectorsResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await sectorsResponse.json();

        if (!isResourceCollectionDocument<SectorsResource>(doc)) {
            return {
                status: sectorsResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const sectors = doc.data.map(transformToSector);

        return {
            status: sectorsResponse.status,
            type: FetchResultType.Success,
            data: sectors,
        };
    } catch (error) {
        console.error('[getSectorsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
