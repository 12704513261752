import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconHeartSolid: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,95.9l-7.2-6.6C17,65.9,0,50.5,0,31.6C0,16.2,12.1,4.1,27.5,4.1c8.7,0,17,4.1,22.5,10.4C55.5,8.2,63.8,4.1,72.5,4.1c15.4,0,27.5,12.1,27.5,27.5c0,18.9-17,34.3-42.8,57.7L50,95.9z" />
    </svg>
);

export default IconHeartSolid;
