import { RichText } from 'prismic-dom';

import { PrismicImage, PrismicLinkResolver } from '../../../models/Prismic';

const linkResolver = (doc: PrismicLinkResolver): string => {
    switch (doc.type) {
        case 'blog':
            return `/blog/${doc.uid}`;
        default:
            return `/${doc.uid}`;
    }
};

export const renderBlogContentAsHtml = (slice: any): string | undefined => {
    switch (slice.slice_type) {
        case 'text':
            return RichText.asHtml(slice.primary.content, linkResolver);
        case 'image':
            return slice.items
                .map((item: { image: PrismicImage }) => (
                    `<img src="${item.image.url}" alt="${item.image.alt}" />`
                ))
                .join('');
        default:
            return undefined;
    }
};
