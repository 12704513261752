import React, { FC, ReactElement, useState } from 'react';

import {
    ConfirmModal,
    IconButton,
    Modal,
    ModalContent,
} from '../../../../../compositions';
import { formatDate } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { JobExperience, JobExperienceFormData } from '../../../../../models/JobExperience';
import { JobExperienceForm } from '../../../..';

import './JobExperienceItem.scss';

interface JobExperienceItemProps {
    isEditable?: boolean;
    jobExperience: JobExperience;
    onEdit: (jobExperienceFormData: JobExperienceFormData) => void;
    onDelete: (jobExperience: JobExperience) => void;
    className?: string;
}

const JobExperienceItem: FC<JobExperienceItemProps> = ({
    isEditable = false,
    jobExperience,
    onEdit,
    onDelete,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const openDeleteModal = (): void => setDeleteModalIsOpen(true);
    const closeDeleteModal = (): void => setDeleteModalIsOpen(false);

    const handleEditJobExperience = (jobExperienceFormData: JobExperienceFormData): void => {
        onEdit(jobExperienceFormData);
        closeEditModal();
    };

    const handleDeleteJobExperience = (): void => {
        onDelete(jobExperience);
        closeDeleteModal();
    };

    const getEndDateOrCurrent = (endDate?: Date): string => (endDate
        ? formatDate(endDate, 'MMMM yyyy')
        : trans('basic.presentDay'));

    return (
        <li className={`job-experience-item ${className}`}>
            <div className="job-experience-item__content-wrapper">
                <h3 className="job-experience-item__position">
                    {jobExperience.position}
                    <span className="job-experience-item__company">
                        {trans('candidateProfile.jobExperiences.atCompany', {
                            company: jobExperience.company,
                        })}
                    </span>
                </h3>

                <p className="job-experience-item__dates">
                    {trans('candidateProfile.jobExperiences.dates', {
                        startDate: formatDate(jobExperience.startDate, 'MMMM yyyy'),
                        endDate: getEndDateOrCurrent(jobExperience.endDate),
                    })}
                </p>

                <p className="job-experience-item__description">
                    {jobExperience.description}
                </p>
            </div>

            {isEditable && (
                <div className="job-experience-item__controls-wrapper">
                    <IconButton
                        icon="pencil"
                        text={trans('actions.edit')}
                        hideLabel
                        onClick={openEditModal}
                        className="job-experience-item__control-button"
                    />

                    <IconButton
                        icon="delete"
                        text={trans('actions.delete')}
                        hideLabel
                        onClick={openDeleteModal}
                        className="job-experience-item__control-button"
                    />
                </div>
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('forms.jobExperience.title.edit')}>
                        <JobExperienceForm
                            jobExperience={jobExperience}
                            onSubmit={handleEditJobExperience}
                            onCancel={closeEditModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {deleteModalIsOpen && (
                <Modal onClose={closeDeleteModal}>
                    <ConfirmModal
                        isNegative
                        title={trans('forms.jobExperience.title.delete')}
                        description={trans(trans('forms.jobExperience.delete.description', {
                            company: jobExperience.company,
                        }))}
                        onCancel={closeDeleteModal}
                        onConfirm={handleDeleteJobExperience}
                    />
                </Modal>
            )}
        </li>
    );
};

export default JobExperienceItem;
