import React from 'react';

import { renderToStaticMarkup } from 'react-dom/server';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import Icon from './Icon';

const checkIcon = color => (
    <Icon kind="check" color={color || 'primary'} size={12} />
);

export const CheckList = styled('ul')(`
    padding: 0;
    margin: 0;
`, space);

export const CheckListItem = styled('li')(props => css`
    display: flex; 
    align-items: center;  
    padding: 0;
    color: ${props.theme.colors[props.checked ? (props.checkedColor || 'text') : 'muted']};
    list-style: none;
    transition: color 0.15s ease-out;
    margin: ${props.margin ? `${props.margin}px 0 ${props.margin}px 0` : '0 0 5px 0'};
    
    &:before {
        content: '';
        color: ${props.theme.colors.text};
        font-size: 24px;
        line-height: 13px;
        ${props.theme.fonts.bold};
        display: inline-block;
        width: 14px;
        height: 13px;
        min-width: 14px;
        min-height: 13px;
        text-align: center;
        margin: ${props.margin ? `${props.margin}px 20px ${props.margin}px 0` : '0 15px 0 0'};
        background-image: url('data:image/svg+xml;utf8,${renderToStaticMarkup(checkIcon(props.checked ? (props.checkedColor || 'text') : 'muted'))}');
        background-repeat: no-repeat;
        background-size: cover;
    }
`);
