import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getCoreValuesApiCall } from '../../services/CoreValuesService';
import { TypedDispatch } from '..';
import { setCoreValues, setError, setIsLoading } from './coreValues';

export const fetchCoreValues = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const coreValuesResponse = await getCoreValuesApiCall();

        if (!isFetchResultSuccessful(coreValuesResponse)) {
            dispatch(setError(coreValuesResponse.error));
            return;
        }

        const coreValues = coreValuesResponse.data;

        dispatch(setCoreValues(coreValues));
    } catch (error) {
        console.error('[fetchCoreValues]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
