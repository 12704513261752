import React, { useEffect, useRef, useState } from 'react';

import { DateInput } from '../../../../../compositions';
import { formatDate } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { RoleType } from '../../../../../models/User';
import Button from '../../../../components/Button';
import BlockRadioList from '../../../../components/form/BlockRadioList/BlockRadioList';
import TextInput from '../../../../components/form/TextInput';
import { isEmptyObject } from '../../../../helpers/objectHelper';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading } from '../../../../styledComponents/components/Typography';
import genderOptions from '../../../constants/userDataOptions';
import { RawPersonData, validatePerson, ValidationErrors } from '../../../services/PersonService';
import EducationFormAssets from './EducationFormAssets';
import ZipCodeCheck from './ZipcodeCheck';

import './PersonDataForm.scss';

export enum EducationFormName {
    School = 'school_id',
    EducationLevel = 'education_level_id',
}

interface Props {
    person: any;
    loading: boolean;
    onSubmit: (data: RawPersonData) => void;
    errors?: ValidationErrors;
    disabledEducationFormFields?: EducationFormName[];
    userRole: RoleType;
}

const PersonDataForm = (props: Props) => {
    const {
        loading,
        person,
    } = props;

    const [errors, setValidationErrors] = useState<ValidationErrors>({});
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
    const [fieldsWithInput, setFieldsWithInput] = useState<RawPersonData>({
        gender: undefined,
        first_name: undefined,
        insertion: undefined,
        last_name: undefined,
        phone_number: undefined,
        avatar: undefined,
        video: undefined,
        address: undefined,
        street_number: undefined,
        extension: undefined,
        zipcode: undefined,
        latitude: undefined,
        longitude: undefined,
        city: undefined,
        province: undefined,
        school_experiences: undefined,
    });

    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(person?.birthday || null);

    const formElement = useRef<HTMLFormElement>(null);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const fieldsToSubmit: RawPersonData = {
            ...fieldsWithInput,
            birthday: dateOfBirth ? formatDate(dateOfBirth, 'yyyy-MM-dd') : undefined,
        };

        const validationErrors = validatePerson(RoleType.student, fieldsToSubmit, true);

        if (!isEmptyObject(validationErrors)) {
            setIsSubmittedOnce(true);
            setValidationErrors(validationErrors);
            return;
        }

        props.onSubmit(fieldsToSubmit);
    };

    const isKeyOfRawPersonData = (name: string): name is keyof RawPersonData => Object.keys(fieldsWithInput).includes(name);

    const setFieldAndValidate = (e: React.FormEvent<HTMLFormElement>) => {
        const newFieldsWithInput: RawPersonData = { ...fieldsWithInput };
        const { name } = e.currentTarget;

        if (!isKeyOfRawPersonData(name)) {
            return;
        }

        newFieldsWithInput[name] = e.currentTarget.value;
        setFieldsWithInput(newFieldsWithInput);

        const newValidationErrors = validatePerson(RoleType.student, newFieldsWithInput, isSubmittedOnce);
        setValidationErrors(newValidationErrors);
    };

    const setInputFieldValueFor = (name: string, value: any) => {
        const newFieldsWithInput: RawPersonData = { ...fieldsWithInput };

        if (!isKeyOfRawPersonData(name)) {
            return;
        }

        newFieldsWithInput[name] = value;
        setFieldsWithInput(newFieldsWithInput);
    };

    const onAddressChange = (addressData: any) => {
        const newFieldsWithInput: RawPersonData = {
            ...fieldsWithInput,
            zipcode: addressData.zipcode,
            address: addressData.address,
            street_number: addressData.street_number,
            extension: addressData.extension,
            latitude: addressData.latitude,
            longitude: addressData.longitude,
            city: addressData.city,
            province: addressData.province,
        };

        setFieldsWithInput(newFieldsWithInput);
    };

    const onEducationChange = (educationData: any) => {
        const newFieldsWithInput: RawPersonData = {
            ...fieldsWithInput,
            school_experiences: [educationData],
        };

        setFieldsWithInput(newFieldsWithInput);
    };

    const handleChangeDateOfBirth = (dateOfBirthValue: Date | null): void => {
        if (dateOfBirthValue instanceof Date || dateOfBirthValue === null) {
            setDateOfBirth(dateOfBirthValue);
        }
    };

    useEffect((): void => {
        if (props.errors) {
            setValidationErrors(props.errors);
        }
    }, [props.errors]);

    useEffect((): void => {
        if (person) {
            const { address, birthday, ...otherProps } = person;
            const newFieldsWithInput: RawPersonData = {
                ...fieldsWithInput,
                ...address,
                ...otherProps,
            };

            setDateOfBirth(birthday || null);
            setFieldsWithInput(newFieldsWithInput);
        }
    }, []);

    return (
        <>
            <Heading as="h2" size="xl">{trans('forms.user.account.heading')}</Heading>
            <form ref={formElement} id="student-data-form" onSubmit={onSubmit} className="person-data-form">
                <Row>
                    <Col mb="md" width={1}>
                        <TextInput
                            errors={errors.first_name}
                            id="first_name"
                            label={trans('forms.user.firstname.label')}
                            placeholder={trans('forms.user.firstname.placeholder')}
                            name="first_name"
                            onBlur={setFieldAndValidate}
                            onChange={(e: React.FormEvent<HTMLFormElement>) => setInputFieldValueFor('first_name', e.currentTarget.value)}
                            defaultValue={person && person.first_name}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col mb="md" width={2 / 8}>
                        <TextInput
                            errors={errors.insertion}
                            id="insertion"
                            label={trans('forms.user.insertion.label')}
                            placeholder={trans('forms.user.insertion.placeholder')}
                            name="insertion"
                            onBlur={setFieldAndValidate}
                            onChange={(e: React.FormEvent<HTMLFormElement>) => setInputFieldValueFor('insertion', e.currentTarget.value)}
                            defaultValue={person && person.insertion}
                        />
                    </Col>
                    <Col mb="md" width={6 / 8} pl="sm">
                        <TextInput
                            errors={errors.last_name}
                            id="last_name"
                            label={trans('forms.user.lastname.label')}
                            placeholder={trans('forms.user.lastname.placeholder')}
                            name="last_name"
                            onBlur={setFieldAndValidate}
                            onChange={(e: React.FormEvent<HTMLFormElement>) => setInputFieldValueFor('last_name', e.currentTarget.value)}
                            defaultValue={person && person.last_name}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col mb="md" width={1 / 2}>
                        <DateInput
                            disableCalendarPopup
                            optional
                            label={trans('forms.user.dayOfBirth.label')}
                            value={dateOfBirth}
                            maxDate={new Date()}
                            onChange={handleChangeDateOfBirth}
                            errors={errors.birthday}
                            labelClassName="person-data-form__label"
                        />
                    </Col>
                    <Col mb="md" width={1 / 2} pl="sm">
                        <BlockRadioList
                            id="gender"
                            errors={errors.gender}
                            options={genderOptions}
                            label={trans('forms.user.gender.label')}
                            name="gender"
                            selected={person && person.gender}
                            onChange={setFieldAndValidate}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col mb="md" width={1}>
                        <TextInput
                            errors={errors.phone_number}
                            id="phone_number"
                            label={trans('forms.phonenumber.label')}
                            placeholder={trans('forms.phonenumber.placeholder')}
                            name="phone_number"
                            formatOptions={{
                                phone: true,
                                phoneRegionCode: 'NL',
                            }}
                            onBlur={setFieldAndValidate}
                            onChange={(e: React.FormEvent<HTMLFormElement>) => setInputFieldValueFor('phone_number', e.currentTarget.value)}
                            defaultValue={person && person.phone_number}
                            optional
                        />
                    </Col>
                </Row>

                <ZipCodeCheck
                    userAddress={fieldsWithInput}
                    errors={errors}
                    onChange={onAddressChange}
                    onBlur={(e: React.FormEvent<HTMLFormElement>) => setFieldAndValidate(e)}
                />

                {props.userRole !== RoleType.jobSeeker && (
                    <EducationFormAssets
                        schoolExperience={person?.school_experiences?.length > 0 ? person.school_experiences[0] : null}
                        errors={errors}
                        userRole={props.userRole}
                        onChange={onEducationChange}
                        disabledFormNames={props.disabledEducationFormFields}
                    />
                )}

                <Row justifyContent="flex-end">
                    <Col>
                        <Button
                            id="jobseeker-data-submit"
                            isLoading={loading}
                            type="submit"
                        >
                            {trans('actions.save')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default PersonDataForm;
