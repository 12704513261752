import React from 'react';

import { Navigate } from 'react-router-dom';

import { JobVacancyIndex, JobVacancyOverview, VacancyDetail } from '../../pages';

export const jobVacancyRoutePaths = {
    jobs: () => '/baanvacatures',
    jobsQuery: (query = ':query') => `/baanvacatures/${query}`,
    altJobs: () => '/banen',
    resetJobs: () => '/herstel-baanvacatures',

    jobDetail: (vacancyUuid = ':vacancyUuid') => `/baanvacature/${vacancyUuid}`,
    jobDetailTab: (vacancyUuid = ':vacancyUuid', tab = ':tab') => `/baanvacature/${vacancyUuid}/${tab}`,

    jobVacancyIndex: () => '/alle-baanvacatures',
    altJobVacancyIndex: () => '/vacatures-per-categorie',
    jobVacancyIndexProvince: (province = ':province') => `/alle-baanvacatures/provincie/${province}`,
    jobVacancyIndexSector: (sector = ':sector', province = '') => `/alle-baanvacatures/sector/${sector}/${province}`,
    jobVacancyIndexOccupation: (letter = ':letter', occupation = '', province = '') => (province
        ? `/alle-baanvacatures/${letter}/${occupation}/${province}`
        : `/alle-baanvacatures/${letter}/${occupation}`),
};

export const jobVacancyRoutes = [
    {
        path: jobVacancyRoutePaths.jobs(),
        element: <JobVacancyOverview />,
        children: [
            { path: ':query', element: <JobVacancyOverview /> },
        ],
    },
    {
        path: jobVacancyRoutePaths.altJobs(),
        element: <Navigate to={jobVacancyRoutePaths.jobs()} replace />,
    },
    {
        path: jobVacancyRoutePaths.resetJobs(),
        element: <Navigate to={jobVacancyRoutePaths.jobs()} />,
    },
    {
        path: jobVacancyRoutePaths.jobDetail(),
        element: <VacancyDetail />,
        children: [
            { path: ':tab', element: <VacancyDetail /> },
        ],
    },
    {
        path: jobVacancyRoutePaths.jobVacancyIndex(),
        element: <JobVacancyIndex />,
        children: [
            {
                path: 'provincie/:province',
                element: <JobVacancyIndex />,
            },
            {
                path: 'sector/:sector',
                element: <JobVacancyIndex />,
                children: [
                    {
                        path: ':province',
                        element: <JobVacancyIndex />,
                    },
                ],
            },
            {
                path: ':letter',
                element: <JobVacancyIndex />,
                children: [
                    {
                        path: ':occupation',
                        element: <JobVacancyIndex />,
                        children: [
                            {
                                path: ':province',
                                element: <JobVacancyIndex />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: jobVacancyRoutePaths.altJobVacancyIndex(),
        element: <Navigate to={jobVacancyRoutePaths.jobVacancyIndex()} replace />,
    },
];
