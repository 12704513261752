import { isValidDateString } from '../helpers/date';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import {
    ApplicationAppointment,
    ApplicationAppointmentFormData,
    ApplicationAppointmentRequest,
    ApplicationAppointmentsResource,
    transformToApplicationAppointment,
    transformToApplicationAppointmentRequest,
} from './ApplicationAppointments';

export enum ApplicationInviteStatus {
    waiting = 'WAITING',
    accepted = 'ACCEPTED',
    rejected = 'REJECTED',
}

export interface ApplicationInvitesResource extends Resource {
    type: 'application-invites';
    id: string;
    attributes: {
        content: string;
        isFromApplicant: boolean;
        status: ApplicationInviteStatus;
        createdAt: string;
        updatedAt: string;
    };
    relationships: {
        appointments: MultipleRelationship<'application-appointments'>;
    };
}

export interface CreateApplicationInviteRequest {
    data: {
        type: 'application-invites';
        id?: string;
        attributes: {
            content: string;
            appointments: ApplicationAppointmentRequest[];
        };
        relationships: {
            application: SingleRelationship<'applications'>;
        };
    };
}

export interface DeclineApplicationInviteRequest {
    data: {
        type: 'application-invites';
        id: string;
        attributes: {
            status: ApplicationInviteStatus;
        };
    };
}

export interface ApplicationInvite {
    uuid: string;
    isFromApplicant: boolean;
    status: ApplicationInviteStatus;
    message: string;
    appointments: ApplicationAppointment[];
    createdAt: Date;
}

export const transformJapiDocumentToApplicationInvite = (
    doc: JapiDocument,
    resource: ApplicationInvitesResource,
): ApplicationInvite => {
    const appointmentResources = getMultipleIncluded<ApplicationAppointmentsResource>(doc, resource, 'appointments');

    // TODO: Maybe filter away appointment options that are in the past?
    const appointments = appointmentResources
        ? appointmentResources.map(transformToApplicationAppointment)
        : [];

    const createdAt = isValidDateString(resource.attributes.createdAt)
        ? new Date(resource.attributes.createdAt)
        : new Date();

    return {
        uuid: resource.id,
        isFromApplicant: resource.attributes.isFromApplicant,
        status: resource.attributes.status,
        message: resource.attributes.content,
        appointments,
        createdAt,
    };
};

export const transformToCreateApplicationInviteRequest = (formData: ApplicationAppointmentFormData, applicationUuid: string): CreateApplicationInviteRequest => ({
    data: {
        type: 'application-invites',
        attributes: {
            content: formData.message,
            appointments: formData.appointments.map(appointment => (
                transformToApplicationAppointmentRequest(formData.type, appointment)
            )),
        },
        relationships: {
            application: {
                data: {
                    id: applicationUuid,
                    type: 'applications',
                },
            },
        },
    },
});

export const transformToDeclineApplicationInviteRequest = (applicationInvite: ApplicationInvite): DeclineApplicationInviteRequest => ({
    data: {
        type: 'application-invites',
        id: applicationInvite.uuid,
        attributes: {
            status: ApplicationInviteStatus.rejected,
        },
    },
});
