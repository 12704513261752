import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { Province } from '../../../../models/Provinces';
import { Sector } from '../../../../models/Sectors';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexSectorCity.scss';

interface VacancyIndexSectorCityProps {
    isLoading: boolean;
    vacancyType: VacancyType;
    sector: Sector;
    province: Province;
    cityOptionColumns: string[][];
    className?: string;
}

const VacancyIndexSectorCity: FC<VacancyIndexSectorCityProps> = ({
    isLoading,
    vacancyType,
    sector,
    province,
    cityOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.sector.city', {
            sector: sector.name,
            province: province.name,
        })}
        className={`vacancy-index-sector-city ${className}`}
    >
        <VacancyIndexColumnList>
            {cityOptionColumns.map(column => {
                const columnIndex = column.join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-sector-city__city-list">
                            {column.map(city => {
                                const params = new URLSearchParams();
                                params.set('sector', sector.slug);

                                const searchQuery = encodeSearchQuery({
                                    province: province.slug,
                                    where: city,
                                });

                                const route = vacancyType === VacancyType.internship
                                    ? `${RoutePaths.internshipsQuery(searchQuery)}?${String(params)}`
                                    : `${RoutePaths.jobsQuery(searchQuery)}?${String(params)}`;

                                return (
                                    <li key={city} className="vacancy-index-sector-city__city">
                                        <VacancyIndexLink to={route} label={city} />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexSectorCity;
