// Do not replace values of enums, these come from the back-end
export enum RoleType {
    student = 'student',
    jobSeeker = 'job_seeker',
    company = 'employee',
    none = 'none',
}

export enum UserRole {
    student = 'ROLE_STUDENT',
    jobSeeker = 'ROLE_JOB_SEEKER',
    company = 'ROLE_EMPLOYEE',
    none = 'ROLE_NONE',
}
