import React, { FC, ReactElement } from 'react';

import { trans } from '../../../helpers/trans';
import { FormOption } from '../../../types';
import { RadioList } from '../..';

import './EducationLevelSelector.scss';

interface EducationLevelSelectorFormErrors {
    educationLevel?: string;
    educationSubLevel?: string;
    learningPath?: string;
}

interface EducationLevelSelectorProps {
    maySelectOtherEducationLevel?: boolean;
    educationLevelLabel?: string;
    educationLevel: string;
    educationSubLevel?: string;
    learningPath?: string;
    educationLevelOptions: FormOption[];
    educationSubLevelOptions: FormOption[];
    learningPathOptions?: FormOption[];
    formErrors?: EducationLevelSelectorFormErrors;
    subLevelsIsRequired?: boolean;
    otherOptionLabel?: string;
    disabled?: boolean;
    onEducationLevelChange: (educationLevel: FormOption) => void;
    onEducationSubLevelChange: (educationSubLevel?: FormOption) => void;
    onLearningPathChange?: (learningPath?: FormOption) => void;
    className?: string;
}

const EducationLevelSelector: FC<EducationLevelSelectorProps> = ({
    maySelectOtherEducationLevel = true,
    educationLevelLabel = trans('forms.educationLevelSelector.educationLevel'),
    educationLevel,
    educationSubLevel,
    learningPath,
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions = [],
    formErrors = {},
    subLevelsIsRequired = true,
    otherOptionLabel,
    disabled,
    onEducationLevelChange,
    onEducationSubLevelChange,
    onLearningPathChange,
    className = '',
}): ReactElement => {
    const handleEducationLevelChange = (valueId: string): void => {
        const label = educationLevelOptions.find(level => level.value === valueId)?.label;

        onEducationLevelChange({
            label: label || '',
            value: valueId,
        });

        onEducationSubLevelChange(undefined);
        onLearningPathChange && onLearningPathChange(undefined);
    };

    const handleEducationSubLevelChange = (valueId: string): void => {
        const educationSubLevelLabel = educationSubLevelOptions.find(subLevel => subLevel.value === valueId)?.label;

        onEducationSubLevelChange({
            label: educationSubLevelLabel || '',
            value: valueId,
        });
    };

    const handleLearningPathChange = (valueId: string): void => {
        if (onLearningPathChange) {
            const learningPathLabel = learningPathOptions.find(path => path.value === valueId)?.label;

            onLearningPathChange({
                label: learningPathLabel || '',
                value: valueId,
            });
        }
    };

    return (
        <div className={`education-level-selector ${className}`}>
            <RadioList
                maySelectOther={maySelectOtherEducationLevel}
                otherOptionLabel={otherOptionLabel}
                label={educationLevelLabel}
                name="education-level"
                required
                options={educationLevelOptions}
                value={educationLevel}
                disabled={disabled}
                error={formErrors.educationLevel}
                onChange={handleEducationLevelChange}
                className="education-level-selector__input"
            />

            {educationSubLevelOptions.length > 0 && (
                <RadioList
                    label={trans('forms.educationLevelSelector.educationSubLevel')}
                    name="education-sub-level"
                    required={subLevelsIsRequired}
                    optional={!subLevelsIsRequired}
                    options={educationSubLevelOptions}
                    value={educationSubLevel || ''}
                    disabled={disabled}
                    error={formErrors.educationSubLevel}
                    onChange={handleEducationSubLevelChange}
                    className="education-level-selector__input"
                />
            )}

            {learningPathOptions.length > 0 && (
                <RadioList
                    maySelectOther={maySelectOtherEducationLevel}
                    label={trans('forms.educationLevelSelector.learningPath')}
                    name="learning-path"
                    required
                    options={learningPathOptions}
                    value={learningPath || ''}
                    disabled={disabled}
                    error={formErrors.learningPath}
                    onChange={handleLearningPathChange}
                    className="education-level-selector__input"
                />
            )}
        </div>
    );
};

export default EducationLevelSelector;
