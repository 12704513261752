import styled, { css } from 'styled-components';
import { space } from 'styled-system';

export default styled('span')(props => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${props.theme.colors.muted};
    
    &:before, &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.muted};
    }
    
    ${!props.noText && css`
        &:before {
            margin-right: ${props.theme.space.sm};
        }
        
        &:after {
            margin-left: ${props.theme.space.sm};
        }
    `}
    
    ${space}
`);
