import React, { FC, ReactElement } from 'react';

import { Card } from '../../components';
import { trans } from '../../helpers/trans';
import { CompetenciesFormData, Competency } from '../../models/Competencies';
import { EscoOccupation } from '../../models/Esco';
import { Skill, SkillsFormData } from '../../models/Skills';
import { JobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { TalentMatchingCompetencies, TalentMatchingSkills } from './subcomponents';

import './TalentMatchingCard.scss';

interface JobTalentMatchingCardProps {
    isLoading: boolean;
    isValidCandidate: boolean;
    activeFilterValues: JobVacancyOverviewFilterValues;
    skillOptions: Skill[];
    skillsError: string;
    competencyOptions: Competency[];
    occupationOptions: EscoOccupation[];
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onSubmitSkills: (skillsFormData: SkillsFormData) => void;
    onSubmitSkillsToProfile: (skillsFormData: SkillsFormData) => void;
    onSubmitCompetencies: (competenciesFormData: CompetenciesFormData) => void;
    onSubmitCompetenciesToProfile: (competenciesFormData: CompetenciesFormData) => void;
    className?: string;
}

const JobTalentMatchingCard: FC<JobTalentMatchingCardProps> = ({
    isLoading,
    isValidCandidate,
    activeFilterValues,
    skillOptions,
    skillsError,
    competencyOptions,
    occupationOptions,
    onSelectOccupation,
    onSubmitSkills,
    onSubmitSkillsToProfile,
    onSubmitCompetencies,
    onSubmitCompetenciesToProfile,
    className = '',
}): ReactElement => {
    const sharedProperties = {
        isLoading,
        isValidCandidate,
        occupationOptions,
        onSelectOccupation,
    };

    const skillsProperties = {
        ...sharedProperties,
        skillOptions,
        skillsError,
    };

    const competenciesProperties = {
        ...sharedProperties,
        competencyOptions,
    };

    const skills = skillOptions.filter(option => activeFilterValues.skills.includes(option.id));
    const competencies = competencyOptions.filter(option => activeFilterValues.competencies.includes(option.id));

    return (
        <Card className={`talent-matching-card ${className}`}>
            <h2 className="talent-matching-card__title">
                {trans('vacancies.overview.talentMatch.title')}
            </h2>

            <p className="talent-matching-card__description">
                {trans('vacancies.overview.talentMatch.description')}
            </p>

            <div className="talent-matching-card__wrapper">
                <TalentMatchingSkills
                    {...skillsProperties}
                    value={skills}
                    onSubmitToProfile={onSubmitSkillsToProfile}
                    onSubmit={onSubmitSkills}
                    className="talent-matching-card__button"
                />

                <TalentMatchingCompetencies
                    {...competenciesProperties}
                    value={competencies}
                    onSubmitToProfile={onSubmitCompetenciesToProfile}
                    onSubmit={onSubmitCompetencies}
                    className="talent-matching-card__button"
                />
            </div>
        </Card>
    );
};

export default JobTalentMatchingCard;
