import { maxLongTextLength } from '../../../../constants';
import { AboutFormData } from '../../../../models/About';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMaxStringLength,
} from '../../../../services/ValidationService';

export type AboutFormErrors = FormErrors<AboutFormData>;

export const validateAboutFormData = (
    formData: AboutFormData,
): FormValidation<AboutFormErrors> => {
    const errors: AboutFormErrors = {
        description: validateMaxStringLength('content', formData.description, maxLongTextLength),
    };

    return validateForm<AboutFormErrors>(errors);
};
