import React, {
    FC,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useToggle } from 'react-use';

import { LinkButton } from '../../../../components';
import {
    ExpandableNavigation,
    IconButton,
    Modal,
    ModalContent,
} from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import useHandleClickOutside from '../../../../hooks/useHandleClickOutside';
import { Chat } from '../../../../models/Chat';
import { ChatReport, ChatReportFormData, UndoChatReportFormData } from '../../../../models/ChatReport';
import { RoutePaths } from '../../../../routes';
import { Control } from '../../../../types';
import { ChatReportForm, UndoChatReportForm } from '../../..';
import { retrieveInterlocutor } from '../../helpers';
import { ChatRoomHeaderSkeletons } from '../../skeletons';

import './ChatRoomHeader.scss';

interface ChatRoomHeaderProps {
    isLoading: boolean;
    chatReportsIsLoading: boolean;
    isCandidate: boolean;
    activeChat: Chat;
    chatReports: ChatReport[];
    onChatSelectorClick: () => void;
    onReportChat: (chatUuid: string, chatReportFormData: ChatReportFormData) => void;
    onUndoChatReport: (undoChatReportFormData: UndoChatReportFormData) => void;
    className?: string;
}

const ChatRoomHeader: FC<ChatRoomHeaderProps> = ({
    isLoading,
    chatReportsIsLoading,
    isCandidate,
    activeChat,
    chatReports,
    onChatSelectorClick,
    onReportChat,
    onUndoChatReport,
    className = '',
}): ReactElement => {
    const [chatActionsIsExpanded, toggleChatActions] = useToggle(false);
    const [reportModalIsOpen, setReportModalIsOpen] = useState<boolean>(false);
    const [undoReportModalIsOpen, setUndoReportModalIsOpen] = useState<boolean>(false);

    const closeChatActions = (): void => toggleChatActions(false);

    const chatActionsRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(chatActionsRef, closeChatActions);

    const openReportModal = (): void => setReportModalIsOpen(true);
    const closeReportModal = (): void => setReportModalIsOpen(false);

    const openUndoReportModal = (): void => setUndoReportModalIsOpen(true);
    const closeUndoReportModal = (): void => setUndoReportModalIsOpen(false);

    const activeChatReportsByUser = useMemo<ChatReport[]>(() => (
        chatReports.filter(chatReport => chatReport.chatUuid === activeChat.uuid)
    ), [chatReports, activeChat]);

    const chatActions = useMemo<Control[]>(() => {
        const { isReported } = activeChat;

        const reportChat = {
            key: 'report',
            label: trans('containers.messageOverview.chatActions.report'),
            icon: 'circle-exclamation',
            disabled: isReported,
            onClick: openReportModal,
        };

        const undoChatReport = {
            key: 'undo-report',
            label: trans('containers.messageOverview.chatActions.undoReport'),
            icon: 'refresh',
            onClick: openUndoReportModal,
        };

        return activeChatReportsByUser.length > 0
            ? [reportChat, undoChatReport]
            : [reportChat];
    }, [activeChat, activeChatReportsByUser]);

    const interlocutor = retrieveInterlocutor(activeChat, isCandidate);

    const handleReportChat = (chatReportFormData: ChatReportFormData): void => {
        onReportChat(activeChat.uuid, chatReportFormData);
        closeReportModal();
    };

    const handleUndoChatReport = (undoChatReportFormData: UndoChatReportFormData): void => {
        onUndoChatReport(undoChatReportFormData);
        closeUndoReportModal();
    };

    return (
        <header className={`chat-room-header ${className}`}>
            <IconButton
                icon="chevron-left"
                text={trans('containers.messageOverview.button.chatSelector')}
                hideLabel
                disabled={isLoading}
                onClick={onChatSelectorClick}
                className="chat-room-header__chat-selector-button"
            />

            {isLoading ? (
                <ChatRoomHeaderSkeletons
                    className="chat-room-header__skeletons"
                />
            ) : (
                <div className="chat-room-header__title-wrapper">
                    <h1 className="chat-room-header__title">
                        {interlocutor.fullName}
                    </h1>

                    {isCandidate ? (
                        <LinkButton
                            to={RoutePaths.companyProfile(activeChat.company.uuid)}
                            className="chat-room-header__profile-link"
                        >
                            {trans('containers.messageOverview.button.companyProfile')}
                        </LinkButton>
                    ) : (
                        <LinkButton
                            to={RoutePaths.candidateProfile(activeChat.candidate.uuid)}
                            className="chat-room-header__profile-link"
                        >
                            {trans('containers.messageOverview.button.candidateProfile')}
                        </LinkButton>
                    )}
                </div>
            )}

            <div ref={chatActionsRef}>
                <IconButton
                    icon="vertical-ellipsis"
                    iconPos="right"
                    text={trans('containers.messageOverview.chatActions.actions')}
                    disabled={isLoading || chatReportsIsLoading}
                    onClick={toggleChatActions}
                    className="chat-room-header__actions-button"
                />

                <ExpandableNavigation
                    isExpanded={chatActionsIsExpanded}
                    name={trans('containers.messageOverview.chatActions.ariaLabel')}
                    navigationItems={chatActions}
                    onClose={closeChatActions}
                    className="chat-room-header__actions-navigation"
                />
            </div>

            {reportModalIsOpen && (
                <Modal onClose={closeReportModal}>
                    <ModalContent title={trans('containers.messageOverview.reportModal.title')}>
                        <ChatReportForm
                            onSubmit={handleReportChat}
                            onCancel={closeReportModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {undoReportModalIsOpen && activeChatReportsByUser.length > 0 && (
                <Modal onClose={closeUndoReportModal}>
                    <ModalContent title={trans('containers.messageOverview.undoReportModal.title')}>
                        <UndoChatReportForm
                            activeChatReport={activeChatReportsByUser[0]}
                            onSubmit={handleUndoChatReport}
                            onCancel={closeUndoReportModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </header>
    );
};

export default ChatRoomHeader;
