import React from 'react';

import { Navigate } from 'react-router-dom';

import PasswordForgetComplete from '../../_old/app_talentz/app/containers/PasswordForgetComplete';
import RegisterUserSwitch from '../../_old/app_talentz/app/containers/RegisterUser/RegisterUserSwitch';
import AboutUsPage from '../../_old/app_talentz/app/pages/AboutUsPage/AboutUsPage';
import FaqPage from '../../_old/app_talentz/app/pages/FaqPage/FaqPage';
import LLOLoginPage from '../../_old/app_talentz/app/pages/LLOLoginPage/LLOLoginPage';
import PrivacyStatement from '../../_old/app_talentz/app/pages/PrivacyStatementPage/PrivacyStatementPage';
import TermsAndConditions from '../../_old/app_talentz/app/pages/TermsAndConditionsPage/TermsAndConditionsPage';
import {
    CompanyProfile,
    ConfirmEmailAddressChange,
    CookieSettings,
    Home,
    RegisterCompany,
    RegistrationPage,
} from '../../pages';

export const generalRoutePaths = {
    home: () => '/',
    registration: () => '/registreren',
    registrationSocialMedia: (platform = ':socialMedia') => `/registreren/${platform}`,
    altRegistration: () => '/aanmelden',
    registrationCompany: () => '/registreren-als-bedrijf',
    resetPassword: () => '/wachtwoord-herstellen',
    altResetPassword: () => '/wachtwoord-vergeten',
    llo: () => '/llo',
    aboutUs: () => '/over-ons',
    faq: () => '/faq',
    cookieSettings: () => '/cookie-instellingen',
    privacyStatement: () => '/privacy-statement',
    termsAndConditions: () => '/algemene-voorwaarden',
    companyProfile: (companyUuid = ':companyUuid') => `/bedrijf/profiel/${companyUuid}`,
    confirmEmailAddressChange: (confirmationKey = ':confirmationKey') => `/account/e-mailadres-wijzigen/${confirmationKey}`,
};

export const generalRoutes = [
    {
        path: generalRoutePaths.home(),
        element: <Home />,
    },
    {
        path: generalRoutePaths.altRegistration(),
        element: <RegistrationPage />,
    },
    {
        path: generalRoutePaths.registration(),
        element: <RegisterUserSwitch />,
        children: [
            {
                path: ':socialMedia',
                element: <RegisterUserSwitch />,
            },
        ],
    },
    {
        path: generalRoutePaths.registrationCompany(),
        element: <RegisterCompany />,
    },
    {
        path: generalRoutePaths.resetPassword(),
        element: <PasswordForgetComplete />,
    },
    {
        path: generalRoutePaths.altResetPassword(),
        element: <Navigate to={generalRoutePaths.resetPassword()} replace />,
    },
    {
        path: generalRoutePaths.llo(),
        element: <LLOLoginPage />,
    },
    {
        path: generalRoutePaths.aboutUs(),
        element: <AboutUsPage />,
    },
    {
        path: generalRoutePaths.faq(),
        element: <FaqPage />,
    },
    {
        path: generalRoutePaths.cookieSettings(),
        element: <CookieSettings />,
    },
    {
        path: generalRoutePaths.privacyStatement(),
        element: <PrivacyStatement />,
    },
    {
        path: generalRoutePaths.termsAndConditions(),
        element: <TermsAndConditions />,
    },
    {
        path: generalRoutePaths.companyProfile(),
        element: <CompanyProfile />,
    },
    {
        path: generalRoutePaths.confirmEmailAddressChange(),
        element: <ConfirmEmailAddressChange />,
    },
];
