import React, { FC, ReactElement, useEffect } from 'react';

import { JobTalentMatchingCard } from '../../containers';
import { Candidate } from '../../models/Candidate';
import { CompetenciesFormData } from '../../models/Competencies';
import { EscoOccupation } from '../../models/Esco';
import { SkillsFormData } from '../../models/Skills';
import { RoleType } from '../../models/User';
import { JobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { patchCandidate } from '../../store/candidate/candidateActions';
import { fetchCompetencies } from '../../store/competencies/competenciesActions';
import {
    clearEscoSuggestions,
    fetchEscoOccupations,
    fetchSuggestedCompetencies,
    fetchSuggestedSkills,
} from '../../store/escoOccupations/escoOccupationsActions';
import { fetchEscoSkills } from '../../store/escoSkills/escoSkillsActions';
import { setActiveFilterValues } from '../../store/jobVacancyOverview/jobVacancyOverview';
import { fetchSkills } from '../../store/skills/skillsActions';

interface ConnectedJobTalentMatchingCardProps {
    isTalentMatching: boolean;
    className?: string;
}

const ConnectedJobTalentMatchingCard: FC<ConnectedJobTalentMatchingCardProps> = ({
    isTalentMatching,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const role = useLegacySelector(state => state.legacyUser.role);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isLoading = useTypedSelector(state => state.jobVacancyOverviewReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.jobVacancyOverviewReducer.activeFilterValues);

    const skillOptions = useTypedSelector(state => state.skillsReducer.skills);
    const skillsError = useTypedSelector(state => state.skillsReducer.error);
    const escoSkillOptions = useTypedSelector(state => state.escoSkillsReducer.escoSkills);
    const escoSkillsError = useTypedSelector(state => state.escoSkillsReducer.error);
    const competencyOptions = useTypedSelector(state => state.competenciesReducer.competencies);
    const escoOccupationOptions = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);

    const suggestedSkills = useTypedSelector(state => state.escoOccupationsReducer.suggestedSkills);
    const suggestedCompetencies = useTypedSelector(state => state.escoOccupationsReducer.suggestedCompetencies);

    const userRole = role || RoleType.none;
    const userIsCandidate = userRole === RoleType.student || userRole === RoleType.jobSeeker;
    const isValidCandidate = userIsCandidate && !!candidate && isAuthenticated;

    useEffect((): () => void => {
        if (skillOptions.length === 0) dispatch(fetchSkills());
        if (escoSkillOptions.length === 0) dispatch(fetchEscoSkills());
        if (competencyOptions.length === 0) dispatch(fetchCompetencies());
        if (escoOccupationOptions.length === 0) dispatch(fetchEscoOccupations());

        return (): void => {
            dispatch(setActiveFilterValues({
                ...activeFilterValues,
                skills: [],
                competencies: [],
            }));

            dispatch(clearEscoSuggestions());
        };
    }, []);

    useEffect((): void => {
        if (!isTalentMatching || !candidate) return;

        const combinedSkills = [...candidate.skills, ...candidate.escoSkills];

        dispatch(setActiveFilterValues({
            ...activeFilterValues,
            skills: combinedSkills.map(skill => skill.id),
            competencies: candidate.competencies.map(competency => competency.id),
        }));
    }, [dispatch, isTalentMatching, candidate]);

    useEffect((): void => {
        if (!isTalentMatching) return;

        dispatch(setActiveFilterValues({
            ...activeFilterValues,
            skills: suggestedSkills.map(skill => skill.id),
            competencies: suggestedCompetencies.map(competency => competency.id),
        }));
    }, [suggestedSkills, suggestedCompetencies]);

    const updateCandidate = (updatedCandidate: Partial<Candidate>): void => {
        if (candidate) {
            dispatch(patchCandidate({
                uuid: candidate.uuid,
                ...updatedCandidate,
            }));
        }
    };

    const updateFilters = (updatedFilters: Partial<JobVacancyOverviewFilterValues>): void => {
        dispatch(setActiveFilterValues({
            ...activeFilterValues,
            ...updatedFilters,
            pageNumber: 1,
        }));
    };

    const handleSelectOccupation = (occupation: EscoOccupation): void => {
        dispatch(fetchSuggestedSkills(occupation.id));
        dispatch(fetchSuggestedCompetencies(occupation.id));
    };

    const handleSubmitSkills = (skillsFormData: SkillsFormData): void => {
        const combinedSkills = [...skillsFormData.skills, ...skillsFormData.escoSkills];
        const skills = combinedSkills.map(skill => skill.id);

        updateFilters({ skills });
    };

    const handleSubmitSkillsToProfile = (skillsFormData: SkillsFormData): void => {
        updateCandidate({
            skills: skillsFormData.skills,
            escoSkills: skillsFormData.escoSkills,
        });
    };

    const handleSubmitCompetencies = (competenciesFormData: CompetenciesFormData): void => {
        const competencies = competenciesFormData.competencies.map(competency => competency.id);

        updateFilters({ competencies });
    };

    const handleSubmitCompetenciesToProfile = (competenciesFormData: CompetenciesFormData): void => {
        updateCandidate({
            competencies: competenciesFormData.competencies,
        });
    };

    return (
        <JobTalentMatchingCard
            isLoading={isLoading}
            isValidCandidate={isValidCandidate}
            activeFilterValues={activeFilterValues}
            skillOptions={[...skillOptions, ...escoSkillOptions]}
            skillsError={skillsError || escoSkillsError}
            competencyOptions={competencyOptions}
            occupationOptions={escoOccupationOptions}
            onSelectOccupation={handleSelectOccupation}
            onSubmitSkills={handleSubmitSkills}
            onSubmitSkillsToProfile={handleSubmitSkillsToProfile}
            onSubmitCompetencies={handleSubmitCompetencies}
            onSubmitCompetenciesToProfile={handleSubmitCompetenciesToProfile}
            className={className}
        />
    );
};

export default ConnectedJobTalentMatchingCard;
