import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { logIn } from '../../_old/app_talentz/store/legacyUser/legacyUserActions';
import { setStep as setRegisterStep, setUserType } from '../../_old/app_talentz/store/register/register';
import { setStep as setResetPasswordStep } from '../../_old/app_talentz/store/resetPassword/resetPassword';
import { VacancyDetail, VacancyDetailError } from '../../containers';
import { ApplicationFormData, transformToApplicationVacancyIds } from '../../models/Applications';
import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { clearApplicationIsSuccessful, createApplication } from '../../store/application/applicationActions';
import { fetchApplications } from '../../store/applications/applicationsActions';
import { patchFavourites } from '../../store/candidate/candidateActions';
import { clearCompany, fetchCompany } from '../../store/company/companyActions';
import { fetchCompetencies } from '../../store/competencies/competenciesActions';
import { fetchEscoSkills } from '../../store/escoSkills/escoSkillsActions';
import { archiveVacancy, deleteVacancy, unarchiveVacancy } from '../../store/myVacancies/myVacanciesActions';
import { clearRelatedVacancies, fetchRelatedVacancyOverview } from '../../store/relatedVacancyOverview/relatedVacancyOverviewActions';
import { fetchSkills } from '../../store/skills/skillsActions';
import { clearVacancyApplications, fetchVacancyApplications } from '../../store/vacancyApplications/vacancyApplicationsActions';
import { clearVacancy, fetchVacancy } from '../../store/vacancyDetail/vacancyDetailActions';

interface ConnectedVacancyDetailProps {
    vacancyUuid?: string;
    className?: string;
}

const ConnectedVacancyDetail: FC<ConnectedVacancyDetailProps> = ({
    vacancyUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    // TODO: Refactor person and apply new Person to this file and it's children
    const person = useLegacySelector(state => state.userPerson.data);
    const authIsLoading = useLegacySelector(state => state.legacyUser.loading);
    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const role = useLegacySelector(state => state.legacyUser.role);

    const vacancyIsLoading = useTypedSelector(state => state.vacancyDetailReducer.isLoading);
    const vacancy = useTypedSelector(state => state.vacancyDetailReducer.vacancy);
    const vacancyStatusCode = useTypedSelector(state => state.vacancyDetailReducer.statusCode);

    const archiveApplicationsIsLoading = useTypedSelector(state => state.vacancyApplicationsReducer.isLoading);
    const archiveApplications = useTypedSelector(state => state.vacancyApplicationsReducer.applications);

    const companyIsLoading = useTypedSelector(state => state.companyReducer.isLoading);
    const company = useTypedSelector(state => state.companyReducer.company);

    const relatedVacanciesIsLoading = useTypedSelector(state => state.relatedVacancyOverviewReducer.isLoading);
    const relatedVacanciesError = useTypedSelector(state => state.relatedVacancyOverviewReducer.error);
    const relatedVacancies = useTypedSelector(state => state.relatedVacancyOverviewReducer.vacancies);

    const skills = useTypedSelector(state => state.skillsReducer.skills);
    const escoSkills = useTypedSelector(state => state.escoSkillsReducer.escoSkills);
    const competencies = useTypedSelector(state => state.competenciesReducer.competencies);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const applicationFormIsLoading = useTypedSelector(state => state.applicationReducer.isLoading);
    const applicationFormIsSuccessful = useTypedSelector(state => state.applicationReducer.isSuccessful);

    const candidateApplicationsIsLoading = useTypedSelector(state => state.applicationsReducer.isLoading);
    const candidateApplications = useTypedSelector(state => state.applicationsReducer.applications);

    const [isFavourite, setIsFavourite] = useState<boolean>(false);
    const [favouriteIds, setFavouriteIds] = useState<string[]>([]);

    const isLoading = companyIsLoading || vacancyIsLoading || candidateApplicationsIsLoading;
    const isValidUser = isAuthenticated && !!person;
    const userRole = role || RoleType.none;

    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));

    useEffect((): () => void => {
        if (vacancyUuid) {
            dispatch(fetchVacancy(vacancyUuid));

            if (skills.length === 0) dispatch(fetchSkills());
            if (escoSkills.length === 0) dispatch(fetchEscoSkills());
            if (competencies.length === 0) dispatch(fetchCompetencies());
        }

        // Clear vacancy data from store when component unmounts
        return (): void => {
            dispatch(clearVacancy());
            dispatch(clearCompany());
            dispatch(clearRelatedVacancies());
        };
    }, [dispatch, vacancyUuid]);

    useEffect((): void => {
        // TODO: Find a way to check if user is from company without vacancy data (ask back-end)
        // userFromCompany will fetch from 'api', which won't return a 410 archive error
        if (userFromCompany && vacancyUuid) {
            fetchVacancy(vacancyUuid, userFromCompany);
        }
    }, [dispatch, person, company, userFromCompany]);

    useEffect((): void => {
        if (vacancy) {
            dispatch(fetchCompany(vacancy.company.uuid));
        }

        if (userRole === RoleType.student || userRole === RoleType.jobSeeker) {
            dispatch(fetchApplications());
        }
    }, [dispatch, vacancy, userRole]);

    useEffect((): void => {
        const hasAllOptions = [skills, escoSkills, competencies].every(options => options.length > 0);

        if (vacancy && hasAllOptions) {
            dispatch(fetchRelatedVacancyOverview({
                vacancy,
                skillOptions: [...skills, ...escoSkills],
                competencyOptions: competencies,
            }));
        }
    }, [dispatch, vacancy, skills, escoSkills, competencies]);

    useEffect((): void => {
        if (vacancy && candidate) {
            const favouriteIdList = candidate.favourites.map(favourite => favourite.uuid);

            setFavouriteIds(favouriteIdList);
            setIsFavourite(favouriteIdList.includes(vacancy.uuid));
        }
    }, [vacancy, candidate]);

    useEffect((): void => {
        if (applicationFormIsSuccessful) {
            dispatch(clearApplicationIsSuccessful());
        }
    }, [applicationFormIsSuccessful]);

    const handleFavouriteClick = (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean): void => {
        if (candidate) {
            const newFavourites = shouldAddFavourite
                ? [...candidate.favourites, favouriteVacancy]
                : candidate.favourites.filter(favourite => favourite.uuid !== favouriteVacancy.uuid);

            dispatch(patchFavourites(candidate.uuid, newFavourites));
        }
    };

    const handleFetchArchiveApplications = (uuid: string): void => {
        dispatch(fetchVacancyApplications(uuid));
    };

    const handleClearArchiveApplications = (): void => {
        dispatch(clearVacancyApplications());
    };

    const handleArchiveVacancy = async (uuid: string, applicationUuids: string[]): Promise<void> => {
        await dispatch(archiveVacancy(uuid, applicationUuids));
        dispatch(fetchVacancy(uuid, userFromCompany));
    };

    const handleUnarchiveVacancy = async (uuid: string): Promise<void> => {
        await dispatch(unarchiveVacancy(uuid));
        dispatch(fetchVacancy(uuid, userFromCompany));
    };

    const handleDeleteVacancy = async (uuid: string): Promise<void> => {
        await dispatch(deleteVacancy(uuid));
        dispatch(fetchVacancy(uuid, userFromCompany));
    };

    const handleSubmitApplication = async (applicationFormData: ApplicationFormData): Promise<void> => {
        await dispatch(createApplication(applicationFormData));
        dispatch(fetchApplications());
    };

    const handleRegistrationClick = (userType: RoleType): void => {
        dispatch(logIn(false));
        dispatch(setResetPasswordStep(null));
        dispatch(setUserType(userType));
        dispatch(setResetPasswordStep(1));
    };

    const handleLoginClick = (): void => {
        dispatch(setResetPasswordStep(null));
        dispatch(setRegisterStep(null));
        dispatch(logIn(true));
    };

    if (vacancyStatusCode) {
        return (
            <VacancyDetailError
                userFromCompany={userFromCompany}
                statusCode={vacancyStatusCode}
            />
        );
    }

    return (
        <VacancyDetail
            isLoading={isLoading}
            authIsLoading={authIsLoading}
            isValidUser={isValidUser}
            isSbbUser={!!person?.is_sbb_user}
            candidate={candidate}
            userRole={userRole}
            userFromCompany={userFromCompany}
            vacancy={vacancy}
            company={company}
            //
            relatedVacancies={relatedVacancies}
            relatedVacanciesIsLoading={relatedVacanciesIsLoading}
            relatedVacanciesError={relatedVacanciesError}
            //
            candidateApplications={candidateApplications.map(transformToApplicationVacancyIds)}
            applicationFormIsLoading={applicationFormIsLoading}
            applicationFormSentSuccessfully={applicationFormIsSuccessful}
            onSubmitApplication={handleSubmitApplication}
            //
            favouriteIsLoading={candidateIsLoading}
            isFavourite={isFavourite}
            favouriteIds={favouriteIds}
            onFavouriteVacancy={handleFavouriteClick}
            archiveApplications={archiveApplications}
            archiveApplicationsIsLoading={archiveApplicationsIsLoading}
            onFetchArchiveApplications={handleFetchArchiveApplications}
            onClearArchiveApplications={handleClearArchiveApplications}
            onArchiveVacancy={handleArchiveVacancy}
            onUnarchiveVacancy={handleUnarchiveVacancy}
            onDeleteVacancy={handleDeleteVacancy}
            //
            onRegistrationClick={handleRegistrationClick}
            onLoginClick={handleLoginClick}
            className={className}
        />
    );
};

export default ConnectedVacancyDetail;
