import { transformPrismicDocumentToBlog, transformPrismicSearchResponseToBlogOverviewInfo } from '../../models/Blog';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { PrismicType } from '../../models/Prismic';
import { getPrismicCategoriesApiCall, getPrismicOverviewApiCall } from '../../services/PrismicService';
import { TypedDispatch } from '..';
import { setContent } from '../blogDetail/blogDetail';
import {
    setBlogArticles,
    setCategories,
    setCategorySizes,
    setIsLoading,
    setTotalPages,
    setTotalResults,
} from './blog';

interface BlogOverviewConfig {
    category: string;
    page: number;
}

export const fetchBlogCategories = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setCategories([]));

    try {
        const blogCategoriesResponse = await getPrismicCategoriesApiCall();

        if (!isFetchResultSuccessful(blogCategoriesResponse)) {
            console.error('[fetchBlogCategories]', blogCategoriesResponse.error);
            return;
        }

        const categories = blogCategoriesResponse.data;

        dispatch(setCategories(categories));
    } catch (error) {
        console.error('[fetchBlogCategories]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchBlogCategorySizes = (categories: string[]) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setCategorySizes({}));

    try {
        const categorySizesList = await Promise.all(categories.map(async category => {
            const categorySizeResponse = await getPrismicOverviewApiCall({
                type: PrismicType.blog,
                page: 1,
                pageSize: 1,
                category,
            });

            if (!isFetchResultSuccessful(categorySizeResponse)) {
                throw Error(categorySizeResponse.error);
            }

            return {
                name: category,
                blogAmount: categorySizeResponse.data.total_results_size,
            };
        }));

        const categorySizes = categorySizesList.reduce((object, category) => ({
            ...object,
            [category.name]: category.blogAmount,
        }), {});

        dispatch(setCategorySizes(categorySizes));
    } catch (error) {
        console.error('[fetchBlogCategorySizes]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchBlogOverview = (config: BlogOverviewConfig) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setTotalPages(0));
    dispatch(setTotalResults(0));
    dispatch(setBlogArticles([]));

    // Reset existing blog, to prevent flash of old content during render
    dispatch(setContent(undefined));

    try {
        const blogOverviewResponse = await getPrismicOverviewApiCall({
            type: PrismicType.blog,
            category: config.category,
            page: config.page,
            pageSize: 8,
        });

        if (!isFetchResultSuccessful(blogOverviewResponse)) {
            console.error('[fetchBlogOverview]', blogOverviewResponse.error);
            return;
        }

        const resultInfo = transformPrismicSearchResponseToBlogOverviewInfo(blogOverviewResponse.data);
        const blogArticles = blogOverviewResponse.data.results.map(transformPrismicDocumentToBlog);

        dispatch(setTotalPages(resultInfo.totalPages));
        dispatch(setTotalResults(resultInfo.totalResults));

        dispatch(setBlogArticles(blogArticles));
    } catch (error) {
        console.error('[fetchBlogOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
