import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../../helpers/trans';
import { Skill } from '../../../../../models/Skills';
import { Tag } from '../../../..';

import './SkillSelectedOptionList.scss';

interface SkillSelectedOptionListProps {
    selectedOptions: Skill[];
    onSelectOption: (option: Skill) => void;
    className?: string;
}

const SkillSelectedOptionList: FC<SkillSelectedOptionListProps> = ({
    selectedOptions,
    onSelectOption,
    className = '',
}): ReactElement => (
    <div className={`skill-selected-option-list ${className}`}>
        <h2 className="skill-selected-option-list__title">
            {trans('forms.skills.selected')}
        </h2>

        <ul className="skill-selected-option-list__list">
            {selectedOptions.map(option => {
                const handleRemoveOption = (): void => {
                    onSelectOption(option);
                };

                return (
                    <li key={option.id} className="skill-selected-option-list__item">
                        <Tag
                            isActive
                            text={option.name}
                            onClick={handleRemoveOption}
                        />
                    </li>
                );
            })}
        </ul>
    </div>
);

export default SkillSelectedOptionList;
