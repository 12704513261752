import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';
import { defaultToastProperties, defaultToastRevealDuration } from '../../../models/Toast';
import { RoleType } from '../../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearApplication, fetchApplication } from '../../../store/application/applicationActions';
import { clearCandidate, clearCandidateSuccess, fetchCandidate } from '../../../store/candidate/candidateActions';
import { addToast } from '../../../store/toast/toastActions';
import { clearVacancy, fetchVacancy } from '../../../store/vacancyDetail/vacancyDetailActions';

interface ConnectedCandidateProfileWrapperProps {
    candidateUuid?: string;
    vacancyUuid?: string;
    applicationUuid?: string;
    className?: string;
    children?: (candidate?: Candidate) => ReactElement;
}

const ConnectedCandidateProfileWrapper: FC<ConnectedCandidateProfileWrapperProps> = ({
    candidateUuid,
    vacancyUuid,
    applicationUuid,
    className = '',
    children,
}): ReactElement => {
    const location = useLocation() as { state: { firstVisit?: boolean } };

    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);
    const role = useLegacySelector(state => state.legacyUser.role);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateNeedsUpdate = useTypedSelector(state => state.candidateReducer.isSuccessful);

    const userRole = role || RoleType.none;

    const fetchProfileData = (): void => {
        const candidateId = candidateUuid || person?.uuid;

        if (candidateId) dispatch(fetchCandidate(candidateId));
        if (vacancyUuid) dispatch(fetchVacancy(vacancyUuid));
        if (applicationUuid) dispatch(fetchApplication(applicationUuid));
    };

    useEffect((): void => {
        if (location?.state?.firstVisit) {
            dispatch(addToast({
                ...defaultToastProperties(),
                title: trans('candidateProfile.reminderToast.title'),
                description: trans('candidateProfile.reminderToast.description'),
                revealDuration: defaultToastRevealDuration * 2,
            }));
        }
    }, [location]);

    useEffect((): () => void => {
        fetchProfileData();

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (userRole === RoleType.company) {
                dispatch(clearCandidate());
                dispatch(clearVacancy());
                dispatch(clearApplication());
            }
        };
    }, []);

    useEffect((): void => {
        if (candidateNeedsUpdate) {
            fetchProfileData();
            dispatch(clearCandidateSuccess());
        }
    }, [candidateNeedsUpdate]);

    return (
        <div className={className}>
            {children ? children(candidate) : null}
        </div>
    );
};

export default ConnectedCandidateProfileWrapper;
