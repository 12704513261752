import axios from 'axios';
import { Dispatch } from 'redux';

import { LegacyReducers } from '../../../../store/reducers';
import { transformCompany } from '../../models/Company';
import { setData, setErrors, setLoading } from './userCompany';

export function createCompany(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userAddress } = getState();
        data.address_id = userAddress.data?.uuid || '';

        try {
            const response = await axios.post('/companies', data);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            console.error('[createCompany]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.resolve();
        }
    };
}

export function getCompany() {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userEmployee } = getState();
        const companyUuid = userEmployee?.data?.company_id || '';

        try {
            const response = await axios.get(`/companies/${companyUuid}`, {
                params: {
                    with: 'address,coreValues',
                },
            });

            const company = transformCompany(response.data.data);

            dispatch(setData(company));
            dispatch(setLoading(false));

            return Promise.resolve(company);
        } catch (error) {
            console.error('[getCompany]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function updateCompany(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userCompany } = getState();
        const companyId = userCompany?.data?.uuid || '';

        try {
            const response = await axios.patch(
                `/companies/${companyId}`,
                data,
                {
                    params: {
                        with: 'address,coreValues',
                    },
                },
            );

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error: any) {
            console.error('[updateCompany]', error);

            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}
