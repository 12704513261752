import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CandidateVideoCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { YouTubeFormData } from '../../../models/YouTube';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate, patchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCandidateVideoCardProps {
    activationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCandidateVideoCard: FC<ConnectedCandidateVideoCardProps> = ({
    activationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    const handleEditVideo = (youTubeFormData: YouTubeFormData): void => {
        if (candidate) {
            dispatch(patchCandidate({
                uuid: candidate.uuid,
                video: youTubeFormData.embedId,
            }));
        }
    };

    return (
        <CandidateVideoCard
            activationRef={activationRef}
            isLoading={isLoading}
            isEditable={isEditable}
            video={candidate?.video}
            onEdit={handleEditVideo}
            className={className}
        />
    );
};

export default ConnectedCandidateVideoCard;
