import React, { FC, ReactElement } from 'react';

import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { EducationLevel } from '../../../../models/EducationLevels';
import { FieldOfStudy } from '../../../../models/FieldsOfStudy';
import { Province } from '../../../../models/Provinces';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexFieldOfStudyProvince.scss';

interface VacancyIndexFieldOfStudyProvinceProps {
    isLoading: boolean;
    letter: string;
    educationLevel: EducationLevel;
    fieldOfStudy: FieldOfStudy;
    provinceOptionColumns: Province[][];
    className?: string;
}

const VacancyIndexFieldOfStudyProvince: FC<VacancyIndexFieldOfStudyProvinceProps> = ({
    isLoading,
    letter,
    educationLevel,
    fieldOfStudy,
    provinceOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.fieldOfStudy.province', {
            fieldOfStudy: fieldOfStudy.name,
        })}
        className={`vacancy-index-field-of-study-province ${className}`}
    >
        <VacancyIndexColumnList>
            {provinceOptionColumns.map(column => {
                const columnIndex = column.map(province => province.slug).join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-field-of-study-province__province-list">
                            {column.map(province => {
                                const searchQuery = encodeSearchQuery({
                                    what: fieldOfStudy.name,
                                    province: province.slug,
                                });

                                const encodedEducationLevel = encodeURIComponent(educationLevel.slug);
                                const encodedLetter = encodeURIComponent(letter);
                                const encodedFieldOfStudy = encodeURIComponent(fieldOfStudy.slug);
                                const encodedProvince = encodeURIComponent(province.slug);

                                return (
                                    <li key={province.slug} className="vacancy-index-field-of-study-province__province">
                                        <VacancyIndexLink
                                            to={RoutePaths.internshipsQuery(searchQuery)}
                                            label={province.name}
                                        />

                                        <VacancyIndexLink
                                            to={RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel, encodedLetter, encodedFieldOfStudy, encodedProvince)}
                                            label={trans('vacancyIndex.button.selectCity')}
                                            className="vacancy-index-field-of-study-province__city-link"
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexFieldOfStudyProvince;
