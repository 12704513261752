import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChatBalloon: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M90,0H10C4.5,0,0,4.5,0,10v90l20-20h70c5.5,0,10-4.5,10-10V10C100,4.5,95.5,0,90,0z M90,70H16l-6,6V10h80V70z" />
    </svg>
);

export default IconChatBalloon;
