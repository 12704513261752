import { Person, PersonApiResponseParams, transformPerson } from './Person';

export interface ApplicationAppointmentApiParams {
    uuid: string;
    date: string;
    start: string;
    end: string;
    leftPerson: PersonApiResponseParams;
    rightPerson: PersonApiResponseParams;
    status: string;
    type: string;
}

export interface ApplicationAppointment {
    uuid: string;
    date: string;
    start: string;
    end: string;
    leftPerson: Person;
    rightPerson: Person;
    status: string;
    type: string;
    name?: string;
}

export function transformApplicationAppointment(params: ApplicationAppointmentApiParams): ApplicationAppointment {
    return {
        uuid: params.uuid,
        date: params.date,
        start: params.start,
        end: params.end,
        leftPerson: transformPerson(params.leftPerson),
        rightPerson: transformPerson(params.rightPerson),
        status: params.status,
        type: params.type,
    };
}
