import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPlus: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M45,55v45h10V55h45V45H55V0H45v45H0v10H45z" />
    </svg>
);

export default IconPlus;
