import 'cleave.js/dist/addons/cleave-phone.nl';

import React from 'react';

import Cleave from 'cleave.js/react';
import styled, { css } from 'styled-components';

import Icon from './Icon';

const CleaveInput = props => {
    const { hasError, noBorder, ...rest } = props; // eslint-disable-line

    return <Cleave {...rest} />;
};

export const Label = styled('label')(props => css`
    display: block;
    ${props.theme.fonts.bold};
    color: ${props.theme.colors.text};
    margin-bottom: 5px;
    font-size: .875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`);

export const InputHolder = styled('div')(props => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: ${props.theme.colors.white};
    box-shadow: ${props.theme.shadow()};
    
    ${props.metric && css`
        ${props.metric.position === 'left' && '&:before'}
        ${props.metric.position === 'right' && '&:after'}
        {
            content: '${props.metric.label}';
            font-size: 12pt;
            padding: 12.5px;
            border: ${props.hasError ? '2px' : '1px'} solid ${props.theme.colors.grey};
            background-color: ${props.theme.colors.grey};
        }
    `}
`);

export const inputTemplate = props => css`
    padding: 0 ${props.theme.space.sm}; 
    height: 46px;
    padding-right: ${props.icon ? '40px' : '15px'};
    line-height: ${props.height || '44px'};
    font-size: 1em;
    width: 100%;
    transition: border 0.35s ease-out;
    border: ${props.noBorder ? 'none' : `1px solid ${props.theme.colors.grey}`};
    
    ${props.disabled && css`
        background-color: ${props.theme.colors.background};
    `}
    
    ${props.hasError && css`
        border: 2px solid ${props.theme.colors.alert};
    `}
    
    &::placeholder {
        color: ${props.theme.colors.muted};
    }
    
    &:focus {
        outline: none;
        border-color: ${props.theme.colors.primary};
        box-shadow: none;
    }
`;

export const Input = styled('input')`
    ${props => inputTemplate(props)};
`;

export const FormattedInput = styled(CleaveInput)`
    ${props => inputTemplate(props)};
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Error = styled('p')`
    color: ${props => props.theme.colors.alert};
    font-size: 12px;
    line-height: 1; 
    overflow: hidden; 
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: height 0.15s ease-out,
                padding 0.15s ease-out;
    
    ${props => props.errors && css`
        height: auto;
        padding-top: 5px;
        padding-bottom: 1px;
    `}
`;

export const OptionalLabel = styled.span`
    float: right;
    color: ${props => props.theme.colors.muted};
    font-weight: normal;
    font-size: 12px;
`;
