export default () => {
    if (typeof window === 'undefined') {
        return 0;
    }

    const ua = window.navigator.userAgent;

    if (ua.indexOf('Trident/7.0') > -1) {
        return 11;
    }
    if (ua.indexOf('Trident/6.0') > -1) {
        return 10;
    }

    if (ua.indexOf('Trident/5.0') > -1) {
        return 9;
    }

    return 0; // not IE9, 10 or 11
};
