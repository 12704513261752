import React, { FC, ReactElement } from 'react';

import { generateIdArray } from '@createnl/pagination/dist/helpers/array';

import { VacancyType } from '../../../../../_old/app_talentz/models/VacancyType';
import { ExternalLinkButton } from '../../../../../components';
import { isNotSSR } from '../../../../../helpers/environment';
import { trans } from '../../../../../helpers/trans';
import { NewestVacancy } from '../../../../../models/NewestVacancy';

import './VacancyApplyFormTips.scss';

interface VacancyApplyFormTipsProps {
    vacancy: NewestVacancy;
    className?: string;
}

const VacancyApplyFormTips: FC<VacancyApplyFormTipsProps> = ({
    vacancy,
    className = '',
}): ReactElement => {
    const tips = generateIdArray(3);

    return (
        <aside className={`vacancy-apply-form-tips ${className}`}>
            <div className="vacancy-apply-form-tips__list-wrapper">
                <h2 className="vacancy-apply-form-tips__title">
                    {trans('formTips.apply.writingTips.title')}
                </h2>

                <ul className="vacancy-apply-form-tips__tip-list">
                    {tips.map(tip => (
                        <li key={tip} className="vacancy-apply-form-tips__tip">
                            {vacancy?.type === VacancyType.job
                                ? trans(`formTips.apply.jobWritingTips.list.${tip}`)
                                : trans(`formTips.apply.writingTips.list.${tip}`)}
                        </li>
                    ))}
                </ul>
            </div>

            {vacancy.isAnonymous && (
                <div className="vacancy-apply-form-tips__anonymous-wrapper">
                    <h3 className="vacancy-apply-form-tips__sub-title">
                        {trans('basic.applyAnonymous')}
                    </h3>

                    <p className="vacancy-apply-form-tips__paragraph">
                        {trans('formTips.apply.anonymous.body')}
                    </p>
                </div>
            )}

            <div className="vacancy-apply-form-tips__glance-wrapper">
                <h3 className="vacancy-apply-form-tips__sub-title">
                    {trans('formTips.apply.watchVacancy.title')}
                </h3>

                <p className="vacancy-apply-form-tips__paragraph">
                    {trans('formTips.apply.watchVacancy.body')}
                </p>

                <ExternalLinkButton
                    href={isNotSSR ? window.location.href : ''}
                    text={trans('formTips.apply.watchVacancy.buttonLabel')}
                    className="vacancy-apply-form-tips__button"
                />
            </div>
        </aside>
    );
};

export default VacancyApplyFormTips;
