import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Avatar } from '../../../../compositions';
import { formatMessageDate } from '../../../../helpers/date';
import { trans } from '../../../../helpers/trans';
import { ChatMessage } from '../../../../models/ChatMessages';

import './ChatEventMessage.scss';

interface ChatEventMessageProps {
    isAuthor: boolean;
    isFirstOfGroup: boolean;
    isLastOfGroup: boolean;
    message: ChatMessage;
    className?: string;
}

const ChatEventMessage: FC<ChatEventMessageProps> = ({
    isAuthor,
    isFirstOfGroup,
    isLastOfGroup,
    message,
    className = '',
}): ReactElement => {
    const chatMessageClassNames = classNames('chat-event-message', {
        'chat-event-message--is-author': isAuthor,
        'chat-event-message--is-first-of-group': isFirstOfGroup,
        'chat-event-message--is-last-of-group': isLastOfGroup,
    }, className);

    return (
        <li className={chatMessageClassNames}>
            <Avatar
                src={message.author.avatar}
                alt={trans('containers.messageOverview.avatarAlt', {
                    name: message.author.fullName,
                })}
                fallbackString={message.author.fullName}
                className="chat-event-message__avatar"
            />

            <div className="chat-event-message__chat-balloon-wrapper">
                <div className="chat-event-message__chat-balloon">
                    {message.text}
                </div>

                <p className="chat-event-message__date">
                    {formatMessageDate(message.createdAt)}
                </p>
            </div>
        </li>
    );
};

export default ChatEventMessage;
