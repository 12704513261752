import React, { Component, createRef } from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../../helpers/trans';
import arrayEquals from '../../../helpers/arrayEquals';
import {
    Error,
    FormattedInput,
    Input,
    InputHolder,
    InputIcon,
    Label,
    OptionalLabel,
} from '../../../styledComponents/components/Input';

class TextInput extends Component {
    constructor(props) {
        super();

        this.input = createRef();

        this.state = {
            showErrors: false,
            value: props.value,
        };
    }

    componentDidMount() {
        const { defaultValue } = this.props;

        defaultValue && this.setValue(defaultValue);
    }

    componentDidUpdate(prevProps) {
        const { defaultValue, errors } = this.props;

        (!arrayEquals(prevProps.errors, errors) && !this.state.showErrors)
        && this.showErrors();

        (prevProps.defaultValue !== defaultValue) && this.setValue(defaultValue);
    }

    setValue(value) {
        this.setState({ value });
    }

    showErrors() {
        this.setState({ showErrors: true });
    }

    onChange = e => {
        this.setState({ showErrors: false });
        this.setValue(e.currentTarget.value);

        this.props.onChange(e);
    };

    onKeyPress = e => this.props.onKeyPress(e);

    onKeyUp = e => this.props.onKeyPress(e);

    render() {
        const {
            label,
            ariaLabel,
            required,
            id,
            name,
            type,
            icon,
            formatOptions,
            placeholder,
            errors,
            onInit,
            onBlur,
            onFocus,
            optional,
            disabled,
            min,
            max,
            maxLength,
            height,
            metric,
            autoComplete,
            noBorder,
            className,
        } = this.props;

        const { value } = this.state;
        const showErrors = (this.state.showErrors && !this.props.controlled) || (!!errors || this.props.controlled);

        return (
            <span className={className}>
                {label && (
                    <Label id={`${id}-label`}>
                        {label}
                        {optional && <OptionalLabel>{trans('forms.optional')}</OptionalLabel>}
                        {required && '*'}
                    </Label>
                )}
                <InputHolder metric={metric} hasError={!!errors && showErrors}>
                    {formatOptions
                        ? (
                            <FormattedInput
                                {...
                                    {
                                        id,
                                        type,
                                        name,
                                        height,
                                        icon,
                                        value: value || '',
                                        options: formatOptions,
                                        placeholder,
                                        onInit,
                                        onChange: this.onChange,
                                        onFocus,
                                        onBlur,
                                        disabled,
                                        min,
                                        max,
                                        maxLength,
                                        hasError: !!errors && showErrors,
                                        noBorder,
                                        autoComplete,
                                    }
                                }
                                data-hj-suppress
                            />
                        )
                        : (
                            <Input
                                {...
                                    {
                                        id,
                                        type,
                                        name,
                                        height,
                                        icon,
                                        ref: this.input,
                                        value: value || '',
                                        placeholder,
                                        onChange: this.onChange,
                                        onFocus,
                                        onKeyPress: this.onKeyPress,
                                        onKeyUp: this.onKeyUp,
                                        onBlur,
                                        disabled,
                                        min,
                                        max,
                                        maxLength,
                                        hasError: !!errors && showErrors,
                                        autoComplete,
                                        noBorder,
                                    }
                                }
                                aria-label={ariaLabel}
                                data-hj-suppress
                            />
                        )
                    }
                    {icon && <InputIcon kind={icon} color="muted" size={15} />}
                </InputHolder>

                <Error errors={!!errors && showErrors}>
                    {(!!errors && showErrors) && <span key={`${name} - ${errors[0]}`}>{errors[0]}<br /></span>}
                </Error>
            </span>
        );
    }
}

TextInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    ariaLabel: PropTypes.string,
    id: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    errors: PropTypes.array,
    type: PropTypes.oneOf([
        'email',
        'password',
        'text',
        'number',
        'date',
    ]),
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    placeholder: PropTypes.string,
    formatOptions: PropTypes.object,
    onInit: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    optional: PropTypes.bool,
    disabled: PropTypes.bool,
    height: PropTypes.string,
    metric: PropTypes.shape({
        label: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
    }),
    autoComplete: PropTypes.string,
    noBorder: PropTypes.bool,
    controlled: PropTypes.bool,
    className: PropTypes.string,
};

TextInput.defaultProps = {
    label: '',
    value: null,
    ariaLabel: '',
    id: undefined,
    type: 'text',
    icon: '',
    max: null,
    min: null,
    maxLength: null,
    errors: null,
    formatOptions: null,
    defaultValue: '',
    placeholder: '',
    onInit: null,
    /* eslint-disable @typescript-eslint/no-empty-function */
    onChange: () => {},
    onFocus: () => {},
    onKeyPress: () => {},
    onKeyUp: () => {},
    onBlur: () => {},
    /* eslint-enable @typescript-eslint/no-empty-function */
    required: false,
    optional: false,
    disabled: false,
    metric: null,
    height: null,
    autoComplete: 'on',
    noBorder: false,
    controlled: false,
    className: '',
};

export default TextInput;
