import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../helpers/trans';
import { CompanyUspCard } from '..';

import './CompanyUsps.scss';

interface CompanyUspsProps {
    className?: string;
}

const CompanyUsps: FC<CompanyUspsProps> = ({
    className = '',
}): ReactElement => {
    const content = [
        {
            title: trans('infoPage.company.usp.cards.left.title'),
            imageAlt: trans('infoPage.company.usp.cards.left.imageAlt'),
            description: trans('infoPage.company.usp.cards.left.description'),
            uspList: trans('infoPage.company.usp.cards.left.uspList'),
        },
        {
            title: trans('infoPage.company.usp.cards.right.title'),
            imageAlt: trans('infoPage.company.usp.cards.right.imageAlt'),
            description: trans('infoPage.company.usp.cards.right.description'),
            uspList: trans('infoPage.company.usp.cards.right.uspList'),
        },
    ];
    const images = ['./images/schoolbooks-colour.svg', './images/worker-colour-illustration.svg'];

    const cards = content.map((card, index) => ({
        ...card,
        image: {
            src: images[index],
            alt: card.imageAlt,
        },
        uspList: card.uspList?.split('|'),
    }));

    return (
        <section className={`company-usps ${className}`}>
            <div className="company-usps__wrapper">
                <h1 className="company-usps__title">
                    {trans('infoPage.company.usp.title')}
                </h1>

                <ul className="company-usps__usp-list">
                    {cards.map(card => (
                        <li key={card.title} className="company-usps__usp-item">
                            <CompanyUspCard
                                title={card.title}
                                image={card.image}
                                description={card.description}
                                uspList={card.uspList}
                                className="company-usps__usp-card"
                            />
                        </li>
                    ))}
                </ul>
            </div>

            <div className="company-usps__backdrop" />
        </section>
    );
};

export default CompanyUsps;
