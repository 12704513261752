import { defaultToastProperties, Toast } from '../../models/Toast';
import { AtLeast, MessageState } from '../../types';
import { ReducerGetter, TypedDispatch } from '..';
import { setToastList } from './toast';

export const addToast = (toast: Toast) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { toastReducer } = getState();
    const currentToastList = toastReducer.toastList;

    const newToastList = [...currentToastList, toast];

    dispatch(setToastList(newToastList));
};

export const addPositiveToast = (toast: AtLeast<Toast, 'title'>) => (dispatch: TypedDispatch): void => {
    dispatch(addToast({
        ...defaultToastProperties(),
        ...toast,
        state: MessageState.positive,
    }));
};

export const addNegativeToast = (toast: AtLeast<Toast, 'title'>) => (dispatch: TypedDispatch): void => {
    dispatch(addToast({
        ...defaultToastProperties(),
        ...toast,
        state: MessageState.negative,
    }));
};

export const addWarningToast = (toast: AtLeast<Toast, 'title'>) => (dispatch: TypedDispatch): void => {
    dispatch(addToast({
        ...defaultToastProperties(),
        ...toast,
        state: MessageState.warning,
    }));
};

export const removeToast = (toastId: string) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { toastReducer } = getState();
    const currentToastList = toastReducer.toastList;

    const newToastList = currentToastList.filter(toast => toast.id !== toastId);

    dispatch(setToastList(newToastList));
};
