import { addDays, differenceInDays } from 'date-fns';

import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { validateRangeInput, validateRequiredProperty, validateRequiredString } from '../../../_old/app_talentz/services/ValidationService';
import { maxLongTextLength, maxVacancyLifetimeInDays } from '../../../constants';
import { formatDate } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';
import { PlaceJobVacancyFormValues } from '../../../services/PlaceVacancyService';
import { validateMaxStringLength } from '../../../services/ValidationService';

export function validateJobVacancyDataStep(formValues: PlaceJobVacancyFormValues, checkAll = false): ValidationErrors {
    const jobVacancyLifetimeInDays = maxVacancyLifetimeInDays;
    const errors: ValidationErrors = {};

    const sectorErrors: (string | null)[] = [validateRequiredString('sector', formValues.sectorId)];

    errors.title = validateRequiredProperty('title', formValues.title, checkAll);
    errors.zipcode = validateRequiredProperty('zipcode', (formValues.address && formValues.address.address), checkAll);
    errors.publicationDate = validateRequiredProperty('publication_date', formValues.publicationDate, checkAll);
    errors.expirationDate = validateRequiredProperty('expiration_date', formValues.expirationDate, checkAll);
    errors.educationLevels = validateRequiredProperty('education_level', formValues.educationLevelIds, checkAll);
    errors.employmentHourRange = validateRangeInput('employmentHourRange', formValues.employmentHourRange);
    errors.jobType = validateRequiredProperty('job_type', formValues.jobTypeId, checkAll);

    const description = validateMaxStringLength('vacancyText', formValues.description, maxLongTextLength);
    errors.description = description ? [description] : [];

    errors.sectors = sectorErrors.filter(error => error !== null) as string[];
    errors.workRemotePreference = validateRequiredProperty('work_remote_preference', formValues.workRemotePreferenceId, checkAll);

    const minCompensation = formValues.minMonthSalary || formValues.minHourSalary;
    const maxCompensation = formValues.maxMonthSalary || formValues.maxHourSalary;

    if (minCompensation === '' && maxCompensation !== '') {
        errors.salary = [trans('forms.vacancy.grossSalary.minValue.error')];
    } else if ((minCompensation !== '' && maxCompensation === '')
        || (minCompensation !== '' && maxCompensation !== '' && maxCompensation < minCompensation)) {
        errors.salary = [trans('forms.vacancy.grossSalary.maxValue.error')];
    }

    if (formValues.publicationDate && formValues.expirationDate) {
        const maxExpireDate = addDays(formValues.publicationDate, jobVacancyLifetimeInDays);
        const dayDiffExpireDate = differenceInDays(maxExpireDate, formValues.expirationDate);

        if (formValues.expirationDate.getTime() <= formValues.publicationDate.getTime()) {
            errors.expirationDate = [
                trans('forms.vacancy.expirationDate.errors.beforePublishDate'),
            ];
        }

        if (dayDiffExpireDate < 0) {
            errors.expirationDate = [
                trans('forms.vacancy.expirationDate.errors.afterLifetime', {
                    jobVacancyLifetimeInDays,
                    date: formatDate(maxExpireDate),
                }),
            ];
        }
    }

    Object.keys(errors).forEach(errorKey => {
        if (!errors[errorKey].length) {
            delete errors[errorKey];
        }
    });

    return errors;
}
