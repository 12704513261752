import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconAnonymousStar: FC<SvgIconProps> = ({ className = '', accentClassName = '' }): ReactElement => (
    <svg viewBox="0 0 100 95.1" className={`icon ${className}`}>
        <path d="M50,78.9L19.1,95.1L25,60.7L0,36.3l34.5-5L50,0l15.5,31.3l34.5,5L75,60.7l5.9,34.4L50,78.9z" />
        <g>
            <path className={accentClassName} d="M50,68c5.8,0,10.5-3.6,10.5-8.1h-21C39.5,64.4,44.2,68,50,68z" />
            <circle className={accentClassName} cx="36.7" cy="45.2" r="4.9" />
            <circle className={accentClassName} cx="63.3" cy="45.2" r="4.9" />
        </g>
    </svg>
);

export default IconAnonymousStar;
