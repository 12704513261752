import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import TextEditor from '../../../_old/components/form/TextEditor';
import { FormActionButtons } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { AboutFormData } from '../../../models/About';
import { FormProps } from '../../../types';
import { AboutFormErrors, validateAboutFormData } from './validations';

import './AboutForm.scss';

interface AboutFormProps extends FormProps<AboutFormData> {
    label: string;
    placeholder?: string;
    description: string;
    className?: string;
}

const AboutForm: FC<AboutFormProps> = ({
    label,
    placeholder,
    description,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<AboutFormErrors>({});

    const [aboutContent, setAboutContent] = useState<string>(description);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            description: aboutContent,
        };

        const [errors, hasErrors] = validateAboutFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`about-form ${className}`}>
            <TextEditor
                name="description"
                label={label}
                placeholder={placeholder}
                defaultValue={aboutContent}
                errors={formErrors.description ? [formErrors.description] : undefined}
                onChange={setAboutContent}
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="about-form__action-buttons"
            />
        </form>
    );
};

export default AboutForm;
