export const majorCitiesNL = [
    'Amsterdam',
    'Arnhem',
    'Breda',
    'Den Haag',
    'Eindhoven',
    'Enschede',
    'Groningen',
    'Nijmegen',
    'Rotterdam',
    'Tilburg',
    'Utrecht',
    'Zwolle',
];

export const residencesNL = [
    'Aadorp',
    'Aagtekerke',
    'Aalden',
    'Aalsmeer',
    'Aalsmeerderbrug',
    'Aalst',
    'Aalsum',
    'Aalten',
    'Ter Aar',
    'Ter Aard',
    'Aardenburg',
    'Aarlanderveen',
    'Aarle-Rixtel',
    'Aartswoud',
    'Abbega',
    'Abbekerk',
    'Abbenbroek',
    'Abbenes',
    'Abcoude',
    'Achlum',
    'Achterveld',
    'Achthuizen',
    'Achtmaal',
    'Acquoy',
    'Adorp',
    'Aduard',
    'Aerdenhout',
    'Aerdt',
    'Afferden',
    'Afferden L',
    'Agelo',
    'Akersloot',
    'Akkrum',
    'Akmarijp',
    'Albergen',
    'Alblasserdam',
    'Alde Leie',
    'Aldeboarn',
    'Aldtsjerk',
    'Alem',
    'Alkmaar',
    'Allingawier',
    'Almelo',
    'Almen',
    'Almere',
    'Almkerk',
    'Alphen',
    'Alphen aan den Rijn',
    'Alteveer',
    'Alteveer gem Hoogeveen',
    'Altforst',
    'Ambt Delden',
    'Ameide',
    'Amen',
    'America',
    'Amerongen',
    'Amersfoort',
    'Ammerstol',
    'Ammerzoden',
    'Amstelhoek',
    'Amstelveen',
    'Amstenrade',
    'Amsterdam',
    'Amsterdam-Duivendrecht',
    'Andel',
    'Den Andel',
    'Andelst',
    'Anderen',
    'Andijk',
    'Ane',
    'Anerveen',
    'Anevelde',
    'Angeren',
    'Angerlo',
    'Anjum',
    'Ankeveen',
    'Anloo',
    'Anna Paulowna',
    'Annen',
    'Annerveenschekanaal',
    'Ansen',
    'Ter Apel',
    'Apeldoorn',
    'Ter Apelkanaal',
    'Appelscha',
    'Appeltern',
    'Appingedam',
    'Arcen',
    'Arkel',
    'Arnemuiden',
    'Arnhem',
    'Arriën',
    'Arum',
    'Asch',
    'Asperen',
    'Assen',
    'Assendelft',
    'Asten',
    'Augsbuurt',
    'Augustinusga',
    'Austerlitz',
    'Avenhorn',
    'Axel',
    'Azewijn',
    'Baaiduinen',
    'Baaium',
    'Baak',
    'Baambrugge',
    'Baard',
    'Baarland',
    'Baarle-Nassau',
    'Baarlo',
    'Baarn',
    'Baars',
    'Babberich',
    'Babyloniënbroek',
    'Bad Nieuweschans',
    'Badhoevedorp',
    'Baexem',
    'Baflo',
    'Bakel',
    'Bakhuizen',
    'Bakkeveen',
    'Balgoij',
    'Balinge',
    'Balk',
    'Balkbrug',
    'Balloërveld',
    'Balloo',
    'Ballum',
    'Baneheide',
    'Banholt',
    'Bant',
    'Bantega',
    'Barchem',
    'Barendrecht',
    'Barger-Compascuum',
    'Barneveld',
    'Barsingerhorn',
    'Basse',
    'Batenburg',
    'Bathmen',
    'Bavel',
    'Bavel AC',
    'Bears',
    'Bedum',
    'Beegden',
    'Beek',
    'Beek en Donk',
    'Beekbergen',
    'Beemte Broekland',
    'Beers NB',
    'Beerta',
    'Beerze',
    'Beerzerveld',
    'Beesd',
    'Beesel',
    'Beets',
    'Beetsterzwaag',
    'Beilen',
    'Beinsdorp',
    'Belfeld',
    'Bellingwolde',
    'Beltrum',
    'Belt-Schutsloot',
    'Bemelen',
    'Bemmel',
    'Beneden-Leeuwen',
    'Bennebroek',
    'Bennekom',
    'Benneveld',
    'Benningbroek',
    'Benschop',
    'Bentelo',
    'Benthuizen',
    'Bentveld',
    'Berg en Dal',
    'Berg en Terblijt',
    'Bergambacht',
    'Bergeijk',
    'Bergen',
    'Bergen aan Zee',
    'Bergen L',
    'Bergen op Zoom',
    'Bergentheim',
    'Bergharen',
    'Berghem',
    'Bergschenhoek',
    'Beringe',
    'Berkel en Rodenrijs',
    'Berkel-Enschot',
    'Berkenwoude',
    'Berkhout',
    'Berlicum',
    'Berltsum',
    'Bern',
    'Best',
    'Beugen',
    'Beuningen',
    'Beuningen Gld',
    'Beusichem',
    'Beutenaken',
    'Beverwijk',
    'Biddinghuizen',
    'Bierum',
    'Biervliet',
    'Biest-Houtakker',
    'Biezenmortel',
    'Biggekerke',
    'De Bilt',
    'Bilthoven',
    'Bingelrade',
    'Bitgum',
    'Bitgummole',
    'Bladel',
    'Blankenham',
    'Blaricum',
    'Blauwestad',
    'Blauwhuis',
    'Bleiswijk',
    'Blesdijke',
    'Bleskensgraaf ca',
    'De Blesse',
    'Blessum',
    'Blije',
    'Blijham',
    'Blitterswijck',
    'Bloemendaal',
    'Blokker',
    'Blokzijl',
    'Boazum',
    'Bocholtz',
    'Bodegraven',
    'Boekel',
    'Boelenslaan',
    'Boer',
    'Ten Boer',
    'Boerakker',
    'Boesingheliede',
    'Boijl',
    'Boksum',
    'Bolsward',
    'Den Bommel',
    'Bontebok',
    'Boornbergum',
    'Boornzwaag',
    'Borculo',
    'Borger',
    'Borgercompagnie',
    'Borgsweer',
    'Born',
    'Borne',
    'Bornerbroek',
    'Bornwird',
    'Borssele',
    'Bosch en Duin',
    'Boschoord',
    'Boskoop',
    'Bosschenhoofd',
    'Botlek Rotterdam',
    'Bourtange',
    'Bovenkarspel',
    'Boven-Leeuwen',
    'Bovensmilde',
    'Boxmeer',
    'Boxtel',
    'Braamt',
    'Brakel',
    'Brandwijk',
    'Brantgum',
    'Breda',
    'Bredevoort',
    'Breedenbroek',
    'Breezand',
    'Breezanddijk',
    'Breskens',
    'Breukelen',
    'Breukeleveen',
    'Brielle',
    'Briltil',
    'Britsum',
    'Britswert',
    'Broek',
    'Broek in Waterland',
    'Broek op Langedijk',
    'Broekhuizen',
    'Broekhuizenvorst',
    'Broekland',
    'Broeksterwâld',
    'Bronkhorst',
    'Bronneger',
    'Bronnegerveen',
    'Brouwershaven',
    'Bruchem',
    'Brucht',
    'Bruchterveld',
    'Bruinehaar',
    'Bruinisse',
    'Brummen',
    'Brunssum',
    'Bruntinge',
    'Buchten',
    'Budel',
    'Budel-Dorplein',
    'Budel-Schoot',
    'Buggenum',
    'Buinen',
    'Buinerveen',
    'Buitenkaag',
    'Buitenpost',
    'De Bult',
    'Bunde',
    'Bunne',
    'Bunnik',
    'Bunschoten-Spakenburg',
    'Burdaard',
    'Buren',
    'Den Burg',
    'Burgerbrug',
    'Burgerveen',
    'Burgh-Haamstede',
    'Burgum',
    'Burgwerd',
    'Burum',
    'Bussum',
    'Buurmalsen',
    'Cadier en Keer',
    'Cadzand',
    'Callantsoog',
    'Capelle aan den IJssel',
    'Castelre',
    'Castenray',
    'Casteren',
    'Castricum',
    'Chaam',
    'Clinge',
    'De Cocksdorp',
    'Coevorden',
    'Colijnsplaat',
    'Collendoorn',
    'Colmschate',
    'Cornwerd',
    'Cothen',
    'Creil',
    'Cromvoirt',
    'Cruquius',
    'Cuijk',
    'Culemborg',
    'Daarle',
    'Daarlerveen',
    'Dalem',
    'Dalen',
    'Dalerpeel',
    'Dalerveen',
    'Dalfsen',
    'Dalmsholte',
    'Damwâld',
    'Darp',
    'Dearsum',
    'Dedemsvaart',
    'Dedgum',
    'Deelen',
    'Deest',
    'Deil',
    'Deinum',
    'Delden',
    'Delfgauw',
    'Delfstrahuizen',
    'Delft',
    'Delfzijl',
    'Delwijnen',
    'Demen',
    'Denekamp',
    'Deurne',
    'Deurningen',
    'Deursen-Dennenburg',
    'Deurze',
    'Deventer',
    'Didam',
    'Dieden',
    'Diemen',
    'Diepenheim',
    'Diepenveen',
    'Dieren',
    'Diessen',
    'Diever',
    'Dieverbrug',
    'Diffelen',
    'Dijken',
    'Dinteloord',
    'Dinxperlo',
    'Diphoorn',
    'Dirkshorn',
    'Dirksland',
    'Dodewaard',
    'Doenrade',
    'Doesburg',
    'Doetinchem',
    'Doeveren',
    'Doezum',
    'Dokkum',
    'Den Dolder',
    'Doldersum',
    'Domburg',
    'Donderen',
    'Dongen',
    'Dongjum',
    'Doniaga',
    'Donkerbroek',
    'Doorn',
    'Doornenburg',
    'Doornspijk',
    'Doorwerth',
    'Dordrecht',
    'Dorst',
    'Drachten',
    'Drachten-Azeven',
    'Drachtstercompagnie',
    'Dreischor',
    'Drempt',
    'Dreumel',
    'Driebergen-Rijsenburg',
    'Drieborg',
    'Driebruggen',
    'Driehuis NH',
    'Driehuizen',
    'Driel',
    'Driewegen',
    'Driezum',
    'Drijber',
    'Drimmelen',
    'Drogeham',
    'Drogteropslagen',
    'Drongelen',
    'Dronryp',
    'Dronten',
    'Drouwen',
    'Drouwenermond',
    'Drouwenerveen',
    'Drunen',
    'Druten',
    'Duiven',
    'Duivendrecht',
    'Duizel',
    'Den Dungen',
    'Dussen',
    'Dwingeloo',
    'Eagum',
    'Earnewâld',
    'Easterein',
    'Easterlittens',
    'Eastermar',
    'Easterwierrum',
    'Echt',
    'Echteld',
    'Echten',
    'Echtenerbrug',
    'Eck en Wiel',
    'Eckelrade',
    'Edam',
    'Ede',
    'Ederveen',
    'Ee',
    'Eede',
    'Eefde',
    'Eelde',
    'Eelderwolde',
    'Eemdijk',
    'Eemnes',
    'Eemshaven',
    'Een',
    'Eenrum',
    'Eenum',
    'Een-West',
    'Eerbeek',
    'Eersel',
    'Ees',
    'Eesergroen',
    'Eeserveen',
    'Eesterga',
    'Eesveen',
    'Eethen',
    'Eext',
    'Eexterveen',
    'Eexterveenschekanaal',
    'Eexterzandvoort',
    'Egchel',
    'Egmond aan den Hoef',
    'Egmond aan Zee',
    'Egmond-Binnen',
    'Eibergen',
    'Eijsden',
    'Eindhoven',
    'Einighausen',
    'Ekehaar',
    'Elahuizen',
    'Elburg',
    'Eldersloo',
    'Eleveld',
    'Elim',
    'Elkenrade',
    'Ell',
    'Ellecom',
    'Ellemeet',
    'Ellertshaar',
    'Ellewoutsdijk',
    'Elp',
    'Elsendorp',
    'Elshout',
    'Elsloo',
    'Elspeet',
    'Elst',
    'Elst Ut',
    'Emmeloord',
    'Emmen',
    'Emmer-Compascuum',
    'Empe',
    'Emst',
    'Engwierum',
    'Enkhuizen',
    'Ens',
    'Enschede',
    'Enspijk',
    'Enter',
    'Enumatil',
    'Epe',
    'Epen',
    'Eppenhuizen',
    'Epse',
    'Erica',
    'Erichem',
    'Erlecom',
    'Erm',
    'Ermelo',
    'Erp',
    'Esbeek',
    'Esch',
    'Escharen',
    'Espel',
    'Est',
    'Etten',
    'Etten-Leur',
    'Europoort Rotterdam',
    'Eursinge',
    'Everdingen',
    'Evertsoord',
    'Ewijk',
    '1e Exloërmond',
    '2e Exloërmond',
    'Exloërveen',
    'Exloo',
    'Exmorra',
    'Eygelshoven',
    'Eys',
    'Ezinge',
    'De Falom',
    'Farmsum',
    'Feanwâlden',
    'Feerwerd',
    'Feinsum',
    'Ferwert',
    'Ferwoude',
    'Fijnaart',
    'Finsterwolde',
    'Firdgum',
    'Fleringen',
    'Fluitenberg',
    'Fochteloo',
    'Follega',
    'Folsgare',
    'Formerum',
    'Foudgum',
    'Foxhol',
    'Foxwolde',
    'Franeker',
    'Frederiksoord',
    'Friens',
    'Frieschepalen',
    'Froombosch',
    'Gaanderen',
    'Gaast',
    'Gaastmeer',
    'Galder',
    'Gameren',
    'Gapinge',
    'Garderen',
    'Garmerwolde',
    'Garminge',
    'Garnwerd',
    'Garrelsweer',
    'Garsthuizen',
    'Garyp',
    'Gassel',
    'Gasselte',
    'Gasselternijveen',
    'Gasselternijveenschemond',
    'Gastel',
    'Gasteren',
    'Gauw',
    'Geelbroek',
    'Geerdijk',
    'Geersdijk',
    'Geertruidenberg',
    'Geervliet',
    'Gees',
    'Geesbrug',
    'Geesteren',
    'Geeuwenbrug',
    'Geffen',
    'Geijsteren',
    'Geldermalsen',
    'Gelderswoude',
    'Geldrop',
    'Geleen',
    'Gellicum',
    'Gelselaar',
    'Gemert',
    'Gemonde',
    'Genderen',
    'Gendringen',
    'Gendt',
    'Genemuiden',
    'Gennep',
    'Gerkesklooster',
    'Gersloot',
    'Geulle',
    'Giesbeek',
    'Giessen',
    'Giessenburg',
    'Gieten',
    'Gieterveen',
    'Giethmen',
    'Giethoorn',
    'Gilze',
    'Ginnum',
    'Glane',
    'Glimmen',
    'De Glind',
    'Godlinze',
    'Goedereede',
    'Goënga',
    'Goëngahuizen',
    'Goes',
    'Goingarijp',
    'Goirle',
    'Goor',
    'De Goorn',
    'Gorinchem',
    'Gorredijk',
    'Gorssel',
    'Gouda',
    'Gouderak',
    'Goudriaan',
    'Goudswaard',
    'Goutum',
    "'t Goy",
    'Graauw',
    'Grafhorst',
    'Graft',
    'Gramsbergen',
    'Grashoek',
    'Grathem',
    'Grave',
    "'s-Graveland",
    "'s-Gravendeel",
    "'s-Gravenhage",
    "'s Gravenmoer",
    "'s-Gravenpolder",
    "'s-Gravenzande",
    'Greonterp',
    'Grevenbicht',
    'Griendtsveen',
    'Grijpskerk',
    'Grijpskerke',
    'Groede',
    'Groenekan',
    'Groeningen',
    'Groenlo',
    'Groesbeek',
    'Groessen',
    'Groet',
    'De Groeve',
    'Grolloo',
    'Groningen',
    'Gronsveld',
    'Groot-Ammers',
    'Grootebroek',
    'Grootegast',
    'Grootschermer',
    'Grou',
    'Grubbenvorst',
    'Gulpen',
    'Guttecoven',
    'Gytsjerk',
    'Haaften',
    'Haaksbergen',
    'Haalderen',
    "'t Haantje",
    'Haaren',
    'Haarle',
    'Haarlem',
    'Haarlemmerliede',
    'Haarlo',
    'Haarsteeg',
    'Haarzuilens',
    'Haastrecht',
    'Haelen',
    'Hagestein',
    'Haghorst',
    'Haler',
    'Halfweg',
    'Hall',
    'Halle',
    'Hallum',
    'Halsteren',
    'Den Ham',
    'Handel',
    'Hank',
    'Hansweert',
    'Hantum',
    'Hantumeruitburen',
    'Hantumhuizen',
    'Hapert',
    'Haps',
    'Harbrinkhoek',
    "'t Harde",
    'Hardenberg',
    'Harderwijk',
    'Hardinxveld-Giessendam',
    'Haren',
    'Haren Gn',
    'Harfsen',
    'Harich',
    'Haringhuizen',
    'Harkema',
    'Harkstede',
    'Harkstede GN',
    'Harlingen',
    'Harmelen',
    'Harreveld',
    'Harskamp',
    'Hartwerd',
    'Haskerdijken',
    'Haskerhorne',
    'Hasselt',
    'Hattem',
    'Hattemerbroek',
    'Haule',
    'Haulerwijk',
    'Hauwert',
    'Havelte',
    'Havelterberg',
    'Hazerswoude-Dorp',
    'Hazerswoude-Rijndijk',
    'Hedel',
    'Hedikhuizen',
    'Hee',
    'Heeg',
    'Heel',
    'Heelsum',
    'Heelweg',
    'Heemserveen',
    'Heemskerk',
    'Heemstede',
    'De Heen',
    'Heenvliet',
    "'s-Heer Abtskerke",
    "'s-Heer Arendskerke",
    "'s-Heer Hendrikskinderen",
    'Heerde',
    "'s-Heerenberg",
    "'s-Heerenbroek",
    "'s-Heerenhoek",
    'Heerenveen',
    'Heerewaarden',
    'Heerhugowaard',
    'Heerjansdam',
    'Heerle',
    'Heerlen',
    'Heesbeen',
    'Heesch',
    'Heesselt',
    'Heeswijk-Dinther',
    'Heeten',
    'Heeze',
    'Hegebeintum',
    'Hegelsom',
    'Hei- en Boeicop',
    'Heibloem',
    'Heide',
    'It Heidenskip',
    'Ter Heijde',
    'Heijen',
    'Heijenrath',
    'Heijningen',
    'Heikant',
    'Heilig Landstichting',
    'Heiligerlee',
    'Heiloo',
    'Heinenoord',
    'Heinkenszand',
    'Heino',
    'Hekelingen',
    'Hekendorp',
    'Helden',
    'Den Helder',
    'Helenaveen',
    'Hellendoorn',
    'Hellevoetsluis',
    'Hellouw',
    'Hellum',
    'Helmond',
    'Helvoirt',
    'Hem',
    'Hemelum',
    'Hemmen',
    'Hempens',
    'Hemrik',
    'Hendrik-Ido-Ambacht',
    'Hengelo',
    'Hengevelde',
    'Hengstdijk',
    'Hensbroek',
    'Herbaijum',
    'Herkenbosch',
    'Herkingen',
    'Hernen',
    'Herpen',
    'Herpt',
    'Herten',
    'Hertme',
    "'s-Hertogenbosch",
    'Herveld',
    'Herwen',
    'Herwijnen',
    'Heteren',
    'Heukelom',
    'Heukelum',
    'Heumen',
    'De Heurne',
    'Heusden',
    'Heveadorp',
    'Heythuysen',
    'Hezingen',
    'Hiaure',
    'Hichtum',
    'Hidaard',
    'Hierden',
    'Hieslum',
    'Hijken',
    'Hijum',
    'Hilaard',
    'Hillegom',
    'Hilvarenbeek',
    'Hilversum',
    'Hindeloopen',
    'Hinnaard',
    'Hippolytushoef',
    'Hitzum',
    'Hobrede',
    'Hoedekenskerke',
    'de Hoef',
    'Hoef en Haag',
    'Hoek',
    'Hoek van Holland',
    'Hoenderloo',
    'Hoensbroek',
    'Hoenzadriel',
    'De Hoeve',
    'Hoevelaken',
    'Hoeven',
    'Hoge Hexel',
    'Hollandsche Rading',
    'Hollandscheveld',
    'Hollum',
    'Holsloot',
    'Holten',
    'Holthees',
    'Holtheme',
    'Holthone',
    'Holtum',
    'Holwerd',
    'Holwierde',
    'Hommerts',
    'Homoet',
    'Honselersdijk',
    'Hoofddorp',
    'Hoofdplaat',
    'Hoog Soeren',
    'Hoogblokland',
    'Hooge Mierde',
    'Hooge Zwaluwe',
    'Hoogeloon',
    'Hoogenweg',
    'Hoogerheide',
    'Hoogersmilde',
    'Hoogeveen',
    'Hoogezand',
    'Hooghalen',
    'Hoogkarspel',
    'Hoog-Keppel',
    'Hoogland',
    'Hooglanderveen',
    'Hoogmade',
    'Hoogvliet Rotterdam',
    'Hoogwoud',
    'Hoorn',
    'Den Hoorn',
    'Hoornaar',
    'Hoornsterzwaag',
    'Horn',
    'Den Horn',
    'Hornhuizen',
    'Horssen',
    'Horst',
    'Den Hout',
    'Houten',
    'Houtigehage',
    'Houwerzijl',
    'Huijbergen',
    'Huis ter Heide',
    'Huisduinen',
    'Huisseling',
    'Huissen',
    'Huizen',
    'Huizinge',
    'Hulsberg',
    'Hulsel',
    'Hulshorst',
    'Hulst',
    'Hulten',
    'Hummelo',
    'Húns',
    'Hunsel',
    'Hurdegaryp',
    'Hurwenen',
    'Idaerd',
    'Idsegahuizum',
    'Idskenhuizen',
    'Ter Idzard',
    'Idzega',
    'Iens',
    'IJhorst',
    'IJlst',
    'IJmuiden',
    'IJsselham',
    'IJsselmuiden',
    'IJsselstein',
    'IJzendijke',
    'IJzendoorn',
    'Den Ilp',
    'Ilpendam',
    'Indijk',
    'Ingber',
    'Ingelum',
    'Ingen',
    'Itens',
    'Ittervoort',
    'Jaarsveld',
    'Jabeek',
    'Jannum',
    'Jellum',
    'Jelsum',
    'Jirnsum',
    'Jislum',
    'Jisp',
    'Jistrum',
    'Jonkerslân',
    'Jonkersvaart',
    'Joppe',
    'Jorwert',
    'Joure',
    'Jouswier',
    'Jubbega',
    'Julianadorp',
    'Jutrijp',
    'Kaag',
    'Kaard',
    'Kaatsheuvel',
    'Kalenberg',
    'Kallenkote',
    'Kamerik',
    'Kampen',
    'Kamperland',
    'Kamperveen',
    'Kantens',
    'Kapel Avezaath',
    'Kapel-Avezaath',
    'Kapelle',
    'Kapellebrug',
    'Katlijk',
    'Kats',
    'Kattendijke',
    'Katwijk',
    'Katwijk NB',
    'Katwoude',
    'Kedichem',
    'Keent',
    'Keijenborg',
    'Kekerdom',
    'Kelpen-Oler',
    'Kerk Avezaath',
    'Kerk-Avezaath',
    'Kerkdriel',
    'Kerkenveld',
    'Kerkrade',
    'Kerkwerve',
    'Kerkwijk',
    'Kessel',
    'Kesteren',
    'De Kiel',
    'Kiel-Windeweer',
    'Kilder',
    'Kimswerd',
    'Kinderdijk',
    'Kinnum',
    'Klaaswaal',
    'Klarenbeek',
    'Klazienaveen',
    'Klazienaveen-Noord',
    'Klein Zundert',
    'Klijndijk',
    'Klimmen',
    'Kloetinge',
    'De Klomp',
    'Klooster Lidlum',
    'Kloosterburen',
    'Kloosterhaar',
    'Kloosterzande',
    'Klundert',
    'Knegsel',
    'De Knipe',
    'Koarnjum',
    'Kockengen',
    'Koedijk',
    'Koekange',
    'Koewacht',
    'Kolderwolde',
    'Kolham',
    'Kolhorn',
    'Kollum',
    'Kollumerpomp',
    'Kollumerzwaag',
    'Kommerzijl',
    'Koningsbosch',
    'Koningslust',
    'De Koog',
    'Koog aan de Zaan',
    'Kootstertille',
    'Kootwijk',
    'Kootwijkerbroek',
    'Kornhorn',
    'Kornwerderzand',
    'Kortehemmen',
    'Kortenhoef',
    'Kortgene',
    'Koudekerk aan den Rijn',
    'Koudekerke',
    'Koudum',
    'Koufurderrige',
    'Krabbendijke',
    'Kraggenburg',
    'Kreileroord',
    'Krewerd',
    'De Krim',
    'Krimpen aan de Lek',
    'Krimpen aan den IJssel',
    'Kring van Dorth',
    'Krommenie',
    'Kronenberg',
    'Kropswolde',
    'Kruiningen',
    'Kruisland',
    'Kûbaard',
    'Kudelstaart',
    'Kuinre',
    'Kuitaart',
    'Kwadendamme',
    'Kwadijk',
    'De Kwakel',
    'Kwintsheul',
    'Laag Zuthem',
    'Laag-Keppel',
    'Laag-Soeren',
    'Lage Mierde',
    'Lage Vuursche',
    'Lage Zwaluwe',
    'Lageland',
    'Lageland GN',
    'Lambertschaag',
    'Lamswaarde',
    'Landerum',
    'Landgraaf',
    'Landhorst',
    'Landsmeer',
    'Langbroek',
    'Langedijke',
    'Langelille',
    'Langelo',
    'Langenboom',
    'Langerak',
    'Langeveen',
    'Langeweg',
    'Langezwaag',
    'Langweer',
    'Laren',
    'Lathum',
    'Lattrop-Breklenkamp',
    'Lauwersoog',
    'Lauwerzijl',
    'Ledeacker',
    'Leek',
    'Leende',
    'Leens',
    'Leerbroek',
    'Leerdam',
    'Leermens',
    'Leersum',
    'Leeuwarden',
    'Legemeer',
    'Leiden',
    'Leiderdorp',
    'Leidschendam',
    'Leimuiden',
    'Leimuiderbrug',
    'Lekkerkerk',
    'Lekkum',
    'Lellens',
    'Lelystad',
    'Lemele',
    'Lemelerveld',
    'Lemiers',
    'Lemmer',
    'Lengel',
    'Lent',
    'Leons',
    'Lepelstraat',
    'Lettelbert',
    'Lettele',
    'Leunen',
    'Leur',
    'Leusden',
    'Leuth',
    'Leutingewolde',
    'Leuvenheim',
    'Leveroy',
    'Lewedorp',
    'Lexmond',
    'Lichtaard',
    'Lichtenvoorde',
    'Liempde',
    'Lienden',
    'De Lier',
    'Lierderholthuis',
    'Lieren',
    'Lierop',
    'Lies',
    'Lieshout',
    'Liessel',
    'Lievelde',
    'Lieveren',
    'Lijnden',
    'Limbricht',
    'Limmen',
    'Linde',
    'Linden',
    'Linne',
    'Linschoten',
    'Lioessens',
    'Lippenhuizen',
    'Lisse',
    'Lisserbroek',
    'Lith',
    'Lithoijen',
    'Lobith',
    'Lochem',
    'Loenen',
    'Loenen aan de Vecht',
    'Loenersloot',
    'Loënga',
    'Loerbeek',
    'Lollum',
    'Lomm',
    'Longerhouw',
    'Loo Gld',
    "'t Loo Oldebroek",
    'Loon',
    'Loon op Zand',
    'Loosbroek',
    'Loosdrecht',
    'Loozen',
    'Lopik',
    'Lopikerkapel',
    'Loppersum',
    'Losdorp',
    'Losser',
    'Lottum',
    'Lucaswolde',
    'Luddeweer',
    'Luinjeberd',
    'Lunteren',
    'Lutjebroek',
    'Lutjegast',
    'Lutjewinkel',
    'de Lutte',
    'Luttelgeest',
    'Lutten',
    'Luttenberg',
    'Luxwoude',
    'Luyksgestel',
    'Lytsewierrum',
    'Maarheeze',
    'Maarn',
    'Maarsbergen',
    'Maarssen',
    'Maartensdijk',
    'Maasbommel',
    'Maasbracht',
    'Maasbree',
    'Maasdam',
    'Maasdijk',
    'Maashees',
    'Maasland',
    'Maassluis',
    'Maastricht',
    'Maastricht-Airport',
    'Maasvlakte Rotterdam',
    'Macharen',
    'Made',
    'Makkinga',
    'Makkum',
    'Malden',
    'Mander',
    'Manderveen',
    'Mantgum',
    'Mantinge',
    'Maren-Kessel',
    'Margraten',
    'Maria Hoop',
    'Mariahout',
    'Mariaparochie',
    'Mariënberg',
    'Mariënheem',
    'Mariënvelde',
    'Marijenkampen',
    'Markelo',
    'Marken',
    'Markenbinnen',
    'Marknesse',
    'Marle',
    'Marrum',
    'Marsum',
    'Marum',
    'Marwijksoord',
    'Mastenbroek',
    'Matsloot',
    'Maurik',
    'Mechelen',
    'Medemblik',
    'Meeden',
    'Meedhuizen',
    'Meerkerk',
    'Meerlo',
    'De Meern',
    'Meerssen',
    'Meerstad',
    'Meeuwen',
    'Megchelen',
    'Megen',
    'Meijel',
    'Melderslo',
    'Melick',
    'Meliskerke',
    'Melissant',
    'Menaam',
    'Mensingeweer',
    'Meppel',
    'Meppen',
    'Merkelbeek',
    'Merselo',
    'Meteren',
    'Meterik',
    'Metslawier',
    'Mheer',
    'Middelaar',
    'Middelburg',
    'Middelharnis',
    'Middelie',
    'Middelstum',
    'Middenbeemster',
    'Middenmeer',
    'Midlaren',
    'Midlum',
    'Midsland',
    'Midwolda',
    'Midwolde',
    'Midwoud',
    'Miedum',
    'Mierlo',
    'Mijdrecht',
    'Mijnsheerenland',
    'Mildam',
    'Milheeze',
    'Mill',
    'Millingen aan de Rijn',
    'Milsbeek',
    'Minnertsga',
    'Mirns',
    'Moddergat',
    'De Moer',
    'Moerdijk',
    'Moergestel',
    'Moerkapelle',
    'Moerstraten',
    'Molenaarsgraaf',
    'Molenhoek',
    'Molenschot',
    'Molkwerum',
    'Monnickendam',
    'Monster',
    'Montfoort',
    'Montfort',
    'Mook',
    'Mookhoek',
    'Moordrecht',
    'Moorveld',
    'Morra',
    'De Mortel',
    'Muiden',
    'Muiderberg',
    'Mûnein',
    'Munnekeburen',
    'Munnekezijl',
    'Munstergeleen',
    'Muntendam',
    'Mussel',
    'Musselkanaal',
    'Naaldwijk',
    'Naarden',
    'Nagele',
    'Nederasselt',
    'Nederhemert',
    'Nederhorst den Berg',
    'Nederland',
    'Nederweert',
    'Nederweert-Eind',
    'Neede',
    'Neer',
    'Neerijnen',
    'Neeritter',
    'Neerkant',
    'Neerlangel',
    'Neerloon',
    'Nes',
    'Netersel',
    'Netterden',
    'Niawier',
    'Nibbixwoud',
    'Niebert',
    'Niehove',
    'Niekerk',
    'Nietap',
    'Nieuw Annerveen',
    'Nieuw Beerta',
    'Nieuw- en Sint Joosland',
    'Nieuw Heeten',
    'Nieuw Namen',
    'Nieuw Scheemda',
    'Nieuwaal',
    'Nieuw-Amsterdam',
    'Nieuw-Balinge',
    'Nieuw-Beijerland',
    'Nieuw-Buinen',
    'Nieuw-Dordrecht',
    'Nieuwdorp',
    'Nieuwe Niedorp',
    'Nieuwe Pekela',
    'Nieuwe Wetering',
    'Nieuwebrug',
    'Nieuwediep',
    'Nieuwegein',
    'Nieuwehorne',
    'Nieuwendijk',
    'Nieuwer Ter Aa',
    'Nieuwerbrug aan den Rijn',
    'Nieuwerkerk',
    'Nieuwerkerk aan den IJssel',
    'Nieuweroord',
    'Nieuwersluis',
    'Nieuweschoot',
    'Nieuwe-Tonge',
    'Nieuwkoop',
    'Nieuwkuijk',
    'Nieuwland',
    'Nieuwlande',
    'Nieuwlande Coevorden',
    'Nieuw-Lekkerland',
    'Nieuwleusen',
    'Nieuwolda',
    'Nieuwpoort',
    'Nieuw-Roden',
    'Nieuw-Schoonebeek',
    'Nieuwstadt',
    'Nieuwveen',
    'Nieuw-Vennep',
    'Nieuwvliet',
    'Nieuw-Vossemeer',
    'Nieuw-Weerdinge',
    'Niezijl',
    'Niftrik',
    'Nigtevecht',
    'Nij Altoenae',
    'Nij Beets',
    'Nijbroek',
    'Nijeberkoop',
    'Nijega',
    'Nijehaske',
    'Nijeholtpade',
    'Nijeholtwolde',
    'Nijelamer',
    'Nijemirdum',
    'Nijensleek',
    'Nijetrijne',
    'Nijeveen',
    'Nijhuizum',
    'Nijkerk',
    'Nijkerkerveen',
    'Nijland',
    'Nijlande',
    'Nijmegen',
    'Nijverdal',
    'Nispen',
    'Nisse',
    'Nistelrode',
    'Noardburgum',
    'Nooitgedacht',
    'Noorbeek',
    'Noordbeemster',
    'Noordbroek',
    'Noordeinde',
    'Noordeinde Gld',
    'Noordeloos',
    'Noorden',
    'Noordgouwe',
    'Noordhoek',
    'Noordhorn',
    'Noordlaren',
    'Noord-Scharwoude',
    'Noordscheschut',
    'Noord-Sleen',
    'Noordwelle',
    'Noordwijk',
    'Noordwijkerhout',
    'Noordwolde',
    'Nootdorp',
    'Norg',
    'Notter',
    'Nuenen',
    'Nuis',
    'Nuland',
    'Numansdorp',
    'Nunhem',
    'Nunspeet',
    'Nuth',
    'Nutter',
    'Obbicht',
    'Obdam',
    'Ochten',
    'Odijk',
    'Odiliapeel',
    'Odoorn',
    'Odoornerveen',
    'Oeffelt',
    'Oegstgeest',
    'Oene',
    'Oentsjerk',
    'Den Oever',
    'Offingawier',
    'Ohé en Laak',
    'Oijen',
    'Oirlo',
    'Oirsbeek',
    'Oirschot',
    'Oisterwijk',
    'Okkenbroek',
    'Olburgen',
    'Oldeberkoop',
    'Oldebroek',
    'Oldeholtpade',
    'Oldeholtwolde',
    'Oldehove',
    'Oldekerk',
    'Oldelamer',
    'Oldemarkt',
    'Oldenzaal',
    'Oldenzijl',
    'Oldeouwer',
    'Oldetrijne',
    'Olst',
    'Olterterp',
    'Ommel',
    'Ommen',
    'Ommeren',
    'Onderdendam',
    'Onna',
    'Onnen',
    'Onstwedde',
    'Ooij',
    'Ooltgensplaat',
    'Oost West en Middelbeers',
    'Oostburg',
    'Oostdijk',
    'Oosteind',
    'Oosterbeek',
    'Oosterbierum',
    'Oosterblokker',
    'Oosterend',
    'Oosterhesselen',
    'Oosterhout',
    'Oosterland',
    'Oosterleek',
    'Oosternieland',
    'Oosternijkerk',
    'Oosterstreek',
    'Oosterwijk',
    'Oosterwijtwerd',
    'Oosterwolde',
    'Oosterwolde Gld',
    'Oosterzee',
    'Oost-Graftdijk',
    'Oosthem',
    'Oosthuizen',
    'Oostkapelle',
    'Oostknollendam',
    'Oostrum',
    'Oost-Souburg',
    'Oostvoorne',
    'Oostwold',
    'Oostwoud',
    'Oostzaan',
    'Ootmarsum',
    'Opeinde',
    'Opende',
    'Ophemert',
    'Opheusden',
    'Opijnen',
    'Oploo',
    'Opmeer',
    'Oppenhuizen',
    'Opperdoes',
    'Oranje',
    'Oranjewoud',
    'Orvelte',
    'Ospel',
    'Oss',
    'Ossendrecht',
    'Ossenisse',
    'Ossenwaard',
    'Ossenzijl',
    'Oterleek',
    'Otterlo',
    'Ottersum',
    'Ottoland',
    'Oud Ade',
    'Oud Annerveen',
    'Oud Gastel',
    'Oud Ootmarsum',
    'Oud Zuilen',
    'Oud-Alblas',
    'Oud-Beijerland',
    'Ouddorp',
    'Oude Meer',
    'Oude Niedorp',
    'Oude Pekela',
    'Oude Wetering',
    'Oude Willem',
    'Oudebildtzijl',
    'Oudega',
    'Oudehaske',
    'Oudehorne',
    'Oudelande',
    'Oudemirdum',
    'Oudemolen',
    'Oudenbosch',
    'Oudendijk',
    'Oudenhoorn',
    'Ouderkerk aan de Amstel',
    'Ouderkerk aan den IJssel',
    'Oudeschans',
    'Oudeschild',
    'Oudeschip',
    'Oudeschoot',
    'Oudesluis',
    'Oude-Tonge',
    'Oudewater',
    'Oudezijl',
    'Oudheusden',
    'Oudkarspel',
    'Oudorp',
    'Oud-Vossemeer',
    'Oudwoude',
    'Ouwerkerk',
    'Ouwsterhaule',
    'Ouwster-Nijega',
    'Overasselt',
    'Overberg',
    'Overdinkel',
    'Overlangel',
    'Overloon',
    'Overschild',
    'Overslag',
    'Overveen',
    'Ovezande',
    'Paasloo',
    'Paesens',
    'Pannerden',
    'Panningen',
    'Papekop',
    'Papendrecht',
    'Papenhoven',
    'Papenvoort',
    'Parrega',
    'Paterswolde',
    'Peest',
    'Peins',
    'Peize',
    'Peperga',
    'Pernis Rotterdam',
    'Persingen',
    'Pesse',
    'Petten',
    'Philippine',
    'Piaam',
    'Piershil',
    'Pieterburen',
    'Pietersbierum',
    'Pieterzijl',
    'Pijnacker',
    'Pingjum',
    'Plasmolen',
    'Poederoijen',
    'Poeldijk',
    'De Pol',
    'Polsbroek',
    'Poortugaal',
    'Poortvliet',
    'Poppenwier',
    'Ten Post',
    'Posterholt',
    'Prinsenbeek',
    'Puiflijk',
    'De Punt',
    'Punthorst',
    'Purmer',
    'Purmerend',
    'Purmerland',
    'Puth',
    'Putte',
    'Putten',
    'Puttershoek',
    'Raalte',
    'Raamsdonk',
    'Raamsdonksveer',
    'Raard',
    'Radewijk',
    'Radio Kootwijk',
    'Raerd',
    'Randwijk',
    'Ransdaal',
    'Rasquert',
    'Ravenstein',
    'Ravenswaaij',
    'Ravenswoud',
    'Readtsjerk',
    'Reahûs',
    'Reduzum',
    'Reek',
    'Reeuwijk',
    'Reijmerstok',
    'Reitsum',
    'Rekken',
    'Renesse',
    'Renkum',
    'Renswoude',
    'Ressen',
    'Retranchement',
    'Reusel',
    'Reutum',
    'Reuver',
    'Rha',
    'Rheden',
    'Rhee',
    'Rheeze',
    'Rheezerveen',
    'Rhenen',
    'Rhenoy',
    'Rhoon',
    'Ridderkerk',
    'Ried',
    'Riel',
    'Rien',
    'Riethoven',
    'Rietmolen',
    'Rijen',
    'Rijkevoort',
    'Rijkevoort-De Walsert',
    'Rijnsaterwoude',
    'Rijnsburg',
    'De Rijp',
    'Rijpwetering',
    'Rijs',
    'Rijsbergen',
    'Rijsenhout',
    'Rijssen',
    'Rijswijk',
    'Rilland',
    'Rinsumageast',
    'De Rips',
    'Ritthem',
    'Rockanje',
    'Roden',
    'Roderesch',
    'Roderwolde',
    'Roelofarendsveen',
    'Roermond',
    'Rogat',
    'Roggel',
    'Rohel',
    'Rolde',
    'Roodeschool',
    'Roosendaal',
    'Roosteren',
    'Rosmalen',
    'Rossum',
    'Roswinkel',
    'Rotstergaast',
    'Rotsterhaule',
    'Rotterdam',
    'Rotterdam-Albrandswaard',
    'Rottevalle',
    'Rottum',
    'Rouveen',
    'Rozenburg',
    'Rozendaal',
    'Rucphen',
    'Ruigahuizen',
    'Ruinen',
    'Ruinerwold',
    'Rumpt',
    'Rutten',
    'Ruurlo',
    'Ryptsjerk',
    'Saaksum',
    'Saasveld',
    'Saaxumhuizen',
    'Sambeek',
    'Sandfirden',
    'Santpoort-Noord',
    'Santpoort-Zuid',
    'Sappemeer',
    'Sas van Gent',
    'Sassenheim',
    'Sauwerd',
    'Schagen',
    'Schagerbrug',
    'Schaijk',
    'Schalkhaar',
    'Schalkwijk',
    'Schalsum',
    'Schardam',
    'Scharendijke',
    'Scharmer',
    'Scharnegoutum',
    'Scharsterbrug',
    'Scharwoude',
    'Scheemda',
    'Scheerwolde',
    'Schellinkhout',
    'Schelluinen',
    'Schermerhorn',
    'Scherpenisse',
    'Scherpenzeel',
    'Schettens',
    'Scheulder',
    'Schiedam',
    'Schiermonnikoog',
    'Schijf',
    'Schijndel',
    'Schildwolde',
    'Schimmert',
    'Schin op Geul',
    'Schinnen',
    'Schinveld',
    'Schipborg',
    'Schiphol',
    'Schiphol-Rijk',
    'De Schiphorst',
    'Schipluiden',
    'Schokland',
    'Schoondijke',
    'Schoonebeek',
    'Schoonhoven',
    'Schoonloo',
    'Schoonoord',
    'Schoonrewoerd',
    'Schoorl',
    'Schore',
    'Schouwerzijl',
    'Schraard',
    'Schuinesloot',
    'Sebaldeburen',
    'Sellingen',
    'Serooskerke',
    'Sevenum',
    'Sexbierum',
    'Sibculo',
    'Sibrandabuorren',
    'Sibrandahûs',
    'Siddeburen',
    'Siebengewald',
    'Siegerswoude',
    'Sijbekarspel',
    'Silvolde',
    'Simonshaven',
    'Simpelveld',
    'Sinderen',
    'Sint Agatha',
    'Sint Annen',
    'Sint Anthonis',
    'Sint Geertruid',
    'Sint Hubert',
    'Sint Jansklooster',
    'Sint Jansteen',
    'Sint Joost',
    'Sint Kruis',
    'Sint Maarten',
    'Sint Maartensbrug',
    'Sint Maartensvlotbrug',
    'Sint Nicolaasga',
    'Sint Odiliënberg',
    'Sint Pancras',
    'Sint Philipsland',
    'St. Willebrord',
    'Sint-Annaland',
    'St.-Annaparochie',
    'St.-Jacobiparochie',
    'Sintjohannesga',
    'Sint-Maartensdijk',
    'Sint-Michielsgestel',
    'Sint-Oedenrode',
    'Sirjansland',
    'Sittard',
    'Skingen',
    'Slagharen',
    'Slappeterp',
    'Sleen',
    'Sleeuwijk',
    'Slenaken',
    'Sliedrecht',
    'Slijkenburg',
    'Slijk-Ewijk',
    'Slochteren',
    'Slootdorp',
    'Sloten',
    'Sluis',
    'Sluiskil',
    'Smakt',
    'Smalle Ee',
    'Smallebrugge',
    'Smilde',
    'Snakkerburen',
    'Sneek',
    'Snelrewaard',
    'Snikzwaag',
    'Soerendonk',
    'Soest',
    'Soesterberg',
    'Someren',
    'Sommelsdijk',
    'Son en Breugel',
    'Sondel',
    'Sonnega',
    'Spaarndam',
    'Spaarndam gem. Haarlem',
    'Spanbroek',
    'Spanga',
    'Spankeren',
    'Spannum',
    'Spaubeek',
    'Spier',
    'Spierdijk',
    'Spijk',
    'Spijkenisse',
    'Spijkerboor',
    'Sprang-Capelle',
    'Sprundel',
    'Spui',
    "Stad aan 't Haringvliet",
    'Stadskanaal',
    'Stampersgat',
    'Standdaarbuiten',
    'Staphorst',
    'Starnmeer',
    'Startenhuizen',
    'Stavenisse',
    'Stavoren',
    'Stedum',
    'De Steeg',
    'Steenbergen',
    'Steendam',
    'Steenderen',
    'Steenenkamer',
    'Steensel',
    'Steenwijk',
    'Steenwijkerwold',
    'Stegeren',
    'Steggerda',
    'Stein',
    'Stellendam',
    'Sterksel',
    'Stevensbeek',
    'Stevensweert',
    'Steyl',
    'Stieltjeskanaal',
    'Stiens',
    'Stitswerd',
    'Stokkum',
    'Stolwijk',
    'Stompetoren',
    'Stoutenburg',
    'Stoutenburg Noord',
    'Stramproy',
    'Streefkerk',
    'Striep',
    'Strijbeek',
    'Strijen',
    'Strijensas',
    'Stroe',
    'Stroobos',
    'Stuifzand',
    'Sumar',
    'Surhuisterveen',
    'Surhuizum',
    'Susteren',
    'Suwâld',
    'Swalmen',
    'Sweikhuizen',
    'Swichum',
    'Swifterbant',
    'Swolgen',
    'Taarlo',
    'Teeffelen',
    'Teerns',
    'Tegelen',
    'Terband',
    'Terborg',
    'Terheijden',
    'Terherne',
    'Terhole',
    'Terkaple',
    'Termunten',
    'Termunterzijl',
    'Ternaard',
    'Terneuzen',
    'Teroele',
    'Terschuur',
    'Tersoal',
    'Terwispel',
    'Terwolde',
    'Teteringen',
    'Teuge',
    'Thesinge',
    'Tholen',
    'Thorn',
    'Tiel',
    'Tiendeveen',
    'Tienhoven',
    'Tienhoven aan de Lek',
    'Tienray',
    'Tijnje',
    'De Tike',
    'Tilburg',
    'Tilligte',
    'Tinallinge',
    'Tinte',
    'Tirns',
    'Tjalhuizum',
    'Tjalleberd',
    'Tjerkgaast',
    'Tjerkwerd',
    'Tjuchem',
    'Tolbert',
    'Toldijk',
    'Tolkamer',
    'Tollebeek',
    'Tonden',
    'Toornwerd',
    'Tricht',
    'Triemen',
    'Tripscompagnie',
    'Tubbergen',
    'Tuil',
    'Tuitjenhorn',
    'Tuk',
    "Tull en 't Waal",
    'Twello',
    'Twijzel',
    'Twijzelerheide',
    'Twisk',
    'Tynaarlo',
    'Tytsjerk',
    'Tzum',
    'Tzummarum',
    'Ubbena',
    'Ubbergen',
    'Uddel',
    'Uden',
    'Udenhout',
    'Uffelte',
    'Ugchelen',
    'Uitdam',
    'Uitgeest',
    'Uithoorn',
    'Uithuizen',
    'Uithuizermeeden',
    'Uitwellingerga',
    'Uitwijk',
    'Ulestraten',
    'Ulft',
    'Ulicoten',
    'Ulrum',
    'Ulvenhout',
    'Ulvenhout AC',
    'Ureterp',
    'Urk',
    'Urmond',
    'Ursem',
    'Usquert',
    'Utrecht',
    'Vaals',
    'Vaassen',
    'Valburg',
    'Valkenburg',
    'Valkenswaard',
    'Valthe',
    'Valthermond',
    '2e Valthermond',
    'Varik',
    'Varsselder',
    'Varsseveld',
    'Vasse',
    'Veelerveen',
    'Veen',
    'Veendam',
    'Veenendaal',
    'De Veenhoop',
    'Veenhuizen',
    'Veeningen',
    'Veenklooster',
    'Veenoord',
    'Veere',
    'Veessen',
    'Vegelinsoord',
    'Veghel',
    "'t Veld",
    'Velddriel',
    'Den Velde',
    'Velden',
    'Veldhoven',
    'Velp',
    'Velsen-Noord',
    'Velsen-Zuid',
    'Velserbroek',
    'Venebrugge',
    'Venhorst',
    'Venhuizen',
    'Venlo',
    'Venray',
    'Ven-Zelderheide',
    'Vessem',
    'Vethuizen',
    'Veulen',
    'Vianen',
    'Vianen NB',
    'Vierakker',
    'Vierhouten',
    'Vierhuizen',
    'Vierlingsbeek',
    'Vierpolders',
    'Vijfhuizen',
    'Vijlen',
    'Vilsteren',
    'Vinkega',
    'Vinkel',
    'Vinkenbuurt',
    'Vinkeveen',
    'Visvliet',
    'Vlaardingen',
    'Vlagtwedde',
    'Vledder',
    'Vledderveen',
    'Vleuten',
    'Vlieland',
    'Vlierden',
    'Vlijmen',
    'Vlissingen',
    'Vlist',
    'Vlodrop',
    'Voerendaal',
    'Vogelenzang',
    'Vogelwaarde',
    'Volendam',
    'Volkel',
    'Vollenhove',
    'Vondelingenplaat Rotterdam',
    'Voorburg',
    'Voorhout',
    'Voorschoten',
    'Voorst',
    'Voorthuizen',
    'Vorchten',
    'Vorden',
    'Vorstenbosch',
    'Vortum-Mullem',
    'Vragender',
    'Vredenheim',
    'Vredepeel',
    'Vreeland',
    'Vries',
    'Vriescheloo',
    'Vriezenveen',
    'Vroomshoop',
    'Vrouwenakker',
    'Vrouwenparochie',
    'Vrouwenpolder',
    'Vught',
    'Vuren',
    'Waaksens',
    'Waal',
    'De Waal',
    'Waalre',
    'Waalwijk',
    "'t Waar",
    'Waarde',
    'Waardenburg',
    'Waarder',
    'Waardhuizen',
    'Waarland',
    'Waaxens',
    'Wachtum',
    'Waddinxveen',
    'Wadenoijen',
    'Wagenberg',
    'Wagenborgen',
    'Wageningen',
    'Walem',
    'Walsoorden',
    'Wâlterswâld',
    'Wamel',
    'Wanneperveen',
    'Wanroij',
    'Wanssum',
    'Wânswert',
    'Wapenveld',
    'Wapse',
    'Wapserveen',
    'Warder',
    'Warffum',
    'Warfhuizen',
    'Warfstermolen',
    'Warmenhuizen',
    'Warmond',
    'Warns',
    'Warnsveld',
    'Warstiens',
    'Warten',
    'Waskemeer',
    'Waspik',
    'Wassenaar',
    'Wateren',
    'Watergang',
    'Waterhuizen',
    'Wateringen',
    'Waterlandkerkje',
    'Waverveen',
    'Wedde',
    'De Weere',
    'Weerselo',
    'Weert',
    'Weesp',
    'Wehe-den Hoorn',
    'Wehl',
    'Weidum',
    'Weiteveen',
    'Wekerom',
    'Well',
    'Well L',
    'Wellerlooi',
    'Welsum',
    'Wemeldinge',
    'Wenum Wiesel',
    'Wergea',
    'Werkendam',
    'Werkhoven',
    'Wernhout',
    'Wervershoof',
    'Wesepe',
    'Wessem',
    'Westbeemster',
    'Westbroek',
    'Westdorp',
    'Westdorpe',
    'Westendorp',
    'Westerbeek',
    'Westerbork',
    'Westerbroek',
    'De Westereen',
    'Westeremden',
    'Westergeest',
    'Westerhaar-Vriezenveensewijk',
    'Westerhoven',
    'Westerland',
    'Westerlee',
    'Westernieland',
    'Westervelde',
    'Westervoort',
    'Westerwijtwerd',
    'West-Graftdijk',
    'Westhem',
    'Westhoek',
    'Westkapelle',
    'Westknollendam',
    'Westmaas',
    'West-Terschelling',
    'Westwoud',
    'Westzaan',
    'Wetering',
    'Weteringbrug',
    'Wetsens',
    'Wetsinge',
    'Weurt',
    'Wezep',
    'Wezup',
    'Wezuperbrug',
    'Wichmond',
    'Wier',
    'Wierden',
    'Wieringerwaard',
    'Wieringerwerf',
    'Wierum',
    'Wijchen',
    'Wijckel',
    'Wijdenes',
    'Wijdewormer',
    'Wijhe',
    'de Wijk',
    'Wijk aan Zee',
    'Wijk bij Duurstede',
    'Wijk en Aalburg',
    'Wijlre',
    'Wijnaldum',
    'Wijnandsrade',
    'Wijnbergen',
    'Wijngaarden',
    'Wijnjewoude',
    'Wijster',
    'Wilbertoord',
    'Wildervank',
    'De Wilgen',
    'Wilhelminadorp',
    'Wilhelminaoord',
    'Willemsoord',
    'Willemstad',
    'Wilnis',
    'Wilp',
    'De Wilp',
    'Wilsum',
    'Winde',
    'Windraak',
    'Winkel',
    'Winneweer',
    'Winschoten',
    'Winssen',
    'Winsum',
    'Wintelre',
    'Winterswijk',
    'Winterswijk Brinkheurne',
    'Winterswijk Corle',
    'Winterswijk Henxel',
    'Winterswijk Huppel',
    'Winterswijk Kotten',
    'Winterswijk Meddo',
    'Winterswijk Miste',
    'Winterswijk Ratum',
    'Winterswijk Woold',
    'Wirdum',
    'Wissenkerke',
    'Witharen',
    'Witmarsum',
    'Witte Paarden',
    'Wittelte',
    'Wittem',
    'Witteveen',
    'Wiuwert',
    'Wjelsryp',
    'Woensdrecht',
    'Woerden',
    'Woerdense Verlaat',
    'Wognum',
    'Woldendorp',
    'Wolfheze',
    'Wolphaartsdijk',
    'Wolsum',
    'Woltersum',
    'Wolvega',
    'Wommels',
    'Wons',
    'Workum',
    'Wormer',
    'Wormerveer',
    'Woubrugge',
    'Woudbloem',
    'de Woude',
    'Woudenberg',
    'Woudrichem',
    'Woudsend',
    'Wouw',
    'Wouwse Plantage',
    'Wyns',
    'Wytgaard',
    'Yde',
    'Yerseke',
    'Ypecolsga',
    'Ysbrechtum',
    'Ysselsteyn',
    'Zaamslag',
    'Zaandam',
    'Zaandijk',
    'Zalk',
    'Zaltbommel',
    "'t Zand",
    'Zandberg',
    'Zandeweer',
    'Zandhuizen',
    'Zandpol',
    "'t Zandt",
    'Zandvoort',
    'Zeddam',
    'Zeegse',
    'Zeeland',
    'Zeerijp',
    'Zeewolde',
    'Zegge',
    'Zegveld',
    'Zeijen',
    'Zeijerveen',
    'Zeijerveld',
    'Zeist',
    'Zelhem',
    'Zenderen',
    'Zennewijnen',
    'Zetten',
    'Zevenaar',
    'Zevenbergen',
    'Zevenbergschen Hoek',
    'Zevenhoven',
    'Zevenhuizen',
    'Zierikzee',
    'Zieuwent',
    'Zijderveld',
    'Zijdewind',
    'Zijldijk',
    'De Zilk',
    'Zoelen',
    'Zoelmond',
    'Zoetermeer',
    'Zoeterwoude',
    'Zonnemaire',
    'Zorgvlied',
    'Zoutelande',
    'Zoutkamp',
    'Zuid-Beijerland',
    'Zuidbroek',
    'Zuiddorpe',
    'Zuidermeer',
    'Zuiderwoude',
    'Zuidhorn',
    'Zuidlaarderveen',
    'Zuidland',
    'Zuidlaren',
    'Zuidoostbeemster',
    'Zuid-Scharwoude',
    'Zuidschermer',
    'Zuidveen',
    'Zuidveld',
    'Zuidvelde',
    'Zuidwolde',
    'Zuidzande',
    'Zuilichem',
    'Zuna',
    'Zundert',
    'Zurich',
    'Zutphen',
    'Zuurdijk',
    'Zwaag',
    'Zwaagdijk-Oost',
    'Zwaagdijk-West',
    'Zwaanshoek',
    'Zwagerbosch',
    'Zwammerdam',
    'Zwanenburg',
    'Zwartebroek',
    'Zwartemeer',
    'Zwartewaal',
    'Zwartsluis',
    'Zweeloo',
    'Zweins',
    'Zwiggelte',
    'Zwijndrecht',
    'Zwinderen',
    'Zwolle',
];
