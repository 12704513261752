import { trans } from '../../../../../../helpers/trans';

export const generalItems = [
    {
        title: trans('faq.general.items.0.title'),
        text: trans('faq.general.items.0.text'),
        steps: [
            trans('faq.general.items.0.steps.0'),
            trans('faq.general.items.0.steps.1'),
            trans('faq.general.items.0.steps.2'),
            trans('faq.general.items.0.steps.3'),
            trans('faq.general.items.0.steps.4'),
            trans('faq.general.items.0.steps.5'),
        ],
    },
    {
        title: trans('faq.general.items.1.title'),
        text: trans('faq.general.items.1.text'),
    },
    {
        title: trans('faq.general.items.2.title'),
        steps: [
            trans('faq.general.items.2.steps.0'),
            trans('faq.general.items.2.steps.1'),
            trans('faq.general.items.2.steps.2'),
            trans('faq.general.items.2.steps.3'),
            trans('faq.general.items.2.steps.4'),
            trans('faq.general.items.2.steps.5'),
            trans('faq.general.items.2.steps.6'),
            trans('faq.general.items.2.steps.7'),
        ],
    },
    {
        title: trans('faq.general.items.3.title'),
        text: trans('faq.general.items.3.text'),
    },
    {
        title: trans('faq.general.items.4.title'),
        text: trans('faq.general.items.4.text'),
        steps: [
            trans('faq.general.items.4.steps.0'),
            trans('faq.general.items.4.steps.1'),
            trans('faq.general.items.4.steps.2'),
            trans('faq.general.items.4.steps.3'),
        ],
        footerText: trans('faq.general.items.4.footerText'),
    },
    {
        title: trans('faq.general.items.5.title'),
        text: trans('faq.general.items.5.text'),
    },
];

export const employeeItems = [
    {
        title: trans('faq.employee.items.0.title'),
        text: trans('faq.employee.items.0.text'),
    },
    {
        title: trans('faq.employee.items.1.title'),
        text: trans('faq.employee.items.1.text'),
    },
    {
        title: trans('faq.employee.items.2.title'),
        text: trans('faq.employee.items.2.text'),
        steps: [
            trans('faq.employee.items.2.steps.0'),
            trans('faq.employee.items.2.steps.1'),
            trans('faq.employee.items.2.steps.2'),
            trans('faq.employee.items.2.steps.3'),
        ],
    },
    {
        title: trans('faq.employee.items.3.title'),
        text: trans('faq.employee.items.3.text'),
    },
    {
        title: trans('faq.employee.items.4.title'),
        text: trans('faq.employee.items.4.text'),
    },
    {
        title: trans('faq.employee.items.5.title'),
        steps: [
            trans('faq.employee.items.5.steps.0'),
            trans('faq.employee.items.5.steps.1'),
            trans('faq.employee.items.5.steps.2'),
            trans('faq.employee.items.5.steps.3'),
        ],
    },
    {
        title: trans('faq.employee.items.6.title'),
        text: trans('faq.employee.items.6.text'),
    },
    {
        title: trans('faq.employee.items.7.title'),
        text: trans('faq.employee.items.7.text'),
    },
    {
        title: trans('faq.employee.items.8.title'),
        text: trans('faq.employee.items.8.text'),
    },
    {
        title: trans('faq.employee.items.9.title'),
        text: trans('faq.employee.items.9.text'),
    },
    {
        title: trans('faq.employee.items.10.title'),
        text: trans('faq.employee.items.10.text'),
    },
];

export const studentItems = [
    {
        title: trans('faq.student.items.0.title'),
        text: trans('faq.student.items.0.text'),
    },
    {
        title: trans('faq.student.items.1.title'),
        text: trans('faq.student.items.1.text'),
    },
    {
        title: trans('faq.student.items.2.title'),
        steps: [
            trans('faq.student.items.2.steps.0'),
            trans('faq.student.items.2.steps.1'),
            trans('faq.student.items.2.steps.2'),
            trans('faq.student.items.2.steps.3'),
            trans('faq.student.items.2.steps.4'),
            trans('faq.student.items.2.steps.5'),
            trans('faq.student.items.2.steps.6'),
        ],
    },
    {
        title: trans('faq.student.items.3.title'),
        text: trans('faq.student.items.3.text'),
    },
    {
        title: trans('faq.student.items.4.title'),
        text: trans('faq.student.items.4.text'),
    },
];

export const applyAnonymousItems = [
    {
        title: trans('faq.applyAnonymous.items.0.title'),
        steps: [
            trans('faq.applyAnonymous.items.0.steps.0'),
            trans('faq.applyAnonymous.items.0.steps.1'),
            trans('faq.applyAnonymous.items.0.steps.2'),
        ],
    },
    {
        title: trans('faq.applyAnonymous.items.1.title'),
        text: trans('faq.applyAnonymous.items.1.text'),
    },
    {
        title: trans('faq.applyAnonymous.items.2.title'),
        text: trans('faq.applyAnonymous.items.2.text'),
    },
    {
        title: trans('faq.applyAnonymous.items.3.title'),
        text: trans('faq.applyAnonymous.items.3.text'),
    },
    {
        title: trans('faq.applyAnonymous.items.4.title'),
        text: trans('faq.applyAnonymous.items.4.text'),
    },
    {
        title: trans('faq.applyAnonymous.items.5.title'),
        text: trans('faq.applyAnonymous.items.5.text'),
    },
];
