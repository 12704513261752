import { parseToRgb } from 'polished';

import colors from './colors';

const sizes = {
    sm: '0 5px 12px 0',
    smInverted: '0 -5px 12px 0',
    md: '1px 2px 4px 1px',
    lg: '1px 2px 17px 1px',
    hoverSm: '0 8px 15px 0',
    whiteBg: '3px 5px 20px',
};

const opacity = {
    sm: '0.2',
    smInverted: '0.2',
    md: '0.21',
    lg: '0.31',
    hoverSm: '0.2',
    whiteBg: '0.5',
};

export default (size = 'md', color = 'shadow') => {
    const c = parseToRgb(colors[color]);
    return (`${sizes[size]} rgba(${c.red},${c.green},${c.blue}, ${opacity[size]})`);
};
