import { maxShortTextLength } from '../../../../constants';
import { sanitizeAllHtml } from '../../../../helpers/sanitizeHtml';
import { ApplicationRejectionFormData } from '../../../../models/ApplicationRejections';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMaxStringLength,
} from '../../../../services/ValidationService';

export type ApplicationRejectionFormErrors = FormErrors<ApplicationRejectionFormData>;

export const validateApplicationRejectionFormData = (
    formData: ApplicationRejectionFormData,
): FormValidation<ApplicationRejectionFormErrors> => {
    const sanitizedMessage = sanitizeAllHtml(formData.message);

    const errors: ApplicationRejectionFormErrors = {
        message: validateMaxStringLength('content', sanitizedMessage, maxShortTextLength),
    };

    return validateForm<ApplicationRejectionFormErrors>(errors);
};
