import { getLocaleISOString } from '../helpers/date';
import { SingleRelationship } from '../japi/types/Relationships';

export interface SchoolExperienceResource {
    type: 'school-experiences';
    id: string;
    attributes: {
        educationLevel?: string;
        educationLevelId?: string;
        educationSubLevel?: string;
        educationSubLevelId?: string;
        learningPath?: string;
        learningPathId?: string;
        fieldOfStudy?: string;
        fieldOfStudyId?: string;
        school?: string;
        schoolId?: string;
        startDate?: string;
        endDate?: string;
    };
}

export interface SchoolExperience {
    id: string;
    educationLevel: string;
    educationLevelId: string;
    educationSubLevel?: string;
    educationSubLevelId?: string;
    learningPath?: string;
    learningPathId?: string;
    fieldOfStudy: string;
    fieldOfStudyId: string;
    school: string;
    schoolId: string;
    startDate: Date;
    endDate: Date;
}

export interface SchoolExperienceRequest {
    data: {
        type: 'school-experiences';
        id?: string;
        attributes: {
            startDate: string;
            endDate: string;
        };
        relationships: {
            educationLevel: SingleRelationship<'education-levels'>;
            fieldOfStudy?: SingleRelationship<'fields-of-study'>;
            school?: SingleRelationship<'schools'>;
            learningPath?: SingleRelationship<'learning-paths'>;
            person: SingleRelationship<'people'>;
        };
    };
}

export type SchoolExperienceFormData = SchoolExperience;

export const defaultSchoolExperience = (): SchoolExperience => ({
    id: '',
    educationLevel: '',
    educationLevelId: '',
    educationSubLevel: '',
    educationSubLevelId: '',
    fieldOfStudy: '',
    fieldOfStudyId: '',
    school: '',
    schoolId: '',
    startDate: new Date(),
    endDate: new Date(),
});

export const transformToSchoolExperience = (schoolExperienceResource: SchoolExperienceResource): SchoolExperience => ({
    id: schoolExperienceResource.id,
    educationLevel: schoolExperienceResource.attributes.educationLevel || '',
    educationLevelId: schoolExperienceResource.attributes.educationLevelId || '',
    educationSubLevel: schoolExperienceResource.attributes.educationSubLevel || '',
    educationSubLevelId: schoolExperienceResource.attributes.educationSubLevelId || '',
    learningPath: schoolExperienceResource.attributes.learningPath || '',
    learningPathId: schoolExperienceResource.attributes.learningPathId || '',
    fieldOfStudy: schoolExperienceResource.attributes.fieldOfStudy || '',
    fieldOfStudyId: schoolExperienceResource.attributes.fieldOfStudyId || '',
    school: schoolExperienceResource.attributes.school || '',
    schoolId: schoolExperienceResource.attributes.schoolId || '',
    startDate: schoolExperienceResource.attributes.startDate
        ? new Date(schoolExperienceResource.attributes.startDate)
        : new Date(),
    endDate: schoolExperienceResource.attributes.endDate
        ? new Date(schoolExperienceResource.attributes.endDate)
        : new Date(),
});

export const transformToSchoolExperienceRequest = (schoolExperience: SchoolExperience, personUuid: string): SchoolExperienceRequest => ({
    data: {
        type: 'school-experiences',
        ...(schoolExperience.id && { id: schoolExperience.id }),
        attributes: {
            startDate: getLocaleISOString(schoolExperience.startDate),
            endDate: getLocaleISOString(schoolExperience.endDate),
        },
        relationships: {
            educationLevel: {
                data: {
                    id: schoolExperience.educationSubLevelId || schoolExperience.educationLevelId,
                    type: 'education-levels',
                },
            },
            ...(schoolExperience.fieldOfStudyId && {
                fieldOfStudy: {
                    data: {
                        id: schoolExperience.fieldOfStudyId,
                        type: 'fields-of-study',
                    },
                },
            }),
            ...(schoolExperience.schoolId && {
                school: {
                    data: {
                        id: schoolExperience.schoolId,
                        type: 'schools',
                    },
                },
            }),
            ...(schoolExperience.learningPathId && {
                learningPath: {
                    data: {
                        id: schoolExperience.learningPathId,
                        type: 'learning-paths',
                    },
                },
            }),
            person: {
                data: {
                    id: personUuid,
                    type: 'people',
                },
            },
        },
    },
});
