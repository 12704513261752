import React, { Component, createRef } from 'react';

import PropTypes from 'prop-types';
import { config, Transition } from 'react-spring/renderprops.cjs';

import { trans } from '../../../helpers/trans';
import { getKeyCode, keyCodes } from '../../helpers/getKeyCode';
import Icon from '../../styledComponents/components/Icon';
import { Laptop } from '../../styledComponents/components/MediaQueries';
import {
    ClickArea,
    CloseButton,
    Content,
    ContentMain,
    OverlayContainer,
    Sidebar,
    SidebarButton,
    SidebarContent,
    SidebarWrapper,
} from './style';

class Overlay extends Component {
    constructor() {
        super();

        this.overlayContainer = createRef();

        this.state = {
            sidebarOpened: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { overlayIsOpen, children, closeWithEsc } = this.props;

        if (
            typeof window !== 'undefined'
            && (
                ((prevProps.children === null || !prevProps.children) && children !== null)
                || (!prevProps.overlayIsOpen && overlayIsOpen)
            )
        ) {
            document.body.style.overflow = 'hidden';
        }

        if (typeof document !== 'undefined' && closeWithEsc) {
            document.onkeydown = e => {
                if (children && getKeyCode(e) === keyCodes.escape) {
                    this.onClose();
                }
            };
        }

        if (
            typeof window !== 'undefined'
            && (
                (prevProps.children !== null && (children === null || !children))
                || (prevProps.overlayIsOpen && !overlayIsOpen)
            )
        ) {
            document.body.style.overflow = 'auto';
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            document.body.style.overflow = 'auto';
        }
    }

    onClose() {
        if (typeof window !== 'undefined') {
            document.body.style.overflow = 'auto';
            this.props.onClose();
        }
    }

    toggleSidebar(open = null) {
        this.setState(state => ({ sidebarOpened: open || !state.sidebarOpened }));
    }

    render() {
        const {
            children,
            sidebarContent,
            dark,
            black,
            fullBlack,
            dontCloseOnclick,
            maxWidth,
            cardless,
            contentCentered,
            contentMainClassName,
            showCloseButton,
        } = this.props;

        const { sidebarOpened } = this.state;

        return (
            <Transition
                items={!!children}
                from={{ opacity: 0 }}
                enter={{ opacity: 1 }}
                leave={{ opacity: 0 }}
            >
                {hasChilds => hasChilds && (overlayStyles => (
                    <Laptop>
                        {matches => (
                            <OverlayContainer
                                id="overlay"
                                dark={dark}
                                black={black}
                                fullBlack={fullBlack}
                                style={overlayStyles}
                                ref={this.overlayContainer}
                            >
                                {(dark && !dontCloseOnclick)
                                && <ClickArea onClick={() => this.props.onClose()} />}
                                {((!dark || cardless) && matches) && (
                                    <CloseButton
                                        fullBlack={fullBlack}
                                        id="overlay-cardless-close"
                                        light={cardless}
                                        onClick={() => this.props.onClose()}
                                    >
                                        <Icon kind="exit" size={15} mr="10px" />
                                        {trans('actions.close')}
                                    </CloseButton>
                                )}
                                <Content
                                    maxWidth={maxWidth}
                                    sidebar={!!sidebarContent}
                                >
                                    <Transition
                                        items={!!children}
                                        from={{ opacity: 0, transform: 'translateY(40px)' }}
                                        enter={{ opacity: 1, transform: 'translateY(0px)' }}
                                        leave={{ opacity: 0, transform: 'translateY(40px)' }}
                                        delay={200}
                                        config={config.wobbly}
                                    >
                                        {hasChildren => hasChildren && (styles => (
                                            <ContentMain
                                                contentCentered={contentCentered}
                                                style={styles}
                                                cardless={cardless}
                                                className={contentMainClassName}
                                                onClick={
                                                    sidebarOpened
                                                        ? () => this.toggleSidebar(false)
                                                        : undefined
                                                }
                                            >
                                                {((dark && !cardless) || !matches) && showCloseButton && (
                                                    <CloseButton
                                                        id="overlay-close"
                                                        dark={dark}
                                                        onClick={() => this.onClose()}
                                                    >
                                                        <Icon kind="exit" size={15} mr="10px" />
                                                    </CloseButton>
                                                )}
                                                {children}
                                            </ContentMain>
                                        ))}
                                    </Transition>

                                    {sidebarContent && (
                                        <Sidebar open={sidebarOpened}>
                                            <SidebarWrapper open={sidebarOpened}>
                                                <SidebarContent>
                                                    {sidebarContent}
                                                </SidebarContent>
                                            </SidebarWrapper>
                                            <SidebarButton onClick={() => this.toggleSidebar()}>
                                                <Icon kind={sidebarOpened ? 'exit' : 'info'} color="white" size="20px" />
                                            </SidebarButton>
                                        </Sidebar>
                                    )}
                                </Content>
                            </OverlayContainer>
                        )}
                    </Laptop>
                ))}
            </Transition>
        );
    }
}

Overlay.propTypes = {
    children: PropTypes.node,
    sidebarContent: PropTypes.node,
    showCloseButton: PropTypes.bool,
    dark: PropTypes.bool,
    black: PropTypes.bool,
    fullBlack: PropTypes.bool,
    dontCloseOnclick: PropTypes.bool,
    closeWithEsc: PropTypes.bool,
    overlayIsOpen: PropTypes.bool,
    cardless: PropTypes.bool,
    contentCentered: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),

    contentMainClassName: PropTypes.string,
};

Overlay.defaultProps = {
    dark: false,
    black: false,
    fullBlack: false,
    dontCloseOnclick: false,
    closeWithEsc: true,
    showCloseButton: true,
    contentCentered: false,
    cardless: null,
    overlayIsOpen: false,
    children: null,
    sidebarContent: null,
    maxWidth: null,
    contentMainClassName: '',
};

export default Overlay;
