import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../../helpers/trans';
import { Competency } from '../../../../../models/Competencies';
import { Tag } from '../../../..';

import './CompetencySelectedOptionList.scss';

interface CompetencySelectedOptionListProps {
    selectedOptions: Competency[];
    onSelectOption: (option: Competency, isChecked: false) => void;
    className?: string;
}

const CompetencySelectedOptionList: FC<CompetencySelectedOptionListProps> = ({
    selectedOptions,
    onSelectOption,
    className = '',
}): ReactElement => (
    <div className={`competency-selected-option-list ${className}`}>
        <h2 className="competency-selected-option-list__title">
            {trans('forms.competencies.selected')}
        </h2>

        <ul className="competency-selected-option-list__list">
            {selectedOptions.map(option => {
                const handleRemoveOption = (): void => {
                    onSelectOption(option, false);
                };

                return (
                    <li key={option.id} className="competency-selected-option-list__item">
                        <Tag
                            isActive
                            text={option.name}
                            onClick={handleRemoveOption}
                        />
                    </li>
                );
            })}
        </ul>
    </div>
);

export default CompetencySelectedOptionList;
