import React, { PropsWithChildren, ReactElement } from 'react';

import { SubMenuItem } from './subcomponents';
import { SubMenuItemProps } from './subcomponents/SubMenuItem/SubMenuItem';

import './SubMenu.scss';

interface SubMenuProps<Tab> {
    tabs: SubMenuItemProps<Tab>[];
    className?: string;
}

const SubMenu = <Tab extends string>({
    tabs,
    className = '',
}: PropsWithChildren<SubMenuProps<Tab>>): ReactElement => (
    <section className={`sub-menu ${className}`}>
        <ul className="sub-menu__list">
            {tabs.map(tab => (
                <SubMenuItem<Tab>
                    {...tab}
                    key={tab.key}
                    className="sub-menu__item"
                />
            ))}
        </ul>
    </section>
);

export default SubMenu;
