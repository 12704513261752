import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../components';
import {
    DetailCard,
    FixedFooter,
    LinkIconButton,
    Modal,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { ApplicationFormData } from '../../../models/Applications';
import { Candidate } from '../../../models/Candidate';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoutePaths } from '../../../routes';
import { ApplicationForm } from '../..';
import { VacancyApplyFormTips } from './subcomponents';

import './VacancyApplyCard.scss';

interface VacancyApplyCardProps {
    isLoading: boolean;
    isAnonymous: boolean;
    candidate: Candidate;
    vacancy: NewestVacancy;
    formIsLoading: boolean;
    formSentSuccessfully: boolean;
    onSubmitApplication: (applicationFormData: ApplicationFormData) => void;
    className?: string;
}

const VacancyApplyCard: FC<VacancyApplyCardProps> = ({
    isLoading,
    isAnonymous,
    candidate,
    vacancy,
    formIsLoading,
    formSentSuccessfully,
    onSubmitApplication,
    className = '',
}): ReactElement => {
    const [applicationModalIsOpen, setApplicationModalIsOpen] = useState<boolean>(false);

    const openApplicationModal = (): void => setApplicationModalIsOpen(true);
    const closeApplicationModal = (): void => setApplicationModalIsOpen(false);

    useEffect((): void => {
        if (formSentSuccessfully) {
            closeApplicationModal();
        }
    }, [formSentSuccessfully]);

    const handleSubmitApplication = (applicationFormData: ApplicationFormData): void => {
        onSubmitApplication(applicationFormData);
    };

    return (
        <DetailCard
            title={trans('vacancyDetail.apply.title')}
            className={`vacancy-apply-card ${className}`}
        >
            <p className="vacancy-apply-card__description">
                {trans('vacancyDetail.apply.description', {
                    name: vacancy.company.contactPerson.fullName,
                    position: vacancy.company.contactPerson.position,
                    company: vacancy.company.name,
                })}
            </p>

            <Button
                text={trans('vacancyDetail.apply.button.apply')}
                disabled={isLoading}
                onClick={openApplicationModal}
                className="vacancy-apply-card__button"
            />

            {isAnonymous ? (
                <p className="vacancy-apply-card__description">
                    {trans('vacancyDetail.apply.anonymousChatDescription', {
                        company: vacancy.company.name,
                    })}
                </p>
            ) : (
                <p className="vacancy-apply-card__description">
                    {trans('vacancyDetail.apply.chatDescription', {
                        company: vacancy.company.name,
                    })}
                </p>
            )}

            <LinkIconButton
                to={RoutePaths.candidateOverviewMessages(vacancy.company.uuid)}
                icon="send"
                text={trans('actions.sendMessage')}
                disabled={isLoading}
                className="vacancy-apply-card__button vacancy-apply-card__button--for-chat"
            />

            <FixedFooter onlySmallScreen>
                <Button
                    text={trans('vacancyDetail.apply.button.apply')}
                    disabled={isLoading}
                    onClick={openApplicationModal}
                    className="vacancy-apply-card__fixed-footer-button"
                />
            </FixedFooter>

            {applicationModalIsOpen && (
                <Modal onClose={closeApplicationModal} cardClassName="vacancy-apply-card__modal-card">
                    <ApplicationForm
                        isLoading={formIsLoading}
                        candidate={candidate}
                        vacancy={vacancy}
                        onCancel={closeApplicationModal}
                        onSubmit={handleSubmitApplication}
                        className="vacancy-apply-card__form"
                    />

                    <VacancyApplyFormTips
                        vacancy={vacancy}
                        className="vacancy-apply-card__form-tips"
                    />
                </Modal>
            )}
        </DetailCard>
    );
};

export default VacancyApplyCard;
