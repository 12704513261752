import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { LinkButton } from '../../../components';
import { ButtonList, LinkIconButton } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';
import { RoutePaths } from '../../../routes';
import { HorizontalAlignment } from '../../../types';
import { CandidateOverviewTab } from '../../../types/pageTabs';
import { ApplicationMotivationCard } from '../..';

import './ApplicationDetailArchived.scss';

interface ApplicationDetailArchivedProps {
    isLoading: boolean;
    application: Application;
    className?: string;
}

const ApplicationDetailArchived: FC<ApplicationDetailArchivedProps> = ({
    isLoading,
    application,
    className = '',
}): ReactElement => (
    <section className={`application-detail-archived ${className}`}>
        <Helmet>
            <title>{trans('containers.applicationDetailArchived.meta.title')}</title>
            <meta name="description" content={trans('containers.applicationDetailArchived.meta.description')} />
        </Helmet>

        <div className="application-detail-archived__wrapper">
            <h1 className="application-detail-archived__title">
                {trans('containers.applicationDetailArchived.title')}
            </h1>

            <p className="application-detail-archived__description">
                {trans('containers.applicationDetailArchived.description', {
                    vacancyTitle: application.vacancy.title,
                })}
            </p>

            <ApplicationMotivationCard
                isLoading={isLoading}
                application={application}
                className="application-detail-archived__motivation-card"
            />

            <ButtonList
                align={HorizontalAlignment.left}
                className="application-detail-archived__button-list"
            >
                <LinkButton
                    to={RoutePaths.candidateOverviewTab(CandidateOverviewTab.applications)}
                    text={trans('containers.applicationDetailArchived.button.toApplications')}
                    className="application-detail-archived__button"
                />

                <LinkIconButton
                    to={RoutePaths.candidateOverviewMessages(application.vacancy.companyUuid)}
                    icon="send"
                    text={trans('actions.sendMessage')}
                    className="application-detail-archived__button application-detail-archived__button--for-chat"
                />
            </ButtonList>
        </div>
    </section>
);

export default ApplicationDetailArchived;
