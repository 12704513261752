import React, { FC, ReactElement } from 'react';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { trans } from '../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../models/EducationLevels';
import { NewestVacancy } from '../../models/NewestVacancy';
import { VacancyMatchingCandidate } from '../../models/VacancyMatches';
import { VacancyMatchesFilterValues } from '../../models/VacancyMatchesFilter';
import { WorkRemoteOption } from '../../models/WorkRemote';
import { FormOption, SearchableOption } from '../../types';
import { VacancyMatchesFilter, VacancyMatchesNullState, VacancyMatchesOverview } from './subcomponents';

import './VacancyMatches.scss';

interface VacancyMatchesProps {
    isLoading: boolean;
    isWspUser: boolean;
    vacancyUuidFromUrl?: string;
    activeVacancy?: NewestVacancy;
    filterValues: VacancyMatchesFilterValues;
    activeFilterValues: VacancyMatchesFilterValues;
    candidates: VacancyMatchingCandidate[];
    amountOfCandidates?: number;
    vacancyOptions: SearchableOption[];
    candidateTypeOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    sectorOptions: FormOption[];
    workRemoteOptions: WorkRemoteOption[];
    driversLicenseOptions: FormOption[];
    error: string;
    pagination?: Pagination;
    onFilterChange: (values: Partial<VacancyMatchesFilterValues>) => void;
    onFilterReset: () => void;
    onFilterSubmit: () => void;
    className?: string;
}

const VacancyMatches: FC<VacancyMatchesProps> = ({
    isLoading,
    isWspUser,
    vacancyUuidFromUrl,
    activeVacancy,
    filterValues,
    activeFilterValues,
    candidates,
    amountOfCandidates = 0,
    vacancyOptions,
    candidateTypeOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    sectorOptions,
    workRemoteOptions,
    driversLicenseOptions,
    error,
    pagination,
    onFilterChange,
    onFilterReset,
    onFilterSubmit,
    className = '',
}): ReactElement => (
    <section className={`vacancy-matches ${className}`}>
        <header className="vacancy-matches__header">
            <h1 className="vacancy-matches__title">
                {trans('containers.vacancyMatches.title')}
            </h1>
        </header>

        <VacancyMatchesFilter
            isLoading={isLoading}
            hasLLOFilter={isWspUser}
            vacancyUuidFromUrl={vacancyUuidFromUrl}
            activeVacancy={activeVacancy}
            filterValues={filterValues}
            activeFilterValues={activeFilterValues}
            amountOfCandidates={amountOfCandidates}
            vacancyOptions={vacancyOptions}
            candidateTypeOptions={candidateTypeOptions}
            educationLevelOptions={educationLevelOptions}
            educationSubLevelOptions={educationSubLevelOptions}
            sectorOptions={sectorOptions}
            workRemoteOptions={workRemoteOptions}
            driversLicenseOptions={driversLicenseOptions}
            onChange={onFilterChange}
            onReset={onFilterReset}
            onSubmit={onFilterSubmit}
            className="vacancy-matches__filters"
        />

        {!isLoading && !error && candidates.length === 0 && (
            <VacancyMatchesNullState
                onFilterReset={onFilterReset}
                className="vacancy-matches__null-state"
            />
        )}

        <VacancyMatchesOverview
            isLoading={isLoading}
            mayViewLLO={isWspUser}
            vacancyUuid={activeVacancy?.uuid}
            candidates={candidates}
            error={error}
            pagination={pagination}
            className="vacancy-matches__overview"
        />
    </section>
);

export default VacancyMatches;
