import { WorkRemotePreferenceFormData } from '../../../../models/WorkRemote';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type WorkRemotePreferenceFormErrors = FormErrors<WorkRemotePreferenceFormData>;

export const validateWorkRemotePreferenceFormData = (
    formData: WorkRemotePreferenceFormData,
): FormValidation<WorkRemotePreferenceFormErrors> => {
    const errors: WorkRemotePreferenceFormErrors = {
        workRemotePreference: validateRequiredString('preference', formData.workRemotePreference.value),
    };

    return validateForm<WorkRemotePreferenceFormErrors>(errors);
};
