import React, { FC, ReactElement } from 'react';

import { addKmUnit } from '../../../helpers/string';
import { SingleRangeInput } from '../..';

interface DistanceFilterProps {
    label: string;
    hideLabel?: boolean;
    value: number;
    tabIndex?: number;
    onChange: (value: number) => void;
    className?: string;
}

const DistanceFilter: FC<DistanceFilterProps> = ({
    label,
    hideLabel,
    value,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`distance-filter ${className}`}>
        <SingleRangeInput
            label={label}
            hideLabel={hideLabel}
            max={150}
            step={5}
            value={value}
            marks={4}
            valueFormat={addKmUnit}
            tabIndex={tabIndex}
            onChange={onChange}
        />
    </div>
);

export default DistanceFilter;
