import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconMedal: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 60.5 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0,30.2c0,9,3.9,17,10.1,22.5V100l20.2-13.1L50.4,100V52.8c12.4-11.1,13.5-30.2,2.4-42.7S22.5-3.4,10.1,7.7C3.7,13.4,0,21.6,0,30.2z M30.2,10.1c-11.1,0-20.2,9-20.2,20.2s9,20.2,20.2,20.2s20.2-9,20.2-20.2S41.4,10.1,30.2,10.1z M40.3,56.1c-3.2,1.3-6.6,1.9-10.1,1.9c-3.6,0-7-0.7-10.1-1.9v25.4l10.1-6.6l10.1,6.6C40.3,81.4,40.3,56.1,40.3,56.1z" />
    </svg>
);

export default IconMedal;
