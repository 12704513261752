import React, { FC, ReactElement } from 'react';

import { alphabetNumber } from '../../../../constants';
import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { EscoOccupation } from '../../../../models/Esco';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexOccupation.scss';

interface VacancyIndexOccupationProps {
    isLoading: boolean;
    letter: string;
    escoOccupationOptions: EscoOccupation[];
    className?: string;
}

const VacancyIndexOccupation: FC<VacancyIndexOccupationProps> = ({
    isLoading,
    letter,
    escoOccupationOptions,
    className = '',
}): ReactElement => {
    const title = letter === alphabetNumber
        ? trans('vacancyIndex.category.occupation.number', { number: alphabetNumber })
        : trans('vacancyIndex.category.occupation.letter', { letter });

    return (
        <VacancyIndexCategory
            isLoading={isLoading}
            title={title}
            className={`vacancy-index-occupation ${className}`}
        >
            <VacancyIndexColumnList>
                {escoOccupationOptions.map(occupation => {
                    const searchQuery = encodeSearchQuery({
                        what: occupation.name,
                    });

                    const encodedLetter = encodeURIComponent(letter);
                    const encodedOccupation = encodeURIComponent(occupation.slug);

                    return (
                        <li key={occupation.id}>
                            <VacancyIndexLink
                                to={RoutePaths.jobsQuery(searchQuery)}
                                label={occupation.name}
                            />

                            <VacancyIndexLink
                                to={RoutePaths.jobVacancyIndexOccupation(encodedLetter, encodedOccupation)}
                                label={trans('vacancyIndex.button.selectProvince')}
                                className="vacancy-index-occupation__province-link"
                            />
                        </li>
                    );
                })}
            </VacancyIndexColumnList>
        </VacancyIndexCategory>
    );
};

export default VacancyIndexOccupation;
