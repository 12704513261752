import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconTriangleUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 75" className={`icon ${className}`}>
        <path d="M50,0l50,75H0L50,0z" />
    </svg>
);

export default IconTriangleUp;
