import { Resource } from '../japi/types/Resource';

export interface EmployeeProfileResource extends Resource {
    type: 'employee-profiles';
    id: string;
    attributes: {
        position: string;
    };
}

export interface EmployeeProfile {
    uuid: string;
    position: string;
}

export const transformToEmployeeProfile = (
    employeeProfileResource: EmployeeProfileResource,
): EmployeeProfile => ({
    uuid: employeeProfileResource.id,
    position: employeeProfileResource.attributes.position,
});
