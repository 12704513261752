import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    border-bottom: 1px solid rgb(229, 229, 229);
    padding: 5px 10px;
`;

export const Header = styled.dt`
    cursor: pointer;
    font-weight: 800;
    padding-top: 18px;
    padding-bottom: 16px;
`;

export const Body = styled.dd(props => css`
    padding: 0 ${props.theme.space.md};
    height: 0;
    overflow: hidden;
    border-bottom: 1px solid rgb(229, 229, 229);
    
    @media ${props.theme.mediaQueries.tablet} {
        padding-right: 250px;
    }
    
    ${props.isOpen && css`
        padding: 0 ${props.theme.space.md} ${props.theme.space.sm} ${props.theme.space.md};
        height: auto;
    `}
`);

export const Arrow = styled.span(props => css`
    display: block;
    float: right;
    margin-top: -17px;
    transform: rotate(${props.isOpen ? '-90deg' : '90deg'});
    transition: transform 0.2s ease-in-out;
`);
