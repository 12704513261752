import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type FavouritesState = AsyncReduxState<{
    isExpanded: boolean;
}>;

const initialState: FavouritesState = {
    ...initialAsyncReduxState,
    isExpanded: false,
};

const favouritesSlice = createSlice({
    name: 'favouritesReducer',
    initialState,
    reducers: {
        setIsExpanded(state, action: PayloadAction<boolean>): FavouritesState {
            return {
                ...state,
                isExpanded: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): FavouritesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): FavouritesState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsExpanded,
    setIsLoading,
    setError,
} = favouritesSlice.actions;

export default favouritesSlice;
