import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Employee } from '../../models/Employee';

export interface UserEmployeeState {
    loading: boolean;
    data: Employee | null;
    errors: Record<string, unknown>;
}

const initialState: UserEmployeeState = {
    loading: false,
    data: null,
    errors: {},
};

const userEmployeeSlice = createSlice({
    name: 'userEmployee',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): UserEmployeeState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setData(state, action: PayloadAction<Employee | null>): UserEmployeeState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): UserEmployeeState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setData,
    setErrors,
} = userEmployeeSlice.actions;

export default userEmployeeSlice;
