import React, { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedApplicationDetail } from '../../connectors';
import { RouteParams } from '../../routes';

import './ApplicationDetail.scss';

interface ApplicationDetailParams extends RouteParams {
    applicationUuid?: string;
}

const ApplicationDetail: FC = (): ReactElement => {
    const { applicationUuid = '' } = useParams<ApplicationDetailParams>();

    return (
        <Page className="application-detail-page">
            <div className="application-detail-page__wrapper">
                <ConnectedApplicationDetail applicationUuid={applicationUuid} />
            </div>
        </Page>
    );
};

export default ApplicationDetail;
