import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useToggle } from 'react-use';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { ExpandableNavigation, IconButton, Modal } from '../../compositions';
import { trans } from '../../helpers/trans';
import useHandleClickOutside from '../../hooks/useHandleClickOutside';
import { Application } from '../../models/Applications';
import { NewestVacancy } from '../../models/NewestVacancy';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { VacancyOverview } from '..';
import { RecentVacancyShareModal } from './subcomponents';

import './MyVacancies.scss';

interface MyVacanciesProps {
    isLoading: boolean;
    isValidCompany: boolean;
    isArchive: boolean;
    recentlyUpdatedVacancy?: NewestVacancy;
    vacancies: NewestVacancy[];
    pagination?: Pagination;
    error: string;
    applications: Application[];
    applicationsIsLoading: boolean;
    onArchiveToggle: () => void;
    onClearRecentlyUpdatedVacancy: () => void;
    onFetchApplications: (vacancyUuid: string) => void;
    onClearApplications: () => void;
    onArchiveVacancy: (vacancyUuid: string, applicationUuids: string[]) => void;
    onUnarchiveVacancy: (vacancyUuid: string) => void;
    onDeleteVacancy: (vacancyUuid: string) => void;
    onPaginationChange: (pageNumber: number) => void;
    className?: string;
}

const MyVacancies: FC<MyVacanciesProps> = ({
    isLoading,
    isValidCompany,
    isArchive,
    recentlyUpdatedVacancy,
    vacancies,
    pagination,
    error,
    applications,
    applicationsIsLoading,
    onArchiveToggle,
    onClearRecentlyUpdatedVacancy,
    onFetchApplications,
    onClearApplications,
    onArchiveVacancy,
    onUnarchiveVacancy,
    onDeleteVacancy,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const [createVacancyControlsIsExpanded, toggleCreateVacancyControls] = useToggle(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState<boolean>(true);

    const closeCreateVacancyControls = (): void => toggleCreateVacancyControls(false);

    const createVacanciesControlsRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(createVacanciesControlsRef, closeCreateVacancyControls);

    const openShareModal = (): void => setShareModalIsOpen(true);
    const closeShareModal = (): void => setShareModalIsOpen(false);

    useEffect((): void => {
        if (recentlyUpdatedVacancy) {
            openShareModal();
        }
    }, [recentlyUpdatedVacancy]);

    useEffect((): void => {
        if (recentlyUpdatedVacancy && !shareModalIsOpen) {
            onClearRecentlyUpdatedVacancy();
        }
    }, [recentlyUpdatedVacancy, shareModalIsOpen, onClearRecentlyUpdatedVacancy]);

    const createVacancyControls = [
        {
            key: 'internship',
            label: trans('myVacancies.overview.create.internshipVacancy'),
            icon: 'academic-cap',
            to: RoutePaths.createInternshipVacancy('1'),
        },
        {
            key: 'job',
            label: trans('myVacancies.overview.create.jobVacancy'),
            icon: 'briefcase',
            to: RoutePaths.createJobVacancy('1'),
        },
    ];

    return (
        <div className={`my-vacancies ${className}`}>
            <h1 className="my-vacancies__title">
                {trans(`myVacancies.${isArchive ? 'archiveTitle' : 'title'}`)}
            </h1>

            <div className="my-vacancies__control-wrapper">
                <div ref={createVacanciesControlsRef}>
                    <IconButton
                        icon="plus"
                        text={trans('myVacancies.overview.create.buttonLabel')}
                        disabled={!isValidCompany}
                        onClick={toggleCreateVacancyControls}
                        className="my-vacancies__create-vacancy-button"
                    />

                    <ExpandableNavigation
                        isExpanded={createVacancyControlsIsExpanded}
                        name={trans('myVacancies.overview.create.ariaLabel')}
                        navigationItems={createVacancyControls}
                        onClose={closeCreateVacancyControls}
                        className="my-vacancies__create-vacancy-controls"
                    />
                </div>

                {isArchive ? (
                    <IconButton
                        icon="home"
                        text={trans('myVacancies.overview.link.activeVacancies')}
                        disabled={!isValidCompany}
                        onClick={onArchiveToggle}
                        className="my-vacancies__archive-button"
                    />
                ) : (
                    <IconButton
                        icon="archive"
                        text={trans('myVacancies.overview.link.archivedVacancies')}
                        disabled={!isValidCompany}
                        onClick={onArchiveToggle}
                        className="my-vacancies__archive-button"
                    />
                )}
            </div>

            <div className="my-vacancies__wrapper">
                {!isValidCompany && (
                    <p className="my-vacancies__invalid-company">
                        {trans('myVacancies.overview.invalidCompany')}
                    </p>
                )}

                {isValidCompany && (
                    <VacancyOverview
                        isAuthor
                        isLoading={isLoading}
                        isArchived={isArchive}
                        error={error}
                        userRole={RoleType.company}
                        vacancies={vacancies}
                        pagination={pagination}
                        applications={applications}
                        applicationsIsLoading={applicationsIsLoading}
                        onFetchApplications={onFetchApplications}
                        onClearApplications={onClearApplications}
                        onArchiveVacancy={onArchiveVacancy}
                        onUnarchiveVacancy={onUnarchiveVacancy}
                        onDeleteVacancy={onDeleteVacancy}
                        onPaginationChange={onPaginationChange}
                        className="my-vacancies__vacancy-list"
                    />
                )}
            </div>

            {recentlyUpdatedVacancy && shareModalIsOpen && (
                <Modal onClose={closeShareModal} cardClassName="my-vacancies__modal-card">
                    <RecentVacancyShareModal
                        vacancy={recentlyUpdatedVacancy}
                        onClose={closeShareModal}
                    />
                </Modal>
            )}
        </div>
    );
};

export default MyVacancies;
