import React, { FC, FormEvent, ReactElement } from 'react';

import { FormActionButtons } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { ChatReport, UndoChatReportFormData } from '../../../models/ChatReport';
import { FormProps } from '../../../types';

import './UndoChatReportForm.scss';

interface UndoChatReportFormProps extends FormProps<UndoChatReportFormData> {
    activeChatReport: ChatReport;
    className?: string;
}

const UndoChatReportForm: FC<UndoChatReportFormProps> = ({
    activeChatReport,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            selectedReport: activeChatReport.uuid,
        });
    };

    return (
        <form onSubmit={handleSubmit} className={`undo-chat-report-form ${className}`}>
            <p className="undo-chat-report-form__description">
                {trans('forms.undoChatReport.description')}
            </p>

            <blockquote className="undo-chat-report-form__report-reason">
                {activeChatReport.reportReason}
            </blockquote>

            <FormActionButtons
                submitText={trans('forms.undoChatReport.button.submit')}
                onCancelClick={onCancel}
                className="undo-chat-report-form__action-buttons"
            />
        </form>
    );
};

export default UndoChatReportForm;
