import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/form/TextInput';
import { CheckList, CheckListItem } from '../../../../styledComponents/components/CheckList';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading } from '../../../../styledComponents/components/Typography';

class PasswordForm extends Component {
    constructor() {
        super();

        this.state = {
            first: false,
            second: false,
            amountOfCharsReached: false,
            hasNumber: false,
            hasSpecialChar: false,
            hasEqualPasswords: false,
        };
    }

    onSubmit = e => {
        e.preventDefault();
        const data = serialize(e.currentTarget, { hash: true });
        this.props.onSubmit(data);
    };

    validatePassword = e => {
        const { value } = e.currentTarget;
        const { second } = this.state;

        this.setState({
            first: value,
            amountOfCharsReached: value.length >= 8,
            hasNumber: value.match(/\d+/g),
            hasSpecialChar: value.match(/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/),
            hasEqualPasswords: second === value,
        });
    };

    checkForEqualPasswords = e => {
        const { value } = e.currentTarget;
        const { first } = this.state;

        this.setState({
            second: value,
            hasEqualPasswords: first === value,
        });
    };

    render() {
        const {
            errors,
            loading,
            resetPassword,
            isChangingPassword,
        } = this.props;
        const {
            amountOfCharsReached,
            hasNumber,
            hasSpecialChar,
            hasEqualPasswords,
        } = this.state;

        return (
            <form id="password-form" onSubmit={this.onSubmit}>
                <Heading as="h2" size="l">{resetPassword ? trans('forms.user.resetPasswordTitle') : trans('forms.user.password.heading')}</Heading>
                {isChangingPassword && (
                    <Row>
                        <Col mb="md" width={1}>
                            <TextInput
                                errors={errors.old_password}
                                id="old_password"
                                type="password"
                                label={trans('forms.user.changePassword.label')}
                                placeholder={trans('forms.user.changePassword.placeholder')}
                                name="old_password"
                                icon="password"
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col mb="md" width={1}>
                        <TextInput
                            errors={errors.password}
                            id="password"
                            type="password"
                            label={resetPassword ? trans('forms.user.resetPassword.label') : trans('forms.user.password.label')}
                            placeholder={resetPassword ? trans('forms.user.resetPassword.placeholder') : trans('forms.user.password.placeholder')}
                            name="password"
                            icon="password"
                            onChange={this.validatePassword}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col mb="md" width={1}>
                        <TextInput
                            errors={errors.password_confirmation}
                            id="password_confirmation"
                            type="password"
                            label={resetPassword ? trans('forms.user.resetPasswordRepeat.label') : trans('forms.user.passwordRepeat.label')}
                            placeholder={resetPassword ? trans('forms.user.resetPasswordRepeat.placeholder') : trans('forms.user.passwordRepeat.placeholder')}
                            name="password_confirmation"
                            icon="password"
                            onChange={this.checkForEqualPasswords}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CheckList mb="xl">
                            <CheckListItem checkedColor="success" checked={amountOfCharsReached}>
                                {trans('validation.min.characters', { characters: '8' })}
                            </CheckListItem>
                            <CheckListItem checkedColor="success" checked={hasNumber}>
                                {trans('validation.contains.number')}
                            </CheckListItem>
                            <CheckListItem checkedColor="success" checked={hasSpecialChar}>
                                {trans('validation.contains.specialchar')}
                            </CheckListItem>
                            <CheckListItem checkedColor="success" checked={hasEqualPasswords}>
                                {trans('validation.equal.passwords')}
                            </CheckListItem>
                        </CheckList>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            disabled={
                                loading
                                || !amountOfCharsReached
                                || !hasSpecialChar
                                || !hasNumber
                                || !hasEqualPasswords
                            }
                            isLoading={loading}
                            type="submit"
                            id="password-submit"
                        >
                            {trans('actions.passwordSave')}
                        </Button>
                    </Col>
                </Row>

            </form>
        );
    }
}

PasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    resetPassword: PropTypes.bool,
    isChangingPassword: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
};

PasswordForm.defaultProps = {
    errors: {},
    resetPassword: false,
    isChangingPassword: false,
};

export default PasswordForm;
