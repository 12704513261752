import React, { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import { trans } from '../../../helpers/trans';
import { HorizontalAlignment } from '../../../types';
import { NumberInput } from '../..';

import './MinMaxInput.scss';

interface InputProps {
    label: string;
    value?: number | string;
    step?: number;
    min?: number;
    max?: number;
    onChange: (value: number) => void;
}

interface MinMaxInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    labelAlignment?: HorizontalAlignment;
    minInput: InputProps;
    maxInput: InputProps;
    unit?: string;
    error?: string;
    onMinBlur?: (value: string) => void;
}

const MinMaxInput: FC<MinMaxInputProps> = ({
    label,
    hideLabel = false,
    labelAlignment,
    required = false,
    minInput,
    maxInput,
    unit,
    error = '',
    onMinBlur,
    className = '',
    ...inputProps
}): ReactElement => {
    const labelAlignRight = labelAlignment === HorizontalAlignment.right;

    const toLabel = trans('basic.to');

    const handleMinBlur = (event: ChangeEvent<HTMLInputElement>): void => {
        if (onMinBlur) {
            onMinBlur(event.currentTarget.value);
        }
    };

    const hourRangeInputClassNames = classNames('min-max-input', {
        'min-max-input--align-right': labelAlignRight,
        'min-max-input--has-error': !!error,
    }, className);

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={hourRangeInputClassNames}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="min-max-input__label"
                />
            )}

            <div className="min-max-input__input-wrapper">
                <div className="min-max-input__range-section">
                    <div className="min-max-input__secondary-label">
                        {labelAlignRight ? toLabel : unit}
                    </div>

                    <NumberInput
                        {...inputProps}
                        {...minInput}
                        hideLabel
                        max={maxInput.value || minInput.max}
                        placeholder={minInput.label}
                        onBlur={handleMinBlur}
                        className="min-max-input__input"
                    />
                </div>

                <div className="min-max-input__range-section">
                    <div className="min-max-input__secondary-label">
                        {labelAlignRight ? unit : toLabel}
                    </div>

                    <NumberInput
                        {...inputProps}
                        {...maxInput}
                        hideLabel
                        min={minInput.value}
                        placeholder={maxInput.label}
                        className="min-max-input__input"
                    />
                </div>
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="min-max-input__error-label"
                />
            )}
        </label>
    );
};

export default MinMaxInput;
