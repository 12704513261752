import React, {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { CheckboxList, FormActionButtons } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { SectorsFormData } from '../../../models/Sectors';
import { FormOption, FormProps } from '../../../types';

import './PreferredSectorsForm.scss';

interface PreferredSectorsFormProps extends FormProps<SectorsFormData> {
    sectors: FormOption[];
    sectorOptions: FormOption[];
    className?: string;
}

const PreferredSectorsForm: FC<PreferredSectorsFormProps> = ({
    sectors,
    sectorOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [selectedSectors, setSelectedSectors] = useState<string[]>(sectors.map(sector => sector.value));

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            sectors: sectorOptions.filter(sector => selectedSectors.includes(sector.value)),
        });
    };

    return (
        <form onSubmit={handleSubmit} className={`preferred-sectors-form ${className}`}>
            <CheckboxList
                label={trans('forms.preferredSectors.input.sectors')}
                name="sectors"
                options={sectorOptions}
                value={selectedSectors}
                onChange={setSelectedSectors}
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="preferred-sectors-form__action-buttons"
            />
        </form>
    );
};

export default PreferredSectorsForm;
