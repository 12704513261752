import React, {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { Card, Page } from '../../components';
import { CookieConsentList, SubmitButton, Toast } from '../../compositions';
import { userConsentCookieKey } from '../../constants';
import { trans } from '../../helpers/trans';
import { RoutePaths } from '../../routes';
import { CookieOption, MessageState } from '../../types';

import './CookieSettings.scss';

const CookieSettings: FC = (): ReactElement => {
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [userConsentCookies, setUserConsentCookies] = useLocalStorage<string | undefined>(userConsentCookieKey);

    const [checkBoxValues, setCheckBoxValues] = useState<CookieOption[]>(userConsentCookies ? userConsentCookies.split(',') as CookieOption[] : [CookieOption.necessary]);

    const handleCheckboxChange = (values: string[]): void => {
        setHasChanges(true);
        setCheckBoxValues(values as CookieOption[]);
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setUserConsentCookies(checkBoxValues.join(','));

        setHasChanges(false);
        setIsSaved(true);
    };

    const handleCloseToast = (): void => setIsSaved(false);

    return (
        <Page className="cookie-settings-page">
            <Helmet>
                <title>{trans('cookiesPage.metadata.title')}</title>
                <meta property="og:title" content={trans('cookiesPage.metadata.title')} />
                <meta property="og:description" content={trans('cookiesPage.metadata.description')} />
                <meta name="description" content={trans('cookiesPage.metadata.description')} />
            </Helmet>

            <div className="cookie-settings-page__wrapper">
                <Card className="cookie-settings-page__card">
                    <h1 className="cookie-settings-page__title">
                        {trans('cookiesPage.title')}
                    </h1>

                    <p className="cookie-settings-page__description">
                        {trans('cookiesPage.intro')}
                    </p>

                    <Link to={RoutePaths.privacyStatement()} className="cookie-settings-page__link">
                        {trans('cookiesPage.privacyStatement')}
                    </Link>

                    <form onSubmit={handleFormSubmit} className="cookie-settings-page__form">
                        <CookieConsentList
                            values={checkBoxValues}
                            onChange={handleCheckboxChange}
                            className="cookie-settings-page__cookie-consent-list"
                        />
                        <SubmitButton
                            text={trans('actions.save')}
                            disabled={isSaved}
                            className="cookie-settings-page__save-button"
                        />
                    </form>

                    {isSaved && (
                        <Toast
                            shouldClose={hasChanges}
                            id="cookie-settings"
                            state={MessageState.positive}
                            title={trans('cookiesPage.toast.title')}
                            description={trans('cookiesPage.toast.description')}
                            onClose={handleCloseToast}
                        />
                    )}
                </Card>
            </div>
        </Page>
    );
};

export default CookieSettings;
