// TODO: Despite apparent refactor, this reducer/slice needs to be refactored, and removed from '_old' entirely.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationsState {
    loading: boolean;
    subscription: unknown;
    errors: Record<string, unknown>;
}

const initialState: NotificationsState = {
    loading: false,
    subscription: null,
    errors: {},
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): NotificationsState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setSubscription(state, action: PayloadAction<unknown>): NotificationsState {
            return {
                ...state,
                subscription: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): NotificationsState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setSubscription,
    setErrors,
} = notificationsSlice.actions;

export default notificationsSlice;
