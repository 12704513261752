import React, { ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { PlaceInternshipVacancySteps } from '../../containers';
import { dataLayerPush } from '../../helpers/analytics';
import { warnBeforeUnload } from '../../helpers/warnBeforeUnload';
import { transformWorkRemoteOptionToFormOption } from '../../models/WorkRemote';
import { RouteParams, RoutePaths } from '../../routes';
import { PlaceInternshipVacancyFormValues } from '../../services/PlaceVacancyService';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { fetchSbbCompanyInfo } from '../../store/company/companyActions';
import { fetchCompetencies } from '../../store/competencies/competenciesActions';
import { fetchEscoSkills } from '../../store/escoSkills/escoSkillsActions';
import { fetchFieldsOfStudy } from '../../store/fieldsOfStudy/fieldsOfStudyActions';
import { createInternshipVacancy, editInternshipVacancy, fetchVacancyToEdit } from '../../store/myVacancies/myVacanciesActions';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { fetchSkills } from '../../store/skills/skillsActions';
import { createSkillOption, getAllVacancyOptions } from '../../store/vacancyOptions/vacancyOptionsActions';
import { fetchWorkRemoteOptions } from '../../store/workRemote/workRemoteActions';
import { CompanyOverviewTab } from '../../types/pageTabs';

interface PlaceInternshipVacancyUrlParams extends RouteParams {
    step?: string;
    vacancyUuid?: string;
}

const ConnectedPlaceInternshipVacancy = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { step = '', vacancyUuid = '' } = useParams<PlaceInternshipVacancyUrlParams>();

    const company = useLegacySelector(state => state.userCompany);

    const educationLevelOptions = useLegacySelector(state => state.vacancyOptionsReducer.educationLevels);
    const internshipTypeOptions = useLegacySelector(state => state.vacancyOptionsReducer.internshipTypes);
    const gradeOptions = useLegacySelector(state => state.vacancyOptionsReducer.grades);

    const vacancyToEdit = useTypedSelector(state => state.myVacanciesReducer.vacancyToEdit);
    const isLoading = useTypedSelector(state => state.myVacanciesReducer.isLoading);
    const recentlyUpdatedVacancy = useTypedSelector(state => state.myVacanciesReducer.recentlyUpdatedVacancy);

    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);
    const fieldOfStudyOptions = useTypedSelector(state => state.fieldsOfStudyReducer.fieldsOfStudy);
    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);
    const sbbCompanyInfo = useTypedSelector(state => state.companyReducer.sbbCompanyInfo);

    const skillOptions = useTypedSelector(state => state.skillsReducer.skills);
    const skillsError = useTypedSelector(state => state.skillsReducer.error);
    const escoSkillOptions = useTypedSelector(state => state.escoSkillsReducer.escoSkills);
    const escoSkillsError = useTypedSelector(state => state.escoSkillsReducer.error);
    const competencyOptions = useTypedSelector(state => state.competenciesReducer.competencies);
    const competenciesError = useTypedSelector(state => state.competenciesReducer.error);

    useEffect((): void => {
        dispatch(getAllVacancyOptions());

        if (sectorOptions.length === 0) dispatch(fetchSectors());
        if (fieldOfStudyOptions.length === 0) dispatch(fetchFieldsOfStudy());
        if (workRemoteOptions.length === 0) dispatch(fetchWorkRemoteOptions());
        if (skillOptions.length === 0) dispatch(fetchSkills());
        if (escoSkillOptions.length === 0) dispatch(fetchEscoSkills());
        if (competencyOptions.length === 0) dispatch(fetchCompetencies());

        if (vacancyUuid) {
            dispatch(fetchVacancyToEdit(vacancyUuid));
        }
    }, [dispatch, vacancyUuid]);

    useEffect((): void => {
        if (company?.data?.uuid) {
            dispatch(fetchSbbCompanyInfo(company.data.uuid));
        }
    }, [dispatch, company]);

    useEffect((): void => {
        if (recentlyUpdatedVacancy?.uuid) {
            dataLayerPush({
                event: 'formSubmission',
                formType: `place-internship-vacancy-${recentlyUpdatedVacancy.uuid}`,
            });

            navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));
        }
    }, [dispatch, recentlyUpdatedVacancy, navigate]);

    const handleCreateSkillOption = (value: string) => {
        dispatch(createSkillOption(value));
    };

    const handleCreateVacancy = (formValues: PlaceInternshipVacancyFormValues): void => {
        dispatch(createInternshipVacancy(formValues));
    };

    const handleEditVacancy = (formValues: PlaceInternshipVacancyFormValues): void => {
        if (vacancyToEdit) {
            dispatch(editInternshipVacancy(vacancyToEdit, formValues));
        }
    };

    const handleStepChange = (newStep: number) => {
        navigate(RoutePaths.editInternshipVacancy(`${newStep}`, vacancyUuid));
    };

    useEffectOnce((): () => void => {
        handleStepChange(1);

        window.addEventListener('beforeunload', warnBeforeUnload);
        return (): void => window.removeEventListener('beforeunload', warnBeforeUnload);
    });

    return (
        <PlaceInternshipVacancySteps
            isLoading={isLoading}
            step={Number(step || 1)}
            vacancy={vacancyToEdit}
            sbbRecognitions={sbbCompanyInfo?.recognitions || []}
            address={company?.data?.address}
            sectorOptions={sectorOptions}
            educationLevelOptions={educationLevelOptions}
            fieldsOfStudyOptions={fieldOfStudyOptions}
            gradeOptions={gradeOptions}
            internshipTypeOptions={internshipTypeOptions}
            workRemoteOptions={workRemoteOptions.map(transformWorkRemoteOptionToFormOption)}
            // TODO: Implement EscoSkills once back-end allows it
            // skillOptions={[...skillOptions, ...escoSkillOptions]}
            skillOptions={skillOptions}
            skillsError={skillsError || escoSkillsError}
            competencyOptions={competencyOptions}
            competenciesError={competenciesError}
            onCreateSkillOption={handleCreateSkillOption}
            onCreate={handleCreateVacancy}
            onEdit={handleEditVacancy}
            onStepChange={handleStepChange}
        />
    );
};

export default ConnectedPlaceInternshipVacancy;
