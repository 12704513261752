import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconExclamation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 92.7" className={`icon ${className}`}>
        <path d="M46.2,64.9V39.7h7.6v25.2H46.2z M50,77.5c2.1,0,3.8-1.7,3.8-3.8S52.1,70,50,70c-2.1,0-3.8,1.7-3.8,3.8S47.9,77.5,50,77.5z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M50,0l50,92.7H0C0,92.7,50,0,50,0z M50,21.3L16.9,82.6h66.2L50,21.3z" />
    </svg>
);

export default IconExclamation;
