import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import { ProfileProgress } from '../../../containers';
import { ActivationRef, ProfileStep, ProfileStepId } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { trans } from '../../../helpers/trans';
import useTimeout from '../../../hooks/useTimeout';
import { RoleType } from '../../../models/User';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCandidateProgressProps {
    activationRefs: Record<string, ActivationRef>;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCandidateProgress: FC<ConnectedCandidateProgressProps> = ({
    activationRefs,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [progressSteps, setProgressSteps] = useState<ProfileStep[]>([]);
    const [profileProgressIsCompleted, setProfileProgressIsCompleted] = useState<boolean>(false);
    const [shouldReveal, setShouldReveal] = useState<boolean>(true);

    const person = useLegacySelector(state => state.userPerson.data);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const hideDelay = 7500;
    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    useEffect((): void => {
        if (isEditable && candidate) {
            const studentProgressSteps = [
                {
                    order: 2,
                    isCompleted: candidate.competenciesToLearn.length > 0,
                    stepId: ProfileStepId.competenciesToLearn,
                    activationRef: activationRefs[ProfileStepId.competenciesToLearn],
                    title: trans('candidateProfile.progress.step.competenciesToLearn.title'),
                    description: trans('candidateProfile.progress.step.competenciesToLearn.description'),
                    callToAction: trans('candidateProfile.progress.step.competenciesToLearn.callToAction'),
                },
                {
                    order: 3,
                    isCompleted: [...candidate.skillsToLearn, ...candidate.escoSkillsToLearn].length > 0,
                    stepId: ProfileStepId.skillsToLearn,
                    activationRef: activationRefs[ProfileStepId.skillsToLearn],
                    title: trans('candidateProfile.progress.step.skillsToLearn.title'),
                    description: trans('candidateProfile.progress.step.skillsToLearn.description'),
                    callToAction: trans('candidateProfile.progress.step.skillsToLearn.callToAction'),
                },
            ];

            const jobSeekerProgressSteps = [
                {
                    order: 8,
                    isCompleted: !!candidate.catchphrase,
                    stepId: ProfileStepId.catchphrase,
                    activationRef: activationRefs[ProfileStepId.catchphrase],
                    title: trans('candidateProfile.progress.step.catchphrase.title'),
                    description: trans('candidateProfile.progress.step.catchphrase.description'),
                    callToAction: trans('candidateProfile.progress.step.catchphrase.callToAction'),
                },
                {
                    order: 6,
                    isCompleted: candidate.schoolExperiences.length > 0,
                    stepId: ProfileStepId.schoolExperience,
                    activationRef: activationRefs[ProfileStepId.schoolExperience],
                    title: trans('candidateProfile.progress.step.schoolExperience.title'),
                    description: trans('candidateProfile.progress.step.schoolExperience.description'),
                    callToAction: trans('candidateProfile.progress.step.schoolExperience.callToAction'),
                },
            ];

            const roleSpecificProgressSteps = candidate.role === RoleType.student
                ? studentProgressSteps
                : jobSeekerProgressSteps;

            const candidateProgressSteps = [
                ...roleSpecificProgressSteps,
                {
                    order: 0,
                    isCompleted: candidate.competencies.length > 0,
                    stepId: ProfileStepId.competencies,
                    activationRef: activationRefs[ProfileStepId.competencies],
                    title: trans('candidateProfile.progress.step.competencies.title'),
                    description: trans('candidateProfile.progress.step.competencies.description'),
                    callToAction: trans('candidateProfile.progress.step.competencies.callToAction'),
                },
                {
                    order: 1,
                    isCompleted: [...candidate.skills, ...candidate.escoSkills].length > 0,
                    stepId: ProfileStepId.skills,
                    activationRef: activationRefs[ProfileStepId.skills],
                    title: trans('candidateProfile.progress.step.skills.title'),
                    description: trans('candidateProfile.progress.step.skills.description'),
                    callToAction: trans('candidateProfile.progress.step.skills.callToAction'),
                },
                {
                    order: 4,
                    isCompleted: !!candidate.avatar,
                    stepId: ProfileStepId.avatar,
                    activationRef: activationRefs[ProfileStepId.avatar],
                    title: trans('candidateProfile.progress.step.avatar.title'),
                    description: trans('candidateProfile.progress.step.avatar.description'),
                    callToAction: trans('candidateProfile.progress.step.avatar.callToAction'),
                },
                {
                    order: 5,
                    isCompleted: !!candidate.description,
                    stepId: ProfileStepId.about,
                    activationRef: activationRefs[ProfileStepId.about],
                    title: trans('candidateProfile.progress.step.about.title'),
                    description: trans('candidateProfile.progress.step.about.description'),
                    callToAction: trans('candidateProfile.progress.step.about.callToAction'),
                },
                {
                    order: 7,
                    isCompleted: candidate.jobExperiences.length > 0,
                    stepId: ProfileStepId.jobExperience,
                    activationRef: activationRefs[ProfileStepId.jobExperience],
                    title: trans('candidateProfile.progress.step.jobExperience.title'),
                    description: trans('candidateProfile.progress.step.jobExperience.description'),
                    callToAction: trans('candidateProfile.progress.step.jobExperience.callToAction'),
                },
                {
                    order: 9,
                    isCompleted: candidate.employmentPreferences.max > 0,
                    stepId: ProfileStepId.employmentPreference,
                    activationRef: activationRefs[ProfileStepId.employmentPreference],
                    title: trans('candidateProfile.progress.step.employmentPreference.title'),
                    description: trans('candidateProfile.progress.step.employmentPreference.description'),
                    callToAction: trans('candidateProfile.progress.step.employmentPreference.callToAction'),
                },
                {
                    order: 10,
                    isCompleted: !!candidate.workRemotePreference.value,
                    stepId: ProfileStepId.workRemote,
                    activationRef: activationRefs[ProfileStepId.workRemote],
                    title: trans('candidateProfile.progress.step.workRemote.title'),
                    description: trans('candidateProfile.progress.step.workRemote.description'),
                    callToAction: trans('candidateProfile.progress.step.workRemote.callToAction'),
                },
                {
                    order: 11,
                    isCompleted: candidate.driversLicenses.length > 0,
                    stepId: ProfileStepId.driversLicenses,
                    activationRef: activationRefs[ProfileStepId.driversLicenses],
                    title: trans('candidateProfile.progress.step.driversLicenses.title'),
                    description: trans('candidateProfile.progress.step.driversLicenses.description'),
                    callToAction: trans('candidateProfile.progress.step.driversLicenses.callToAction'),
                },
                {
                    order: 12,
                    isCompleted: candidate.preferredSectors.length > 0,
                    stepId: ProfileStepId.sectors,
                    activationRef: activationRefs[ProfileStepId.sectors],
                    title: trans('candidateProfile.progress.step.sectors.title'),
                    description: trans('candidateProfile.progress.step.sectors.description'),
                    callToAction: trans('candidateProfile.progress.step.sectors.callToAction'),
                },
                {
                    order: 13,
                    isCompleted: !!candidate.video,
                    stepId: ProfileStepId.youTube,
                    activationRef: activationRefs[ProfileStepId.youTube],
                    title: trans('candidateProfile.progress.step.youTube.title'),
                    description: trans('candidateProfile.progress.step.youTube.description'),
                    callToAction: trans('candidateProfile.progress.step.youTube.callToAction'),
                },
            ];

            const sortedCandidateProgressSteps = candidateProgressSteps
                .sort((step, comparisonStep) => step.order - comparisonStep.order);

            setProgressSteps(sortedCandidateProgressSteps);

            const isCompleted = candidateProgressSteps.every(step => step.isCompleted);
            setProfileProgressIsCompleted(isCompleted);

            if (!isCompleted) {
                setShouldReveal(true);
            }

            if (isFirstRender) {
                setShouldReveal(!isCompleted);
                setIsFirstRender(false);
            }
        }
    }, [candidate]);

    useTimeout((): void => {
        if (profileProgressIsCompleted) {
            setShouldReveal(false);
        }
    }, hideDelay, [profileProgressIsCompleted]);

    const handleFinishProgress = (): void => {
        setShouldReveal(false);
    };

    const handleCallToActionClick = (stepId: ProfileStepId): void => {
        const selectedStep = progressSteps.find(step => step.stepId === stepId);

        if (selectedStep?.activationRef?.current) {
            selectedStep.activationRef.current.click();
        }
    };

    return shouldReveal && isEditable && progressSteps.length > 0 ? (
        <ProfileProgress
            title={trans('candidateProfile.progress.title')}
            steps={progressSteps}
            finishedTitle={trans('candidateProfile.progress.finished.title')}
            finishedDescription={trans('candidateProfile.progress.finished.description')}
            onCallToActionClick={handleCallToActionClick}
            onFinishClick={handleFinishProgress}
            className={className}
        />
    ) : null;
};

export default ConnectedCandidateProgress;
