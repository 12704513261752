import sanitize from 'sanitize-html';

type SanitizeCustomOptions = sanitize.IOptions;

export type SanitizeOptions = Pick<SanitizeCustomOptions, 'allowedTags' | 'allowedAttributes'>;

const defaultAllowedHtmlTags = [
    ...['h1', 'h2', 'h3', 'h4'],
    ...['p', 'strong', 'em', 'a', 'b', 'i'],
    ...['ul', 'ol', 'li'],
    ...['br', 'pre', 'img'],
];

const defaultAllowedHtmlAttributes = {
    a: ['href'],
    img: ['src', 'alt'],
};

export const sanitizeHtml = (html: string, options?: SanitizeOptions): string => sanitize(html, {
    allowedTags: [
        ...defaultAllowedHtmlTags,
        ...(options?.allowedTags || []),
    ],
    allowedAttributes: {
        ...defaultAllowedHtmlAttributes,
        ...(options?.allowedAttributes || {}),
    },
});

export const sanitizeAllHtml = (html: string): string => sanitize(html, {
    allowedTags: [],
    allowedAttributes: {},
});

export const sanitizeCustomHtml = (html: string, options?: SanitizeCustomOptions): string => sanitize(html, options);
