import React, {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Card, RootPortal } from '../../components';
import { trans } from '../../helpers/trans';
import { IconButton } from '..';

import './Modal.scss';

export interface ModalProps {
    transitionDuration?: number;
    onClose: () => void;
    className?: string;
    cardClassName?: string;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
    transitionDuration = 200,
    onClose,
    className = '',
    cardClassName = '',
    children,
}): ReactElement => {
    const [isRevealed, setIsRevealed] = useState<boolean>(false);

    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const hideModal = (): void => {
        setIsRevealed(false);
        setTimeout(onClose, transitionDuration);
    };

    useEffect((): void => {
        setIsRevealed(true);
    }, []);

    useEffect((): () => void => {
        if (isRevealed) document.body.classList.add('has-active-modal');

        if (isRevealed && closeButtonRef.current) {
            closeButtonRef.current.focus();
            closeButtonRef.current.blur();
        }

        return (): void => document.body.classList.remove('has-active-modal');
    }, [isRevealed]);

    const cssVariables = {
        '--modal-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    const modalClassNames = classNames('modal', {
        'modal--revealed': isRevealed,
    }, className);

    return (
        <RootPortal>
            <section style={cssVariables} className={modalClassNames}>
                <Card className={`modal__card ${cardClassName}`}>
                    <IconButton
                        ref={closeButtonRef}
                        icon="cross"
                        text={trans('actions.closeModal')}
                        hideLabel
                        onClick={hideModal}
                        className="modal__close-button"
                    />

                    {children}
                </Card>
            </section>
        </RootPortal>
    );
};

export default Modal;
