import React, {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
} from 'react';

import './VacancyIndexColumnList.scss';

interface VacancyIndexColumnListProps {
    columnAmount?: number;
    className?: string;
}

const VacancyIndexColumnList: FC<PropsWithChildren<VacancyIndexColumnListProps>> = ({
    columnAmount = 3,
    className = '',
    children,
}): ReactElement => {
    const cssVariables = {
        '--vacancy-index-column-amount': columnAmount,
    } as CSSProperties;

    return (
        <ul style={cssVariables} className={`vacancy-index-column-list ${className}`}>
            {children}
        </ul>
    );
};

export default VacancyIndexColumnList;
