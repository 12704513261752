import React, {
    FC,
    ReactElement,
    UIEvent,
    useState,
} from 'react';

import './Table.scss';

interface TableScroll {
    x: number;
    y: number;
}

type HTMLTableProps = JSX.IntrinsicElements['table'];

interface TableProps extends HTMLTableProps {
    id?: string;
    onTableScroll?: (scroll: TableScroll) => void;
    className?: string;
}

const Table: FC<TableProps> = ({
    onTableScroll,
    className = '',
    children,
    ...tableProps
}): ReactElement => {
    const [hasScrolled, setHasScrolled] = useState(false);

    const handleScroll = (event: UIEvent<HTMLTableElement>): void => {
        if (event.currentTarget) {
            const x = event.currentTarget.scrollLeft;
            const y = event.currentTarget.scrollTop;

            setHasScrolled(x > 0 || y > 0);

            if (onTableScroll) {
                onTableScroll({ x, y });
            }
        }
    };

    return (
        <table
            {...tableProps}
            data-scrolled={hasScrolled}
            onScroll={handleScroll}
            className={`table ${className}`}
        >
            {children}
        </table>
    );
};

export default Table;
