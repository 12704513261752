import React, { Component, createRef } from 'react';

import axios from 'axios';
import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import ReactSelect from '../../../../components/form/ReactSelect/ReactSelect';
import TextInput from '../../../../components/form/TextInput';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading } from '../../../../styledComponents/components/Typography';
import ZipcodeCheck from './ZipcodeCheck';

class CompanyDataForm extends Component {
    constructor() {
        super();

        this.companyForm = createRef();
        this.state = {
            loadingOptions: true,
            amountOfEmployeesOptions: [],
            selectedAmountOfEmployees: null,
        };
    }

    componentDidMount() {
        this.getAmountOfEmployees();
    }

    getAmountOfEmployees() {
        const { company } = this.props;

        axios.get('/amount-of-employees?only=uuid,name')
            .then(response => this.setState({
                amountOfEmployeesOptions: response.data.data,
                loadingOptions: false,
                selectedAmountOfEmployees: company ? company.amount_of_employees.uuid : null,
            }));
    }

    onSubmit = e => {
        e.preventDefault();
        this.props.onSubmit(
            serialize(this.companyForm.current, { hash: true, empty: true }),
        );
    };

    render() {
        const {
            errors,
            loading,
            company,
        } = this.props;
        const {
            amountOfEmployeesOptions,
            loadingOptions,
            selectedAmountOfEmployees,
        } = this.state;

        return (
            <>
                <Heading as="h2" size="xl" mb="sm">{trans('forms.register.employee.title')}</Heading>
                <form id="company-data-form" ref={this.companyForm} onSubmit={this.onSubmit}>
                    <Row>
                        <Col mb="md" width={1}>
                            <TextInput
                                required
                                errors={errors.name}
                                label={trans('forms.company.name.label')}
                                placeholder={trans('forms.company.name.placeholder')}
                                name="name"
                                defaultValue={company && company.name}
                            />
                        </Col>
                    </Row>
                    <ZipcodeCheck
                        {...{
                            userAddress: company && company.address,
                            errors,
                        }}
                    />
                    <Row>
                        <Col mb="md" width={1}>
                            <TextInput
                                errors={errors.website}
                                label={trans('forms.company.website.label')}
                                placeholder={trans('forms.company.website.placeholder')}
                                name="website"
                                defaultValue={company && company.website}
                            />
                        </Col>
                    </Row>
                    <Row mb="md">
                        <Col mb="md" width={4 / 5}>
                            <ReactSelect
                                required
                                id="amount-of-employees"
                                errors={errors.amount_of_employees_id}
                                label={trans('forms.company.amountOfEmployees.label')}
                                placeholder={trans('forms.company.amountOfEmployees.placeholder')}
                                name="amount_of_employees_id"
                                keyVal={['name', 'uuid']}
                                options={amountOfEmployeesOptions}
                                selected={selectedAmountOfEmployees}
                                optionsLoading={loadingOptions}
                            />
                        </Col>
                    </Row>
                    <Row justifyContent="flex-end">
                        <Col>
                            <Button id="company-data-form-submit" isLoading={loading} type="submit">
                                {trans('actions.save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

CompanyDataForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    company: PropTypes.object,
};

CompanyDataForm.defaultProps = {
    errors: {},
    company: null,
};

export default CompanyDataForm;
