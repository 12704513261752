/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortingOptions } from '../../../../enums/sortingOptions';
import { JobVacancyOverviewFilterValues } from '../../../../models/VacancyOverview';
import { JobPreferencesApiRequestParams } from '../../models/JobPreferences';
import { JobVacancy } from '../../models/JobVacancy';
import { Pagination } from '../../models/Pagination';
import { ApplyTypeOptions, FilterParams } from '../internshipVacancies/internshipVacancies';

// TODO: Remove once preferences is refactored or removed
export interface JobVacanciesFilterParams extends FilterParams {
    job_types?: string[];
    gross_salary?: string;
    travel_distance?: string;
    zipcode?: string;
    profession?: string;
    employments?: string[];
    education_levels?: string[];
    work_remote?: string[];
    amount_of_employees?: string[];
    sort_by?: SortingOptions;
    page?: number;
    province?: string;
    sectors?: string[];
    location?: string;
}

export const transformToLegacyJobVacanciesFilterParams = (
    filterValues: JobVacancyOverviewFilterValues,
): JobVacanciesFilterParams => ({
    page: filterValues.pageNumber,
    limit: filterValues.pageSize,
    s: filterValues.what,
    location: filterValues.where,
    zipcode: filterValues.postalCode,
    province: filterValues.province,
    apply_types: filterValues.applyType
        ? [filterValues.applyType] as ApplyTypeOptions[]
        : undefined,
    sectors: filterValues.sectors,
    education_levels: filterValues.educationLevels,
    work_remote: filterValues.workRemotePreference,
    job_types: filterValues.jobTypes,
    employments: undefined,
    amount_of_employees: filterValues.amountOfEmployees,
    gross_salary: String(filterValues.salary),
    travel_distance: String(filterValues.distance),
});
//

export type JobVacanciesState = Readonly<{
    vacancySaveLoading: boolean;
    vacanciesLoading: boolean;
    vacancyLoading: boolean;
    vacancies: JobVacancy[] | null;
    pagination: Pagination | null;
    vacancy: JobVacancy | null;
    filterParams: JobVacanciesFilterParams | Record<string, unknown>;
}>;

const initialState: JobVacanciesState = {
    vacancySaveLoading: false,
    vacanciesLoading: false,
    vacancyLoading: false,
    vacancies: [],
    pagination: null,
    vacancy: null,
    filterParams: {},
};

export function transformFiltersToJobPreferencesRequest(params: JobVacanciesFilterParams): JobPreferencesApiRequestParams {
    return {
        amount_of_employees: params.amount_of_employees || [],
        apply_types: params.apply_types || [],
        gross_salary: params.gross_salary || '0',
        job_types: params.job_types || [],
        profession_id: params.profession || null,
        travel_distance: params.travel_distance || '0',
        zipcode: params.zipcode || null,
        sectors: params.sectors || [],
    };
}

const jobVacanciesSlice = createSlice({
    initialState,
    name: 'jobVacancies',
    reducers: {
        resetJobVacanciesState(): JobVacanciesState {
            return initialState;
        },
        setJobVacancyLoading(state, action: PayloadAction<boolean>): JobVacanciesState {
            return {
                ...state,
                vacancyLoading: action.payload,
            };
        },
        setJobVacancySaveLoading(state, action: PayloadAction<boolean>): JobVacanciesState {
            return {
                ...state,
                vacancySaveLoading: action.payload,
            };
        },
        jobVacancyFetchSuccess(state, action: PayloadAction<JobVacancy>): JobVacanciesState {
            return {
                ...state,
                vacancy: action.payload,
            };
        },
        setFilterParams(state, action: PayloadAction<JobVacanciesFilterParams>): JobVacanciesState {
            return {
                ...state,
                filterParams: action.payload,
            };
        },
    },
});

export const {
    resetJobVacanciesState,
    setJobVacancyLoading,
    jobVacancyFetchSuccess,
    setFilterParams,
    setJobVacancySaveLoading,
} = jobVacanciesSlice.actions;

export default jobVacanciesSlice;
/* eslint-enable camelcase */
