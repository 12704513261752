import { isValidDateString } from '../helpers/date';
import getIncluded from '../japi/getIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { ChatMessageResource, transformJapiDocumentToChatMessage } from './ChatMessages';
import {
    CompanyInfo,
    CompanyInfoResource,
    defaultCompanyInfo,
    transformToCompanyInfo,
} from './info/CompanyInfo';
import {
    defaultPersonInfo,
    PersonInfo,
    PersonInfoResource,
    transformToPersonInfo,
} from './info/PersonInfo';

export interface ChatResource extends Resource {
    type: 'company-chats';
    id: string;
    attributes: {
        hasUnresolvedReports: boolean;
        createdAt: string;
    };
    relationships: {
        candidate: SingleRelationship<'person-info'>;
        company: SingleRelationship<'company-info'>;
        lastMessage?: SingleRelationship<'company-chat-messages'>;
        messages?: MultipleRelationship<'company-chat-messages'>;
    };
}

export interface CreateChatWithCompanyRequest {
    data: {
        type: 'company-chats';
        relationships: {
            company: SingleRelationship<'companies'>;
        };
    };
}

export interface CreateChatWithCandidateRequest {
    data: {
        type: 'company-chats';
        relationships: {
            candidate: SingleRelationship<'people'>;
        };
    };
}

export interface Chat {
    uuid: string;
    isRead: boolean;
    isReported: boolean;
    latestMessage?: string;
    candidate: PersonInfo;
    company: CompanyInfo;
    createdAt: Date;
}

export interface ChatConnection {
    uuid: string;
    socket?: WebSocket;
}

export const transformJapiDocumentToChat = (
    doc: JapiDocument,
    chatResource: ChatResource,
): Chat => {
    const includedCandidate = getIncluded<PersonInfoResource>(doc, chatResource, 'candidate');
    const includedCompany = getIncluded<CompanyInfoResource>(doc, chatResource, 'company');
    const includedLatestMessage = getIncluded<ChatMessageResource>(doc, chatResource, 'lastMessage');

    const candidate = includedCandidate
        ? transformToPersonInfo(includedCandidate)
        : defaultPersonInfo();

    const company = includedCompany
        ? transformToCompanyInfo(includedCompany)
        : defaultCompanyInfo();

    const latestMessage = includedLatestMessage
        ? transformJapiDocumentToChatMessage(doc, includedLatestMessage, company)
        : undefined;

    return {
        uuid: chatResource.id,
        isRead: true,
        isReported: chatResource.attributes.hasUnresolvedReports,
        latestMessage: latestMessage?.text,
        candidate,
        company,
        createdAt: isValidDateString(chatResource.attributes.createdAt)
            ? new Date(chatResource.attributes.createdAt)
            : new Date(),
    };
};

export const transformToCreateChatWithCompanyRequest = (companyUuid: string): CreateChatWithCompanyRequest => ({
    data: {
        type: 'company-chats',
        relationships: {
            company: {
                data: {
                    id: companyUuid,
                    type: 'companies',
                },
            },
        },
    },
});

export const transformToCreateChatWithCandidateRequest = (candidateUuid: string): CreateChatWithCandidateRequest => ({
    data: {
        type: 'company-chats',
        relationships: {
            candidate: {
                data: {
                    id: candidateUuid,
                    type: 'people',
                },
            },
        },
    },
});
