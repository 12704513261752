/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortingOptions } from '../../../../enums/sortingOptions';
import { InternshipVacancyOverviewFilterValues } from '../../../../models/VacancyOverview';
import { InternshipPreferencesApiRequestParams } from '../../models/InternshipPreferences';
import { InternshipVacancy } from '../../models/InternshipVacancy';
import { Pagination } from '../../models/Pagination';

export type ApplyTypeOptions = 'apply_regular' | 'apply_anonymous';

export interface FilterParams {
    page?: number;
    apply_types?: ApplyTypeOptions[];
    limit?: number;
    s?: string;
}

// TODO: Remove once preferences is refactored or removed
export interface InternshipVacanciesFilterParams extends FilterParams {
    amount_of_employees?: string[];
    compensation?: string;
    grade?: string;
    internship_types?: string[];
    travel_distance?: string;
    education_levels?: string[];
    work_remote?: string[];
    zipcode?: string;
    sort_by?: SortingOptions;
    sectors?: string[];
    location?: string;
    has_sbb_approval?: boolean;
}

export const transformToLegacyInternshipVacanciesFilterParams = (
    filterValues: InternshipVacancyOverviewFilterValues,
): InternshipVacanciesFilterParams => ({
    page: filterValues.pageNumber,
    limit: filterValues.pageSize,
    s: filterValues.what,
    location: filterValues.where,
    zipcode: filterValues.postalCode,
    apply_types: filterValues.applyType
        ? [filterValues.applyType] as ApplyTypeOptions[]
        : undefined,
    sectors: filterValues.sectors,
    education_levels: filterValues.educationLevels,
    work_remote: filterValues.workRemotePreference,
    internship_types: filterValues.internshipTypes,
    grade: filterValues.grade,
    amount_of_employees: filterValues.amountOfEmployees,
    compensation: String(filterValues.compensation),
    travel_distance: String(filterValues.distance),
    has_sbb_approval: filterValues.sbbApproval.length > 0,
});
//

export type InternshipVacanciesState = Readonly<{
    vacanciesLoading: boolean;
    vacancyLoading: boolean;
    vacancies: InternshipVacancy[] | null;
    pagination: Pagination | null;
    vacancy: InternshipVacancy | null;
    filterOptionsLoading: boolean;
    filterOptions: any | null;
    filterParams: InternshipVacanciesFilterParams | Record<string, unknown>;
}>;

const initialState: InternshipVacanciesState = {
    vacanciesLoading: false,
    vacancyLoading: false,
    vacancies: [],
    pagination: null,
    vacancy: null,
    filterOptionsLoading: false,
    filterOptions: null,
    filterParams: {
        sort_by: 'publication_date',
    },
};

export function transformFiltersToInternshipPreferencesRequest(params: InternshipVacanciesFilterParams): InternshipPreferencesApiRequestParams {
    return {
        amount_of_employees: params.amount_of_employees || [],
        apply_types: params.apply_types || [],
        compensation: params.compensation || '0',
        grade_id: params.grade,
        internship_types: params.internship_types || [],
        has_sbb_approval: params.has_sbb_approval,
        travel_distance: params.travel_distance || '0',
        zipcode: params.zipcode,
    };
}

const internshipVacanciesSlice = createSlice({
    initialState,
    name: 'internshipVacancies',
    reducers: {
        resetInternshipVacanciesState(): InternshipVacanciesState {
            return initialState;
        },
        setInternshipVacancyLoading(state, action: PayloadAction<boolean>): InternshipVacanciesState {
            return {
                ...state,
                vacancyLoading: action.payload,
            };
        },
        internshipVacancyFetchSuccess(state, action: PayloadAction<InternshipVacancy>): InternshipVacanciesState {
            return {
                ...state,
                vacancy: action.payload,
            };
        },
        internshipFilterOptionsLoading(state, action: PayloadAction<boolean>): InternshipVacanciesState {
            return {
                ...state,
                filterOptionsLoading: action.payload,
            };
        },
        internshipFilterOptionsFetchSuccess(state, action: PayloadAction<any>): InternshipVacanciesState {
            return {
                ...state,
                filterOptions: action.payload,
            };
        },
        setFilterParams(state, action: PayloadAction<InternshipVacanciesFilterParams>): InternshipVacanciesState {
            return {
                ...state,
                filterParams: action.payload,
            };
        },
    },
});

export const {
    resetInternshipVacanciesState,
    setInternshipVacancyLoading,
    internshipVacancyFetchSuccess,
    internshipFilterOptionsLoading,
    internshipFilterOptionsFetchSuccess,
    setFilterParams,
} = internshipVacanciesSlice.actions;

export default internshipVacanciesSlice;
/* eslint-enable camelcase */
