import React, { FC, ReactElement, useEffect } from 'react';

import { ApplicationStatusCard } from '../../../containers';
import { ApplicationAppointment, ApplicationAppointmentFormData } from '../../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../../models/ApplicationInvites';
import { ApplicationRejectionFormData } from '../../../models/ApplicationRejections';
import { useTypedDispatch, useTypedSelector } from '../../../store';
import { clearApplication, fetchApplication, rejectApplication } from '../../../store/application/applicationActions';
import { acceptApplicationInvite, createApplicationInvite, declineApplicationInvite } from '../../../store/applicationInvite/applicationInviteActions';

interface ConnectedApplicationStatusCardProps {
    shouldFetchApplication?: boolean;
    applicationUuid?: string;
    className?: string;
}

const ConnectedApplicationStatusCard: FC<ConnectedApplicationStatusCardProps> = ({
    shouldFetchApplication,
    applicationUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const applicationIsLoading = useTypedSelector(state => state.applicationReducer.isLoading);
    const application = useTypedSelector(state => state.applicationReducer.application);

    const applicationInviteIsLoading = useTypedSelector(state => state.applicationInviteReducer.isLoading);
    const applicationInvite = useTypedSelector(state => state.applicationInviteReducer.applicationInvite);

    useEffect((): () => void => {
        if (shouldFetchApplication && applicationUuid) {
            dispatch(fetchApplication(applicationUuid));
        }

        // Clear application data from store when component unmounts
        return (): void => {
            if (shouldFetchApplication) dispatch(clearApplication());
        };
    }, [dispatch, applicationUuid]);

    const handleAddApplicationInvite = async (applicationUuid: string, formData: ApplicationAppointmentFormData): Promise<void> => {
        await dispatch(createApplicationInvite(applicationUuid, formData));
        dispatch(fetchApplication(applicationUuid));
    };

    const handleAcceptApplicationInvite = (appointment: ApplicationAppointment, inviteUuid: string): void => {
        dispatch(acceptApplicationInvite(appointment, inviteUuid));
    };

    const handleDeclineApplicationInvite = (applicationInvite: ApplicationInvite): void => {
        dispatch(declineApplicationInvite(applicationInvite));
    };

    const handleRejectApplication = async (applicationUuid: string, formData: ApplicationRejectionFormData): Promise<void> => {
        await dispatch(rejectApplication(applicationUuid, formData));
        dispatch(fetchApplication(applicationUuid));
    };

    return application ? (
        <ApplicationStatusCard
            isLoading={applicationIsLoading || applicationInviteIsLoading}
            application={application}
            applicationInvite={applicationInvite}
            onAddApplicationInvite={handleAddApplicationInvite}
            onAcceptApplicationInvite={handleAcceptApplicationInvite}
            onDeclineApplicationInvite={handleDeclineApplicationInvite}
            onRejectApplication={handleRejectApplication}
            className={className}
        />
    ) : null;
};

export default ConnectedApplicationStatusCard;
