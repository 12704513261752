import React, { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import ucFirst from '../../../_old/helpers/ucFirst';
import { trans } from '../../../helpers/trans';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCandidateHelmetProps {
    shouldFetchCandidate?: boolean;
    isCompanyApplication?: boolean;
    isCompanyMatch?: boolean;
    candidateUuid?: string;
}

const ConnectedCandidateHelmet: FC<ConnectedCandidateHelmetProps> = ({
    shouldFetchCandidate,
    isCompanyApplication,
    isCompanyMatch,
    candidateUuid,
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const vacancy = useTypedSelector(state => state.vacancyDetailReducer.vacancy);
    const application = useTypedSelector(state => state.applicationReducer.application);

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    const isAnonymous = [
        isCompanyMatch && vacancy?.isAnonymous,
        isCompanyApplication && application?.vacancy.isAnonymous,
    ].some(Boolean);

    return candidate ? (
        <Helmet>
            <title>
                {isAnonymous
                    ? trans('candidateProfile.helmet.anonymousTitle')
                    : trans('candidateProfile.helmet.title', {
                        name: ucFirst(candidate.fullName),
                    })}
            </title>
            <meta name="description" content={candidate.description} />
        </Helmet>
    ) : null;
};

export default ConnectedCandidateHelmet;
