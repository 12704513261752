import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../../../../helpers/trans';
import CardsSliderBlock from '../../../../containers/CardsSliderBlock/CardsSliderBlock';
import { Styles } from './styles';

const GOV = 'Gov';
const SCHOOL = 'School';
const ORG = 'Org';

const TeamAmbassador: FC = (): ReactElement => (
    <Styles>
        <CardsSliderBlock
            title={trans('infoPage.team.ambassadors.title')}
            defaultFilterUuid={GOV}
            className="team-ambassador__slider"
            filters={[
                { uuid: GOV, name: trans('infoPage.team.ambassadors.municipalities') },
                { uuid: SCHOOL, name: trans('infoPage.team.ambassadors.schools') },
                { uuid: ORG, name: trans('infoPage.team.ambassadors.organizations') },
            ]}
            cards={[
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/uwv.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/vno-ncw.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/rpa.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/nhn.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/tt-logo.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/bedrijfskring.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/ondernemedalkmaar.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/ohra.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/wbg.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/huisvanhetwerk.png',
                },
                {
                    category: ORG,
                    imageSrc: 'images/ambassadors/ondernemendregioalkmaar.png',
                },

                // Schools (repeated twice to fill the page)
                {
                    category: SCHOOL,
                    imageSrc: 'images/ambassadors/horzion.png',
                },
                {
                    category: SCHOOL,
                    imageSrc: 'images/ambassadors/regiocollege.png',
                },
                {
                    category: SCHOOL,
                    imageSrc: 'images/ambassadors/elhbo.png',
                },
                {
                    category: SCHOOL,
                    imageSrc: 'images/ambassadors/horzion.png',
                },
                {
                    category: SCHOOL,
                    imageSrc: 'images/ambassadors/regiocollege.png',
                },
                {
                    category: SCHOOL,
                    imageSrc: 'images/ambassadors/elhbo.png',
                },

                // Goverments
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/hollandsekroon.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/westfriesland.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/stadvankansen.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/alkmaar.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/bergen.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/langedijk.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/heiloo.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/castricum.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/uitgeest.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/schagen.png',
                },
                {
                    category: GOV,
                    imageSrc: 'images/ambassadors/denhelder.png',
                },
            ]}
        />
    </Styles>
);

export default TeamAmbassador;
