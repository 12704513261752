import { Resource } from '../japi/types/Resource';

export enum VacancyTypeSlug {
    job = 'job',
    internship = 'internship',
}

export interface VacancyTypeResource extends Resource {
    type: 'vacancy-types';
    id: string;
    attributes: {
        name: string;
        slug: VacancyTypeSlug;
        createdAt: string;
        updatedAt: string;
    };
}

export interface VacancyType {
    id: string;
    name: string;
    slug: VacancyTypeSlug;
}

export const transformToVacancyType = (
    vacancyTypeResource: VacancyTypeResource,
): VacancyType => ({
    id: vacancyTypeResource.id,
    name: vacancyTypeResource.attributes.name,
    slug: vacancyTypeResource.attributes.slug,
});
