import styled, { css } from 'styled-components';
import { display, space } from 'styled-system';

export default styled('div')(props => css`
    min-height: calc(100vh - ${props.theme.variables.navHeight});
    margin-top: ${props.theme.variables.navHeight};
    padding: ${`${props.theme.space.md} 0 ${props.theme.space.xl} 0`};
    background-color: ${props.theme.colors.background};
    
    ${props.color && css`
        background-color: ${props.theme.colors[props.color]};
    `}
    
    @media ${props.theme.mediaQueries.laptop} {
        padding: ${`${props.theme.space.xl} 0 ${props.theme.space.xxl} 0`};
        ${space};
    }
    
    ${display};
    ${space};
`);
