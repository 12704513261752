import { trans } from '../../helpers/trans';
import { ChatReportFormData, UndoChatReportFormData } from '../../models/ChatReport';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { deleteChatReportApiCall, getChatReportsApiCall, postChatReportApiCall } from '../../services/ChatReportService';
import { ReducerGetter, TypedDispatch } from '..';
import { broadcastChatReportState } from '../chatMessages/chatMessagesActions';
import { setChatReportedStatus } from '../chats/chatsActions';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setChatReports, setError, setIsLoading } from './chatReports';

export const clearChatReports = () => (dispatch: TypedDispatch): void => {
    dispatch(setChatReports([]));
};

export const fetchChatReports = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const reportChatResponse = await getChatReportsApiCall();

        if (!isFetchResultSuccessful(reportChatResponse)) {
            console.error('[fetchChatReports]', reportChatResponse.error);
            dispatch(setError(reportChatResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        const chatReports = reportChatResponse.data;

        dispatch(setChatReports(chatReports));
    } catch (error) {
        console.error('[fetchChatReports]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const reportChat = (chatUuid: string, chatReportFormData: ChatReportFormData) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const reportChatResponse = await postChatReportApiCall(chatUuid, chatReportFormData);

        if (!isFetchResultSuccessful(reportChatResponse)) {
            console.error('[reportChat]', reportChatResponse.error);
            dispatch(setError(reportChatResponse.error));

            dispatch(addNegativeToast({
                title: trans('toast.failure'),
                description: trans('toast.chatReport.create.failure.description'),
            }));

            return;
        }

        const { chatsReducer, chatReportsReducer } = getState();
        const { chatConnections } = chatsReducer;
        const { chatReports } = chatReportsReducer;

        const activeChatConnection = chatConnections.find(chat => chat.uuid === chatUuid);
        const newChatReport = reportChatResponse.data;

        dispatch(setChatReports([
            newChatReport,
            ...chatReports,
        ]));

        dispatch(setChatReportedStatus(newChatReport.chatUuid, true));

        if (activeChatConnection) {
            dispatch(broadcastChatReportState(activeChatConnection, true));
        }

        dispatch(addPositiveToast({
            title: trans('toast.chatReport.create.success.title'),
            description: trans('toast.chatReport.create.success.description'),
        }));
    } catch (error) {
        console.error('[reportChat]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const undoChatReport = (undoChatReportFormData: UndoChatReportFormData) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const undoChatReportResponse = await deleteChatReportApiCall(undoChatReportFormData);

        if (!isFetchResultSuccessful(undoChatReportResponse)) {
            console.error('[undoChatReport]', undoChatReportResponse.error);
            dispatch(setError(undoChatReportResponse.error));

            dispatch(addNegativeToast({
                title: trans('toast.failure'),
                description: trans('toast.chatReport.delete.failure.description'),
            }));

            return;
        }

        const { chatsReducer, chatReportsReducer } = getState();
        const { chatConnections } = chatsReducer;
        const { chatReports } = chatReportsReducer;
        const { selectedReport } = undoChatReportFormData;

        const reportToUpdate = chatReports.find(chatReport => chatReport.uuid === selectedReport);
        const filteredChatReports = chatReports.filter(chatReport => chatReport.uuid !== selectedReport);

        dispatch(setChatReports(filteredChatReports));

        if (reportToUpdate) {
            const activeChatConnection = chatConnections.find(chat => chat.uuid === reportToUpdate.chatUuid);

            dispatch(setChatReportedStatus(reportToUpdate.chatUuid, false));

            if (activeChatConnection) {
                dispatch(broadcastChatReportState(activeChatConnection, false));
            }
        }

        dispatch(addPositiveToast({
            title: trans('toast.chatReport.delete.success.title'),
            description: trans('toast.chatReport.delete.success.description'),
        }));
    } catch (error) {
        console.error('[undoChatReport]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
