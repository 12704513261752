import axios from 'axios';

import { getWebPushSubscription, subscribeWebPush } from '../../../helpers/ServiceWorkerHelper';
import { setErrors, setLoading, setSubscription } from './notifications';

/**
 * Subscribe for push notifications
 *
 * @returns {Function}
 */
export function webPushSubscribe() {
    return dispatch => {
        dispatch(setLoading(true));

        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve, reject) => subscribeWebPush()
            .then(subscription => {
                axios.post('/me/notifications/subscriptions', {
                    subscription,
                })
                    .then(response => {
                        dispatch(setSubscription(response.data.data));

                        resolve(response.data);
                    })
                    .catch(errors => {
                        dispatch(setErrors(errors.response.data));
                        dispatch(setLoading(false));

                        reject(errors.response.data);
                    });
            })
            .catch(error => {
                dispatch(setErrors({ notifications: [error] }));
                dispatch(setLoading(false));

                reject(error);
            }));
    };
}

/**
 * Check if the web-push subscription of the browser is present within the user's subscription
 * or subscribe and create it
 *
 * @returns {Function}
 */
export function checkForWebPushSubscription() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        const { legacyUser, userPersonSettings } = getState();
        const userSubscriptions = legacyUser.data.notification_subscriptions;
        const notificationsAllowed = userPersonSettings?.data?.notifications;

        if (!notificationsAllowed || !getWebPushSubscription()) {
            return;
        }

        getWebPushSubscription().then(subscription => {
            if (subscription !== null) {
                dispatch(setSubscription(subscription));

                const browserSubscriptionsInBackend = userSubscriptions.filter(
                    sub => sub.endpoint === subscription.endpoint,
                );

                if (browserSubscriptionsInBackend.length !== 0) {
                    return false;
                }
            }

            return dispatch(webPushSubscribe());
        })
            .catch(error => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    };
}
