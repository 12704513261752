import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon, Skeleton } from '../../../components';
import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoutePaths } from '../../../routes';
import { CompanyOverviewTab } from '../../../types/pageTabs';

import './CandidateProfileBreadcrumbs.scss';

interface CandidateProfileBreadcrumbsProps {
    isLoading: boolean;
    isCompanyApplication?: boolean;
    isCompanyMatch?: boolean;
    vacancy?: NewestVacancy;
    candidate?: Candidate;
    className?: string;
}

const CandidateProfileBreadcrumbs: FC<CandidateProfileBreadcrumbsProps> = ({
    isLoading,
    isCompanyApplication,
    isCompanyMatch,
    vacancy,
    candidate,
    className = '',
}): ReactElement => (
    <div className={`candidate-profile-breadcrumbs ${className}`}>
        {isLoading && (
            <Skeleton className="candidate-profile-breadcrumbs__skeleton" />
        )}

        {!isLoading && vacancy && candidate && (
            <ul className="candidate-profile-breadcrumbs__list">
                <li className="candidate-profile-breadcrumbs__item">
                    <Link
                        to={RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies)}
                        className="candidate-profile-breadcrumbs__link"
                    >
                        {trans('containers.candidateProfileBreadcrumbs.myVacancies')}
                    </Link>

                    <Icon name="chevron-right" className="candidate-profile-breadcrumbs__icon" />
                </li>

                <li className="candidate-profile-breadcrumbs__item">
                    <Link
                        to={RoutePaths.myVacancyDetail(vacancy.uuid)}
                        className="candidate-profile-breadcrumbs__link"
                    >
                        {vacancy.title}
                    </Link>

                    <Icon name="chevron-right" className="candidate-profile-breadcrumbs__icon" />
                </li>

                {(isCompanyApplication || isCompanyMatch) && (
                    <li className="candidate-profile-breadcrumbs__item">
                        {isCompanyApplication && (
                            <Link
                                to={RoutePaths.companyOverviewApplicants(vacancy.uuid)}
                                className="candidate-profile-breadcrumbs__link"
                            >
                                {trans('containers.candidateProfileBreadcrumbs.applicants')}
                            </Link>
                        )}

                        {isCompanyMatch && (
                            <Link
                                to={RoutePaths.companyOverviewMatches(vacancy.uuid)}
                                className="candidate-profile-breadcrumbs__link"
                            >
                                {trans('containers.candidateProfileBreadcrumbs.matches')}
                            </Link>
                        )}

                        <Icon name="chevron-right" className="candidate-profile-breadcrumbs__icon" />
                    </li>
                )}

                <li className="candidate-profile-breadcrumbs__item">
                    {vacancy.isAnonymous ? trans('basic.anonymousCandidate') : candidate.fullName}
                </li>
            </ul>
        )}
    </div>
);

export default CandidateProfileBreadcrumbs;
