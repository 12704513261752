/* eslint-disable */
import PropertyTypes from '../models/PropertyTypes';
import { capatalizeFirstCharacter } from '../../helpers/textHelpers';
import { MinMaxValue } from '../../../types';
import { trans } from '../../../helpers/trans';

/**
 * Validates if a string is filled in and not empty
 *
 * @export
 * @param {string} propertyKey
 * @param {(string | null | undefined)} propertyValue
 * @returns {(string | null)}
 */
export function validateRequiredString(propertyKey: string, propertyValue: string | null | undefined): string | null {
    if (propertyValue && propertyValue.trim().length !== 0) {
        return null;
    }

    return trans('validation.filled', {
        Attribute: capatalizeFirstCharacter(trans(`validation.attributes.${propertyKey}`)),
    });
}

/**
 * Validates required properties
 * null/undefined = user did not fill in the property
 * empty string ('') = user touched the object and property will be validated
 *
 * @export
 * @param {string} propertyKey (also used for translation -> validation.attributes.{0})
 * @param {string} propertyValue
 * @param {boolean} validateEmpty
 * @deprecated use validateRequiredArray instead
 * @returns
 */
export function validateRequiredProperty(propertyKey: string, propertyValue?: string | number | Date | any[] | null, validateEmpty?: boolean): string[] {
    const errors = [];

    if (validateEmpty) {
        if (propertyValue === null || typeof propertyValue === 'undefined') {
            errors.push(trans('validation.filled', {
                Attribute: capatalizeFirstCharacter(trans(`validation.attributes.${propertyKey}`)),
            }));
        }
    }

    if (propertyValue !== null && typeof propertyValue !== 'undefined') {
        if (typeof propertyValue === 'string') {
            if (propertyValue.trim().length === 0) {
                errors.push(trans('validation.filled', {
                    Attribute: capatalizeFirstCharacter(trans(`validation.attributes.${propertyKey}`)),
                }));
            }
        } else if (propertyValue instanceof Date) {
            if (isNaN(propertyValue.getTime())) {
                errors.push(trans('validation.filled', {
                    Attribute: capatalizeFirstCharacter(trans(`validation.attributes.${propertyKey}`)),
                }));
            }
        } else if (Array.isArray(propertyValue)) {
            if (!propertyValue.length) {
                errors.push(trans('validation.filled', {
                    Attribute: capatalizeFirstCharacter(trans(`validation.attributes.${propertyKey}`)),
                }));
            }
        }
    }

    return errors;
}

export function validatePropertyForType(propertyKey: string, propertyValue: any, propertyType: PropertyTypes): string[] {
    const errors = [];

    if (propertyType === PropertyTypes.Number && isNaN(propertyValue)) {
        errors.push(trans('validation.integer', {
            Attribute: trans(`validation.attributes.${propertyKey}`),
        }));
    }

    return errors;
}

/**
 * Validates if a end date is not lower than the start date
 *
 * @export
 * @param {Date} startDate
 * @param {Date} endDate
 */
export function validateDateForOutOfBounds(startDate: Date, endDate: Date, startDatePropertyKey: string, endDatePropertyKey: string): string[] {
    const errors = [];

    if (startDate.getTime() > endDate.getTime()) {
        errors.push(trans('validation.before', {
            Attribute: capatalizeFirstCharacter(trans(`validation.attributes.${startDatePropertyKey}`)),
            date: trans(`validation.attributes.${endDatePropertyKey}`),
        }));
    }

    return errors;
}

export function validateRangeInput(key: string, range?: MinMaxValue): string[] {
    const errors = [];

    if (!range) {
        errors.push(trans('validation.range.isRequired', {
            attribute: trans(`validation.attributes.${key}`),
        }));
    }

    if (range && range.min > range.max) {
        errors.push(trans('validation.range.minExceedsMax', {
            attribute: trans(`validation.attributes.${key}`),
        }));
    }

    return errors;
}
