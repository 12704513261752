import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../_old/app_talentz/models/VacancyType';
import { SafeHtml, Skeleton } from '../../../components';
import { DetailCard } from '../../../compositions';
import { generateIdArray } from '../../../helpers/array';
import { formatDate } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';

import './VacancyDescriptionCard.scss';

interface VacancyDescriptionCardProps {
    isApplication?: boolean;
    isLoading: boolean;
    vacancy?: NewestVacancy;
    className?: string;
}

const VacancyDescriptionCard: FC<VacancyDescriptionCardProps> = ({
    isApplication,
    isLoading,
    vacancy,
    className = '',
}): ReactElement => {
    const skeletonAmount = 6;
    const skeletons = generateIdArray(skeletonAmount);

    const title = isApplication && vacancy
        ? vacancy.title
        : trans('vacancyDetail.description.title');

    return (
        <DetailCard
            title={title}
            className={`vacancy-description-card ${className}`}
        >
            {isLoading && (
                <div className="vacancy-description-card__skeleton-wrapper">
                    {skeletons.map(skeleton => (
                        <Skeleton key={skeleton} className="vacancy-description-card__skeleton" />
                    ))}
                </div>
            )}

            {!isLoading && (
                <SafeHtml
                    html={vacancy?.description}
                    className="vacancy-description-card__description"
                />
            )}

            {vacancy?.type === VacancyType.internship && vacancy?.periodFrom && vacancy?.periodTo && (
                <div className="vacancy-description-card__internship-period-wrapper">
                    <h2 className="vacancy-description-card__internship-period-title">
                        {trans('vacancyDetail.description.internshipPeriod.title')}
                    </h2>

                    <p className="vacancy-description-card__internship-period-range">
                        {trans('vacancyDetail.description.internshipPeriod.range', {
                            from: formatDate(vacancy.periodFrom, 'd MMMM yyyy'),
                            to: formatDate(vacancy.periodTo, 'd MMMM yyyy'),
                        })}
                    </p>
                </div>
            )}
        </DetailCard>
    );
};

export default VacancyDescriptionCard;
