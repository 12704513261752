import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CandidateAvatarCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { convertFileToBase64 } from '../../../helpers/file';
import { FileFormData } from '../../../models/File';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate, patchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCandidateAvatarCardProps {
    activationRef?: ActivationRef;
    isCompanyApplication?: boolean;
    isCompanyMatch?: boolean;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCandidateAvatarCard: FC<ConnectedCandidateAvatarCardProps> = ({
    activationRef,
    isCompanyApplication,
    isCompanyMatch,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const vacancy = useTypedSelector(state => state.vacancyDetailReducer.vacancy);
    const application = useTypedSelector(state => state.applicationReducer.application);

    const isWspUser = !!person?.is_wsp_user;
    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    const handleEditAvatar = async (avatarFormData: FileFormData): Promise<void> => {
        if (candidate) {
            if (avatarFormData.file) {
                const base64Image = await convertFileToBase64(avatarFormData.file);

                dispatch(patchCandidate({
                    uuid: candidate.uuid,
                    avatar: base64Image,
                }));
            } else {
                dispatch(patchCandidate({
                    uuid: candidate.uuid,
                    avatar: '',
                }));
            }
        }
    };

    const showLoading = [
        isCompanyMatch && !vacancy,
        isCompanyApplication && !application,
    ].some(Boolean);

    const isAnonymous = [
        isCompanyMatch && vacancy?.isAnonymous,
        isCompanyApplication && application?.vacancy.isAnonymous,
    ].some(Boolean);

    return (
        <CandidateAvatarCard
            avatarActivationRef={activationRef}
            isWspUser={isWspUser}
            isLoading={isLoading || showLoading}
            isEditable={isEditable}
            isAnonymous={isAnonymous}
            candidate={candidate}
            onEditAvatar={handleEditAvatar}
            className={className}
        />
    );
};

export default ConnectedCandidateAvatarCard;
