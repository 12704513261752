import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { MenuItem } from '../../../../connectors/ConnectedMenu/helpers';
import { MenuListItem } from '..';

import './MenuList.scss';

export enum MenuListTemplate {
    regular = 'regular',
    information = 'information',
    buttons = 'buttons',
    drawer = 'drawer',
    session = 'session',
    icon = 'icon',
}

interface MenuListProps {
    isLoading?: boolean;
    template?: MenuListTemplate;
    menuItems: MenuItem[];
    disabled?: boolean;
    tabIndex?: number;
    className?: string;
}

const MenuList: FC<MenuListProps> = ({
    isLoading,
    template = MenuListTemplate.regular,
    menuItems,
    disabled,
    tabIndex,
    className = '',
}): ReactElement => {
    const menuListClassNames = classNames('menu-list', {
        'menu-list--template-information': template === MenuListTemplate.information,
        'menu-list--template-buttons': template === MenuListTemplate.buttons,
        'menu-list--template-drawer': template === MenuListTemplate.drawer,
        'menu-list--template-session': template === MenuListTemplate.session,
        'menu-list--template-icon': template === MenuListTemplate.icon,
    }, className);

    return (
        <ul className={menuListClassNames}>
            {menuItems.map(menuItem => (
                <MenuListItem
                    {...menuItem}
                    key={menuItem.key}
                    isLoading={isLoading}
                    template={template}
                    disabled={disabled || isLoading}
                    tabIndex={tabIndex}
                    className="menu-list__item"
                />
            ))}
        </ul>
    );
};

export default MenuList;
