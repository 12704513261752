import { useLocation } from 'react-router-dom';

/**
 * Gets and returns the query params in a url
 *
 * @export
 * @returns
 */
export default function useQuery() {
    return new URLSearchParams(useLocation().search);
}
