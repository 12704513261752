import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';
import { AddressFormValues } from '../../services/AddressService';
import { CompanyDataFormErrors, CompanyDataFormValues } from '../../services/CompanyService';
import { FormOption } from '../../types';
import { SelectInput, TextInput } from '..';
import AddressInput from '../AddressInput/AddressInput';

import './CompanyDataForm.scss';

interface CompanyDataFormProps {
    errors: CompanyDataFormErrors;
    formValues: CompanyDataFormValues;
    onChange: (formValues: CompanyDataFormValues) => void;
    onAddressInputSubmit: () => void;
    showWebsite?: boolean;
    amountOfEmployeesOptions: FormOption[];
    addressLoading: boolean;
    addressNotFound: boolean;
    className?: string;
}

const CompanyDataForm: FC<CompanyDataFormProps> = ({
    errors,
    amountOfEmployeesOptions,
    addressLoading,
    addressNotFound,
    showWebsite = false,
    formValues,
    onChange,
    onAddressInputSubmit,
    className = '',
}): ReactElement => {
    const handleCompanyNameChange = (companyName: string): void => {
        onChange({
            ...formValues,
            companyName,
        });
    };

    const handleWebsiteChange = (website: string): void => {
        onChange({
            ...formValues,
            website,
        });
    };

    const handleAmountOfEmployeesChange = (options: FormOption[]): void => {
        const amountOfEmployeesId = options[0].value;

        onChange({
            ...formValues,
            amountOfEmployeesId,
        });
    };

    const onAddressInputChange = (newAddressValues: AddressFormValues) => {
        onChange({
            ...formValues,
            ...newAddressValues,
        });
    };

    return (
        <div className={`company-data-form ${className}`}>
            <h3 className="company-data-form__title">
                {trans('forms.register.employee.organization.title')}
            </h3>

            <p className="company-data-form__intro">
                {trans('forms.register.employee.organization.intro')}
            </p>

            <div className="company-data-form__form-row">
                <TextInput
                    required
                    error={errors.name}
                    label={trans('forms.company.name.labelExtended')}
                    placeholder={trans('forms.company.name.placeholder')}
                    value={formValues.companyName}
                    onChange={handleCompanyNameChange}
                />
            </div>

            {showWebsite && (
                <div className="company-data-form__form-row">
                    <TextInput
                        optional
                        error={errors.website}
                        label={trans('forms.company.website.label')}
                        placeholder={trans('forms.company.website.placeholder')}
                        value={formValues.website}
                        onChange={handleWebsiteChange}
                    />
                </div>
            )}

            <div className="company-data-form__form-row">
                <SelectInput
                    required
                    label={trans('forms.company.amountOfEmployees.label')}
                    placeholder={trans('forms.company.amountOfEmployees.placeholder')}
                    value={formValues.amountOfEmployeesId}
                    options={amountOfEmployeesOptions}
                    error={errors.amountOfEmployeesId}
                    onSelected={handleAmountOfEmployeesChange}
                />
            </div>

            <AddressInput
                isLoading={addressLoading}
                addressNotFound={addressNotFound}
                formValues={{
                    address: formValues.address,
                    streetNumber: formValues.streetNumber,
                    extension: formValues.extension || '',
                    zipcode: formValues.zipcode,
                    city: formValues.city,
                }}
                errors={errors}
                onChange={onAddressInputChange}
                onSubmit={onAddressInputSubmit}
            />
        </div>
    );
};

export default CompanyDataForm;
