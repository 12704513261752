import React, {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { ErrorLabel, InputLabel, Textarea as TextareaComponent } from '../../../components';
import { HTMLTextareaProps } from '../../../components/@inputs/Textarea/Textarea';

import './Textarea.scss';

interface TextareaProps extends Omit<HTMLTextareaProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
    inputClassName?: string;
}

type TextareaWithRefProps = TextareaProps & RefAttributes<HTMLTextAreaElement>;

const Textarea: ForwardRefExoticComponent<TextareaWithRefProps> = forwardRef(({
    label,
    hideLabel = false,
    required = false,
    error = '',
    onChange,
    className = '',
    inputClassName = '',
    ...textareaProps
}, ref: Ref<HTMLTextAreaElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`textarea ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="textarea__label"
                />
            )}

            <TextareaComponent
                {...textareaProps}
                ref={ref}
                required={required}
                error={error}
                onChange={handleChange}
                className={inputClassName}
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="textarea__error-label"
                />
            )}
        </label>
    );
});

export default Textarea;
