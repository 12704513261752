import { createGlobalStyle } from 'styled-components';

import colors from './colors';
import fonts from './fonts';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    html {
        font-size: 14px;
    }

    body {
        ${fonts.regular};
        line-height: 1.4em;
    }
    
    a.text-link {
        color: ${colors.link};
        text-decoration: none;
        
        &:hover {
            color: ${colors.primaryMuted};
        }
    }

    .d-none {
        display: none;
    }

    .break-words {
        word-break: break-word;
    }
`;
