import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getEscoOccupationsApiCall, getSuggestedCompetenciesApiCall, getSuggestedSkillsApiCall } from '../../services/EscoOccupationsService';
import { TypedDispatch } from '..';
import {
    setError,
    setEscoOccupations,
    setIsLoading,
    setSuggestedCompetencies,
    setSuggestedSkills,
} from './escoOccupations';

export const fetchEscoOccupations = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const escoOccupationsResponse = await getEscoOccupationsApiCall();

        if (!isFetchResultSuccessful(escoOccupationsResponse)) {
            dispatch(setError(escoOccupationsResponse.error));
            return;
        }

        dispatch(setEscoOccupations(escoOccupationsResponse.data));
    } catch (error) {
        console.error('[fetchEscoOccupations]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const clearEscoSuggestions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setSuggestedSkills([]));
    dispatch(setSuggestedCompetencies([]));
};

export const fetchSuggestedSkills = (escoOccupationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const suggestedSkillsResponse = await getSuggestedSkillsApiCall(escoOccupationUuid);

        if (!isFetchResultSuccessful(suggestedSkillsResponse)) {
            dispatch(setError(suggestedSkillsResponse.error));
            return;
        }

        const suggestedSkills = suggestedSkillsResponse.data;

        dispatch(setSuggestedSkills(suggestedSkills));
    } catch (error) {
        console.error('[fetchSuggestedSkills]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchSuggestedCompetencies = (escoOccupationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const suggestedCompetenciesResponse = await getSuggestedCompetenciesApiCall(escoOccupationUuid);

        if (!isFetchResultSuccessful(suggestedCompetenciesResponse)) {
            dispatch(setError(suggestedCompetenciesResponse.error));
            return;
        }

        const suggestedCompetencies = suggestedCompetenciesResponse.data;

        dispatch(setSuggestedCompetencies(suggestedCompetencies));
    } catch (error) {
        console.error('[fetchSuggestedCompetencies]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
