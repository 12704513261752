import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, SearchableValueInput, SkillsSelector } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { EscoOccupation, transformEscoOccupationToSearchableOption } from '../../../models/Esco';
import { Skill, SkillsFormData, SkillType } from '../../../models/Skills';
import { FormProps, SearchableOption } from '../../../types';
import { SkillsFormErrors, validateSkillsFormData } from './validations';

import './SkillsForm.scss';

interface SkillsFormProps extends FormProps<SkillsFormData> {
    skills: Skill[];
    options: Skill[];
    suggestedSkills: Skill[];
    occupationOptions: EscoOccupation[];
    onSelectOccupation?: (occupation: EscoOccupation) => void;
    className?: string;
}

const SkillsForm: FC<SkillsFormProps> = ({
    isLoading,
    skills,
    options,
    suggestedSkills,
    occupationOptions,
    error = '',
    onSelectOccupation,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<SkillsFormErrors>({});

    const [selectedOptions, setSelectedOptions] = useState<Skill[]>(skills);
    const [occupation, setOccupation] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (suggestedSkills.length) {
            setSelectedOptions(suggestedSkills);
        }
    }, [suggestedSkills]);

    const handleChangeOccupation = (occupationValue: SearchableOption): void => {
        setOccupation(occupationValue.label);

        const selectedOccupation = occupationValue.value
            ? occupationOptions.find(option => option.id === occupationValue.value)
            : undefined;

        if (selectedOccupation && onSelectOccupation) {
            onSelectOccupation(selectedOccupation);
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            skills: selectedOptions.filter(skill => skill.type === SkillType.skill),
            escoSkills: selectedOptions.filter(skill => skill.type === SkillType.esco),
        };

        const [errors, hasErrors] = validateSkillsFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`skills-form ${className}`}>
            <SkillsSelector
                isLoading={isLoading}
                label={trans('forms.skills.input.searchMinimum')}
                value={selectedOptions}
                options={options}
                error={error}
                validationError={formErrors.skills}
                onChange={setSelectedOptions}
            />

            {onSelectOccupation && (
                <div className="skills-form__occupation-wrapper">
                    <h2 className="skills-form__occupation-title">
                        {trans('forms.skills.occupation.title')}
                    </h2>

                    <p className="skills-form__occupation-description">
                        {trans('forms.skills.occupation.description')}
                    </p>

                    <SearchableValueInput
                        isSearchable
                        showResultsOnClick
                        label={trans('forms.skills.input.occupation')}
                        value={occupation}
                        options={occupationOptions.map(transformEscoOccupationToSearchableOption)}
                        placeholder={trans('forms.skills.placeholder.occupation')}
                        onChange={handleChangeOccupation}
                        className="skills-form__occupation-input"
                    />
                </div>
            )}

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="skills-form__action-buttons"
            />
        </form>
    );
};

export default SkillsForm;
