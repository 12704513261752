import React, { FC, ReactElement, useEffect } from 'react';

import { CompanyInformationCard } from '../../../containers';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany } from '../../../store/company/companyActions';

interface ConnectedCompanyProfileProps {
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyProfile: FC<ConnectedCompanyProfileProps> = ({
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);
    const isLoading = useTypedSelector(state => state.companyReducer.isLoading);

    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    return (
        <CompanyInformationCard
            isLoading={isLoading}
            isEditable={userFromCompany}
            company={company}
            className={className}
        />
    );
};

export default ConnectedCompanyProfile;
