import React, {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import { InputLabel } from '../../../components';
import { trans } from '../../../helpers/trans';
import { RangeInputBar, RangeInputHandle, RangeInputMarks } from './subcomponents';

import './RangeInput.scss';

interface SingleRangeInputProps {
    isInverted?: boolean;
    label: string;
    hideLabel?: boolean;
    value: number;
    min?: number;
    max?: number;
    step?: number;
    required?: boolean;
    tabIndex?: number;
    disabled?: boolean;
    marks?: number;
    valueFormat?: (value: number) => string;
    onChange: (rangeValue: number) => void;
    className?: string;
}

const SingleRangeInput: FC<SingleRangeInputProps> = ({
    isInverted,
    label,
    hideLabel,
    value,
    min = 0,
    max = 100,
    step = 1,
    required,
    tabIndex = 0,
    disabled,
    marks,
    valueFormat,
    onChange,
    className = '',
}): ReactElement => {
    const { width } = useWindowSize();
    const [barWidth, setBarWidth] = useState<number>(0);

    const minMaxDiff = max - min;

    const barRef = useRef<HTMLDivElement>(null);

    useEffect((): void => {
        if (barRef.current) {
            setBarWidth(barRef.current.offsetWidth);
        }
    }, [barRef, width]);

    const inputWrapperClassNames = classNames('range-input__input-wrapper', {
        'range-input__input-wrapper--is-disabled': !!disabled,
    });

    const cssVariables = {
        '--range-input-width': `${barWidth}px`,
        '--range-input-max': (value - min) / minMaxDiff,
        '--range-input-track-size': (value - min) / minMaxDiff,
    } as CSSProperties;

    const rangeInputProps = {
        min,
        max,
        step,
        disabled,
    };

    return (
        <label
            style={cssVariables}
            aria-label={hideLabel ? label : undefined}
            className={`range-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="range-input__label"
                />
            )}

            <div ref={barRef} className={inputWrapperClassNames}>
                <RangeInputHandle
                    {...rangeInputProps}
                    parentRef={barRef}
                    valueFormat={valueFormat}
                    rangeSize={value - min}
                    tabIndex={disabled ? -1 : tabIndex}
                    label={trans('basic.range.value')}
                    value={value}
                    clampMin={min}
                    clampMax={max}
                    onChange={onChange}
                    className="range-input__handle--max"
                />

                <RangeInputBar
                    {...rangeInputProps}
                    isInverted={isInverted}
                    value={{ min, max: value }}
                    onClick={onChange}
                />
            </div>

            {marks && (
                <RangeInputMarks
                    amount={marks}
                    min={min}
                    max={max}
                    className="range-input__marks"
                />
            )}
        </label>
    );
};

export default SingleRangeInput;
