import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    EducationLevel,
    EducationLevelResource,
    EducationSubLevel,
    transformResourceToEducationLevel,
    transformResourceToEducationLevelIds,
    transformResourceToEducationSubLevel,
} from '../models/EducationLevels';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { RoleType } from '../models/User';

interface EducationLevels {
    educationLevels: EducationLevel[];
    educationSubLevels: EducationSubLevel[];
}

export const getEducationLevelsApiCall = async (role: RoleType = RoleType.none): Promise<FetchResult<EducationLevels, string>> => {
    try {
        const rootUuid = '11111111-1111-1111-1111-111111111111';
        const includes = ['educationLevelDescendants'].join(',');
        const requestPrefix = role === RoleType.none ? 'public/' : 'api/';
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/${requestPrefix}education-levels?filter[children-of]=${rootUuid}&include=${includes}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<EducationLevelResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const existingEducationLevels = doc.data.filter((educationLevel: EducationLevelResource) => !educationLevel?.attributes?.manual);
        const subLevelsPerEducationLevel = existingEducationLevels.map(transformResourceToEducationLevelIds);

        const includedSubLevels = doc.included as EducationLevelResource[] || [];
        const educationLevels = existingEducationLevels.map(transformResourceToEducationLevel);
        const educationSubLevels = includedSubLevels.map(subLevel => transformResourceToEducationSubLevel(subLevel, subLevelsPerEducationLevel));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                educationLevels,
                educationSubLevels,
            },
        };
    } catch (error) {
        console.error('[getEducationLevelsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
