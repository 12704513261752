import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconDocument: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 80 100" className={`icon ${className}`}>
        <path d="M10,0C4.5,0,0.1,4.5,0.1,10L0,90c0,5.5,4.4,10,9.9,10H70c5.5,0,10-4.5,10-10V30L50,0H10z M45,35V7.5L72.5,35H45z" />
    </svg>
);

export default IconDocument;
