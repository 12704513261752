import React, {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Cropper } from 'react-cropper';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '../../../../../components';
import { ButtonList, IconButton } from '../../../../../compositions';
import { scrollIntoView } from '../../../../../helpers/scroll';
import { trans } from '../../../../../helpers/trans';
import { FileFormData } from '../../../../../models/File';
import { ImageValidationOptions } from '../../../../../models/ImageMeta';
import { FormProps } from '../../../../../types';

import './cropper.scss';
import './ImageCropper.scss';

interface ImageCropperProps extends FormProps<FileFormData> {
    hasBoundaries?: boolean;
    src: string;
    aspectRatio?: number;
    validationOptions?: ImageValidationOptions;
    renderOptions?: Cropper.GetCroppedCanvasOptions;
    className?: string;
}

const ImageCropper: FC<ImageCropperProps> = ({
    hasBoundaries,
    src,
    aspectRatio,
    validationOptions = {},
    renderOptions = {},
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [cropper, setCropper] = useState<Cropper>();
    const [croppedImageFile, setCroppedImageFile] = useState<File>();
    const [croppedImageSrc, setCroppedImageSrc] = useState<string>();

    const [scaleX, setScaleX] = useState<number>(1);
    const [scaleY, setScaleY] = useState<number>(1);

    const cropButtonRef = useRef<HTMLButtonElement>(null);

    const cropperConfig = {
        viewMode: hasBoundaries ? 1 : 0 as Cropper.ViewMode,
        dragMode: 'move' as Cropper.DragMode,
        toggleDragModeOnDblclick: false,
        aspectRatio,
        initialAspectRatio: !aspectRatio ? 1 : undefined,
        zoomTo: 1,
        minCropBoxWidth: validationOptions?.minWidth || 10,
        minCropBoxHeight: validationOptions?.minHeight || 10,
        autoCropArea: 1,
    };

    const handleMirrorHorizontal = (): void => {
        setScaleX(scaleX === 1 ? -1 : 1);

        if (cropper) {
            cropper.scaleX(scaleX === 1 ? -1 : 1);
        }
    };

    const handleMirrorVertical = (): void => {
        setScaleY(scaleY === 1 ? -1 : 1);

        if (cropper) {
            cropper.scaleY(scaleY === 1 ? -1 : 1);
        }
    };

    const handleReset = () => cropper?.reset();

    const handleCrop = (): void => {
        if (cropper) {
            const canvasData = cropper.getCroppedCanvas({
                fillColor: 'white',
                ...renderOptions,
            });

            canvasData.toBlob(blob => {
                if (blob) {
                    const fileName = uuidv4();
                    const file = new File([blob], fileName);
                    const fileSrc = URL.createObjectURL(blob);

                    setCroppedImageFile(file);
                    setCroppedImageSrc(fileSrc);
                }
            }, 'image/jpeg', 1);

            scrollIntoView<HTMLButtonElement>(cropButtonRef);
        }
    };

    const handleSubmit = (): void => {
        if (croppedImageFile) {
            onSubmit({ file: croppedImageFile });
        }
    };

    return (
        <section className={`image-cropper ${className}`}>
            <Cropper
                {...cropperConfig}
                src={src}
                onInitialized={setCropper}
                className="image-cropper__cropper"
            />

            <ButtonList className="image-cropper__control-buttons">
                <IconButton
                    ref={cropButtonRef}
                    icon="crop"
                    text={trans('forms.imageDrop.crop.crop')}
                    onClick={handleCrop}
                    className="image-cropper__control-button image-cropper__control-button--crop"
                />
                <Button
                    text={trans('forms.imageDrop.crop.reset')}
                    onClick={handleReset}
                    className="image-cropper__control-button"
                />
                <IconButton
                    icon="arrow-vertical"
                    text={trans('forms.imageDrop.crop.mirrorVertical')}
                    onClick={handleMirrorVertical}
                    className="image-cropper__control-button"
                />
                <IconButton
                    icon="arrow-horizontal"
                    text={trans('forms.imageDrop.crop.mirrorHorizontal')}
                    onClick={handleMirrorHorizontal}
                    className="image-cropper__control-button"
                />
            </ButtonList>

            {croppedImageSrc && (
                <div className="image-cropper__result-wrapper">
                    <h2 className="image-cropper__result-title">
                        {trans('forms.imageDrop.crop.result')}
                    </h2>

                    <img
                        src={croppedImageSrc}
                        alt={trans('forms.imageDrop.crop.resultAlt')}
                        className="image-cropper__result"
                    />
                </div>
            )}

            <ButtonList className="image-cropper__action-buttons">
                <IconButton
                    icon="check"
                    text={trans('actions.save')}
                    onClick={handleSubmit}
                    disabled={!croppedImageSrc}
                />
                <Button
                    text={trans('actions.cancel')}
                    onClick={onCancel}
                    className="image-cropper__cancel-button"
                />
            </ButtonList>
        </section>
    );
};

export default ImageCropper;
