import React, { FC, ReactElement, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import {
    ConnectedCompanyAboutCard,
    ConnectedCompanyContactCard,
    ConnectedCompanyHeader,
    ConnectedCompanyHelmet,
    ConnectedCompanyInformationCard,
    ConnectedCompanyProfileWrapper,
    ConnectedCompanyProgress,
    ConnectedCompanySbbInfo,
    ConnectedCompanyVacancies,
    ConnectedCoreValuesCard,
    ConnectedFunFactsCard,
} from '../../connectors';
import { ProfileStepId } from '../../containers/@profile/ProfileProgress/ProfileProgress';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import { RouteParams } from '../../routes';

import './CompanyProfile.scss';

interface CompanyProfileParams extends RouteParams {
    companyUuid?: string;
}

const CompanyProfile: FC = (): ReactElement => {
    const { isTabletPortrait, isMobile } = useDeviceWidth();
    const isSmallScreen = isTabletPortrait || isMobile;

    const { companyUuid = '' } = useParams<CompanyProfileParams>();

    const activationRefs = {
        [ProfileStepId.about]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.avatar]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.coreValues]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.coverImage]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.funFacts]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.youTube]: useRef<HTMLButtonElement>(null),
    };

    return (
        <Page className="company-profile-page">
            <ConnectedCompanyProfileWrapper
                companyUuid={companyUuid}
                className="company-profile-page__wrapper"
            >
                <ConnectedCompanyHelmet />

                <ConnectedCompanyHeader
                    coverImageActivationRef={activationRefs[ProfileStepId.coverImage]}
                    youTubeActivationRef={activationRefs[ProfileStepId.youTube]}
                    avatarActivationRef={activationRefs[ProfileStepId.avatar]}
                />

                <ConnectedCompanyProgress
                    activationRefs={activationRefs}
                    className="company-profile-page__progress"
                />

                <div className="company-profile-page__card-wrapper">
                    <main className="company-profile-page__main">
                        <ConnectedCompanyAboutCard
                            activationRef={activationRefs[ProfileStepId.about]}
                            className="company-profile-page__card"
                        />

                        <ConnectedFunFactsCard
                            activationRef={activationRefs[ProfileStepId.funFacts]}
                            className="company-profile-page__card"
                        />

                        <ConnectedCompanySbbInfo className="company-profile-page__card" />

                        {!isSmallScreen && (
                            <ConnectedCompanyVacancies className="company-profile-page__vacancy-overview" />
                        )}
                    </main>

                    <aside className="company-profile-page__sidebar">
                        <ConnectedCoreValuesCard
                            activationRef={activationRefs[ProfileStepId.coreValues]}
                            className="company-profile-page__card"
                        />

                        <ConnectedCompanyInformationCard className="company-profile-page__card" />

                        <ConnectedCompanyContactCard className="company-profile-page__card" />
                    </aside>
                </div>

                {isSmallScreen && (
                    <ConnectedCompanyVacancies className="company-profile-page__vacancy-overview" />
                )}
            </ConnectedCompanyProfileWrapper>
        </Page>
    );
};

export default CompanyProfile;
