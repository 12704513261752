import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CatchphraseCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { CatchphraseFormData } from '../../../models/Catchphrase';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate, patchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCatchphraseCardProps {
    activationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCatchphraseCard: FC<ConnectedCatchphraseCardProps> = ({
    activationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    const handleEditCatchphrase = (catchphraseFormData: CatchphraseFormData): void => {
        if (candidate) {
            dispatch(patchCandidate({
                uuid: candidate.uuid,
                catchphrase: catchphraseFormData.catchphrase,
            }));
        }
    };

    return (
        <CatchphraseCard
            activationRef={activationRef}
            isLoading={isLoading}
            isEditable={isEditable}
            catchphrase={candidate?.catchphrase}
            onEdit={handleEditCatchphrase}
            className={className}
        />
    );
};

export default ConnectedCatchphraseCard;
