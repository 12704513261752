import React, {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { EducationLevel } from '../../../_old/app_talentz/models/SchoolExperience';
import { Checkbox } from '../../../components';
import { Disclaimer, FormActionButtons, InformationBox } from '../../../compositions';
import { retrieveLatestEducationLevel } from '../../../helpers/schoolExperience';
import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';
import { FormProps } from '../../../types';

import './ApplicationMatchForm.scss';

interface ApplicationMatchFormProps extends FormProps<boolean> {
    candidate?: Candidate;
    className?: string;
}

const ApplicationMatchForm: FC<ApplicationMatchFormProps> = ({
    candidate,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [shouldArchive, setShouldArchive] = useState<boolean>(false);

    const isMboCandidate = retrieveLatestEducationLevel(candidate).toLowerCase() === EducationLevel.Mbo;

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit(shouldArchive);
    };

    return (
        <form onSubmit={handleSubmit} className={`application-match-form ${className}`}>
            <p className="application-match-form__description">
                {trans('forms.applicationMatch.description')}
            </p>

            {isMboCandidate && (
                <InformationBox
                    title={trans('forms.applicationMatch.mboDisclaimer.title')}
                    descriptions={[
                        trans('forms.applicationMatch.mboDisclaimer.descriptions.1'),
                        trans('forms.applicationMatch.mboDisclaimer.descriptions.2'),
                        trans('forms.applicationMatch.mboDisclaimer.descriptions.3'),
                    ]}
                    className="application-match-form__mbo-disclaimer"
                />
            )}

            <Checkbox
                label={trans('forms.applicationMatch.input.shouldArchive')}
                checked={shouldArchive}
                onChange={setShouldArchive}
                className="application-match-form__archive-checkbox"
            />

            {shouldArchive && (
                <Disclaimer
                    title={trans('forms.applicationMatch.archiveDisclaimer.title')}
                    description={trans('forms.applicationMatch.archiveDisclaimer.description')}
                    className="application-match-form__archive-disclaimer"
                />
            )}

            <FormActionButtons
                submitIcon="check"
                submitText={trans(`forms.applicationMatch.button.${shouldArchive ? 'submitAndArchive' : 'submit'}`)}
                onCancelClick={onCancel}
                className="application-match-form__action-buttons"
            />
        </form>
    );
};

export default ApplicationMatchForm;
