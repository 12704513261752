import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../_old/app_talentz/models/VacancyType';
import { Button } from '../../../components';
import { ButtonList, DetailCard } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoleType } from '../../../models/User';
import { HorizontalAlignment } from '../../../types';

import './VacancyAuthenticationCard.scss';

interface VacancyAuthenticationCardProps {
    vacancy: NewestVacancy;
    onRegistrationClick: (userType: RoleType) => void;
    onLoginClick: () => void;
    className?: string;
}

const VacancyAuthenticationCard: FC<VacancyAuthenticationCardProps> = ({
    vacancy,
    onRegistrationClick,
    onLoginClick,
    className = '',
}): ReactElement => {
    const handleRegistrationClick = (): void => {
        const userType = vacancy.type === VacancyType.internship
            ? RoleType.student
            : RoleType.jobSeeker;

        onRegistrationClick(userType);
    };

    return (
        <DetailCard
            title={trans('vacancyDetail.authentication.title')}
            className={`vacancy-authentication-card ${className}`}
        >
            <p className="vacancy-authentication-card__description">
                {trans('vacancyDetail.authentication.description')}
            </p>

            <ButtonList
                align={HorizontalAlignment.left}
                className="vacancy-authentication-card__button-list"
            >
                <Button
                    text={trans('vacancyDetail.authentication.button.register')}
                    onClick={handleRegistrationClick}
                />
                <Button
                    text={trans('vacancyDetail.authentication.button.login')}
                    onClick={onLoginClick}
                    className="vacancy-authentication-card__button vacancy-authentication-card__login-button"
                />
            </ButtonList>
        </DetailCard>
    );
};

export default VacancyAuthenticationCard;
