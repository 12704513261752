import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { height, space } from 'styled-system';

const defaultHeight = 190;

const GreyFooterContent = styled('div')`
    position: relative;
    z-index: 1;
`;

export const GreyFooterElement = styled('div')(props => css`
    position: relative;
    width: 100%;
    background-color: ${props.theme.colors.background};
    
    ${Array.isArray(props.height)
        ? css`
            margin-top: ${props.mt || '-50%'};
            `
        : css`
            height: ${defaultHeight}px;
            margin-top: -${(props.height || defaultHeight) / 2}px;
    `}
  
    ${space}
    ${height}
`);

const GreyFooter = props => {
    const { children, ...rest } = props;

    return (
        <>
            <GreyFooterContent>
                {children}
            </GreyFooterContent>
            <GreyFooterElement {...rest} />
        </>
    );
};

GreyFooter.propTypes = {
    height: PropTypes.arrayOf(PropTypes.number),
    mt: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.node.isRequired,
};

GreyFooter.defaultProps = {
    height: null,
    mt: null,
};

export default GreyFooter;
