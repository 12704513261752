import { SchoolExperience, SchoolExperienceFormData } from '../../../../models/NewSchoolExperience';
import {
    FormErrors,
    FormValidation,
    OptionalFormValidation,
    validateEndDate,
    validateForm,
    validateRequiredOption,
    validateRequiredString,
    validateStartDate,
} from '../../../../services/ValidationService';

export type SchoolExperienceFormErrors = FormErrors<SchoolExperienceFormData>;

export const validateSchoolExperienceFormData = (formData: SchoolExperience, optionalValidation: OptionalFormValidation<SchoolExperience>): FormValidation<SchoolExperienceFormErrors> => {
    const dateNotation = 'MMMM yyyy';

    const errors: SchoolExperienceFormErrors = {
        educationLevel: validateRequiredString('educationLevel', formData.educationLevelId),
        educationSubLevel: optionalValidation.educationSubLevel
            ? validateRequiredString('educationSubLevel', formData.educationSubLevelId)
            : undefined,
        learningPath: optionalValidation.learningPath
            ? validateRequiredString('learningPath', formData.learningPathId)
            : undefined,
        // TODO: Remove this optional validation when there is a BE solution for adding manual field of study
        fieldOfStudy: optionalValidation.fieldOfStudy
            ? validateRequiredOption('fieldOfStudy', formData.fieldOfStudyId)
            : undefined,
        // TODO: Remove this optional validation when there is a BE solution for adding manual schools
        school: optionalValidation.school
            ? validateRequiredOption('school', formData.schoolId)
            : undefined,
        startDate: validateStartDate(formData.startDate, formData.endDate, dateNotation),
        endDate: validateEndDate(formData.endDate, formData.startDate, dateNotation),
    };

    return validateForm<SchoolExperienceFormErrors>(errors);
};
