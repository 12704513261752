import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { CookieConsentList, SubmitButton, Toast } from '../../compositions';
import { userConsentCookieKey } from '../../constants';
import { ignoreErrors, ignoreUrls } from '../../constants/sentry';
import { initializeAnalytics } from '../../helpers/analytics';
import { isSSR } from '../../helpers/environment';
import { trans } from '../../helpers/trans';
import { initializeZendesk } from '../../helpers/zendesk';
import { ToastPosition } from '../../models/Toast';
import { RoutePaths } from '../../routes';
import { CookieOption } from '../../types';

import './CookieConsentToast.scss';

interface CookieConsentToastProps {
    className?: string;
}

const packageJson = {
    name: isSSR
        ? process.env.npm_package_name
        : require('../../../package.json').name, // eslint-disable-line
    version: isSSR
        ? process.env.npm_package_version
        : require('../../../package.json').version, // eslint-disable-line
};

const CookieConsentToast: FC<CookieConsentToastProps> = ({
    className = '',
}): ReactElement | null => {
    const [userConsentCookies, setUserConsentCookies] = useLocalStorage<string | undefined>(userConsentCookieKey);
    const [closingToast, setClosingToast] = useState<boolean>(false);

    const [analyticsIsInitialized, setAnalyticsIsInitialized] = useState<boolean>(false);
    const [zendeskIsInitialized, setZendeskIsInitialized] = useState<boolean>(false);
    const [sentryIsInitialized, setSentryIsInitialized] = useState<boolean>(false);

    const [checkBoxValues, setCheckBoxValues] = useState<CookieOption[]>([
        CookieOption.necessary,
        CookieOption.statistics,
        CookieOption.liveChat,
    ]);

    const userConsentCookieValues = useMemo((): string[] => (
        userConsentCookies ? userConsentCookies.split(',') : []
    ), [userConsentCookies]);

    const [cookieConsentToastIsOpen, setCookieConsentToastIsOpen] = useState(userConsentCookieValues.length === 0);

    useEffect((): void => {
        if (!analyticsIsInitialized && userConsentCookieValues.includes(CookieOption.statistics)) {
            if (process.env.REACT_APP_ENV === 'production') {
                initializeAnalytics({
                    originalLocation: window?.location?.href,
                });
            }

            setAnalyticsIsInitialized(true);
        }

        if (!sentryIsInitialized && userConsentCookieValues.includes(CookieOption.statistics)) {
            if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_ENV !== 'local') {
                Sentry.init({
                    dsn: process.env.REACT_APP_SENTRY_DSN,
                    release: `${packageJson.name}@${packageJson.version}`,
                    environment: process.env.REACT_APP_APP_ENV,
                    integrations: [new CaptureConsole({
                        levels: ['error'],
                    })],
                    ignoreErrors,
                    denyUrls: ignoreUrls,
                });
            }

            setSentryIsInitialized(true);
        }

        if (!zendeskIsInitialized && userConsentCookieValues.includes(CookieOption.liveChat)) {
            initializeZendesk();
            setZendeskIsInitialized(true);
        }
    }, [userConsentCookieValues]);

    const handleCheckboxChange = (values: string[]): void => {
        setCheckBoxValues(values as CookieOption[]);
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setClosingToast(true);
    };

    const handleClose = (): void => {
        setCookieConsentToastIsOpen(false);
        setUserConsentCookies(checkBoxValues.join(','));
    };

    return cookieConsentToastIsOpen ? (
        <Toast
            shouldClose={closingToast}
            hideCloseButton
            id="cookie-consent"
            position={ToastPosition.bottomRight}
            title={trans('reminder.cookies.title')}
            onClose={handleClose}
            className={`cookie-consent-toast ${className}`}
        >
            <p className="cookie-consent-toast__body">
                {trans('reminder.cookies.body')}
            </p>

            <Link to={RoutePaths.privacyStatement()} className="cookie-consent-toast__link">
                {trans('reminder.cookies.privacyStatement')}
            </Link>

            <form onSubmit={handleFormSubmit} className="cookie-consent-toast__list-and-button-wrapper">
                <CookieConsentList
                    values={checkBoxValues}
                    onChange={handleCheckboxChange}
                />
                <SubmitButton
                    text={trans('reminder.cookies.acceptButton')}
                    className="cookie-consent-toast__accept-button"
                />
            </form>
        </Toast>
    ) : null;
};

export default CookieConsentToast;
