import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { VacancyApplicants } from '../../containers';
import { sortNewestVacanciesByMostApplicants, transformNewestVacancyToSearchableOptionWithApplicants } from '../../models/NewestVacancy';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchMyVacancies } from '../../store/myVacancies/myVacanciesActions';
import { clearVacancyApplications, fetchVacancyApplications } from '../../store/vacancyApplications/vacancyApplicationsActions';
import { SearchableOption } from '../../types';

interface ConnectedVacancyApplicantsProps {
    vacancyUuidFromUrl?: string;
    className?: string;
}

const ConnectedVacancyApplicants: FC<ConnectedVacancyApplicantsProps> = ({
    vacancyUuidFromUrl,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const vacanciesIsLoading = useTypedSelector(state => state.myVacanciesReducer.isLoading);
    const vacancies = useTypedSelector(state => state.myVacanciesReducer.vacancies);

    const vacancyApplicationsIsLoading = useTypedSelector(state => state.vacancyApplicationsReducer.isLoading);
    const vacancyApplicationsError = useTypedSelector(state => state.vacancyApplicationsReducer.error);
    const activeVacancy = useTypedSelector(state => state.vacancyApplicationsReducer.activeVacancy);
    const vacancyApplications = useTypedSelector(state => state.vacancyApplicationsReducer.applications);

    const vacancyOptions = useMemo<SearchableOption[]>(() => (
        [...vacancies]
            .sort(sortNewestVacanciesByMostApplicants)
            .map(transformNewestVacancyToSearchableOptionWithApplicants)
    ), [vacancies]);

    useEffect((): () => void => {
        if (vacancies.length === 0) {
            dispatch(fetchMyVacancies({
                pageSize: 1000,
                pageNumber: 1,
                isArchive: false,
            }));
        }

        return (): void => {
            dispatch(clearVacancyApplications());
        };
    }, []);

    useEffect((): void => {
        if (vacancyUuidFromUrl) {
            dispatch(fetchVacancyApplications(vacancyUuidFromUrl));
        }
    }, [vacancyUuidFromUrl]);

    return (
        <VacancyApplicants
            isLoading={vacanciesIsLoading || vacancyApplicationsIsLoading}
            vacancyUuidFromUrl={vacancyUuidFromUrl}
            activeVacancy={activeVacancy}
            vacancyOptions={vacancyOptions}
            applications={vacancyApplications}
            error={vacancyApplicationsError}
            className={className}
        />
    );
};

export default ConnectedVacancyApplicants;
