import { ImageMetaFormData, ImageValidationOptions } from '../../../../models/ImageMeta';
import {
    FormErrors,
    FormValidation,
    validateFileSize,
    validateForm,
    validateImageHeight,
    validateImageWidth,
} from '../../../../services/ValidationService';

export type ImageMetaFormErrors = FormErrors<ImageMetaFormData>;

export const validateImageMetaFormData = (
    formData: ImageMetaFormData,
    options: ImageValidationOptions = {},
): FormValidation<ImageMetaFormErrors> => {
    const errors: ImageMetaFormErrors = {
        width: validateImageWidth(formData.width, {
            minimum: options.minWidth,
            maximum: options.maxWidth,
        }),
        height: validateImageHeight(formData.height, {
            minimum: options.minHeight,
            maximum: options.maxHeight,
        }),
        size: validateFileSize(formData.size, options.sizeLimit),
    };

    return validateForm<ImageMetaFormErrors>(errors);
};
