import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton, Skeleton } from '../../../components';
import {
    DetailCard,
    IconButton,
    Modal,
    ModalContent,
    Theatre,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { YouTubeFormData } from '../../../models/YouTube';
import { YouTubeForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';

import './CandidateVideoCard.scss';

interface CandidateVideoCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable?: boolean;
    video?: string;
    onEdit: (youTubeFormData: YouTubeFormData) => void;
    className?: string;
}

const CandidateVideoCard: FC<CandidateVideoCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    video = '',
    onEdit,
    className = '',
}): ReactElement => {
    const [theatreIsOpen, setTheatreIsOpen] = useState<boolean>(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openTheatre = (): void => setTheatreIsOpen(true);
    const closeTheatre = (): void => setTheatreIsOpen(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditVideo = (youTubeFormData: YouTubeFormData): void => {
        if (onEdit) {
            onEdit(youTubeFormData);
        }

        closeEditModal();
    };

    const handleDeleteVideo = (): void => {
        if (onEdit) {
            onEdit({ embedId: '' });
        }
    };

    const nullState = isEditable
        ? trans('candidateProfile.video.nullState.candidate')
        : trans('candidateProfile.video.nullState.company');

    return (
        <DetailCard
            isLoading={isLoading}
            isEditable={isEditable && video.length > 0}
            title={trans('candidateProfile.video.title')}
            onEditClick={openEditModal}
            onDeleteClick={handleDeleteVideo}
            className={`candidate-video-card ${className}`}
        >
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            {isLoading && (
                <Skeleton className="candidate-video-card__skeleton" />
            )}

            {!isLoading && video.length === 0 && (
                <div className="candidate-video-card__null-state">
                    <p>{nullState}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('actions.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="candidate-video-card__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && video.length > 0 && (
                <IconButton
                    icon="play"
                    text={trans('candidateProfile.video.button.play')}
                    onClick={openTheatre}
                    className="candidate-video-card__play-button"
                />
            )}

            {video && theatreIsOpen && (
                <Theatre
                    autoPlay
                    embedId={video}
                    onClose={closeTheatre}
                />
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.video.modal.title')}>
                        <YouTubeForm
                            embedId={video}
                            onCancel={closeEditModal}
                            onSubmit={handleEditVideo}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default CandidateVideoCard;
