import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { VacancyType } from '../../../_old/app_talentz/models/VacancyType';
import { Button } from '../../../components';
import { NewBlockRadioList, SearchableValueInput } from '../../../compositions';
import { searchQueryInSplit, searchQueryProvinceSplit } from '../../../helpers/search';
import { trans } from '../../../helpers/trans';
import { SearchableOption } from '../../../types';

import './VacancySearchForm.scss';

export interface VacancySearchFormValues {
    what: string;
    where: string;
    type: VacancyType;
}

interface VacancySearchFormProps {
    showVacancyType?: boolean;
    value?: Partial<VacancySearchFormValues>;
    whatOptions: string[];
    whereOptions: string[];
    onSubmit: (values: VacancySearchFormValues) => void;
    className?: string;
}

const VacancySearchForm: FC<VacancySearchFormProps> = ({
    showVacancyType,
    value,
    whatOptions,
    whereOptions,
    onSubmit,
    className = '',
}): ReactElement => {
    const resultLimit = 25;

    const [what, setWhat] = useState<string>('');
    const [where, setWhere] = useState<string>('');
    const [type, setType] = useState<VacancyType>(VacancyType.job);

    useEffect((): void => {
        setWhat(value?.what || '');
        setWhere(value?.where || '');

        if (value?.type) setType(value.type);
    }, [value]);

    const whatSearchOptions = whatOptions.map((option, index): SearchableOption => ({
        label: option,
        value: String(index),
    }));

    const whereSearchOptions = whereOptions.map((option, index): SearchableOption => ({
        label: option,
        value: String(index),
    }));

    const typeOptions = [
        { label: trans('vacancyTypes.job'), value: VacancyType.job },
        { label: trans('vacancyTypes.internship'), value: VacancyType.internship },
    ];

    const handleWhatChange = (selectedOption: SearchableOption) => {
        setWhat(selectedOption.label);
    };

    const handleWhereChange = (selectedOption: SearchableOption) => {
        setWhere(selectedOption.label);
    };

    const handleTypeChange = (selectedValue: string) => {
        setType(selectedValue as VacancyType);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const cleanWhat = what
            .replaceAll(searchQueryInSplit, '')
            .replaceAll(searchQueryProvinceSplit, '');

        const cleanWhere = where
            .replaceAll(searchQueryInSplit, '')
            .replaceAll(searchQueryProvinceSplit, '');

        onSubmit({
            what: cleanWhat,
            where: cleanWhere,
            type,
        });
    };

    return (
        <form onSubmit={handleSubmit} className={`vacancy-search-form ${className}`}>
            <SearchableValueInput
                isSearchable
                hideIcon
                label={trans('vacancies.search.inputs.what.label')}
                value={what}
                options={whatSearchOptions}
                placeholder={trans('vacancies.search.inputs.what.placeholder')}
                resultLimit={resultLimit}
                onChange={handleWhatChange}
                className="vacancy-search-form__input"
                inputWrapperClassName="vacancy-search-form__input-field"
                listClassName="vacancy-search-form__input-list"
            />

            <SearchableValueInput
                isSearchable
                hideIcon
                label={trans('vacancies.search.inputs.where.label')}
                value={where}
                options={whereSearchOptions}
                placeholder={trans('vacancies.search.inputs.where.placeholder')}
                resultLimit={resultLimit}
                onChange={handleWhereChange}
                className="vacancy-search-form__input"
                inputWrapperClassName="vacancy-search-form__input-field"
                listClassName="vacancy-search-form__input-list"
            />

            {showVacancyType && (
                <NewBlockRadioList
                    label={trans('vacancies.search.inputs.type.label')}
                    name="vacancy-search-type"
                    options={typeOptions}
                    value={type}
                    onChange={handleTypeChange}
                    className="vacancy-search-form__radio"
                    listClassName="vacancy-search-form__radio-list"
                />
            )}

            <Button
                type="submit"
                text={trans('actions.search')}
                className="vacancy-search-form__submit-button"
            />
        </form>
    );
};

export default VacancySearchForm;
