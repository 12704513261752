import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Control } from '../../types';
import { IconButton, LinkIconButton } from '..';

import './ExpandableNavigation.scss';

interface ExpandableNavigationProps {
    isExpanded: boolean;
    name: string;
    navigationItems: Control[];
    onClose?: () => void;
    className?: string;
}

const ExpandableNavigation: FC<ExpandableNavigationProps> = ({
    isExpanded,
    name,
    navigationItems,
    onClose,
    className = '',
}): ReactElement => {
    const navigationClassNames = classNames('expandable-navigation', {
        'expandable-navigation--is-expanded': isExpanded,
    }, className);

    return (
        <nav aria-label={name} className={navigationClassNames}>
            <ul className="expandable-navigation__list">
                {navigationItems.map(navItem => {
                    const buttonProps = {
                        icon: navItem.icon || '',
                        text: navItem.label,
                        tabIndex: isExpanded ? 0 : -1,
                        disabled: navItem.disabled,
                        className: 'expandable-navigation__button',
                    };

                    const handleClick = (): void => {
                        if (onClose) onClose();
                        if (navItem.onClick) navItem.onClick();
                    };

                    return (
                        <li key={navItem.key} className="expandable-navigation__item">
                            {navItem.to ? (
                                <LinkIconButton {...buttonProps} to={navItem.to} />
                            ) : (
                                <IconButton {...buttonProps} onClick={handleClick} />
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default ExpandableNavigation;
