import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import { VacancyMeta } from '../../../../../models/NewestVacancy';

import './VacancyCardMeta.scss';

interface VacancyCardMetaProps {
    vacancyMeta: VacancyMeta;
    className?: string;
}

const VacancyCardMeta: FC<VacancyCardMetaProps> = ({
    vacancyMeta,
    className = '',
}): ReactElement => (
    <div className={`vacancy-card-meta ${className}`}>
        <ul className="vacancy-card-meta__list">
            <li className="vacancy-card-meta__item">
                <div className="vacancy-card-meta__icon-wrapper vacancy-card-meta__icon-wrapper--applicants">
                    <Icon name="applicant" className="vacancy-card-meta__icon" />
                </div>
                <div className="vacancy-card-meta__text-wrapper">
                    <h3 className="vacancy-card-meta__title">
                        {trans('vacancies.meta.applicants')}
                    </h3>
                    <p className="vacancy-card-meta__value">
                        {vacancyMeta.applicants}
                    </p>
                </div>
            </li>

            <li className="vacancy-card-meta__item">
                <div className="vacancy-card-meta__icon-wrapper vacancy-card-meta__icon-wrapper--views">
                    <Icon name="eye" className="vacancy-card-meta__icon" />
                </div>
                <div className="vacancy-card-meta__text-wrapper">
                    <h3 className="vacancy-card-meta__title">
                        {trans('vacancies.meta.views')}
                    </h3>
                    <p className="vacancy-card-meta__value">
                        {vacancyMeta.views}
                    </p>
                </div>
            </li>

            <li className="vacancy-card-meta__item">
                <div className="vacancy-card-meta__icon-wrapper vacancy-card-meta__icon-wrapper--favourites">
                    <Icon name="heart" className="vacancy-card-meta__icon" />
                </div>
                <div className="vacancy-card-meta__text-wrapper">
                    <h3 className="vacancy-card-meta__title">
                        {trans('vacancies.meta.favourites')}
                    </h3>
                    <p className="vacancy-card-meta__value">
                        {vacancyMeta.favourites}
                    </p>
                </div>
            </li>
        </ul>
    </div>
);

export default VacancyCardMeta;
