import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLoader: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M80.3,26.9c-1.8,0-3.7-0.7-5.1-2.1c-13.9-13.9-36.5-13.9-50.4,0c-2.8,2.8-7.3,2.8-10.1,0c-2.8-2.8-2.8-7.3,0-10.1c19.5-19.5,51.2-19.5,70.7,0c2.8,2.8,2.8,7.3,0,10.1C84,26.2,82.1,26.9,80.3,26.9z" />
        <path d="M50,100c-12.8,0-25.6-4.9-35.4-14.6c-2.8-2.8-2.8-7.3,0-10.1c2.8-2.8,7.3-2.8,10.1,0c13.9,13.9,36.5,13.9,50.4,0c2.8-2.8,7.3-2.8,10.1,0c2.8,2.8,2.8,7.3,0,10.1C75.6,95.1,62.8,100,50,100z" />
    </svg>
);

export default IconLoader;
