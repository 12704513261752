import React, {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { Card, ErrorLabel } from '../../../components';
import {
    Avatar,
    LinkIconButton,
    LLOCandidateLabel,
    Tag,
} from '../../../compositions';
import { maxFileUploadSize } from '../../../constants';
import { retrieveLatestEducationLevel } from '../../../helpers/schoolExperience';
import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';
import { FileFormData } from '../../../models/File';
import { RoutePaths } from '../../../routes';
import { UserSettingsTab } from '../../../types/pageTabs';
import { ImageDropForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';
import { CandidateAvatarCardSkeletons } from './skeletons';

import './CandidateAvatarCard.scss';

interface CandidateAvatarCardProps {
    avatarActivationRef?: ActivationRef;
    isWspUser: boolean;
    isLoading: boolean;
    isEditable: boolean;
    isAnonymous?: boolean;
    candidate?: Candidate;
    onEditAvatar: (avatarFormData: FileFormData) => void;
    className?: string;
}

const CandidateAvatarCard: FC<CandidateAvatarCardProps> = ({
    avatarActivationRef,
    isWspUser,
    isLoading,
    isEditable,
    isAnonymous,
    candidate,
    onEditAvatar,
    className = '',
}): ReactElement => {
    const [avatarError, setAvatarError] = useState<string>('');

    const latestEducationLevel = useMemo((): string => (
        retrieveLatestEducationLevel(candidate)
    ), [candidate]);

    const dimensionLimits = {
        minWidth: 112,
        minHeight: 112,
    };

    return (
        <Card className={`candidate-avatar-card ${className}`}>
            {isLoading && (
                <CandidateAvatarCardSkeletons />
            )}

            {!isLoading && candidate && (
                <div className="candidate-avatar-card__wrapper">
                    {isEditable ? (
                        <ImageDropForm
                            activationRef={avatarActivationRef}
                            hasControls
                            shouldCrop
                            hasCropBoundaries
                            label={trans('candidateProfile.avatar.imageAlt', { name: candidate.firstName })}
                            value={candidate.avatar}
                            cropAspectRatio={1}
                            validationOptions={{
                                ...dimensionLimits,
                                sizeLimit: maxFileUploadSize,
                            }}
                            renderOptions={dimensionLimits}
                            onSubmit={onEditAvatar}
                            onError={setAvatarError}
                            className="candidate-avatar-card__avatar-form"
                        />
                    ) : (
                        <Avatar
                            isAnonymous={isAnonymous}
                            src={candidate.avatar}
                            alt={trans('candidateProfile.avatar.imageAlt', { name: candidate.firstName })}
                            fallbackString={candidate.fullName}
                            className="candidate-avatar-card__avatar"
                        />
                    )}

                    <div className="candidate-avatar-card__personal-info-wrapper">
                        <h1 className="candidate-avatar-card__name">
                            {isAnonymous ? trans('basic.anonymousCandidate') : candidate.fullName}
                        </h1>

                        {!isAnonymous && (
                            <p className="candidate-avatar-card__city">
                                {candidate.city}
                            </p>
                        )}

                        <div className="candidate-avatar-card__tag-wrapper">
                            {isWspUser && candidate.isLlo && (
                                <LLOCandidateLabel className="candidate-avatar-card__tag" />
                            )}

                            {latestEducationLevel && (
                                <Tag
                                    isActive
                                    text={latestEducationLevel}
                                    className="candidate-avatar-card__tag"
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isEditable && (
                <LinkIconButton
                    to={RoutePaths.userSettings(UserSettingsTab.personalData)}
                    icon="pencil"
                    text={trans('actions.edit')}
                    hideLabel
                    disabled={isLoading}
                    className="candidate-avatar-card__edit-button"
                />
            )}

            {avatarError && (
                <ErrorLabel
                    text={avatarError}
                    className="candidate-avatar-card__error-label"
                />
            )}
        </Card>
    );
};

export default CandidateAvatarCard;
