import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { VacancyIndexColumnList } from '../../subcomponents';

import './VacancyIndexSkeletons.scss';

interface VacancyIndexSkeletonsProps {
    columnAmount?: number;
    amountPerColumn?: number;
    className?: string;
}

const VacancyIndexSkeletons: FC<VacancyIndexSkeletonsProps> = ({
    columnAmount = 3,
    amountPerColumn = 4,
    className = '',
}): ReactElement => {
    const skeletonColumns = generateIdArray(columnAmount);
    const skeletons = generateIdArray(amountPerColumn);

    return (
        <VacancyIndexColumnList className={className}>
            {skeletonColumns.map(column => (
                <li key={column}>
                    <ul className="vacancy-index-skeletons__skeleton-list">
                        {skeletons.map(skeleton => (
                            <li key={skeleton} className="vacancy-index-skeletons__skeleton">
                                <Skeleton />
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </VacancyIndexColumnList>
    );
};

export default VacancyIndexSkeletons;
