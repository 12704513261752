import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CandidatePreferencesCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { Candidate } from '../../../models/Candidate';
import { DriversLicensesFormData } from '../../../models/DriversLicenses';
import { EmploymentPreferenceFormData } from '../../../models/Employment';
import { SectorsFormData, transformSectorToFormOption } from '../../../models/Sectors';
import { RoleType } from '../../../models/User';
import { transformWorkRemoteOptionToFormOption, WorkRemotePreferenceFormData } from '../../../models/WorkRemote';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate, patchCandidate } from '../../../store/candidate/candidateActions';
import { fetchDriversLicenses } from '../../../store/driversLicenses/driversLicensesActions';
import { fetchSectors } from '../../../store/sectors/sectorsActions';
import { fetchWorkRemoteOptions } from '../../../store/workRemote/workRemoteActions';

interface ConnectedCandidatePreferencesCardProps {
    employmentPreferencesActivationRef?: ActivationRef;
    workRemotePreferenceActivationRef?: ActivationRef;
    driversLicensesActivationRef?: ActivationRef;
    preferredSectorsActivationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCandidatePreferencesCard: FC<ConnectedCandidatePreferencesCardProps> = ({
    employmentPreferencesActivationRef,
    workRemotePreferenceActivationRef,
    driversLicensesActivationRef,
    preferredSectorsActivationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const role = useLegacySelector(state => state.legacyUser.role);
    const person = useLegacySelector(state => state.userPerson.data);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);
    const workRemoteIsLoading = useTypedSelector(state => state.workRemoteReducer.isLoading);

    const driversLicenseOptions = useTypedSelector(state => state.driversLicensesReducer.driversLicenses);
    const driversLicensesIsLoading = useTypedSelector(state => state.driversLicensesReducer.isLoading);

    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);
    const sectorsIsLoading = useTypedSelector(state => state.sectorsReducer.isLoading);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    useEffect((): void => {
        if (role === RoleType.jobSeeker || role === RoleType.student) {
            if (workRemoteOptions.length === 0) dispatch(fetchWorkRemoteOptions());
            if (driversLicenseOptions.length === 0) dispatch(fetchDriversLicenses());
            if (sectorOptions.length === 0) dispatch(fetchSectors());
        }
    }, [role]);

    const updateCandidate = (updatedValues: Partial<Candidate>): void => {
        if (candidate) {
            dispatch(patchCandidate({
                uuid: candidate.uuid,
                ...updatedValues,
            }));
        }
    };

    const handleEditEmploymentPreferences = (employmentPreferenceFormData: EmploymentPreferenceFormData): void => {
        updateCandidate({ employmentPreferences: employmentPreferenceFormData });
    };

    const handleEditWorkRemotePreference = (workRemotePreferenceFormData: WorkRemotePreferenceFormData): void => {
        updateCandidate({ workRemotePreference: workRemotePreferenceFormData.workRemotePreference });
    };

    const handleEditDriversLicenses = (driversLicensesFormData: DriversLicensesFormData): void => {
        updateCandidate({ driversLicenses: driversLicensesFormData.driversLicenses });
    };

    const handleEditPreferredSectors = (preferredSectorsFormData: SectorsFormData): void => {
        updateCandidate({ preferredSectors: preferredSectorsFormData.sectors });
    };

    return (
        <CandidatePreferencesCard
            employmentPreferencesActivationRef={employmentPreferencesActivationRef}
            workRemotePreferenceActivationRef={workRemotePreferenceActivationRef}
            driversLicensesActivationRef={driversLicensesActivationRef}
            preferredSectorsActivationRef={preferredSectorsActivationRef}
            isLoading={candidateIsLoading}
            isEditable={isEditable}
            employmentPreferences={candidate?.employmentPreferences}
            workRemotePreference={candidate?.workRemotePreference}
            workRemotePreferenceOptions={workRemoteOptions.map(transformWorkRemoteOptionToFormOption)}
            workRemotePreferenceIsLoading={workRemoteIsLoading}
            driversLicenses={candidate?.driversLicenses}
            driversLicenseOptions={driversLicenseOptions}
            driversLicensesIsLoading={driversLicensesIsLoading}
            preferredSectors={candidate?.preferredSectors}
            sectorOptions={sectorOptions.map(transformSectorToFormOption)}
            sectorsIsLoading={sectorsIsLoading}
            onEditEmploymentPreferences={handleEditEmploymentPreferences}
            onEditWorkRemotePreference={handleEditWorkRemotePreference}
            onEditDriversLicenses={handleEditDriversLicenses}
            onEditPreferredSectors={handleEditPreferredSectors}
            className={className}
        />
    );
};

export default ConnectedCandidatePreferencesCard;
