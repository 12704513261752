import { trans } from '../../../helpers/trans';
import { RoleType } from '../../../models/User';

export interface RoleInfoBenefitCard {
    title: string;
    text: string;
}

interface RoleInfoBenefitsContent {
    title: string;
    cards: RoleInfoBenefitCard[];
}

export const roleInfoBenefitsContent = (role: RoleType): RoleInfoBenefitsContent => {
    switch (role) {
        case RoleType.company:
            return {
                title: trans('infoPage.company.benefits.title'),
                cards: [
                    {
                        title: trans('infoPage.company.benefits.cards.left.title'),
                        text: trans('infoPage.company.benefits.cards.left.text'),
                    },
                    {
                        title: trans('infoPage.company.benefits.cards.center.title'),
                        text: trans('infoPage.company.benefits.cards.center.text'),
                    },
                    {
                        title: trans('infoPage.company.benefits.cards.right.title'),
                        text: trans('infoPage.company.benefits.cards.right.text'),
                    },
                ],
            };
        case RoleType.jobSeeker:
            return {
                title: trans('infoPage.jobSeeker.benefits.title'),
                cards: [
                    {
                        title: trans('infoPage.jobSeeker.benefits.cards.left.title'),
                        text: trans('infoPage.jobSeeker.benefits.cards.left.text'),
                    },
                    {
                        title: trans('infoPage.jobSeeker.benefits.cards.center.title'),
                        text: trans('infoPage.jobSeeker.benefits.cards.center.text'),
                    },
                    {
                        title: trans('infoPage.jobSeeker.benefits.cards.right.title'),
                        text: trans('infoPage.jobSeeker.benefits.cards.right.text'),
                    },
                ],
            };
        case RoleType.student:
        default:
            return {
                title: trans('infoPage.student.benefits.title'),
                cards: [
                    {
                        title: trans('infoPage.student.benefits.cards.left.title'),
                        text: trans('infoPage.student.benefits.cards.left.text'),
                    },
                    {
                        title: trans('infoPage.student.benefits.cards.center.title'),
                        text: trans('infoPage.student.benefits.cards.center.text'),
                    },
                    {
                        title: trans('infoPage.student.benefits.cards.right.title'),
                        text: trans('infoPage.student.benefits.cards.right.text'),
                    },
                ],
            };
    }
};
