import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { ApplicationRejectionFormData, transformToApplicationRejectionRequest } from '../models/ApplicationRejections';
import {
    Application,
    ApplicationFormData,
    ApplicationResource,
    ApplicationStatus,
    transformJapiDocumentToApplication,
    transformToApplicationRequest,
    transformToApplicationStatusRequest,
} from '../models/Applications';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getApplicationApiCall = async (applicationUuid: string): Promise<FetchResult<Application, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/applications/${applicationUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ApplicationResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const application = transformJapiDocumentToApplication(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: application,
        };
    } catch (error) {
        console.error('[getApplicationApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const createApplicationApiCall = async (formData: ApplicationFormData): Promise<FetchResult<Application, string>> => {
    try {
        const createApplicationRequest = await transformToApplicationRequest(formData);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/applications`, {
            method: 'POST',
            body: JSON.stringify(createApplicationRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ApplicationResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const application = transformJapiDocumentToApplication(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: application,
        };
    } catch (error) {
        console.error('[createApplicationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteApplicationApiCall = async (applicationUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/applications/${applicationUuid}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteApplicationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchApplicationStatusApiCall = async (applicationUuid: string, status: ApplicationStatus): Promise<FetchResult<Application, string>> => {
    try {
        const patchApplicationStatusRequest = await transformToApplicationStatusRequest(applicationUuid, status);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/applications/${applicationUuid}`, {
            method: 'PATCH',
            body: JSON.stringify(patchApplicationStatusRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ApplicationResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const application = transformJapiDocumentToApplication(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: application,
        };
    } catch (error) {
        console.error('[patchApplicationStatusApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const rejectApplicationApiCall = async (applicationUuid: string, formData: ApplicationRejectionFormData): Promise<FetchResult<undefined, string>> => {
    try {
        const rejectApplicationRequest = await transformToApplicationRejectionRequest(applicationUuid, formData);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/application-rejections`, {
            method: 'POST',
            body: JSON.stringify(rejectApplicationRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[rejectApplicationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
