import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '../../../../components';
import { Avatar } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { Chat } from '../../../../models/Chat';
import { retrieveInterlocutor } from '../../helpers';

import './ChatRoomPreview.scss';

interface ChatRoomPreviewProps {
    isCandidate: boolean;
    isActive: boolean;
    chat: Chat;
    onSelectChat: (chat: Chat) => void;
    className?: string;
}

const ChatRoomPreview: FC<ChatRoomPreviewProps> = ({
    isCandidate,
    isActive,
    chat,
    onSelectChat,
    className = '',
}): ReactElement => {
    const interlocutor = retrieveInterlocutor(chat, isCandidate);

    const handleSelectChatRoom = (): void => {
        onSelectChat(chat);
    };

    const selectButtonClassNames = classNames('chat-room-preview__select-button', {
        'chat-room-preview__select-button--is-active': isActive,
        'chat-room-preview__select-button--is-unread': !chat.isRead,
        'chat-room-preview__select-button--is-reported': chat.isReported,
    });

    return (
        <li className={`chat-room-preview ${className}`}>
            <Button
                text={interlocutor.fullName}
                onClick={handleSelectChatRoom}
                className={selectButtonClassNames}
            >
                <Avatar
                    src={interlocutor.avatar}
                    alt={trans('containers.messageOverview.avatarAlt', {
                        name: interlocutor.fullName,
                    })}
                    fallbackString={interlocutor.fullName}
                    className="chat-room-preview__avatar"
                />

                <div className="chat-room-preview__text-wrapper">
                    <div className="chat-room-preview__title-wrapper">
                        <h3 className="chat-room-preview__title">
                            {interlocutor.fullName}
                        </h3>

                        {!chat.isRead && (
                            <div className="chat-room-preview__unread-indicator" />
                        )}
                    </div>

                    <p className="chat-room-preview__message">
                        {chat.latestMessage || (
                            <em>
                                {chat.isReported
                                    ? trans('containers.messageOverview.chatRoomPreview.reported')
                                    : trans('containers.messageOverview.chatRoomPreview.nullState')}
                            </em>
                        )}
                    </p>
                </div>
            </Button>
        </li>
    );
};

export default ChatRoomPreview;
