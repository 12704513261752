import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrowLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 56.2" className={`icon ${className}`}>
        <path d="M22.5,0L0,28.1l22.5,28.1l7.8-6.2L16.8,33.1H100v-10H16.8L30.3,6.2L22.5,0z" />
    </svg>
);

export default IconArrowLeft;
