import { transformMetaToPagination } from '../_old/app_talentz/models/Pagination';
import { VacancyType } from '../_old/app_talentz/models/VacancyType';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { convertToQueryParamsNew } from '../helpers/params';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { transformToVacancyMatchingCandidate, VacancyMatchesOverview, VacancyMatchingCandidateResource } from '../models/VacancyMatches';
import { transformToVacancyMatchesFilterParams, VacancyMatchesFilterValues } from '../models/VacancyMatchesFilter';

export const getVacancyMatchesApiCall = async (vacancyUuid: string, vacancyType: VacancyType, filterValues: VacancyMatchesFilterValues): Promise<FetchResult<VacancyMatchesOverview, string>> => {
    try {
        const vacancyMatchesFilterParams = transformToVacancyMatchesFilterParams(filterValues);

        const filterParams = convertToQueryParamsNew({
            ...vacancyMatchesFilterParams,
            roles: vacancyMatchesFilterParams.roles
                ? [...vacancyMatchesFilterParams.roles].filter(role => role !== 'llo_candidate')
                : undefined,
            llo: vacancyMatchesFilterParams.roles?.includes('llo_candidate') || undefined,
        });

        const response = vacancyType === VacancyType.internship
            ? await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/matching-job-seekers/${vacancyUuid}?${filterParams}`)
            : await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/matching-students/${vacancyUuid}?${filterParams}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<VacancyMatchingCandidateResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidates = doc.data.map(transformToVacancyMatchingCandidate);
        const pagination = doc.meta ? transformMetaToPagination(doc.meta) : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: { candidates, pagination },
        };
    } catch (error) {
        console.error('[getVacancyMatchesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
