import { Meta } from '../../../japi/types/Meta';

export interface Pagination {
    count: number;
    currentPage: number;
    links: {
        next: string;
    };
    perPage: number;
    total: number;
    totalPages: number;
}

export const transformMetaToPagination = (
    meta: Meta,
): Pagination => ({
    count: meta.count,
    currentPage: meta.pagination.number,
    links: {
        next: '',
    },
    perPage: meta.pagination.size,
    total: meta.count,
    totalPages: meta.pagination.pages,
});
