import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { ApplicationAppointment, ApplicationAppointmentFormData, transformToAcceptApplicationAppointmentRequest } from '../models/ApplicationAppointments';
import {
    ApplicationInvite,
    ApplicationInvitesResource,
    transformJapiDocumentToApplicationInvite,
    transformToCreateApplicationInviteRequest,
    transformToDeclineApplicationInviteRequest,
} from '../models/ApplicationInvites';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const createApplicationInviteApiCall = async (applicationUuid: string, formData: ApplicationAppointmentFormData): Promise<FetchResult<ApplicationInvite, string>> => {
    const applicationInviteRequest = transformToCreateApplicationInviteRequest(formData, applicationUuid);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/application-invites`, {
            method: 'POST',
            body: JSON.stringify(applicationInviteRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ApplicationInvitesResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const applicationInvite = transformJapiDocumentToApplicationInvite(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: applicationInvite,
        };
    } catch (error) {
        console.error('[createApplicationInviteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const acceptApplicationInviteApiCall = async (appointment: ApplicationAppointment, inviteUuid: string): Promise<FetchResult<ApplicationInvite, string>> => {
    const acceptApplicationInviteRequest = transformToAcceptApplicationAppointmentRequest(appointment);

    try {
        const appointmentResponse = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/application-appointments/${appointment.uuid}`, {
            method: 'PATCH',
            body: JSON.stringify(acceptApplicationInviteRequest),
        });

        if (!appointmentResponse.ok) {
            return {
                status: appointmentResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        // Fetch invite of appointment in order to update store
        const inviteResponse = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/application-invites/${inviteUuid}`);

        if (!inviteResponse.ok) {
            return {
                status: inviteResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await inviteResponse.json();

        if (!isResourceDocument<ApplicationInvitesResource>(doc) || !doc.data) {
            return {
                status: inviteResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const applicationInvite = transformJapiDocumentToApplicationInvite(doc, doc.data);

        return {
            status: inviteResponse.status,
            type: FetchResultType.Success,
            data: applicationInvite,
        };
    } catch (error) {
        console.error('[acceptApplicationInviteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const declineApplicationInviteApiCall = async (applicationInvite: ApplicationInvite): Promise<FetchResult<ApplicationInvite, string>> => {
    const declineApplicationInviteRequest = transformToDeclineApplicationInviteRequest(applicationInvite);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/application-invites/${applicationInvite.uuid}`, {
            method: 'PATCH',
            body: JSON.stringify(declineApplicationInviteRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ApplicationInvitesResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const updatedApplicationInvite = transformJapiDocumentToApplicationInvite(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: updatedApplicationInvite,
        };
    } catch (error) {
        console.error('[declineApplicationInviteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
