import { FetchResourceCollectionOK } from './types/FetchResourceCollectionOK';
import { FetchResourceOK } from './types/FetchResourceOK';
import { Resource } from './types/Resource';

// eslint-disable-next-line
export default <T extends Resource>(japiDocument: FetchResourceOK<any> | FetchResourceCollectionOK<any>, resource: Resource, property: string): T | undefined => {
    if (!japiDocument.included) {
        return undefined;
    }

    if (!resource.relationships || Array.isArray(resource.relationships)) {
        return undefined;
    }

    if (!resource.relationships[property]) {
        return undefined;
    }

    const relationship = resource.relationships[property];

    if (!relationship.data || Array.isArray(relationship.data)) {
        return undefined;
    }

    const { id, type } = relationship.data;

    return japiDocument.included.find(el => el.type === type && el.id === id) as T;
};
