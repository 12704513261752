import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    ChatReport,
    ChatReportFormData,
    ChatReportResource,
    transformJapiDocumentToChatReport,
    transformToChatReportRequest,
    UndoChatReportFormData,
} from '../models/ChatReport';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getChatReportsApiCall = async (): Promise<FetchResult<ChatReport[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/company-chat-reports`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ChatReportResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const chatReports = doc.data.map(chatReportResource => transformJapiDocumentToChatReport(doc, chatReportResource));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: chatReports,
        };
    } catch (error) {
        console.error('[getChatReportsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postChatReportApiCall = async (chatUuid: string, chatReportFormData: ChatReportFormData): Promise<FetchResult<ChatReport, string>> => {
    try {
        const reportChatRequest = transformToChatReportRequest(chatUuid, chatReportFormData);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/company-chat-reports`, {
            method: 'POST',
            body: JSON.stringify(reportChatRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ChatReportResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const chatReport = transformJapiDocumentToChatReport(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: chatReport,
        };
    } catch (error) {
        console.error('[postChatReportApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteChatReportApiCall = async (undoChatReportFormData: UndoChatReportFormData): Promise<FetchResult<undefined, string>> => {
    try {
        const { selectedReport } = undoChatReportFormData;

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/company-chat-reports/${selectedReport}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteChatReportApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
