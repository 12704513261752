import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CurrentStudyCard } from '../../../containers';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCurrentStudyCardProps {
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCurrentStudyCard: FC<ConnectedCurrentStudyCardProps> = ({
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    return (
        <CurrentStudyCard
            isLoading={isLoading}
            isEditable={isEditable}
            candidate={candidate}
            className={className}
        />
    );
};

export default ConnectedCurrentStudyCard;
