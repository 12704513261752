import React, { FC, ReactElement } from 'react';

import { Sidebar } from '../../compositions';
import { trans } from '../../helpers/trans';
import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { FavouriteItem } from './subcomponents';

import './FavouritesSidebar.scss';

interface FavouritesSidebarProps {
    isLoading: boolean;
    isExpanded: boolean;
    favourites: VacanciesInfo[];
    onRemoveFavourite: (id: string) => void;
    onClose: () => void;
    className?: string;
}

const FavouritesSidebar: FC<FavouritesSidebarProps> = ({
    isLoading,
    isExpanded,
    favourites,
    onRemoveFavourite,
    onClose,
    className = '',
}): ReactElement => (
    <Sidebar
        isLoading={isLoading}
        isExpanded={isExpanded}
        title={trans('favourites.title')}
        onClose={onClose}
        className={`favourites-sidebar ${className}`}
    >
        {favourites.length > 0 ? (
            <ul id="favourites-list" className="favourites-sidebar__list">
                {favourites.map(favourite => (
                    <FavouriteItem
                        {...favourite}
                        key={favourite.uuid}
                        isLoading={isLoading}
                        onRemoveClick={onRemoveFavourite}
                    />
                ))}
            </ul>
        ) : (
            <div className="favourites-sidebar__no-results">
                <p className="favourites-sidebar__no-results-description">
                    {trans('favourites.nullState')}
                </p>
            </div>
        )}
    </Sidebar>
);

export default FavouritesSidebar;
