import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { FilterButton, IconButton } from '../../compositions';
import { getActiveFilterValues } from '../../helpers/filter';
import { getAmountOfDefinedValues } from '../../helpers/object';
import { trans } from '../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../models/EducationLevels';
import { defaultJobVacancyOverviewFilterValues, JobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { WorkRemoteOption } from '../../models/WorkRemote';
import { FormOption } from '../../types';
import { VacancySearchForm } from '..';
import { VacancySearchFormValues } from '../@forms/VacancySearchForm/VacancySearchForm';
import { JobVacancyOverviewFilterSidebar } from './subcomponents';

import './JobVacancyOverviewFilter.scss';

interface JobVacancyOverviewFilterProps {
    isLoading: boolean;
    activeFilterValues: JobVacancyOverviewFilterValues;
    filterValues: JobVacancyOverviewFilterValues;
    whatOptions: string[];
    whereOptions: string[];
    applyTypeOptions: FormOption[];
    sectorOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    workRemoteOptions: WorkRemoteOption[];
    jobTypeOptions: FormOption[];
    amountOfEmployeesOptions: FormOption[];
    onSearchSubmit: (value: VacancySearchFormValues) => void;
    onFilterChange: (value: Partial<JobVacancyOverviewFilterValues>) => void;
    onFilterReset: () => void;
    onFilterSubmit: () => void;
    className?: string;
}

const JobVacancyOverviewFilter: FC<JobVacancyOverviewFilterProps> = ({
    isLoading,
    activeFilterValues,
    filterValues,
    whatOptions,
    whereOptions,
    applyTypeOptions,
    sectorOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    workRemoteOptions,
    jobTypeOptions,
    amountOfEmployeesOptions,
    onSearchSubmit,
    onFilterChange,
    onFilterReset,
    onFilterSubmit,
    className = '',
}): ReactElement => {
    const [searchValues, setSearchValues] = useState<VacancySearchFormValues>();
    const [sidebarIsExpanded, setSidebarIsExpanded] = useState<boolean>(false);

    const openSidebar = (): void => setSidebarIsExpanded(true);
    const closeSidebar = (): void => setSidebarIsExpanded(false);

    useEffect((): void => {
        setSearchValues({
            what: filterValues.what,
            where: filterValues.where,
            type: VacancyType.job,
        });
    }, [filterValues]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
        pageNumber,
        pageSize,
        what,
        where,
        skills,
        competencies,
        province,
        ...sidebarFilters
    } = activeFilterValues;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const defaultFilters = defaultJobVacancyOverviewFilterValues();
    const activeFilters = getActiveFilterValues(sidebarFilters, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    return (
        <section className={`job-vacancy-overview-filter ${className}`}>
            <VacancySearchForm
                value={searchValues}
                whatOptions={whatOptions}
                whereOptions={whereOptions}
                onSubmit={onSearchSubmit}
                className="job-vacancy-overview-filter__search-form"
            />

            <div className="job-vacancy-overview-filter__button-wrapper">
                <FilterButton
                    hasIcon
                    activeFilters={amountOfActiveFilters}
                    disabled={isLoading}
                    onClick={openSidebar}
                    className="job-vacancy-overview-filter__filter-button"
                />

                {amountOfActiveFilters > 0 && (
                    <IconButton
                        icon="refresh"
                        text={trans('actions.reset')}
                        disabled={isLoading}
                        onClick={onFilterReset}
                        className="job-vacancy-overview-filter__reset-button"
                    />
                )}
            </div>

            <JobVacancyOverviewFilterSidebar
                isLoading={isLoading}
                isExpanded={sidebarIsExpanded}
                hasLocation={!!where}
                filterValues={filterValues}
                applyTypeOptions={applyTypeOptions}
                sectorOptions={sectorOptions}
                educationLevelOptions={educationLevelOptions}
                educationSubLevelOptions={educationSubLevelOptions}
                workRemoteOptions={workRemoteOptions}
                jobTypeOptions={jobTypeOptions}
                amountOfEmployeesOptions={amountOfEmployeesOptions}
                onChange={onFilterChange}
                onReset={onFilterReset}
                onSubmit={onFilterSubmit}
                onClose={closeSidebar}
            />
        </section>
    );
};

export default JobVacancyOverviewFilter;
