import React, { FC, ReactElement } from 'react';

import './SocialLinks.scss';

export interface SocialPlatform {
    label: string;
    url: string;
    icon: string;
}

interface SocialLinksProps {
    socialPlatforms: SocialPlatform[];
    className?: string;
}

const SocialLinks: FC<SocialLinksProps> = ({
    socialPlatforms,
    className = '',
}): ReactElement => (
    <ul className={`social-links ${className}`}>
        {socialPlatforms.map(platform => (
            <li key={platform.label} className="social-links__item">
                <a
                    aria-label={platform.label}
                    href={platform.url}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={platform.icon} alt={platform.label} />
                </a>
            </li>
        ))}
    </ul>
);

export default SocialLinks;
