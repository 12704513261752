import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { Competency } from '../../../models/Competencies';
import { Skill } from '../../../models/Skills';
import { validateMinimumArrayLength } from '../../../services/ValidationService';

interface JobVacancySkillsStepValues {
    skills: Skill[];
    competencies: Competency[];
}

export const validateJobVacancySkillsStep = (formValues: JobVacancySkillsStepValues): ValidationErrors => {
    const { skills, competencies } = formValues;

    const skillsValidation = validateMinimumArrayLength('skills', skills, 3);
    const competenciesValidation = validateMinimumArrayLength('competencies', competencies, 3);

    return {
        ...(skillsValidation ? { skills: [skillsValidation] } : {}),
        ...(competenciesValidation ? { competencies: [competenciesValidation] } : {}),
    };
};
