import React, { FC, ReactElement } from 'react';

import { LinkButton } from '../../../components';
import { DetailCard } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoutePaths } from '../../../routes';

import './VacancyRecruitmentCard.scss';

interface VacancyRecruitmentCardProps {
    isLoading: boolean;
    vacancy?: NewestVacancy;
    className?: string;
}

const VacancyRecruitmentCard: FC<VacancyRecruitmentCardProps> = ({
    isLoading,
    vacancy,
    className = '',
}): ReactElement => (
    <DetailCard
        title={trans('vacancyDetail.recruitment.title')}
        className={`vacancy-recruitment-card ${className}`}
    >
        <p className="vacancy-recruitment-card__description">
            {trans('vacancyDetail.recruitment.description')}
        </p>

        <div className="vacancy-recruitment-card__button-wrapper">
            <LinkButton
                to={RoutePaths.companyOverviewApplicants(vacancy?.uuid)}
                text={trans('vacancyDetail.recruitment.button.applicants')}
                disabled={isLoading || !vacancy}
                className="vacancy-recruitment-card__button"
            />

            <LinkButton
                to={RoutePaths.companyOverviewMatches(vacancy?.uuid)}
                text={trans('vacancyDetail.recruitment.button.matches')}
                disabled={isLoading || !vacancy}
                className="vacancy-recruitment-card__button"
            />
        </div>
    </DetailCard>
);

export default VacancyRecruitmentCard;
