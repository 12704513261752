import React from 'react';

import { parseToRgb } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { display, space } from 'styled-system';

import Icon from '../../icons/Icon';
import colors from '../theme/colors';

const IconComponent = props => {
    let color = 'transparent';

    if (props.color !== 'transparent') {
        const c = parseToRgb(colors[props.color || 'text'] || props.color);
        color = `rgb(${c.red},${c.green},${c.blue})`;
    }

    const { hasLoader, ...rest } = props;

    const styleProps = {};
    if (hasLoader) {
        styleProps.fill = 'transparent';
    }
    styleProps.pointerEvents = props.pointerEvents;

    return (
        <Icon
            {...rest}
            style={styleProps}
            color={color}
            xmlns="http://www.w3.org/2000/svg"
        />
    );
};

IconComponent.propTypes = {
    color: PropTypes.string,
    pointerEvents: PropTypes.string,
    hasLoader: PropTypes.bool,
    rotate: PropTypes.number,
};

IconComponent.defaultProps = {
    color: 'primary',
    pointerEvents: 'none',
    hasLoader: false,
    rotate: 0,
};

export default styled(IconComponent)(`
  opacity: ${props => props.opacity || 1};
`, space, display);
