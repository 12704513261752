import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getKvkBaseProfileApiCall } from '../../services/KvkBaseProfileService';
import { TypedDispatch } from '..';
import { setError, setIsLoading, setKvkBaseProfile } from './kvkBaseProfile';

export const fetchKvkBaseProfile = (kvkNumber: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const kvkBaseProfileResponse = await getKvkBaseProfileApiCall(kvkNumber);

        if (!isFetchResultSuccessful(kvkBaseProfileResponse)) {
            dispatch(setError(kvkBaseProfileResponse.error));
            return;
        }

        dispatch(setKvkBaseProfile(kvkBaseProfileResponse.data));
    } catch (error) {
        console.error('[fetchKvkBaseProfile]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
