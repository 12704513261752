import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';

import './ChatRoomHeaderSkeletons.scss';

interface ChatRoomHeaderSkeletonsProps {
    className?: string;
}

const ChatRoomHeaderSkeletons: FC<ChatRoomHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`chat-room-header-skeletons ${className}`}>
        <Skeleton className="chat-room-header-skeletons__title" />
        <Skeleton className="chat-room-header-skeletons__link" />
    </div>
);

export default ChatRoomHeaderSkeletons;
