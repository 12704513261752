import React, { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';
import { useLegacyDispatch, useLegacySelector } from '../../../../store';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import MultiStepOverlay from '../../../components/MultiStepOverlay';
import Page from '../../../styledComponents/components/Page';
import { getUserByPasswordToken, resetPasswordComplete } from '../../store/resetPassword/resetPasswordActions';
import PasswordForm from '../components/forms/PasswordForm';

const PasswordForgetComplete: FC = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const navigate = useNavigate();

    const isLoading = useLegacySelector(state => state.resetPassword.loading);
    const resetPasswordToken = useLegacySelector(state => state.resetPassword.resetPasswordToken);
    const errors = useLegacySelector(state => state.resetPassword.errors);
    const completeStep = useLegacySelector(state => state.resetPassword.completeStep);

    useEffect((): void => {
        if (resetPasswordToken) {
            legacyDispatch(getUserByPasswordToken(resetPasswordToken));
        } else {
            navigate(RoutePaths.home());
        }
    }, []);

    const navigateHome = (): void => navigate(RoutePaths.home());

    const handleSubmit = (data: any): void => {
        legacyDispatch(resetPasswordComplete(data));
    };

    const steps = [
        {
            message: {
                body: trans('passwords.forget.tokenNotExist'),
                button: (
                    <Button
                        onClick={navigateHome}
                        color="white"
                        mt="lg"
                        icon="backward"
                        iconSize={14}
                    >
                        {trans('actions.backToHome')}
                    </Button>
                ),
            },
            component: null,
        },
        {
            component: (
                <PasswordForm
                    resetPassword
                    errors={errors}
                    onSubmit={handleSubmit}
                    loading={isLoading}
                />
            ),
        },
        {
            message: {
                body: trans('forms.passwordForget.changedSuccess'),
                button: (
                    <Button
                        onClick={navigateHome}
                        color="white"
                        mt="lg"
                        icon="backward"
                        iconSize={14}
                    >
                        {trans('actions.backToHome')}
                    </Button>
                ),
            },
            component: null,
        },
    ];

    const activeStep = completeStep
        ? steps[completeStep]
        : undefined;

    return (
        <Page>
            <Helmet>
                <title>{trans('forms.passwordForget.meta.title')}</title>
                <meta name="description" content={trans('forms.passwordForget.meta.description')} />
            </Helmet>
            <Loader />
            <MultiStepOverlay
                step={completeStep}
                onClose={navigateHome}
                message={activeStep ? activeStep.message : null}
            >
                {activeStep && activeStep.component}
            </MultiStepOverlay>
        </Page>
    );
};

export default PasswordForgetComplete;
