import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { trans } from '../../../../helpers/trans';
import { Sector } from '../../../../models/Sectors';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexSector.scss';

interface VacancyIndexSectorProps {
    isLoading: boolean;
    vacancyType: VacancyType;
    shouldSearchDirectly?: boolean;
    sectorOptionColumns: Sector[][];
    className?: string;
}

const VacancyIndexSector: FC<VacancyIndexSectorProps> = ({
    isLoading,
    vacancyType,
    shouldSearchDirectly,
    sectorOptionColumns,
    className = '',
}): ReactElement => {
    const getSectorRoute = (sector: Sector): string => {
        if (shouldSearchDirectly) {
            const params = new URLSearchParams();
            params.set('sector', sector.slug);

            return vacancyType === VacancyType.internship
                ? `${RoutePaths.internshipsQuery('')}?${String(params)}`
                : `${RoutePaths.jobsQuery('')}?${String(params)}`;
        }

        const encodedSector = encodeURIComponent(sector.slug);
        return vacancyType === VacancyType.internship
            ? RoutePaths.internshipVacancyIndexSector(encodedSector)
            : RoutePaths.jobVacancyIndexSector(encodedSector);
    };

    return (
        <VacancyIndexCategory
            isLoading={isLoading}
            title={trans('vacancyIndex.category.sector.main')}
            className={`vacancy-index-sector ${className}`}
        >
            <VacancyIndexColumnList>
                {sectorOptionColumns.map(column => {
                    const columnIndex = column.map(sector => sector.slug).join('-');

                    return (
                        <li key={columnIndex}>
                            <ul className="vacancy-index-sector__sector-list">
                                {column.map(sector => {
                                    const route = getSectorRoute(sector);

                                    return (
                                        <li key={sector.id} className="vacancy-index-sector__sector">
                                            <VacancyIndexLink to={route} label={sector.name} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </VacancyIndexColumnList>
        </VacancyIndexCategory>
    );
};

export default VacancyIndexSector;
