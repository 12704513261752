import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';

import { LinkIconButton, Tag } from '../../../../compositions';
import { jitsiScriptUrl } from '../../../../constants';
import { formatDate } from '../../../../helpers/date';
import { trans } from '../../../../helpers/trans';
import { ApplicationAppointment } from '../../../../models/ApplicationAppointments';
import { Application } from '../../../../models/Applications';
import { RoutePaths } from '../../../../routes';

import './Jitsi.scss';

interface JitsiProps {
    isCandidate: boolean;
    application: Application;
    appointment: ApplicationAppointment;
    className?: string;
}

const Jitsi: FC<JitsiProps> = ({
    isCandidate,
    application,
    appointment,
    className = '',
}): ReactElement => {
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
    const [apiConnection, setApiConnection] = useState(null);

    const streamContainerRef = useRef<HTMLDivElement>(null);

    const employerName = application.employee.fullName;
    const applicantName = application.vacancy.isAnonymous
        ? trans('basic.anonymousCandidate')
        : application.applicant.fullName;

    const localDisplayName = isCandidate ? applicantName : employerName;
    const remoteDisplayName = isCandidate
        ? `${employerName} – ${application.vacancy.companyName}`
        : applicantName;

    const endButtonPath = isCandidate
        ? RoutePaths.application(application.uuid)
        : RoutePaths.myVacancyApplication(application.vacancy.uuid, application.applicant.uuid, application.uuid);

    useEffect((): void => {
        const scriptTag = document.createElement('script');

        scriptTag.setAttribute('src', jitsiScriptUrl);
        scriptTag.setAttribute('async', 'true');
        scriptTag.addEventListener('load', () => setScriptLoaded(true));

        document.body.appendChild(scriptTag);
    }, []);

    useEffect((): void => {
        if (!scriptLoaded || apiConnection) return;

        // @ts-ignore
        if (typeof JitsiMeetExternalAPI === 'function') {
            setApiConnection(
                // @ts-ignore
                new JitsiMeetExternalAPI('meet.jit.si', {
                    roomName: appointment.uuid,
                    parentNode: streamContainerRef.current,
                    userInfo: {
                        displayName: localDisplayName,
                    },
                    interfaceConfigOverwrite: {
                        APP_NAME: process.env.REACT_APP_APP_TITLE,
                        NATIVE_APP_NAME: process.env.REACT_APP_APP_TITLE,
                        DEFAULT_LOCAL_DISPLAY_NAME: localDisplayName,
                        DEFAULT_REMOTE_DISPLAY_NAME: remoteDisplayName,
                        DISABLE_VIDEO_BACKGROUND: true,
                        HIDE_KICK_BUTTON_FOR_GUESTS: true,
                        TOOLBAR_BUTTONS: [
                            'microphone',
                            'camera',
                            'closedcaptions',
                            'tileview',
                            'help',
                            'livestreaming',
                            'chat',
                            'desktop',
                        ],
                    },
                }),
            );
        }
    }, [scriptLoaded, application, appointment]);

    return (
        <section className={`jitsi ${className}`}>
            <Helmet>
                <title>
                    {trans('containers.videoAppointment.meta.title', {
                        name: remoteDisplayName,
                    })}
                </title>
                <meta name="description" content={trans('containers.videoAppointment.meta.description')} />
            </Helmet>

            <header className="jitsi__header">
                <div className="jitsi__live-indicator-wrapper">
                    <Tag
                        text={trans('basic.live')}
                        className="jitsi__live-indicator"
                    />
                    <div className="jitsi__live-pulse" />
                </div>

                <h1 className="jitsi__title">
                    {trans('containers.videoAppointment.title', {
                        name: remoteDisplayName,
                    })}
                </h1>
            </header>

            <p className="jitsi__appointment-time">
                {trans('containers.videoAppointment.appointmentTime', {
                    startTime: formatDate(appointment.startDate, 'HH:mm'),
                    endTime: formatDate(appointment.endDate, 'HH:mm'),
                })}
            </p>

            <div ref={streamContainerRef} className="jitsi__stream-container" />

            <LinkIconButton
                icon="cross"
                to={endButtonPath}
                text={trans('containers.videoAppointment.button.endAppointment')}
                className="jitsi__end-button"
            />
        </section>
    );
};

export default Jitsi;
