import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import './VacancyIndexLink.scss';

interface VacancyIndexLinkProps {
    to?: string;
    label: string;
    className?: string;
}

const VacancyIndexLink: FC<VacancyIndexLinkProps> = ({
    to,
    label,
    className = '',
}): ReactElement => (
    <div className={`vacancy-index-link ${className}`}>
        {to ? (
            <Link to={to} className="vacancy-index-link__link">
                {label}
            </Link>
        ) : label}
    </div>
);

export default VacancyIndexLink;
