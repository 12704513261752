// TODO: Despite apparent refactor, this reducer/slice needs to be refactored, and removed from '_old' entirely.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegisterCloseBehaviour } from './RegisterActionTypes';

export interface RegisterState {
    loading: boolean;
    loadingSocialMedia: boolean;
    applyAnonymous: boolean;
    userType: string | null;
    step: number | null;
    completeStep: number;
    authCompleted: boolean | null;
    activationToken: string | null;
    errors: Record<string, unknown>;
    registerSuccessData: any;
    closeBehaviour: RegisterCloseBehaviour;
    isCloseButtonDisabled: boolean;
}

const initialState: RegisterState = {
    loading: false,
    loadingSocialMedia: false,
    applyAnonymous: false,
    userType: null,
    step: null,
    completeStep: 0,
    authCompleted: null,
    activationToken: null,
    errors: {},
    registerSuccessData: {},
    closeBehaviour: RegisterCloseBehaviour.CloseDialog,
    isCloseButtonDisabled: false,
};

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): RegisterState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setLoadingSocialMedia(state, action: PayloadAction<boolean>): RegisterState {
            return {
                ...state,
                loadingSocialMedia: action.payload,
            };
        },
        setStep(state, action: PayloadAction<number | null>): RegisterState {
            return {
                ...state,
                step: action.payload,
            };
        },
        setCompleteStep(state, action: PayloadAction<number>): RegisterState {
            return {
                ...state,
                completeStep: action.payload,
            };
        },
        setAuthCompleted(state, action: PayloadAction<boolean>): RegisterState {
            return {
                ...state,
                authCompleted: action.payload,
            };
        },
        setActivationToken(state, action: PayloadAction<string | null>): RegisterState {
            return {
                ...state,
                activationToken: action.payload,
            };
        },
        setUserType(state, action: PayloadAction<string | null>): RegisterState {
            return {
                ...state,
                userType: action.payload,
            };
        },
        setRegisterSuccessData(state, action: PayloadAction<unknown>): RegisterState {
            return {
                ...state,
                registerSuccessData: action.payload,
            };
        },
        setCloseBehaviour(state, action: PayloadAction<RegisterCloseBehaviour>): RegisterState {
            return {
                ...state,
                closeBehaviour: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): RegisterState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setLoadingSocialMedia,
    setStep,
    setCompleteStep,
    setActivationToken,
    setAuthCompleted,
    setUserType,
    setRegisterSuccessData,
    setCloseBehaviour,
    setErrors,
} = registerSlice.actions;

export default registerSlice;
