import React, { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Skeleton } from '../../../../components';
import { SearchableValueInput } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { NewestVacancy } from '../../../../models/NewestVacancy';
import { RoutePaths } from '../../../../routes';
import { SearchableOption } from '../../../../types';

import './VacancyApplicantsFilter.scss';

interface VacancyApplicantsFilterProps {
    isLoading: boolean;
    vacancyUuidFromUrl?: string;
    activeVacancy?: NewestVacancy;
    vacancyOptions: SearchableOption[];
    className?: string;
}

const VacancyApplicantsFilter: FC<VacancyApplicantsFilterProps> = ({
    isLoading,
    vacancyUuidFromUrl,
    activeVacancy,
    vacancyOptions,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const setActiveVacancy = (selectedOption: SearchableOption): void => {
        if (selectedOption.value) {
            navigate(RoutePaths.companyOverviewApplicants(selectedOption.value));
        }
    };

    useEffect((): void => {
        if (!vacancyUuidFromUrl && vacancyOptions.length > 0) {
            setActiveVacancy(vacancyOptions[0]);
        }
    }, [vacancyOptions]);

    return (
        <section className={`vacancy-applicants-filter ${className}`}>
            {isLoading && (
                <Skeleton className="vacancy-applicants-filter__selected-vacancy-skeleton" />
            )}

            {!isLoading && activeVacancy && (
                <h2 className="vacancy-applicants-filter__selected-vacancy">
                    {trans('containers.vacancyApplicants.selectedVacancy', {
                        title: activeVacancy.title,
                    })}
                </h2>
            )}

            <SearchableValueInput
                isSearchable
                showResultsOnClick
                label={trans('containers.vacancyApplicants.input.vacancy')}
                hideLabel
                options={vacancyOptions}
                placeholder={trans('containers.vacancyApplicants.placeholder.vacancy')}
                disabled={isLoading}
                onChange={setActiveVacancy}
                className="vacancy-applicants-filter__vacancy-input"
                listClassName="vacancy-applicants-filter__vacancy-input-results"
            />
        </section>
    );
};

export default VacancyApplicantsFilter;
