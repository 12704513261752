import { shuffleArray } from '../../../../helpers/array';
import { Skill } from '../../../../models/Skills';

export const searchSkillOptionsOnQuery = (options: Skill[], query: string): Skill[] => {
    try {
        const searchQuery = new RegExp(query, 'i');

        return options.filter(item => item.name.match(searchQuery));
    } catch {
        return [];
    }
};

export const sortSkillOptions = (skills: Skill[]): Skill[] => (
    skills.sort((skill, comparisonSkill) => skill.name.localeCompare(comparisonSkill.name))
);

export const getRandomExampleOptions = (options: Skill[], amount = 2): string => {
    const maxLength = 14;
    const shortOptions = options.filter(option => option.name.length <= maxLength);
    const shuffledOptions = shuffleArray<Skill>(shortOptions);

    return shuffledOptions
        .slice(0, amount)
        .map(option => option.name)
        .join(', ');
};
