import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCircleCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M10,50c0-22.1,17.9-40,40-40c22.1,0,40,17.9,40,40c0,22.1-17.9,40-40,40C27.9,90,10,72.1,10,50z" />
        <polygon points="42.5,70 77.5,40 72.5,35 42.5,60 27.5,45 22.5,50" />
    </svg>
);

export default IconCircleCheck;
