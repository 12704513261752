import React, { FC, ReactElement, useEffect } from 'react';

import { CoreValuesCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { CoreValuesFormData, transformCoreValueToSearchableOption } from '../../../models/CoreValue';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany, patchCompany } from '../../../store/company/companyActions';
import { fetchCoreValues } from '../../../store/coreValues/coreValuesActions';

interface ConnectedCoreValuesCardProps {
    activationRef?: ActivationRef;
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCoreValuesCard: FC<ConnectedCoreValuesCardProps> = ({
    activationRef,
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);
    const isLoading = useTypedSelector(state => state.companyReducer.isLoading);

    const coreValueOptions = useTypedSelector(state => state.coreValuesReducer.coreValues);
    const coreValuesIsLoading = useTypedSelector(state => state.coreValuesReducer.isLoading);

    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    useEffect((): void => {
        if (userFromCompany) {
            if (coreValueOptions.length === 0) dispatch(fetchCoreValues());
        }
    }, [userFromCompany]);

    const handleEditCoreValues = (coreValuesFormData: CoreValuesFormData): void => {
        const selectedCoreValueList = coreValuesFormData.coreValues.map(coreValue => coreValue.value);
        const selectedCoreValues = coreValueOptions.filter(coreValue => selectedCoreValueList.includes(coreValue.uuid));

        if (company) {
            dispatch(patchCompany({
                uuid: company.uuid,
                coreValues: selectedCoreValues,
            }));
        }
    };

    return (
        <CoreValuesCard
            activationRef={activationRef}
            isLoading={isLoading || coreValuesIsLoading}
            isEditable={userFromCompany}
            userFromCompany={userFromCompany}
            coreValues={company?.coreValues}
            coreValueOptions={coreValueOptions.map(transformCoreValueToSearchableOption)}
            onEdit={handleEditCoreValues}
            className={className}
        />
    );
};

export default ConnectedCoreValuesCard;
