import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';
import { IconButton, SubmitButton } from '..';

import './StepFooter.scss';

interface StepFooterProps {
    loading?: boolean;
    className?: string;
    nextButtonText?: string;
    backButtonText?: string;
    onBackButtonClick?: () => void;
}

const StepFooter: FC<StepFooterProps> = ({
    loading = false,
    onBackButtonClick,
    nextButtonText = trans('placeVacancyPage.steps.next'),
    backButtonText = '',
    className = '',
}): ReactElement => (
    <footer className={`step-footer ${className}`}>
        <SubmitButton isLoading={loading} text={nextButtonText} />

        {onBackButtonClick && (
            <IconButton
                icon="chevron-left"
                text={backButtonText}
                onClick={onBackButtonClick}
                className="step-footer__back-button"
            />
        )}
    </footer>
);

export default StepFooter;
