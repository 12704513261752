import React, { FC, ReactElement } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { FilesToUploadListItem } from '..';

import './FilesToUploadList.scss';

interface FilesToUploadListProps {
    files: File[];
    onDeleteFile: (file: File) => void;
    className?: string;
}

const FilesToUploadList: FC<FilesToUploadListProps> = ({
    files,
    onDeleteFile,
    className = '',
}): ReactElement => (
    <ul className={`files-to-upload-list ${className}`}>
        {files.map(file => {
            const fileKey = uuidv4();
            const handleDeleteFile = () => onDeleteFile(file);

            return (
                <FilesToUploadListItem
                    key={fileKey}
                    file={file}
                    onDelete={handleDeleteFile}
                    className="files-to-upload-list__item"
                />
            );
        })}
    </ul>
);

export default FilesToUploadList;
