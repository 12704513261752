import { trans } from '../../../helpers/trans';
import { SbbRecognition } from '../../../models/SbbTrainingCompany';

interface SbbPerson {
    id: number;
    name: string;
    role: {
        name: string;
        recognitions: SbbRecognition[];
    };
    email: string;
    phone: string;
}

export const transformRecognitionsToPeople = (recognitions: SbbRecognition[]): SbbPerson[] => {
    const people = recognitions.reduce((allTrainers: SbbPerson[], recognition: SbbRecognition) => {
        const trainers = recognition.practicalTrainers.map(practicalTrainer => ({
            id: practicalTrainer.id,
            name: `${practicalTrainer.firstName} ${practicalTrainer.lastName}`,
            email: practicalTrainer.email,
            phone: practicalTrainer.phoneNumber,
            role: {
                name: trans('userProfile.sbb.practicalTrainer'),
                recognitions: [],
            },
        }));

        return [
            ...allTrainers,
            ...trainers.filter(trainer => !allTrainers.find(compareTrainer => trainer.id === compareTrainer.id)),
        ];
    }, []);

    return people.map(person => ({
        ...person,
        role: {
            name: person.role.name,
            recognitions: recognitions.filter(recognition => (
                recognition.practicalTrainers.find(trainer => trainer.id === person.id)
            )),
        },
    }));
};
