import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Disclaimer, FormActionButtons, Textarea } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { ChatReportFormData } from '../../../models/ChatReport';
import { FormProps } from '../../../types';
import { ChatReportFormErrors, validateChatReportFormData } from './validations';

import './ChatReportForm.scss';

interface ChatReportFormProps extends FormProps<ChatReportFormData> {
    className?: string;
}

const ChatReportForm: FC<ChatReportFormProps> = ({
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<ChatReportFormErrors>({});

    const [reportReason, setReportReason] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = { reportReason };

        const [errors, hasErrors] = validateChatReportFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`chat-report-form ${className}`}>
            <Textarea
                label={trans('forms.chatReport.input.reason')}
                value={reportReason}
                placeholder={trans('forms.chatReport.placeholder.reason')}
                error={formErrors.reportReason}
                onChange={setReportReason}
            />

            <Disclaimer
                title={trans('forms.chatReport.disclaimer.title')}
                description={trans('forms.chatReport.disclaimer.description')}
                className="chat-report-form__disclaimer"
            />

            <FormActionButtons
                submitText={trans('forms.chatReport.button.submit')}
                onCancelClick={onCancel}
                className="chat-report-form__action-buttons"
            />
        </form>
    );
};

export default ChatReportForm;
