import React, { FC, ReactElement } from 'react';

import './TableRow.scss';

type HTMLTableRowProps = JSX.IntrinsicElements['tr'];

interface TableRowProps extends HTMLTableRowProps {
    className?: string;
}

const TableRow: FC<TableRowProps> = ({
    className = '',
    children,
    ...tableRowProps
}): ReactElement => (
    <tr {...tableRowProps} className={`table-row ${className}`}>
        {children}
    </tr>
);

export default TableRow;
