/* eslint-disable camelcase */
import { sanitizeHtml } from '../../../helpers/sanitizeHtml';
import { EscoSkillViewModel } from '../../../models/Esco';
import { Address } from './Address';
import { Application, ApplicationApiParams, transformApplication } from './Application';
import { Company } from './Company';
import { MatchProperty } from './MatchProperty';
import { VacancyType } from './VacancyType';

export interface VacancyApiParams {
    uuid: string;
    slug: string;
    title: string;
    date: string;
    summary?: string;
    description?: string;
    type: VacancyType.internship | VacancyType.job;
    is_paid: boolean;
    price: number;
    deleted_at?: string;
    is_favourite: boolean;
    page_views: number;
    favourite_count: number;
    publication_days: number;
    company: any;
    invited_users: any[];
    ignored_users: any[];
    previous_vacancy?: string;
    next_vacancy?: string;
    apply_anonymous: boolean;
    period: any[];
    address: Address;
    fields_of_study: MatchProperty[];
    education_levels: MatchProperty[];
    vacancy_type: MatchProperty;
    travel_distance: MatchProperty;
    skills: MatchProperty[];
    skills_to_learn: MatchProperty[];
    esco_skills: EscoSkillViewModel[];
    competencies: MatchProperty[];
    competencies_to_learn: MatchProperty[];
    sector: any;
    application?: ApplicationApiParams;
    applications?: ApplicationApiParams[];
}

export interface Vacancy {
    uuid: string;
    slug: string;
    title: string;
    date: string;
    summary?: string;
    description?: string;
    type: VacancyType.internship | VacancyType.job;
    is_paid: boolean;
    price: number;
    deleted_at?: string;
    is_favourite: boolean;
    page_views: number;
    favourite_count: number;
    publication_days: number;
    company: Company;
    invited_users: any[];
    ignored_users: any[];
    previous_vacancy?: string;
    next_vacancy?: string;
    apply_anonymous: boolean;
    period: any[];
    address: Address | null;
    application?: Application;
    applications?: Application[];
    education_levels: MatchProperty[];
    esco_skills: EscoSkillViewModel[];
    sector: {
        slug: string;
        name: string;
    };
}

export function transformVacancy(vacancy: VacancyApiParams): Vacancy {
    return {
        uuid: vacancy.uuid,
        slug: vacancy.slug,
        title: vacancy.title,
        date: vacancy.date,
        summary: vacancy.summary,
        description: sanitizeHtml(vacancy.description || ''),
        type: vacancy.type,
        is_paid: vacancy.is_paid,
        price: vacancy.price,
        deleted_at: vacancy.deleted_at,
        is_favourite: vacancy.is_favourite,
        page_views: vacancy.page_views,
        favourite_count: vacancy.favourite_count,
        publication_days: vacancy.publication_days,
        company: {
            ...vacancy.company,
            description: sanitizeHtml(vacancy.company.description || ''),
            fun_facts: sanitizeHtml(vacancy.company.fun_facts || ''),
        },
        invited_users: vacancy.invited_users,
        ignored_users: vacancy.ignored_users,
        previous_vacancy: vacancy.previous_vacancy,
        next_vacancy: vacancy.next_vacancy,
        period: vacancy.period,
        application: vacancy.application ? transformApplication(vacancy.application) : undefined,
        applications: vacancy.applications ? vacancy.applications.map(transformApplication) : undefined,
        education_levels: vacancy.education_levels,
        apply_anonymous: vacancy.apply_anonymous,
        esco_skills: vacancy.esco_skills || [],
        sector: vacancy.sector,
        address: vacancy.address,
    };
}
/* eslint-enable camelcase */
