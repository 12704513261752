import { SelectOption } from '../../../../components/@inputs/Select/Select';

export default function getSelectedInputValues(values: string | string[] | boolean | undefined, options: SelectOption[]): SelectOption[] {
    if (!values) {
        return [];
    }

    let combinedValues: (string | boolean)[] = [];

    if (!Array.isArray(values)) {
        combinedValues = [values];
    } else {
        combinedValues = values;
    }

    return combinedValues.map(value => options.find(option => option.value === value)) as SelectOption[];
}
