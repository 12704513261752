import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Checkbox } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import { isPushManagerSupported } from '../../../../helpers/ServiceWorkerHelper';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../styledComponents/components/Typography';

export interface RegisterNotificationsFormSettings {
    notifications: boolean;
    newsletter: boolean;
}

interface RegisterNotificationsFormProps {
    isLoading: boolean;
    onSubmit: (notificationSettings: RegisterNotificationsFormSettings) => Promise<void>;
}

const RegisterNotificationsForm: FC<RegisterNotificationsFormProps> = ({
    isLoading,
    onSubmit,
}): ReactElement => {
    const [notificationsIsSupported, setNotificationsIsSupported] = useState<boolean>(false);
    const [checkedNotifications, setCheckNotifications] = useState<boolean>(false);
    const [checkedNewsletter, setCheckNewsletter] = useState<boolean>(false);

    useEffect((): void => {
        const retrievePushManager = async (): Promise<void> => {
            const hasPushManager = await isPushManagerSupported();

            setNotificationsIsSupported(hasPushManager);
            setCheckNotifications(false);
        };

        retrievePushManager();
    }, []);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            notifications: checkedNotifications,
            newsletter: checkedNewsletter,
        });
    };

    return (
        <form id="register-notifications-form" onSubmit={handleSubmit}>
            <Heading as="h2" size="xl" mb="xs">{trans('forms.notifications.heading')}</Heading>
            <Paragraph mb="lg">{trans('forms.notifications.body')}</Paragraph>

            {notificationsIsSupported && (
                <Row>
                    <Col width={1}>
                        <Checkbox
                            label={trans('forms.notifications.agreement')}
                            checked={checkedNotifications}
                            disabled={isLoading}
                            onChange={setCheckNotifications}
                        />
                    </Col>
                </Row>
            )}

            <Row mt="sm">
                <Col width={1}>
                    <Checkbox
                        label={trans('forms.notifications.newsletterAgreement')}
                        checked={checkedNewsletter}
                        disabled={isLoading}
                        onChange={setCheckNewsletter}
                    />
                </Col>
            </Row>

            <Row mt="md" justifyContent="flex-end">
                <Col>
                    <Button id="register-notifications-submit" isLoading={isLoading} type="submit">
                        {trans('forms.newsletter.action')}
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default RegisterNotificationsForm;
