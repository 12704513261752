import axios from 'axios';
import { Dispatch } from 'redux';

import { LegacyReducers } from '../../../../store/reducers';
import { setData, setErrors, setLoading } from './userAddress';

export function createAddress(data: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setLoading(true));

        try {
            const response = await axios.post('/addresses', data);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function getAddress(uuid = null) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userPerson } = getState();

        const addressId = uuid || userPerson?.data?.address_id;

        try {
            const response = await axios.get(`/addresses/${addressId}`);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function updateAddress(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userAddress } = getState();
        const addressUuid = userAddress.data?.uuid || '';

        try {
            const response = await axios.patch(`/addresses/${addressUuid}`, data);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}
