import React from 'react';

import { Error } from '../../pages';
import { StatusCode } from '../../types';

export const errorRoutePaths = {
    error401: () => '/401',
    error404: () => '/404',
    error410: () => '/410',
    error500: () => '/500',
};

export const errorRoutes = [
    {
        path: errorRoutePaths.error401(),
        element: <Error status={StatusCode.unauthorized} />,
    },
    {
        path: errorRoutePaths.error410(),
        element: <Error status={StatusCode.gone} />,
    },
    {
        path: errorRoutePaths.error404(),
        element: <Error status={StatusCode.notFound} />,
    },
    {
        path: errorRoutePaths.error500(),
        element: <Error status={StatusCode.server} />,
    },
    {
        path: '*',
        element: <Error status={StatusCode.notFound} />,
    },
];
