import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    ReactNode,
    Ref,
    RefAttributes,
} from 'react';

import './Card.scss';

export interface CardProps extends RefAttributes<HTMLDivElement> {
    className?: string;
    children: ReactNode;
}

const Card: ForwardRefExoticComponent<CardProps> = forwardRef(({
    className = '',
    children,
}, ref: Ref<HTMLDivElement>): ReactElement => (
    <div ref={ref} className={`card ${className}`}>
        {children}
    </div>
));

export default Card;
