import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { LinkButton } from '../../../components';
import { scrollToTop } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { RoutePaths } from '../../../routes';
import { StatusCode } from '../../../types';
import { CompanyOverviewTab } from '../../../types/pageTabs';

import './VacancyDetailError.scss';

interface VacancyDetailErrorProps {
    userFromCompany: boolean;
    statusCode: StatusCode;
    className?: string;
}

const VacancyDetailError: FC<VacancyDetailErrorProps> = ({
    userFromCompany,
    statusCode,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    if (userFromCompany && statusCode === StatusCode.notFound) {
        scrollToTop();

        navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));
    }

    return (
        <section className={`vacancy-detail-error ${className}`}>
            <Helmet>
                <title>{trans(`errorPage.page${statusCode}.title`)}</title>
                <meta name="description" content={trans(`errorPage.page${statusCode}.message`)} />
            </Helmet>

            <h1 className="vacancy-detail-error__title">
                {statusCode}
            </h1>

            <p className="vacancy-detail-error__description">
                {trans(`errorPage.page${statusCode}.message`)}
            </p>

            <LinkButton
                to={RoutePaths.home()}
                text={trans('errorPage.button.label')}
                className="vacancy-detail-error__button"
            />
        </section>
    );
};

export default VacancyDetailError;
