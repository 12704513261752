import React, { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

const LogoTalentzCompact: FC<SvgLogoProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 579.2 1000" className={`logo ${className}`}>
        <polygon points="518.9,619.6 334.8,753.8 404.2,778 361.6,900 132,900 442,60.5 442,0 18.5,0 18.5,100 293.9,100 0,900 0,1000 442,1000 442,999.7 442.3,1000 509.7,814.9 579.2,839.2" />
    </svg>
);

export default LogoTalentzCompact;
