import { trans } from '../../helpers/trans';
import { MatchProperty } from '../app_talentz/models/MatchProperty';

export default function formatCompensationOrSalary(min: MatchProperty, max: MatchProperty): string {
    const minValue = Number(min.value);
    const maxValue = Number(max.value);

    if (!maxValue) {
        return trans('vacancies.compensation', {
            compensation: minValue,
        });
    }

    return trans('vacancies.moneyRangePerMonth', {
        min: min.value,
        max: max.value,
    });
}
