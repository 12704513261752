import React, {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import BlockRadioList from '../../../../components/form/BlockRadioList';
import GreyFooter from '../../../../styledComponents/components/GreyFooter';
import { Heading } from '../../../../styledComponents/components/Typography';
import SlidingSmallCards from '../../compositions/SlidingSmallCards/SlidingSmallCards';
import { CardSlide } from './helpers/CardSlide';
import categorizeCardsByCategory from './helpers/categorizeCardsByCategory';
import { Styles } from './styles';

interface Filter {
    name: string;
    uuid: string;
}

interface CardsSliderBlockProps {
    title: string;
    filters: Filter[];
    cards: CardSlide[];
    defaultFilterUuid: string;
    className?: string;
}

const CardsSliderBlock: FC<CardsSliderBlockProps> = ({
    title,
    filters,
    cards,
    defaultFilterUuid,
    className = '',
}): ReactElement => {
    const [selectedCategory, setSelectedCategory] = useState<string>(defaultFilterUuid);

    const handleSelectedFilterChange = (event: FormEvent<HTMLInputElement>): void => {
        const { value } = event.currentTarget;
        setSelectedCategory(value);
    };

    const categorisedCards = categorizeCardsByCategory(cards);

    return (
        <Styles>
            <div className={`cards-slider-block ${className}`}>
                <GreyFooter height={[150, 150, 150, 150, 110]} mt={[-80, -80, -100, -100, -80]}>
                    <div className="cards-slider-block__header">
                        <Heading size="xl" mb="xl" className="cards-slider-block__header-title">{title}</Heading>
                        <div className="cards-slider-block__block-list-wrapper">
                            <BlockRadioList
                                selected={defaultFilterUuid}
                                labelClassName="cards-slider-block__block-list-label"
                                wrapperClassName="cards-slider-block__block-list-component-wrapper"
                                options={filters}
                                onChange={handleSelectedFilterChange}
                            />
                        </div>
                    </div>
                    <div className="cards-slider-block__sliders-wrapper">
                        {categorisedCards.map(cardsCollection => {
                            const wrapperClassName = cardsCollection[0].category === selectedCategory ? '' : 'cards-slider-block__slide--hidden';
                            return <SlidingSmallCards cards={cardsCollection} className={`cards-slider-block__slide ${wrapperClassName}`} />;
                        })}
                    </div>
                </GreyFooter>
            </div>
        </Styles>
    );
};

export default CardsSliderBlock;
