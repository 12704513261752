import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { SlideBackground, SlideImage } from '../TeamMember/style';

export const Carousel = styled.div(props => css`

    /* Slider */
    .slick-slider
    {
        position: relative;
    
        display: block;
        box-sizing: border-box;
    
        user-select: none;
    
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    
    .slick-list
    {
        position: relative;
    
        display: block;
    
        margin: 0;
        padding: 0;
    }
    .slick-list:focus
    {
        outline: none;
    }
    .slick-list.dragging
    {
        cursor: pointer;
        cursor: hand;
    }
    
    .slick-slider .slick-track,
    .slick-slider .slick-list
    {
        transform: translate3d(0, 0, 0);
    }
    
    .slick-track
    {
        position: relative;
        top: 0;
        left: 0;
        width: 99999999px !important;
        padding-top: 30px;
        overflow-y: hidden;
    
        display: block;
    }
    .slick-track:before,
    .slick-track:after
    {
        display: table;
    
        content: '';
    }
    .slick-track:after
    {
        clear: both;
    }
    .slick-loading .slick-track
    {
        visibility: hidden;
    }
    
    .slick-current {
        z-index: 1;
    
        ${SlideImage} {
            height: calc(100% + 30px);
        }
        
        ${SlideBackground}:before {
            transform: scale(3);
        }
    }
    
    .slick-slide
    {
        position: relative;
        display: none;
        float: left;
    
        height: 100%;
        width: calc(100vw - 40px);
        margin-right: 80px;
        min-height: 1px;
        
        @media ${props.theme.mediaQueries.tablet} {
            width: 300px;
        }
        
        div:focus {
            outline: none;
        }
    }
    [dir='rtl'] .slick-slide
    {
        float: right;
    }
    .slick-slide img
    {
        display: block;
    }
    .slick-slide.slick-loading img
    {
        display: none;
    }
    .slick-slide.dragging img
    {
        pointer-events: none;
    }
    .slick-initialized .slick-slide
    {
        display: block;
    }
    .slick-loading .slick-slide
    {
        visibility: hidden;
    }
    .slick-vertical .slick-slide
    {
        display: block;
    
        height: auto;
    
        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }
    
    .slick-dots {
        width: 100%;
        margin-top: 30px;
        text-align: center;
    
        li {
            display: inline-block;
            list-style: none;
            padding: 0;
            margin-right: 10px;
            
            :last-child {
                margin-right: 0;
            }
            
            &.slick-active button {
                background-color: ${props.theme.colors.secondary};
            }
            
            button {
                overflow: hidden;
                text-indent: -99999px;
                border: 0;
                background-color: ${props.theme.colors.muted};
                width: 12px;
                height: 12px;
                cursor: pointer;
                transition: background-color 0.35s ease-out;
                
                :hover {
                    transform: scale(1.2);
                }
            }
        }
    }
`);

export const SliderNavigation = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    right: 20px;
    transform: translateY(50%);
`;

export const SliderButton = styled.button(props => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
    background-color: ${props.theme.colors.secondary};
    transition: background-color 0.15s ease-out, transform 0.15s ease-out;
    
    svg { transition: transform 0.15s ease-out; }
    
    &:hover svg {
        transform: translateX(${props.prev ? -3 : 3}px);
    }
    
    &:active {
        transform: translateY(1px);
        box-shadow: ${props.theme.shadow('sm')};
    }
    
    &:active {
        transform: translateY(1px);
        box-shadow: ${props.theme.shadow('sm')};
    }
    
    ${space}
`);
