export const generateIdArray = (amount: number): number[] => {
    const idArray = [];

    for (let i = 0; i < amount; i += 1) {
        idArray[i] = i;
    }

    return idArray;
};

export const retrieveUniqueValues = <T>(array: T[]): T[] => (
    Array.from(new Set(array))
);

export const shuffleArray = <T>(arrayToShuffle: T[]): T[] => {
    const array = arrayToShuffle;
    let currentIndex = array.length;
    let temporaryValue = null;
    let randomIndex = null;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const divideArrayInColumns = <T>(arrayToDivide: T[], columnSize = 1): T[][] => {
    const dividedArray: T[][] = [];

    for (let i = 0; i < arrayToDivide.length; i += 1) {
        const j = Math.floor(i / columnSize);

        if (!dividedArray[j]) dividedArray[j] = [];

        dividedArray[j].push(arrayToDivide[i]);
    }

    return dividedArray;
};
