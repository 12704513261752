import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedPlaceInternshipVacancy } from '../../connectors';

const PlaceInternshipVacancyPage: FC = (): ReactElement => (
    <Page>
        <ConnectedPlaceInternshipVacancy />
    </Page>
);

export default PlaceInternshipVacancyPage;
