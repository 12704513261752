import React, { FC, ReactElement } from 'react';

import { Card, Icon } from '../../../../components';

import './CompanyUspCard.scss';

interface CompanyUspCardProps {
    image: {
        src: string;
        alt: string;
    };
    title: string;
    description: string;
    uspList: string[];
    className?: string;
}

const CompanyUspCard: FC<CompanyUspCardProps> = ({
    image,
    title,
    description,
    uspList,
    className = '',
}): ReactElement => (
    <Card className={`company-usp-card ${className}`}>
        <h2 className="company-usp-card__title">
            {title}
        </h2>

        <div className="company-usp-card__description-wrapper">
            <div>
                <img
                    src={image.src}
                    alt={image.alt}
                    className="company-usp-card__image"
                />
            </div>
            <p className="company-usp-card__description">
                {description}
            </p>
        </div>

        <ul className="company-usp-card__list">
            {uspList.map(usp => (
                <li key={usp} className="company-usp-card__item">
                    <div className="company-usp-card__icon-wrapper">
                        <Icon name="check" className="company-usp-card__icon" />
                    </div>

                    <p className="company-usp-card__label">
                        {usp}
                    </p>
                </li>
            ))}
        </ul>
    </Card>
);

export default CompanyUspCard;
