import { JobVacancyDetailApiParams, JobVacancyProperties } from '../app_talentz/models/JobVacancy';
import formatCompensationOrSalary from './formatCompensationOrSalary';

export default function transformApiCompensationToJobVacancyGrossSalary(
    grossSalary: JobVacancyDetailApiParams['gross_salary'],
): JobVacancyProperties['gross_salary'] | null {
    if (!grossSalary) {
        return null;
    }

    return {
        ...grossSalary,
        name: formatCompensationOrSalary(grossSalary.min, grossSalary.max),
    };
}
