import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { useScrollYPosition } from 'react-use-scroll-position';

import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Page } from '../../components';
import { FixedFooter, IconButton } from '../../compositions';
import {
    ConnectedInternshipTalentMatchingCard,
    ConnectedInternshipVacancyOverview,
    ConnectedInternshipVacancyOverviewFilter,
    ConnectedInternshipVacancyOverviewHeader,
} from '../../connectors';
import { setDarkMode } from '../../helpers/darkMode';
import { scrollIntoView } from '../../helpers/scroll';
import { trans } from '../../helpers/trans';
import useQuery from '../../hooks/useQuery';

import './VacancyOverview.scss';

const InternshipVacancyOverview: FC = (): ReactElement => {
    const currentQuery = useQuery();
    const initialPage = Number(currentQuery.get('page'));
    const scrollY = useScrollYPosition();

    const [currentPage, setCurrentPage] = useState<number>(initialPage || 1);
    const [isTalentMatching, setIsTalentMatching] = useState<boolean>(false);

    useEffect((): () => void => {
        setDarkMode(isTalentMatching);

        return (): void => setDarkMode(false);
    }, [isTalentMatching]);

    const headerRef = useRef<HTMLElement>(null);
    const [headerIntersection, setHeaderIntersection] = useState<number>(0);
    const [showFixedFooter, setShowFixedFooter] = useState<boolean>(false);

    useEffect((): void => {
        if (headerRef.current) {
            const { top, height } = headerRef.current.getBoundingClientRect();

            setHeaderIntersection(top + height);
        }
    }, [headerRef]);

    useEffect((): void => {
        if (scrollY <= headerIntersection && showFixedFooter) {
            setShowFixedFooter(false);
        }
        if (scrollY > headerIntersection && !showFixedFooter) {
            setShowFixedFooter(true);
        }
    }, [scrollY, headerIntersection, showFixedFooter]);

    const handleScrollToFilters = (): void => {
        scrollIntoView(headerRef);
    };

    const internshipVacancyOverviewClassNames = classNames('vacancy-overview-page', {
        'vacancy-overview-page--is-talent-matching': isTalentMatching,
    });

    return (
        <Page className={internshipVacancyOverviewClassNames}>
            <Helmet>
                <title>{trans('vacancies.overview.internship.title')}</title>
                <meta property="og:title" content={createMetadataTitle(trans('vacancies.overview.internship.title'))} />
                <meta property="og:description" content={trans('vacancies.overview.internship.meta.description')} />
                <meta name="description" content={trans('vacancies.overview.internship.meta.description')} />
            </Helmet>

            <header ref={headerRef} className="vacancy-overview-page__header">
                <div className="vacancy-overview-page__wrapper">
                    <ConnectedInternshipVacancyOverviewFilter currentPage={currentPage} />
                </div>
            </header>

            <main className="vacancy-overview-page__main">
                <div className="vacancy-overview-page__wrapper">
                    <ConnectedInternshipVacancyOverviewHeader
                        isTalentMatching={isTalentMatching}
                        talentMatchingToggle={setIsTalentMatching}
                        className="vacancy-overview-page__title-wrapper"
                    />

                    <div className="vacancy-overview-page__result-wrapper">
                        <div className="vacancy-overview-page__talent-matching-card-wrapper">
                            <ConnectedInternshipTalentMatchingCard
                                isTalentMatching={isTalentMatching}
                                className="vacancy-overview-page__talent-matching-card"
                            />
                        </div>

                        <ConnectedInternshipVacancyOverview
                            isTalentMatching={isTalentMatching}
                            onPaginationChange={setCurrentPage}
                            className="vacancy-overview-page__result-list"
                        />
                    </div>
                </div>

                {showFixedFooter && (
                    <FixedFooter onlySmallScreen>
                        <IconButton
                            icon="filter"
                            text={trans('actions.scrollTo.filters')}
                            onClick={handleScrollToFilters}
                            className="vacancy-overview-page__filter-button"
                        />
                    </FixedFooter>
                )}
            </main>
        </Page>
    );
};

export default InternshipVacancyOverview;
