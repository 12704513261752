import { authorizedFetch, TokenEnvironmentType } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { Notification, NotificationsResource, transformApiDataToNotification } from '../models/Notifications';

export const getNotificationsApiCall = async (): Promise<FetchResult<Notification[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/me/notifications`, {
            tokenEnvironmentType: TokenEnvironmentType.Legacy,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        // TODO: Convert to JapiDocument (with isResourceCollectionDocument) once the notifications call supports JSON API
        const doc: NotificationsResource = await response.json();

        const notifications = doc.data.map(transformApiDataToNotification);
        const sortedNotifications = notifications.sort((notification, comparisonNotification) => {
            const notificationDate = new Date(notification.createdAt).getTime();
            const comparisonNotificationDate = new Date(comparisonNotification.createdAt).getTime();

            return comparisonNotificationDate - notificationDate;
        });

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedNotifications,
        };
    } catch (error) {
        console.error('[getNotificationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteNotificationApiCall = async (notificationId: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/me/notifications/${notificationId}`, {
            method: 'DELETE',
            tokenEnvironmentType: TokenEnvironmentType.Legacy,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteNotificationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const updateNotificationApiCall = async (notificationId: string, newState: Partial<Notification>): Promise<FetchResult<Notification, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/me/notifications/${notificationId}`, {
            method: 'POST',
            tokenEnvironmentType: TokenEnvironmentType.Legacy,
            body: JSON.stringify(newState),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc = await response.json();

        const notification = transformApiDataToNotification(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: notification,
        };
    } catch (error) {
        console.error('[updateNotificationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const updateAllNotificationsApiCall = async (newState: Partial<Notification>): Promise<FetchResult<Notification[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/me/notifications/all`, {
            method: 'POST',
            tokenEnvironmentType: TokenEnvironmentType.Legacy,
            body: JSON.stringify(newState),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: NotificationsResource = await response.json();

        const notifications = doc.data.map(transformApiDataToNotification);
        const sortedNotifications = notifications.sort((notification, comparisonNotification) => {
            const notificationDate = new Date(notification.createdAt).getTime();
            const comparisonNotificationDate = new Date(comparisonNotification.createdAt).getTime();

            return comparisonNotificationDate - notificationDate;
        });

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedNotifications,
        };
    } catch (error) {
        console.error('[updateAllNotificationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
