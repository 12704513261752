import React, { FC, ReactElement, useState } from 'react';

import {
    ConfirmModal,
    IconButton,
    Modal,
    ModalContent,
} from '../../../../../compositions';
import { formatDate, isFutureDate } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { SchoolExperience, SchoolExperienceFormData } from '../../../../../models/NewSchoolExperience';
import { SchoolExperienceForm } from '../../../..';
import { SchoolExperienceFormOptions } from '../../../../@forms/SchoolExperienceForm/SchoolExperienceForm';
import { getEducationLevelDescription } from '../../helpers';

import './SchoolExperienceItem.scss';

interface SchoolExperienceItemProps extends SchoolExperienceFormOptions {
    isEditable?: boolean;
    schoolExperience: SchoolExperience;
    onEdit: (schoolExperienceFormData: SchoolExperienceFormData) => void;
    onDelete: (schoolExperience: SchoolExperience) => void;
    className?: string;
}

const SchoolExperienceItem: FC<SchoolExperienceItemProps> = ({
    isEditable = false,
    schoolExperience,
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions,
    fieldOfStudyOptions,
    schoolOptions,
    onEdit,
    onDelete,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const openDeleteModal = (): void => setDeleteModalIsOpen(true);
    const closeDeleteModal = (): void => setDeleteModalIsOpen(false);

    const handleEditSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData): void => {
        onEdit(schoolExperienceFormData);
        closeEditModal();
    };

    const handleDeleteSchoolExperience = (): void => {
        onDelete(schoolExperience);
        closeDeleteModal();
    };

    return (
        <li className={`school-experience-item ${className}`}>
            <div className="school-experience-item__content-wrapper">
                {(schoolExperience.fieldOfStudy && schoolExperience.school) && (
                    <h3 className="school-experience-item__field-of-study">
                        {schoolExperience.fieldOfStudy}
                        <span className="school-experience-item__school">
                            {trans('userProfile.schoolExperiences.atSchool', {
                                school: schoolExperience.school,
                            })}
                        </span>
                    </h3>
                )}
                {(schoolExperience.school && !schoolExperience.fieldOfStudy) && (
                    <h3 className="school-experience-item__field-of-study">
                        {schoolExperience.school}
                    </h3>
                )}
                <p className="school-experience-item__dates">
                    {trans('userProfile.schoolExperiences.dates', {
                        startDate: formatDate(schoolExperience.startDate, 'MMMM yyyy'),
                        endDate: isFutureDate(schoolExperience.endDate)
                            ? trans('basic.presentDay')
                            : formatDate(schoolExperience.endDate, 'MMMM yyyy'),
                    })}
                </p>
                <p className="school-experience-item__description">
                    {getEducationLevelDescription(schoolExperience.educationLevel, schoolExperience.learningPath)}
                </p>
            </div>

            {isEditable && (
                <div className="school-experience-item__controls-wrapper">
                    <IconButton
                        icon="pencil"
                        text={trans('actions.edit')}
                        hideLabel
                        onClick={openEditModal}
                        className="school-experience-item__control-button"
                    />

                    <IconButton
                        icon="delete"
                        text={trans('actions.delete')}
                        hideLabel
                        onClick={openDeleteModal}
                        className="school-experience-item__control-button"
                    />
                </div>
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('forms.new.schoolExperience.title.edit')}>
                        <SchoolExperienceForm
                            schoolExperience={schoolExperience}
                            educationLevelOptions={educationLevelOptions}
                            educationSubLevelOptions={educationSubLevelOptions}
                            learningPathOptions={learningPathOptions}
                            fieldOfStudyOptions={fieldOfStudyOptions}
                            schoolOptions={schoolOptions}
                            onSubmit={handleEditSchoolExperience}
                            onCancel={closeEditModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {deleteModalIsOpen && (
                <Modal onClose={closeDeleteModal}>
                    <ConfirmModal
                        isNegative
                        title={trans('forms.new.schoolExperience.title.delete')}
                        description={trans('forms.new.schoolExperience.delete.description', {
                            school: schoolExperience.school,
                        })}
                        onCancel={closeDeleteModal}
                        onConfirm={handleDeleteSchoolExperience}
                    />
                </Modal>
            )}
        </li>
    );
};

export default SchoolExperienceItem;
