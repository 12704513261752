import { darken } from 'polished';
// @ts-ignore
import styled, { css } from 'styled-components';
import {
    alignItems,
    display,
    fontSize,
    fontWeight,
    height,
    letterSpacing,
    maxWidth,
    minHeight,
    space,
    textAlign,
    width,
} from 'styled-system';

const fontAttributes = [
    space,
    display,
    fontSize,
    fontWeight,
    letterSpacing,
    width,
    height,
    minHeight,
    textAlign,
    maxWidth,
    alignItems,
];

export const Heading = styled('h1')((props: any) => css`
    ${props.theme.fonts[props.font || 'heading']};
    font-size: ${props.theme.fontSizes[props.size || 'lg']};
    line-height: ${props.lineHeight || '1.2em'};
    color: ${props.theme.colors[props.color || 'primary']};
    margin-bottom: ${props.standAlone ? '0.5em' : '1em'};
    font-weight: ${props.fontWeight || 800};
    text-transform: ${props.textTransform || 'none'};
    display: ${props.display || 'block'};
    
    ${props.stripped && css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    `}
    
    ${props.uppercase && css`
        text-transform: uppercase;
        letter-spacing: 0.07em;
    `}
    
    ${props.fontType && props.theme.fonts[props.fontType]};
`, fontAttributes);

const paragraphTemplate = (props: any) => css`    
    font-size: ${props.theme.fontSizes[props.size || 'rg']};
    line-height: ${props.lineHeight || '1.5em'};
    margin-bottom: 1em;
    color: ${props.theme.colors[props.color || 'text']};
    text-transform: ${props.textTransform || 'none'};
    
    ${props.fontType && props.theme.fonts[props.fontType]};
    
    b, strong {
        ${props.theme.fonts.bold}
    }
    
    i {
        ${props.theme.fonts.regularItalic}
    }
  
    &:last-child {
        ${space};
        margin-bottom: 0;
    }
`;

export const Paragraph = styled('p')((props: any) => css`
    ${paragraphTemplate(props)}
    ${space};
    ${props.whiteSpace && css`
        white-space: ${props.whiteSpace}
    `};
`, fontAttributes);

export const Span = styled('span')((props: any) => css`
    display: inline-block;
    font-size: ${props.theme.fontSizes[props.size || 'rg']};
    line-height: ${props.lineHeight || '1.4em'};
    color: ${props.theme.colors[props.color || 'text']};
    
    ${props.fontType && props.theme.fonts[props.fontType]};
    
    ${props.withIcon && css`
        display: flex;
        align-items: center;
        
        svg {
            margin-right: ${props.theme.space.xs}; 
        }
    `}
    
    ${fontAttributes};
    ${space};
`);

export const Link = styled('a')((props: any) => css`
    color: ${props.theme.colors[props.color || 'primary']};
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        color: ${darken(0.1, props.theme.colors[props.color || 'primary'])};
        text-decoration: underline;
        
        ${props.textDecoration && css`
            text-decoration: ${props.textDecoration};
        `}
    }
    
    ${props.hasIcon && css`
        display: flex;
        align-items: center;
        width: 100%;
     
         svg {
              width: ${props.theme.space.md};
         }
    `}
    
    ${props.textDecoration && css`
        text-decoration: ${props.textDecoration};
    `}
    
`, fontAttributes);

export const TextHolder = styled('div')((props: any) => css`
    font-size: 1em;
    line-height: ${props.lh || '1.4em'};
    
    p {
        ${paragraphTemplate(props)}
    }
    
    .mb-0 {
        margin-bottom: 0;
    }
    
    ul, ol {
        padding-left: ${props.theme.space.sm};
        margin-bottom: ${props.theme.space.lg};
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    li {
        margin-bottom: 0;
        padding-left: 10px;
        ${props.listColor && css`
            color: ${props.theme.colors[props.listColor] || props.theme.colors['text']};
        `};
        letter-spacing: 0.5px;
        line-height: 1.7em;
    }
    
`, fontAttributes, space);

export const FakeLink = styled.button((props: any) => css`
    font-family: inherit;
    font-size: inherit;
    color: ${props.theme.colors.link};
    text-decoration: none;
    border: none;
    background: none;
    
    &:hover,
    &:focus {
        color: ${props.theme.colors.primaryDarker};
        cursor: pointer;
    }
    
    ${fontAttributes};
    ${space};
`);
