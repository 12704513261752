import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { Chat } from '../../../../models/Chat';
import { ChatMessage } from '../../../../models/ChatMessages';
import { ChatReport, ChatReportFormData, UndoChatReportFormData } from '../../../../models/ChatReport';
import { ChatMessageForm } from '../../..';
import { ChatEventList, ChatRoomHeader } from '..';

import './ChatRoom.scss';

interface ChatRoomProps {
    isLoading: boolean;
    chatReportsIsLoading: boolean;
    isReconnecting: boolean;
    isCandidate: boolean;
    userUuid: string;
    activeChat: Chat;
    chatMessages: ChatMessage[];
    chatReports: ChatReport[];
    onChatSelectorClick: () => void;
    onSendMessage: (message: string) => void;
    onReportChat: (chatUuid: string, chatReportFormData: ChatReportFormData) => void;
    onUndoChatReport: (undoChatReportFormData: UndoChatReportFormData) => void;
    className?: string;
}

const ChatRoom: FC<ChatRoomProps> = ({
    isLoading,
    chatReportsIsLoading,
    isReconnecting,
    isCandidate,
    userUuid,
    activeChat,
    chatMessages,
    chatReports,
    onChatSelectorClick,
    onSendMessage,
    onReportChat,
    onUndoChatReport,
    className = '',
}): ReactElement => {
    const { isReported } = activeChat;

    const chatEventListClassNames = classNames('chat-room__event-list', {
        'chat-room__event-list--is-reconnecting': isReconnecting,
    });

    const reconnectWrapperClassNames = classNames('chat-room__reconnect-wrapper', {
        'chat-room__reconnect-wrapper--is-visible': isReconnecting,
    });

    const handleResetManually = (): void => {
        if (window?.location) window.location.reload();
    };

    return (
        <div className={`chat-room ${className}`}>
            <ChatRoomHeader
                isLoading={isLoading}
                chatReportsIsLoading={chatReportsIsLoading}
                isCandidate={isCandidate}
                activeChat={activeChat}
                chatReports={chatReports}
                onChatSelectorClick={onChatSelectorClick}
                onReportChat={onReportChat}
                onUndoChatReport={onUndoChatReport}
            />

            <ChatEventList
                isLoading={isLoading}
                userUuid={userUuid}
                messages={chatMessages}
                className={chatEventListClassNames}
            />

            <div className={reconnectWrapperClassNames}>
                <div className="chat-room__reconnect-message">
                    {trans('containers.messageOverview.reconnect.label')}

                    <Button
                        text={trans('containers.messageOverview.reconnect.resetManually')}
                        onClick={handleResetManually}
                        className="chat-room__reconnect-button"
                    />
                </div>
            </div>

            <footer className="chat-room__footer">
                <ChatMessageForm
                    key={activeChat.uuid}
                    isDisabled={isLoading || isReconnecting || isReported}
                    onSubmit={onSendMessage}
                    className="chat-room__message-form"
                />

                <div className="chat-room__disclaimer-wrapper">
                    <Icon name="circle-exclamation" className="chat-room__disclaimer-icon" />

                    {isReported ? (
                        <p className="chat-room__disclaimer-text">
                            {trans('containers.messageOverview.disclaimer.reported')}
                        </p>
                    ) : (
                        <p className="chat-room__disclaimer-text">
                            {isCandidate
                                ? trans('containers.messageOverview.disclaimer.candidate')
                                : trans('containers.messageOverview.disclaimer.company')}
                        </p>
                    )}
                </div>
            </footer>
        </div>
    );
};

export default ChatRoom;
