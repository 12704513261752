import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { thunk } from 'redux-thunk';

import { isProduction } from '../helpers';
import reducers, { LegacyReducers, Reducers } from './reducers';

export type TypedDispatch = ThunkDispatch<Reducers, unknown, UnknownAction>;
export type LegacyDispatch = ThunkDispatch<LegacyReducers, unknown, any>;
export type ReducerGetter = () => Reducers;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useLegacyDispatch = () => useDispatch<LegacyDispatch>();

export const useTypedSelector: TypedUseSelectorHook<Reducers> = useSelector;
export const useLegacySelector: TypedUseSelectorHook<LegacyReducers> = useSelector;

export const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk),
    devTools: !isProduction,
});
