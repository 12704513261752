import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Button, SafeHtml } from '../../../../../components';
import {
    DetailCard,
    Modal,
    ModalContent,
    RadioList,
    SubmitButton,
} from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import {
    ApplicationAppointment,
    ApplicationAppointmentFormData,
    ApplicationAppointmentType,
    transformApplicationAppointmentToFormOption,
} from '../../../../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../../../../models/ApplicationInvites';
import { ApplicationRejectionFormData } from '../../../../../models/ApplicationRejections';
import { Application } from '../../../../../models/Applications';
import { ApplicationRejectionForm, AppointmentForm } from '../../../..';

import './ApplicationStatusInvited.scss';

interface ApplicationStatusInvitedProps {
    isLoading: boolean;
    isCandidate?: boolean;
    application: Application;
    activeInvite: ApplicationInvite;
    onAcceptInvite: (appointment: ApplicationAppointment, inviteUuid: string) => void;
    onChangeInvite: (applicationUuid: string, formData: ApplicationAppointmentFormData) => void;
    onDeclineInvite?: (applicationInvite: ApplicationInvite) => void;
    onRejectApplication?: (applicationUuid: string, formData: ApplicationRejectionFormData) => void;
    className?: string;
}

const ApplicationStatusInvited: FC<ApplicationStatusInvitedProps> = ({
    isLoading,
    isCandidate,
    application,
    activeInvite,
    onAcceptInvite,
    onChangeInvite,
    onDeclineInvite,
    onRejectApplication,
    className = '',
}): ReactElement => {
    const [changeAppointmentModalIsOpen, setChangeAppointmentModalIsOpen] = useState<boolean>(false);
    const [rejectApplicationModalIsOpen, setRejectApplicationModalIsOpen] = useState<boolean>(false);
    const [selectedAppointment, setSelectedAppointment] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    const openChangeAppointmentModal = (): void => setChangeAppointmentModalIsOpen(true);
    const closeChangeAppointmentModal = (): void => setChangeAppointmentModalIsOpen(false);

    const openRejectApplicationModal = (): void => setRejectApplicationModalIsOpen(true);
    const closeRejectApplicationModal = (): void => setRejectApplicationModalIsOpen(false);

    useEffect((): void => {
        const firstOption = activeInvite.appointments.length > 0
            ? activeInvite.appointments[0]
            : undefined;

        if (firstOption) {
            setSelectedAppointment(firstOption.uuid);
        }
    }, [activeInvite]);

    const description = isCandidate
        ? trans('containers.applicationStatusCard.invited.description.candidate', {
            company: application.vacancy.companyName,
        })
        : trans('containers.applicationStatusCard.invited.description.company');

    const handleAcceptInvite = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const appointment = activeInvite.appointments.find(appointment => appointment.uuid === selectedAppointment);

        if (appointment) {
            onAcceptInvite(appointment, activeInvite.uuid);
        }
    };

    const handleChangeInvite = (formData: ApplicationAppointmentFormData): void => {
        closeChangeAppointmentModal();
        onChangeInvite(application.uuid, formData);
    };

    const handleDeclineInvite = (): void => {
        if (onDeclineInvite) {
            onDeclineInvite(activeInvite);
        }
    };

    const handleRejectApplication = (formData: ApplicationRejectionFormData): void => {
        if (onRejectApplication) {
            onRejectApplication(application.uuid, formData);
        }
    };

    return (
        <DetailCard
            title={trans('containers.applicationStatusCard.invited.title')}
            className={`application-status-invited ${className}`}
        >
            <p className="application-status-invited__description">
                {description}
            </p>

            {activeInvite.message ? (
                <SafeHtml
                    html={activeInvite.message}
                    className="application-status-invited__message"
                />
            ) : (
                <p className="application-status-invited__no-message">
                    {trans('containers.applicationStatusCard.invited.noMessage')}
                </p>
            )}

            {activeInvite.appointments.length > 0 && (
                <form ref={formRef} onSubmit={handleAcceptInvite}>
                    <p className="application-status-invited__appointment-description">
                        {activeInvite.appointments.length === 1
                            ? trans('containers.applicationStatusCard.invited.optionDescription.single')
                            : trans('containers.applicationStatusCard.invited.optionDescription.multiple')}
                    </p>

                    <RadioList
                        name="appointment"
                        label={trans('containers.applicationStatusCard.invited.input.selectAppointment')}
                        hideLabel
                        options={activeInvite.appointments.map(transformApplicationAppointmentToFormOption)}
                        value={selectedAppointment}
                        onChange={setSelectedAppointment}
                        className="application-status-invited__appointment-input"
                    />

                    <h3 className="application-status-invited__appointment-type-title">
                        {trans('containers.applicationStatusCard.invited.appointmentType.title')}
                    </h3>

                    <p className="application-status-invited__appointment-type-description">
                        {activeInvite.appointments[0].type === ApplicationAppointmentType.physical ? (
                            trans('containers.applicationStatusCard.invited.appointmentType.physical', {
                                company: application.vacancy.companyName,
                            })
                        ) : (
                            trans('containers.applicationStatusCard.invited.appointmentType.jitsi', {
                                company: application.vacancy.companyName,
                            })
                        )}
                    </p>

                    <div className="application-status-invited__button-wrapper">
                        <SubmitButton
                            isLoading={isLoading}
                            text={trans('containers.applicationStatusCard.invited.button.accept')}
                            className="application-status-invited__submit-button"
                        />

                        <Button
                            text={trans('containers.applicationStatusCard.invited.button.change')}
                            disabled={isLoading}
                            onClick={openChangeAppointmentModal}
                            className="application-status-invited__button"
                        />

                        <p className="application-status-invited__or-divider">
                            {trans('basic.or')}
                        </p>

                        {isCandidate ? (
                            <Button
                                text={trans('containers.applicationStatusCard.invited.button.declineInvite')}
                                disabled={isLoading}
                                onClick={handleDeclineInvite}
                                className="application-status-invited__button"
                            />
                        ) : (
                            <Button
                                text={trans('containers.applicationStatusCard.invited.button.rejectApplication')}
                                disabled={isLoading}
                                onClick={openRejectApplicationModal}
                                className="application-status-invited__button"
                            />
                        )}
                    </div>
                </form>
            )}

            {changeAppointmentModalIsOpen && (
                <Modal onClose={closeChangeAppointmentModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.invited.changeAppointmentModal.title')}>
                        <AppointmentForm
                            isCandidate={isCandidate}
                            onSubmit={handleChangeInvite}
                            onCancel={closeChangeAppointmentModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {rejectApplicationModalIsOpen && (
                <Modal onClose={closeRejectApplicationModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.invited.rejectApplicationModal.title')}>
                        <ApplicationRejectionForm
                            onSubmit={handleRejectApplication}
                            onCancel={closeRejectApplicationModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default ApplicationStatusInvited;
