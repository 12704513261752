import React from 'react';

import { trans } from '../../../../../../../helpers/trans';
import Button from '../../../../../../components/Button';
import Container from '../../../../../../styledComponents/components/Container';
import { Col, Row } from '../../../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../../../styledComponents/components/Typography';
import { ContactLink, GreyBlock, Image } from './style';

const TeamHeader = () => (
    <Container pt={['lg', 'lg', 'lg', 'lg', 'xl']}>
        <Container maxWidth="1200px" centered>
            <Row flexDirection={['column', 'column', 'column', 'column', 'row']} alignItems="center">
                <Col pr={[0, 0, 0, 0, 'xxl']} width={[1, 1, 1, 1, 1 / 2]} mb={['xl', 'xl', 'xl', 'xl', 0]}>
                    <Heading size="xl">
                        {trans('infoPage.team.header.title')}
                    </Heading>
                    <Paragraph mb="xl" size="sm">
                        {trans('infoPage.team.header.text')}
                    </Paragraph>
                    <ContactLink href="mailto:info@talentz.nl">
                        <Button>{trans('infoPage.team.header.callToAction')}</Button>
                    </ContactLink>
                </Col>
                <Col width={[1, 1, 1, 1, 1 / 2]}>
                    <Image src="images/team-header.jpg" alt={trans('infoPage.team.header.imageAlt')} />
                </Col>
            </Row>
        </Container>

        <Row justifyContent="flex-end">
            <GreyBlock />
        </Row>
    </Container>
);

export default TeamHeader;
