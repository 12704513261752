import { fulltimeThreshold } from '../../../../../../constants';
import { trans } from '../../../../../../helpers/trans';
import { MinMaxValue } from '../../../../../../types';

export const retrieveEmploymentType = (employmentPreferences: MinMaxValue): string => {
    const { min, max } = employmentPreferences;

    if (max === 0) return '';

    if (min === max) {
        return min >= fulltimeThreshold
            ? trans('compositions.candidateCard.property.employmentPreference.specific.fulltime', { hours: max })
            : trans('compositions.candidateCard.property.employmentPreference.specific.parttime', { hours: max });
    }

    return min >= fulltimeThreshold
        ? trans('compositions.candidateCard.property.employmentPreference.fulltime', { ...employmentPreferences })
        : trans('compositions.candidateCard.property.employmentPreference.parttime', { ...employmentPreferences });
};

export const retrieveDriversLicenses = (driversLicenses: string[]): string[] => driversLicenses.map(license => {
    const [type] = license.split(' - ');

    return type
        ? trans('compositions.candidateCard.property.driversLicense', { type })
        : '';
});
