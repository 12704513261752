import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../../helpers/trans';
import arrayEquals from '../../../helpers/arrayEquals';
import {
    Error,
    Holder,
    Label,
    OptionalLabel,
    Requirement,
    Text,
} from './style';

class TextArea extends Component {
    constructor() {
        super();

        this.state = {
            showErrors: false,
        };
    }

    componentDidUpdate(nextProps) {
        (!arrayEquals(nextProps.errors, this.props.errors) && !this.state.showErrors)
        && this.showErrors();
    }

    showErrors() {
        this.setState({ showErrors: true });
    }

    onChange = e => {
        this.props.onChange(e);
        this.setState({ showErrors: false });
    };

    render() {
        const {
            label,
            name,
            placeholder,
            onBlur,
            disabled,
            errors,
            requirement,
            required,
            optional,
            id,
        } = this.props;

        const defaultValue = this.props.defaultValue.replace(/<br\s?\/?>/g, '\n');
        const value = this.props.value ? this.props.value.replace(/<br\s?\/?>/g, '\n') : null;

        const { showErrors } = this.state;

        return (
            <div>
                {label && (
                    <Label>
                        {label}{required && '*'}
                        {optional && <OptionalLabel>{trans('forms.optional')}</OptionalLabel>}
                    </Label>
                )}
                <Holder>
                    <Text
                        id={id || name}
                        data-hj-suppress
                        hasError={!!errors && showErrors}
                        onChange={this.onChange}
                        value={value}
                        disabled={disabled}
                        {...{
                            name,
                            placeholder,
                            onBlur,
                            defaultValue,
                        }}
                    />
                </Holder>
                {requirement && (
                    <Requirement>
                        {requirement}
                    </Requirement>
                )}
                <Error errors={!!errors && showErrors}>
                    {(!!errors && showErrors) && <span key={`${name} - ${errors[0]}`}>{errors[0]}<br /></span>}
                </Error>
            </div>
        );
    }
}

TextArea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.array,
    icon: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    requirement: PropTypes.string,
    onBlur: PropTypes.func,
    optional: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    id: PropTypes.string,
};

TextArea.defaultProps = {
    label: '',
    icon: '',
    disabled: false,
    errors: null,
    defaultValue: '',
    value: null,
    placeholder: '',
    requirement: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBlur: () => {},
    optional: false,
    required: false,
    id: null,
};

export default TextArea;
