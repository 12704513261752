import React, { FC, ReactElement, useState } from 'react';

import { Card, ProgressRefButton, Skeleton } from '../../../components';
import {
    IconButton,
    Modal,
    ModalContent,
    Tag,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { CatchphraseFormData } from '../../../models/Catchphrase';
import { CatchphraseForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';

import './CatchphraseCard.scss';

interface CatchphraseCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    catchphrase?: string;
    onEdit?: (catchphraseFormData: CatchphraseFormData) => void;
    className?: string;
}

const CatchphraseCard: FC<CatchphraseCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    catchphrase = '',
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditCatchphrase = (catchphraseFormData: CatchphraseFormData): void => {
        if (onEdit) {
            onEdit(catchphraseFormData);
        }

        closeEditModal();
    };

    const handleDeleteCatchphrase = (): void => {
        if (onEdit) {
            onEdit({ catchphrase: '' });
        }
    };

    const nullState = isEditable
        ? trans('candidateProfile.catchphrase.nullState.candidate')
        : trans('candidateProfile.catchphrase.nullState.company');

    return (
        <Card className={`catchphrase-card ${className}`}>
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            <div className="catchphrase-card__header">
                {!isLoading && (
                    <Tag
                        isActive
                        text={trans('candidateProfile.tag.jobSeeker')}
                        className="catchphrase-card__tag"
                    />
                )}

                {isEditable && catchphrase.length > 0 && (
                    <div className="catchphrase-card__controls-wrapper">
                        <IconButton
                            icon="pencil"
                            text={trans('actions.edit')}
                            hideLabel
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="catchphrase-card__edit-button"
                        />

                        <IconButton
                            icon="delete"
                            text={trans('actions.delete')}
                            hideLabel
                            disabled={isLoading}
                            onClick={handleDeleteCatchphrase}
                            className="catchphrase-card__edit-button"
                        />
                    </div>
                )}
            </div>

            {isLoading && (
                <div className="catchphrase-card__skeleton-wrapper">
                    <Skeleton className="catchphrase-card__skeleton" />
                    <Skeleton className="catchphrase-card__skeleton" />
                    <Skeleton className="catchphrase-card__skeleton" />
                </div>
            )}

            {!isLoading && catchphrase.length === 0 && (
                <div className="candidate-about-card__null-state">
                    <p>{nullState}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('actions.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="candidate-about-card__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && catchphrase.length > 0 && (
                <blockquote className="catchphrase-card__catchphrase">
                    {catchphrase}
                </blockquote>
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.catchphrase.modal.title')}>
                        <CatchphraseForm
                            catchphrase={catchphrase}
                            onCancel={closeEditModal}
                            onSubmit={handleEditCatchphrase}
                        />
                    </ModalContent>
                </Modal>
            )}
        </Card>
    );
};

export default CatchphraseCard;
