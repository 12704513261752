import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';
import DatePicker from 'react-date-picker';

import { Icon, InputLabel } from '../../../components';
import { trans } from '../../../helpers/trans';

import './DateInput.scss';

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('../../../../node_modules/react-date-picker/dist/DatePicker.css');
    // eslint-disable-next-line global-require
    require('../../../../node_modules/react-calendar/dist/Calendar.css');
}

interface DateInputProps {
    label?: string | null;
    errors?: string | string[] | null;
    value: Date | null;
    onChange: (date: Date | null) => void;
    disabled?: boolean;
    required?: boolean;
    optional?: boolean;
    minDate?: Date;
    maxDate?: Date;
    disableCalendarPopup?: boolean;
    className?: string;
    labelClassName?: string;
}

const DateInput: FC<DateInputProps> = ({
    label,
    errors = [],
    value,
    onChange,
    disableCalendarPopup,
    disabled,
    required,
    optional,
    minDate,
    maxDate,
    className = '',
    labelClassName = '',
}): ReactElement => {
    const hasError = !!(errors && errors.length);
    const inputClassName = classnames('date-input__input', {
        'date-input__input--error': hasError,
    });

    const handleDateChange = (date: any) => {
        // The typing of DatePicker is finiky, since multiple date pickers are possible
        // But since we don't support that we add this to satisfy TypeScript
        onChange(Array.isArray(date) ? date[0] : date);
    };

    return (
        <div className={`date-input ${className}`}>
            <span className={`date-input__wrapper ${hasError ? 'date-input__wrapper--has-error' : ''}`}>
                {label && (
                    <InputLabel
                        text={label}
                        required={required}
                        optional={optional}
                        className={labelClassName}
                    />
                )}

                <DatePicker
                    className={inputClassName}
                    calendarClassName="date-input__input--is-open"
                    onChange={handleDateChange}
                    locale="nl"
                    value={value !== null ? value : undefined}
                    required={required}
                    clearIcon={<Icon name="cross" className="date-input__icon" />}
                    calendarIcon={<Icon name="calendar" className="date-input__icon date-input__icon--calendar" />}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                    dayPlaceholder={trans('dates.dayFormat')}
                    monthPlaceholder={trans('dates.monthFormat')}
                    yearPlaceholder={trans('dates.yearFormat')}
                    // The type def from DatePicker is incorrect. This property does exist
                    // @ts-ignore
                    disableCalendar={disableCalendarPopup}
                />
            </span>

            <div className={`date-input__errors${errors?.length === 0 ? '--hidden' : ''}`}>
                <span key={`${errors?.[0]}`}>{errors?.[0]}<br /></span>
            </div>
        </div>
    );
};

export default DateInput;
