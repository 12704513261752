/* eslint-disable camelcase */
import { MatchProperty } from './MatchProperty';

export enum LearningPath {
    Other = 'learning_path_different'
}

export enum EducationLevel {
    Mbo = 'mbo',
    Hbo = 'hoger-onderwijs'
}

export interface School {
    uuid: string;
    name: string;
    customer_number: number;
    fqdn: string;
    manual: boolean;
}

export interface SchoolExperienceApiResponseParams {
    uuid?: string;
    person_id?: string;
    school_id: string;
    school_name?: string;
    start_date: string;
    end_date?: string;
    education_level_id: string;
    education_level_name?: string;
    field_of_study_id: string;
    field_of_study_name?: string;
    learning_path_id?: string;
    school: School;
    grade: MatchProperty;
    customer_number: number;
    education_level: MatchProperty;
    field_of_study: MatchProperty;
    learning_path: MatchProperty;
}

export interface SchoolExperienceApiRequestParams {
    uuid?: string;
    school_id: string;
    school_name?: string;
    education_level_id: string;
    education_level_name?: string;
    field_of_study_id: string;
    field_of_study_name?: string;
    learning_path_id?: string;
    grade_id?: string;
    start_date: string;
    end_date?: string;
}

export interface SchoolExperience {
    uuid?: string;
    person_id?: string;
    start_date: string;
    end_date?: string;
    school_id: string;
    school_name?: string;
    education_level_id: string;
    education_level_name?: string;
    field_of_study_id: string;
    field_of_study_name?: string;
    learning_path_id?: string;
    customer_number?: number;
    grade_id?: string;
    school: School;
    education_level?: MatchProperty;
    field_of_study?: MatchProperty;
    learning_path?: MatchProperty;
    grade?: MatchProperty;
}

export function transformToSchoolExperience(params: SchoolExperienceApiResponseParams): SchoolExperience {
    return {
        ...params,
        learning_path_id: params.learning_path_id ? params.learning_path_id : LearningPath.Other,
    };
}
/* eslint-enable camelcase */
