import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Logo } from '../../components';

import './LLOCandidateLabel.scss';

interface LLOCandidateLabelProps {
    isSmall?: boolean;
    className?: string;
}

const LLOCandidateLabel: FC<LLOCandidateLabelProps> = ({
    isSmall,
    className = '',
}): ReactElement => {
    const lloCandidateLabelClassNames = classNames('llo-candidate-label', {
        'llo-candidate-label--is-small': isSmall,
    }, className);

    return (
        <div className={lloCandidateLabelClassNames}>
            <Logo name="llo-compact" className="llo-candidate-label__logo" />
        </div>
    );
};

export default LLOCandidateLabel;
