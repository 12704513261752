import React from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../../helpers/trans';
import { Col } from '../../../styledComponents/components/Grid';
import Button from '../../Button';
import { Footer } from './style';

const ButtonFooter = props => {
    const {
        onCancel,
        onSubmit,
        submitButtonDisabled,
        loading,
        saveLabel,
        cancelLabel,
        mt,
        id,
        className,
        hideSubmitIcon,
    } = props;

    return (
        <Footer mt={mt} className={className}>
            {onCancel && (
                <Col>
                    <Button
                        borderless
                        width={['100%', '100%', '100%', 'auto']}
                        onClick={() => onCancel()}
                        type="button"
                        id={id && `${id}-cancel`}
                    >
                        {cancelLabel || trans('actions.cancel')}
                    </Button>
                </Col>
            )}
            <Col mb={['sm', 'sm', 'sm', 0]}>
                <Button
                    width={['100%', '100%', '100%', 'auto']}
                    disabled={submitButtonDisabled || loading}
                    isLoading={loading}
                    onClick={onSubmit}
                    type="submit"
                    hideIcon={hideSubmitIcon}
                    id={id ? `${id}-submit` : undefined}
                >
                    {saveLabel || trans('actions.save')}
                </Button>
            </Col>
        </Footer>
    );
};

ButtonFooter.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    submitButtonDisabled: PropTypes.bool,
    loading: PropTypes.bool,
    saveLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    mt: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    id: PropTypes.string,
    className: PropTypes.string,
    hideSubmitIcon: PropTypes.bool,
};

ButtonFooter.defaultProps = {
    onCancel: null,
    onSubmit: null,
    submitButtonDisabled: false,
    loading: false,
    saveLabel: null,
    cancelLabel: null,
    mt: 0,
    id: null,
    className: '',
    hideSubmitIcon: false,
};

export default ButtonFooter;
