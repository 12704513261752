import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';

import { Page } from '../../components';
import { ConnectedErrorPageContent } from '../../connectors';
import { trans } from '../../helpers/trans';
import { StatusCode } from '../../types';

import './Error.scss';

interface ErrorPageProps {
    status?: StatusCode;
}

const ErrorPage: FC<ErrorPageProps> = ({
    status = StatusCode.notFound,
}): ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect((): void => {
        setIsLoading(false);
    }, []);

    return (
        <Page className="error-page">
            {!isLoading && (
                <Helmet>
                    <title>{trans(`errorPage.page${status}.title`)}</title>
                    <meta name="description" content={trans(`errorPage.page${status}.message`)} />
                </Helmet>
            )}

            <div className="error-page__wrapper">
                <ConnectedErrorPageContent status={status} />
            </div>
        </Page>
    );
};

export default ErrorPage;
