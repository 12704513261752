import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconSend: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 85.7" className={`icon ${className}`}>
        <path d="M0,85.7l100-42.9L0,0v33.3l71.4,9.5L0,52.4V85.7z" />
    </svg>
);

export default IconSend;
