import React, { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../..';

import './Checkbox.scss';

export type HTMLInputProps = JSX.IntrinsicElements['input'];

interface CheckboxProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    onChange: (isChecked: boolean) => void;
    className?: string;
}

const Checkbox: FC<CheckboxProps> = ({
    label,
    hideLabel,
    checked,
    disabled,
    onChange,
    className = '',
    ...checkboxProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.checked);
    };

    const checkboxClassNames = classNames('checkbox', {
        'checkbox--is-disabled': !!disabled,
    }, className);

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={checkboxClassNames}
        >
            <input
                {...checkboxProps}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                className="checkbox__input"
            />

            <div className="checkbox__box">
                <Icon name="check" className="checkbox__icon" />
            </div>

            {!hideLabel && (
                <span className="checkbox__label">
                    {label}
                </span>
            )}
        </label>
    );
};

export default Checkbox;
