import axios from 'axios';
import { Dispatch } from 'redux';

import { trans } from '../../../../helpers/trans';
import { RoleType } from '../../../../models/User';
import { setIsLoading as setInternshipVacancyOverviewIsLoading } from '../../../../store/internshipVacancyOverview/internshipVacancyOverview';
import { setIsLoading as setJobVacancyOverviewIsLoading } from '../../../../store/jobVacancyOverview/jobVacancyOverview';
import { LegacyReducers } from '../../../../store/reducers';
import { addNegativeToast, addPositiveToast } from '../../../../store/toast/toastActions';
import { transformToInternshipPreferences } from '../../models/InternshipPreferences';
import { transformToJobPreferences } from '../../models/JobPreferences';
import { MatchingCandidate, MatchingCandidateApiResponseParams, transformMatchingCandidate } from '../../models/MatchingCandidate';
import {
    Person,
    PersonApiResponseParams,
    transformPerson,
    transformToApiPersonRequest,
} from '../../models/Person';
import { ResponseType } from '../../models/Response';
import { updateInternshipPreferences } from '../../services/InternshipPreferencesService';
import { updateJobPreferences } from '../../services/JobPreferencesService';
import { ValidationErrors } from '../../services/PersonService';
import { InternshipVacanciesFilterParams } from '../internshipVacancies/internshipVacancies';
import { updateInternshipVacanciesFilterParams } from '../internshipVacancies/internshipVacanciesActions';
import { JobVacanciesFilterParams } from '../jobVacancies/jobVacancies';
import { updateJobVacanciesFilterParams } from '../jobVacancies/jobVacanciesActions';
import {
    setData,
    setErrors,
    setInternshipPreferencesUpdating,
    setJobPreferencesUpdating,
    setLoading,
    setUpdating,
} from './userPerson';

export function getTransformedPerson(responseData: PersonApiResponseParams | MatchingCandidateApiResponseParams) {
    return (dispatch: Dispatch<any>, getState: () => LegacyReducers): Person | MatchingCandidate => {
        const { legacyUser } = getState();

        if (legacyUser.role === RoleType.student || legacyUser.role === RoleType.jobSeeker) {
            return transformMatchingCandidate(responseData);
        }

        return transformPerson(responseData);
    };
}

export function createPerson(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers): Promise<(dispatch: Dispatch<any>, getState: () => LegacyReducers) => (Person | MatchingCandidate)> => {
        dispatch(setLoading(true));

        const { legacyUser, userAddress } = getState();
        const { role } = legacyUser;

        data.user_id = legacyUser?.data?.uuid;

        if (role !== RoleType.company) {
            data.address_id = userAddress.data?.uuid || '';
        }

        data.longitude = data.longitude && data.longitude.toString();
        data.latitude = data.latitude && data.latitude.toString();

        try {
            const response = await axios.post('/people', transformToApiPersonRequest(data));
            const personDispatch = dispatch(getTransformedPerson(response.data.data));

            const person = role === RoleType.student || role === RoleType.jobSeeker
                ? transformMatchingCandidate(response.data.data)
                : transformPerson(response.data.data);

            dispatch(setData(person));
            dispatch(setLoading(false));

            // TODO: Fix translations for all toasts
            dispatch(addPositiveToast({
                title: trans('basic.toast.success'),
                description: trans('candidateProfile.toast.savedChanges.success'),
            }));

            return Promise.resolve(personDispatch);
        } catch (error) {
            console.error('[createPerson]', error);

            dispatch(setErrors(error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            return Promise.reject(error);
        }
    };
}

export function updatePerson(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setUpdating(true));

        const { legacyUser, userPerson } = getState();
        const { role } = legacyUser;

        const personUuid = userPerson?.data?.uuid;

        try {
            if (data.school_experiences?.length > 0 && data.school_experiences[0].school_name) {
                data.school_experiences[0].school_id = null;
            }

            if (data.school_experiences?.length > 0 && data.school_experiences[0].field_of_study_name) {
                data.school_experiences[0].field_of_study_id = null;
            }

            if (data.school_experiences?.length > 0 && data.school_experiences[0].education_level_name) {
                data.school_experiences[0].education_level_id = null;
            }

            const response = await axios.patch(`/people/${personUuid}`, transformToApiPersonRequest(data));

            const person = role === RoleType.student || role === RoleType.jobSeeker
                ? transformMatchingCandidate(response.data.data)
                : transformPerson(response.data.data);

            if (person) {
                dispatch(setData(person));
            }

            dispatch(setUpdating(false));

            dispatch(addPositiveToast({
                title: trans('basic.toast.success'),
                description: trans('candidateProfile.toast.savedChanges.success'),
            }));

            return true;
        } catch (error) {
            console.error('[updatePerson]', error);

            dispatch(setErrors(error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return false;
        }
    };
}

export function mapInternshipPreferencesToFilterParams() {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        const { userPerson, internshipVacancies } = getState();
        const peronData = userPerson?.data;
        const internshipPreferencesSet = peronData?.internship_preferences_set;
        const { filterParams } = internshipVacancies;

        const internshipTypes = (internshipPreferencesSet?.internship_types || [])
            .map(internshipType => internshipType.uuid);
        const amountOfEmployees = (internshipPreferencesSet?.amount_of_employees || [])
            .map(amount => amount.uuid);

        const params: InternshipVacanciesFilterParams = {
            ...filterParams,
            internship_types: internshipTypes,
            amount_of_employees: amountOfEmployees,
            compensation: internshipPreferencesSet?.compensation.value,
            travel_distance: internshipPreferencesSet?.travel_distance.value,
            grade: internshipPreferencesSet?.grade?.uuid,
            zipcode: internshipPreferencesSet?.zipcode,
        };

        dispatch(updateInternshipVacanciesFilterParams(params));
    };
}

export function mapJobPreferencesToFilterParams() {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        const { userPerson, jobVacancies } = getState();
        const peronData = userPerson?.data;
        const jobPreferencesSet = peronData?.job_preferences_set;
        const { filterParams } = jobVacancies;

        const amountOfEmployees = (jobPreferencesSet?.amount_of_employees || [])
            .map(amount => amount.uuid);
        const jobTypes = (jobPreferencesSet?.job_types || [])
            .map(jobType => jobType.uuid);

        const params = {
            ...filterParams,
            job_types: jobTypes,
            amount_of_employees: amountOfEmployees,
            gross_salary: jobPreferencesSet?.gross_salary.value,
            travel_distance: jobPreferencesSet?.travel_distance.value,
            zipcode: jobPreferencesSet?.zipcode,
        };

        dispatch(updateJobVacanciesFilterParams(params));
    };
}

export function getPerson() {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers): Promise<(dispatch: Dispatch<any>, getState: () => LegacyReducers) => (Person | MatchingCandidate)> => {
        dispatch(setLoading(true));

        const { legacyUser } = getState();
        const { role } = legacyUser;

        try {
            const response = await axios.get(`/people/${legacyUser?.data?.person_id}`);
            const personDispatch = dispatch(getTransformedPerson(response.data.data));

            if (role === RoleType.student || role === RoleType.jobSeeker) {
                dispatch(mapInternshipPreferencesToFilterParams());
                dispatch(mapJobPreferencesToFilterParams());
            }

            const person = role === RoleType.student || role === RoleType.jobSeeker
                ? transformMatchingCandidate(response.data.data)
                : transformPerson(response.data.data);

            dispatch(setData(person));
            dispatch(setLoading(false));

            return Promise.resolve(personDispatch);
        } catch (error) {
            console.error('[getPerson]', error);

            dispatch(setErrors(error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            return Promise.reject(error);
        }
    };
}

// TODO: Ask if this is needed, at all
export function updateInternshipPreferencesSet(preferences: InternshipVacanciesFilterParams) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        try {
            dispatch(setInternshipPreferencesUpdating(true));

            const { userPerson } = getState();
            const internshipPreferencesSetId = userPerson?.data?.internship_preferences_set?.uuid;

            if (!internshipPreferencesSetId) {
                return false;
            }

            dispatch(setInternshipVacancyOverviewIsLoading(true));

            const response = await updateInternshipPreferences(preferences, internshipPreferencesSetId);

            if (response.type === ResponseType.Success) {
                if (response.data && userPerson.data) {
                    dispatch(setData({
                        ...userPerson.data,
                        internship_preferences_set: transformToInternshipPreferences(response.data),
                    }));
                }

                return true;
            }

            dispatch(setErrors(response.error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            return false;
        } catch (error) {
            console.error('[updateInternshipPreferencesSet]', error);

            dispatch(setErrors(error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            return false;
        } finally {
            dispatch(setInternshipVacancyOverviewIsLoading(false));
        }
    };
}

// TODO: Ask if this is needed, at all
export function updateJobPreferencesSet(preferences: JobVacanciesFilterParams) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        try {
            dispatch(setJobPreferencesUpdating(true));

            const { userPerson } = getState();
            const jobPreferencesSetId = userPerson?.data?.job_preferences_set?.uuid;

            if (!jobPreferencesSetId) {
                return false;
            }

            dispatch(setJobVacancyOverviewIsLoading(true));

            const response = await updateJobPreferences(preferences, jobPreferencesSetId);

            if (response.type === ResponseType.Success) {
                if (response.data && userPerson.data) {
                    dispatch(setData({
                        ...userPerson.data,
                        job_preferences_set: transformToJobPreferences(response.data),
                    }));
                }

                return true;
            }

            dispatch(setErrors(response.error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            return false;
        } catch (error) {
            console.error('[updateJobPreferencesSet]', error);

            dispatch(setErrors(error as ValidationErrors));
            dispatch(setLoading(false));
            dispatch(setUpdating(false));

            return false;
        } finally {
            dispatch(setJobVacancyOverviewIsLoading(false));
        }
    };
}
