import React, { FC, ReactElement } from 'react';

import { ErrorLabel, InputLabel, Select } from '../../../components';
import { SelectProps } from '../../../components/@inputs/Select/Select';
import VirtualSelectMenuList from './components/VirtualSelectMenuList/VirtualSelectMenuList';
import getSelectedInputValues from './helpers/getSelectedInputValues';

interface SelectInputProps extends Omit<SelectProps, 'selected' | 'error'> {
    label: string;
    id?: string;
    hideLabel?: boolean;
    required?: boolean;
    optional?: boolean;
    error?: string;
    className?: string;
    labelClassName?: string;
    value?: string | string[];
}

const SelectInput: FC<SelectInputProps> = ({
    label,
    id,
    optional = false,
    required = false,
    hideLabel = false,
    error = '',
    labelClassName = '',
    className = '',
    value = [],
    options,
    ...selectProps
}): ReactElement => {
    const selectedValues = getSelectedInputValues(value, options);

    return (
        <label
            id={id}
            aria-label={hideLabel ? label : undefined}
            className={`text-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    className={labelClassName}
                    text={label}
                    required={required}
                    optional={optional}
                />
            )}

            <Select
                {...selectProps}
                components={{ MenuList: VirtualSelectMenuList }}
                options={options}
                selected={selectedValues}
                error={!!error}
            />

            {error && <ErrorLabel text={error} />}
        </label>
    );
};

export default SelectInput;
