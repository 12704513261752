import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Competency } from '../../models/Competencies';
import { AsyncReduxState } from '../../types';

export type CompetenciesState = AsyncReduxState<{
    competencies: Competency[];
}>;

const initialState: CompetenciesState = {
    ...initialAsyncReduxState,
    competencies: [],
};

export const competenciesSlice = createSlice({
    name: 'competenciesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CompetenciesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CompetenciesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCompetencies(state, action: PayloadAction<Competency[]>): CompetenciesState {
            return {
                ...state,
                competencies: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCompetencies,
} = competenciesSlice.actions;

export default competenciesSlice;
