// TODO: Despite apparent refactor, this reducer/slice needs to be refactored, and removed from '_old' entirely.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VacancyState {
    loading: boolean;
    vacancy: unknown;
    newestVacancy: unknown;
    ignoreErrors: unknown;
    inviteErrors: unknown;
}

const initialState: VacancyState = {
    loading: false,
    vacancy: null,
    newestVacancy: null,
    ignoreErrors: null,
    inviteErrors: null,
};

const vacancySlice = createSlice({
    name: 'vacancy',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): VacancyState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setVacancy(state, action: PayloadAction<unknown>): VacancyState {
            return {
                ...state,
                vacancy: action.payload,
            };
        },
        setNewestVacancy(state, action: PayloadAction<unknown>): VacancyState {
            return {
                ...state,
                newestVacancy: action.payload,
            };
        },
        setIgnoreError(state, action: PayloadAction<unknown>): VacancyState {
            return {
                ...state,
                ignoreErrors: action.payload,
            };
        },
        setInviteError(state, action: PayloadAction<unknown>): VacancyState {
            return {
                ...state,
                inviteErrors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setVacancy,
    setNewestVacancy,
    setIgnoreError,
    setInviteError,
} = vacancySlice.actions;

export default vacancySlice;
