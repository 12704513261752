export default [
    {
        uuid: 'male',
        name: 'Man',
    },
    {
        uuid: 'female',
        name: 'Vrouw',
    },
];
