import React from 'react';

import { Helmet } from 'react-helmet-async';

import { trans } from '../../../../../helpers/trans';
import { getGoogleFaqJsonForFaqPage } from '../../../../helpers/getGoogleFaqJsonForFaqPage';
import Card from '../../../../styledComponents/components/Card';
import Container from '../../../../styledComponents/components/Container';
import Page from '../../../../styledComponents/components/Page';
import { Heading } from '../../../../styledComponents/components/Typography';
import { createMetadataTitle } from '../../../services/MetadataService';
import Accordion from '../../components/Accordion';
import FaqAccordionItem from './components/FaqAccordionItem/FaqAccordionItem';
import {
    applyAnonymousItems,
    employeeItems,
    generalItems,
    studentItems,
} from './content';

const FaqPage = () => {
    const allItems = [
        ...generalItems,
        ...employeeItems,
        ...studentItems,
        ...applyAnonymousItems,
    ];

    return (
        <Page>
            <Helmet>
                <title>{trans('infoPage.faq.metadata.title')}</title>
                <meta property="og:title" content={createMetadataTitle(trans('infoPage.faq.metadata.title'))} />
                <meta property="og:description" content={trans('infoPage.faq.metadata.description')} />
                <meta name="description" content={trans('infoPage.faq.metadata.description')} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/faq`} />
                <script type="application/ld+json">
                    {JSON.stringify(getGoogleFaqJsonForFaqPage(allItems))}
                </script>
            </Helmet>
            <Container centered>
                <Heading>
                    {trans('faq.title')}
                </Heading>
                <Card mb="xl">
                    <Heading as="h2">
                        {trans('faq.general.title')}
                    </Heading>
                    <Accordion allowMultipleOpen>
                        {generalItems.map(item => <FaqAccordionItem label={item.title} item={item} />)}
                    </Accordion>
                </Card>
                <Card mb="xl">
                    <Heading as="h2">
                        {trans('faq.student.title')}
                    </Heading>
                    <Accordion allowMultipleOpen>
                        {studentItems.map(item => <FaqAccordionItem label={item.title} item={item} />)}
                    </Accordion>
                </Card>
                <Card mb="xl">
                    <Heading as="h2">
                        {trans('faq.employee.title')}
                    </Heading>
                    <Accordion allowMultipleOpen>
                        {employeeItems.map(item => <FaqAccordionItem label={item.title} item={item} />)}
                    </Accordion>
                </Card>
                <Card mb="xl">
                    <Heading as="h2">
                        {trans('faq.applyAnonymous.title')}
                    </Heading>
                    <Accordion allowMultipleOpen>
                        {applyAnonymousItems.map(item => <FaqAccordionItem label={item.title} item={item} />)}
                    </Accordion>
                </Card>
            </Container>
        </Page>
    );
};

export default FaqPage;
