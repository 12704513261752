import React, { FC, MouseEvent, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../components';
import { ButtonProps } from '../../../components/@buttons/Button/Button';
import { trans } from '../../../helpers/trans';

import './FavouriteButton.scss';

interface FavouriteButtonProps extends Omit<ButtonProps, 'text' | 'onClick'> {
    isActive?: boolean;
    isLoading?: boolean;
    onClick: (active: boolean) => void;
    className?: string;
}

const FavouriteButton: FC<FavouriteButtonProps> = ({
    isActive = false,
    isLoading = false,
    onClick,
    className = '',
}): ReactElement => {
    const favouriteButtonClassNames = classNames('favourite-button', {
        'favourite-button--is-active': isActive,
        'favourite-button--is-loading': isLoading,
    }, className);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        onClick(!isActive);
    };

    const favouriteButtonLabel = isActive
        ? trans('favourites.removeFavourite')
        : trans('favourites.addFavourite');

    return (
        <Button
            text={favouriteButtonLabel}
            disabled={isLoading}
            onClick={handleClick}
            className={favouriteButtonClassNames}
        >
            <Icon name={isActive ? 'heart-solid' : 'heart'} className="favourite-button__icon" />
        </Button>
    );
};

export default FavouriteButton;
