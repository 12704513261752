import {
    formatAppointment,
    formatDate,
    getNextMonday,
    isValidDateString,
} from '../helpers/date';
import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export enum ApplicationAppointmentType {
    physical = 'physical',
    jitsi = 'jitsi',
}

export enum ApplicationAppointmentStatus {
    inRequest = 'IN_REQUEST',
    accepted = 'ACCEPTED',
    declined = 'DECLINED',
    cancelled = 'CANCELLED',
    unknown = 'UNKNOWN',
}

export interface ApplicationAppointmentsResource extends Resource {
    type: 'application-appointments';
    id: string;
    attributes: {
        type: ApplicationAppointmentType;
        status: ApplicationAppointmentStatus;
        start: string;
        end: string;
        createdAt: string;
        updatedAt: string;
    };
}

export interface AppointmentDate {
    date: string;
    startTime: string;
    endTime: string;
}

export const defaultAppointmentDate = (): AppointmentDate => {
    const nextMonday = getNextMonday();

    return {
        date: formatDate(nextMonday, 'y-MM-dd'),
        startTime: '09:00',
        endTime: '10:00',
    };
};

export interface ApplicationAppointmentRequest {
    type: ApplicationAppointmentType;
    date: string;
    start: string;
    end: string;
}

export interface AcceptApplicationAppointmentRequest {
    data: {
        type: 'application-appointments';
        id: string;
        attributes: {
            status: ApplicationAppointmentStatus;
        };
    };
}

export interface ApplicationAppointment {
    uuid: string;
    type: ApplicationAppointmentType;
    status: ApplicationAppointmentStatus;
    startDate: Date;
    endDate: Date;
}

export interface ApplicationAppointmentFormData {
    appointments: AppointmentDate[];
    type: ApplicationAppointmentType;
    message: string;
}

export const transformToApplicationAppointment = (
    applicationAppointmentResource: ApplicationAppointmentsResource,
): ApplicationAppointment => {
    const startDate = isValidDateString(applicationAppointmentResource.attributes.start)
        ? new Date(applicationAppointmentResource.attributes.start)
        : new Date();

    const endDate = isValidDateString(applicationAppointmentResource.attributes.end)
        ? new Date(applicationAppointmentResource.attributes.end)
        : new Date();

    return {
        uuid: applicationAppointmentResource.id,
        type: applicationAppointmentResource.attributes.type,
        status: applicationAppointmentResource.attributes.status,
        startDate,
        endDate,
    };
};

export const transformApplicationAppointmentToFormOption = (
    applicationAppointment: ApplicationAppointment,
): FormOption => ({
    label: formatAppointment(applicationAppointment.startDate, applicationAppointment.endDate),
    value: applicationAppointment.uuid,
});

export const transformToApplicationAppointmentRequest = (
    type: ApplicationAppointmentType,
    appointmentDate: AppointmentDate,
): ApplicationAppointmentRequest => ({
    type,
    date: appointmentDate.date,
    start: `${appointmentDate.startTime}:00`,
    end: `${appointmentDate.endTime}:00`,
});

export const transformToAcceptApplicationAppointmentRequest = (applicationAppointment: ApplicationAppointment): AcceptApplicationAppointmentRequest => ({
    data: {
        type: 'application-appointments',
        id: applicationAppointment.uuid,
        attributes: {
            status: ApplicationAppointmentStatus.accepted,
        },
    },
});
