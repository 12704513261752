import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../../models/Company';

export interface UserCompanyState {
    loading: boolean;
    data: Company | null;
    errors: Record<string, unknown>;
}

const initialState: UserCompanyState = {
    loading: false,
    data: null,
    errors: {},
};

const userCompanySlice = createSlice({
    name: 'userCompany',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): UserCompanyState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setData(state, action: PayloadAction<Company | null>): UserCompanyState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): UserCompanyState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setData,
    setErrors,
} = userCompanySlice.actions;

export default userCompanySlice;
