import React, {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '../../components';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { CompanyOverviewTab } from '../../types/pageTabs';

import './RegistrationCallToAction.scss';

interface RegistrationCallToActionProps {
    role: RoleType;
    template: RoleType;
    onCallToActionClick: (role: RoleType) => void;
    className?: string;
}

const RegistrationCallToAction: FC<RegistrationCallToActionProps> = ({
    role,
    template,
    onCallToActionClick,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const [userIsAuthenticated, setUserIsAuthenticated] = useState<boolean>(false);
    const [ctaTemplate, setCtaTemplate] = useState<RoleType>(template);

    useEffect((): void => {
        const roles = [RoleType.jobSeeker, RoleType.company];
        const randomRole = roles[Math.floor(Math.random() * roles.length)];
        const newTemplate = template !== RoleType.none ? template : randomRole;

        setCtaTemplate(newTemplate);
        setUserIsAuthenticated(role !== RoleType.none && role === newTemplate);
    }, [role, template]);

    const handleCallToActionClick = (): void => {
        if (userIsAuthenticated) {
            if (template === RoleType.student) navigate(RoutePaths.internships());
            if (template === RoleType.jobSeeker) navigate(RoutePaths.jobs());
            if (template === RoleType.company) navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));

            return;
        }

        onCallToActionClick(ctaTemplate);
    };

    const backgroundImage: Record<RoleType, string> = {
        [RoleType.student]: '/images/background/cta-student.jpg',
        [RoleType.jobSeeker]: '/images/background/cta-job-seeker.jpg',
        [RoleType.company]: '/images/background/cta-employer.jpg',
        [RoleType.none]: '/images/background/cta-student.jpg',
    };

    const cssVariables = {
        '--registration-call-to-action-background-image': `url(${backgroundImage[ctaTemplate]})`,
    } as CSSProperties;

    const descriptionText = userIsAuthenticated
        ? trans(`callToAction.registration.${ctaTemplate}.authenticated.description`)
        : trans(`callToAction.registration.${ctaTemplate}.description`);

    const buttonText = userIsAuthenticated
        ? trans(`callToAction.registration.${ctaTemplate}.authenticated.buttonText`)
        : trans(`callToAction.registration.${ctaTemplate}.buttonText`);

    return (
        <section style={cssVariables} className={`registration-call-to-action ${className}`}>
            <div className="registration-call-to-action__wrapper">
                <div className="registration-call-to-action__title-wrapper">
                    <h1 className="registration-call-to-action__title">
                        {trans(`callToAction.registration.${ctaTemplate}.title`)}
                    </h1>
                    <h2 className="registration-call-to-action__subtitle">
                        {trans(`callToAction.registration.${ctaTemplate}.subtitle`)}
                    </h2>
                </div>

                <p className="registration-call-to-action__description">
                    {descriptionText}
                </p>

                <Button
                    id="registration-call-to-action"
                    text={buttonText}
                    onClick={handleCallToActionClick}
                    className="registration-call-to-action__button"
                />
            </div>
        </section>
    );
};

export default RegistrationCallToAction;
