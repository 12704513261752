import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLike: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M75.9,0H57.1L36.4,36.2L30,39.4V35H0v65h30v-4.6l11.5,4.6h41.3L100,71.4V30H70.9L75.9,0z M27.5,85H31l12.5,5h33.7L90,68.6V40H59.1l5-30h-1.2L43.6,43.8L31.2,50h-3.7V85z M20,45H10v45h10V45z" />
    </svg>
);

export default IconLike;
