import React, { FC, ReactElement } from 'react';

import { RegistrationCallToAction } from '../../containers';
import { RoleType } from '../../models/User';
import { useLegacySelector } from '../../store';

interface ConnectedRegistrationCallToActionProps {
    template?: RoleType;
    onCallToActionClick: (role: RoleType) => void;
    className?: string;
}

const ConnectedRegistrationCallToAction: FC<ConnectedRegistrationCallToActionProps> = ({
    template,
    onCallToActionClick,
    className = '',
}): ReactElement => {
    const role = useLegacySelector(state => state.legacyUser.role);

    return (
        <RegistrationCallToAction
            role={role ?? RoleType.none}
            template={template ?? RoleType.none}
            onCallToActionClick={onCallToActionClick}
            className={className}
        />
    );
};

export default ConnectedRegistrationCallToAction;
