import React, { FC, PropsWithChildren, ReactElement } from 'react';

import './ExternalLinkButton.scss';

interface ExternalLinkButtonProps {
    href: string;
    text?: string;
    className?: string;
}

const ExternalLinkButton: FC<PropsWithChildren<ExternalLinkButtonProps>> = ({
    href,
    text,
    className = '',
    children,
}): ReactElement => (
    <a
        href={href}
        target="_blank"
        title={children ? text : undefined}
        rel="noopener noreferrer"
        className={`external-link-button ${className}`}
    >
        {children || text}
    </a>
);

export default ExternalLinkButton;
