import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { components } from 'react-select';

import { trans } from '../../../../helpers/trans';
import arrayEquals from '../../../helpers/arrayEquals';
import { getOptionWithValue } from '../../../helpers/formHelper';
import { Row } from '../../../styledComponents/components/Grid';
import Icon from '../../../styledComponents/components/Icon';
import {
    Error,
    InputLoader,
    Label,
    OptionalLabel,
    Select,
    SelectIcon,
} from './style';

const DropdownIndicator = props => components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
        <SelectIcon kind="dropdown" color="text" size={15} />
    </components.DropdownIndicator>
);

const customStyles = {
    option: props => ({
        ...props,
    }),
    dropdownIndicator: props => ({
        ...props,
        color: '#000000',
    }),
};

class ReactSelect extends Component {
    constructor() {
        super();

        this.state = {
            selectedOption: null,
            showErrors: false,
        };
    }

    componentDidMount() {
        this.props.selected && this.setSelectedFromProps(this.props);
    }

    componentDidUpdate(prevProps) {
        this.props.selected !== prevProps.selected && this.setSelectedFromProps();

        if (this.props.errors && this.props.errors !== prevProps.errors) {
            this.showErrors();
        }

        if (!arrayEquals(this.props.options, prevProps.options)) {
            this.setSelectedFromProps();
        }
    }

    showErrors() {
        this.setState({
            showErrors: true,
        });
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    clear = () => {
        this.setState({ selectedOption: null });
    };

    setSelectedFromProps() {
        const { options, keyVal, selected } = this.props;

        if (options) {
            const selectedOption = options.find(
                option => getOptionWithValue(selected, option, keyVal[1]),
            );

            const mappedSelectedOption = selectedOption ? {
                label: selectedOption[keyVal[0]],
                value: selectedOption[keyVal[1]],
            } : null;

            this.setState({
                selectedOption: mappedSelectedOption,
            });
        }
    }

    handleChange = option => {
        this.setState({
            selectedOption: option,
            showErrors: false,
        });

        this.props.onChange(option ? option.value : '');
    };

    render() {
        const { selectedOption, showErrors } = this.state;
        const {
            options,
            required,
            label,
            id,
            errors,
            placeholder,
            optional,
            keyVal,
            optionsLoading,
            canReset,
            noValue,
            disabled,
            showErrorStyle,
            customIcon,
        } = this.props;

        /**
         * ReactSelect uses value, label combination
         * @todo Check ReactSelect options for a better solution?
         */
        const mappedOptions = options ? options.map(option => ({
            label: option[keyVal[0]],
            value: option[keyVal[1]],
        })) : [];

        return (
            <>
                {label && (
                    <Label htmlFor={id}>
                        {label}
                        {optional && <OptionalLabel>{trans('forms.optional')}</OptionalLabel>}
                        {required && '*'}
                    </Label>
                )}
                <Row data-hj-suppress position="relative">
                    {optionsLoading && (
                        <InputLoader>
                            <Icon size={16} kind="loader" stroke="primary" color="transparent" />
                        </InputLoader>
                    )}
                    <Select
                        {...this.props}
                        classNamePrefix="rs"
                        isClearable={canReset}
                        components={customIcon && { DropdownIndicator }}
                        value={(noValue || !selectedOption) ? null : selectedOption}
                        onChange={this.handleChange}
                        options={mappedOptions || []}
                        placeholder={placeholder}
                        styles={customStyles}
                        errors={(!!errors && showErrors) || showErrorStyle}
                        isDisabled={optionsLoading || !mappedOptions || !mappedOptions.length || disabled}
                    />
                </Row>
                <Error errors={!!errors && showErrors}>
                    {(!!errors && showErrors) && <span key={`${errors[0]}`}>{errors[0]}<br /></span>}
                </Error>
            </>
        );
    }
}

ReactSelect.propTypes = {
    showErrorStyle: PropTypes.bool,
    errors: PropTypes.array,
    optionsLoading: PropTypes.bool,
    options: PropTypes.array,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    selected: PropTypes.string,
    placeholder: PropTypes.string,
    optional: PropTypes.bool,
    keyVal: PropTypes.array,
    onChange: PropTypes.func,
    canReset: PropTypes.bool,
    noValue: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    customIcon: PropTypes.bool,
};

ReactSelect.defaultProps = {
    label: '',
    options: null,
    selected: null,
    errors: null,
    optional: false,
    placeholder: null,
    optionsLoading: false,
    required: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    keyVal: ['name', 'uuid'],
    canReset: false,
    noValue: false,
    disabled: false,
    showErrorStyle: false,
    customIcon: true,
};

export default ReactSelect;
