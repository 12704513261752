import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { MatchProperty } from '../../../_old/app_talentz/models/MatchProperty';
import Tooltip from '../../../_old/components/Tooltip/Tooltip';
import { ErrorLabel, InputLabel } from '../../../components';
import Loader from '../../Loader/Loader';
import { BlockRadioItem } from './subcomponents';

import './BlockRadioList.scss';

interface BlockRadioListProps {
    id?: string;
    label?: string;
    error?: string;
    options: MatchProperty[];
    name: string;
    value: string;
    loading?: boolean;
    required?: boolean;
    optional?: boolean;
    tooltip?: string;
    tooltipId?: string;
    onChange: (value: string) => void;
    className?: string;
}

const BlockRadioList: FC<BlockRadioListProps> = ({
    id,
    label = '',
    error = '',
    options,
    name,
    value,
    loading = false,
    required = false,
    optional = false,
    tooltip = '',
    tooltipId = '',
    onChange,
    className = '',
}): ReactElement => {
    const listItemsClassName = classnames('block-radio-list__items', {
        'block-radio-list__items--error': !!error,
    });

    return (
        <div id={id} className={`block-radio-list ${className}`}>
            <div className="block-radio-list__label">
                {label && (
                    <InputLabel
                        text={label}
                        required={required}
                        optional={optional}
                    />
                )}
                {tooltip && (
                    <Tooltip content={tooltip} id={tooltipId || tooltip} />
                )}
            </div>
            <div className={listItemsClassName}>
                {loading && <Loader />}
                {!loading && (
                    options?.map(option => (
                        <BlockRadioItem
                            key={option.uuid}
                            name={name}
                            label={option.name}
                            value={option.uuid}
                            selected={value === option.uuid}
                            onChange={onChange}
                        />
                    ))
                )}
            </div>
            {error && <ErrorLabel text={error} />}
        </div>
    );
};

export default BlockRadioList;
