import { CompetenciesFormData } from '../../../../models/Competencies';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMinimumArrayLength,
} from '../../../../services/ValidationService';

export type CompetenciesFormErrors = FormErrors<CompetenciesFormData>;

export const validateCompetenciesFormData = (
    formData: CompetenciesFormData,
): FormValidation<CompetenciesFormErrors> => {
    const errors: CompetenciesFormErrors = {
        competencies: validateMinimumArrayLength('competencies', formData.competencies, 3),
    };

    return validateForm<CompetenciesFormErrors>(errors);
};
