// eslint-disable-next-line import/no-anonymous-default-export
export default {
    primary: '#11171D',
    primaryMuted: '#313131',
    primaryLighter: '#444444',
    primaryDarker: '#2f2f2f',
    secondary: '#ffdb02',
    action: '#ffdb02',
    alert: '#ff3334',
    success: '#06cb70',
    warning: '#ff3334',
    notification: '#ff9821',
    text: '#5B5B5B',
    heading: '#2E3347',
    muted: '#979797',
    black: '#000000',
    white: '#ffffff',
    background: '#f7f8fa',
    shadow: '#9ca4a8',
    lightShadow: '#d0d0d0',
    grey: '#e5e5e5',
    greyLight: '#F0F0F0',
    llo: '#01BC9C',
    linkedIn: '#0077b5',
    link: '#208BC8',
    placeholder: '#b0b0b0',
};
