import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import './CurrencyIcon.scss';

interface CurrencyIconProps {
    isVisible?: boolean;
    className?: string;
}

const CurrencyIcon: FC<CurrencyIconProps> = ({
    isVisible,
    className = '',
}): ReactElement => {
    const currencyIconClassNames = classNames('currency-icon', {
        'currency-icon--is-visible': isVisible,
    }, className);

    return (
        <svg viewBox="0 0 1000 895" className={currencyIconClassNames}>
            <defs>
                <path id="currency-icon-overflow-path" className="currency-icon__overflow-path" d="M422.4,0c-232,0-420,188.1-420,420c0,226.9,180,411.8,404.9,419.8c33.2,32.6,78.7,52.8,128.9,52.8H1000V0H422.4z" />
            </defs>

            <clipPath id="currency-icon-overflow-clip">
                <use href="#currency-icon-overflow-path" />
            </clipPath>

            <g className="currency-icon__content">
                <circle className="currency-icon__background" cx="422.4" cy="420" r="420" />

                <g className="currency-icon__bill">
                    <path className="currency-icon__bill-background" d="M70.3,535.9c-9.4,0-17-7.6-17-17V102c0-9.4,7.6-17,17-17H968c9.4,0,17,7.6,17,17v416.8c0,9.4-7.6,17-17,17H70.3z" />
                    <path className="currency-icon__bill-border" d="M968,100c1.1,0,2,0.9,2,2v416.8c0,1.1-0.9,2-2,2H70.3c-1.1,0-2-0.9-2-2V102c0-1.1,0.9-2,2-2H968 M968,70H70.3c-17.7,0-32,14.3-32,32v416.8c0,17.7,14.3,32,32,32H968c17.7,0,32-14.3,32-32V102C1000,84.3,985.7,70,968,70L968,70z" />

                    <circle className="currency-icon__bill-circle" cx="527.4" cy="310.4" r="174.1" />
                    <path className="currency-icon__bill-currency" d="M536.7,359.7c5.8,0,11.5-1.1,17.3-3.3c5.8-2.2,12.4-5.9,19.8-11l11.7,22.9c-6.1,5.2-13.7,9.3-22.9,12.3c-9.2,3-18.4,4.5-27.6,4.5c-16,0-29.4-4.3-40.2-12.9c-10.6-8.6-17.7-20.8-21.3-36.6h-20.8v-18.8h18.2c-0.2-1.4-0.2-3.5-0.2-6.5c0-2.9,0.1-5,0.2-6.3h-18.2v-18.8h20.8c3.6-15.7,10.7-27.8,21.3-36.4c10.7-8.6,24.1-12.9,40.2-12.9c9.2,0,18.4,1.5,27.6,4.5c9.2,3,16.8,7.1,22.9,12.3l-11.7,22.9c-7.5-5.2-14.1-8.9-19.8-11c-5.8-2.2-11.5-3.3-17.3-3.3c-7.3,0-13.2,2-17.7,5.9c-4.4,3.8-7.6,9.8-9.6,18h34.8v18.8H507v6.3v6.5h37.1v18.8h-34.8c2,8.3,5.2,14.4,9.6,18.4C523.4,357.8,529.4,359.7,536.7,359.7z" />

                    <path className="currency-icon__shadow" d="M279.1,70H70.6c-17.8,0-32.3,14.4-32.3,32.3v416.3c0,17.8,14.4,32.3,32.3,32.3h208.5h372.7L419.7,70H279.1z" />
                </g>

                <g className="currency-icon__bill">
                    <path className="currency-icon__bill-background" d="M-34.7,640.9c-9.4,0-17-7.6-17-17V207c0-9.4,7.6-17,17-17H863c9.4,0,17,7.6,17,17v416.8c0,9.4-7.6,17-17,17H-34.7z" />
                    <path className="currency-icon__bill-border" d="M863,205c1.1,0,2,0.9,2,2v416.8c0,1.1-0.9,2-2,2H-34.7c-1.1,0-2-0.9-2-2V207c0-1.1,0.9-2,2-2H863 M863,175H-34.7c-17.7,0-32,14.3-32,32v416.8c0,17.7,14.3,32,32,32H863c17.7,0,32-14.3,32-32V207C895,189.3,880.7,175,863,175L863,175z" />

                    <circle className="currency-icon__bill-circle" cx="422.4" cy="415.4" r="174.1" />
                    <path className="currency-icon__bill-currency" d="M431.7,464.7c5.8,0,11.5-1.1,17.3-3.3c5.8-2.2,12.4-5.9,19.8-11l11.7,22.9c-6.1,5.2-13.7,9.3-22.9,12.3c-9.2,3-18.4,4.5-27.6,4.5c-16,0-29.4-4.3-40.2-12.9c-10.6-8.6-17.7-20.8-21.3-36.6h-20.8v-18.8H366c-0.2-1.4-0.2-3.5-0.2-6.5c0-2.9,0.1-5,0.2-6.3h-18.2v-18.8h20.8c3.6-15.7,10.7-27.8,21.3-36.4c10.7-8.6,24.1-12.9,40.2-12.9c9.2,0,18.4,1.5,27.6,4.5c9.2,3,16.8,7.1,22.9,12.3l-11.7,22.9c-7.5-5.2-14.1-8.9-19.8-11c-5.8-2.2-11.5-3.3-17.3-3.3c-7.3,0-13.2,2-17.7,5.9c-4.4,3.8-7.6,9.8-9.6,18h34.8v18.8H402v6.3v6.5h37.1v18.8h-34.8c2,8.3,5.2,14.4,9.6,18.4C418.4,462.8,424.3,464.7,431.7,464.7z" />

                    <path className="currency-icon__shadow" d="M174.1,175H-34.4c-17.8,0-32.3,14.4-32.3,32.3v416.3c0,17.8,14.4,32.3,32.3,32.3h208.5h372.7L314.7,175H174.1z" />
                </g>

                <g className="currency-icon__coin">
                    <circle className="currency-icon__coin-border" cx="682.6" cy="708.8" r="186.1" />
                    <circle className="currency-icon__coin-background" cx="682.6" cy="708.8" r="150.7" />
                    <path className="currency-icon__coin-currency" d="M692.4,761.5c6.2,0,12.3-1.2,18.5-3.5c6.2-2.3,13.2-6.3,21.2-11.8l12.5,24.5c-6.5,5.5-14.6,9.9-24.5,13.1c-9.8,3.2-19.6,4.8-29.5,4.8c-17.1,0-31.5-4.6-42.9-13.8c-11.3-9.2-18.9-22.2-22.7-39.1h-22.2v-20.1h19.5c-0.2-1.5-0.2-3.8-0.2-7c0-3.1,0.1-5.3,0.2-6.8h-19.5v-20.1H625c3.8-16.8,11.4-29.7,22.7-38.9c11.5-9.2,25.8-13.8,42.9-13.8c9.8,0,19.6,1.6,29.5,4.8c9.8,3.2,18,7.6,24.5,13.1l-12.5,24.5c-8-5.5-15.1-9.5-21.2-11.8c-6.2-2.3-12.3-3.5-18.5-3.5c-7.8,0-14.1,2.1-19,6.3c-4.7,4.1-8.1,10.5-10.2,19.2h37.2v20.1h-39.7v6.8v7h39.7v20.1h-37.2c2.2,8.9,5.6,15.4,10.2,19.7C678.3,759.5,684.6,761.5,692.4,761.5z" />

                    <path className="currency-icon__shadow" d="M584.1,550.8c-41.9,26.1-71.7,67.8-82.9,115.9c-11.2,48.1-2.8,98.6,23.3,140.5c26.1,41.9,67.8,71.7,115.9,82.9c48.1,11.2,98.6,2.8,140.5-23.3l-98.4-158L584.1,550.8z" />
                    <ellipse className="currency-icon__joint" cx="496.4" cy="1061" rx="419.5" ry="613.6" />
                </g>

                <g className="currency-icon__coin">
                    <circle className="currency-icon__coin-border" cx="536.2" cy="708.8" r="183.8" />
                    <circle className="currency-icon__coin-background" cx="536.2" cy="708.8" r="148.8" />
                    <path className="currency-icon__coin-currency" d="M545.9,760.8c6.1,0,12.2-1.1,18.2-3.5c6.1-2.3,13.1-6.2,21-11.7l12.3,24.2c-6.4,5.5-14.5,9.8-24.2,12.9c-9.7,3.2-19.4,4.7-29.1,4.7c-16.9,0-31.1-4.5-42.4-13.6c-11.2-9.1-18.7-21.9-22.4-38.6h-21.9v-19.9h19.2c-0.2-1.4-0.2-3.7-0.2-6.9c0-3,0.1-5.3,0.2-6.7h-19.2v-19.9h21.9c3.8-16.5,11.3-29.3,22.4-38.4c11.3-9.1,25.5-13.6,42.4-13.6c9.7,0,19.4,1.6,29.1,4.7c9.7,3.2,17.7,7.5,24.2,12.9l-12.3,24.2c-7.9-5.5-14.9-9.4-21-11.7c-6.1-2.3-12.2-3.5-18.2-3.5c-7.7,0-14,2.1-18.7,6.3c-4.6,4-8,10.4-10.1,19h36.7v19.9h-39.2v6.7v6.9h39.2v19.9h-36.7c2.1,8.8,5.5,15.2,10.1,19.4C532,758.8,538.2,760.8,545.9,760.8z" />

                    <path className="currency-icon__shadow" d="M439,552.9c-41.4,25.8-70.8,66.9-81.8,114.4c-11,47.5-2.7,97.4,23,138.8c25.8,41.4,66.9,70.8,114.4,81.8c47.5,11,97.4,2.7,138.8-23l-97.2-156L439,552.9z" />
                    <circle className="currency-icon__joint" cx="352.4" cy="892.6" r="475" />
                </g>
            </g>
        </svg>
    );
};

export default CurrencyIcon;
