export const space = {
    none: '0px',
    xxs: '5px',
    xs: '10px',
    sm: '15px',
    md: '20px',
    lg: '30px',
    xl: '45px',
    xxl: '70px',
    x: '100px',
};

export const numeralSpace = {
    none: 0,
    xxs: 5,
    xs: 10,
    sm: 15,
    md: 20,
    lg: 30,
    xl: 45,
    xxl: 70,
    x: 100,
};

export default space;
