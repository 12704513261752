import { combineSlices } from '@reduxjs/toolkit';

import app, { AppState } from '../_old/app_talentz/store/app/app';
import internshipVacancies, { InternshipVacanciesState } from '../_old/app_talentz/store/internshipVacancies/internshipVacancies';
import jobVacancies, { JobVacanciesState } from '../_old/app_talentz/store/jobVacancies/jobVacancies';
import legacyUser, { LegacyUserState } from '../_old/app_talentz/store/legacyUser/legacyUser';
import notifications, { NotificationsState as LegacyNotificationsState } from '../_old/app_talentz/store/notifications/notifications';
import register, { RegisterState } from '../_old/app_talentz/store/register/register';
import resetPassword, { ResetPasswordState } from '../_old/app_talentz/store/resetPassword/resetPassword';
import students, { StudentsState } from '../_old/app_talentz/store/students/students';
import userAddress, { UserAddressState } from '../_old/app_talentz/store/userAddress/userAddress';
import userCompany, { UserCompanyState } from '../_old/app_talentz/store/userCompany/userCompany';
import userEmployee, { UserEmployeeState } from '../_old/app_talentz/store/userEmployee/userEmployee';
import userPerson, { UserPersonState } from '../_old/app_talentz/store/userPerson/userPerson';
import userPersonSettings, { UserPersonSettingsState } from '../_old/app_talentz/store/userPersonSettings/userPersonSettings';
import userProfile, { UserProfileState } from '../_old/app_talentz/store/userProfile/userProfile';
import vacancy, { VacancyState } from '../_old/app_talentz/store/vacancy/vacancy';
//
import accountSettingsReducer, { AccountSettingsState } from './accountSettings/accountSettings';
import amountOfEmployeesReducer, { AmountOfEmployeesState } from './amountOfEmployees/amountOfEmployees';
import applicationReducer, { ApplicationState } from './application/application';
import applicationAppointmentReducer, { ApplicationAppointmentState } from './applicationAppointment/applicationAppointment';
import applicationInviteReducer, { ApplicationInviteState } from './applicationInvite/applicationInvite';
import applicationsReducer, { ApplicationsState } from './applications/applications';
import blogReducer, { BlogState } from './blog/blog';
import blogDetailReducer, { BlogDetailState } from './blogDetail/blogDetail';
import candidateReducer, { CandidateState } from './candidate/candidate';
import chatMessagesReducer, { ChatMessagesState } from './chatMessages/chatMessages';
import chatReportsReducer, { ChatReportsState } from './chatReports/chatReports';
import chatsReducer, { ChatsState } from './chats/chats';
import companyReducer, { CompanyState } from './company/company';
import companyVacancyOverviewReducer, { CompanyVacancyOverviewState } from './companyVacancyOverview/companyVacancyOverview';
import competenciesReducer, { CompetenciesState } from './competencies/competencies';
import coreValuesReducer, { CoreValuesState } from './coreValues/coreValues';
import driversLicensesReducer, { DriversLicensesState } from './driversLicenses/driversLicenses';
import educationLevelsReducer, { EducationLevelsState } from './educationLevels/educationLevels';
import escoOccupationsReducer, { EscoOccupationsState } from './escoOccupations/escoOccupations';
import escoSkillsReducer, { EscoSkillsState } from './escoSkills/escoSkills';
import favouritesReducer, { FavouritesState } from './favourites/favourites';
import featuredBlogArticlesReducer, { FeaturedBlogArticlesState } from './featuredBlogArticles/featuredBlogArticles';
import fieldsOfStudyReducer, { FieldsOfStudyState } from './fieldsOfStudy/fieldsOfStudy';
import internshipVacancyOverviewReducer, { InternshipVacancyOverviewState } from './internshipVacancyOverview/internshipVacancyOverview';
import jobTypesReducer, { JobTypesState } from './jobTypes/jobTypes';
import jobVacancyOverviewReducer, { JobVacancyOverviewState } from './jobVacancyOverview/jobVacancyOverview';
import kvkBaseProfileReducer, { KvkBaseProfileState } from './kvkBaseProfile/kvkBaseProfile';
import kvkSearchResultsReducer, { KvkSearchResultsState } from './kvkSearchResults/kvkSearchResults';
import learningPathsReducer, { LearningPathsState } from './learningPaths/learningPaths';
import myVacanciesReducer, { MyVacanciesState } from './myVacancies/myVacancies';
import notificationsReducer, { NotificationsState } from './notifications/notifications';
import provincesReducer, { ProvincesState } from './provinces/provinces';
import recentVacanciesReducer, { RecentVacanciesState } from './recentVacancies/recentVacancies';
import relatedVacancyOverviewReducer, { RelatedVacancyOverviewState } from './relatedVacancyOverview/relatedVacancyOverview';
import residencesReducer, { ResidencesState } from './residences/residences';
import schoolsReducer, { SchoolsState } from './schools/schools';
import sectorsReducer, { SectorsState } from './sectors/sectors';
import skillsReducer, { SkillsState } from './skills/skills';
import toastReducer, { ToastState } from './toast/toast';
import userReducer, { UserState } from './user/user';
import vacancyApplicationsReducer, { VacancyApplicationsState } from './vacancyApplications/vacancyApplications';
import vacancyDetailReducer, { VacancyDetailState } from './vacancyDetail/vacancyDetail';
import vacancyIndexReducer, { VacancyIndexState } from './vacancyIndex/vacancyIndex';
import vacancyMatchesReducer, { VacancyMatchesState } from './vacancyMatches/vacancyMatches';
import vacancyOptionsReducer, { VacancyOptionsState } from './vacancyOptions/vacancyOptions';
import workRemoteReducer, { WorkRemoteState } from './workRemote/workRemote';

export interface LegacyReducers {
    app: AppState;
    internshipVacancies: InternshipVacanciesState;
    jobVacancies: JobVacanciesState;
    notifications: LegacyNotificationsState;
    register: RegisterState;
    resetPassword: ResetPasswordState;
    students: StudentsState;
    legacyUser: LegacyUserState;
    userAddress: UserAddressState;
    userCompany: UserCompanyState;
    userEmployee: UserEmployeeState;
    userPerson: UserPersonState;
    userPersonSettings: UserPersonSettingsState;
    userProfile: UserProfileState;
    vacancy: VacancyState;
    vacancyOptionsReducer: VacancyOptionsState;
}

export interface Reducers {
    accountSettingsReducer: AccountSettingsState;
    amountOfEmployeesReducer: AmountOfEmployeesState;
    applicationAppointmentReducer: ApplicationAppointmentState;
    applicationReducer: ApplicationState;
    applicationInviteReducer: ApplicationInviteState;
    applicationsReducer: ApplicationsState;
    blogReducer: BlogState;
    blogDetailReducer: BlogDetailState;
    candidateReducer: CandidateState;
    chatMessagesReducer: ChatMessagesState;
    chatReportsReducer: ChatReportsState;
    chatsReducer: ChatsState;
    companyReducer: CompanyState;
    companyVacancyOverviewReducer: CompanyVacancyOverviewState;
    competenciesReducer: CompetenciesState;
    coreValuesReducer: CoreValuesState;
    driversLicensesReducer: DriversLicensesState;
    educationLevelsReducer: EducationLevelsState;
    escoOccupationsReducer: EscoOccupationsState;
    escoSkillsReducer: EscoSkillsState;
    favouritesReducer: FavouritesState;
    featuredBlogArticlesReducer: FeaturedBlogArticlesState;
    fieldsOfStudyReducer: FieldsOfStudyState;
    internshipVacancyOverviewReducer: InternshipVacancyOverviewState;
    jobTypesReducer: JobTypesState;
    jobVacancyOverviewReducer: JobVacancyOverviewState;
    kvkBaseProfileReducer: KvkBaseProfileState;
    kvkSearchResultsReducer: KvkSearchResultsState;
    learningPathsReducer: LearningPathsState;
    myVacanciesReducer: MyVacanciesState;
    notificationsReducer: NotificationsState;
    provincesReducer: ProvincesState;
    recentVacanciesReducer: RecentVacanciesState;
    relatedVacancyOverviewReducer: RelatedVacancyOverviewState;
    residencesReducer: ResidencesState;
    schoolsReducer: SchoolsState;
    sectorsReducer: SectorsState;
    skillsReducer: SkillsState;
    toastReducer: ToastState;
    userReducer: UserState;
    vacancyApplicationsReducer: VacancyApplicationsState;
    vacancyDetailReducer: VacancyDetailState;
    vacancyIndexReducer: VacancyIndexState;
    vacancyMatchesReducer: VacancyMatchesState;
    workRemoteReducer: WorkRemoteState;
}

export default combineSlices(
    // Legacy reducers
    app,
    legacyUser,
    internshipVacancies,
    jobVacancies,
    notifications,
    register,
    resetPassword,
    students,
    userAddress,
    userCompany,
    userEmployee,
    userPerson,
    userPersonSettings,
    userProfile,
    vacancy,
    vacancyOptionsReducer,
    // New reducers
    accountSettingsReducer,
    amountOfEmployeesReducer,
    applicationReducer,
    applicationAppointmentReducer,
    applicationInviteReducer,
    applicationsReducer,
    blogReducer,
    blogDetailReducer,
    candidateReducer,
    chatMessagesReducer,
    chatReportsReducer,
    chatsReducer,
    companyReducer,
    companyVacancyOverviewReducer,
    competenciesReducer,
    coreValuesReducer,
    driversLicensesReducer,
    educationLevelsReducer,
    escoOccupationsReducer,
    escoSkillsReducer,
    favouritesReducer,
    featuredBlogArticlesReducer,
    fieldsOfStudyReducer,
    internshipVacancyOverviewReducer,
    jobTypesReducer,
    jobVacancyOverviewReducer,
    kvkBaseProfileReducer,
    kvkSearchResultsReducer,
    learningPathsReducer,
    myVacanciesReducer,
    notificationsReducer,
    provincesReducer,
    recentVacanciesReducer,
    relatedVacancyOverviewReducer,
    residencesReducer,
    schoolsReducer,
    sectorsReducer,
    skillsReducer,
    toastReducer,
    userReducer,
    vacancyApplicationsReducer,
    vacancyDetailReducer,
    vacancyIndexReducer,
    vacancyMatchesReducer,
    workRemoteReducer,
);
