import { format, isValid } from 'date-fns';

export const CURRENT_YEAR = new Date().getFullYear();

function isInvalidDate(date?: Date): boolean {
    if (!date) {
        return true;
    }

    return !isValid(date);
}

export function getDateFromDayMonthYear(day: number, month: number, year: number): Date {
    return new Date(year, month, day, 11, 11, 11);
}

export function getISODateFormat(date: Date): string {
    if (isInvalidDate(date)) {
        throw new Error('Cannot get ISO format from an invalid date');
    }

    return format(date, 'y-MM-dd');
}

export function fromISODate(isoDate: string, time?: string) {
    const splittedIsoDate = isoDate.split('-');
    const year = parseInt(splittedIsoDate[0], 10);
    const month = parseInt(splittedIsoDate[1], 10) - 1;
    const day = parseInt(splittedIsoDate[2], 10);
    let date = new Date(year, month, day);

    if (time) {
        const [h, m] = time.split(':');
        const hours = parseInt(h, 10);
        const minutes = parseInt(m, 10);
        date = new Date(year, month, day, hours, minutes);
    }

    return date;
}

export function convertDayMonthYearStringToDate(dateString: string, reverse?: boolean) {
    const splitDate = reverse
        ? dateString.split('-').reverse()
        : dateString.split('-');

    const day = parseInt(splitDate[0], 10);
    const month = parseInt(splitDate[1], 10) - 1;
    const year = parseInt(splitDate[2], 10);

    return getDateFromDayMonthYear(day, month, year);
}
