import React from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../helpers/trans';
import Icon from '../../styledComponents/components/Icon';
import {
    Button,
    ComingSoon,
    IconWrapper,
    Label,
    LabelWrapper,
    Wrapper,
} from './style';

const IconButton = ({
    onClick,
    children,
    kind,
    comingSoon,
    active,
}) => (
    <Button active={active} comingSoon={comingSoon} id={`register-select-user-type-${kind}`} type="button" onClick={onClick}>
        <Wrapper>
            <IconWrapper>
                <Icon kind={kind} color="black" size="auto" />
            </IconWrapper>
            <LabelWrapper>
                <Label>{children}</Label>
                {comingSoon && <ComingSoon superScript>{trans('basic.comingSoon')}</ComingSoon>}
            </LabelWrapper>
        </Wrapper>
    </Button>
);

IconButton.propTypes = {
    kind: PropTypes.string,
    children: PropTypes.string,
    comingSoon: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

IconButton.defaultProps = {
    kind: null,
    children: null,
    comingSoon: false,
    active: false,
    onClick: undefined,
};

export default IconButton;
