import { v4 as uuidv4 } from 'uuid';

import { AtLeast, MessageState } from '../types';

export enum ToastPosition {
    topLeft = 'top-left',
    topRight = 'top-right',
    bottomLeft = 'bottom-left',
    bottomRight = 'bottom-right',
}

export interface Toast {
    id: string;
    revealDuration?: number;
    transitionDuration?: number;
    position?: ToastPosition;
    state?: MessageState;
    title: string;
    description?: string;
}

export const defaultToastRevealDuration = 5000;

export const defaultToastProperties = (): AtLeast<Toast, 'id'> => ({
    id: uuidv4(),
    revealDuration: defaultToastRevealDuration,
    position: ToastPosition.topRight,
});
