import React from 'react';

import { InfoCompany, InfoJobSeeker, InfoStudent } from '../../pages';

export const infoRoutePaths = {
    infoStudent: () => '/studenten',
    infoJobSeeker: () => '/werkzoekenden',
    infoCompany: () => '/werkgevers',
};

export const infoRoutes = [
    {
        path: infoRoutePaths.infoStudent(),
        element: <InfoStudent />,
    },
    {
        path: infoRoutePaths.infoJobSeeker(),
        element: <InfoJobSeeker />,
    },
    {
        path: infoRoutePaths.infoCompany(),
        element: <InfoCompany />,
    },
];
