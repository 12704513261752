import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { HorizontalAlignment } from '../../../types';

import './TableCell.scss';

type HTMLTableHeadProps = JSX.IntrinsicElements['td'];

interface TableCellProps extends HTMLTableHeadProps {
    align?: HorizontalAlignment;
    colSpan?: number;
    className?: string;
}

const TableCell: FC<TableCellProps> = ({
    align = HorizontalAlignment.left,
    colSpan,
    className = '',
    children,
}): ReactElement => {
    const tableCellClassNames = classNames('table-cell', {
        [`table-cell--align-${align}`]: align,
    }, className);

    return (
        <td className={tableCellClassNames} colSpan={colSpan}>
            {children}
        </td>
    );
};

export default TableCell;
