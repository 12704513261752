import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { transformToLegacyInternshipVacanciesFilterParams } from '../../_old/app_talentz/store/internshipVacancies/internshipVacancies';
import { fetchFilterOptions } from '../../_old/app_talentz/store/internshipVacancies/internshipVacanciesActions';
import { updateInternshipPreferencesSet } from '../../_old/app_talentz/store/userPerson/userPersonActions';
import { InternshipTypeOption } from '../../compositions/@filters/InternshipTypesFilter/InternshipTypesFilter';
import { defaultTravelDistance } from '../../constants';
import { InternshipVacancyOverviewFilter } from '../../containers';
import { VacancySearchFormValues } from '../../containers/@forms/VacancySearchForm/VacancySearchForm';
import { retrieveUniqueValues } from '../../helpers/array';
import { decodeSearchQuery, encodeSearchQuery } from '../../helpers/search';
import { trans } from '../../helpers/trans';
import useQuery from '../../hooks/useQuery';
import { transformSectorToSlugFormOption } from '../../models/Sectors';
import { defaultInternshipVacancyOverviewFilterValues, InternshipVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { RouteParams, RoutePaths } from '../../routes';
import { useLegacyDispatch, useLegacySelector, useTypedSelector } from '../../store';
import { fetchAmountOfEmployeesOptions } from '../../store/amountOfEmployees/amountOfEmployeesActions';
import { fetchEscoOccupations } from '../../store/escoOccupations/escoOccupationsActions';
import { setActiveFilterValues } from '../../store/internshipVacancyOverview/internshipVacancyOverview';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { fetchWorkRemoteOptions } from '../../store/workRemote/workRemoteActions';
import { FormOption } from '../../types';

interface ConnectedInternshipVacancyOverviewFilterParams extends RouteParams {
    query?: string;
}

interface ConnectedInternshipVacancyOverviewFilterProps {
    currentPage: number;
}

const ConnectedInternshipVacancyOverviewFilter: FC<ConnectedInternshipVacancyOverviewFilterProps> = ({
    currentPage,
}): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const currentQuery = useQuery();
    const { query = '' } = useParams<ConnectedInternshipVacancyOverviewFilterParams>();
    const navigate = useNavigate();

    const isSbbUser = useLegacySelector(state => !!state.userPerson.data?.is_sbb_user);
    const schoolExperiences = useLegacySelector(state => state.userPerson.data?.school_experiences);
    const schoolExperience = schoolExperiences ? schoolExperiences[0] : undefined;

    const filterOptions = useLegacySelector(state => state.internshipVacancies.filterOptions);
    const filterOptionsIsLoading = useLegacySelector(state => state.internshipVacancies.filterOptionsLoading);
    const applyTypeOptions = [
        { label: trans('forms.preferences.applyType.regular'), value: 'apply_regular' },
        { label: trans('forms.preferences.applyType.anonymous'), value: 'apply_anonymous' },
    ];
    const educationLevelOptions = filterOptions?.educationLevels || [];
    const internshipTypeOptions = filterOptions?.internshipTypes || [];
    const gradesOptions = filterOptions?.grades || [];
    const sbbApprovalOptions = [
        { label: trans('forms.preferences.sbb.option'), value: 'has_sbb_approval' },
    ];

    const transformLegacyFilterOptionsToFormOption = (input: any): FormOption => ({ label: input.name, value: input.uuid });
    const transformLegacyFilterOptionsToInternshipTypeOption = (input: any): InternshipTypeOption => ({
        educationLevel: input?.education_level?.uuid || '',
        label: input.name,
        value: input.uuid,
    });
    //

    const isLoading = useTypedSelector(state => state.internshipVacancyOverviewReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.internshipVacancyOverviewReducer.activeFilterValues);

    const escoOccupationOptions = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const residenceOptions = useTypedSelector(state => state.residencesReducer.residences);
    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);
    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);
    const amountOfEmployeesOptions = useTypedSelector(state => state.amountOfEmployeesReducer.amountOfEmployeesOptions);

    const [filterValues, setFilterValues] = useState<InternshipVacancyOverviewFilterValues>(activeFilterValues);
    const talentMatchingFilterValues = {
        skills: activeFilterValues.skills,
        competencies: activeFilterValues.competencies,
        skillsToLearn: activeFilterValues.skillsToLearn,
        competenciesToLearn: activeFilterValues.competenciesToLearn,
    };

    useEffect((): void => {
        if (!filterOptions) legacyDispatch(fetchFilterOptions());

        if (escoOccupationOptions.length === 0) legacyDispatch(fetchEscoOccupations());
        if (sectorOptions.length === 0) legacyDispatch(fetchSectors());
        if (workRemoteOptions.length === 0) legacyDispatch(fetchWorkRemoteOptions());
        if (amountOfEmployeesOptions.length === 0) legacyDispatch(fetchAmountOfEmployeesOptions());
    }, []);

    useEffect((): void => {
        const { what, province, where } = decodeSearchQuery(query);

        const getSectorParams = (): string[] => {
            const querySector = currentQuery.get('sector') || '';

            if (activeFilterValues.sectors && activeFilterValues.sectors.length > 0) {
                const newSectors = querySector
                    ? [...activeFilterValues.sectors, querySector]
                    : [...activeFilterValues.sectors];

                return retrieveUniqueValues(newSectors);
            }

            return querySector ? [querySector] : [];
        };

        const newFilterValues = {
            ...activeFilterValues,
            pageNumber: currentPage,
            what: what || '',
            where: where || '',
            province: province || '',
            sectors: getSectorParams(),
            distance: where
                ? activeFilterValues.distance || defaultTravelDistance
                : 0,
        };

        setFilterValues(newFilterValues);
        legacyDispatch(setActiveFilterValues(newFilterValues));
    }, [currentPage]);

    useEffect((): void => {
        const {
            pageNumber,
            what,
            province,
            where,
            sectors,
        } = activeFilterValues;

        currentQuery.set('page', String(pageNumber));

        sectors.length > 0
            ? currentQuery.set('sector', sectors[sectors.length - 1])
            : currentQuery.delete('sector');

        const searchQuery = encodeSearchQuery({ what, province, where });

        if (searchQuery) {
            navigate(`${RoutePaths.internshipsQuery(searchQuery)}?${currentQuery}`, { replace: true });
        } else {
            navigate(`${RoutePaths.internships()}?${currentQuery}`, { replace: true });
        }
    }, [activeFilterValues]);

    const handleSearchSubmit = (value: VacancySearchFormValues): void => {
        const { what, where } = value;

        const newFilterValues = {
            ...talentMatchingFilterValues,
            pageNumber: 1,
            what,
            where,
            distance: where ? defaultTravelDistance : 0,
        };

        setFilterValues({
            ...filterValues,
            ...newFilterValues,
        });

        legacyDispatch(setActiveFilterValues({
            ...activeFilterValues,
            ...newFilterValues,
        }));
    };

    const handleFilterOpen = (): void => {
        if (!filterOptions) legacyDispatch(fetchFilterOptions());
    };

    const handleFilterChange = (value: Partial<InternshipVacancyOverviewFilterValues>): void => {
        setFilterValues({
            ...filterValues,
            ...value,
        });
    };

    const handleFilterReset = (): void => {
        const defaultFilters = defaultInternshipVacancyOverviewFilterValues();

        const newFilterValues = {
            ...defaultFilters,
            ...talentMatchingFilterValues,
        };

        setFilterValues(newFilterValues);
        legacyDispatch(setActiveFilterValues(newFilterValues));

        legacyDispatch(updateInternshipPreferencesSet({}));

        navigate(RoutePaths.internships());
    };

    const handleFilterSubmit = (): void => {
        const newFilterValues = {
            ...filterValues,
            ...talentMatchingFilterValues,
            pageNumber: 1,
        };

        legacyDispatch(setActiveFilterValues(newFilterValues));

        const newPreferencesSet = transformToLegacyInternshipVacanciesFilterParams(newFilterValues);
        legacyDispatch(updateInternshipPreferencesSet(newPreferencesSet));
    };

    return (
        <InternshipVacancyOverviewFilter
            isLoading={isLoading || filterOptionsIsLoading}
            isSbbUser={isSbbUser}
            activeFilterValues={activeFilterValues}
            filterValues={filterValues}
            schoolExperience={schoolExperience}
            whatOptions={escoOccupationOptions.map(escoOccupation => escoOccupation.name)}
            whereOptions={residenceOptions}
            applyTypeOptions={applyTypeOptions}
            sectorOptions={sectorOptions.map(transformSectorToSlugFormOption)}
            educationLevelOptions={educationLevelOptions.map(transformLegacyFilterOptionsToFormOption)}
            workRemoteOptions={workRemoteOptions}
            internshipTypeOptions={internshipTypeOptions.map(transformLegacyFilterOptionsToInternshipTypeOption)}
            gradeOptions={gradesOptions.map(transformLegacyFilterOptionsToFormOption)}
            amountOfEmployeesOptions={amountOfEmployeesOptions}
            sbbApprovalOptions={sbbApprovalOptions}
            onSearchSubmit={handleSearchSubmit}
            onFilterOpen={handleFilterOpen}
            onFilterChange={handleFilterChange}
            onFilterReset={handleFilterReset}
            onFilterSubmit={handleFilterSubmit}
        />
    );
};

export default ConnectedInternshipVacancyOverviewFilter;
