import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    EmailAddressChange,
    EmailAddressChangeFormData,
    EmailAddressChangeResource,
    transformToEmailAddressChange,
    transformToEmailAddressChangeRequest,
} from '../models/EmailAddress';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getEmailAddressChangesApiCall = async (): Promise<FetchResult<EmailAddressChange[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/user-identifier-changes`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<EmailAddressChangeResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const emailAddressChanges = doc.data.map(transformToEmailAddressChange);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: emailAddressChanges,
        };
    } catch (error) {
        console.error('[getEmailAddressChangesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postEmailAddressChangeApiCall = async (emailAddressChangeFormData: EmailAddressChangeFormData): Promise<FetchResult<EmailAddressChange, string>> => {
    try {
        const emailAddressChangeRequest = transformToEmailAddressChangeRequest(emailAddressChangeFormData);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/user-identifier-changes`, {
            method: 'POST',
            body: JSON.stringify(emailAddressChangeRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EmailAddressChangeResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const currentEmailAddressChange = transformToEmailAddressChange(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: currentEmailAddressChange,
        };
    } catch (error) {
        console.error('[postEmailAddressChangeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getEmailAddressChangeConfirmationApiCall = async (confirmationKey: string): Promise<FetchResult<EmailAddressChange, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/change-email/${confirmationKey}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EmailAddressChangeResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const confirmedEmailAddressChange = transformToEmailAddressChange(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: confirmedEmailAddressChange,
        };
    } catch (error) {
        console.error('[getEmailAddressChangeConfirmationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
