// @ts-ignore
import styled, { css } from 'styled-components';

import { Heading, Paragraph } from '../../../../../styledComponents/components/Typography';
import StyledComponentProps from '../../../../../styledComponents/theme/StyledComponentProps';

export const AnonymousHeading = styled(Heading)((props: StyledComponentProps) => css`
    margin-bottom: ${props.theme.space.md};
    line-height: 26px;
    font-size: 29px;
`);

export const CreateAccountHeading = styled(Heading)(() => css`
    font-size: 27px;
`);

export const AnonymousParagraph = styled(Paragraph)(() => css`
    font-size: 16px;
`);

export const UserTypeHeading = styled(Heading)((props: StyledComponentProps) => css`
    font-size: 23px;
    color: ${props.theme.colors.muted};
`);

export const UserTypeParagraph = styled(Paragraph)(() => css`
    font-size: 17px;
`);
