import styled, { css } from 'styled-components';

export default styled('span')(props => css`
    display: inline-block;
    margin-left: .875rem;
    padding: .25rem .75rem;
    white-space: nowrap;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: ${props.theme.colors.primary};
    border-radius: 2px;
    background-color: ${props.theme.colors.secondary};
    
    ${props.superScript && css`
        position: absolute;
        top: 0.2rem;
        right: 0;
        padding: 0.2rem 0.4rem;
        font-size: 9px;
        line-height: 1;
    `}
`);
