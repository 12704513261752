import { maxShortTextLength } from '../../../../constants';
import { FunFactsFormData } from '../../../../models/FunFacts';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMaxStringLength,
} from '../../../../services/ValidationService';

export type FunFactsFormErrors = FormErrors<FunFactsFormData>;

export const validateFunFactsFormData = (
    formData: FunFactsFormData,
): FormValidation<FunFactsFormErrors> => {
    const errors: FunFactsFormErrors = {
        funFacts: validateMaxStringLength('funFacts', formData.funFacts, maxShortTextLength),
    };

    return validateForm<FunFactsFormErrors>(errors);
};
