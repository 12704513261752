/* eslint-disable camelcase */
import { SelectOption } from '../components/@inputs/Select/Select';

interface Sector {
    slug: string;
    name: string;
}

export function sectorsToSelectBoxOptions(sectors: Sector[]): SelectOption[] {
    return sectors.map(sector => ({
        label: sector.name,
        value: sector.slug,
    }));
}

export interface EducationLevel {
    manual: boolean;
    name: string;
    slug: string;
    uuid: string;
}

export interface InternshipTypeResource {
    name: string;
    slug: string;
    uuid: string;
    price: number;
    education_level: EducationLevel;
}

export interface InternshipType {
    name: string;
    slug: string;
    uuid: string;
    price: number;
    educationLevel: EducationLevel;
}

export function transformToInternshipType(resource: InternshipTypeResource): InternshipType {
    return {
        name: resource.name,
        slug: resource.slug,
        uuid: resource.uuid,
        price: resource.price,
        educationLevel: resource.education_level,
    };
}

export interface GradeResource {
    name: string;
    uuid: string;
}

export interface Grade {
    name: string;
    uuid: string;
}

export interface Skill {
    name: string;
    slug: string;
    uuid: string;
}
/* eslint-enable camelcase */
