import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { BlogArticle } from '../../models/Blog';
import { AsyncReduxState } from '../../types';

export type BlogState = AsyncReduxState<{
    totalPages: number;
    totalResults: number;
    categories: string[];
    categorySizes: Record<string, number>;
    blogArticles: BlogArticle[];
}>;

const initialState: BlogState = {
    ...initialAsyncReduxState,
    totalPages: 1,
    totalResults: 0,
    categories: [],
    categorySizes: {},
    blogArticles: [],
};

const blogSlice = createSlice({
    name: 'blogReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BlogState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BlogState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setTotalPages(state, action: PayloadAction<number>): BlogState {
            return {
                ...state,
                totalPages: action.payload,
            };
        },
        setTotalResults(state, action: PayloadAction<number>): BlogState {
            return {
                ...state,
                totalResults: action.payload,
            };
        },
        setCategories(state, action: PayloadAction<string[]>): BlogState {
            return {
                ...state,
                categories: action.payload,
            };
        },
        setCategorySizes(state, action: PayloadAction<Record<string, number>>): BlogState {
            return {
                ...state,
                categorySizes: action.payload,
            };
        },
        setBlogArticles(state, action: PayloadAction<BlogArticle[]>): BlogState {
            return {
                ...state,
                blogArticles: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setTotalPages,
    setTotalResults,
    setCategories,
    setCategorySizes,
    setBlogArticles,
} = blogSlice.actions;

export default blogSlice;
