import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Checkbox, ErrorLabel, InputLabel } from '../../../components';
import { trans } from '../../../helpers/trans';
import { FormOption } from '../../../types';
import { IconButton } from '../..';

import './CheckboxList.scss';

interface CheckboxListProps {
    id?: string;
    label: string;
    hideLabel?: boolean;
    isHorizontal?: boolean;
    required?: boolean;
    optional?: boolean;
    name: string;
    value: string[];
    options: FormOption[];
    showLessThreshold?: number;
    tabIndex?: number;
    disabled?: boolean;
    error?: string;
    onChange: (values: string[]) => void;
    className?: string;
}

const CheckboxList: FC<CheckboxListProps> = ({
    id,
    label,
    hideLabel = false,
    isHorizontal = false,
    name,
    required = false,
    optional = false,
    value = [],
    options,
    showLessThreshold = 0,
    tabIndex,
    disabled,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const [showLessOptions, toggleShowLessOptions] = useToggle(!!showLessThreshold);

    const handleCheckboxChange = (option: FormOption, isChecked: boolean): void => {
        const newCheckedOptions = isChecked
            ? [...value, option.value]
            : [...value].filter(selectedOption => selectedOption !== option.value);

        onChange(newCheckedOptions);
    };

    const checkboxListWrapperClassNames = classNames('checkbox-list__wrapper', {
        'checkbox-list__wrapper--is-horizontal': isHorizontal,
        'checkbox-list__wrapper--has-error': !!error,
    });

    return (
        <div id={id} className={`checkbox-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    optional={optional}
                    className="checkbox-list__label"
                />
            )}

            {options.length > 0 && (
                <div className={checkboxListWrapperClassNames}>
                    {options.map((option, index) => {
                        const isChecked = value.includes(option.value);
                        const isHidden = showLessOptions && index >= showLessThreshold && !isChecked;

                        const handleSelect = (isChecked: boolean): void => handleCheckboxChange(option, isChecked);

                        const optionClassNames = classNames('checkbox-list__checkbox', {
                            'checkbox-list__checkbox--is-hidden': isHidden,
                        });

                        return (
                            <Checkbox
                                key={option.value}
                                label={option.label}
                                name={name}
                                value={option.value}
                                checked={isChecked}
                                tabIndex={tabIndex}
                                disabled={disabled}
                                onChange={handleSelect}
                                className={optionClassNames}
                            />
                        );
                    })}
                </div>
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="checkbox-list__error-label"
                />
            )}

            {showLessThreshold && options.length > showLessThreshold ? (
                <IconButton
                    icon={showLessOptions ? 'chevron-down' : 'chevron-up'}
                    text={showLessOptions ? trans('actions.show.more') : trans('actions.show.less')}
                    tabIndex={tabIndex}
                    onClick={toggleShowLessOptions}
                    className="checkbox-list__toggle-button"
                />
            ) : null}
        </div>
    );
};

export default CheckboxList;
