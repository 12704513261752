import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Skeleton } from '../../../components';
import { DetailCard } from '../../../compositions';
import { generateIdArray } from '../../../helpers/array';
import { filterObjectOnFilledValues } from '../../../helpers/object';
import { trans } from '../../../helpers/trans';
import { VacancyProperties } from '../../../models/NewestVacancy';
import { VacancyProperty } from './subcomponents';

import './VacancyPropertiesCard.scss';

interface VacancyPropertiesCardProps {
    isLoading: boolean;
    isSbbUser: boolean;
    properties?: VacancyProperties;
    className?: string;
}

const VacancyPropertiesCard: FC<VacancyPropertiesCardProps> = ({
    isLoading,
    isSbbUser,
    properties,
    className = '',
}): ReactElement => {
    const skeletonAmount = 5;
    const skeletons = generateIdArray(skeletonAmount);

    const [approvedFieldsOfStudy, setApprovedFieldsOfStudy] = useState<string[]>([]);
    const [propertiesWithValue, setPropertiesWithValue] = useState<[string, string | string[]][]>([]);

    useEffect((): void => {
        if (properties) {
            const sbbUnrelatedProperties = {
                ...properties,
                fieldsOfStudy: isSbbUser
                    ? properties.fieldsOfStudy
                    : [...properties.approvedFieldsOfStudy, ...properties.fieldsOfStudy],
                approvedFieldsOfStudy: [],
            };

            const filteredProperties = filterObjectOnFilledValues(sbbUnrelatedProperties);

            setPropertiesWithValue(Object.entries(filteredProperties));
            setApprovedFieldsOfStudy(properties.approvedFieldsOfStudy || []);
        }
    }, [properties, isSbbUser]);

    return (
        <DetailCard
            title={trans('vacancyDetail.properties.title')}
            className={`vacancy-properties-card ${className}`}
        >
            {isLoading && (
                <div className="vacancy-properties-card__skeleton-list">
                    {skeletons.map(skeleton => (
                        <div key={skeleton} className="vacancy-properties-card__skeleton-item">
                            <Skeleton className="vacancy-properties-card__skeleton" />
                            <Skeleton className="vacancy-properties-card__skeleton" />
                        </div>
                    ))}
                </div>
            )}

            {!isLoading && (
                <ul className="vacancy-properties-card__list">
                    {propertiesWithValue.map(propertyEntry => {
                        const [key, value] = propertyEntry;
                        const tags = [value].flat();

                        return (
                            <VacancyProperty
                                key={key}
                                property={key}
                                tags={tags}
                                className="vacancy-properties-card__item"
                            />
                        );
                    })}

                    {isSbbUser && approvedFieldsOfStudy.length > 0 && (
                        <VacancyProperty
                            isSbbApproved
                            property="approvedFieldsOfStudy"
                            tags={approvedFieldsOfStudy}
                            className="vacancy-properties-card__item"
                        />
                    )}
                </ul>
            )}
        </DetailCard>
    );
};

export default VacancyPropertiesCard;
