import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getSchoolsApiCall } from '../../services/SchoolService';
import { TypedDispatch } from '..';
import { setError, setIsLoading, setSchools } from './schools';

export const fetchSchools = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const schoolsResponse = await getSchoolsApiCall();

        if (!isFetchResultSuccessful(schoolsResponse)) {
            dispatch(setError(schoolsResponse.error));
            return;
        }

        dispatch(setSchools(schoolsResponse.data));
    } catch (error) {
        console.error('[fetchSchools]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
