import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Address as OldAddress } from '../../_old/app_talentz/models/Address';
import { registerUserProfile } from '../../_old/app_talentz/store/register/registerActions';
import { RegisterCompanyContainer } from '../../containers';
import { hotjarEvent } from '../../helpers/hotjar';
import { FetchResultType } from '../../models/FetchResult';
import { KvkSearchResult } from '../../models/KvkSearchResult';
import { RoleType } from '../../models/User';
import { getAddressApiCall } from '../../services/AddressService';
import { CompanyDataFormValues } from '../../services/CompanyService';
import { RegisterCompanyApiValues } from '../../services/RegisterCompanyService';
import {
    useLegacyDispatch,
    useLegacySelector,
    useTypedDispatch,
    useTypedSelector,
} from '../../store';
import { fetchAmountOfEmployeesOptions } from '../../store/amountOfEmployees/amountOfEmployeesActions';
import { fetchKvkBaseProfile } from '../../store/kvkBaseProfile/kvkBaseProfileActions';
import { fetchKvkSearchResults } from '../../store/kvkSearchResults/kvkSearchResultsActions';

const ConnectedRegisterCompanyData: FC = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const dispatch = useTypedDispatch();

    const isLoading = useLegacySelector(state => state.register.loading);
    const errors = useLegacySelector(state => state.register.errors);

    const amountOfEmployeesOptions = useTypedSelector(state => state.amountOfEmployeesReducer.amountOfEmployeesOptions);

    const kvkSearchResultsIsLoading = useTypedSelector(state => state.kvkSearchResultsReducer.isLoading);
    const kvkSearchResults = useTypedSelector(state => state.kvkSearchResultsReducer.kvkSearchResults);

    const kvkBaseProfileIsLoading = useTypedSelector(state => state.kvkBaseProfileReducer.isLoading);
    const kvkBaseProfile = useTypedSelector(state => state.kvkBaseProfileReducer.kvkBaseProfile);

    const [addressIsLoading, setAddressIsLoading] = useState<boolean>(false);
    const [address, setAddress] = useState<OldAddress>();

    useEffect((): void => {
        if (amountOfEmployeesOptions.length === 0) dispatch(fetchAmountOfEmployeesOptions());
    }, []);

    const handleKvkSearchSubmit = (tradeName: string, location: string): void => {
        dispatch(fetchKvkSearchResults(tradeName, location));
    };

    const handleSearchResultSelect = (kvkSearchResult: KvkSearchResult): void => {
        setAddress(undefined);
        dispatch(fetchKvkBaseProfile(kvkSearchResult.kvkNumber));
    };

    const handleAddressInput = (formValues: CompanyDataFormValues): void => {
        // TODO: Convert to action, linked with reducers
        (async function getAddress() {
            setAddressIsLoading(true);
            setAddress(undefined);

            const response = await getAddressApiCall(
                formValues.zipcode,
                formValues.streetNumber,
                formValues.extension,
            );

            if (response.type === FetchResultType.Success) {
                setAddress(response.data);
            }

            setAddressIsLoading(false);
        }());
    };

    const handleSubmit = (data: RegisterCompanyApiValues): void => {
        legacyDispatch(registerUserProfile(data));

        hotjarEvent('vpv', `/registreren/notificaties/${RoleType.company}`);
    };

    return (
        <RegisterCompanyContainer
            isLoading={isLoading}
            kvkSearchResultsIsLoading={kvkSearchResultsIsLoading}
            kvkBaseProfileIsLoading={kvkBaseProfileIsLoading}
            addressIsLoading={addressIsLoading}
            kvkSearchResults={kvkSearchResults}
            kvkBaseProfile={kvkBaseProfile}
            address={address}
            errors={errors}
            amountOfEmployeesOptions={amountOfEmployeesOptions}
            onKvkSearchSubmit={handleKvkSearchSubmit}
            onKvkSearchResultSelect={handleSearchResultSelect}
            onAddressInput={handleAddressInput}
            onSubmit={handleSubmit}
        />
    );
};

export default ConnectedRegisterCompanyData;
