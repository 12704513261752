import React, { FC, ReactElement } from 'react';

import { SbbApprovedLabel } from '../../../../../components';
import { Tag } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';

import './VacancyProperty.scss';

interface VacancyPropertyProps {
    isSbbApproved?: boolean;
    property: string;
    tags: string[];
    className?: string;
}

const VacancyProperty: FC<VacancyPropertyProps> = ({
    isSbbApproved,
    property,
    tags,
    className = '',
}): ReactElement => (
    <li className={`vacancy-property ${className}`}>
        <h3 className="vacancy-property__title">
            {trans(`vacancyDetail.properties.key.${property}`)}
        </h3>

        <ul className="vacancy-property__tag-list">
            {tags.map(tag => (
                <li key={property + tag} className="vacancy-property__tag-item">
                    {isSbbApproved ? (
                        <div className="vacancy-property__sbb-approved-tag">
                            <span className="vacancy-property__sbb-approved-tag-text">
                                {tag}
                            </span>

                            <SbbApprovedLabel className="vacancy-property__sbb-approved-tag-label" />
                        </div>
                    ) : (
                        <Tag text={tag} className="vacancy-property__tag" />
                    )}
                </li>
            ))}
        </ul>
    </li>
);

export default VacancyProperty;
