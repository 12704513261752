import React, {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';

import { trans } from '../../../helpers/trans';
import { defaultToastProperties, defaultToastRevealDuration } from '../../../models/Toast';
import { useLegacySelector, useTypedDispatch } from '../../../store';
import { clearCompany, fetchCompany } from '../../../store/company/companyActions';
import { addToast } from '../../../store/toast/toastActions';

interface ConnectedCompanyProfileWrapperProps {
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyProfileWrapper: FC<PropsWithChildren<ConnectedCompanyProfileWrapperProps>> = ({
    companyUuid,
    className = '',
    children,
}): ReactElement => {
    const location = useLocation() as { state: { firstVisit?: boolean } };

    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    useEffect((): void => {
        if (location?.state?.firstVisit) {
            dispatch(addToast({
                ...defaultToastProperties(),
                title: trans('companyProfile.reminderToast.title'),
                description: trans('companyProfile.reminderToast.description'),
                revealDuration: defaultToastRevealDuration * 2,
            }));
        }
    }, [location]);

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            dispatch(clearCompany());
        };
    }, []);

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export default ConnectedCompanyProfileWrapper;
