import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Person } from '../../models/Person';
import { ValidationErrors } from '../../services/PersonService';

export interface UserPersonState {
    loading: boolean;
    updating: boolean;
    internshipPreferencesUpdating: boolean;
    jobPreferencesUpdating: boolean;
    data: Person | null;
    preFillData: any;
    isEmpty: boolean;
    errors: ValidationErrors;
}

const initialState: UserPersonState = {
    loading: false,
    updating: false,
    internshipPreferencesUpdating: false,
    jobPreferencesUpdating: false,
    data: null,
    preFillData: null,
    isEmpty: false,
    errors: {},
};

const userPersonSlice = createSlice({
    name: 'userPerson',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): UserPersonState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setUpdating(state, action: PayloadAction<boolean>): UserPersonState {
            return {
                ...state,
                updating: action.payload,
            };
        },
        setInternshipPreferencesUpdating(state, action: PayloadAction<boolean>): UserPersonState {
            return {
                ...state,
                internshipPreferencesUpdating: action.payload,
            };
        },
        setJobPreferencesUpdating(state, action: PayloadAction<boolean>): UserPersonState {
            return {
                ...state,
                jobPreferencesUpdating: action.payload,
            };
        },
        setData(state, action: PayloadAction<Person | null>): UserPersonState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setPrefillData(state, action: PayloadAction<any>): UserPersonState {
            return {
                ...state,
                preFillData: action.payload,
            };
        },
        setIsEmpty(state, action: PayloadAction<boolean>): UserPersonState {
            return {
                ...state,
                isEmpty: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<ValidationErrors>): UserPersonState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setUpdating,
    setInternshipPreferencesUpdating,
    setJobPreferencesUpdating,
    setData,
    setPrefillData,
    setIsEmpty,
    setErrors,
} = userPersonSlice.actions;

export default userPersonSlice;
