import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLocation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 70 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.2,20c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2l0,0c9,0,16.2-7.3,16.2-16.2S44.2,20,35.2,20z M26.5,36.2c0-4.8,3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8S40,45,35.2,45S26.5,41.1,26.5,36.2z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M35,100l-3.5-4C30,94.5,0,61.5,0,35C0,15.5,15.5,0,35,0s35,15.5,35,35c0,26.5-30,59.5-31.5,61L35,100z M35,10c-14,0-25,11-25,25c0,18,17.5,40.5,25,50c7.5-9.5,25-32,25-50C60,21,49,10,35,10z" />
    </svg>
);

export default IconLocation;
