import React, { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoleType } from '../../../../../models/User';
import { RoutePaths } from '../../../../../routes';
import { useLegacyDispatch, useLegacySelector } from '../../../../../store';
import Loader from '../../../../components/Loader/Loader';
import getQueryParam from '../../../../helpers/getQueryParam';
import Page from '../../../../styledComponents/components/Page';
import { getUserByToken, registerLinkedInUser } from '../../../store/register/registerActions';
import RegisterEmployee from './RegisterEmployee';
import RegisterPerson from './RegisterPerson';

const RegisterUserSwitch: FC = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useLegacySelector(state => state.legacyUser.data);
    const userRole = useLegacySelector(state => state.legacyUser.role);

    const activationToken = useLegacySelector(state => state.register.activationToken);

    useEffect((): void => {
        if (activationToken) {
            legacyDispatch(getUserByToken(activationToken, navigate));
            return;
        }

        const userType = getQueryParam('userType', location.search);
        const linkedInCode = getQueryParam('code', location.search);

        if (userType && linkedInCode && !user) {
            legacyDispatch(registerLinkedInUser(userType, linkedInCode, navigate));
            return;
        }

        navigate(RoutePaths.home());
    }, []);

    const RegisterComponent = userRole !== RoleType.company
        ? RegisterPerson
        : RegisterEmployee;

    return userRole
        ? <RegisterComponent />
        : (
            <Page>
                <Helmet>
                    <title>Registreren</title>
                    <meta name="description" content="Doorloop nu het registratie proces en vul je profiel zo volledig mogelijk in. Hierdoor match je met de perfecte stage of stagiair op TalentZ!" />
                </Helmet>
                <Loader />
            </Page>
        );
};

export default RegisterUserSwitch;
