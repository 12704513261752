import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconUpload: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 87.1" className={`icon ${className}`}>
        <path d="M50,0l23.5,23.5l-7.1,7.1L55,19.1v47.9H45V19.1L33.5,30.6l-7.1-7.1L50,0z" />
        <path d="M7.5,63.3v16.2h85V63.3h7.5v23.8H0V63.3H7.5z" />
    </svg>
);

export default IconUpload;
