import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { SchoolExperiencesCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { transformEducationLevelToFormOption } from '../../../models/EducationLevels';
import { SchoolExperience, SchoolExperienceFormData } from '../../../models/NewSchoolExperience';
import { RoutePaths } from '../../../routes';
import { useLegacyDispatch, useLegacySelector, useTypedSelector } from '../../../store';
import {
    addCandidateSchoolExperience,
    clearCandidate,
    clearCandidateSuccess,
    deleteCandidateSchoolExperience,
    editCandidateSchoolExperience,
    fetchCandidate,
} from '../../../store/candidate/candidateActions';
import { fetchEducationLevels } from '../../../store/educationLevels/educationLevelsActions';
import { fetchFieldsOfStudy } from '../../../store/fieldsOfStudy/fieldsOfStudyActions';
import { fetchLearningPaths } from '../../../store/learningPaths/learningPathsActions';
import { fetchSchools } from '../../../store/schools/schoolsActions';

interface ConnectedSchoolExperiencesCardProps {
    activationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedSchoolExperiencesCard: FC<ConnectedSchoolExperiencesCardProps> = ({
    activationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateNeedsUpdate = useTypedSelector(state => state.candidateReducer.isSuccessful);

    const educationLevels = useTypedSelector(state => state.educationLevelsReducer.educationLevels);
    const educationSubLevels = useTypedSelector(state => state.educationLevelsReducer.educationSubLevels);
    const learningPaths = useTypedSelector(state => state.learningPathsReducer.learningPaths);
    const fieldsOfStudy = useTypedSelector(state => state.fieldsOfStudyReducer.fieldsOfStudy);
    const schools = useTypedSelector(state => state.schoolsReducer.schools);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    const fetchCandidateData = (): void => {
        const candidateId = candidateUuid || person?.uuid;

        if (candidateId) {
            legacyDispatch(fetchCandidate(candidateId));
        }
    };

    useEffect((): () => void => {
        if (shouldFetchCandidate) fetchCandidateData();

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) legacyDispatch(clearCandidate());
        };
    }, []);

    useEffect((): void => {
        if (shouldFetchCandidate && candidateNeedsUpdate) {
            fetchCandidateData();
            legacyDispatch(clearCandidateSuccess());
        }
    }, [candidateNeedsUpdate]);

    useEffect((): void => {
        if (isEditable) {
            if (educationLevels.length === 0) legacyDispatch(fetchEducationLevels());
            if (learningPaths.length === 0) legacyDispatch(fetchLearningPaths());
            if (fieldsOfStudy.length === 0) legacyDispatch(fetchFieldsOfStudy());
            if (schools.length === 0) legacyDispatch(fetchSchools());
        }
    }, [isEditable]);

    const handleAddSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData): void => {
        legacyDispatch(addCandidateSchoolExperience(schoolExperienceFormData));
    };

    const handleEditSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData): void => {
        legacyDispatch(editCandidateSchoolExperience(schoolExperienceFormData));
    };

    const handleDeleteSchoolExperience = (schoolExperience: SchoolExperience): void => {
        legacyDispatch(deleteCandidateSchoolExperience(schoolExperience));
    };

    return (
        <SchoolExperiencesCard
            activationRef={activationRef}
            isLoading={isLoading}
            isEditable={isEditable}
            schoolExperiences={candidate?.schoolExperiences}
            educationLevelOptions={educationLevels.map(transformEducationLevelToFormOption)}
            educationSubLevelOptions={educationSubLevels}
            learningPathOptions={learningPaths}
            fieldOfStudyOptions={fieldsOfStudy}
            schoolOptions={schools}
            onAddSchoolExperience={handleAddSchoolExperience}
            onEditSchoolExperience={handleEditSchoolExperience}
            onDeleteSchoolExperience={handleDeleteSchoolExperience}
            className={className}
        />
    );
};

export default ConnectedSchoolExperiencesCard;
