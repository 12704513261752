import axios from 'axios';
import { Dispatch } from 'redux';

import { setAppUpdates, setErrors } from './app';

export function updateAppUpdate(updateUUid: string) {
    return (dispatch: Dispatch) => axios.put(`/me/updates/${updateUUid}`)
        .then(response => {
            dispatch(setAppUpdates(response.data.data));

            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(setErrors(error.response.data.errors));

            return Promise.reject(error.response.data.errors);
        });
}
