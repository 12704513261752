import React, {
    CSSProperties,
    FC,
    memo,
    ReactElement,
} from 'react';

import { randomInBetweenValue } from '../../helpers/number';

import './Skeleton.scss';

interface SkeletonProps {
    className?: string;
}

const Skeleton: FC<SkeletonProps> = ({
    className = '',
}): ReactElement => {
    const cssVariables = {
        '--skeleton-width': `${randomInBetweenValue(45, 95)}%`,
    } as CSSProperties;

    return (
        <span style={cssVariables} className={`skeleton ${className}`} />
    );
};

export default memo(Skeleton);
