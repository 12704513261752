import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Checkbox } from '../../../components';
import { CheckboxList, FormActionButtons } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { DriversLicense, DriversLicensesFormData, transformDriversLicenseToFormOption } from '../../../models/DriversLicenses';
import { FormOption, FormProps } from '../../../types';

import './DriversLicensesForm.scss';

interface DriversLicensesFormProps extends FormProps<DriversLicensesFormData> {
    driversLicenses: FormOption[];
    driversLicenseOptions: DriversLicense[];
    className?: string;
}

const DriversLicensesForm: FC<DriversLicensesFormProps> = ({
    driversLicenses,
    driversLicenseOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [noDriversLicenseSelected, setNoDriversLicenseSelected] = useState<boolean>(false);
    const [selectedDriversLicenses, setSelectedDriversLicenses] = useState<string[]>(driversLicenses.map(license => license.value));

    const allOptions = driversLicenseOptions.map(transformDriversLicenseToFormOption);

    const noLicense = driversLicenseOptions.find(option => option.type === '');
    const noLicenseOption = noLicense
        ? transformDriversLicenseToFormOption(noLicense)
        : undefined;

    const licenseOptions = driversLicenseOptions
        .filter(option => option.type !== '')
        .map(transformDriversLicenseToFormOption);

    useEffect((): void => {
        const driverLicenseIds = driversLicenses.map(license => license.value);

        if (noLicenseOption && driverLicenseIds.includes(noLicenseOption.value)) {
            setNoDriversLicenseSelected(true);
        }
    }, []);

    const handleChangeNoDriversLicenseSelected = (isChecked: boolean): void => {
        setNoDriversLicenseSelected(isChecked);
        setSelectedDriversLicenses([]);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            driversLicenses: noLicenseOption && noDriversLicenseSelected
                ? [noLicenseOption]
                : allOptions.filter(license => selectedDriversLicenses.includes(license.value)),
        });
    };

    return (
        <form onSubmit={handleSubmit} className={`drivers-licenses-form ${className}`}>
            <h2 className="drivers-licenses-form__title">
                {trans('forms.driversLicenses.input.driversLicenses')}
            </h2>

            {noLicenseOption && (
                <Checkbox
                    label={noLicenseOption.label}
                    value={noLicenseOption.value}
                    checked={noDriversLicenseSelected}
                    onChange={handleChangeNoDriversLicenseSelected}
                />
            )}

            <CheckboxList
                label={trans('forms.driversLicenses.input.driversLicenses')}
                hideLabel
                name="drivers-licenses"
                options={licenseOptions}
                value={selectedDriversLicenses}
                disabled={noDriversLicenseSelected}
                onChange={setSelectedDriversLicenses}
                className="drivers-licenses-form__checkbox-list"
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="drivers-licenses-form__action-buttons"
            />
        </form>
    );
};

export default DriversLicensesForm;
