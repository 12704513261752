import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Company } from '../../models/Company';
import { AsyncReduxState } from '../../types';

export type UserState = AsyncReduxState<{
    company?: Company;
}>;

const initialState: UserState = {
    ...initialAsyncReduxState,
    company: undefined,
};

const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): UserState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCompany(state, action: PayloadAction<Company>): UserState {
            return {
                ...state,
                company: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCompany,
} = userSlice.actions;

export default userSlice;
