import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { initialAsyncReduxState } from '../../constants';
import { NewestVacancy } from '../../models/NewestVacancy';
import { defaultRelatedVacancyOverviewFilterValues, VacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { AsyncReduxState } from '../../types';

export type RelatedVacancyOverviewState = AsyncReduxState<{
    activeFilterValues: VacancyOverviewFilterValues;
    vacancies: NewestVacancy[];
    pagination?: Pagination;
}>;

const initialState: RelatedVacancyOverviewState = {
    ...initialAsyncReduxState,
    activeFilterValues: defaultRelatedVacancyOverviewFilterValues(),
    vacancies: [],
    pagination: undefined,
};

export const relatedVacancyOverviewSlice = createSlice({
    name: 'relatedVacancyOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): RelatedVacancyOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): RelatedVacancyOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<VacancyOverviewFilterValues>): RelatedVacancyOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setVacancies(state, action: PayloadAction<NewestVacancy[]>): RelatedVacancyOverviewState {
            return {
                ...state,
                vacancies: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination | undefined>): RelatedVacancyOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveFilterValues,
    setVacancies,
    setPagination,
} = relatedVacancyOverviewSlice.actions;

export default relatedVacancyOverviewSlice;
