import { MatchProperty } from '../_old/app_talentz/models/MatchProperty';
import { authorizedFetch, TokenEnvironmentType } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import { ApiResponse, FetchResult, FetchResultType } from '../models/FetchResult';
import {
    Grade,
    GradeResource,
    InternshipType,
    InternshipTypeResource,
    Skill,
    transformToInternshipType,
} from '../models/VacancyOptions';

export async function getAllEducationLevelsApiCall(): Promise<FetchResult<MatchProperty[], string>> {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/education-levels?only=uuid,name`, {
            tokenEnvironmentType: TokenEnvironmentType.Legacy,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const responseData: ApiResponse<MatchProperty[]> = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: responseData.data,
        };
    } catch (error) {
        console.error('[fetchAllEducationLevels]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
}

export async function fetchAllInternshipTypeOptions(): Promise<FetchResult<InternshipType[], string>> {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/internship-types`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const responseData: ApiResponse<InternshipTypeResource[]> = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: responseData.data.map(transformToInternshipType),
        };
    } catch (error) {
        console.error('[fetchAllInternshipTypeOptions]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
}

export async function fetchAllGradeOptions(): Promise<FetchResult<Grade[], string>> {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/grades?only=uuid,name`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const responseData: ApiResponse<GradeResource[]> = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: responseData.data,
        };
    } catch (error) {
        console.error('[fetchAllGradeOptions]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
}

export async function createSkillApiCall(skill: string): Promise<FetchResult<Skill, string>> {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V1_URL}/api/v1/skills`, {
            tokenEnvironmentType: TokenEnvironmentType.Legacy,
            method: 'POST',
            body: JSON.stringify({
                name: skill,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const responseData: ApiResponse<Skill> = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: responseData.data,
        };
    } catch (error) {
        console.error('[fetchCreateSkill]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
}
