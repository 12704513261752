import React, { FC, ReactElement, useState } from 'react';

import {
    DetailCard,
    IconButton,
    Modal,
    ModalContent,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';
import { Candidate } from '../../../models/Candidate';
import { ApplicationMatchForm } from '../..';

import './ApplicationMatchCard.scss';

interface ApplicationMatchCardProps {
    isLoading: boolean;
    application: Application;
    candidate?: Candidate;
    onMatchApplication: (application: Application, shouldArchive: boolean) => void;
    className?: string;
}

const ApplicationMatchCard: FC<ApplicationMatchCardProps> = ({
    isLoading,
    application,
    candidate,
    onMatchApplication,
    className = '',
}): ReactElement => {
    const [matchModalIsOpen, setMatchModalIsOpen] = useState<boolean>(false);

    const openMatchModal = (): void => setMatchModalIsOpen(true);
    const closeMatchModal = (): void => setMatchModalIsOpen(false);

    const handleMatchApplication = (shouldArchive: boolean): void => {
        if (application) {
            onMatchApplication(application, shouldArchive);
        }
    };

    return (
        <DetailCard
            title={trans('containers.applicationMatchCard.title')}
            className={`application-match-card ${className}`}
        >
            <p className="application-match-card__description">
                {trans('containers.applicationMatchCard.description')}
            </p>

            <IconButton
                icon="check"
                text={trans('containers.applicationMatchCard.button.foundMatch')}
                disabled={isLoading}
                onClick={openMatchModal}
                className="application-match-card__button"
            />

            {matchModalIsOpen && (
                <Modal onClose={closeMatchModal}>
                    <ModalContent title={trans('containers.applicationMatchCard.matchModal.title')}>
                        <ApplicationMatchForm
                            candidate={candidate}
                            onSubmit={handleMatchApplication}
                            onCancel={closeMatchModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default ApplicationMatchCard;
