import { RegisterCompanyApiErrors } from './RegisterCompanyService';

export enum Gender {
    male = 'male',
    female = 'female',
}

/* eslint-disable camelcase */
export interface RegisterCompanyApiValues {
    address: string;
    amount_of_employees_id: string;
    city: string;
    latitude: string;
    longitude: string;
    position: string;
    province: string;
    street_number: string;
    street_number_extension: string;
    website: string;
    zipcode: string;
}
/* eslint-enable camelcase */

export interface EmployeeDataFormValues {
    firstName: string;
    insertion?:string;
    lastName: string;
    gender: string;
    phoneNumber: string;
    position: string;
}

export interface EmployeeDataFormErrors {
    firstName?: string;
    insertion?: string;
    lastName?: string;
    phoneNumber?: string;
    position?: string;
}

export const generateEmptyEmployeeDataFormValues = (): EmployeeDataFormValues => ({
    firstName: '',
    lastName: '',
    gender: Gender.male,
    phoneNumber: '',
    position: '',
});

export const transformRegisterCompanyApiErrorsToEmployeeDataFormErrors = (
    errors: RegisterCompanyApiErrors,
): EmployeeDataFormErrors => ({
    firstName: errors.first_name ? errors.first_name[1] : undefined,
    insertion: errors.insertion ? errors.insertion[1] : undefined,
    lastName: errors.last_name ? errors.last_name[1] : undefined,
    phoneNumber: errors.phone_number ? errors.phone_number[1] : undefined,
    position: errors.position ? errors.position[1] : undefined,
});
