import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconProfile: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,0C22.4,0,0,22.4,0,50s22.4,50,50,50s50-22.4,50-50S77.6,0,50,0z M25.4,81.4c2.1-4.5,15.2-8.9,24.6-8.9s22.6,4.4,24.7,8.9C67.8,86.8,59.3,90,50,90S32.2,86.8,25.4,81.4z M81.8,74.2C74.7,65.4,57.3,62.5,50,62.5s-24.6,2.9-31.8,11.7C13.1,67.4,10,59.1,10,50c0-22,18-40,40-40s40,18,40,40C90,59.1,86.9,67.4,81.8,74.2z" />
        <path d="M50,20c-9.7,0-17.5,7.8-17.5,17.5S40.3,55,50,55s17.5-7.8,17.5-17.5S59.7,20,50,20z M50,45c-4.2,0-7.5-3.4-7.5-7.5c0-4.2,3.3-7.5,7.5-7.5s7.5,3.3,7.5,7.5C57.5,41.6,54.2,45,50,45z" />
    </svg>
);

export default IconProfile;
