import React, { ChangeEvent, FC, ReactElement } from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';

import './NumberInput.scss';

interface NumberInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    required?: boolean;
    optional?: boolean;
    error?: string;
    metric?: string;
    onChange: (value: number) => void;
    className?: string;
}

const NumberInput: FC<NumberInputProps> = ({
    label,
    hideLabel = false,
    required = false,
    optional = false,
    error = '',
    metric = '',
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(Number(event.currentTarget.value));
    };

    const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`number-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    optional={optional}
                    className="number-input__label"
                />
            )}

            <div className="number-input__input-wrapper">
                {metric && (
                    <span className="number-input__metric">
                        {metric}
                    </span>
                )}
                <Input
                    {...inputProps}
                    type="number"
                    required={required}
                    error={error}
                    onChange={handleChange}
                    onFocus={handleFocus}
                />
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="number-input__error-label"
                />
            )}
        </label>
    );
};

export default NumberInput;
