import React, { FC, ReactElement, useEffect } from 'react';

import { RelatedBlogArticles } from '../../containers';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchRelatedBlogArticles } from '../../store/blogDetail/blogDetailActions';

interface ConnectedRelatedBlogArticlesProps {
    slug: string;
    className?: string;
}

const ConnectedRelatedBlogArticles: FC<ConnectedRelatedBlogArticlesProps> = ({
    slug,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.blogDetailReducer.isLoading);
    const relatedBlogArticles = useTypedSelector(state => state.blogDetailReducer.relatedBlogArticles);

    useEffect((): void => {
        dispatch(fetchRelatedBlogArticles(slug));
    }, [slug]);

    return (
        <RelatedBlogArticles
            isLoading={isLoading}
            relatedBlogArticles={relatedBlogArticles}
            className={className}
        />
    );
};

export default ConnectedRelatedBlogArticles;
