import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronSort: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 47.1 100" className={`icon ${className}`}>
        <polygon points="0,76.5 23.5,100 47.1,76.5 40,69.4 23.5,85.9 7.1,69.4" />
        <path d="M47.1,23.5L23.5,0L0,23.5l7.1,7.1l16.5-16.5L40,30.6C40,30.6,47.1,23.5,47.1,23.5z" />
    </svg>
);

export default IconChevronSort;
