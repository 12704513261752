import React, { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Page } from '../../components';
import {
    ConnectedHomeCallToAction,
    ConnectedHomeHero,
    ConnectedHomeVacancyIndex,
    ConnectedRecentVacancies,
    ConnectedRegisterModal,
} from '../../connectors';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';

import './Home.scss';

const Home: FC = (): ReactElement => {
    const [registrationModalIsOpen, setRegistrationModalIsOpen] = useState<boolean>(false);

    const openRegistrationModal = (): void => setRegistrationModalIsOpen(true);
    const closeRegistrationModal = (): void => setRegistrationModalIsOpen(false);

    return (
        <Page className="home-page">
            <Helmet>
                <title>{trans('home.metadata.title')}</title>
                <meta property="og:title" content={createMetadataTitle(trans('home.metadata.title'))} />
                <meta property="og:description" content={trans('basic.appDescription')} />
                <meta name="description" content={trans('basic.appDescription')} />
            </Helmet>

            <section className="home-page__section home-page__hero">
                <div className="home-page__wrapper">
                    <ConnectedHomeHero />
                </div>
            </section>

            <section className="home-page__section">
                <div className="home-page__wrapper">
                    <ConnectedRecentVacancies />
                </div>
            </section>

            <section className="home-page__section">
                <div className="home-page__wrapper">
                    <ConnectedHomeVacancyIndex className="home-page__vacancy-index" />
                </div>
            </section>

            <section className="home-page__section">
                <div className="home-page__wrapper">
                    <ConnectedHomeCallToAction onCallToActionClick={openRegistrationModal} />
                </div>
            </section>

            {registrationModalIsOpen && (
                <ConnectedRegisterModal
                    role={RoleType.company}
                    onClose={closeRegistrationModal}
                />
            )}
        </Page>
    );
};

export default Home;
