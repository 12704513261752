import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../../components';
import {
    ButtonList,
    FilterButton,
    Modal,
    SearchableValueInput,
    SkillsSelector,
    SubmitButton,
} from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { EscoOccupation, transformEscoOccupationToSearchableOption } from '../../../../models/Esco';
import { Skill, SkillsFormData, SkillType } from '../../../../models/Skills';
import { SearchableOption } from '../../../../types';
import { TalentMatchingInputWrapper } from '..';

import './TalentMatchingSkills.scss';

interface TalentMatchingSkillsProps {
    isLoading: boolean;
    isValidCandidate: boolean;
    isToLearn?: boolean;
    value: Skill[];
    skillOptions: Skill[];
    skillsError: string;
    occupationOptions?: EscoOccupation[];
    onSelectOccupation?: (occupation: EscoOccupation) => void;
    onSubmitToProfile: (skillsFormData: SkillsFormData) => void;
    onSubmit: (skillsFormData: SkillsFormData) => void;
    className?: string;
}

const TalentMatchingSkills: FC<TalentMatchingSkillsProps> = ({
    isLoading,
    isValidCandidate,
    isToLearn,
    value,
    skillOptions,
    skillsError,
    occupationOptions = [],
    onSelectOccupation,
    onSubmitToProfile,
    onSubmit,
    className = '',
}): ReactElement => {
    const [formModalIsOpen, setFormModalIsOpen] = useState<boolean>(false);
    const [skills, setSkills] = useState<Skill[]>(value);
    const [occupation, setOccupation] = useState<string>('');

    const openFormModal = (): void => setFormModalIsOpen(true);
    const closeFormModal = (): void => setFormModalIsOpen(false);

    useEffect((): void => {
        setSkills(value);
    }, [value]);

    const handleChangeOccupation = (occupationValue: SearchableOption): void => {
        setOccupation(occupationValue.label);

        const selectedOccupation = occupationValue.value
            ? occupationOptions.find(option => option.id === occupationValue.value)
            : undefined;

        if (selectedOccupation && onSelectOccupation) {
            onSelectOccupation(selectedOccupation);
            closeFormModal();
        }
    };

    const handleSubmitToProfile = (): void => {
        onSubmitToProfile({
            skills: skills.filter(skill => skill.type === SkillType.skill),
            escoSkills: skills.filter(skill => skill.type === SkillType.esco),
        });

        closeFormModal();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            skills: skills.filter(skill => skill.type === SkillType.skill),
            escoSkills: skills.filter(skill => skill.type === SkillType.esco),
        });

        closeFormModal();
    };

    const buttonLabel = isToLearn
        ? trans('basic.skillsToLearn')
        : trans('basic.skills');

    const translationPrefix = isToLearn
        ? 'vacancies.overview.talentMatch.skillsToLearn'
        : 'vacancies.overview.talentMatch.skills';

    return (
        <div className={`talent-matching-skills ${className}`}>
            <h3 className="talent-matching-skills__title">
                {trans(`${translationPrefix}.buttonLabel`)}
            </h3>

            <FilterButton
                activeFilters={value.length}
                text={buttonLabel}
                onClick={openFormModal}
                className="talent-matching-skills__button"
            />

            {formModalIsOpen && (
                <Modal onClose={closeFormModal}>
                    <form onSubmit={handleSubmit}>
                        <h1 className="talent-matching-skills__modal-title">
                            {trans(`${translationPrefix}.title`)}
                        </h1>

                        <p className="talent-matching-skills__modal-description">
                            {trans(`${translationPrefix}.description`)}
                        </p>

                        <TalentMatchingInputWrapper
                            title={trans(`${translationPrefix}.input.title`)}
                            description={trans(`${translationPrefix}.input.description`)}
                            className="talent-matching-skills__input-wrapper"
                        >
                            <SkillsSelector
                                label={trans('forms.skills.input.search')}
                                value={skills}
                                options={skillOptions}
                                error={skillsError}
                                onChange={setSkills}
                            />
                        </TalentMatchingInputWrapper>

                        {onSelectOccupation && (
                            <TalentMatchingInputWrapper
                                title={trans(`${translationPrefix}.suggestion.title`)}
                                description={trans(`${translationPrefix}.suggestion.description`)}
                                className="talent-matching-skills__input-wrapper"
                            >
                                <SearchableValueInput
                                    isSearchable
                                    label={trans('forms.occupation.input.position')}
                                    value={occupation}
                                    options={occupationOptions.map(transformEscoOccupationToSearchableOption)}
                                    placeholder={trans('forms.occupation.placeholder.position')}
                                    onChange={handleChangeOccupation}
                                />
                            </TalentMatchingInputWrapper>
                        )}

                        <ButtonList className="talent-matching-skills__button-wrapper">
                            <SubmitButton
                                isLoading={isLoading}
                                icon="search"
                                text={trans('actions.search')}
                            />

                            {isValidCandidate && (
                                <Button
                                    text={trans('actions.saveToProfileSearch')}
                                    onClick={handleSubmitToProfile}
                                    disabled={skills.length < 3}
                                />
                            )}

                            <Button
                                text={trans('actions.cancel')}
                                onClick={closeFormModal}
                                className="talent-matching-skills__cancel-button"
                            />
                        </ButtonList>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default TalentMatchingSkills;
