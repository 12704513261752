// @ts-ignore
import styled, { css } from 'styled-components';

import StyledComponentProps from '../../../styledComponents/theme/StyledComponentProps';

export const Styles = styled('div')((props: StyledComponentProps) => css`
    .checkbox-wide {
        background-color: ${props.theme.colors.grey};
        padding: ${props.theme.space.xs};
    }
`);
