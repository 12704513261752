import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { Button } from '../../../../components';
import { ButtonList, ModalContent, ShareList } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { NewestVacancy } from '../../../../models/NewestVacancy';
import { RoutePaths } from '../../../../routes';

import './RecentVacancyShareModal.scss';

interface RecentVacancyShareModalProps {
    vacancy: NewestVacancy;
    onClose: () => void;
    className?: string;
}

const RecentVacancyShareModal: FC<RecentVacancyShareModalProps> = ({
    vacancy,
    onClose,
    className = '',
}): ReactElement => {
    const vacancyPath = vacancy.type === VacancyType.internship
        ? RoutePaths.internshipDetail(vacancy.uuid)
        : RoutePaths.jobDetail(vacancy.uuid);

    const shareUrl = process.env.REACT_APP_URL + vacancyPath;

    return (
        <ModalContent
            title={trans('myVacancies.modal.share.title')}
            className={`recent-vacancy-share-modal ${className}`}
        >
            <p className="recent-vacancy-share-modal__description">
                {trans('myVacancies.modal.share.description')}
            </p>

            <p className="recent-vacancy-share-modal__vacancy-title">
                {trans('myVacancies.modal.share.vacancy', { title: vacancy.title })}
            </p>

            <ShareList
                hasEmail
                titleText={trans('share.recentVacancy.title')}
                bodyText={trans('share.recentVacancy.body', { title: vacancy.title })}
                url={shareUrl}
                className="recent-vacancy-share-modal__share-list"
                itemClassName="recent-vacancy-share-modal__share-item"
            />

            <ButtonList className="recent-vacancy-share-modal__button-list">
                <Button
                    text={trans('actions.close')}
                    onClick={onClose}
                    className="recent-vacancy-share-modal__cancel-button"
                />
            </ButtonList>
        </ModalContent>
    );
};

export default RecentVacancyShareModal;
