import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import axios from 'axios';

import { AddressFormValues, AddressResponseApiParams, transformAddressRequestApiParamsToAddressFormValues } from '../../_old/app_talentz/models/Address';
import { removeSpacesFromString, sanitizeTextInput } from '../../_old/helpers/formHelper';
import { isEmptyObject } from '../../_old/helpers/objectHelper';
import { Col, Row } from '../../_old/styledComponents/components/Grid';
import Icon from '../../_old/styledComponents/components/Icon';
import { Paragraph } from '../../_old/styledComponents/components/Typography';
import { trans } from '../../helpers/trans';
import { TextInput } from '..';
import validateUserAddress from './helpers/vallidateUserAddress';

import './LocationCheck.scss';

interface LocationCheckProps {
    error: string;
    userAddress: AddressFormValues | null;
    disabled?: boolean;
    onChange: (address: AddressFormValues | null) => void;
}

const LocationCheck: FC<LocationCheckProps> = ({
    error,
    userAddress,
    disabled,
    onChange,
}): ReactElement => {
    const [addressNotFound, setAddressNotFound] = useState(false);
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [address, setAddress] = useState<AddressFormValues | null>(null);
    const [zipcode, setZipcode] = useState('');

    const setAddressAndOnChange = (data: AddressFormValues | null) => {
        setAddress(data);
        onChange(data);
    };

    const calculateAddress = (forcedZipcode?: string) => {
        const ZipcodeToUse = forcedZipcode || zipcode;
        const zipCodeCharacters = (ZipcodeToUse && ZipcodeToUse.length >= 6);
        setLoadingAddress(true);

        if (zipCodeCharacters) {
            axios.get(`addresses/search/${ZipcodeToUse}`)
                .then(result => {
                    const data = transformAddressRequestApiParamsToAddressFormValues(result.data.data as AddressResponseApiParams);
                    setLoadingAddress(false);
                    setAddressNotFound(false);
                    setAddressAndOnChange(data);
                })
                .catch(() => {
                    setLoadingAddress(false);
                    setAddressNotFound(true);
                    setAddressAndOnChange(null);
                });
        } else {
            setAddressAndOnChange(null);
            setLoadingAddress(false);
        }
    };

    const onZipCodeChange = (value: string) => {
        const sanitizedValue = sanitizeTextInput(removeSpacesFromString(value));
        setZipcode(sanitizedValue);
    };

    const onZipCodeBlur = () => {
        if (!address || address.zipcode !== zipcode) {
            calculateAddress();
        }
    };

    useEffect((): void => {
        if (userAddress && isEmptyObject(validateUserAddress(userAddress)) && userAddress.zipcode !== zipcode) {
            setAddress(userAddress);
            setZipcode(userAddress.zipcode);
        }

        if (userAddress && !isEmptyObject(validateUserAddress(userAddress))) {
            calculateAddress(userAddress.zipcode);
        }
    }, [userAddress]);

    return (
        <div className="location-check">
            <Row>
                <Col mb="md" width={3 / 6}>
                    <TextInput
                        id="zipcode"
                        name="zipcode"
                        required
                        label={trans('forms.zipcodeCheck.location')}
                        placeholder={trans('forms.zipcodeCheck.zipcode.label')}
                        value={zipcode}
                        onChange={onZipCodeChange}
                        onBlur={onZipCodeBlur}
                        error={error}
                        disabled={disabled || loadingAddress}
                        autoComplete="postal-code"
                    />
                </Col>
            </Row>
            {(addressNotFound || loadingAddress || (address && address.city)) && (
                <div className={`location-check__result ${(addressNotFound && !loadingAddress) ? 'location-check__result--has-error' : ''}`}>
                    {loadingAddress && (
                        <Row>
                            <Col width={2 / 3} alignSelf="center">
                                {trans('forms.zipcodeCheck.locationChecking')}
                            </Col>
                            <Col width={1 / 3} ml="auto" textAlign="right">
                                <Icon kind="loader" color="transparent" />
                            </Col>
                        </Row>
                    )}
                    {(!loadingAddress && addressNotFound) && (
                        <Row>
                            <Col width={1}>
                                <Paragraph color={addressNotFound && !loadingAddress ? 'white' : 'text'}>
                                    {trans('forms.zipcodeCheck.locationNotFound')}
                                </Paragraph>
                            </Col>
                        </Row>
                    )}
                    {(!loadingAddress && address && address.city) && (
                        <>
                            <Row>
                                <Col mb="xs" width={1}>
                                    {trans('forms.zipcodeCheck.locationFound')}
                                </Col>
                            </Row>
                            <Row>
                                <Col width={1}>
                                    <TextInput
                                        name="city"
                                        id="city"
                                        label=""
                                        value={(address && address.city) || ''}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default LocationCheck;
