import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { dataLayerPush } from '../../../../helpers/analytics';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';
import MultiStepOverlay from '../../../components/MultiStepOverlay';
import { authenticateUser, getLinkedInLoginRedirectUrl, logIn } from '../../store/legacyUser/legacyUserActions';
import LoginForm from '../components/forms/LoginForm';

const Login = props => {
    const navigate = useNavigate();

    const onLoginWithLinkedIn = () => {
        if (typeof window !== 'undefined') {
            props.getLinkedInLoginRedirectUrl().then(
                response => window.open(response.redirect_url, '_self'),
            );
        }
    };

    const loginFormHandler = data => {
        props.authenticateUser(data)
            .then(() => {
                dataLayerPush({
                    event: 'formSubmission',
                    formType: 'login',
                });
            });
    };

    const onLoginWithLLO = () => {
        navigate(RoutePaths.llo());
    };

    const { errors, loading, login } = props;

    return (
        <MultiStepOverlay
            id="login"
            onClose={() => props.logIn(false)}
            message={login
                ? { title: trans('basic.login.introTitle') }
                : null
            }
        >
            {login && (
                <LoginForm
                    errors={errors}
                    onSubmit={data => loginFormHandler(data)}
                    loginWithLinkedIn={onLoginWithLinkedIn}
                    loginWithLLO={onLoginWithLLO}
                    onRegisterClick={props.onRegisterClick}
                    onPasswordForgetClick={props.onPasswordForgetClick}
                    loading={loading}
                />
            )}
        </MultiStepOverlay>
    );
};

Login.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    authenticateUser: PropTypes.func.isRequired,
    logIn: PropTypes.func.isRequired,
    login: PropTypes.bool,
    getLinkedInLoginRedirectUrl: PropTypes.func.isRequired,
    onRegisterClick: PropTypes.func.isRequired,
    onPasswordForgetClick: PropTypes.func.isRequired,
};

Login.defaultProps = {
    errors: {},
    loading: false,
    login: null,
};

export default connect(
    state => ({
        errors: state.legacyUser.errors,
        loading: state.legacyUser.loading,
        tokenData: state.legacyUser.tokenData,
        login: state.legacyUser.login,
    }),
    dispatch => bindActionCreators({
        authenticateUser,
        getLinkedInLoginRedirectUrl,
        logIn,
    }, dispatch),
)(Login);
