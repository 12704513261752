import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { trans } from '../../../helpers/trans';
import { Skill } from '../../../models/Skills';
import { Loader, SearchInput } from '../..';
import { getRandomExampleOptions, searchSkillOptionsOnQuery, sortSkillOptions } from './helpers';
import { SkillResults, SkillSelectedOptionList } from './subcomponents';

import './SkillsSelector.scss';

interface SkillsSelectorProps {
    isLoading?: boolean;
    label: string;
    value: Skill[];
    options: Skill[];
    error?: string;
    validationError?: string;
    onChange: (skills: Skill[]) => void;
    onCreateNewSkill?: (newSkill: string) => void;
    className?: string;
}

const SkillsSelector: FC<SkillsSelectorProps> = ({
    isLoading,
    label,
    value = [],
    options,
    error = '',
    validationError = '',
    onChange,
    onCreateNewSkill,
    className = '',
}): ReactElement => {
    const defaultResults: Skill[] = [];

    const [placeholderExample, setPlaceholderExample] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Skill[]>(defaultResults);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect((): void => {
        if (options.length > 0 && !placeholderExample) {
            const example = getRandomExampleOptions(options);
            setPlaceholderExample(trans('basic.example', { example }));
        }
    }, [options, placeholderExample]);

    const handleSearch = (query: string): void => {
        setSearchQuery(query);

        if (query === '') {
            setSearchResults(defaultResults);
        } else {
            const results = searchSkillOptionsOnQuery(options, query);
            setSearchResults(results);
        }
    };

    const handleSelectOption = (selectedOption: Skill): void => {
        const optionExists = value.find(option => option.id === selectedOption.id);

        const updatedSelectedOptions = optionExists
            ? value.filter(option => option.id !== selectedOption.id)
            : [...value, selectedOption];

        const sortedSelectedOptions = sortSkillOptions(updatedSelectedOptions);

        onChange(sortedSelectedOptions);
    };

    const handleClearSearch = (): void => {
        if (inputRef.current) {
            inputRef.current.focus();
            setSearchQuery('');
            setSearchResults(defaultResults);
        }
    };

    return (
        <div className={`skills-selector ${className}`}>
            <SearchInput
                ref={inputRef}
                label={label}
                value={searchQuery}
                placeholder={placeholderExample}
                disabled={!!error || isLoading}
                error={error || validationError}
                onChange={handleSearch}
            />

            <SkillResults
                query={searchQuery}
                searchResults={searchResults}
                selectedOptions={value}
                onSelectOption={handleSelectOption}
                onClearSearch={handleClearSearch}
                onCreateNewSkill={onCreateNewSkill}
            />

            {isLoading && (
                <Loader className="skills-selector__loader" />
            )}

            {!isLoading && value.length > 0 && (
                <SkillSelectedOptionList
                    selectedOptions={value}
                    onSelectOption={handleSelectOption}
                    className="skills-selector__selected-options"
                />
            )}
        </div>
    );
};

export default SkillsSelector;
