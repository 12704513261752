import React, {
    FC,
    ReactElement,
    UIEvent,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import { Skill } from '../../../../../models/Skills';
import { IconButton, Tag } from '../../../..';

import './SkillResults.scss';

interface SkillResultsProps {
    query: string;
    searchResults: Skill[];
    selectedOptions: Skill[];
    onSelectOption: (selectedOption: Skill) => void;
    onClearSearch: () => void;
    onCreateNewSkill?: (newSkill: string) => void;
    className?: string;
}

const SkillResults: FC<SkillResultsProps> = ({
    query,
    searchResults,
    selectedOptions,
    onSelectOption,
    onClearSearch,
    onCreateNewSkill,
    className = '',
}): ReactElement => {
    const resultsLimit = 20;

    const [newSkill, setNewSkill] = useState<string>('');
    const [mayCreateNew, setMayCreateNew] = useState<boolean>(false);
    const [resultsPage, setResultsPage] = useState<number>(1);
    const [limitedSearchResults, setLimitedSearchResults] = useState<Skill[]>([]);

    useEffect((): void => {
        const cleanQuery = query.replace(/[^a-zA-Z0-9 ]/g, '');
        const optionExists = searchResults.find(result => result.name.toLowerCase() === cleanQuery.toLowerCase());

        setNewSkill(cleanQuery);
        setMayCreateNew(!optionExists && cleanQuery.length > 3);
    }, [query, searchResults]);

    useEffect((): void => {
        if (searchResults.length === 0) {
            setResultsPage(1);
        }
    }, [searchResults]);

    useEffect((): void => {
        const limitedResults = searchResults.slice(0, resultsLimit * resultsPage);
        setLimitedSearchResults(limitedResults);
    }, [searchResults, resultsPage]);

    const handleListScroll = (event: UIEvent<HTMLUListElement>): void => {
        if (searchResults.length > resultsLimit) {
            const scrollPosition = event.currentTarget.scrollTop + event.currentTarget.clientHeight;
            const listHeight = event.currentTarget.scrollHeight;

            if (scrollPosition === listHeight) {
                setResultsPage(resultsPage + 1);
            }
        }
    };

    const handleCreateNewSkill = (): void => {
        if (onCreateNewSkill) {
            onCreateNewSkill(newSkill);
            onClearSearch();
        }
    };

    return (
        <div className={`skill-results ${className}`}>
            {limitedSearchResults.length > 0 && (
                <ul onScroll={handleListScroll} className="skill-results__list">
                    {limitedSearchResults.map(option => {
                        const isSelected = selectedOptions.find(existingOption => existingOption.id === option.id);
                        const handleSelectOption = (): void => {
                            onSelectOption(option);
                        };

                        return (
                            <li key={option.id} className="skill-results__item">
                                <Tag
                                    isActive={!!isSelected}
                                    text={option.name}
                                    onClick={handleSelectOption}
                                />
                            </li>
                        );
                    })}
                </ul>
            )}

            {query.length > 0 && (
                <div className="skill-results__button-wrapper">
                    <Button
                        text={trans('forms.skills.reset')}
                        onClick={onClearSearch}
                        className="skill-results__reset-button"
                    />

                    {onCreateNewSkill && mayCreateNew && (
                        <IconButton
                            icon="plus"
                            text={trans('forms.skills.create', { skill: newSkill })}
                            onClick={handleCreateNewSkill}
                            className="skill-results__create-button"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SkillResults;
