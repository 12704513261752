/* eslint-disable camelcase */
export interface AddressResponseApiParams {
    uuid: string;
    address: string;
    street_number: string;
    extension?: string;
    zipcode: string;
    latitude: string;
    longitude: string;
    city: string;
    province: string;
}

export interface AddressRequestApiParams {
    uuid?: string;
    address?: string;
    street_number?: string;
    extension?: string;
    zipcode?: string;
    latitude?: string;
    longitude?: string;
    city?: string;
    province?: string;
}

export interface Address {
    uuid: string;
    address: string;
    street_number: string;
    extension?: string;
    zipcode: string;
    latitude: string;
    longitude: string;
    city: string;
    province: string;
}

export interface AddressFormValues extends Partial<Address> {
    address: string;
    street_number: string;
    extension?: string;
    zipcode: string;
    latitude: string;
    longitude: string;
    city: string;
    province: string;
}

export function transformToAddress(address: AddressResponseApiParams): Address {
    return {
        uuid: address.uuid,
        address: address.address,
        street_number: address.street_number,
        extension: address.extension,
        zipcode: address.zipcode,
        latitude: address.latitude,
        longitude: address.longitude,
        city: address.city,
        province: address.province,
    };
}

export function transformAddressToAddressFormValues(address: Address): AddressFormValues {
    return {
        address: address.address,
        street_number: address.street_number,
        extension: address.extension,
        zipcode: address.zipcode,
        latitude: address.latitude,
        longitude: address.longitude,
        city: address.city,
        province: address.province,
    };
}

export function transformAddressRequestApiParamsToAddressFormValues(address: AddressRequestApiParams): AddressFormValues {
    return {
        address: address.address || '',
        street_number: address.street_number || '',
        extension: address.extension,
        zipcode: address.zipcode || '',
        latitude: address.latitude || '',
        longitude: address.longitude || '',
        city: address.city || '',
        province: address.province || '',
    };
}
/* eslint-enable camelcase */
