import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { EmailAddressChange } from '../../models/EmailAddress';
import { AsyncReduxState } from '../../types';

export type AccountSettingsState = AsyncReduxState<{
    emailAddressChange?: EmailAddressChange;
}>;

const initialState: AccountSettingsState = {
    ...initialAsyncReduxState,
    emailAddressChange: undefined,
};

const accountSettingsSlice = createSlice({
    name: 'accountSettingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AccountSettingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AccountSettingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEmailAddressChange(state, action: PayloadAction<EmailAddressChange | undefined>): AccountSettingsState {
            return {
                ...state,
                emailAddressChange: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setEmailAddressChange,
} = accountSettingsSlice.actions;

export default accountSettingsSlice;
