import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import TextEditor from '../../../_old/components/form/TextEditor';
import { FormActionButtons, RadioList } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import {
    ApplicationAppointmentFormData,
    ApplicationAppointmentType,
    AppointmentDate,
    defaultAppointmentDate,
} from '../../../models/ApplicationAppointments';
import { FormProps } from '../../../types';
import { AppointmentsInput } from './subcomponents';
import { ApplicationAppointmentFormErrors, validateApplicationAppointmentFormData } from './validations';

import './AppointmentForm.scss';

interface AppointmentFormProps extends FormProps<ApplicationAppointmentFormData> {
    isCandidate?: boolean;
    className?: string;
}

const AppointmentForm: FC<AppointmentFormProps> = ({
    isCandidate,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<ApplicationAppointmentFormErrors>({});

    const [appointments, setAppointments] = useState<AppointmentDate[]>([defaultAppointmentDate()]);
    const [type, setType] = useState<string>(ApplicationAppointmentType.physical);
    const [message, setMessage] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    const typeOptions = [
        {
            label: trans('forms.appointment.option.type.physical'),
            value: ApplicationAppointmentType.physical,
        },
        {
            label: trans('forms.appointment.option.type.jitsi'),
            value: ApplicationAppointmentType.jitsi,
        },
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            appointments,
            type: type as ApplicationAppointmentType,
            message,
        };

        const [errors, hasErrors] = validateApplicationAppointmentFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`appointment-form ${className}`}>
            <AppointmentsInput
                appointments={appointments}
                error={formErrors.appointments}
                onChange={setAppointments}
                className="appointment-form__appointments-input"
            />

            <RadioList
                name="appointment-type"
                label={isCandidate ? trans('forms.appointment.input.type.candidate') : trans('forms.appointment.input.type.company')}
                options={typeOptions}
                value={type}
                error={formErrors.type}
                onChange={setType}
                className="appointment-form__type-input"
            />

            <div className="appointment-form__message-input">
                <TextEditor
                    name="message"
                    label={trans('forms.appointment.input.message')}
                    placeholder={trans('forms.appointment.placeholder.message')}
                    defaultValue={message}
                    errors={formErrors.message ? [formErrors.message] : undefined}
                    onChange={setMessage}
                />
            </div>

            <FormActionButtons
                submitIcon="check"
                submitText={trans('forms.appointment.button.submit')}
                onCancelClick={onCancel}
                className="appointment-form__action-buttons"
            />
        </form>
    );
};

export default AppointmentForm;
