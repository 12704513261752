import React from 'react';

import { Helmet } from 'react-helmet-async';
import styled, { css } from 'styled-components';

import { trans } from '../../../../../helpers/trans';
import StickyComponent from '../../../../components/StickyComponent';
import Card from '../../../../styledComponents/components/Card';
import { CheckList, CheckListItem } from '../../../../styledComponents/components/CheckList';
import Container from '../../../../styledComponents/components/Container';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Laptop, MaxLaptop } from '../../../../styledComponents/components/MediaQueries';
import Page from '../../../../styledComponents/components/Page';
import {
    FakeLink as FakeLinkWrapper,
    Heading,
    Paragraph,
} from '../../../../styledComponents/components/Typography';
import { createMetadataTitle } from '../../../services/MetadataService';

const scrollTo = to => {
    if (typeof window !== 'undefined') {
        const elementToScrollTo = document.querySelector(`#${to}`).offsetTop;
        window.scrollTo(0, elementToScrollTo - 60);
    }
};

const FakeLink = (label, url) => (
    <FakeLinkWrapper onClick={() => scrollTo(url)} color="primary">
        {label}
    </FakeLinkWrapper>
);

const LinkStyle = styled('a')(props => css`
    color: ${props.theme.colors.link};
    text-decoration: none;
`);

const PrivacyStatementMenu = (
    <>
        <Heading as="h2">Inhoudsopgave</Heading>
        <Paragraph mb="sm" ml="md" as="ol">
            <li>{FakeLink('Verwerkingsverantwoordelijke', 'A1')}</li>
            <li>{FakeLink('Contactgegevens', 'A2')}</li>
            <li>{FakeLink('Persoonsgegevens die wij verzamelen', 'A3')}</li>
            <li>{FakeLink('Gebruik van de informatie', 'A4')}</li>
            <li>{FakeLink('Delen van informatie met derden', 'A5')}</li>
            <li>{FakeLink('Cookiebeleid', 'A6')}</li>
            <li>{FakeLink('Bewaartermijn van de gegevens', 'A7')}</li>
            <li>{FakeLink('Inzage- en wijzigingsrecht', 'A8')}</li>
            <li>{FakeLink('Beveiliging van de gegevens', 'A9')}</li>
            <li>{FakeLink('Tenslotte', 'A10')}</li>
        </Paragraph>
    </>
);

const PrivacyStatement = () => (
    <Page>
        <Helmet>
            <title>{trans('infoPage.privacyStatement.metadata.title')}</title>
            <meta property="og:title" content={createMetadataTitle(trans('infoPage.privacyStatement.metadata.title'))} />
            <meta property="og:description" content={trans('infoPage.privacyStatement.metadata.description')} />
            <meta name="description" content={trans('infoPage.privacyStatement.metadata.description')} />
            <link rel="canonical" href={`${process.env.REACT_APP_URL}/privacy-statement`} />
        </Helmet>
        <Container pb="xl" centered>
            <Heading as="h1" size={36}>Privacy Statement</Heading>
            <MaxLaptop>
                <Col width={['100%']}>
                    <Card mb="sm">
                        {PrivacyStatementMenu}
                    </Card>
                </Col>
            </MaxLaptop>
            <Row>
                <Col width={['100%', '100%', '100%', '100%', 8 / 12]} mr={[0, 0, 0, 0, 'md']}>
                    <Card>
                        <Paragraph mb="md" fontType="bold">
                            Natuurlijk vinden wij uw privacy net zo belangrijk als die van ons zelf.
                            Daarom kunt u er op rekenen dat wij uw gegevens zorgvuldig behandelen en
                            alleen gebruiken binnen de daarvoor gestelde kaders. In deze verklaring
                            vertellen wij u hoe wij dit doen.
                        </Paragraph>

                        <Heading as="h2" id="A1">Verwerkingsverantwoordelijke</Heading>
                        <Paragraph mb="md">
                            TalentZ in Spanbroek is verantwoordelijk voor de verwerking van gegevens
                            zoals weergegeven in dit privacy statement.
                        </Paragraph>

                        <Heading as="h2" id="A2">Contactgegevens</Heading>
                        <Paragraph mb="md">
                            TalentZ, C.N. Appelstraat 8, 1715 GG Spanbroek<br />
                            Email:&nbsp;
                            <LinkStyle
                                href="mailto:info@talentz.nl"
                            >
                                info@talentz.nl
                            </LinkStyle><br />
                            Website: https://www.talentz.nl
                        </Paragraph>

                        <Heading as="h2" id="A3">Persoonsgegevens die wij verzamelen</Heading>
                        <Paragraph mb="md">
                            TalentZ verzamelt en verwerkt uw persoonsgegevens doordat u gebruik
                            maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.
                            Hieronder vindt u een overzicht van de belangrijkste persoonsgegevens
                            die wij mogelijk verwerken:
                        </Paragraph>

                        <CheckList mb="md">
                            <CheckListItem checked color="text">Voor- en achternaam</CheckListItem>
                            <CheckListItem checked color="text">Adresgegevens</CheckListItem>
                            <CheckListItem checked color="text">Telefoonnummer</CheckListItem>
                            <CheckListItem checked color="text">Bankrekeningnummer</CheckListItem>
                            <CheckListItem checked color="text">E-mailadres</CheckListItem>
                            <CheckListItem checked color="text">IP-adres</CheckListItem>
                            <CheckListItem checked color="text">
                                Gegevens over uw activiteiten op onze website
                            </CheckListItem>
                            <CheckListItem checked color="text">
                                Gegevens over uw surfgedrag over verschillende websites heen
                                (bijvoorbeeld omdat dit bedrijf onderdeel is van een
                                advertentienetwerk)
                            </CheckListItem>
                        </CheckList>

                        <Paragraph mb="md">
                            Wij hebben geen enkele intentie om bewust gegevens te verzamelen van
                            personen die jonger zijn dan 16 jaar. Mocht u een vermoeden hebben,
                            dat wij dat toch gedaan hebben, neem dan contact op met&nbsp;
                            <LinkStyle
                                href="mailto:info@talentz.nl"
                            >
                                info@talentz.nl
                            </LinkStyle>
                            &nbsp;en dan zullen wij die
                            informatie direct verwijderen.
                        </Paragraph>

                        <Heading as="h2" id="A4">Gebruik van de informatie</Heading>
                        <Paragraph mb="md">
                            In principe gebruiken wij uw persoonsgegevens om u onze diensten te
                            kunnen leveren en u daarbij zo goed mogelijk te kunnen ondersteunen,
                            onder meer om:
                        </Paragraph>

                        <CheckList mb="md">
                            <CheckListItem checked color="text">
                                Contact met u te kunnen opnemen om onze dienstverlening uit te
                                kunnen voeren
                            </CheckListItem>
                            <CheckListItem checked color="text">
                                U te informeren over wijzigingen van onze diensten en producten
                            </CheckListItem>
                            <CheckListItem checked color="text">
                                Goederen en diensten bij u af te leveren
                            </CheckListItem>
                            <CheckListItem checked color="text">
                                Financiële en administratieve transacties (zoals betalingen) te
                                kunnen verwerken
                            </CheckListItem>
                        </CheckList>

                        <Paragraph mb="md">
                            Daarnaast analyseert TalentZ uw gedrag op onze website om zodoende ons
                            aanbod van producten en diensten nog beter af te stemmen op uw
                            voorkeuren. Ook wordt de data van gebruikers gebruikt voor statistisch doeleinde
                        </Paragraph>

                        <Paragraph mb="md">
                            Natuurlijk verwerken wij ook persoonsgegevens als wij hier wettelijk toe
                            verplicht zijn, zoals bijvoorbeeld gegevens die wij nodig hebben voor
                            onze belastingaangifte.
                        </Paragraph>

                        <Heading as="h2" id="A5">Delen van informatie met derden</Heading>
                        <Paragraph mb="md">
                            Alleen wanneer dit noodzakelijk is voor het uitvoeren van de
                            overeenkomst of in het geval wij moeten voldoen aan wettelijke
                            verplichtingen, zullen wij uw persoonsgegevens met derden delen.
                            Met deze partijen sluiten wij verwerkingsovereenkomsten, die worden
                            geregistreerd en bijgehouden in een verwerkingsregister. Dit
                            register kunt u desgevraagd op elk moment inzien. Vanzelfsprekend
                            zullen wij uw gegevens nooit met derden delen of aan hen verkopen
                            zonder uw uitdrukkelijke toestemming, tenzij wij hiertoe wettelijk
                            verplicht zijn of worden.
                        </Paragraph>

                        <Heading as="h2" id="A6">Cookiebeleid</Heading>
                        <Paragraph mb="md">
                            TalentZ maakt gebruik van analytische cookies. Deze cookies zorgen
                            ervoor dat we anoniem data kunnen verzamelen. Hierdoor kunnen wij de
                            gebruikerservaring verbeteren. Wij maken hiervoor gebruik van
                            Hotjar en Google Analytics.
                        </Paragraph>

                        <Heading as="h2" id="A7">Bewaartermijn van de gegevens</Heading>
                        <Paragraph mb="md">
                            Wij bewaren uw persoonsgegevens niet langer dan strikt nodig is om de
                            doelen te realiseren waarvoor uw gegevens worden verzameld, behalve
                            wanneer wet of regelgeving ons daartoe verplicht. Voor de meeste
                            gegevens hanteren wij doorgaans een bewaartermijn van zeven jaar.
                            Het is mogelijk om account inclusief historie zelf van het
                            talentZ platform te verwijderen.
                        </Paragraph>

                        <Heading as="h2" id="A8">Inzage- en wijzigingsrecht</Heading>
                        <Paragraph mb="md">
                            Het spreekt voor zich dat u het recht heeft om uw persoonsgegevens in te
                            zien, te corrigeren of te verwijderen. Daarnaast kunt u wanneer u dit
                            wilt, het recht om uw eventuele toestemming voor de gegevensverwerking
                            intrekken of bezwaar maken tegen de verwerking van uw persoonsgegevens
                            door TalentZ. Alle verwerkingen worden in een register bijgehouden.
                            Zoals eerder aangegeven, kunt u dat inzien wanneer u dat wenst. Elk
                            redelijk verzoek zal worden gehonoreerd. We reageren zo snel mogelijk,
                            maar in ieder geval binnen dertig dagen.
                        </Paragraph>

                        <Paragraph mb="md">
                            Mocht u onverhoopt van mening zijn dat wij iets niet goed hebben gedaan,
                            dan kunt u een klacht indienen. U mag dit doen bij de nationale
                            toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de
                            volgende link:&nbsp;
                            <LinkStyle
                                href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/informatie-en-meldpunt-privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://autoriteitpersoonsgegevens.nl/
                            </LinkStyle>
                        </Paragraph>

                        <Heading as="h2" id="A9">Beveiliging van de gegevens</Heading>
                        <Paragraph mb="md">
                            TalentZ neemt de bescherming van uw gegevens serieus. Wij hebben
                            technische en organisatorische veiligheidsmaatregelen getroffen
                            om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking
                            en wijziging tegen te gaan. Om deze zogeheten datalekken te
                            voorkomen, zijn onder meer de volgende maatregelen genomen:
                        </Paragraph>

                        <CheckList mb="md">
                            <CheckListItem checked color="text">Beveiliging van de server;</CheckListItem>
                            <CheckListItem checked color="text">
                                De computers zijn voorzien van een virusscanner en firewall;
                            </CheckListItem>
                            <CheckListItem checked color="text">Applicatie is veilig gecodeerd;</CheckListItem>
                            <CheckListItem checked color="text">
                                Medewerkers hebben zich verbonden tot geheimhouding.
                            </CheckListItem>
                        </CheckList>

                        <Heading as="h2" id="A10">Tenslotte</Heading>
                        <Paragraph mb="md">
                            Wet en regelgeving ten aanzien van Privacy kunnen snel veranderen. Om
                            actueel te blijven en steeds aan de laatste regels en rechtspraak te
                            kunnen voldoen, zullen wij er niet aan ontkomen om zo nu en dan dit
                            Privacy Statement te wijzigen. Via onze website stellen wij u op de
                            hoogte van alle wijzigingen die plaatsvinden. Dit Privacy Statement
                            is van 12 februari 2019.
                        </Paragraph>
                    </Card>
                </Col>
                <Laptop>
                    <Col width={['100%', '100%', '100%', '100%', 4 / 12]}>
                        <StickyComponent>
                            <Card>
                                {PrivacyStatementMenu}
                            </Card>
                        </StickyComponent>
                    </Col>
                </Laptop>
            </Row>
        </Container>
    </Page>
);

export default PrivacyStatement;
