import { transformPrismicDocumentToBlog, transformPrismicDocumentToBlogSeoData } from '../../models/Blog';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { PrismicType } from '../../models/Prismic';
import { getPrismicDetailApiCall, getPrismicOverviewApiCall } from '../../services/PrismicService';
import { TypedDispatch } from '..';
import {
    setContent,
    setIsLoading,
    setRelatedBlogArticles,
    setSeoData,
} from './blogDetail';

export const fetchBlogSeoData = (slug: string) => async (dispatch: TypedDispatch): Promise<void> => {
    if (!slug) return; // Early exit for SSR purpose

    dispatch(setIsLoading(true));
    dispatch(setSeoData(undefined));

    try {
        const blogDetailResponse = await getPrismicDetailApiCall(PrismicType.blog, slug);

        if (!isFetchResultSuccessful(blogDetailResponse)) {
            console.error('[fetchBlogSeoData]', blogDetailResponse.error);
            return;
        }

        const blogSeoData = transformPrismicDocumentToBlogSeoData(blogDetailResponse.data);

        dispatch(setSeoData(blogSeoData));
    } catch (error) {
        console.error('[fetchBlogSeoData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchBlogContent = (slug: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setContent(undefined));

    try {
        const blogDetailResponse = await getPrismicDetailApiCall(PrismicType.blog, slug);

        if (!isFetchResultSuccessful(blogDetailResponse)) {
            console.error('[fetchBlogContent]', blogDetailResponse.error);
            return;
        }

        const blogContent = transformPrismicDocumentToBlog(blogDetailResponse.data);

        dispatch(setContent(blogContent));
    } catch (error) {
        console.error('[fetchBlogContent]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchRelatedBlogArticles = (slug: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setRelatedBlogArticles([]));

    try {
        const blogDetailResponse = await getPrismicDetailApiCall(PrismicType.blog, slug);

        if (!isFetchResultSuccessful(blogDetailResponse)) {
            console.error('[fetchRelatedBlogArticles]', blogDetailResponse.error);
            return;
        }

        const blogContent = transformPrismicDocumentToBlog(blogDetailResponse.data);

        const { id, categories } = blogContent;
        const randomCategoryIndex = Math.floor(Math.random() * categories.length);

        const relatedBlogArticlesResponse = await getPrismicOverviewApiCall({
            type: PrismicType.blog,
            category: categories[randomCategoryIndex],
            page: 1,
            pageSize: 8,
        });

        if (!isFetchResultSuccessful(relatedBlogArticlesResponse)) {
            console.error('[fetchRelatedBlogArticles]', relatedBlogArticlesResponse.error);
            return;
        }

        const blogArticlesFromCategory = relatedBlogArticlesResponse.data.results.map(transformPrismicDocumentToBlog);
        const relatedBlogArticles = blogArticlesFromCategory.filter(blog => blog.id !== id);

        dispatch(setRelatedBlogArticles(relatedBlogArticles));
    } catch (error) {
        console.error('[fetchRelatedBlogArticles]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
