import React, { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { VacanciesInfo } from '../../../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../../../models/NewestVacancy';
import { RoleType } from '../../../../models/User';
import { RoutePaths } from '../../../../routes';
import { VacancyOverview } from '../../..';

import './RecentRoleVacanciesList.scss';

interface RecentRoleVacanciesListProps {
    isLoading?: boolean;
    userRole: RoleType.student | RoleType.jobSeeker;
    userFieldsOfStudy?: string[];
    vacancies?: NewestVacancy[];
    error: string;
    favouriteIds: string[];
    favouriteIsLoading: boolean;
    onFavouriteClick: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    className?: string;
}

const RecentRoleVacanciesList: FC<RecentRoleVacanciesListProps> = ({
    isLoading = false,
    userRole,
    userFieldsOfStudy,
    vacancies = [],
    error,
    favouriteIds,
    favouriteIsLoading,
    onFavouriteClick,
    className = '',
}): ReactElement => {
    const roleTitle = trans(`home.recentVacancies.collections.${userRole === RoleType.student ? 'internships' : 'jobs'}`);
    const roleButtonLabel = trans(`home.recentVacancies.button.${userRole === RoleType.student ? 'allInternships' : 'allJobs'}`);
    const roleUrl = userRole === RoleType.student ? RoutePaths.internships() : RoutePaths.jobs();

    return (
        <div className={`recent-role-vacancies-list ${className}`}>
            <h1 className="recent-role-vacancies-list__title">
                {trans('home.recentVacancies.withMatch.title')}
            </h1>

            <div className="recent-role-vacancies-list__list-wrapper">
                <div className="recent-role-vacancies-list__controls">
                    <h2 className="recent-role-vacancies-list__role-title">
                        {roleTitle}
                    </h2>

                    <LinkIconButton
                        to={roleUrl}
                        icon="chevron-right"
                        iconPos="right"
                        text={roleButtonLabel}
                        className="recent-role-vacancies-list__role-button"
                    />
                </div>

                <VacancyOverview
                    isLoading={isLoading}
                    skeletonAmount={4}
                    vacancies={vacancies}
                    userRole={userRole}
                    userFieldsOfStudy={userFieldsOfStudy}
                    error={error}
                    favouriteIds={favouriteIds}
                    favouriteIsLoading={favouriteIsLoading}
                    onFavouriteVacancy={onFavouriteClick}
                    className="recent-role-vacancies-list__vacancies"
                />
            </div>
        </div>
    );
};

export default RecentRoleVacanciesList;
