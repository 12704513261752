import { transformMetaToPagination } from '../_old/app_talentz/models/Pagination';
import { VacancyType } from '../_old/app_talentz/models/VacancyType';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { convertToQueryParams } from '../helpers/params';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { Competency } from '../models/Competencies';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import {
    NewestVacancy,
    NewestVacancyResource,
    transformToNewestVacancy,
    VacancyOverview,
} from '../models/NewestVacancy';
import { Skill } from '../models/Skills';
import { transformToVacancyOverviewFilterParams, VacancyOverviewFilterValues } from '../models/VacancyOverview';

export interface RelatedVacancyOverviewConfig {
    vacancy: NewestVacancy;
    skillOptions: Skill[];
    competencyOptions: Competency[];
    filterValues: VacancyOverviewFilterValues;
}

export const getRelatedVacanciesApiCall = async (relatedVacancyOverviewConfig: RelatedVacancyOverviewConfig): Promise<FetchResult<VacancyOverview, string>> => {
    const {
        vacancy,
        skillOptions,
        competencyOptions,
        filterValues,
    } = relatedVacancyOverviewConfig;

    const skills = skillOptions
        .filter(skill => vacancy.skills.includes(skill.name))
        .map(skill => skill.id);

    const competencies = competencyOptions
        .filter(competency => vacancy.competencies.includes(competency.name))
        .map(competency => competency.id);

    const filterParams = transformToVacancyOverviewFilterParams({
        ...filterValues,
        skills,
        competencies,
    });

    const queryParams = convertToQueryParams({
        ...filterParams,
        'filter[vacancy_type]': vacancy.type,
    });

    try {
        const response = vacancy.type === VacancyType.internship
            ? await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/matching-internship-vacancies?${queryParams}`)
            : await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/matching-job-vacancies?${queryParams}`);

        if (!response.ok) {
            const errorDoc = await response.json();
            const error = errorDoc.errors[0];

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<NewestVacancyResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const vacancies = doc.data.map(transformToNewestVacancy);
        const pagination = doc.meta ? transformMetaToPagination(doc.meta) : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                vacancies,
                pagination,
            },
        };
    } catch (error) {
        console.error('[getRelatedVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
