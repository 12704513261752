/* eslint-disable camelcase */
export interface JobPreferencesApiParams {
    uuid: string;
    gross_salary: MatchPropertyTemp;
    travel_distance: MatchPropertyTemp;
    job_types: MatchPropertyTemp[];
    profession: MatchPropertyTemp;
    amount_of_employees: MatchPropertyTemp[];
    zipcode: string;
    sectors: MatchPropertyTemp[];
}

export interface JobPreferencesApiRequestParams {
    amount_of_employees: string[];
    apply_types: string[];
    gross_salary: string;
    job_types: string[];
    travel_distance: string;
    profession_id: string | null;
    zipcode: string | null;
    sectors: string[];
}

export interface JobPreferences {
    uuid: string;
    gross_salary: MatchPropertyTemp;
    travel_distance: MatchPropertyTemp;
    job_types: MatchPropertyTemp[];
    profession: MatchPropertyTemp;
    amount_of_employees: MatchPropertyTemp[];
    zipcode: string;
    sectors: MatchPropertyTemp[];
}

export interface MatchPropertyTemp {
    uuid: string;
    name: string;
    value?: string;
    slug?: string;
    is_matched?: boolean;
}

export function transformToJobPreferences(params: JobPreferencesApiParams): JobPreferences {
    return {
        ...params,
    };
}
/* eslint-enable camelcase */
