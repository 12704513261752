import { maxShortTextLength } from '../../../../constants';
import { CatchphraseFormData } from '../../../../models/Catchphrase';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMaxStringLength,
} from '../../../../services/ValidationService';

export type CatchphraseFormErrors = FormErrors<CatchphraseFormData>;

export const validateCatchphraseFormData = (
    formData: CatchphraseFormData,
): FormValidation<CatchphraseFormErrors> => {
    const errors: CatchphraseFormErrors = {
        catchphrase: validateMaxStringLength('catchphrase', formData.catchphrase, maxShortTextLength),
    };

    return validateForm<CatchphraseFormErrors>(errors);
};
