import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Checkbox } from '../../../../../components';
import { SubmitButton } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../styledComponents/components/Typography';
import { PersonSettings } from '../../../models/PersonSettings';

interface StudentSettingsFormProps {
    isLoading: boolean;
    settings: PersonSettings | null;
    checkForWebPushSubscription: () => void;
    onSubmit: (data: any) => void;
}

const StudentSettingsForm: FC<StudentSettingsFormProps> = ({
    isLoading,
    settings,
    checkForWebPushSubscription,
    onSubmit,
}): ReactElement => {
    const [checkedInternshipMatches, setCheckInternshipMatches] = useState<boolean>(!!(settings && settings.show_internship_matches));
    const [checkedJobMatches, setCheckJobMatches] = useState<boolean>(!!(settings && settings.show_job_matches));
    const [checkedNotifications, setCheckNotifications] = useState<boolean>(!!(settings && settings.notifications));
    const [checkedNewsletter, setCheckNewsletter] = useState<boolean>(!!(settings && settings.newsletter));

    useEffect((): void => {
        checkForWebPushSubscription();
    }, []);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            show_internship_matches: checkedInternshipMatches,
            show_job_matches: checkedJobMatches,
            notifications: checkedNotifications,
            newsletter: checkedNewsletter,
        });
    };

    return (
        <form id="student-notification-form" onSubmit={handleSubmit}>
            <Heading as="h2" size="xl" mb="xs">{trans('forms.user.account.privacy.heading')}</Heading>
            <Paragraph mb="lg">{trans('forms.user.account.privacy.summary')}</Paragraph>
            <Row>
                <Col mb="lg" width={1}>
                    <Heading as="h2" mb="xs">{trans('forms.user.account.matches.heading')}</Heading>
                    <Paragraph mb="md">{trans('forms.user.account.matches.summary')}</Paragraph>

                    <Row>
                        <Checkbox
                            label={trans('forms.user.account.matches.options.internships')}
                            checked={checkedInternshipMatches}
                            disabled={isLoading}
                            onChange={setCheckInternshipMatches}
                        />
                    </Row>

                    <Row mt="sm">
                        <Checkbox
                            label={trans('forms.user.account.matches.options.jobs')}
                            checked={checkedJobMatches}
                            disabled={isLoading}
                            onChange={setCheckJobMatches}
                        />
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col mb="md" width={1}>
                    <Heading as="h2" mb="xs">{trans('forms.user.account.notifications.heading')}</Heading>
                    <Paragraph mb="md">{trans('forms.user.account.notifications.summary')}</Paragraph>

                    <Checkbox
                        label={trans('forms.user.account.notifications.all')}
                        checked={checkedNotifications}
                        disabled={isLoading}
                        onChange={setCheckNotifications}
                    />
                </Col>
            </Row>
            <Row>
                <Col mb="md" width={1}>
                    <Heading as="h2" mb="xs">{trans('forms.user.account.newsletter.heading')}</Heading>
                    <Paragraph mb="md">{trans('forms.user.account.newsletter.summary')}</Paragraph>

                    <Checkbox
                        label={trans('forms.user.account.newsletter.all')}
                        checked={checkedNewsletter}
                        disabled={isLoading}
                        onChange={setCheckNewsletter}
                    />
                </Col>
            </Row>
            <Row justifyContent="flex-end">
                <Col>
                    <SubmitButton
                        id="notifications-submit"
                        isLoading={isLoading}
                        text={trans('actions.save')}
                    />
                </Col>
            </Row>
        </form>
    );
};

export default StudentSettingsForm;
