import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../../../components';
import { convertBytesToLabel } from '../../../../../helpers/file';
import { trans } from '../../../../../helpers/trans';
import { IconButton } from '../../../..';

import './FilesToUploadListItem.scss';

interface FileItemProps {
    file: File;
    onDelete: () => void;
    className?: string;
}

const FilesToUploadListItem: FC<FileItemProps> = ({
    file,
    onDelete,
    className = '',
}): ReactElement => (
    <li className={`files-to-upload-list-item ${className}`}>
        <Icon name="paperclip" className="files-to-upload-list-item__icon" />

        <div className="files-to-upload-list-item__meta-wrapper">
            <span className="files-to-upload-list-item__name">
                {file.name}
            </span>

            <span className="files-to-upload-list-item__size">
                {convertBytesToLabel(file.size)}
            </span>
        </div>

        <IconButton
            icon="delete"
            text={trans('actions.delete')}
            hideLabel
            onClick={onDelete}
            className="files-to-upload-list-item__delete-button"
            iconClassName="files-to-upload-list-item__delete-button-icon"
        />
    </li>
);

export default FilesToUploadListItem;
