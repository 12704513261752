import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Logo } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './FooterEnd.scss';

interface FooterEndProps {
    className?: string;
}

const FooterEnd: FC<FooterEndProps> = ({
    className = '',
}): ReactElement => (
    <div className={`footer-end ${className}`}>
        <div className="footer-end__wrapper">
            <div className="footer-end__column">
                <a
                    href="https://endeavour.nl/nl"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="footer-end__link footer-end__link--logo"
                >
                    <span className="footer-end__link-label">
                        {trans('footer.createdBy')}
                    </span>

                    <Logo name="endeavour" className="footer-end__logo" />
                </a>
            </div>

            <div className="footer-end__column">
                <Link to={RoutePaths.privacyStatement()} className="footer-end__link">
                    {trans('footer.privacyStatement')}
                </Link>
            </div>

            <div className="footer-end__column">
                <Link to={RoutePaths.termsAndConditions()} className="footer-end__link">
                    {trans('footer.termsAndConditions')}
                </Link>
            </div>
        </div>
    </div>
);

export default FooterEnd;
