import React, { FC, ReactElement } from 'react';

import { TextHolder } from '../../../../../../styledComponents/components/Typography';
import { FaqItemTranslationModel } from '../../../../../models/FaqItemTranslationModel';

interface FaqAccordionItemProps {
    item: FaqItemTranslationModel;

    // Since <Accordion> is doing something dirty, we have to have a prop named label
    // Otherwise <Accordion> refuses to show a title...
    label: string;
}

const FaqAccordionItem: FC<FaqAccordionItemProps> = ({
    item,
    label,
}): ReactElement => (
    <TextHolder listColor label={label}>
        {item.html && (
        // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: item.html }} />
        )}

        {item.text && (
            <p>{item.text}</p>
        )}

        {item?.steps?.length && (
            <ol>
                {item.steps.map(step => <li>{step}</li>)}
            </ol>
        )}

        {item.footerText && (
            <p>{item.footerText}</p>
        )}
    </TextHolder>
);

export default FaqAccordionItem;
