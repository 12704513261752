import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getApplicationsApiCall } from '../../services/ApplicationsService';
import { TypedDispatch } from '..';
import { setApplications, setError, setIsLoading } from './applications';

export const clearApplications = () => (dispatch: TypedDispatch): void => {
    dispatch(setApplications([]));
};

export const fetchApplications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const applicationResponse = await getApplicationsApiCall();

        if (!isFetchResultSuccessful(applicationResponse)) {
            console.error('[fetchApplications]', applicationResponse.error);
            dispatch(setError(applicationResponse.error));
            return;
        }

        const applications = applicationResponse.data;

        dispatch(setApplications(applications));
    } catch (error) {
        console.error('[fetchApplications]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
