import React, { FC, ReactElement } from 'react';

import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { EscoOccupation } from '../../../../models/Esco';
import { Province } from '../../../../models/Provinces';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexOccupationCity.scss';

interface VacancyIndexOccupationCityProps {
    isLoading: boolean;
    occupation: EscoOccupation;
    province: Province;
    cityOptionColumns: string[][];
    className?: string;
}

const VacancyIndexOccupationCity: FC<VacancyIndexOccupationCityProps> = ({
    isLoading,
    occupation,
    province,
    cityOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.occupation.city', {
            occupation: occupation.name,
            province: province.name,
        })}
        className={`vacancy-index-occupation-city ${className}`}
    >
        <VacancyIndexColumnList>
            {cityOptionColumns.map(column => {
                const columnIndex = column.join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-occupation-city__city-list">
                            {column.map(city => {
                                const searchQuery = encodeSearchQuery({
                                    what: occupation.name,
                                    province: province.slug,
                                    where: city,
                                });

                                return (
                                    <li key={city} className="vacancy-index-occupation-city__city">
                                        <VacancyIndexLink
                                            to={RoutePaths.jobsQuery(searchQuery)}
                                            label={city}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexOccupationCity;
