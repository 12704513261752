import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconQuestionMark: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M56.9,76.3c0,3.8-2.9,7-6.9,7c-3.9,0-7-3.1-7-7c0-3.9,3.1-6.9,7-6.9C53.9,69.4,56.9,72.4,56.9,76.3z M49.9,16.7c-9.8,0-16.6,6.4-19.1,13.8l9.1,3.8c1.2-3.7,4.1-8.2,10-8.2c9,0,10.8,8.4,7.6,12.9c-3,4.3-8.2,7.2-10.9,12c-2.2,3.8-1.7,8.3-1.7,11h10.1c0-5.2,0.4-6.2,1.2-7.8c2.2-4,6.2-5.9,10.4-12.1c3.8-5.6,2.3-13.1-0.1-17.1C63.8,20.4,58.2,16.7,49.9,16.7z M88.9,11.1H11.1v77.8h77.8V11.1z M88.9,0C95,0,100,5,100,11.1v77.8c0,6.1-5,11.1-11.1,11.1H11.1C5,100,0,95,0,88.9V11.1C0,5,5,0,11.1,0H88.9z" />
    </svg>
);

export default IconQuestionMark;
