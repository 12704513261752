import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './CandidatePreferencesCardSkeletons.scss';

interface CandidatePreferencesCardSkeletonsProps {
    amount?: number;
    className?: string;
}

const CandidatePreferencesCardSkeletons: FC<CandidatePreferencesCardSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`candidate-preferences-card-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="candidate-preferences-card-skeletons__item">
                    <div className="candidate-preferences-card-skeletons__text-wrapper">
                        <Skeleton className="candidate-preferences-card-skeletons__text" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default CandidatePreferencesCardSkeletons;
