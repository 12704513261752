import React, { FC, PropsWithChildren, ReactElement } from 'react';

import { VacancyIndexSkeletons } from '../../skeletons';

import './VacancyIndexCategory.scss';

interface VacancyIndexCategoryProps {
    isLoading?: boolean;
    title: string;
    className?: string;
}

const VacancyIndexCategory: FC<PropsWithChildren<VacancyIndexCategoryProps>> = ({
    isLoading,
    title,
    className = '',
    children,
}): ReactElement => (
    <section className={`vacancy-index-category ${className}`}>
        <h1 className="vacancy-index-category__title">
            {title}
        </h1>

        {isLoading ? (
            <VacancyIndexSkeletons
                amountPerColumn={5}
                className="vacancy-index-category__skeletons"
            />
        ) : (
            <div className="vacancy-index-category__list">
                {children}
            </div>
        )}
    </section>
);

export default VacancyIndexCategory;
