import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrowDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 56.2 100" className={`icon ${className}`}>
        <path d="M0,77.5L28.1,100l28.1-22.5L50,69.7L33.1,83.2V0h-10v83.2L6.2,69.7L0,77.5z" />
    </svg>
);

export default IconArrowDown;
