import React, { FC, ReactElement } from 'react';

import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { EscoOccupation } from '../../../../models/Esco';
import { Province } from '../../../../models/Provinces';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexOccupationProvince.scss';

interface VacancyIndexOccupationProvinceProps {
    isLoading: boolean;
    letter: string;
    occupation: EscoOccupation;
    provinceOptionColumns: Province[][];
    className?: string;
}

const VacancyIndexOccupationProvince: FC<VacancyIndexOccupationProvinceProps> = ({
    isLoading,
    letter,
    occupation,
    provinceOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.occupation.province', {
            occupation: occupation.name,
        })}
        className={`vacancy-index-occupation-province ${className}`}
    >
        <VacancyIndexColumnList>
            {provinceOptionColumns.map(column => {
                const columnIndex = column.map(province => province.slug).join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-occupation-province__province-list">
                            {column.map(province => {
                                const searchQuery = encodeSearchQuery({
                                    what: occupation.name,
                                    province: province.slug,
                                });

                                const encodedLetter = encodeURIComponent(letter);
                                const encodedOccupation = encodeURIComponent(occupation.slug);
                                const encodedProvince = encodeURIComponent(province.slug);

                                return (
                                    <li key={province.slug} className="vacancy-index-occupation-province__province">
                                        <VacancyIndexLink
                                            to={RoutePaths.jobsQuery(searchQuery)}
                                            label={province.name}
                                        />

                                        <VacancyIndexLink
                                            to={RoutePaths.jobVacancyIndexOccupation(encodedLetter, encodedOccupation, encodedProvince)}
                                            label={trans('vacancyIndex.button.selectCity')}
                                            className="vacancy-index-occupation-province__city-link"
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexOccupationProvince;
