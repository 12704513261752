import React, { FC, ReactElement, useRef } from 'react';

import { matchPath, useLocation, useParams } from 'react-router-dom';

import { Page } from '../../components';
import {
    ConnectedApplicationMatchCard,
    ConnectedApplicationMotivationCard,
    ConnectedApplicationStatusCard,
    ConnectedCandidateAboutCard,
    ConnectedCandidateAvatarCard,
    ConnectedCandidateContactCard,
    ConnectedCandidateHelmet,
    ConnectedCandidateMatchCard,
    ConnectedCandidatePreferencesCard,
    ConnectedCandidateProfileBreadcrumbs,
    ConnectedCandidateProfileWrapper,
    ConnectedCandidateProgress,
    ConnectedCandidateVideoCard,
    ConnectedCatchphraseCard,
    ConnectedCompetenciesCard,
    ConnectedCompetenciesToLearnCard,
    ConnectedCurrentStudyCard,
    ConnectedJobExperiencesCard,
    ConnectedSchoolExperiencesCard,
    ConnectedSkillsCard,
    ConnectedSkillsToLearnCard,
} from '../../connectors';
import { ProfileStepId } from '../../containers/@profile/ProfileProgress/ProfileProgress';
import { RoleType } from '../../models/User';
import { RouteParams, RoutePaths } from '../../routes';

import './CandidateProfile.scss';

interface CandidateProfileParams extends RouteParams {
    candidateUuid?: string;
    vacancyUuid?: string;
    applicationUuid?: string;
}

const CandidateProfile: FC = (): ReactElement => {
    const location = useLocation();
    const {
        candidateUuid = '',
        vacancyUuid = '',
        applicationUuid = '',
    } = useParams<CandidateProfileParams>();

    const isCompanyApplicationPage = matchPath(RoutePaths.myVacancyApplication(), location.pathname);
    const isCompanyMatchPage = matchPath(RoutePaths.myVacancyMatch(), location.pathname);

    const activationRefs = {
        [ProfileStepId.avatar]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.catchphrase]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.about]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.competencies]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.skills]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.competenciesToLearn]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.skillsToLearn]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.schoolExperience]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.jobExperience]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.youTube]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.employmentPreference]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.workRemote]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.driversLicenses]: useRef<HTMLButtonElement>(null),
        [ProfileStepId.sectors]: useRef<HTMLButtonElement>(null),
    };

    return (
        <Page className="candidate-profile-page">
            <ConnectedCandidateProfileWrapper
                candidateUuid={candidateUuid}
                vacancyUuid={vacancyUuid}
                applicationUuid={applicationUuid}
            >
                {(candidate => (
                    <div className="candidate-profile-page__wrapper">
                        <ConnectedCandidateHelmet
                            isCompanyApplication={!!isCompanyApplicationPage}
                            isCompanyMatch={!!isCompanyMatchPage}
                        />

                        {(isCompanyMatchPage || isCompanyApplicationPage) && (
                            <ConnectedCandidateProfileBreadcrumbs
                                isCompanyApplication={!!isCompanyApplicationPage}
                                isCompanyMatch={!!isCompanyMatchPage}
                                className="candidate-profile-page__breadcrumbs"
                            />
                        )}

                        <header className="candidate-profile-page__header">
                            <ConnectedCandidateAvatarCard
                                activationRef={activationRefs[ProfileStepId.avatar]}
                                isCompanyApplication={!!isCompanyApplicationPage}
                                isCompanyMatch={!!isCompanyMatchPage}
                                className="candidate-profile-page__header-card"
                            />

                            {candidate && candidate.role === RoleType.jobSeeker ? (
                                <ConnectedCatchphraseCard
                                    activationRef={activationRefs[ProfileStepId.catchphrase]}
                                    className="candidate-profile-page__header-card"
                                />
                            ) : (
                                <ConnectedCurrentStudyCard className="candidate-profile-page__header-card" />
                            )}
                        </header>

                        <ConnectedCandidateProgress
                            activationRefs={activationRefs}
                            className="candidate-profile-page__progress"
                        />

                        <div className="candidate-profile-page__card-wrapper">
                            <main className="candidate-profile-page__main">
                                {isCompanyApplicationPage && (
                                    <ConnectedApplicationMotivationCard className="candidate-profile-page__card" />
                                )}

                                <ConnectedCandidateAboutCard
                                    activationRef={activationRefs[ProfileStepId.about]}
                                    className="candidate-profile-page__card"
                                />

                                <ConnectedCompetenciesCard
                                    activationRef={activationRefs[ProfileStepId.competencies]}
                                    className="candidate-profile-page__card"
                                />

                                <ConnectedSkillsCard
                                    activationRef={activationRefs[ProfileStepId.skills]}
                                    className="candidate-profile-page__card"
                                />

                                {candidate && candidate.role === RoleType.student && (
                                    <ConnectedCompetenciesToLearnCard
                                        activationRef={activationRefs[ProfileStepId.competenciesToLearn]}
                                        className="candidate-profile-page__card"
                                    />
                                )}

                                {candidate && candidate.role === RoleType.student && (
                                    <ConnectedSkillsToLearnCard
                                        activationRef={activationRefs[ProfileStepId.skillsToLearn]}
                                        className="candidate-profile-page__card"
                                    />
                                )}

                                {candidate && candidate.role === RoleType.jobSeeker && (
                                    <ConnectedSchoolExperiencesCard
                                        activationRef={activationRefs[ProfileStepId.schoolExperience]}
                                        className="candidate-profile-page__card"
                                    />
                                )}

                                <ConnectedJobExperiencesCard
                                    activationRef={activationRefs[ProfileStepId.jobExperience]}
                                    className="candidate-profile-page__card"
                                />
                            </main>

                            <aside className="candidate-profile-page__sidebar">
                                {isCompanyMatchPage && (
                                    <ConnectedCandidateMatchCard className="candidate-profile-page__card" />
                                )}

                                {isCompanyApplicationPage && (
                                    <>
                                        <ConnectedApplicationStatusCard className="candidate-profile-page__card" />
                                        <ConnectedApplicationMatchCard
                                            candidate={candidate}
                                            className="candidate-profile-page__card"
                                        />
                                    </>
                                )}

                                <ConnectedCandidateVideoCard
                                    activationRef={activationRefs[ProfileStepId.youTube]}
                                    className="candidate-profile-page__card"
                                />

                                <ConnectedCandidatePreferencesCard
                                    employmentPreferencesActivationRef={activationRefs[ProfileStepId.employmentPreference]}
                                    workRemotePreferenceActivationRef={activationRefs[ProfileStepId.workRemote]}
                                    driversLicensesActivationRef={activationRefs[ProfileStepId.driversLicenses]}
                                    preferredSectorsActivationRef={activationRefs[ProfileStepId.sectors]}
                                    className="candidate-profile-page__card"
                                />

                                <ConnectedCandidateContactCard
                                    isCompanyApplication={!!isCompanyApplicationPage}
                                    isCompanyMatch={!!isCompanyMatchPage}
                                    className="candidate-profile-page__card"
                                />
                            </aside>
                        </div>
                    </div>
                ))}
            </ConnectedCandidateProfileWrapper>
        </Page>
    );
};

export default CandidateProfile;
