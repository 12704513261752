import { trans } from '../../helpers/trans';
import { ApplicationAppointment, ApplicationAppointmentFormData } from '../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../models/ApplicationInvites';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { acceptApplicationInviteApiCall, createApplicationInviteApiCall, declineApplicationInviteApiCall } from '../../services/ApplicationInviteService';
import { TypedDispatch } from '..';
import { setError, setIsLoading } from '../application/application';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setApplicationInvite } from './applicationInvite';

export const createApplicationInvite = (applicationUuid: string, formData: ApplicationAppointmentFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const createApplicationInviteResponse = await createApplicationInviteApiCall(applicationUuid, formData);

        if (!isFetchResultSuccessful(createApplicationInviteResponse)) {
            console.error('[createApplicationInvite]', createApplicationInviteResponse.error);
            dispatch(setError(createApplicationInviteResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.applicationInvite.create.success.description'),
        }));

        dispatch(setApplicationInvite(createApplicationInviteResponse.data));
    } catch (error) {
        console.error('[createApplicationInvite]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const acceptApplicationInvite = (appointment: ApplicationAppointment, inviteUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const acceptApplicationInviteResponse = await acceptApplicationInviteApiCall(appointment, inviteUuid);

        if (!isFetchResultSuccessful(acceptApplicationInviteResponse)) {
            console.error('[acceptApplicationInvite]', acceptApplicationInviteResponse.error);
            dispatch(setError(acceptApplicationInviteResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.applicationInvite.accept.success.description'),
        }));

        dispatch(setApplicationInvite(acceptApplicationInviteResponse.data));
    } catch (error) {
        console.error('[acceptApplicationInvite]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const declineApplicationInvite = (applicationInvite: ApplicationInvite) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const declineApplicationInviteResponse = await declineApplicationInviteApiCall(applicationInvite);

        if (!isFetchResultSuccessful(declineApplicationInviteResponse)) {
            console.error('[declineApplicationInvite]', declineApplicationInviteResponse.error);
            dispatch(setError(declineApplicationInviteResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.applicationInvite.decline.success.description'),
        }));

        dispatch(setApplicationInvite(declineApplicationInviteResponse.data));
    } catch (error) {
        console.error('[declineApplicationInvite]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
