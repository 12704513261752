import React, { FC, ReactElement } from 'react';

import { Pagination } from '../../../_old/app_talentz/models/Pagination';
import { VacanciesInfo } from '../../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoleType } from '../../../models/User';
import { VacancyOverview } from '../..';

import './CompanyVacancies.scss';

interface CompanyVacanciesProps {
    isLoading: boolean;
    userRole: RoleType;
    title: string;
    vacancies: NewestVacancy[];
    pagination?: Pagination;
    error: string;
    favouriteIsLoading?: boolean;
    favouriteIds?: string[];
    onFavouriteVacancy?: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    onPaginationChange: (pageNumber: number) => void;
    className?: string;
}

const CompanyVacancies: FC<CompanyVacanciesProps> = ({
    isLoading,
    userRole,
    title,
    vacancies,
    pagination,
    error,
    favouriteIsLoading,
    favouriteIds,
    onFavouriteVacancy,
    onPaginationChange,
    className = '',
}): ReactElement => (
    <section className={`company-vacancies ${className}`}>
        <h1 className="company-vacancies__title">
            {title}
        </h1>

        <VacancyOverview
            isLoading={isLoading}
            userRole={userRole}
            vacancies={vacancies}
            pagination={pagination}
            error={error}
            favouriteIds={favouriteIds}
            favouriteIsLoading={favouriteIsLoading}
            onFavouriteVacancy={onFavouriteVacancy}
            onPaginationChange={onPaginationChange}
            className="company-vacancies__list"
        />
    </section>
);

export default CompanyVacancies;
