import { transformMetaToPagination } from '../_old/app_talentz/models/Pagination';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { convertToQueryParams } from '../helpers/params';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { EditableVacancy, EditableVacancyResource, transformJapiDocumentToEditableVacancy } from '../models/EditableVacancy';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import {
    MyVacanciesOverviewParams,
    transformToArchiveVacancyRequestBody,
    transformToMyVacanciesOverviewFilterParams,
    transformToPublishInternshipVacancyRequestBody,
    transformToPublishJobVacancyRequestBody,
} from '../models/MyVacancies';
import { NewestVacancyResource, transformToNewestVacancy, VacancyOverview } from '../models/NewestVacancy';
import { PlaceInternshipVacancyFormValues, PlaceJobVacancyFormValues } from './PlaceVacancyService';

export const getMyVacanciesApiCall = async (params: MyVacanciesOverviewParams): Promise<FetchResult<VacancyOverview, string>> => {
    const filterParams = transformToMyVacanciesOverviewFilterParams(params);
    const queryParams = convertToQueryParams(filterParams);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies?${queryParams}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<NewestVacancyResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const vacancies = doc.data.map(transformToNewestVacancy);
        const pagination = doc.meta ? transformMetaToPagination(doc.meta) : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                vacancies,
                pagination,
            },
        };
    } catch (error) {
        console.error('[getMyVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getVacancyToEditApiCall = async (vacancyUuid: string): Promise<FetchResult<EditableVacancy, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/vacancies/${vacancyUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EditableVacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const editableVacancy = transformJapiDocumentToEditableVacancy(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: editableVacancy,
        };
    } catch (error) {
        console.error('[getVacancyToEditApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postInternshipVacancyApiCall = async (vacancyValues: PlaceInternshipVacancyFormValues, addressUuid: string): Promise<FetchResult<NewestVacancyResource, string>> => {
    const body = transformToPublishInternshipVacancyRequestBody(vacancyValues, addressUuid);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies`, {
            method: 'POST',
            body: JSON.stringify({ data: body }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc = await response.json();

        if (!isResourceDocument<NewestVacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data,
        };
    } catch (error) {
        console.error('[postInternshipVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchInternshipVacancyApiCall = async (vacancyToEdit: EditableVacancy, vacancyValues: PlaceInternshipVacancyFormValues): Promise<FetchResult<NewestVacancyResource, string>> => {
    const addressUuid = vacancyToEdit?.address?.uuid || '';
    const body = {
        id: vacancyToEdit.uuid,
        ...transformToPublishInternshipVacancyRequestBody(vacancyValues, addressUuid),
    };

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies/${vacancyToEdit.uuid}`, {
            method: 'PATCH',
            body: JSON.stringify({ data: body }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc = await response.json();

        if (!isResourceDocument<NewestVacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data,
        };
    } catch (error) {
        console.error('[patchInternshipVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postJobVacancyApiCall = async (vacancyValues: PlaceJobVacancyFormValues, addressUuid: string): Promise<FetchResult<NewestVacancyResource, string>> => {
    const body = transformToPublishJobVacancyRequestBody(vacancyValues, addressUuid);

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies`, {
            method: 'POST',
            body: JSON.stringify({ data: body }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc = await response.json();

        if (!isResourceDocument<NewestVacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data,
        };
    } catch (error) {
        console.error('[postJobVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchJobVacancyApiCall = async (vacancyToEdit: EditableVacancy, vacancyValues: PlaceJobVacancyFormValues): Promise<FetchResult<NewestVacancyResource, string>> => {
    const addressUuid = vacancyToEdit?.address?.uuid || '';
    const body = {
        id: vacancyToEdit.uuid,
        ...transformToPublishJobVacancyRequestBody(vacancyValues, addressUuid),
    };

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies/${vacancyToEdit.uuid}`, {
            method: 'PATCH',
            body: JSON.stringify({ data: body }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc = await response.json();

        if (!isResourceDocument<NewestVacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data,
        };
    } catch (error) {
        console.error('[patchJobVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const archiveVacancyApiCall = async (vacancyUuid: string, applicationUuids: string[]): Promise<FetchResult<undefined, string>> => {
    const body = applicationUuids.length > 0
        ? transformToArchiveVacancyRequestBody(vacancyUuid, applicationUuids)
        : undefined;

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies/${vacancyUuid}`, {
            method: 'ARCHIVE',
            body: body
                ? JSON.stringify({ data: body })
                : undefined,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[archiveVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const unarchiveVacancyApiCall = async (vacancyUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies/${vacancyUuid}`, {
            method: 'UNARCHIVE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[unarchiveVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteVacancyApiCall = async (vacancyUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/vacancies/${vacancyUuid}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
