import React, { FC, ReactElement } from 'react';

import { transformWorkRemoteOptionToFormOption, WorkRemoteOption } from '../../../models/WorkRemote';
import { CheckboxList } from '../..';

interface WorkRemotePreferenceFilterProps {
    label: string;
    hideLabel?: boolean;
    value: string[];
    workRemoteOptions: WorkRemoteOption[];
    tabIndex?: number;
    onChange: (value: string[]) => void;
    className?: string;
}

const WorkRemotePreferenceFilter: FC<WorkRemotePreferenceFilterProps> = ({
    label,
    hideLabel,
    value,
    workRemoteOptions,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => {
    const noneOption = 'working-from-irrelevant';
    const options = workRemoteOptions
        .filter(option => option.value !== noneOption)
        .map(transformWorkRemoteOptionToFormOption);

    return (
        <div className={`work-remote-preference-filter ${className}`}>
            <CheckboxList
                label={label}
                hideLabel={hideLabel}
                name="work-remote-preference"
                options={options}
                value={value}
                tabIndex={tabIndex}
                onChange={onChange}
            />
        </div>
    );
};

export default WorkRemotePreferenceFilter;
