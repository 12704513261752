import React, { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Tab } from '../../../../../components';
import { AccountSettings } from '../../../../../containers';
import { trans } from '../../../../../helpers/trans';
import { EmailAddressChangeFormData } from '../../../../../models/EmailAddress';
import { RoleType } from '../../../../../models/User';
import { RouteParams, RoutePaths } from '../../../../../routes';
import { useLegacyDispatch, useLegacySelector, useTypedSelector } from '../../../../../store';
import { changeEmailAddress, fetchRecentEmailAddressChange } from '../../../../../store/accountSettings/accountSettingsActions';
import { UserSettingsTab } from '../../../../../types/pageTabs';
import StickyComponent from '../../../../components/StickyComponent';
import TabsSidebar from '../../../../components/TabsSidebar';
import Card from '../../../../styledComponents/components/Card';
import Container from '../../../../styledComponents/components/Container';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Laptop, MaxLaptop } from '../../../../styledComponents/components/MediaQueries';
import Page from '../../../../styledComponents/components/Page';
import { deleteUser } from '../../../store/legacyUser/legacyUserActions';
import { checkForWebPushSubscription } from '../../../store/notifications/notificationsActions';
import { updatePassword } from '../../../store/resetPassword/resetPasswordActions';
import { createPerson, updatePerson } from '../../../store/userPerson/userPersonActions';
import { updatePersonSettings } from '../../../store/userPersonSettings/userPersonSettingsActions';
import PersonDataForm, { EducationFormName } from '../../components/forms/PersonDataForm';
import StudentSettingsForm from '../../components/forms/StudentSettingsForm';

interface SettingsPersonParams extends RouteParams {
    tab?: UserSettingsTab;
}

// TODO: Refactor to PACO connector
const SettingsPerson: FC = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();

    const navigate = useNavigate();
    const { tab = '' } = useParams<SettingsPersonParams>();
    const location = useLocation();
    const locationState = location.state as { completeRegister?: boolean };

    const user = useLegacySelector(state => state.legacyUser.data);
    const userRole = useLegacySelector(state => state.legacyUser.role);

    const person = useLegacySelector(state => state.userPerson.data);
    const personIsLoading = useLegacySelector(state => state.userPerson.loading);
    const personIsUpdating = useLegacySelector(state => state.userPerson.updating);
    const personErrors = useLegacySelector(state => state.userPerson.errors);

    const deleteIsLoading = useLegacySelector(state => state.legacyUser.deleteLoading);
    const deleteErrors = useLegacySelector(state => state.legacyUser.deleteErrors);

    const settings = useLegacySelector(state => state.userPersonSettings.data);
    const settingsIsLoading = useLegacySelector(state => state.userPersonSettings.loading);

    const resetPasswordIsLoading = useLegacySelector(state => state.resetPassword.loading);
    const resetPasswordErrors = useLegacySelector(state => state.resetPassword.errors);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const accountSettingsIsLoading = useTypedSelector(state => state.accountSettingsReducer.isLoading);
    const emailAddressChange = useTypedSelector(state => state.accountSettingsReducer.emailAddressChange);

    useEffect((): void => {
        if (!tab) {
            navigate(RoutePaths.userSettings(UserSettingsTab.personalData), {
                replace: true,
                state: locationState,
            });
        }

        if (tab === UserSettingsTab.account) {
            legacyDispatch(fetchRecentEmailAddressChange());
        }
    }, [tab]);

    const handleUpdatePerson = async (data: any): Promise<void> => {
        if (locationState && locationState.completeRegister) {
            await legacyDispatch(createPerson(data));

            navigate(RoutePaths.userSettings(UserSettingsTab.notificationsAndPrivacy));
            return;
        }

        legacyDispatch(updatePerson(data));
    };

    const handleUpdateSettings = (data: any): void => {
        legacyDispatch(updatePersonSettings(data));
    };

    const handleResetPassword = (data: any): void => {
        legacyDispatch(updatePassword(data));
    };

    const handleChangeEmailAddress = (emailAddressChangeFormData: EmailAddressChangeFormData): void => {
        legacyDispatch(changeEmailAddress(emailAddressChangeFormData));
    };

    const handleDeleteUser = (data: any): void => {
        legacyDispatch(deleteUser(data));
    };

    const handleCheckForWebPushSubscription = (): void => {
        legacyDispatch(checkForWebPushSubscription());
    };

    const tabs = [
        {
            label: trans('navigation.userProfile.myInformation'),
            to: {
                pathname: RoutePaths.userSettings(UserSettingsTab.personalData),
                state: locationState,
            },
        },
        {
            label: trans('navigation.userProfile.myAccount'),
            to: {
                pathname: RoutePaths.userSettings(UserSettingsTab.account),
                state: locationState,
            },
            disabled: person ? person.is_llo_user : false,
        },
        {
            label: trans('navigation.userProfile.notificationsAndPrivacy'),
            to: {
                pathname: RoutePaths.userSettings(UserSettingsTab.notificationsAndPrivacy),
                state: locationState,
            },
            disabled: !person,
        },
    ];

    const disabledEducationFormFields = user && user.school
        ? [EducationFormName.School, EducationFormName.EducationLevel]
        : [];

    return (
        <Page>
            <Helmet>
                <title>Instellingen</title>
                <meta
                    name="description"
                    content="Pas hier je persoonlijke gegevens en account instellingen aan. Bovendien kun je hier je meldingen en privacy gegevens aanpassen."
                />
            </Helmet>
            <Container centered maxWidth="1064px">
                <MaxLaptop>
                    <Row>
                        <Col width={1} mb="md">
                            <TabsSidebar
                                title={trans('navigation.userProfile.settings')}
                                tabs={tabs}
                            />
                        </Col>
                    </Row>
                </MaxLaptop>
                <Row>
                    <Laptop>
                        <Col width={[1, 1, 1, 1, 4 / 12]}>
                            <StickyComponent>
                                <TabsSidebar
                                    title={trans('navigation.userProfile.settings')}
                                    tabs={tabs}
                                />
                            </StickyComponent>
                        </Col>
                    </Laptop>
                    <Col width={[1, 1, 1, 1, 8 / 12]} pl={[0, 0, 0, 0, 'sm']}>
                        <Tab isActive={tab === UserSettingsTab.personalData}>
                            <Card>
                                <PersonDataForm
                                    errors={personErrors}
                                    person={person}
                                    onSubmit={handleUpdatePerson}
                                    loading={personIsLoading || personIsUpdating}
                                    disabledEducationFormFields={disabledEducationFormFields}
                                    userRole={userRole || RoleType.none}
                                />
                            </Card>
                        </Tab>
                        <Tab isActive={tab === UserSettingsTab.account}>
                            <AccountSettings
                                isLoading={accountSettingsIsLoading}
                                isLLOCandidate={!!candidate?.isLlo}
                                user={user || undefined}
                                emailAddressChange={emailAddressChange}
                                resetPasswordIsLoading={resetPasswordIsLoading}
                                deleteAccountIsLoading={deleteIsLoading}
                                connectedProviders={user?.connection_providers || []}
                                resetPasswordErrors={resetPasswordErrors}
                                deleteAccountErrors={deleteErrors}
                                onResetPassword={handleResetPassword}
                                onChangeEmailAddress={handleChangeEmailAddress}
                                onDeleteAccount={handleDeleteUser}
                            />
                        </Tab>
                        <Tab isActive={tab === UserSettingsTab.notificationsAndPrivacy}>
                            <Card>
                                <StudentSettingsForm
                                    isLoading={settingsIsLoading}
                                    settings={settings}
                                    checkForWebPushSubscription={handleCheckForWebPushSubscription}
                                    onSubmit={handleUpdateSettings}
                                />
                            </Card>
                        </Tab>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default SettingsPerson;
