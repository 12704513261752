import { maxEmploymentHoursPerWeek } from '../constants';
import { addDaysToGivenDate, isValidDateString } from '../helpers/date';
import getIncluded from '../japi/getIncluded';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { MinMaxValue } from '../types';
import {
    Address,
    AddressResource,
    defaultAddress,
    transformToAddress,
} from './Address';
import { CompetenciesResource, transformToCompetency } from './Competencies';
import { EducationLevelResource, transformResourceToEducationLevel } from './EducationLevels';
import { EscoOccupationsResource, EscoSkillsResource } from './Esco';
import { FieldsOfStudyResource, transformResourceToFieldOfStudy } from './FieldsOfStudy';
import { GradesResource, transformToGrade } from './Grades';
import { SkillsResource } from './Skills';
import { transformToVacancyType, VacancyTypeResource } from './VacancyTypes';
import { transformToWorkRemoteOption, WorkRemoteResource } from './WorkRemote';

export interface EditableVacancyResource extends Resource {
    type: 'vacancies';
    id: string;
    attributes: {
        uuid: string;
        title: string;
        slug: string;
        description: string;
        sector: string;
        publicationDate: string;
        publicationDays: number;
        companyName: string;
        amountOfEmployeesName: string;
        vacancyTypeName: string;
        employmentHourRange: MinMaxValue;
        maxCompensation: number;
        minCompensation: number;
        grossHourlyWageInCents: MinMaxValue;
        periodFrom: string;
        periodTill: string;
        applyAnonymous: boolean;
        hasMatch: boolean;
        views: number;
        favouriteCount: number;
        applicants: number;
        createdAt: string;
        deletedAt: string;
        updatedAt: string;
    };
    relationships: {
        address: SingleRelationship<'addresses'>;
        company: SingleRelationship<'companies'>;
        educationLevels: MultipleRelationship<'education-levels'>;
        grades: MultipleRelationship<'grades'>;
        fieldOfStudies: MultipleRelationship<'fields-of-study'>;
        vacancyType: SingleRelationship<'vacancy-types'>;
        escoOccupations: SingleRelationship<'esco-occupations'>;
        remoteWorkingOptions: SingleRelationship<'remote-working'>;
        skills: MultipleRelationship<'skills'>;
        escoSkills: MultipleRelationship<'esco-skills'>;
        skillsToLearn: MultipleRelationship<'skills'>;
        competencies: MultipleRelationship<'competencies'>;
        competenciesToLearn: MultipleRelationship<'competencies'>;
        ignoredCandidates: MultipleRelationship<'ignored-candidates'>;
        invitedCandidates: MultipleRelationship<'invited-candidates'>;
    };
}

export interface EditableVacancy {
    isAnonymous: boolean;
    uuid: string;
    title: string;
    slug: string;
    address: Address;
    sector: string;
    publicationDate: Date;
    expirationDate: Date;
    educationLevels: string[];
    grades: string[];
    fieldsOfStudy: string[];
    vacancyType: string;
    employmentHourRange: MinMaxValue;
    minCompensation: number;
    maxCompensation: number;
    grossHourlyWageInCents: MinMaxValue;
    periodFrom: Date;
    periodTill: Date;
    workRemotePreference: string;
    description: string;
    escoOccupations: string[];
    skills: string[];
    escoSkills: string[];
    skillsToLearn: string[];
    competencies: string[];
    competenciesToLearn: string[];
    createdAt: Date;
    deletedAt?: Date;
    updatedAt?: Date;
}

const defaultEditableVacancy = (): EditableVacancy => ({
    isAnonymous: false,
    uuid: '',
    title: '',
    slug: '',
    address: defaultAddress(),
    sector: '',
    publicationDate: new Date(),
    expirationDate: new Date(),
    educationLevels: [],
    grades: [],
    fieldsOfStudy: [],
    vacancyType: '',
    employmentHourRange: {
        min: 0,
        max: maxEmploymentHoursPerWeek,
    },
    minCompensation: 0,
    maxCompensation: 0,
    grossHourlyWageInCents: {
        min: 0,
        max: 0,
    },
    periodFrom: new Date(),
    periodTill: new Date(),
    workRemotePreference: '',
    description: '',
    escoOccupations: [],
    skills: [],
    escoSkills: [],
    skillsToLearn: [],
    competencies: [],
    competenciesToLearn: [],
    createdAt: new Date(),
    deletedAt: undefined,
    updatedAt: undefined,
});

export const transformJapiDocumentToEditableVacancy = (
    doc: JapiDocument,
    data: EditableVacancyResource,
): EditableVacancy => {
    const defaults = defaultEditableVacancy();

    const createdAt = isValidDateString(data.attributes.createdAt)
        ? new Date(data.attributes.createdAt)
        : defaults.createdAt;

    const updatedAt = data.attributes.updatedAt && isValidDateString(data.attributes.updatedAt)
        ? new Date(data.attributes.updatedAt)
        : defaults.updatedAt;

    const deletedAt = data.attributes.deletedAt && isValidDateString(data.attributes.deletedAt)
        ? new Date(data.attributes.deletedAt)
        : defaults.deletedAt;

    const publicationDate = isValidDateString(data.attributes.publicationDate)
        ? new Date(data.attributes.publicationDate)
        : new Date();

    const expirationDate = data.attributes.publicationDays
        ? addDaysToGivenDate(publicationDate, data.attributes.publicationDays)
        : publicationDate;

    const periodFrom = isValidDateString(data.attributes.periodFrom)
        ? new Date(data.attributes.periodFrom)
        : defaults.periodFrom;

    const periodTill = isValidDateString(data.attributes.periodTill)
        ? new Date(data.attributes.periodTill)
        : defaults.periodTill;

    const includedAddress = getIncluded<AddressResource>(doc, data, 'address');

    const includedEducationLevels = getMultipleIncluded<EducationLevelResource>(doc, data, 'educationLevels');
    const includedGrades = getMultipleIncluded<GradesResource>(doc, data, 'grades');
    const includedFieldsOfStudy = getMultipleIncluded<FieldsOfStudyResource>(doc, data, 'fieldOfStudies');

    const includedVacancyType = getIncluded<VacancyTypeResource>(doc, data, 'vacancyType');
    const includedRemoteWorkingOptions = getIncluded<WorkRemoteResource>(doc, data, 'remoteWorkingOptions');

    const includedEscoOccupations = getMultipleIncluded<EscoOccupationsResource>(doc, data, 'escoOccupations');

    const includedSkills = getMultipleIncluded<SkillsResource>(doc, data, 'skills');
    const includedEscoSkills = getMultipleIncluded<EscoSkillsResource>(doc, data, 'escoSkills');
    const includedSkillsToLearn = getMultipleIncluded<SkillsResource>(doc, data, 'skillsToLearn');

    const includedCompetencies = getMultipleIncluded<CompetenciesResource>(doc, data, 'competencies');
    const includedCompetenciesToLearn = getMultipleIncluded<CompetenciesResource>(doc, data, 'competenciesToLearn');

    const address = includedAddress
        ? transformToAddress(includedAddress)
        : defaults.address;

    const educationLevels = includedEducationLevels
        ? includedEducationLevels.map(transformResourceToEducationLevel).map(educationLevel => educationLevel.id)
        : defaults.educationLevels;

    const grades = includedGrades
        ? includedGrades.map(transformToGrade).map(grade => grade.id)
        : defaults.grades;

    const fieldsOfStudy = includedFieldsOfStudy
        ? includedFieldsOfStudy.map(transformResourceToFieldOfStudy).map(fieldOfStudy => fieldOfStudy.id)
        : defaults.fieldsOfStudy;

    const vacancyType = includedVacancyType
        ? transformToVacancyType(includedVacancyType).id
        : defaults.vacancyType;

    const workRemotePreference = includedRemoteWorkingOptions
        ? transformToWorkRemoteOption(includedRemoteWorkingOptions).id
        : defaults.workRemotePreference;

    const escoOccupations = includedEscoOccupations
        ? includedEscoOccupations.map(occupation => occupation.id)
        : defaults.escoOccupations;

    const skills = includedSkills
        ? includedSkills.map(skill => skill.id)
        : defaults.skills;

    const escoSkills = includedEscoSkills
        ? includedEscoSkills.map(escoSkill => escoSkill.id)
        : defaults.escoSkills;

    const skillsToLearn = includedSkillsToLearn
        ? includedSkillsToLearn.map(skillToLearn => skillToLearn.id)
        : defaults.skillsToLearn;

    const competencies = includedCompetencies
        ? includedCompetencies.map(transformToCompetency).map(competency => competency.id)
        : defaults.competencies;

    const competenciesToLearn = includedCompetenciesToLearn
        ? includedCompetenciesToLearn.map(transformToCompetency).map(competencyToLearn => competencyToLearn.id)
        : defaults.competenciesToLearn;

    return {
        isAnonymous: data.attributes.applyAnonymous,
        uuid: data.attributes.uuid,
        title: data.attributes.title,
        slug: data.attributes.slug,
        address,
        sector: data.attributes.sector,
        publicationDate,
        expirationDate,
        educationLevels,
        grades,
        fieldsOfStudy,
        vacancyType,
        employmentHourRange: data.attributes.employmentHourRange,
        minCompensation: data.attributes.minCompensation,
        maxCompensation: data.attributes.maxCompensation,
        grossHourlyWageInCents: data.attributes.grossHourlyWageInCents,
        periodFrom,
        periodTill,
        workRemotePreference,
        description: data.attributes.description,
        escoOccupations,
        skills,
        escoSkills,
        skillsToLearn,
        competencies,
        competenciesToLearn,
        createdAt,
        deletedAt,
        updatedAt,
    };
};
