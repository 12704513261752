import React from 'react';

import PropTypes from 'prop-types';

import Card from '../../styledComponents/components/Card';
import Icon from '../../styledComponents/components/Icon';
import { Heading } from '../../styledComponents/components/Typography';
import { Tabs, TabsItem, TabsLink } from './style';

const TabsSidebar = ({ title, tabs }) => (
    <Card>
        <Heading as="h1" color="text">{title}</Heading>
        <Tabs>
            {tabs.map((tab, index) => (
                <TabsItem key={tab.label}>
                    <TabsLink id={`tab-link-${index}`} data-hj-suppress as={tab.disabled ? 'div' : null} disabled={tab.disabled} to={!tab.disabled ? tab.to : null}>
                        {tab.label}
                        <Icon kind="forward" size={15} ml="auto" />
                    </TabsLink>
                </TabsItem>
            ))}
        </Tabs>
    </Card>
);

TabsSidebar.propTypes = {
    tabs: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

export default TabsSidebar;
