// TODO: Deprecate this version so that we don't need an extra transform layer to transform to correct query params (blame the back end!)
export const convertToQueryParams = (queryParams: any): string => {
    const filteredEntries = Object.entries(queryParams).filter(([, value]) => value !== undefined);
    const filteredParams = Object.fromEntries(filteredEntries);

    return Object.entries(filteredParams).map(entry => entry.join('=')).join('&');
};

export const convertToQueryParamsNew = (queryParams: any): URLSearchParams => {
    const params = new URLSearchParams();

    Object.keys(queryParams).forEach(key => {
        const param = queryParams[key];

        if (Array.isArray(param)) {
            param.forEach(paramValue => {
                params.append(`${key}[]`, paramValue);
            });
        } else if (queryParams[key] !== undefined) {
            params.append(key, queryParams[key] as string);
        }
    });

    return params;
};
