import { isFetchResultSuccessful } from '../../models/FetchResult';
import { RoleType } from '../../models/User';
import { getEducationLevelsApiCall } from '../../services/EducationLevelsService';
import { TypedDispatch } from '..';
import { LegacyReducers } from '../reducers';
import {
    setEducationLevels,
    setEducationSubLevels,
    setError,
    setIsLoading,
} from './educationLevels';

export const fetchEducationLevels = () => async (dispatch: TypedDispatch, getState: () => LegacyReducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { legacyUser } = getState();

        const educationLevelsResponse = await getEducationLevelsApiCall(legacyUser.role || RoleType.none);

        if (!isFetchResultSuccessful(educationLevelsResponse)) {
            dispatch(setError(educationLevelsResponse.error));
            return;
        }

        const { educationLevels, educationSubLevels } = educationLevelsResponse.data;

        dispatch(setEducationLevels(educationLevels));
        dispatch(setEducationSubLevels(educationSubLevels));
    } catch (error) {
        console.error('[fetchEducationLevels]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
