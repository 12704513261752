import React, { FC, ReactElement } from 'react';

import { Input } from '../../../../components';
import { IconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';

import './NewsletterForm.scss';

interface NewsletterFormProps {
    className?: string;
}

const NewsletterForm: FC<NewsletterFormProps> = ({
    className = '',
}): ReactElement => {
    const subscriptionEndpoint = 'https://talentz.us3.list-manage.com/subscribe/post?u=07f05ad84b9f1526f49c14a60&amp;id=dad710038d';

    return (
        <form
            id="mailchimp-subscribe-form"
            method="POST"
            action={subscriptionEndpoint}
            target="_blank"
            noValidate
            className={`newsletter-form ${className}`}
        >
            <Input
                type="email"
                name="EMAIL"
                aria-label={trans('forms.newsletter.label')}
                placeholder="E-mailadres"
                className="newsletter-form__input"
            />

            <input
                type="hidden"
                name="b_07f05ad84b9f1526f49c14a60_dad710038d"
                tabIndex={-1}
            />

            <IconButton
                type="submit"
                icon="check"
                text={trans('forms.newsletter.action')}
                className="newsletter-form__submit-button"
            />
        </form>
    );
};

export default NewsletterForm;
