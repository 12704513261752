import { Resource } from '../japi/types/Resource';

export interface CompetenciesResource extends Resource {
    type: 'competencies';
    id: string;
    attributes: {
        name: string;
        description: string;
        behavioralExamples: string[];
        createdAt: string;
        updatedAt: string;
    };
}

export interface Competency {
    id: string;
    name: string;
    description: string;
    behavioralExamples: string[];
}

export interface CompetenciesFormData {
    competencies: Competency[];
}

export const transformToCompetency = (
    competenciesResource: CompetenciesResource,
): Competency => ({
    id: competenciesResource.id,
    name: competenciesResource.attributes.name,
    description: competenciesResource.attributes.description,
    behavioralExamples: competenciesResource.attributes.behavioralExamples,
});
