import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { useLocation, useToggle } from 'react-use';

import { IconButton } from '../../../../compositions';
import { MenuItem } from '../../../../connectors/ConnectedMenu/helpers';
import { trans } from '../../../../helpers/trans';
import useHandleClickOutside from '../../../../hooks/useHandleClickOutside';
import { MenuList } from '..';
import { MenuListTemplate } from '../MenuList/MenuList';

import './ExpandableInformationNavigation.scss';

interface ExpandableInformationNavigationProps {
    isLoading?: boolean;
    primaryMenuItems: MenuItem[];
    secondaryMenuItems: MenuItem[];
    className?: string;
}

const ExpandableInformationNavigation: FC<ExpandableInformationNavigationProps> = ({
    isLoading,
    primaryMenuItems,
    secondaryMenuItems,
    className = '',
}): ReactElement => {
    const location = useLocation();
    const [navigationIsExpanded, toggleNavigation] = useToggle(false);

    const closeNavigation = (): void => toggleNavigation(false);

    const informationNavigationRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(informationNavigationRef, closeNavigation);

    useEffect((): void => {
        toggleNavigation(false);
    }, [location, toggleNavigation]);

    const navigationClassNames = classNames('expandable-information-navigation__navigation', {
        'expandable-information-navigation__navigation--is-expanded': navigationIsExpanded,
    });

    return (
        <div ref={informationNavigationRef} className={`expandable-information-navigation ${className}`}>
            <IconButton
                icon={navigationIsExpanded ? 'chevron-up' : 'chevron-down'}
                iconPos="right"
                text={trans('navigation.labels.information')}
                disabled={isLoading}
                onClick={toggleNavigation}
                className="expandable-information-navigation__toggle-button"
                iconClassName="expandable-information-navigation__toggle-icon"
            />

            <div className={navigationClassNames}>
                <MenuList
                    template={MenuListTemplate.information}
                    menuItems={primaryMenuItems}
                    tabIndex={navigationIsExpanded ? 0 : -1}
                    className="expandable-information-navigation__list"
                />
                <MenuList
                    template={MenuListTemplate.information}
                    menuItems={secondaryMenuItems}
                    tabIndex={navigationIsExpanded ? 0 : -1}
                    className="expandable-information-navigation__list"
                />
            </div>
        </div>
    );
};

export default ExpandableInformationNavigation;
