import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton } from '../../../../../components';
import { IconButton, Modal, ModalContent } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { WorkRemotePreferenceFormData } from '../../../../../models/WorkRemote';
import { FormOption } from '../../../../../types';
import { WorkRemotePreferenceForm } from '../../../..';
import { ActivationRef } from '../../../ProfileProgress/ProfileProgress';
import { CandidatePreferencesCardSkeletons } from '../../skeletons';

import './WorkRemotePreference.scss';

interface WorkRemotePreferenceProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    workRemotePreference: FormOption;
    workRemotePreferenceOptions: FormOption[];
    onEdit: (workRemotePreferenceFormData: WorkRemotePreferenceFormData) => void;
    className?: string;
}

const WorkRemotePreference: FC<WorkRemotePreferenceProps> = ({
    activationRef,
    isLoading,
    isEditable,
    workRemotePreference,
    workRemotePreferenceOptions,
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditWorkRemotePreference = (workRemotePreferenceFormData: WorkRemotePreferenceFormData): void => {
        onEdit(workRemotePreferenceFormData);
        closeEditModal();
    };

    return (
        <div className={`work-remote-preference ${className}`}>
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            <h3 className="work-remote-preference__title">
                {trans('candidateProfile.workRemotePreference.title')}
            </h3>

            {isLoading && (
                <CandidatePreferencesCardSkeletons
                    amount={1}
                    className="work-remote-preference__skeletons"
                />
            )}

            {!isLoading && !workRemotePreference.value && (
                <div className="work-remote-preference__null-state">
                    <p>{trans('candidateProfile.workRemotePreference.nullState')}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('userProfile.content.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="work-remote-preference__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && workRemotePreference.value && (
                <p className="work-remote-preference__preference">
                    {workRemotePreference.label}
                </p>
            )}

            {isEditable && workRemotePreference.value && (
                <IconButton
                    icon="pencil"
                    text={trans('actions.edit')}
                    hideLabel
                    disabled={isLoading}
                    onClick={openEditModal}
                    className="work-remote-preference__edit-button"
                />
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.workRemotePreference.modal.title')}>
                        <WorkRemotePreferenceForm
                            workRemotePreference={workRemotePreference}
                            workRemotePreferenceOptions={workRemotePreferenceOptions}
                            onCancel={closeEditModal}
                            onSubmit={handleEditWorkRemotePreference}
                        />
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default WorkRemotePreference;
