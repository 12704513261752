import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './_old/app_talentz/App';
import { registerServiceWorker } from './_old/helpers/ServiceWorkerHelper';
import { store } from './store';

axios.defaults.baseURL = `${process.env.REACT_APP_API_V1_URL}/api/v1`;

if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

// TODO:
// - isSbbUser on Candidate?

const container = document.getElementById('root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <HelmetProvider context={helmetContext}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </HelmetProvider>
    </StoreProvider>,
);

registerServiceWorker();
