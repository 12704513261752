const arrayEquals = (array1, array2) => {
    if (!array1 && !array2) {
        return true;
    }

    if (!array1 || !array2 || (array1.length !== array2.length)) {
        return false;
    }

    for (let i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested array2s
        if (Array.isArray(array1[i]) && Array.isArray(array2[i])) {
            // recurse into the nested array2s
            if (!arrayEquals(array1[i], array2[i])) {
                return false;
            }
        } else if (array1[i] !== array2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
};

export default arrayEquals;
