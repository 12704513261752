import React, { ReactElement, useEffect } from 'react';

import { getLLOLoginRedirectUrl } from '../../_old/app_talentz/store/legacyUser/legacyUserActions';
import Loader from '../../_old/components/Loader';
import { useTypedDispatch } from '../../store';

const ConnectedLLOLogin = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const fetchRedirectUrl = async () => {
        if (typeof window !== 'undefined') {
            const redirectUrl = await dispatch(getLLOLoginRedirectUrl());

            window.open(String(redirectUrl), '_self');
        }
    };

    useEffect((): void => {
        fetchRedirectUrl();
    }, []);

    return (
        // Fake spinner, to have a "page"
        <Loader />
    );
};

export default ConnectedLLOLogin;
