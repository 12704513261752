import React, { cloneElement, Component } from 'react';

import PropTypes from 'prop-types';
import {
    animated,
    config,
    Spring,
    Trail,
    Transition,
} from 'react-spring/renderprops.cjs';

import { trans } from '../../../helpers/trans';
import { RoutePaths } from '../../../routes';
import { Col } from '../../styledComponents/components/Grid';
import Icon from '../../styledComponents/components/Icon';
import { MaxTablet, Tablet } from '../../styledComponents/components/MediaQueries';
import { Heading, Paragraph } from '../../styledComponents/components/Typography';
import {
    Card,
    CloseButton,
    CloseLabel,
    Content,
    LinkButton,
    Logo,
    Message,
    Overlay,
} from './style';

class MultiStepOverlay extends Component {
    componentDidUpdate(prevProps) {
        const { children, message } = this.props;
        if (
            typeof window !== 'undefined'
            && (
                (prevProps.children === null && children !== null)
                || (prevProps.message === null && message !== null)
            )
        ) {
            document.body.style.overflow = 'hidden';
        }

        if (
            typeof window !== 'undefined'
            && (
                (prevProps.children !== null && children === null)
                || (prevProps.message !== null && message === null)
            )
        ) {
            document.body.style.overflow = 'auto';
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            document.body.style.overflow = 'auto';
        }
    }

    getMessageNodes() {
        const { message } = this.props;
        if (!message) return null;

        const {
            title,
            body,
            step,
            button,
        } = message;
        const nodes = [];

        title && nodes.push(
            <Heading as="h2" key={`${step}-title`} color="white" size="xl" width="100%" mb={['md', 'md', 'md', 'md', body ? 'lg' : 'none']}>
                {title}
            </Heading>,
        );

        body && nodes.push(
            <Paragraph key={`${step}-message`} size="md" width={1} fontType="bold" color="white">
                {body}
            </Paragraph>,
        );

        button && nodes.push(button);

        return nodes;
    }

    onCloseHandler = e => {
        e.preventDefault();
        this.props.onClose();
    };

    render() {
        const {
            children,
            step,
            onClose,
            id,
        } = this.props;

        const messageNodes = this.getMessageNodes();

        return (
            <Transition
                items={!!(children || messageNodes)}
                from={{ opacity: 0 }}
                enter={{ opacity: 1 }}
                leave={{ opacity: 0 }}
            >
                {hasMessages => hasMessages && (overlayStyles => (
                    <Overlay messageOnly={!children} formOnly={!messageNodes} style={overlayStyles}>
                        {onClose
                            ? (
                                <LinkButton to={RoutePaths.home()} onClick={this.onCloseHandler}>
                                    <Logo />
                                </LinkButton>
                            )
                            : (
                                <LinkButton as="span">
                                    <Logo />
                                </LinkButton>
                            )
                        }
                        {onClose && (
                            <Tablet>
                                <CloseButton onClick={this.onCloseHandler}>
                                    <Icon kind="exit" color="white" opacity={0.4} size={20} mr={[0, 0, 0, 0, '10px']} />
                                    <CloseLabel>{trans('actions.close')}</CloseLabel>
                                </CloseButton>
                            </Tablet>
                        )}
                        <Content id={id} className="multi-step-overlay__content">
                            {messageNodes && (
                                <Col width={[1, 1, 1, 1, 1, 8 / 16]} pr={2} m="auto" justifyContent={children ? 'flex-end' : 'center'}>
                                    <Message centered={!children} className="multi-step-overlay__message">
                                        <Trail
                                            items={messageNodes}
                                            keys={messageNodes.map((n, i) => `message-${i}`)}
                                            from={{ opacity: 0, transform: 'translateY(40px)' }}
                                            to={{ opacity: 1, transform: 'translateY(0px)' }}
                                            config={config.wobbly}
                                        >
                                            {node => msgStyle => cloneElement(node, { style: msgStyle })}
                                        </Trail>
                                    </Message>
                                </Col>
                            )}
                            {children && (
                                <Col width={[1, 1, 1, 1, 1, 7 / 16]}>
                                    <Spring
                                        from={{ opacity: 0, transform: 'translateY(40px)' }}
                                        to={{ opacity: 1, transform: 'translateY(0px)' }}
                                        delay={200}
                                        config={config.wobbly}
                                    >
                                        {formStyles => (
                                            <Card key={`${step}-card`} as={animated.div} style={formStyles}>
                                                <MaxTablet>
                                                    {onClose && (
                                                        <CloseButton onClick={this.onCloseHandler}>
                                                            <Icon kind="exit" color="primary" opacity={0.4} size={20} />
                                                        </CloseButton>
                                                    )}
                                                </MaxTablet>
                                                {children}
                                            </Card>
                                        )}
                                    </Spring>
                                </Col>
                            )}
                        </Content>
                    </Overlay>
                ))}
            </Transition>
        );
    }
}

MultiStepOverlay.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node,
    message: PropTypes.object,
    step: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    id: PropTypes.string,
};

MultiStepOverlay.defaultProps = {
    message: null,
    children: null,
    step: null,
    onClose: null,
    id: null,
};

export default MultiStepOverlay;
