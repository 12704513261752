import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../helpers/trans';
import { SbbRecognition } from '../../../../models/SbbTrainingCompany';

import './SbbPersonRecognitions.scss';

interface SbbPersonRecognitionsProps {
    recognitions: SbbRecognition[];
    className?: string;
}

const SbbPersonRecognitions: FC<SbbPersonRecognitionsProps> = ({
    recognitions,
    className = '',
}): ReactElement => (
    <div className={`sbb-person-recognitions ${className}`}>
        <p className="sbb-person-recognitions__title">
            {trans('userProfile.sbb.isEligibleFor')}:
        </p>

        <ul className="sbb-person-recognitions__list">
            {recognitions.map(recognition => (
                <li key={recognition.crebo} className="sbb-person-recognitions__item">
                    {`${recognition.description} (${recognition.crebo}) (${recognition.level})`}
                </li>
            ))}
        </ul>
    </div>
);

export default SbbPersonRecognitions;
