import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../../components';
import { VacancyIndexLink } from '..';

import './VacancyIndexBreadcrumb.scss';

interface VacancyIndexBreadcrumbProps {
    to?: string;
    label: string;
    className?: string;
}

const VacancyIndexBreadcrumb: FC<VacancyIndexBreadcrumbProps> = ({
    to,
    label,
    className = '',
}): ReactElement => (
    <li className={`vacancy-index-breadcrumb ${className}`}>
        <VacancyIndexLink
            to={to}
            label={label}
            className="vacancy-index-breadcrumb__link"
        />

        <Icon name="chevron-right" className="vacancy-index-breadcrumb__icon" />
    </li>
);

export default VacancyIndexBreadcrumb;
