import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { animateToFormElement } from '../../../_old/helpers/formHelper';

export default function animateToJobVacancyErrorElement(errors: ValidationErrors) {
    if (errors.title) {
        animateToFormElement('title');
    } else if (errors.zipcode) {
        animateToFormElement('zipcode');
    } else if (errors.sectors) {
        animateToFormElement('sectors');
    } else if (errors.publicationDate) {
        animateToFormElement('publication-date');
    } else if (errors.educationLevels) {
        animateToFormElement('education-levels');
    } else if (errors.employmentHourRange) {
        animateToFormElement('employment-hour-range');
    } else if (errors.jobType) {
        animateToFormElement('job-type');
    } else if (errors.workRemotePreference) {
        animateToFormElement('work-remote-preference');
    } else if (errors.skills) {
        animateToFormElement('skills');
    } else if (errors.competencies) {
        animateToFormElement('job-competence');
    } else if (errors.occupations) {
        animateToFormElement('occupations');
    } else if (errors.salary) {
        animateToFormElement('salary');
    } else if (errors.description) {
        animateToFormElement('description-label');
    }
}
