import React, { FC, FormEvent, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import {
    CandidateTypesFilter,
    DistanceFilter,
    DriversLicensesFilter,
    EducationLevelsFilter,
    EmploymentFilter,
    ExpandableFilter,
    SectorsFilter,
    WorkRemotePreferenceFilter,
} from '../../../../compositions';
import { objectsAreEqual } from '../../../../helpers/object';
import { addHourUnit, addKmUnit } from '../../../../helpers/string';
import { trans } from '../../../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../../../models/EducationLevels';
import { defaultFullEmploymentPreference } from '../../../../models/Employment';
import { VacancyMatchesFilterValues } from '../../../../models/VacancyMatchesFilter';
import { WorkRemoteOption } from '../../../../models/WorkRemote';
import { FormOption, MinMaxValue } from '../../../../types';

import './VacancyMatchesFilterDesktop.scss';

interface VacancyMatchesFilterDesktopProps {
    isLoading: boolean;
    hasLLOFilter?: boolean;
    vacancyType?: VacancyType;
    filterValues: VacancyMatchesFilterValues;
    activeFilterValues: VacancyMatchesFilterValues;
    candidateTypeOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    sectorOptions: FormOption[];
    workRemoteOptions: WorkRemoteOption[];
    driversLicenseOptions: FormOption[];
    onChange: (values: Partial<VacancyMatchesFilterValues>) => void;
    onSubmit: () => void;
    className?: string;
}

const VacancyMatchesFilterDesktop: FC<VacancyMatchesFilterDesktopProps> = ({
    isLoading,
    hasLLOFilter,
    vacancyType,
    filterValues,
    activeFilterValues,
    candidateTypeOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    sectorOptions,
    workRemoteOptions,
    driversLicenseOptions,
    onChange,
    onSubmit,
    className = '',
}): ReactElement => {
    const employmentPreferencesIsActive = !objectsAreEqual(activeFilterValues.employmentPreferences, defaultFullEmploymentPreference());
    const selectedEmploymentPreferences = employmentPreferencesIsActive
        ? Object.values(activeFilterValues.employmentPreferences).map(addHourUnit).join(' - ')
        : undefined;

    const selectedTravelDistance = activeFilterValues.travelDistance > 0
        ? addKmUnit(activeFilterValues.travelDistance)
        : undefined;

    const handleCandidateTypesChange = (value: string[]): void => {
        onChange({ candidateTypes: value });
    };

    const handleEmploymentPreferenceChange = (value: MinMaxValue): void => {
        onChange({ employmentPreferences: value });
    };

    const handleEducationLevelsChange = (value: string[]): void => {
        onChange({ educationLevels: value });
    };

    const handleSectorsChange = (value: string[]): void => {
        onChange({ sectors: value });
    };

    const handleWorkRemotePreferencesChange = (value: string[]): void => {
        onChange({ workRemotePreference: value });
    };

    const handleDriversLicensesChange = (value: string[]): void => {
        onChange({ driversLicenses: value });
    };

    const handleTravelDistanceChange = (value: number): void => {
        onChange({ travelDistance: value });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => event.preventDefault();

    return (
        <form onSubmit={handleSubmit} className={`vacancy-matches-filter-desktop ${className}`}>
            {vacancyType === VacancyType.job && (
                <ExpandableFilter
                    isActive={activeFilterValues.candidateTypes.length > 0}
                    label={trans('forms.preferences.candidateType')}
                    selectedFilter={String(activeFilterValues.candidateTypes.length)}
                    disabled={isLoading}
                    onSave={onSubmit}
                    className="vacancy-matches-filter-desktop__input"
                >
                    {isExpanded => (
                        <CandidateTypesFilter
                            hasLLO={hasLLOFilter}
                            label={trans('forms.preferences.candidateType')}
                            hideLabel
                            value={filterValues.candidateTypes}
                            candidateTypes={candidateTypeOptions}
                            tabIndex={isExpanded ? 0 : -1}
                            onChange={handleCandidateTypesChange}
                        />
                    )}
                </ExpandableFilter>
            )}

            <ExpandableFilter
                isActive={employmentPreferencesIsActive}
                label={trans('forms.preferences.employmentPreferences')}
                selectedFilter={selectedEmploymentPreferences}
                disabled={isLoading}
                onSave={onSubmit}
                className="vacancy-matches-filter-desktop__input"
            >
                {isExpanded => (
                    <EmploymentFilter
                        label={trans('forms.preferences.employmentPreferences')}
                        value={filterValues.employmentPreferences}
                        tabIndex={isExpanded ? 0 : -1}
                        onChange={handleEmploymentPreferenceChange}
                    />
                )}
            </ExpandableFilter>

            <ExpandableFilter
                isActive={activeFilterValues.educationLevels.length > 0}
                label={trans('forms.preferences.educationLevel')}
                selectedFilter={String(activeFilterValues.educationLevels.length)}
                disabled={isLoading}
                onSave={onSubmit}
                className="vacancy-matches-filter-desktop__input"
            >
                {isExpanded => (
                    <EducationLevelsFilter
                        label={trans('forms.preferences.educationLevel')}
                        hideLabel
                        name="education-levels"
                        value={filterValues.educationLevels}
                        educationLevels={educationLevelOptions}
                        educationSubLevels={educationSubLevelOptions}
                        tabIndex={isExpanded ? 0 : -1}
                        onChange={handleEducationLevelsChange}
                    />
                )}
            </ExpandableFilter>

            <ExpandableFilter
                isActive={activeFilterValues.sectors.length > 0}
                label={trans('forms.preferences.sectors')}
                selectedFilter={String(activeFilterValues.sectors.length)}
                disabled={isLoading}
                onSave={onSubmit}
                className="vacancy-matches-filter-desktop__input"
            >
                {isExpanded => (
                    <SectorsFilter
                        label={trans('forms.preferences.sectors')}
                        hideLabel
                        value={filterValues.sectors}
                        sectors={sectorOptions}
                        tabIndex={isExpanded ? 0 : -1}
                        onChange={handleSectorsChange}
                    />
                )}
            </ExpandableFilter>

            <ExpandableFilter
                isActive={activeFilterValues.workRemotePreference.length > 0}
                label={trans('forms.preferences.workRemotePreference')}
                selectedFilter={String(activeFilterValues.workRemotePreference.length)}
                disabled={isLoading}
                onSave={onSubmit}
                className="vacancy-matches-filter-desktop__input"
            >
                {isExpanded => (
                    <WorkRemotePreferenceFilter
                        label={trans('forms.preferences.workRemotePreference')}
                        hideLabel
                        value={filterValues.workRemotePreference}
                        workRemoteOptions={workRemoteOptions}
                        tabIndex={isExpanded ? 0 : -1}
                        onChange={handleWorkRemotePreferencesChange}
                    />
                )}
            </ExpandableFilter>

            <ExpandableFilter
                isActive={activeFilterValues.driversLicenses.length > 0}
                label={trans('forms.preferences.driversLicenses')}
                selectedFilter={String(activeFilterValues.driversLicenses.length)}
                disabled={isLoading}
                onSave={onSubmit}
                className="vacancy-matches-filter-desktop__input"
            >
                {isExpanded => (
                    <DriversLicensesFilter
                        label={trans('forms.preferences.driversLicenses')}
                        hideLabel
                        value={filterValues.driversLicenses}
                        driversLicenses={driversLicenseOptions}
                        tabIndex={isExpanded ? 0 : -1}
                        onChange={handleDriversLicensesChange}
                        className="vacancy-matches-filter-desktop__drivers-licenses"
                    />
                )}
            </ExpandableFilter>

            <ExpandableFilter
                isActive={activeFilterValues.travelDistance > 0}
                label={trans('forms.preferences.travelDistance')}
                selectedFilter={selectedTravelDistance}
                disabled={isLoading}
                onSave={onSubmit}
                className="vacancy-matches-filter-desktop__input"
            >
                {isExpanded => (
                    <DistanceFilter
                        label={trans('forms.preferences.travelDistance')}
                        value={filterValues.travelDistance}
                        tabIndex={isExpanded ? 0 : -1}
                        onChange={handleTravelDistanceChange}
                    />
                )}
            </ExpandableFilter>
        </form>
    );
};

export default VacancyMatchesFilterDesktop;
