import { scroller } from 'react-scroll/modules';

interface KeyValueObject {
    [key: string]: string;
}

export const getOptionWithValue = (
    value: KeyValueObject | string | number,
    option: KeyValueObject,
    key: string,
): boolean => {
    if (!option || (!value && value !== 0)) {
        return false;
    }

    if (typeof value === 'object') {
        return value[key] === option[key];
    }

    return value === option[key];
};

export function animateToFormElement(elementId: string) {
    scroller.scrollTo(elementId, { duration: 1000, smooth: 'easeInOutQuart', offset: -70 });
}

// Removes all special characters
export function sanitizeTextInput(value: string) {
    if (!value) {
        return '';
    }

    return value.replace(/[^a-z\d\s]+/gi, '');
}

export function removeSpacesFromString(value: string) {
    if (!value) {
        return '';
    }

    return value.split(' ').join('');
}
