import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCrop: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M100,70H80V30h0L70,20v0H30V0h-10v20H0v10h20v40h0l0,0v0h0l10,10v0h40v20h10V80h20V70z M30,70L30,70L30,70l0-40h40h0h0v40H30z" />
    </svg>
);

export default IconCrop;
