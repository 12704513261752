import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, TextInput } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { CatchphraseFormData } from '../../../models/Catchphrase';
import { FormProps } from '../../../types';
import { CatchphraseFormErrors, validateCatchphraseFormData } from './validations';

import './CatchphraseForm.scss';

interface CatchphraseFormProps extends FormProps<CatchphraseFormData> {
    catchphrase: string;
    className?: string;
}

const CatchphraseForm: FC<CatchphraseFormProps> = ({
    catchphrase,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CatchphraseFormErrors>({});

    const [catchphraseContent, setCatchphraseContent] = useState<string>(catchphrase);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            catchphrase: catchphraseContent,
        };

        const [errors, hasErrors] = validateCatchphraseFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`catchphrase-form ${className}`}>
            <TextInput
                label={trans('forms.user.catchphrase.label')}
                placeholder={trans('forms.user.catchphrase.placeholder')}
                value={catchphraseContent}
                error={formErrors.catchphrase}
                onChange={setCatchphraseContent}
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="catchphrase-form__action-buttons"
            />
        </form>
    );
};

export default CatchphraseForm;
