import { isFetchResultSuccessful } from '../../models/FetchResult';
import { defaultInternshipVacancyOverviewFilterValues, defaultJobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { getInternshipVacanciesApiCall } from '../../services/InternshipVacancyOverviewService';
import { getJobVacanciesApiCall } from '../../services/JobVacancyOverviewService';
import { TypedDispatch } from '..';
import {
    setError,
    setInternshipVacancies,
    setIsLoading,
    setJobVacancies,
    setTotalVacancies,
} from './recentVacancies';

export const fetchRecentVacancies = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const internshipVacanciesFilterValues = {
            ...defaultInternshipVacancyOverviewFilterValues(),
            pageSize: 4,
        };

        const jobVacanciesFilterValues = {
            ...defaultJobVacancyOverviewFilterValues(),
            pageSize: 4,
        };

        const internshipVacanciesResponse = await getInternshipVacanciesApiCall(internshipVacanciesFilterValues);
        const jobVacanciesResponse = await getJobVacanciesApiCall(jobVacanciesFilterValues);

        if (!isFetchResultSuccessful(internshipVacanciesResponse)) {
            dispatch(setError(internshipVacanciesResponse.error));
            return;
        }

        if (!isFetchResultSuccessful(jobVacanciesResponse)) {
            dispatch(setError(jobVacanciesResponse.error));
            return;
        }

        const internshipVacanciesTotal = internshipVacanciesResponse.data.pagination?.total || 0;
        const jobVacanciesTotal = jobVacanciesResponse.data.pagination?.total || 0;

        const totalVacancies = internshipVacanciesTotal + jobVacanciesTotal;

        dispatch(setInternshipVacancies(internshipVacanciesResponse.data.vacancies));
        dispatch(setJobVacancies(jobVacanciesResponse.data.vacancies));

        dispatch(setTotalVacancies(totalVacancies));
    } catch (error) {
        console.error('[fetchRecentVacancies]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchRecentInternshipVacancies = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const filterValues = {
            ...defaultInternshipVacancyOverviewFilterValues(),
            pageSize: 4,
        };

        const recentInternshipVacanciesResponse = await getInternshipVacanciesApiCall(filterValues);

        if (!isFetchResultSuccessful(recentInternshipVacanciesResponse)) {
            dispatch(setError(recentInternshipVacanciesResponse.error));
            return;
        }

        dispatch(setInternshipVacancies(recentInternshipVacanciesResponse.data.vacancies));
    } catch (error) {
        console.error('[fetchRecentInternshipVacancies]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchRecentJobVacancies = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const filterValues = {
            ...defaultJobVacancyOverviewFilterValues(),
            pageSize: 4,
        };

        const recentJobVacanciesResponse = await getJobVacanciesApiCall(filterValues);

        if (!isFetchResultSuccessful(recentJobVacanciesResponse)) {
            dispatch(setError(recentJobVacanciesResponse.error));
            return;
        }

        dispatch(setJobVacancies(recentJobVacanciesResponse.data.vacancies));
    } catch (error) {
        console.error('[fetchRecentJobVacancies]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
