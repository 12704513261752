import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Candidate } from '../../models/Candidate';
import { AsyncReduxState } from '../../types';

export type CandidateState = AsyncReduxState<{
    candidate?: Candidate;
}>;

const initialState: CandidateState = {
    ...initialAsyncReduxState,
    candidate: undefined,
};

const candidateSlice = createSlice({
    name: 'candidateReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CandidateState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CandidateState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CandidateState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCandidate(state, action: PayloadAction<Candidate | undefined>): CandidateState {
            return {
                ...state,
                candidate: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setCandidate,
} = candidateSlice.actions;

export default candidateSlice;
