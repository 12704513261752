import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCompany: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 90" className={`icon ${className}`}>
        <path d="M50,20V0H0v90h100V20H50z M20,80H10V70h10V80z M20,60H10V50h10V60z M20,40H10V30h10V40z M20,20H10V10h10V20z M40,80H30V70h10V80z M40,60H30V50h10V60z M40,40H30V30h10V40z M40,20H30V10h10V20z M90,80H50V70h10V60H50V50h10V40H50V30h40V80z M80,40H70v10h10V40z M80,60H70v10h10V60z" />
    </svg>
);

export default IconCompany;
