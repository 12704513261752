import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { trans } from '../../../helpers/trans';
import { Competency } from '../../../models/Competencies';
import { Loader, Modal, SearchInput } from '../..';
import { searchCompetencyOptionsOnQuery } from './helpers';
import { CompetencyHighlight, CompetencyOption, CompetencySelectedOptionList } from './subcomponents';

import './CompetenciesSelector.scss';

interface CompetenciesSelectorProps {
    isLoading?: boolean;
    highlightInModal?: boolean;
    label: string;
    value: Competency[];
    options: Competency[];
    error?: string;
    validationError?: string;
    onChange: (competencies: Competency[]) => void;
    className?: string;
}

const CompetenciesSelector: FC<CompetenciesSelectorProps> = ({
    isLoading,
    highlightInModal,
    label,
    value,
    options,
    error,
    validationError,
    onChange,
    className = '',
}): ReactElement => {
    const [optionsAreShown, setOptionsAreShown] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Competency[]>(options);
    const [highlightedCompetency, setHighlightedCompetency] = useState<Competency>();
    const [highlightModalIsOpen, setHighlightModalIsOpen] = useState<boolean>(false);

    const closeHighlightModal = (): void => setHighlightModalIsOpen(false);

    useEffect((): void => {
        if (!highlightModalIsOpen) {
            setHighlightedCompetency(undefined);
        }
    }, [highlightModalIsOpen]);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleFocus = (): void => setOptionsAreShown(true);

    const handleSearch = (query: string): void => {
        setSearchQuery(query);

        if (query === '') {
            setSearchResults(options);
        } else {
            const results = searchCompetencyOptionsOnQuery(options, query);
            setSearchResults(results);
        }
    };

    const handleSelectOption = (competency: Competency, isChecked: boolean): void => {
        const newSelectedOptions = isChecked
            ? [...value, competency]
            : value.filter(option => option.id !== competency.id);

        onChange(newSelectedOptions);
    };

    const handleHighlightCompetency = (competency: Competency): void => {
        setHighlightedCompetency(competency);

        if (highlightInModal) {
            setHighlightModalIsOpen(true);
        }
    };

    const handleCloseHighlight = (): void => setHighlightedCompetency(undefined);

    if (!highlightInModal && highlightedCompetency) {
        return (
            <CompetencyHighlight
                competency={highlightedCompetency}
                onClose={handleCloseHighlight}
            />
        );
    }

    return (
        <div className={`competencies-selector ${className}`}>
            <SearchInput
                ref={inputRef}
                label={label}
                value={searchQuery}
                disabled={!!error || isLoading}
                error={error || validationError}
                onFocus={handleFocus}
                onChange={handleSearch}
            />

            {optionsAreShown && (
                <div>
                    {searchResults.length > 0 ? (
                        <ul className="competencies-selector__option-list">
                            {searchResults.map(competency => (
                                <CompetencyOption
                                    key={competency.id}
                                    isChecked={value.map(option => option.id).includes(competency.id)}
                                    competency={competency}
                                    onChange={handleSelectOption}
                                    onMoreInformationClick={handleHighlightCompetency}
                                    className="competencies-selector__option"
                                />
                            ))}
                        </ul>
                    ) : (
                        <p className="competencies-selector__no-results">
                            {trans('forms.competencies.noResults', {
                                query: searchQuery,
                            })}
                        </p>
                    )}
                </div>
            )}

            {isLoading && (
                <Loader className="competencies-selector__loader" />
            )}

            {!isLoading && value.length > 0 && (
                <CompetencySelectedOptionList
                    selectedOptions={value}
                    onSelectOption={handleSelectOption}
                    className="competencies-selector__selected-options"
                />
            )}

            {highlightInModal && highlightModalIsOpen && highlightedCompetency && (
                <Modal onClose={closeHighlightModal}>
                    <CompetencyHighlight competency={highlightedCompetency} />
                </Modal>
            )}
        </div>
    );
};

export default CompetenciesSelector;
