import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconUnarchive: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 85" className={`icon ${className}`}>
        <polygon points="32.2,50.8 44.6,38.3 44.6,67.5 55.4,67.5 55.4,38.3 67.8,50.8 75.5,43.1 50,17.6 24.5,43.1" />
        <path d="M0,0v35h5v50h90V35h5V0H0z M90,25H68.7l7.5,7.5H85V75H15V32.5h8.8l7.5-7.5H10V10h80V25z" />
    </svg>
);

export default IconUnarchive;
