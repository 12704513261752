/* eslint-disable prefer-promise-reject-errors */
import { configureRefreshFetch } from 'refresh-fetch';

import { AccessToken, LegacyAccessToken } from '../models/AccessToken';
import { isFetchResultSuccessful } from '../models/FetchResult';
import { exchangeRefreshTokenApiCall } from '../services/AuthenticationService';
import { isSSR } from './environment';

export const TOKEN_KEY = 'talentzToken';
export const LEGACY_TOKEN_KEY = 'tokenData';

export enum TokenEnvironmentType {
    Legacy,
    TalentzApi,
}

interface RequestInitExtraOptions extends RequestInit {
    disableContentType?: boolean;
    tokenEnvironmentType?: TokenEnvironmentType;
}

const fetchWithToken = async (url: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithToken: RequestInit = {
        ...options,
        headers: {
            Accept: 'application/json',
            ...options?.headers,
        },
    };

    if (!options?.disableContentType) {
        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            'Content-Type': 'application/json',
        };
    }

    if (!isSSR) {
        if (!options?.tokenEnvironmentType || options?.tokenEnvironmentType === TokenEnvironmentType.TalentzApi) {
            const token = localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);

            if (token) {
                const parsedToken: AccessToken = JSON.parse(token);

                optionsWithToken.headers = {
                    ...optionsWithToken.headers,
                    Authorization: `Bearer ${parsedToken.access_token}`,
                };
            }
        }

        if (options?.tokenEnvironmentType === TokenEnvironmentType.Legacy) {
            const token = localStorage.getItem(LEGACY_TOKEN_KEY) || sessionStorage.getItem(LEGACY_TOKEN_KEY);

            if (token) {
                const parsedToken: LegacyAccessToken = JSON.parse(token);

                optionsWithToken.headers = {
                    ...optionsWithToken.headers,
                    Authorization: `Bearer ${parsedToken.access_token}`,
                };
            }
        }
    }

    const response = await fetch(url, optionsWithToken);

    if (response.status === 401) {
        return Promise.reject({
            response,
        });
    }

    return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefreshToken = (error: any): boolean => error?.response?.status === 401;

const updateRefreshToken = async (): Promise<void> => {
    if (isSSR) return;

    const token = localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);

    if (!token) {
        throw new Error('No token found');
    }

    const parsedToken: AccessToken = JSON.parse(token);
    const refreshResponse = await exchangeRefreshTokenApiCall(parsedToken.refresh_token);

    if (!isFetchResultSuccessful(refreshResponse)) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(LEGACY_TOKEN_KEY);

        sessionStorage.clear();
        window.location.href = '/';

        throw new Error('Refresh token was invalid');
    }

    localStorage.setItem(TOKEN_KEY, JSON.stringify(refreshResponse.data));
};

export const authorizedFetch = configureRefreshFetch({
    fetch: fetchWithToken,
    refreshToken: updateRefreshToken,
    shouldRefreshToken,
});
