import React, { FC, ReactElement, useMemo } from 'react';

import { Logo } from '../../../../components';
import { trans } from '../../../../helpers/trans';

import './EnvironmentLogo.scss';

interface EnvironmentLogoProps {
    className?: string;
}

const EnvironmentLogo: FC<EnvironmentLogoProps> = ({
    className = '',
}): ReactElement => {
    const environment = process.env.REACT_APP_ENV;

    const environmentLabel = useMemo<string>(() => {
        switch (environment) {
            case 'local':
                return trans('basic.environment.local');
            case 'staging':
                return trans('basic.environment.staging');
            case 'acceptance':
                return trans('basic.environment.acceptance');
            case 'production':
            default:
                return '';
        }
    }, [environment]);

    return (
        <div className={`environment-logo ${className}`}>
            <Logo className="environment-logo__logo" />

            {environmentLabel && (
                <span className="environment-logo__underline">
                    {environmentLabel}
                </span>
            )}
        </div>
    );
};

export default EnvironmentLogo;
