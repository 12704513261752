import { isValidDateString } from '../helpers/date';
import getIncluded from '../japi/getIncluded';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import { JapiDocument } from '../japi/types/Document';
import { SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { ChatResource } from './Chat';
import {
    CompanyInfo,
    CompanyInfoResource,
    defaultCompanyInfo,
    transformToCompanyInfo,
} from './info/CompanyInfo';
import {
    defaultPersonInfo,
    PersonInfo,
    PersonInfoResource,
    transformCompanyInfoToPersonInfo,
    transformToPersonInfo,
} from './info/PersonInfo';
import { UserRole } from './User';

export interface ChatMessageResource extends Resource {
    type: 'company-chat-messages';
    id: string;
    attributes: {
        message: {
            type: 'message';
            message: string;
        };
        createdAt: string;
    };
    relationships: {
        author: SingleRelationship<'person-info'>;
    };
}

export interface ChatMessage {
    uuid: string;
    author: PersonInfo;
    text: string;
    createdAt: Date;
}

export const transformJapiDocumentToChatMessage = (
    doc: JapiDocument,
    chatMessageResource: ChatMessageResource,
    company: CompanyInfo,
): ChatMessage => {
    const includedAuthor = getIncluded<PersonInfoResource>(doc, chatMessageResource, 'author');

    const author = includedAuthor
        ? transformToPersonInfo(includedAuthor)
        : defaultPersonInfo();

    const isFromCompany = author.roles.includes(UserRole.company);

    return {
        uuid: chatMessageResource.id,
        author: isFromCompany
            ? transformCompanyInfoToPersonInfo(company)
            : author,
        text: chatMessageResource.attributes.message.message,
        createdAt: isValidDateString(chatMessageResource.attributes.createdAt)
            ? new Date(chatMessageResource.attributes.createdAt)
            : new Date(),
    };
};

export const transformJapiDocumentToChatMessages = (
    doc: JapiDocument,
    chatResource: ChatResource,
): ChatMessage[] => {
    const includedCompany = getIncluded<CompanyInfoResource>(doc, chatResource, 'company');
    const includedMessages = getMultipleIncluded<ChatMessageResource>(doc, chatResource, 'messages');

    const company = includedCompany
        ? transformToCompanyInfo(includedCompany)
        : defaultCompanyInfo();

    return includedMessages
        ? includedMessages.map(messageResource => transformJapiDocumentToChatMessage(doc, messageResource, company))
        : [];
};
