import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface DriversLicensesResource extends Resource {
    type: 'driving-licenses';
    id: string;
    attributes: {
        name: string;
        type: string;
    };
}

export interface DriversLicense {
    id: string;
    type: string;
    name: string;
}

export interface DriversLicensesFormData {
    driversLicenses: FormOption[];
}

export const transformToDriversLicense = (
    driversLicenseResource: DriversLicensesResource,
): DriversLicense => ({
    id: driversLicenseResource.id,
    type: driversLicenseResource.attributes.type,
    name: driversLicenseResource.attributes.name,
});

export const transformDriversLicenseToFormOption = (
    driversLicense: DriversLicense,
): FormOption => ({
    label: driversLicense.type
        ? `${driversLicense.type} - ${driversLicense.name}`
        : driversLicense.name,
    value: driversLicense.id,
});
