import React, { FC, ReactElement } from 'react';

import { trans } from '../../../helpers/trans';

import './InputLabel.scss';

interface InputLabelProps {
    id?: string;
    text: string;
    required?: boolean;
    optional?: boolean; // TODO: Remove optional, since non-required fields are optional by default
    optionalLabel?: string;
    className?: string;
}

const InputLabel: FC<InputLabelProps> = ({
    id,
    text,
    required = false,
    optional = false,
    optionalLabel = trans('forms.optional'),
    className = '',
}): ReactElement => (
    <div id={id} className={`input-label ${className}`}>
        {text}
        {required && <span className="input-label__required">*</span>}
        {optional && <span className="input-label__optional">{optionalLabel}</span>}
    </div>
);

export default InputLabel;
