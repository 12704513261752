import React, { FC, ReactElement, useEffect } from 'react';

import { ApplicationMotivationCard } from '../../../containers';
import { useTypedDispatch, useTypedSelector } from '../../../store';
import { clearApplication, fetchApplication } from '../../../store/application/applicationActions';

interface ConnectedApplicationMotivationCardProps {
    shouldFetchApplication?: boolean;
    applicationUuid?: string;
    className?: string;
}

const ConnectedApplicationMotivationCard: FC<ConnectedApplicationMotivationCardProps> = ({
    shouldFetchApplication,
    applicationUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.applicationReducer.isLoading);
    const application = useTypedSelector(state => state.applicationReducer.application);

    useEffect((): () => void => {
        if (shouldFetchApplication && applicationUuid) {
            dispatch(fetchApplication(applicationUuid));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchApplication) dispatch(clearApplication());
        };
    }, []);

    return (
        <ApplicationMotivationCard
            isLoading={isLoading}
            application={application}
            className={className}
        />
    );
};

export default ConnectedApplicationMotivationCard;
