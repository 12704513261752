import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { Province } from '../../../../models/Provinces';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexProvinceCity.scss';

interface VacancyIndexProvinceCityProps {
    isLoading: boolean;
    vacancyType: VacancyType;
    province: Province;
    cityOptionColumns: string[][];
    className?: string;
}

const VacancyIndexProvinceCity: FC<VacancyIndexProvinceCityProps> = ({
    isLoading,
    vacancyType,
    province,
    cityOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.province.city', {
            province: province.name,
        })}
        className={`vacancy-index-province-city ${className}`}
    >
        <VacancyIndexColumnList>
            {cityOptionColumns.map(column => {
                const columnIndex = column.join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-province-city__city-list">
                            {column.map(city => {
                                const searchQuery = encodeSearchQuery({
                                    province: province.slug,
                                    where: city,
                                });

                                const route = vacancyType === VacancyType.internship
                                    ? RoutePaths.internshipsQuery(searchQuery)
                                    : RoutePaths.jobsQuery(searchQuery);

                                return (
                                    <li key={city} className="vacancy-index-province-city__city">
                                        <VacancyIndexLink to={route} label={city} />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexProvinceCity;
