import React, { FC, ReactElement } from 'react';

import { DetailCard } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoleType } from '../../../models/User';

import './VacancyApplyAnonymousCard.scss';

interface VacancyApplyAnonymousCardProps {
    userRole?: RoleType;
    vacancy: NewestVacancy;
    className?: string;
}

const VacancyApplyAnonymousCard: FC<VacancyApplyAnonymousCardProps> = ({
    userRole,
    vacancy,
    className = '',
}): ReactElement => (
    <DetailCard
        title={trans('vacancyDetail.applyAnonymous.title')}
        className={`vacancy-apply-anonymous-card ${className}`}
    >
        {userRole === RoleType.company ? (
            <p className="vacancy-apply-anonymous-card__description">
                {trans('vacancyDetail.applyAnonymous.companyDescription')}
            </p>
        ) : (
            <p className="vacancy-apply-anonymous-card__description">
                {trans('vacancyDetail.applyAnonymous.candidateDescription', {
                    company: vacancy.company.name,
                })}
            </p>
        )}
    </DetailCard>
);

export default VacancyApplyAnonymousCard;
