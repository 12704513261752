import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { logIn, logOut } from '../../_old/app_talentz/store/legacyUser/legacyUserActions';
import { setStep as setRegisterStep } from '../../_old/app_talentz/store/register/register';
import { setStep as setResetPasswordStep } from '../../_old/app_talentz/store/resetPassword/resetPassword';
import { edukadoUrl } from '../../constants';
import { Menu } from '../../containers';
import { hotjarEvent } from '../../helpers/hotjar';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { toggleExpandFavourites } from '../../store/favourites/favouritesActions';
import { toggleExpandNotifications } from '../../store/notifications/notificationsActions';
import {
    CandidateOverviewTab,
    CompanyOverviewTab,
    CompanySettingsTab,
    UserSettingsTab,
} from '../../types/pageTabs';
import {
    getRoleSpecificItems,
    MenuItem,
    menuItems,
    MenuKey,
} from './helpers';

// TODO: authIsLoading should go via store, once registration and authentication is refactored
interface ConnectedMenuProps {
    authIsLoading: boolean;
}

const ConnectedMenu: FC<ConnectedMenuProps> = ({ authIsLoading }): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { isMobile, isTabletPortrait } = useDeviceWidth();
    const showMobileMenu = isMobile || isTabletPortrait;

    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const role = useLegacySelector(state => state.legacyUser.role);
    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.userReducer.company);

    const favouritesIsExpanded = useTypedSelector(state => state.favouritesReducer.isExpanded);
    const notificationsIsExpanded = useTypedSelector(state => state.notificationsReducer.isExpanded);
    const amountOfNewNotifications = useTypedSelector(state => state.notificationsReducer.amountOfNew);

    const userRole = role || RoleType.none;
    const isValidUser = isAuthenticated && !!person;

    // TODO: Refactor registration, login, logout flows
    const handleRegistrationClick = (): void => {
        dispatch(logIn(false));
        dispatch(setResetPasswordStep(null));
        dispatch(setRegisterStep(0));

        hotjarEvent('vpv', '/registreren');
        hotjarEvent('trigger', 'register_start');
    };

    const handleLoginClick = (): void => {
        dispatch(setResetPasswordStep(null));
        dispatch(setRegisterStep(null));
        dispatch(logIn(true));
    };

    const handleLogoutClick = (): void => {
        dispatch(logOut());
    };

    const handleCompleteRegistrationClick = (): void => {
        const settingsPage = userRole === RoleType.company
            ? RoutePaths.companySettings(CompanySettingsTab.myCompany)
            : RoutePaths.userSettings(UserSettingsTab.personalData);

        navigate(settingsPage, {
            state: {
                // TODO: This was added because old code used it, not sure if needed
                completeRegister: true,
            },
        });
    };

    const handleToggleFavourites = (): void => {
        dispatch(toggleExpandFavourites());
    };

    const handleToggleNotifications = (): void => {
        dispatch(toggleExpandNotifications());
    };

    const handleInfoEducationClick = (): void => {
        if (window) window.open(edukadoUrl);
    };

    useEffect((): void => {
        if (notificationsIsExpanded) {
            dispatch(toggleExpandFavourites(false));
        }
    }, [dispatch, notificationsIsExpanded]);

    useEffect((): void => {
        if (favouritesIsExpanded) {
            dispatch(toggleExpandNotifications(false));
        }
    }, [dispatch, favouritesIsExpanded]);

    // Main
    const roleBasedMainItems = useMemo((): MenuItem[] => {
        const mainRoleConfig: Record<RoleType, MenuKey[]> = {
            [RoleType.student]: showMobileMenu
                ? [MenuKey.candidateOverview, MenuKey.userProfile, MenuKey.internships, MenuKey.userSettings]
                : [MenuKey.candidateOverview, MenuKey.internships, MenuKey.jobs],
            [RoleType.jobSeeker]: showMobileMenu
                ? [MenuKey.candidateOverview, MenuKey.userProfile, MenuKey.jobs, MenuKey.userSettings]
                : [MenuKey.candidateOverview, MenuKey.jobs],
            [RoleType.company]: showMobileMenu
                ? [MenuKey.companyOverview, MenuKey.companyProfile, MenuKey.jobs, MenuKey.internships, MenuKey.companyUserSettings]
                : [MenuKey.companyOverview, MenuKey.jobs, MenuKey.internships],
            [RoleType.none]: [MenuKey.jobs, MenuKey.internships, MenuKey.createVacancy],
        };

        return getRoleSpecificItems({
            allowed: mainRoleConfig[userRole],
            items: [
                menuItems.internships(),
                menuItems.jobs(),
                menuItems.createVacancy(userRole === RoleType.none ? {
                    to: RoutePaths.registrationCompany(),
                } : {}),
                menuItems.candidateOverview(),
                menuItems.companyOverview(),
                menuItems.userProfile(),
                menuItems.companyProfile(),
                menuItems.userSettings(),
                menuItems.companyUserSettings(),
            ],
        });
    }, [userRole, showMobileMenu, company]);

    // Icon controls
    const roleBasedIconItems = useMemo((): MenuItem[] => {
        const iconRoleConfig: Record<RoleType, MenuKey[]> = {
            [RoleType.student]: [MenuKey.favourites, MenuKey.messages, MenuKey.notifications],
            [RoleType.jobSeeker]: [MenuKey.favourites, MenuKey.messages, MenuKey.notifications],
            [RoleType.company]: [MenuKey.messages, MenuKey.notifications],
            [RoleType.none]: [],
        };

        return getRoleSpecificItems({
            allowed: iconRoleConfig[userRole],
            items: [
                menuItems.favourites({
                    onClick: handleToggleFavourites,
                }),
                menuItems.messages({
                    to: userRole === RoleType.company
                        ? RoutePaths.companyOverviewTab(CompanyOverviewTab.messages)
                        : RoutePaths.candidateOverviewTab(CandidateOverviewTab.messages),
                }),
                menuItems.notifications({
                    counter: amountOfNewNotifications,
                    onClick: handleToggleNotifications,
                }),
            ],
        });
    }, [userRole, amountOfNewNotifications]);

    // Profile
    const roleBasedProfileItems = useMemo((): MenuItem[] => {
        const profileRoleConfig: Record<RoleType, MenuKey[]> = {
            [RoleType.student]: [MenuKey.userProfile, MenuKey.userSettings, MenuKey.logout],
            [RoleType.jobSeeker]: [MenuKey.userProfile, MenuKey.userSettings, MenuKey.logout],
            [RoleType.company]: [MenuKey.companyProfile, MenuKey.companyUserSettings, MenuKey.logout],
            [RoleType.none]: [],
        };

        return getRoleSpecificItems({
            allowed: profileRoleConfig[userRole],
            items: [
                menuItems.userProfile(),
                menuItems.userSettings(),
                menuItems.companyProfile(),
                menuItems.companyUserSettings(),
                menuItems.logout({
                    onClick: handleLogoutClick,
                }),
            ],
        });
    }, [userRole]);

    // Session
    const roleBasedSessionItems = useMemo((): MenuItem[] => {
        const sessionRoleConfig: Record<RoleType, MenuKey[]> = {
            [RoleType.student]: [MenuKey.logout],
            [RoleType.jobSeeker]: [MenuKey.logout],
            [RoleType.company]: [MenuKey.logout, MenuKey.createVacancy],
            [RoleType.none]: [MenuKey.login, MenuKey.registration],
        };

        return getRoleSpecificItems({
            allowed: userRole !== RoleType.none && !isValidUser
                ? [MenuKey.logout, MenuKey.completeRegistration]
                : sessionRoleConfig[userRole],
            items: [
                menuItems.login({
                    isTextual: true,
                    onClick: handleLoginClick,
                }),
                menuItems.logout({
                    isTextual: true,
                    onClick: handleLogoutClick,
                }),
                menuItems.createVacancy({
                    icon: undefined,
                }),
                menuItems.registration({
                    onClick: handleRegistrationClick,
                }),
                menuItems.completeRegistration({
                    isImportant: true,
                    onClick: handleCompleteRegistrationClick,
                }),
            ],
        });
    }, [userRole, isValidUser]);

    const informationItems = [
        menuItems.infoJobSeeker(),
        menuItems.infoStudent(),
        menuItems.infoCompany(),
        menuItems.infoEducation({
            onClick: handleInfoEducationClick,
        }),
    ];

    const extraInformationItems = [
        menuItems.faq(),
        menuItems.aboutUs(),
        menuItems.blog(),
    ];

    const mobileShortcutItem = userRole === RoleType.none
        ? menuItems.login({
            isTextual: true,
            onClick: handleLoginClick,
        })
        : menuItems.completeRegistration({
            isImportant: true,
            onClick: handleCompleteRegistrationClick,
        });

    return (
        <Menu
            authIsLoading={authIsLoading}
            isValidUser={isValidUser}
            showMobileMenu={showMobileMenu}
            role={userRole}
            person={person || undefined}
            company={company}
            mainItems={roleBasedMainItems}
            informationItems={informationItems}
            extraInformationItems={extraInformationItems}
            iconItems={roleBasedIconItems}
            profileItems={roleBasedProfileItems}
            sessionItems={roleBasedSessionItems}
            mobileShortcutItem={mobileShortcutItem}
        />
    );
};

export default ConnectedMenu;
