import React, { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

const LogoCreate: FC<SvgLogoProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 1000 199.4" className={`logo ${className}`}>
        <path d="M998.8,21.1c0.8-1.8,1.2-3.9,1.2-6.3c0-2.3-0.4-4.4-1.2-6.3c-0.8-1.8-1.8-3.4-3.1-4.7c-1.3-1.3-2.8-2.2-4.4-2.9c-1.7-0.7-3.4-1-5.3-1c-1.8,0-3.6,0.3-5.3,1c-1.7,0.7-3.2,1.6-4.4,2.9c-1.3,1.3-2.3,2.8-3.1,4.7c-0.8,1.8-1.1,3.9-1.1,6.3c0,2.4,0.4,4.5,1.1,6.3c0.8,1.8,1.8,3.4,3.1,4.7c1.3,1.3,2.8,2.2,4.4,2.9c1.7,0.7,3.4,1,5.3,1c1.8,0,3.6-0.3,5.3-1c1.7-0.7,3.2-1.6,4.4-2.9C997.1,24.5,998.1,23,998.8,21.1z M997.6,14.8c0-1.9-0.3-3.6-0.9-5.2c-0.6-1.6-1.4-2.9-2.4-4c-1-1.1-2.2-2-3.6-2.6c-1.4-0.6-3-0.9-4.6-0.9c-1.7,0-3.2,0.3-4.6,0.9c-1.4,0.6-2.6,1.5-3.6,2.6c-1,1.1-1.8,2.4-2.4,4c-0.6,1.6-0.9,3.3-0.9,5.2c0,1.9,0.3,3.6,0.9,5.2c0.6,1.6,1.4,2.9,2.4,4c1,1.1,2.2,2,3.6,2.6c1.4,0.6,3,0.9,4.6,0.9c1.7,0,3.2-0.3,4.6-0.9c1.4-0.6,2.6-1.5,3.6-2.6c1-1.1,1.8-2.4,2.4-4C997.3,18.4,997.6,16.7,997.6,14.8z M986.3,23.3c-1.1,0-2.1-0.2-3-0.6c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.6-1.6-2.6c-0.4-1.1-0.6-2.3-0.6-3.6c0-1.3,0.2-2.5,0.6-3.5c0.4-1,1-1.9,1.7-2.6c0.7-0.7,1.5-1.3,2.5-1.6c0.9-0.4,1.9-0.6,2.9-0.6c1.3,0,2.4,0.2,3.3,0.6c0.8,0.4,1.4,0.7,1.7,0.9l-0.8,1.8c-0.4-0.2-0.9-0.5-1.6-0.7c-0.6-0.3-1.5-0.4-2.6-0.4c-1.5,0-2.8,0.5-3.8,1.6c-1,1.1-1.5,2.6-1.5,4.6c0,2,0.5,3.6,1.5,4.7c1,1.1,2.3,1.6,4,1.6c1.2,0,2.1-0.1,2.8-0.4c0.7-0.3,1.2-0.5,1.5-0.7l0.7,2c-0.4,0.2-1,0.5-1.9,0.8C988.7,23.1,987.6,23.3,986.3,23.3z" />
        <path d="M941.9,176.7v-10.4c0.2-6,10.1-9.1,19.8-9.2v27.6c0,9.3-15.2,14.2-30.3,14.2h-86.7c-15,0-30.3-4.9-30.3-14.2V44.2c0-9.3,15.2-14.2,30.3-14.2h66.8c15,0,30.3,4.9,30.3,14.2v66.3c0,9.3-15.2,14.2-30.3,14.2h-77.2v52c0.8,0.8,4.2,2.5,10.4,2.5h86.7C937.7,179.1,941.1,177.4,941.9,176.7z M844.8,49.8c-6.2,0-9.7,1.7-10.4,2.5v52.6h77.2c6.2,0,9.7-1.7,10.4-2.5V52.3c-0.8-0.8-4.2-2.5-10.4-2.5H844.8z" />
        <path d="M688,104.9V30h-1.2c-9.3,0.3-18.3,3.3-18.6,9v145.3c0,9.3,15.2,14.2,30.3,14.2h57.2c15,0,30.3-4.9,30.3-14.2v-27.1c-9.8,0-19.7,3.2-19.8,9.2v10.3c-0.8,0.8-4.2,2.5-10.4,2.5h-57.2c-6.2,0-9.7-1.7-10.4-2.5v-52h29.5c6-0.2,9.2-10,9.2-19.8H688z" />
        <path d="M531.6,52.3v10.4c-0.2,6-10.1,9.1-19.8,9.2V44.2C511.8,34.9,527,30,542,30h66.8c15,0,30.3,4.9,30.3,14.2v140.6c0,9.3-15.2,14.2-30.3,14.2h-86.7c-15,0-30.3-4.9-30.3-14.2v-65.7c0-9.3,15.2-14.2,30.3-14.2h97.1V52.3c-0.8-0.8-4.2-2.5-10.4-2.5H542C535.8,49.8,532.4,51.5,531.6,52.3z M608.9,179.1c6.2,0,9.7-1.7,10.4-2.5v-52h-97.1c-6.2,0-9.7,1.7-10.4,2.5v49.5c0.8,0.8,4.2,2.5,10.4,2.5H608.9z" />
        <path d="M445.4,176.7v-10.4c0.2-6,10.1-9.1,19.8-9.2v27.6c0,9.3-15.2,14.2-30.3,14.2h-86.7c-15,0-30.3-4.9-30.3-14.2V44.2c0-9.3,15.2-14.2,30.3-14.2h66.8c15,0,30.3,4.9,30.3,14.2v66.3c0,9.3-15.2,14.2-30.3,14.2h-77.2v52c0.8,0.8,4.2,2.5,10.4,2.5H435C441.2,179.1,444.6,177.4,445.4,176.7z M348.3,49.8c-6.2,0-9.7,1.7-10.4,2.5v52.6h77.2c6.2,0,9.7-1.7,10.4-2.5V52.3c-0.8-0.8-4.2-2.5-10.4-2.5H348.3z" />
        <path d="M261.1,30h-57.2c-15,0-30.3,4.9-30.3,14.2v155.2h19.8V52.3c0.8-0.8,4.2-2.5,10.4-2.5h57.2c6.2,0,9.7,1.7,10.4,2.5V63c0.3,5.4,8.7,8.5,17.5,8.9h2.3V44.2C291.3,34.9,276.1,30,261.1,30z" />
        <path d="M127.3,166.5v10.2c-0.8,0.8-4.2,2.5-10.4,2.5H30.3c-6.2,0-9.7-1.7-10.4-2.5V52.3c0.8-0.8,4.2-2.5,10.4-2.5h66.8c6.2,0,9.7,1.7,10.4,2.5v10.3c0,5.7,8.5,8.8,17.6,9.3h2.3V44.2c0-9.3-15.2-14.2-30.3-14.2H30.3C15.2,30,0,34.9,0,44.2v140.6c0,9.3,15.2,14.2,30.3,14.2h86.7c15,0,30.3-4.9,30.3-14.2v-27.6C137.3,157.2,127.3,160.4,127.3,166.5z" />
    </svg>
);

export default LogoCreate;
