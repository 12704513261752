import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { CompetenciesSelector, FormActionButtons, SearchableValueInput } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { CompetenciesFormData, Competency } from '../../../models/Competencies';
import { EscoOccupation, transformEscoOccupationToSearchableOption } from '../../../models/Esco';
import { FormProps, SearchableOption } from '../../../types';
import { CompetenciesFormErrors, validateCompetenciesFormData } from './validations';

import './CompetenciesForm.scss';

interface CompetenciesFormProps extends FormProps<CompetenciesFormData> {
    competencies: Competency[];
    options: Competency[];
    suggestedCompetencies: Competency[];
    occupationOptions: EscoOccupation[];
    onSelectOccupation?: (occupation: EscoOccupation) => void;
    className?: string;
}

const CompetenciesForm: FC<CompetenciesFormProps> = ({
    isLoading,
    competencies,
    options,
    suggestedCompetencies,
    occupationOptions,
    error,
    onSelectOccupation,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CompetenciesFormErrors>({});

    const [selectedOptions, setSelectedOptions] = useState<Competency[]>(competencies);
    const [occupation, setOccupation] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (suggestedCompetencies.length) {
            setSelectedOptions(suggestedCompetencies);
        }
    }, [suggestedCompetencies]);

    const handleChangeOccupation = (occupationValue: SearchableOption): void => {
        setOccupation(occupationValue.label);

        const selectedOccupation = occupationValue.value
            ? occupationOptions.find(option => option.id === occupationValue.value)
            : undefined;

        if (selectedOccupation && onSelectOccupation) {
            onSelectOccupation(selectedOccupation);
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = { competencies: selectedOptions };
        const [errors, hasErrors] = validateCompetenciesFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`competencies-form ${className}`}>
            <CompetenciesSelector
                isLoading={isLoading}
                label={trans('forms.competencies.input.searchMinimum')}
                value={selectedOptions}
                options={options}
                error={error}
                validationError={formErrors.competencies}
                onChange={setSelectedOptions}
            />

            {onSelectOccupation && (
                <div className="competencies-form__occupation-wrapper">
                    <h2 className="competencies-form__occupation-title">
                        {trans('forms.competencies.occupation.title')}
                    </h2>

                    <p className="competencies-form__occupation-description">
                        {trans('forms.competencies.occupation.description')}
                    </p>

                    <SearchableValueInput
                        isSearchable
                        showResultsOnClick
                        label={trans('forms.competencies.input.occupation')}
                        value={occupation}
                        options={occupationOptions.map(transformEscoOccupationToSearchableOption)}
                        placeholder={trans('forms.competencies.placeholder.occupation')}
                        onChange={handleChangeOccupation}
                        className="competencies-form__occupation-input"
                    />
                </div>
            )}

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="competencies-form__action-buttons"
            />
        </form>
    );
};

export default CompetenciesForm;
