import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { provincesNL } from '../../constants/provinces-nl';
import { Province } from '../../models/Provinces';
import { AsyncReduxState } from '../../types';

export type ProvincesState = AsyncReduxState<{
    provinces: Province[];
}>;

const initialState: ProvincesState = {
    ...initialAsyncReduxState,
    provinces: provincesNL,
};

export const provincesSlice = createSlice({
    name: 'provincesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ProvincesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProvincesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setProvinces(state, action: PayloadAction<Province[]>): ProvincesState {
            return {
                ...state,
                provinces: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setProvinces,
} = provincesSlice.actions;

export default provincesSlice;
