import React, { FC, ReactElement, useEffect } from 'react';

import { CompanyHeader } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { convertFileToBase64 } from '../../../helpers/file';
import { Company } from '../../../models/Company';
import { FileFormData } from '../../../models/File';
import { YouTubeFormData } from '../../../models/YouTube';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany, patchCompany } from '../../../store/company/companyActions';

interface ConnectedCompanyHeaderProps {
    coverImageActivationRef?: ActivationRef;
    youTubeActivationRef?: ActivationRef;
    avatarActivationRef?: ActivationRef;
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyHeader: FC<ConnectedCompanyHeaderProps> = ({
    coverImageActivationRef,
    youTubeActivationRef,
    avatarActivationRef,
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);
    const isLoading = useTypedSelector(state => state.companyReducer.isLoading);

    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    const updateCompany = (updatedValues: Partial<Company>): void => {
        if (company) {
            dispatch(patchCompany({
                uuid: company.uuid,
                ...updatedValues,
            }));
        }
    };

    const handleEditAvatar = async (avatarFormData: FileFormData): Promise<void> => {
        if (avatarFormData.file) {
            const base64Logo = await convertFileToBase64(avatarFormData.file);

            updateCompany({ logo: base64Logo });
        } else {
            updateCompany({ logo: '' });
        }
    };

    const handleEditCoverImage = async (coverImageFormData: FileFormData): Promise<void> => {
        if (coverImageFormData.file) {
            const base64CoverImage = await convertFileToBase64(coverImageFormData.file);

            updateCompany({ coverImage: base64CoverImage });
        } else {
            updateCompany({ coverImage: '' });
        }
    };

    const handleEditYouTube = (youTubeFormData: YouTubeFormData): void => {
        updateCompany({ coverVideo: youTubeFormData.embedId });
    };

    return (
        <CompanyHeader
            coverImageActivationRef={coverImageActivationRef}
            youTubeActivationRef={youTubeActivationRef}
            avatarActivationRef={avatarActivationRef}
            isLoading={isLoading}
            isEditable={userFromCompany}
            company={company}
            onEditAvatar={handleEditAvatar}
            onEditCoverImage={handleEditCoverImage}
            onEditYouTube={handleEditYouTube}
            className={className}
        />
    );
};

export default ConnectedCompanyHeader;
