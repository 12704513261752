import { addDays } from 'date-fns';

import { Address, transformAddressToAddressFormValues } from '../../../_old/app_talentz/models/Address';
import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { animateToFormElement } from '../../../_old/helpers/formHelper';
import { defaultVacancyLifetimeInDays } from '../../../constants';
import { Grade } from '../../../models/VacancyOptions';
import { PlaceInternshipVacancyFormValues } from '../../../services/PlaceVacancyService';
import { FormOption } from '../../../types';

export function animateToInternshipErrorElement(errors: ValidationErrors) {
    if (errors.title) {
        animateToFormElement('title');
    } else if (errors.address) {
        animateToFormElement('zipcode');
    } else if (errors.sectors) {
        animateToFormElement('sector');
    } else if (errors.publicationDate) {
        animateToFormElement('publication-date');
    } else if (errors.internshipType) {
        animateToFormElement('internship-type');
    } else if (errors.fieldsOfStudy) {
        animateToFormElement('fields-of-study');
    } else if (errors.grades) {
        animateToFormElement('grades');
    } else if (errors.skills) {
        animateToFormElement('skills');
    } else if (errors.skillsToLearn) {
        animateToFormElement('skills-to-learn');
    } else if (errors.competencies) {
        animateToFormElement('internship-competence');
    } else if (errors.competenciesToLearn) {
        animateToFormElement('internship-competence-to-learn');
    } else if (errors.compensation) {
        animateToFormElement('compensation');
    } else if (errors.description) {
        animateToFormElement('description-label');
    }
}

export function convertGradesToCheckboxListOptions(grades: Grade[]): FormOption[] {
    return grades.map(grade => ({
        label: grade.name,
        value: grade.uuid,
    }));
}

export function createEmptyPlaceInternshipVacancyFormValues(address: Address | undefined): PlaceInternshipVacancyFormValues {
    return {
        applyAnonymous: false,
        title: '',
        address: address ? transformAddressToAddressFormValues(address) : null,
        expirationDate: addDays(new Date(), defaultVacancyLifetimeInDays),
        publicationDate: new Date(),
        educationLevelId: '',
        fieldsOfStudyIds: [],
        description: '',
        skillIds: [],
        skillsToLearnIds: [],
        minCompensation: '',
        maxCompensation: '',
        competencyIds: [],
        competenciesToLearnIds: [],
        gradeIds: [],
        internshipTypeId: '',
        periodFrom: null,
        periodTill: null,
        workRemotePreferenceId: '',
        sectorId: '',
    };
}
