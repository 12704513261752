import { ChatConnection } from '../../models/Chat';
import { ChatMessage } from '../../models/ChatMessages';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { WebSocketEvent, WebSocketReadyState } from '../../models/WebSocket';
import { getChatMessagesApiCall } from '../../services/ChatMessagesService';
import { ReducerGetter, TypedDispatch } from '..';
import { resetChatConnection, setChatLatestMessage } from '../chats/chatsActions';
import {
    setError,
    setIsLoading,
    setIsReconnecting,
    setMessages,
} from './chatMessages';

export const clearChatMessages = () => (dispatch: TypedDispatch): void => {
    dispatch(setMessages([]));
};

export const fetchChatMessages = (chatUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsReconnecting(false));

    try {
        const chatMessagesResponse = await getChatMessagesApiCall(chatUuid);

        if (!isFetchResultSuccessful(chatMessagesResponse)) {
            console.error('[fetchChatMessages]', chatMessagesResponse.error);
            dispatch(setError(chatMessagesResponse.error));
            return;
        }

        const messages = chatMessagesResponse.data;

        dispatch(setMessages(messages));
    } catch (error) {
        console.error('[fetchChatMessages]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addChatMessage = (chatMessage: ChatMessage) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { chatMessagesReducer } = getState();
    const { messages } = chatMessagesReducer;

    dispatch(setMessages([...messages, chatMessage]));
};

export const broadcastChatMessage = (chatConnection: ChatConnection, chatMessage: ChatMessage) => (dispatch: TypedDispatch): void => {
    if (!chatConnection.socket) return;

    const isOpen = chatConnection.socket.readyState === WebSocketReadyState.open;
    const isClosed = [WebSocketReadyState.closing, WebSocketReadyState.closed].includes(chatConnection.socket.readyState);

    if (isOpen) {
        dispatch(setIsReconnecting(false));
        dispatch(addChatMessage(chatMessage));
        dispatch(setChatLatestMessage(chatConnection.uuid, chatMessage.text));

        chatConnection.socket.send(JSON.stringify({
            type: WebSocketEvent.message,
            chatUuid: chatConnection.uuid,
            authorUuid: chatMessage.author.uuid,
            message: chatMessage.text,
        }));
    }

    if (isClosed) {
        dispatch(setIsReconnecting(true));
        dispatch(resetChatConnection(chatConnection.uuid, chatMessage));
    }
};

export const broadcastChatReportState = (chatConnection: ChatConnection, isReported: boolean) => (): void => {
    if (!chatConnection.socket) return;

    const isOpen = chatConnection.socket.readyState === WebSocketReadyState.open;

    if (isOpen) {
        chatConnection.socket.send(JSON.stringify({
            type: WebSocketEvent.chatReport,
            chatUuid: chatConnection.uuid,
            isReported,
        }));
    }
};
