/* eslint-disable camelcase */
import { ApplicationAppointment, ApplicationAppointmentApiParams, transformApplicationAppointment } from './ApplicationAppointment';

export interface ApplicationInviteApiParams {
    uuid: string;
    content: string;
    appointments: ApplicationAppointmentApiParams[];
    is_waiting: boolean;
    is_accepted: boolean;
    is_rejected: boolean;
    inviter: string;
    accepted_appointment: ApplicationAppointmentApiParams | null;
}

export interface ApplicationInvite {
    uuid: string;
    content: string;
    appointments: ApplicationAppointment[];
    is_waiting: boolean;
    is_accepted: boolean;
    is_rejected: boolean;
    inviter: string;
    accepted_appointment: ApplicationAppointment | null;
}

export function transformApplicationInvite(params: ApplicationInviteApiParams): ApplicationInvite {
    return {
        uuid: params.uuid,
        content: params.content,
        appointments: params.appointments.map((appointment: ApplicationAppointmentApiParams) => transformApplicationAppointment(appointment)),
        is_waiting: params.is_waiting,
        is_accepted: params.is_accepted,
        is_rejected: params.is_rejected,
        inviter: params.inviter,
        accepted_appointment: params.accepted_appointment && transformApplicationAppointment(params.accepted_appointment),
    };
}
/* eslint-enable camelcase */
