import React from 'react';

import { LinkButton } from '../../../../../../../components';
import { trans } from '../../../../../../../helpers/trans';
import { RoutePaths } from '../../../../../../../routes';
import Container from '../../../../../../styledComponents/components/Container';
import { Row } from '../../../../../../styledComponents/components/Grid';
import Section from '../../../../../../styledComponents/components/Section';
import { Heading, Paragraph } from '../../../../../../styledComponents/components/Typography';

const TeamCTA = () => (
    <Section py="xxl">
        <Container centered maxWidth="900px">
            <Row flexDirection="column" alignItems="center" mb="xl">
                <Heading size="xl" textAlign="center" mb="md">
                    {trans('infoPage.team.callToAction.title')}
                </Heading>
                <Paragraph fontType="subHeading" size="md" textAlign="center" mb="xl">
                    {trans('infoPage.team.callToAction.text')}
                </Paragraph>
                <LinkButton
                    to={RoutePaths.altRegistration()}
                    text={trans('infoPage.team.callToAction.button')}
                />
            </Row>
        </Container>
    </Section>
);

export default TeamCTA;
