import getIncluded from '../japi/getIncluded';
import { JapiDocument } from '../japi/types/Document';
import { SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { ChatResource } from './Chat';
import { PersonInfoResource } from './info/PersonInfo';

export interface ChatReportResource extends Resource {
    type: 'company-chat-reports';
    id: string;
    attributes: {
        isResolved: boolean;
        problemDescription: string;
        resolution?: string;
        resolvedAt?: string;
        createdAt: string;
    };
    relationships: {
        chat: SingleRelationship<'company-chats'>;
        reporter: SingleRelationship<'person-info'>;
    };
}

export interface ChatReportRequest {
    data: {
        type: 'company-chat-reports';
        attributes: {
            problemDescription: string;
        };
        relationships: {
            companyChat: SingleRelationship<'company-chats'>;
        };
    };
}

export interface ChatReport {
    uuid: string;
    chatUuid: string;
    reporterUuid: string;
    reportReason: string;
}

export interface ChatReportFormData {
    reportReason: string;
}

export interface UndoChatReportFormData {
    selectedReport: string;
}

export const transformJapiDocumentToChatReport = (
    doc: JapiDocument,
    chatReportResource: ChatReportResource,
): ChatReport => {
    const includedChat = getIncluded<ChatResource>(doc, chatReportResource, 'chat');
    const includedReporter = getIncluded<PersonInfoResource>(doc, chatReportResource, 'reporter');

    const chatUuid = includedChat?.id || '';
    const reporterUuid = includedReporter?.id || '';

    return {
        uuid: chatReportResource.id,
        chatUuid,
        reporterUuid,
        reportReason: chatReportResource.attributes.problemDescription,
    };
};

export const transformToChatReportRequest = (chatUuid: string, chatReportFormData: ChatReportFormData): ChatReportRequest => ({
    data: {
        type: 'company-chat-reports',
        attributes: {
            problemDescription: chatReportFormData.reportReason,
        },
        relationships: {
            companyChat: {
                data: {
                    id: chatUuid,
                    type: 'company-chats',
                },
            },
        },
    },
});
