import React, { FC, ReactElement, useMemo } from 'react';

import { Checkbox } from '../../components';
import { trans } from '../../helpers/trans';
import { CookieOption, FormOption } from '../../types';

import './CookieConsentList.scss';

interface CookieFormOption extends FormOption {
    disabled?: boolean;
    value: CookieOption;
}

interface CookieConsentList {
    values: CookieOption[];
    onChange: (values: string[]) => void;
    className?: string;
}

const CookieConsentList: FC<CookieConsentList> = ({
    values,
    onChange,
    className = '',
}): ReactElement => {
    const checkBoxOptions = useMemo((): CookieFormOption[] => [
        {
            value: CookieOption.necessary,
            label: trans('reminder.cookies.necessary'),
            disabled: true,
        },
        {
            value: CookieOption.statistics,
            label: trans('reminder.cookies.statistics'),
        },
        {
            value: CookieOption.liveChat,
            label: trans('reminder.cookies.liveChat'),
        },
    ], []);

    const handleCheckboxChange = (option: FormOption, isChecked: boolean): void => {
        const newCheckedOptions = isChecked
            ? [...values, option.value]
            : [...values].filter(selectedOption => selectedOption !== option.value);

        onChange(newCheckedOptions);
    };

    return (
        <div className={`cookie-consent-list ${className}`}>
            {checkBoxOptions.map(option => {
                const handleSelect = (isChecked: boolean): void => handleCheckboxChange(option, isChecked);

                return (
                    <Checkbox
                        key={option.value}
                        label={option.label}
                        name="cookie-options"
                        value={option.value}
                        checked={values.includes(option.value)}
                        disabled={option.disabled}
                        onChange={handleSelect}
                        className="cookie-consent-list__checkbox"
                    />
                );
            })}
        </div>
    );
};

export default CookieConsentList;
