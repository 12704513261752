import React, { FC, ReactElement, useEffect } from 'react';

import { CompanyContactCard } from '../../../containers';
import { RoleType } from '../../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany } from '../../../store/company/companyActions';

interface ConnectedCompanyContactCardProps {
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyContactCard: FC<ConnectedCompanyContactCardProps> = ({
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const userIsLoading = useLegacySelector(state => state.legacyUser.loading);
    const person = useLegacySelector(state => state.userPerson.data);
    const role = useLegacySelector(state => state.legacyUser.role);

    const companyIsLoading = useTypedSelector(state => state.companyReducer.isLoading);
    const company = useTypedSelector(state => state.companyReducer.company);

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    return role !== RoleType.company ? (
        <CompanyContactCard
            isLoading={userIsLoading || companyIsLoading}
            isCandidate={role === RoleType.student || role === RoleType.jobSeeker}
            company={company}
            className={className}
        />
    ) : null;
};

export default ConnectedCompanyContactCard;
