import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Application } from '../../models/Applications';
import { NewestVacancy } from '../../models/NewestVacancy';
import { AsyncReduxState, StatusCode } from '../../types';

export type ApplicationState = AsyncReduxState<{
    application?: Application;
    vacancy?: NewestVacancy;
}>;

const initialState: ApplicationState = {
    ...initialAsyncReduxState,
    application: undefined,
    vacancy: undefined,
};

const applicationSlice = createSlice({
    name: 'applicationReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ApplicationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ApplicationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ApplicationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setStatusCode(state, action: PayloadAction<StatusCode | undefined>): ApplicationState {
            return {
                ...state,
                statusCode: action.payload,
            };
        },
        setApplication(state, action: PayloadAction<Application | undefined>): ApplicationState {
            return {
                ...state,
                application: action.payload,
            };
        },
        setApplicationVacancy(state, action: PayloadAction<NewestVacancy | undefined>): ApplicationState {
            return {
                ...state,
                vacancy: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setStatusCode,
    setApplication,
    setApplicationVacancy,
} = applicationSlice.actions;

export default applicationSlice;
