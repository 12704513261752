import React, { FC, PropsWithChildren, ReactElement } from 'react';

import './TalentMatchingInputWrapper.scss';

interface TalentMatchingInputWrapperProps {
    title: string;
    description: string;
    className?: string;
}

const TalentMatchingInputWrapper: FC<PropsWithChildren<TalentMatchingInputWrapperProps>> = ({
    title,
    description,
    className = '',
    children,
}): ReactElement => (
    <div className={`talent-matching-input-wrapper ${className}`}>
        <h2 className="talent-matching-input-wrapper__title">
            {title}
        </h2>

        <p className="talent-matching-input-wrapper__description">
            {description}
        </p>

        <div className="talent-matching-input-wrapper__input">
            {children}
        </div>
    </div>
);

export default TalentMatchingInputWrapper;
