import React from 'react';

import * as PropTypes from 'prop-types';

import { Heading, Paragraph } from '../../../../../../styledComponents/components/Typography';
import {
    Slide,
    SlideBackground,
    SlideImage,
    SlideImageContainer,
} from './style';

const TeamMember = props => (
    <Slide {...props}>
        <SlideImageContainer>
            <SlideBackground />
            <SlideImage src={props.teamMemberPhoto} alt={props.teamMemberName} />
        </SlideImageContainer>
        <Heading as="h3" size="lg" mb="xxs">
            {props.teamMemberName}
        </Heading>
        <Paragraph fontType="subHeading" size="md" color="muted">
            {props.teamMemberFunction}
        </Paragraph>
    </Slide>
);
TeamMember.propTypes = {
    teamMemberName: PropTypes.string.isRequired,
    teamMemberFunction: PropTypes.string.isRequired,
    teamMemberPhoto: PropTypes.string.isRequired,
};
export default TeamMember;
