import React, { FC, ReactElement } from 'react';

import CreatePagination from '@createnl/pagination';

import { trans } from '../../helpers/trans';

import './Pagination.scss';

interface PaginationProps {
    amountOfPages: number;
    currentPage: number;
    baseHref?: string;
    className?: string;
    onChange?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
    amountOfPages,
    currentPage,
    baseHref,
    onChange,
    className = '',
}): ReactElement => {
    const handlePaginationChange = (page: number) => {
        if (onChange) onChange(page);
    };

    return (
        <CreatePagination
            id="pagination"
            centerNumbers
            baseHref={baseHref}
            amountOfPages={amountOfPages}
            currentPage={currentPage}
            marginInitial={3}
            marginCenter={2}
            buttonPrevText={trans('actions.previous')}
            buttonNextText={trans('actions.next')}
            onChange={handlePaginationChange}
            className={className}
        />
    );
};

export default Pagination;
