import { Resource } from '../../japi/types/Resource';

export interface CompanyInfoResource extends Resource {
    type: 'company-info';
    id: string;
    attributes: {
        address: {
            city: string;
        };
        coverImage: string;
        logo: string;
        name: string;
    };
}

export interface CompanyInfo {
    uuid: string;
    name: string;
    logo: string;
    coverImage: string;
}

export const defaultCompanyInfo = (): CompanyInfo => ({
    uuid: '',
    name: '',
    logo: '',
    coverImage: '',
});

export const transformToCompanyInfo = (
    companyInfoResource: CompanyInfoResource,
): CompanyInfo => ({
    uuid: companyInfoResource.id,
    name: companyInfoResource.attributes.name || '',
    logo: companyInfoResource.attributes.logo
        ? `${process.env.REACT_APP_API_V1_URL}/storage/logos/${companyInfoResource.attributes.logo}`
        : '',
    coverImage: companyInfoResource.attributes.coverImage
        ? `${process.env.REACT_APP_API_V1_URL}/storage/cover-images/${companyInfoResource.attributes.coverImage}`
        : '',
});
