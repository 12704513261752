import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { NewestVacancy } from '../../models/NewestVacancy';
import { AsyncReduxState, StatusCode } from '../../types';

export type VacancyDetailState = AsyncReduxState<{
    vacancy?: NewestVacancy;
}>;

const initialState: VacancyDetailState = {
    ...initialAsyncReduxState,
    vacancy: undefined,
};

const vacancyDetailSlice = createSlice({
    name: 'vacancyDetailReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacancyDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacancyDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setVacancy(state, action: PayloadAction<NewestVacancy | undefined>): VacancyDetailState {
            return {
                ...state,
                vacancy: action.payload,
            };
        },
        setStatusCode(state, action: PayloadAction<StatusCode | undefined>): VacancyDetailState {
            return {
                ...state,
                statusCode: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setVacancy,
    setStatusCode,
} = vacancyDetailSlice.actions;

export default vacancyDetailSlice;
