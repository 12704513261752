import {
    addDays,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInWeeks,
    differenceInYears,
    endOfDay,
    format,
    isFuture,
    isValid,
    isWithinInterval,
    startOfMonth,
    subMinutes,
} from 'date-fns';
import nl from 'date-fns/locale/nl';

import { stringIsNumber } from './number';
import { trans } from './trans';

export const subtractMinutes = (date: Date, minutes: number) => subMinutes(date, minutes);

export const isValidDateString = (dateString?: string): boolean => {
    if (!dateString) return false;

    return isValid(new Date(dateString));
};

export const isValidTimeString = (time?: string): boolean => {
    if (!time) return false;
    if (!time.includes(':')) return false;

    const splitTime = time.split(':');
    const isValidFormat = splitTime.every(stringIsNumber) && splitTime.every(number => number.length === 2);

    const [hh, mm] = splitTime.map(Number);
    const isValidHours = hh >= 0 && hh <= 23;
    const isValidMinutes = mm >= 0 && mm <= 59;

    return isValidFormat && isValidHours && isValidMinutes;
};

export const startOfGivenMonth = (date: Date): Date => startOfMonth(date);

export const isFutureDate = (date: Date): boolean => isFuture(date);

export const dateIsWithinInterval = (interval: Interval, date: Date = new Date()): boolean => (
    isWithinInterval(date, interval)
);

export const formatDate = (date?: Date, notation = 'dd-MM-y'): string => {
    if (!date || typeof date !== 'object' || !isValid(date)) return '';

    return format(date, notation, {
        locale: nl,
    });
};

export const formatExtensiveDate = (date?: Date): string => formatDate(date, 'eeee d MMMM yyyy');

export const formatAppointment = (startDate: Date, endDate: Date): string => {
    const formattedStart = formatDate(startDate, 'eeee d MMMM y, HH:mm');
    const formattedEnd = formatDate(endDate, 'HH:mm');

    return `${formattedStart} - ${formattedEnd}`;
};

export const formatMessageDate = (date: Date): string => {
    const now = endOfDay(new Date());
    const ageInDays = differenceInDays(now, date);

    if (ageInDays === 0) {
        return trans('helpers.date.messageDate.today', {
            time: formatDate(date, 'HH:mm'),
        });
    }

    if (ageInDays === 1) {
        return trans('helpers.date.messageDate.yesterday', {
            time: formatDate(date, 'HH:mm'),
        });
    }

    return formatDate(date, 'eeee d MMMM yyyy - HH:mm');
};

export const getAgeShort = (date: Date): string => {
    const now = new Date();
    const ageInMinutes = differenceInMinutes(now, date);
    const ageInHours = differenceInHours(now, date);
    const ageInDays = differenceInDays(now, date);
    const ageInWeeks = differenceInWeeks(now, date);
    const ageInYears = differenceInYears(now, date);

    if (ageInMinutes < 1) return 'nu';
    if (ageInMinutes <= 60) return `${ageInMinutes}m`;
    if (ageInHours <= 24) return `${ageInHours}u`;
    if (ageInDays <= 7) return `${ageInDays}d`;
    if (ageInWeeks <= 52) return `${ageInWeeks}w`;

    return `${ageInYears}y`;
};

export const getAgeInDays = (date: Date): string => {
    const now = new Date();
    const ageInDays = differenceInDays(now, date);

    if (ageInDays < 1) return trans('dates.today');
    if (ageInDays === 1) return trans('dates.oneDayOld');
    if (ageInDays <= 30) return trans('dates.ageInDays', { days: ageInDays });

    return trans('dates.ageExceededMonth');
};

export const getAbsoluteDifferenceInDays = (date: Date, comparisonDate: Date): number => (
    Math.abs(differenceInDays(date, comparisonDate))
);

export const addDaysToGivenDate = (date: Date, daysToAdd: number): Date => (
    addDays(date, daysToAdd)
);

export const getLocaleISOString = (date: Date): string => {
    const timestamp = date.getTime();
    const offset = date.getTimezoneOffset() * 60000;
    const localeDate = new Date(timestamp - offset);

    return localeDate.toISOString();
};

export const getNextMonday = (date: Date = new Date()): Date => {
    const mondayIndex = 1; // index starts at sunday
    const daysInWeek = 7;
    const nextMonday = new Date(date.getTime());

    const dayOfMonth = date.getDate();
    const dayOfWeek = date.getDay();

    const daysUntilNextWeek = (daysInWeek - dayOfWeek + mondayIndex) % daysInWeek;
    const nextMondayDate = dayOfMonth + (daysUntilNextWeek || daysInWeek);

    nextMonday.setDate(nextMondayDate);

    return new Date(nextMonday);
};
