import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';
import { Icon } from '..';

import './SbbApprovedLabel.scss';

interface SbbApprovedLabelProps {
    className?: string;
}

const SbbApprovedLabel: FC<SbbApprovedLabelProps> = ({
    className = '',
}): ReactElement => (
    <span className={`sbb-approved-label ${className}`}>
        <Icon
            name="sbb-approved-star"
            className="sbb-approved-label__icon"
            accentClassName="sbb-approved-label__icon-accent"
        />

        <span className="sbb-approved-label__label">
            {trans('basic.sbbApproved.badge')}
        </span>
    </span>
);

export default SbbApprovedLabel;
