import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Skill } from '../../models/Skills';
import { AsyncReduxState } from '../../types';

export type EscoSkillsState = AsyncReduxState<{
    escoSkills: Skill[];
}>;

const initialState: EscoSkillsState = {
    ...initialAsyncReduxState,
    escoSkills: [],
};

export const escoSkillsSlice = createSlice({
    name: 'escoSkillsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): EscoSkillsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EscoSkillsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEscoSkills(state, action: PayloadAction<Skill[]>): EscoSkillsState {
            return {
                ...state,
                escoSkills: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setEscoSkills,
} = escoSkillsSlice.actions;

export default escoSkillsSlice;
