import { trans } from '../../helpers/trans';
import { Company } from '../../models/Company';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getCompanyApiCall, patchCompanyApiCall } from '../../services/CompanyService';
import { getSbbCompanyInfoApiCall, postSbbCompanyInfoApiCall } from '../../services/SbbTrainingCompanyService';
import { getVacancyApiCall } from '../../services/VacancyService';
import { AtLeast } from '../../types';
import { TypedDispatch } from '..';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setCompany as setUserCompany } from '../user/user';
import { setStatusCode } from '../vacancyDetail/vacancyDetail';
import {
    setCompany,
    setError,
    setIsLoading,
    setSbbCompanyInfo,
    setSbbCompanyInfoHasUpdated,
    setSbbCompanyInfoIsLoading,
} from './company';

const showSavedChangesToast = (dispatch: TypedDispatch): void => {
    dispatch(addPositiveToast({
        title: trans('basic.toast.success'),
        description: trans('candidateProfile.toast.savedChanges.success'),
    }));
};

const showErrorToast = (dispatch: TypedDispatch): void => {
    dispatch(addNegativeToast({
        title: trans('errors.unknownError'),
    }));
};

export const clearCompany = () => (dispatch: TypedDispatch): void => {
    dispatch(setCompany(undefined));
    dispatch(setSbbCompanyInfo(undefined));
};

export const fetchCompany = (companyUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const companyResponse = await getCompanyApiCall(companyUuid);

        if (!isFetchResultSuccessful(companyResponse)) {
            console.error('[fetchCompany]', companyResponse.error);
            dispatch(setError(companyResponse.error));
            return;
        }

        dispatch(setCompany(companyResponse.data));
    } catch (error) {
        console.error('[fetchCompany]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchCompanyByVacancyUuid = (vacancyUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyResponse = await getVacancyApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(vacancyResponse)) {
            console.error('[fetchCompanyByVacancyUuid]', vacancyResponse.error);
            dispatch(setStatusCode(vacancyResponse.status));
            dispatch(setError(vacancyResponse.error));
            return;
        }

        const companyUuid = vacancyResponse.data.company.uuid;
        const companyResponse = await getCompanyApiCall(companyUuid);

        if (!isFetchResultSuccessful(companyResponse)) {
            console.error('[fetchCompanyByVacancyUuid]', companyResponse.error);
            dispatch(setError(companyResponse.error));
            showErrorToast(dispatch);
            return;
        }

        dispatch(setCompany(companyResponse.data));
    } catch (error) {
        console.error('[fetchCompanyByVacancyUuid]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const patchCompany = (company: AtLeast<Company, 'uuid'>) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const companyResponse = await patchCompanyApiCall(company);

        if (!isFetchResultSuccessful(companyResponse)) {
            console.error('[patchCompany]', companyResponse.error);
            dispatch(setError(companyResponse.error));
            showErrorToast(dispatch);
            return;
        }

        dispatch(setCompany(companyResponse.data));
        dispatch(setUserCompany(companyResponse.data));
        showSavedChangesToast(dispatch);
    } catch (error) {
        console.error('[patchCompany]', error);
        dispatch(setError(error as string));
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchSbbCompanyInfo = (uuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const sbbInfoResponse = await getSbbCompanyInfoApiCall(uuid);

        if (!isFetchResultSuccessful(sbbInfoResponse)) {
            console.error('[fetchSbbCompanyInfo]', sbbInfoResponse.error);
            dispatch(setError(sbbInfoResponse.error));
            return;
        }

        dispatch(setSbbCompanyInfo(sbbInfoResponse.data));
    } catch (error) {
        console.error('[fetchSbbCompanyInfo]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateSbbCompanyInfo = (uuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setSbbCompanyInfoIsLoading(true));
    dispatch(setError(''));

    try {
        const sbbInfoResponse = await postSbbCompanyInfoApiCall(uuid);

        if (!isFetchResultSuccessful(sbbInfoResponse)) {
            console.error('[updateSbbCompanyInfo]', sbbInfoResponse.error);
            dispatch(setError(sbbInfoResponse.error));
            return;
        }

        dispatch(setSbbCompanyInfo(sbbInfoResponse.data));
        dispatch(setSbbCompanyInfoHasUpdated(true));
    } catch (error) {
        console.error('[updateSbbCompanyInfo]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setSbbCompanyInfoIsLoading(false));
    }
};
