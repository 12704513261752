import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';

import './BlogHeaderSkeletons.scss';

interface BlogHeaderSkeletonsProps {
    className?: string;
}

const BlogHeaderSkeletons: FC<BlogHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`blog-header-skeletons ${className}`}>
        <Skeleton className="blog-header-skeletons__poster" />
        <div className="blog-header-skeletons__backdrop" />
    </div>
);

export default BlogHeaderSkeletons;
