import React, { FC, ReactElement, useEffect } from 'react';

import classNames from 'classnames';
import { useLocation, useToggle } from 'react-use';

import { Icon } from '../../../../components';
import { MenuItem } from '../../../../connectors/ConnectedMenu/helpers';
import { trans } from '../../../../helpers/trans';
import { HamburgerButton, MenuList } from '..';
import { MenuListTemplate } from '../MenuList/MenuList';

import './MobileNavigation.scss';

interface MobileNavigationProps {
    authIsLoading: boolean;
    isValidUser: boolean;
    mainItems: MenuItem[];
    informationItems: MenuItem[];
    iconItems: MenuItem[];
    sessionItems: MenuItem[];
    shortcutItem: MenuItem;
    className?: string;
}

const MobileNavigation: FC<MobileNavigationProps> = ({
    authIsLoading,
    isValidUser,
    mainItems,
    informationItems,
    iconItems,
    sessionItems,
    shortcutItem,
    className = '',
}): ReactElement => {
    const location = useLocation();
    const [navigationIsExpanded, toggleNavigation] = useToggle(false);

    useEffect((): void => {
        toggleNavigation(false);
    }, [location, toggleNavigation]);

    const drawerClassNames = classNames('mobile-navigation__drawer', {
        'mobile-navigation__drawer--is-expanded': navigationIsExpanded,
    });

    return (
        <nav aria-label={trans('navigation.mainNavigation.ariaLabel')} className={`mobile-navigation ${className}`}>
            <div className="mobile-navigation__controls">
                {(authIsLoading || isValidUser) && !navigationIsExpanded && (
                    <MenuList
                        isLoading={authIsLoading}
                        template={MenuListTemplate.icon}
                        menuItems={iconItems}
                        className="mobile-navigation__icon-controls"
                    />
                )}

                {(!authIsLoading && !isValidUser) && !navigationIsExpanded && (
                    <MenuList
                        template={MenuListTemplate.buttons}
                        menuItems={[shortcutItem]}
                        className="mobile-navigation__shortcut-item"
                    />
                )}

                <HamburgerButton
                    isActive={navigationIsExpanded}
                    disabled={authIsLoading}
                    onToggle={toggleNavigation}
                    className="mobile-navigation__hamburger-button"
                />
            </div>

            <div className={drawerClassNames}>
                <header className="mobile-navigation__header">
                    <Icon
                        name="static-hamburger"
                        className="mobile-navigation__static-hamburger-icon"
                    />

                    <h2 className="mobile-navigation__navigation-title">
                        {trans('navigation.labels.menu')}
                    </h2>
                </header>

                <div className="mobile-navigation__segment-wrapper">
                    <div className="mobile-navigation__segment">
                        <h2 className="mobile-navigation__list-title">
                            {trans('navigation.labels.general')}
                        </h2>

                        <MenuList
                            template={MenuListTemplate.drawer}
                            menuItems={mainItems}
                            tabIndex={navigationIsExpanded ? 0 : -1}
                            className="mobile-navigation__list"
                        />
                    </div>

                    <div className="mobile-navigation__segment">
                        <h2 className="mobile-navigation__list-title">
                            {trans('navigation.labels.information')}
                        </h2>

                        <MenuList
                            template={MenuListTemplate.drawer}
                            menuItems={informationItems}
                            tabIndex={navigationIsExpanded ? 0 : -1}
                            className="mobile-navigation__list"
                        />
                    </div>
                </div>

                <footer className="mobile-navigation__footer">
                    <MenuList
                        template={MenuListTemplate.session}
                        menuItems={sessionItems}
                        tabIndex={navigationIsExpanded ? 0 : -1}
                        className="mobile-navigation__session"
                    />
                </footer>
            </div>
        </nav>
    );
};

export default MobileNavigation;
