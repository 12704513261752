import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconVerticalEllipsis: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 20 100" className={`icon ${className}`}>
        <circle cx="10" cy="10" r="10" />
        <circle cx="10" cy="50" r="10" />
        <circle cx="10" cy="90" r="10" />
    </svg>
);

export default IconVerticalEllipsis;
