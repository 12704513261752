import React, { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './MyApplicationsNullState.scss';

interface MyApplicationsNullStateProps {
    className?: string;
}

const MyApplicationsNullState: FC<MyApplicationsNullStateProps> = ({
    className = '',
}): ReactElement => (
    <div className={`my-applications-null-state ${className}`}>
        <p className="my-applications-null-state__description">
            {trans('containers.myApplications.nullState.description')}
        </p>

        <div className="my-applications-null-state__button-wrapper">
            <LinkIconButton
                icon="arrow-right"
                iconPos="right"
                to={RoutePaths.jobs()}
                text={trans('containers.myApplications.nullState.button.jobs')}
                className="my-applications-null-state__button"
            />

            <LinkIconButton
                icon="arrow-right"
                iconPos="right"
                to={RoutePaths.internships()}
                text={trans('containers.myApplications.nullState.button.internships')}
                className="my-applications-null-state__button"
            />
        </div>
    </div>
);

export default MyApplicationsNullState;
