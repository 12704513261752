import React, { FC, ReactElement, useEffect } from 'react';

import { CandidateContactCard } from '../../../containers';
import { RoleType } from '../../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate } from '../../../store/candidate/candidateActions';

interface ConnectedCandidateContactCardProps {
    isCompanyApplication?: boolean;
    isCompanyMatch?: boolean;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCandidateContactCard: FC<ConnectedCandidateContactCardProps> = ({
    isCompanyApplication,
    isCompanyMatch,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const role = useLegacySelector(state => state.legacyUser.role);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const vacancy = useTypedSelector(state => state.vacancyDetailReducer.vacancy);
    const application = useTypedSelector(state => state.applicationReducer.application);

    useEffect((): () => void => {
        if (shouldFetchCandidate && candidateUuid) {
            dispatch(fetchCandidate(candidateUuid));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    const showLoading = [
        isCompanyMatch && !vacancy,
        isCompanyApplication && !application,
    ].some(Boolean);

    const isAnonymous = [
        isCompanyMatch && vacancy?.isAnonymous,
        isCompanyApplication && application?.vacancy.isAnonymous,
    ].some(Boolean);

    return role === RoleType.company && !isAnonymous ? (
        <CandidateContactCard
            isLoading={isLoading || showLoading}
            candidate={candidate}
            className={className}
        />
    ) : null;
};

export default ConnectedCandidateContactCard;
