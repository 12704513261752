import React from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../helpers/trans';
import { Col, Row } from '../../styledComponents/components/Grid';
import { Heading } from '../../styledComponents/components/Typography';
import Button from '../Button';
import Overlay from '../Overlay';
import RichText from '../RichText';

const ConfirmOverlay = ({
    title,
    id,
    body,
    open,
    onCancel,
    onConfirm,
    confirmLabel,
    cancelLabel,
    loading,
    overlayIsOpen,
    cancelButtonColor,
    confirmButtonColor,
}) => (
    <Overlay
        black
        onClose={onCancel}
        overlayIsOpen={overlayIsOpen}
        maxWidth={550}
        color="red"
    >
        {open && (
            <>
                <Row>
                    <Col>
                        <Heading data-hj-suppress size="lg">{title}</Heading>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RichText content={body} />
                    </Col>
                </Row>
                <Row flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} alignItems="center" justifyContent="flex-end" mt="lg">
                    <Col>
                        <Button
                            id={`${id}-confirm-overlay-cancel`}
                            borderless={cancelButtonColor === 'muted'}
                            onClick={onCancel}
                            color={cancelButtonColor}
                            type="button"
                        >
                            {cancelLabel || trans('actions.cancel')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            id={`${id}-confirm-overlay-submit`}
                            onClick={onConfirm}
                            isLoading={loading}
                            mb={['sm', 'sm', 'sm', 'sm', 0]}
                            color={confirmButtonColor}
                        >
                            {confirmLabel || trans('actions.confirm')}
                        </Button>
                    </Col>
                </Row>
            </>
        )}
    </Overlay>
);

ConfirmOverlay.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    overlayIsOpen: PropTypes.bool,
    confirmButtonColor: PropTypes.string,
    cancelButtonColor: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    loading: PropTypes.bool,
    id: PropTypes.string,
};

ConfirmOverlay.defaultProps = {
    confirmButtonColor: 'alert',
    cancelButtonColor: 'muted',
    confirmLabel: null,
    cancelLabel: null,
    overlayIsOpen: false,
    loading: false,
    id: undefined,
};

export default ConfirmOverlay;
