import React, {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { MatchingCandidate } from '../../../_old/app_talentz/models/MatchingCandidate';
import { convertDayMonthYearStringToDate } from '../../../_old/app_talentz/services/DateService';
import { Button, Skeleton } from '../../../components';
import { ConfirmModal, DetailCard, Modal } from '../../../compositions';
import { formatExtensiveDate } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';

import './CandidateMatchCard.scss';

interface CandidateMatchCardProps {
    isLoading: boolean;
    candidate?: MatchingCandidate;
    onInviteCandidate: () => void;
    onIgnoreCandidate: () => void;
    className?: string;
}

const CandidateMatchCard: FC<CandidateMatchCardProps> = ({
    isLoading,
    candidate,
    onInviteCandidate,
    onIgnoreCandidate,
    className = '',
}): ReactElement => {
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
    const [ignoreModalIsOpen, setIgnoreModalIsOpen] = useState(false);

    const openInviteModal = (): void => setInviteModalIsOpen(true);
    const closeInviteModal = (): void => setInviteModalIsOpen(false);

    const openIgnoreModal = (): void => setIgnoreModalIsOpen(true);
    const closeIgnoreModal = (): void => setIgnoreModalIsOpen(false);

    const title = useMemo((): string => {
        if (candidate?.invited_at) return trans('students.alreadyInterested.title');
        if (candidate?.ignored_at) return trans('students.alreadyIgnored.title');

        return trans('applications.invite.title');
    }, [candidate]);

    const handleInviteCandidate = (): void => {
        onInviteCandidate();
        closeInviteModal();
    };

    const handleIgnoreCandidate = (): void => {
        onIgnoreCandidate();
        closeIgnoreModal();
    };

    return (
        <DetailCard
            isLoading={isLoading}
            title={title}
            className={`candidate-match-card ${className}`}
        >
            {isLoading && (
                <Skeleton className="candidate-match-card__skeleton" />
            )}

            {!isLoading && candidate?.invited_at && (
                <div className="candidate-match-card__wrapper">
                    <p className="candidate-match-card__description">
                        {trans('students.alreadyInterested.content', {
                            date: formatExtensiveDate(convertDayMonthYearStringToDate(candidate.invited_at, true)),
                        })}
                    </p>
                </div>
            )}

            {!isLoading && candidate?.ignored_at && (
                <div className="candidate-match-card__wrapper">
                    <p className="candidate-match-card__description">
                        {trans('students.alreadyIgnored.content', {
                            date: formatExtensiveDate(convertDayMonthYearStringToDate(candidate.ignored_at, true)),
                        })}
                    </p>
                </div>
            )}

            {!isLoading && (!candidate?.invited_at && !candidate?.ignored_at) && (
                <div className="candidate-match-card__wrapper">
                    <p className="candidate-match-card__description">
                        {trans('students.inviteToApply')}
                    </p>

                    <Button
                        text={trans('students.showInterests')}
                        onClick={openInviteModal}
                        className="candidate-match-card__button"
                    />

                    <Button
                        text={trans('students.stopShowing')}
                        onClick={openIgnoreModal}
                        className="candidate-match-card__button candidate-match-card__button--for-ignore"
                    />
                </div>
            )}

            {inviteModalIsOpen && (
                <Modal onClose={closeInviteModal}>
                    <ConfirmModal
                        title={trans('students.confirmation.interested.title')}
                        description={trans('students.confirmation.interested.content')}
                        onCancel={closeInviteModal}
                        onConfirm={handleInviteCandidate}
                    />
                </Modal>
            )}

            {ignoreModalIsOpen && (
                <Modal onClose={closeIgnoreModal}>
                    <ConfirmModal
                        isNegative
                        title={trans('students.confirmation.ignoring.title')}
                        description={trans('students.confirmation.ignoring.content')}
                        onCancel={closeIgnoreModal}
                        onConfirm={handleIgnoreCandidate}
                    />
                </Modal>
            )}
        </DetailCard>
    );
};

export default CandidateMatchCard;
