import React, { FC, ReactElement } from 'react';

import { TableCell, TableRow } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { SbbSubRecognition } from '../../../../models/SbbTrainingCompany';

import './SbbSubRecognitionCollection.scss';

interface SbbSubRecognitionCollectionProps {
    subRecognition: SbbSubRecognition;
}

const SbbSubRecognitionCollection: FC<SbbSubRecognitionCollectionProps> = ({
    subRecognition,
}): ReactElement => (
    <>
        <TableRow className="sbb-sub-competence-collection__row">
            <TableCell className="sbb-sub-competence-collection__cell sbb-sub-competence-collection__cell--task">
                {`${trans('userProfile.sbb.task')}: ${subRecognition.crebo} ${subRecognition.description}`}
            </TableCell>
        </TableRow>

        {subRecognition.skills.map(skill => (
            <TableRow key={skill.crebo} className="sbb-sub-competence-collection__row sbb-sub-competence-collection__row--skill">
                <TableCell className="sbb-sub-competence-collection__cell sbb-sub-competence-collection__cell-skill">
                    {`${skill.crebo} ${skill.description}`}
                </TableCell>
            </TableRow>
        ))}
    </>
);

export default SbbSubRecognitionCollection;
