import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Card, Icon } from '../../../components';
import { Avatar, LinkIconButton } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoutePaths } from '../../../routes';
import { ApplicationCompanyInformationSkeletons } from './skeletons';

import './ApplicationCompanyInformation.scss';

interface ApplicationCompanyInformationProps {
    isLoading: boolean;
    application?: Application;
    vacancy?: NewestVacancy;
    className?: string;
}

const ApplicationCompanyInformation: FC<ApplicationCompanyInformationProps> = ({
    isLoading,
    application,
    vacancy,
    className = '',
}): ReactElement => (
    <Card className={`application-company-information ${className}`}>
        {isLoading && (
            <ApplicationCompanyInformationSkeletons
                className="application-company-information__skeletons"
            />
        )}

        {!isLoading && application && vacancy && (
            <div className="application-company-information__wrapper">
                <Link
                    to={RoutePaths.companyProfile(vacancy.company.uuid)}
                    className="application-company-information__company-wrapper"
                >
                    <Avatar
                        src={vacancy.company.logo}
                        alt={trans('containers.applicationCompanyInformation.logoAlt', {
                            companyName: vacancy.company.name,
                        })}
                        fallbackString={vacancy.company.name}
                        className="application-company-information__company-logo"
                    />

                    <div className="application-company-information__company-text-wrapper">
                        <h2 className="application-company-information__company-name">
                            {vacancy.company.name}
                        </h2>

                        <p className="application-company-information__company-city">
                            {vacancy.company.city}
                        </p>
                    </div>

                    <Icon
                        name="chevron-right"
                        className="application-company-information__company-link-icon"
                    />
                </Link>

                <p className="application-company-information__employee-description">
                    {vacancy.company.contactPerson.fullName}

                    <br />

                    {trans('containers.applicationCompanyInformation.contactPersonPosition', {
                        position: vacancy.company.contactPerson.position,
                        companyName: vacancy.company.name,
                    })}
                </p>

                <LinkIconButton
                    to={RoutePaths.candidateOverviewMessages(application.vacancy.companyUuid)}
                    icon="send"
                    text={trans('actions.sendMessage')}
                    className="application-company-information__chat-button"
                />
            </div>
        )}
    </Card>
);

export default ApplicationCompanyInformation;
