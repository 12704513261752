export const objectContainsValues = <T extends Record<string, any>>(object: T): boolean => {
    const valueArray = Object.values(object);

    for (let i = 0; i < valueArray.length; i += 1) {
        if (![undefined, null].includes(valueArray[i])) {
            return true;
        }
    }

    return false;
};

export const filterObjectOnFilledValues = (object: Record<string, any>): Record<string, any> => {
    const entries = Object.entries(object);
    let filteredObject = {};

    for (let i = 0; i < entries.length; i += 1) {
        const [key, value] = entries[i];

        if (Array.isArray(value) ? value.length > 0 : !!value) {
            filteredObject = {
                ...filteredObject,
                [key]: value,
            };
        }
    }

    return filteredObject;
};

export const getAmountOfDefinedValues = <T extends Record<any, any>>(object: T): number => {
    let amount = 0;

    Object.values(object).forEach(param => param !== undefined && amount++);

    return amount;
};

export const objectsAreEqual = <T extends Record<string, any>>(object: T, comparisonObject: T): boolean => {
    const sortedObject = Object.keys(object).sort().reduce((obj: Record<string, unknown>, key: string) => {
        obj[key] = object[key];
        return obj;
    }, {});

    const sortedComparisonObject = Object.keys(comparisonObject).sort().reduce((obj: Record<string, unknown>, key: string) => {
        obj[key] = comparisonObject[key];
        return obj;
    }, {});

    return JSON.stringify(sortedObject) === JSON.stringify(sortedComparisonObject);
};

export const filterArrayOnObjectKey = <T = Record<string, unknown>>(objectArray: T[], key: keyof T): T[] => {
    const objectEntries: [T[keyof T], T][] = objectArray.map(object => [object[key], object]);

    const uniqueEntries = new Map(objectEntries).values();

    return Array.from(uniqueEntries);
};

export const encodeAllObjectValues = <T extends Record<string, any>>(object: T): T => {
    const entries = Object.entries(object);
    const encodedEntries = entries.map(([key, value]) => [key, encodeURIComponent(value || '')]);

    return Object.fromEntries(encodedEntries);
};

export const decodeAllObjectValues = <T extends Record<string, any>>(object: T): T => {
    const entries = Object.entries(object);
    const decodedEntries = entries.map(([key, value]) => [key, decodeURIComponent(value || '')]);

    return Object.fromEntries(decodedEntries);
};
