import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Profile } from '../../models/Profile';

export interface UserProfileState {
    loading: boolean;
    data: Profile | null;
    errors: Record<string, unknown>;
}

const initialState: UserProfileState = {
    loading: false,
    data: null,
    errors: {},
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): UserProfileState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setData(state, action: PayloadAction<Profile | null>): UserProfileState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): UserProfileState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setData,
    setErrors,
} = userProfileSlice.actions;

export default userProfileSlice;
