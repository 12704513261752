import styled from 'styled-components';

export const Error = styled('p')`
    color: ${props => props.theme.colors.alert};
    font-size: 12px;
    line-height: 1; 
    overflow: hidden; 
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: height 0.15s ease-out,
                padding 0.15s ease-out;
    
    ${props => props.errors && `
        height: 22px;
        padding-top: 5px;
        padding-bottom: 5px;
    `}
`;

export const OptionalLabel = styled.span`
    float: right;
    color: ${props => props.theme.colors.muted};
    font-weight: normal;
    font-size: 12px;
`;
