import React, { ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { PlaceJobVacancySteps } from '../../containers';
import { dataLayerPush } from '../../helpers/analytics';
import { warnBeforeUnload } from '../../helpers/warnBeforeUnload';
import useUnmount from '../../hooks/useUnmount';
import { transformEducationLevelToFormOption } from '../../models/EducationLevels';
import { EscoOccupation } from '../../models/Esco';
import { transformWorkRemoteOptionToFormOption } from '../../models/WorkRemote';
import { RouteParams, RoutePaths } from '../../routes';
import { PlaceJobVacancyFormValues } from '../../services/PlaceVacancyService';
import { useLegacyDispatch, useLegacySelector, useTypedSelector } from '../../store';
import { fetchCompetencies } from '../../store/competencies/competenciesActions';
import { fetchEducationLevels } from '../../store/educationLevels/educationLevelsActions';
import { setSuggestedCompetencies, setSuggestedSkills } from '../../store/escoOccupations/escoOccupations';
import { fetchEscoOccupations, fetchSuggestedCompetencies, fetchSuggestedSkills } from '../../store/escoOccupations/escoOccupationsActions';
import { fetchEscoSkills } from '../../store/escoSkills/escoSkillsActions';
import { fetchFieldsOfStudy } from '../../store/fieldsOfStudy/fieldsOfStudyActions';
import { fetchJobTypes } from '../../store/jobTypes/jobTypesActions';
import { createJobVacancy, editJobVacancy, fetchVacancyToEdit } from '../../store/myVacancies/myVacanciesActions';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { fetchSkills } from '../../store/skills/skillsActions';
import { createSkillOption } from '../../store/vacancyOptions/vacancyOptionsActions';
import { fetchWorkRemoteOptions } from '../../store/workRemote/workRemoteActions';
import { CompanyOverviewTab } from '../../types/pageTabs';

interface PlaceJobVacancyUrlParams extends RouteParams {
    step?: string;
    vacancyUuid?: string;
}

const ConnectedPlaceJobVacancy = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const navigate = useNavigate();
    const { step = '1', vacancyUuid = '' } = useParams<PlaceJobVacancyUrlParams>();

    const company = useLegacySelector(state => state.userCompany);

    const vacancyToEdit = useTypedSelector(state => state.myVacanciesReducer.vacancyToEdit);
    const isLoading = useTypedSelector(state => state.myVacanciesReducer.isLoading);
    const recentlyUpdatedVacancy = useTypedSelector(state => state.myVacanciesReducer.recentlyUpdatedVacancy);

    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);
    const educationLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationLevels);
    const educationSubLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationSubLevels);
    const fieldOfStudyOptions = useTypedSelector(state => state.fieldsOfStudyReducer.fieldsOfStudy);
    const jobTypeOptions = useTypedSelector(state => state.jobTypesReducer.jobTypes);
    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);
    const escoOccupationOptions = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);

    const suggestedSkills = useTypedSelector(state => state.escoOccupationsReducer.suggestedSkills);
    const suggestedCompetencies = useTypedSelector(state => state.escoOccupationsReducer.suggestedCompetencies);

    const skillOptions = useTypedSelector(state => state.skillsReducer.skills);
    const skillsError = useTypedSelector(state => state.skillsReducer.error);
    const escoSkillOptions = useTypedSelector(state => state.escoSkillsReducer.escoSkills);
    const escoSkillsError = useTypedSelector(state => state.escoSkillsReducer.error);
    const competencyOptions = useTypedSelector(state => state.competenciesReducer.competencies);
    const competenciesError = useTypedSelector(state => state.competenciesReducer.error);

    useUnmount((): void => {
        legacyDispatch(setSuggestedSkills([]));
        legacyDispatch(setSuggestedCompetencies([]));
    });

    useEffect((): void => {
        if (sectorOptions.length === 0) legacyDispatch(fetchSectors());
        if (educationLevelOptions.length === 0) legacyDispatch(fetchEducationLevels());
        if (fieldOfStudyOptions.length === 0) legacyDispatch(fetchFieldsOfStudy());
        if (jobTypeOptions.length === 0) legacyDispatch(fetchJobTypes());
        if (workRemoteOptions.length === 0) legacyDispatch(fetchWorkRemoteOptions());
        if (escoOccupationOptions.length === 0) legacyDispatch(fetchEscoOccupations());
        if (skillOptions.length === 0) legacyDispatch(fetchSkills());
        if (escoSkillOptions.length === 0) legacyDispatch(fetchEscoSkills());
        if (competencyOptions.length === 0) legacyDispatch(fetchCompetencies());

        if (vacancyUuid) {
            legacyDispatch(fetchVacancyToEdit(vacancyUuid));
        }
    }, [legacyDispatch, vacancyUuid]);

    useEffect((): void => {
        if (recentlyUpdatedVacancy?.uuid) {
            dataLayerPush({
                event: 'formSubmission',
                formType: `place-job-vacancy-${recentlyUpdatedVacancy.uuid}`,
            });

            navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));
        }
    }, [legacyDispatch, recentlyUpdatedVacancy, navigate]);

    const handleSelectOccupation = (occupation: EscoOccupation): void => {
        legacyDispatch(fetchSuggestedSkills(occupation.id));
        legacyDispatch(fetchSuggestedCompetencies(occupation.id));
    };

    const handleCreateSkillOption = (value: string) => {
        legacyDispatch(createSkillOption(value));
    };

    const handleCreateVacancy = (formValues: PlaceJobVacancyFormValues): void => {
        legacyDispatch(createJobVacancy(formValues));
    };

    const handleEditVacancy = (formValues: PlaceJobVacancyFormValues): void => {
        if (vacancyToEdit) {
            legacyDispatch(editJobVacancy(vacancyToEdit, formValues));
        }
    };

    const handleStepChange = (newStep: number) => {
        navigate(RoutePaths.editJobVacancy(`${newStep}`, vacancyUuid));
    };

    useEffectOnce((): () => void => {
        handleStepChange(1);

        window.addEventListener('beforeunload', warnBeforeUnload);
        return (): void => window.removeEventListener('beforeunload', warnBeforeUnload);
    });

    return (
        <PlaceJobVacancySteps
            isLoading={isLoading}
            step={Number(step)}
            vacancy={vacancyToEdit}
            address={company?.data?.address}
            sectorOptions={sectorOptions}
            educationLevelOptions={educationLevelOptions.map(transformEducationLevelToFormOption)}
            educationSubLevelOptions={educationSubLevelOptions}
            fieldsOfStudyOptions={fieldOfStudyOptions}
            jobTypeOptions={jobTypeOptions}
            workRemoteOptions={workRemoteOptions.map(transformWorkRemoteOptionToFormOption)}
            escoOccupationOptions={escoOccupationOptions}
            suggestedSkills={suggestedSkills}
            suggestedCompetencies={suggestedCompetencies}
            skillOptions={[...skillOptions, ...escoSkillOptions]}
            skillsError={skillsError || escoSkillsError}
            competencyOptions={competencyOptions}
            competenciesError={competenciesError}
            onSelectOccupation={handleSelectOccupation}
            onCreateSkillOption={handleCreateSkillOption}
            onStepChange={handleStepChange}
            onCreate={handleCreateVacancy}
            onEdit={handleEditVacancy}
        />
    );
};

export default ConnectedPlaceJobVacancy;
