import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { BlogHeaderSkeletons, BlogRendererSkeletons } from '..';

import './BlogContentSkeletons.scss';

interface BlogContentSkeletonsProps {
    className?: string;
}

const BlogContentSkeletons: FC<BlogContentSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`blog-content-skeletons ${className}`}>
        <div className="blog-content-skeletons__breadcrumbs">
            <Skeleton />
        </div>

        <BlogHeaderSkeletons className="blog-content-skeletons__header" />

        <div className="blog-content-skeletons__body">
            <BlogRendererSkeletons className="blog-content-skeletons__renderer" />
        </div>
    </div>
);

export default BlogContentSkeletons;
