import axios from 'axios';

import { InternshipPreferencesApiParams, transformToInternshipPreferences } from '../models/InternshipPreferences';
import { Response, ResponseType } from '../models/Response';
import { InternshipVacanciesFilterParams, transformFiltersToInternshipPreferencesRequest } from '../store/internshipVacancies/internshipVacancies';

export async function updateInternshipPreferences(
    preferences: InternshipVacanciesFilterParams,
    internshipPreferencesSetId: string,
): Promise<Response<InternshipPreferencesApiParams>> {
    try {
        const response = await axios.put(`/internship-preferences/${internshipPreferencesSetId}`, {
            ...transformFiltersToInternshipPreferencesRequest(preferences),
        });

        return {
            status: response.status,
            type: ResponseType.Success,
            data: transformToInternshipPreferences(response.data.data),
        };
    } catch (error) {
        return {
            status: 500,
            error,
            type: ResponseType.Error,
        };
    }
}
