import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './JobExperiencesCardSkeletons.scss';

interface JobExperiencesCardSkeletonsProps {
    amount?: number;
    className?: string;
}

const JobExperiencesCardSkeletons: FC<JobExperiencesCardSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`job-experiences-card-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="job-experiences-card-skeletons__item">
                    <div className="job-experiences-card-skeletons__text-wrapper">
                        <Skeleton className="job-experiences-card-skeletons__title" />
                        <Skeleton className="job-experiences-card-skeletons__dates" />
                        <Skeleton className="job-experiences-card-skeletons__description" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default JobExperiencesCardSkeletons;
