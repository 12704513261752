import { trans } from '../helpers/trans';
import { AccessToken } from '../models/AccessToken';
import { FetchResult, FetchResultType } from '../models/FetchResult';

// Logs a user in via username and password and returns the access token
export const logUserInApiCall = async (email: string, password: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const query = new URLSearchParams();

        query.set('password', password);
        query.set('username', email);
        query.set('grant_type', 'password');
        query.set('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID || '');

        const response = await fetch(`${process.env.REACT_APP_API_V2_URL}/token`, {
            method: 'POST',
            headers: {
                Authorization: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: String(query),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[logUserInApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

// Logs a socials (LinkedIn / Azure) user in and returns the access token
export const logSocialsUserInApiCall = async (token: string, email: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const query = new URLSearchParams();

        query.set('username', email);
        query.set('grant_type', 'stageplatform');
        query.set('token', token);
        query.set('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID || '');

        const response = await fetch(`${process.env.REACT_APP_API_V2_URL}/token`, {
            method: 'POST',
            headers: {
                Authorization: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: String(query),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[logSocialsUserInApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const exchangeRefreshTokenApiCall = async (refreshToken: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const query = new URLSearchParams();

        query.set('refresh_token', refreshToken);
        query.set('grant_type', 'refresh_token');
        query.set('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID || '');

        const response = await fetch(`${process.env.REACT_APP_API_V2_URL}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: String(query),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[exchangeRefreshTokenApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
