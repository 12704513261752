import React, { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Page } from '../../components';
import { RoleInfoBenefits, RoleInfoHeader, RoleInfoSteps } from '../../compositions';
import { ConnectedRegisterModal, ConnectedRegistrationCallToAction } from '../../connectors';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { useLegacySelector } from '../../store';

import './InfoJobSeeker.scss';

const InfoJobSeeker: FC = (): ReactElement => {
    const navigate = useNavigate();
    const [registrationModalIsOpen, setRegistrationModalIsOpen] = useState(false);

    const role = useLegacySelector(state => state.legacyUser.role);

    const template = RoleType.jobSeeker;
    const userRole = role ?? RoleType.none;

    const openRegistrationModal = (): void => setRegistrationModalIsOpen(true);
    const closeRegistrationModal = (): void => setRegistrationModalIsOpen(false);

    const handleCallToActionClick = (): void => {
        openRegistrationModal();
    };

    const navigateToJobs = (): void => navigate(RoutePaths.jobs());

    return (
        <Page className="info-job-seeker-page">
            <Helmet>
                <title>{trans('infoPage.jobSeeker.metadata.title')}</title>
                <meta property="og:title" content={createMetadataTitle(trans('infoPage.jobSeeker.metadata.title'))} />
                <meta property="og:description" content={trans('infoPage.jobSeeker.metadata.description')} />
                <meta name="description" content={trans('infoPage.jobSeeker.metadata.description')} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/werkzoekenden`} />
            </Helmet>

            <RoleInfoHeader
                role={userRole}
                template={template}
                onCallToActionClick={navigateToJobs}
            />

            <RoleInfoBenefits
                template={template}
                className="info-job-seeker-page__benefits"
            />

            <RoleInfoSteps
                role={userRole}
                template={template}
                onCallToActionClick={handleCallToActionClick}
                className="info-job-seeker-page__steps"
            />

            <ConnectedRegistrationCallToAction
                template={template}
                onCallToActionClick={handleCallToActionClick}
                className="info-job-seeker-page__registration-call-to-action"
            />

            {registrationModalIsOpen && (
                <ConnectedRegisterModal
                    role={template}
                    onClose={closeRegistrationModal}
                />
            )}
        </Page>
    );
};

export default InfoJobSeeker;
