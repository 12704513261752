/* eslint-disable camelcase */
import { ApplicationInvite, ApplicationInviteApiParams, transformApplicationInvite } from './ApplicationInvite';
import { ApplicationRejection, ApplicationRejectionApiParams, transformApplicationRejection } from './ApplicationRejection';
import { InternshipVacancy } from './InternshipVacancy';
import { JobVacancy } from './JobVacancy';
import { Person, PersonApiResponseParams, transformPerson } from './Person';

export interface ApplicationApiParams {
    uuid: string;
    content: string;
    read: number;
    next_application?: string;
    previous_application?: string;
    created_at: string;
    is_hired: false;
    invite?: ApplicationInviteApiParams;
    rejection?: ApplicationRejectionApiParams;
    person?: PersonApiResponseParams;
}

export interface Application {
    uuid: string;
    content: string;
    read: number;
    next_application?: string;
    previous_application?: string;
    created_at: string;
    is_matched: boolean;
    invite?: ApplicationInvite;
    rejection?: ApplicationRejection;
    person?: Person;
    vacancy?: InternshipVacancy | JobVacancy;
}

export function transformApplication(params: ApplicationApiParams): Application {
    return {
        ...params,
        is_matched: params.is_hired,
        invite: params.invite ? transformApplicationInvite(params.invite) : undefined,
        rejection: params.rejection ? transformApplicationRejection(params.rejection) : undefined,
        person: params.person ? transformPerson(params.person) : undefined,
    };
}
/* eslint-enable camelcase */
