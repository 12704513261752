import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { ApplicationAppointment } from '../../models/ApplicationAppointments';
import { AsyncReduxState, StatusCode } from '../../types';

export type ApplicationAppointmentState = AsyncReduxState<{
    appointment?: ApplicationAppointment;
}>;

const initialState: ApplicationAppointmentState = {
    ...initialAsyncReduxState,
    appointment: undefined,
};

const applicationAppointmentSlice = createSlice({
    name: 'applicationAppointmentReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ApplicationAppointmentState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ApplicationAppointmentState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ApplicationAppointmentState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setStatusCode(state, action: PayloadAction<StatusCode | undefined>): ApplicationAppointmentState {
            return {
                ...state,
                statusCode: action.payload,
            };
        },
        setAppointment(state, action: PayloadAction<ApplicationAppointment | undefined>): ApplicationAppointmentState {
            return {
                ...state,
                appointment: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setStatusCode,
    setAppointment,
} = applicationAppointmentSlice.actions;

export default applicationAppointmentSlice;
