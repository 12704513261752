import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './ChatSelectorSkeletons.scss';

interface ChatSelectorSkeletonsProps {
    amount?: number;
    className?: string;
}

const ChatSelectorSkeletons: FC<ChatSelectorSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`chat-selector-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="chat-selector-skeletons__item">
                    <Skeleton className="chat-selector-skeletons__title" />

                    <div className="chat-selector-skeletons__message">
                        <Skeleton className="chat-selector-skeletons__line" />
                        <Skeleton className="chat-selector-skeletons__line" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default ChatSelectorSkeletons;
