import React, { FC, ReactElement } from 'react';

import { Card, Label } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { Testimonial } from '../../Testimonials';

import './TestimonialCard.scss';

interface TestimonialCardProps extends Testimonial {
    className?: string;
}

const TestimonialCard: FC<TestimonialCardProps> = ({
    poster,
    logo,
    label,
    quote,
    author,
    className = '',
}): ReactElement => (
    <Card className={`testimonial-card ${className}`}>
        <header className="testimonial-card__header">
            <div className="testimonial-card__header-content">
                {logo && (
                    <div className="testimonial-card__logo-wrapper">
                        <img
                            src={logo}
                            alt={trans('basic.logo')}
                            className="testimonial-card__logo"
                        />
                    </div>
                )}
                <Label text={label} className="testimonial-card__label" />
            </div>

            <img
                src={poster}
                alt={author}
                className="testimonial-card__poster"
            />
        </header>
        <div className="testimonial-card__text-wrapper">
            <q className="testimonial-card__quote">
                {quote}
            </q>
            <p className="testimonial-card__author">
                {author}
            </p>
        </div>
    </Card>
);

export default TestimonialCard;
