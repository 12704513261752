import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getKvkSearchResultsApiCall } from '../../services/KvkSearchResultsService';
import { TypedDispatch } from '..';
import { setError, setIsLoading, setKvkSearchResults } from './kvkSearchResults';

export const fetchKvkSearchResults = (tradeName: string, location: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const kvkSearchResultsResponse = await getKvkSearchResultsApiCall(tradeName, location);

        if (!isFetchResultSuccessful(kvkSearchResultsResponse)) {
            dispatch(setError(kvkSearchResultsResponse.error));
            return;
        }

        dispatch(setKvkSearchResults(kvkSearchResultsResponse.data));
    } catch (error) {
        console.error('[fetchKvkSearchResults]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
