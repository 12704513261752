import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import LogoTop from '../../styledComponents/components/Logo';

const expand = css`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
`;

export const Overlay = styled.div(props => css`
   ${expand};
   position: fixed;
   display: flex;
   align-items: ${props.messageOnly ? 'center' : 'flex-start'};
   overflow-y: auto;
   z-index: 290;
   background-color: ${props.theme.colors.primary};
`);

export const Background = styled('div')(`
   ${expand};
   position: fixed;
   background-color: ${props => props.theme.colors.primary};
   z-index: 0;
`);

export const LinkButton = styled(Link)(props => css`
    border: none;
    text-decoration: none;
    cursor: pointer;
   
    ${props.as && css`
        cursor: default;
    `}
`);

export const CloseButton = styled('button')(props => css`
    position: absolute;
    top: ${props.theme.space.sm};
    right: ${props.theme.space.sm};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 0;
    ${props.theme.fonts.bold};
    text-transform: uppercase;
    color: ${props.theme.colors.white};
    border: 0;
    background-color: transparent;
    z-index: 9999;
    cursor: pointer;
    opacity: 0.7;
    text-decoration: none;
    
    &:hover {
        opacity: 1;
    }
    
    @media ${props.theme.mediaQueries.smallTablet} {
        top: ${props.theme.space.md};
        right: ${props.theme.space.md};
    }
`);

export const CloseLabel = styled('span')`
    display: none;

    @media ${props => props.theme.mediaQueries.laptop} {
        display: inline-block;
    }
`;

export const Message = styled.div(props => css`
    display: ${props.centered ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 ${props.centered ? props.theme.space.sm : 0};
    max-width: ${(props.centered ? '100%' : '465px')};
    text-align: ${(props.centered ? 'center' : 'left')};
    margin-top: ${props.theme.space.xxl};
    
    @media ${props.theme.mediaQueries.smallTablet} {
        display: flex;
    }
    
    @media ${props.theme.mediaQueries.laptop} {
        margin-top: 0;
    }
`);

export const Content = styled.div(props => css`
   position: relative;
   align-items: center;
   justify-content: center;
   width: 100%;
   z-index: 1;
   
   @media ${props.theme.mediaQueries.smallTablet} {
        margin: auto;
        padding: ${props.theme.space.sm};
        width: auto;
   }
   
   @media ${props.theme.mediaQueries.tablet} {
        max-width: ${props.theme.variables.maxContainerWidth}px;
        padding: ${props.theme.space.xxl} ${props.theme.space.sm};
   }
   
   @media ${props.theme.mediaQueries.laptop} {
        display: flex;
        flex-direction: row;
        width: 100%;
   }
`);

export const Card = styled('div')(props => css`
    background-color: ${props.theme.colors.white};
    box-shadow: ${props.theme.shadow()};
    margin-top: ${props.theme.space.xxl};
    padding: ${props.theme.space.xl} ${props.theme.space.md} ${props.theme.space.md};
  
    @media ${props.theme.mediaQueries.smallTablet} {
        display: block;
        padding: ${props.theme.space.xxl} ${props.theme.space.xl} ${props.theme.space.xl};
    }
    
    @media ${props.theme.mediaQueries.tablet} {
        margin-top: ${props.theme.space.xl};
    }
    
    @media ${props.theme.mediaQueries.laptop} {
        margin-top: 0;
    }
`, space);

export const Logo = styled(LogoTop)(props => css`
    position: absolute;
    z-index: 100;
    top: ${props.theme.space.md};
    left: ${props.theme.space.lg};
    height: 28px;
`, space);
