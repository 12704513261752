import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

export const Tip = styled.span(props => css`
    display: flex;
    align-items: center;
    margin-left: ${props.theme.space.xxs};
    
    svg {
        width: auto;
        height: auto;
        margin: 0;
    }
`);

export const TipContent = styled(ReactTooltip)(props => css`
    ${props.width && `width: ${props.width}px;`}
    max-width: 100%;
    padding: ${props.theme.space.md};
    
    @media ${props.theme.mediaQueries.smallTablet} {
        max-width: ${props.maxWidth ? `${props.maxWidth}px` : '34rem'};
    }
`);
