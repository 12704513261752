import React, { FC, ReactElement } from 'react';

import { LinkButton } from '../../../components';
import { DetailCard } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoutePaths } from '../../../routes';

import './VacancyAwaitingResponseCard.scss';

interface VacancyAwaitingResponseCardProps {
    applicationUuid: string;
    vacancy: NewestVacancy;
    className?: string;
}

const VacancyAwaitingResponseCard: FC<VacancyAwaitingResponseCardProps> = ({
    applicationUuid,
    vacancy,
    className = '',
}): ReactElement => (
    <DetailCard
        title={trans('vacancyDetail.awaitingResponse.title')}
        className={`vacancy-awaiting-response-card ${className}`}
    >
        <p className="vacancy-awaiting-response-card__description">
            {trans('vacancyDetail.awaitingResponse.description', {
                company: vacancy.company.name,
            })}
        </p>

        <LinkButton
            to={RoutePaths.application(applicationUuid)}
            text={trans('vacancyDetail.awaitingResponse.button.viewApplication')}
            className="vacancy-awaiting-response-card__button"
        />
    </DetailCard>
);

export default VacancyAwaitingResponseCard;
