import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { trans } from '../../../../helpers/trans';

import './CarouselBulletControls.scss';

interface CarouselBulletControlsProps {
    slideAmount: number;
    currentSlide: number;
    onBulletClick: (slide: number) => void;
    className?: string;
}

const CarouselBulletControls: FC<CarouselBulletControlsProps> = ({
    slideAmount,
    currentSlide,
    onBulletClick,
    className = '',
}): ReactElement | null => {
    if (slideAmount <= 1) return null;

    const slides = generateIdArray(slideAmount);

    return (
        <ul className={`carousel-bullet-controls ${className}`}>
            {slides.map(slide => {
                const handleClick = (): void => onBulletClick(slide);

                const buttonClassNames = classNames('carousel-bullet-controls__button', {
                    'carousel-bullet-controls__button--is-active': slide === currentSlide,
                });

                return (
                    <li key={slide} className="carousel-bullet-controls__item">
                        <Button
                            text={trans('actions.carousel.toSlide', { slide: slide + 1 })}
                            onClick={handleClick}
                            className={buttonClassNames}
                        >
                            <span />
                        </Button>
                    </li>
                );
            })}
        </ul>
    );
};

export default CarouselBulletControls;
