import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../components';
import { Avatar, DetailCard, LinkIconButton } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Company } from '../../../models/Company';
import { RoutePaths } from '../../../routes';

import './VacancyCompanyCard.scss';

interface VacancyCompanyCardProps {
    isLoading: boolean;
    company?: Company;
    className?: string;
}

const VacancyCompanyCard: FC<VacancyCompanyCardProps> = ({
    isLoading,
    company,
    className = '',
}): ReactElement => (
    <DetailCard
        title={trans('vacancyDetail.company.title')}
        className={`vacancy-company-card ${className}`}
    >
        {isLoading && (
            <div className="vacancy-company-card__skeleton-wrapper">
                <Skeleton className="vacancy-company-card__skeleton-logo" />

                <div className="vacancy-company-card__skeleton-line-wrapper">
                    <Skeleton className="vacancy-company-card__skeleton-line" />
                    <Skeleton className="vacancy-company-card__skeleton-line" />
                </div>
            </div>
        )}

        {!isLoading && company && (
            <div className="vacancy-company-card__wrapper">
                <Avatar
                    src={company.logo}
                    alt={trans('vacancyDetail.company.logoAlt', {
                        company: company.name,
                    })}
                    fallbackString={company.name}
                    className="vacancy-company-card__logo"
                />

                <div className="vacancy-company-card__text-wrapper">
                    <h2 className="vacancy-company-card__name">
                        {company.name}
                    </h2>

                    <h3 className="vacancy-company-card__city">
                        {company.address.city}
                    </h3>
                </div>
            </div>
        )}

        <LinkIconButton
            icon="arrow-right"
            iconPos="right"
            to={RoutePaths.companyProfile(company?.uuid)}
            text={trans('vacancyDetail.company.button.viewCompanyProfile')}
            disabled={isLoading || !company}
            className="vacancy-company-card__button"
        />
    </DetailCard>
);

export default VacancyCompanyCard;
