import React, { FC, ReactElement } from 'react';

import { Button } from '../../components';
import { TestimonialCard } from './subcomponents';

import './Testimonials.scss';

export interface Testimonial {
    poster: string;
    logo?: string;
    label: string;
    quote: string;
    author: string;
}

interface TestimonialsProps {
    title: string;
    description: string;
    buttonLabel: string;
    testimonial: Testimonial;
    onCallToActionClick: () => void;
    className?: string;
}

const Testimonials: FC<TestimonialsProps> = ({
    title,
    description,
    buttonLabel,
    testimonial,
    onCallToActionClick,
    className = '',
}): ReactElement => (
    <section className={`testimonials ${className}`}>
        <div className="testimonials__wrapper">
            <div className="testimonials__card-wrapper">
                <TestimonialCard {...testimonial} className="testimonials__card" />
            </div>

            <div className="testimonials__text-wrapper">
                <h1 className="testimonials__title">
                    {title}
                </h1>
                <p className="testimonials__description">
                    {description}
                </p>

                <Button
                    text={buttonLabel}
                    onClick={onCallToActionClick}
                    className="testimonials__button"
                />
            </div>
        </div>

        <div className="testimonials__backdrop" />
    </section>
);

export default Testimonials;
