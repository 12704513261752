import React from 'react';

import { Helmet } from 'react-helmet-async';
import styled, { css } from 'styled-components';

import { trans } from '../../../../../helpers/trans';
import StickyComponent from '../../../../components/StickyComponent';
import Card from '../../../../styledComponents/components/Card';
import Container from '../../../../styledComponents/components/Container';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Laptop, MaxLaptop } from '../../../../styledComponents/components/MediaQueries';
import Page from '../../../../styledComponents/components/Page';
import {
    FakeLink as FakeLinkWrapper,
    Heading,
    Paragraph,
    Span,
} from '../../../../styledComponents/components/Typography';
import { createMetadataTitle } from '../../../services/MetadataService';

const scrollTo = to => {
    if (typeof window !== 'undefined') {
        const element = document.querySelector(`#${to}`);
        const windowView = element.ownerDocument.defaultView;
        const elementRect = document.querySelector(`#${to}`).getBoundingClientRect();

        window.scrollTo(0, elementRect.top + windowView.pageYOffset - 100);
    }
};

const FakeLink = (label, url) => (
    <FakeLinkWrapper onClick={() => scrollTo(url)} display="block" color="primary">
        {label}
    </FakeLinkWrapper>
);

const LinkStyle = styled('a')(props => css`
    color: ${props.theme.colors.link};
    text-decoration: none;
`);

const TermsAndConditionsMenu = (
    <>
        <Heading as="h2">Inhoudsopgave</Heading>
        <Span>
            <Paragraph mb="xs">
                {FakeLink('Artikel 1 - Definities', 'A01')}
                {FakeLink('Artikel 2 - Toepassing', 'A02')}
                {FakeLink('Artikel 3 - Totstandkoming overeenkomst', 'A03')}
                {FakeLink('Artikel 4 - De dienst', 'A04')}
                {FakeLink('Artikel 5 - Gebruik van het Platform', 'A05')}
                {FakeLink('Artikel 6 - Geheimhouding', 'A06')}
                {FakeLink('Artikel 7 - Privacy en veiligheid', 'A07')}
                {FakeLink('Artikel 8 - Duur en beëindiging', 'A08')}
                {FakeLink('Artikel 9 - Aansprakelijkheid en schade', 'A09')}
                {FakeLink('Artikel 10 - Overmacht', 'A10')}
                {FakeLink('Artikel 11 - Rechten van intellectuele eigendom', 'A11')}
                {FakeLink('Artikel 12 - Verkoopmateriaal', 'A12')}
                {FakeLink('Artikel 13 - Toepasselijk recht en bevoegde rechter', 'A13')}
                {FakeLink('Artikel 14 - Wijziging en uitleg van de voorwaarden', 'A14')}
            </Paragraph>
        </Span>
    </>
);

const TermsAndConditionsPage = () => (
    <Page>
        <Helmet>
            <title>{trans('infoPage.termsAndConditions.metadata.title')}</title>
            <meta property="og:title" content={createMetadataTitle(trans('infoPage.termsAndConditions.metadata.title'))} />
            <meta property="og:description" content={trans('infoPage.termsAndConditions.metadata.description')} />
            <meta name="description" content={trans('infoPage.termsAndConditions.metadata.description')} />
            <link rel="canonical" href={`${process.env.REACT_APP_URL}/algemene-voorwaarden`} />
        </Helmet>
        <Container pb="xl" centered>
            <Heading as="h1" size={36}>Algemene Voorwaarden</Heading>
            <MaxLaptop>
                <Col width={['100%']}>
                    <Card mb="sm">
                        {TermsAndConditionsMenu}
                    </Card>
                </Col>
            </MaxLaptop>
            <Row>
                <Col width={['100%', '100%', '100%', '100%', 8 / 12]} mr={[0, 0, 0, 0, 'md']}>
                    <Card mb="xl">
                        <Heading as="h2" id="A01">Artikel 1 - Definities</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Algemene voorwaarden: onderhavige algemene voorwaarden, waarin de
                                bepalingen voor het gebruik van het door TalentZ B.V. aangeboden
                                Platform en aanverwante diensten staan beschreven.
                            </li>
                            <li>
                                Overeenkomst: de overeenkomst tussen TalentZ B.V. en Ondernemers
                                met betrekking tot het gebruik van de diensten zoals die worden
                                aangeboden op het Platform, waarop onderhavige algemene voorwaarden
                                van toepassing worden verklaard.
                            </li>
                            <li>
                                Platform: de door TalentZ B.V. ontwikkelde, onderhouden en geleverde
                                online dienst op de Website, welke dienst betrekking heeft op het
                                enerzijds aanbieden van een online vacatures- en
                                stageopdrachtenplatform ten behoeve van Gebruikers en anderzijds
                                betrekking heeft op het automatisch Matchen van deze Gebruikers
                                op basis van op het Platform door Gebruikers ingevoerde informatie.
                            </li>
                            <li>
                                Gebruikers: de internetgebruikers die op welke manier dan ook
                                gebruikmaken van het Platform, waaronder begrepen, maar niet beperkt
                                tot Ondernemers en Studenten.
                            </li>
                            <li>
                                Student: de stageopdracht zoekende of werkzoekende
                                persoon die een Account aanmaakt op het Platform en/of anderszins
                                van het Platform gebruikmaakt.
                            </li>
                            <li>
                                Ondernemer: de natuurlijke of rechtspersoon die een Account aanmaakt
                                op het Platform en/of anderszins van het Platform gebruikmaakt.
                            </li>
                            <li>
                                Account: persoonlijke sectie van Ondernemers en Studenten waarbinnen
                                voorkeuren, eigenschappen en/of vaardigheden kunnen worden opgegeven
                                en waarbinnen Studenten een curriculum vitae (cv) kunnen uploaden.
                            </li>
                            <li>
                                Matchen en/of Matching: het op basis van door Gebruikers ingevoerde
                                informatie automatisch koppelen (matchen) van Gebruikers.
                            </li>
                            <li>
                                Baanvacature: daadwerkelijk bestaande, concrete en op het moment van
                                plaatsing openstaande baanvacatures van een Ondernemer waarop een
                                Student kan reageren.
                            </li>
                            <li>
                                Stageopdracht: de via het Platform aangeboden stageopdracht waarop
                                een Student kan reageren, naar aanleiding waarvan een
                                stageovereenkomst in welke vorm dan ook tot stand kan komen tussen
                                Ondernemer en Student.
                            </li>
                            <li>
                                Advertentie: een advertentie op het Platform met betrekking tot
                                een openstaande Baanvacature en/of Stageopdracht.
                            </li>
                            <li>
                                Website: de website te vinden op de domeinnaam:&nbsp;
                                <LinkStyle href="https://www.talentz.nl">
                                    www.talentz.nl
                                </LinkStyle>,
                                of andere domeinnamen, althans Websites die door TalentZ B.V.
                                worden beheerd voor het ter beschikking stellen van haar producten
                                en/of diensten.
                            </li>
                            <li>
                                Servers: de door of ten behoeve van het gebruik van het Platform
                                beheerde, bij elkaar horende computers en aanverwante hardware,
                                met daarop de webserverprogrammatuur, ondersteunende programmatuur,
                                of databasesoftware.
                            </li>
                            <li>
                                TalentZ B.V.: de besloten vennootschap TalentZ B.V. (KvK-nummer
                                74259199), statutair gevestigd te Spanbroek en kantoorhoudende te
                                (1715 GG) Spanbroek aan de C.N. Appelstraat nr. 8, nader te noemen:
                                ‘TalentZ’.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A02">Artikel 2 - Toepassing</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Deze voorwaarden zijn van toepassing op de toegang tot en het
                                gebruik van de door TalentZ geëxploiteerde websites, waaronder&nbsp;
                                <LinkStyle href="https://www.talentz.nl">
                                    www.talentz.nl
                                </LinkStyle>
                                &nbsp;en meer in het bijzonder op de toegang tot en het gebruik van
                                het Platform door Gebruikers op welke manier dan ook.
                            </li>
                            <li>
                                Deze voorwaarden zijn voorts van toepassing op de tussen TalentZ en
                                Ondernemers gesloten Overeenkomsten met betrekking tot het gebruik
                                van de door TalentZ aangeboden producten en/of diensten, alsmede op
                                de hieruit voortvloeiende Overeenkomsten.
                            </li>
                            <li>
                                Toepasselijkheid van eventuele (afwijkende) algemene voorwaarden
                                wordt uitdrukkelijk van de hand gewezen.
                            </li>
                            <li>
                                Afwijkingen van deze voorwaarden moeten uitdrukkelijk schriftelijk
                                overeengekomen worden. Afwijkingen gelden alsdan alleen voor de
                                betreffende aanbiedingen en Overeenkomsten waarop deze van
                                toepassing zijn.
                            </li>
                            <li>
                                Indien TalentZ niet steeds de strikte naleving van deze voorwaarden
                                verlangt, brengt zulks niet met zich mede dat deze voorwaarden niet
                                van toepassing zijn en/of dat TalentZ het recht verliest om in
                                toekomstige, al dan niet soortgelijke gevallen strikte naleving van
                                deze voorwaarden te verlangen.
                            </li>
                            <li>
                                Indien de Gebruiker een natuurlijk persoon is, die niet handelt in
                                de uitoefening van een beroep of bedrijf, blijven de artikelen van
                                deze voorwaarden, of de onderdelen daarvan, die onredelijk bezwarend
                                zijn voor de Gebruiker omdat ze voorkomen op de lijst als bedoeld
                                in artikel 6:236 BW dan wel in strijd zijn met de bepalingen van
                                dwingend consumentenrecht, buiten toepassing. De overige bepalingen
                                blijven in dat voorkomende geval onverkort van toepassing.
                            </li>
                            <li>
                                De vernietiging en/of nietigheid van enige bepaling van deze
                                voorwaarden laat de geldigheid van de overige bepalingen van deze
                                voorwaarden onverlet. De strijdige, niet rechtsgeldige bepaling,
                                wordt geacht te zijn vervangen door een bepaling waarmee zoveel
                                mogelijk recht wordt gedaan aan de bedoeling en strekking van de
                                oorspronkelijke bepaling.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A03">Artikel 3 - Totstandkoming overeenkomst</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Alle aanbiedingen van TalentZ, in welke vorm dan ook, zijn geheel
                                vrijblijvend, tenzij uitdrukkelijk anders vermeld. TalentZ is steeds
                                gerechtigd een aanbod geheel of gedeeltelijk in te trekken, dan wel
                                te wijzigen.
                            </li>
                            <li>
                                Overeenkomsten kunnen naar keuze van de contractspartijen,
                                mondeling, schriftelijk, telefonisch, op elektronische wijze of op
                                een andere manier worden gesloten c.q. tot stand komen. TalentZ
                                beslist – geheel naar eigen inzicht – met welke wijze van
                                totstandkoming zij wel en niet akkoord gaat. De Overeenkomst tussen
                                partijen is bindend, ongeacht de wijze waarop deze tot stand komt.
                            </li>
                            <li>
                                Indien tijdens de uitvoering van de Overeenkomst naar het oordeel
                                van TalentZ blijkt dat het ten behoeve van een behoorlijke
                                uitvoering noodzakelijk is om de te verrichten prestaties te
                                wijzigen en/of aan te vullen, zal TalentZ de Gebruiker waarmee
                                een Overeenkomst is gesloten hiervan in kennis stellen, waarna
                                de Overeenkomst al dan niet geheel of gedeeltelijk zal worden
                                gewijzigd. TalentZ kan nimmer ten gevolge van een dergelijke
                                wijziging gehouden zijn tot enige vergoeding van schade aan
                                desbetreffende Gebruiker.
                            </li>
                            <li>
                                Mocht de Overeenkomst namens de Gebruiker worden gesloten door
                                een derde, dan staat deze derde ervoor in, dat desbetreffende
                                Gebruiker deze voorwaarden heeft aanvaard, bij gebreke waarvan
                                de derde aan deze voorwaarden is gebonden al ware hij zelf
                                Gebruiker.
                            </li>
                            <li>
                                TalentZ is gerechtigd om ter uitvoering van de Overeenkomst
                                derden in te schakelen.
                            </li>
                            <li>
                                TalentZ is gerechtigd en bevoegd rechten en verplichtingen
                                voortvloeiende uit de Overeenkomst aan een derde over te
                                dragen, in het bijzonder in het kader van een overdracht van
                                (een gedeelte van) de door haar gedreven onderneming.
                            </li>
                            <li>
                                De Gebruiker kan zich niet op een Overeenkomst beroepen,
                                indien voor of bij de uitvoering van de Overeenkomst blijkt
                                dat de door de Gebruiker verstrekte informatie en gegevens
                                niet juist of onvolledig zijn. TalentZ behoudt zich alsdan
                                het recht voor een Overeenkomst niet dan wel niet verder uit
                                te voeren. In dat geval kan TalentZ nimmer gehouden zijn tot
                                enige vergoeding van schade van de Gebruiker, onverminderd het
                                recht en de mogelijkheid van TalentZ om ten opzichte van de
                                Gebruiker wel aanspraak te kunnen maken op vergoeding van schade
                                dan wel de Overeenkomst alsnog uit te voeren tegen een hogere
                                prijs dan overeengekomen, tot welke betaling de Gebruiker dan
                                verplicht is.
                            </li>
                            <li>
                                Indien tijdens de uitvoering van de Overeenkomst naar het
                                oordeel van TalentZ blijkt dat het ten behoeve van een
                                behoorlijke uitvoering noodzakelijk is om de te verrichten
                                werkzaamheden te wijzigen en/of aan te vullen, zal TalentZ de
                                Gebruiker hiervan in kennis stellen, waarna de Overeenkomst al
                                dan niet geheel of gedeeltelijk zal worden gewijzigd. TalentZ
                                kan nimmer ten gevolge van een dergelijke wijziging gehouden zijn
                                tot enige vergoeding van schade aan de Gebruiker.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A04">Artikel 4 - De dienst</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                TalentZ biedt een Platform aan waarop Gebruikers, mede aan de
                                hand van de op het Platform door Ondernemers geplaatste
                                Advertenties, met elkaar in contact kunnen komen in het kader
                                van selectie en/of werving van stagiaires en personeel. Speciaal
                                hiervoor biedt TalentZ een automatisch Matchingsysteem tussen
                                Gebruikers aan op basis waarvan Gebruikers op het Platform
                                automatisch aan elkaar worden gekoppeld. Deze automatische
                                Matching vindt plaats op grond van de informatie die door Gebruikers
                                op het Platform is ingevoerd.
                            </li>
                            <li>
                                TalentZ probeert Gebruikers mede op basis van het door TalentZ
                                ontwikkelde Matchingsysteem in positieve zin tot elkaar te brengen.
                                TalentZ is echter nadrukkelijk geen partij bij een overeenkomst
                                tussen Gebruikers, noch de totstandkoming hiervan. Bovendien kan
                                TalentZ niet garanderen dat daadwerkelijk een overeenkomst tussen
                                Gebruikers tot stand komt, noch garanderen dat Gebruikers aan
                                elkaars verwachtingen en/of wensen voldoen, ongeacht de eventuele
                                Matching van Gebruikers.
                            </li>
                            <li>
                                Hetgeen onder lid 2 bepaald, brengt met zich dat TalentZ op geen
                                enkele wijze verantwoordelijk is voor de uit de eventuele tussen
                                Gebruikers tot stand gekomen overeenkomsten voortvloeiende
                                verplichtingen, waaronder doch niet uitsluitend begrepen het voldoen
                                aan enige betalingsverplichting, dan wel enige verplichting
                                gerelateerd aan de uitvoering van de werkzaamheden uit hoofde van
                                een arbeids- dan wel (stage)opdrachtovereenkomst.
                            </li>
                            <li>
                                Gebruikers bepalen zelf welke informatie op het Platform wordt
                                opgeslagen, bewerkt, verwerkt of anderszins ingevoerd en/of gedeeld.
                                TalentZ draagt geen kennis van deze informatie. Gebruikers zijn er
                                dan ook voor verantwoordelijk dat die informatie rechtmatig is en
                                geen inbreuk maakt op rechten van derden. TalentZ aanvaardt geen
                                enkele aansprakelijkheid voor de met behulp van het Platform
                                opgeslagen en/of uitgewisselde informatie. TalentZ is niet gehouden
                                de juistheid en volledigheid van de opgeslagen, bewerkte, verwerkte
                                of anderszins ingevoerde en/of gedeelde informatie te controleren en
                                is daarom niet aansprakelijk voor de gevolgen van het gebruik van
                                door de Gebruikers aangeleverde onjuiste en/of onvolledige
                                informatie.
                            </li>
                            <li>
                                TalentZ biedt de door haar zelf geplaatste informatie op het
                                Platform, voor zover het toepasselijk recht zulks toestaat, aan
                                op een “zoals het is ” basis, met uitsluiting van eventuele
                                uitdrukkelijke en/of stilzwijgende garanties, toezeggingen of
                                vrijwaringen van welke aard dan ook, met inbegrip van (doch niet
                                beperkt tot) de uitsluiting van garanties ter zake van gerechtigheid
                                tot (eigendoms)rechten, voldoende kwaliteit, geschiktheid voor een
                                bepaald doel. Met name garandeert TalentZ niet:
                                <ol type="i" style={{ marginLeft: '40px' }}>
                                    <li>
                                        dat de informatie op het Platform, waaronder mede verstaan
                                        de door Gebruikers opgegeven informatie, juist, volledig,
                                        geschikt, actueel, niet onrechtmatig is en geen inbreuk
                                        maakt op (intellectuele eigendoms)rechten van derden;
                                    </li>
                                    <li>
                                        dat het Platform ononderbroken zal werken, vrij zal zijn van
                                        virussen, trojans en andere fouten en/of gebreken en dat
                                        eventuele gebreken zullen worden verholpen, een en ander in
                                        samenhang met het bepaalde in lid 6 van onderhavig artikel;
                                    </li>
                                    <li>
                                        dat derden niet het Platform en/of de systemen van TalentZ
                                        onrechtmatig zullen gebruiken.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                TalentZ behoudt zich het recht voor om zoekopdrachten voor stages en
                                vacatures, de inhoud van Accounts, waaronder curricula vitae te
                                wijzigen, dan wel te verwijderen indien dit naar het oordeel van
                                TalentZ noodzakelijk is in verband met overtreding van geldende
                                wet- en regelgeving en/of verplichtingen voortvloeiende uit
                                onderhavige voorwaarden. TalentZ behoudt zich het recht voor om
                                teksten op het Platform en/of in Accounts te verwijderen die
                                onethisch zijn of die aanzetten tot illegaal gedrag.
                            </li>
                            <li>
                                TalentZ is gerechtigd het Platform (tijdelijk) buiten gebruik te
                                stellen of te beperken in het geval dit bijvoorbeeld noodzakelijk
                                of wenselijk is voor het onderhouden, aanpassen en/of verbeteren van
                                de gebruikerservaring van het Platform, zonder dat hierdoor enig
                                recht op schadevergoeding jegens TalentZ ontstaat. TalentZ is niet
                                gehouden om Gebruikers daar (al dan niet vooraf) over te informeren.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A05">Artikel 5 - Gebruik van het Platform</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Om gebruik te kunnen maken van het Platform, dienen Gebruikers een
                                Account aan te maken conform de instructies op de Website, rekening
                                houdend met de bepalingen zoals neergelegd in onderhavige algemene
                                voorwaarden.
                            </li>
                            <li>
                                Alleen Gebruikers die (wettelijk) bevoegd, dan wel gerechtigd zijn
                                om gebruik te maken van de Website en het Platform, zijn gerechtig
                                een Account aan te maken.
                            </li>
                            <li>
                                Gebruikers zullen op geen enkele wijze inbreuk maken op
                                intellectuele eigendomsrechten van TalentZ en/of derden. Indien
                                het TalentZ ter kennis komt dat gegevens die met behulp van het
                                Platform worden opgeslagen, bewerkt, verwerkt of anderszins
                                ingevoerd onrechtmatig zijn jegens derden, dan is TalentZ gerechtigd
                                deze gegevens per direct en zonder voorafgaande mededeling van de
                                server te verwijderen en te vernietigen. Reeds nu voor alsdan
                                verlenen de Gebruikers toestemming aan TalentZ om alle
                                inbreukmakende gegevens van de server te verwijderen en te
                                vernietigen. TalentZ zal in geen enkel geval aansprakelijk zijn voor
                                enige schade die voortvloeit uit dit handelen.
                            </li>
                            <li>
                                TalentZ en Gebruikers zijn verplicht de door TalentZ verstrekte
                                en/of door Gebruikers aangemaakte gebruikersnamen en wachtwoorden
                                geheim te houden.
                            </li>
                            <li>
                                TalentZ is niet aansprakelijk voor misbruik, of verlies van
                                gebruikersnamen en/of wachtwoorden door Gebruikers. Het is de
                                verantwoordelijkheid van Gebruikers om erop toe te zien dat
                                aanmeldingen op het Platform geschieden door Gebruikers die
                                hiertoe geautoriseerd zijn. In het geval gebruikersnamen en/of
                                wachtwoorden in handen zijn gekomen van onbevoegden, dan dient
                                TalentZ hiervan door Gebruikers onverwijld, schriftelijk, op de
                                hoogte te worden gebracht.
                            </li>
                            <li>
                                Het is Gebruikers niet toegestaan om informatie en gegevens die
                                van TalentZ wordt ontvangen in het kader van het gebruik van het
                                Platform openbaar te maken, te verveelvoudigen of anderszins aan
                                te wenden dan voor gebruik in zijn interne bedrijfsvoering. Het
                                delen of doorgeven van informatie en gegevens met andere bedrijven
                                is niet toegestaan.
                            </li>
                            <li>
                                Gebruikers vrijwaren TalentZ voor aanspraken van derden op
                                vergoeding van schade die deze derden op enigerlei wijze op
                                TalentZ zouden kunnen verhalen, voor zover deze aanspraak is
                                gegrond op het gebruik dat door de Gebruikers van het Platform
                                is gemaakt.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A06">Artikel 6 - Geheimhouding</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Zowel TalentZ als Gebruikers zullen geheimhouding betrachten ten
                                aanzien van bij de uitvoering van de Overeenkomst, dan wel via
                                het Platform verkregen gegevens en informatie omtrent de
                                bedrijfsaangelegenheden van andere partij(en).
                            </li>
                            <li>
                                Het is de Gebruikers van het Platform uitdrukkelijk verboden de
                                persoonlijke inloggegevens zoals gebruikersnaam en wachtwoorden
                                met andere te delen. Indien en voor zover één of meerdere Gebruikers
                                in strijd handelen met deze bepaling, dan is TalentZ hiervoor op
                                geen enkele wijze verantwoordelijk en aanvaardt zij te dien
                                aanzien op geen enkele wijze aansprakelijkheid.
                            </li>
                            <li>
                                Gebruikers verbinden zich ertoe alles in het werk te stellen om
                                te voorkomen dat vertrouwelijke informatie van en over TalentZ ter
                                kennis of in handen van derden komt. Een en ander geldt niet indien
                                er een wettelijke verplichting tot openbaarmaking bestaat en/of
                                indien Gebruikers aantonen dat bepaalde informatie reeds
                                publiekelijk bekend is, anders dan door schending van deze
                                geheimhoudingsverplichting.
                            </li>
                            <li>
                                De Ondernemer draagt er zorg voor, dat een gelijke
                                geheimhoudingsplicht als in dit artikel omschreven wordt
                                opgelegd aan zijn werknemers of door hem ingeschakelde derden,
                                die toegang hebben tot de in dit artikel bedoelde informatie.
                            </li>
                            <li>
                                In geval van overtreding van dit artikel verbeurt de Gebruiker
                                per overtreding aan TalentZ een direct en zonder ingebrekestelling
                                opeisbare boete van € 10.000,-- (tienduizend euro), alsmede een
                                boete van € 1.000,-- (duizend euro) voor elke dag of gedeelte
                                daarvan dat de overtreding voortduurt, onverminderd zijn
                                gehoudenheid tot betaling aan TalentZ van een volledige
                                schadevergoeding te dezer zake indien deze meer dan gemelde
                                boetebedragen mocht belopen.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A07">Artikel 7 - Privacy en veiligheid</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Het gebruik van het Platform kan leiden tot de verwerking van
                                persoonsgegevens in de zin van artikel 4 onder 2 van de Algemene
                                Verordening Gegevensbescherming (AVG). Deze verwerking vindt plaats
                                ten behoeve van de uitvoering van de door TalentZ aangeboden
                                diensten en aanverwante activiteiten en is in overeenstemming met
                                de geldende wettelijke voorschriften ter zake de bescherming van
                                persoonsgegevens.
                            </li>
                            <li>
                                Alle medewerkers die handelen onder het gezag van TalentZ en
                                toegang hebben tot de persoonsgegevens, zullen tevens geheimhouding
                                betrachten ten aanzien van de persoonsgegevens waarvan zij kennis
                                nemen, tenzij een wettelijk voorschrift hen tot mededeling
                                verplicht.
                            </li>
                            <li>
                                TalentZ zal zich inspannen om maatregelen te treffen ter beveiliging
                                van op de Servers opgeslagen informatie. Deze maatregelen zullen
                                passend zijn, rekening houdend met de stand van de techniek en de
                                kosten die ermee gemoeid zijn. TalentZ zal met name zorg betrachten
                                te voorkomen dat onbevoegden ongeautoriseerd toegang verschaffen
                                tot gegevens van de Gebruikers van het Platform Webapplicatie. De
                                informatie met betrekking tot deze maatregelen wordt op verzoek,
                                in beperkte en verantwoorde mate, ten kantore van TalentZ ter inzage
                                beschikbaar gesteld aan Gebruikers. De Gebruikers van het Platform
                                worden geacht gebruik te maken van de gangbare veiligheidsmiddelen
                                die redelijkerwijs op een computer geïnstalleerd behoren te zijn,
                                zoals een antivirus, anti-spam, anti-spyware, anti-malware, anti-
                                phishing en firewall oplossing, alsmede van de beveiligingsmiddelen
                                die TalentZ beschikbaar stelt.
                            </li>
                            <li>
                                Ter zake de rechten van personen van wie persoonsgegevens worden
                                verwerkt, alsmede ter zake de verplichtingen die dienaangaande op
                                TalentZ rusten, verwijst TalentZ naar haar Privacy Statement zoals
                                gepubliceerd op haar website:&nbsp;
                                <LinkStyle href="https://www.talentz.nl">
                                    www.talentz.nl
                                </LinkStyle>.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A08">Artikel 8 - Duur en beëindiging</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Gebruikers hebben het recht om op elk gewenst moment het gebruik van
                                het Platform te stoppen en een Account (inclusief de eventueel aan
                                het Account gekoppelde Baanvacatures en/of Stageopdrachten) te
                                verwijderen. In het geval TalentZ reeds tot uitvoering van een
                                Overeenkomst is overgegaan, dan zal er ten gevolge van een eventuele
                                stopzetting van het gebruik van het Platform en/of verwijdering van
                                een Account geen restitutieplicht op TalentZ komen te rusten en zal
                                TalentZ derhalve niet gehouden zijn kosten ten aanzien van de
                                plaatsing van één of meerdere Baanvacatures te crediteren aan de
                                Gebruiker die gebruikmaking van het Platform stopzet en/of zijn
                                Account verwijdert.
                            </li>
                            <li>
                                TalentZ is te allen tijde - zonder opgave van redenen en zonder
                                voorafgaande uitleg - gerechtigd om:
                                <ol type="i" style={{ marginLeft: '40px' }}>
                                    <li>
                                        de activiteiten in verband met de Dienst (tijdelijk) te
                                        beperken, op te schorten of buiten gebruik te stellen;
                                    </li>
                                    <li>
                                        Accounts van Gebruikers tijdelijk of blijvend stop te
                                        zetten en/of te verwijderen;
                                    </li>
                                    <li>
                                        de toegang tot het Platform te beëindigen en/of te
                                        weigeren, in het bijzonder - maar niet daartoe beperkt
                                        - indien Gebruikers handelen in strijd met deze voorwaarden
                                        en/of de TalentZ van mening is dat het handelen van
                                        Gebruikers schade of aansprakelijkheid aan andere
                                        Gebruikers, derden of aan het Platform en/of TalentZ
                                        kunnen toebrengen. TalentZ is hiervoor op geen enkele wijze
                                        aansprakelijk of schadeplichtig jegens de Gebruiker tegen
                                        wie de maatregel wordt opgelegd.
                                    </li>
                                </ol>
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A09">Artikel 9 - Aansprakelijkheid en schade</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                TalentZ aanvaardt geen enkele aansprakelijkheid voor schade
                                voortvloeiende uit of verband houdende met het gebruik van het
                                Platform en/of aanverwante dienstverlening of de onmogelijkheid om
                                het Platform en/of aanverwante dienstverlening te gebruiken, tenzij
                                de schade het directe gevolg is van een toerekenbare tekortkoming of
                                een onrechtmatige daad van TalentZ. In dat geval is TalentZ enkel en
                                alleen aansprakelijk voor zover deze aansprakelijkheid door de
                                verzekering van TalentZ wordt gedekt, en wel tot maximaal het bedrag
                                van de door de verzekeraar gedane uitkering.
                            </li>
                            <li>
                                Indien de verzekeraar van TalentZ om welke reden dan ook niet
                                overgaat tot uitkering, dan wel de schade onverhoopt niet door de
                                verzekering is gedekt, is de aansprakelijkheid in het voorkomende
                                geval beperkt tot maximaal € 5.000,--. TalentZ is niet verplicht
                                tot verdere vergoeding van schade en kosten, hoe dan ook genaamd
                                en van welke aard dan ook, bedrijfsschade (waaronder bijvoorbeeld
                                stagnatieschade en gederfde winst), immateriële schade of andere
                                gevolgschade van de Ondernemer daarbij inbegrepen.
                            </li>
                            <li>
                                TalentZ is voorts niet aansprakelijk in geval van overmacht, zoals
                                omschreven in artikel 12 van deze voorwaarden.
                            </li>
                            <li>
                                De in dit artikel opgenomen beperkingen gelden niet indien de schade
                                het gevolg is van opzet of grove schuld van TalentZ.
                            </li>
                            <li>
                                ndien het functioneren van het Platform wegens een bepaald handelen
                                van Gebruikers in gevaar wordt gebracht, is TalentZ gerechtigd om
                                zonder nadere aankondiging of waarschuwing de toegang tot het
                                Platform te blokkeren.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A10">Artikel 10 - Overmacht</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Omstandigheden buiten de wil en toedoen van TalentZ om, al dan niet
                                ten tijde van het sluiten van de Overeenkomst voorzienbaar, welke
                                van dien aard zijn dat naleving van de Overeenkomst redelijkerwijs
                                niet langer van TalentZ kan worden verlangd, gelden als overmacht,
                                ongeacht blijvend of tijdelijk, en bevrijden TalentZ van de
                                verplichtingen tot nakoming.
                            </li>
                            <li>
                                Onder overmacht wordt in ieder geval verstaan: oorlog, onlusten,
                                natuurrampen, stormschade, brand, aardbevingen, overstromingen,
                                abnormale weersomstandigheden, sneeuw, sneeuwval, vorst, ijsgang,
                                werkstakingen, uitsluiting van of gebrek aan personeel, gebreken aan
                                hulp- en transportmiddelen, problemen op zee, verkeersbelemmeringen,
                                diefstal van goederen, storingen in elektriciteitsvoorzieningen,
                                internetverbindingen en kabel-, telefoon- of andere
                                communicatienetwerken zoals e-mail, malfuncties op apparaten
                                (pc’s, tablets etc.) van Gebruikers, wanprestatie van derden die
                                door TalentZ zijn ingeschakeld, evenals alle belemmeringen,
                                veroorzaakt door maatregelen van overheidswege.
                            </li>
                            <li>
                                Ingeval van overmacht heeft TalentZ het recht om zonder
                                rechterlijke tussenkomst de Overeenkomst geheel of gedeeltelijk
                                te ontbinden, zonder dat de Ondernemer aanspraak kan maken op
                                schadevergoeding.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A11">Artikel 11 - Rechten van intellectuele eigendom</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Alle intellectuele eigendomsrechten met betrekking tot het
                                Platform en/of aanverwante diensten behoren uitsluitend toe
                                aan TalentZ en/of haar licentiegevers, met uitzondering van
                                het materiaal dat door de Gebruikers is aangeleverd ten behoeve
                                van het gebruik van het Platform, waaronder het materiaal dat
                                door Gebruikers in de Accounts is aangeleverd, alsmede is
                                aangeleverd door de plaatsing van Advertenties, of anderszins.
                            </li>
                            <li>
                                Anders dan voor het gebruik van de functionaliteiten van het
                                Platform is vereist, is het de Gebruikers niet toegestaan de
                                intellectuele eigendomsrechten van TalentZ te gebruiken, laat
                                staan deze aan te passen, te verwijderen en/of wijzigen. Voor
                                het geval TalentZ aan Gebruikers toestemming geeft om haar
                                intellectuele eigendomsrechten te gebruiken, geldt te allen
                                tijde dat deze er voor zorg dient te dragen dat er geen schade
                                wordt toegebracht aan het onderscheidend vermogen, de reputatie,
                                de geldigheid en/of de goodwill van deze intellectuele
                                eigendomsrechten van TalentZ. Het is Gebruikers voorts niet
                                toegestaan merken te registeren die gelijk zijn aan of
                                overeenstemmen met de merken van TalentZ, waardoor verwarring
                                kan ontstaan bij het publiek of dat het publiek wordt misleid.
                            </li>
                            <li>
                                Het is gebruikers niet toegestaan het Platform of enige inhoud
                                daarvan, zonder schriftelijke toestemming van TalentZ, op welke
                                wijze dan ook aan derden ter beschikking te stellen, te
                                reproduceren, verspreiden, verzenden, of op welke wijze dan ook
                                in een ander document of ander materiaal te verwerken. Dit houdt
                                onder meer in dat het Gebruikers niet is toegestaan, zonder
                                voorafgaande schriftelijke toestemming van TalentZ, een substantieel
                                gedeelte van de inhoud van (een) databank(en) op te vragen en te
                                hergebruiken en/of niet-substantiële gedeelten van de inhoud hiervan
                                herhaald en systematisch op te vragen en te hergebruiken in de zin
                                van artikel 1 sub d van de Databankenwet.
                            </li>
                            <li>
                                In geval van overtreding van dit artikel verbeurt de Gebruiker een
                                direct opeisbare boete van € 10.000,-- (tienduizend euro) alsmede
                                een boete van € 1.000,-- (duizend euro) voor elke dag of een deel
                                van een dag dat de overtreding voortduurt, onverminderd zijn
                                verplichting tot volledige betaling van de door TalentZ geleden
                                schade indien deze schade meer bedraagt dan voormelde boetes.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A12">Artikel 12 - Verkoopmateriaal</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Promotiemateriaal dat wordt verstrekt door TalentZ aan Gebruikers,
                                waaronder maar niet beperkt tot displays, standaarden, brochures,
                                monsters, samples, demonstratiemodellen etc., mogen alleen door de
                                Ondernemer worden gebruikt ten behoeve van de (weder)verkoop en
                                promotie van TalentZ producten en/of diensten. Voornoemd
                                promotiemateriaal blijft eigendom van TalentZ. In het geval dat
                                een Ondernemer staakt met het aanschaffen van TalentZ producten
                                en/of diensten en zich zal wenden tot een concurrerende merk of
                                concurrerende partij, heeft TalentZ het recht om het
                                promotiemateriaal te revindiceren van de Ondernemer. De Ondernemer
                                verplicht zich alsdan tot het onmiddellijk teruggeven van het
                                promotiemateriaal. TalentZ is bovendien gerechtigd tot het
                                terugnemen van het promotiemateriaal indien de Ondernemer
                                failliet wordt verklaard, zijn betalingsverplichting niet nakomt,
                                de Wet schuldsanering Natuurlijke Personen (WSNP) van toepassing
                                is verklaard op de Ondernemer en/of er beslag is gelegd op
                                roerende en/of onroerende zaken van de Ondernemer. In voornoemde
                                gevallen is de Ondernemer aldus op eerste verzoek verplicht en
                                gehouden tot teruggave van al het verstrekte promotiemateriaal
                                aan TalentZ.
                            </li>
                            <li>
                                De Ondernemer zal, bij voorbaat, zijn volledige medewerking geven
                                aan TalentZ tot het herstellen van promotiemateriaal.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A13">Artikel 13 - Toepasselijk recht en bevoegde rechter</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Op deze algemene voorwaarden, alle Overeenkomsten tussen TalentZ
                                en Ondernemers, ieder gebruik van het Platform en alle daarmee
                                verband houdende geschillen (op welke grond dan ook) is uitsluitend
                                Nederlands recht van toepassing.
                            </li>
                            <li>
                                Alle geschillen, voortvloeiend uit of verband houdend met deze
                                algemene voorwaarden, de Overeenkomst en/of het gebruik van het
                                Platform, op welke grond dan ook gebaseerd, worden exclusief
                                voorgelegd aan de daartoe bevoegde rechter te Noord-Holland,
                                locatie Alkmaar.
                            </li>
                        </Paragraph>

                        <Heading as="h2" id="A14">Artikel 14 - Wijziging en uitleg van de voorwaarden</Heading>
                        <Paragraph mb="xl" ml="xl" as="ol">
                            <li>
                                Deze algemene voorwaarden kunnen worden gewijzigd en aangepast.
                                Eventuele toekomstige aanpassingen en wijzigingen gelden ook ten
                                aanzien van Overeenkomsten die voor de datum van wijziging en
                                aanpassing tot stand zijn gekomen, tenzij schriftelijk uitdrukkelijk
                                anders is overeengekomen.
                            </li>
                            <li>
                                De wijzigingen en aanpassingen treden in werking 21 dagen na
                                bekendmaking, tenzij anders is vermeld bij bekendmaking.
                            </li>
                        </Paragraph>
                    </Card>
                </Col>
                <Laptop>
                    <Col width={['100%', '100%', '100%', '100%', 4 / 12]}>
                        <StickyComponent>
                            <Card>
                                {TermsAndConditionsMenu}
                            </Card>
                        </StickyComponent>
                    </Col>
                </Laptop>
            </Row>
        </Container>
    </Page>
);

export default TermsAndConditionsPage;
