import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import {
    JobExperience,
    JobExperienceResource,
    transformToJobExperience,
    transformToJobExperienceRequest,
} from '../models/JobExperience';

export const postJobExperienceApiCall = async (data: JobExperience, candidateUuid: string): Promise<FetchResult<JobExperience, string>> => {
    try {
        const jobExperienceRequest = transformToJobExperienceRequest(data, candidateUuid);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/job-experiences`, {
            method: 'POST',
            body: JSON.stringify(jobExperienceRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<JobExperienceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const jobExperience = transformToJobExperience(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: jobExperience,
        };
    } catch (error) {
        console.error('[postJobExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchJobExperienceApiCall = async (data: JobExperience, candidateUuid: string): Promise<FetchResult<JobExperience, string>> => {
    try {
        const jobExperienceRequest = transformToJobExperienceRequest(data, candidateUuid);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/job-experiences/${data.id}`, {
            method: 'PATCH',
            body: JSON.stringify(jobExperienceRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<JobExperienceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const jobExperience = transformToJobExperience(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: jobExperience,
        };
    } catch (error) {
        console.error('[patchJobExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteJobExperienceApiCall = async (data: JobExperience): Promise<FetchResult<true, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/job-experiences/${data.id}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteJobExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
