import { getAbsoluteDifferenceInDays, getLocaleISOString } from '../helpers/date';
import { trimToMaxLength } from '../helpers/string';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { PlaceInternshipVacancyFormValues, PlaceJobVacancyFormValues } from '../services/PlaceVacancyService';
import { MinMaxValue } from '../types';

export interface PublishInternshipVacancyRequestBody extends Omit<Resource, 'id'> {
    type: 'vacancies';
    id?: string;
    attributes: {
        _type: 'internship';
        title: string;
        sector: string;
        description: string;
        summary: string;
        publicationDays: number;
        publicationDate: string;
        periodFrom: string;
        periodTill: string;
        minCompensation: number;
        maxCompensation: number;
        applyAnonymous: boolean;
    };
    relationships: {
        address: SingleRelationship<'address'>;
        educationLevel: MultipleRelationship<'educationLevel'>;
        grades: MultipleRelationship<'grades'>;
        fieldOfStudies: MultipleRelationship<'fieldOfStudies'>;
        internshipType: SingleRelationship<'internshipType'>;
        remoteWorkingOptions: SingleRelationship<'remote-working'>;
        skills: MultipleRelationship<'skills'>;
        skillsToLearn: MultipleRelationship<'skillsToLearn'>;
        competencies: MultipleRelationship<'competencies'>;
        competenciesToLearn: MultipleRelationship<'competenciesToLearn'>;
    };
}

export interface PublishJobVacancyRequestBody extends Omit<Resource, 'id'> {
    type: 'vacancies';
    id?: string;
    attributes: {
        _type: 'job';
        title: string;
        sector: string;
        description: string;
        summary: string;
        publicationDays: number;
        publicationDate: string;
        minGrossSalary: number;
        maxGrossSalary: number;
        grossHourlyWageInCents: MinMaxValue;
        employmentHourRange: MinMaxValue;
        applyAnonymous: boolean;
    };
    relationships: {
        address: SingleRelationship<'address'>;
        educationLevel: MultipleRelationship<'educationLevel'>;
        fieldOfStudies: MultipleRelationship<'fieldOfStudies'>;
        jobType: SingleRelationship<'jobType'>;
        remoteWorkingOptions: SingleRelationship<'remote-working'>;
        escoOccupations: MultipleRelationship<'escoOccupations'>;
        skills: MultipleRelationship<'skills'>;
        escoSkills: MultipleRelationship<'esco-skills'>;
        competencies: MultipleRelationship<'competencies'>;
    };
}

export interface ArchiveVacancyRequestBody extends Resource {
    type: 'vacancies';
    id: string;
    attributes: {
        applicationUuids: string[];
    };
}

export interface MyVacanciesOverviewParams {
    pageSize: number;
    pageNumber: number;
    isArchive: boolean;
}

export interface MyVacanciesOverviewFilterParams {
    'page[number]': number;
    'page[size]': number;
    'filter[archived]'?: boolean;
}

export const transformToMyVacanciesOverviewFilterParams = (
    myVacancyParams: MyVacanciesOverviewParams,
): MyVacanciesOverviewFilterParams => ({
    'page[number]': myVacancyParams.pageNumber,
    'page[size]': myVacancyParams.pageSize,
    'filter[archived]': myVacancyParams.isArchive || undefined,
});

export const transformToPublishInternshipVacancyRequestBody = (vacancyValues: PlaceInternshipVacancyFormValues, addressUuid: string): PublishInternshipVacancyRequestBody => {
    const publicationDate = vacancyValues.publicationDate || new Date();
    const expirationDate = vacancyValues.expirationDate || new Date();
    const amountOfPublicationDays = getAbsoluteDifferenceInDays(publicationDate, expirationDate);

    const periodFrom = vacancyValues.periodFrom || new Date();
    const periodTill = vacancyValues.periodTill || new Date();

    const educationLevelIds = [vacancyValues.educationLevelId];

    const summaryContent = trimToMaxLength(vacancyValues.description);

    return {
        type: 'vacancies',
        attributes: {
            _type: 'internship',
            title: vacancyValues.title,
            sector: vacancyValues.sectorId,
            description: vacancyValues.description,
            summary: summaryContent,
            publicationDate: getLocaleISOString(publicationDate),
            publicationDays: amountOfPublicationDays,
            periodFrom: getLocaleISOString(periodFrom),
            periodTill: getLocaleISOString(periodTill),
            minCompensation: vacancyValues.minCompensation || 0,
            maxCompensation: vacancyValues.maxCompensation || 0,
            applyAnonymous: vacancyValues.applyAnonymous,
        },
        relationships: {
            address: {
                data: {
                    type: 'address',
                    id: addressUuid,
                },
            },
            educationLevel: {
                data: educationLevelIds.map(educationLevelId => ({
                    type: 'educationLevel',
                    id: educationLevelId,
                })),
            },
            grades: {
                data: vacancyValues.gradeIds.map(gradeId => ({
                    type: 'grades',
                    id: gradeId,
                })),
            },
            fieldOfStudies: {
                data: vacancyValues.fieldsOfStudyIds.map(fieldOfStudyId => ({
                    type: 'fieldOfStudies',
                    id: fieldOfStudyId,
                })),
            },
            internshipType: {
                data: {
                    type: 'internshipType',
                    id: vacancyValues.internshipTypeId,
                },
            },
            remoteWorkingOptions: {
                data: {
                    type: 'remote-working',
                    id: vacancyValues.workRemotePreferenceId,
                },
            },
            skills: {
                data: vacancyValues.skillIds.map(skillId => ({
                    type: 'skills',
                    id: skillId,
                })),
            },
            skillsToLearn: {
                data: vacancyValues.skillsToLearnIds.map(skillToLearnId => ({
                    type: 'skillsToLearn',
                    id: skillToLearnId,
                })),
            },
            competencies: {
                data: vacancyValues.competencyIds.map(competencyId => ({
                    type: 'competencies',
                    id: competencyId,
                })),
            },
            competenciesToLearn: {
                data: vacancyValues.competenciesToLearnIds.map(competenciesToLearnId => ({
                    type: 'competenciesToLearn',
                    id: competenciesToLearnId,
                })),
            },
        },
    };
};

export const transformToPublishJobVacancyRequestBody = (vacancyValues: PlaceJobVacancyFormValues, addressUuid: string): PublishJobVacancyRequestBody => {
    const publicationDate = vacancyValues.publicationDate || new Date();
    const expirationDate = vacancyValues.expirationDate || new Date();
    const amountOfPublicationDays = getAbsoluteDifferenceInDays(publicationDate, expirationDate);

    const summaryContent = trimToMaxLength(vacancyValues.description);

    return {
        type: 'vacancies',
        attributes: {
            _type: 'job',
            title: vacancyValues.title,
            sector: vacancyValues.sectorId,
            description: vacancyValues.description,
            summary: summaryContent,
            publicationDate: getLocaleISOString(publicationDate),
            publicationDays: amountOfPublicationDays,
            minGrossSalary: vacancyValues.minMonthSalary || 0,
            maxGrossSalary: vacancyValues.maxMonthSalary || 0,
            grossHourlyWageInCents: {
                min: vacancyValues.minHourSalary
                    ? vacancyValues.minHourSalary * 100
                    : 0,
                max: vacancyValues.maxHourSalary
                    ? vacancyValues.maxHourSalary * 100
                    : 0,
            },
            employmentHourRange: vacancyValues.employmentHourRange,
            applyAnonymous: vacancyValues.applyAnonymous,
        },
        relationships: {
            address: {
                data: {
                    type: 'address',
                    id: addressUuid,
                },
            },
            educationLevel: {
                data: vacancyValues.educationLevelIds.map(educationLevelId => ({
                    type: 'educationLevel',
                    id: educationLevelId,
                })),
            },
            fieldOfStudies: {
                data: vacancyValues.fieldsOfStudyIds.map(fieldOfStudyId => ({
                    type: 'fieldOfStudies',
                    id: fieldOfStudyId,
                })),
            },
            jobType: {
                data: {
                    type: 'jobType',
                    id: vacancyValues.jobTypeId,
                },
            },
            remoteWorkingOptions: {
                data: {
                    type: 'remote-working',
                    id: vacancyValues.workRemotePreferenceId,
                },
            },
            escoOccupations: {
                data: vacancyValues.escoOccupationIds.map(escoOccupationId => ({
                    type: 'escoOccupations',
                    id: escoOccupationId,
                })),
            },
            skills: {
                data: vacancyValues.skillIds.map(skillId => ({
                    type: 'skills',
                    id: skillId,
                })),
            },
            escoSkills: {
                data: vacancyValues.escoSkillIds.map(skillId => ({
                    type: 'esco-skills',
                    id: skillId,
                })),
            },
            competencies: {
                data: vacancyValues.competencyIds.map(competencyId => ({
                    type: 'competencies',
                    id: competencyId,
                })),
            },
        },
    };
};

export const transformToArchiveVacancyRequestBody = (vacancyUuid: string, applicationUuids: string[]): ArchiveVacancyRequestBody => ({
    type: 'vacancies',
    id: vacancyUuid,
    attributes: {
        applicationUuids,
    },
});
