import React, { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './VacancyApplicantsNullState.scss';

interface VacancyApplicantsNullStateProps {
    vacancyUuid?: string;
    className?: string;
}

const VacancyApplicantsNullState: FC<VacancyApplicantsNullStateProps> = ({
    vacancyUuid,
    className = '',
}): ReactElement => (
    <div className={`vacancy-applicants-null-state ${className}`}>
        <h2 className="vacancy-applicants-null-state__title">
            {trans('containers.vacancyApplicants.nullState.title')}
        </h2>

        <p className="vacancy-applicants-null-state__description">
            {trans('containers.vacancyApplicants.nullState.description')}
        </p>

        {vacancyUuid && (
            <LinkIconButton
                icon="arrow-right"
                iconPos="right"
                to={RoutePaths.companyOverviewMatches(vacancyUuid)}
                text={trans('containers.vacancyApplicants.nullState.button.matches')}
                className="vacancy-applicants-null-state__button"
            />
        )}
    </div>
);

export default VacancyApplicantsNullState;
