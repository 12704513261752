import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { NewestVacancy } from '../../models/NewestVacancy';
import { AsyncReduxState } from '../../types';

export type RecentVacanciesState = AsyncReduxState<{
    internshipVacancies: NewestVacancy[];
    jobVacancies: NewestVacancy[];
    totalVacancies: number;
}>;

const initialState: RecentVacanciesState = {
    ...initialAsyncReduxState,
    internshipVacancies: [],
    jobVacancies: [],
    totalVacancies: 0,
};

export const recentVacanciesSlice = createSlice({
    name: 'recentVacanciesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): RecentVacanciesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): RecentVacanciesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setInternshipVacancies(state, action: PayloadAction<NewestVacancy[]>): RecentVacanciesState {
            return {
                ...state,
                internshipVacancies: action.payload,
            };
        },
        setJobVacancies(state, action: PayloadAction<NewestVacancy[]>): RecentVacanciesState {
            return {
                ...state,
                jobVacancies: action.payload,
            };
        },
        setTotalVacancies(state, action: PayloadAction<number>): RecentVacanciesState {
            return {
                ...state,
                totalVacancies: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setInternshipVacancies,
    setJobVacancies,
    setTotalVacancies,
} = recentVacanciesSlice.actions;

export default recentVacanciesSlice;
