import { sortByStartDate } from '../../helpers/sort';
import { trans } from '../../helpers/trans';
import { Candidate } from '../../models/Candidate';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { JobExperience } from '../../models/JobExperience';
import { SchoolExperience } from '../../models/NewSchoolExperience';
import { Toast } from '../../models/Toast';
import { getCandidateApiCall, patchCandidateApiCall } from '../../services/CandidateService';
import { deleteJobExperienceApiCall, patchJobExperienceApiCall, postJobExperienceApiCall } from '../../services/JobExperienceService';
import { deleteSchoolExperienceApiCall, patchSchoolExperienceApiCall, postSchoolExperienceApiCall } from '../../services/SchoolExperienceService';
import { AtLeast } from '../../types';
import { ReducerGetter, TypedDispatch } from '..';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import {
    setCandidate,
    setError,
    setIsLoading,
    setIsSuccessful,
} from './candidate';

export const clearCandidate = () => (dispatch: TypedDispatch): void => {
    dispatch(setCandidate(undefined));
};

export const clearCandidateSuccess = () => (dispatch: TypedDispatch): void => {
    dispatch(setIsSuccessful(false));
};

const showSavedChangesToast = (dispatch: TypedDispatch, toast?: Partial<Toast>): void => {
    dispatch(addPositiveToast({
        ...toast,
        title: toast?.title || trans('basic.toast.success'),
        description: toast?.description || trans('candidateProfile.toast.savedChanges.success'),
    }));
};

const showErrorToast = (dispatch: TypedDispatch): void => {
    dispatch(addNegativeToast({
        title: trans('errors.unknownError'),
    }));
};

export const fetchCandidate = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getCandidateApiCall(candidateUuid);

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);
            return;
        }

        dispatch(setCandidate(response.data));
    } catch (error) {
        console.error('[fetchCandidate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const patchCandidate = (candidate: AtLeast<Candidate, 'uuid'>, toast?: Partial<Toast>) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await patchCandidateApiCall(candidate);

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        dispatch(setCandidate(response.data));

        showSavedChangesToast(dispatch, toast);
    } catch (error) {
        console.error('[patchCandidate]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addCandidateSchoolExperience = (schoolExperience: SchoolExperience) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const { candidateReducer } = getState();
        const { candidate } = candidateReducer;

        const response = await postSchoolExperienceApiCall(schoolExperience, candidate?.uuid || '');

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        if (candidate) {
            const schoolExperiences = [...candidate.schoolExperiences, schoolExperience]
                .sort(sortByStartDate)
                .reverse();

            dispatch(setCandidate({ ...candidate, schoolExperiences }));
            dispatch(setIsSuccessful(true));

            showSavedChangesToast(dispatch);
        }
    } catch (error) {
        console.error('[addCandidateSchoolExperience]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editCandidateSchoolExperience = (schoolExperience: SchoolExperience) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const { candidateReducer } = getState();
        const { candidate } = candidateReducer;

        const response = await patchSchoolExperienceApiCall(schoolExperience, candidateReducer?.candidate?.uuid || '');

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        if (candidate) {
            const schoolExperiences = candidate.schoolExperiences
                .map(experience => (experience.id === schoolExperience.id ? schoolExperience : experience))
                .sort(sortByStartDate)
                .reverse();

            dispatch(setCandidate({ ...candidate, schoolExperiences }));

            showSavedChangesToast(dispatch);
        }
    } catch (error) {
        console.error('[editCandidateSchoolExperience]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteCandidateSchoolExperience = (schoolExperience: SchoolExperience) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const { candidateReducer } = getState();
        const { candidate } = candidateReducer;

        const response = await deleteSchoolExperienceApiCall(schoolExperience);

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        if (candidate) {
            const schoolExperiences = candidate.schoolExperiences
                .filter(experience => experience.id !== schoolExperience.id)
                .sort(sortByStartDate)
                .reverse();

            dispatch(setCandidate({ ...candidate, schoolExperiences }));

            showSavedChangesToast(dispatch);
        }
    } catch (error) {
        console.error('[deleteCandidateSchoolExperience]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addCandidateJobExperience = (jobExperience: JobExperience) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const { candidateReducer } = getState();
        const { candidate } = candidateReducer;

        const response = await postJobExperienceApiCall(jobExperience, candidate?.uuid || '');

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        if (candidate) {
            const jobExperiences = [...candidate.jobExperiences, jobExperience]
                .sort(sortByStartDate)
                .reverse();

            dispatch(setCandidate({ ...candidate, jobExperiences }));
            dispatch(setIsSuccessful(true));

            showSavedChangesToast(dispatch);
        }
    } catch (error) {
        console.error('[addCandidateJobExperience]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editCandidateJobExperience = (jobExperience: JobExperience) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const { candidateReducer } = getState();
        const { candidate } = candidateReducer;

        const response = await patchJobExperienceApiCall(jobExperience, candidateReducer?.candidate?.uuid || '');

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        if (candidate) {
            const jobExperiences = candidate.jobExperiences
                .map(experience => (experience.id === jobExperience.id ? jobExperience : experience))
                .sort(sortByStartDate)
                .reverse();

            dispatch(setCandidate({ ...candidate, jobExperiences }));

            showSavedChangesToast(dispatch);
        }
    } catch (error) {
        console.error('[editCandidateJobExperience]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteCandidateJobExperience = (jobExperience: JobExperience) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const { candidateReducer } = getState();
        const { candidate } = candidateReducer;

        const response = await deleteJobExperienceApiCall(jobExperience);

        if (!isFetchResultSuccessful(response)) {
            console.error(response.error);
            dispatch(setError(trans('errors.unknownError')));
            showErrorToast(dispatch);

            return;
        }

        if (candidate) {
            const jobExperiences = candidate.jobExperiences
                .filter(experience => experience.id !== jobExperience.id)
                .sort(sortByStartDate)
                .reverse();

            dispatch(setCandidate({ ...candidate, jobExperiences }));

            showSavedChangesToast(dispatch);
        }
    } catch (error) {
        console.error('[deleteCandidateJobExperience]', error);
        showErrorToast(dispatch);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const patchFavourites = (candidateUuid: string, favourites: VacanciesInfo[]) => (dispatch: TypedDispatch): void => {
    dispatch(patchCandidate({
        uuid: candidateUuid,
        favourites,
    }, {
        title: trans('favourites.toast.title'),
        description: trans('favourites.toast.description'),
    }));
};
