import React, {
    CSSProperties,
    FC,
    ReactElement,
    RefObject,
} from 'react';

import { Card } from '../../../components';
import { getGradientColor } from '../../../helpers/color';
import { ProgressCallToAction, ProgressStepIndicator } from './subcomponents';

import './ProfileProgress.scss';

export enum ProfileStepId {
    about = 'about',
    avatar = 'avatar',
    catchphrase = 'catchphrase',
    competencies = 'competencies',
    competenciesToLearn = 'competencies-to-learn',
    coreValues = 'core-values',
    coverImage = 'cover-image',
    driversLicenses = 'drivers-licenses',
    employmentPreference = 'employment-preference',
    funFacts = 'fun-facts',
    jobExperience = 'job-experience',
    schoolExperience = 'school-experience',
    sectors = 'sectors',
    skills = 'skills',
    skillsToLearn = 'skills-to-learn',
    workRemote = 'work-remote',
    youTube = 'youtube',
}

export type ActivationRef = RefObject<HTMLButtonElement>;

export interface ProfileStep {
    order: number;
    isCompleted: boolean;
    stepId: ProfileStepId;
    activationRef?: ActivationRef;
    title: string;
    description: string;
    callToAction: string;
}

interface ProfileProgressProps {
    isRandom?: boolean;
    title: string;
    steps: ProfileStep[];
    finishedTitle: string;
    finishedDescription: string;
    onCallToActionClick: (stepId: ProfileStepId) => void;
    onFinishClick: () => void;
    className?: string;
}

const ProfileProgress: FC<ProfileProgressProps> = ({
    isRandom,
    title,
    steps,
    finishedTitle,
    finishedDescription,
    onCallToActionClick,
    onFinishClick,
    className = '',
}): ReactElement => {
    const prefilledStepAmount = 3;
    const amountOfSteps = prefilledStepAmount + steps.length;
    const amountOfCompletedSteps = prefilledStepAmount + steps.filter(step => step.isCompleted).length;

    const progressPercentage = (amountOfCompletedSteps / amountOfSteps);
    const isSatisfactory = progressPercentage >= 0.75;

    const incompleteSteps = steps.filter(step => !step.isCompleted);
    const randomIndex = Math.floor(Math.random() * incompleteSteps.length);

    const currentStep = isRandom
        ? incompleteSteps[randomIndex]
        : incompleteSteps[0];

    const negativeColor = '#ff7b01';
    const positiveColor = '#0aca6e';

    const cssVariables = {
        '--c-progress-theme': getGradientColor(negativeColor, positiveColor, progressPercentage),
    } as CSSProperties;

    return (
        <Card className={`profile-progress ${className}`}>
            <div style={cssVariables}>
                <h2 className="profile-progress__title">
                    {title}
                </h2>

                <ProgressStepIndicator
                    amountOfSteps={amountOfSteps}
                    amountOfCompletedSteps={amountOfCompletedSteps}
                    className="profile-progress__step-indicator"
                />

                <ProgressCallToAction
                    isSatisfactory={isSatisfactory}
                    isCompleted={steps.every(step => step.isCompleted)}
                    currentStep={currentStep}
                    finishedTitle={finishedTitle}
                    finishedDescription={finishedDescription}
                    onCallToActionClick={onCallToActionClick}
                    onFinishClick={onFinishClick}
                    className="profile-progress__call-to-action"
                />
            </div>
        </Card>
    );
};

export default ProfileProgress;
