import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';

import CompanyDataForm from '../../_old/app_talentz/app/components/forms/CompanyDataForm';
import EmployeeDataForm from '../../_old/app_talentz/app/components/forms/EmployeeDataForm';
import EmployeeSettingsForm from '../../_old/app_talentz/app/components/forms/EmployeeSettingsForm';
import { Company, CompanySettings } from '../../_old/app_talentz/models/Company';
import { Employee } from '../../_old/app_talentz/models/Employee';
import { Person } from '../../_old/app_talentz/models/Person';
import { UpdatePasswordParams } from '../../_old/app_talentz/models/ResetPassword';
import {
    DeleteAccountParams,
    UpdateCompanyParams,
    UpdateEmployeeParams,
    UpdatePersonSettingsParams,
} from '../../_old/app_talentz/models/SettingsPage';
import { User } from '../../_old/app_talentz/models/User';
import StickyComponent from '../../_old/components/StickyComponent';
import TabsSidebar from '../../_old/components/TabsSidebar';
import { TabsSideBarChild } from '../../_old/components/TabsSidebar/models';
import Card from '../../_old/styledComponents/components/Card';
import Container from '../../_old/styledComponents/components/Container';
import { Col, Row } from '../../_old/styledComponents/components/Grid';
import { Laptop, MaxLaptop } from '../../_old/styledComponents/components/MediaQueries';
import { Tab } from '../../components';
import { trans } from '../../helpers/trans';
import { EmailAddressChange, EmailAddressChangeFormData } from '../../models/EmailAddress';
import { RouteParams } from '../../routes';
import { CompanySettingsTab } from '../../types/pageTabs';
import { AccountSettings } from '..';
import { getEmployeeTabs } from './helpers';

interface SettingsCompanyContainerParams extends RouteParams {
    tab?: CompanySettingsTab;
}

interface SettingsCompanyContainerProps {
    company: Company | null;
    employee: Employee | null;
    isLoading: boolean;
    accountSettingsIsLoading: boolean;
    resetPasswordIsLoading: boolean;
    deleteIsLoading: boolean;
    person: Person | null;
    settings: CompanySettings | null;
    user: User | null;
    emailAddressChange?: EmailAddressChange;
    companyErrors: Record<string, unknown>;
    employeeErrors: Record<string, unknown>;
    personErrors: Record<string, unknown>;
    profileErrors: Record<string, unknown>;
    resetPasswordErrors: Record<string, unknown>;
    settingsErrors: Record<string, unknown>;
    deleteErrors: Record<string, string[]>;
    checkForWebPushSubscription: () => void;
    onResetPassword: (resetPasswordFormData: UpdatePasswordParams) => void;
    onChangeEmailAddress: (emailAddressChangeFormData: EmailAddressChangeFormData) => void;
    onDeleteAccount: (deleteAccountFormData: DeleteAccountParams) => void;
    updateCompany: (data: UpdateCompanyParams) => void;
    updateEmployee: (data: UpdateEmployeeParams) => void;
    updatePersonSettings: (data: UpdatePersonSettingsParams) => void;
}

const SettingsCompanyContainer: FC<SettingsCompanyContainerProps> = ({
    company,
    employee,
    isLoading = false,
    accountSettingsIsLoading,
    resetPasswordIsLoading,
    deleteIsLoading,
    person,
    settings,
    user,
    emailAddressChange,
    companyErrors,
    employeeErrors,
    personErrors,
    profileErrors,
    resetPasswordErrors,
    deleteErrors,
    checkForWebPushSubscription,
    onResetPassword,
    onChangeEmailAddress,
    onDeleteAccount,
    updateCompany,
    updateEmployee,
    updatePersonSettings,
}): ReactElement => {
    const { tab = '' } = useParams<SettingsCompanyContainerParams>();

    const [tabs, setTabs] = useState<TabsSideBarChild[]>([]);
    const location = useLocation();

    useEffect((): void => {
        setTabs(getEmployeeTabs(location, company, settings));
    }, [location, company, settings]);

    return (
        <Container centered maxWidth="1064px">
            <MaxLaptop>
                <Row>
                    <Col width={1} mb="md">
                        <TabsSidebar
                            title={trans('navigation.userProfile.settings')}
                            tabs={tabs}
                        />
                    </Col>
                </Row>
            </MaxLaptop>
            <Row>
                <Laptop>
                    <Col width={[1, 1, 1, 1, 4 / 12]}>
                        <StickyComponent>
                            <TabsSidebar
                                title={trans('navigation.userProfile.settings')}
                                tabs={tabs}
                            />
                        </StickyComponent>
                    </Col>
                </Laptop>
                <Col width={[1, 1, 1, 1, 8 / 12]} pl={[0, 0, 0, 0, 'sm']}>
                    <Tab isActive={tab === CompanySettingsTab.myCompany}>
                        <Card>
                            <CompanyDataForm
                                errors={{
                                    ...profileErrors,
                                    ...companyErrors,
                                }}
                                company={company}
                                onSubmit={updateCompany}
                                loading={isLoading}
                            />
                        </Card>
                    </Tab>
                    <Tab isActive={tab === CompanySettingsTab.personalData}>
                        <Card>
                            <EmployeeDataForm
                                errors={{
                                    ...personErrors,
                                    ...employeeErrors,
                                    ...profileErrors,
                                }}
                                person={person}
                                employee={employee}
                                onSubmit={updateEmployee}
                                loading={isLoading}
                            />
                        </Card>
                    </Tab>
                    <Tab isActive={tab === CompanySettingsTab.account}>
                        <AccountSettings
                            isLoading={accountSettingsIsLoading}
                            user={user || undefined}
                            emailAddressChange={emailAddressChange}
                            resetPasswordIsLoading={resetPasswordIsLoading}
                            deleteAccountIsLoading={deleteIsLoading}
                            connectedProviders={user?.connection_providers || []}
                            resetPasswordErrors={resetPasswordErrors}
                            deleteAccountErrors={deleteErrors}
                            onResetPassword={onResetPassword}
                            onChangeEmailAddress={onChangeEmailAddress}
                            onDeleteAccount={onDeleteAccount}
                        />
                    </Tab>
                    <Tab isActive={tab === CompanySettingsTab.notificationsAndPrivacy}>
                        <Card>
                            <EmployeeSettingsForm
                                isLoading={isLoading}
                                onSubmit={updatePersonSettings}
                                checkForWebPushSubscription={checkForWebPushSubscription}
                                settings={settings}
                            />
                        </Card>
                    </Tab>
                </Col>
            </Row>
        </Container>
    );
};

export default SettingsCompanyContainer;
