import React, {
    CSSProperties,
    PropsWithChildren,
    ReactElement,
    RefObject,
    useEffect,
    useState,
} from 'react';

import { useWindowSize } from 'react-use';
import { useScrollYPosition } from 'react-use-scroll-position';

import { clamp } from '../../helpers/number';

import './ScrollProgress.scss';

interface ScrollProgressProps<Element> {
    refElement: RefObject<Element>;
    className?: string;
}

const ScrollProgress = <Element extends HTMLElement>({
    refElement,
    className = '',
}: PropsWithChildren<ScrollProgressProps<Element>>): ReactElement => {
    const { height: pageHeight } = useWindowSize();
    const scrollY = useScrollYPosition();

    const [refSize, setRefSize] = useState<number>(0);
    const [refStart, setRefStart] = useState<number>(0);
    const [refEnd, setRefEnd] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);

    useEffect((): void => {
        if (refElement.current) {
            const { height, top, bottom } = refElement.current.getBoundingClientRect();

            setRefSize(height);
            setRefStart(top);
            setRefEnd(bottom);
        }
    }, [refElement]);

    useEffect((): void => {
        if (refSize && refStart && refEnd) {
            const currentPageCenter = scrollY + (pageHeight / 2);
            const clampedScrollPosition = clamp(currentPageCenter, refStart, refEnd);
            const scrollPositionWithinRef = clampedScrollPosition - refStart;
            const roundedPercentage = Math.round((scrollPositionWithinRef / refSize) * 100);

            setPercentage(roundedPercentage);
        }
    }, [refSize, refStart, refEnd, scrollY, pageHeight]);

    const cssVariables = {
        '--scroll-progress-percentage': `${percentage}%`,
    } as CSSProperties;

    return (
        <div
            style={cssVariables}
            className={`scroll-progress ${className}`}
        />
    );
};

export default ScrollProgress;
