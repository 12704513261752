import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { CompanyVacancies } from '../../../containers';
import { trans } from '../../../helpers/trans';
import { VacanciesInfo } from '../../../models/info/VacanciesInfo';
import { RoleType } from '../../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { patchFavourites } from '../../../store/candidate/candidateActions';
import { clearCompany, fetchCompany } from '../../../store/company/companyActions';
import { setActiveFilterValues } from '../../../store/companyVacancyOverview/companyVacancyOverview';
import { clearCompanyVacancies, fetchCompanyVacancyOverview } from '../../../store/companyVacancyOverview/companyVacancyOverviewActions';

interface ConnectedCompanyVacanciesProps {
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyVacancies: FC<ConnectedCompanyVacanciesProps> = ({
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);
    const role = useLegacySelector(state => state.legacyUser.role);

    const company = useTypedSelector(state => state.companyReducer.company);

    const isLoading = useTypedSelector(state => state.companyVacancyOverviewReducer.isLoading);
    const error = useTypedSelector(state => state.companyVacancyOverviewReducer.error);
    const vacancies = useTypedSelector(state => state.companyVacancyOverviewReducer.vacancies);
    const filterValues = useTypedSelector(state => state.companyVacancyOverviewReducer.activeFilterValues);
    const pagination = useTypedSelector(state => state.companyVacancyOverviewReducer.pagination);

    const favouritesIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const [favouriteIds, setFavouriteIds] = useState<string[]>([]);

    const userRole = role || RoleType.none;

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
            if (shouldFetchCompany) dispatch(clearCompanyVacancies());
        };
    }, [dispatch, companyUuid]);

    useEffect((): void => {
        if (company) {
            dispatch(fetchCompanyVacancyOverview(company.uuid));
        }
    }, [dispatch, company, filterValues]);

    useEffect((): void => {
        if (candidate) {
            setFavouriteIds(candidate.favourites.map(favourite => favourite.uuid));
        }
    }, [candidate]);

    const handlePaginationChange = (pageNumber: number) => {
        dispatch(setActiveFilterValues({
            ...filterValues,
            pageNumber,
        }));
    };

    const handleFavouriteClick = (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean): void => {
        if (candidate) {
            const newFavourites = shouldAddFavourite
                ? [...candidate.favourites, favouriteVacancy]
                : candidate.favourites.filter(favourite => favourite.uuid !== favouriteVacancy.uuid);

            dispatch(patchFavourites(candidate.uuid, newFavourites));
        }
    };

    return company ? (
        <CompanyVacancies
            isLoading={isLoading}
            userRole={userRole}
            title={trans('companyProfile.vacancyOverview.title', { company: company.name })}
            vacancies={vacancies}
            pagination={pagination}
            error={error}
            favouriteIsLoading={favouritesIsLoading}
            favouriteIds={favouriteIds}
            onFavouriteVacancy={handleFavouriteClick}
            onPaginationChange={handlePaginationChange}
            className={className}
        />
    ) : null;
};

export default ConnectedCompanyVacancies;
