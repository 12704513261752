import React, { FC, ReactElement } from 'react';

import { Button, Logo } from '../../../../components';
import { ButtonProps } from '../../../../components/@buttons/Button/Button';
import { trans } from '../../../../helpers/trans';
import { TalentMatchingQuote } from '..';

import './TalentMatchingCallToAction.scss';

interface TalentMatchingCallToActionProps extends ButtonProps {
    showTalentMatchingQuote?: boolean;
}

const TalentMatchingCallToAction: FC<TalentMatchingCallToActionProps> = ({
    showTalentMatchingQuote,
    text,
    className = '',
    ...buttonProps
}): ReactElement => (
    <div className={`talent-matching-call-to-action ${className}`}>
        <Button
            {...buttonProps}
            text={text}
            className="talent-matching-call-to-action__button"
        >
            <Logo name="talentz-compact" className="talent-matching-call-to-action__icon" />

            <span className="talent-matching-call-to-action__label">
                {text}
            </span>
        </Button>

        {showTalentMatchingQuote && (
            <TalentMatchingQuote
                quote={trans('vacancies.overview.talentMatch.quote')}
                className="talent-matching-call-to-action__quote"
            />
        )}
    </div>
);

export default TalentMatchingCallToAction;
