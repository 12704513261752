import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton, SafeHtml, Skeleton } from '../../../components';
import {
    DetailCard,
    IconButton,
    Modal,
    ModalContent,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { FunFactsFormData } from '../../../models/FunFacts';
import { FunFactsForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';

import './FunFactsCard.scss';

interface FunFactsCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable?: boolean;
    userFromCompany: boolean;
    funFacts?: string;
    onEdit?: (funFactsFormData: FunFactsFormData) => void;
    className?: string;
}

const FunFactsCard: FC<FunFactsCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    userFromCompany,
    funFacts = '',
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditFunFacts = (funFactsFormData: FunFactsFormData): void => {
        if (onEdit) {
            onEdit(funFactsFormData);
        }

        closeEditModal();
    };

    const handleDeleteFunFacts = (): void => {
        if (onEdit) {
            onEdit({ funFacts: '' });
        }
    };

    const nullState = userFromCompany
        ? trans('companyProfile.funFacts.nullState.company')
        : trans('companyProfile.funFacts.nullState.candidate');

    return (
        <>
            <DetailCard
                isLoading={isLoading}
                isEditable={isEditable && funFacts.length > 0}
                title={trans('companyProfile.funFacts.title')}
                onEditClick={openEditModal}
                onDeleteClick={handleDeleteFunFacts}
                className={`fun-facts-card ${className}`}
            >
                <ProgressRefButton ref={activationRef} onClick={openEditModal} />

                {isLoading && (
                    <div className="fun-facts-card__skeleton-wrapper">
                        <Skeleton className="fun-facts-card__skeleton" />
                        <Skeleton className="fun-facts-card__skeleton" />
                        <Skeleton className="fun-facts-card__skeleton" />
                        <Skeleton className="fun-facts-card__skeleton" />
                    </div>
                )}

                {!isLoading && funFacts.length === 0 && (
                    <div className="fun-facts-card__null-state">
                        <p>{nullState}</p>

                        {isEditable && (
                            <IconButton
                                icon="plus"
                                text={trans('actions.add')}
                                disabled={isLoading}
                                onClick={openEditModal}
                                className="fun-facts-card__null-state-button"
                            />
                        )}
                    </div>
                )}

                {!isLoading && funFacts.length > 0 && (
                    <SafeHtml
                        html={funFacts}
                        className="fun-facts-card__fun-facts"
                    />
                )}
            </DetailCard>

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('companyProfile.funFacts.modal.title')}>
                        <FunFactsForm
                            funFacts={funFacts}
                            onCancel={closeEditModal}
                            onSubmit={handleEditFunFacts}
                        />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default FunFactsCard;
