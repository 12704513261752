import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import Card from '../../components/Card/Card';
import { trans } from '../../helpers/trans';
import { SubmitButton } from '..';

import './SbbInfoCard.scss';

interface SbbInfoCardProps {
    isLoading: boolean;
    isUpdated?: boolean;
    userFromCompany?: boolean;
    sbbNumber?: string;
    classification: string;
    onUpdateSbbInfo?: () => void;
    className?: string;
}

const SbbInfoCard: FC<SbbInfoCardProps> = ({
    isLoading,
    isUpdated,
    userFromCompany,
    sbbNumber,
    classification,
    onUpdateSbbInfo,
    className = '',
}): ReactElement => {
    const sbbInfoCardClassNames = classNames('sbb-info-card', {
        'sbb-info-card--user-is-company': userFromCompany,
    }, className);

    return (
        <Card className={sbbInfoCardClassNames}>
            <div className="sbb-info-card__top-section">
                <div className="sbb-info-card__text-wrapper">
                    <h3 className="sbb-info-card__title">
                        {trans('userProfile.sbb.number.title')}
                    </h3>

                    {userFromCompany && (
                        <p className="sbb-info-card__description">
                            {sbbNumber ? trans('userProfile.sbb.number.intro') : trans('userProfile.sbb.number.notFound')}
                        </p>
                    )}
                </div>

                {userFromCompany && (
                    <SubmitButton
                        isLoading={isLoading}
                        icon={isUpdated ? 'check' : 'refresh'}
                        text={isUpdated ? trans('actions.updated') : trans('actions.update')}
                        disabled={isUpdated}
                        onClick={onUpdateSbbInfo}
                        className="sbb-info-card__update-button"
                    />
                )}
            </div>

            {sbbNumber && (
                <ul className="sbb-info-card__list">
                    <li className="sbb-info-card__list-item">
                        <span className="sbb-info-card__list-item-label">
                            {trans('userProfile.sbb.number.label')}:
                        </span>
                        <span className="sbb-info-card__list-item-value">
                            {sbbNumber}
                        </span>
                    </li>
                    <li className="sbb-info-card__list-item">
                        <span className="sbb-info-card__list-item-label">
                            {trans('userProfile.sbb.classification')}:
                        </span>
                        <span className="sbb-info-card__list-item-value">
                            {classification}
                        </span>
                    </li>
                </ul>
            )}
        </Card>
    );
};

export default SbbInfoCard;
