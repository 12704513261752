import React, { FC, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import { Skeleton } from '../../../components';
import { DetailCard, Tag } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Company } from '../../../models/Company';
import { RoutePaths } from '../../../routes';
import { CompanySettingsTab } from '../../../types/pageTabs';

import './CompanyInformationCard.scss';

interface CompanyInformationCardProps {
    isLoading: boolean;
    isEditable?: boolean;
    company?: Company;
    className?: string;
}

const CompanyInformationCard: FC<CompanyInformationCardProps> = ({
    isLoading,
    isEditable,
    company,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const handleEditClick = (): void => navigate(RoutePaths.companySettings(CompanySettingsTab.myCompany));

    return (
        <DetailCard
            isLoading={isLoading}
            isEditable={isEditable}
            title={trans('companyProfile.information.title')}
            onEditClick={handleEditClick}
            className={`company-information-card ${className}`}
        >
            {isLoading && (
                <div className="company-information-card__skeleton-wrapper">
                    <Skeleton className="company-information-card__skeleton" />
                    <Skeleton className="company-information-card__skeleton" />
                </div>
            )}

            {!isLoading && company?.amountOfEmployees.label && (
                <div className="company-information-card__item">
                    <h3 className="company-information-card__item-title">
                        {trans('companyProfile.information.amountOfEmployees')}
                    </h3>
                    <ul className="company-information-card__tag-list">
                        <li className="company-information-card__tag-item">
                            <Tag text={company.amountOfEmployees.label} />
                        </li>
                    </ul>
                </div>
            )}

            {!isLoading && company?.website && (
                <div className="company-information-card__item">
                    <h3 className="company-information-card__item-title">
                        {trans('companyProfile.information.website')}
                    </h3>
                    <ul className="company-information-card__tag-list">
                        <li className="company-information-card__tag-item">
                            <a
                                href={company.website}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="company-information-card__tag-link"
                            >
                                {company.website}
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </DetailCard>
    );
};

export default CompanyInformationCard;
