import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { LinkButton } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './VacancyOverviewNullState.scss';

interface VacancyOverviewNullStateProps {
    overviewType?: VacancyType;
    className?: string;
}

const VacancyOverviewNullState: FC<VacancyOverviewNullStateProps> = ({
    overviewType,
    className = '',
}): ReactElement => {
    const button = overviewType === VacancyType.internship ? {
        route: RoutePaths.resetInternships(),
        label: trans('vacancies.nullState.button.internships'),
    } : {
        route: RoutePaths.resetJobs(),
        label: trans('vacancies.nullState.button.jobs'),
    };

    return (
        <div className={`vacancy-overview-null-state ${className}`}>
            {overviewType ? (
                <div>
                    <p className="vacancy-overview-null-state__paragraph">
                        {trans(`vacancies.nullState.${overviewType}s`)}
                    </p>
                    <p className="vacancy-overview-null-state__paragraph">
                        {trans('vacancies.nullState.adjustYourPreferences')}
                    </p>

                    <LinkButton
                        to={button.route}
                        text={button.label}
                        className="vacancy-overview-null-state__button"
                    />
                </div>
            ) : (
                <p className="vacancy-overview-null-state__paragraph">
                    {trans('vacancies.nullState.vacancies')}
                </p>
            )}
        </div>
    );
};

export default VacancyOverviewNullState;
