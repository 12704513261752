import React, { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';

import { trans } from '../../helpers/trans';
import { Chat } from '../../models/Chat';
import { ChatMessage } from '../../models/ChatMessages';
import { ChatReport, ChatReportFormData, UndoChatReportFormData } from '../../models/ChatReport';
import { ChatRoom, ChatSelector } from './subcomponents';

import './MessageOverview.scss';

interface MessageOverviewProps {
    isLoading: boolean;
    messagesIsLoading: boolean;
    chatReportsIsLoading: boolean;
    isReconnecting: boolean;
    isCandidate: boolean;
    userUuid: string;
    chats: Chat[];
    activeChat?: Chat;
    chatMessages: ChatMessage[];
    chatReports: ChatReport[];
    onSelectChat: (chat: Chat) => void;
    onSendMessage: (message: string) => void;
    onReportChat: (chatUuid: string, chatReportFormData: ChatReportFormData) => void;
    onUndoChatReport: (undoChatReportFormData: UndoChatReportFormData) => void;
    className?: string;
}

const MessageOverview: FC<MessageOverviewProps> = ({
    isLoading,
    messagesIsLoading,
    chatReportsIsLoading,
    isReconnecting,
    isCandidate,
    userUuid,
    chats,
    activeChat,
    chatMessages,
    chatReports,
    onSelectChat,
    onSendMessage,
    onReportChat,
    onUndoChatReport,
    className = '',
}): ReactElement => {
    const [chatIsOpen, setChatIsOpen] = useState<boolean>(false);

    const closeChat = (): void => setChatIsOpen(false);

    const handleSelectChat = (chat: Chat): void => {
        setChatIsOpen(true);
        onSelectChat(chat);
    };

    const messageOverviewWrapperClassNames = classNames('message-overview__wrapper', {
        'message-overview__wrapper--chat-is-open': chatIsOpen,
    });

    return (
        <section className={`message-overview ${className}`}>
            <header className="message-overview__header">
                <h1 className="message-overview__title">
                    {trans('containers.messageOverview.title')}
                </h1>
            </header>

            <div className="message-overview__box">
                <div className={messageOverviewWrapperClassNames}>
                    <ChatSelector
                        isLoading={isLoading}
                        isCandidate={isCandidate}
                        chats={chats}
                        activeChat={activeChat}
                        onSelectChat={handleSelectChat}
                        className="message-overview__chat-list"
                    />

                    {activeChat && (
                        <ChatRoom
                            isLoading={messagesIsLoading}
                            chatReportsIsLoading={chatReportsIsLoading}
                            isReconnecting={isReconnecting}
                            isCandidate={isCandidate}
                            userUuid={userUuid}
                            activeChat={activeChat}
                            chatMessages={chatMessages}
                            chatReports={chatReports}
                            onChatSelectorClick={closeChat}
                            onSendMessage={onSendMessage}
                            onReportChat={onReportChat}
                            onUndoChatReport={onUndoChatReport}
                            className="message-overview__chat-room"
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default MessageOverview;
