import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../components';
import { MessageState } from '../../types';

import './Tag.scss';

interface TagProps {
    isActive?: boolean;
    status?: MessageState;
    icon?: string;
    text: string;
    onClick?: () => void;
    className?: string;
}

const Tag: FC<TagProps> = ({
    isActive,
    status,
    icon,
    text,
    onClick,
    className = '',
}): ReactElement => {
    const tagClassNames = classNames('tag', {
        'tag--is-interactive': onClick,
        'tag--is-active': isActive,
        'tag--is-positive': status === MessageState.positive,
        'tag--is-warning': status === MessageState.warning,
        'tag--is-negative': status === MessageState.negative,
    }, className);

    return onClick ? (
        <Button
            text={text}
            onClick={onClick}
            className={tagClassNames}
        >
            {text}
            <div className="tag__icon-wrapper">
                <Icon
                    name={isActive ? 'cross' : 'plus'}
                    className="tag__icon"
                />
            </div>
        </Button>
    ) : (
        <span className={tagClassNames}>
            {icon && <Icon name={icon} className="tag__icon" />}
            {text}
        </span>
    );
};

export default Tag;
