import React, { FC, ReactElement } from 'react';

import { Pagination as PaginationModel } from '../../../../_old/app_talentz/models/Pagination';
import { ErrorLabel } from '../../../../components';
import { CandidateCard } from '../../../../compositions';
import { VacancyMatchingCandidate } from '../../../../models/VacancyMatches';
import { Pagination } from '../../..';
import { VacancyMatchesOverviewSkeletons } from '../../skeletons';

import './VacancyMatchesOverview.scss';

interface VacancyMatchesOverviewProps {
    isLoading: boolean;
    mayViewLLO?: boolean;
    vacancyUuid?: string;
    error: string;
    candidates: VacancyMatchingCandidate[];
    pagination?: PaginationModel;
    className?: string;
}

const VacancyMatchesOverview: FC<VacancyMatchesOverviewProps> = ({
    isLoading,
    mayViewLLO,
    vacancyUuid,
    error,
    candidates,
    pagination,
    className = '',
}): ReactElement => (
    <div className={`vacancy-matches-overview ${className}`}>
        {isLoading && (
            <VacancyMatchesOverviewSkeletons
                amount={6}
                className="vacancy-matches-overview__candidate-list"
            />
        )}

        {!isLoading && error && (
            <ErrorLabel
                text={error}
                className="vacancy-matches-overview__error"
            />
        )}

        {!isLoading && candidates.length > 0 && vacancyUuid && (
            <section>
                <ul className="vacancy-matches-overview__candidate-list">
                    {candidates.map(candidate => (
                        <li key={candidate.uuid} className="vacancy-matches-overview__candidate-item">
                            <CandidateCard
                                mayViewLLO={mayViewLLO}
                                vacancyUuid={vacancyUuid}
                                candidate={candidate}
                                className="vacancy-matches-overview__candidate"
                            />
                        </li>
                    ))}
                </ul>

                {!error && pagination && (
                    <Pagination
                        amountOfPages={pagination.totalPages}
                        currentPage={pagination.currentPage}
                        baseHref="?page="
                        className="vacancy-matches-overview__pagination"
                    />
                )}
            </section>
        )}
    </div>
);

export default VacancyMatchesOverview;
