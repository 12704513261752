// @ts-ignore
import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';

export default styled('div')((props: any) => css`
    border-top: 1px solid ${props.theme.colors[props.color || 'grey']};
    
    ${props.vertical && css`
        border-top: none;
        width: 1px;
        background-color: ${props.theme.colors[props.color || 'grey']};
    `}
    
    ${space}
    ${width}
`);
