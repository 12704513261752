import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import TextEditor from '../../../_old/components/form/TextEditor';
import { FileDropZone, FormActionButtons } from '../../../compositions';
import { formatDate } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { ApplicationFormData } from '../../../models/Applications';
import { Candidate } from '../../../models/Candidate';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { FormProps } from '../../../types';
import { ApplicationFormErrors, validateApplicationFormData } from './validations';

import './ApplicationForm.scss';

interface ApplicationFormProps extends FormProps<ApplicationFormData> {
    candidate: Candidate;
    vacancy: NewestVacancy;
    className?: string;
}

const ApplicationForm: FC<ApplicationFormProps> = ({
    isLoading,
    candidate,
    vacancy,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<ApplicationFormErrors>({});

    const [bodyText, setBodyText] = useState<string>('');
    const [attachments, setAttachments] = useState<File[]>([]);

    const formRef = useRef<HTMLFormElement>(null);

    const validationOptions = {
        maxFileAmount: 20,
        maxFileSize: 5 * (10 ** 6), // 5MB
        acceptedFileTypes: [
            'application/pdf',
            'application/x-pdf',
            'application/x-bzpdf',
            'application/x-gzpdf',
        ],
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            vacancyUuid: vacancy.uuid,
            bodyText,
            attachments,
        };

        const [localErrors, hasErrors] = validateApplicationFormData(formData, validationOptions);

        setFormErrors(localErrors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`application-form ${className}`}>
            <h1 className="application-form__title">
                {trans('forms.apply.title', {
                    title: vacancy.title,
                })}
            </h1>

            <h2 className="application-form__subtitle">
                {trans('vacancies.atCompany', {
                    company: vacancy.company.name,
                    city: vacancy.address.city || vacancy.company.city,
                })}
            </h2>

            <div className="application-form__salutation-wrapper">
                <p className="application-form__salutation">
                    {trans('basic.application.salutation')}
                </p>

                <p className="application-form__date">
                    {formatDate(new Date(), 'dd MMMM yyyy')}
                </p>
            </div>

            <div className="application-form__input-wrapper">
                <TextEditor
                    name="body-text"
                    placeholder={trans('forms.apply.placeholder.body')}
                    defaultValue={bodyText}
                    errors={formErrors.bodyText ? [formErrors.bodyText] : undefined}
                    onChange={setBodyText}
                />
            </div>

            <div className="application-form__greeting-wrapper">
                <p className="application-form__greeting">
                    {trans('basic.application.valediction')}
                </p>
                <p className="application-form__author">
                    {vacancy.isAnonymous ? trans('forms.apply.anonymousCandidate') : candidate.fullName}
                </p>
            </div>

            <div className="application-form__attachments-wrapper">
                <FileDropZone
                    {...validationOptions}
                    label={trans('forms.apply.documents')}
                    value={attachments}
                    error={formErrors.attachments}
                    onChange={setAttachments}
                />
            </div>

            <FormActionButtons
                isLoading={isLoading}
                submitIcon="check"
                submitText={trans('forms.apply.button.submit')}
                onCancelClick={onCancel}
                className="application-form__action-buttons"
            />
        </form>
    );
};

export default ApplicationForm;
