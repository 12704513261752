import React, { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Button, Icon, Skeleton } from '../../../../components';
import { MenuItem } from '../../../../connectors/ConnectedMenu/helpers';
import { MenuListTemplate } from '../MenuList/MenuList';

import './MenuListItem.scss';

interface MenuListItemProps extends MenuItem {
    isLoading?: boolean;
    template: MenuListTemplate;
    disabled?: boolean;
    className?: string;
}

const MenuListItem: FC<MenuListItemProps> = ({
    isLoading,
    template,
    isTextual,
    isImportant,
    icon,
    themeColor,
    label,
    to,
    counter,
    disabled,
    tabIndex,
    onClick,
    className = '',
    ...navLinkProps
}): ReactElement => {
    const menuListItemClassNames = classNames('menu-list-item', {
        'menu-list-item--template-regular': template === MenuListTemplate.regular,
        'menu-list-item--template-information': template === MenuListTemplate.information,
        'menu-list-item--template-buttons': template === MenuListTemplate.buttons,
        'menu-list-item--template-drawer': template === MenuListTemplate.drawer,
        'menu-list-item--template-session': template === MenuListTemplate.session,
        'menu-list-item--template-icon': template === MenuListTemplate.icon,
        'menu-list-item--is-textual': isTextual,
        'menu-list-item--is-important': isImportant,
    }, className);

    const getNavLinkClassName = (props: { isActive: boolean }): string => classNames('menu-list-item__link', {
        'menu-list-item__link--is-active': props.isActive,
        'menu-list-item__link--is-disabled': disabled,
    });

    const cssVariables = {
        '--c-menu-item': themeColor,
    } as CSSProperties;

    const renderItemContent = (): ReactElement => (
        <>
            {icon && (
                <div className="menu-list-item__icon-wrapper">
                    <Icon name={icon} className="menu-list-item__icon" />
                </div>
            )}

            {isLoading ? (
                <Skeleton className="menu-list-item__skeleton" />
            ) : (
                <div className="menu-list-item__label">
                    {label}
                </div>
            )}

            {counter ? (
                <span className="menu-list-item__counter">
                    {counter}
                </span>
            ) : null}
        </>
    );

    return (
        <li style={cssVariables} className={menuListItemClassNames}>
            {to ? (
                <NavLink
                    {...navLinkProps}
                    to={to}
                    tabIndex={disabled ? -1 : tabIndex}
                    className={getNavLinkClassName}
                >
                    {renderItemContent()}
                </NavLink>
            ) : (
                <Button
                    text={label}
                    disabled={disabled}
                    tabIndex={tabIndex}
                    onClick={onClick}
                    className="menu-list-item__button"
                >
                    {renderItemContent()}
                </Button>
            )}
        </li>
    );
};

export default MenuListItem;
