import styled, { css } from 'styled-components';
import {
    alignItems,
    display,
    flexDirection,
    height,
    justifyContent,
    maxWidth,
    minHeight,
    position,
    space,
    width,
} from 'styled-system';

export default styled('div')(props => css`
    position: relative;
    background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.white};
    box-shadow: ${props.theme.shadow()};
    border: ${props.borderLess ? 'none' : '1px solid rgba(207,216,220,0.2)'};
    min-width: 0;
    
    ${!props.noPadding && css`
        padding: ${props.theme.space.lg} ${props.theme.space.md};
    `}
    
    @media ${({ theme }) => theme.mediaQueries.tablet} {
        ${!props.noPadding && css`
            padding: ${props.theme.space[props.size || 'xl']} ${props.theme.space[props.size || 'xl']};
        `}
        
        ${props.small && css`
             padding: ${props.theme.space.lg} ${props.theme.space.lg};
        `}
        ${space}
    }
    
    ${props.hoverAnimation && css`
        transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateY(0px) scale(1);
        
        &:hover {
            transform: translateY(-3px) scale(1.007);
            box-shadow: ${props.theme.shadow('hoverSm')};
        }
    `}
    
    ${props.onClick && css`
        cursor: pointer;
    `}
    
    ${display}
    ${flexDirection}
    ${alignItems}
    ${justifyContent}
    ${minHeight}
    ${height}
    ${width}
    ${maxWidth}
    ${space}
    ${position}
`);
