import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

export const Tabs = styled('ul')`
    list-style: none;
    padding: 0;
    margin: 0;
    ${space};
`;

export const TabsItem = styled('li')`
    padding: 0;
    margin: 0;
    ${space};
`;

export const TabsLink = styled(NavLink)(props => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${props.theme.colors.text};
    padding: ${props.theme.space.sm} 0;
    border-bottom: 1px solid ${props.theme.colors.grey};
    transition:  color 0.15s ease-out;
    
    & svg {
        fill: ${props.theme.colors.primary};
        transition: fill 0.15s ease-out;
    }
    
    &:hover, &.active {
        color: ${props.theme.colors.primary};
        
        & svg {
            fill: ${props.theme.colors.primary};
        }
    }
    
    ${props.disabled && css`
        color: ${props.theme.colors.muted};
        
        & svg {
            fill: ${props.theme.colors.muted};
        }
        
        &:hover, &.active {
            color: ${props.theme.colors.muted};
            
            & svg {
                fill: ${props.theme.colors.muted};
            }
        }
    `}
    
    ${space};
`);
