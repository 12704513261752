import styled, { css } from 'styled-components';

export const Label = styled('label')(props => css`
    display: block;
    ${props.theme.fonts.bold};
    color: ${props.theme.colors.text};
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`);

export const Holder = styled('div')`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadow()};
`;

export const Text = styled('textarea')(props => css`
    ${props.theme.fonts.regular};
    color: ${props.theme.colors.text};
    line-height: 1.3em;
    border: none;
    padding: ${props.theme.space.sm}; 
    border: ${props.hasError ? '2px' : '1px'} solid ${props.hasError ? props.theme.colors.alert : props.theme.colors.grey};
    font-size: 1em;
    width: 100%;
    min-height: 200px;
    padding-right: ${(props.icon ? '40px' : '15px')};
    transition: border 0.35s ease-out;

    &::placeholder {
        color: ${props.theme.colors.muted};
    }
    
    &:focus {
        outline: none;
        border-color: ${props.theme.colors.primary};
        box-shadow: none;
    }
`);

export const Error = styled('p')`
    color: ${props => props.theme.colors.alert};
    font-size: 12px;
    line-height: 1; 
    overflow: hidden; 
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: height 0.15s ease-out,
                padding 0.15s ease-out;
    
    ${props => props.errors && `
        height: 22px;
        padding-top: 5px;
        padding-bottom: 5px;
    `}
`;

export const Requirement = styled('p')`
    color: ${props => props.theme.colors.text};
    height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    text-align: right;
    line-height: 1; 
    overflow: hidden; 
`;

export const OptionalLabel = styled.span`
    float: right;
    color: ${props => props.theme.colors.muted};
    font-weight: normal;
    font-size: 12px;
`;
