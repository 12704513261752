import { FC } from 'react';

import { useRoutes } from 'react-router-dom';

import { RoleType } from '../models/User';
import { blogRoutePaths, blogRoutes } from './categories/blogRoutes';
import { candidateRoutePaths, candidateRoutes } from './categories/candidateRoutes';
import { companyRoutePaths, companyRoutes } from './categories/companyRoutes';
import { errorRoutePaths, errorRoutes } from './categories/errorRoutes';
import { generalRoutePaths, generalRoutes } from './categories/generalRoutes';
import { infoRoutePaths, infoRoutes } from './categories/infoRoutes';
import { internshipVacancyRoutePaths, internshipVacancyRoutes } from './categories/internshipVacancyRoutes';
import { jobVacancyRoutePaths, jobVacancyRoutes } from './categories/jobVacancyRoutes';

export type RouteParams = Record<string, string | undefined>;

export const RoutePaths = {
    ...generalRoutePaths,
    ...candidateRoutePaths,
    ...companyRoutePaths,
    ...jobVacancyRoutePaths,
    ...internshipVacancyRoutePaths,
    ...infoRoutePaths,
    ...blogRoutePaths,
    ...errorRoutePaths,
};

export const routes = [
    ...generalRoutes,
    ...internshipVacancyRoutes,
    ...jobVacancyRoutes,
    ...infoRoutes,
    ...blogRoutes,
];

interface RouteTreeProps {
    userRole: RoleType;
}

const RouteTree: FC<RouteTreeProps> = ({ userRole }) => {
    if (userRole === RoleType.student || userRole === RoleType.jobSeeker) {
        routes.push(...candidateRoutes);
    }

    if (userRole === RoleType.company) {
        routes.push(...companyRoutes);
    }

    routes.push(...errorRoutes);

    return useRoutes(routes);
};

export default RouteTree;
