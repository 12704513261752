import { Person } from '../../../_old/app_talentz/models/Person';
import { Company } from '../../../models/Company';
import { RoleType } from '../../../models/User';

export const generateProfileName = (config: {
    role: RoleType;
    person?: Person;
    company?: Company;
}): string => {
    const { role, person, company } = config;

    if (role === RoleType.none) return '';

    if (role === RoleType.company && company) {
        return person?.full_name
            ? `${company.name} (${person.full_name})`
            : company.name;
    }

    return person?.full_name || '';
};
