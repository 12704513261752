import React, { FC, ReactElement, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Page, Tab } from '../../components';
import { ConnectedMessageOverview, ConnectedMyApplications } from '../../connectors';
import { SubMenu } from '../../containers';
import { trans } from '../../helpers/trans';
import { RouteParams, RoutePaths } from '../../routes';
import { CandidateOverviewTab } from '../../types/pageTabs';

import './CandidateOverview.scss';

interface CandidateOverviewParams extends RouteParams {
    tab?: CandidateOverviewTab;
    uuid?: string;
}

const CandidateOverview: FC = (): ReactElement => {
    const { tab = CandidateOverviewTab.applications, uuid = '' } = useParams<CandidateOverviewParams>();

    const tabs = useMemo(() => ([
        {
            key: CandidateOverviewTab.applications,
            isActive: tab === CandidateOverviewTab.applications,
            to: RoutePaths.candidateOverviewTab(CandidateOverviewTab.applications),
            label: trans('pages.candidateOverview.tabs.applications'),
        },
        {
            key: CandidateOverviewTab.messages,
            isActive: tab === CandidateOverviewTab.messages,
            to: RoutePaths.candidateOverviewTab(CandidateOverviewTab.messages),
            label: trans('pages.candidateOverview.tabs.messages'),
        },
    ]), [tab]);

    return (
        <Page className="candidate-overview-page">
            <SubMenu<CandidateOverviewTab>
                tabs={tabs}
                className="candidate-overview-page__sub-menu"
            />

            <div className="candidate-overview-page__wrapper">
                <Tab isActive={tab === CandidateOverviewTab.applications}>
                    <Helmet>
                        <title>{trans('pages.candidateOverview.applications.meta.title')}</title>
                        <meta name="description" content={trans('pages.candidateOverview.applications.meta.description')} />
                    </Helmet>

                    <ConnectedMyApplications />
                </Tab>

                <Tab isActive={tab === CandidateOverviewTab.messages}>
                    <Helmet>
                        <title>{trans('pages.candidateOverview.messages.meta.title')}</title>
                        <meta name="description" content={trans('pages.candidateOverview.messages.meta.description')} />
                    </Helmet>

                    <ConnectedMessageOverview chatUuid={uuid} />
                </Tab>
            </div>
        </Page>
    );
};

export default CandidateOverview;
