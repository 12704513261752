import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './SchoolExperiencesCardSkeletons.scss';

interface SchoolExperiencesCardSkeletonsProps {
    amount?: number;
    className?: string;
}

const SchoolExperiencesCardSkeletons: FC<SchoolExperiencesCardSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`school-experiences-card-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="school-experiences-card-skeletons__item">
                    <div className="school-experiences-card-skeletons__text-wrapper">
                        <Skeleton className="school-experiences-card-skeletons__title" />
                        <Skeleton className="school-experiences-card-skeletons__dates" />
                        <Skeleton className="school-experiences-card-skeletons__description" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default SchoolExperiencesCardSkeletons;
