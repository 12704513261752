/* eslint-disable camelcase */
import { Resource } from '../japi/types/Resource';

export enum NotificationStatus {
    new = 0,
    unread = 1,
    read = 2,
}

export interface ApiNotification {
    uuid: string;
    logo?: string;
    avatar?: string;
    body: string;
    created_at: string;
    status: NotificationStatus;
    title: string;
    url: string;
}

// TODO: Convert to proper resource once the notifications call supports JSON API
export interface NotificationsResource extends Resource {
    data: ApiNotification[];
}

export interface Notification {
    id: string;
    status: NotificationStatus;
    image: string;
    title: string;
    description: string;
    url: string;
    createdAt: Date;
}

export const transformApiDataToNotification = (
    apiNotification: ApiNotification,
): Notification => {
    const logo = apiNotification.logo
        ? `${process.env.REACT_APP_API_V1_URL}/storage/logos/${apiNotification.logo}`
        : '';

    const avatar = apiNotification.avatar
        ? `${process.env.REACT_APP_API_V1_URL}/storage/avatars/${apiNotification.avatar}`
        : '';

    return {
        id: apiNotification.uuid,
        status: apiNotification.status,
        image: logo || avatar || '',
        title: apiNotification.title,
        description: apiNotification.body,
        url: apiNotification.url,
        createdAt: new Date(apiNotification.created_at),
    };
};
/* eslint-enable camelcase */
