import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';
import { Application } from '../../models/Applications';
import { ApplicationOverview } from '..';
import { MyApplicationsNullState } from './subcomponents';

import './MyApplications.scss';

interface MyApplicationsProps {
    isLoading: boolean;
    applications: Application[];
    error: string;
    className?: string;
}

const MyApplications: FC<MyApplicationsProps> = ({
    isLoading,
    applications,
    error,
    className = '',
}): ReactElement => (
    <section className={`my-applications ${className}`}>
        <header className="my-applications__header">
            <h1 className="my-applications__title">
                {trans('containers.myApplications.title')}
            </h1>
        </header>

        {!isLoading && applications.length === 0 && (
            <MyApplicationsNullState
                className="my-applications__null-state"
            />
        )}

        <ApplicationOverview
            isCandidate
            isLoading={isLoading}
            applications={applications}
            error={error}
            className="my-applications__overview"
        />
    </section>
);

export default MyApplications;
