import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Notification } from '../../models/Notifications';
import { AsyncReduxState } from '../../types';

export type NotificationsState = AsyncReduxState<{
    isExpanded: boolean;
    notifications: Notification[];
    amountOfNew: number;
}>;

const initialState: NotificationsState = {
    ...initialAsyncReduxState,
    isExpanded: false,
    notifications: [],
    amountOfNew: 0,
};

const notificationsSlice = createSlice({
    name: 'notificationsReducer',
    initialState,
    reducers: {
        setIsExpanded(state, action: PayloadAction<boolean>): NotificationsState {
            return {
                ...state,
                isExpanded: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): NotificationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NotificationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setNotifications(state, action: PayloadAction<Notification[]>): NotificationsState {
            return {
                ...state,
                notifications: action.payload,
            };
        },
        setAmountOfNew(state, action: PayloadAction<number>): NotificationsState {
            return {
                ...state,
                amountOfNew: action.payload,
            };
        },
    },
});

export const {
    setIsExpanded,
    setIsLoading,
    setError,
    setNotifications,
    setAmountOfNew,
} = notificationsSlice.actions;

export default notificationsSlice;
