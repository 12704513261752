import React, { FC, ReactElement, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import {
    ConnectedBlogContent,
    ConnectedBlogDetailHelmet,
    ConnectedRegisterModal,
    ConnectedRegistrationCallToAction,
    ConnectedRelatedBlogArticles,
} from '../../connectors';
import { RoleType } from '../../models/User';
import { RouteParams } from '../../routes';

import './BlogDetail.scss';

interface BlogDetailParams extends RouteParams {
    slug?: string;
}

const BlogDetail: FC = (): ReactElement => {
    const { slug = '' } = useParams<BlogDetailParams>();
    const [registrationModalIsOpen, setRegistrationModalIsOpen] = useState<boolean>(false);
    const [callToActionRoleType, setCallToActionRoleType] = useState<RoleType>(RoleType.none);

    const openRegistrationModal = (): void => setRegistrationModalIsOpen(true);
    const closeRegistrationModal = (): void => setRegistrationModalIsOpen(false);

    const handleCallToActionClick = (role: RoleType): void => {
        setCallToActionRoleType(role);
        openRegistrationModal();
    };

    return (
        <Page className="blog-detail">
            <ConnectedBlogDetailHelmet slug={slug} />

            <ConnectedBlogContent slug={slug} />
            {/* <ConnectedBlogComments slug={slug} /> */}
            <ConnectedRelatedBlogArticles
                slug={slug}
                className="blog-detail__related-blog-articles"
            />

            <ConnectedRegistrationCallToAction
                template={RoleType.company}
                onCallToActionClick={handleCallToActionClick}
                className="blog-detail__registration-call-to-action"
            />

            {registrationModalIsOpen && (
                <ConnectedRegisterModal
                    role={callToActionRoleType}
                    onClose={closeRegistrationModal}
                />
            )}
        </Page>
    );
};

export default BlogDetail;
