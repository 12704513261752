import React, { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

const LogoTalentzDefault: FC<SvgLogoProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 1000 260.7" className={`logo ${className}`}>
        <path d="M44.1,27H0V0h123.3v27H80.5v233.6H44.1V27z" />
        <path d="M178.4,0h35l56,260.7h-34.6L222.8,195h-53.1l-12.6,65.7h-34.1L178.4,0z M218,169L196.1,51.5L174.5,169H218z" />
        <path d="M295.5,0h36.4v234.6h66.5v26.1H295.5V0z" />
        <path d="M423.3,0h101.1v27h-64.7v86.2h52.5V139h-52.5v95.6H525v26.1H423.3V0z" />
        <path d="M554.3,0h24.8l74,173.1V0h30.3v260.7h-23.2l-75-178v178h-30.9L554.3,0z" />
        <path d="M752.8,27h-44.1V0H832v27h-42.8v233.6h-36.4V27z" />
        <polygon points="984.3,161.5 936.3,196.5 954.4,202.8 943.3,234.6 883.5,234.6 964.3,15.8 964.3,0 853.9,0 853.9,26.1 925.6,26.1 849.1,234.6 849.1,260.7 964.3,260.7 964.3,260.5 964.4,260.7 981.9,212.4 1000,218.8" />
    </svg>
);

export default LogoTalentzDefault;
