import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import ButtonFooter from '../../../../components/form/ButtonFooter';
import TextInput from '../../../../components/form/TextInput';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../styledComponents/components/Typography';

class DeleteAccountConfirmForm extends Component {
    onSubmit = e => {
        e.preventDefault();
        const data = serialize(e.currentTarget, { hash: true });
        this.props.onSubmit(data);
    };

    render() {
        const { errors, loading } = this.props;

        return (
            <form id="delete-account-confirm-form" onSubmit={this.onSubmit}>
                <Heading as="h2" size="xl">{trans('forms.user.account.delete.confirm.heading')}</Heading>
                <Paragraph>{trans('forms.user.account.delete.confirm.summary')}</Paragraph>

                <Row mt="lg">
                    <Col mb="lg" width={1}>
                        <TextInput
                            errors={errors.password}
                            type="password"
                            name="password"
                            label={trans('forms.user.account.delete.password.label')}
                            placeholder={trans('forms.user.account.delete.password.placeholder')}
                            icon="password"
                        />
                    </Col>
                </Row>

                <ButtonFooter
                    id="confirm-account-delete"
                    loading={loading}
                    onCancel={() => this.props.onCancel()}
                    saveLabel={trans('actions.delete')}
                />
            </form>
        );
    }
}

DeleteAccountConfirmForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

DeleteAccountConfirmForm.defaultProps = {
    errors: {},
};

export default DeleteAccountConfirmForm;
