import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Application } from '../../models/Applications';
import { NewestVacancy } from '../../models/NewestVacancy';
import { AsyncReduxState } from '../../types';

export type VacancyApplicationsState = AsyncReduxState<{
    activeVacancy?: NewestVacancy;
    applications: Application[];
}>;

const initialState: VacancyApplicationsState = {
    ...initialAsyncReduxState,
    activeVacancy: undefined,
    applications: [],
};

const vacancyApplicationsSlice = createSlice({
    name: 'vacancyApplicationsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacancyApplicationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacancyApplicationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveVacancy(state, action: PayloadAction<NewestVacancy | undefined>): VacancyApplicationsState {
            return {
                ...state,
                activeVacancy: action.payload,
            };
        },
        setApplications(state, action: PayloadAction<Application[]>): VacancyApplicationsState {
            return {
                ...state,
                applications: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveVacancy,
    setApplications,
} = vacancyApplicationsSlice.actions;

export default vacancyApplicationsSlice;
