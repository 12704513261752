import { Resource } from '../japi/types/Resource';

export interface GradesResource extends Resource {
    type: 'grades';
    id: string;
    attributes: {
        name: string;
        slug: string;
        createdAt: string;
        updatedAt: string;
    };
}

export interface Grade {
    id: string;
    name: string;
    slug: string;
}

export const transformToGrade = (
    gradesResource: GradesResource,
): Grade => ({
    id: gradesResource.id,
    name: gradesResource.attributes.name,
    slug: gradesResource.attributes.slug,
});
