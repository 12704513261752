import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';

import './CandidateAvatarCardSkeletons.scss';

interface CandidateAvatarCardSkeletonsProps {
    className?: string;
}

const CandidateAvatarCardSkeletons: FC<CandidateAvatarCardSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`candidate-avatar-card-skeletons ${className}`}>
        <div className="candidate-avatar-card-skeletons__avatar" />

        <div className="candidate-avatar-card-skeletons__personal-info-wrapper">
            <Skeleton className="candidate-avatar-card-skeletons__name" />
            <Skeleton className="candidate-avatar-card-skeletons__city" />
        </div>
    </div>
);

export default CandidateAvatarCardSkeletons;
