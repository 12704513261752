import { isFetchResultSuccessful } from '../../models/FetchResult';
import { defaultCompanyVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { getCompanyVacanciesApiCall } from '../../services/CompanyVacancyOverviewService';
import { ReducerGetter, TypedDispatch } from '..';
import {
    setActiveFilterValues,
    setError,
    setIsLoading,
    setPagination,
    setVacancies,
} from './companyVacancyOverview';

export const clearCompanyVacancies = () => (dispatch: TypedDispatch): void => {
    dispatch(setVacancies([]));
    dispatch(setPagination(undefined));
    dispatch(setActiveFilterValues(defaultCompanyVacancyOverviewFilterValues()));
};

export const fetchCompanyVacancyOverview = (uuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { companyVacancyOverviewReducer } = getState();
        const { activeFilterValues } = companyVacancyOverviewReducer;

        const companyVacanciesResponse = await getCompanyVacanciesApiCall({
            ...activeFilterValues,
            companyUuids: [uuid],
        });

        if (!isFetchResultSuccessful(companyVacanciesResponse)) {
            console.error('[fetchCompanyVacancyOverview]', companyVacanciesResponse.error);
            dispatch(setError(companyVacanciesResponse.error));
            dispatch(setPagination(undefined));
            dispatch(setVacancies([]));
            return;
        }

        const { pagination, vacancies } = companyVacanciesResponse.data;

        dispatch(setPagination(pagination));
        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchCompanyVacancyOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
