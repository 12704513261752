import React, { FC, ReactElement } from 'react';

import { animateScroll, Link } from 'react-scroll';

import { Icon } from '../../components';
import { trans } from '../../helpers/trans';

import './ScrollHinter.scss';

export enum ScrollDirection {
    up = 'up',
    down = 'down',
}

interface ScrollHinterProps {
    to?: string;
    direction?: ScrollDirection;
    offsetTop?: number;
    duration?: number;
    scrollToTop?: boolean;
    className?: string;
}

const ScrollHinter: FC<ScrollHinterProps> = ({
    to = '',
    direction = ScrollDirection.down,
    offsetTop = -100,
    duration = 700,
    scrollToTop = false,
    className = '',
}): ReactElement => {
    const handleScrollToTop = (): void => animateScroll.scrollToTop();
    const arrowIcon = direction === ScrollDirection.up ? 'arrow-up' : 'arrow-down';

    return scrollToTop ? (
        <button
            type="button"
            aria-label={trans('actions.scrollTo.top')}
            onClick={handleScrollToTop}
            className={`scroll-hinter ${className}`}
        >
            <div className="scroll-hinter__icon-wrapper">
                <Icon name="arrow-up" className="scroll-hinter__icon" />
            </div>

            <div className="scroll-hinter__pulse" />
        </button>
    ) : (
        <Link
            aria-label={trans('actions.scrollTo.section')}
            to={to}
            spy
            smooth
            duration={duration}
            offset={offsetTop}
            className={`scroll-hinter ${className}`}
        >
            <div className="scroll-hinter__icon-wrapper">
                <Icon name={arrowIcon} className="scroll-hinter__icon" />
            </div>

            <div className="scroll-hinter__pulse" />
        </Link>
    );
};

export default ScrollHinter;
