import React, { FC, ReactElement } from 'react';

import { LinkButton } from '../../components';
import { Loader } from '../../compositions';
import { trans } from '../../helpers/trans';
import { RoutePaths } from '../../routes';
import { StatusCode } from '../../types';

import './ErrorPageContent.scss';

interface ErrorPageContentProps {
    isLoading: boolean;
    status: StatusCode;
    className?: string;
}

const ErrorPageContent: FC<ErrorPageContentProps> = ({
    isLoading,
    status,
    className = '',
}): ReactElement => (
    <section className={`error-page-content ${className}`}>
        {isLoading ? (
            <div className="error-page-content__loader-wrapper">
                <Loader className="error-page-content__loader" />
            </div>
        ) : (
            <div className="error-page-content__wrapper">
                <h1 className="error-page-content__title">
                    {status}
                </h1>

                <p className="error-page-content__description">
                    {trans(`errorPage.page${status}.message`)}
                </p>

                <LinkButton
                    to={RoutePaths.home()}
                    text={trans('errorPage.button.label')}
                    className="error-page-content__button"
                />
            </div>
        )}
    </section>
);

export default ErrorPageContent;
