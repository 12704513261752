import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconEye: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 70" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M30,35c0-11,9-20,20-20s20,9,20,20s-9,20-20,20S30,46,30,35z M50,22.5c-6.9,0-12.5,5.6-12.5,12.5S43.1,47.5,50,47.5S62.5,41.9,62.5,35S56.9,22.5,50,22.5z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M98.9,32.8L100,35l-1.1,2.3l0,0l0,0l-0.1,0.1c-0.2,0.5-0.5,0.9-0.8,1.4c-3.1,5.4-6.9,10.3-11.3,14.8c-8,8-20.4,16.5-37,16.5C33,70,20.8,61.6,12.9,53.5c-3.3-3.4-6.3-7.1-8.8-11.1c-1-1.6-1.9-3.1-2.8-4.8c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.1l0,0v0L0,35l1.1-2.2v0l0,0l0,0l0.1-0.1c0.2-0.5,0.5-0.9,0.7-1.4c3-5.4,6.8-10.4,11.1-14.8C20.8,8.5,33,0,49.7,0c16.6,0,29,8.4,37,16.5c3.4,3.4,6.4,7.1,9,11.1c1.1,1.6,2.1,3.2,3,4.9l0.1,0.2L98.9,32.8L98.9,32.8L98.9,32.8L98.9,32.8z M12.5,37c-0.5-0.7-0.9-1.4-1.2-2c2.5-4.2,5.4-8,8.8-11.5C26.9,16.6,36.7,10,49.7,10c13,0,23,6.6,29.9,13.5c3.4,3.5,6.5,7.3,9,11.5c-2.6,4.2-5.6,8-9,11.5C72.7,53.4,62.7,60,49.7,60c-13,0-22.8-6.6-29.6-13.5C17.2,43.6,14.7,40.4,12.5,37z" />
    </svg>
);

export default IconEye;
