import React, { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { InternshipVacancyIndex } from '../../containers';
import { decodeAllObjectValues } from '../../helpers/object';
import { InternshipVacancyIndexParams } from '../../models/VacancyIndex';
import { useLegacyDispatch, useTypedSelector } from '../../store';
import { fetchEducationLevels } from '../../store/educationLevels/educationLevelsActions';
import { fetchFieldsOfStudy, fetchManualFieldsOfStudy } from '../../store/fieldsOfStudy/fieldsOfStudyActions';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { fetchInternshipVacancyIndexAggregation } from '../../store/vacancyIndex/vacancyIndexActions';

interface ConnectedInternshipVacancyIndexProps {
    className?: string;
}

const ConnectedInternshipVacancyIndex: FC<ConnectedInternshipVacancyIndexProps> = ({
    className = '',
}): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const params = useParams() as InternshipVacancyIndexParams;
    const {
        educationLevel,
        letter,
        fieldOfStudy,
        sector,
        province,
    } = decodeAllObjectValues(params);

    const vacancyIndexIsLoading = useTypedSelector(state => state.vacancyIndexReducer.isLoading);
    const activeEducationLevels = useTypedSelector(state => state.vacancyIndexReducer.activeEducationLevels);
    const activeLetters = useTypedSelector(state => state.vacancyIndexReducer.activeLetters);
    const activeFieldsOfStudy = useTypedSelector(state => state.vacancyIndexReducer.activeFieldsOfStudy);
    const activeSectors = useTypedSelector(state => state.vacancyIndexReducer.activeSectors);
    const activeProvinces = useTypedSelector(state => state.vacancyIndexReducer.activeProvinces);
    const activeCities = useTypedSelector(state => state.vacancyIndexReducer.activeCities);

    const educationLevelIsLoading = useTypedSelector(state => state.educationLevelsReducer.isLoading);
    const educationLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationLevels);

    const fieldOfStudyIsLoading = useTypedSelector(state => state.fieldsOfStudyReducer.isLoading);
    const fieldOfStudyOptions = useTypedSelector(state => state.fieldsOfStudyReducer.fieldsOfStudy);
    const manualFieldOfStudyOptions = useTypedSelector(state => state.fieldsOfStudyReducer.manualFieldsOfStudy);

    const sectorIsLoading = useTypedSelector(state => state.sectorsReducer.isLoading);
    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);

    const provinceOptions = useTypedSelector(state => state.provincesReducer.provinces);

    const allOptionsLoaded = educationLevelOptions.length
        && fieldOfStudyOptions.length
        && manualFieldOfStudyOptions.length
        && sectorOptions.length
        && provinceOptions.length;

    useEffect((): void => {
        if (educationLevelOptions.length === 0) legacyDispatch(fetchEducationLevels());
        if (fieldOfStudyOptions.length === 0) legacyDispatch(fetchFieldsOfStudy());
        if (manualFieldOfStudyOptions.length === 0) legacyDispatch(fetchManualFieldsOfStudy());
        if (sectorOptions.length === 0) legacyDispatch(fetchSectors());
    }, [legacyDispatch]);

    useEffect((): void => {
        if (allOptionsLoaded) {
            legacyDispatch(fetchInternshipVacancyIndexAggregation(params));
        }
    }, [legacyDispatch, allOptionsLoaded, params]);

    const optionsIsLoading = educationLevelIsLoading || fieldOfStudyIsLoading || sectorIsLoading;
    const isLoading = optionsIsLoading || vacancyIndexIsLoading;

    return (
        <InternshipVacancyIndex
            isLoading={isLoading}
            educationLevel={educationLevel}
            letter={letter}
            fieldOfStudy={fieldOfStudy}
            sector={sector}
            province={province}
            educationLevelOptions={activeEducationLevels}
            fieldOfStudyOptions={activeFieldsOfStudy}
            activeLetters={activeLetters}
            sectorOptions={activeSectors}
            provinceOptions={activeProvinces}
            cityOptions={activeCities}
            className={className}
        />
    );
};

export default ConnectedInternshipVacancyIndex;
