import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RoleType } from '../../../../models/User';
import { User } from '../../models/User';
import { environmentPermissionsChecker } from './legacyUserActions';

export interface LegacyUserState {
    loading: boolean;
    loadingSocialMedia: boolean;
    deleteLoading: boolean;
    deleteErrors: Record<string, string[]>;
    tokenData: any;
    authenticated: boolean;
    login: boolean;
    logoutLoading: boolean;
    data: User | null;
    role?: RoleType | null;
    permissions: string[];
    errors: Record<string, unknown>;
}

const initialState: LegacyUserState = {
    loading: false,
    loadingSocialMedia: false,
    deleteLoading: false,
    deleteErrors: {},
    tokenData: undefined,
    authenticated: false,
    login: false,
    logoutLoading: false,
    data: null,
    role: undefined,
    permissions: environmentPermissionsChecker(),
    errors: {},
};

const legacyUserSlice = createSlice({
    name: 'legacyUser',
    initialState,
    reducers: {
        resetLegacyUserState(): LegacyUserState {
            return initialState;
        },
        setLoading(state, action: PayloadAction<boolean>): LegacyUserState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setLoadingSocialMedia(state, action: PayloadAction<boolean>): LegacyUserState {
            return {
                ...state,
                loadingSocialMedia: action.payload,
            };
        },
        setDeleteLoading(state, action: PayloadAction<boolean>): LegacyUserState {
            return {
                ...state,
                deleteLoading: action.payload,
            };
        },
        setDeleteErrors(state, action: PayloadAction<Record<string, string[]>>): LegacyUserState {
            return {
                ...state,
                deleteErrors: action.payload,
            };
        },
        setTokenData(state, action: PayloadAction<any>): LegacyUserState {
            return {
                ...state,
                tokenData: action.payload,
            };
        },
        setAuthenticated(state, action: PayloadAction<boolean>): LegacyUserState {
            return {
                ...state,
                authenticated: action.payload,
            };
        },
        setLogin(state, action: PayloadAction<boolean>): LegacyUserState {
            return {
                ...state,
                login: action.payload,
            };
        },
        setLogoutLoading(state, action: PayloadAction<boolean>): LegacyUserState {
            return {
                ...state,
                logoutLoading: action.payload,
            };
        },
        setData(state, action: PayloadAction<User | null>): LegacyUserState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setRole(state, action: PayloadAction<RoleType | null>): LegacyUserState {
            return {
                ...state,
                role: action.payload,
            };
        },
        setPermissions(state, action: PayloadAction<string[]>): LegacyUserState {
            return {
                ...state,
                permissions: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): LegacyUserState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    resetLegacyUserState,
    setLoading,
    setLoadingSocialMedia,
    setDeleteLoading,
    setDeleteErrors,
    setTokenData,
    setAuthenticated,
    setLogin,
    setLogoutLoading,
    setData,
    setRole,
    setPermissions,
    setErrors,
} = legacyUserSlice.actions;

export default legacyUserSlice;
