import axios from 'axios';
import { Dispatch } from 'redux';

import { isFetchResultSuccessful } from '../../../../models/FetchResult';
import { getVacancyBySlugApiCall } from '../../../../services/VacancyService';
import { transformInternshipVacancy, transformInternshipVacancyToNewestVacancy } from '../../models/InternshipVacancy';
import { transformJobVacancy, transformJobVacancyToNewestVacancy } from '../../models/JobVacancy';
import { MatchingCandidate } from '../../models/MatchingCandidate';
import { VacancyType } from '../../models/VacancyType';
import {
    setIgnoreError,
    setInviteError,
    setLoading,
    setNewestVacancy,
    setVacancy,
} from './vacancy';

let cancelFetchVacancyToken;

/**
 * @param uuid
 * @returns {function(*): Promise<unknown>}
 */
export function fetchVacancyForCompany(uuid: string) {
    return async (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        cancelFetchVacancyToken = axios.CancelToken.source();

        try {
            const vacancyResponse = await getVacancyBySlugApiCall(uuid);

            if (!isFetchResultSuccessful(vacancyResponse)) {
                console.error('[fetchVacancyForCompany]', vacancyResponse.error);
                return;
            }

            const response = await axios.get(`/me/company/vacancies/${uuid}`, {
                cancelToken: cancelFetchVacancyToken.token,
                params: {
                    with: ''.concat(
                        'address,',
                        'company,',
                        'company.address,',
                        'company.employee_profiles,',
                        'company.coreValues,',
                        'job_group,',
                        'vacancy_type,',
                        'education_level,',
                        'competencies,',
                        'grade,',
                        'orders,',
                        'skills,',
                        'skills_to_learn,',
                        'company.contact_person,',
                    ),
                },
            });

            const combinedVacancyData = {
                ...response.data.data,
                esco_skills: vacancyResponse.data.escoSkills,
                esco_occupations: vacancyResponse.data.escoOccupations,
            };

            const vacancyType = combinedVacancyData.type;
            const vacancy = vacancyType === VacancyType.internship
                ? transformInternshipVacancy(combinedVacancyData)
                : transformJobVacancy(combinedVacancyData);
            const newestVacancy = vacancyType === VacancyType.internship
                ? transformInternshipVacancyToNewestVacancy(combinedVacancyData)
                : transformJobVacancyToNewestVacancy(combinedVacancyData);

            dispatch(setVacancy(vacancy));
            dispatch(setNewestVacancy(newestVacancy));
            dispatch(setLoading(false));
        } catch (error: any) {
            if (axios.isCancel(error)) {
                return;
            }

            if (!error.response) {
                console.error('[fetchVacancyForCompany]', error);
            }
        }
    };
}

export function inviteStudent(vacancyUuid: string, student?: MatchingCandidate, callback?: () => void) {
    return (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        const studentUuid = student?.uuid || '';

        return axios.post(`/me/company/vacancies/${vacancyUuid}/matching-candidates/${studentUuid}/invite-user`, {
            callback_url: '/:type/:slug',
        })
            .then(response => {
                console.log('Invite student', response.data.data);
                dispatch(setVacancy(response.data.data));
                dispatch(setLoading(false));

                if (callback) callback();
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(setInviteError(error.response.data.errors));
                dispatch(setLoading(false));

                return Promise.reject(error.response.data.errors);
            });
    };
}

export function ignoreStudent(vacancyUuid: string, student?: MatchingCandidate, callback?: () => void) {
    return (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        const studentUuid = student?.uuid || '';

        return axios.post(`/me/company/vacancies/${vacancyUuid}/matching-candidates/${studentUuid}/ignore-user`)
            .then(response => {
                dispatch(setVacancy(response.data.data));
                dispatch(setLoading(false));

                if (callback) callback();
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(setIgnoreError(error.response.data.errors));
                dispatch(setLoading(false));

                return Promise.reject(error.response.data.errors);
            });
    };
}
