import React, { FC, ReactElement } from 'react';

import { FormOption } from '../../../types';
import { CheckboxList } from '../..';

interface DriversLicensesFilterProps {
    label: string;
    hideLabel?: boolean;
    value: string[];
    driversLicenses: FormOption[];
    tabIndex?: number;
    onChange: (value: string[]) => void;
    className?: string;
}

const DriversLicensesFilter: FC<DriversLicensesFilterProps> = ({
    label,
    hideLabel,
    value,
    driversLicenses,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`drivers-licenses-filter ${className}`}>
        <CheckboxList
            label={label}
            hideLabel={hideLabel}
            name="drivers-licenses"
            options={driversLicenses}
            value={value}
            showLessThreshold={6}
            tabIndex={tabIndex}
            onChange={onChange}
        />
    </div>
);

export default DriversLicensesFilter;
