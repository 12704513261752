import React, { FC, ReactElement } from 'react';

import { Sidebar } from '../../compositions';
import { trans } from '../../helpers/trans';
import { Notification } from '../../models/Notifications';
import { NotificationItem } from './subcomponents';

import './NotificationsSidebar.scss';

interface NotificationsSidebarProps {
    isLoading: boolean;
    isExpanded: boolean;
    notifications: Notification[];
    onSelectNotification: (id: string) => void;
    onRemoveNotification: (id: string) => void;
    onClose: () => void;
    className?: string;
}

const NotificationsSidebar: FC<NotificationsSidebarProps> = ({
    isLoading,
    isExpanded,
    notifications,
    onSelectNotification,
    onRemoveNotification,
    onClose,
    className = '',
}): ReactElement => (
    <Sidebar
        isLoading={isLoading}
        isExpanded={isExpanded}
        title={trans('notifications.title')}
        onClose={onClose}
        className={`notifications-sidebar ${className}`}
    >
        {notifications.length > 0 ? (
            <ul id="notifications-list" className="notifications-sidebar__list">
                {notifications.map(notification => (
                    <NotificationItem
                        {...notification}
                        key={notification.id}
                        isLoading={isLoading}
                        onSelect={onSelectNotification}
                        onRemoveClick={onRemoveNotification}
                    />
                ))}
            </ul>
        ) : (
            <div className="notifications-sidebar__no-results">
                <p className="notifications-sidebar__no-results-description">
                    {trans('notifications.nullState')}
                </p>
            </div>
        )}
    </Sidebar>
);

export default NotificationsSidebar;
