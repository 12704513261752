import { Pagination } from './Pagination';

export enum ResponseType {
    Error = 'error',
    Success = 'success',
}

export interface Response<T> {
    status: number;
    data?: T;
    pagination?: Pagination;
    error?: any;
    type: ResponseType;
}
