import React, { FC, ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../components';
import { Toast } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { RoleType } from '../../../../models/User';
import { RoutePaths } from '../../../../routes';
import { MessageState } from '../../../../types';
import { CompanySettingsTab, UserSettingsTab } from '../../../../types/pageTabs';

import './CompleteRegistrationToast.scss';

interface CompleteRegistrationToastProps {
    role: RoleType;
    className?: string;
}

const CompleteRegistrationToast: FC<CompleteRegistrationToastProps> = ({
    role,
    className = '',
}): ReactElement | null => {
    const navigate = useNavigate();

    const [toastIsExpanded, setToastIsExpanded] = useState<boolean>(true);
    const [closingToast, setClosingToast] = useState(false);

    const closeToast = (): void => setToastIsExpanded(false);

    const handleConfirmClick = (): void => {
        setClosingToast(true);

        const settingsPage = role === RoleType.company
            ? RoutePaths.companySettings(CompanySettingsTab.myCompany)
            : RoutePaths.userSettings(UserSettingsTab.personalData);

        navigate(settingsPage, {
            state: {
                completeRegister: true,
            },
        });
    };

    return toastIsExpanded ? (
        <Toast
            shouldClose={closingToast}
            id="complete-registration"
            state={MessageState.negative}
            title={trans('navigation.completeRegistration.title')}
            description={trans('navigation.completeRegistration.description')}
            onClose={closeToast}
            className={`complete-registration-toast ${className}`}
        >
            <Button
                text={trans('navigation.completeRegistration.confirmButtonLabel')}
                onClick={handleConfirmClick}
                className="complete-registration-toast__button"
            />
        </Toast>
    ) : null;
};

export default CompleteRegistrationToast;
