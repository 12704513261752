import { alphabetNumber } from '../../constants';
import { retrieveUniqueValues } from '../../helpers/array';
import { isSSR } from '../../helpers/environment';
import { stringIsNumber } from '../../helpers/number';
import { filterArrayOnObjectKey } from '../../helpers/object';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { InternshipVacancyIndexParams, JobVacancyIndexParams } from '../../models/VacancyIndex';
import { getEducationLevelsApiCall } from '../../services/EducationLevelsService';
import { getEscoOccupationsApiCall } from '../../services/EscoOccupationsService';
import { getFieldsOfStudyApiCall, getManualFieldsOfStudyApiCall } from '../../services/FieldsOfStudyService';
import { getSectorsApiCall } from '../../services/SectorService';
import { getInternshipVacancyIndexAggregationApiCall, getJobVacancyIndexAggregationApiCall } from '../../services/VacancyIndexService';
import { ReducerGetter, TypedDispatch } from '..';
import {
    setActiveCities,
    setActiveEducationLevels,
    setActiveFieldsOfStudy,
    setActiveLetters,
    setActiveOccupations,
    setActiveProvinces,
    setActiveSectors,
    setAggregation,
    setError,
    setIsLoading,
} from './vacancyIndex';

export const fetchInternshipVacancyIndexAggregation = (params: InternshipVacancyIndexParams) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const {
            educationLevelsReducer,
            fieldsOfStudyReducer,
            sectorsReducer,
            provincesReducer,
        } = getState();

        const { educationLevels } = educationLevelsReducer;
        const { fieldsOfStudy, manualFieldsOfStudy } = fieldsOfStudyReducer;
        const { sectors } = sectorsReducer;
        const { provinces } = provincesReducer;

        const educationLevelsResponse = isSSR && await getEducationLevelsApiCall();
        const educationLevelOptions = educationLevelsResponse && isFetchResultSuccessful(educationLevelsResponse)
            ? educationLevelsResponse.data.educationLevels
            : educationLevels;

        const fieldsOfStudyResponse = isSSR && await getFieldsOfStudyApiCall();
        const fieldOfStudyOptions = fieldsOfStudyResponse && isFetchResultSuccessful(fieldsOfStudyResponse)
            ? fieldsOfStudyResponse.data
            : fieldsOfStudy;

        const manualFieldsOfStudyResponse = isSSR && await getManualFieldsOfStudyApiCall();
        const manualFieldOfStudyOptions = manualFieldsOfStudyResponse && isFetchResultSuccessful(manualFieldsOfStudyResponse)
            ? manualFieldsOfStudyResponse.data
            : manualFieldsOfStudy;

        const sectorsResponse = isSSR && await getSectorsApiCall();
        const sectorOptions = sectorsResponse && isFetchResultSuccessful(sectorsResponse)
            ? sectorsResponse.data
            : sectors;

        const selectedEducationLevel = educationLevelOptions.find(option => option.slug === params.educationLevel);
        const selectedFieldOfStudy = [...fieldOfStudyOptions, ...manualFieldOfStudyOptions]
            .find(option => option.slug === params.fieldOfStudy);

        const aggregationResponse = await getInternshipVacancyIndexAggregationApiCall({
            ...params,
            educationLevel: selectedEducationLevel?.name,
            fieldOfStudy: selectedFieldOfStudy?.name,
        });

        if (!isFetchResultSuccessful(aggregationResponse)) {
            console.error('[fetchInternshipVacancyIndexAggregation]', aggregationResponse.error);
            return;
        }

        const aggregation = aggregationResponse.data;

        if (aggregation.educationLevels) {
            const availableEducationLevels = aggregation.educationLevels.map(item => item.key);
            const filteredEducationLevels = educationLevelOptions.filter(option => availableEducationLevels.includes(option.name));

            dispatch(setActiveEducationLevels(filteredEducationLevels));
        }

        if (aggregation.fosIndex) {
            const availableLetters = aggregation.fosIndex.map(item => (
                stringIsNumber(item.key) ? alphabetNumber : item.key
            ));

            const uniqueLetters = retrieveUniqueValues(availableLetters);

            dispatch(setActiveLetters(uniqueLetters));
        }

        if (aggregation.fieldsOfStudy) {
            const availableFieldsOfStudy = aggregation.fieldsOfStudy.map(item => item.key);
            const filteredFieldsOfStudy = [...fieldOfStudyOptions, ...manualFieldOfStudyOptions]
                .filter(option => availableFieldsOfStudy.includes(option.name));

            const uniqueFieldsOfStudy = filterArrayOnObjectKey(filteredFieldsOfStudy, 'name');

            dispatch(setActiveFieldsOfStudy(uniqueFieldsOfStudy));
        }

        if (aggregation.sectors) {
            const availableSectors = aggregation.sectors.map(item => item.key);
            const filteredSectors = sectorOptions.filter(option => availableSectors.includes(option.name));

            dispatch(setActiveSectors(filteredSectors));
        } else {
            dispatch(setActiveSectors(sectorOptions));
        }

        if (aggregation.provinces) {
            const availableProvinces = aggregation.provinces.map(item => item.key);
            const filteredProvinces = provinces.filter(option => availableProvinces.includes(option.name));

            dispatch(setActiveProvinces(filteredProvinces));
        } else {
            dispatch(setActiveProvinces(provinces));
        }

        if (aggregation.cities) {
            const availableCities = aggregation.cities.map(item => item.key);

            dispatch(setActiveCities(availableCities));
        }

        dispatch(setAggregation(aggregation));
    } catch (error) {
        console.error('[fetchInternshipVacancyIndexAggregation]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchJobVacancyIndexAggregation = (params: JobVacancyIndexParams) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { escoOccupationsReducer, sectorsReducer, provincesReducer } = getState();
        const { escoOccupations } = escoOccupationsReducer;
        const { sectors } = sectorsReducer;
        const { provinces } = provincesReducer;

        const escoOccupationsResponse = isSSR && await getEscoOccupationsApiCall();
        const escoOccupationOptions = escoOccupationsResponse && isFetchResultSuccessful(escoOccupationsResponse)
            ? escoOccupationsResponse.data
            : escoOccupations;

        const sectorsResponse = isSSR && await getSectorsApiCall();
        const sectorOptions = sectorsResponse && isFetchResultSuccessful(sectorsResponse)
            ? sectorsResponse.data
            : sectors;

        const selectedOccupation = escoOccupationOptions.find(option => option.slug === params.occupation);

        const aggregationResponse = await getJobVacancyIndexAggregationApiCall({
            ...params,
            occupation: selectedOccupation?.name,
        });

        if (!isFetchResultSuccessful(aggregationResponse)) {
            console.error('[fetchJobVacancyIndexAggregation]', aggregationResponse.error);
            return;
        }

        const aggregation = aggregationResponse.data;

        if (aggregation.occIndex) {
            const availableLetters = aggregation.occIndex.map(item => (
                stringIsNumber(item.key) ? alphabetNumber : item.key
            ));

            const uniqueLetters = retrieveUniqueValues(availableLetters);

            dispatch(setActiveLetters(uniqueLetters));
        }

        if (aggregation.occupations) {
            const availableOccupations = aggregation.occupations.map(item => item.key);
            const filteredOccupations = escoOccupationOptions.filter(option => availableOccupations.includes(option.name));

            dispatch(setActiveOccupations(filteredOccupations));
        }

        if (aggregation.sectors) {
            const availableSectors = aggregation.sectors.map(item => item.key);
            const filteredSectors = sectorOptions.filter(option => availableSectors.includes(option.name));

            dispatch(setActiveSectors(filteredSectors));
        } else {
            dispatch(setActiveSectors(sectorOptions));
        }

        if (aggregation.provinces) {
            const availableProvinces = aggregation.provinces.map(item => item.key);
            const filteredProvinces = provinces.filter(option => availableProvinces.includes(option.name));

            dispatch(setActiveProvinces(filteredProvinces));
        } else {
            dispatch(setActiveProvinces(provinces));
        }

        if (aggregation.cities) {
            const availableCities = aggregation.cities.map(item => item.key);

            dispatch(setActiveCities(availableCities));
        }

        dispatch(setAggregation(aggregation));
    } catch (error) {
        console.error('[fetchJobVacancyIndexAggregation]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
