import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { KvkBaseProfile } from '../../models/KvkBaseProfile';
import { AsyncReduxState } from '../../types';

export type KvkBaseProfileState = AsyncReduxState<{
    kvkBaseProfile?: KvkBaseProfile;
}>;

const initialState: KvkBaseProfileState = {
    ...initialAsyncReduxState,
    kvkBaseProfile: undefined,
};

export const kvkBaseProfileSlice = createSlice({
    name: 'kvkBaseProfileReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): KvkBaseProfileState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): KvkBaseProfileState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setKvkBaseProfile(state, action: PayloadAction<KvkBaseProfile | undefined>): KvkBaseProfileState {
            return {
                ...state,
                kvkBaseProfile: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setKvkBaseProfile,
} = kvkBaseProfileSlice.actions;

export default kvkBaseProfileSlice;
