import React, { FC, ReactElement } from 'react';

import { BlogList } from '../../compositions';
import { trans } from '../../helpers/trans';
import { BlogArticle } from '../../models/Blog';
import { Carousel } from '..';

import './RelatedBlogArticles.scss';

interface RelatedBlogArticlesProps {
    isLoading: boolean;
    relatedBlogArticles: BlogArticle[];
    className?: string;
}

const RelatedBlogArticles: FC<RelatedBlogArticlesProps> = ({
    isLoading,
    relatedBlogArticles,
    className = '',
}): ReactElement | null => {
    if (!isLoading && relatedBlogArticles.length <= 1) {
        return null;
    }

    return (
        <div className={`related-blog-articles ${className}`}>
            <div className="related-blog-articles__wrapper">
                <Carousel
                    title={trans('blog.relatedArticles')}
                    columnAmount={2}
                    itemAmount={relatedBlogArticles.length}
                    className="related-blog-articles__carousel"
                >
                    <BlogList
                        isLoading={isLoading}
                        blogArticles={relatedBlogArticles}
                        skeletonAmount={2}
                        className="related-blog-articles__list"
                    />
                </Carousel>
            </div>
        </div>
    );
};

export default RelatedBlogArticles;
