import React, { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedVacancyDetail } from '../../connectors';
import { RouteParams } from '../../routes';

import './VacancyDetail.scss';

interface VacancyDetailParams extends RouteParams {
    vacancyUuid?: string;
}

const VacancyDetail: FC = (): ReactElement => {
    const { vacancyUuid = '' } = useParams<VacancyDetailParams>();

    return (
        <Page className="vacancy-detail-page">
            <div className="vacancy-detail-page__wrapper">
                <ConnectedVacancyDetail vacancyUuid={vacancyUuid} />
            </div>
        </Page>
    );
};

export default VacancyDetail;
