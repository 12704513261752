import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { CheckIntersection, SafeHtml } from '../../../components';
import { trans } from '../../../helpers/trans';
import { FormOption, SalaryTimeframe } from '../../../types';
import { MinMaxInput, NewBlockRadioList } from '../..';
import { CurrencyIcon } from './subcomponents';

import './SalaryInput.scss';

interface SalaryInputProps {
    id?: string;
    label: string;
    ctaText?: string;
    timeframe: SalaryTimeframe;
    timeframeOptions?: FormOption[];
    minValue: number | '';
    maxValue: number | '';
    disabled?: boolean;
    error?: string;
    onTimeframeChange?: (timeframe: SalaryTimeframe) => void;
    onMinMaxChange: (min: number | '', max: number | '') => void;
    className?: string;
}

const SalaryInput: FC<SalaryInputProps> = ({
    id,
    label,
    ctaText,
    timeframe,
    timeframeOptions,
    minValue,
    maxValue,
    disabled,
    error,
    onTimeframeChange,
    onMinMaxChange,
    className = '',
}): ReactElement => {
    const minMaxRatio = 1.25;

    const [hasSelectedHour, setHasSelectedHour] = useState<boolean>(timeframe === SalaryTimeframe.hour);
    const [step, setStep] = useState<number>(timeframe === SalaryTimeframe.hour ? 0.5 : 50);

    useEffect((): void => {
        setHasSelectedHour(timeframe === SalaryTimeframe.hour);
        setStep(timeframe === SalaryTimeframe.hour ? 0.5 : 50);
    }, [timeframe]);

    const handleTimeframeChange = (value: string): void => {
        if (onTimeframeChange) {
            onTimeframeChange(value as SalaryTimeframe);
            onMinMaxChange('', '');
        }
    };

    const handleMinChange = (value: number): void => {
        onMinMaxChange(value || '', maxValue);
    };

    const handleMaxChange = (value: number): void => {
        onMinMaxChange(minValue, value || '');
    };

    const handleSuggestMax = (value: string): void => {
        const min = Number(value);

        if (min > 0) {
            const minValueFactor = Math.round(min * minMaxRatio);
            const steppedValue = min + step;
            const suggestedMaxValue = Math.max(minValueFactor, steppedValue);
            const roundedSuggestedMaxValue = Math.round(suggestedMaxValue / step) * step;

            onMinMaxChange(min, roundedSuggestedMaxValue);
        }
    };

    const inputProps = { step, min: 0 };

    const minInputProps = {
        ...inputProps,
        label: hasSelectedHour
            ? trans('forms.salary.input.minPerHour')
            : trans('forms.salary.input.minPerMonth'),
        value: minValue,
        onChange: handleMinChange,
    };

    const maxInputProps = {
        ...inputProps,
        label: hasSelectedHour
            ? trans('forms.salary.input.maxPerHour')
            : trans('forms.salary.input.maxPerMonth'),
        value: maxValue,
        onChange: handleMaxChange,
    };

    return (
        <div id={id} className={`salary-input ${className}`}>
            {ctaText && (
                <div className="salary-input__cta">
                    <CheckIntersection>
                        {isVisible => (
                            <CurrencyIcon
                                isVisible={isVisible}
                                className="salary-input__cta-icon"
                            />
                        )}
                    </CheckIntersection>

                    <SafeHtml
                        html={ctaText}
                        className="salary-input__cta-text"
                    />
                </div>
            )}

            {timeframeOptions && (
                <NewBlockRadioList
                    label={trans('forms.salary.input.timeframe.label')}
                    name="salary-timeframe"
                    options={timeframeOptions}
                    value={timeframe}
                    onChange={handleTimeframeChange}
                    className="salary-input__timeframe-input"
                />
            )}

            <MinMaxInput
                label={label}
                minInput={minInputProps}
                maxInput={maxInputProps}
                unit={trans('basic.unit.euro')}
                disabled={disabled}
                error={error}
                onMinBlur={handleSuggestMax}
                className="salary-input__min-max-input"
            />
        </div>
    );
};

export default SalaryInput;
