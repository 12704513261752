import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArticle: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M88.9,11.1v77.8H11.1V11.1H88.9z M88.9,0H11.1C5,0,0,5,0,11.1v77.8C0,95,5,100,11.1,100h77.8c6.1,0,11.1-5,11.1-11.1V11.1C100,5,95,0,88.9,0z" />
        <path d="M61.1,77.8H22.2V66.7h38.9V77.8z M77.8,55.6H22.2V44.4h55.6V55.6z M77.8,33.3H22.2V22.2h55.6V33.3z" />
    </svg>
);

export default IconArticle;
