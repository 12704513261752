/* eslint-disable camelcase */
import { Resource } from '../japi/types/Resource';
import {
    EscoOccupation,
    EscoOccupationsResource,
    EscoSkillsResource,
    EscoSkillViewModel,
} from './Esco';

export interface VacancyResource extends Resource {
    type: 'vacancies';
    attributes: {
        slug: string;
        title: string;
        description: string;
        sector: string;
        is_paid: boolean;
        apply_anonymous: boolean;
        views: number;
        publication_days: number;
        publication_date: string;
    };
    relationships?: {
        escoSkills: {
            data: {
                type: EscoSkillsResource['type'];
                id: string;
            }[];
        };
        escoOccupations: {
            data: {
                type: EscoOccupationsResource['type'];
                id: string;
            }[];
        };
    };
}

export interface VacancyViewModel {
    id: string;
    slug: string;
    title: string;
    description: string;
    sector: string;
    isPaid: boolean;
    applyAnonymous: boolean;
    views: number;
    publicationDays: number;
    publicationDate: Date;
    escoSkills: EscoSkillViewModel[];
    escoOccupations: EscoOccupation[];
}

export function transformToVacancyViewModel(
    resource: VacancyResource,
    escoOccupations: EscoOccupation[] = [],
    escoSkills: EscoSkillViewModel[] = [],
): VacancyViewModel {
    return {
        id: resource.id,
        applyAnonymous: resource.attributes.apply_anonymous,
        escoOccupations,
        escoSkills,
        description: resource.attributes.description,
        isPaid: resource.attributes.is_paid,
        publicationDate: new Date(resource.attributes.publication_date),
        publicationDays: resource.attributes.publication_days,
        sector: resource.attributes.sector,
        slug: resource.attributes.slug,
        title: resource.attributes.title,
        views: resource.attributes.views,
    };
}
/* eslint-enable camelcase */
