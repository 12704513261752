import React, { FC, ReactElement } from 'react';

import { FormOption } from '../../../types';
import { CheckboxList } from '../..';

interface AmountOfEmployeesFilterProps {
    label: string;
    hideLabel?: boolean;
    value: string[];
    options: FormOption[];
    tabIndex?: number;
    onChange: (value: string[]) => void;
    className?: string;
}

const AmountOfEmployeesFilter: FC<AmountOfEmployeesFilterProps> = ({
    label,
    hideLabel,
    value,
    options,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`amount-of-employees-filter ${className}`}>
        <CheckboxList
            label={label}
            hideLabel={hideLabel}
            name="amount-of-employees"
            options={options}
            value={value}
            tabIndex={tabIndex}
            onChange={onChange}
        />
    </div>
);

export default AmountOfEmployeesFilter;
