import React, { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button, Icon } from '../../../../components';
import { Avatar, SwipeDetector } from '../../../../compositions';
import { getAgeShort } from '../../../../helpers/date';
import { trans } from '../../../../helpers/trans';
import { NotificationStatus } from '../../../../models/Notifications';

import './NotificationItem.scss';

interface NotificationItemProps {
    isLoading: boolean;
    id: string;
    status: NotificationStatus;
    image: string;
    title: string;
    description: string;
    url: string;
    createdAt: Date;
    onSelect: (id: string) => void;
    onRemoveClick: (id: string) => void;
    className?: string;
}

const NotificationItem: FC<NotificationItemProps> = ({
    isLoading,
    id,
    status,
    image,
    title,
    description,
    url,
    createdAt,
    onSelect,
    onRemoveClick,
    className = '',
}): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openNotificationItem = (): void => setIsOpen(true);
    const closeNotificationItem = (): void => setIsOpen(false);

    const notificationAge = getAgeShort(createdAt);

    const handleSelect = (): void => {
        if (status !== NotificationStatus.read) {
            onSelect(id);
        }
    };

    const handleRemoveClick = (): void => onRemoveClick(id);

    const notificationItemClassNames = classNames('notification-item', {
        'notification-item--is-loading': isLoading,
        'notification-item--is-new': status === NotificationStatus.new,
        'notification-item--is-unread': status === NotificationStatus.unread,
        'notification-item--is-open': isOpen,
    }, className);

    return (
        <li className={notificationItemClassNames}>
            <SwipeDetector
                threshold={50}
                onLeftSwipe={openNotificationItem}
                onRightSwipe={closeNotificationItem}
                className="notification-item__swipe-detector"
            >
                <div className="notification-item__content-wrapper">
                    <div className="notification-item__image-wrapper">
                        <Avatar
                            src={image}
                            alt={title}
                            className="notification-item__image"
                        />
                    </div>

                    <div className="notification-item__text-wrapper">
                        {url ? (
                            <Link
                                to={url}
                                onClick={handleSelect}
                                className="notification-item__link"
                            >
                                <h2 data-hj-suppress="true" className="notification-item__title">
                                    {title}
                                </h2>
                            </Link>
                        ) : title}

                        <p data-hj-suppress="true" className="notification-item__description">
                            {description}
                        </p>
                    </div>

                    <p data-hj-suppress="true" className="notification-item__age">
                        {notificationAge}
                    </p>
                </div>
            </SwipeDetector>

            <Button
                text={trans('notifications.item.removeButtonLabel')}
                disabled={isLoading}
                onClick={handleRemoveClick}
                className="notification-item__delete-button"
            >
                <Icon name="delete" className="notification-item__delete-icon" />
            </Button>
        </li>
    );
};

export default NotificationItem;
