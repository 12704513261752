import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getFieldsOfStudyApiCall, getManualFieldsOfStudyApiCall } from '../../services/FieldsOfStudyService';
import { TypedDispatch } from '..';
import {
    setError,
    setFieldsOfStudy,
    setIsLoading,
    setManualFieldsOfStudy,
} from './fieldsOfStudy';

export const fetchFieldsOfStudy = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const fieldsOfStudyResponse = await getFieldsOfStudyApiCall();

        if (!isFetchResultSuccessful(fieldsOfStudyResponse)) {
            dispatch(setError(fieldsOfStudyResponse.error));
            return;
        }

        dispatch(setFieldsOfStudy(fieldsOfStudyResponse.data));
    } catch (error) {
        console.error('[fetchFieldsOfStudy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchManualFieldsOfStudy = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const manualFieldsOfStudyResponse = await getManualFieldsOfStudyApiCall();

        if (!isFetchResultSuccessful(manualFieldsOfStudyResponse)) {
            dispatch(setError(manualFieldsOfStudyResponse.error));
            return;
        }

        dispatch(setManualFieldsOfStudy(manualFieldsOfStudyResponse.data));
    } catch (error) {
        console.error('[fetchManualFieldsOfStudy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
