import { socketReconnectDelay } from '../constants';
import { TOKEN_KEY } from '../helpers/authorizedFetch';
import { Chat } from '../models/Chat';

export const initializeWebSocket = (url: string, queryParams?: Record<string, string | undefined>, protocols?: string | string[]): WebSocket => {
    const token = localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
    const parsedToken = token ? JSON.parse(token) : {};

    const params = new URLSearchParams({
        ...queryParams,
        bearer: parsedToken?.access_token,
    }).toString();

    const queryString = `?${params}`;
    const webSocketUrl = process.env.REACT_APP_WS_URL + url + queryString;

    return new WebSocket(webSocketUrl, protocols);
};

export const initializeChatSocket = (chat: Chat, isCandidate: boolean): WebSocket | undefined => {
    const queryParams = isCandidate
        ? { company: chat.company.uuid }
        : { candidate: chat.candidate.uuid };

    let socket: WebSocket | undefined = initializeWebSocket('/ws/company-chat', queryParams);

    const reconnectSocket = (): void => {
        socket = undefined;
        setTimeout(() => initializeChatSocket(chat, isCandidate), socketReconnectDelay);
    };

    socket.addEventListener('close', (event): void => {
        if (!event.wasClean) {
            reconnectSocket();
        }
    });

    socket.addEventListener('error', reconnectSocket);

    return socket;
};
