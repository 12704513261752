import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import {
    Skill,
    SkillsResource,
    SkillType,
    transformToSkill,
} from '../models/Skills';

export const getSkillsApiCall = async (): Promise<FetchResult<Skill[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/skills`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<SkillsResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const selectableSkills = doc.data.filter(data => data.attributes.isSelectable);
        const skills = selectableSkills.map(skillsResource => transformToSkill(skillsResource, SkillType.skill));
        const sortedSkills = skills.sort((skill, comparisonSkill) => skill.name.localeCompare(comparisonSkill.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedSkills,
        };
    } catch (error) {
        console.error('[getSkillsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
