import React, { Component, createRef } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import BlockRadioList from '../../../../components/form/BlockRadioList/BlockRadioList';
import TextInput from '../../../../components/form/TextInput';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading } from '../../../../styledComponents/components/Typography';
import genderOptions from '../../../constants/userDataOptions';

class EmployeeDataForm extends Component {
    constructor() {
        super();

        this.formElement = createRef();
    }

    onSubmit = e => {
        e.preventDefault();

        const data = serialize(this.formElement.current, { hash: true, empty: true });

        this.props.onSubmit(data);
    };

    render() {
        const {
            loading,
            person,
            employee,
            errors,
        } = this.props;

        return (
            <>
                <Heading as="h2" size="xl" mb="sm">{trans('forms.user.account.heading')}</Heading>
                <form ref={this.formElement} id="employee-data-form" onSubmit={this.onSubmit}>
                    <Row>
                        <Col mb="md" width={6 / 8}>
                            <TextInput
                                errors={errors.first_name}
                                label={trans('forms.user.firstname.label')}
                                placeholder={trans('forms.user.firstname.placeholder')}
                                name="first_name"
                                defaultValue={(person && person.first_name) || null}
                            />
                        </Col>
                        <Col mb="md" pl="sm" width={2 / 8}>
                            <TextInput
                                errors={errors.insertion}
                                label={trans('forms.user.insertion.label')}
                                placeholder={trans('forms.user.insertion.placeholder')}
                                name="insertion"
                                defaultValue={(person && person.insertion) || null}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col mb="md" width={4 / 8}>
                            <TextInput
                                errors={errors.last_name}
                                label={trans('forms.user.lastname.label')}
                                placeholder={trans('forms.user.lastname.placeholder')}
                                name="last_name"
                                defaultValue={(person && person.last_name) || null}
                            />
                        </Col>
                        <Col mb="md" width={4 / 8} pl="sm">
                            <BlockRadioList
                                id="register-gender"
                                errors={errors.gender}
                                options={genderOptions}
                                label={trans('forms.user.gender.label')}
                                name="gender"
                                selected={person ? person.gender : null}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col mb="md" width={1}>
                            <TextInput
                                errors={errors.phone_number}
                                label={trans('forms.phonenumber.label')}
                                placeholder={trans('forms.phonenumber.placeholder')}
                                name="phone_number"
                                formatOptions={{
                                    phone: true,
                                    phoneRegionCode: 'NL',
                                }}
                                defaultValue={
                                    (person && person.phone_number) || null
                                }
                                optional
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col mb="md" width={1}>
                            <TextInput
                                errors={errors.position}
                                label={trans('forms.user.position.label')}
                                placeholder={trans('forms.user.position.placeholder')}
                                name="position"
                                defaultValue={(employee && employee.position) || null}
                            />
                        </Col>
                    </Row>
                    <Row justifyContent="flex-end">
                        <Col>
                            <Button
                                isLoading={loading}
                                id="employee-data-form-submit"
                                onClick={this.onSubmit}
                                type="submit"
                            >
                                {trans('actions.save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

EmployeeDataForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    employee: PropTypes.object,
    person: PropTypes.object,
};

EmployeeDataForm.defaultProps = {
    errors: {},
    person: null,
    employee: null,
};

export default EmployeeDataForm;
