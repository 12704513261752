import { parseToRgb } from 'polished';

export default color => {
    const c = parseToRgb(color);
    return Math.sqrt(
        c.red * c.red * 0.241
        + c.green * c.green * 0.691
        + c.blue * c.blue * 0.068,
    ) > 160;
};
