import React, { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { Card } from '../../../components';
import { trans } from '../../../helpers/trans';
import { IconButton } from '../..';

import './DetailCard.scss';

interface DetailCardProps {
    isLoading?: boolean;
    isEditable?: boolean;
    title: string;
    description?: string;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    className?: string;
}

const DetailCard: FC<PropsWithChildren<DetailCardProps>> = ({
    isLoading,
    isEditable,
    title,
    description,
    onEditClick,
    onDeleteClick,
    className = '',
    children,
}): ReactElement => {
    const detailCardClassNames = classNames('detail-card', {
        'detail-card--has-description': !!description,
    }, className);

    return (
        <Card className={detailCardClassNames}>
            <div className="detail-card__header">
                <div className="detail-card__text-wrapper">
                    <h2 className="detail-card__title">
                        {title}
                    </h2>

                    {description && (
                        <p className="detail-card__description">
                            {description}
                        </p>
                    )}
                </div>

                {isEditable && (
                    <div className="detail-card__control-wrapper">
                        <IconButton
                            icon="pencil"
                            text={trans('actions.edit')}
                            hideLabel
                            disabled={isLoading}
                            onClick={onEditClick}
                            className="detail-card__edit-button"
                        />

                        {onDeleteClick && (
                            <IconButton
                                icon="delete"
                                text={trans('actions.delete')}
                                hideLabel
                                disabled={isLoading}
                                onClick={onDeleteClick}
                                className="detail-card__edit-button"
                            />
                        )}
                    </div>
                )}
            </div>

            {children}
        </Card>
    );
};

export default DetailCard;
