import React, { FC, ReactElement } from 'react';

import Slider from 'react-slick';

import ReactSlickStyles from '../../components/ReactSlickStyles/ReactSlickStyles';
import SmallCardWithImage from '../../components/SmallCardWithImage/SmallCardWithImage';
import { Styles } from './styles';

interface ImageCard {
    imageSrc: string;
    altImage?: string;
}

interface SlidingSmallCardsProps {
    cards: ImageCard[];
    className?: string;
}

const SlidingSmallCards: FC<SlidingSmallCardsProps> = ({
    cards,
    className = '',
}): ReactElement => (
    <Styles>
        <div className={`sliding-small-cards ${className}`}>
            <ReactSlickStyles>
                <Slider
                    infinite
                    autoplay
                    slidesToShow={1}
                    slidesToScroll={1}
                    speed={2000}
                    autoplaySpeed={0}
                    cssEase="linear"
                    variableWidth
                    waitForAnimate
                    swipeToSlide={false}
                    pauseOnFocus={false}
                    pauseOnHover={false}
                    pauseOnDotsHover={false}
                    className="sliding-small-cards__slider"
                    arrows={false}
                >
                    {cards.map(card => (
                        <div style={{ width: '204px' }}>
                            <SmallCardWithImage className="sliding-small-cards__card" imageSrc={card.imageSrc} altImage={card.altImage} />
                        </div>
                    ))}
                </Slider>
            </ReactSlickStyles>
        </div>
    </Styles>
);

export default SlidingSmallCards;
