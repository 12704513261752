import React, { FC, ReactElement } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Icon } from '../../components';

import './InformationBox.scss';

interface InformationBoxProps {
    title?: string;
    descriptions: string[];
    className?: string;
}

const InformationBox: FC<InformationBoxProps> = ({
    title,
    descriptions,
    className = '',
}): ReactElement => (
    <div className={`information-box ${className}`}>
        <div className="information-box__icon-wrapper">
            <Icon name="information" className="information-box__icon" />
        </div>

        <div className="information-box__text-wrapper">
            {title && <h3 className="information-box__title">{title}</h3>}
            {descriptions.map(description => {
                const descriptionKey = uuidv4();

                return (
                    <p key={descriptionKey} className="information-box__description">
                        {description}
                    </p>
                );
            })}
        </div>
    </div>
);

export default InformationBox;
