// @ts-ignore
import styled, { css } from 'styled-components';

export const Styles = styled('div')(() => css`
    .team-ambassador {
        &__slider {
            margin-bottom: 2rem;
        }
    }
`);
