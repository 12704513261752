import React, { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './VacancyMatchesOverviewSkeletons.scss';

interface VacancyMatchesOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const VacancyMatchesOverviewSkeletons: FC<VacancyMatchesOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`vacancy-matches-overview-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="vacancy-matches-overview-skeletons__item">
                    <Card className="vacancy-matches-overview-skeletons__card">
                        <Skeleton className="vacancy-matches-overview-skeletons__avatar" />

                        <div className="vacancy-matches-overview-skeletons__text-wrapper">
                            <Skeleton className="vacancy-matches-overview-skeletons__text" />
                            <Skeleton className="vacancy-matches-overview-skeletons__text" />
                        </div>
                    </Card>
                </li>
            ))}
        </ul>
    );
};

export default VacancyMatchesOverviewSkeletons;
