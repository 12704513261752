import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrowVertical: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 56.3 100" className={`icon ${className}`}>
        <polygon points="33.2,83.2 33.2,49.9 33.2,49.9 33.2,16.8 50.1,30.4 56.3,22.5 28.2,0 0,22.5 6.3,30.4 23.2,16.8 23.2,50.1 23.2,50.1 23.2,83.2 6.3,69.6 0,77.5 28.2,100 56.3,77.5 50.1,69.6" />
    </svg>
);

export default IconArrowVertical;
