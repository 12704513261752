import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../components';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';
import { NewestVacancy } from '../../../models/NewestVacancy';
import {
    ApplicationSelector,
    ButtonList,
    Disclaimer,
    IconButton,
    Loader,
} from '../..';

import './VacancyArchiveModal.scss';

interface VacancyArchiveModalProps {
    isLoading?: boolean;
    vacancy: NewestVacancy;
    applications?: Application[];
    onFetchApplications?: (vacancyUuid: string) => void;
    onClearApplications?: () => void;
    onCancel: () => void;
    onConfirm: (applicationUuids: string[]) => void;
    className?: string;
}

const VacancyArchiveModal: FC<VacancyArchiveModalProps> = ({
    isLoading = false,
    vacancy,
    applications = [],
    onFetchApplications,
    onClearApplications,
    onCancel,
    onConfirm,
    className = '',
}): ReactElement => {
    const [hasFoundMatch, setHasFoundMatch] = useState<boolean>(false);
    const [selectedApplications, setSelectedApplications] = useState<Application[]>([]);

    useEffect((): void => {
        if (onClearApplications) {
            onClearApplications();
        }
    }, []);

    useEffect((): void => {
        const shouldFetchApplications = !vacancy.isAnonymous && vacancy.meta?.applicants;

        if (shouldFetchApplications && onFetchApplications) {
            onFetchApplications(vacancy.uuid);
        }
    }, [vacancy]);

    const handleConfirm = (): void => {
        const selectedApplicationsUuids = selectedApplications.map(application => application.uuid);

        onConfirm(selectedApplicationsUuids);
    };

    return (
        <div className={`vacancy-archive-modal ${className}`}>
            <p className="vacancy-archive-modal__description">
                {trans('myVacancies.modal.archive.description', {
                    name: vacancy.title,
                })}
            </p>

            {isLoading && (
                <Loader className="vacancy-archive-modal__loader" />
            )}

            {!isLoading && applications.length > 0 && (
                <ApplicationSelector
                    applications={applications}
                    value={selectedApplications}
                    onChangeHasFoundMatch={setHasFoundMatch}
                    onChangeApplication={setSelectedApplications}
                    className="vacancy-archive-modal__application-selector"
                />
            )}

            <Disclaimer
                title={trans('myVacancies.modal.archive.disclaimer.title')}
                description={trans('myVacancies.modal.archive.disclaimer.description')}
                className="vacancy-archive-modal__disclaimer"
            />

            <ButtonList className="vacancy-archive-modal__button-list">
                <IconButton
                    icon="archive"
                    text={trans('actions.archive')}
                    disabled={isLoading || (hasFoundMatch && selectedApplications.length === 0)}
                    onClick={handleConfirm}
                />
                <Button
                    text={trans('actions.cancel')}
                    onClick={onCancel}
                    className="vacancy-archive-modal__cancel-button"
                />
            </ButtonList>
        </div>
    );
};

export default VacancyArchiveModal;
