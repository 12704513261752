import { rgba } from 'polished';
import { animated } from 'react-spring/renderprops.cjs';
import styled, { css } from 'styled-components';
import { maxWidth, space } from 'styled-system';

export const OverlayContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: ${props => props.theme.colors.background}; 
    z-index: 200;
    padding-top: 70px; // Temporary fix to prevent content to be shown behind the menu
    
    ${props => props.dark && css`
        background: ${rgba(props.theme.colors.black, 0.7)};
    `} 
    
    ${props => props.black && css`
        background: ${rgba(props.theme.colors.black, 0.9)};
    `} 
    
    ${props => props.fullBlack && css`
        background: ${rgba(props.theme.colors.black, 1)};
    `} 
`;

export const ClickArea = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
`;

export const CloseButton = styled('button')(props => css`
    position: ${props.dark ? 'absolute' : 'fixed'};
    display: flex;
    justify-content: center;
    align-items: center;
    top: ${props.theme.space.md};
    right: ${props.theme.space[props.dark ? 'xs' : 'md']};
    font-size: 14px;
    line-height: 0;
    ${props.theme.fonts.bold};
    text-transform: uppercase;
    color: ${props.theme.colors.text};
    border: 0;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.8;
    
    svg {
        fill: ${props.theme.colors.text} !important;
    }
    
    ${props.light && css`
        color: ${props.theme.colors.white};
        svg {
            fill: ${props.theme.colors.white};
        }
    `}
    
    ${props.dark && css`
        color: ${props.theme.colors.primary};
        svg {
            fill: ${props.theme.colors.primary};
        }
    `}
    
    ${props.fullBlack && css`
        color: white;
        svg {
            fill: white;
        }
    `}
    
    &:focus {
        outline: none;
    }
    
    &:hover {
        opacity: 1;
    }
`);

export const Content = styled('div')(props => css`
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: ${props.cardless ? '1200px' : '710px'};
    min-height: 100vh;
    width: 100%;
    z-index: 1;
    
    @media ${props.theme.mediaQueries.tablet} {
        min-height: 0;
        margin: ${props.theme.space.xxl} ${props.theme.space.sm};
        
        ${props.sidebar && css`
            max-width: 1000px;
        `}
        
        ${maxWidth}
    }
`, [space, maxWidth]);

export const ContentMain = styled(animated.div)(props => css`
    position: relative;
    z-index: 1;
    flex: 1;
    width: 100vw;

    ${!props.cardless && css`
        padding: ${props.theme.space.xxl} ${props.theme.space.sm} ${props.theme.space.xl};
        background-color: ${props.theme.colors.white};
        box-shadow: ${props.theme.shadow()};
    `}
    
    ${props.contentCentered && css`
        display: flex;
        align-items: center;
    `}

    @media ${props.theme.mediaQueries.tablet} {
        width: 100%;
    
        ${!props.cardless && css`
            padding: ${props.theme.space.xxl} ${props.theme.space.xl} ${props.theme.space.xl};
        `}
    }
`);

export const Sidebar = styled(animated.div)(props => css`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: ${props.theme.colors.primary};
    
    @media ${props.theme.mediaQueries.laptop} {
        position: relative;
        width: 320px;
        height: auto;
    }
`);

export const SidebarWrapper = styled('div')(props => css`
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: width 0.30s ease-out, box-shadow 0.30s ease-out;
    box-shadow: 5px 0 5px 0 rgba(0,0,0,0.24);
    
    ${props.open && css`
        box-shadow: -5px 0 5px 0 rgba(0,0,0,0.24);
        
        @media ${props.theme.mediaQueries.smallMobile} {
            width: 280px;
        }
        
        width: 320px;
    `}
    
    @media ${props.theme.mediaQueries.laptop} {
        position: relative;
        width: 320px;
        height: auto;
    }
`);

export const SidebarContent = styled('div')(props => css`
    height: 100%;
    width: 300px;
    padding: ${props.theme.space.xxl} ${props.theme.space.xl} ${props.theme.space.xl};
    overflow-y: auto;
    
    @media ${props.theme.mediaQueries.laptop} {
        width: 320px;
    }
`);

export const SidebarButton = styled('button')(props => css`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: calc(100vh - 60px);
    left: -40px;
    width: 40px;
    height: 40px;
    text-align: center;
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.primary};
    box-shadow: -5px 0 5px 0 rgba(0,0,0,0.24);
    border: none;
    border-radius: 4px 0 0 4px;
    
    &:focus {
        outline: 0;
    }
    
    @media ${props.theme.mediaQueries.laptop} {
        display: none;
    }
`);
