import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon, Skeleton } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import { Application } from '../../../../../models/Applications';
import { RoutePaths } from '../../../../../routes';
import { CandidateOverviewTab } from '../../../../../types/pageTabs';

import './ApplicationBreadcrumbs.scss';

interface ApplicationBreadcrumbsProps {
    isLoading: boolean;
    application?: Application;
    className?: string;
}

const ApplicationBreadcrumbs: FC<ApplicationBreadcrumbsProps> = ({
    isLoading,
    application,
    className = '',
}): ReactElement => (
    <div className={`application-breadcrumbs ${className}`}>
        {isLoading && (
            <Skeleton className="application-breadcrumbs__skeleton" />
        )}

        {!isLoading && application && (
            <ul className="application-breadcrumbs__list">
                <li className="application-breadcrumbs__item">
                    <Link
                        to={RoutePaths.candidateOverviewTab(CandidateOverviewTab.applications)}
                        className="application-breadcrumbs__link"
                    >
                        {trans('containers.applicationDetail.breadcrumbs.myApplications')}
                    </Link>

                    <Icon name="chevron-right" className="application-breadcrumbs__icon" />
                </li>

                <li className="application-breadcrumbs__item">
                    <span className="application-breadcrumbs__label">
                        {application.vacancy.title}
                    </span>
                </li>
            </ul>
        )}
    </div>
);

export default ApplicationBreadcrumbs;
