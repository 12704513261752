import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { ChatReport } from '../../models/ChatReport';
import { AsyncReduxState } from '../../types';

export type ChatReportsState = AsyncReduxState<{
    chatReports: ChatReport[];
}>;

const initialState: ChatReportsState = {
    ...initialAsyncReduxState,
    chatReports: [],
};

const chatReportsSlice = createSlice({
    name: 'chatReportsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ChatReportsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ChatReportsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setChatReports(state, action: PayloadAction<ChatReport[]>): ChatReportsState {
            return {
                ...state,
                chatReports: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setChatReports,
} = chatReportsSlice.actions;

export default chatReportsSlice;
