export const getFullYouTubeUrl = (embedId: string): string => `https://www.youtube.com/watch?v=${embedId}`;

export const getYouTubeEmbedUrl = (embedId: string, autoPlay?: boolean): string => {
    const embedUrl = 'https://www.youtube.com/embed';

    if (autoPlay) {
        return `${embedUrl}/${embedId}?rel=0&autoplay=1`;
    }

    return `${embedUrl}/${embedId}?rel=0`;
};

export const retrieveYouTubeVideoId = (youTubeUrl: string): string | undefined => {
    const defaultYouTube = 'youtube.com';
    const sharedYouTube = 'youtu.be';

    try {
        const url = new URL(youTubeUrl);

        if (url.hostname.includes(defaultYouTube)) {
            return url.searchParams.get('v') || undefined;
        }

        if (url.hostname.includes(sharedYouTube)) {
            return url.pathname.replace('/', '');
        }

        return undefined;
    } catch {
        return undefined;
    }
};
