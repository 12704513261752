import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArchive: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 85" className={`icon ${className}`}>
        <path d="M65,47.5H35V40h30V47.5z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5,35H0V0h100v35h-5v50H5V35z M10,25V10h80v15H10z M15,32.5V75h70V32.5H15z" />
    </svg>
);

export default IconArchive;
