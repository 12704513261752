import { Competency } from '../../../../models/Competencies';

export const searchCompetencyOptionsOnQuery = (options: Competency[], query: string): Competency[] => {
    try {
        const searchQuery = new RegExp(query, 'i');

        return options.filter(item => item.name.match(searchQuery));
    } catch {
        return [];
    }
};
