import React, { FC, ReactElement, useState } from 'react';

import { IconButton, Theatre } from '../../../../../../../compositions';
import { trans } from '../../../../../../../helpers/trans';
import { Company } from '../../../../../../../models/Company';

import './VacancyHeaderCover.scss';

interface VacancyHeaderCoverProps {
    company: Company;
    className?: string;
}

const VacancyHeaderCover: FC<VacancyHeaderCoverProps> = ({
    company,
    className = '',
}): ReactElement => {
    const [theatreIsOpen, setTheatreIsOpen] = useState<boolean>(false);

    const openTheatre = (): void => setTheatreIsOpen(true);
    const closeTheatre = (): void => setTheatreIsOpen(false);

    return (
        <div className={`vacancy-header-cover ${className}`}>
            <div className="vacancy-header-cover__play-button-wrapper">
                {company.coverVideo && (
                    <IconButton
                        icon="play"
                        text={trans('actions.watch')}
                        hideLabel
                        onClick={openTheatre}
                        className="vacancy-header-cover__play-button"
                        iconClassName="vacancy-header-cover__play-icon"
                    />
                )}
            </div>

            {company.coverImage && (
                <img
                    src={company.coverImage}
                    alt={trans('vacancyDetail.header.coverAlt')}
                    className="vacancy-header-cover__image"
                />
            )}

            {company.coverVideo && theatreIsOpen && (
                <Theatre
                    autoPlay
                    embedId={company.coverVideo}
                    onClose={closeTheatre}
                />
            )}
        </div>
    );
};

export default VacancyHeaderCover;
