import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Disclaimer,
    EducationLevelSelector,
    FormActionButtons,
    MonthYearInput,
    SearchableValueInput,
} from '../../../compositions';
import { maxEmploymentYears } from '../../../constants';
import { startOfGivenMonth } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { EducationSubLevel, transformEducationSubLevelToFormOption } from '../../../models/EducationLevels';
import { FieldOfStudy, transformFieldOfStudyToSearchableOption } from '../../../models/FieldsOfStudy';
import { LearningPath, transformLearningPathToFormOption } from '../../../models/LearningPaths';
import { defaultSchoolExperience, SchoolExperience, SchoolExperienceFormData } from '../../../models/NewSchoolExperience';
import { School, transformSchoolToSearchableOption } from '../../../models/Schools';
import { FormOption, FormProps, SearchableOption } from '../../../types';
import { retrieveEducationLevelSpecificOptions } from './helpers';
import { SchoolExperienceFormErrors, validateSchoolExperienceFormData } from './validations';

import './SchoolExperienceForm.scss';

export interface SchoolExperienceFormOptions {
    educationLevelOptions: FormOption[];
    educationSubLevelOptions: EducationSubLevel[];
    learningPathOptions: LearningPath[];
    fieldOfStudyOptions: FieldOfStudy[];
    schoolOptions: School[];
}

export interface SchoolExperienceFormProps extends FormProps<SchoolExperienceFormData>, SchoolExperienceFormOptions {
    schoolExperience?: SchoolExperience;
    className?: string;
}

const SchoolExperienceForm: FC<SchoolExperienceFormProps> = ({
    schoolExperience = defaultSchoolExperience(),
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions,
    schoolOptions,
    fieldOfStudyOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<SchoolExperienceFormErrors>({});

    const [educationLevelSubLevelOptions, setEducationLevelSubLevelOptions] = useState<FormOption[]>([]);
    const [educationLevelLearningPathOptions, setEducationLevelLearningPathOptions] = useState<FormOption[]>([]);
    const [educationLevelFieldOfStudyOptions, setEducationLevelFieldOfStudyOptions] = useState<SearchableOption[]>([]);
    const [educationLevelSchoolOptions, setEducationLevelSchoolOptions] = useState<SearchableOption[]>([]);

    const [educationLevel, setEducationLevel] = useState<string>(schoolExperience.educationLevel);
    const [educationLevelId, setEducationLevelId] = useState<string>(schoolExperience.educationLevelId);
    const [educationSubLevel, setEducationSubLevel] = useState<string | undefined>(schoolExperience.educationSubLevel);
    const [educationSubLevelId, setEducationSubLevelId] = useState<string | undefined>(schoolExperience.educationSubLevelId);
    const [learningPath, setLearningPath] = useState<string | undefined>(schoolExperience.learningPath);
    const [learningPathId, setLearningPathId] = useState<string | undefined>(schoolExperience.learningPathId);
    const [school, setSchool] = useState<string>(schoolExperience.school);
    const [schoolId, setSchoolId] = useState<string>(schoolExperience.schoolId);
    const [fieldOfStudy, setFieldOfStudy] = useState<string>(schoolExperience.fieldOfStudy);
    const [fieldOfStudyId, setFieldOfStudyId] = useState<string>(schoolExperience.fieldOfStudyId);
    const [startDate, setStartDate] = useState<Date>(schoolExperience.startDate);
    const [endDate, setEndDate] = useState<Date>(schoolExperience.endDate);

    const formRef = useRef<HTMLFormElement>(null);

    const hasFieldsOfStudyOptions = educationLevelFieldOfStudyOptions.length > 0;
    const hasSchoolsOptions = educationLevelSchoolOptions.length > 0;

    useEffect((): void => {
        const filteredEducationSubLevels = retrieveEducationLevelSpecificOptions<EducationSubLevel>(educationSubLevelOptions, educationLevelId);
        const filteredLearningPaths = retrieveEducationLevelSpecificOptions<LearningPath>(learningPathOptions, educationLevelId);
        const filteredFieldsOfStudy = retrieveEducationLevelSpecificOptions<FieldOfStudy>(fieldOfStudyOptions, educationLevelId);
        const filteredSchools = retrieveEducationLevelSpecificOptions<School>(schoolOptions, educationLevelId);

        setEducationLevelSubLevelOptions(filteredEducationSubLevels.map(transformEducationSubLevelToFormOption));
        setEducationLevelLearningPathOptions(filteredLearningPaths.map(transformLearningPathToFormOption));
        setEducationLevelFieldOfStudyOptions(filteredFieldsOfStudy.map(transformFieldOfStudyToSearchableOption));
        setEducationLevelSchoolOptions(filteredSchools.map(transformSchoolToSearchableOption));
    }, [educationSubLevelOptions, learningPathOptions, fieldOfStudyOptions, schoolOptions, educationLevelId]);

    const handleEducationLevelChange = (value: FormOption): void => {
        setEducationLevelId(value.value);
        setEducationLevel(value.label);

        setFieldOfStudy('');
        setFieldOfStudyId('');

        setSchool('');
        setSchoolId('');
    };

    const handleEducationSubLevelChange = (value?: FormOption): void => {
        setEducationSubLevelId(value?.value);
        setEducationSubLevel(value?.label);
    };

    const handleLearningPathChange = (value?: FormOption): void => {
        setLearningPathId(value?.value);
        setLearningPath(value?.label);
    };

    const handleFieldOfStudyChange = (value: SearchableOption): void => {
        setFieldOfStudyId(value.value);
        setFieldOfStudy(value.label);
    };

    const handleSchoolChange = (value: SearchableOption): void => {
        setSchoolId(value.value);
        setSchool(value.label);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: schoolExperience.id,
            educationLevel,
            educationLevelId,
            educationSubLevel,
            educationSubLevelId,
            learningPath,
            learningPathId,
            fieldOfStudy,
            fieldOfStudyId,
            school,
            schoolId,
            startDate: startOfGivenMonth(startDate),
            endDate: startOfGivenMonth(endDate),
        };

        const [errors, hasErrors] = validateSchoolExperienceFormData(formData, {
            educationSubLevel: educationLevelSubLevelOptions.length > 0,
            learningPath: educationLevelLearningPathOptions.length > 0,
            // TODO: Remove these optional validations when there is a BE solution for adding manual field of study and school
            fieldOfStudy: hasFieldsOfStudyOptions,
            school: hasSchoolsOptions,
        });

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`school-experience-form ${className}`}>
            <Disclaimer
                title={trans('forms.new.schoolExperience.disclaimer.title')}
                description={trans('forms.new.schoolExperience.disclaimer.description')}
                className="school-experience-form__disclaimer"
            />

            <EducationLevelSelector
                maySelectOtherEducationLevel={false}
                educationLevel={educationLevelId}
                educationSubLevel={educationSubLevelId}
                learningPath={learningPathId}
                educationLevelOptions={educationLevelOptions}
                educationSubLevelOptions={educationLevelSubLevelOptions}
                learningPathOptions={educationLevelLearningPathOptions}
                formErrors={formErrors}
                onEducationLevelChange={handleEducationLevelChange}
                onEducationSubLevelChange={handleEducationSubLevelChange}
                onLearningPathChange={handleLearningPathChange}
                className="school-experience-form__input"
            />

            {hasFieldsOfStudyOptions && (
                <SearchableValueInput
                    isSearchable={hasFieldsOfStudyOptions}
                    showResultsOnClick
                    label={trans('forms.new.schoolExperience.input.fieldOfStudy')}
                    required
                    options={educationLevelFieldOfStudyOptions}
                    value={fieldOfStudy}
                    placeholder={hasFieldsOfStudyOptions
                        // TODO: Change this translation when you can add a fieldOfStudy manually while searching
                        ? trans('forms.new.schoolExperience.placeholder.fieldOfStudySearchable')
                        : trans('forms.new.schoolExperience.placeholder.fieldOfStudy')}
                    error={formErrors.fieldOfStudy}
                    onChange={handleFieldOfStudyChange}
                    className="school-experience-form__input"
                />
            )}

            {hasSchoolsOptions && (
                <SearchableValueInput
                    isSearchable={hasSchoolsOptions}
                    showResultsOnClick
                    label={trans('forms.new.schoolExperience.input.school')}
                    required
                    options={educationLevelSchoolOptions}
                    value={school}
                    placeholder={hasSchoolsOptions
                        // TODO: Change this translation when you can add a school manually while searching
                        ? trans('forms.new.schoolExperience.placeholder.schoolSearchable')
                        : trans('forms.new.schoolExperience.placeholder.school')}
                    error={formErrors.school}
                    onChange={handleSchoolChange}
                    className="school-experience-form__input"
                />
            )}

            <MonthYearInput
                label={trans('forms.new.schoolExperience.input.startDate')}
                name="start-date"
                required
                value={startDate}
                minYear={new Date().getFullYear() - maxEmploymentYears}
                maxYear={new Date().getFullYear()}
                error={formErrors.startDate}
                onChange={setStartDate}
                className="school-experience-form__input school-experience-form__input--start-date"
            />

            <MonthYearInput
                label={trans('forms.new.schoolExperience.input.endDate')}
                name="end-date"
                required
                value={endDate}
                minYear={startDate.getFullYear()}
                maxYear={startDate.getFullYear() + maxEmploymentYears}
                error={formErrors.endDate}
                onChange={setEndDate}
                className="school-experience-form__input school-experience-form__input--end-date"
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="school-experience-form__action-buttons"
            />
        </form>
    );
};

export default SchoolExperienceForm;
