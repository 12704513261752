import React from 'react';

import { Navigate } from 'react-router-dom';

import { InternshipVacancyIndex, InternshipVacancyOverview, VacancyDetail } from '../../pages';

export const internshipVacancyRoutePaths = {
    internships: () => '/stagevacatures',
    internshipsQuery: (query = ':query') => `/stagevacatures/${query}`,
    altInternships: () => '/stages',
    resetInternships: () => '/herstel-stagevacatures',

    internshipDetail: (vacancyUuid = ':vacancyUuid') => `/stagevacature/${vacancyUuid}`,
    internshipDetailTab: (vacancyUuid = ':vacancyUuid', tab = ':tab') => `/stagevacature/${vacancyUuid}/${tab}`,

    internshipVacancyIndex: () => '/alle-stagevacatures',
    internshipVacancyIndexProvince: (province = ':province') => `/alle-stagevacatures/provincie/${province}`,
    internshipVacancyIndexSector: (sector = ':sector', province = '') => `/alle-stagevacatures/sector/${sector}/${province}`,
    internshipVacancyIndexEducationLevel: (educationLevel = ':educationLevel', letter = '', fieldOfStudy = '', province = '') => {
        if (province) return `/alle-stagevacatures/${educationLevel}/${letter}/${fieldOfStudy}/${province}`;
        if (fieldOfStudy) return `/alle-stagevacatures/${educationLevel}/${letter}/${fieldOfStudy}`;

        return `/alle-stagevacatures/${educationLevel}/${letter}`;
    },
};

export const internshipVacancyRoutes = [
    {
        path: internshipVacancyRoutePaths.internships(),
        element: <InternshipVacancyOverview />,
        children: [
            { path: ':query', element: <InternshipVacancyOverview /> },
        ],
    },
    {
        path: internshipVacancyRoutePaths.altInternships(),
        element: <Navigate to={internshipVacancyRoutePaths.internships()} replace />,
    },
    {
        path: internshipVacancyRoutePaths.resetInternships(),
        element: <Navigate to={internshipVacancyRoutePaths.internships()} />,
    },
    {
        path: internshipVacancyRoutePaths.internshipDetail(),
        element: <VacancyDetail />,
        children: [
            { path: ':tab', element: <VacancyDetail /> },
        ],
    },
    {
        path: internshipVacancyRoutePaths.internshipVacancyIndex(),
        element: <InternshipVacancyIndex />,
        children: [
            {
                path: 'provincie/:province',
                element: <InternshipVacancyIndex />,
            },
            {
                path: 'sector/:sector',
                element: <InternshipVacancyIndex />,
                children: [
                    {
                        path: ':province',
                        element: <InternshipVacancyIndex />,
                    },
                ],
            },
            {
                path: ':educationLevel',
                element: <InternshipVacancyIndex />,
                children: [
                    {
                        path: ':letter',
                        element: <InternshipVacancyIndex />,
                        children: [
                            {
                                path: ':fieldOfStudy',
                                element: <InternshipVacancyIndex />,
                                children: [
                                    {
                                        path: ':province',
                                        element: <InternshipVacancyIndex />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
