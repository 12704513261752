import React, { FC, ReactElement } from 'react';

import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { FieldOfStudy } from '../../../../models/FieldsOfStudy';
import { Province } from '../../../../models/Provinces';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexFieldOfStudyCity.scss';

interface VacancyIndexFieldOfStudyCityProps {
    isLoading: boolean;
    fieldOfStudy: FieldOfStudy;
    province: Province;
    cityOptionColumns: string[][];
    className?: string;
}

const VacancyIndexFieldOfStudyCity: FC<VacancyIndexFieldOfStudyCityProps> = ({
    isLoading,
    fieldOfStudy,
    province,
    cityOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.fieldOfStudy.city', {
            fieldOfStudy: fieldOfStudy.name,
            province: province.name,
        })}
        className={`vacancy-index-field-of-study-city ${className}`}
    >
        <VacancyIndexColumnList>
            {cityOptionColumns.map(column => {
                const columnIndex = column.join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-field-of-study-city__city-list">
                            {column.map(city => {
                                const searchQuery = encodeSearchQuery({
                                    what: fieldOfStudy.name,
                                    province: province.slug,
                                    where: city,
                                });

                                return (
                                    <li key={city}>
                                        <VacancyIndexLink
                                            to={RoutePaths.internshipsQuery(searchQuery)}
                                            label={city}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexFieldOfStudyCity;
