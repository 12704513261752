import React, { FC, ReactElement } from 'react';

import { MenuListProps } from 'react-select';
import { FixedSizeList } from 'react-window';

import { SelectOption } from '../../../../../components/@inputs/Select/Select';

const ITEM_HEIGHT = 35;

type VirtualSelectMenuListProps = MenuListProps<SelectOption>;

const VirtualSelectMenuList: FC<VirtualSelectMenuListProps> = ({
    children,
    maxHeight,
}): ReactElement => {
    const itemCount = Array.isArray(children) ? children.length : 1;
    const menuSizeHeight = itemCount * ITEM_HEIGHT;
    const bestMenuHeight = menuSizeHeight >= maxHeight ? maxHeight : menuSizeHeight;

    return (
        <FixedSizeList width="100%" height={bestMenuHeight} itemCount={itemCount} itemSize={ITEM_HEIGHT}>
            {({ index, style }) => (
                <div style={style}>{Array.isArray(children) ? children[index] : children}</div>
            )}
        </FixedSizeList>
    );
};

export default VirtualSelectMenuList;
