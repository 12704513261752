import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../components';
import { DetailCard, Tag } from '../../../compositions';
import { retrieveUniqueValues } from '../../../helpers/array';
import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';

import './VacancyToLearnCard.scss';

interface VacancyToLearnCardProps {
    isLoading: boolean;
    candidate?: Candidate;
    competencies?: string[];
    skills?: string[];
    className?: string;
}

const VacancyToLearnCard: FC<VacancyToLearnCardProps> = ({
    isLoading,
    candidate,
    competencies = [],
    skills = [],
    className = '',
}): ReactElement => {
    const candidateCompetenciesToLearn = candidate
        ? candidate.competenciesToLearn.map(competency => competency.name)
        : [];

    const candidateSkillsToLearn = candidate
        ? candidate.skillsToLearn.map(skill => skill.name)
        : [];

    const uniqueCompetenciesToLearn = retrieveUniqueValues(competencies);
    const uniqueSkillsToLearn = retrieveUniqueValues(skills);

    const categories = [
        {
            title: trans('vacancyDetail.toLearn.competencies.title'),
            description: trans('vacancyDetail.toLearn.competencies.description'),
            nullState: trans('vacancyDetail.toLearn.competencies.nullState'),
            tags: uniqueCompetenciesToLearn,
            compareTags: candidateCompetenciesToLearn,
        },
        {
            title: trans('vacancyDetail.toLearn.skills.title'),
            description: trans('vacancyDetail.toLearn.skills.description'),
            nullState: trans('vacancyDetail.toLearn.skills.nullState'),
            tags: uniqueSkillsToLearn,
            compareTags: candidateSkillsToLearn,
        },
    ];

    return (
        <DetailCard
            title={trans('vacancyDetail.toLearn.title')}
            className={`vacancy-to-learn-card ${className}`}
        >
            <p className="vacancy-to-learn-card__description">
                {trans('vacancyDetail.toLearn.description')}
            </p>

            {categories.map(category => (
                <div key={category.title} className="vacancy-to-learn-card__category-wrapper">
                    <h2 className="vacancy-to-learn-card__category-title">
                        {category.title}
                    </h2>

                    <p className="vacancy-to-learn-card__category-description">
                        {category.description}
                    </p>

                    {isLoading && (
                        <div className="vacancy-to-learn-card__skeleton-wrapper">
                            <Skeleton className="vacancy-to-learn-card__skeleton" />
                        </div>
                    )}

                    {!isLoading && category.tags.length === 0 && (
                        <p className="vacancy-to-learn-card__category-null-state">
                            {category.nullState}
                        </p>
                    )}

                    {!isLoading && category.tags.length > 0 && (
                        <ul className="vacancy-to-learn-card__tag-list">
                            {category.tags.map(tag => {
                                const isActive = category.compareTags.includes(tag);

                                return (
                                    <li key={tag} className="vacancy-to-learn-card__tag-item">
                                        <Tag
                                            isActive={isActive}
                                            text={tag}
                                            className="vacancy-to-learn-card__tag"
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            ))}
        </DetailCard>
    );
};

export default VacancyToLearnCard;
