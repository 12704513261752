import React, { FC, ReactElement, useEffect } from 'react';

import { BlogContent } from '../../containers';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchBlogContent } from '../../store/blogDetail/blogDetailActions';

interface ConnectedBlogContentProps {
    slug: string;
    className?: string;
}

const ConnectedBlogContent: FC<ConnectedBlogContentProps> = ({
    slug,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.blogDetailReducer.isLoading);
    const content = useTypedSelector(state => state.blogDetailReducer.content);

    useEffect((): void => {
        dispatch(fetchBlogContent(slug));
    }, [slug]);

    return (
        <BlogContent
            isLoading={isLoading}
            content={content}
            className={className}
        />
    );
};

export default ConnectedBlogContent;
