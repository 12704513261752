export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export enum HorizontalAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum MessageState {
    positive = 'positive',
    warning = 'warning',
    negative = 'negative',
}

export enum SalaryTimeframe {
    hour = 'hour',
    month = 'month',
}

export enum CookieOption {
    necessary = 'necessary',
    statistics = 'statistics',
    liveChat = 'live-chat',
}

export interface Control<Key = string> {
    key: Key;
    label: string;
    icon?: string;
    to?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export interface FormProps<FormData> {
    isLoading?: boolean;
    error?: string;
    onSubmit: (formData: FormData) => void;
    onCancel: () => void;
}

export interface FormOption {
    label: string;
    value: string;
}

export interface SearchableOption extends FormOption {
    secondaryLabel?: string;
}

export interface MinMaxValue {
    min: number;
    max: number;
}

export enum StatusCode {
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    gone = 410,
    server = 500,
}

interface AsyncReduxProperties {
    isLoading: boolean;
    isSuccessful: boolean;
    error: string;
    statusCode?: StatusCode;
}

export type AsyncReduxState<T = unknown> = Readonly<{
    [P in keyof T]: T[P];
} & AsyncReduxProperties>;
