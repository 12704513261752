import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconTriangleSort: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 40 100" className={`icon ${className}`}>
        <path d="M40,30L20,0L0,30H40z M0,70l20,30l20-30H0z" />
    </svg>
);

export default IconTriangleSort;
