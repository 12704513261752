import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { initialAsyncReduxState } from '../../constants';
import { EditableVacancy } from '../../models/EditableVacancy';
import { NewestVacancy } from '../../models/NewestVacancy';
import { AsyncReduxState } from '../../types';

export type MyVacanciesState = AsyncReduxState<{
    vacancies: NewestVacancy[];
    pagination?: Pagination;
    vacancyToEdit?: EditableVacancy;
    recentlyUpdatedVacancy?: NewestVacancy;
}>;

const initialState: MyVacanciesState = {
    ...initialAsyncReduxState,
    vacancies: [],
    pagination: undefined,
    vacancyToEdit: undefined,
    recentlyUpdatedVacancy: undefined,
};

const myVacanciesSlice = createSlice({
    name: 'myVacanciesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): MyVacanciesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): MyVacanciesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setVacancies(state, action: PayloadAction<NewestVacancy[]>): MyVacanciesState {
            return {
                ...state,
                vacancies: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination | undefined>): MyVacanciesState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
        setVacancyToEdit(state, action: PayloadAction<EditableVacancy | undefined>): MyVacanciesState {
            return {
                ...state,
                vacancyToEdit: action.payload,
            };
        },
        setRecentlyUpdatedVacancy(state, action: PayloadAction<NewestVacancy | undefined>): MyVacanciesState {
            return {
                ...state,
                recentlyUpdatedVacancy: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setVacancies,
    setPagination,
    setVacancyToEdit,
    setRecentlyUpdatedVacancy,
} = myVacanciesSlice.actions;

export default myVacanciesSlice;
