import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { VacancyMatches } from '../../containers';
import { scrollToTop } from '../../helpers/scroll';
import { trans } from '../../helpers/trans';
import useQuery from '../../hooks/useQuery';
import { transformDriversLicenseToFormOption } from '../../models/DriversLicenses';
import { transformNewestVacancyToSearchableOption } from '../../models/NewestVacancy';
import { transformSectorToFormOption } from '../../models/Sectors';
import { RoleType } from '../../models/User';
import { defaultVacancyMatchesFilterValues, VacancyMatchesFilterValues } from '../../models/VacancyMatchesFilter';
import {
    useLegacyDispatch,
    useLegacySelector,
    useTypedDispatch,
    useTypedSelector,
} from '../../store';
import { fetchDriversLicenses } from '../../store/driversLicenses/driversLicensesActions';
import { fetchEducationLevels } from '../../store/educationLevels/educationLevelsActions';
import { fetchMyVacancies } from '../../store/myVacancies/myVacanciesActions';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { setActiveFilterValues } from '../../store/vacancyMatches/vacancyMatches';
import { clearVacancyMatches, fetchVacancyMatches } from '../../store/vacancyMatches/vacancyMatchesActions';
import { fetchWorkRemoteOptions } from '../../store/workRemote/workRemoteActions';
import { SearchableOption } from '../../types';

interface ConnectedVacancyMatchesProps {
    vacancyUuidFromUrl?: string;
    className?: string;
}

const ConnectedVacancyMatches: FC<ConnectedVacancyMatchesProps> = ({
    vacancyUuidFromUrl,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const legacyDispatch = useLegacyDispatch();
    const currentQuery = useQuery();

    const currentPage = Number(currentQuery.get('page')) || 1;

    const person = useLegacySelector(state => state.userPerson.data);
    const isWspUser = !!person?.is_wsp_user;

    const vacanciesIsLoading = useTypedSelector(state => state.myVacanciesReducer.isLoading);
    const vacancies = useTypedSelector(state => state.myVacanciesReducer.vacancies);

    const vacancyMatchesIsLoading = useTypedSelector(state => state.vacancyMatchesReducer.isLoading);
    const activeVacancy = useTypedSelector(state => state.vacancyMatchesReducer.activeVacancy);
    const activeFilterValues = useTypedSelector(state => state.vacancyMatchesReducer.activeFilterValues);
    const candidates = useTypedSelector(state => state.vacancyMatchesReducer.candidates);
    const error = useTypedSelector(state => state.vacancyMatchesReducer.error);
    const pagination = useTypedSelector(state => state.vacancyMatchesReducer.pagination);

    const educationLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationLevels);
    const educationSubLevelOptions = useTypedSelector(state => state.educationLevelsReducer.educationSubLevels);
    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);
    const driversLicenseOptions = useTypedSelector(state => state.driversLicensesReducer.driversLicenses);
    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);

    const vacancyOptions = useMemo<SearchableOption[]>(() => (
        vacancies.map(transformNewestVacancyToSearchableOption)
    ), [vacancies]);

    // TODO: Retrieve from back-end
    const candidateTypeOptions = [
        { label: trans('basic.candidateType.student'), value: RoleType.student },
        { label: trans('basic.candidateType.jobSeeker'), value: RoleType.jobSeeker },
    ];

    const [filterValues, setFilterValues] = useState<VacancyMatchesFilterValues>(activeFilterValues);

    useEffect((): () => void => {
        if (educationLevelOptions.length === 0) legacyDispatch(fetchEducationLevels());
        if (sectorOptions.length === 0) dispatch(fetchSectors());
        if (workRemoteOptions.length === 0) dispatch(fetchWorkRemoteOptions());
        if (driversLicenseOptions.length === 0) dispatch(fetchDriversLicenses());

        if (vacancies.length === 0) {
            dispatch(fetchMyVacancies({
                pageSize: 1000,
                pageNumber: 1,
                isArchive: false,
            }));
        }

        return (): void => {
            dispatch(clearVacancyMatches());
        };
    }, []);

    useEffect((): void => {
        if (vacancyUuidFromUrl) {
            dispatch(fetchVacancyMatches(vacancyUuidFromUrl));
        }
    }, [vacancyUuidFromUrl]);

    useEffect((): void => {
        scrollToTop();

        dispatch(setActiveFilterValues({
            ...filterValues,
            pageNumber: currentPage,
        }));
    }, [currentPage]);

    useEffect((): void => {
        if (activeVacancy) {
            dispatch(fetchVacancyMatches(activeVacancy.uuid));
        }
    }, [activeFilterValues]);

    const handleFilterChange = (value: Partial<VacancyMatchesFilterValues>): void => {
        setFilterValues({
            ...filterValues,
            ...value,
        });
    };

    const handleFilterReset = (): void => {
        const defaultFilters = defaultVacancyMatchesFilterValues();

        setFilterValues(defaultFilters);
        dispatch(setActiveFilterValues(defaultFilters));
    };

    const handleFilterSubmit = (): void => {
        dispatch(setActiveFilterValues({
            ...filterValues,
            pageNumber: 1,
        }));
    };

    return (
        <VacancyMatches
            isLoading={vacanciesIsLoading || vacancyMatchesIsLoading}
            isWspUser={isWspUser}
            vacancyUuidFromUrl={vacancyUuidFromUrl}
            activeVacancy={activeVacancy}
            filterValues={filterValues}
            activeFilterValues={activeFilterValues}
            candidates={candidates}
            amountOfCandidates={pagination?.count}
            vacancyOptions={vacancyOptions}
            candidateTypeOptions={candidateTypeOptions}
            educationLevelOptions={educationLevelOptions}
            educationSubLevelOptions={educationSubLevelOptions}
            sectorOptions={sectorOptions.map(transformSectorToFormOption)}
            workRemoteOptions={workRemoteOptions}
            driversLicenseOptions={driversLicenseOptions.filter(license => license.type).map(transformDriversLicenseToFormOption)}
            error={error}
            pagination={pagination}
            onFilterChange={handleFilterChange}
            onFilterReset={handleFilterReset}
            onFilterSubmit={handleFilterSubmit}
            className={className}
        />
    );
};

export default ConnectedVacancyMatches;
