import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { dataLayerPush } from '../../helpers/analytics';
import { trans } from '../../helpers/trans';
import { ApplicationRejectionFormData } from '../../models/ApplicationRejections';
import { ApplicationFormData, ApplicationStatus } from '../../models/Applications';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import {
    createApplicationApiCall,
    deleteApplicationApiCall,
    getApplicationApiCall,
    patchApplicationStatusApiCall,
    rejectApplicationApiCall,
} from '../../services/ApplicationService';
import { getVacancyApiCall } from '../../services/VacancyService';
import { TypedDispatch } from '..';
import { setApplicationInvite } from '../applicationInvite/applicationInvite';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import {
    setApplication,
    setApplicationVacancy,
    setError,
    setIsLoading,
    setIsSuccessful,
    setStatusCode,
} from './application';

export const clearApplication = () => (dispatch: TypedDispatch): void => {
    dispatch(setApplication(undefined));
    dispatch(setApplicationVacancy(undefined));
    dispatch(setStatusCode(undefined));
};

export const clearApplicationIsSuccessful = () => (dispatch: TypedDispatch): void => {
    dispatch(setIsSuccessful(false));
};

export const fetchApplication = (applicationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const applicationResponse = await getApplicationApiCall(applicationUuid);

        if (!isFetchResultSuccessful(applicationResponse)) {
            console.error('[fetchApplication]', applicationResponse.error);
            dispatch(setStatusCode(applicationResponse.status));
            dispatch(setError(applicationResponse.error));
            return;
        }

        const application = applicationResponse.data;
        const activeInvite = application.invites?.[0];

        dispatch(setApplication(application));
        dispatch(setApplicationInvite(activeInvite));
    } catch (error) {
        console.error('[fetchApplication]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchApplicationWithVacancy = (applicationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const applicationResponse = await getApplicationApiCall(applicationUuid);

        if (!isFetchResultSuccessful(applicationResponse)) {
            console.error('[fetchApplicationWithVacancy]', applicationResponse.error);
            dispatch(setStatusCode(applicationResponse.status));
            dispatch(setError(applicationResponse.error));
            return;
        }

        const application = applicationResponse.data;
        const activeInvite = application.invites?.[0];

        dispatch(setApplication(application));
        dispatch(setApplicationInvite(activeInvite));

        const vacancyUuid = applicationResponse.data?.vacancy?.uuid;

        if (vacancyUuid) {
            const vacancyResponse = await getVacancyApiCall(vacancyUuid);

            if (!isFetchResultSuccessful(vacancyResponse)) {
                console.error('[fetchApplicationWithVacancy]', vacancyResponse.error);
                dispatch(setStatusCode(vacancyResponse.status));
                dispatch(setError(vacancyResponse.error));
                return;
            }

            dispatch(setApplicationVacancy(vacancyResponse.data));
        }
    } catch (error) {
        console.error('[fetchApplicationWithVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createApplication = (formData: ApplicationFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const createApplicationResponse = await createApplicationApiCall(formData);

        if (!isFetchResultSuccessful(createApplicationResponse)) {
            console.error('[createApplication]', createApplicationResponse.error);
            dispatch(setError(createApplicationResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        const application = createApplicationResponse.data;
        const formType = application.vacancy.type === VacancyType.internship
            ? 'application-internship'
            : 'application-job';

        dataLayerPush({
            translationID: application.uuid,
            event: 'formSubmission',
            formType,
        });

        dispatch(addPositiveToast({
            title: trans('toast.application.create.success.title'),
            description: trans('toast.application.create.success.description'),
        }));

        dispatch(setApplication(application));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createApplication]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteApplication = (applicationUuid: string, callback: () => void) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const deleteApplicationResponse = await deleteApplicationApiCall(applicationUuid);

        if (!isFetchResultSuccessful(deleteApplicationResponse)) {
            console.error('[deleteApplication]', deleteApplicationResponse.error);
            dispatch(setError(deleteApplicationResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.application.delete.success.description'),
        }));

        callback();
    } catch (error) {
        console.error('[deleteApplication]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const matchApplication = (applicationUuid: string) => async (dispatch: TypedDispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const matchApplicationResponse = await patchApplicationStatusApiCall(applicationUuid, ApplicationStatus.hired);

        if (!isFetchResultSuccessful(matchApplicationResponse)) {
            console.error('[matchApplication]', matchApplicationResponse.error);
            dispatch(setError(matchApplicationResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        const application = matchApplicationResponse.data;

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.application.match.success.description'),
        }));

        dataLayerPush({
            event: 'formSubmission',
            formType: 'match-found',
        });

        dispatch(setApplication(application));
    } catch (error) {
        console.error('[matchApplication]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const rejectApplication = (applicationUuid: string, formData: ApplicationRejectionFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const rejectApplicationResponse = await rejectApplicationApiCall(applicationUuid, formData);

        if (!isFetchResultSuccessful(rejectApplicationResponse)) {
            console.error('[rejectApplication]', rejectApplicationResponse.error);
            dispatch(setError(rejectApplicationResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.application.reject.success.description'),
        }));
    } catch (error) {
        console.error('[rejectApplication]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
