import React, { FC, ReactElement } from 'react';

import { Icon, LinkButton } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './BlogCategoryList.scss';

interface BlogCategoryListProps {
    categories: string[];
    categorySizes: Record<string, number>;
    currentCategory: string;
    className?: string;
}

const BlogCategoryList: FC<BlogCategoryListProps> = ({
    categories,
    categorySizes,
    currentCategory,
    className = '',
}): ReactElement | null => {
    const otherCategories = categories.filter(category => category !== currentCategory);

    if (otherCategories.length === 0) {
        return null;
    }

    return (
        <section className={`blog-category-list ${className}`}>
            <h1 className="blog-category-list__title">
                {trans('blog.categories.title')}
            </h1>
            <ul className="blog-category-list__list">
                {otherCategories.map(category => (
                    <li key={category} className="blog-category-list__item">
                        <LinkButton
                            to={RoutePaths.blogCategory(category)}
                            text={category}
                            className="blog-category-list__button"
                        >
                            <span className="blog-category-list__button-label">
                                {category}
                            </span>

                            <div className="blog-category-list__info-wrapper">
                                <span className="blog-category-list__counter">
                                    {trans('blog.categories.blogAmount', {
                                        amount: categorySizes[category] ?? 0,
                                    })}
                                </span>
                                <Icon name="chevron-right" />
                            </div>
                        </LinkButton>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default BlogCategoryList;
