import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classnames from 'classnames';

import './Input.scss';

interface InputProps extends RefAttributes<HTMLInputElement> {
    error?: string;
}

export type HTMLInputProps = JSX.IntrinsicElements['input'] & InputProps;

const Input: ForwardRefExoticComponent<HTMLInputProps> = forwardRef(({
    type = 'text',
    error = '',
    className = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const inputClassName = classnames('input', {
        'input--has-error': !!error,
        'input--number': type === 'number',
    }, className);

    return (
        <input
            {...inputProps}
            ref={ref}
            type={type}
            className={inputClassName}
        />
    );
});

export default Input;
