import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Chat, ChatConnection } from '../../models/Chat';
import { AsyncReduxState } from '../../types';

export type ChatsState = AsyncReduxState<{
    chatConnections: ChatConnection[];
    chats: Chat[];
    activeChat?: Chat;
}>;

const initialState: ChatsState = {
    ...initialAsyncReduxState,
    chatConnections: [],
    chats: [],
    activeChat: undefined,
};

const chatsSlice = createSlice({
    name: 'chatsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ChatsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ChatsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setChatConnections(state, action: PayloadAction<ChatConnection[]>): ChatsState {
            return {
                ...state,
                chatConnections: action.payload,
            };
        },
        setChats(state, action: PayloadAction<Chat[]>): ChatsState {
            return {
                ...state,
                chats: action.payload,
            };
        },
        setActiveChat(state, action: PayloadAction<Chat>): ChatsState {
            return {
                ...state,
                activeChat: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setChatConnections,
    setChats,
    setActiveChat,
} = chatsSlice.actions;

export default chatsSlice;
