// @ts-ignore
import styled, { css } from 'styled-components';

export const Styles = styled('div')(() => css`
    .small-card-with-image {
        width: 206px;
        height: 126px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
`);
