/* eslint-disable camelcase */
import { sanitizeHtml } from '../../../helpers/sanitizeHtml';
import { Address, AddressResponseApiParams, transformToAddress } from './Address';
import { Competence, CompetenceApiParams } from './Competence';
import { InternshipPreferences, InternshipPreferencesApiParams, transformToInternshipPreferences } from './InternshipPreferences';
import {
    JobExperience,
    JobExperienceApiParams,
    transformToApiJobExperienceRequest,
    transformToJobExperience,
} from './JobExperience';
import { JobPreferences, JobPreferencesApiParams, transformToJobPreferences } from './JobPreferences';
import {
    LearningPath,
    SchoolExperience,
    SchoolExperienceApiRequestParams,
    SchoolExperienceApiResponseParams,
    transformToSchoolExperience,
} from './SchoolExperience';
import Skill, { SkillApiParams } from './Skill';

export interface PersonApiResponseParams {
    uuid: string;
    address_id: string;
    gender: string;
    first_name: string;
    insertion: string;
    last_name: string;
    full_name: string;
    birthday: string;
    phone_number: string;
    personal_message: string;
    avatar?: string;
    video: string;
    person_settings_id: string;
    employee_profile_id: string;
    profile_complete_percentage: number;
    address: AddressResponseApiParams;
    skills: SkillApiParams[];
    skills_to_learn: SkillApiParams[];
    escoSkills: SkillApiParams[];
    competencies: CompetenceApiParams[];
    competencies_to_learn: CompetenceApiParams[];
    school_experiences: SchoolExperienceApiResponseParams[];
    job_experiences: JobExperienceApiParams[];
    job_preferences_set: JobPreferencesApiParams;
    internship_preferences_set: InternshipPreferencesApiParams;
    personal_catchphrase: string;
    user: any;
    match_details?: any;
    is_llo_user: boolean;
    is_sbb_user: boolean;
    can_filter_llo: boolean;
}

// TODO: Move Matching Candidate specific types to matching candidate
export interface PersonApiRequestParams {
    uuid: string;
    address_id: string;
    gender: string;
    first_name: string;
    insertion: string;
    last_name: string;
    birthday: string;
    phone_number: string;
    personal_message: string;
    avatar?: string;
    video: string;
    profile_complete_percentage: number;
    skills: SkillApiParams[];
    skills_to_learn: SkillApiParams[];
    escoSkills: SkillApiParams[];
    competencies: CompetenceApiParams[];
    competencies_to_learn: CompetenceApiParams[];
    school_experiences?: SchoolExperienceApiRequestParams[];
    job_experiences?: JobExperienceApiParams[];
    job_preferences_set: JobPreferencesApiParams;
    internship_preferences_set: InternshipPreferencesApiParams;
    personal_catchphrase: string;
}

export interface Person {
    uuid: string;
    address_id: string;
    gender: string;
    birthday: string;
    first_name: string;
    insertion: string;
    last_name: string;
    full_name: string;
    phone_number: string;
    personal_message: string;
    avatar?: string;
    video: string;
    profile_complete_percentage: number;
    skills: Skill[];
    skills_to_learn: Skill[];
    esco_skills: Skill[];
    competencies: Competence[];
    competencies_to_learn: Competence[];
    address?: Address;
    person_settings_id: string;
    employee_profile_id: string;
    employee_profile?: any;
    personal_catchphrase: string;
    internship_preferences_set: InternshipPreferences;
    job_preferences_set: JobPreferences;
    school_experiences?: SchoolExperience[];
    job_experiences?: JobExperience[];
    user: any;
    match_details?: any;
    is_llo_user: boolean;
    is_sbb_user: boolean;
    is_wsp_user: boolean;
}

export function transformPerson(person: PersonApiResponseParams): Person {
    return {
        ...person,
        uuid: person.uuid,
        address_id: person.address_id,
        gender: person.gender,
        first_name: person.first_name,
        insertion: person.insertion,
        last_name: person.last_name,
        full_name: person.full_name,
        birthday: person.birthday,
        phone_number: person.phone_number,
        personal_message: sanitizeHtml(person.personal_message || ''),
        avatar: person.avatar
            ? `${process.env.REACT_APP_API_V1_URL}/storage/avatars/${person.avatar}`
            : '',
        video: person.video,
        profile_complete_percentage: person.profile_complete_percentage,
        skills: person.skills,
        skills_to_learn: person.skills_to_learn,
        esco_skills: person.escoSkills,
        competencies: person.competencies,
        competencies_to_learn: person.competencies_to_learn,
        school_experiences: person.school_experiences
            ? person.school_experiences.map(transformToSchoolExperience)
            : [],
        person_settings_id: person.person_settings_id,
        employee_profile_id: person.employee_profile_id,
        job_experiences: person.job_experiences
            ? person.job_experiences.map(transformToJobExperience)
            : undefined,
        address: person.address
            ? transformToAddress(person.address)
            : undefined,
        job_preferences_set: transformToJobPreferences(person.job_preferences_set),
        internship_preferences_set: transformToInternshipPreferences(person.internship_preferences_set),
        personal_catchphrase: sanitizeHtml(person.personal_catchphrase),
        is_llo_user: person.is_llo_user,
        is_sbb_user: person.is_sbb_user,
        is_wsp_user: person.can_filter_llo,
    };
}

export function transformToApiPersonRequest(person: Person): PersonApiRequestParams {
    return {
        ...person,
        escoSkills: person.esco_skills,
        avatar: person?.avatar || undefined,
        job_experiences: person.job_experiences ? person.job_experiences.map(jobExperience => transformToApiJobExperienceRequest(jobExperience)) : undefined,
        school_experiences: typeof person.school_experiences !== 'undefined' ? person.school_experiences.map((schoolExperience: SchoolExperience) => ({
            field_of_study_id: schoolExperience.field_of_study_id,
            field_of_study_name: schoolExperience.field_of_study_name,
            school_id: schoolExperience.school_id,
            school_name: schoolExperience.school_name,
            education_level_id: schoolExperience.education_level_id,
            education_level_name: schoolExperience.education_level_name,
            ...(schoolExperience.learning_path_id !== LearningPath.Other && { learning_path_id: schoolExperience.learning_path_id }),
            start_date: schoolExperience.start_date,
            end_date: schoolExperience.end_date,
        })) : undefined,
    };
}
/* eslint-enable camelcase */
