import React, {
    FC,
    ReactElement,
    SyntheticEvent,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon } from '../../components';
import { fallbackAvatar } from '../../constants';
import { getInitials } from '../../helpers/string';

import './Avatar.scss';

export interface AvatarProps {
    isAnonymous?: boolean;
    isCover?: boolean;
    src?: string;
    alt: string;
    fallbackString?: string;
    className?: string;
}

const Avatar: FC<AvatarProps> = ({
    isAnonymous,
    isCover,
    src,
    alt,
    fallbackString = '',
    className = '',
}): ReactElement => {
    const [showFallbackInitials, setShowFallbackInitials] = useState<boolean>(!src && !!fallbackString);

    const initials = useMemo((): string => (
        getInitials(fallbackString)
    ), [fallbackString]);

    const setPlaceholderImage = (event: SyntheticEvent<HTMLImageElement>): void => {
        if (fallbackString) {
            setShowFallbackInitials(true);

            return;
        }

        if (event.currentTarget && event.currentTarget.src !== fallbackAvatar) {
            event.currentTarget.src = fallbackAvatar;
        }
    };

    const avatarClassNames = classNames('avatar', {
        'avatar--has-initials': showFallbackInitials,
        'avatar--is-anonymous': isAnonymous,
    }, className);

    const imageClassNames = classNames('avatar__image', {
        'avatar__image--is-cover': isCover,
    });

    return (
        <div className={avatarClassNames}>
            {isAnonymous && (
                <Icon
                    name="anonymous-star"
                    accentClassName="avatar__anonymous-star-accent"
                    className="avatar__anonymous-star"
                />
            )}

            {!isAnonymous && (
                showFallbackInitials ? (
                    <h3 data-initials={initials} className="avatar__initials">
                        {initials}
                    </h3>
                ) : (
                    <img
                        src={src}
                        alt={alt}
                        onError={setPlaceholderImage}
                        className={imageClassNames}
                    />
                )
            )}
        </div>
    );
};

export default Avatar;
