import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useLocalStorage } from 'react-use';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { userTriedTalentMatchingKey } from '../../constants';
import { VacancyOverviewHeader } from '../../containers';
import { getActiveFilterValues } from '../../helpers/filter';
import { getAmountOfDefinedValues } from '../../helpers/object';
import { defaultJobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { useTypedSelector } from '../../store';

interface ConnectedJobVacancyOverviewHeaderProps {
    isTalentMatching: boolean;
    talentMatchingToggle: (isActive: boolean) => void;
    className?: string;
}

const ConnectedJobVacancyOverviewHeader: FC<ConnectedJobVacancyOverviewHeaderProps> = ({
    isTalentMatching,
    talentMatchingToggle,
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.jobVacancyOverviewReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.jobVacancyOverviewReducer.activeFilterValues);
    const pagination = useTypedSelector(state => state.jobVacancyOverviewReducer.pagination);

    const provinceOptions = useTypedSelector(state => state.provincesReducer.provinces);

    const [selectedProvince, setSelectedProvince] = useState<string>('');
    const [userTriedTalentMatching, setUserTriedTalentMatching] = useLocalStorage(userTriedTalentMatchingKey, false);

    useEffect((): void => {
        if (isTalentMatching) setUserTriedTalentMatching(true);
    }, [isTalentMatching]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pageNumber, pageSize, ...restFilters } = activeFilterValues;

    const defaultFilters = defaultJobVacancyOverviewFilterValues();
    const activeFilters = getActiveFilterValues(restFilters, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    useEffect((): void => {
        const province = provinceOptions.find(option => option.slug === activeFilters?.province);

        if (province) {
            setSelectedProvince(province.name);
        }
    }, [provinceOptions, activeFilters]);

    return (
        <VacancyOverviewHeader
            isTalentMatching={isTalentMatching}
            isLoading={isLoading}
            hasActiveFilters={amountOfActiveFilters > 0}
            showTalentMatchingQuote={!userTriedTalentMatching}
            type={VacancyType.job}
            what={activeFilterValues.what}
            province={selectedProvince}
            where={activeFilterValues.where}
            pagination={pagination}
            talentMatchingToggle={talentMatchingToggle}
            className={className}
        />
    );
};

export default ConnectedJobVacancyOverviewHeader;
