import React, { FC, ReactElement } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { hotjarEvent } from '../../../../../helpers/hotjar';
import { trans } from '../../../../../helpers/trans';
import { RoutePaths } from '../../../../../routes';
import { useLegacyDispatch, useLegacySelector } from '../../../../../store';
import Button from '../../../../components/Button';
import MultiStepOverlay from '../../../../components/MultiStepOverlay';
import { Heading, Paragraph } from '../../../../styledComponents/components/Typography';
import { logIn } from '../../../store/legacyUser/legacyUserActions';
import { setStep as setRegisterStep, setUserType } from '../../../store/register/register';
import { getLinkedInConnectUrl, registerUser } from '../../../store/register/registerActions';
import { RegisterCloseBehaviour } from '../../../store/register/RegisterActionTypes';
import { setStep as setResetPasswordStep } from '../../../store/resetPassword/resetPassword';
import RegisterUser from '../../components/forms/RegisterUser';
import SelectUserType from '../../components/forms/SelectUserType/SelectUserType';

const EmailSentIcon = styled.img`
    display: block;
    margin: 0 auto;
`;

const Register: FC = (): ReactElement | null => {
    const legacyDispatch = useLegacyDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as { noLinkedInUser?: boolean };

    const isLoading = useLegacySelector(state => state.register.loading);
    const userType = useLegacySelector(state => state.register.userType);
    const step = useLegacySelector(state => state.register.step);
    const errors = useLegacySelector(state => state.register.errors);

    const applyAnonymous = useLegacySelector(state => state.register.applyAnonymous);
    const registerSuccessData = useLegacySelector(state => state.register.registerSuccessData);

    const closeBehaviour = useLegacySelector(state => state.register.closeBehaviour);

    const handleLinkedInConnectUrl = (): void => {
        if (typeof window !== 'undefined') {
            legacyDispatch(getLinkedInConnectUrl());

            hotjarEvent('vpv', `/registreren/linkedin/${userType}`);
        }
    };

    const handleLogin = (): void => {
        legacyDispatch(setRegisterStep(null));
        legacyDispatch(setResetPasswordStep(null));
        legacyDispatch(logIn(true));
    };

    const handleRegisterUser = (data: any): void => {
        hotjarEvent('vpv', `/registreren/email/${userType}`);

        legacyDispatch(registerUser(data));
    };

    const handleUserSelect = (currentUserType: string): void => {
        legacyDispatch(setUserType(currentUserType));
        legacyDispatch(setRegisterStep(1));

        hotjarEvent('vpv', `/registreren/type/${currentUserType}`);
    };

    const handleResetRegisterStep = (): void => {
        legacyDispatch(setRegisterStep(0));
    };

    const handleFinishRegister = (): void => {
        legacyDispatch(setRegisterStep(null));

        navigate(RoutePaths.home());
    };

    const registerSteps = [
        {
            id: 'register-userType',
            step: 0,
            ...(!applyAnonymous && {
                message: {
                    title: trans('basic.register.introTitle'),
                    body: trans('basic.register.introText'),
                },
            }),
            component: (
                <SelectUserType
                    applyAnonymous={applyAnonymous}
                    noLinkedInUser={!!locationState?.noLinkedInUser}
                    onSelect={handleUserSelect}
                    onLoginClick={handleLogin}
                />
            ),
        },
        {
            id: `register-user-${userType}`,
            step: 1,
            message: {
                // The MultiStepOverlay is so incredibly messed up that removing a title property (which should do nothing crazy when removed)
                // will crash the entire page when pressing back ("Vorige") under the model..
                // We fix this by doing a white space and setting the css margin-top to -20px in order to hide this...
                title: ' ',
                body: userType ? (
                    <>
                        <Heading as="h2" color="white" size="xl" width="100%" mb={['md', 'md', 'md', 'md', 'lg']}>
                            {trans(`basic.register.${userType}.title`)}
                        </Heading>
                        <Paragraph size="md" width={1} fontType="bold" color="white">
                            {trans(`basic.register.${userType}.body`)}
                        </Paragraph>
                    </>
                ) : '',
            },
            component: (
                <RegisterUser
                    loading={isLoading}
                    errors={errors}
                    getLinkedInConnectUrl={handleLinkedInConnectUrl}
                    onLoginClick={handleLogin}
                    userType={userType || ''}
                    onPrev={handleResetRegisterStep}
                    onSubmit={handleRegisterUser}
                />
            ),
        },
        {
            id: `register-user-${userType}-emailSent`,
            step: 2,
            component: (
                <>
                    <EmailSentIcon src="/images/email-verstuurd.svg" alt={trans('basic.registerEmail.emailIcon')} />
                    <Heading textAlign="center" as="h3" size="md" mt="xl">
                        {trans('basic.registerEmail.emailSent')}
                    </Heading>
                    <Paragraph textAlign="center" size="sm" mt="md">
                        {trans('basic.registerEmail.emailSpam')}
                    </Paragraph>
                    <Button
                        color="secondary"
                        mt="xl"
                        // @ts-ignore
                        ml="auto"
                        mr="auto"
                        icon="backward"
                        iconSize={14}
                        onClick={handleFinishRegister}
                    >
                        {trans('actions.backToHome')}
                    </Button>

                    {registerSuccessData && (
                        <input type="hidden" id="testerActivationUrl" value={registerSuccessData.activation_url} />
                    )}
                </>
            ),
        },
    ];

    const handleCloseClick = (): void => {
        legacyDispatch(setRegisterStep(null));

        if (closeBehaviour === RegisterCloseBehaviour.RedirectToHome) {
            navigate(RoutePaths.home());
        }
    };

    if (step === null) {
        return null;
    }

    const activeStep = registerSteps[step];

    return activeStep ? (
        <MultiStepOverlay
            id={activeStep.id}
            step={step}
            message={activeStep.message}
            onClose={handleCloseClick}
        >
            {activeStep.component}
        </MultiStepOverlay>
    ) : null;
};

export default Register;
