import React, { FC, FormEvent, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { Button } from '../../../../components';
import {
    ButtonList,
    CandidateTypesFilter,
    DistanceFilter,
    DriversLicensesFilter,
    EducationLevelsFilter,
    EmploymentFilter,
    SectorsFilter,
    SubmitButton,
    WorkRemotePreferenceFilter,
} from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../../../models/EducationLevels';
import { VacancyMatchesFilterValues } from '../../../../models/VacancyMatchesFilter';
import { WorkRemoteOption } from '../../../../models/WorkRemote';
import { FormOption, MinMaxValue } from '../../../../types';

import './VacancyMatchesFilterMobile.scss';

interface VacancyMatchesFilterMobileProps {
    hasLLOFilter?: boolean;
    vacancyType?: VacancyType;
    filterValues: VacancyMatchesFilterValues;
    candidateTypeOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    sectorOptions: FormOption[];
    workRemoteOptions: WorkRemoteOption[];
    driversLicenseOptions: FormOption[];
    onChange: (values: Partial<VacancyMatchesFilterValues>) => void;
    onReset: () => void;
    onSubmit: () => void;
    className?: string;
}

const VacancyMatchesFilterMobile: FC<VacancyMatchesFilterMobileProps> = ({
    hasLLOFilter,
    vacancyType,
    filterValues,
    candidateTypeOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    sectorOptions,
    workRemoteOptions,
    driversLicenseOptions,
    onChange,
    onReset,
    onSubmit,
    className = '',
}): ReactElement => {
    const handleCandidateTypesChange = (value: string[]): void => {
        onChange({ candidateTypes: value });
    };

    const handleEmploymentPreferenceChange = (value: MinMaxValue): void => {
        onChange({ employmentPreferences: value });
    };

    const handleEducationLevelsChange = (value: string[]): void => {
        onChange({ educationLevels: value });
    };

    const handleSectorsChange = (value: string[]): void => {
        onChange({ sectors: value });
    };

    const handleWorkRemotePreferencesChange = (value: string[]): void => {
        onChange({ workRemotePreference: value });
    };

    const handleDriversLicensesChange = (value: string[]): void => {
        onChange({ driversLicenses: value });
    };

    const handleTravelDistanceChange = (value: number): void => {
        onChange({ travelDistance: value });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className={`vacancy-matches-filter-mobile ${className}`}>
            {vacancyType === VacancyType.job && (
                <CandidateTypesFilter
                    hasLLO={hasLLOFilter}
                    label={trans('forms.preferences.candidateType')}
                    value={filterValues.candidateTypes}
                    candidateTypes={candidateTypeOptions}
                    onChange={handleCandidateTypesChange}
                    className="vacancy-matches-filter-mobile__input"
                />
            )}

            <EmploymentFilter
                label={trans('forms.preferences.employmentPreferences')}
                value={filterValues.employmentPreferences}
                onChange={handleEmploymentPreferenceChange}
                className="vacancy-matches-filter-mobile__input vacancy-matches-filter-mobile__employment-preference-slider"
            />

            <EducationLevelsFilter
                label={trans('forms.preferences.educationLevel')}
                name="education-levels"
                value={filterValues.educationLevels}
                educationLevels={educationLevelOptions}
                educationSubLevels={educationSubLevelOptions}
                onChange={handleEducationLevelsChange}
                className="vacancy-matches-filter-mobile__input"
            />

            <SectorsFilter
                label={trans('forms.preferences.sectors')}
                value={filterValues.sectors}
                sectors={sectorOptions}
                onChange={handleSectorsChange}
                className="vacancy-matches-filter-mobile__input"
            />

            <WorkRemotePreferenceFilter
                label={trans('forms.preferences.workRemotePreference')}
                value={filterValues.workRemotePreference}
                workRemoteOptions={workRemoteOptions}
                onChange={handleWorkRemotePreferencesChange}
                className="vacancy-matches-filter-mobile__input"
            />

            <DriversLicensesFilter
                label={trans('forms.preferences.driversLicenses')}
                value={filterValues.driversLicenses}
                driversLicenses={driversLicenseOptions}
                onChange={handleDriversLicensesChange}
                className="vacancy-matches-filter-mobile__input"
            />

            <DistanceFilter
                label={trans('forms.preferences.travelDistance')}
                value={filterValues.travelDistance}
                onChange={handleTravelDistanceChange}
                className="vacancy-matches-filter-mobile__input vacancy-matches-filter-mobile__travel-distance"
            />

            <ButtonList className="vacancy-matches-filter-mobile__button-wrapper">
                <Button
                    text={trans('containers.vacancyMatches.filter.button.clear')}
                    onClick={onReset}
                    className="vacancy-matches-filter-mobile__button-reset"
                />

                <SubmitButton text={trans('containers.vacancyMatches.filter.button.apply')} />
            </ButtonList>
        </form>
    );
};

export default VacancyMatchesFilterMobile;
