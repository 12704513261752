import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { logIn, logOut } from '../../_old/app_talentz/store/legacyUser/legacyUserActions';
import { setStep as setRegisterStep } from '../../_old/app_talentz/store/register/register';
import { setStep as setResetPasswordStep } from '../../_old/app_talentz/store/resetPassword/resetPassword';
import { ConfirmEmailAddressChange } from '../../containers';
import { LEGACY_TOKEN_KEY } from '../../helpers/authorizedFetch';
import useUnmount from '../../hooks/useUnmount';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { clearEmailAddressChange, confirmEmailAddressChange } from '../../store/accountSettings/accountSettingsActions';

interface ConnectedConfirmEmailAddressChangeProps {
    confirmationKey?: string;
    className?: string;
}

const ConnectedConfirmEmailAddressChange: FC<ConnectedConfirmEmailAddressChangeProps> = ({
    confirmationKey,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const [authenticationToken] = useLocalStorage(LEGACY_TOKEN_KEY);

    const role = useLegacySelector(state => state.legacyUser.role);

    const userRole = role || RoleType.none;
    const isAuthenticated = userRole !== RoleType.none;
    const isCandidate = [RoleType.student, RoleType.jobSeeker].includes(userRole);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const company = useTypedSelector(state => state.userReducer.company);

    const isLoading = useTypedSelector(state => state.accountSettingsReducer.isLoading);
    const error = useTypedSelector(state => state.accountSettingsReducer.error);
    const emailAddressChange = useTypedSelector(state => state.accountSettingsReducer.emailAddressChange);

    const hasUserData = useMemo<boolean>(() => (
        isCandidate ? !!candidate : !!company
    ), [isCandidate, candidate, company]);

    useUnmount((): void => {
        dispatch(clearEmailAddressChange());
    });

    useEffect((): void => {
        const shouldConfirmChange = authenticationToken
            ? hasUserData && !emailAddressChange
            : !emailAddressChange;

        if (confirmationKey && shouldConfirmChange) {
            dispatch(confirmEmailAddressChange(confirmationKey));
        }
    }, [
        confirmationKey,
        authenticationToken,
        hasUserData,
        emailAddressChange,
    ]);

    useEffect(() => (): void => {
        const isAuthenticated = userRole !== RoleType.none;

        if (isAuthenticated && emailAddressChange) {
            dispatch(logOut());
        }
    }, [confirmationKey, userRole, emailAddressChange]);

    const handleLoginClick = (): void => {
        navigate(RoutePaths.home());

        dispatch(setResetPasswordStep(null));
        dispatch(setRegisterStep(null));
        dispatch(logIn(true));
    };

    const handleLogoutClick = (): void => {
        navigate(RoutePaths.home());

        dispatch(logOut());
    };

    return (
        <ConfirmEmailAddressChange
            isLoading={isLoading}
            isAuthenticated={isAuthenticated}
            isCandidate={isCandidate}
            emailAddressChange={emailAddressChange}
            error={error}
            onLoginClick={handleLoginClick}
            onLogoutClick={handleLogoutClick}
            className={className}
        />
    );
};

export default ConnectedConfirmEmailAddressChange;
