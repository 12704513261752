import React, {
    ChangeEvent,
    DragEvent,
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useEffectOnce } from 'react-use';

import { ErrorLabel, InputLabel } from '../../../components';
import { convertBytesToLabel } from '../../../helpers/file';
import { trans } from '../../../helpers/trans';
import { IconButton } from '../..';
import { FilesToUploadList } from './subcomponents';

import './FileDropZone.scss';

export interface FileDropZoneValidation {
    maxFileAmount?: number;
    maxFileSize?: number;
    acceptedFileTypes?: string[];
}

interface FileDropZoneProps extends FileDropZoneValidation {
    label: string;
    hideLabel?: boolean;
    required?: boolean;
    value: File[];
    error?: string;
    onChange: (files: File[]) => void;
    className?: string;
}

const FileDropZone: FC<FileDropZoneProps> = ({
    label,
    hideLabel,
    required,
    value,
    maxFileAmount,
    maxFileSize,
    acceptedFileTypes = [],
    error,
    onChange,
    className = '',
}): ReactElement => {
    const [isEntering, setIsEntering] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<boolean>(false);

    // Rerender state for input when user changes/deletes input, so the same file can be uploaded again after deletion
    const [updateCount, setUpdateCount] = useState<number>(1);

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffectOnce(() => {
        document.addEventListener('dragover', () => setIsEntering(true));
        document.addEventListener('dragleave', () => setIsEntering(false));

        return () => {
            document.removeEventListener('dragover', () => setIsEntering(true));
            document.removeEventListener('dragleave', () => setIsEntering(false));
        };
    });

    const handleFileUpload = async (fileList?: FileList | null): Promise<void> => {
        setUpdateCount(updateCount + 1);

        const addedFiles = fileList ? Array.from(fileList) : [];
        const newFiles = [...value, ...addedFiles];

        onChange(newFiles);
    };

    const stopDefaultEvents = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleDragEnter = (event: DragEvent<HTMLDivElement>): void => {
        stopDefaultEvents(event);
        setIsHovering(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>): void => {
        stopDefaultEvents(event);
        setIsHovering(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
        stopDefaultEvents(event);
        setIsHovering(false);

        handleFileUpload(event.dataTransfer.files);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        handleFileUpload(event.currentTarget.files);
    };

    const handleDeleteFile = (fileToDelete: File): void => {
        const newFiles = [...value].filter(file => file !== fileToDelete);
        onChange(newFiles);
    };

    const handleAddClick = (): void => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const containerClassNames = classNames('file-drop-zone__drop-indicator', {
        'file-drop-zone__drop-indicator--is-entering': isEntering,
        'file-drop-zone__drop-indicator--is-hovering': isHovering,
    });

    return (
        <div
            onDragOver={handleDragEnter}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragLeave}
            onDrop={handleDrop}
            className={`file-drop-zone ${className}`}
        >
            {!hideLabel && (
                <InputLabel text={label} required={required} />
            )}

            <div className={containerClassNames}>
                <label aria-label={hideLabel ? label : undefined}>
                    <input
                        key={updateCount}
                        ref={fileInputRef}
                        type="file"
                        multiple
                        accept={acceptedFileTypes.join(',')}
                        onChange={handleChange}
                        className="file-drop-zone__input"
                    />
                </label>

                {value.length > 0 && (
                    <FilesToUploadList
                        files={value}
                        onDeleteFile={handleDeleteFile}
                        className="file-drop-zone__file-list"
                    />
                )}

                <IconButton
                    icon="plus"
                    text={trans('actions.addItem', { item: trans('basic.file') })}
                    onClick={handleAddClick}
                    className="file-drop-zone__add-button"
                />
            </div>

            {maxFileAmount && (
                <p className="file-drop-zone__restriction">
                    {trans('forms.apply.attachment.maxFileAmount', {
                        amountLimit: String(maxFileAmount),
                    })}
                </p>
            )}

            {maxFileSize && (
                <p className="file-drop-zone__restriction">
                    {trans('forms.apply.attachment.maxSize', {
                        sizeLimit: convertBytesToLabel(maxFileSize),
                    })}
                </p>
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="file-drop-zone__error-label"
                />
            )}
        </div>
    );
};

export default FileDropZone;
