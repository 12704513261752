import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton } from '../../../../../components';
import { IconButton, Modal, ModalContent } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { DriversLicense, DriversLicensesFormData } from '../../../../../models/DriversLicenses';
import { FormOption } from '../../../../../types';
import { DriversLicensesForm } from '../../../..';
import { ActivationRef } from '../../../ProfileProgress/ProfileProgress';
import { CandidatePreferencesCardSkeletons } from '../../skeletons';

import './DriversLicenses.scss';

interface DriversLicensesProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    driversLicenses: FormOption[];
    driversLicenseOptions: DriversLicense[];
    onEdit: (driversLicensesFormData: DriversLicensesFormData) => void;
    className?: string;
}

const DriversLicenses: FC<DriversLicensesProps> = ({
    activationRef,
    isLoading,
    isEditable,
    driversLicenses,
    driversLicenseOptions,
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditDriversLicenses = (driversLicensesFormData: DriversLicensesFormData): void => {
        onEdit(driversLicensesFormData);
        closeEditModal();
    };

    return (
        <div className={`drivers-licenses ${className}`}>
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            <h3 className="drivers-licenses__title">
                {trans('candidateProfile.driversLicenses.title')}
            </h3>

            {isLoading && (
                <CandidatePreferencesCardSkeletons
                    amount={2}
                    className="drivers-licenses__skeletons"
                />
            )}

            {!isLoading && driversLicenses.length === 0 && (
                <div className="drivers-licenses__null-state">
                    <p>{trans('candidateProfile.driversLicenses.nullState')}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('userProfile.content.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="drivers-licenses__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && driversLicenses.length > 0 && (
                <ul className="drivers-licenses__list">
                    {driversLicenses.map(driversLicense => (
                        <li key={driversLicense.value} className="drivers-licenses__list-item">
                            {driversLicense.label}
                        </li>
                    ))}
                </ul>
            )}

            {isEditable && driversLicenses.length > 0 && (
                <IconButton
                    icon="pencil"
                    text={trans('actions.edit')}
                    hideLabel
                    disabled={isLoading}
                    onClick={openEditModal}
                    className="drivers-licenses__edit-button"
                />
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.driversLicenses.modal.title')}>
                        <DriversLicensesForm
                            driversLicenses={driversLicenses}
                            driversLicenseOptions={driversLicenseOptions}
                            onCancel={closeEditModal}
                            onSubmit={handleEditDriversLicenses}
                        />
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default DriversLicenses;
