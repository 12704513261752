import React, { FC, ReactElement } from 'react';

import { Card, Icon } from '../../components';
import { RoleType } from '../../models/User';
import { roleInfoBenefitsContent } from './content';

import './RoleInfoBenefits.scss';

interface RoleInfoBenefitsProps {
    template: RoleType;
    className?: string;
}

const RoleInfoBenefits: FC<RoleInfoBenefitsProps> = ({
    template,
    className = '',
}): ReactElement => {
    const content = roleInfoBenefitsContent(template);

    return (
        <section className={`role-info-benefits ${className}`}>
            <div className="role-info-benefits__wrapper">
                <h1 className="role-info-benefits__title">
                    {content.title}
                </h1>

                <ul className="role-info-benefits__card-list">
                    {content.cards.map(card => (
                        <li key={card.title} className="role-info-benefits__card-item">
                            <Card className="role-info-benefits__card">
                                <div className="role-info-benefits__check">
                                    <Icon name="check" className="role-info-benefits__check-icon" />
                                </div>

                                <h2 className="role-info-benefits__card-title">
                                    {card.title}
                                </h2>

                                <p className="role-info-benefits__card-description">
                                    {card.text}
                                </p>
                            </Card>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="role-info-benefits__backdrop" />
        </section>
    );
};

export default RoleInfoBenefits;
