import React from 'react';

import PropTypes from 'prop-types';

import { Content } from './style';

const RichText = ({ content, as, id }) => (
    <Content data-hj-suppress as={as} id={id} dangerouslySetInnerHTML={{ __html: content }} />
);

RichText.propTypes = {
    content: PropTypes.string.isRequired,
    as: PropTypes.string,
    id: PropTypes.string,
};

RichText.defaultProps = {
    as: undefined,
    id: undefined,
};

export default RichText;
