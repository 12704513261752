import getIncluded from '../japi/getIncluded';
import { JapiDocument } from '../japi/types/Document';
import { SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { Address } from './Address';

interface KvkOwnerResource extends Resource {
    type: 'kvk-eigenaar';
    id: string;
    attributes: {
        adressen?: {
            huisnummer: number;
            indAfgeschermd: string;
            land: string;
            plaats: string;
            postcode: string;
            straatnaam: string;
            type: string;
            volledigAdres: string;
        }[];
        kvkNummer: string;
        rechtsvorm: string;
        rsin: string;
        uitgebreideRechtsvorm: string;
    };
}

interface KvkMainBranchResource extends Resource {
    type: 'kvk-hoofdvestiging';
    id: string;
    attributes: {
        adressen: {
            huisnummer?: number;
            indAfgeschermd: string;
            land: string;
            plaats: string;
            postbusnummer?: number;
            postcode: string;
            type: string;
            volledigAdres: string;
        }[];
        eersteHandelsnaam: string;
        indCommercieleVestiging: string;
        indHoofdvestiging: string;
        kvkNummer: string;
        materieleRegistratie: {
            datumAanvang: string;
        };
        vestigingsnummer: string;
    };
}

export interface KvkBaseProfileResource extends Resource {
    type: 'kvk-basisprofiel';
    id: string;
    attributes: {
        indNonMailing: string;
        kvkNummer: string;
        materieleRegistratie: {
            datumAanvang: string;
        };
        naam: string;
        sbiActiviteiten: {
            indHoofdactiviteit: string;
            sbiCode: string;
            sbiOmschrijving: string;
        }[];
    };
    relationships: {
        eigenaar: SingleRelationship<'kvk-eigenaar'>;
        hoofdvestiging: SingleRelationship<'kvk-hoofdvestiging'>;
    };
}

interface KvkOwner {
    uuid: string;
    kvkNumber: string;
    rsinNumber: string;
    legalEntity: string;
    address?: Address;
}

interface KvkMainBranch {
    uuid: string;
    name: string;
    kvkNumber: string;
    branchNumber: string;
    isCommercial: boolean;
    isMainBranch: boolean;
    address?: Address;
}

export interface KvkBaseProfile {
    uuid: string;
    name: string;
    kvkNumber: string;
    postalCode?: string;
    houseNumber?: string;
}

export const transformToKvkOwner = (
    kvkOwnerResource: KvkOwnerResource,
): KvkOwner => {
    const address = kvkOwnerResource.attributes.adressen?.length
        ? {
            city: kvkOwnerResource.attributes.adressen[0]?.plaats,
            street: kvkOwnerResource.attributes.adressen[0]?.straatnaam,
            houseNumber: String(kvkOwnerResource.attributes.adressen[0]?.huisnummer),
            postalCode: kvkOwnerResource.attributes.adressen[0]?.postcode,
        }
        : undefined;

    return {
        uuid: kvkOwnerResource.id,
        kvkNumber: kvkOwnerResource.attributes.kvkNummer,
        rsinNumber: kvkOwnerResource.attributes.rsin,
        legalEntity: kvkOwnerResource.attributes.rechtsvorm,
        address,
    };
};

export const transformToKvkMainBranch = (
    kvkMainBranchResource: KvkMainBranchResource,
): KvkMainBranch => {
    const addressWithStreet = kvkMainBranchResource.attributes.adressen?.length
        ? kvkMainBranchResource.attributes.adressen.find(address => address.huisnummer)
        : undefined;

    const address = addressWithStreet
        ? {
            city: addressWithStreet?.plaats,
            street: addressWithStreet.volledigAdres.split(' ')[0],
            houseNumber: String(addressWithStreet?.huisnummer),
            postalCode: addressWithStreet?.postcode,
        }
        : undefined;

    return {
        uuid: '',
        name: '',
        kvkNumber: '',
        branchNumber: '',
        isCommercial: kvkMainBranchResource.attributes.indCommercieleVestiging === 'Ja',
        isMainBranch: kvkMainBranchResource.attributes.indHoofdvestiging === 'Ja',
        address,
    };
};

export const transformToKvkBaseProfile = (
    doc: JapiDocument,
    data: KvkBaseProfileResource,
): KvkBaseProfile => {
    const includedKvkOwner = getIncluded<KvkOwnerResource>(doc, data, 'eigenaar');
    const includedKvkMainBranch = getIncluded<KvkMainBranchResource>(doc, data, 'hoofdvestiging');

    const kvkOwner = includedKvkOwner
        ? transformToKvkOwner(includedKvkOwner)
        : undefined;

    const kvkMainBranch = includedKvkMainBranch
        ? transformToKvkMainBranch(includedKvkMainBranch)
        : undefined;

    const postalCode = kvkMainBranch?.address?.postalCode || kvkOwner?.address?.postalCode;
    const houseNumber = kvkMainBranch?.address?.houseNumber || kvkOwner?.address?.houseNumber;

    return {
        uuid: data.id,
        name: data.attributes.naam,
        kvkNumber: data.attributes.kvkNummer,
        postalCode,
        houseNumber,
    };
};
