import React, { FC, ReactElement, useEffect } from 'react';

import { CompanySbbInfo } from '../../../containers';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import {
    clearCompany,
    fetchCompany,
    fetchSbbCompanyInfo,
    updateSbbCompanyInfo,
} from '../../../store/company/companyActions';

interface ConnectedCompanySbbInfoProps {
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanySbbInfo: FC<ConnectedCompanySbbInfoProps> = ({
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);

    const sbbCompanyInfo = useTypedSelector(state => state.companyReducer.sbbCompanyInfo);
    const sbbCompanyInfoHasUpdated = useTypedSelector(state => state.companyReducer.sbbCompanyInfoHasUpdated);
    const sbbCompanyInfoIsLoading = useTypedSelector(state => state.companyReducer.sbbCompanyInfoIsLoading);

    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));
    const hasSbbContent = sbbCompanyInfo?.sbbCompanyName && sbbCompanyInfo?.sbbId && sbbCompanyInfo?.recognitions.length;

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    useEffect((): void => {
        const mayViewSbbData = person?.is_sbb_user || userFromCompany;

        if (mayViewSbbData) {
            if (company && !sbbCompanyInfo) {
                dispatch(fetchSbbCompanyInfo(company.uuid));
            }
        }
    }, [person, company, userFromCompany]);

    const handleUpdateSbbInfo = (): void => {
        if (company) {
            dispatch(updateSbbCompanyInfo(company.uuid));
        }
    };

    return sbbCompanyInfo && hasSbbContent ? (
        <CompanySbbInfo
            isLoading={sbbCompanyInfoIsLoading}
            hasUpdated={sbbCompanyInfoHasUpdated}
            userFromCompany={userFromCompany}
            sbbCompanyInfo={sbbCompanyInfo}
            onUpdateSbbInfo={handleUpdateSbbInfo}
            className={className}
        />
    ) : null;
};

export default ConnectedCompanySbbInfo;
