import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { residencesNL } from '../../constants/residences-nl';
import { AsyncReduxState } from '../../types';

export type ResidencesState = AsyncReduxState<{
    residences: string[];
}>;

const initialState: ResidencesState = {
    ...initialAsyncReduxState,
    residences: residencesNL,
};

export const residencesSlice = createSlice({
    name: 'residencesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ResidencesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ResidencesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setResidences(state, action: PayloadAction<string[]>): ResidencesState {
            return {
                ...state,
                residences: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setResidences,
} = residencesSlice.actions;

export default residencesSlice;
