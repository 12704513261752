import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { SearchInput } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { Chat } from '../../../../models/Chat';
import { retrieveInterlocutor } from '../../helpers';
import { ChatSelectorSkeletons } from '../../skeletons';
import { ChatRoomPreview } from '..';

import './ChatSelector.scss';

interface ChatSelectorProps {
    isLoading: boolean;
    isCandidate: boolean;
    chats: Chat[];
    activeChat?: Chat;
    onSelectChat: (chat: Chat) => void;
    className?: string;
}

const ChatSelector: FC<ChatSelectorProps> = ({
    isLoading,
    isCandidate,
    chats,
    activeChat,
    onSelectChat,
    className = '',
}): ReactElement => {
    const [chatList, setChatList] = useState<Chat[]>(chats);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect((): void => {
        setSearchQuery('');
        setChatList(chats);
    }, [chats]);

    const handleSearch = (query: string): void => {
        const searchResults = [...chats].filter(chat => {
            const interlocutor = retrieveInterlocutor(chat, isCandidate);
            const name = interlocutor.fullName.toLowerCase();

            return name.includes(query.toLowerCase());
        });

        setSearchQuery(query);
        setChatList(searchResults);
    };

    const searchInputLabel = isCandidate
        ? trans('containers.messageOverview.input.search.candidate')
        : trans('containers.messageOverview.input.search.company');

    const noChatsNullState = isCandidate
        ? trans('containers.messageOverview.nullState.noChats.candidate')
        : trans('containers.messageOverview.nullState.noChats.company');

    const noSearchResultsNullState = isCandidate
        ? trans('containers.messageOverview.nullState.noSearchResults.candidate')
        : trans('containers.messageOverview.nullState.noSearchResults.company');

    const nullState = chats.length === 0
        ? noChatsNullState
        : noSearchResultsNullState;

    return (
        <aside className={`chat-selector ${className}`}>
            <div className="chat-selector__search-wrapper">
                <SearchInput
                    label={searchInputLabel}
                    hideLabel
                    value={searchQuery}
                    placeholder={!isLoading ? searchInputLabel : ''}
                    disabled={chats.length === 0 || isLoading}
                    onChange={handleSearch}
                    className="chat-selector__search-input"
                />
            </div>

            {isLoading && (
                <ChatSelectorSkeletons className="chat-selector__skeletons" />
            )}

            {!isLoading && chatList.length === 0 && (
                <p className="chat-selector__null-state">
                    {nullState}
                </p>
            )}

            {!isLoading && chatList.length > 0 && (
                <ul className="chat-selector__preview-list">
                    {chatList.map(chat => (
                        <ChatRoomPreview
                            key={chat.uuid}
                            isCandidate={isCandidate}
                            isActive={chat.uuid === activeChat?.uuid}
                            chat={chat}
                            onSelectChat={onSelectChat}
                            className="chat-selector__preview-item"
                        />
                    ))}
                </ul>
            )}
        </aside>
    );
};

export default ChatSelector;
