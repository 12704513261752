import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { trans } from '../../helpers/trans';
import { KvkSearchResult } from '../../models/KvkSearchResult';
import { Loader, SubmitButton, TextInput } from '..';
import { CompanyKVKList } from './subcomponents';

import './CompanyKVKFinder.scss';

interface CompanyKVKFinderProps {
    isLoading: boolean;
    onKvkSearchSubmit: (tradeName: string, location: string) => void;
    onKvkSearchResultSelect: (kvkCompany: KvkSearchResult) => void;
    searchResults: KvkSearchResult[];
    className?: string;
}

const CompanyKVKFinder: FC<CompanyKVKFinderProps> = ({
    isLoading,
    onKvkSearchSubmit,
    onKvkSearchResultSelect,
    searchResults = [],
    className = '',
}): ReactElement => {
    const [initialized, setInitialized] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [tradeName, setTradeName] = useState('');
    const [location, setLocation] = useState('');

    useEffect((): void => {
        if (isLoading) {
            setInitialized(true);
        }
    }, [isLoading]);

    useEffect((): void => {
        setCanSubmit(true);
    }, [tradeName, location]);

    const handleKvkNumberChange = (id: string) => {
        const matchingKvkCompany = searchResults.find(company => company.uuid === id);

        if (matchingKvkCompany) {
            onKvkSearchResultSelect(matchingKvkCompany);
        }
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        onKvkSearchSubmit(tradeName, location);
        setCanSubmit(false);
    };

    return (
        <form onSubmit={handleSubmit} className={`company-kvk-finder ${className}`}>
            <h3 className="company-kvk-finder__title">
                {trans('forms.register.employee.findYourCompany')}
            </h3>

            <div className="company-kvk-finder__input-wrapper">
                <TextInput
                    required
                    label={trans('forms.register.company.tradeName.label')}
                    value={tradeName}
                    placeholder={trans('forms.register.company.tradeName.placeholder')}
                    onChange={setTradeName}
                    className="company-kvk-finder__input"
                />

                <TextInput
                    required
                    label={trans('forms.register.company.location.label')}
                    value={location}
                    placeholder={trans('forms.register.company.location.placeholder')}
                    onChange={setLocation}
                    className="company-kvk-finder__input"
                />
            </div>

            <SubmitButton
                isLoading={isLoading}
                text={trans('actions.search')}
                disabled={!canSubmit || !(tradeName.length > 1 && location.length > 2)}
                className="company-kvk-finder__submit-button"
            />

            {isLoading && <Loader className="company-kvk-finder__loader" />}

            {initialized && !isLoading && (
                <CompanyKVKList
                    searchResults={searchResults}
                    onChange={handleKvkNumberChange}
                    className="company-kvk-finder__results-list"
                />
            )}
        </form>
    );
};

export default CompanyKVKFinder;
