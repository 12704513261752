import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';

import './CompanyHeaderSkeletons.scss';

interface CompanyHeaderSkeletonsProps {
    className?: string;
}

const CompanyHeaderSkeletons: FC<CompanyHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`company-header-skeletons ${className}`}>
        <div className="company-header-skeletons__cover" />

        <div className="company-header-skeletons__information-wrapper">
            <div className="company-header-skeletons__avatar" />

            <div className="company-header-skeletons__text-wrapper">
                <Skeleton className="company-header-skeletons__name" />
                <Skeleton className="company-header-skeletons__city" />
                <Skeleton className="company-header-skeletons__address" />
            </div>
        </div>
    </div>
);

export default CompanyHeaderSkeletons;
