import { Address } from '../_old/app_talentz/models/Address';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    Company,
    CompanyResource,
    transformJapiDocumentToCompany,
    transformToCompanyRequest,
} from '../models/Company';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { AtLeast } from '../types';
import { KvkCompanyApiParams } from './KvkCompanyService';
import { RegisterCompanyApiErrors } from './RegisterCompanyService';

export interface CompanyDataFormValues {
    companyName: string;
    kvkNumber: string;
    kvkData: KvkCompanyApiParams | null;
    website?: string;
    amountOfEmployeesId?: string;
    address: string;
    zipcode: string;
    city: string;
    streetNumber: string;
    province: string;
    extension?: string;
    latitude?: string;
    longitude?: string;
}

export interface CompanyDataFormErrors {
    name?: string;
    website?: string;
    amountOfEmployeesId?: string;
    address?: string;
    zipcode?: string;
    streetNumber?: string;
    extension?: string;
    province?: string;
}

export const generateEmptyCompanyDataFormValues = (): CompanyDataFormValues => ({
    companyName: '',
    kvkNumber: '',
    kvkData: null,
    address: '',
    zipcode: '',
    city: '',
    province: '',
    streetNumber: '',
    extension: '',
});

export const getCompanyApiCall = async (companyUuid: string): Promise<FetchResult<Company, string>> => {
    try {
        // const includes = ['coreValues', 'amountOfEmployees', 'address', 'employeeProfiles', 'sbbQualifications', 'sbbQualifications.fieldOfStudy'].join(',');
        // const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/companies/${companyUuid}?include=${includes}`);
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/companies/${companyUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<CompanyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const company = transformJapiDocumentToCompany(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: company,
        };
    } catch (error) {
        console.error('[getCompanyApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchCompanyApiCall = async (data: AtLeast<Company, 'uuid'>): Promise<FetchResult<Company, string>> => {
    try {
        const companyRequest = transformToCompanyRequest(data);

        const includes = ['coreValues', 'amountOfEmployees', 'address', 'employeeProfiles', 'sbbQualifications', 'sbbQualifications.fieldOfStudy'].join(',');
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/companies/${data.uuid}?include=${includes}`, {
            method: 'PATCH',
            body: JSON.stringify({ data: companyRequest }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<CompanyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const company = transformJapiDocumentToCompany(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: company,
        };
    } catch (error) {
        console.error('[patchCompanyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const transformRegisterCompanyApiErrorsToCompanyFormErrors = (
    errors: RegisterCompanyApiErrors,
): CompanyDataFormErrors => ({
    name: errors.name ? errors.name[1] : undefined,
    website: errors.website ? errors.website[1] : undefined,
    amountOfEmployeesId: errors.amount_of_employees_id ? errors.amount_of_employees_id[1] : undefined,
    address: errors.address ? errors.address[1] : undefined,
    zipcode: errors.zipcode ? errors.zipcode[1] : undefined,
    streetNumber: errors.street_number ? errors.street_number[1] : undefined,
    extension: errors.street_number_extension ? errors.street_number_extension[1] : undefined,
});

export const transformAddressToCompanyDataFormValues = (
    address: Address,
    formValues: CompanyDataFormValues,
): CompanyDataFormValues => ({
    ...formValues,
    address: address.address,
    city: address.city,
    longitude: address.longitude,
    latitude: address.latitude,
    province: address.province,
});
