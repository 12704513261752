import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './BlogListSkeletons.scss';

interface BlogListSkeletonsProps {
    amount?: number;
    className?: string;
}

const BlogListSkeletons: FC<BlogListSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`blog-list-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="blog-list-skeletons__item">
                    <Skeleton className="blog-list-skeletons__thumbnail" />

                    <div className="blog-list-skeletons__text-wrapper">
                        <Skeleton className="blog-list-skeletons__title" />
                        <Skeleton className="blog-list-skeletons__title" />

                        <Skeleton className="blog-list-skeletons__text" />
                        <Skeleton className="blog-list-skeletons__text" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default BlogListSkeletons;
