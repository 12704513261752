import React, { FC, ReactElement } from 'react';

import { DetailCard } from '../../../compositions';
import { defaultFormOption } from '../../../constants';
import { trans } from '../../../helpers/trans';
import { DriversLicense, DriversLicensesFormData } from '../../../models/DriversLicenses';
import { defaultEmploymentPreference, EmploymentPreferenceFormData } from '../../../models/Employment';
import { SectorsFormData } from '../../../models/Sectors';
import { WorkRemotePreferenceFormData } from '../../../models/WorkRemote';
import { FormOption, MinMaxValue } from '../../../types';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';
import {
    DriversLicenses,
    EmploymentPreferences,
    PreferredSectors,
    WorkRemotePreference,
} from './subcomponents';

import './CandidatePreferencesCard.scss';

interface CandidatePreferencesCardProps {
    employmentPreferencesActivationRef?: ActivationRef;
    workRemotePreferenceActivationRef?: ActivationRef;
    driversLicensesActivationRef?: ActivationRef;
    preferredSectorsActivationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    employmentPreferences?: MinMaxValue;
    workRemotePreference?: FormOption;
    workRemotePreferenceOptions: FormOption[];
    workRemotePreferenceIsLoading: boolean;
    driversLicenses?: FormOption[];
    driversLicenseOptions: DriversLicense[];
    driversLicensesIsLoading: boolean;
    preferredSectors?: FormOption[];
    sectorOptions: FormOption[];
    sectorsIsLoading: boolean;
    onEditEmploymentPreferences: (employmentPreferenceFormData: EmploymentPreferenceFormData) => void;
    onEditWorkRemotePreference: (workRemotePreferenceFormData: WorkRemotePreferenceFormData) => void;
    onEditDriversLicenses: (driversLicensesFormData: DriversLicensesFormData) => void;
    onEditPreferredSectors: (preferredSectorsFormData: SectorsFormData) => void;
    className?: string;
}

const CandidatePreferencesCard: FC<CandidatePreferencesCardProps> = ({
    employmentPreferencesActivationRef,
    workRemotePreferenceActivationRef,
    driversLicensesActivationRef,
    preferredSectorsActivationRef,
    isLoading,
    isEditable,
    employmentPreferences = defaultEmploymentPreference(),
    workRemotePreference = defaultFormOption(),
    workRemotePreferenceOptions,
    workRemotePreferenceIsLoading,
    driversLicenses = [],
    driversLicenseOptions,
    driversLicensesIsLoading,
    preferredSectors = [],
    sectorOptions,
    sectorsIsLoading,
    onEditEmploymentPreferences,
    onEditWorkRemotePreference,
    onEditDriversLicenses,
    onEditPreferredSectors,
    className = '',
}): ReactElement => {
    const title = isEditable
        ? trans('candidateProfile.candidatePreferencesCard.candidate.title')
        : trans('candidateProfile.candidatePreferencesCard.employee.title');

    return (
        <DetailCard title={title} className={`candidate-preferences-card ${className}`}>
            {isEditable && (
                <p className="candidate-preferences-card__description">
                    {trans('candidateProfile.candidatePreferencesCard.description')}
                </p>
            )}

            <ul className="candidate-preferences-card__info-list">
                <li className="candidate-preferences-card__info-item">
                    <EmploymentPreferences
                        activationRef={employmentPreferencesActivationRef}
                        isLoading={isLoading}
                        isEditable={isEditable}
                        employmentPreference={employmentPreferences}
                        onEdit={onEditEmploymentPreferences}
                    />
                </li>
                <li className="candidate-preferences-card__info-item">
                    <WorkRemotePreference
                        activationRef={workRemotePreferenceActivationRef}
                        isLoading={isLoading || workRemotePreferenceIsLoading}
                        isEditable={isEditable}
                        workRemotePreference={workRemotePreference}
                        workRemotePreferenceOptions={workRemotePreferenceOptions}
                        onEdit={onEditWorkRemotePreference}
                    />
                </li>
                <li className="candidate-preferences-card__info-item">
                    <DriversLicenses
                        activationRef={driversLicensesActivationRef}
                        isLoading={isLoading || driversLicensesIsLoading}
                        isEditable={isEditable}
                        driversLicenses={driversLicenses}
                        driversLicenseOptions={driversLicenseOptions}
                        onEdit={onEditDriversLicenses}
                    />
                </li>
                <li className="candidate-preferences-card__info-item">
                    <PreferredSectors
                        activationRef={preferredSectorsActivationRef}
                        isLoading={isLoading || sectorsIsLoading}
                        isEditable={isEditable}
                        preferredSectors={preferredSectors}
                        sectorOptions={sectorOptions}
                        onEdit={onEditPreferredSectors}
                    />
                </li>
            </ul>
        </DetailCard>
    );
};

export default CandidatePreferencesCard;
