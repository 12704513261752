import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { initialAsyncReduxState } from '../../constants';
import { NewestVacancy } from '../../models/NewestVacancy';
import { defaultInternshipVacancyOverviewFilterValues, InternshipVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { AsyncReduxState } from '../../types';

export type InternshipVacancyOverviewState = AsyncReduxState<{
    activeFilterValues: InternshipVacancyOverviewFilterValues;
    internshipVacancies: NewestVacancy[];
    pagination?: Pagination;
}>;

const initialState: InternshipVacancyOverviewState = {
    ...initialAsyncReduxState,
    activeFilterValues: defaultInternshipVacancyOverviewFilterValues(),
    internshipVacancies: [],
    pagination: undefined,
};

export const internshipVacancyOverviewSlice = createSlice({
    name: 'internshipVacancyOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): InternshipVacancyOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): InternshipVacancyOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<InternshipVacancyOverviewFilterValues>): InternshipVacancyOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setVacancies(state, action: PayloadAction<NewestVacancy[]>): InternshipVacancyOverviewState {
            return {
                ...state,
                internshipVacancies: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination | undefined>): InternshipVacancyOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveFilterValues,
    setVacancies,
    setPagination,
} = internshipVacancyOverviewSlice.actions;

export default internshipVacancyOverviewSlice;
