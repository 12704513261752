import React, { FC, ReactElement } from 'react';

import { alphabet } from '../../../../constants';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexLink } from '..';

import './VacancyIndexOccupationLetter.scss';

interface VacancyIndexOccupationLetterProps {
    activeLetters?: string[];
    className?: string;
}

const VacancyIndexOccupationLetter: FC<VacancyIndexOccupationLetterProps> = ({
    activeLetters = [],
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        title={trans('vacancyIndex.category.occupation.main')}
        className={`vacancy-index-occupation-letter ${className}`}
    >
        <ul className="vacancy-index-occupation-letter__list">
            {alphabet.map(letter => {
                const encodedLetter = encodeURIComponent(letter);
                const route = activeLetters?.includes(letter)
                    ? RoutePaths.jobVacancyIndexOccupation(encodedLetter)
                    : undefined;

                return (
                    <li key={letter} className="vacancy-index-occupation-letter__letter">
                        <VacancyIndexLink to={route} label={letter} />
                    </li>
                );
            })}
        </ul>
    </VacancyIndexCategory>
);

export default VacancyIndexOccupationLetter;
