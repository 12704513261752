import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconHome: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M50,0l50,51.3l-7.5,6.9L90,55.6V100H10V55.6l-2.5,2.6L0,51.3L50,0z M50,14.5l30,30.8V90H65V60H35v30H20V45.3L50,14.5z M42.5,90h15V67.5h-15V90z" />
    </svg>
);

export default IconHome;
