import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCross: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,56.9L93.1,100l6.9-6.9L56.9,50L100,6.9L93.1,0L50,43.1L6.9,0L0,6.9L43.1,50L0,93.1l6.9,6.9L50,56.9z" />
    </svg>
);

export default IconCross;
