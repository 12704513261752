import { Pagination } from '../_old/app_talentz/models/Pagination';
import { VacancyType } from '../_old/app_talentz/models/VacancyType';
import { addDaysToGivenDate, isValidDateString } from '../helpers/date';
import { trans } from '../helpers/trans';
import { sumCompensation, sumEmploymentHourRange } from '../helpers/vacancy';
import { Resource } from '../japi/types/Resource';
import { MinMaxValue, SalaryTimeframe, SearchableOption } from '../types';
import {
    Address,
    AddressResource,
    defaultAddress,
    transformAttributesToAddress,
} from './Address';
import {
    ContactPerson,
    ContactPersonResponse,
    defaultContactPerson,
    transformToContactPerson,
} from './ContactPerson';

export interface NewestVacancyResource extends Resource {
    type: 'vacancies';
    id: string;
    attributes: {
        uuid: string;
        slug: string;
        title: string;
        description: string;
        sector: string;
        companyName: string;
        amountOfEmployeesName: string;
        address: AddressResource['attributes'];
        minCompensation: number;
        maxCompensation: number;
        grossHourlyWageInCents: MinMaxValue;
        fieldOfStudies: string[];
        approvedFieldOfStudies: string[];
        educationLevels: string[];
        grades: string[];
        hasMatch: boolean;
        applyAnonymous: boolean;
        views: number;
        favouriteCount: number;
        vacancyType: VacancyType;
        vacancyTypeName: string;
        employmentHourRange: MinMaxValue;
        employmentName: string;
        remoteWorkingOptions: string;
        applicants: number;
        skills: string[];
        skillsToLearn: string[];
        competencies: string[];
        competenciesToLearn: string[];
        invitedCandidates: string[];
        ignoredCandidates: string[];
        company: {
            uuid: string;
            companyName: string;
            contactPerson: ContactPersonResponse;
            zipCode: string;
            city: string;
            amountOfEmployees: string;
            sbbQualifications: number[];
            createdAt: string;
            lastLogin: string;
            logo: string;
            hasLogo: boolean;
            hasCover: boolean;
            hasVideo: boolean;
            totalApplicationAmount: number;
            totalVacancies: number;
            totalActiveVacancies: number;
        };
        publicationDate: string;
        publicationDays: number;
        periodFrom: string;
        periodTill: string;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string;

        elasticScore?: number;
        isFavourite?: boolean;
    };
}

export interface VacancyMeta {
    applicants: number;
    views: number;
    favourites: number;
}

export interface VacancyProperties {
    vacancyTypeName: string;
    amountOfEmployees: string;
    educationLevels: string[];
    employmentHourRange: string;
    compensation: string;
    sector: string;
    grades: string[];
    fieldsOfStudy: string[];
    workingRemoteName: string;
    approvedFieldsOfStudy: string[];
}

export interface NewestVacancy {
    type: VacancyType;
    isAnonymous: boolean;
    isArchived: boolean;
    isFavourite?: boolean;
    uuid: string;
    title: string;
    slug: string;
    description: string;
    company: {
        uuid: string;
        name: string;
        logo: string;
        city: string;
        contactPerson: ContactPerson;
    };
    address: Address;
    skills: string[];
    publicationDate: Date;
    expirationDate: Date;
    periodFrom?: Date;
    periodTo?: Date;
    skillsToLearn: string[];
    competencies: string[];
    competenciesToLearn: string[];
    employmentHourRange: MinMaxValue;
    compensation: MinMaxValue;
    grossHourlyWageInCents: MinMaxValue;
    meta: VacancyMeta;
    properties: VacancyProperties;
}

export const transformToNewestVacancy = (
    vacancyResource: NewestVacancyResource,
): NewestVacancy => {
    const contactPerson = vacancyResource.attributes.company.contactPerson
        ? transformToContactPerson(vacancyResource.attributes.company.contactPerson)
        : defaultContactPerson();

    const address = vacancyResource.attributes.address
        ? transformAttributesToAddress(vacancyResource.attributes.address)
        : defaultAddress();

    const publicationDate = isValidDateString(vacancyResource.attributes.publicationDate)
        ? new Date(vacancyResource.attributes.publicationDate)
        : new Date();

    const expirationDate = vacancyResource.attributes.publicationDays
        ? addDaysToGivenDate(publicationDate, vacancyResource.attributes.publicationDays)
        : new Date();

    const periodFrom = isValidDateString(vacancyResource.attributes.periodFrom)
        ? new Date(vacancyResource.attributes.periodFrom)
        : undefined;

    const periodTo = isValidDateString(vacancyResource.attributes.periodTill)
        ? new Date(vacancyResource.attributes.periodTill)
        : undefined;

    const employmentHourRange = vacancyResource.attributes.employmentHourRange
        ? sumEmploymentHourRange(vacancyResource.attributes.employmentHourRange)
        : '';

    const compensation = vacancyResource.attributes.grossHourlyWageInCents.max
        ? sumCompensation(SalaryTimeframe.hour, vacancyResource.attributes.grossHourlyWageInCents)
        : sumCompensation(SalaryTimeframe.month, {
            min: vacancyResource.attributes.minCompensation,
            max: vacancyResource.attributes.maxCompensation,
        });

    const fieldsOfStudy = vacancyResource.attributes.fieldOfStudies
        .filter(fieldOfStudy => !vacancyResource.attributes.approvedFieldOfStudies.includes(fieldOfStudy));

    return {
        type: vacancyResource.attributes.vacancyType,
        isAnonymous: vacancyResource.attributes.applyAnonymous,
        isArchived: !!vacancyResource.attributes.deletedAt,
        isFavourite: vacancyResource.attributes.isFavourite,
        uuid: vacancyResource.id,
        title: vacancyResource.attributes.title,
        slug: vacancyResource.attributes.slug,
        description: vacancyResource.attributes.description,
        company: {
            uuid: vacancyResource.attributes.company.uuid,
            name: vacancyResource.attributes.company.companyName,
            logo: vacancyResource.attributes.company.logo
                ? `${process.env.REACT_APP_API_V1_URL}/storage/logos/${vacancyResource.attributes.company.logo}`
                : '',
            city: vacancyResource.attributes.company.city,
            contactPerson,
        },
        address,
        publicationDate,
        expirationDate,
        periodFrom,
        periodTo,
        skills: vacancyResource.attributes.skills,
        skillsToLearn: vacancyResource.attributes.skillsToLearn,
        competencies: vacancyResource.attributes.competencies,
        competenciesToLearn: vacancyResource.attributes.competenciesToLearn,
        employmentHourRange: vacancyResource.attributes.employmentHourRange,
        compensation: {
            min: vacancyResource.attributes.minCompensation || 0,
            max: vacancyResource.attributes.maxCompensation || 0,
        },
        grossHourlyWageInCents: vacancyResource.attributes.grossHourlyWageInCents,
        meta: {
            applicants: vacancyResource.attributes.applicants,
            views: vacancyResource.attributes.views,
            favourites: vacancyResource.attributes.favouriteCount,
        },
        properties: {
            vacancyTypeName: vacancyResource.attributes.vacancyTypeName,
            amountOfEmployees: vacancyResource.attributes.amountOfEmployeesName,
            educationLevels: vacancyResource.attributes.educationLevels,
            employmentHourRange,
            compensation,
            sector: vacancyResource.attributes.sector,
            grades: vacancyResource.attributes.grades,
            fieldsOfStudy,
            workingRemoteName: vacancyResource.attributes.remoteWorkingOptions,
            approvedFieldsOfStudy: vacancyResource.attributes.approvedFieldOfStudies,
        },
    };
};

export const sortNewestVacanciesByMostApplicants = (newestVacancy: NewestVacancy, compareNewestVacancy: NewestVacancy): number => (
    compareNewestVacancy.meta.applicants - newestVacancy.meta.applicants
);

export const transformNewestVacancyToSearchableOption = (
    newestVacancy: NewestVacancy,
): SearchableOption => ({
    label: newestVacancy.title,
    value: newestVacancy.uuid,
});

export const transformNewestVacancyToSearchableOptionWithApplicants = (
    newestVacancy: NewestVacancy,
): SearchableOption => ({
    label: newestVacancy.title,
    secondaryLabel: trans('models.vacancy.amountOfApplicants', {
        applicants: newestVacancy.meta.applicants,
    }),
    value: newestVacancy.uuid,
});

export interface VacancyOverview {
    vacancies: NewestVacancy[];
    pagination?: Pagination;
}
