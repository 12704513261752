import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import { dataLayerPush } from '../../../../helpers/analytics';
import { LEGACY_TOKEN_KEY, TOKEN_KEY } from '../../../../helpers/authorizedFetch';
import { trans } from '../../../../helpers/trans';
import { isFetchResultSuccessful } from '../../../../models/FetchResult';
import { RoleType } from '../../../../models/User';
import { RoutePaths } from '../../../../routes';
import { logSocialsUserInApiCall, logUserInApiCall } from '../../../../services/AuthenticationService';
import { TypedDispatch } from '../../../../store';
import { fetchCandidate } from '../../../../store/candidate/candidateActions';
import { LegacyReducers } from '../../../../store/reducers';
import { addNegativeToast, addPositiveToast } from '../../../../store/toast/toastActions';
import { fetchUserCompany } from '../../../../store/user/userActions';
import { CompanySettingsTab } from '../../../../types/pageTabs';
import SessionStorageType from '../../constants/SessionStorageType';
import { DeleteAccountParams } from '../../models/SettingsPage';
import { transformToUser, User } from '../../models/User';
import { setAppUpdates } from '../app/app';
import { resetInternshipVacanciesState } from '../internshipVacancies/internshipVacancies';
import { resetJobVacanciesState } from '../jobVacancies/jobVacancies';
import { checkForWebPushSubscription } from '../notifications/notificationsActions';
// eslint-disable-next-line import/no-cycle
import { updatePassword } from '../resetPassword/resetPasswordActions';
import { createAddress, getAddress } from '../userAddress/userAddressActions';
import { createCompany, getCompany, updateCompany } from '../userCompany/userCompanyActions';
import { createEmployee, getEmployee } from '../userEmployee/userEmployeeActions';
import { setIsEmpty } from '../userPerson/userPerson';
import { createPerson, getPerson } from '../userPerson/userPersonActions';
import { getPersonSettings, updatePersonSettings } from '../userPersonSettings/userPersonSettingsActions';
// eslint-disable-next-line import/no-cycle
import { updateProfile } from '../userProfile/userProfileActions';
import {
    resetLegacyUserState,
    setAuthenticated,
    setData,
    setDeleteErrors,
    setDeleteLoading,
    setErrors,
    setLoading,
    setLoadingSocialMedia,
    setLogin,
    setLogoutLoading,
    setPermissions,
    setRole,
    setTokenData,
} from './legacyUser';

const userWith = 'tenant.school.educationLevel,tenantInvites.website.school.educationLevel';

const showSavedChangesToast = (dispatch: TypedDispatch): void => {
    dispatch(addPositiveToast({
        title: trans('basic.toast.success'),
        description: trans('candidateProfile.toast.savedChanges.success'),
    }));
};

const showErrorToast = (dispatch: TypedDispatch): void => {
    dispatch(addNegativeToast({
        title: trans('errors.unknownError'),
    }));
};

export function environmentPermissionsChecker() {
    return process.env.REACT_APP_ENV_PERMISSIONS ? process.env.REACT_APP_ENV_PERMISSIONS.trim().split(',') : [];
}

export function setUserToken(tokenData: any) {
    return (dispatch: Dispatch<any>) => {
        axios.defaults.headers.common['Authorization'] = `${tokenData.token_type} ${tokenData.access_token}`;
        localStorage.setItem(LEGACY_TOKEN_KEY, JSON.stringify(tokenData));

        dispatch(setTokenData(tokenData));
    };
}

export function logIn(login = true) {
    return (dispatch: Dispatch<any>) => {
        dispatch(setErrors({}));
        dispatch(setLogin(login));
    };
}

export function logOut() {
    return (dispatch: Dispatch<any>) => {
        dispatch(setLogoutLoading(true));

        axios.post('/authentication/logout').then(() => {
            delete axios.defaults.headers.common['Authorization'];

            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(LEGACY_TOKEN_KEY);

            dispatch(resetLegacyUserState());
            dispatch(resetInternshipVacanciesState());
            dispatch(resetJobVacanciesState());

            return Promise.resolve();
        });
    };
}

export function getUser() {
    return async (dispatch: Dispatch<any>): Promise<User> => {
        dispatch(setLoading(true));

        try {
            const response = await axios.get('/me', {
                params: {
                    with: 'tenant.school.educationLevel,tenantInvites.website.school.educationLevel',
                },
            });

            const user = transformToUser(response.data.data);

            if (user.app_updates) {
                dispatch(setAppUpdates(user.app_updates));
            }

            const envPermissions = environmentPermissionsChecker();

            dispatch(setData(user));
            dispatch(setRole(user.roles[0].slug as RoleType));
            dispatch(setPermissions([...new Set([...envPermissions, ...user.permissions])]));

            return Promise.resolve(user);
        } catch (error) {
            console.error('[getUser]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            dispatch(logOut());

            return Promise.reject(error);
        }
    };
}

export function getUserProfiles() {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        const { legacyUser } = getState();
        const { role, data } = legacyUser;

        try {
            // @ts-ignore
            await dispatch(getPerson());
            // @ts-ignore
            await dispatch(getPersonSettings());

            if (role === RoleType.company) {
                // @ts-ignore
                await dispatch(getEmployee());
                // @ts-ignore
                const company = await dispatch(getCompany());

                // TODO: Remove above 'company' entirely when refactoring the whole user store
                if (company?.uuid) {
                    // @ts-ignore
                    dispatch(fetchUserCompany(company.uuid));
                }

                // @ts-ignore
                dispatch(getAddress(company?.address?.uuid));
            }

            if (role === RoleType.student || role === RoleType.jobSeeker) {
                if (data?.person_id) {
                    // @ts-ignore
                    dispatch(fetchCandidate(data.person_id));
                }
            }
        } catch (error) {
            console.error('[getUserProfiles]', error);
        }
    };
}

export function updateUser(data: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setLoading(true));

        try {
            const response = await axios.patch<AxiosResponse<User>>('/me', data, {
                params: {
                    with: userWith,
                },
            });

            const user = response.data.data;

            if (user.app_updates) {
                dispatch(setAppUpdates(user.app_updates));
            }

            const envPermissions = environmentPermissionsChecker();

            dispatch(setData(user));
            dispatch(setRole(user.roles[0].slug as RoleType));
            dispatch(setPermissions([...new Set([...envPermissions, ...user.permissions])]));
            dispatch(setLoading(false));

            dispatch(getUserProfiles());
            return true;
        } catch (error) {
            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return false;
        }
    };
}

export function getUserWithProfile(isSocial?: boolean) {
    return async (dispatch: Dispatch) => {
        try {
            // @ts-ignore
            const user = await dispatch(getUser());

            if (!user.person_id) {
                dispatch(setLoading(false));
                dispatch(setLoadingSocialMedia(false));
                dispatch(setAuthenticated(true));

                Promise.resolve();
            }

            if (!isSocial) {
                // @ts-ignore
                await dispatch(getUserProfiles());

                dispatch(setLoading(false));
                dispatch(setLoadingSocialMedia(false));
                dispatch(setAuthenticated(true));
            }

            Promise.resolve();
        } catch (error) {
            console.error('[getUserWithProfile]', error);
        }
    };
}

export function getUserWithAccessToken() {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        await dispatch(getUserWithProfile());

        const { legacyUser } = getState();

        dispatch(setIsEmpty(!legacyUser?.data?.person_id));

        return Promise.resolve();
    };
}

export function deleteUser(data: DeleteAccountParams) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setDeleteLoading(true));
        dispatch(setDeleteErrors({}));

        try {
            await axios.delete('/me', {
                params: data,
            });

            dispatch(setDeleteLoading(false));
            dispatch(logOut());

            dataLayerPush({
                event: 'formSubmission',
                formType: 'delete-account',
            });
        } catch (error: any) {
            dispatch(setDeleteLoading(false));

            const errorResponse = error.response.data.errors;

            const errorObject = typeof errorResponse === 'string'
                ? { password: [errorResponse] }
                : errorResponse;

            dispatch(setDeleteErrors(errorObject));
        }
    };
}

export function authenticateUser(data: any, withoutSubscription = false) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setLoading(true));

        const loginResponse = await logUserInApiCall(data.username, data.password);

        if (isFetchResultSuccessful(loginResponse)) {
            localStorage.setItem(TOKEN_KEY, JSON.stringify(loginResponse.data));
        }

        return axios.post('/authentication/passport/authenticate', {
            email: data.username,
            password: data.password,
        }).then(response => {
            dispatch(setUserToken(response.data.data));
            sessionStorage.setItem(SessionStorageType.RedirectUri, window.location.pathname);

            if (!withoutSubscription) {
                // @ts-ignore
                dispatch(getUserWithAccessToken()).then(() => {
                    // @ts-ignore
                    dispatch(checkForWebPushSubscription());
                    return Promise.resolve(response.data.data);
                });
            }

            dispatch(setErrors({}));
            dispatch(setLogin(false));
        }).catch(error => {
            const errorObject = error.response?.data?.errors
                ? error.response.data.errors
                : error;

            dispatch(setErrors(errorObject));
            dispatch(setLoading(false));
        });
    };
}

export function getLLOLoginRedirectUrl() {
    return (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        return axios.get('/authentication/omnimap/url')
            .then(response => Promise.resolve(response.data.data.redirect_url))
            .catch(error => Promise.reject(error.response));
    };
}

export function authenticateLLOUser(code: string, sessionState: any, navigate: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));
        dispatch(setLoadingSocialMedia(true));

        const redirectUrl = encodeURI('?llo-login=true');

        try {
            const response = await axios.post('/authentication/omnimap/authenticate', {
                code,
                session_state: sessionState,
                redirect_url: redirectUrl,
            });

            const accessToken = response.data.data.access_token;

            dispatch(setUserToken(response.data.data));
            await dispatch(getUserWithProfile(true));

            const { legacyUser } = getState();
            const email = legacyUser?.data?.email;

            if (!email) {
                console.error('user has no email');
                return Promise.reject();
            }

            const loginResponse = await logSocialsUserInApiCall(accessToken, email);

            if (isFetchResultSuccessful(loginResponse)) {
                localStorage.setItem(TOKEN_KEY, JSON.stringify(loginResponse.data));
            }

            // @ts-ignore
            await dispatch(getUserProfiles());
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));
            dispatch(setAuthenticated(true));

            dispatch(checkForWebPushSubscription());

            return Promise.resolve(response.data.data);
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            navigate(RoutePaths.home());
            return Promise.reject(error);
        }
    };
}

export function getLinkedInLoginRedirectUrl() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setLoading(true));

        const redirectUrl = encodeURI('?linkedin-login=true');

        return axios.get(`/authentication/linkedin/url?redirect_url=${redirectUrl}`)
            .then(response => Promise.resolve(response.data.data))
            .catch(error => Promise.reject(error.response.data));
    };
}

export function authenticateLinkedInUser(code: string, navigate: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));
        dispatch(setLoadingSocialMedia(true));

        const redirectUrl = encodeURI('?linkedin-login=true');

        try {
            const response = await axios.post('/authentication/linkedin/authenticate', {
                code,
                redirect_url: redirectUrl,
            });

            const accessToken = response.data.data.access_token;
            dispatch(setUserToken(response.data.data));

            // @ts-ignore
            await dispatch(getUser());
            const { legacyUser } = getState();
            const email = legacyUser?.data?.email;

            if (!email) {
                throw new Error('User has no email');
            }

            const loginResponse = await logSocialsUserInApiCall(accessToken, email);

            if (isFetchResultSuccessful(loginResponse)) {
                localStorage.setItem(TOKEN_KEY, JSON.stringify(loginResponse.data));
            }

            // @ts-ignore
            await dispatch(getUserWithProfile());

            // @ts-ignore
            dispatch(checkForWebPushSubscription());

            return Promise.resolve(response.data.data);
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            navigate(RoutePaths.home(), {
                state: { noLinkedInUser: true },
            });

            showErrorToast(dispatch);

            return Promise.reject(error);
        }
    };
}

export function updateOrCreateCompany(data: any, navigate: any, locationState: any) {
    return async (dispatch: Dispatch<any>) => {
        try {
            if (locationState && locationState.completeRegister) {
                const addressResponse = await dispatch(createAddress(data));
                const companyResponse = await dispatch(createCompany(data));

                if (!addressResponse || !companyResponse) {
                    return;
                }

                navigate(RoutePaths.companySettings(CompanySettingsTab.personalData), {
                    state: locationState,
                });
                showSavedChangesToast(dispatch);
                return;
            }

            await dispatch(updateCompany(data));
            showSavedChangesToast(dispatch);
        } catch (error) {
            console.error('[updateOrCreateCompany]', error);

            showErrorToast(dispatch);
        }
    };
}

export function updateOrCreateEmployee(data: any, navigate: any, locationState: any) {
    return async (dispatch: Dispatch<any>) => {
        try {
            if (locationState && locationState.completeRegister) {
                await dispatch(createPerson(data));
                await dispatch(createEmployee(data));
                await dispatch(getUserWithProfile());

                navigate(RoutePaths.myCompanyProfile());
                showSavedChangesToast(dispatch);
                return;
            }

            await dispatch(updateProfile(data));
            showSavedChangesToast(dispatch);
        } catch (error) {
            console.error('[updateOrCreateEmployee]', error);

            showErrorToast(dispatch);
        }
    };
}

export function resetUserPassword(data: any) {
    return async (dispatch: Dispatch<any>) => {
        try {
            await dispatch(updatePassword(data));

            dispatch(addPositiveToast({
                title: trans('basic.toast.success'),
                description: trans('basic.passwordResetSuccess'),
            }));
        } catch (error) {
            console.warn('[resetUserPassword]', error);
        }
    };
}

export function updatePersonSettingsAndCheckForWebPushSubscription(data: any) {
    return async (dispatch: Dispatch) => {
        // @ts-ignore
        const response = await dispatch(updatePersonSettings(data));

        if (response.notifications) {
            // @ts-ignore
            dispatch(checkForWebPushSubscription());
        }
    };
}
