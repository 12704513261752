import React, { ReactElement, useEffect } from 'react';

import { setCloseBehaviour, setStep as setRegisterStep } from '../../_old/app_talentz/store/register/register';
import { RegisterCloseBehaviour } from '../../_old/app_talentz/store/register/RegisterActionTypes';
import Loader from '../../_old/components/Loader';
import { useLegacyDispatch } from '../../store';

const ConnectedRegisterDialog = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();

    useEffect((): () => void => {
        // Basicly calls <Register> in the nav to open up..
        // We really need a dedicated register page with a dedicated register connector..
        legacyDispatch(setCloseBehaviour(RegisterCloseBehaviour.RedirectToHome));
        legacyDispatch(setRegisterStep(0));

        return (): void => {
            legacyDispatch(setCloseBehaviour(RegisterCloseBehaviour.CloseDialog));
        };
    }, [legacyDispatch]);

    return (
        // Fake spinner, to have a "page"
        <Loader />
    );
};

export default ConnectedRegisterDialog;
