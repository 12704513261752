import { Resource } from '../../japi/types/Resource';
import { CompanyInfo } from './CompanyInfo';

export interface PersonInfoResource extends Resource {
    type: 'person-info';
    id: string;
    attributes: {
        fullname: string;
        avatar: string;
        roles: string[];
        createdAt: string;
        updatedAt?: string;
    };
}

export interface PersonInfo {
    uuid: string;
    fullName: string;
    avatar: string;
    roles: string[];
}

export const defaultPersonInfo = (): PersonInfo => ({
    uuid: '',
    fullName: '',
    avatar: '',
    roles: [],
});

export const transformToPersonInfo = (
    personInfoResource: PersonInfoResource,
): PersonInfo => ({
    uuid: personInfoResource.id,
    fullName: personInfoResource.attributes.fullname || '',
    avatar: personInfoResource.attributes.avatar
        ? `${process.env.REACT_APP_API_V1_URL}/storage/avatars/${personInfoResource.attributes.avatar}`
        : '',
    roles: personInfoResource.attributes.roles,
});

export const transformCompanyInfoToPersonInfo = (
    companyInfo: CompanyInfo,
): PersonInfo => ({
    ...defaultPersonInfo(),
    uuid: companyInfo.uuid,
    fullName: companyInfo.name,
    avatar: companyInfo.logo,
});
