import axios from 'axios';
import { Dispatch } from 'redux';

import { LegacyReducers } from '../../../../store/reducers';
import { setData, setErrors, setLoading } from './userEmployee';

export function getEmployee() {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userPerson } = getState();
        const employeeId = userPerson?.data?.employee_profile_id;

        if (!employeeId) {
            throw new Error('[getEmployee] No employeeId present');
        }

        try {
            const response = await axios.get(`/employee-profiles/${employeeId}`);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            console.error('[getEmployee]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function createEmployee(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userCompany, userPerson } = getState();
        const personId = userPerson?.data?.uuid;
        const companyId = userCompany?.data?.uuid || '';

        if (!personId || !companyId) {
            throw new Error('[createEmployee] No personId or companyId present');
        }

        data.personId = userPerson?.data?.uuid;
        data.companyId = userCompany?.data?.uuid || '';

        try {
            const response = await axios.post('/employee-profiles', data);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            console.error('[createEmployee]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function updateEmployee(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userEmployee } = getState();
        const employeeId = userEmployee?.data?.uuid || '';

        if (!employeeId) {
            throw new Error('[updateEmployee] No employeeId present');
        }

        try {
            const response = await axios.patch(`/employee-profiles/${employeeId}`, data);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            console.error('[updateEmployee]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function updateEmployeeNotificationsSettings(data: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setLoading(true));

        try {
            const response = await axios.put(
                '/me/employee/settings',
                data,
            );

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            console.error('[updateEmployeeNotificationsSettings]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}
