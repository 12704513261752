import { sortByCreatedAtDateDesc } from '../../helpers/sort';
import { trans } from '../../helpers/trans';
import { EmailAddressChangeFormData } from '../../models/EmailAddress';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getEmailAddressChangeConfirmationApiCall, getEmailAddressChangesApiCall, postEmailAddressChangeApiCall } from '../../services/EmailAddressService';
import { StatusCode } from '../../types';
import { TypedDispatch } from '../index';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setEmailAddressChange, setError, setIsLoading } from './accountSettings';

export const clearEmailAddressChange = () => (dispatch: TypedDispatch): void => {
    dispatch(setEmailAddressChange(undefined));
};

export const fetchRecentEmailAddressChange = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const emailAddressChangesResponse = await getEmailAddressChangesApiCall();

        if (!isFetchResultSuccessful(emailAddressChangesResponse)) {
            dispatch(setError(emailAddressChangesResponse.error));

            return;
        }

        const sortedEmailAddressChanges = emailAddressChangesResponse.data.sort(sortByCreatedAtDateDesc);

        if (sortedEmailAddressChanges.length > 0) {
            const recentEmailAddressChange = sortedEmailAddressChanges[0];

            if (!recentEmailAddressChange.isConfirmed) {
                dispatch(setEmailAddressChange(recentEmailAddressChange));
            }
        }
    } catch (error) {
        console.error('[fetchRecentEmailAddressChange]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const changeEmailAddress = (emailAddressChangeFormData: EmailAddressChangeFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const changeEmailAddressResponse = await postEmailAddressChangeApiCall(emailAddressChangeFormData);

        if (!isFetchResultSuccessful(changeEmailAddressResponse)) {
            dispatch(setError(changeEmailAddressResponse.error));

            if (changeEmailAddressResponse.status === StatusCode.forbidden) {
                dispatch(addNegativeToast({
                    title: trans('toast.failure'),
                    description: trans('toast.accountSettings.changeEmailAddress.failure.wrongPassword'),
                }));
            } else {
                dispatch(addNegativeToast({
                    title: trans('errors.unknownError'),
                }));
            }

            return;
        }

        const emailAddressChange = changeEmailAddressResponse.data;

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.accountSettings.changeEmailAddress.success.description', {
                newEmailAddress: emailAddressChange.newEmailAddress,
            }),
        }));

        dispatch(setEmailAddressChange(emailAddressChange));
    } catch (error) {
        console.error('[changeEmailAddress]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const confirmEmailAddressChange = (confirmationKey: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const confirmResponse = await getEmailAddressChangeConfirmationApiCall(confirmationKey);

        if (!isFetchResultSuccessful(confirmResponse)) {
            dispatch(setError(confirmResponse.error));

            dispatch(addNegativeToast({
                title: trans('toast.failure'),
                description: trans('toast.accountSettings.confirmEmailAddressChange.failure.description'),
            }));

            return;
        }

        const confirmedEmailAddressChange = confirmResponse.data;

        dispatch(addPositiveToast({
            title: trans('toast.success'),
            description: trans('toast.accountSettings.confirmEmailAddressChange.success.description', {
                newEmailAddress: confirmedEmailAddressChange.newEmailAddress,
            }),
        }));

        dispatch(setEmailAddressChange(confirmedEmailAddressChange));
    } catch (error) {
        console.error('[confirmEmailAddressChange]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
