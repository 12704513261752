import { CardSlide } from './CardSlide';

export default function categorizeCardsByCategory(cards: CardSlide[]) {
    const result: CardSlide[][] = [];

    cards.forEach(card => {
        const foundCategory = result.findIndex(subCards => subCards.find(subCard => subCard.category === card.category));

        if (foundCategory === -1) {
            result.push([card]);
        } else {
            result[foundCategory].push(card);
        }
    });

    return result;
}
