import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../components';
import { DetailCard, LinkIconButton } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Company } from '../../../models/Company';
import { RoutePaths } from '../../../routes';

import './CompanyContactCard.scss';

interface CompanyContactCardProps {
    isLoading: boolean;
    isCandidate: boolean;
    company?: Company;
    className?: string;
}

const CompanyContactCard: FC<CompanyContactCardProps> = ({
    isLoading,
    isCandidate,
    company,
    className = '',
}): ReactElement => (
    <DetailCard
        isLoading={isLoading}
        title={trans('companyProfile.contact.title')}
        className={`company-contact-card ${className}`}
    >
        {isLoading && (
            <div className="company-contact-card__skeleton-wrapper">
                <Skeleton className="company-contact-card__skeleton" />
                <Skeleton className="company-contact-card__skeleton" />
            </div>
        )}

        {!isLoading && company && (
            <p className="company-contact-card__description">
                {isCandidate
                    ? trans('companyProfile.contact.candidateDescription', {
                        company: company.name,
                    })
                    : trans('companyProfile.contact.description', {
                        company: company.name,
                    })}
            </p>
        )}

        <LinkIconButton
            to={RoutePaths.candidateOverviewMessages(company?.uuid)}
            icon="send"
            text={trans('actions.sendMessage')}
            disabled={isLoading || !company || !isCandidate}
            className="company-contact-card__chat-button"
        />
    </DetailCard>
);

export default CompanyContactCard;
