import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { ApplicationInvite } from '../../models/ApplicationInvites';
import { AsyncReduxState } from '../../types';

export type ApplicationInviteState = AsyncReduxState<{
    applicationInvite?: ApplicationInvite;
}>;

const initialState: ApplicationInviteState = {
    ...initialAsyncReduxState,
    applicationInvite: undefined,
};

const applicationInviteSlice = createSlice({
    name: 'applicationInviteReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ApplicationInviteState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ApplicationInviteState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setApplicationInvite(state, action: PayloadAction<ApplicationInvite | undefined>): ApplicationInviteState {
            return {
                ...state,
                applicationInvite: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setApplicationInvite,
} = applicationInviteSlice.actions;

export default applicationInviteSlice;
