import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedSettingsCompany } from '../../../../../connectors';
import { trans } from '../../../../../helpers/trans';
import Page from '../../../../styledComponents/components/Page';

const SettingsCompanyPage = (): ReactElement => (
    <Page className="settings-company-page">
        <Helmet>
            <title>{trans('navigation.items.settings')}</title>
            <meta name="description" content={trans('basic.settings.description')} />
        </Helmet>
        <ConnectedSettingsCompany />
    </Page>
);

export default SettingsCompanyPage;
