import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../../components';

import './ContactDetailList.scss';

interface ContactDetailListProps {
    emailAddress: string;
    phoneNumber: string;
    readablePhoneNumber?: string;
    className?: string;
}

const ContactDetailList: FC<ContactDetailListProps> = ({
    emailAddress,
    phoneNumber,
    readablePhoneNumber,
    className = '',
}): ReactElement => (
    <ul className={`contact-detail-list ${className}`}>
        <li className="contact-detail-list__item">
            <Icon name="mail" className="contact-detail-list__icon" />
            <a href={`mailto:${emailAddress}`} className="contact-detail-list__link">
                {emailAddress}
            </a>
        </li>
        <li className="contact-detail-list__item">
            <Icon name="phone" className="contact-detail-list__icon" />
            <a href={`tel:${phoneNumber}`} className="contact-detail-list__link">
                {readablePhoneNumber || phoneNumber}
            </a>
        </li>
    </ul>
);

export default ContactDetailList;
