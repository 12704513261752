import { trans } from '../../helpers/trans';

function isCorrectFormat(string: string) {
    // eslint-disable-next-line prefer-regex-literals
    const expression = new RegExp('\\d{4}[a-zA-Z]{2}');

    return expression.test(string);
}

export default function validateZipcode(zipcode: string | null) {
    if (!zipcode) {
        return [];
    }

    if (zipcode.length > 0 && zipcode.length !== 6) {
        return [trans('validation.zipcode.length')];
    }

    if (!isCorrectFormat(zipcode)) {
        return [trans('validation.zipcode.format')];
    }

    return [];
}
