import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Page } from '../../components';
import { ConnectedJobVacancyIndex } from '../../connectors';
import { trans } from '../../helpers/trans';

import './VacancyIndex.scss';

const JobVacancyIndex: FC = (): ReactElement => {
    const helmetTitle = trans('vacancyIndex.helmet.title', {
        title: trans('vacancyIndex.jobs.main'),
    });

    return (
        <Page className="vacancy-index-page">
            <Helmet>
                <title>{helmetTitle}</title>
                <meta property="og:title" content={createMetadataTitle(helmetTitle)} />
                <meta property="og:description" content={trans('vacancyIndex.helmet.description')} />
                <meta name="description" content={trans('vacancyIndex.helmet.description')} />
            </Helmet>

            <div className="vacancy-index-page__wrapper">
                <ConnectedJobVacancyIndex />
            </div>
        </Page>
    );
};

export default JobVacancyIndex;
