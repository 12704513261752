import React, { FC, ReactElement, useEffect } from 'react';

import { MyApplications } from '../../containers';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchApplications } from '../../store/applications/applicationsActions';

interface ConnectedMyApplicationsProps {
    className?: string;
}

const ConnectedMyApplications: FC<ConnectedMyApplicationsProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { isLoading, applications, error } = useTypedSelector(state => state.applicationsReducer);

    useEffect((): void => {
        dispatch(fetchApplications());
    }, []);

    return (
        <MyApplications
            isLoading={isLoading}
            applications={applications}
            error={error}
            className={className}
        />
    );
};

export default ConnectedMyApplications;
