import { EmailAddressChangeFormData } from '../../../../models/EmailAddress';
import {
    FormErrors,
    FormValidation,
    OptionalFormValidation,
    validateEmailAddress,
    validateForm,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type EmailAddressChangeFormErrors = FormErrors<EmailAddressChangeFormData>;

export const validateEmailAddressChangeFormData = (
    formData: EmailAddressChangeFormData,
    optionalValidation: OptionalFormValidation<EmailAddressChangeFormData>,
): FormValidation<EmailAddressChangeFormErrors> => {
    const errors: EmailAddressChangeFormErrors = {
        newEmailAddress: optionalValidation
            ? validateEmailAddress(formData.newEmailAddress)
            : undefined,
        password: optionalValidation.password
            ? validateRequiredString('password', formData.password)
            : undefined,
    };

    return validateForm<EmailAddressChangeFormErrors>(errors);
};
