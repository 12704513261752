import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import ReactSelect, { OnChangeValue } from 'react-select';

import { trans } from '../../../helpers/trans';
import { FormOption } from '../../../types';

import './NewSelect.scss';

interface NewSelectProps {
    name: string;
    options: FormOption[];
    value?: OnChangeValue<FormOption, false>;
    placeholder?: string;
    disabled?: boolean;
    error?: string;
    onChange: (selected: FormOption) => void;
    className?: string;
}

const NewSelect: FC<NewSelectProps> = ({
    name,
    options,
    value,
    placeholder = trans('basic.select.placeholder'),
    disabled,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const selectClassName = classNames('new-select', className, {
        'new-select--is-disabled': disabled,
        'new-select--has-error': !!error,
    });

    const noOptionsMessage = (): string => trans('basic.select.noOptionsFound');

    const handleChange = (selectValue: OnChangeValue<FormOption, false>): void => {
        onChange(selectValue as FormOption);
    };

    return (
        <ReactSelect
            isSearchable
            name={name}
            options={options}
            value={value}
            placeholder={placeholder}
            isDisabled={disabled}
            noOptionsMessage={noOptionsMessage}
            onChange={handleChange}
            classNamePrefix="new-select"
            className={selectClassName}
        />
    );
};

export default NewSelect;
