import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useToggle } from 'react-use';

import { MyVacancies } from '../../containers';
import { scrollToTop } from '../../helpers/scroll';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { setRecentlyUpdatedVacancy, setVacancyToEdit } from '../../store/myVacancies/myVacancies';
import {
    archiveVacancy,
    deleteVacancy,
    fetchMyVacancies,
    unarchiveVacancy,
} from '../../store/myVacancies/myVacanciesActions';
import { clearVacancyApplications, fetchVacancyApplications } from '../../store/vacancyApplications/vacancyApplicationsActions';

interface ConnectedMyVacanciesProps {
    className?: string;
}

const ConnectedMyVacancies: FC<ConnectedMyVacanciesProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const [isArchive, toggleIsArchive] = useToggle(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const personId = useLegacySelector(state => state.legacyUser.data?.person_id);

    const isLoading = useTypedSelector(state => state.myVacanciesReducer.isLoading);
    const error = useTypedSelector(state => state.myVacanciesReducer.error);
    const vacancies = useTypedSelector(state => state.myVacanciesReducer.vacancies);
    const pagination = useTypedSelector(state => state.myVacanciesReducer.pagination);
    const recentlyUpdatedVacancy = useTypedSelector(state => state.myVacanciesReducer.recentlyUpdatedVacancy);

    const applicationsIsLoading = useTypedSelector(state => state.vacancyApplicationsReducer.isLoading);
    const applications = useTypedSelector(state => state.vacancyApplicationsReducer.applications);

    const isValidCompany = !!personId;

    useEffect((): () => void => {
        dispatch(setVacancyToEdit(undefined));

        return (): void => {
            dispatch(setRecentlyUpdatedVacancy(undefined));
        };
    }, []);

    useEffect((): void => {
        if (!isValidCompany) return;

        dispatch(fetchMyVacancies({
            pageSize: 10,
            pageNumber: currentPage,
            isArchive,
        }));

        scrollToTop();
    }, [dispatch, isValidCompany, currentPage, isArchive]);

    const handleClearRecentlyUpdatedVacancy = (): void => {
        dispatch(setRecentlyUpdatedVacancy(undefined));
    };

    const handleFetchApplications = (vacancyUuid: string): void => {
        dispatch(fetchVacancyApplications(vacancyUuid));
    };

    const handleClearApplications = (): void => {
        dispatch(clearVacancyApplications());
    };

    const handleArchiveVacancy = (vacancyUuid: string, applicationUuids: string[]): void => {
        dispatch(archiveVacancy(vacancyUuid, applicationUuids));
        scrollToTop();
    };

    const handleUnarchiveVacancy = (vacancyUuid: string): void => {
        dispatch(unarchiveVacancy(vacancyUuid));
        scrollToTop();
    };

    const handleDeleteVacancy = (vacancyUuid: string): void => {
        dispatch(deleteVacancy(vacancyUuid));
        scrollToTop();
    };

    return (
        <MyVacancies
            isLoading={isLoading}
            isValidCompany={isValidCompany}
            isArchive={isArchive}
            recentlyUpdatedVacancy={recentlyUpdatedVacancy}
            vacancies={vacancies}
            pagination={pagination}
            error={error}
            applications={applications}
            applicationsIsLoading={applicationsIsLoading}
            onArchiveToggle={toggleIsArchive}
            onClearRecentlyUpdatedVacancy={handleClearRecentlyUpdatedVacancy}
            onFetchApplications={handleFetchApplications}
            onClearApplications={handleClearApplications}
            onArchiveVacancy={handleArchiveVacancy}
            onUnarchiveVacancy={handleUnarchiveVacancy}
            onDeleteVacancy={handleDeleteVacancy}
            onPaginationChange={setCurrentPage}
            className={className}
        />
    );
};

export default ConnectedMyVacancies;
