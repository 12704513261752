import { maxShortTextLength } from '../../../../constants';
import { ChatReportFormData } from '../../../../models/ChatReport';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMaxStringLength,
} from '../../../../services/ValidationService';

export type ChatReportFormErrors = FormErrors<ChatReportFormData>;

export const validateChatReportFormData = (
    formData: ChatReportFormData,
): FormValidation<ChatReportFormErrors> => {
    const errors: ChatReportFormErrors = {
        reportReason: validateMaxStringLength('reason', formData.reportReason, maxShortTextLength),
    };

    return validateForm<ChatReportFormErrors>(errors);
};
