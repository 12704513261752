import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { BlogArticle, BlogSeoData } from '../../models/Blog';
import { AsyncReduxState } from '../../types';

export type BlogDetailState = AsyncReduxState<{
    title: string;
    intro: string;
    content?: BlogArticle;
    seoData?: BlogSeoData;
    relatedBlogArticles: BlogArticle[];
}>;

const initialState: BlogDetailState = {
    ...initialAsyncReduxState,
    title: '',
    intro: '',
    relatedBlogArticles: [],
};

const blogDetailSlice = createSlice({
    name: 'blogDetailReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BlogDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BlogDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setContent(state, action: PayloadAction<BlogArticle | undefined>): BlogDetailState {
            return {
                ...state,
                content: action.payload,
            };
        },
        setSeoData(state, action: PayloadAction<BlogSeoData | undefined>): BlogDetailState {
            return {
                ...state,
                seoData: action.payload,
            };
        },
        setRelatedBlogArticles(state, action: PayloadAction<BlogArticle[]>): BlogDetailState {
            return {
                ...state,
                relatedBlogArticles: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setContent,
    setSeoData,
    setRelatedBlogArticles,
} = blogDetailSlice.actions;

export default blogDetailSlice;
