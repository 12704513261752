import { Resource } from '../japi/types/Resource';
import { SearchableOption } from '../types';

export interface CoreValuesResource extends Resource {
    type: 'core-values';
    id: string;
    attributes: {
        name: string;
        slug: string;
        isSelectable: boolean;
        createdAt: string;
        updatedAt: string;
    };
}

export interface CoreValue {
    uuid: string;
    name: string;
    slug: string;
}

export interface CoreValuesFormData {
    coreValues: SearchableOption[];
}

export const transformToCoreValue = (
    coreValueResource: CoreValuesResource,
): CoreValue => ({
    uuid: coreValueResource.id,
    name: coreValueResource.attributes.name,
    slug: coreValueResource.attributes.slug,
});

export const transformCoreValueToSearchableOption = (
    coreValue: CoreValue,
): SearchableOption => ({
    label: coreValue.name,
    value: coreValue.uuid,
});
