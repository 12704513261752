import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { CoreValue, CoreValuesResource, transformToCoreValue } from '../models/CoreValue';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getCoreValuesApiCall = async (): Promise<FetchResult<CoreValue[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/core-values`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<CoreValuesResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const selectableCoreValues = doc.data.filter(coreValue => coreValue.attributes.isSelectable);
        const coreValues = selectableCoreValues.map(transformToCoreValue);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: coreValues,
        };
    } catch (error) {
        console.error('[getCoreValuesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
