import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { Resource } from '../../japi/types/Resource';
import { NewestVacancy } from '../NewestVacancy';

export interface VacanciesInfoResource extends Resource {
    type: 'vacancies-info';
    id: string;
    attributes: {
        isArchived: boolean;
        isAnonymousApplication: boolean;
        title: string;
        description: string;
        typeName: VacancyType;
        company: {
            uuid: string;
            address: {
                city: string;
            };
            name: string;
            logo: string;
            coverImage: string;
        };
    };
}

export interface VacanciesInfo {
    uuid: string;
    isArchived: boolean;
    isAnonymous: boolean;
    type: VacancyType;
    title: string;
    companyUuid: string;
    companyName: string;
    companyCity: string;
    companyLogo?: string;
}

export const defaultVacanciesInfo = (): VacanciesInfo => ({
    uuid: '',
    isArchived: false,
    isAnonymous: false,
    type: VacancyType.job,
    title: '',
    companyUuid: '',
    companyName: '',
    companyCity: '',
});

export const transformToVacanciesInfo = (
    vacanciesInfoResource: VacanciesInfoResource,
): VacanciesInfo => ({
    uuid: vacanciesInfoResource.id,
    isArchived: vacanciesInfoResource.attributes.isArchived,
    isAnonymous: vacanciesInfoResource.attributes.isAnonymousApplication,
    type: vacanciesInfoResource.attributes.typeName,
    title: vacanciesInfoResource.attributes.title,
    companyUuid: vacanciesInfoResource.attributes.company.uuid,
    companyName: vacanciesInfoResource.attributes.company.name,
    companyCity: vacanciesInfoResource.attributes.company.address.city,
    companyLogo: vacanciesInfoResource.attributes.company.logo
        ? `${process.env.REACT_APP_API_V1_URL}/storage/logos/${vacanciesInfoResource.attributes.company.logo}`
        : '',
});

export const transformVacancyToVacanciesInfo = (
    vacancy: NewestVacancy,
): VacanciesInfo => ({
    uuid: vacancy.uuid,
    isArchived: vacancy.isArchived,
    isAnonymous: vacancy.isAnonymous,
    type: vacancy.type,
    title: vacancy.title,
    companyUuid: vacancy.company.uuid,
    companyName: vacancy.company.name,
    companyCity: vacancy.company.city,
    companyLogo: vacancy.company.logo,
});
