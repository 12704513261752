// @ts-ignore
import styled, { css } from 'styled-components';

export const Styles = styled('div')((props: any) => css`
    .cards-slider-block {
        
        &__header {        
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &__header-title {
            margin: 0 1.071rem 3.214rem;
        }

        &__block-list-wrapper {
            margin-bottom: 4.428rem;
            width: 100%;
            
            @media ${props.theme.mediaQueries.tablet} {
                display: flex;
                justify-content: center;
            }
        }

        &__block-list-component-wrapper {
            flex-direction: column;

            @media ${props.theme.mediaQueries.tablet} {
                flex-direction: row;
            }
        }

        &__block-list-label {
            ${props.theme.fonts.button};
            padding: 0 0.714rem;

            @media ${props.theme.mediaQueries.tablet} {
                width: 12.5rem;
            }

            .block-radio-list__label-text {
                text-transform: uppercase;
            }
        }

        &__sliders-wrapper {
            position: relative;
            height: 150px;
        }

        &__slide {
            position: absolute;
            max-width: 100%;
            height: 100%;
        }

        &__slide--hidden {
            visibility: hidden;
        }

        &__slide--visible {
            display: block;
        }
    }
`);
