import colors from './colors';
import fonts from './fonts';
import fontSizes from './fontSizes';
import GlobalStyling from './GlobalStyling';
import { breakpoints, mediaQueries } from './mediaQueries';
import shadow from './shadow';
import { space } from './spacing';
import variables from './variables';

export default {
    variables,
    colors,
    fonts,
    shadow,
    mediaQueries,
    breakpoints,
    space,
    fontSizes,
    GlobalStyling,
};
