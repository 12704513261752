import React, { FC, ReactElement } from 'react';

import './ProgressEmoticon.scss';

interface ProgressEmoticonProps {
    isSatisfactory: boolean;
    className?: string;
}

const ProgressEmoticon: FC<ProgressEmoticonProps> = ({
    isSatisfactory,
    className = '',
}): ReactElement => (
    <svg viewBox="0 0 100 97.8" className={`progress-emoticon ${className}`}>
        {isSatisfactory ? (
            <path className="progress-emoticon__face" d="M50.7,21.1c-17.3,0-31.4,14.1-31.4,31.4S33.5,84,50.7,84s31.4-14.1,31.4-31.4S68,21.1,50.7,21.1z M61.7,40c2.5,0,4.7,2.2,4.7,4.7c0,2.5-2.2,4.7-4.7,4.7c-2.5,0-4.7-2.2-4.7-4.7C57,42.2,59.2,40,61.7,40z M39.7,40c2.5,0,4.7,2.2,4.7,4.7c0,2.5-2.2,4.7-4.7,4.7c-2.5,0-4.7-2.2-4.7-4.7C35,42.2,37.2,40,39.7,40z M50.7,71.4c-8.2,0-15.1-5.3-17.6-12.6h35.2C65.8,66.1,58.9,71.4,50.7,71.4z" />
        ) : (
            <path className="progress-emoticon__face" d="M50.7,21.1c-17.3,0-31.4,14.1-31.4,31.4S33.5,84,50.7,84s31.4-14.1,31.4-31.4S68,21.1,50.7,21.1z M35,44.7c0-2.5,2.2-4.7,4.7-4.7c2.5,0,4.7,2.2,4.7,4.7c0,2.5-2.2,4.7-4.7,4.7C37.2,49.4,35,47.2,35,44.7z M56.2,66.5c-1.6,1-3.5,1.6-5.4,1.6c-1.9,0-3.8-0.5-5.4-1.6c-1.6-1-2.8-2.4-3.6-4h18C59,64.1,57.7,65.5,56.2,66.5z M61.7,49.4c-2.5,0-4.7-2.2-4.7-4.7c0-2.5,2.2-4.7,4.7-4.7c2.5,0,4.7,2.2,4.7,4.7C66.5,47.2,64.3,49.4,61.7,49.4z" />
        )}
        <path className="progress-emoticon__star progress-emoticon__star--has-color" d="M6.1,33.9l-3.6,1.9l0.7-4l-2.9-2.9l4-0.6l1.8-3.7l1.8,3.7l4,0.6L9,31.8l0.7,4L6.1,33.9z" />
        <path className="progress-emoticon__star" d="M7.9,70.9l-3.6,1.9l0.7-4L2,65.9l4-0.6l1.8-3.7l1.8,3.7l4,0.6l-2.9,2.9l0.7,4L7.9,70.9z" />
        <path className="progress-emoticon__star" d="M29,10.1l-2.6,1.4l0.5-2.9l-2.1-2l2.9-0.4L29,3.5l1.3,2.6l2.9,0.4l-2.1,2l0.5,2.9L29,10.1z" />
        <path className="progress-emoticon__star" d="M66.9,7.9l-3.1,1.6l0.6-3.5l-2.5-2.4l3.5-0.5L66.9,0l1.6,3.1l3.5,0.5l-2.5,2.4L70,9.6L66.9,7.9z" />
        <path className="progress-emoticon__star" d="M69.6,95.9l-3.1,1.6l0.6-3.5l-2.5-2.4l3.5-0.5l1.6-3.1l1.6,3.1l3.5,0.5l-2.5,2.4l0.6,3.5L69.6,95.9z" />
        <path className="progress-emoticon__star progress-emoticon__star--has-color" d="M94.1,29.5l-2.6,1.4L92,28l-2.1-2l2.9-0.4l1.3-2.6l1.3,2.6l2.9,0.4l-2.1,2l0.5,2.9L94.1,29.5z" />
        <path className="progress-emoticon__star progress-emoticon__star--has-color" d="M94.1,67.3l-3.6,1.9l0.7-4l-2.9-2.9l4-0.6l1.8-3.7l1.8,3.7l4,0.6l-2.9,2.9l0.7,4L94.1,67.3z" />
        <path className="progress-emoticon__star progress-emoticon__star--has-color" d="M32.5,96.4l-2.6,1.4l0.5-2.9l-2.1-2l2.9-0.4l1.3-2.6l1.3,2.6l2.9,0.4l-2.1,2l0.5,2.9L32.5,96.4z" />
    </svg>
);

export default ProgressEmoticon;
