import getIncluded from '../japi/getIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship } from '../japi/types/Relationships';
import { FieldsOfStudyResource } from './FieldsOfStudy';
import { SbbCreboNumber } from './SbbCreboNumber';

interface PracticalTrainerResource {
    id: number;
    email: string;
    lastName: string;
    firstName: string;
    phoneNumber: string;
    phoneNumberType: string;
}

interface SbbRecognitionResource {
    crebo: number;
    level: string;
    description: string;
    practicalTrainers: PracticalTrainerResource[];
}

export interface SbbQualificationResource {
    type: 'sbb-qualifications';
    id: string;
    attributes: {
        createdAt: string;
        creboNumber: number;
    };
    relationships: {
        fieldOfStudy: MultipleRelationship<'field-of-study'>;
    };
}

export interface SbbTrainingCompanyResource {
    type: 'sbb-training-companies';
    id: string;
    attributes: {
        dataSource: string;
        identificationScore: number;
        createdAt: string;
        sbbReceivedData: {
            score: number;
            address: {
                city: string;
                zipCode: string;
                streetName: string;
                streetNumber: string;
            };
            companyId: string;
            companyName: string;
            recognitions: SbbRecognitionResource[];
            internshipCapacity: number;
            registeredStudents: number;
        };
    };
}

interface PracticalTrainer {
    id: number;
    email: string;
    lastName: string;
    firstName: string;
    phoneNumber: string;
    phoneNumberType: string;
}

export interface SbbRecognition {
    crebo: number;
    level: string;
    description: string;
    practicalTrainers: PracticalTrainer[];
    subRecognitions: SbbSubRecognition[];
}

export interface SbbSubRecognition {
    crebo: number;
    description: string;
    skills: SbbSkill[];
}

interface SbbSkill {
    crebo: number;
    description: string;
}

export interface SbbCompanyInfo {
    sbbId: string;
    sbbCompanyName: string;
    recognitions: SbbRecognition[];
}

const transformToPracticalTrainer = (PracticalTrainerResource: PracticalTrainerResource): PracticalTrainer => ({
    id: PracticalTrainerResource.id,
    email: PracticalTrainerResource.email,
    lastName: PracticalTrainerResource.lastName,
    firstName: PracticalTrainerResource.firstName,
    phoneNumber: PracticalTrainerResource.phoneNumber,
    phoneNumberType: PracticalTrainerResource.phoneNumberType,
});

const transformToSbbRecognition = (sbbRecognitionResource: SbbRecognitionResource): SbbRecognition => ({
    crebo: sbbRecognitionResource.crebo,
    level: sbbRecognitionResource.level,
    description: sbbRecognitionResource.description,
    practicalTrainers: sbbRecognitionResource.practicalTrainers
        ? sbbRecognitionResource.practicalTrainers.map(transformToPracticalTrainer)
        : [],
    subRecognitions: [],
});

export const transformToSbbCompanyInfo = (sbbTrainingCompanyResource: SbbTrainingCompanyResource): SbbCompanyInfo => ({
    sbbId: sbbTrainingCompanyResource.attributes.sbbReceivedData.companyId,
    sbbCompanyName: sbbTrainingCompanyResource.attributes.sbbReceivedData.companyName,
    recognitions: sbbTrainingCompanyResource.attributes.sbbReceivedData.recognitions
        ? sbbTrainingCompanyResource.attributes.sbbReceivedData.recognitions.map(transformToSbbRecognition)
        : [],
});

export const transformToSbbCreboNumber = (
    sbbQualificationResource: SbbQualificationResource,
    doc: JapiDocument,
): SbbCreboNumber => {
    const includedFieldOfStudy = getIncluded<FieldsOfStudyResource>(doc, sbbQualificationResource, 'fieldOfStudy');

    return {
        creboNumber: sbbQualificationResource.attributes.creboNumber,
        fieldOfStudy: {
            id: includedFieldOfStudy?.id || '',
            name: includedFieldOfStudy?.attributes?.name || '',
            slug: includedFieldOfStudy?.attributes?.slug || '',
            relatedEducationLevelIds: [],
        },
    };
};
