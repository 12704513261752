import { FileDropZoneValidation } from '../../../../compositions/@inputs/FileDropZone/FileDropZone';
import { maxLongTextLength } from '../../../../constants';
import { ApplicationFormData } from '../../../../models/Applications';
import {
    FormErrors,
    FormValidation,
    validateFileAmount,
    validateFileSize,
    validateFileType,
    validateForm,
    validateMaxStringLength,
} from '../../../../services/ValidationService';

export type ApplicationFormErrors = FormErrors<ApplicationFormData>;
export type ApplicationAttachmentValidation = FileDropZoneValidation;

export const validateApplicationFormData = (
    formData: ApplicationFormData,
    options: ApplicationAttachmentValidation = {},
): FormValidation<ApplicationFormErrors> => {
    const attachmentErrors = [
        validateFileAmount(formData.attachments.length, options.maxFileAmount),
        ...formData.attachments.map(attachment => ([
            validateFileType(attachment.type, options.acceptedFileTypes),
            validateFileSize(attachment.size, options.maxFileSize),
        ])),
    ];

    const filteredAttachmentErrors = attachmentErrors.flat().filter(error => error);

    const errors: ApplicationFormErrors = {
        bodyText: validateMaxStringLength('content', formData.bodyText, maxLongTextLength),
        attachments: filteredAttachmentErrors.length > 0
            ? filteredAttachmentErrors[0]
            : undefined,
    };

    return validateForm<ApplicationFormErrors>(errors);
};
