import React, { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import { siteUrl } from '../../constants';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchBlogSeoData } from '../../store/blogDetail/blogDetailActions';

interface ConnectedBlogDetailHelmetProps {
    slug: string;
}

const ConnectedBlogDetailHelmet: FC<ConnectedBlogDetailHelmetProps> = ({
    slug,
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const seoData = useTypedSelector(state => state.blogDetailReducer.seoData);

    const blogUrl = `${siteUrl}/blog/${slug}`;
    const logoUrl = `${siteUrl}/images/logo-seo.png`;

    useEffect((): void => {
        dispatch(fetchBlogSeoData(slug));
    }, [slug]);

    return seoData ? (
        <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />

            <meta property="og:site_name" content="TalentZ" />
            <meta property="og:title" content={seoData.title} />
            <meta property="og:description" content={seoData.description} />
            <meta property="og:image" content={seoData.image.src} />
            <meta property="og:image:alt" content={seoData.image.alt} />
            <meta property="og:url" content={blogUrl} />

            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BlogPosting',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                    },
                    author: {
                        '@type': 'Organization',
                        name: 'TalentZ',
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: 'TalentZ',
                        logo: {
                            '@type': 'ImageObject',
                            url: logoUrl,
                        },
                    },
                    headline: seoData.title,
                    image: [seoData.image.src],
                    datePublished: seoData.createdAt,
                    dateModified: seoData.updatedAt,
                })}
            </script>

            <link rel="canonical" href={blogUrl} />
        </Helmet>
    ) : null;
};

export default ConnectedBlogDetailHelmet;
