import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';

import './ApplicationCompanyInformationSkeletons.scss';

interface ApplicationCompanyInformationSkeletonsProps {
    className?: string;
}

const ApplicationCompanyInformationSkeletons: FC<ApplicationCompanyInformationSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`application-company-information-skeletons ${className}`}>
        <div className="application-company-information-skeletons__company-wrapper">
            <div className="application-company-information-skeletons__company-logo" />

            <div className="application-company-information-skeletons__company-text-wrapper">
                <Skeleton className="application-company-information-skeletons__company-name" />
                <Skeleton className="application-company-information-skeletons__company-city" />
            </div>
        </div>

        <div className="application-company-information-skeletons__employee-description">
            <Skeleton className="application-company-information-skeletons__employee-line" />
            <Skeleton className="application-company-information-skeletons__employee-line" />
        </div>
    </div>
);

export default ApplicationCompanyInformationSkeletons;
