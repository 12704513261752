import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconDelete: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 70 100" className={`icon ${className}`}>
        <path fillRule="evenodd" d="M29.3,1.1C36.9-2,45.7,1.6,48.9,9.3C49.6,11.1,50,13,50,15h20v30h-7.5v55h-55V45H0V15h20C20,8.9,23.7,3.5,29.3,1.1z M39.6,13.1c0.2,0.6,0.4,1.2,0.4,1.9H30c0-2.8,2.3-5,5-5C37,10,38.8,11.2,39.6,13.1L39.6,13.1z M10,25v10h50V25H10z M52.5,42.5h-35V90h35V42.5z" />
    </svg>
);

export default IconDelete;
