import { hideVisually } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled('div')`
    display: flex;
    flex-direction: row;
    width: 100%;
    
    @media ${props => props.theme.mediaQueries.laptop} {
        width: 100%;
    }
    
    ${props => props.errors && css`
        border: 2px solid ${props.theme.colors.alert};
    `}
`;

export const ListLabel = styled('span')(props => css`
    display: flex;
    align-items: center;
    ${props.theme.fonts.bold};
    margin-bottom: 5px;
    font-size: .875rem;
    color: ${props.theme.colors.text};
`);

export const ListLabelText = styled('span')`
    flex: 1;
    display: inline-block;
`;

export const Native = styled('input')`
    ${hideVisually()}
`;

export const TextLabel = styled('span')`
    line-height: 44px;
    padding: 0 ${props => props.theme.space.sm}; 
    border: 1px solid ${props => props.theme.colors.grey};
    font-size: 1em;
    width: 100%;
    transition: all 0.35s ease-out;
    color: ${props => props.theme.colors.primary};
    text-align: center;
    background-color: ${props => props.theme.colors.white};
    z-index: 1;
    cursor: pointer;
    
    ${props => !props.checked && css`
        &:hover {
            background-color: ${props.theme.colors.primaryMuted};
            color: ${props.theme.colors.white};
        }
    `}

    ${props => props.checked && css`
        background-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.white};
        border-color: ${props.theme.colors.primaryDarker}
        z-index: 2;
    `}
    
    ${props => props.disabled && css`
        background-color: ${props.theme.colors.muted};
        border-color: ${props.theme.colors.muted};
        color: ${props.theme.colors.white};
        
        &:hover {
            background-color: ${props.theme.colors.muted};
        }
    `}
`;

export const Label = styled('label')(props => css`
    display: flex;
    z-index: 0;
    margin-right: -1px;
    font-size: .875rem;
    width: 100%;
    
    &:last-of-type {
        margin-right: 0;
    }
    
    &:focus-within {
    
        ${TextLabel} {
            color: ${props.theme.colors.white};
            background-color: ${props.theme.colors.primaryLighter}
        }
    }
`);
