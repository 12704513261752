import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../../../components';

import './VacancyHeaderSkeletons.scss';

interface VacancyHeaderSkeletonsProps {
    className?: string;
}

const VacancyHeaderSkeletons: FC<VacancyHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`vacancy-header-skeletons ${className}`}>
        <div className="vacancy-header-skeletons__cover" />

        <div className="vacancy-header-skeletons__information-wrapper">
            <div className="vacancy-header-skeletons__avatar" />

            <div className="vacancy-header-skeletons__text-wrapper">
                <Skeleton className="vacancy-header-skeletons__title" />
                <Skeleton className="vacancy-header-skeletons__company" />
                <Skeleton className="vacancy-header-skeletons__publication-date" />
            </div>
        </div>
    </div>
);

export default VacancyHeaderSkeletons;
