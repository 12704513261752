import React, { FC, ReactElement, useState } from 'react';

import { ErrorLabel } from '../../../components';
import { Avatar } from '../../../compositions';
import { maxFileUploadSize } from '../../../constants';
import { trans } from '../../../helpers/trans';
import { Company } from '../../../models/Company';
import { FileFormData } from '../../../models/File';
import { YouTubeFormData } from '../../../models/YouTube';
import { ImageDropForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';
import { CompanyHeaderSkeletons } from './skeletons';
import { CompanyHeaderCover } from './subcomponents';

import './CompanyHeader.scss';

interface CompanyHeaderProps {
    coverImageActivationRef?: ActivationRef;
    youTubeActivationRef?: ActivationRef;
    avatarActivationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    company?: Company;
    onEditAvatar: (avatarFormData: FileFormData) => void;
    onEditCoverImage: (avatarFormData: FileFormData) => void;
    onEditYouTube: (youTubeFormData: YouTubeFormData) => void;
    className?: string;
}

const CompanyHeader: FC<CompanyHeaderProps> = ({
    coverImageActivationRef,
    youTubeActivationRef,
    avatarActivationRef,
    isLoading,
    isEditable,
    company,
    onEditAvatar,
    onEditCoverImage,
    onEditYouTube,
    className = '',
}): ReactElement => {
    const [avatarError, setAvatarError] = useState<string>('');

    const dimensionLimits = {
        minWidth: 112,
        minHeight: 112,
    };

    return (
        <header className={`company-header ${className}`}>
            {isLoading && (
                <CompanyHeaderSkeletons />
            )}

            {!isLoading && company && (
                <CompanyHeaderCover
                    coverImageActivationRef={coverImageActivationRef}
                    youTubeActivationRef={youTubeActivationRef}
                    isEditable={isEditable}
                    company={company}
                    onEditCoverImage={onEditCoverImage}
                    onEditYouTube={onEditYouTube}
                />
            )}

            {!isLoading && company && (
                <div className="company-header__details">
                    <div className="company-header__information-wrapper">
                        {isEditable ? (
                            <ImageDropForm
                                activationRef={avatarActivationRef}
                                hasControls
                                shouldCrop
                                label={trans('companyProfile.header.logoAlt', { name: company.name })}
                                value={company.logo || ''}
                                cropAspectRatio={1}
                                validationOptions={{
                                    ...dimensionLimits,
                                    sizeLimit: maxFileUploadSize,
                                }}
                                renderOptions={dimensionLimits}
                                onSubmit={onEditAvatar}
                                onError={setAvatarError}
                                className="company-header__avatar-form"
                            />
                        ) : (
                            <Avatar
                                src={company.logo}
                                alt={trans('companyProfile.header.logoAlt', { name: company.name })}
                                fallbackString={company.name}
                                className="company-header__avatar"
                            />
                        )}

                        <div className="company-header__text-wrapper">
                            <h1 className="company-header__company-name">
                                {company.name}
                            </h1>
                            <p className="company-header__city">
                                {company.address.city}
                            </p>
                            <p className="company-header__address">
                                {trans('companyProfile.header.address', { ...company.address })}
                            </p>
                        </div>
                    </div>

                    {avatarError && (
                        <ErrorLabel
                            text={avatarError}
                            className="company-header__error-label"
                        />
                    )}
                </div>
            )}
        </header>
    );
};

export default CompanyHeader;
