import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCamera: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 75" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M50,20c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S61,20,50,20z M37.5,40c0-6.9,5.6-12.5,12.5-12.5S62.5,33.1,62.5,40S56.9,52.5,50,52.5S37.5,46.9,37.5,40z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M100,75V10H75L67.5,0h-35L25,10H0v65H100z M10,65V20h20l7.5-10h25L70,20h20v45H10z" />
    </svg>
);

export default IconCamera;
