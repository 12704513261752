import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconSettings: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 97.3 100" className={`icon ${className}`}>
        <path d="M96.3,63.1l-10.6-8.2c0.2-1.6,0.4-3.2,0.4-4.9s-0.2-3.3-0.4-4.9l10.6-8.2c0.9-0.8,1.2-2.1,0.6-3.2l-10-17.3c-0.5-0.8-1.3-1.2-2.2-1.2c-0.3,0-0.6,0-0.8,0.2l-12.5,5c-2.6-2-5.4-3.7-8.5-4.9L61.1,2.1C60.9,0.9,59.9,0,58.6,0h-20c-1.2,0-2.3,0.9-2.5,2.1l-1.9,13.2c-3,1.2-5.8,3-8.4,4.9l-12.5-5c-0.3-0.1-0.6-0.2-0.9-0.2c-0.8,0-1.7,0.5-2.1,1.2l-10,17.3c-0.7,1.1-0.4,2.5,0.6,3.2l10.5,8.2c-0.2,1.6-0.3,3.2-0.3,4.9c0,1.6,0.1,3.3,0.3,4.9L0.9,63.1c-1,0.8-1.2,2.1-0.6,3.2l10,17.3c0.4,0.8,1.3,1.2,2.2,1.2c0.3,0,0.6,0,0.9-0.1l12.5-5c2.6,2,5.4,3.6,8.4,4.9l1.9,13.2c0.2,1.2,1.2,2.1,2.5,2.1h20c1.2,0,2.3-0.9,2.4-2.1L63,84.6c3-1.2,5.9-2.9,8.5-4.9l12.5,5c0.3,0.1,0.6,0.1,0.9,0.1c0.9,0,1.7-0.4,2.2-1.2l10-17.3C97.5,65.2,97.3,63.9,96.3,63.1z M81.5,73l-6.3-2.5L70,68.4l-4.5,3.4c-2.2,1.6-4.2,2.8-6.2,3.7l-5.3,2.1l-0.8,5.7l-1,6.8h-7l-1-6.8l-0.8-5.7l-5.3-2.1c-2.2-0.9-4.2-2.1-6.2-3.6l-4.5-3.5l-5.3,2.2l-6.4,2.5l-3.5-6l5.4-4.2l4.5-3.5l-0.7-5.6c-0.2-1.6-0.2-2.7-0.2-3.7s0.1-2.2,0.2-3.7l0.7-5.6l-4.5-3.5L12.2,33l3.5-6l6.4,2.5l5.2,2.1l4.5-3.4c2.2-1.6,4.2-2.8,6.2-3.7l5.3-2.2l0.8-5.6l1-6.8h6.9l1,6.8l0.8,5.6l5.3,2.2c2.1,0.9,4.1,2,6.1,3.5l4.5,3.5l5.3-2.1l6.3-2.6L85,33l-5.3,4.2l-4.5,3.5l0.7,5.6c0.2,1.5,0.2,2.6,0.2,3.7s-0.1,2.1-0.2,3.6l-0.7,5.7l4.5,3.5L85,67L81.5,73z" />
        <path d="M48.6,30c-11.1,0-20,9-20,20s9,20,20,20c11,0,20-9,20-20S59.7,30,48.6,30z M48.6,60c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10S54.1,60,48.6,60z" />
    </svg>
);

export default IconSettings;
