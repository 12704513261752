import React, { FC, ReactElement } from 'react';

import { alphabetNumber } from '../../../../constants';
import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { EducationLevel } from '../../../../models/EducationLevels';
import { FieldOfStudy } from '../../../../models/FieldsOfStudy';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexFieldOfStudy.scss';

interface VacancyIndexFieldOfStudyProps {
    isLoading: boolean;
    educationLevel: EducationLevel;
    letter: string;
    fieldOfStudyOptions: FieldOfStudy[];
    className?: string;
}

const VacancyIndexFieldOfStudy: FC<VacancyIndexFieldOfStudyProps> = ({
    isLoading,
    educationLevel,
    letter,
    fieldOfStudyOptions,
    className = '',
}): ReactElement => {
    const title = letter === alphabetNumber
        ? trans('vacancyIndex.category.fieldOfStudy.number', { number: alphabetNumber })
        : trans('vacancyIndex.category.fieldOfStudy.letter', { letter });

    return (
        <VacancyIndexCategory
            isLoading={isLoading}
            title={title}
            className={`vacancy-index-field-of-study ${className}`}
        >
            <VacancyIndexColumnList>
                {fieldOfStudyOptions.map(fieldOfStudy => {
                    const searchQuery = encodeSearchQuery({
                        what: fieldOfStudy.name,
                    });

                    const encodedEducationLevel = encodeURIComponent(educationLevel.slug);
                    const encodedLetter = encodeURIComponent(letter);
                    const encodedFieldOfStudy = encodeURIComponent(fieldOfStudy.slug);

                    return (
                        <li key={fieldOfStudy.id}>
                            <VacancyIndexLink
                                to={RoutePaths.internshipsQuery(searchQuery)}
                                label={fieldOfStudy.name}
                            />

                            <VacancyIndexLink
                                to={RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel, encodedLetter, encodedFieldOfStudy)}
                                label={trans('vacancyIndex.button.selectProvince')}
                                className="vacancy-index-field-of-study__province-link"
                            />
                        </li>
                    );
                })}
            </VacancyIndexColumnList>
        </VacancyIndexCategory>
    );
};

export default VacancyIndexFieldOfStudy;
