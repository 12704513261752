import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCircleInfo: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <g>
            <path d="M46.2,75V40h7.5v35H46.2z" />
            <path d="M50,32.5c2.1,0,3.8-1.7,3.8-3.8S52.1,25,50,25c-2.1,0-3.8,1.7-3.8,3.8S47.9,32.5,50,32.5z" />
        </g>
        <path d="M0,50C0,22.4,22.4,0,50,0c27.6,0,50,22.4,50,50c0,27.6-22.4,50-50,50C22.4,100,0,77.6,0,50z M50,10c-22.1,0-40,17.9-40,40c0,22.1,17.9,40,40,40c22.1,0,40-17.9,40-40C90,27.9,72.1,10,50,10z" />
    </svg>
);

export default IconCircleInfo;
