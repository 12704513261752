import React, { FC, ReactElement } from 'react';

import { Footer } from '../../containers';
import { RoleType } from '../../models/User';
import { useLegacySelector } from '../../store';

interface ConnectedFooterProps {
    className?: string;
}

const ConnectedFooter: FC<ConnectedFooterProps> = ({
    className = '',
}): ReactElement => {
    const role = useLegacySelector(state => state.legacyUser.role);
    const userRole = role || RoleType.none;

    return (
        <Footer
            userRole={userRole}
            className={className}
        />
    );
};

export default ConnectedFooter;
