import React, { FC, ReactElement, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Page, Tab } from '../../components';
import {
    ConnectedMessageOverview,
    ConnectedMyVacancies,
    ConnectedVacancyApplicants,
    ConnectedVacancyMatches,
} from '../../connectors';
import { SubMenu } from '../../containers';
import { trans } from '../../helpers/trans';
import { RouteParams, RoutePaths } from '../../routes';
import { CompanyOverviewTab } from '../../types/pageTabs';

import './CompanyOverview.scss';

interface CompanyOverviewParams extends RouteParams {
    tab?: CompanyOverviewTab;
    uuid?: string;
}

const CompanyOverview: FC = (): ReactElement => {
    const { tab = CompanyOverviewTab.vacancies, uuid = '' } = useParams<CompanyOverviewParams>();

    const tabs = useMemo(() => ([
        {
            key: CompanyOverviewTab.vacancies,
            isActive: tab === CompanyOverviewTab.vacancies,
            to: RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies),
            label: trans('pages.companyOverview.tabs.vacancies'),
        },
        {
            key: CompanyOverviewTab.applicants,
            isActive: tab === CompanyOverviewTab.applicants,
            to: RoutePaths.companyOverviewTab(CompanyOverviewTab.applicants),
            label: trans('pages.companyOverview.tabs.applicants'),
        },
        {
            key: CompanyOverviewTab.matches,
            isActive: tab === CompanyOverviewTab.matches,
            to: RoutePaths.companyOverviewTab(CompanyOverviewTab.matches),
            label: trans('pages.companyOverview.tabs.matches'),
        },
        {
            key: CompanyOverviewTab.messages,
            isActive: tab === CompanyOverviewTab.messages,
            to: RoutePaths.companyOverviewTab(CompanyOverviewTab.messages),
            label: trans('pages.companyOverview.tabs.messages'),
        },
    ]), [tab]);

    return (
        <Page className="company-overview-page">
            <SubMenu<CompanyOverviewTab>
                tabs={tabs}
                className="company-overview-page__sub-menu"
            />

            <div className="company-overview-page__wrapper">
                <Tab isActive={tab === CompanyOverviewTab.vacancies}>
                    <Helmet>
                        <title>{trans('pages.companyOverview.vacancies.meta.title')}</title>
                        <meta name="description" content={trans('pages.companyOverview.vacancies.meta.description')} />
                    </Helmet>

                    <ConnectedMyVacancies />
                </Tab>

                <Tab isActive={tab === CompanyOverviewTab.applicants}>
                    <Helmet>
                        <title>{trans('pages.companyOverview.applicants.meta.title')}</title>
                        <meta name="description" content={trans('pages.companyOverview.applicants.meta.description')} />
                    </Helmet>

                    <ConnectedVacancyApplicants vacancyUuidFromUrl={uuid} />
                </Tab>

                <Tab isActive={tab === CompanyOverviewTab.matches}>
                    <Helmet>
                        <title>{trans('pages.companyOverview.matches.meta.title')}</title>
                        <meta name="description" content={trans('pages.companyOverview.matches.meta.description')} />
                    </Helmet>

                    <ConnectedVacancyMatches vacancyUuidFromUrl={uuid} />
                </Tab>

                <Tab isActive={tab === CompanyOverviewTab.messages}>
                    <Helmet>
                        <title>{trans('pages.companyOverview.messages.meta.title')}</title>
                        <meta name="description" content={trans('pages.companyOverview.messages.meta.description')} />
                    </Helmet>

                    <ConnectedMessageOverview chatUuid={uuid} />
                </Tab>
            </div>
        </Page>
    );
};

export default CompanyOverview;
