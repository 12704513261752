import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { FileModel } from '../models/File';

export const downloadFileFromApi = async (file: FileModel): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/files/${file.uuid}?_disposition`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const blob = await response.blob();
        const objectUrl = await URL.createObjectURL(blob);

        const anchor = document.createElement('a');

        anchor.setAttribute('href', objectUrl);
        anchor.setAttribute('download', file.name);

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[downloadFileFromApi]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
