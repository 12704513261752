import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import './Label.scss';

interface LabelProps {
    text: string;
    isDark?: boolean;
    isSmall?: boolean;
    className?: string;
}

const Label: FC<LabelProps> = ({
    text,
    isDark = false,
    isSmall = false,
    className = '',
}): ReactElement => {
    const labelClassnames = classNames('label', {
        'label--is-dark': isDark,
        'label--is-small': isSmall,
    }, className);

    return (
        <span className={labelClassnames}>
            {text}
        </span>
    );
};

export default Label;
