import React, { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import ucFirst from '../../../_old/helpers/ucFirst';
import { trans } from '../../../helpers/trans';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany } from '../../../store/company/companyActions';

interface ConnectedCompanyHelmetProps {
    shouldFetchCompany?: boolean;
    companyUuid?: string;
}

const ConnectedCompanyHelmet: FC<ConnectedCompanyHelmetProps> = ({
    shouldFetchCompany,
    companyUuid,
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    return company ? (
        <Helmet>
            <title>
                {trans('companyProfile.helmet.title', {
                    companyName: ucFirst(company.name),
                })}
            </title>
            <meta name="description" content={company.description} />
        </Helmet>
    ) : null;
};

export default ConnectedCompanyHelmet;
