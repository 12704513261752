import React, { FC, ReactElement } from 'react';

import { ErrorLabel } from '../../components';
import { ApplicationCard } from '../../compositions';
import { Application } from '../../models/Applications';
import { ApplicationOverviewSkeletons } from './skeletons';

import './ApplicationOverview.scss';

interface ApplicationOverviewProps {
    isCandidate?: boolean;
    isLoading: boolean;
    applications: Application[];
    error: string;
    className?: string;
}

const ApplicationOverview: FC<ApplicationOverviewProps> = ({
    isCandidate,
    isLoading,
    applications,
    error,
    className = '',
}): ReactElement => (
    <div className={`application-overview ${className}`}>
        {isLoading && (
            <ApplicationOverviewSkeletons className="application-overview__skeletons" />
        )}

        {!isLoading && error && (
            <ErrorLabel text={error} className="application-overview__error" />
        )}

        {!isLoading && (
            <ul className="application-overview__list">
                {applications.map(application => (
                    <li key={application.uuid} className="application-overview__item">
                        <ApplicationCard
                            isCandidate={isCandidate}
                            application={application}
                            className="application-overview__card"
                        />
                    </li>
                ))}
            </ul>
        )}
    </div>
);

export default ApplicationOverview;
