/* eslint-disable */

class serviceWorkerHelper {
    registration = undefined;

    /**
     * Convert base 64 to Uint8Array
     *
     * @param base64String
     * @returns {Uint8Array}
     */
    urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    /**
     * Register the serviceWorker when it's not registered yet
     *
     * @returns {Promise}
     */
    register = () => {
        return new Promise((resolve, reject) => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
                    .then((swRegistration) => {
                        this.registration = swRegistration;
                        resolve(swRegistration);
                    })
                    .catch((err) => {
                        console.error('serviceWorker.register failed', err);
                        reject(err);
                    });
            }
        })
    };

    /**
     * Subscribe for push notifications
     *
     * @returns {Promise}
     */
    subscribe = () => {
        return new Promise((resolve, reject) => {
            const registration = this.registration || this.register();
            const webPushPubKey = process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY;

            if (!('pushManager' in registration)) {
                return reject('registration.pushManager.getSubscription is not available');
            }

            registration.pushManager && registration.pushManager.getSubscription()
                .then((subscription) => {
                    return subscription !== null
                        ? (
                            resolve(subscription)
                        )
                        : (
                            registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: this.urlBase64ToUint8Array(webPushPubKey),
                                })
                                .then((newSubscription) => {
                                    resolve(newSubscription);
                                })
                                .catch((err) => {
                                    console.error('registration.pushManager.subscribe failed: ' + err);
                                    reject(err);
                                })
                        );
                })
                .catch((err) => {
                    console.error('registration.pushManager.getSubscription failed: ' + err);
                    reject(err);
                });
        });
    };

    /**
     * Get web push subscription when present
     *
     * @returns {Promise}
     */
    getSubscription = () => {
        return new Promise((resolve, reject) => {
            const registration = this.registration || this.register();

            registration.pushManager && registration.pushManager.getSubscription()
                .then((subscription) => {
                    resolve(subscription);
                })
                .catch((err) => {
                    console.error('registration.pushManager.getSubscription failed: ' + err);
                    reject(err);
                });
        });
    };
}

/**
 * Create ServiceWorker instance
 *
 * @type {serviceWorkerHelper}
 */
export const serviceWorkerHelperInstance = new serviceWorkerHelper();

/**
 * Checks if the push manager is supported
 */
export const isPushManagerSupported = async () => {
    const registration = serviceWorkerHelperInstance.registration || await serviceWorkerHelperInstance.register();

    return 'pushManager' in registration;
};

/**
 * Export initServiceWorker.register
 */
export const registerServiceWorker = () => {
    if (process.env.REACT_APP_ENV !== 'local') {
        return serviceWorkerHelperInstance.register();
    }
};

/**
 * Export initServiceWorker.subscribe
 */
export const subscribeWebPush = () => {
    if (process.env.REACT_APP_ENV !== 'local') {
        return serviceWorkerHelperInstance.subscribe();
    }
};

/**
 * Export initServiceWorker.subscribe
 */
export const getWebPushSubscription = () => {
    if (process.env.REACT_APP_ENV !== 'local') {
        return serviceWorkerHelperInstance.getSubscription();
    }
};
