import React, { ReactElement, ReactNode } from 'react';

import { trans } from '../../../helpers/trans';
import { Col, Row } from '../../styledComponents/components/Grid';
import Icon from '../../styledComponents/components/Icon';

interface LoaderProps {
    children?: ReactNode;
    marginTop?: number | string;
    noText?: boolean;
    justifyContent?: string;
}

const Loader = ({
    children,
    marginTop = '30vh',
    noText,
    justifyContent = 'center',
    ...rest
}: LoaderProps): ReactElement => (
    <Row {...rest} justifyContent={justifyContent} pt={marginTop}>
        <Col>
            <Row>
                <Col pr={noText ? 0 : 'sm'}>
                    <Icon kind="loader" stroke="primary" color="transparent" />
                </Col>
                {!noText && (
                    <Col mr="auto" alignSelf="center">
                        {(children || trans('actions.waiting.moment.form'))}
                    </Col>
                )}
            </Row>
        </Col>
    </Row>
);

export default Loader;
