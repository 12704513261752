import React, { FC, ReactElement, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RegisterUser from '../../_old/app_talentz/app/components/forms/RegisterUser';
import { logIn } from '../../_old/app_talentz/store/legacyUser/legacyUserActions';
import { setUserType } from '../../_old/app_talentz/store/register/register';
import { getLinkedInConnectUrl, registerUser } from '../../_old/app_talentz/store/register/registerActions';
import Overlay from '../../_old/components/Overlay';
import { dataLayerPush } from '../../helpers/analytics';
import { RoleType } from '../../models/User';
import { LegacyReducers } from '../../store/reducers';

interface ConnectedRegisterModalProps {
    notTransparent?: boolean;
    role: RoleType;
    registerUser: (data: any) => Promise<any>;
    getLinkedInConnectUrl: () => Promise<any>;
    setUserType: (type: string) => void;
    logIn: (toggle: boolean) => void;
    onClose: () => void;
    errors: any;
    loading: boolean;
}

const ConnectedRegisterModal: FC<ConnectedRegisterModalProps> = (props): ReactElement => {
    useEffect((): void => {
        props.setUserType(props.role);
    }, []);

    const getLinkedInConnectUrlHandler = () => {
        if (typeof window !== 'undefined') {
            props.getLinkedInConnectUrl().then(
                response => window.open(response.redirect_url, '_self'),
            );
        }
    };

    const onSubmitHandler = (data: any) => {
        props.registerUser(data)
            .then(() => {
                dataLayerPush({
                    event: 'formSubmission',
                    formType: `register-${data.user_type}`,
                });

                props.onClose();
            });
    };

    const onLoginClickHandler = () => {
        props.onClose();
        props.logIn(true);
    };

    return (
        <Overlay
            dark={!props.notTransparent}
            fullBlack={props.notTransparent}
            dontCloseOnclick
            onClose={props.onClose}
        >
            <RegisterUser
                standAlone
                errors={props.errors}
                onSubmit={onSubmitHandler}
                getLinkedInConnectUrl={getLinkedInConnectUrlHandler}
                onLoginClick={onLoginClickHandler}
                loading={props.loading}
                userType={props.role}
            />
        </Overlay>
    );
};

// TODO: when register in the store is refactored, remove and re-implement the following:
export default connect(
    (state: LegacyReducers) => ({
        errors: state.register.errors,
        loading: state.register.loading,
        registerSuccessData: state.register.registerSuccessData,
    }),
    dispatch => bindActionCreators({
        registerUser,
        getLinkedInConnectUrl,
        setUserType,
        logIn,
    }, dispatch),
    // @ts-ignore
)(ConnectedRegisterModal);
