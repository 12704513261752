import React, { FC, ReactElement } from 'react';

import { SbbInfoCard, SbbPeopleCard, SbbRecognitionsCard } from '../../compositions';
import { SbbCompanyInfo } from '../../models/SbbTrainingCompany';

import './CompanySbbInfo.scss';

interface CompanySbbInfoProps {
    isLoading: boolean;
    hasUpdated?: boolean;
    userFromCompany?: boolean;
    sbbCompanyInfo: SbbCompanyInfo;
    onUpdateSbbInfo?: () => void;
    className?: string;
}

const CompanySbbInfo: FC<CompanySbbInfoProps> = ({
    isLoading,
    hasUpdated,
    userFromCompany,
    sbbCompanyInfo,
    onUpdateSbbInfo,
    className = '',
}): ReactElement => (
    <div className={`company-sbb-info ${className}`}>
        <SbbInfoCard
            isLoading={isLoading}
            isUpdated={hasUpdated}
            userFromCompany={userFromCompany}
            classification={sbbCompanyInfo.sbbCompanyName}
            sbbNumber={sbbCompanyInfo.sbbId}
            onUpdateSbbInfo={onUpdateSbbInfo}
            className="company-sbb-info__card"
        />
        <SbbPeopleCard
            recognitions={sbbCompanyInfo.recognitions}
            className="company-sbb-info__card"
        />
        <SbbRecognitionsCard
            recognitions={sbbCompanyInfo.recognitions}
            className="company-sbb-info__card"
        />
    </div>
);

export default CompanySbbInfo;
