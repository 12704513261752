import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getJobVacanciesApiCall, getMatchingJobVacanciesApiCall } from '../../services/JobVacancyOverviewService';
import { ReducerGetter, TypedDispatch } from '..';
import {
    setError,
    setIsLoading,
    setPagination,
    setVacancies,
} from './jobVacancyOverview';

export const fetchMatchingJobVacancyOverview = () => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { jobVacancyOverviewReducer } = getState();
        const { activeFilterValues } = jobVacancyOverviewReducer;

        const jobVacanciesResponse = await getMatchingJobVacanciesApiCall(activeFilterValues);

        if (!isFetchResultSuccessful(jobVacanciesResponse)) {
            console.error('[fetchMatchingJobVacancyOverview]', jobVacanciesResponse.error);
            dispatch(setError(jobVacanciesResponse.error));
            dispatch(setPagination(undefined));
            dispatch(setVacancies([]));
            return;
        }

        const { pagination, vacancies } = jobVacanciesResponse.data;

        dispatch(setPagination(pagination));
        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchMatchingJobVacancyOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchJobVacancyOverview = () => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { jobVacancyOverviewReducer } = getState();
        const { activeFilterValues } = jobVacancyOverviewReducer;

        const jobVacanciesResponse = await getJobVacanciesApiCall(activeFilterValues);

        if (!isFetchResultSuccessful(jobVacanciesResponse)) {
            console.error('[fetchJobVacancyOverview]', jobVacanciesResponse.error);
            dispatch(setError(jobVacanciesResponse.error));
            dispatch(setPagination(undefined));
            dispatch(setVacancies([]));
            return;
        }

        const { pagination, vacancies } = jobVacanciesResponse.data;

        dispatch(setPagination(pagination));
        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchJobVacancyOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
