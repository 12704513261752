import React, { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { HomeHero } from '../../containers';
import { VacancySearchFormValues } from '../../containers/@forms/VacancySearchForm/VacancySearchForm';
import { encodeSearchQuery } from '../../helpers/search';
import { RoutePaths } from '../../routes';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchEscoOccupations } from '../../store/escoOccupations/escoOccupationsActions';

interface ConnectedHomeHeroProps {
    className?: string;
}

const ConnectedHomeHero: FC<ConnectedHomeHeroProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const escoOccupations = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const residences = useTypedSelector(state => state.residencesReducer.residences);

    useEffect((): void => {
        if (escoOccupations.length === 0) dispatch(fetchEscoOccupations());
    }, []);

    const handleSearch = (values: VacancySearchFormValues): void => {
        const searchQuery = encodeSearchQuery({
            what: values.what,
            where: values.where,
        });

        const route = values.type === VacancyType.job
            ? RoutePaths.jobsQuery(searchQuery)
            : RoutePaths.internshipsQuery(searchQuery);

        navigate(route);
    };

    const escoOccupationNames = escoOccupations.map(escoOccupation => escoOccupation.name);

    return (
        <HomeHero
            whatOptions={escoOccupationNames}
            whereOptions={residences}
            onSearch={handleSearch}
            className={className}
        />
    );
};

export default ConnectedHomeHero;
