import React, {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { ErrorLabel, ProgressRefButton } from '../../../../../components';
import {
    ExpandableNavigation,
    IconButton,
    Modal,
    ModalContent,
    Theatre,
} from '../../../../../compositions';
import { maxFileUploadSize } from '../../../../../constants';
import { trans } from '../../../../../helpers/trans';
import useHandleClickOutside from '../../../../../hooks/useHandleClickOutside';
import { Company } from '../../../../../models/Company';
import { FileFormData } from '../../../../../models/File';
import { YouTubeFormData } from '../../../../../models/YouTube';
import { ImageDropForm, YouTubeForm } from '../../../..';
import { ActivationRef } from '../../../ProfileProgress/ProfileProgress';

import './CompanyHeaderCover.scss';

enum CompanyProfileCoverControl {
    imageEdit = 'image-edit',
    imageDelete = 'image-delete',
    youTubeEdit = 'youTube-edit',
    youTubeDelete = 'youTube-delete',
}

interface CompanyHeaderCoverProps {
    coverImageActivationRef?: ActivationRef;
    youTubeActivationRef?: ActivationRef;
    isEditable: boolean;
    company: Company;
    onEditCoverImage: (coverImageFormData: FileFormData) => void;
    onEditYouTube: (youTubeFormData: YouTubeFormData) => void;
    className?: string;
}

const CompanyHeaderCover: FC<CompanyHeaderCoverProps> = ({
    coverImageActivationRef,
    youTubeActivationRef,
    isEditable,
    company,
    onEditCoverImage,
    onEditYouTube,
    className = '',
}): ReactElement | null => {
    const [coverControlsIsExpanded, toggleCoverControlsIsExpanded] = useToggle(false);
    const [theatreIsOpen, setTheatreIsOpen] = useState<boolean>(false);
    const [youTubeEditModalIsOpen, setYouTubeEditModalIsOpen] = useState<boolean>(false);
    const [coverImageError, setCoverImageError] = useState<string>('');

    const coverControlsRef = useRef<HTMLDivElement>(null);
    const coverImageDeleteRef = useRef<HTMLButtonElement>(null);

    const closeCoverControls = (): void => toggleCoverControlsIsExpanded(false);

    useHandleClickOutside(coverControlsRef, closeCoverControls);

    const openTheatre = (): void => setTheatreIsOpen(true);
    const closeTheatre = (): void => setTheatreIsOpen(false);

    const openYouTubeEditModal = (): void => setYouTubeEditModalIsOpen(true);
    const closeYouTubeEditModal = (): void => setYouTubeEditModalIsOpen(false);

    const handleEditCoverImage = (): void => {
        if (coverImageActivationRef?.current) {
            coverImageActivationRef.current.click();
        }
    };

    const handleDeleteCoverImage = (): void => {
        if (coverImageDeleteRef?.current) {
            coverImageDeleteRef.current.click();
        }
    };

    const handleEditYouTube = (youTubeFormData: YouTubeFormData): void => {
        onEditYouTube(youTubeFormData);
        closeYouTubeEditModal();
    };

    const handleDeleteYouTube = (): void => {
        onEditYouTube({ embedId: '' });
    };

    const coverControlsToggleButtonLabel = coverControlsIsExpanded
        ? trans('companyProfile.cover.controls.toggle.close')
        : trans('companyProfile.cover.controls.toggle.open');

    const coverControls = [
        {
            key: CompanyProfileCoverControl.imageEdit,
            label: trans('companyProfile.cover.controls.image.edit'),
            icon: 'camera',
            onClick: handleEditCoverImage,
        },
        {
            key: CompanyProfileCoverControl.imageDelete,
            label: trans('companyProfile.cover.controls.image.delete'),
            icon: 'delete',
            disabled: !company.coverImage,
            onClick: handleDeleteCoverImage,
        },
        {
            key: CompanyProfileCoverControl.youTubeEdit,
            label: trans('companyProfile.cover.controls.youTube.edit'),
            icon: 'video',
            onClick: openYouTubeEditModal,
        },
        {
            key: CompanyProfileCoverControl.youTubeDelete,
            label: trans('companyProfile.cover.controls.youTube.delete'),
            icon: 'delete',
            disabled: !company.coverVideo,
            onClick: handleDeleteYouTube,
        },
    ];

    const companyHeaderCoverClassNames = classNames('company-header-cover', {
        'company-header-cover--is-editable': isEditable,
    }, className);

    return (
        <div className={companyHeaderCoverClassNames}>
            <ProgressRefButton ref={youTubeActivationRef} onClick={openYouTubeEditModal} />

            {isEditable ? (
                <div ref={coverControlsRef} className="company-header-cover__controls-wrapper">
                    {company.coverVideo && (
                        <IconButton
                            icon="play"
                            text={trans('actions.watch')}
                            hideLabel
                            onClick={openTheatre}
                            className="company-header-cover__control-button"
                            iconClassName="company-header-cover__control-play-icon"
                        />
                    )}

                    <IconButton
                        icon="pencil"
                        text={coverControlsToggleButtonLabel}
                        hideLabel
                        onClick={toggleCoverControlsIsExpanded}
                        className="company-header-cover__control-button"
                    />

                    <ExpandableNavigation
                        isExpanded={coverControlsIsExpanded}
                        name="cover-controls"
                        navigationItems={coverControls}
                        onClose={closeCoverControls}
                        className="company-header-cover__controls-list"
                    />
                </div>
            ) : (
                <div className="company-header-cover__play-button-wrapper">
                    {company.coverVideo && (
                        <IconButton
                            icon="play"
                            text={trans('actions.watch')}
                            hideLabel
                            onClick={openTheatre}
                            className="company-header-cover__play-button"
                            iconClassName="company-header-cover__play-icon"
                        />
                    )}
                </div>
            )}

            {isEditable ? (
                <ImageDropForm
                    activationRef={coverImageActivationRef}
                    deleteButtonRef={coverImageDeleteRef}
                    shouldCrop
                    hasCropBoundaries
                    label={trans('companyProfile.cover.image.alt')}
                    value={company.coverImage}
                    cropAspectRatio={1400 / 320}
                    validationOptions={{
                        minWidth: 1400,
                        minHeight: 320,
                        sizeLimit: maxFileUploadSize,
                    }}
                    onSubmit={onEditCoverImage}
                    onError={setCoverImageError}
                    className="company-header-cover__cover-image-form"
                />
            ) : (
                company.coverImage && (
                    <img
                        src={company.coverImage}
                        alt={trans('companyProfile.cover.image.alt')}
                        className="company-header-cover__image"
                    />
                )
            )}

            {coverImageError && (
                <div className="company-header-cover__error-wrapper">
                    <ErrorLabel
                        text={coverImageError}
                        className="company-header-cover__error-label"
                    />
                </div>
            )}

            {company.coverVideo && theatreIsOpen && (
                <Theatre
                    autoPlay
                    embedId={company.coverVideo}
                    onClose={closeTheatre}
                />
            )}

            {youTubeEditModalIsOpen && (
                <Modal onClose={closeYouTubeEditModal}>
                    <ModalContent title={trans('companyProfile.youTube.modal.title')}>
                        <YouTubeForm
                            embedId={company.coverVideo}
                            onCancel={closeYouTubeEditModal}
                            onSubmit={handleEditYouTube}
                        />
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default CompanyHeaderCover;
