import { trans } from './trans';

export const isCreboNumber = (string: string): boolean => (
    string.length === 5 && /^\d+$/.test(string)
);

export const capitalizeFirstCharacter = (string: string): string => (
    string.trim().charAt(0).toUpperCase() + string.trim().substring(1)
);

export const slugifyString = (string: string, spaceCharacter = '-'): string => (
    string.trim().toLowerCase().replace(/ /g, spaceCharacter)
);

export const trimToMaxLength = (string: string, maxLength = 100): string => {
    if (string.length <= maxLength) return string;

    const words = string.substring(0, maxLength).split(' ');
    words.pop();

    const output = words.join(' ').trim();

    return `${output}…`;
};

export const getInitials = (name: string): string => {
    const cleanName = name.replace(/[^a-zA-Z ]/g, ' ');

    const capitals = cleanName
        .split('')
        .filter(letter => letter === letter.toUpperCase())
        .filter(letter => letter !== ' ')
        .join('');

    if (capitals.length >= 2) {
        return capitals.substring(0, 3);
    }

    const words = cleanName.split(' ');
    const firstLetters = words.map(word => word[0]).filter(Boolean);

    return firstLetters.length === 1
        ? cleanName.substring(0, 3)
        : firstLetters.join('').substring(0, 3);
};

export const generateQueryHighlight = (label: string, query?: string): [string, string, string] => {
    if (!query) return [label, '', ''];

    const cleanLabel = label.toLowerCase();
    const cleanQuery = query.toLowerCase();

    const [start = ''] = cleanLabel.split(cleanQuery);
    const startIndex = start.length;
    const endIndex = start.length + query.length;

    return [
        label.slice(0, startIndex),
        label.slice(startIndex, endIndex),
        label.slice(endIndex),
    ];
};

export const addHourUnit = (hour: string | number): string => trans('basic.format.hour', {
    hour: String(hour),
}).split(' ').join('');

export const addKmUnit = (distance: string | number): string => trans('basic.format.km', {
    distance: String(distance),
}).split(' ').join('');

export const convertStringToWebsiteURL = (websiteURL: string): string => {
    if (websiteURL.startsWith('http://') || websiteURL.startsWith('https://')) {
        return websiteURL;
    }
    return `http://${websiteURL}`;
};
