import axios from 'axios';
import { Dispatch } from 'redux';

import { dataLayerPush } from '../../../../helpers/analytics';
import { TOKEN_KEY } from '../../../../helpers/authorizedFetch';
import { hotjarEvent } from '../../../../helpers/hotjar';
import { isFetchResultSuccessful } from '../../../../models/FetchResult';
import { RoleType } from '../../../../models/User';
import { RoutePaths } from '../../../../routes';
import { logSocialsUserInApiCall } from '../../../../services/AuthenticationService';
import { updateSbbCompanyInfo } from '../../../../store/company/companyActions';
import { LegacyReducers } from '../../../../store/reducers';
import {
    setAuthenticated as setUserAuthenticated,
    setData,
    setLoading as setUserLoading,
    setLoadingSocialMedia as setUserLoadingSocialMedia,
    setPermissions,
    setRole,
} from '../legacyUser/legacyUser';
import {
    authenticateUser,
    environmentPermissionsChecker,
    getUser,
    getUserWithProfile,
    setUserToken,
} from '../legacyUser/legacyUserActions';
import {
    setLoading as setPersonLoading,
    setPrefillData as setPersonPrefillData,
    setUpdating as setPersonUpdating,
} from '../userPerson/userPerson';
import { createPerson } from '../userPerson/userPersonActions';
import { createProfile } from '../userProfile/userProfileActions';
import {
    setActivationToken,
    setAuthCompleted,
    setCompleteStep,
    setErrors,
    setLoading,
    setLoadingSocialMedia,
    setRegisterSuccessData,
    setStep,
} from './register';

export function registerUser(data: any) {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { register } = getState();
        data.role = register.userType;
        data.redirect_url = '/?activation-token=';

        return axios.post('/authentication/passport/register', data)
            .then(response => {
                dispatch(setRegisterSuccessData(response.data.data));
                dispatch(setStep(2));
                dispatch(setLoading(false));

                hotjarEvent('vpv', `/registreren/activatie-link/${data.user_type}`);

                dataLayerPush({
                    event: 'formSubmission',
                    formType: `register-${data.user_type}`,
                });

                return Promise.resolve(response.data.data);
            }).catch(error => {
                const errorObject = error.response.data.errors
                    ? error.response.data.errors
                    : error;

                dispatch(setErrors(errorObject));
                dispatch(setLoading(false));
                dispatch(setLoadingSocialMedia(false));

                return Promise.reject(error);
            });
    };
}

export function getUserByToken(token: string, navigate: any) {
    return async (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await axios.get(`/authentication/passport/user/${token}`);

            response.data.data.activation_token = token;

            const user = response.data.data;
            const envPermissions = environmentPermissionsChecker();

            dispatch(setData(user));
            dispatch(setRole(user.roles[0].slug as RoleType));
            dispatch(setPermissions([...new Set([...envPermissions, ...user.permissions])]));

            dispatch(setCompleteStep(0));
            dispatch(setActivationToken(null));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error: any) {
            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            navigate(RoutePaths.home());
            return Promise.reject(error);
        }
    };
}

export function setPasswordAndAuthenticate(data: any): any {
    // eslint-disable-next-line consistent-return
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        const { legacyUser, register } = getState();

        if (!legacyUser?.data || !legacyUser?.data?.activation_token) {
            return null;
        }

        dispatch(setLoading(true));

        data.token = legacyUser.data.activation_token;

        try {
            const response = await axios.post('/authentication/passport/password', data);

            dispatch(setUserToken(response.data.data));

            // Update Redux authentication state
            dispatch(setUserLoading(false));
            dispatch(setUserLoadingSocialMedia(false));
            dispatch(setUserAuthenticated(true));

            dispatch(setCompleteStep(register.completeStep + 1));
            dispatch(setAuthCompleted(true));
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            dispatch(authenticateUser({
                username: legacyUser?.data.email,
                password: data.password,
            }));
        } catch (error: any) {
            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));
        }
    };
}

export function preFillProfile() {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        const { legacyUser } = getState();

        try {
            const response = await axios.get(`/authentication/linkedin/user/${legacyUser.tokenData.driver_token}`);
            const preFillData = {
                first_name: response.data.data.first_name,
                last_name: response.data.data.last_name,
                full_name: response.data.data.full_name,
                avatar: response.data.data.avatar,
            };

            dispatch(setPersonPrefillData(preFillData));
            dispatch(setPersonLoading(false));
            dispatch(setPersonUpdating(false));

            return Promise.resolve(preFillData);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export function getLinkedInConnectUrl() {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { register } = getState();
        const redirectUrl = encodeURI(`registreren/linkedin/?userType=${register.userType}`);

        try {
            const response = await axios.get(`/authentication/linkedin/url?redirect_url=${redirectUrl}`);

            window.open(response.data.data.redirect_url, '_self');

            return Promise.resolve(response.data.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export function getTokenFromNewApi(accessToken: string) {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        const { legacyUser } = getState();

        if (!legacyUser?.data?.email) {
            return;
        }

        const loginResponse = await logSocialsUserInApiCall(accessToken, legacyUser?.data?.email);

        if (isFetchResultSuccessful(loginResponse)) {
            localStorage.setItem(TOKEN_KEY, JSON.stringify(loginResponse.data));
        }
    };
}

export function registerLinkedInUser(role: string, code: string, navigate: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        const { register } = getState();

        dispatch(setLoading(true));
        dispatch(setLoadingSocialMedia(true));

        const redirectUrl = encodeURI(`registreren/linkedin/?userType=${role}`);

        try {
            const response = await axios.post('/authentication/linkedin/register', {
                code,
                role,
                redirect_url: redirectUrl,
            });

            await dispatch(setUserToken(response.data.data));

            if (response.data.data.is_new_user) {
                await dispatch(preFillProfile());
                await dispatch(getUser());

                const accessToken = response.data.data.access_token;
                dispatch(getTokenFromNewApi(accessToken));

                // Update Redux authentication state
                dispatch(setUserLoading(false));
                dispatch(setUserLoadingSocialMedia(false));
                dispatch(setUserAuthenticated(true));

                dispatch(setCompleteStep(register.completeStep + 1));
                dispatch(setAuthCompleted(true));
                dispatch(setLoading(false));
                dispatch(setLoadingSocialMedia(false));

                navigate(RoutePaths.registration());
                return;
            }

            await dispatch(getUserWithProfile());

            const accessToken = response.data.data.access_token;
            dispatch(getTokenFromNewApi(accessToken));

            // Update Redux authentication state
            dispatch(setUserLoading(false));
            dispatch(setUserLoadingSocialMedia(false));
            dispatch(setUserAuthenticated(true));

            dispatch(setCompleteStep(register.completeStep + 1));
            dispatch(setAuthCompleted(true));
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            navigate(RoutePaths.home());
        } catch (error) {
            console.error('[registerLinkedInUser]', error);

            navigate(RoutePaths.home());
        }
    };
}

export function registerUserProfile(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        try {
            await dispatch(createProfile(data));
            await dispatch(getUserWithProfile());

            const { legacyUser, userCompany, register } = getState();

            if (legacyUser.role === RoleType.company && userCompany.data) {
                await dispatch(updateSbbCompanyInfo(userCompany.data.uuid));
            }

            dispatch(setCompleteStep(register.completeStep + 1));
            dispatch(setLoading(false));

            return Promise.resolve();
        } catch (error: any) {
            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            return Promise.resolve();
        }
    };
}

export function registerPerson(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        const { register } = getState();

        dispatch(setLoading(true));

        try {
            await dispatch(createPerson(data));
            await dispatch(getUserWithProfile());

            dispatch(setCompleteStep(register.completeStep + 1));
            dispatch(setLoading(false));

            return Promise.resolve();
        } catch (error: any) {
            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));
            dispatch(setLoadingSocialMedia(false));

            return Promise.reject(error);
        }
    };
}
