import { trans } from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { NotificationStatus } from '../../models/Notifications';
import {
    deleteNotificationApiCall,
    getNotificationsApiCall,
    updateAllNotificationsApiCall,
    updateNotificationApiCall,
} from '../../services/NotificationsService';
import { ReducerGetter, TypedDispatch } from '..';
import { addNegativeToast } from '../toast/toastActions';
import {
    setAmountOfNew,
    setIsExpanded,
    setIsLoading,
    setNotifications,
} from './notifications';

export const toggleExpandNotifications = (newState?: boolean) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const state = getState();
    const { isExpanded } = state.notificationsReducer;

    dispatch(setIsExpanded(newState ?? !isExpanded));
};

export const fetchNotifications = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const fetchNotificationsResponse = await getNotificationsApiCall();

        if (!isFetchResultSuccessful(fetchNotificationsResponse)) {
            console.error('[fetchNotifications]', fetchNotificationsResponse.error);
            return;
        }

        const allNotifications = fetchNotificationsResponse.data;
        const newNotifications = fetchNotificationsResponse.data.filter(notification => notification.status === NotificationStatus.new);

        dispatch(setNotifications(allNotifications));
        dispatch(setAmountOfNew(newNotifications.length));
    } catch (error) {
        console.error('[fetchNotifications]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteNotification = (notificationId: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const state = getState();
    const { notifications } = state.notificationsReducer;

    dispatch(setIsLoading(true));

    try {
        const deleteNotificationResponse = await deleteNotificationApiCall(notificationId);

        if (!isFetchResultSuccessful(deleteNotificationResponse)) {
            console.error('[deleteNotification]', deleteNotificationResponse.error);

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: deleteNotificationResponse.error,
            }));

            return;
        }

        const updatedNotifications = [...notifications].filter(notification => notification.id !== notificationId);

        dispatch(setNotifications(updatedNotifications));
    } catch (error) {
        console.error('[deleteNotification]', error);

        dispatch(addNegativeToast({
            title: trans('errors.unknownError'),
        }));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateNotificationStatus = (notificationId: string, status: NotificationStatus) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const state = getState();
    const { notifications } = state.notificationsReducer;

    dispatch(setIsLoading(true));

    try {
        const updateNotificationsResponse = await updateNotificationApiCall(notificationId, { status });

        if (!isFetchResultSuccessful(updateNotificationsResponse)) {
            console.error('[updateNotificationStatus]', updateNotificationsResponse.error);

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: updateNotificationsResponse.error,
            }));

            return;
        }

        const updatedNotificationList = [...notifications];
        const notificationIndex = updatedNotificationList.findIndex(notification => notification.id === notificationId);
        const updatedNotification = updateNotificationsResponse.data;

        updatedNotificationList.splice(notificationIndex, 1, updatedNotification);

        dispatch(setNotifications(updatedNotificationList));
    } catch (error) {
        console.error('[updateNotificationStatus]', error);

        dispatch(addNegativeToast({
            title: trans('errors.unknownError'),
        }));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateNotificationListStatus = (status: NotificationStatus) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const updateAllNotificationsResponse = await updateAllNotificationsApiCall({ status });

        if (!isFetchResultSuccessful(updateAllNotificationsResponse)) {
            console.error('[updateNotificationListStatus]', updateAllNotificationsResponse.error);

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: updateAllNotificationsResponse.error,
            }));

            return;
        }

        const allNotifications = updateAllNotificationsResponse.data;
        const newNotifications = updateAllNotificationsResponse.data.filter(notification => notification.status === NotificationStatus.new);

        dispatch(setNotifications(allNotifications));
        dispatch(setAmountOfNew(newNotifications.length));
    } catch (error) {
        console.error('[updateNotificationListStatus]', error);

        dispatch(addNegativeToast({
            title: trans('errors.unknownError'),
        }));
    } finally {
        dispatch(setIsLoading(false));
    }
};
