import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface WorkRemoteResource extends Resource {
    type: 'remote-working';
    id: string;
    attributes: {
        name: string;
        slug: string;
    };
}

export interface WorkRemoteOption extends FormOption {
    id: string;
}

export interface WorkRemotePreferenceFormData {
    workRemotePreference: FormOption;
}

export const transformToWorkRemoteOption = (
    workRemoteResource: WorkRemoteResource,
): WorkRemoteOption => ({
    id: workRemoteResource.id,
    label: workRemoteResource.attributes.name,
    value: workRemoteResource.attributes.slug,
});

export const transformWorkRemoteOptionToFormOption = (
    workRemoteOption: WorkRemoteOption,
): FormOption => ({
    label: workRemoteOption.label,
    value: workRemoteOption.id,
});
