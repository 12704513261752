import React, { FC, ReactElement } from 'react';

import { CandidateProfileBreadcrumbs } from '../../../containers';
import { useTypedSelector } from '../../../store';

interface ConnectedCandidateProfileBreadcrumbsProps {
    isCompanyApplication?: boolean;
    isCompanyMatch?: boolean;
    className?: string;
}

const ConnectedCandidateProfileBreadcrumbs: FC<ConnectedCandidateProfileBreadcrumbsProps> = ({
    isCompanyApplication,
    isCompanyMatch,
    className,
}): ReactElement => {
    const vacancyIsLoading = useTypedSelector(state => state.vacancyDetailReducer.isLoading);
    const vacancy = useTypedSelector(state => state.vacancyDetailReducer.vacancy);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    return (
        <CandidateProfileBreadcrumbs
            isLoading={vacancyIsLoading || candidateIsLoading}
            isCompanyApplication={isCompanyApplication}
            isCompanyMatch={isCompanyMatch}
            vacancy={vacancy}
            candidate={candidate}
            className={className}
        />
    );
};

export default ConnectedCandidateProfileBreadcrumbs;
