import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface SectorsResource extends Resource {
    type: 'sectors';
    id: string;
    attributes: {
        slug: string;
        name: string;
    };
}

export interface Sector {
    id: string;
    slug: string;
    name: string;
}

export interface SectorsFormData {
    sectors: FormOption[];
}

export const transformToSector = (
    sectorsResource: SectorsResource,
): Sector => ({
    id: sectorsResource.id,
    slug: sectorsResource.attributes.slug,
    name: sectorsResource.attributes.name,
});

export const transformSectorToFormOption = (
    sector: Sector,
): FormOption => ({
    label: sector.name,
    value: sector.id,
});

export const transformSectorToSlugFormOption = (
    sector: Sector,
): FormOption => ({
    label: sector.name,
    value: sector.slug,
});
