import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { ThemeProvider } from 'styled-components';

import { ConnectedToastList } from '../../connectors';
import { dataLayerPush } from '../../helpers/analytics';
import { scrollToTop } from '../../helpers/scroll';
import { trans } from '../../helpers/trans';
import useMailAnalytics from '../../hooks/useMailAnalytics';
import RouteTree from '../../routes';
import { useLegacySelector, useTypedDispatch } from '../../store';
import { addNegativeToast } from '../../store/toast/toastActions';
import AppUpdates from '../components/AppUpdates';
import theme from '../styledComponents/theme';
import Authentication from './app/authentication/Authentication';
import AppContainer from './app/containers/AppContainer';
import { setAppUpdates } from './store/app/app';
import { updateAppUpdate } from './store/app/appActions';

import '../../styles/global.scss';

const { GlobalStyling } = theme;

const App: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const appUpdates = useLegacySelector(state => state.app.appUpdates);

    const title = useRef<string | null>(null);
    const url = useRef<string | null>(null);

    useMailAnalytics();

    useEffect((): void => {
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response) {
                    if (error.response.status === 500) {
                        console.error('[App]', error);
                        dispatch(addNegativeToast({
                            title: trans('errors.unknownError'),
                        }));
                    }
                }

                console.error('[App]', error);
                return Promise.reject(error);
            },
        );
    }, []);

    useEffect(scrollToTop, [location.pathname]);

    const handleHelmetChangeState = (newState: any) => {
        if (typeof window === 'undefined' || typeof document === 'undefined') {
            return;
        }

        const href = window.location.origin + location.pathname + location.search;

        if (!newState.title || (title.current === newState.title && url.current === href)) {
            return;
        }

        title.current = newState.title;
        url.current = href;

        dataLayerPush({
            event: 'Pageview',
            pagePath: href,
            pageTitle: newState.title,
        });
    };

    const shareableUrl = `${process.env.REACT_APP_URL}${location.pathname}`;

    return (
        <ThemeProvider theme={theme}>
            <Helmet titleTemplate={`%s | ${trans('basic.appName')}`} onChangeClientState={handleHelmetChangeState}>
                <meta property="og:url" content={shareableUrl} />
                <meta property="og:title" content={trans('basic.appName')} />
                <meta property="og:description" content={trans('basic.appDescription')} />
                <meta property="og:image" content={`${process.env.REACT_APP_URL}/images/social-thumbnail.png`} />
                <meta property="og:type" content="website" />
            </Helmet>

            <GlobalStyling />

            <Authentication>
                {(isLoading, userRole) => (
                    <AppContainer authIsLoading={isLoading}>
                        <RouteTree userRole={userRole} />
                    </AppContainer>
                )}
            </Authentication>

            {appUpdates.length > 0 && (
                <AppUpdates
                    clearUpdates={() => dispatch(setAppUpdates([]))}
                    onUpdateRead={(uuid: string) => dispatch(updateAppUpdate(uuid))}
                    updates={appUpdates}
                />
            )}

            <ConnectedToastList />
        </ThemeProvider>
    );
};

export default App;
