import styled, { css } from 'styled-components';

export const Footer = styled('div')(props => css`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
    
    @media ${props.theme.mediaQueries.tablet} {
        flex-direction: row;
        justify-content: flex-end;
    }
`);

export default Footer;
