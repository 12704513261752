import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { LinkButton } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './BlogCategoryFilterButtons.scss';

interface BlogCategoryFilterButtonsProps {
    categories: string[];
    currentCategory: string;
    className?: string;
}

const BlogCategoryFilterButtons: FC<BlogCategoryFilterButtonsProps> = ({
    categories,
    currentCategory,
    className = '',
}): ReactElement | null => {
    if (categories.length === 0) {
        return null;
    }

    const allButtonClassNames = classNames('blog-category-filter-buttons__button', {
        'blog-category-filter-buttons__button--is-active': !currentCategory,
    });

    return (
        <ul className={`blog-category-filter-buttons ${className}`}>
            <li className="blog-category-filter-buttons__item">
                <LinkButton
                    to={RoutePaths.blog()}
                    text={trans('blog.categories.all')}
                    className={allButtonClassNames}
                />
            </li>

            {categories.map(category => {
                const categoryButtonClassNames = classNames('blog-category-filter-buttons__button', {
                    'blog-category-filter-buttons__button--is-active': category === currentCategory,
                });

                return (
                    <li key={category} className="blog-category-filter-buttons__item">
                        <LinkButton
                            to={RoutePaths.blogCategory(category)}
                            text={category}
                            className={categoryButtonClassNames}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default BlogCategoryFilterButtons;
