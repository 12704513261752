import React, { FC, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedRegisterModal } from '../../connectors';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';

const RegisterCompany: FC = (): ReactElement => {
    const navigate = useNavigate();

    const handleCloseClick = (): void => {
        navigate(RoutePaths.home());
    };

    return (
        <Page className="register-company-page">
            <ConnectedRegisterModal
                notTransparent
                role={RoleType.company}
                onClose={handleCloseClick}
            />
        </Page>
    );
};

export default RegisterCompany;
