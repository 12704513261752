import React, {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';

interface TextInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    type?: string;
    required?: boolean;
    optional?: boolean;
    error?: string;
    onChange?: (value: string) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    inputClassName?: string;
}

type TextInputWithRefProps = TextInputProps & RefAttributes<HTMLInputElement>;

const TextInput: ForwardRefExoticComponent<TextInputWithRefProps> = forwardRef(({
    label,
    hideLabel = false,
    type = 'text',
    required = false,
    optional = false,
    error = '',
    onChange,
    onBlur,
    className = '',
    inputClassName = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => (
        onChange && onChange(event.currentTarget.value)
    );

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => (
        onBlur && onBlur(event)
    );

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`text-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    optional={optional}
                />
            )}

            <Input
                {...inputProps}
                ref={ref}
                type={type}
                required={required}
                error={error}
                onChange={handleChange}
                onBlur={handleBlur}
                className={inputClassName}
            />

            {error && (
                <ErrorLabel text={error} />
            )}
        </label>
    );
});

export default TextInput;
