import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { trans } from '../../../../helpers/trans';
import Button from '../../../components/Button';
import MultiStepOverlay from '../../../components/MultiStepOverlay';
import { setStep as setResetPasswordStep } from '../../store/resetPassword/resetPassword';
import { resetPasswordEmail } from '../../store/resetPassword/resetPasswordActions';
import PasswordForgetForm from '../components/forms/PasswordForgetForm';

class PasswordForget extends Component {
    getSteps() {
        const { errors, loading } = this.props;

        return [
            {
                component: (
                    <PasswordForgetForm
                        errors={errors}
                        onSubmit={this.props.resetPasswordEmail}
                        loading={loading}
                    />
                ),
            },
            {
                message: {
                    body: trans('forms.passwordForget.emailSent'),
                    button: (
                        <Button
                            onClick={() => this.props.setResetPasswordStep(null)}
                            color="white"
                            mt="lg"
                            icon="backward"
                            id="password-forget-back-to-home"
                            iconSize={14}
                        >
                            {trans('actions.backToHome')}
                        </Button>
                    ),
                },
                component: null,
            },
        ];
    }

    render() {
        const step = this.getSteps()[this.props.step] || null;

        const handleClose = () => {
            this.props.setResetPasswordStep(null);
        };

        return (
            <MultiStepOverlay
                step={this.props.step}
                onClose={handleClose}
                message={step ? step.message : null}
            >
                {step && step.component}
            </MultiStepOverlay>
        );
    }
}

PasswordForget.propTypes = {
    errors: PropTypes.object,
    resetPasswordEmail: PropTypes.func.isRequired,
    step: PropTypes.number,
    setResetPasswordStep: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

PasswordForget.defaultProps = {
    step: null,
    errors: {},
};

export default connect(
    state => ({
        errors: state.resetPassword.errors,
        loading: state.resetPassword.loading,
        step: state.resetPassword.step,
    }),
    dispatch => bindActionCreators({
        resetPasswordEmail,
        setResetPasswordStep,
    }, dispatch),
)(PasswordForget);
