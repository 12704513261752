import { Address, AddressFormValues, transformAddressToAddressFormValues } from '../_old/app_talentz/models/Address';
import { transformAddressToOldAddress } from '../models/Address';
import { EditableVacancy } from '../models/EditableVacancy';
import { MinMaxValue } from '../types';

export interface PlaceJobVacancyFormValues {
    title: string;
    address: AddressFormValues | null;
    applyAnonymous: boolean;
    expirationDate: Date | null;
    publicationDate: Date | null;
    educationLevelIds: string[];
    fieldsOfStudyIds: string[];
    employmentHourRange: MinMaxValue;
    minMonthSalary: number | '';
    maxMonthSalary: number | '';
    minHourSalary: number | '';
    maxHourSalary: number | '';
    description: string;
    jobTypeId: string;
    workRemotePreferenceId: string;
    escoOccupationIds: string[];
    skillIds: string[];
    escoSkillIds: string[];
    competencyIds: string[];
    sectorId: string;
}

export interface PlaceInternshipVacancyFormValues {
    title: string;
    address: AddressFormValues | null;
    applyAnonymous: boolean;
    sectorId: string;
    expirationDate: Date | null;
    publicationDate: Date | null;
    educationLevelId: string;
    internshipTypeId: string;
    fieldsOfStudyIds: string[];
    gradeIds: string[];
    minCompensation: number | '';
    maxCompensation: number | '';
    periodFrom: Date | null;
    periodTill: Date | null;
    workRemotePreferenceId: string;
    description: string;
    skillIds: string[];
    skillsToLearnIds: string[];
    competencyIds: string[];
    competenciesToLearnIds: string[];
}

export function transformJobVacancyToPlaceJobVacancyFormValues(
    vacancy: EditableVacancy,
    companyAddress?: Address,
): PlaceJobVacancyFormValues {
    const address = transformAddressToOldAddress(vacancy.address) || companyAddress;

    return {
        applyAnonymous: vacancy.isAnonymous,
        title: vacancy.title,
        address,
        expirationDate: vacancy.expirationDate,
        publicationDate: vacancy.publicationDate,
        educationLevelIds: vacancy.educationLevels,
        escoOccupationIds: vacancy.escoOccupations,
        fieldsOfStudyIds: vacancy.fieldsOfStudy,
        employmentHourRange: vacancy.employmentHourRange,
        minMonthSalary: vacancy.minCompensation,
        maxMonthSalary: vacancy.maxCompensation,
        minHourSalary: vacancy.grossHourlyWageInCents.min
            ? vacancy.grossHourlyWageInCents.min / 100
            : '',
        maxHourSalary: vacancy.grossHourlyWageInCents.max
            ? vacancy.grossHourlyWageInCents.max / 100
            : '',
        description: vacancy.description,
        jobTypeId: vacancy.vacancyType,
        workRemotePreferenceId: vacancy.workRemotePreference,
        skillIds: vacancy.skills,
        escoSkillIds: vacancy.escoSkills,
        competencyIds: vacancy.competencies,
        sectorId: vacancy.sector,
    };
}

export function transformInternshipVacancyToPlaceInternshipVacancyFormValues(
    vacancy: EditableVacancy,
    companyAddress?: Address,
): PlaceInternshipVacancyFormValues {
    const address = transformAddressToOldAddress(vacancy.address) || companyAddress;

    const educationLevelId = vacancy.educationLevels.length > 0
        ? vacancy.educationLevels[0]
        : '';

    return {
        applyAnonymous: vacancy.isAnonymous,
        title: vacancy.title,
        address: address ? transformAddressToAddressFormValues(address) : null,
        expirationDate: vacancy.expirationDate,
        publicationDate: vacancy.publicationDate,
        educationLevelId,
        fieldsOfStudyIds: vacancy.fieldsOfStudy,
        description: vacancy.description,
        skillIds: vacancy.skills,
        skillsToLearnIds: vacancy.skillsToLearn,
        minCompensation: vacancy.minCompensation,
        maxCompensation: vacancy.maxCompensation,
        competencyIds: vacancy.competencies,
        competenciesToLearnIds: vacancy.competenciesToLearn,
        gradeIds: vacancy.grades,
        internshipTypeId: vacancy.vacancyType,
        periodFrom: vacancy.periodFrom,
        periodTill: vacancy.periodTill,
        workRemotePreferenceId: vacancy.workRemotePreference,
        sectorId: vacancy.sector,
    };
}
