import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './ChatEventListSkeletons.scss';

interface ChatEventListSkeletonsProps {
    amount?: number;
    className?: string;
}

const ChatEventListSkeletons: FC<ChatEventListSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <div className={`chat-event-list-skeletons ${className}`}>
            <ul className="chat-event-list-skeletons__list">
                {skeletons.map(skeleton => (
                    <li key={skeleton} className="chat-event-list-skeletons__item">
                        <Skeleton className="chat-event-list-skeletons__avatar" />

                        <div className="chat-event-list-skeletons__chat-balloon-wrapper">
                            <Skeleton className="chat-event-list-skeletons__chat-balloon" />
                            <Skeleton className="chat-event-list-skeletons__date" />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChatEventListSkeletons;
