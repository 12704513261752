import { fulltimeThreshold } from '../constants';
import { MinMaxValue, SalaryTimeframe } from '../types';
import { convertNumberToCurrency } from './number';
import { trans } from './trans';

export const sumEmploymentHourRange = (employmentHourRange: MinMaxValue): string => {
    const { min, max } = employmentHourRange;

    if (max === 0) return '';

    if (min === max) {
        return min >= fulltimeThreshold
            ? trans('vacancies.properties.employmentHourRange.specific.fulltime', { hours: max })
            : trans('vacancies.properties.employmentHourRange.specific.parttime', { hours: max });
    }

    return min >= fulltimeThreshold
        ? trans('vacancies.properties.employmentHourRange.fulltime', { ...employmentHourRange })
        : trans('vacancies.properties.employmentHourRange.parttime', { ...employmentHourRange });
};

export const sumCompensation = (timeframe: SalaryTimeframe, compensation: MinMaxValue): string => {
    const { min, max } = compensation;

    if (max === 0) return '';

    if (timeframe === SalaryTimeframe.hour) {
        const hourlyCompensation = {
            min: convertNumberToCurrency(min / 100),
            max: convertNumberToCurrency(max / 100),
        };

        return min === max
            ? trans('vacancies.properties.compensation.specific.hour', { compensation: hourlyCompensation.max })
            : trans('vacancies.properties.compensation.hour', hourlyCompensation);
    }

    return min === max
        ? trans('vacancies.properties.compensation.specific.month', { compensation: convertNumberToCurrency(max) })
        : trans('vacancies.properties.compensation.month', {
            min: convertNumberToCurrency(min),
            max: convertNumberToCurrency(max),
        });
};

export const sumGrades = (grades: string[]): string => {
    const summedGrades = grades.map((grade, index) => {
        if (index !== grades.length - 1) {
            return grade.split(' ')[0];
        }

        return grade;
    });

    return summedGrades.join(', ');
};

export const sumFieldsOfStudy = (
    isSbbUser?: boolean,
    fieldsOfStudy: string[] = [],
    approvedFieldsOfStudy: string[] = [],
    userFieldsOfStudy: string[] = [],
): string => {
    const allFieldsOfStudy = isSbbUser
        ? fieldsOfStudy
        : [...approvedFieldsOfStudy, ...fieldsOfStudy];

    if (allFieldsOfStudy.length < 2) {
        return allFieldsOfStudy[0];
    }

    const matchingUserFieldsOfStudy = fieldsOfStudy.filter(fieldOfStudy => userFieldsOfStudy.includes(fieldOfStudy));
    const existsInApprovedFieldsOfStudy = approvedFieldsOfStudy.some(approvedFieldOfStudy => userFieldsOfStudy.includes(approvedFieldOfStudy));

    if (matchingUserFieldsOfStudy.length > 0 && !existsInApprovedFieldsOfStudy) {
        return `${userFieldsOfStudy[0]}, +${allFieldsOfStudy.length - 1}`;
    }

    return isSbbUser
        ? `${fieldsOfStudy[0]}, +${allFieldsOfStudy.length - 1}`
        : `${allFieldsOfStudy[0]}, +${allFieldsOfStudy.length - 1}`;
};
