import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useScrollYPosition } from 'react-use-scroll-position';

import { MatchingAnimation } from '../../components';
import { isNotSSR } from '../../helpers/environment';
import { trans } from '../../helpers/trans';
import VacancySearchForm, { VacancySearchFormValues } from '../@forms/VacancySearchForm/VacancySearchForm';

import './HomeHero.scss';

interface HomeHeroProps {
    whatOptions: string[];
    whereOptions: string[];
    onSearch: (values: VacancySearchFormValues) => void;
    className?: string;
}

const HomeHero: FC<HomeHeroProps> = ({
    whatOptions,
    whereOptions,
    onSearch,
    className = '',
}): ReactElement => {
    const scrollY = useScrollYPosition();
    const userPrefersReducedMotion = isNotSSR
        ? window.matchMedia('(prefers-reduced-motion: reduce)')?.matches
        : false;

    const animationRef = useRef<HTMLDivElement>(null);
    const [scrolledPastAnimation, setScrolledPastAnimation] = useState(false);
    const [person, setPerson] = useState<string>('');

    const peopleImages = ['construction-worker', 'diversity-candidate', 'doctor', 'vr'];

    useEffect((): void => {
        if (scrollY && animationRef.current) {
            setScrolledPastAnimation(scrollY > animationRef.current.offsetHeight + animationRef.current.offsetTop);
        }
    }, [scrollY, animationRef]);

    useEffect((): void => {
        const today = new Date();
        const year = today.getFullYear();
        const now = today.getTime();

        const [sinterklaasStart, sinterklaasEnd] = [
            new Date(year, 11, 4),
            new Date(year, 11, 6),
        ].map(date => date.getTime());

        if (now > sinterklaasStart && now < sinterklaasEnd) {
            setPerson('sinterklaas');
            return;
        }

        const [christmasStart, christmasEnd] = [
            new Date(year, 11, 24),
            new Date(year, 11, 27),
        ].map(date => date.getTime());

        if (now > christmasStart && now < christmasEnd) {
            setPerson('christmas');
            return;
        }

        const randomIndex = Math.floor(Math.random() * peopleImages.length);

        setPerson(peopleImages[randomIndex]);
    }, []);

    return (
        <div className={`home-hero ${className}`}>
            <header className="home-hero__header">
                <h1 className="home-hero__title">
                    {trans('home.hero.title.start')}

                    <span className="home-hero__title-underline">
                        {trans('home.hero.title.center')}
                    </span>

                    {trans('home.hero.title.end')}
                </h1>

                <div className="home-hero__impression">
                    <div className="home-hero__image-wrapper">
                        {person && (
                            <picture className="home-hero__picture">
                                <source srcSet={`/images/people/${person}.webp`} type="image/webp" />
                                <img
                                    src={`/images/people/${person}.png`}
                                    alt={trans('home.hero.imageAlt')}
                                    className="home-hero__image"
                                />
                            </picture>
                        )}
                    </div>

                    <div className="home-hero__circle" />
                </div>
            </header>

            <VacancySearchForm
                showVacancyType
                whatOptions={whatOptions}
                whereOptions={whereOptions}
                onSubmit={onSearch}
                className="home-hero__search-form"
            />

            <div ref={animationRef} className="home-hero__animation-container">
                <MatchingAnimation
                    animationIsDisabled={userPrefersReducedMotion}
                    animationIsPaused={scrolledPastAnimation}
                />
            </div>
        </div>
    );
};

export default HomeHero;
