import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Skeleton } from '../../../../components';
import { LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { NewestVacancy } from '../../../../models/NewestVacancy';
import { RoutePaths } from '../../../../routes';
import { VacancyOverview } from '../../..';

import './RecentVacanciesList.scss';

interface RecentVacancyRoleData {
    title: string;
    buttonLabel: string;
    route: string;
    vacancies: NewestVacancy[];
}

interface RecentVacanciesListProps {
    isLoading?: boolean;
    internshipVacancies?: NewestVacancy[];
    jobVacancies?: NewestVacancy[];
    totalVacancies: number;
    error: string;
    className?: string;
}

const RecentVacanciesList: FC<RecentVacanciesListProps> = ({
    isLoading = false,
    jobVacancies = [],
    internshipVacancies = [],
    totalVacancies,
    error,
    className = '',
}): ReactElement => {
    const isMilestone = totalVacancies > 0 && totalVacancies % 1000 === 0;

    const roleData: RecentVacancyRoleData[] = [
        {
            title: 'jobs',
            buttonLabel: 'allJobs',
            route: RoutePaths.jobs(),
            vacancies: jobVacancies,
        },
        {
            title: 'internships',
            buttonLabel: 'allInternships',
            route: RoutePaths.internships(),
            vacancies: internshipVacancies,
        },
    ];

    const titleClassNames = classNames('recent-vacancies-list__title', {
        'recent-vacancies-list__title--is-milestone': isMilestone,
    });

    return (
        <div className={`recent-vacancies-list ${className}`}>
            {isLoading && (
                <Skeleton className="recent-vacancies-list__title-skeleton" />
            )}

            {!isLoading && (
                <h1 className={titleClassNames}>
                    <span>{trans('home.recentVacancies.public.title.start')}</span>

                    <span className="recent-vacancies-list__title-amount">
                        {totalVacancies}
                    </span>

                    <span>{trans('home.recentVacancies.public.title.end')}</span>
                </h1>
            )}

            {roleData.map(role => (
                <div key={role.title} className="recent-vacancies-list__list-wrapper">
                    <div className="recent-vacancies-list__controls">
                        <h2 className="recent-vacancies-list__role-title">
                            {trans(`home.recentVacancies.collections.${role.title}`)}
                        </h2>

                        <LinkIconButton
                            to={role.route}
                            icon="chevron-right"
                            iconPos="right"
                            text={trans(`home.recentVacancies.button.${role.buttonLabel}`)}
                            className="recent-vacancies-list__role-button"
                        />
                    </div>

                    <VacancyOverview
                        isGrid
                        isLoading={isLoading}
                        skeletonAmount={4}
                        vacancies={role.vacancies}
                        error={error}
                        className="recent-vacancies-list__vacancies"
                    />
                </div>
            ))}
        </div>
    );
};

export default RecentVacanciesList;
