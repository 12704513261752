import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Icon from '../../../../styledComponents/components/Icon';
import { Heading } from '../../../../styledComponents/components/Typography';
import {
    Arrow,
    Body,
    Header,
} from './style';

class AccordionSection extends Component {
    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const {
            onClick,
            props: { isOpen, label },
        } = this;

        return (
            <>
                <Header onClick={onClick} role="main">
                    <Heading as="h3" font="body" size="rg" mb="none" mr="md" color="body">{label}</Heading>
                    <Arrow isOpen={isOpen}>
                        <Icon kind="forward" width={20} height={20} />
                    </Arrow>
                </Header>
                <Body isOpen={isOpen}>
                    {this.props.children}
                </Body>
            </>
        );
    }
}

AccordionSection.propTypes = {
    children: PropTypes.instanceOf(Object),
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

AccordionSection.defaultProps = {
    children: null,
};

export default AccordionSection;
