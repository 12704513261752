import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { VacancyType } from '../../../_old/app_talentz/models/VacancyType';
import { trans } from '../../../helpers/trans';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import { ApplicationAppointment, ApplicationAppointmentFormData } from '../../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../../models/ApplicationInvites';
import { Application } from '../../../models/Applications';
import { Candidate } from '../../../models/Candidate';
import { NewestVacancy } from '../../../models/NewestVacancy';
import {
    ApplicationCompanyInformation,
    ApplicationDeleteCard,
    ApplicationMotivationCard,
    ApplicationStatusCard,
    VacancyApplyAnonymousCard,
    VacancyDescriptionCard,
    VacancyPropertiesCard,
    VacancyToHaveCard,
    VacancyToLearnCard,
} from '../..';
import { ApplicationBreadcrumbs } from './subcomponents';

import './ApplicationDetail.scss';

interface ApplicationDetailProps {
    isLoading: boolean;
    isLoadingStatus: boolean;
    isCandidate: boolean;
    isSbbUser: boolean;
    candidate?: Candidate;
    application?: Application;
    applicationInvite?: ApplicationInvite;
    vacancy?: NewestVacancy;
    onDeleteApplication: (applicationUuid: string) => void;
    onAddApplicationInvite: (applicationUuid: string, formData: ApplicationAppointmentFormData) => void;
    onAcceptApplicationInvite: (appointment: ApplicationAppointment, inviteUuid: string) => void;
    onDeclineApplicationInvite: (applicationInvite: ApplicationInvite) => void;
    className?: string;
}

const ApplicationDetail: FC<ApplicationDetailProps> = ({
    isLoading,
    isLoadingStatus,
    isCandidate,
    isSbbUser,
    candidate,
    application,
    applicationInvite,
    vacancy,
    onDeleteApplication,
    onAddApplicationInvite,
    onAcceptApplicationInvite,
    onDeclineApplicationInvite,
    className = '',
}): ReactElement => {
    const { isTabletPortrait, isMobile } = useDeviceWidth();
    const isSmallScreen = isTabletPortrait || isMobile;

    const metaTitle = vacancy
        ? trans('containers.applicationDetail.meta.vacancyTitle', { vacancyTitle: vacancy?.title })
        : trans('containers.applicationDetail.meta.title');

    const isAnonymousVacancy = vacancy?.isAnonymous;
    const isInternshipVacancy = vacancy?.type === VacancyType.internship;

    return (
        <div className={`application-detail ${className}`}>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={trans('containers.applicationDetail.meta.description')} />
            </Helmet>

            <ApplicationBreadcrumbs
                isLoading={isLoading}
                application={application}
            />

            <div className="application-detail__wrapper">
                <main className="application-detail__main">
                    <ApplicationMotivationCard
                        isLoading={isLoading}
                        application={application}
                        className="application-detail__card"
                    />

                    {!isLoading && isSmallScreen && (
                        <ApplicationStatusCard
                            isLoading={isLoadingStatus}
                            isCandidate={isCandidate}
                            application={application}
                            applicationInvite={applicationInvite}
                            onAddApplicationInvite={onAddApplicationInvite}
                            onAcceptApplicationInvite={onAcceptApplicationInvite}
                            onDeclineApplicationInvite={onDeclineApplicationInvite}
                            className="application-detail__card"
                        />
                    )}

                    <VacancyDescriptionCard
                        isApplication
                        isLoading={isLoading}
                        vacancy={vacancy}
                        className="application-detail__card"
                    />

                    <VacancyToHaveCard
                        isLoading={isLoading}
                        candidate={candidate}
                        competencies={vacancy?.competencies}
                        skills={vacancy?.skills}
                        className="application-detail__card"
                    />

                    {isInternshipVacancy && (
                        <VacancyToLearnCard
                            isLoading={isLoading}
                            candidate={candidate}
                            competencies={vacancy?.competenciesToLearn}
                            skills={vacancy?.skillsToLearn}
                            className="application-detail__card"
                        />
                    )}
                </main>

                <aside className="application-detail__sidebar">
                    {!isLoading && !isSmallScreen && (
                        <ApplicationStatusCard
                            isLoading={isLoadingStatus}
                            isCandidate={isCandidate}
                            application={application}
                            applicationInvite={applicationInvite}
                            onAddApplicationInvite={onAddApplicationInvite}
                            onAcceptApplicationInvite={onAcceptApplicationInvite}
                            onDeclineApplicationInvite={onDeclineApplicationInvite}
                            className="application-detail__card"
                        />
                    )}

                    <ApplicationCompanyInformation
                        isLoading={isLoading}
                        application={application}
                        vacancy={vacancy}
                        className="application-detail__card"
                    />

                    {isAnonymousVacancy && (
                        <VacancyApplyAnonymousCard
                            vacancy={vacancy}
                            className="application-detail__card"
                        />
                    )}

                    <VacancyPropertiesCard
                        isLoading={isLoading}
                        isSbbUser={isSbbUser}
                        properties={vacancy?.properties}
                        className="application-detail__card"
                    />

                    <ApplicationDeleteCard
                        isLoading={isLoading}
                        application={application}
                        onDeleteClick={onDeleteApplication}
                        className="application-detail__card"
                    />
                </aside>
            </div>
        </div>
    );
};

export default ApplicationDetail;
