import React, { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { MessageOverview } from '../../containers';
import { Chat } from '../../models/Chat';
import { ChatReportFormData, UndoChatReportFormData } from '../../models/ChatReport';
import { transformCompanyInfoToPersonInfo } from '../../models/info/PersonInfo';
import { RoleType } from '../../models/User';
import { WebSocketEvent } from '../../models/WebSocket';
import { RoutePaths } from '../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import {
    addChatMessage,
    broadcastChatMessage,
    clearChatMessages,
    fetchChatMessages,
} from '../../store/chatMessages/chatMessagesActions';
import {
    clearChatReports,
    fetchChatReports,
    reportChat,
    undoChatReport,
} from '../../store/chatReports/chatReportsActions';
import { setActiveChat } from '../../store/chats/chats';
import {
    clearChats,
    createChat,
    fetchChats,
    setChatLatestMessage,
    setChatReadStatus,
    setChatReportedStatus,
} from '../../store/chats/chatsActions';

interface ConnectedMessageOverviewProps {
    chatUuid?: string;
    className?: string;
}

const ConnectedMessageOverview: FC<ConnectedMessageOverviewProps> = ({
    chatUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const person = useLegacySelector(state => state.userPerson.data);
    const company = useLegacySelector(state => state.userCompany.data);
    const role = useLegacySelector(state => state.legacyUser.role);

    const isCandidate = role === RoleType.student || role === RoleType.jobSeeker;
    const userUuid = isCandidate
        ? person?.uuid || ''
        : company?.uuid || '';

    const chatsIsLoading = useTypedSelector(state => state.chatsReducer.isLoading);
    const chatConnections = useTypedSelector(state => state.chatsReducer.chatConnections);
    const chats = useTypedSelector(state => state.chatsReducer.chats);
    const activeChat = useTypedSelector(state => state.chatsReducer.activeChat);

    const messagesIsLoading = useTypedSelector(state => state.chatMessagesReducer.isLoading);
    const isReconnecting = useTypedSelector(state => state.chatMessagesReducer.isReconnecting);
    const chatMessages = useTypedSelector(state => state.chatMessagesReducer.messages);

    const chatReportIsLoading = useTypedSelector(state => state.chatReportsReducer.isLoading);
    const chatReports = useTypedSelector(state => state.chatReportsReducer.chatReports);

    useEffect((): () => void => {
        dispatch(fetchChats(isCandidate));
        dispatch(fetchChatReports());

        return (): void => {
            dispatch(clearChats());
            dispatch(clearChatMessages());
            dispatch(clearChatReports());
        };
    }, []);

    useEffect((): void => {
        if (activeChat) {
            dispatch(fetchChatMessages(activeChat.uuid));
        }
    }, [activeChat]);

    useEffect((): void => {
        if (!chatUuid) return;

        const selectedChat = isCandidate
            ? chats.find(chat => chat.company.uuid === chatUuid)
            : chats.find(chat => chat.candidate.uuid === chatUuid);

        if (selectedChat) {
            dispatch(setActiveChat(selectedChat));
        } else {
            dispatch(createChat(isCandidate, chatUuid));
        }
    }, [chats.length, chatUuid]);

    useEffect((): () => void => {
        const handleChatEvent = (messageEvent: MessageEvent): void => {
            if (!activeChat) return;

            const { candidate, company } = activeChat;

            const messageData = JSON.parse(messageEvent.data);

            const isActiveChat = messageData.chatUuid === activeChat.uuid;
            const isReceiver = messageData.authorUuid !== userUuid;

            if (messageData.type === WebSocketEvent.message && messageData.message) {
                dispatch(setChatLatestMessage(messageData.chatUuid, messageData.message));

                if (isActiveChat && isReceiver) {
                    const author = isCandidate
                        ? transformCompanyInfoToPersonInfo(company)
                        : candidate;

                    dispatch(addChatMessage({
                        uuid: uuidv4(),
                        author,
                        text: messageData.message,
                        createdAt: new Date(),
                    }));
                }
            }

            if (messageData.type === WebSocketEvent.chatReport) {
                dispatch(setChatReportedStatus(messageData.chatUuid, messageData.isReported));
            }
        };

        chatConnections.forEach(chatConnection => {
            if (chatConnection.socket) {
                chatConnection.socket.addEventListener('message', handleChatEvent);
            }
        });

        return (): void => {
            chatConnections.forEach(chatConnection => {
                if (chatConnection.socket) {
                    chatConnection.socket.removeEventListener('message', handleChatEvent);
                }
            });
        };
    }, [isCandidate, activeChat, chatConnections]);

    const handleSelectChat = (chat: Chat): void => {
        const chatPath = isCandidate
            ? RoutePaths.candidateOverviewMessages(chat.company.uuid)
            : RoutePaths.companyOverviewMessages(chat.candidate.uuid);

        navigate(chatPath, { replace: true });

        dispatch(setActiveChat(chat));
        dispatch(setChatReadStatus(chat.uuid));
    };

    const handleSendMessage = (messageText: string): void => {
        const activeChatConnection = chatConnections.find(chat => chat.uuid === activeChat?.uuid);

        if (activeChatConnection && activeChat) {
            const { candidate, company } = activeChat;

            dispatch(broadcastChatMessage(activeChatConnection, {
                uuid: uuidv4(),
                author: isCandidate
                    ? candidate
                    : transformCompanyInfoToPersonInfo(company),
                text: messageText,
                createdAt: new Date(),
            }));
        }
    };

    const handleReportChat = (chatUuid: string, chatReportFormData: ChatReportFormData): void => {
        dispatch(reportChat(chatUuid, chatReportFormData));
    };

    const handleUndoChatReport = (undoChatReportFormData: UndoChatReportFormData): void => {
        dispatch(undoChatReport(undoChatReportFormData));
    };

    return (
        <MessageOverview
            isLoading={chatsIsLoading}
            messagesIsLoading={messagesIsLoading}
            chatReportsIsLoading={chatReportIsLoading}
            isReconnecting={isReconnecting}
            isCandidate={isCandidate}
            userUuid={userUuid}
            chats={chats}
            activeChat={activeChat}
            chatMessages={chatMessages}
            chatReports={chatReports}
            onSelectChat={handleSelectChat}
            onSendMessage={handleSendMessage}
            onReportChat={handleReportChat}
            onUndoChatReport={handleUndoChatReport}
            className={className}
        />
    );
};

export default ConnectedMessageOverview;
