/* eslint-disable camelcase */
import { sanitizeHtml } from '../../../helpers/sanitizeHtml';
import { Address, AddressResponseApiParams, transformToAddress } from './Address';

interface CompanyApiParams {
    uuid: string;
    address: AddressResponseApiParams;
    amount_of_employees: AmountOfEmployeesApiResponse[];
    contact_person: {
        email: string;
        full_name: string;
        phone_number: string;
        position: string;
    };
    core_values: {
        uuid: string;
        name: string;
        slug: string;
        usage: number;
    }[];
    cover_image: string;
    cover_video: string;
    city: string;
    description: string;
    employee_profiles: {
        uuid: string;
        position: string;
        company_id: string;
    }[];
    fun_facts: string;
    is_hidden: boolean;
    is_recommended: boolean;
    kvk_number: string | null;
    kvk_data: any;
    sbb_crebo_numbers: {
        crebo_number: number;
        field_of_study: {
            manual: boolean;
            name: string;
            slug: string;
            uuid: string;
        };
    }[];
    latitude: string;
    logo: string;
    longitude: string;
    name: string;
    position: string;
    province: string;
    share_image: string;
    slug: string;
    street_number: string;
    street_number_extension: string;
    unread_applications_count: number;
    website: string;
    zipcode: string;
}

interface ContactPerson {
    email: string;
    full_name: string;
    phone_number: string;
    position: string;
}

interface CoreValue {
    name: string;
    slug: string;
    usage: number;
    uuid: string;
}

interface EmployeeProfile {
    company_id: string;
    position: string;
    uuid: string;
}

export interface CompanySettings {
    newsletter: boolean;
    notifications: boolean;
    show_internship_matches: boolean;
    show_job_matches: boolean;
    toc: string;
    uuid: string;
}

export interface AmountOfEmployees {
    uuid: string;
    name: string;
    value?: string;
}

export interface AmountOfEmployeesApiResponse {
    uuid: string;
    name: string;
    value?: string;
}

export interface CompanyCreboNumber {
    crebo_number: number;
    field_of_study: {
        manual: boolean;
        name: string;
        slug: string;
        uuid: string;
    };
}

export interface Company {
    address: Address;
    amount_of_employees: AmountOfEmployees[];
    contact_person: ContactPerson;
    core_values: CoreValue[];
    cover_image: string;
    cover_video: string;
    description: string;
    employee_profiles: EmployeeProfile[];
    fun_facts: string;
    is_hidden: boolean;
    is_recommended: boolean;
    kvk_number: string | null;
    kvk_data: any;
    sbb_crebo_numbers: CompanyCreboNumber[];
    logo: string;
    name: string;
    share_image: string;
    slug: string;
    unread_applications_count: number;
    uuid: string;
    website: string;
}

export function transformCompany(company: CompanyApiParams): Company {
    return {
        address: transformToAddress(company.address),
        amount_of_employees: company.amount_of_employees,
        contact_person: company.contact_person,
        core_values: company.core_values,
        cover_image: company.cover_image,
        cover_video: company.cover_video,
        description: sanitizeHtml(company.description || ''),
        employee_profiles: company.employee_profiles,
        fun_facts: sanitizeHtml(company.fun_facts || ''),
        is_hidden: company.is_hidden,
        is_recommended: company.is_recommended,
        kvk_number: company.kvk_number,
        kvk_data: company.kvk_data,
        sbb_crebo_numbers: company.sbb_crebo_numbers,
        logo: company.logo,
        name: company.name,
        share_image: company.share_image,
        slug: company.slug,
        unread_applications_count: company.unread_applications_count,
        uuid: company.uuid,
        website: company.website,
    };
}
/* eslint-enable camelcase */
