import React, { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './ApplicationOverviewSkeletons.scss';

interface ApplicationOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const ApplicationOverviewSkeletons: FC<ApplicationOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`application-overview-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className="application-overview-skeletons__item">
                    <Card className="application-overview-skeletons__card">
                        <Skeleton className="application-overview-skeletons__logo" />

                        <div className="application-overview-skeletons__text-wrapper">
                            <Skeleton className="application-overview-skeletons__title" />
                            <Skeleton className="application-overview-skeletons__at-company" />

                            <div className="application-overview-skeletons__motivation">
                                <Skeleton className="application-overview-skeletons__motivation-line" />
                                <Skeleton className="application-overview-skeletons__motivation-line" />
                                <Skeleton className="application-overview-skeletons__motivation-line" />
                            </div>
                        </div>

                        <div className="application-overview-skeletons__status-wrapper">
                            <Skeleton className="application-overview-skeletons__status-title" />
                        </div>
                    </Card>
                </li>
            ))}
        </ul>
    );
};

export default ApplicationOverviewSkeletons;
