import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Address } from '../../models/Address';

export interface UserAddressState {
    loading: boolean;
    data?: Address;
    errors: Record<string, unknown>;
}

const initialState = {
    loading: false,
    errors: {},
};

const userAddressSlice = createSlice({
    name: 'userAddress',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): UserAddressState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setData(state, action: PayloadAction<Address | undefined>): UserAddressState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): UserAddressState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setData,
    setErrors,
} = userAddressSlice.actions;

export default userAddressSlice;
