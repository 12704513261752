import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import './FooterLinkList.scss';

export interface FooterLinkItem {
    label: string;
    path?: string;
    externalUrl?: string;
}

interface FooterLinkListProps {
    links: FooterLinkItem[];
    className?: string;
}

const FooterLinkList: FC<FooterLinkListProps> = ({
    links,
    className = '',
}): ReactElement => (
    <ul className={`footer-link-list ${className}`}>
        {links.map(link => (
            <li key={link.label} className="footer-link-list__item">
                {link.path && (
                    <Link to={link.path} className="footer-link-list__link">
                        {link.label}
                    </Link>
                )}

                {link.externalUrl && (
                    <a
                        href={link.externalUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link-list__link"
                    >
                        {link.label}
                    </a>
                )}
            </li>
        ))}
    </ul>
);

export default FooterLinkList;
