import React, { FC, ReactElement, useEffect } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import { ApplicationDetail, ApplicationDetailArchived } from '../../../containers';
import { ApplicationAppointment, ApplicationAppointmentFormData } from '../../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../../models/ApplicationInvites';
import { RoleType } from '../../../models/User';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearApplication, deleteApplication, fetchApplicationWithVacancy } from '../../../store/application/applicationActions';
import { acceptApplicationInvite, createApplicationInvite, declineApplicationInvite } from '../../../store/applicationInvite/applicationInviteActions';
import { StatusCode } from '../../../types';
import { CandidateOverviewTab } from '../../../types/pageTabs';

interface ConnectedApplicationDetailProps {
    applicationUuid?: string;
    className?: string;
}

const ConnectedApplicationDetail: FC<ConnectedApplicationDetailProps> = ({
    applicationUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const person = useLegacySelector(state => state.userPerson.data);
    const role = useLegacySelector(state => state.legacyUser.role);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const applicationIsLoading = useTypedSelector(state => state.applicationReducer.isLoading);
    const applicationStatusCode = useTypedSelector(state => state.applicationReducer.statusCode);
    const application = useTypedSelector(state => state.applicationReducer.application);
    const vacancy = useTypedSelector(state => state.applicationReducer.vacancy);

    const applicationInviteIsLoading = useTypedSelector(state => state.applicationInviteReducer.isLoading);
    const applicationInvite = useTypedSelector(state => state.applicationInviteReducer.applicationInvite);

    useEffect((): () => void => {
        if (applicationUuid) {
            dispatch(fetchApplicationWithVacancy(applicationUuid));
        }

        // Clear application data from store when component unmounts
        return (): void => {
            dispatch(clearApplication());
        };
    }, [dispatch, applicationUuid]);

    const handleDeleteApplication = (uuid: string): void => {
        dispatch(deleteApplication(uuid, (): void => {
            navigate(RoutePaths.candidateOverviewTab(CandidateOverviewTab.applications));
        }));
    };

    const handleAddApplicationInvite = (applicationUuid: string, formData: ApplicationAppointmentFormData): void => {
        dispatch(createApplicationInvite(applicationUuid, formData));
    };

    const handleAcceptApplicationInvite = (appointment: ApplicationAppointment, inviteUuid: string): void => {
        dispatch(acceptApplicationInvite(appointment, inviteUuid));
    };

    const handleDeclineApplicationInvite = (applicationInvite: ApplicationInvite): void => {
        dispatch(declineApplicationInvite(applicationInvite));
    };

    if (applicationStatusCode === StatusCode.notFound) {
        return <Navigate to={RoutePaths.error404()} />;
    }

    if (applicationStatusCode === StatusCode.server) {
        return <Navigate to={RoutePaths.error500()} />;
    }

    if (application?.vacancy.isArchived) {
        return (
            <ApplicationDetailArchived
                isLoading={applicationIsLoading}
                application={application}
                className={className}
            />
        );
    }

    return (
        <ApplicationDetail
            isLoading={candidateIsLoading || applicationIsLoading}
            isLoadingStatus={applicationInviteIsLoading}
            isCandidate={role === RoleType.student || role === RoleType.jobSeeker}
            isSbbUser={!!person?.is_sbb_user}
            candidate={candidate}
            application={application}
            applicationInvite={applicationInvite}
            vacancy={vacancy}
            onDeleteApplication={handleDeleteApplication}
            onAddApplicationInvite={handleAddApplicationInvite}
            onAcceptApplicationInvite={handleAcceptApplicationInvite}
            onDeclineApplicationInvite={handleDeclineApplicationInvite}
            className={className}
        />
    );
};

export default ConnectedApplicationDetail;
