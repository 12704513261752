import React, { FC, ReactElement } from 'react';

import './TableHead.scss';

type HTMLTableHeadProps = JSX.IntrinsicElements['thead'];

interface TableHeadProps extends HTMLTableHeadProps {
    className?: string;
}

const TableHead: FC<TableHeadProps> = ({
    className = '',
    children,
    ...tableHeadProps
}): ReactElement => (
    <thead {...tableHeadProps} className={`table-head ${className}`}>
        {children}
    </thead>
);

export default TableHead;
