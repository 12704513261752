import React, { FC, ReactElement } from 'react';

import { BlogArticle } from '../../models/Blog';
import { BlogListSkeletons } from './skeletons';
import { BlogListItem } from './subcomponents';

import './BlogList.scss';

interface BlogListProps {
    isLoading?: boolean;
    isFeatured?: boolean;
    blogArticles: BlogArticle[];
    skeletonAmount?: number;
    className?: string;
}

const BlogList: FC<BlogListProps> = ({
    isLoading,
    isFeatured,
    blogArticles,
    skeletonAmount,
    className = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <BlogListSkeletons
                amount={skeletonAmount}
                className={`blog-list ${className}`}
            />
        );
    }

    return (
        <ul className={`blog-list ${className}`}>
            {blogArticles.map(blog => (
                <BlogListItem
                    {...blog}
                    key={blog.id}
                    isFeatured={isFeatured}
                />
            ))}
        </ul>
    );
};

export default BlogList;
