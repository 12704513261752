import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../components';
import { DetailCard, IconButton, ShareList } from '../../../compositions';
import { isNotSSR } from '../../../helpers/environment';
import { trans } from '../../../helpers/trans';
import { transformVacancyToVacanciesInfo, VacanciesInfo } from '../../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoleType } from '../../../models/User';

import './VacancyShareCard.scss';

interface VacancyShareCardProps {
    userRole: RoleType;
    vacancy?: NewestVacancy;
    isFavourite?: boolean;
    favouriteIsLoading?: boolean;
    onFavouriteVacancy: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    className?: string;
}

const VacancyShareCard: FC<VacancyShareCardProps> = ({
    userRole,
    vacancy,
    isFavourite,
    favouriteIsLoading,
    onFavouriteVacancy,
    className = '',
}): ReactElement => {
    const shareTitle = trans('share.vacancy.title');
    const shareBody = trans('share.vacancy.body', { title: vacancy?.title });
    const shareUrl = isNotSSR ? window.location.href : '';

    const emailBody = encodeURIComponent(`${shareBody}\n\n${shareUrl}`);

    const mayHaveFavourites = userRole === RoleType.student || userRole === RoleType.jobSeeker;

    const handleFavouriteClick = (): void => {
        if (vacancy) {
            const favouriteVacancy = transformVacancyToVacanciesInfo(vacancy);
            onFavouriteVacancy(favouriteVacancy, !isFavourite);
        }
    };

    return (
        <DetailCard
            title={trans(`vacancyDetail.share.${mayHaveFavourites ? 'authenticatedTitle' : 'title'}`)}
            className={`vacancy-share-card ${className}`}
        >
            <div className="vacancy-share-card__button-wrapper">
                {mayHaveFavourites && (
                    <IconButton
                        icon={isFavourite ? 'heart-solid' : 'heart'}
                        text={trans(`vacancyDetail.share.button.${isFavourite ? 'favourite' : 'noFavourite'}`)}
                        disabled={favouriteIsLoading}
                        onClick={handleFavouriteClick}
                        className="vacancy-share-card__button"
                        iconClassName="vacancy-share-card__button-icon"
                    />
                )}

                <a
                    href={`mailto:?subject=${shareTitle}&body=${emailBody}`}
                    className="vacancy-share-card__link-button vacancy-share-card__button"
                >
                    <Icon name="mail" className="vacancy-share-card__button-icon" />
                    {trans('vacancyDetail.share.button.email')}
                </a>
            </div>

            <div className="vacancy-share-card__share-wrapper">
                <ShareList
                    userRole={userRole}
                    titleText={shareTitle}
                    bodyText={shareBody}
                    url={shareUrl}
                    className="vacancy-share-card__share-list"
                    itemClassName="vacancy-share-card__share-item"
                />
            </div>
        </DetailCard>
    );
};

export default VacancyShareCard;
