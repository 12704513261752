// @ts-ignore
import styled, { css } from 'styled-components';

export const Styles = styled('div')(() => css`
    .sliding-small-cards {
        &__slider {
            .slick-slide {
                margin-right: 1.428rem;
            }
        }

        &__slider-inner {
            width: 100%!important;
        }

        &__card {
            margin-bottom: 1.428rem;
        }
    }
`);
