import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { AmountOfEmployeesOption, AmountOfEmployeesResource, transformToAmountOfEmployeesOption } from '../models/AmountOfEmployees';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getAmountOfEmployeesOptionsApiCall = async (): Promise<FetchResult<AmountOfEmployeesOption[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/amounts-of-employees`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<AmountOfEmployeesResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const amountOfEmployeesOptions = doc.data.map(transformToAmountOfEmployeesOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: amountOfEmployeesOptions,
        };
    } catch (error) {
        console.error('[getAmountOfEmployeesOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
