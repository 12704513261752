import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';

import './VideoAppointmentSkeletons.scss';

interface VideoAppointmentSkeletonsProps {
    isGrid?: boolean;
    amount?: number;
    className?: string;
}

const VideoAppointmentSkeletons: FC<VideoAppointmentSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`video-appointment-skeletons ${className}`}>
        <Skeleton className="video-appointment-skeletons__title" />
        <Skeleton className="video-appointment-skeletons__appointment-time" />

        <div className="video-appointment-skeletons__video" />
    </div>
);

export default VideoAppointmentSkeletons;
