import { YouTubeFormData } from '../../../../models/YouTube';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateYouTubeEmbedId,
} from '../../../../services/ValidationService';

export type YouTubeFormErrors = FormErrors<YouTubeFormData>;

export const validateYouTubeFormData = (
    formData: YouTubeFormData,
): FormValidation<YouTubeFormErrors> => {
    const errors: YouTubeFormErrors = {
        embedId: validateYouTubeEmbedId(formData.embedId),
    };

    return validateForm<YouTubeFormErrors>(errors);
};
