import React, { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import { ProfileStep, ProfileStepId } from '../../ProfileProgress';
import { ProgressEmoticon } from '..';

import './ProgressCallToAction.scss';

interface ProgressCallToActionProps {
    isSatisfactory: boolean;
    isCompleted: boolean;
    currentStep?: ProfileStep;
    finishedTitle: string;
    finishedDescription: string;
    onCallToActionClick: (stepId: ProfileStepId) => void;
    onFinishClick: () => void;
    className?: string;
}

const ProgressCallToAction: FC<ProgressCallToActionProps> = ({
    isSatisfactory,
    isCompleted,
    currentStep,
    finishedTitle,
    finishedDescription,
    onCallToActionClick,
    onFinishClick,
    className = '',
}): ReactElement => {
    const handleCallToActionClick = (): void => {
        if (currentStep) {
            onCallToActionClick(currentStep.stepId);
        }
    };

    return (
        <div className={`progress-call-to-action ${className}`}>
            <ProgressEmoticon
                isSatisfactory={isSatisfactory}
                className="progress-call-to-action__emoticon"
            />

            {isCompleted && (
                <div className="progress-call-to-action__text-wrapper">
                    <h2 className="progress-call-to-action__title">
                        {finishedTitle}
                    </h2>
                    <p className="progress-call-to-action__description">
                        {finishedDescription}
                    </p>
                </div>
            )}

            {isCompleted && (
                <Button
                    text={trans('actions.hide')}
                    onClick={onFinishClick}
                    className="progress-call-to-action__button"
                />
            )}

            {!isCompleted && currentStep && (
                <div className="progress-call-to-action__text-wrapper">
                    <h2 className="progress-call-to-action__title">
                        {currentStep.title}
                    </h2>
                    <p className="progress-call-to-action__description">
                        {currentStep.description}
                    </p>
                </div>
            )}

            {!isCompleted && currentStep && (
                <Button
                    text={currentStep.callToAction}
                    onClick={handleCallToActionClick}
                    className="progress-call-to-action__button"
                />
            )}
        </div>
    );
};

export default ProgressCallToAction;
