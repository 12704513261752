import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    Chat,
    ChatResource,
    transformJapiDocumentToChat,
    transformToCreateChatWithCandidateRequest,
    transformToCreateChatWithCompanyRequest,
} from '../models/Chat';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getChatsApiCall = async (): Promise<FetchResult<Chat[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/company-chats`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ChatResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const chats = doc.data.map(chatResource => transformJapiDocumentToChat(doc, chatResource));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: chats,
        };
    } catch (error) {
        console.error('[getChatsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const createChatApiCall = async (isCandidate: boolean, recipientUuid: string): Promise<FetchResult<Chat, string>> => {
    try {
        const createChatRequest = isCandidate
            ? transformToCreateChatWithCompanyRequest(recipientUuid)
            : transformToCreateChatWithCandidateRequest(recipientUuid);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/company-chats`, {
            method: 'POST',
            body: JSON.stringify(createChatRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ChatResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const chat = transformJapiDocumentToChat(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: chat,
        };
    } catch (error) {
        console.error('[createChatApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
