import React, { FC, ReactElement } from 'react';

import { Link, LinkProps } from 'react-router-dom';

import './LinkButton.scss';

export interface LinkButtonProps extends LinkProps {
    text?: string;
    disabled?: boolean;
}

const LinkButton: FC<LinkButtonProps> = ({
    text,
    className = '',
    children,
    ...linkButtonProps
}): ReactElement => (
    <Link
        {...linkButtonProps}
        title={text}
        className={`link-button ${className}`}
    >
        {children || text}
    </Link>
);

export default LinkButton;
