import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconVideo: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 70" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0,70h80V55l20,15V0L80,15V0H0V70z M10,60h60V10H10V60z M77.5,29.4v11.2L90,50V20L77.5,29.4z" />
    </svg>
);

export default IconVideo;
