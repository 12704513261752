import { Address as OldAddress, AddressFormValues } from '../_old/app_talentz/models/Address';
import { Resource } from '../japi/types/Resource';

export interface AddressResource extends Resource {
    type: 'addresses';
    id: string;
    attributes: {
        address: string;
        latitude: number;
        longitude: number;
        streetNumber: string;
        streetNumberExtension?: string;
        zipCode: string;
        city: string;
        province: string;
    };
}

export interface CreateAddressRequestBody extends Omit<AddressResource, 'id'> {
    id?: string;
}

export interface Address {
    uuid?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    houseNumberAddition?: string;
    postalCode?: string;
    province?: string;
    latitude?: string;
    longitude?: string;
}

export const defaultAddress = (): Address => ({
    uuid: '',
    city: '',
    street: '',
    houseNumber: '',
    houseNumberAddition: '',
    postalCode: '',
    province: '',
    latitude: '',
    longitude: '',
});

export const transformToAddress = (
    addressResource: AddressResource,
): Address => ({
    uuid: addressResource.id,
    city: addressResource.attributes.city,
    street: addressResource.attributes.address,
    houseNumber: addressResource.attributes.streetNumber,
    houseNumberAddition: addressResource.attributes.streetNumberExtension,
    postalCode: addressResource.attributes.zipCode,
    province: addressResource.attributes.province,
    latitude: String(addressResource.attributes.latitude),
    longitude: String(addressResource.attributes.longitude),
});

export const transformAttributesToAddress = (
    addressAttributes: AddressResource['attributes'],
): Address => ({
    uuid: '',
    city: addressAttributes.city,
    street: addressAttributes.address,
    houseNumber: addressAttributes.streetNumber,
    houseNumberAddition: addressAttributes.streetNumberExtension,
    postalCode: addressAttributes.zipCode,
    province: addressAttributes.province,
    latitude: String(addressAttributes.latitude),
    longitude: String(addressAttributes.longitude),
});

export const transformAddressFormValuesToAddress = (
    addressFormValues?: AddressFormValues,
): Address => {
    const defaults = defaultAddress();

    return {
        city: addressFormValues?.city || defaults.city,
        street: addressFormValues?.address || defaults.street,
        houseNumber: addressFormValues?.street_number || defaults.houseNumber,
        houseNumberAddition: addressFormValues?.extension || defaults.houseNumberAddition,
        postalCode: addressFormValues?.zipcode || defaults.postalCode,
        province: addressFormValues?.province || defaults.province,
        latitude: String(addressFormValues?.latitude) || defaults.latitude,
        longitude: String(addressFormValues?.longitude) || defaults.longitude,
    };
};

export const transformToCreateAddressRequestBody = (
    address: Address,
): CreateAddressRequestBody => ({
    type: 'addresses',
    attributes: {
        address: address.street || '',
        latitude: Number(address.latitude) || 0,
        longitude: Number(address.longitude) || 0,
        streetNumber: address.houseNumber || '',
        streetNumberExtension: address.houseNumberAddition || '',
        zipCode: address.postalCode || '',
        city: address.city || '',
        province: address.province || '',
    },
});

export const transformAddressToOldAddress = (
    address: Address,
): OldAddress => ({
    uuid: address.uuid || '',
    city: address.city || '',
    address: address.street || '',
    street_number: address.houseNumber || '',
    extension: address.houseNumberAddition || '',
    zipcode: address.postalCode || '',
    province: address.province || '',
    latitude: address.latitude || '',
    longitude: address.longitude || '',
});
