/* eslint-disable camelcase */
import { MinMaxValue } from '../types';
import { defaultFullEmploymentPreference } from './Employment';

export interface VacancyMatchesFilterValues {
    pageNumber: number;
    pageSize: number;
    candidateTypes: string[];
    employmentPreferences: MinMaxValue;
    educationLevels: string[];
    sectors: string[];
    workRemotePreference: string[];
    driversLicenses: string[];
    travelDistance: number;
}

export const defaultVacancyMatchesFilterValues = (): VacancyMatchesFilterValues => ({
    pageNumber: 1,
    pageSize: 10,
    candidateTypes: [],
    employmentPreferences: defaultFullEmploymentPreference(),
    educationLevels: [],
    sectors: [],
    workRemotePreference: [],
    driversLicenses: [],
    travelDistance: 0,
});

export interface VacancyMatchesFilterParams {
    'page[number]': number;
    'page[size]': number;
    roles?: string[];
    'hour_range[min]'?: string;
    'hour_range[max]'?: string;
    education_levels?: string[];
    sectors?: string[];
    remoteWorkingOptions?: string[];
    driving_licenses?: string[];
    travel_distance?: string;
}

export const transformToVacancyMatchesFilterParams = (
    filterValues: VacancyMatchesFilterValues,
): VacancyMatchesFilterParams => ({
    'page[number]': filterValues.pageNumber,
    'page[size]': filterValues.pageSize,
    roles: filterValues.candidateTypes.length > 0
        ? filterValues.candidateTypes
        : undefined,
    'hour_range[min]': filterValues.employmentPreferences
        ? `${filterValues.employmentPreferences.min}`
        : undefined,
    'hour_range[max]': filterValues.employmentPreferences
        ? `${filterValues.employmentPreferences.max}`
        : undefined,
    education_levels: filterValues.educationLevels.length > 0
        ? filterValues.educationLevels
        : undefined,
    sectors: filterValues.sectors.length > 0
        ? filterValues.sectors
        : undefined,
    remoteWorkingOptions: filterValues.workRemotePreference.length > 0
        ? filterValues.workRemotePreference
        : undefined,
    driving_licenses: filterValues.driversLicenses.length > 0
        ? filterValues.driversLicenses
        : undefined,
    travel_distance: filterValues.travelDistance > 0
        ? `${filterValues.travelDistance}`
        : undefined,
});
/* eslint-enable camelcase */
