import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Icon from '../../styledComponents/components/Icon';
import { Span } from '../../styledComponents/components/Typography';
import { Tip, TipContent } from './style';

const Tooltip = props => {
    const {
        id,
        title,
        content,
        icon,
        iconSize,
        iconColor,
        maxWidth,
        titleSize,
        showTipIcon,
    } = props;

    const largeContent = content.length > 40;
    const fixedWidth = 34 * 14; // 14 default font size in pixels

    const handleOverridePosition = useCallback(({ left, top }, event, triggerElement, tooltipElement) => ({
        top,
        // Make sure the tooltip is not cut off by the window
        left: left < 0 ? 0 : Math.min(left, window.innerWidth - tooltipElement.offsetWidth),
    }), []);

    return (
        <>
            {showTipIcon && (
                <Tip data-tip data-for={id}>
                    <Icon kind={icon} size={iconSize} color={iconColor} />
                </Tip>
            )}
            <TipContent
                id={id}
                effect="solid"
                width={largeContent && fixedWidth}
                maxWidth={maxWidth}
                overridePosition={handleOverridePosition}
                {...props}
            >
                {title && <Span size={titleSize || 'xs'} fontWeight="bold" color="white" mb="sm" mt="xxs">{title}</Span>}
                <Span size="xs" fontWeight="normal" color="white" mb="xxs">{content}</Span>
            </TipContent>
        </>
    );
};

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    showTipIcon: PropTypes.bool,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,

    title: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string,
    maxWidth: PropTypes.number,
    titleSize: PropTypes.string,
};

Tooltip.defaultProps = {
    title: null,
    icon: 'info',
    iconSize: 16,
    iconColor: 'text',
    maxWidth: null,
    titleSize: null,
    showTipIcon: true,
};

export default Tooltip;
