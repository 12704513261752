import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import {
    SchoolExperience,
    SchoolExperienceResource,
    transformToSchoolExperience,
    transformToSchoolExperienceRequest,
} from '../models/NewSchoolExperience';

export const postSchoolExperienceApiCall = async (data: SchoolExperience, candidateUuid: string): Promise<FetchResult<SchoolExperience, string>> => {
    try {
        const schoolExperienceRequest = transformToSchoolExperienceRequest(data, candidateUuid);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/school-experiences`, {
            method: 'POST',
            body: JSON.stringify(schoolExperienceRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<SchoolExperienceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const schoolExperience = transformToSchoolExperience(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: schoolExperience,
        };
    } catch (error) {
        console.error('[postSchoolExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchSchoolExperienceApiCall = async (data: SchoolExperience, candidateUuid: string): Promise<FetchResult<SchoolExperience, string>> => {
    try {
        const schoolExperienceRequest = transformToSchoolExperienceRequest(data, candidateUuid);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/school-experiences/${data.id}`, {
            method: 'PATCH',
            body: JSON.stringify(schoolExperienceRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<SchoolExperienceResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const schoolExperience = transformToSchoolExperience(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: schoolExperience,
        };
    } catch (error) {
        console.error('[patchSchoolExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteSchoolExperienceApiCall = async (data: SchoolExperience): Promise<FetchResult<true, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/school-experiences/${data.id}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            data: true,
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[deleteSchoolExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
