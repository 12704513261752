import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconAcademicCap: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 81.8" className={`icon ${className}`}>
        <path d="M50,0L0,27.3l18.2,9.9v27.3L50,81.8l31.8-17.4V37.2l9.1-5v31.4h9.1V27.3L50,0z M81,27.3L50,44.2L19,27.3l31-16.9L81,27.3zM72.7,59L50,71.5L27.3,59V42.1L50,54.5l22.7-12.4V59z" />
    </svg>
);

export default IconAcademicCap;
