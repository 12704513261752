import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { BlogArticle } from '../../models/Blog';
import { AsyncReduxState } from '../../types';

export type FeaturedBlogArticlesState = AsyncReduxState<{
    featuredBlogArticles: BlogArticle[];
}>;

const initialState: FeaturedBlogArticlesState = {
    ...initialAsyncReduxState,
    featuredBlogArticles: [],
};

const featuredBlogArticlesSlice = createSlice({
    name: 'featuredBlogArticlesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): FeaturedBlogArticlesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): FeaturedBlogArticlesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFeaturedBlogArticles(state, action: PayloadAction<BlogArticle[]>): FeaturedBlogArticlesState {
            return {
                ...state,
                featuredBlogArticles: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setFeaturedBlogArticles,
} = featuredBlogArticlesSlice.actions;

export default featuredBlogArticlesSlice;
