import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton, SafeHtml, Skeleton } from '../../../components';
import {
    DetailCard,
    IconButton,
    Modal,
    ModalContent,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { AboutFormData } from '../../../models/About';
import { AboutForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';

import './CompanyAboutCard.scss';

interface CompanyAboutCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable?: boolean;
    userFromCompany: boolean;
    description?: string;
    onEdit?: (aboutFormData: AboutFormData) => void;
    className?: string;
}

const CompanyAboutCard: FC<CompanyAboutCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    userFromCompany,
    description = '',
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditAboutCompany = (aboutFormData: AboutFormData): void => {
        if (onEdit) {
            onEdit(aboutFormData);
        }

        closeEditModal();
    };

    const handleDeleteDescription = (): void => {
        if (onEdit) {
            onEdit({ description: '' });
        }
    };

    const nullState = userFromCompany
        ? trans('companyProfile.about.nullState.company')
        : trans('companyProfile.about.nullState.candidate');

    return (
        <>
            <DetailCard
                isLoading={isLoading}
                isEditable={isEditable && description.length > 0}
                title={trans('companyProfile.about.title')}
                onEditClick={openEditModal}
                onDeleteClick={handleDeleteDescription}
                className={`company-about-card ${className}`}
            >
                <ProgressRefButton ref={activationRef} onClick={openEditModal} />

                {isLoading && (
                    <div className="company-about-card__skeleton-wrapper">
                        <Skeleton className="company-about-card__skeleton" />
                        <Skeleton className="company-about-card__skeleton" />
                        <Skeleton className="company-about-card__skeleton" />
                        <Skeleton className="company-about-card__skeleton" />
                    </div>
                )}

                {!isLoading && description.length === 0 && (
                    <div className="company-about-card__null-state">
                        <p>{nullState}</p>

                        {isEditable && (
                            <IconButton
                                icon="plus"
                                text={trans('actions.add')}
                                disabled={isLoading}
                                onClick={openEditModal}
                                className="company-about-card__null-state-button"
                            />
                        )}
                    </div>
                )}

                {!isLoading && description.length > 0 && (
                    <SafeHtml
                        html={description}
                        className="company-about-card__description"
                    />
                )}
            </DetailCard>

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('companyProfile.about.modal.title')}>
                        <AboutForm
                            label={trans('forms.aboutCompany.input.description')}
                            placeholder={trans('forms.aboutCompany.placeholder.description')}
                            description={description}
                            onCancel={closeEditModal}
                            onSubmit={handleEditAboutCompany}
                        />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default CompanyAboutCard;
