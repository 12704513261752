import React, { ReactElement } from 'react';

import classnames from 'classnames';

import { TipContent } from './style';

interface Props {
    id: string;
    content: ReactElement | string;
    place?: 'bottom' | 'top' | 'left' | 'right';
    effect?: string;
    className?: string;
}

const ToolTip = ({
    id,
    content,
    className,
    effect = 'solid',
    place = 'top',
}: Props): ReactElement => (
    <TipContent
        id={id}
        effect={effect}
        className={classnames('tooltip', className)}
        place={place}
    >
        <span className="tooltip__content">
            {content}
        </span>
    </TipContent>
);

export default ToolTip;
