import { convertStringToWebsiteURL } from '../helpers/string';
import getIncluded from '../japi/getIncluded';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { AtLeast } from '../types';
import {
    Address,
    AddressResource,
    defaultAddress,
    transformToAddress,
} from './Address';
import {
    AmountOfEmployeesOption,
    AmountOfEmployeesResource,
    defaultAmountOfEmployees,
    transformToAmountOfEmployeesOption,
} from './AmountOfEmployees';
import { CoreValue, CoreValuesResource, transformToCoreValue } from './CoreValue';
import { EmployeeProfile, EmployeeProfileResource, transformToEmployeeProfile } from './EmployeeProfile';
import { SbbCreboNumber } from './SbbCreboNumber';
import { SbbQualificationResource, transformToSbbCreboNumber } from './SbbTrainingCompany';

export interface CompanyResource extends Resource {
    type: 'companies';
    id: string;
    attributes: {
        name: string;
        slug: string;
        description: string;
        funFacts: string;
        logo: string;
        coverImage: string;
        coverVideo: string;
        website: string;
        kvkNumber: string;
        kvkData: any;
    };
    relationships: {
        address: SingleRelationship<'address'>;
        amountOfEmployees: SingleRelationship<'amount-of-employees'>;
        coreValues: MultipleRelationship<'core-values'>;
        employeeProfile: SingleRelationship<'employee-profile'>;
    };
}

export interface Company {
    uuid: string;
    name: string;
    slug: string;
    logo: string;
    address: Address;
    coverImage: string;
    coverVideo: string;
    description: string;
    funFacts: string;
    coreValues: CoreValue[];
    amountOfEmployees: AmountOfEmployeesOption;
    website: string;
    kvkNumber?: string;
    kvkData?: any;
    employeeProfiles: EmployeeProfile[];
    sbbCreboNumbers: SbbCreboNumber[];
    // TODO: ↓ not included in response yet
    isHidden: boolean;
    isRecommended: boolean;
    unreadApplicationsCount: number;
}

export interface CompanyRequest extends Resource {
    type: 'companies';
    id: string;
}

export const defaultCompany = (): Company => ({
    uuid: '',
    name: '',
    slug: '',
    logo: '',
    address: defaultAddress(),
    coverImage: '',
    coverVideo: '',
    description: '',
    funFacts: '',
    coreValues: [],
    amountOfEmployees: defaultAmountOfEmployees(),
    website: '',
    employeeProfiles: [],
    sbbCreboNumbers: [],
    //
    isHidden: false,
    isRecommended: false,
    unreadApplicationsCount: 0,
});

export const transformJapiDocumentToCompany = (
    doc: JapiDocument,
    data: CompanyResource,
): Company => {
    const defaults = defaultCompany();

    const includedAddress = getIncluded<AddressResource>(doc, data, 'address');
    const includedAmountOfEmployees = getIncluded<AmountOfEmployeesResource>(doc, data, 'amountOfEmployees');
    const includedCoreValues = getMultipleIncluded<CoreValuesResource>(doc, data, 'coreValues');
    const includedEmployeeProfiles = getMultipleIncluded<EmployeeProfileResource>(doc, data, 'employeeProfiles');
    const includedSbbQualifications = getMultipleIncluded<SbbQualificationResource>(doc, data, 'sbbQualifications');

    const address = includedAddress
        ? transformToAddress(includedAddress)
        : defaults.address;

    const amountOfEmployees = includedAmountOfEmployees
        ? transformToAmountOfEmployeesOption(includedAmountOfEmployees)
        : defaults.amountOfEmployees;

    const coreValues = includedCoreValues
        ? includedCoreValues.map(transformToCoreValue)
        : defaults.coreValues;

    const employeeProfiles = includedEmployeeProfiles
        ? includedEmployeeProfiles.map(transformToEmployeeProfile)
        : defaults.employeeProfiles;

    const sbbCreboNumbers = includedSbbQualifications
        ? includedSbbQualifications.map(sbbQualification => transformToSbbCreboNumber(sbbQualification, doc))
        : defaults.sbbCreboNumbers;

    const website = data.attributes.website ? convertStringToWebsiteURL(data.attributes.website) : '';

    return {
        uuid: data.id,
        name: data.attributes.name,
        slug: data.attributes.slug,
        logo: data.attributes.logo
            ? `${process.env.REACT_APP_API_V1_URL}/storage/logos/${data.attributes.logo}`
            : '',
        address,
        coverImage: data.attributes.coverImage
            ? `${process.env.REACT_APP_API_V1_URL}/storage/cover-images/${data.attributes.coverImage}`
            : '',
        coverVideo: data.attributes.coverVideo || '',
        description: data.attributes.description || '',
        funFacts: data.attributes.funFacts || '',
        coreValues,
        amountOfEmployees,
        website,
        kvkNumber: data.attributes.kvkNumber,
        kvkData: data.attributes.kvkData,
        employeeProfiles,
        sbbCreboNumbers,
        // TODO: ↓ not included in response yet
        isHidden: false,
        isRecommended: false,
        unreadApplicationsCount: 0,
    };
};

export const transformToCompanyRequest = (company: AtLeast<Company, 'uuid'>): CompanyRequest => ({
    type: 'companies',
    id: company.uuid,
    attributes: {
        ...((company.description || company.description === '') && {
            description: company.description || null,
        }),
        ...((company.funFacts || company.funFacts === '') && {
            funFacts: company.funFacts || null,
        }),
        ...((company.logo || company.logo === '') && {
            logo: company.logo || null,
        }),
        ...((company.coverImage || company.coverImage === '') && {
            coverImage: company.coverImage || null,
        }),
        ...((company.coverVideo || company.coverVideo === '') && {
            coverVideo: company.coverVideo || null,
        }),
    },
    relationships: {
        ...(company.coreValues && {
            coreValues: {
                data: company.coreValues.map(coreValue => ({
                    id: coreValue.uuid,
                    type: 'core-values',
                })),
            },
        }),
    },
});
