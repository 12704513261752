import React, { FC, ReactElement } from 'react';

import { Person } from '../../../../_old/app_talentz/models/Person';
import { MenuItem } from '../../../../connectors/ConnectedMenu/helpers';
import { trans } from '../../../../helpers/trans';
import { Company } from '../../../../models/Company';
import { RoleType } from '../../../../models/User';
import { ExpandableInformationNavigation, MenuList, ProfileControls } from '..';
import { MenuListTemplate } from '../MenuList/MenuList';

import './DesktopNavigation.scss';

interface DesktopNavigationProps {
    authIsLoading: boolean;
    isValidUser: boolean;
    role: RoleType;
    person?: Person;
    company?: Company;
    mainItems: MenuItem[];
    informationItems: MenuItem[];
    extraInformationItems: MenuItem[];
    iconItems: MenuItem[];
    profileItems: MenuItem[];
    sessionItems: MenuItem[];
    className?: string;
}

const DesktopNavigation: FC<DesktopNavigationProps> = ({
    authIsLoading,
    isValidUser,
    role,
    person,
    company,
    mainItems,
    informationItems,
    extraInformationItems,
    iconItems,
    profileItems,
    sessionItems,
    className = '',
}): ReactElement => (
    <nav aria-label={trans('navigation.mainNavigation.ariaLabel')} className={`desktop-navigation ${className}`}>
        <div className="desktop-navigation__wrapper">
            <MenuList
                isLoading={authIsLoading}
                menuItems={mainItems}
                className="desktop-navigation__main-list"
            />

            <ExpandableInformationNavigation
                isLoading={authIsLoading}
                primaryMenuItems={informationItems}
                secondaryMenuItems={extraInformationItems}
                className="desktop-navigation__information"
            />
        </div>

        {authIsLoading || isValidUser ? (
            <div className="desktop-navigation__wrapper">
                <MenuList
                    isLoading={authIsLoading}
                    template={MenuListTemplate.icon}
                    menuItems={iconItems}
                    className="desktop-navigation__icon-controls"
                />

                <ProfileControls
                    isLoading={authIsLoading}
                    role={role}
                    person={person}
                    company={company}
                    controls={profileItems}
                    className="desktop-navigation__profile-controls"
                />
            </div>
        ) : (
            <MenuList
                template={MenuListTemplate.buttons}
                menuItems={sessionItems}
                className="desktop-navigation__session-list"
            />
        )}
    </nav>
);

export default DesktopNavigation;
