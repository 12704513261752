import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { alphabetNumber } from '../../constants';
import { divideArrayInColumns } from '../../helpers/array';
import { trans } from '../../helpers/trans';
import { EscoOccupation } from '../../models/Esco';
import { Province } from '../../models/Provinces';
import { Sector } from '../../models/Sectors';
import { JobVacancyIndexParams } from '../../models/VacancyIndex';
import {
    VacancyIndexBreadcrumbList,
    VacancyIndexOccupation,
    VacancyIndexOccupationCity,
    VacancyIndexOccupationLetter,
    VacancyIndexOccupationProvince,
    VacancyIndexProvince,
    VacancyIndexProvinceCity,
    VacancyIndexSector,
    VacancyIndexSectorCity,
    VacancyIndexSectorProvince,
    VacancyIndexTab,
} from './subcomponents';

import './VacancyIndex.scss';

interface JobVacancyIndexProps extends JobVacancyIndexParams {
    isLoading: boolean;
    activeLetters: string[];
    sectorOptions: Sector[];
    provinceOptions: Province[];
    cityOptions: string[];
    escoOccupationOptions: EscoOccupation[];
    className?: string;
}

const JobVacancyIndex: FC<JobVacancyIndexProps> = ({
    isLoading,
    letter,
    occupation,
    sector,
    province,
    activeLetters,
    sectorOptions,
    provinceOptions,
    cityOptions,
    escoOccupationOptions,
    className = '',
}): ReactElement => {
    const showInitialCategory = !(letter || occupation || sector || province);

    const showOccupationCategory = letter && !(occupation || sector || province);
    const showOccupationProvinceCategory = letter && occupation && !(sector || province);
    const showOccupationCityCategory = letter && occupation && province && !sector;

    const showSectorProvinceCategory = sector && !(letter || occupation || province);
    const showSectorCityCategory = sector && province && !(letter || occupation);

    const showProvinceCityCategory = province && !(letter || occupation || sector);

    const selectedOccupation = escoOccupationOptions.find(option => option.slug === occupation);
    const selectedSector = sectorOptions.find(option => option.slug === sector);
    const selectedProvince = provinceOptions.find(option => option.slug === province);

    const sectorOptionColumns = divideArrayInColumns(sectorOptions, 5);
    const provinceOptionColumns = divideArrayInColumns(provinceOptions, 4);
    const cityOptionColumns = divideArrayInColumns(cityOptions, 6);

    const occupationTitle = letter === alphabetNumber
        ? trans('vacancyIndex.jobs.number', { number: alphabetNumber })
        : trans('vacancyIndex.jobs.letter', { letter });

    const categoryProps = {
        isLoading,
        vacancyType: VacancyType.job,
        className: 'vacancy-index__category',
    };

    return (
        <div className={`vacancy-index ${className}`}>
            {!showInitialCategory && (
                <VacancyIndexBreadcrumbList
                    vacancyType={VacancyType.job}
                    letter={letter}
                    occupation={occupation}
                    sector={sector}
                    province={province}
                    occupationLabel={selectedOccupation?.name}
                    sectorLabel={selectedSector?.name}
                    provinceLabel={selectedProvince?.name}
                    className="vacancy-index__breadcrumbs"
                />
            )}

            <VacancyIndexTab isActive={showInitialCategory} title={trans('vacancyIndex.jobs.main')}>
                <VacancyIndexOccupationLetter
                    {...categoryProps}
                    activeLetters={activeLetters}
                />

                <VacancyIndexSector
                    {...categoryProps}
                    sectorOptionColumns={sectorOptionColumns}
                />

                <VacancyIndexProvince
                    {...categoryProps}
                    provinceOptionColumns={provinceOptionColumns}
                />
            </VacancyIndexTab>

            {letter && (
                <VacancyIndexTab isActive={!!showOccupationCategory} title={occupationTitle}>
                    <VacancyIndexOccupation
                        {...categoryProps}
                        letter={letter}
                        escoOccupationOptions={escoOccupationOptions}
                    />
                </VacancyIndexTab>
            )}

            {letter && selectedOccupation && (
                <VacancyIndexTab
                    isActive={!!showOccupationProvinceCategory}
                    title={trans('vacancyIndex.jobs.occupation', {
                        occupation: selectedOccupation.name,
                    })}
                >
                    <VacancyIndexOccupationProvince
                        {...categoryProps}
                        letter={letter}
                        occupation={selectedOccupation}
                        provinceOptionColumns={provinceOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedOccupation && selectedProvince && (
                <VacancyIndexTab
                    isActive={!!showOccupationCityCategory}
                    title={trans('vacancyIndex.jobs.occupation', {
                        occupation: selectedOccupation.name,
                    })}
                >
                    <VacancyIndexOccupationCity
                        {...categoryProps}
                        occupation={selectedOccupation}
                        province={selectedProvince}
                        cityOptionColumns={cityOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedSector && (
                <VacancyIndexTab
                    isActive={!!showSectorProvinceCategory}
                    title={trans('vacancyIndex.jobs.sector', {
                        sector: selectedSector.name,
                    })}
                >
                    <VacancyIndexSectorProvince
                        {...categoryProps}
                        sector={selectedSector}
                        provinceOptionColumns={provinceOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedSector && selectedProvince && (
                <VacancyIndexTab
                    isActive={!!showSectorCityCategory}
                    title={trans('vacancyIndex.jobs.sector', {
                        sector: selectedSector.name,
                    })}
                >
                    <VacancyIndexSectorCity
                        {...categoryProps}
                        sector={selectedSector}
                        province={selectedProvince}
                        cityOptionColumns={cityOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedProvince && (
                <VacancyIndexTab
                    isActive={!!showProvinceCityCategory}
                    title={trans('vacancyIndex.jobs.province', {
                        province: selectedProvince.name,
                    })}
                >
                    <VacancyIndexProvinceCity
                        {...categoryProps}
                        province={selectedProvince}
                        cityOptionColumns={cityOptionColumns}
                    />
                </VacancyIndexTab>
            )}
        </div>
    );
};

export default JobVacancyIndex;
