import React, { FC, ReactElement, useEffect } from 'react';

import { VideoAppointment } from '../../containers';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { clearApplication, fetchApplication } from '../../store/application/applicationActions';
import { clearApplicationAppointment, fetchApplicationAppointment } from '../../store/applicationAppointment/applicationAppointmentActions';

interface ConnectedVideoAppointmentProps {
    applicationUuid?: string;
    appointmentUuid?: string;
    className?: string;
}

const ConnectedVideoAppointment: FC<ConnectedVideoAppointmentProps> = ({
    applicationUuid,
    appointmentUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const role = useLegacySelector(state => state.legacyUser.role);

    const applicationIsLoading = useTypedSelector(state => state.applicationReducer.isLoading);
    const application = useTypedSelector(state => state.applicationReducer.application);

    const appointmentIsLoading = useTypedSelector(state => state.applicationAppointmentReducer.isLoading);
    const appointmentStatusCode = useTypedSelector(state => state.applicationAppointmentReducer.statusCode);
    const appointment = useTypedSelector(state => state.applicationAppointmentReducer.appointment);

    useEffect((): () => void => {
        if (applicationUuid) {
            dispatch(fetchApplication(applicationUuid));
        }

        if (appointmentUuid) {
            dispatch(fetchApplicationAppointment(appointmentUuid));
        }

        return (): void => {
            dispatch(clearApplication());
            dispatch(clearApplicationAppointment());
        };
    }, [applicationUuid, appointmentUuid]);

    return (
        <VideoAppointment
            isLoading={applicationIsLoading || appointmentIsLoading}
            isCandidate={role === RoleType.student || role === RoleType.jobSeeker}
            application={application}
            appointmentStatusCode={appointmentStatusCode}
            appointment={appointment}
            className={className}
        />
    );
};

export default ConnectedVideoAppointment;
