import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Label } from '../../../../components';
import { formatDate } from '../../../../helpers/date';
import { trimToMaxLength } from '../../../../helpers/string';
import { BlogArticle } from '../../../../models/Blog';
import { RoutePaths } from '../../../../routes';

import './BlogListItem.scss';

interface BlogListItemProps extends BlogArticle {
    isFeatured?: boolean;
    className?: string;
}

const BlogListItem: FC<BlogListItemProps> = ({
    isFeatured,
    slug,
    thumbnail,
    categories,
    title,
    intro,
    createdAt,
    className = '',
}): ReactElement => {
    const thumbnailStyle = { backgroundImage: `url(${thumbnail.src})` };
    const createdDate = formatDate(createdAt, 'd MMMM yyyy');
    const introText = trimToMaxLength(intro, 140);

    const category = categories[0];

    const blogListItemClassName = classNames('blog-list-item', {
        'blog-list-item--is-featured': isFeatured,
    }, className);

    return (
        <li className={blogListItemClassName}>
            <div style={thumbnailStyle} className="blog-list-item__thumbnail">
                <span className="blog-list-item__thumbnail-alt">
                    {thumbnail.alt}
                </span>
            </div>

            <div className="blog-list-item__text-wrapper">
                <ul className="blog-list-item__categories">
                    <li key={category} className="blog-list-item__category">
                        <Label text={category} />
                    </li>
                </ul>

                <Link to={RoutePaths.blogDetail(category, slug)} className="blog-list-item__link">
                    <h2 className="blog-list-item__title">
                        {title}
                    </h2>
                </Link>

                <p className="blog-list-item__meta">
                    {createdDate}
                </p>

                <p className="blog-list-item__intro">
                    {introText}
                </p>
            </div>
        </li>
    );
};

export default BlogListItem;
