import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Card, Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './VacancyOverviewSkeletons.scss';

interface VacancyOverviewSkeletonsProps {
    isGrid?: boolean;
    amount?: number;
    className?: string;
}

const VacancyOverviewSkeletons: FC<VacancyOverviewSkeletonsProps> = ({
    isGrid,
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    const skeletonItemClassNames = classNames('vacancy-overview-skeletons__item', {
        'vacancy-overview-skeletons__item--is-grid': isGrid,
    });

    return (
        <ul className={`vacancy-overview-skeletons ${className}`}>
            {skeletons.map(skeleton => (
                <li key={skeleton} className={skeletonItemClassNames}>
                    <Card className="vacancy-overview-skeletons__card">
                        <Skeleton className="vacancy-overview-skeletons__avatar" />
                        <div className="vacancy-overview-skeletons__text-wrapper">
                            <Skeleton className="vacancy-overview-skeletons__title" />

                            <div className="vacancy-overview-skeletons__meta-wrapper">
                                <Skeleton className="vacancy-overview-skeletons__company" />
                                <Skeleton className="vacancy-overview-skeletons__date" />
                            </div>
                        </div>
                    </Card>
                </li>
            ))}
        </ul>
    );
};

export default VacancyOverviewSkeletons;
