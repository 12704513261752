import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArticleAdd: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 86.7 100" className={`icon ${className}`}>
        <path d="M0,95.1h43.8v-10H10V10h61.2v46.9h10V0H0V95.1z M16.8,60H63v0v-7.5H16.8V60z M57.6,43.7H16.8v-7.9h40.8L57.6,43.7L57.6,43.7z M16.8,27.3h43.6l0,0v-7.9H16.8V27.3z" />
        <path d="M64.8,100V87.9H52.7v-9.8h12.1V66h9.8v12.1h12.1v9.8H74.6V100H64.8z" />
    </svg>
);

export default IconArticleAdd;
