import React from 'react';

import SettingsPerson from '../../_old/app_talentz/app/containers/Settings/SettingsPerson';
import {
    ApplicationDetail,
    ApplicationVideoAppointment,
    CandidateOverview,
    CandidateProfile,
} from '../../pages';

export const candidateRoutePaths = {
    userProfile: () => '/gebruiker/mijn-profiel',
    userSettings: (tab = ':tab') => `/gebruiker/instellingen/${tab}`,

    candidateOverview: () => '/gebruiker/mijn-overzicht',
    candidateOverviewTab: (tab = ':tab') => `/gebruiker/mijn-overzicht/${tab}`,
    candidateOverviewMessages: (uuid = ':uuid') => `/gebruiker/mijn-overzicht/berichten/${uuid}`,

    application: (applicationUuid = ':applicationUuid') => `/gebruiker/mijn-sollicitaties/${applicationUuid}`,
    appointment: (applicationUuid = ':applicationUuid', appointmentUuid = ':appointmentUuid') => (
        `/gebruiker/mijn-sollicitaties/${applicationUuid}/afspraak/${appointmentUuid}`
    ),
};

export const candidateRoutes = [
    {
        path: candidateRoutePaths.userProfile(),
        element: <CandidateProfile />,
    },
    {
        path: candidateRoutePaths.userSettings(),
        element: <SettingsPerson />,
    },
    {
        path: candidateRoutePaths.candidateOverview(),
        element: <CandidateOverview />,
        children: [
            {
                path: ':tab',
                element: <CandidateOverview />,
                children: [
                    {
                        path: ':uuid',
                        element: <CandidateOverview />,
                    },
                ],
            },
        ],
    },
    {
        path: candidateRoutePaths.application(),
        element: <ApplicationDetail />,
    },
    {
        path: candidateRoutePaths.appointment(),
        element: <ApplicationVideoAppointment />,
    },
];
