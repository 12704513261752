import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconDislike: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.1,100h18.8l20.7-36.2l6.4-3.2V65h30V0H70v4.6L58.5,0H17.2L0,28.6V70h29.1L24.1,100z M72.5,15H69l-12.5-5H22.8L10,31.4V60h30.9l-5,30h1.2l19.3-33.8L68.8,50h3.7V15z M80,55h10V10H80V55z" />
    </svg>
);

export default IconDislike;
