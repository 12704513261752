import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Application } from '../../models/Applications';
import { AsyncReduxState } from '../../types';

export type ApplicationsState = AsyncReduxState<{
    applications: Application[];
}>;

const initialState: ApplicationsState = {
    ...initialAsyncReduxState,
    applications: [],
};

const applicationsSlice = createSlice({
    name: 'applicationsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ApplicationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ApplicationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setApplications(state, action: PayloadAction<Application[]>): ApplicationsState {
            return {
                ...state,
                applications: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setApplications,
} = applicationsSlice.actions;

export default applicationsSlice;
