import React, { FC, ReactElement, useState } from 'react';

import { IconButton, TextInput } from '../../../../../compositions';
import { formatDate } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { AppointmentDate } from '../../../../../models/ApplicationAppointments';

import './AppointmentInput.scss';

interface AppointmentInputProps {
    isFirst: boolean;
    isLast: boolean;
    value: AppointmentDate;
    onChange: (value: AppointmentDate) => void;
    onDeleteClick: () => void;
    className?: string;
}

const AppointmentInput: FC<AppointmentInputProps> = ({
    isFirst,
    isLast,
    value,
    onChange,
    onDeleteClick,
    className = '',
}): ReactElement => {
    const [date, setDate] = useState<string>(value.date);
    const [startTime, setStartTime] = useState<string>(value.startTime);
    const [endTime, setEndTime] = useState<string>(value.endTime);

    const handleDateChange = (value: string): void => {
        setDate(value);

        onChange({
            date: value,
            startTime,
            endTime,
        });
    };

    const handleStartTimeChange = (value: string): void => {
        setStartTime(value);

        onChange({
            date,
            startTime: value,
            endTime,
        });
    };

    const handleEndTimeChange = (value: string): void => {
        setEndTime(value);

        onChange({
            date,
            startTime,
            endTime: value,
        });
    };

    return (
        <div className={`appointment-input ${className}`}>
            <TextInput
                type="date"
                hideLabel={!isFirst}
                label={trans('forms.appointment.input.appointment.date')}
                value={date}
                min={formatDate(new Date(), 'y-MM-dd')}
                onChange={handleDateChange}
                className="appointment-input__date-input"
                inputClassName="appointment-input__input-field"
            />

            <div className="appointment-input__time-wrapper">
                <TextInput
                    type="time"
                    hideLabel={!isFirst}
                    label={trans('forms.appointment.input.appointment.startTime')}
                    value={startTime}
                    onChange={handleStartTimeChange}
                    className="appointment-input__time-input"
                    inputClassName="appointment-input__input-field"
                />

                <TextInput
                    type="time"
                    hideLabel={!isFirst}
                    label={trans('forms.appointment.input.appointment.endTime')}
                    value={endTime}
                    onChange={handleEndTimeChange}
                    className="appointment-input__time-input"
                    inputClassName="appointment-input__input-field"
                />

                {!isFirst && isLast && (
                    <div className="appointment-input__remove-button-wrapper">
                        <IconButton
                            icon="delete"
                            text={trans('forms.appointment.button.removeAppointment')}
                            hideLabel
                            onClick={onDeleteClick}
                            className="appointment-input__remove-button"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AppointmentInput;
