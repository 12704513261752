// eslint-disable-next-line import/no-extraneous-dependencies
import fetch from 'node-fetch';

import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    EscoOccupationsResource,
    EscoSkillsResource,
    transformToEscoOccupation,
    transformToEscoSkillViewModel,
} from '../models/Esco';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { NewestVacancy, NewestVacancyResource, transformToNewestVacancy } from '../models/NewestVacancy';
import { transformToVacancyViewModel, VacancyResource, VacancyViewModel } from '../models/Vacancy';

export const getVacancyBySlugApiCall = async (slug: string): Promise<FetchResult<VacancyViewModel, string>> => {
    try {
        const includes = ['escoSkills', 'escoOccupations'].join(',');
        const response = await fetch(`${process.env.REACT_APP_API_V2_URL}/public/vacancies/${slug}?include=${includes}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<VacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const includedEscoOccupations = getMultipleIncluded<EscoOccupationsResource>(doc, doc.data, 'escoOccupations');
        const includedEscoSkills = getMultipleIncluded<EscoSkillsResource>(doc, doc.data, 'escoSkills');

        const escoOccupations = includedEscoOccupations
            ? includedEscoOccupations.map(transformToEscoOccupation)
            : [];

        const escoSkills = includedEscoSkills
            ? includedEscoSkills.map(skillResource => transformToEscoSkillViewModel(skillResource))
            : [];

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: transformToVacancyViewModel(
                doc.data,
                escoOccupations,
                escoSkills,
            ),
        };
    } catch (error) {
        console.error('[getVacancyBySlugApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getVacancyApiCall = async (vacancyUuid: string, userFromCompany?: boolean): Promise<FetchResult<NewestVacancy, string>> => {
    try {
        const apiType = userFromCompany ? 'api' : 'public';
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/${apiType}/elasticsearch/vacancies/${vacancyUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<NewestVacancyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const vacancy = transformToNewestVacancy(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: vacancy,
        };
    } catch (error) {
        console.error('[getVacancyApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
