import React, { FC, ReactElement } from 'react';

import { Button, LinkButton } from '../../components';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { CompanyOverviewTab } from '../../types/pageTabs';

import './HomeCallToAction.scss';

interface HomeCallToActionProps {
    userRole: RoleType;
    onCallToActionClick: () => void;
    className?: string;
}

const HomeCallToAction: FC<HomeCallToActionProps> = ({
    userRole,
    onCallToActionClick,
    className = '',
}): ReactElement => (
    <div className={`home-call-to-action ${className}`}>
        <div className="home-call-to-action__content-wrapper">
            <h2 className="home-call-to-action__title">
                {trans('home.callToAction.title')}
            </h2>

            {userRole === RoleType.company ? (
                <LinkButton
                    to={RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies)}
                    text={trans('home.callToAction.buttonLabel')}
                    className="home-call-to-action__button"
                />
            ) : (
                <Button
                    text={trans('home.callToAction.buttonLabel')}
                    onClick={onCallToActionClick}
                    className="home-call-to-action__button"
                />
            )}
        </div>

        <div className="home-call-to-action__image-wrapper">
            <img
                src="/images/people/diversity-candidate.png"
                alt={trans('home.callToAction.imageAlt')}
                className="home-call-to-action__image"
            />

            <picture className="home-call-to-action__picture">
                <source srcSet="/images/people/diversity-candidate.webp" type="image/webp" />
                <img
                    loading="lazy"
                    src="/images/people/diversity-candidate.png"
                    alt={trans('home.callToAction.imageAlt')}
                    className="home-call-to-action__image"
                />
            </picture>

            <div className="home-call-to-action__circle-wrapper">
                <div className="home-call-to-action__circle" />
            </div>
        </div>
    </div>
);

export default HomeCallToAction;
