import { defaultFormOption } from '../constants';
import { isValidDateString } from '../helpers/date';
import { generateFullName } from '../helpers/name';
import { sortByStartDate } from '../helpers/sort';
import getIncluded from '../japi/getIncluded';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import { JapiDocument } from '../japi/types/Document';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { AtLeast, FormOption, MinMaxValue } from '../types';
import { CompetenciesResource, Competency, transformToCompetency } from './Competencies';
import { DriversLicensesResource, transformDriversLicenseToFormOption, transformToDriversLicense } from './DriversLicenses';
import { defaultEmploymentPreference } from './Employment';
import { transformToVacanciesInfo, VacanciesInfo } from './info/VacanciesInfo';
import { JobExperience, JobExperienceResource, transformToJobExperience } from './JobExperience';
import { SchoolExperience, SchoolExperienceResource, transformToSchoolExperience } from './NewSchoolExperience';
import { SectorsResource, transformSectorToFormOption, transformToSector } from './Sectors';
import {
    Skill,
    SkillsResource,
    SkillType,
    transformToSkill,
} from './Skills';
import { RoleType, UserRole } from './User';
import { transformToWorkRemoteOption, transformWorkRemoteOptionToFormOption, WorkRemoteResource } from './WorkRemote';

const getCandidateRole = (userRole: string): RoleType => {
    if (userRole === UserRole.student) return RoleType.student;

    return RoleType.jobSeeker;
};

export interface CandidateResource extends Resource {
    type: 'people';
    id: string;
    attributes: {
        isLlo: boolean;
        user: {
            roles: string[];
        };
        firstName: string;
        insertion?: string;
        lastName: string;
        gender: string;
        address: {
            city: string;
        };
        birthday?: string;
        phoneNumber?: string;
        avatar: string;
        video: string;
        personalMessage: string;
        personalCatchphrase: string;
        employmentHourRange: MinMaxValue;
        createdAt: string;
        updatedAt: string;
    };
    relationships: {
        competences: MultipleRelationship<'competencies'>;
        competencesToLearn: MultipleRelationship<'competencies'>;
        skills: MultipleRelationship<'skills'>;
        escoSkills: MultipleRelationship<'esco-skills'>;
        skillsToLearn: MultipleRelationship<'skills'>;
        escoSkillsToLearn: MultipleRelationship<'esco-skills'>;
        favouriteVacancies: MultipleRelationship<'vacancies-info'>;
        internshipPreferencesSet: SingleRelationship<'internship-preferences-sets'>;
        schoolExperiences: MultipleRelationship<'school-experiences'>;
        jobExperiences: MultipleRelationship<'job-experiences'>;
        jobPreferencesSet: SingleRelationship<'job-preferences-sets'>;
        remoteWorkingOptions: MultipleRelationship<'remote-working'>;
        drivingLicenses: MultipleRelationship<'driving-licenses'>;
        sectors: MultipleRelationship<'sectors'>;
        workRemote: SingleRelationship<'work-remote'>;
    };
}

export interface Candidate {
    uuid: string;
    isLlo: boolean;
    role: RoleType;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    fullName: string;
    gender: string;
    dateOfBirth?: Date;
    phoneNumber: string;
    city: string;
    avatar: string;
    video: string;
    description: string;
    catchphrase: string;
    competencies: Competency[];
    competenciesToLearn: Competency[];
    skills: Skill[];
    escoSkills: Skill[];
    skillsToLearn: Skill[];
    escoSkillsToLearn: Skill[];
    schoolExperiences: SchoolExperience[];
    jobExperiences: JobExperience[];
    employmentPreferences: MinMaxValue;
    driversLicenses: FormOption[];
    preferredSectors: FormOption[];
    workRemotePreference: FormOption;
    favourites: VacanciesInfo[];
    createdAt: Date;
}

export interface CandidateRequest extends Resource {
    type: 'people';
    id: string;
}

export const defaultCandidate = (): Candidate => ({
    uuid: '',
    isLlo: false,
    role: RoleType.jobSeeker,
    firstName: '',
    lastNamePrefix: '',
    lastName: '',
    fullName: '',
    gender: '',
    phoneNumber: '',
    city: '',
    avatar: '',
    video: '',
    description: '',
    catchphrase: '',
    competencies: [],
    competenciesToLearn: [],
    skills: [],
    escoSkills: [],
    skillsToLearn: [],
    escoSkillsToLearn: [],
    schoolExperiences: [],
    jobExperiences: [],
    employmentPreferences: defaultEmploymentPreference(),
    workRemotePreference: defaultFormOption(),
    driversLicenses: [],
    preferredSectors: [],
    favourites: [],
    createdAt: new Date(),
});

export const transformJapiDocumentToCandidate = (
    doc: JapiDocument,
    data: CandidateResource,
): Candidate => {
    const defaults = defaultCandidate();

    const includedCompetencies = getMultipleIncluded<CompetenciesResource>(doc, data, 'competences');
    const includedCompetenciesToLearn = getMultipleIncluded<CompetenciesResource>(doc, data, 'competencesToLearn');
    const includedSkills = getMultipleIncluded<SkillsResource>(doc, data, 'skills');
    const includedEscoSkills = getMultipleIncluded<SkillsResource>(doc, data, 'escoSkills');
    const includedSkillsToLearn = getMultipleIncluded<SkillsResource>(doc, data, 'skillsToLearn');
    const includedEscoSkillsToLearn = getMultipleIncluded<SkillsResource>(doc, data, 'escoSkillsToLearn');
    const includedSchoolExperiences = getMultipleIncluded<SchoolExperienceResource>(doc, data, 'schoolExperiences');
    const includedJobExperiences = getMultipleIncluded<JobExperienceResource>(doc, data, 'jobExperiences');
    const includedWorkRemotePreference = getIncluded<WorkRemoteResource>(doc, data, 'remoteWorkingOptions');
    const includedDriversLicenses = getMultipleIncluded<DriversLicensesResource>(doc, data, 'drivingLicenses');
    const includedSectors = getMultipleIncluded<SectorsResource>(doc, data, 'sectors');
    const includedFavourites = getMultipleIncluded<any>(doc, data, 'favouriteVacancies');

    const role = data.attributes.user.roles && data.attributes.user.roles.length > 0
        ? getCandidateRole(data.attributes.user.roles[0])
        : defaults.role;

    const fullName = generateFullName({
        firstName: data.attributes.firstName,
        lastNamePrefix: data.attributes.insertion || '',
        lastName: data.attributes.lastName,
    });

    const competencies = includedCompetencies
        ? includedCompetencies.map(transformToCompetency)
        : defaults.competencies;

    const competenciesToLearn = includedCompetenciesToLearn
        ? includedCompetenciesToLearn.map(transformToCompetency)
        : defaults.competenciesToLearn;

    const skills = includedSkills
        ? includedSkills.map(skillsResource => transformToSkill(skillsResource, SkillType.skill))
        : defaults.skills;

    const escoSkills = includedEscoSkills
        ? includedEscoSkills.map(skillsResource => transformToSkill(skillsResource, SkillType.esco))
        : defaults.escoSkills;

    const skillsToLearn = includedSkillsToLearn
        ? includedSkillsToLearn.map(skillsResource => transformToSkill(skillsResource, SkillType.skill))
        : defaults.skillsToLearn;

    const escoSkillsToLearn = includedEscoSkillsToLearn
        ? includedEscoSkillsToLearn.map(skillsResource => transformToSkill(skillsResource, SkillType.esco))
        : defaults.escoSkillsToLearn;

    const schoolExperiences = includedSchoolExperiences
        ? includedSchoolExperiences
            .map(transformToSchoolExperience)
            .sort(sortByStartDate)
            .reverse()
        : defaults.schoolExperiences;

    const jobExperiences = includedJobExperiences
        ? includedJobExperiences
            .map(transformToJobExperience)
            .sort(sortByStartDate)
            .reverse()
        : defaults.jobExperiences;

    const employmentPreferences = data?.attributes?.employmentHourRange
        ? data.attributes.employmentHourRange
        : defaults.employmentPreferences;

    const workRemotePreference = includedWorkRemotePreference
        ? [includedWorkRemotePreference]
            .map(transformToWorkRemoteOption)
            .map(transformWorkRemoteOptionToFormOption)[0]
        : defaults.workRemotePreference;

    const driversLicenses = includedDriversLicenses
        ? includedDriversLicenses
            .map(transformToDriversLicense)
            .map(transformDriversLicenseToFormOption)
        : defaults.driversLicenses;

    const preferredSectors = includedSectors
        ? includedSectors
            .map(transformToSector)
            .map(transformSectorToFormOption)
        : defaults.preferredSectors;

    const favourites = includedFavourites
        ? includedFavourites.map(transformToVacanciesInfo)
        : defaults.favourites;

    const createdAt = isValidDateString(data.attributes.createdAt)
        ? new Date(data.attributes.createdAt)
        : new Date();

    return {
        uuid: data.id,
        isLlo: data.attributes.isLlo,
        role,
        firstName: data.attributes.firstName,
        lastNamePrefix: data.attributes.insertion || '',
        lastName: data.attributes.lastName,
        fullName,
        gender: data.attributes.gender || '',
        phoneNumber: data.attributes.phoneNumber || '',
        city: data.attributes.address.city || '',
        avatar: data.attributes.avatar
            ? `${process.env.REACT_APP_API_V1_URL}/storage/avatars/${data.attributes.avatar}`
            : '',
        video: data.attributes.video || '',
        description: data.attributes.personalMessage || '',
        catchphrase: data.attributes.personalCatchphrase || '',
        competencies,
        competenciesToLearn,
        skills,
        escoSkills,
        skillsToLearn,
        escoSkillsToLearn,
        schoolExperiences,
        jobExperiences,
        employmentPreferences,
        workRemotePreference,
        driversLicenses,
        preferredSectors,
        favourites,
        createdAt,
    };
};

export const transformToCandidateRequest = (candidate: AtLeast<Candidate, 'uuid'>): CandidateRequest => ({
    type: 'people',
    id: candidate.uuid,
    attributes: {
        ...((candidate.avatar || candidate.avatar === '') && {
            avatar: candidate.avatar || null,
        }),
        ...((candidate.catchphrase || candidate.catchphrase === '') && {
            personalCatchphrase: candidate.catchphrase || null,
        }),
        ...((candidate.description || candidate.description === '') && {
            personalMessage: candidate.description || null,
        }),
        ...((candidate.video || candidate.video === '') && {
            video: candidate.video || null,
        }),
        ...(candidate.employmentPreferences && {
            employmentHourRange: candidate.employmentPreferences,
        }),
        ...(candidate.workRemotePreference && {
            workRemoteSlug: candidate.workRemotePreference.value,
        }),
    },
    relationships: {
        ...(candidate.competencies && {
            competences: {
                data: candidate.competencies.map(competency => ({
                    id: competency.id,
                    type: 'competences',
                })),
            },
        }),
        ...(candidate.skills && {
            skills: {
                data: candidate.skills.map(skill => ({
                    id: skill.id,
                    type: 'skills',
                })),
            },
        }),
        ...(candidate.escoSkills && {
            escoSkills: {
                data: candidate.escoSkills.map(escoSkill => ({
                    id: escoSkill.id,
                    type: 'esco-skills',
                })),
            },
        }),
        ...(candidate.competenciesToLearn && {
            competencesToLearn: {
                data: candidate.competenciesToLearn.map(competencyToLearn => ({
                    id: competencyToLearn.id,
                    type: 'competencesToLearn',
                })),
            },
        }),
        ...(candidate.skillsToLearn && {
            skillsToLearn: {
                data: candidate.skillsToLearn.map(skillToLearn => ({
                    id: skillToLearn.id,
                    type: 'skillsToLearn',
                })),
            },
        }),
        ...(candidate.escoSkillsToLearn && {
            escoSkillsToLearn: {
                data: candidate.escoSkillsToLearn.map(escoSkillToLearn => ({
                    id: escoSkillToLearn.id,
                    type: 'escoSkillsToLearn',
                })),
            },
        }),
        ...(candidate.driversLicenses && {
            drivingLicenses: {
                data: candidate.driversLicenses.map(license => ({
                    id: license.value,
                    type: 'drivingLicense',
                })),
            },
        }),
        ...(candidate.preferredSectors && {
            sectors: {
                data: candidate.preferredSectors.map(sector => ({
                    id: sector.value,
                    type: 'sector',
                })),
            },
        }),
        ...(candidate.favourites && {
            favouriteVacancies: {
                data: candidate.favourites.map(favourite => ({
                    id: favourite.uuid,
                    type: 'favouriteVacancies',
                })),
            },
        }),
    },
});
