import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import ConfirmOverlay from '../../../../components/ConfirmOverlay';
import Overlay from '../../../../components/Overlay';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../styledComponents/components/Typography';
import DeleteAccountConfirmForm from '../forms/DeleteAccountConfirmForm';

const DeleteAccount = ({
    loading,
    deleteUser,
    errors,
    connectedProviders,
}) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const isConnectedWithSocialMedia = connectedProviders.includes('linkedin');

    return (
        <>
            <Heading as="h2" size="l">{trans('forms.user.account.delete.heading')}</Heading>
            <Row>
                <Col mb="md" width={1}>
                    <Paragraph size="rg">
                        {trans('forms.user.account.delete.summary')}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button id="account-delete" onClick={() => setShowDeleteConfirm(true)}>
                        {trans('forms.user.account.delete.button')}
                    </Button>
                </Col>
            </Row>
            <Overlay
                onClose={() => setShowDeleteConfirm(false)}
                dark
            >
                {(showDeleteConfirm && !isConnectedWithSocialMedia) && (
                    <DeleteAccountConfirmForm
                        onCancel={() => setShowDeleteConfirm(false)}
                        onSubmit={deleteUser}
                        loading={loading}
                        errors={errors}
                    />
                )}
            </Overlay>

            <ConfirmOverlay
                title={trans('forms.user.account.delete.confirm.heading')}
                body={trans('forms.user.account.delete.confirm.summary')}
                open={showDeleteConfirm && isConnectedWithSocialMedia}
                onCancel={() => setShowDeleteConfirm(false)}
                onConfirm={() => deleteUser(null)}
            />
        </>
    );
};

DeleteAccount.propTypes = {
    deleteUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    connectedProviders: PropTypes.array.isRequired,
};

export default DeleteAccount;
