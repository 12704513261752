import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Card, Icon, LinkButton } from '../../../components';
import { trans } from '../../../helpers/trans';
import { RoleType } from '../../../models/User';
import { VacancyMatchingCandidate } from '../../../models/VacancyMatches';
import { RoutePaths } from '../../../routes';
import { Avatar, LinkIconButton, LLOCandidateLabel } from '../..';
import { CandidatePropertyTags } from './subcomponents';

import './CandidateCard.scss';

interface CandidateCardProps {
    mayViewLLO?: boolean;
    vacancyUuid: string;
    candidate: VacancyMatchingCandidate;
    className?: string;
}

const CandidateCard: FC<CandidateCardProps> = ({
    mayViewLLO,
    vacancyUuid,
    candidate,
    className = '',
}): ReactElement => {
    const matchPath = RoutePaths.myVacancyMatch(vacancyUuid, candidate.uuid);

    return (
        <div className={`candidate-card ${className}`}>
            <Card className="candidate-card__card">
                <Avatar
                    isAnonymous={candidate.isAnonymous}
                    src={candidate.profilePicture}
                    alt={candidate.fullName}
                    fallbackString={candidate.fullName}
                    className="candidate-card__avatar"
                />

                <div className="candidate-card__text-wrapper">
                    <div className="candidate-card__title-wrapper">
                        <Link to={matchPath} className="candidate-card__link">
                            <h3 className="candidate-card__full-name">{candidate.fullName}</h3>
                        </Link>

                        {candidate.isInteresting && (
                            <span className="candidate-card__interested-circle">
                                <Icon name="check" className="candidate-card__interested-icon" />
                            </span>
                        )}
                    </div>

                    {mayViewLLO && candidate.role === RoleType.jobSeeker && candidate.isLLOCandidate && (
                        <LLOCandidateLabel isSmall className="candidate-card__llo-candidate-label" />
                    )}

                    {candidate.personalCatchPhrase && (
                        <q className="candidate-card__personal-catch-phrase">
                            {candidate.personalCatchPhrase}
                        </q>
                    )}

                    {candidate.city && (
                        <p className="candidate-card__role-from-city">
                            {candidate.role === RoleType.student && (
                                trans('compositions.candidateCard.studentFromCity', { city: candidate.city })
                            )}

                            {candidate.role === RoleType.jobSeeker && (
                                trans('compositions.candidateCard.jobSeekerFromCity', { city: candidate.city })
                            )}
                        </p>
                    )}

                    <CandidatePropertyTags
                        candidateUuid={candidate.uuid}
                        properties={candidate.properties}
                        className="candidate-card__property-tags"
                    />
                </div>

                <div className="candidate-card__button-wrapper">
                    <LinkButton
                        to={matchPath}
                        text={trans('compositions.candidateCard.button.viewCandidate')}
                        className="candidate-card__button"
                    />

                    {!candidate.isAnonymous && (
                        <LinkIconButton
                            to={RoutePaths.companyOverviewMessages(candidate.uuid)}
                            icon="send"
                            text={trans('actions.sendMessage')}
                            className="candidate-card__button candidate-card__button--for-chat"
                        />
                    )}
                </div>
            </Card>
        </div>
    );
};

export default CandidateCard;
