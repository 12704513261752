import { CoreValuesFormData } from '../../../../models/CoreValue';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMinimumArrayLength,
} from '../../../../services/ValidationService';

export type CoreValuesFormErrors = FormErrors<CoreValuesFormData>;

export const validateCoreValuesFormData = (
    formData: CoreValuesFormData,
): FormValidation<CoreValuesFormErrors> => {
    const errors: CoreValuesFormErrors = {
        coreValues: validateMinimumArrayLength('coreValues', formData.coreValues, 3),
    };

    return validateForm<CoreValuesFormErrors>(errors);
};
