import React, { FC, ReactElement, useState } from 'react';

import { trans } from '../../../../helpers/trans';
import { KvkSearchResult } from '../../../../models/KvkSearchResult';
import { getAmountOfCompaniesTranslation } from '../../helpers/getAmountOfCompaniesTranslation';
import { CompanyKVKRadioInput } from '..';

import './CompanyKVKList.scss';

interface CompanyKVKListProps {
    searchResults: KvkSearchResult[];
    onChange: (id: string) => void;
    className?: string;
}

const CompanyKVKList: FC<CompanyKVKListProps> = ({
    searchResults,
    onChange,
    className = '',
}): ReactElement => {
    const [checkedRadio, setCheckedRadio] = useState<null | string>(null);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setCheckedRadio(e.currentTarget.value);
        onChange(e.currentTarget.value);
    };

    return (
        <div className={`company-kvk-list ${className}`}>
            <h2 className="company-kvk-list__title">
                {trans('forms.register.company.selectYourCompany')}
            </h2>

            <p className="company-kvk-list__results-count">
                {getAmountOfCompaniesTranslation(searchResults.length)}
            </p>

            {searchResults.length > 0 && (
                <div className="company-kvk-list__list">
                    {searchResults.map(searchResult => (
                        <CompanyKVKRadioInput
                            key={searchResult.uuid}
                            kvkSearchResult={searchResult}
                            onChange={handleInputChange}
                            checked={checkedRadio === searchResult.uuid}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyKVKList;
