import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { trans } from '../../../helpers/trans';

import './ProgressRefButton.scss';

type ProgressRefButtonProps = JSX.IntrinsicElements['button'] & RefAttributes<HTMLButtonElement>;

const ProgressRefButton: ForwardRefExoticComponent<ProgressRefButtonProps> = forwardRef(({
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => (
    <button
        {...buttonProps}
        ref={ref}
        type="button"
        tabIndex={-1}
        className="progress-ref-button"
    >
        {trans('actions.edit')}
    </button>
));

export default ProgressRefButton;
