import React, { FC, ReactElement } from 'react';

import { Button } from '../../../components';
import { trans } from '../../../helpers/trans';
import { ButtonList, SubmitButton } from '../..';

import './FormActionButtons.scss';

interface FormActionButtonsProps {
    isLoading?: boolean;
    submitIcon?: string;
    submitText?: string;
    disabled?: boolean;
    onCancelClick: () => void;
    className?: string;
}

const FormActionButtons: FC<FormActionButtonsProps> = ({
    isLoading = false,
    submitIcon,
    submitText = trans('actions.save'),
    disabled,
    onCancelClick,
    className = '',
}): ReactElement => (
    <ButtonList className={`form-action-buttons ${className}`}>
        <SubmitButton
            isLoading={isLoading}
            icon={submitIcon}
            text={submitText}
            disabled={disabled}
            className="form-action-buttons__button"
        />
        <Button
            text={trans('actions.cancel')}
            onClick={onCancelClick}
            className="form-action-buttons__button form-action-buttons__button--cancel"
        />
    </ButtonList>
);

export default FormActionButtons;
