import { trans } from '../../helpers/trans';
import { transformAddressFormValuesToAddress } from '../../models/Address';
import { EditableVacancy } from '../../models/EditableVacancy';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { MyVacanciesOverviewParams } from '../../models/MyVacancies';
import { transformToNewestVacancy } from '../../models/NewestVacancy';
import { patchAddressApiCall, postAddressApiCall } from '../../services/AddressService';
import {
    archiveVacancyApiCall,
    deleteVacancyApiCall,
    getMyVacanciesApiCall,
    getVacancyToEditApiCall,
    patchInternshipVacancyApiCall,
    patchJobVacancyApiCall,
    postInternshipVacancyApiCall,
    postJobVacancyApiCall,
    unarchiveVacancyApiCall,
} from '../../services/MyVacanciesService';
import { PlaceInternshipVacancyFormValues, PlaceJobVacancyFormValues } from '../../services/PlaceVacancyService';
import { ReducerGetter, TypedDispatch } from '..';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import {
    setError,
    setIsLoading,
    setPagination,
    setRecentlyUpdatedVacancy,
    setVacancies,
    setVacancyToEdit,
} from './myVacancies';

export const fetchMyVacancies = (params: MyVacanciesOverviewParams) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const myVacanciesResponse = await getMyVacanciesApiCall(params);

        if (!isFetchResultSuccessful(myVacanciesResponse)) {
            console.error('[fetchMyVacancies]', myVacanciesResponse.error);
            dispatch(setError(myVacanciesResponse.error));
            return;
        }

        const { pagination, vacancies } = myVacanciesResponse.data;

        dispatch(setPagination(pagination));
        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchMyVacancies]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchVacancyToEdit = (vacancyUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyToEditResponse = await getVacancyToEditApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(vacancyToEditResponse)) {
            console.error('[fetchVacancyToEdit]', vacancyToEditResponse.error);
            dispatch(setError(vacancyToEditResponse.error));
            return;
        }

        const vacancyToEdit = vacancyToEditResponse.data;

        dispatch(setVacancyToEdit(vacancyToEdit));
    } catch (error) {
        console.error('[fetchVacancyToEdit]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createInternshipVacancy = (formValues: PlaceInternshipVacancyFormValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        // Only full address available, vacancy call expects a address uuid, so we need to create one first
        const address = transformAddressFormValuesToAddress(formValues.address || undefined);
        const addressResponse = await postAddressApiCall(address);

        if (!isFetchResultSuccessful(addressResponse)) {
            console.error('[createInternshipVacancy]', addressResponse.error);
            dispatch(setError(addressResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: addressResponse.error,
            }));
            return;
        }

        const addressUuid = addressResponse.data.id;
        const createInternshipVacancyResponse = await postInternshipVacancyApiCall(formValues, addressUuid);

        if (!isFetchResultSuccessful(createInternshipVacancyResponse)) {
            console.error('[createInternshipVacancy]', createInternshipVacancyResponse.error);
            dispatch(setError(createInternshipVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: createInternshipVacancyResponse.error,
            }));
            return;
        }

        const newVacancy = transformToNewestVacancy(createInternshipVacancyResponse.data);

        dispatch(setRecentlyUpdatedVacancy(newVacancy));
    } catch (error) {
        console.error('[createInternshipVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editInternshipVacancy = (vacancyToEdit: EditableVacancy, formValues: PlaceInternshipVacancyFormValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        // Address has a different resource, and needs to be patched first
        const addressUuid = vacancyToEdit.address.uuid || '';
        const address = transformAddressFormValuesToAddress(formValues.address || undefined);
        const addressResponse = await patchAddressApiCall(addressUuid, address);

        if (!isFetchResultSuccessful(addressResponse)) {
            console.error('[editInternshipVacancy]', addressResponse.error);
            dispatch(setError(addressResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: addressResponse.error,
            }));

            return;
        }

        const editInternshipVacancyResponse = await patchInternshipVacancyApiCall(vacancyToEdit, formValues);

        if (!isFetchResultSuccessful(editInternshipVacancyResponse)) {
            console.error('[editInternshipVacancy]', editInternshipVacancyResponse.error);
            dispatch(setError(editInternshipVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: editInternshipVacancyResponse.error,
            }));

            return;
        }

        const updatedVacancy = transformToNewestVacancy(editInternshipVacancyResponse.data);

        dispatch(setRecentlyUpdatedVacancy(updatedVacancy));
    } catch (error) {
        console.error('[editInternshipVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createJobVacancy = (formValues: PlaceJobVacancyFormValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        // Only full address available, vacancy call expects a address uuid, so we need to create one first
        const address = transformAddressFormValuesToAddress(formValues.address || undefined);
        const addressResponse = await postAddressApiCall(address);

        if (!isFetchResultSuccessful(addressResponse)) {
            console.error('[createJobVacancy]', addressResponse.error);
            dispatch(setError(addressResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: addressResponse.error,
            }));

            return;
        }

        const addressUuid = addressResponse.data.id;
        const createJobVacancyResponse = await postJobVacancyApiCall(formValues, addressUuid);

        if (!isFetchResultSuccessful(createJobVacancyResponse)) {
            console.error('[createJobVacancy]', createJobVacancyResponse.error);
            dispatch(setError(createJobVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: createJobVacancyResponse.error,
            }));

            return;
        }

        const newVacancy = transformToNewestVacancy(createJobVacancyResponse.data);

        dispatch(setRecentlyUpdatedVacancy(newVacancy));
    } catch (error) {
        console.error('[createJobVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editJobVacancy = (vacancyToEdit: EditableVacancy, formValues: PlaceJobVacancyFormValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        // Address has a different resource, and needs to be patched first
        const addressUuid = vacancyToEdit.address.uuid || '';
        const address = transformAddressFormValuesToAddress(formValues.address || undefined);
        const addressResponse = await patchAddressApiCall(addressUuid, address);

        if (!isFetchResultSuccessful(addressResponse)) {
            console.error('[editJobVacancy]', addressResponse.error);
            dispatch(setError(addressResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: addressResponse.error,
            }));

            return;
        }

        const editJobVacancyResponse = await patchJobVacancyApiCall(vacancyToEdit, formValues);

        if (!isFetchResultSuccessful(editJobVacancyResponse)) {
            console.error('[editJobVacancy]', editJobVacancyResponse.error);
            dispatch(setError(editJobVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: editJobVacancyResponse.error,
            }));

            return;
        }

        const updatedVacancy = transformToNewestVacancy(editJobVacancyResponse.data);

        dispatch(setRecentlyUpdatedVacancy(updatedVacancy));
    } catch (error) {
        console.error('[editJobVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const archiveVacancy = (vacancyUuid: string, applicationUuids: string[]) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const state = getState();
    const { vacancies } = state.myVacanciesReducer;

    dispatch(setIsLoading(true));

    try {
        const archiveVacancyResponse = await archiveVacancyApiCall(vacancyUuid, applicationUuids);

        if (!isFetchResultSuccessful(archiveVacancyResponse)) {
            console.error('[archiveVacancy]', archiveVacancyResponse.error);
            dispatch(setError(archiveVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('basic.toast.success'),
            description: trans('vacancies.toast.archiveVacancy.success'),
        }));

        const updatedVacancies = vacancies.filter(vacancy => vacancy.uuid !== vacancyUuid);

        dispatch(setVacancies(updatedVacancies));
    } catch (error) {
        console.error('[archiveVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const unarchiveVacancy = (vacancyUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const state = getState();
    const { vacancies } = state.myVacanciesReducer;

    dispatch(setIsLoading(true));

    try {
        const unarchiveVacancyResponse = await unarchiveVacancyApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(unarchiveVacancyResponse)) {
            console.error('[unarchiveVacancy]', unarchiveVacancyResponse.error);
            dispatch(setError(unarchiveVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('basic.toast.success'),
            description: trans('vacancies.toast.unarchiveVacancy.success'),
        }));

        const updatedVacancies = vacancies.filter(vacancy => vacancy.uuid !== vacancyUuid);

        dispatch(setVacancies(updatedVacancies));
    } catch (error) {
        console.error('[unarchiveVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteVacancy = (vacancyUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const state = getState();
    const { vacancies } = state.myVacanciesReducer;

    dispatch(setIsLoading(true));

    try {
        const deleteVacancyResponse = await deleteVacancyApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(deleteVacancyResponse)) {
            console.error('[deleteVacancy]', deleteVacancyResponse.error);
            dispatch(setError(deleteVacancyResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        dispatch(addPositiveToast({
            title: trans('basic.toast.success'),
            description: trans('vacancies.toast.deleteVacancy.success'),
        }));

        const updatedVacancies = vacancies.filter(vacancy => vacancy.uuid !== vacancyUuid);

        dispatch(setVacancies(updatedVacancies));
    } catch (error) {
        console.error('[deleteVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
