import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconBriefcase: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 95" className={`icon ${className}`}>
        <path d="M60,20V10H40v10H60z M10,30v55h80V30H10z M90,20c5.5,0,10,4.5,10,10v55c0,5.5-4.5,10-10,10H10C4.4,95,0,90.6,0,85l0-55c0-5.5,4.4-10,9.9-10h20V10c0-5.5,4.4-10,10-10h20c5.5,0,10,4.4,10,10v10H90z" />
    </svg>
);

export default IconBriefcase;
