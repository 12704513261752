import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { Skeleton } from '../../../../components';
import {
    FilterButton,
    FixedFooter,
    Modal,
    ModalContent,
    SearchableValueInput,
} from '../../../../compositions';
import { getActiveFilterValues } from '../../../../helpers/filter';
import { getAmountOfDefinedValues } from '../../../../helpers/object';
import { trans } from '../../../../helpers/trans';
import useDeviceWidth from '../../../../hooks/useDeviceWidth';
import { EducationLevel, EducationSubLevel } from '../../../../models/EducationLevels';
import { NewestVacancy } from '../../../../models/NewestVacancy';
import { defaultVacancyMatchesFilterValues, VacancyMatchesFilterValues } from '../../../../models/VacancyMatchesFilter';
import { WorkRemoteOption } from '../../../../models/WorkRemote';
import { RoutePaths } from '../../../../routes';
import { FormOption, SearchableOption } from '../../../../types';
import { VacancyMatchesFilterDesktop, VacancyMatchesFilterMobile } from '..';

import './VacancyMatchesFilter.scss';

interface VacancyMatchesFilterProps {
    isLoading: boolean;
    hasLLOFilter: boolean;
    vacancyUuidFromUrl?: string;
    activeVacancy?: NewestVacancy;
    filterValues: VacancyMatchesFilterValues;
    activeFilterValues: VacancyMatchesFilterValues;
    amountOfCandidates: number;
    vacancyOptions: SearchableOption[];
    candidateTypeOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    sectorOptions: FormOption[];
    workRemoteOptions: WorkRemoteOption[];
    driversLicenseOptions: FormOption[];
    onChange: (values: Partial<VacancyMatchesFilterValues>) => void;
    onReset: () => void;
    onSubmit: () => void;
    className?: string;
}

const VacancyMatchesFilter: FC<VacancyMatchesFilterProps> = ({
    isLoading,
    hasLLOFilter,
    vacancyUuidFromUrl,
    activeVacancy,
    filterValues,
    activeFilterValues,
    amountOfCandidates,
    vacancyOptions,
    candidateTypeOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    sectorOptions,
    workRemoteOptions,
    driversLicenseOptions,
    onChange,
    onReset,
    onSubmit,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const { isMobile, isTabletPortrait } = useDeviceWidth();
    const isSmallScreen = isMobile || isTabletPortrait;

    const [mobileFilterModalIsOpen, setMobileFilterModalIsOpen] = useState<boolean>(false);

    const openMobileFilterModal = (): void => setMobileFilterModalIsOpen(true);
    const closeMobileFilterModal = (): void => setMobileFilterModalIsOpen(false);

    const setActiveVacancy = (selectedOption: SearchableOption): void => {
        if (selectedOption.value) {
            navigate(RoutePaths.companyOverviewMatches(selectedOption.value));
        }
    };

    useEffect((): void => {
        if (!vacancyUuidFromUrl && vacancyOptions.length > 0) {
            setActiveVacancy(vacancyOptions[0]);
        }
    }, [vacancyOptions]);

    const handleFilterReset = (): void => {
        closeMobileFilterModal();
        onReset();
    };

    const handleFilterSubmit = (): void => {
        closeMobileFilterModal();
        onSubmit();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pageNumber, pageSize, ...filtersToCheck } = activeFilterValues;

    const defaultFilters = defaultVacancyMatchesFilterValues();
    const activeFilters = getActiveFilterValues(filtersToCheck, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    const filterProps = {
        hasLLOFilter,
        vacancyType: activeVacancy?.type,
        filterValues,
        candidateTypeOptions,
        educationLevelOptions,
        educationSubLevelOptions,
        sectorOptions,
        workRemoteOptions,
        driversLicenseOptions,
        onChange,
    };

    return (
        <section className={`vacancy-matches-filter ${className}`}>
            <div className="vacancy-matches-filter__vacancy-selector-wrapper">
                {isLoading && (
                    <Skeleton className="vacancy-matches-filter__title-skeleton" />
                )}

                {!isLoading && activeVacancy && (
                    <h2 className="vacancy-matches-filter__title">
                        {trans('containers.vacancyMatches.filter.selectedVacancy', {
                            title: activeVacancy.title,
                        })}
                    </h2>
                )}

                <SearchableValueInput
                    isSearchable
                    showResultsOnClick
                    label={trans('containers.vacancyMatches.filter.input.vacancy')}
                    hideLabel
                    options={vacancyOptions}
                    placeholder={trans('containers.vacancyMatches.filter.placeholder.vacancy')}
                    disabled={isLoading}
                    onChange={setActiveVacancy}
                    className="vacancy-matches-filter__vacancy-input"
                    listClassName="vacancy-matches-filter__vacancy-input-results"
                />
            </div>

            {!isSmallScreen && (
                <div className="vacancy-matches-filter__desktop-filter-wrapper">
                    <div className="vacancy-matches-filter__heading-wrapper">
                        <h2 className="vacancy-matches-filter__title">
                            {trans('containers.vacancyMatches.filter.title')}
                        </h2>

                        {amountOfActiveFilters > 0 && (
                            <FilterButton
                                hasIcon
                                activeFilters={amountOfActiveFilters}
                                text={trans('containers.vacancyMatches.filter.button.clear')}
                                disabled={isLoading}
                                onClick={onReset}
                                className="vacancy-matches-filter__reset-button"
                            />
                        )}
                    </div>

                    <VacancyMatchesFilterDesktop
                        {...filterProps}
                        isLoading={isLoading}
                        activeFilterValues={activeFilterValues}
                        onSubmit={onSubmit}
                        className="vacancy-matches-filter__desktop-filter-form"
                    />
                </div>
            )}

            {isLoading ? (
                <Skeleton className="vacancy-matches-filter__results-title-skeleton" />
            ) : (
                <h1 className="vacancy-matches-filter__results-title">
                    {amountOfCandidates === 1 ? (
                        trans('containers.vacancyMatches.filter.resultsTitle.singular')
                    ) : (
                        trans('containers.vacancyMatches.filter.resultsTitle.plural', {
                            amount: amountOfCandidates,
                        })
                    )}
                </h1>
            )}

            {isSmallScreen && (
                <FixedFooter className="vacancy-matches-filter__fixed-footer">
                    <FilterButton
                        hasIcon
                        activeFilters={amountOfActiveFilters}
                        disabled={isLoading}
                        onClick={openMobileFilterModal}
                    />
                </FixedFooter>
            )}

            {mobileFilterModalIsOpen && (
                <Modal onClose={closeMobileFilterModal}>
                    <ModalContent title={trans('containers.vacancyMatches.filter.button.apply')}>
                        <VacancyMatchesFilterMobile
                            {...filterProps}
                            onReset={handleFilterReset}
                            onSubmit={handleFilterSubmit}
                        />
                    </ModalContent>
                </Modal>
            )}
        </section>
    );
};

export default VacancyMatchesFilter;
