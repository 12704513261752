import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import ConnectedRegisterDialog from '../../connectors/ConnectedRegisterDialog/ConnectedRegisterDialog';

const RegistrationPage: FC = (): ReactElement => (
    <Page>
        <ConnectedRegisterDialog />
    </Page>
);

export default RegistrationPage;
