import React, { FC, ReactElement } from 'react';

import {
    IconAcademicCap,
    IconAnonymousStar,
    IconApplicant,
    IconArchive,
    IconArrowDown,
    IconArrowHorizontal,
    IconArrowLeft,
    IconArrowRight,
    IconArrowUp,
    IconArrowVertical,
    IconArticle,
    IconArticleAdd,
    IconBell,
    IconBriefcase,
    IconCalendar,
    IconCamera,
    IconCameraAdd,
    IconChatBalloon,
    IconCheck,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronSort,
    IconChevronUp,
    IconCircleCheck,
    IconCircleExclamation,
    IconCircleInfo,
    IconCompany,
    IconCompetency,
    IconCrop,
    IconCross,
    IconCurrency,
    IconDelete,
    IconDislike,
    IconDocument,
    IconExclamation,
    IconEye,
    IconFilter,
    IconHeart,
    IconHeartSolid,
    IconHome,
    IconInformation,
    IconLike,
    IconLoader,
    IconLocation,
    IconLock,
    IconLogin,
    IconLogout,
    IconMail,
    IconMedal,
    IconPaperclip,
    IconPencil,
    IconPhone,
    IconPlay,
    IconPlus,
    IconProfile,
    IconQuestionMark,
    IconRefresh,
    IconSbbApprovedStar,
    IconSearch,
    IconSend,
    IconSettings,
    IconSkill,
    IconStaticHamburger,
    IconTriangleDown,
    IconTriangleSort,
    IconTriangleUp,
    IconUnarchive,
    IconUpload,
    IconVerticalEllipsis,
    IconVideo,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
    accentClassName?: string;
}

type IconSet = Record<string, FC<SvgIconProps>>;

interface IconProps extends SvgIconProps {
    name: string;
}

const icons: IconSet = {
    'academic-cap': IconAcademicCap,
    'anonymous-star': IconAnonymousStar,
    applicant: IconApplicant,
    archive: IconArchive,
    'arrow-down': IconArrowDown,
    'arrow-horizontal': IconArrowHorizontal,
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    'arrow-up': IconArrowUp,
    'arrow-vertical': IconArrowVertical,
    article: IconArticle,
    'article-add': IconArticleAdd,
    bell: IconBell,
    briefcase: IconBriefcase,
    calendar: IconCalendar,
    camera: IconCamera,
    'camera-add': IconCameraAdd,
    'chat-balloon': IconChatBalloon,
    check: IconCheck,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'chevron-sort': IconChevronSort,
    'chevron-up': IconChevronUp,
    'circle-check': IconCircleCheck,
    'circle-exclamation': IconCircleExclamation,
    'circle-info': IconCircleInfo,
    company: IconCompany,
    competency: IconCompetency,
    crop: IconCrop,
    cross: IconCross,
    currency: IconCurrency,
    delete: IconDelete,
    dislike: IconDislike,
    document: IconDocument,
    exclamation: IconExclamation,
    eye: IconEye,
    filter: IconFilter,
    heart: IconHeart,
    'heart-solid': IconHeartSolid,
    home: IconHome,
    information: IconInformation,
    like: IconLike,
    loader: IconLoader,
    location: IconLocation,
    lock: IconLock,
    login: IconLogin,
    logout: IconLogout,
    mail: IconMail,
    medal: IconMedal,
    paperclip: IconPaperclip,
    pencil: IconPencil,
    phone: IconPhone,
    play: IconPlay,
    plus: IconPlus,
    profile: IconProfile,
    'question-mark': IconQuestionMark,
    refresh: IconRefresh,
    'sbb-approved-star': IconSbbApprovedStar,
    search: IconSearch,
    send: IconSend,
    settings: IconSettings,
    skill: IconSkill,
    'static-hamburger': IconStaticHamburger,
    'triangle-down': IconTriangleDown,
    'triangle-sort': IconTriangleSort,
    'triangle-up': IconTriangleUp,
    unarchive: IconUnarchive,
    upload: IconUpload,
    'vertical-ellipsis': IconVerticalEllipsis,
    video: IconVideo,
};

const Icon: FC<IconProps> = ({
    name,
    className = '',
    accentClassName = '',
}): ReactElement | null => {
    const IconComponent = icons[name];

    return IconComponent
        ? <IconComponent className={className} accentClassName={accentClassName} />
        : null;
};

export default Icon;
