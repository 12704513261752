import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getEscoSkillsApiCall } from '../../services/EscoSkillsService';
import { TypedDispatch } from '..';
import { setError, setEscoSkills, setIsLoading } from './escoSkills';

export const fetchEscoSkills = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const escoSkillsResponse = await getEscoSkillsApiCall();

        if (!isFetchResultSuccessful(escoSkillsResponse)) {
            dispatch(setError(escoSkillsResponse.error));
            return;
        }

        const escoSkills = escoSkillsResponse.data;

        dispatch(setEscoSkills(escoSkills));
    } catch (error) {
        console.error('[fetchEscoSkills]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
