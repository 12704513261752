import React, { ReactElement, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UpdatePasswordParams } from '../../_old/app_talentz/models/ResetPassword';
import {
    DeleteAccountParams,
    UpdateCompanyParams,
    UpdateEmployeeParams,
    UpdatePersonSettingsParams,
} from '../../_old/app_talentz/models/SettingsPage';
import {
    deleteUser,
    resetUserPassword,
    updateOrCreateCompany,
    updateOrCreateEmployee,
    updatePersonSettingsAndCheckForWebPushSubscription,
} from '../../_old/app_talentz/store/legacyUser/legacyUserActions';
import { checkForWebPushSubscription } from '../../_old/app_talentz/store/notifications/notificationsActions';
import { SettingsCompanyContainer } from '../../containers';
import { EmailAddressChangeFormData } from '../../models/EmailAddress';
import { RouteParams, RoutePaths } from '../../routes';
import { useLegacyDispatch, useLegacySelector, useTypedSelector } from '../../store';
import { changeEmailAddress } from '../../store/accountSettings/accountSettingsActions';
import { CompanySettingsTab } from '../../types/pageTabs';

interface ConnectedSettingsCompanyParams extends RouteParams {
    tab?: string;
}

interface LocationParams {
    completeRegister: boolean;
}

// TODO: Refactor to proper PACO connector, including refactor of underlying container
const ConnectedSettingsCompany = (): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const navigate = useNavigate();
    const { tab = '' } = useParams<ConnectedSettingsCompanyParams>();
    const location = useLocation();
    const locationState = location.state as LocationParams;

    const company = useLegacySelector(state => state.userCompany);

    const resetPasswordIsLoading = useLegacySelector(state => state.resetPassword.loading);
    const resetPasswordErrors = useLegacySelector(state => state.resetPassword.errors);

    const deleteIsLoading = useLegacySelector(state => state.legacyUser.deleteLoading);
    const deleteErrors = useLegacySelector(state => state.legacyUser.deleteErrors);

    const employee = useLegacySelector(state => state.userEmployee);
    const person = useLegacySelector(state => state.userPerson);
    const profile = useLegacySelector(state => state.userProfile);
    const user = useLegacySelector(state => state.legacyUser);
    const settings = useLegacySelector(state => state.userPersonSettings);

    const accountSettingsIsLoading = useTypedSelector(state => state.accountSettingsReducer.isLoading);
    const emailAddressChange = useTypedSelector(state => state.accountSettingsReducer.emailAddressChange);

    useEffect((): void => {
        if (!tab) {
            navigate(RoutePaths.companySettings(CompanySettingsTab.myCompany), {
                replace: true,
                state: locationState,
            });
        }
    }, [tab]);

    const updateCompanyHandler = (data: UpdateCompanyParams) => {
        legacyDispatch(updateOrCreateCompany(data, navigate, locationState));
    };

    const updateEmployeeHandler = (data: UpdateEmployeeParams) => {
        legacyDispatch(updateOrCreateEmployee(data, navigate, locationState));
    };

    const resetUserPasswordHandler = (data: UpdatePasswordParams) => {
        legacyDispatch(resetUserPassword(data));
    };

    const handleChangeEmailAddress = (emailAddressChangeFormData: EmailAddressChangeFormData): void => {
        legacyDispatch(changeEmailAddress(emailAddressChangeFormData));
    };

    const deleteUserHandler = (data: DeleteAccountParams) => legacyDispatch(deleteUser(data));

    const updatePersonSettingHandler = (data: UpdatePersonSettingsParams) => {
        legacyDispatch(updatePersonSettingsAndCheckForWebPushSubscription(data));
    };

    const checkForWebPushSubscriptionHandler = () => {
        legacyDispatch(checkForWebPushSubscription());
    };

    const isLoading = [
        company.loading,
        employee.loading,
        profile.loading,
        settings.loading,
    ].some(Boolean);

    return (
        <SettingsCompanyContainer
            company={company.data}
            employee={employee.data}
            isLoading={isLoading}
            accountSettingsIsLoading={accountSettingsIsLoading}
            resetPasswordIsLoading={resetPasswordIsLoading}
            deleteIsLoading={deleteIsLoading}
            person={person.data}
            settings={settings.data}
            user={user.data}
            emailAddressChange={emailAddressChange}
            personErrors={person.errors}
            companyErrors={company.errors}
            employeeErrors={employee.errors}
            profileErrors={profile.errors}
            settingsErrors={settings.errors}
            resetPasswordErrors={resetPasswordErrors}
            deleteErrors={deleteErrors}
            updateCompany={updateCompanyHandler}
            updateEmployee={updateEmployeeHandler}
            onResetPassword={resetUserPasswordHandler}
            onChangeEmailAddress={handleChangeEmailAddress}
            onDeleteAccount={deleteUserHandler}
            updatePersonSettings={updatePersonSettingHandler}
            checkForWebPushSubscription={checkForWebPushSubscriptionHandler}
        />
    );
};

export default ConnectedSettingsCompany;
