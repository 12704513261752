import React, { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedVideoAppointment } from '../../connectors';
import { RouteParams } from '../../routes';

import './ApplicationVideoAppointment.scss';

interface ApplicationVideoAppointmentParams extends RouteParams {
    applicationUuid?: string;
    appointmentUuid?: string;
}

const ApplicationVideoAppointment: FC = (): ReactElement => {
    const { applicationUuid = '', appointmentUuid = '' } = useParams<ApplicationVideoAppointmentParams>();

    return (
        <Page className="application-video-appointment-page">
            <div className="application-video-appointment-page__wrapper">
                <ConnectedVideoAppointment
                    applicationUuid={applicationUuid}
                    appointmentUuid={appointmentUuid}
                />
            </div>
        </Page>
    );
};

export default ApplicationVideoAppointment;
