import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrowUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 56.2 100" className={`icon ${className}`}>
        <path d="M56.2,22.5L28.1,0L0,22.5l6.2,7.8l16.9-13.5V100h10V16.8L50,30.3L56.2,22.5z" />
    </svg>
);

export default IconArrowUp;
