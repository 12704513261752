import React, { ReactElement } from 'react';

import { ConnectedLLOLogin } from '../../../../../connectors';
import Page from '../../../../styledComponents/components/Page';

const LLOLoginPage = (): ReactElement => (
    <Page>
        <ConnectedLLOLogin />
    </Page>
);

export default LLOLoginPage;
