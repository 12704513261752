import { isFetchResultSuccessful } from '../../models/FetchResult';
import { defaultRelatedVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { getRelatedVacanciesApiCall, RelatedVacancyOverviewConfig } from '../../services/RelatedVacancyOverviewService';
import { ReducerGetter, TypedDispatch } from '..';
import {
    setActiveFilterValues,
    setError,
    setIsLoading,
    setPagination,
    setVacancies,
} from './relatedVacancyOverview';

type FetchRelatedVacancyOverviewConfig = Omit<RelatedVacancyOverviewConfig, 'filterValues'>;

export const clearRelatedVacancies = () => (dispatch: TypedDispatch): void => {
    dispatch(setVacancies([]));
    dispatch(setPagination(undefined));
    dispatch(setActiveFilterValues(defaultRelatedVacancyOverviewFilterValues()));
};

export const fetchRelatedVacancyOverview = (relatedVacancyOverviewConfig: FetchRelatedVacancyOverviewConfig) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { relatedVacancyOverviewReducer } = getState();
        const { activeFilterValues } = relatedVacancyOverviewReducer;

        const relatedVacanciesResponse = await getRelatedVacanciesApiCall({
            ...relatedVacancyOverviewConfig,
            filterValues: activeFilterValues,
        });

        if (!isFetchResultSuccessful(relatedVacanciesResponse)) {
            console.error('[fetchRelatedVacancyOverview]', relatedVacanciesResponse.error);
            dispatch(setError(relatedVacanciesResponse.error));
            dispatch(setPagination(undefined));
            dispatch(setVacancies([]));
            return;
        }

        const { pagination, vacancies } = relatedVacanciesResponse.data;

        const vacancyUuid = relatedVacancyOverviewConfig.vacancy.uuid;
        const filteredVacancies = vacancies.filter(relatedVacancy => relatedVacancy.uuid !== vacancyUuid);

        dispatch(setPagination(pagination));
        dispatch(setVacancies(filteredVacancies));
    } catch (error) {
        console.error('[fetchRelatedVacancyOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
