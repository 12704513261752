import { RichText } from 'prismic-dom';

import {
    PrismicBlogTemplate,
    PrismicDocument,
    PrismicSearchResponse,
    PrismicSlice,
    PrismicSliceSeo,
    PrismicSliceType,
} from './Prismic';

export interface BlogOverviewInfo {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalResults: number;
}

interface BlogImage {
    alt: string;
    src: string;
}

export interface BlogArticle {
    id: string;
    slug: string;
    categories: string[];
    title: string;
    intro: string;
    readTime: number;
    thumbnail: BlogImage;
    poster: BlogImage;
    body: PrismicSlice[];
    createdAt?: Date;
    updatedAt?: Date;
}

export interface BlogSeoData {
    title: string;
    description: string;
    keywords: string;
    image: BlogImage;
    createdAt?: Date;
    updatedAt?: Date;
}

export const transformPrismicSearchResponseToBlogOverviewInfo = (prismicSearchResponse: PrismicSearchResponse): BlogOverviewInfo => ({
    currentPage: prismicSearchResponse.page,
    pageSize: prismicSearchResponse.results_per_page,
    totalPages: prismicSearchResponse.total_pages,
    totalResults: prismicSearchResponse.total_results_size,
});

export const transformPrismicDocumentToBlog = (prismicDocument: PrismicDocument<PrismicBlogTemplate>): BlogArticle => {
    const titleText = RichText.asText(prismicDocument.data.title);
    const introText = RichText.asText(prismicDocument.data.intro);

    return {
        id: prismicDocument.id,
        slug: prismicDocument.uid,
        categories: prismicDocument.tags,
        title: titleText,
        intro: introText,
        readTime: prismicDocument.data.read_time,
        thumbnail: {
            src: prismicDocument.data.thumbnail.url,
            alt: prismicDocument.data.thumbnail.alt,
        },
        poster: {
            src: prismicDocument.data.poster.url,
            alt: prismicDocument.data.poster.alt,
        },
        body: prismicDocument.data.body,
        createdAt: prismicDocument.first_publication_date
            ? new Date(prismicDocument.first_publication_date)
            : undefined,
        updatedAt: prismicDocument.last_publication_date
            ? new Date(prismicDocument.last_publication_date)
            : undefined,
    };
};

export const transformPrismicDocumentToBlogSeoData = (prismicDocument: PrismicDocument<PrismicBlogTemplate>): BlogSeoData => {
    const bodyContent = prismicDocument.data.body;
    const createdAt = prismicDocument.first_publication_date;
    const updatedAt = prismicDocument.last_publication_date;
    const seoSlice = bodyContent.find(section => section.slice_type === PrismicSliceType.seo);
    const seoData = seoSlice?.primary as PrismicSliceSeo;

    return {
        title: seoData?.seo_title || '',
        description: seoData?.seo_description || '',
        keywords: seoData?.seo_keywords || '',
        image: {
            src: seoData?.seo_image.url || '',
            alt: seoData?.seo_image.alt || '',
        },
        createdAt: createdAt ? new Date(createdAt) : undefined,
        updatedAt: updatedAt ? new Date(updatedAt) : undefined,
    };
};
