import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { FieldOfStudy, FieldsOfStudyResource, transformResourceToFieldOfStudy } from '../models/FieldsOfStudy';

export const getFieldsOfStudyApiCall = async (): Promise<FetchResult<FieldOfStudy[], string>> => {
    try {
        const includes = ['educationLevel'].join(',');
        const fieldsOfStudyResponse = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/fields-of-study?include=${includes}`);

        if (!fieldsOfStudyResponse.ok) {
            return {
                status: fieldsOfStudyResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await fieldsOfStudyResponse.json();

        if (!isResourceCollectionDocument<FieldsOfStudyResource>(doc)) {
            return {
                status: fieldsOfStudyResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const existingFieldsOfStudy = doc.data.filter((fieldOfStudy: FieldsOfStudyResource) => !fieldOfStudy?.attributes?.manual);
        const fieldsOfStudy = existingFieldsOfStudy.map(transformResourceToFieldOfStudy);
        const sortedFieldsOfStudy = fieldsOfStudy.sort((fieldOfStudy, comparisonFieldOfStudy) => fieldOfStudy.name.localeCompare(comparisonFieldOfStudy.name));

        return {
            status: fieldsOfStudyResponse.status,
            type: FetchResultType.Success,
            data: sortedFieldsOfStudy,
        };
    } catch (error) {
        console.error('[getFieldsOfStudyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getManualFieldsOfStudyApiCall = async (): Promise<FetchResult<FieldOfStudy[], string>> => {
    try {
        const includes = ['educationLevel'].join(',');
        const fieldsOfStudyResponse = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/fields-of-study?include=${includes}`);

        if (!fieldsOfStudyResponse.ok) {
            return {
                status: fieldsOfStudyResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await fieldsOfStudyResponse.json();

        if (!isResourceCollectionDocument<FieldsOfStudyResource>(doc)) {
            return {
                status: fieldsOfStudyResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const manualFieldsOfStudy = doc.data.filter((fieldOfStudy: FieldsOfStudyResource) => fieldOfStudy?.attributes?.manual);
        const fieldsOfStudy = manualFieldsOfStudy.map(transformResourceToFieldOfStudy);
        const sortedFieldsOfStudy = fieldsOfStudy.sort((fieldOfStudy, comparisonFieldOfStudy) => fieldOfStudy.name.localeCompare(comparisonFieldOfStudy.name));

        return {
            status: fieldsOfStudyResponse.status,
            type: FetchResultType.Success,
            data: sortedFieldsOfStudy,
        };
    } catch (error) {
        console.error('[getManualFieldsOfStudyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
