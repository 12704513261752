import styled, { css } from 'styled-components';

export const Label = styled('label')(props => css`
    display: block;
    ${props.theme.fonts.bold};
    color: ${props.theme.colors.text};
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`);

export const Error = styled('p')`
    color: ${props => props.theme.colors.alert};
    font-size: 12px;
    line-height: 1; 
    overflow: hidden; 
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: height 0.15s ease-out,
                padding 0.15s ease-out;
    
    ${props => props.errors && `
        height: 22px;
        padding-top: 5px;
        padding-bottom: 5px;
    `}
`;

export const Requirement = styled('p')`
    color: ${props => props.theme.colors.text};
    height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    text-align: right;
    line-height: 1; 
    overflow: hidden; 
`;

export const OptionalLabel = styled.span`
    float: right;
    color: ${props => props.theme.colors.muted};
    font-weight: normal;
    font-size: 12px;
`;

export const EditorWrapper = styled.div(props => css`
    .ck-content {
        min-height: 250px;
    }
    
    ${props.errors && css`
        border: 2px solid ${props.theme.colors.alert};
        border-color: ${props.theme.colors.alert};
    `}

    ul {
        margin: ${props.theme.space.sm} 0 0 25px;
        margin-left: 25px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    ol {
        margin: ${props.theme.space.sm} 0 0 25px;
        margin-left: 25px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    .ck-placeholder:before {
        white-space: pre;
        color: ${props.theme.colors.placeholder} !important;
    }
    
    h2 {
        ${props.theme.fonts.heading};
        font-size: ${props.theme.fontSizes.lg};
        line-height: 1.2em;
        letter-spacing: 0.01875em;
        color: ${props.theme.colors.primary};
        margin-bottom: ${props.theme.space.xs};
    }
    
    p {
        margin-bottom: 1em;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
`);
