import React, { FC, ReactElement } from 'react';

import { generateIdArray } from '@createnl/pagination/dist/helpers/array';

import { trans } from '../../../../../helpers/trans';

import './SkillsFormTips.scss';

interface SkillsFormTipsProps {
    className?: string;
}

const SkillsFormTips: FC<SkillsFormTipsProps> = ({
    className = '',
}): ReactElement => {
    const tips = generateIdArray(4);

    return (
        <aside className={`skills-form-tips ${className}`}>
            <div className="skills-form-tips__list-wrapper">
                <h2 className="skills-form-tips__title">
                    {trans('formTips.skills.title')}
                </h2>

                <ul className="skills-form-tips__tip-list">
                    {tips.map(tip => (
                        <li key={tip} className="skills-form-tips__tip">
                            {trans(`formTips.skills.tips.${tip}`)}
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
};

export default SkillsFormTips;
