import { SkillsFormData } from '../../../../models/Skills';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMinimumArrayLength,
} from '../../../../services/ValidationService';

export type SkillsFormErrors = FormErrors<SkillsFormData>;

export const validateSkillsFormData = (
    formData: SkillsFormData,
): FormValidation<SkillsFormErrors> => {
    const combinedSkills = [...formData.skills, ...formData.escoSkills];

    const errors: SkillsFormErrors = {
        skills: validateMinimumArrayLength('skills', combinedSkills, 3),
    };

    return validateForm<SkillsFormErrors>(errors);
};
