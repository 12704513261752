import React, { FC, ReactElement } from 'react';

import { Card } from '../../components';
import { Loader } from '../../compositions';
import { trans } from '../../helpers/trans';
import { EmailAddressChange } from '../../models/EmailAddress';
import { EmailAddressChangeFailure, EmailAddressChangeSuccess } from './subcomponents';

import './ConfirmEmailAddressChange.scss';

interface ConfirmEmailAddressChangeProps {
    isLoading: boolean;
    isAuthenticated: boolean;
    isCandidate: boolean;
    emailAddressChange?: EmailAddressChange;
    error: string;
    onLoginClick: () => void;
    onLogoutClick: () => void;
    className?: string;
}

const ConfirmEmailAddressChange: FC<ConfirmEmailAddressChangeProps> = ({
    isLoading,
    isAuthenticated,
    isCandidate,
    emailAddressChange,
    error,
    onLoginClick,
    onLogoutClick,
    className = '',
}): ReactElement => (
    <Card className={`confirm-email-address-change ${className}`}>
        <h1 className="confirm-email-address-change__title">
            {trans('containers.confirmEmailAddressChange.title')}
        </h1>

        <p className="confirm-email-address-change__description">
            {trans('containers.confirmEmailAddressChange.description')}
        </p>

        {isLoading && (
            <Loader className="confirm-email-address-change__loader" />
        )}

        {error && (
            <EmailAddressChangeFailure
                isAuthenticated={isAuthenticated}
                isCandidate={isCandidate}
                onLoginClick={onLoginClick}
                className="confirm-email-address-change__result"
            />
        )}

        {!error && emailAddressChange && (
            <EmailAddressChangeSuccess
                isAuthenticated={isAuthenticated}
                emailAddressChange={emailAddressChange}
                onLoginClick={onLoginClick}
                onLogoutClick={onLogoutClick}
                className="confirm-email-address-change__result"
            />
        )}
    </Card>
);

export default ConfirmEmailAddressChange;
