import React from 'react';

import SettingsCompanyPage from '../../_old/app_talentz/app/pages/SettingsCompanyPage/SettingsCompanyPage';
import {
    ApplicationVideoAppointment,
    CandidateProfile,
    CompanyOverview,
    CompanyProfile,
    PlaceInternshipVacancyPage,
    PlaceJobVacancyPage,
    VacancyDetail,
} from '../../pages';

export const companyRoutePaths = {
    myCompanyProfile: () => '/bedrijf/mijn-profiel',
    companySettings: (tab = ':tab') => `/bedrijf/instellingen/${tab}`,

    companyOverview: () => '/bedrijf/mijn-overzicht',
    companyOverviewTab: (tab = ':tab') => `/bedrijf/mijn-overzicht/${tab}`,
    companyOverviewApplicants: (uuid = ':uuid') => `/bedrijf/mijn-overzicht/sollicitanten/${uuid}`,
    companyOverviewMatches: (uuid = ':uuid') => `/bedrijf/mijn-overzicht/matches-zoeken/${uuid}`,
    companyOverviewMessages: (uuid = ':uuid') => `/bedrijf/mijn-overzicht/berichten/${uuid}`,

    myVacancyDetail: (vacancyUuid = ':vacancyUuid') => `/bedrijf/mijn-vacatures/${vacancyUuid}`,
    myVacancyApplication: (vacancyUuid = ':vacancyUuid', candidateUuid = ':candidateUuid', applicationUuid = ':applicationUuid') => (
        `/bedrijf/mijn-vacatures/${vacancyUuid}/sollicitaties/${candidateUuid}/${applicationUuid}`
    ),
    myVacancyAppointment: (vacancyUuid = ':vacancyUuid', applicationUuid = ':applicationUuid', appointmentUuid = ':appointmentUuid') => (
        `/bedrijf/mijn-vacatures/${vacancyUuid}/sollicitaties/${applicationUuid}/afspraak/${appointmentUuid}`
    ),
    myVacancyMatch: (vacancyUuid = ':vacancyUuid', candidateUuid = ':candidateUuid') => (
        `/bedrijf/mijn-vacatures/${vacancyUuid}/matches/${candidateUuid}`
    ),

    candidateProfile: (candidateUuid = ':candidateUuid') => `/kandidaat/profiel/${candidateUuid}`,

    createInternshipVacancy: (step = ':step') => `/bedrijf/vacature-plaatsen/stage/${step}`,
    editInternshipVacancy: (step = ':step', vacancyUuid = ':vacancyUuid') => `/bedrijf/vacature-plaatsen/stage/${step}/${vacancyUuid}`,

    createJobVacancy: (step = ':step') => `/bedrijf/vacature-plaatsen/baan/${step}`,
    editJobVacancy: (step = ':step', vacancyUuid = ':vacancyUuid') => `/bedrijf/vacature-plaatsen/baan/${step}/${vacancyUuid}`,
};

export const companyRoutes = [
    {
        path: companyRoutePaths.myCompanyProfile(),
        // Key needed in order for company to navigate to own profile from other company profile
        element: <CompanyProfile key="my-company-profile" />,
    },
    {
        path: companyRoutePaths.companySettings(),
        element: <SettingsCompanyPage />,
    },
    {
        path: companyRoutePaths.companyOverview(),
        element: <CompanyOverview />,
        children: [
            {
                path: ':tab',
                element: <CompanyOverview />,
                children: [
                    {
                        path: ':uuid',
                        element: <CompanyOverview />,
                    },
                ],
            },
        ],
    },
    {
        path: companyRoutePaths.myVacancyDetail(),
        element: <VacancyDetail />,
    },
    {
        path: companyRoutePaths.myVacancyApplication(),
        element: <CandidateProfile />,
    },
    {
        path: companyRoutePaths.myVacancyAppointment(),
        element: <ApplicationVideoAppointment />,
    },
    {
        path: companyRoutePaths.myVacancyMatch(),
        element: <CandidateProfile />,
    },
    {
        path: companyRoutePaths.candidateProfile(),
        element: <CandidateProfile />,
    },
    {
        path: companyRoutePaths.createInternshipVacancy(),
        element: <PlaceInternshipVacancyPage />,
    },
    {
        path: companyRoutePaths.editInternshipVacancy(),
        element: <PlaceInternshipVacancyPage />,
    },
    {
        path: companyRoutePaths.createJobVacancy(),
        element: <PlaceJobVacancyPage />,
    },
    {
        path: companyRoutePaths.editJobVacancy(),
        element: <PlaceJobVacancyPage />,
    },
];
