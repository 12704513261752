import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { display, space, width } from 'styled-system';

const LogoElement = styled.img(props => css`
    position: relative;
    display: block;
    z-index: ${props.zIndex || 0 };
    ${space}
    ${width}
    ${display}
`);

const Logo = props => {
    const { zOnly, ...leftoverProps } = props;

    const logo = zOnly ? '/images/logo-white-small.svg' : '/images/logo-white-full.svg';

    return (
        <LogoElement
            src={logo}
            alt={process.env.REACT_APP_APP_TITLE}
            {...leftoverProps}
        />
    );
};

Logo.propTypes = {
    dark: PropTypes.bool,
    zOnly: PropTypes.bool,
};

Logo.defaultProps = {
    dark: false,
    zOnly: false,
};

export default Logo;
