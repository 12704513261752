import React, { CSSProperties, FC, ReactElement } from 'react';

import './StepProgressIndicator.scss';

interface StepProgressIndicatorProps {
    stepAmount: number;
    currentStep: number;
    className?: string;
}

const StepProgressIndicator: FC<StepProgressIndicatorProps> = ({
    stepAmount,
    currentStep,
    className = '',
}): ReactElement => {
    const percentage = (currentStep / stepAmount) * 100;

    const cssVariables = {
        '--step-progress-indicator-width': `${percentage}%`,
    } as CSSProperties;

    return (
        <div className={`step-progress-indicator ${className}`}>
            <div style={cssVariables} className="step-progress-indicator__progress" />
        </div>
    );
};

export default StepProgressIndicator;
