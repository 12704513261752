import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import getIncluded from '../japi/getIncluded';
import getMultipleIncluded from '../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { CompetenciesResource, Competency, transformToCompetency } from '../models/Competencies';
import {
    EscoOccupation,
    EscoOccupationSkillsResource,
    EscoOccupationsResource,
    EscoSkillsResource,
    transformToEscoOccupation,
    transformToEscoSkill,
} from '../models/Esco';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { Skill } from '../models/Skills';

export const getEscoOccupationsApiCall = async (): Promise<FetchResult<EscoOccupation[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/esco-occupations`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<EscoOccupationsResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const escoOccupations = doc.data.map(transformToEscoOccupation);
        const sortedEscoOccupations = escoOccupations.sort((occupation, comparisonOccupation) => occupation.name.localeCompare(comparisonOccupation.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedEscoOccupations,
        };
    } catch (error) {
        console.error('[getEscoOccupationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getSuggestedSkillsApiCall = async (escoOccupationUuid: string): Promise<FetchResult<Skill[], string>> => {
    try {
        const includes = ['escoOccupationSkills', 'escoOccupationSkills.skill'].join(',');
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/esco-occupations/${escoOccupationUuid}?include=${includes}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<EscoOccupationsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const includedEscoOccupationSkills = getMultipleIncluded<EscoOccupationSkillsResource>(doc, doc.data, 'escoOccupationSkills');
        const escoSkills = includedEscoOccupationSkills
            ? includedEscoOccupationSkills
                .filter(skills => !skills.attributes.optional)
                .map(occupationSkillResource => {
                    const includedSkillResource = getIncluded<EscoSkillsResource>(doc, occupationSkillResource, 'skill');

                    return includedSkillResource
                        ? transformToEscoSkill(includedSkillResource)
                        : undefined;
                })
                .filter(Boolean)
            : [];

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: escoSkills as Skill[],
        };
    } catch (error) {
        console.error('[getSuggestedSkillsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getSuggestedCompetenciesApiCall = async (escoOccupationUuid: string): Promise<FetchResult<Competency[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/competencies/esco_occupation/${escoOccupationUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<CompetenciesResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc.data.map(transformToCompetency),
        };
    } catch (error) {
        console.error('[getSuggestedCompetenciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
