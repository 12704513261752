import React, { FC, ReactElement, useState } from 'react';

import { Button, Icon } from '../../../../../components';
import {
    DetailCard,
    LinkIconButton,
    Modal,
    ModalContent,
} from '../../../../../compositions';
import {
    dateIsWithinInterval,
    formatAppointment,
    formatDate,
    subtractMinutes,
} from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { ApplicationAppointmentFormData, ApplicationAppointmentStatus, ApplicationAppointmentType } from '../../../../../models/ApplicationAppointments';
import { ApplicationInvite } from '../../../../../models/ApplicationInvites';
import { ApplicationRejectionFormData } from '../../../../../models/ApplicationRejections';
import { Application } from '../../../../../models/Applications';
import { RoutePaths } from '../../../../../routes';
import { ApplicationRejectionForm, AppointmentForm } from '../../../..';

import './ApplicationStatusAccepted.scss';

interface ApplicationStatusAcceptedProps {
    isLoading: boolean;
    isCandidate?: boolean;
    application: Application;
    activeInvite: ApplicationInvite;
    onChangeInvite: (applicationUuid: string, formData: ApplicationAppointmentFormData) => void;
    onDeclineInvite?: (applicationInvite: ApplicationInvite) => void;
    onRejectApplication?: (applicationUuid: string, formData: ApplicationRejectionFormData) => void;
    className?: string;
}

const ApplicationStatusAccepted: FC<ApplicationStatusAcceptedProps> = ({
    isLoading,
    isCandidate,
    application,
    activeInvite,
    onChangeInvite,
    onDeclineInvite,
    onRejectApplication,
    className = '',
}): ReactElement => {
    const [changeAppointmentModalIsOpen, setChangeAppointmentModalIsOpen] = useState<boolean>(false);
    const [rejectApplicationModalIsOpen, setRejectApplicationModalIsOpen] = useState<boolean>(false);

    const openChangeAppointmentModal = (): void => setChangeAppointmentModalIsOpen(true);
    const closeChangeAppointmentModal = (): void => setChangeAppointmentModalIsOpen(false);

    const openRejectApplicationModal = (): void => setRejectApplicationModalIsOpen(true);
    const closeRejectApplicationModal = (): void => setRejectApplicationModalIsOpen(false);

    const description = isCandidate
        ? trans('containers.applicationStatusCard.accepted.description.candidate', {
            company: application?.vacancy.companyName,
        })
        : trans('containers.applicationStatusCard.accepted.description.company', {
            candidate: application?.applicant.fullName,
        });

    const activeAppointment = activeInvite.appointments
        .find(appointment => appointment.status === ApplicationAppointmentStatus.accepted);

    const appointmentIsNow = activeAppointment
        ? dateIsWithinInterval({
            start: activeAppointment.startDate,
            end: activeAppointment.endDate,
        })
        : false;

    const hasVideoMeeting = activeAppointment?.type === ApplicationAppointmentType.jitsi;
    const videoMeetingPath = isCandidate
        ? RoutePaths.appointment(application.uuid, activeAppointment?.uuid)
        : RoutePaths.myVacancyAppointment(application.vacancy.uuid, application.uuid, activeAppointment?.uuid);

    const videoMeetingJoinMargin = 30;
    const videoMeetingIsOpen = activeAppointment
        ? dateIsWithinInterval({
            start: subtractMinutes(activeAppointment.startDate, videoMeetingJoinMargin),
            end: activeAppointment.endDate,
        })
        : false;

    const handleChangeInvite = (formData: ApplicationAppointmentFormData): void => {
        closeChangeAppointmentModal();
        onChangeInvite(application.uuid, formData);
    };

    const handleDeclineInvite = (): void => {
        if (onDeclineInvite) {
            onDeclineInvite(activeInvite);
        }
    };

    const handleRejectApplication = (formData: ApplicationRejectionFormData): void => {
        closeRejectApplicationModal();

        if (onRejectApplication) {
            onRejectApplication(application.uuid, formData);
        }
    };

    return (
        <DetailCard
            title={trans('containers.applicationStatusCard.accepted.title')}
            className={`application-status-accepted ${className}`}
        >
            <p className="application-status-accepted__description">
                {description}
            </p>

            {activeAppointment && (
                <div className="application-status-accepted__appointment">
                    <Icon name="calendar" className="application-status-accepted__appointment-icon" />

                    <p className="application-status-accepted__appointment-label">
                        {appointmentIsNow
                            ? trans('containers.applicationStatusCard.accepted.appointmentIsNow', {
                                endTime: formatDate(activeAppointment.endDate, 'HH:mm'),
                            })
                            : formatAppointment(activeAppointment.startDate, activeAppointment.endDate)}
                    </p>
                </div>
            )}

            {hasVideoMeeting && (
                <div className="application-status-accepted__video-meeting-wrapper">
                    <p className="application-status-accepted__video-meeting-description">
                        {trans('containers.applicationStatusCard.accepted.videoAppointmentDescription', {
                            minutes: videoMeetingJoinMargin,
                        })}
                    </p>

                    <LinkIconButton
                        icon="video"
                        to={videoMeetingPath}
                        disabled={!videoMeetingIsOpen}
                        text={trans('containers.applicationStatusCard.accepted.button.startVideoMeeting')}
                        className="application-status-accepted__video-meeting-button"
                    />

                    <p className="application-status-accepted__or-divider">
                        {trans('basic.or')}
                    </p>
                </div>
            )}

            <div className="application-status-accepted__button-wrapper">
                <Button
                    text={trans('containers.applicationStatusCard.accepted.button.change')}
                    disabled={isLoading}
                    onClick={openChangeAppointmentModal}
                    className="application-status-accepted__button"
                />

                {isCandidate ? (
                    <Button
                        text={trans('containers.applicationStatusCard.accepted.button.declineInvite')}
                        disabled={isLoading}
                        onClick={handleDeclineInvite}
                        className="application-status-accepted__button"
                    />
                ) : (
                    <Button
                        text={trans('containers.applicationStatusCard.accepted.button.rejectApplication')}
                        disabled={isLoading}
                        onClick={openRejectApplicationModal}
                        className="application-status-accepted__button"
                    />
                )}
            </div>

            {changeAppointmentModalIsOpen && (
                <Modal onClose={closeChangeAppointmentModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.accepted.changeAppointmentModal.title')}>
                        <AppointmentForm
                            isCandidate={isCandidate}
                            onSubmit={handleChangeInvite}
                            onCancel={closeChangeAppointmentModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {rejectApplicationModalIsOpen && (
                <Modal onClose={closeRejectApplicationModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.accepted.rejectApplicationModal.title')}>
                        <ApplicationRejectionForm
                            onSubmit={handleRejectApplication}
                            onCancel={closeRejectApplicationModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default ApplicationStatusAccepted;
