/* eslint-disable camelcase */
import { CompanyDataFormErrors, CompanyDataFormValues } from './CompanyService';
import { EmployeeDataFormValues } from './EmployeeService';

export interface RegisterCompanyFormErrors extends CompanyDataFormErrors, EmployeeDataFormValues {}

export interface RegisterCompanyApiValues {
    address: string;
    amount_of_employees_id: string;
    city: string;
    first_name: string;
    gender: string;
    insertion: string;
    kvk_number: string;
    kvk_data?: string;
    last_name: string;
    latitude: string;
    longitude: string;
    name: string;
    phone_number: string;
    position: string;
    province: string;
    street_number: string;
    street_number_extension: string;
    website: string;
    zipcode: string;
}

export interface RegisterCompanyApiErrors {
    name?: string[];
    website?: string[];
    amount_of_employees_id?: string[];
    address?: string[];
    zipcode?: string[];
    street_number?: string;
    street_number_extension?: string[];
    first_name?: string[];
    insertion?: string[];
    last_name?: string[];
    phone_number?: string[];
    position?:string[];
}

export function transformFormValuesToRegisterCompanyApiValues(
    company: CompanyDataFormValues,
    employee: EmployeeDataFormValues,
): RegisterCompanyApiValues {
    return {
        address: company.address,
        amount_of_employees_id: company.amountOfEmployeesId || '',
        city: company.city,
        first_name: employee.firstName,
        gender: employee.gender,
        insertion: employee.insertion || '',
        kvk_number: company.kvkNumber,
        ...(company.kvkData && { kvk_data: JSON.stringify(company.kvkData) }),
        last_name: employee.lastName,
        latitude: company.latitude || '',
        longitude: company.longitude || '',
        name: company.companyName,
        phone_number: employee.phoneNumber,
        position: employee.position,
        province: company.province,
        street_number: company.streetNumber,
        street_number_extension: company.extension || '',
        website: company.website || '',
        zipcode: company.zipcode,
    };
}
/* eslint-enable camelcase */
