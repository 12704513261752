import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { InputLabel } from '../../../components';
import { SearchableOption } from '../../../types';
import { SearchableValueInput, Tag } from '../..';
import { SearchableValueInputProps } from '../SearchableValueInput/SearchableValueInput';

import './SearchableMultiValueInput.scss';

interface SearchableMultiValueInputProps extends Omit<SearchableValueInputProps, 'value' | 'onChange'> {
    value: SearchableOption[];
    onChange: (options: SearchableOption[]) => void;
    className?: string;
}

const SearchableMultiValueInput: FC<SearchableMultiValueInputProps> = ({
    label,
    hideLabel,
    required,
    value,
    options,
    onChange,
    className = '',
    ...searchableValueInputProps
}): ReactElement => {
    const [query, setQuery] = useState<string>('');
    const [valueList, setValueList] = useState<string[]>([]);

    useEffect((): void => {
        setValueList(value.map(item => item.value));
    }, [value]);

    const handleAddValue = (optionToAdd: SearchableOption): void => {
        const newValue = [...value, optionToAdd];

        onChange(newValue);
    };

    const handleChange = (option: SearchableOption): void => {
        setQuery(option.label);

        if (option.value) {
            setQuery('');
            handleAddValue(option);
        }
    };

    return (
        <div className={`searchable-multi-value-input ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="searchable-multi-value-input__label"
                />
            )}

            {value.length > 0 && (
                <ul className="searchable-multi-value-input__result-list">
                    {value.map(item => {
                        const handleRemoveValue = (): void => {
                            const newValue = value.filter(option => option.value !== item.value);

                            onChange(newValue);
                        };

                        return (
                            <li key={item.value} className="searchable-multi-value-input__result-item">
                                <Tag
                                    isActive
                                    text={item.label}
                                    onClick={handleRemoveValue}
                                />
                            </li>
                        );
                    })}
                </ul>
            )}

            <SearchableValueInput
                {...searchableValueInputProps}
                label={label}
                hideLabel
                required={required}
                value={query}
                options={options.filter(option => !valueList.includes(option.value))}
                onChange={handleChange}
                className="searchable-multi-value-input__input"
            />
        </div>
    );
};

export default SearchableMultiValueInput;
