import React, { FC, ReactElement } from 'react';

import PasswordForm from '../../../_old/app_talentz/app/components/forms/PasswordForm';
import DeleteAccount from '../../../_old/app_talentz/app/components/settings/DeleteAccount';
import { User } from '../../../_old/app_talentz/models/User';
import { Card } from '../../../components';
import { trans } from '../../../helpers/trans';
import { EmailAddressChange, EmailAddressChangeFormData } from '../../../models/EmailAddress';
import { EmailAddressChangeForm } from '../..';

import './AccountSettings.scss';

// TODO: Refactor props of legacy components to PACO
interface AccountSettingsProps {
    isLoading: boolean;
    isLLOCandidate?: boolean;
    user?: User;
    emailAddressChange?: EmailAddressChange;
    resetPasswordIsLoading: boolean;
    deleteAccountIsLoading: boolean;
    connectedProviders: any[];
    resetPasswordErrors: Record<string, unknown>;
    deleteAccountErrors: Record<string, string[]>;
    onResetPassword: (resetPasswordFormData: any) => void;
    onChangeEmailAddress: (emailAddressChangeFormData: EmailAddressChangeFormData) => void;
    onDeleteAccount: (deleteAccountFormData: any) => void;
    className?: string;
}

const AccountSettings: FC<AccountSettingsProps> = ({
    isLoading,
    isLLOCandidate,
    user,
    emailAddressChange,
    resetPasswordIsLoading,
    deleteAccountIsLoading,
    connectedProviders,
    resetPasswordErrors,
    deleteAccountErrors,
    onResetPassword,
    onChangeEmailAddress,
    onDeleteAccount,
    className = '',
}): ReactElement => {
    const isAllowedToChangeAccountData = !connectedProviders.includes('linkedin') && !isLLOCandidate;

    return (
        <div className={`account-settings ${className}`}>
            {isAllowedToChangeAccountData && (
                <Card className="account-settings__card">
                    {/* TODO: Refactor this legacy component to PACO */}
                    <PasswordForm
                        resetPassword
                        isChangingPassword
                        loading={resetPasswordIsLoading}
                        errors={resetPasswordErrors}
                        onSubmit={onResetPassword}
                    />
                </Card>
            )}

            {isAllowedToChangeAccountData && (
                <Card className="account-settings__card">
                    <h2 className="account-settings__card-title">
                        {trans('containers.accountSettings.email.title')}
                    </h2>

                    <p className="account-settings__card-description">
                        {trans('containers.accountSettings.email.description')}
                    </p>

                    <EmailAddressChangeForm
                        isLoading={isLoading}
                        currentEmailAddress={user?.email}
                        emailAddressChange={emailAddressChange}
                        onSubmit={onChangeEmailAddress}
                        className="account-settings__card-form"
                    />
                </Card>
            )}

            <Card className="account-settings__card">
                {/* TODO: Refactor this legacy component to PACO */}
                <DeleteAccount
                    loading={deleteAccountIsLoading}
                    connectedProviders={connectedProviders}
                    errors={deleteAccountErrors}
                    deleteUser={onDeleteAccount}
                />
            </Card>
        </div>
    );
};

export default AccountSettings;
