import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { trans } from '../../helpers/trans';
import { RoutePaths } from '../../routes';
import { LinkIconButton } from '..';

import './BlogBreadcrumbs.scss';

interface BlogBreadcrumbsProps {
    category: string;
    blogTitle?: string;
    className?: string;
}

const BlogBreadcrumbs: FC<BlogBreadcrumbsProps> = ({
    category,
    blogTitle,
    className = '',
}): ReactElement => (
    <div className={`blog-breadcrumbs ${className}`}>
        <LinkIconButton
            icon="arrow-left"
            to={RoutePaths.blog()}
            text={trans('blog.breadcrumb.back')}
            className="blog-breadcrumbs__back-button"
        />

        <ul className="blog-breadcrumbs__list">
            <li className="blog-breadcrumbs__item">
                <Link to={RoutePaths.blog()} className="blog-breadcrumbs__link">
                    {trans('blog.breadcrumb.blog')}
                </Link>
            </li>

            <li className="blog-breadcrumbs__item">
                {blogTitle ? (
                    <Link to={RoutePaths.blogCategory(category)} className="blog-breadcrumbs__link">
                        {category}
                    </Link>
                ) : category}
            </li>

            {blogTitle && (
                <li className="blog-breadcrumbs__item">
                    {blogTitle}
                </li>
            )}
        </ul>
    </div>
);

export default BlogBreadcrumbs;
