import { Candidate } from '../models/Candidate';
import { isFutureDate } from './date';
import { sortByEndDate } from './sort';

export const retrieveLatestEducationLevel = (candidate?: Candidate): string => {
    if (!candidate || candidate?.schoolExperiences.length === 0) return '';

    const sortedSchoolExperiences = candidate.schoolExperiences
        .filter(schoolExperience => !isFutureDate(schoolExperience.endDate))
        .sort(sortByEndDate);

    return sortedSchoolExperiences.length > 0
        ? sortedSchoolExperiences[0].educationLevel
        : '';
};
