import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import TextEditor from '../../../_old/components/form/TextEditor';
import { FormActionButtons } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { ApplicationRejectionFormData } from '../../../models/ApplicationRejections';
import { FormProps } from '../../../types';
import { ApplicationRejectionFormErrors, validateApplicationRejectionFormData } from './validations';

import './ApplicationRejectionForm.scss';

interface ApplicationRejectionFormProps extends FormProps<ApplicationRejectionFormData> {
    className?: string;
}

const ApplicationRejectionForm: FC<ApplicationRejectionFormProps> = ({
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<ApplicationRejectionFormErrors>({});

    const [message, setMessage] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = { message };

        const [localErrors, hasErrors] = validateApplicationRejectionFormData(formData);

        setFormErrors(localErrors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`application-rejection-form ${className}`}>
            <div className="application-rejection-form__input-wrapper">
                <TextEditor
                    name="decline-message"
                    label={trans('forms.applicationRejection.input.message')}
                    placeholder={trans('forms.applicationRejection.placeholder.message')}
                    defaultValue={message}
                    errors={formErrors.message ? [formErrors.message] : undefined}
                    onChange={setMessage}
                />
            </div>

            <FormActionButtons
                submitIcon="check"
                submitText={trans('forms.applicationRejection.button.submit')}
                onCancelClick={onCancel}
                className="application-rejection-form__action-buttons"
            />
        </form>
    );
};

export default ApplicationRejectionForm;
