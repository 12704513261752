import React, { FC, PropsWithChildren, ReactElement } from 'react';

import { RootPortal } from '../../components';
import useDeviceWidth from '../../hooks/useDeviceWidth';

import './FixedFooter.scss';

interface FixedFooterProps {
    onlySmallScreen?: boolean;
    className?: string;
}

const FixedFooter: FC<PropsWithChildren<FixedFooterProps>> = ({
    onlySmallScreen,
    className = '',
    children,
}): ReactElement | null => {
    const { isMobile } = useDeviceWidth();

    if (onlySmallScreen && !isMobile) {
        return null;
    }

    return (
        <RootPortal>
            <div className={`fixed-footer ${className}`}>
                {children}
            </div>
        </RootPortal>
    );
};

export default FixedFooter;
