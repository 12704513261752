import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ProgressRefButton, Skeleton } from '../../../components';
import {
    DetailCard,
    IconButton,
    Modal,
    Tag,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { EscoOccupation } from '../../../models/Esco';
import { Skill, SkillsFormData } from '../../../models/Skills';
import { SkillsForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';
import { SkillsFormTips } from './subcomponents';

import './SkillsCard.scss';

interface SkillsCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    suggestionsIsLoading: boolean;
    isEditable?: boolean;
    skills: Skill[];
    skillOptions: Skill[];
    suggestedSkills: Skill[];
    occupationOptions: EscoOccupation[];
    error: string;
    onSelectOccupation?: (occupation: EscoOccupation) => void;
    onClearSuggestions?: () => void;
    onEdit?: (skillsFormData: SkillsFormData) => void;
    className?: string;
}

const SkillsCard: FC<SkillsCardProps> = ({
    activationRef,
    isLoading,
    suggestionsIsLoading,
    isEditable,
    skills,
    skillOptions,
    suggestedSkills,
    occupationOptions,
    error,
    onSelectOccupation,
    onClearSuggestions,
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    useEffect((): void => {
        if (!editModalIsOpen && onClearSuggestions) {
            onClearSuggestions();
        }
    }, [editModalIsOpen]);

    const handleEditSkills = (skillsFormData: SkillsFormData): void => {
        if (onEdit) {
            onEdit(skillsFormData);
        }

        closeEditModal();
    };

    const handleDeleteSkills = (): void => {
        if (onEdit) {
            onEdit({
                skills: [],
                escoSkills: [],
            });
        }
    };

    const description = isEditable
        ? trans('candidateProfile.skills.description.candidate')
        : trans('candidateProfile.skills.description.company');

    const nullState = isEditable
        ? trans('candidateProfile.skills.nullState.candidate')
        : trans('candidateProfile.skills.nullState.company');

    return (
        <DetailCard
            isLoading={isLoading}
            isEditable={isEditable && skills.length > 0}
            title={trans('candidateProfile.skills.title')}
            description={description}
            onEditClick={openEditModal}
            onDeleteClick={handleDeleteSkills}
            className={`skills-card ${className}`}
        >
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            {isLoading && (
                <Skeleton className="skills-card__skeleton" />
            )}

            {!isLoading && skills.length === 0 && (
                <div className="skills-card__null-state">
                    <p>{nullState}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('actions.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="skills-card__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && skills.length > 0 && (
                <ul className="skills-card__tag-list">
                    {skills.map(skill => (
                        <li key={skill.id} className="skills-card__tag-item">
                            <Tag isActive text={skill.name} />
                        </li>
                    ))}
                </ul>
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal} cardClassName="skills-card__modal-card">
                    <div className="skills-card__form-wrapper">
                        <h2 className="skills-card__form-title">
                            {trans('candidateProfile.skills.modal.title')}
                        </h2>

                        <SkillsForm
                            isLoading={suggestionsIsLoading}
                            skills={skills}
                            options={skillOptions}
                            suggestedSkills={suggestedSkills}
                            occupationOptions={occupationOptions}
                            error={error}
                            onSelectOccupation={onSelectOccupation}
                            onCancel={closeEditModal}
                            onSubmit={handleEditSkills}
                            className="skills-card__form"
                        />
                    </div>

                    <SkillsFormTips className="skills-card__form-tips" />
                </Modal>
            )}
        </DetailCard>
    );
};

export default SkillsCard;
