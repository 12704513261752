import React, { FC, ReactElement, useState } from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { trans } from '../../helpers/trans';
import { Application } from '../../models/Applications';
import { transformVacancyToVacanciesInfo, VacanciesInfo } from '../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../models/NewestVacancy';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import {
    FavouriteButton,
    IconButton,
    LinkIconButton,
    Modal,
    ModalContent,
    VacancyArchiveModal,
    VacancyDeleteModal,
    VacancyUnarchiveModal,
} from '..';

import './VacancyControls.scss';

interface VacancyControlsProps {
    isArchived?: boolean;
    userRole: RoleType;
    userFromCompany?: boolean;
    vacancy: NewestVacancy;
    isFavourite?: boolean;
    favouriteIsLoading?: boolean;
    onFavouriteClick?: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    applications?: Application[];
    applicationsIsLoading?: boolean;
    onFetchApplications?: (vacancyUuid: string) => void;
    onClearApplications?: () => void;
    onArchiveClick?: (vacancyUuid: string, applicationUuids: string[]) => void;
    onUnarchiveClick?: (vacancyUuid: string) => void;
    onDeleteClick?: (vacancyUuid: string) => void;
    className?: string;
}

const VacancyControls: FC<VacancyControlsProps> = ({
    isArchived,
    userRole,
    userFromCompany,
    vacancy,
    isFavourite,
    favouriteIsLoading,
    onFavouriteClick,
    applications,
    applicationsIsLoading,
    onFetchApplications,
    onClearApplications,
    onArchiveClick,
    onUnarchiveClick,
    onDeleteClick,
    className = '',
}): ReactElement => {
    const [archiveModalIsOpen, setArchiveModalIsOpen] = useState<boolean>(false);
    const [unarchiveModalIsOpen, setUnarchiveModalIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    const openArchiveModal = (): void => setArchiveModalIsOpen(true);
    const closeArchiveModal = (): void => setArchiveModalIsOpen(false);
    const openUnarchiveModal = (): void => setUnarchiveModalIsOpen(true);
    const closeUnarchiveModal = (): void => setUnarchiveModalIsOpen(false);
    const openDeleteModal = (): void => setDeleteModalIsOpen(true);
    const closeDeleteModal = (): void => setDeleteModalIsOpen(false);

    const mayHaveFavourites = userRole === RoleType.student || userRole === RoleType.jobSeeker;

    const editLink = vacancy.type === VacancyType.internship
        ? RoutePaths.editInternshipVacancy('1', vacancy.uuid)
        : RoutePaths.editJobVacancy('1', vacancy.uuid);

    const handleFavouriteClick = (shouldAddFavourite: boolean): void => {
        if (onFavouriteClick) {
            const favouriteVacancy = transformVacancyToVacanciesInfo(vacancy);
            onFavouriteClick(favouriteVacancy, shouldAddFavourite);
        }
    };

    const handleArchiveVacancy = (applicationUuids: string[]): void => {
        if (onArchiveClick) {
            onArchiveClick(vacancy.uuid, applicationUuids);
            closeArchiveModal();
        }
    };

    const handleUnarchiveVacancy = (): void => {
        if (onUnarchiveClick) {
            onUnarchiveClick(vacancy.uuid);
            closeUnarchiveModal();
        }
    };

    const handleDeleteVacancy = (): void => {
        if (onDeleteClick) {
            onDeleteClick(vacancy.uuid);
            closeDeleteModal();
        }
    };

    return (
        <div className={`vacancy-controls ${className}`}>
            {mayHaveFavourites && (
                <FavouriteButton
                    isActive={isFavourite}
                    isLoading={favouriteIsLoading}
                    onClick={handleFavouriteClick}
                />
            )}

            {userFromCompany && !isArchived && (
                <>
                    <LinkIconButton
                        to={editLink}
                        icon="pencil"
                        text={trans('actions.edit')}
                        hideLabel
                        className="vacancy-controls__button"
                    />
                    {onArchiveClick && (
                        <IconButton
                            icon="archive"
                            text={trans('actions.archive')}
                            hideLabel
                            onClick={openArchiveModal}
                            className="vacancy-controls__button"
                        />
                    )}
                </>
            )}

            {userFromCompany && isArchived && (
                <>
                    {onUnarchiveClick && (
                        <IconButton
                            icon="unarchive"
                            text={trans('actions.unarchive')}
                            hideLabel
                            onClick={openUnarchiveModal}
                            className="vacancy-controls__button"
                        />
                    )}
                    {onDeleteClick && (
                        <IconButton
                            icon="delete"
                            text={trans('actions.deletePermanently')}
                            hideLabel
                            onClick={openDeleteModal}
                            className="vacancy-controls__button"
                        />
                    )}
                </>
            )}

            {archiveModalIsOpen && (
                <Modal onClose={closeArchiveModal}>
                    <ModalContent title={trans('myVacancies.modal.archive.title')}>
                        <VacancyArchiveModal
                            isLoading={applicationsIsLoading}
                            vacancy={vacancy}
                            applications={applications}
                            onFetchApplications={onFetchApplications}
                            onClearApplications={onClearApplications}
                            onCancel={closeArchiveModal}
                            onConfirm={handleArchiveVacancy}
                        />
                    </ModalContent>
                </Modal>
            )}

            {unarchiveModalIsOpen && (
                <Modal onClose={closeUnarchiveModal}>
                    <ModalContent title={trans('myVacancies.modal.unarchive.title')}>
                        <VacancyUnarchiveModal
                            vacancy={vacancy}
                            onConfirm={handleUnarchiveVacancy}
                            onCancel={closeUnarchiveModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {deleteModalIsOpen && (
                <Modal onClose={closeDeleteModal}>
                    <ModalContent title={trans('myVacancies.modal.delete.title')}>
                        <VacancyDeleteModal
                            vacancy={vacancy}
                            onConfirm={handleDeleteVacancy}
                            onCancel={closeDeleteModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default VacancyControls;
