import React, { CSSProperties, FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Label } from '../../../../components';
import { formatDate } from '../../../../helpers/date';
import { trans } from '../../../../helpers/trans';
import { BlogArticle } from '../../../../models/Blog';
import { RoutePaths } from '../../../../routes';

import './BlogHeader.scss';

interface BlogHeaderProps {
    content?: BlogArticle;
    className?: string;
}

const BlogHeader: FC<BlogHeaderProps> = ({
    content,
    className = '',
}): ReactElement | null => {
    if (!content) return null;

    const cssVariables = {
        '--blog-header-poster-url': `url(${content.poster.src})`,
    } as CSSProperties;

    const updatedAt = formatDate(content.updatedAt, 'd MMMM, yyyy');

    return (
        <header style={cssVariables} className={`blog-header ${className}`}>
            <div className="blog-header__poster">
                <div className="blog-header__content-wrapper">
                    <ul className="blog-header__category-list">
                        {content.categories.map(category => (
                            <li key={category} className="blog-header__category-item">
                                <Link to={RoutePaths.blogCategory(category)}>
                                    <Label text={category} />
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <h1 className="blog-header__title">
                        {content.title}
                    </h1>

                    {content.readTime && (
                        <p className="blog-header__read-time">
                            {trans('blog.detail.readTime', {
                                minutes: content.readTime,
                            })}
                        </p>
                    )}

                    <p className="blog-header__updated-date">
                        {trans('blog.detail.updatedAt', { updatedAt })}
                    </p>
                </div>

                <div className="blog-header__poster-gradient" />
            </div>

            <div className="blog-header__backdrop" />
        </header>
    );
};

export default BlogHeader;
