import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { alphabetNumber } from '../../constants';
import { divideArrayInColumns } from '../../helpers/array';
import { trans } from '../../helpers/trans';
import { EducationLevel } from '../../models/EducationLevels';
import { FieldOfStudy } from '../../models/FieldsOfStudy';
import { Province } from '../../models/Provinces';
import { Sector } from '../../models/Sectors';
import { InternshipVacancyIndexParams } from '../../models/VacancyIndex';
import {
    VacancyIndexBreadcrumbList,
    VacancyIndexEducationLevel,
    VacancyIndexEducationLevelLetter,
    VacancyIndexFieldOfStudy,
    VacancyIndexFieldOfStudyCity,
    VacancyIndexFieldOfStudyProvince,
    VacancyIndexProvince,
    VacancyIndexProvinceCity,
    VacancyIndexSector,
    VacancyIndexSectorCity,
    VacancyIndexSectorProvince,
    VacancyIndexTab,
} from './subcomponents';

import './VacancyIndex.scss';

interface InternshipVacancyIndexProps extends InternshipVacancyIndexParams {
    isLoading: boolean;
    educationLevelOptions: EducationLevel[];
    fieldOfStudyOptions: FieldOfStudy[];
    activeLetters: string[];
    sectorOptions: Sector[];
    provinceOptions: Province[];
    cityOptions: string[];
    className?: string;
}

const InternshipVacancyIndex: FC<InternshipVacancyIndexProps> = ({
    isLoading,
    educationLevel,
    letter,
    fieldOfStudy,
    sector,
    province,
    educationLevelOptions,
    fieldOfStudyOptions,
    activeLetters,
    sectorOptions,
    provinceOptions,
    cityOptions,
    className = '',
}): ReactElement => {
    const showInitialCategory = !(educationLevel || letter || fieldOfStudy || sector || province);

    const showEducationLevelCategory = educationLevel && !(letter || fieldOfStudy || sector || province);
    const showEducationLevelFieldOfStudyCategory = educationLevel && letter && !(fieldOfStudy || sector || province);
    const showEducationLevelProvinceCategory = educationLevel && letter && fieldOfStudy && !(sector || province);
    const showEducationLevelCityCategory = educationLevel && letter && fieldOfStudy && province && !sector;

    const showSectorProvinceCategory = sector && !(educationLevel || letter || fieldOfStudy || province);
    const showSectorCityCategory = sector && province && !(educationLevel || letter || fieldOfStudy);

    const showProvinceCityCategory = province && !(educationLevel || letter || fieldOfStudy || sector);

    const selectedEducationLevel = educationLevelOptions.find(option => option.slug === educationLevel);
    const selectedFieldOfStudy = fieldOfStudyOptions.find(option => option.slug === fieldOfStudy);
    const selectedSector = sectorOptions.find(option => option.slug === sector);
    const selectedProvince = provinceOptions.find(option => option.slug === province);

    const educationLevelOptionColumns = divideArrayInColumns(educationLevelOptions, 4);
    const sectorOptionColumns = divideArrayInColumns(sectorOptions, 5);
    const provinceOptionColumns = divideArrayInColumns(provinceOptions, 4);
    const cityOptionColumns = divideArrayInColumns(cityOptions, 6);

    const educationLevelTitle = letter === alphabetNumber
        ? trans('vacancyIndex.internships.number', { number: alphabetNumber })
        : trans('vacancyIndex.internships.letter', { letter });

    const categoryProps = {
        isLoading,
        vacancyType: VacancyType.internship,
        className: 'vacancy-index__category',
    };

    return (
        <div className={`vacancy-index ${className}`}>
            {!showInitialCategory && (
                <VacancyIndexBreadcrumbList
                    vacancyType={VacancyType.internship}
                    educationLevel={educationLevel}
                    letter={letter}
                    fieldOfStudy={fieldOfStudy}
                    sector={sector}
                    province={province}
                    educationLevelLabel={selectedEducationLevel?.name}
                    fieldOfStudyLabel={selectedFieldOfStudy?.name}
                    sectorLabel={selectedSector?.name}
                    provinceLabel={selectedProvince?.name}
                    className="vacancy-index__breadcrumbs"
                />
            )}

            <VacancyIndexTab isActive={showInitialCategory} title={trans('vacancyIndex.internships.main')}>
                <VacancyIndexEducationLevel
                    {...categoryProps}
                    educationLevelOptionColumns={educationLevelOptionColumns}
                />

                <VacancyIndexSector
                    {...categoryProps}
                    sectorOptionColumns={sectorOptionColumns}
                />

                <VacancyIndexProvince
                    {...categoryProps}
                    provinceOptionColumns={provinceOptionColumns}
                />
            </VacancyIndexTab>

            {selectedEducationLevel && (
                <VacancyIndexTab
                    isActive={!!showEducationLevelCategory}
                    title={trans('vacancyIndex.internships.educationLevel', {
                        educationLevel: selectedEducationLevel.name,
                    })}
                >
                    <VacancyIndexEducationLevelLetter
                        {...categoryProps}
                        educationLevel={selectedEducationLevel}
                        activeLetters={activeLetters}
                    />
                </VacancyIndexTab>
            )}

            {selectedEducationLevel && letter && (
                <VacancyIndexTab isActive={!!showEducationLevelFieldOfStudyCategory} title={educationLevelTitle}>
                    <VacancyIndexFieldOfStudy
                        {...categoryProps}
                        educationLevel={selectedEducationLevel}
                        letter={letter}
                        fieldOfStudyOptions={fieldOfStudyOptions}
                    />
                </VacancyIndexTab>
            )}

            {selectedEducationLevel && letter && selectedFieldOfStudy && (
                <VacancyIndexTab
                    isActive={!!showEducationLevelProvinceCategory}
                    title={trans('vacancyIndex.internships.educationLevel', {
                        educationLevel: selectedEducationLevel.name,
                    })}
                >
                    <VacancyIndexFieldOfStudyProvince
                        {...categoryProps}
                        letter={letter}
                        educationLevel={selectedEducationLevel}
                        fieldOfStudy={selectedFieldOfStudy}
                        provinceOptionColumns={provinceOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedEducationLevel && selectedFieldOfStudy && selectedProvince && (
                <VacancyIndexTab
                    isActive={!!showEducationLevelCityCategory}
                    title={trans('vacancyIndex.internships.educationLevel', {
                        educationLevel: selectedEducationLevel.name,
                    })}
                >
                    <VacancyIndexFieldOfStudyCity
                        {...categoryProps}
                        fieldOfStudy={selectedFieldOfStudy}
                        province={selectedProvince}
                        cityOptionColumns={cityOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedSector && (
                <VacancyIndexTab
                    isActive={!!showSectorProvinceCategory}
                    title={trans('vacancyIndex.internships.sector', {
                        sector: selectedSector.name,
                    })}
                >
                    <VacancyIndexSectorProvince
                        {...categoryProps}
                        sector={selectedSector}
                        provinceOptionColumns={provinceOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedSector && selectedProvince && (
                <VacancyIndexTab
                    isActive={!!showSectorCityCategory}
                    title={trans('vacancyIndex.internships.sector', {
                        sector: selectedSector.name,
                    })}
                >
                    <VacancyIndexSectorCity
                        {...categoryProps}
                        sector={selectedSector}
                        province={selectedProvince}
                        cityOptionColumns={cityOptionColumns}
                    />
                </VacancyIndexTab>
            )}

            {selectedProvince && (
                <VacancyIndexTab
                    isActive={!!showProvinceCityCategory}
                    title={trans('vacancyIndex.internships.province', {
                        province: selectedProvince.name,
                    })}
                >
                    <VacancyIndexProvinceCity
                        {...categoryProps}
                        province={selectedProvince}
                        cityOptionColumns={cityOptionColumns}
                    />
                </VacancyIndexTab>
            )}
        </div>
    );
};

export default InternshipVacancyIndex;
