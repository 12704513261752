import React, { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { JobVacancyIndex } from '../../containers';
import { decodeAllObjectValues } from '../../helpers/object';
import { JobVacancyIndexParams } from '../../models/VacancyIndex';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchEscoOccupations } from '../../store/escoOccupations/escoOccupationsActions';
import { fetchSectors } from '../../store/sectors/sectorsActions';
import { fetchJobVacancyIndexAggregation } from '../../store/vacancyIndex/vacancyIndexActions';

interface ConnectedJobVacancyIndexProps {
    className?: string;
}

const ConnectedJobVacancyIndex: FC<ConnectedJobVacancyIndexProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const params = useParams() as JobVacancyIndexParams;
    const {
        letter,
        occupation,
        sector,
        province,
    } = decodeAllObjectValues(params);

    const vacancyIndexIsLoading = useTypedSelector(state => state.vacancyIndexReducer.isLoading);
    const activeLetters = useTypedSelector(state => state.vacancyIndexReducer.activeLetters);
    const activeOccupations = useTypedSelector(state => state.vacancyIndexReducer.activeOccupations);
    const activeSectors = useTypedSelector(state => state.vacancyIndexReducer.activeSectors);
    const activeProvinces = useTypedSelector(state => state.vacancyIndexReducer.activeProvinces);
    const activeCities = useTypedSelector(state => state.vacancyIndexReducer.activeCities);

    const escoOccupationIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const escoOccupationOptions = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);

    const sectorIsLoading = useTypedSelector(state => state.sectorsReducer.isLoading);
    const sectorOptions = useTypedSelector(state => state.sectorsReducer.sectors);

    const provinceOptions = useTypedSelector(state => state.provincesReducer.provinces);

    const allOptionsLoaded = escoOccupationOptions.length && sectorOptions.length && provinceOptions.length;

    useEffect((): void => {
        if (escoOccupationOptions.length === 0) dispatch(fetchEscoOccupations());
        if (sectorOptions.length === 0) dispatch(fetchSectors());
    }, [dispatch, escoOccupationOptions, sectorOptions]);

    useEffect((): void => {
        if (allOptionsLoaded) {
            dispatch(fetchJobVacancyIndexAggregation(params));
        }
    }, [dispatch, allOptionsLoaded, params]);

    const optionsIsLoading = escoOccupationIsLoading || sectorIsLoading;
    const isLoading = optionsIsLoading || vacancyIndexIsLoading;

    return (
        <JobVacancyIndex
            isLoading={isLoading}
            letter={letter}
            occupation={occupation}
            sector={sector}
            province={province}
            activeLetters={activeLetters}
            sectorOptions={activeSectors}
            provinceOptions={activeProvinces}
            cityOptions={activeCities}
            escoOccupationOptions={activeOccupations}
            className={className}
        />
    );
};

export default ConnectedJobVacancyIndex;
