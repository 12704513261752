import styled, { css } from 'styled-components';
import { display, position, space } from 'styled-system';

export default styled.section(props => css`
    position: relative;
    display: block;
    padding-top: ${props.theme.space.lg};
    overflow-x: hidden;
    
    @media ${props.theme.mediaQueries.laptop} {
        padding-top: ${props.theme.space.xl};
        ${space}
    }
`, space, display, position);
