import styled, { css } from 'styled-components';
import { space } from 'styled-system';

export const Content = styled.div(props => css`
    font-size: ${props.theme.fontSizes[props.size || 'rg']};
    line-height: ${props.lineHeight || '1.4em'};
    color: ${props.theme.colors.text};
    overflow-wrap: break-word;
        
    ul {
        margin: ${props.theme.space.sm} 0 ${props.theme.space.sm} 25px;
        margin-left: 25px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    ol {
        margin: ${props.theme.space.sm} 0 0 25px;
        margin-left: 25px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    h2 {
        ${props.theme.fonts.heading};
        font-size: ${props.theme.fontSizes.md};
        line-height: 1em;
        letter-spacing: 0.01875em;
        color: ${props.theme.colors.primary};
        margin-bottom: ${props.theme.space.xs};
    }
    
    p {
        margin-bottom: 1em;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    b {
        font-weight: 700;
    }
    
    i {
        font-style: italic;
    }
    
    a {
        word-wrap: break-word;
    }
`, space);

export default Content;
