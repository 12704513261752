import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconTriangleDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 75" className={`icon ${className}`}>
        <path d="M50,75L0,0h100L50,75z" />
    </svg>
);

export default IconTriangleDown;
