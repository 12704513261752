import React, {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { Address } from '../../_old/app_talentz/models/Address';
import { MatchProperty } from '../../_old/app_talentz/models/MatchProperty';
import { StepProgressIndicator } from '../../components';
import { Loader } from '../../compositions';
import { Competency } from '../../models/Competencies';
import { EditableVacancy } from '../../models/EditableVacancy';
import { FieldOfStudy } from '../../models/FieldsOfStudy';
import { SbbRecognition } from '../../models/SbbTrainingCompany';
import { Sector } from '../../models/Sectors';
import { Skill, SkillType } from '../../models/Skills';
import { Grade, InternshipType } from '../../models/VacancyOptions';
import { PlaceInternshipVacancyFormValues, transformInternshipVacancyToPlaceInternshipVacancyFormValues } from '../../services/PlaceVacancyService';
import { FormOption } from '../../types';
import { createEmptyPlaceInternshipVacancyFormValues } from './helpers';
import { InternshipVacancyDataStep, InternshipVacancySkillsStep } from './steps';

import './PlaceInternshipVacancySteps.scss';

interface PlaceInternshipVacancyStepsProps {
    isLoading?: boolean;
    step?: number;
    maxAmountOfSteps?: number;
    vacancy?: EditableVacancy;
    sbbRecognitions: SbbRecognition[];
    address?: Address;
    sectorOptions: Sector[];
    educationLevelOptions: MatchProperty[];
    fieldsOfStudyOptions: FieldOfStudy[];
    gradeOptions: Grade[];
    internshipTypeOptions: InternshipType[];
    skillOptions: Skill[];
    skillsError: string;
    competencyOptions: Competency[];
    competenciesError: string;
    workRemoteOptions: FormOption[];
    onCreateSkillOption: (value: string) => void;
    onStepChange: (step: number) => void;
    onCreate: (values: PlaceInternshipVacancyFormValues) => void;
    onEdit: (values: PlaceInternshipVacancyFormValues) => void;
}

const PlaceInternshipVacancySteps: FC<PlaceInternshipVacancyStepsProps> = ({
    isLoading = false,
    step = 1,
    maxAmountOfSteps = 2,
    vacancy,
    sbbRecognitions,
    address,
    sectorOptions,
    educationLevelOptions,
    fieldsOfStudyOptions,
    gradeOptions,
    internshipTypeOptions,
    workRemoteOptions,
    skillOptions,
    skillsError,
    competencyOptions,
    competenciesError,
    onCreateSkillOption,
    onStepChange,
    onCreate,
    onEdit,
}): ReactElement => {
    const [formValues, setFormValues] = useState<PlaceInternshipVacancyFormValues>(createEmptyPlaceInternshipVacancyFormValues(address));
    const [newSkillLabel, setNewSkillLabel] = useState<string>('');
    const [newSkillToLearnLabel, setNewSkillToLearnLabel] = useState<string>('');

    const [educationLevelId, setEducationLevelId] = useState<string>('');
    const [internshipTypeId, setInternshipTypeId] = useState<string>('');
    const [fieldsOfStudy, setFieldsOfStudy] = useState<FieldOfStudy[]>([]);
    const [workRemotePreferenceId, setWorkRemotePreferenceId] = useState<string>('');

    const [skills, setSkills] = useState<Skill[]>([]);
    const [skillsToLearn, setSkillsToLearn] = useState<Skill[]>([]);
    const [competencies, setCompetencies] = useState<Competency[]>([]);
    const [competenciesToLearn, setCompetenciesToLearn] = useState<Competency[]>([]);

    useEffect((): void => {
        if (!vacancy) return;

        setEducationLevelId(vacancy.educationLevels.length ? vacancy.educationLevels[0] : '');
        setInternshipTypeId(vacancy.vacancyType);

        if (fieldsOfStudyOptions && fieldsOfStudy.length === 0) {
            const vacancyFieldsOfStudy = fieldsOfStudyOptions.filter(fieldOfStudy => vacancy.fieldsOfStudy.includes(fieldOfStudy.id));

            const orderedVacancyFieldsOfStudy = vacancy.fieldsOfStudy.map(fieldOfStudyId => {
                const selectedFieldOfStudy = vacancyFieldsOfStudy.filter(fieldOfStudy => fieldOfStudy.id === fieldOfStudyId);

                return selectedFieldOfStudy[0];
            }).filter(Boolean);

            setFieldsOfStudy(orderedVacancyFieldsOfStudy);
        }

        setWorkRemotePreferenceId(vacancy.workRemotePreference);

        if (skillOptions) {
            const vacancySkills = skillOptions.filter(skill => vacancy.skills.includes(skill.id));
            const vacancySkillsToLearn = skillOptions.filter(skill => vacancy.skillsToLearn.includes(skill.id));

            setSkills(vacancySkills);
            setSkillsToLearn(vacancySkillsToLearn);
        }

        if (competencyOptions) {
            const vacancyCompetencies = competencyOptions.filter(competency => vacancy.competencies.includes(competency.id));
            const vacancyCompetenciesToLearn = competencyOptions.filter(competency => vacancy.competenciesToLearn.includes(competency.id));

            setCompetencies(vacancyCompetencies);
            setCompetenciesToLearn(vacancyCompetenciesToLearn);
        }
    }, [vacancy, fieldsOfStudyOptions, skillOptions, competencyOptions]);

    useEffect((): void => {
        if (!vacancy) return;

        setFormValues(transformInternshipVacancyToPlaceInternshipVacancyFormValues(vacancy, address));
    }, [vacancy, address]);

    // Add newly added option to list of selected skills (to learn)
    useEffect((): void => {
        if (newSkillLabel) {
            const newSkill = skillOptions.find(option => option.name.toLowerCase() === newSkillLabel.toLowerCase());

            if (newSkill) {
                setSkills([...skills, newSkill]);
            }

            setNewSkillLabel('');
        }

        if (newSkillToLearnLabel) {
            const newSkillToLearn = skillOptions.find(option => option.name.toLowerCase() === newSkillToLearnLabel.toLowerCase());

            if (newSkillToLearn) {
                setSkillsToLearn([...skillsToLearn, newSkillToLearn]);
            }

            setNewSkillToLearnLabel('');
        }
    }, [skillOptions]);

    const handleOnChange = useCallback((values: Partial<PlaceInternshipVacancyFormValues>): void => {
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            ...values,
        }));
    }, []);

    const handleEducationLevelChange = (educationLevelUuid: string): void => {
        setEducationLevelId(educationLevelUuid);
        setInternshipTypeId('');
        setFieldsOfStudy([]);
    };

    const handleWorkRemotePreferenceChange = (workRemotePreference: string): void => {
        setWorkRemotePreferenceId(workRemotePreference);

        setFormValues({
            ...formValues,
            workRemotePreferenceId: workRemotePreference,
        });
    };

    const handleCreateNewSkill = (skill: string): void => {
        setNewSkillLabel(skill);
        onCreateSkillOption(skill);
    };

    const handleCreateNewSkillToLearn = (skillToLearn: string): void => {
        setNewSkillToLearnLabel(skillToLearn);
        onCreateSkillOption(skillToLearn);
    };

    const handleSubmit = (): void => {
        const values: PlaceInternshipVacancyFormValues = {
            ...formValues,
            educationLevelId,
            internshipTypeId,
            fieldsOfStudyIds: fieldsOfStudy.map(fieldOfStudy => fieldOfStudy.id),
            workRemotePreferenceId,
            skillIds: skills
                .filter(skill => skill.type === SkillType.skill)
                .map(skill => skill.id),
            skillsToLearnIds: skillsToLearn
                .filter(skillToLearn => skillToLearn.type === SkillType.skill)
                .map(skillToLearn => skillToLearn.id),
            competencyIds: competencies.map(competency => competency.id),
            competenciesToLearnIds: competenciesToLearn.map(competency => competency.id),
        };

        if (!vacancy) {
            onCreate(values);
        } else {
            onEdit({
                ...vacancy,
                ...values,
            });
        }
    };

    const handleNextClick = (): void => onStepChange(step + 1);
    const handlePreviousClick = (): void => onStepChange(step - 1);

    return (
        <div className="place-internship-vacancy-steps">
            {isLoading && <Loader className="place-internship-vacancy-steps__loader" />}

            <StepProgressIndicator stepAmount={maxAmountOfSteps} currentStep={step} />

            {step === 1 && (
                <InternshipVacancyDataStep
                    isEditing={!!vacancy}
                    isLoading={isLoading}
                    values={formValues}
                    educationLevelId={educationLevelId}
                    internshipTypeId={internshipTypeId}
                    fieldsOfStudy={fieldsOfStudy}
                    sectorOptions={sectorOptions}
                    educationLevelOptions={educationLevelOptions}
                    fieldsOfStudyOptions={fieldsOfStudyOptions}
                    internshipTypeOptions={internshipTypeOptions}
                    gradeOptions={gradeOptions}
                    workRemoteOptions={workRemoteOptions}
                    sbbRecognitions={sbbRecognitions}
                    onEducationLevelChange={handleEducationLevelChange}
                    onInternshipTypeChange={setInternshipTypeId}
                    onFieldOfStudyChange={setFieldsOfStudy}
                    onWorkRemotePreferenceChange={handleWorkRemotePreferenceChange}
                    onChange={handleOnChange}
                    onNextClick={handleNextClick}
                />
            )}

            {step === 2 && (
                <InternshipVacancySkillsStep
                    isLoading={isLoading}
                    skills={skills}
                    skillsToLearn={skillsToLearn}
                    competencies={competencies}
                    competenciesToLearn={competenciesToLearn}
                    skillOptions={skillOptions}
                    skillsError={skillsError}
                    competencyOptions={competencyOptions}
                    competenciesError={competenciesError}
                    onChangeSkills={setSkills}
                    onChangeSkillsToLearn={setSkillsToLearn}
                    onChangeCompetencies={setCompetencies}
                    onChangeCompetenciesToLearn={setCompetenciesToLearn}
                    onCreateSkillOption={handleCreateNewSkill}
                    onCreateSkillToLearnOption={handleCreateNewSkillToLearn}
                    onPreviousClick={handlePreviousClick}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
};

export default PlaceInternshipVacancySteps;
