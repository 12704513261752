import { AddressFormValues } from '../../../_old/app_talentz/models/Address';
import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { validateRequiredProperty } from '../../../_old/app_talentz/services/ValidationService';

const validateUserAddress = (address: AddressFormValues) => {
    const errors: ValidationErrors = {};

    errors.title = validateRequiredProperty('street_number', address.street_number, true);
    errors.title = validateRequiredProperty('extension', address.extension, true);
    errors.title = validateRequiredProperty('zipcode', address.zipcode, true);
    errors.title = validateRequiredProperty('latitude', address.latitude, true);
    errors.title = validateRequiredProperty('longitude', address.longitude, true);
    errors.title = validateRequiredProperty('city', address.city, true);
    errors.title = validateRequiredProperty('province', address.province, true);

    Object.keys(errors).forEach(errorKey => {
        if (!errors[errorKey].length) {
            delete errors[errorKey];
        }
    });

    return errors;
};

export default validateUserAddress;
