import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../helpers/trans';
import { EducationLevel } from '../../../../models/EducationLevels';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexEducationLevel.scss';

interface VacancyIndexEducationLevelProps {
    isLoading: boolean;
    educationLevelOptionColumns: EducationLevel[][];
    className?: string;
}

const VacancyIndexEducationLevel: FC<VacancyIndexEducationLevelProps> = ({
    isLoading,
    educationLevelOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.educationLevel.main')}
        className={`vacancy-index-education-level ${className}`}
    >
        <VacancyIndexColumnList>
            {educationLevelOptionColumns.map(column => {
                const columnIndex = column.map(level => level.slug).join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-education-level__level-list">
                            {column.map(educationLevel => {
                                const encodedEducationLevel = encodeURIComponent(educationLevel.slug);

                                return (
                                    <li key={educationLevel.slug} className="vacancy-index-education-level__level">
                                        <VacancyIndexLink
                                            to={RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel)}
                                            label={educationLevel.name}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexEducationLevel;
