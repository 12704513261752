import React from 'react';

import { trans } from '../../../../../../../helpers/trans';
import Container from '../../../../../../styledComponents/components/Container';
import GreyFooter from '../../../../../../styledComponents/components/GreyFooter';
import { Col, Row } from '../../../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../../../styledComponents/components/Typography';
import { Image } from './style';

const TeamContent = () => (
    <Container pt={['lg', 'lg', 'lg', 'lg', 'xl']}>
        <GreyFooter>
            <Container maxWidth="1200px" centered>
                <Row flexDirection="column" maxWidth="875px" mb="md">
                    <Heading size="xl">
                        {trans('infoPage.team.contentBlock.title')}
                    </Heading>
                </Row>
                <Row flexDirection="column" mb="xxl" maxWidth="875px">
                    <Paragraph size="sm">
                        {trans('infoPage.team.contentBlock.text')}
                    </Paragraph>
                </Row>
                <Row flexDirection={['column', 'column', 'column', 'row']} alignItems="center">
                    <Col pr={[0, 0, 0, 'md', 'lg']} width={[1, 1, 1, 1 / 2]} mb={['xl', 'xl', 'xl', 0]}>
                        <Image src="images/team-impact-1.jpg" alt="Impact maken" />
                    </Col>
                    <Col pl={[0, 0, 0, 'md', 'lg']} width={[1, 1, 1, 1 / 2]}>
                        <Image src="images/team-impact-2.jpg" alt="Impact maken" />
                    </Col>
                </Row>
            </Container>
        </GreyFooter>
    </Container>
);

export default TeamContent;
