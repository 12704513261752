import { maxShortTextLength } from '../../../../constants';
import { sanitizeAllHtml } from '../../../../helpers/sanitizeHtml';
import { ApplicationAppointmentFormData } from '../../../../models/ApplicationAppointments';
import {
    FormErrors,
    FormValidation,
    validateAppointmentDate,
    validateForm,
    validateMaxStringLength,
    validateRequiredOption,
} from '../../../../services/ValidationService';

export type ApplicationAppointmentFormErrors = FormErrors<ApplicationAppointmentFormData>;

export const validateApplicationAppointmentFormData = (
    formData: ApplicationAppointmentFormData,
): FormValidation<ApplicationAppointmentFormErrors> => {
    const appointmentsErrors = formData.appointments.map(validateAppointmentDate).filter(Boolean);
    const sanitizedMessage = sanitizeAllHtml(formData.message);

    const errors: ApplicationAppointmentFormErrors = {
        appointments: appointmentsErrors.length > 0
            ? appointmentsErrors[0]
            : undefined,
        type: validateRequiredOption('appointmentType', formData.type),
        message: validateMaxStringLength('message', sanitizedMessage, maxShortTextLength),
    };

    return validateForm<ApplicationAppointmentFormErrors>(errors);
};
