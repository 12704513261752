import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 52.2 100" className={`icon ${className}`}>
        <path d="M0,50L44.4,0l7.7,6.9L13.8,50l38.3,43.1l-7.7,6.9L0,50z" />
    </svg>
);

export default IconChevronLeft;
