import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPencil: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M82.5,0l-78,78L0,100l22.5-4.5l77.5-78L82.5,0z M17.5,86l-6,2l2.5-6l50.5-50l3.5,3.5L17.5,86z M70,26.5L82.5,14l3.5,3.5L73.5,30L70,26.5z" />
    </svg>
);

export default IconPencil;
