import React, { FC, ReactElement } from 'react';

import { Icon, Skeleton } from '../../../components';
import { DetailCard } from '../../../compositions';
import { generateIdArray } from '../../../helpers/array';
import { formatDate } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';

import './VacancyMetaCard.scss';

interface VacancyMetaData {
    hasData: boolean;
    icon: string;
    label: string;
    value?: string | number;
}

interface VacancyMetaCardProps {
    isLoading: boolean;
    favourites?: number;
    views?: number;
    publicationDate?: Date;
    className?: string;
}

const VacancyMetaCard: FC<VacancyMetaCardProps> = ({
    isLoading,
    favourites,
    views,
    publicationDate,
    className = '',
}): ReactElement => {
    const skeletonAmount = 3;
    const skeletons = generateIdArray(skeletonAmount);

    const metaData: VacancyMetaData[] = [
        {
            hasData: favourites !== undefined,
            icon: 'heart',
            label: trans('vacancyDetail.meta.favourited'),
            value: favourites,
        },
        {
            hasData: views !== undefined,
            icon: 'eye',
            label: trans('vacancyDetail.meta.viewed'),
            value: views,
        },
        {
            hasData: !!publicationDate,
            icon: 'calendar',
            label: trans('vacancyDetail.meta.created'),
            value: publicationDate ? formatDate(publicationDate) : undefined,
        },
    ];

    return (
        <DetailCard
            title={trans('vacancyDetail.meta.title')}
            className={`vacancy-meta-card ${className}`}
        >
            {isLoading && (
                <div className="vacancy-meta-card__skeleton-list">
                    {skeletons.map(skeleton => (
                        <div key={skeleton} className="vacancy-meta-card__skeleton-item">
                            <Skeleton className="vacancy-meta-card__skeleton-icon" />
                            <Skeleton className="vacancy-meta-card__skeleton" />
                            <Skeleton className="vacancy-meta-card__skeleton" />
                        </div>
                    ))}
                </div>
            )}

            {!isLoading && (
                <ul className="vacancy-meta-card__meta-list">
                    {metaData.map((item: VacancyMetaData) => (item.hasData ? (
                        <li key={item.label} className="vacancy-meta-card__meta-item">
                            <Icon name={item.icon} className="vacancy-meta-card__icon" />
                            <p className="vacancy-meta-card__value">{item.value}</p>
                            <p className="vacancy-meta-card__label">{item.label}</p>
                        </li>
                    ) : null))}
                </ul>
            )}
        </DetailCard>
    );
};

export default VacancyMetaCard;
