import { Resource } from '../japi/types/Resource';

export interface KvkSearchResultResource extends Resource {
    type: 'kvk-zoeken';
    id: string;
    attributes: {
        type: string;
        handelsnaam: string;
        kvkNummer: string;
        vestigingsnummer: string;
        plaats: string;
        straatnaam: string;
    };
}

export interface KvkSearchResult {
    uuid: string;
    isMainBranch: boolean;
    type: string;
    companyName: string;
    kvkNumber: string;
    branchNumber: string;
    streetName: string;
    city: string;
}

export const transformToKvkSearchResult = (
    kvkSearchResultResource: KvkSearchResultResource,
): KvkSearchResult => ({
    uuid: kvkSearchResultResource.id,
    isMainBranch: kvkSearchResultResource.attributes.type === 'hoofdvestiging',
    type: kvkSearchResultResource.attributes.type,
    companyName: kvkSearchResultResource.attributes.handelsnaam,
    kvkNumber: kvkSearchResultResource.attributes.kvkNummer,
    branchNumber: kvkSearchResultResource.attributes.vestigingsnummer,
    streetName: kvkSearchResultResource.attributes.straatnaam,
    city: kvkSearchResultResource.attributes.plaats,
});
