import React, { FC, PropsWithChildren, ReactElement } from 'react';

import { Card } from '../../../components';
import { trans } from '../../../helpers/trans';
import { IconButton } from '../..';

import './AddingCard.scss';

interface AddingCardProps {
    isEditable?: boolean;
    title: string;
    item?: string;
    onAddClick?: () => void;
    className?: string;
}

const AddingCard: FC<PropsWithChildren<AddingCardProps>> = ({
    isEditable = false,
    title,
    item,
    onAddClick,
    className = '',
    children,
}): ReactElement => {
    const getAddLabel = item
        ? trans('actions.addItem', { item: item.toLowerCase() })
        : trans('actions.add');

    return (
        <Card className={`adding-card ${className}`}>
            <div className="adding-card__header">
                <h2 className="adding-card__title">
                    {title}
                </h2>

                {isEditable && (
                    <IconButton
                        icon="plus"
                        text={getAddLabel}
                        hideLabel
                        onClick={onAddClick}
                        className="adding-card__add-button"
                        iconClassName="adding-card__add-icon"
                    />
                )}
            </div>

            {children}
        </Card>
    );
};

export default AddingCard;
