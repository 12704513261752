import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 52.2" className={`icon ${className}`}>
        <path d="M50,52.2L0,7.7L6.9,0L50,38.3L93.1,0l6.9,7.7L50,52.2z" />
    </svg>
);

export default IconChevronDown;
