import styled, { css } from 'styled-components';

import ComingSoonLabel from '../../styledComponents/components/ComingSoonLabel';

export const Label = styled('p')(props => css`
    position: relative;
    margin-bottom: ${props.theme.space.sm};
`);

export const LabelWrapper = styled('span')`
    position: relative;
    display: block;
`;

export const ComingSoon = styled(ComingSoonLabel)(props => css`
    position: absolute;
    top: -${props.theme.space.md};
    right: 50%;
    transform: translateX(50%);
`);

export const Button = styled('button')(props => css`
    ${props.theme.fonts.button}
    position: relative;
    width: 100%;
    background: ${props.theme.colors.white};
    border: 1px solid ${props.theme.colors.grey};
    color: ${props.theme.colors.primary};
    font-size: ${props.theme.fontSizes.sm};
    border-radius: 4px;
    font-weight: 600;
    transition: background 0.15s ease-out, color 0.15s ease-out;
    box-shadow: ${props.theme.shadow()};
    cursor: pointer;
    
    ${!props.comingSoon && css`
        &:hover,
        &:focus {
            background: ${props.theme.colors.primary};
            color: ${props.theme.colors.white};
        
            & svg {
                fill: ${props.theme.colors.white};
            }
        }
    `}
    
    svg {
        fill: ${props.theme.colors.primary};
    }
    
    ${props.active && css`
        background: ${props.theme.colors.primary};
        color: ${props.theme.colors.white};
    
        svg {
            fill: ${props.theme.colors.white};
        }
    `}
    
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    
    ${props.comingSoon && css`
        border: 1px solid ${props.theme.colors.background};
        cursor: not-allowed;

        svg {
            opacity: 0.3;
        }
        
        ${Label} {
            opacity: 0.3;   
        }
    `}
`);

export const Wrapper = styled('span')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
`;

export const IconWrapper = styled('span')`
    display: block;
    flex: 1;
    width: 100%;
    padding: 10px;
    
    & svg {
        transition: fill 0.15s ease-out;
        width: calc(100% - 33px);
    }
`;
