// Do NOT change the order of these break points, otherwise the whole app will collapse
const numericBreakPoints = [
    350,
    600,
    768,
    1024,
    1200,
    1480,
];

// This constant is used by styled system
export const breakpoints = numericBreakPoints.map(bp => `${bp}px`);

export const mediaQueries = {
    smallMobile: `(max-width: ${breakpoints[0]})`,
    smallTablet: `(min-width: ${breakpoints[1]})`,
    tablet: `(min-width: ${breakpoints[2]})`,
    laptop: `(min-width: ${breakpoints[3]})`,
    desktop: `(min-width: ${breakpoints[4]})`,
    largeScreen: `(min-width: ${breakpoints[5]})`,
    maxSmallTablet: `(max-width: ${numericBreakPoints[1] - 1}px)`,
    maxTablet: `(max-width: ${numericBreakPoints[2] - 1}px)`,
    maxLaptop: `(max-width: ${numericBreakPoints[3] - 1}px)`,
    maxDesktop: `(max-width: ${numericBreakPoints[4] - 1}px)`,
    maxLargeScreen: `(max-width: ${numericBreakPoints[5] - 1}px)`,
};
