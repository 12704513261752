import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import './Button.scss';

type HTMLButtonProps = JSX.IntrinsicElements['button'] & RefAttributes<HTMLButtonElement>;

export interface ButtonProps extends HTMLButtonProps {
    text: string;
    hideLabel?: boolean;
}

const Button: ForwardRefExoticComponent<ButtonProps> = forwardRef(({
    type = 'button',
    text,
    className = '',
    children,
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => (
    <button
        {...buttonProps}
        ref={ref}
        type={type} // eslint-disable-line react/button-has-type
        title={children ? text : undefined}
        className={`button ${className}`}
    >
        {children || text}
    </button>
));

export default Button;
