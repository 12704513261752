import { ImageDimensions, ImageMeta } from '../models/ImageMeta';
import { trans } from './trans';

export const convertFileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (): void => {
        if (reader.result) {
            const stringResult = reader.result as string;
            const base64String = stringResult
                .replace('data:', '')
                .replace(/^.+,/, '');

            resolve(base64String);
        } else {
            resolve('');
        }
    };

    reader.onerror = error => reject(error);
});

export const getImageDimensions = async (src: string): Promise<ImageDimensions> => {
    const image = new Image();

    image.src = src;
    await image.decode();

    return {
        width: image.width,
        height: image.height,
    };
};

export const getImageFileMeta = async (file: File): Promise<ImageMeta> => {
    const src = URL.createObjectURL(file);
    const dimensions = await getImageDimensions(src);

    return fetch(src)
        .then(response => response.arrayBuffer())
        .then(buffer => ({
            ...dimensions,
            size: buffer.byteLength,
        }));
};

export const convertBytesToLabel = (bytes: number): string => {
    if (bytes >= 10 ** 6) {
        return trans('basic.fileSize.megabytes', {
            size: (bytes / 10 ** 6).toFixed(1),
        });
    }

    if (bytes >= 10 ** 3) {
        return trans('basic.fileSize.kilobytes', {
            size: (bytes / 10 ** 3).toFixed(1),
        });
    }

    return trans('basic.fileSize.bytes', {
        size: `${bytes}`,
    });
};
