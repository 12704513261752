import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../components';
import { DetailCard, LinkIconButton } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate';
import { RoutePaths } from '../../../routes';

import './CandidateContactCard.scss';

interface CandidateContactCardProps {
    isLoading: boolean;
    candidate?: Candidate;
    className?: string;
}

const CandidateContactCard: FC<CandidateContactCardProps> = ({
    isLoading,
    candidate,
    className = '',
}): ReactElement => (
    <DetailCard
        isLoading={isLoading}
        title={trans('candidateProfile.contact.title')}
        className={`candidate-contact-card ${className}`}
    >
        {isLoading && (
            <div className="candidate-contact-card__skeleton-wrapper">
                <Skeleton className="candidate-contact-card__skeleton" />
                <Skeleton className="candidate-contact-card__skeleton" />
            </div>
        )}

        {!isLoading && candidate && (
            <p className="candidate-contact-card__description">
                {trans('candidateProfile.contact.description', {
                    candidate: candidate.fullName,
                })}
            </p>
        )}

        <LinkIconButton
            to={RoutePaths.companyOverviewMessages(candidate?.uuid)}
            icon="send"
            text={trans('actions.sendMessage')}
            disabled={isLoading || !candidate}
            className="candidate-contact-card__chat-button"
        />
    </DetailCard>
);

export default CandidateContactCard;
