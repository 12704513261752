import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconFilter: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 90 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M20,16V0H10v16C4,18,0,23.5,0,30s4,12,10,14v56h10V44c6-2,10-7.5,10-14S26,18,20,16z M15,35c-2.5,0-5-2.5-5-5s2.5-5,5-5s5,2.5,5,5S17.5,35,15,35z M50,56V0H40v56c-6,2-10,7.5-10,14s4,12,10,14v16h10V84c6-2,10-7.5,10-14C60,63.5,56,58,50,56z M45,75c-2.5,0-5-2.5-5-5s2.5-5,5-5s5,2.5,5,5S47.5,75,45,75z M80,0v21c6,2,10,7.5,10,14s-4,12-10,14v51H70V49c-6-2-10-7.5-10-14s4-12,10-14V0H80z M70,35c0,2.5,2.5,5,5,5s5-2.5,5-5s-2.5-5-5-5S70,32.5,70,35z" />
    </svg>
);

export default IconFilter;
