import { trans } from '../../../helpers/trans';

export const getAmountOfCompaniesTranslation = (amount: number): string => {
    if (!amount) {
        return trans('forms.register.company.noCompaniesFound');
    }

    if (amount === 1) {
        return trans('forms.register.company.oneCompanyFound');
    }

    return trans('forms.register.company.amountOfCompaniesFound', { amount });
};
