import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, SearchableMultiValueInput } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { CoreValuesFormData } from '../../../models/CoreValue';
import { FormProps, SearchableOption } from '../../../types';
import { CoreValuesFormErrors, validateCoreValuesFormData } from './validations';

import './CoreValuesForm.scss';

interface CoreValuesFormProps extends FormProps<CoreValuesFormData> {
    coreValues: SearchableOption[];
    coreValueOptions: SearchableOption[];
    className?: string;
}

const CoreValuesForm: FC<CoreValuesFormProps> = ({
    coreValues,
    coreValueOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CoreValuesFormErrors>({});

    const [selectedCoreValues, setSelectedCoreValues] = useState<SearchableOption[]>(coreValues);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            coreValues: selectedCoreValues,
        };

        const [errors, hasErrors] = validateCoreValuesFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`core-values-form ${className}`}>
            <SearchableMultiValueInput
                isSearchable
                showResultsOnClick
                label={trans('forms.coreValues.input.coreValues')}
                value={selectedCoreValues}
                error={formErrors.coreValues}
                options={coreValueOptions}
                onChange={setSelectedCoreValues}
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="core-values-form__action-buttons"
            />
        </form>
    );
};

export default CoreValuesForm;
