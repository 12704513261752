import React, { FC, MouseEvent, ReactElement } from 'react';

import { Button, Checkbox } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import { Competency } from '../../../../../models/Competencies';

import './CompetencyOption.scss';

interface CompetencyOptionProps {
    isChecked: boolean;
    competency: Competency;
    onChange: (competency: Competency, isChecked: boolean) => void;
    onMoreInformationClick: (competency: Competency) => void;
    className?: string;
}

const CompetencyOption: FC<CompetencyOptionProps> = ({
    isChecked,
    competency,
    onChange,
    onMoreInformationClick,
    className = '',
}): ReactElement => {
    const handleChange = (checked: boolean): void => {
        onChange(competency, checked);
    };

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        onChange(competency, !isChecked);
    };

    const handleMoreInformationClick = (): void => {
        onMoreInformationClick(competency);
    };

    return (
        <li className={`competency-option ${className}`}>
            <Checkbox
                label={competency.name}
                hideLabel
                checked={isChecked}
                onChange={handleChange}
                className="competency-option__checkbox"
            />

            <div className="competency-option__text-wrapper">
                <h3 className="competency-option__name">
                    {competency.name}
                </h3>

                <p className="competency-option__description">
                    {competency.description}
                </p>

                {competency.behavioralExamples.length > 0 && (
                    <Button
                        text={trans('forms.competencies.moreInfo.buttonLabel')}
                        onClick={handleMoreInformationClick}
                        className="competency-option__information-button"
                    />
                )}
            </div>

            <Button
                text={trans('forms.competencies.input.selectOption', { name: competency.name })}
                tabIndex={-1}
                onClick={handleButtonClick}
                className="competency-option__checkbox-button"
            />
        </li>
    );
};

export default CompetencyOption;
