import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { initialAsyncReduxState } from '../../constants';
import { NewestVacancy } from '../../models/NewestVacancy';
import { VacancyMatchingCandidate } from '../../models/VacancyMatches';
import { defaultVacancyMatchesFilterValues, VacancyMatchesFilterValues } from '../../models/VacancyMatchesFilter';
import { AsyncReduxState } from '../../types';

export type VacancyMatchesState = AsyncReduxState<{
    activeVacancy?: NewestVacancy;
    activeFilterValues: VacancyMatchesFilterValues;
    candidates: VacancyMatchingCandidate[];
    pagination?: Pagination;
}>;

const initialState: VacancyMatchesState = {
    ...initialAsyncReduxState,
    activeVacancy: undefined,
    activeFilterValues: defaultVacancyMatchesFilterValues(),
    candidates: [],
    pagination: undefined,
};

export const VacancyMatchesSlice = createSlice({
    name: 'vacancyMatchesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacancyMatchesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacancyMatchesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveVacancy(state, action: PayloadAction<NewestVacancy | undefined>): VacancyMatchesState {
            return {
                ...state,
                activeVacancy: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<VacancyMatchesFilterValues>): VacancyMatchesState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setCandidates(state, action: PayloadAction<VacancyMatchingCandidate[]>): VacancyMatchesState {
            return {
                ...state,
                candidates: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination | undefined>): VacancyMatchesState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveVacancy,
    setActiveFilterValues,
    setCandidates,
    setPagination,
} = VacancyMatchesSlice.actions;

export default VacancyMatchesSlice;
