import { MinMaxValue } from '../types';

export type EmploymentPreferenceFormData = MinMaxValue;

export const defaultEmploymentPreference = (): MinMaxValue => ({
    min: 0,
    max: 0,
});

export const defaultFullEmploymentPreference = (): MinMaxValue => ({
    min: 0,
    max: 40,
});
