import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getInternshipVacanciesApiCall, getMatchingInternshipVacanciesApiCall } from '../../services/InternshipVacancyOverviewService';
import { ReducerGetter, TypedDispatch } from '..';
import {
    setError,
    setIsLoading,
    setPagination,
    setVacancies,
} from './internshipVacancyOverview';

export const fetchMatchingInternshipVacancyOverview = () => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { internshipVacancyOverviewReducer } = getState();
        const { activeFilterValues } = internshipVacancyOverviewReducer;

        const internshipVacanciesResponse = await getMatchingInternshipVacanciesApiCall(activeFilterValues);

        if (!isFetchResultSuccessful(internshipVacanciesResponse)) {
            console.error('[fetchMatchingInternshipVacancyOverview]', internshipVacanciesResponse.error);
            dispatch(setError(internshipVacanciesResponse.error));
            dispatch(setPagination(undefined));
            dispatch(setVacancies([]));
            return;
        }

        const { pagination, vacancies } = internshipVacanciesResponse.data;

        dispatch(setPagination(pagination));
        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchMatchingInternshipVacancyOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchInternshipVacancyOverview = () => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { internshipVacancyOverviewReducer } = getState();
        const { activeFilterValues } = internshipVacancyOverviewReducer;

        const internshipVacanciesResponse = await getInternshipVacanciesApiCall(activeFilterValues);

        if (!isFetchResultSuccessful(internshipVacanciesResponse)) {
            console.error('[fetchInternshipVacancyOverview]', internshipVacanciesResponse.error);
            dispatch(setError(internshipVacanciesResponse.error));
            dispatch(setPagination(undefined));
            dispatch(setVacancies([]));
            return;
        }

        const { pagination, vacancies } = internshipVacanciesResponse.data;

        dispatch(setPagination(pagination));
        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchInternshipVacancyOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
