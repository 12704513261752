import { trans } from '../../../helpers/trans';
import { RoleType } from '../../../models/User';

interface RoleInfoStep {
    category: string;
    title: string;
    description: string;
    buttonLabel?: string;
    imageDesktop: {
        src: string;
        alt: string;
    };
    imageMobile: {
        src: string;
        alt: string;
    };
}

interface RoleInfoStepsContent {
    title: string;
    description: string;
    steps: RoleInfoStep[];
}

export const roleInfoStepsContent = (role: RoleType): RoleInfoStepsContent => {
    switch (role) {
        case RoleType.company:
            return {
                title: trans('infoPage.company.steps.title'),
                description: trans('infoPage.company.steps.description'),
                steps: [
                    {
                        category: trans('infoPage.company.steps.items.0.category'),
                        title: trans('infoPage.company.steps.items.0.title'),
                        description: trans('infoPage.company.steps.items.0.description'),
                        buttonLabel: trans('infoPage.company.steps.items.0.buttonLabel'),
                        imageDesktop: {
                            src: './images/info/register-desktop.jpg',
                            alt: trans('infoPage.company.steps.items.0.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/register-mobile.jpg',
                            alt: trans('infoPage.company.steps.items.0.imageMobileAlt'),
                        },
                    },
                    {
                        category: trans('infoPage.company.steps.items.1.category'),
                        title: trans('infoPage.company.steps.items.1.title'),
                        description: trans('infoPage.company.steps.items.1.description'),
                        buttonLabel: trans('infoPage.company.steps.items.1.buttonLabel'),
                        imageDesktop: {
                            src: './images/info/create-vacancy-desktop.jpg',
                            alt: trans('infoPage.company.steps.items.1.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/create-vacancy-mobile.jpg',
                            alt: trans('infoPage.company.steps.items.1.imageMobileAlt'),
                        },
                    },
                    {
                        category: trans('infoPage.company.steps.items.2.category'),
                        title: trans('infoPage.company.steps.items.2.title'),
                        description: trans('infoPage.company.steps.items.2.description'),
                        imageDesktop: {
                            src: './images/info/matching-desktop.jpg',
                            alt: trans('infoPage.company.steps.items.2.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/matching-mobile.jpg',
                            alt: trans('infoPage.company.steps.items.2.imageMobileAlt'),
                        },
                    },
                ],
            };
        case RoleType.jobSeeker:
            return {
                title: trans('infoPage.jobSeeker.steps.title'),
                description: trans('infoPage.jobSeeker.steps.description'),
                steps: [
                    {
                        category: trans('infoPage.jobSeeker.steps.items.0.category'),
                        title: trans('infoPage.jobSeeker.steps.items.0.title'),
                        description: trans('infoPage.jobSeeker.steps.items.0.description'),
                        buttonLabel: trans('infoPage.jobSeeker.steps.items.0.buttonLabel'),
                        imageDesktop: {
                            src: './images/info/screenshot-stage-profile.jpg',
                            alt: trans('infoPage.jobSeeker.steps.items.0.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/screenshot-stage-profile-mobile.jpg',
                            alt: trans('infoPage.jobSeeker.steps.items.0.imageMobileAlt'),
                        },
                    },
                    {
                        category: trans('infoPage.jobSeeker.steps.items.1.category'),
                        title: trans('infoPage.jobSeeker.steps.items.1.title'),
                        description: trans('infoPage.jobSeeker.steps.items.1.description'),
                        imageDesktop: {
                            src: './images/info/screenshot-stage-overzicht.jpg',
                            alt: trans('infoPage.jobSeeker.steps.items.1.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/screenshot-stage-overzicht-mobile.jpg',
                            alt: trans('infoPage.jobSeeker.steps.items.1.imageMobileAlt'),
                        },
                    },
                ],
            };
        case RoleType.student:
        default:
            return {
                title: trans('infoPage.student.steps.title'),
                description: trans('infoPage.student.steps.description'),
                steps: [
                    {
                        category: trans('infoPage.student.steps.items.0.category'),
                        title: trans('infoPage.student.steps.items.0.title'),
                        description: trans('infoPage.student.steps.items.0.description'),
                        buttonLabel: trans('infoPage.student.steps.items.0.buttonLabel'),
                        imageDesktop: {
                            src: './images/info/screenshot-stage-profile.jpg',
                            alt: trans('infoPage.student.steps.items.0.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/screenshot-stage-profile-mobile.jpg',
                            alt: trans('infoPage.student.steps.items.0.imageMobileAlt'),
                        },
                    },
                    {
                        category: trans('infoPage.student.steps.items.1.category'),
                        title: trans('infoPage.student.steps.items.1.title'),
                        description: trans('infoPage.student.steps.items.1.description'),
                        imageDesktop: {
                            src: './images/info/screenshot-stage-overzicht.jpg',
                            alt: trans('infoPage.student.steps.items.1.imageDesktopAlt'),
                        },
                        imageMobile: {
                            src: './images/info/screenshot-stage-overzicht-mobile.jpg',
                            alt: trans('infoPage.student.steps.items.1.imageMobileAlt'),
                        },
                    },
                ],
            };
    }
};
