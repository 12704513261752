import React, { FC, ReactElement } from 'react';

import { BlogList } from '../../compositions';
import { trans } from '../../helpers/trans';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import { BlogArticle } from '../../models/Blog';
import { Pagination } from '..';
import { BlogCategoryFilterButtons, BlogCategoryList } from './subcomponents';

import './BlogOverview.scss';

interface BlogOverviewProps {
    isLoading: boolean;
    totalPages: number;
    currentPage: number;
    totalResults: number;
    categories: string[];
    categorySizes: Record<string, number>;
    currentCategory: string;
    blogArticles: BlogArticle[];
    onPaginationChange: (page: number) => void;
    className?: string;
}

const BlogOverview: FC<BlogOverviewProps> = ({
    isLoading,
    totalPages,
    currentPage,
    totalResults,
    categories,
    categorySizes,
    currentCategory,
    blogArticles,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const { isTabletLandscape, isDesktop } = useDeviceWidth();

    const category = currentCategory || trans('blog.allArticles');
    const categoryTitle = isLoading ? category : `${category} (${totalResults})`;

    return (
        <section className={`blog-overview ${className}`}>
            <header className="blog-overview__header">
                <h1 className="blog-overview__title">
                    {categoryTitle}
                </h1>

                {(isTabletLandscape || isDesktop) && (
                    <BlogCategoryFilterButtons
                        categories={categories}
                        currentCategory={currentCategory}
                        className="blog-overview__category-filter-buttons"
                    />
                )}
            </header>

            <BlogList
                isLoading={isLoading}
                blogArticles={blogArticles}
                className="blog-overview__list"
            />

            {!isLoading && (
                <>
                    <Pagination
                        amountOfPages={totalPages}
                        currentPage={currentPage}
                        onChange={onPaginationChange}
                        className="blog-overview__pagination"
                    />

                    <BlogCategoryList
                        categories={categories}
                        categorySizes={categorySizes}
                        currentCategory={currentCategory}
                        className="blog-overview__category-list"
                    />
                </>
            )}
        </section>
    );
};

export default BlogOverview;
