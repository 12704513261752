import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrowRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 56.2" className={`icon ${className}`}>
        <path d="M77.5,56.2L100,28.1L77.5,0l-7.8,6.2l13.5,16.9H0v10h83.2L69.7,50L77.5,56.2z" />
    </svg>
);

export default IconArrowRight;
