import { Address } from '../_old/app_talentz/models/Address';

export interface SeekMatchesSchoolExperienceResponse {
    educationLevel: string;
    fieldOfStudy: string;
    isCurrent: boolean;
    learningPath?: string;
    school: {
        address?: Address;
        customerNumber?: string;
        educationLevel: string;
        fqdn?: string;
        name: string;
        sbbQualified?: boolean;
    };
    endDate?: string;
    startDate?: string;
}

export interface SeekMatchesSchoolExperience {
    educationLevel: string;
    fieldOfStudy: string;
    learningPath?: string;
    schoolName: string;
    schoolCity?: string;
}

export const transformToSeekMatchesSchoolExperience = (
    schoolExperience: SeekMatchesSchoolExperienceResponse,
): SeekMatchesSchoolExperience => ({
    educationLevel: schoolExperience.educationLevel,
    fieldOfStudy: schoolExperience.fieldOfStudy,
    learningPath: schoolExperience.learningPath || undefined,
    schoolName: schoolExperience.school.name,
    schoolCity: schoolExperience.school.address?.city,
});
