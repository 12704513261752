import { VacancyType } from '../_old/app_talentz/models/VacancyType';
import { alphabetNumber } from '../constants';
import { generateIdArray } from '../helpers/array';
import { authorizedFetch } from '../helpers/authorizedFetch';
import { convertToQueryParams } from '../helpers/params';
import { trans } from '../helpers/trans';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { InternshipVacancyIndexParams, JobVacancyIndexParams, VacancyIndexAggregation } from '../models/VacancyIndex';

export const getInternshipVacancyIndexAggregationApiCall = async (params: InternshipVacancyIndexParams): Promise<FetchResult<VacancyIndexAggregation, string>> => {
    const {
        educationLevel,
        fieldOfStudy,
        letter,
        sector,
        province,
    } = params;

    const initialCategory = !(educationLevel || letter || fieldOfStudy || sector || province);

    const educationLevelCategory = educationLevel && !(letter || fieldOfStudy || sector || province);
    const educationLevelFieldOfStudyCategory = educationLevel && letter && !(fieldOfStudy || sector || province);
    const educationLevelProvinceCategory = educationLevel && letter && fieldOfStudy && !(sector || province);
    const educationLevelCityCategory = educationLevel && letter && fieldOfStudy && province && !sector;

    const sectorProvinceCategory = sector && !(educationLevel || letter || fieldOfStudy || province);
    const sectorCityCategory = sector && province && !(educationLevel || letter || fieldOfStudy);

    const provinceCityCategory = province && !(educationLevel || letter || fieldOfStudy || sector);

    let aggs: string[] = [];
    let filters = {};

    if (initialCategory) {
        aggs = ['educationLevels', 'sectors', 'provinces'];
    }

    if (educationLevelCategory) {
        aggs = ['educationLevels', 'fosIndex'];
        filters = { 'filter[educationLevels]': educationLevel };
    }

    if (educationLevelFieldOfStudyCategory) {
        const fosIndexFilter = letter === alphabetNumber
            ? generateIdArray(10).map(String)
            : letter;

        aggs = ['educationLevels', 'fieldsOfStudy'];
        filters = {
            'filter[educationLevels]': educationLevel,
            'filter[fosIndex]': fosIndexFilter,
        };
    }

    if (educationLevelProvinceCategory) {
        const fosIndexFilter = letter === alphabetNumber
            ? generateIdArray(10).map(String)
            : letter;

        aggs = ['educationLevels', 'fieldsOfStudy', 'provinces'];
        filters = {
            'filter[educationLevels]': educationLevel,
            'filter[fieldsOfStudy]': fieldOfStudy,
            'filter[fosIndex]': fosIndexFilter,
        };
    }

    if (educationLevelCityCategory) {
        aggs = ['educationLevels', 'fieldsOfStudy', 'cities'];
        filters = {
            'filter[fieldsOfStudy]': fieldOfStudy,
            'filter[province]': province,
        };
    }

    if (sectorProvinceCategory) {
        aggs = ['provinces'];
        filters = { 'filter[sectors][]': sector };
    }

    if (sectorCityCategory) {
        aggs = ['cities'];
        filters = {
            'filter[sectors][]': sector,
            'filter[province]': province,
        };
    }

    if (provinceCityCategory) {
        aggs = ['cities'];
        filters = { 'filter[province]': province };
    }

    const queryParams = convertToQueryParams({
        'page[size]': 0,
        _aggs: aggs.join(','),
        'filter[vacancy_type]': VacancyType.internship,
        ...filters,
    });

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/vacancies/search?${queryParams}`);

        if (!response.ok) {
            const errorDoc = await response.json();
            const error = errorDoc.errors[0];

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        const aggregation = doc.meta?.aggregation;

        if (!aggregation) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: aggregation,
        };
    } catch (error) {
        console.error('[getInternshipVacancyIndexAggregationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getJobVacancyIndexAggregationApiCall = async (params: JobVacancyIndexParams): Promise<FetchResult<VacancyIndexAggregation, string>> => {
    const {
        letter,
        occupation,
        sector,
        province,
    } = params;

    const initialCategory = !(letter || occupation || sector || province);

    const occupationCategory = letter && !(occupation || sector || province);
    const occupationProvinceCategory = letter && occupation && !(sector || province);
    const occupationCityCategory = letter && occupation && province && !sector;

    const sectorProvinceCategory = sector && !(letter || occupation || province);
    const sectorCityCategory = sector && province && !(letter || occupation);

    const provinceCityCategory = province && !(letter || occupation || sector);

    let aggs: string[] = [];
    let filters = {};

    if (initialCategory) {
        aggs = ['occIndex', 'sectors', 'provinces'];
    }

    if (occupationCategory) {
        const occIndexFilter = letter === alphabetNumber
            ? generateIdArray(10).map(String)
            : letter;

        aggs = ['occupations'];
        filters = { 'filter[occIndex]': occIndexFilter };
    }

    if (occupationProvinceCategory) {
        aggs = ['occupations', 'provinces'];
        filters = { 'filter[occupations]': occupation };
    }

    if (occupationCityCategory) {
        aggs = ['occupations', 'cities'];
        filters = {
            'filter[occupations]': occupation,
            'filter[province]': province,
        };
    }

    if (sectorProvinceCategory) {
        aggs = ['provinces'];
        filters = { 'filter[sectors][]': sector };
    }

    if (sectorCityCategory) {
        aggs = ['cities'];
        filters = {
            'filter[sectors][]': sector,
            'filter[province]': province,
        };
    }

    if (provinceCityCategory) {
        aggs = ['cities'];
        filters = { 'filter[province]': province };
    }

    const queryParams = convertToQueryParams({
        'page[size]': 0,
        _aggs: aggs.join(','),
        'filter[vacancy_type]': VacancyType.job,
        ...filters,
    });

    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/vacancies/search?${queryParams}`);

        if (!response.ok) {
            const errorDoc = await response.json();
            const error = errorDoc.errors[0];

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: error?.detail || trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        const aggregation = doc.meta?.aggregation;

        if (!aggregation) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: aggregation,
        };
    } catch (error) {
        console.error('[getJobVacancyIndexAggregationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
