import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import {
    Candidate,
    CandidateResource,
    transformJapiDocumentToCandidate,
    transformToCandidateRequest,
} from '../models/Candidate';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { AtLeast } from '../types';

export const getCandidateApiCall = async (personUuid: string): Promise<FetchResult<Candidate, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/people/${personUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<CandidateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidate = transformJapiDocumentToCandidate(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidate,
        };
    } catch (error) {
        console.error('[getCandidateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchCandidateApiCall = async (data: AtLeast<Candidate, 'uuid'>): Promise<FetchResult<Candidate, string>> => {
    try {
        const candidateRequest = transformToCandidateRequest(data);

        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/people/${data.uuid}`, {
            method: 'PATCH',
            body: JSON.stringify({ data: candidateRequest }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<CandidateResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidate = transformJapiDocumentToCandidate(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidate,
        };
    } catch (error) {
        console.error('[patchCandidateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
