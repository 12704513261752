import React, { FC, ReactElement, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { VacancyType } from '../../../../../_old/app_talentz/models/VacancyType';
import { Icon, Skeleton } from '../../../../../components';
import { encodeSearchQuery } from '../../../../../helpers/search';
import { trans } from '../../../../../helpers/trans';
import { NewestVacancy } from '../../../../../models/NewestVacancy';
import { RoutePaths } from '../../../../../routes';

import './VacancyBreadcrumbs.scss';

interface VacancyBreadcrumb {
    path?: string;
    label: string;
}

interface VacancyBreadcrumbsProps {
    isLoading: boolean;
    vacancy?: NewestVacancy;
    className?: string;
}

const VacancyBreadcrumbs: FC<VacancyBreadcrumbsProps> = ({
    isLoading,
    vacancy,
    className = '',
}): ReactElement => {
    const breadcrumbs = useMemo((): VacancyBreadcrumb[] => {
        if (!vacancy) {
            return [];
        }

        const vacancyTypePath = vacancy.type === VacancyType.internship
            ? RoutePaths.internships()
            : RoutePaths.jobs();

        const city = vacancy.address?.city || vacancy.company?.city || '';

        const citySearchQuery = encodeSearchQuery({ where: city });

        return [
            {
                path: RoutePaths.home(),
                label: trans('navigation.items.home'),
            },
            {
                path: vacancyTypePath,
                label: vacancy.type === VacancyType.internship
                    ? trans('navigation.items.internshipVacancies')
                    : trans('navigation.items.jobVacancies'),
            },
            {
                path: vacancy.type === VacancyType.internship
                    ? RoutePaths.internshipsQuery(citySearchQuery)
                    : RoutePaths.jobsQuery(citySearchQuery),
                label: city,
            },
            {
                path: RoutePaths.companyProfile(vacancy.company.uuid),
                label: vacancy.company.name,
            },
            {
                label: vacancy.title,
            },
        ];
    }, [vacancy]);

    return (
        <div className={`vacancy-breadcrumbs ${className}`}>
            {isLoading && (
                <Skeleton className="vacancy-breadcrumbs__skeleton" />
            )}

            {!isLoading && vacancy && (
                <ul className="vacancy-breadcrumbs__list">
                    {breadcrumbs.map(breadcrumb => {
                        if (!breadcrumb.path) {
                            return (
                                <li key={breadcrumb.label} className="vacancy-breadcrumbs__item">
                                    <span className="vacancy-breadcrumbs__label">
                                        {breadcrumb.label}
                                    </span>
                                </li>
                            );
                        }

                        return (
                            <li key={breadcrumb.path} className="vacancy-breadcrumbs__item">
                                <Link to={breadcrumb.path} className="vacancy-breadcrumbs__link">
                                    {breadcrumb.label}
                                </Link>

                                <Icon name="chevron-right" className="vacancy-breadcrumbs__icon" />
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default VacancyBreadcrumbs;
