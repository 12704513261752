import { Company, CompanySettings } from '../../../_old/app_talentz/models/Company';
import { TabsSideBarChild } from '../../../_old/components/TabsSidebar/models';
import { trans } from '../../../helpers/trans';

export const getEmployeeTabs = (
    location: any,
    company: Company | null,
    settings: CompanySettings | null,
): TabsSideBarChild[] => {
    const state = location.state || null;

    return [
        {
            label: trans('navigation.company.myCompany'),
            to: {
                pathname: '/bedrijf/instellingen/mijn-bedrijf',
                state,
            },
            id: 'company-settings-company',
        },
        {
            label: trans('navigation.company.myInformation'),
            to: {
                pathname: '/bedrijf/instellingen/mijn-gegevens',
                state,
            },
            id: 'company-settings-my-information',
            disabled: !company,
        },
        {
            label: trans('navigation.company.myAccount'),
            to: {
                pathname: '/bedrijf/instellingen/mijn-account',
                state,
            },
            id: 'company-settings-account',
        },
        {
            label: trans('navigation.company.notificationsAndPrivacy'),
            to: {
                pathname: '/bedrijf/instellingen/meldingen-en-privacy',
                state,
            },
            id: 'company-settings-notifications-and-privacy',
            disabled: !company || !settings,
        },
    ];
};
