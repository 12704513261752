import React, { FC, ReactElement } from 'react';

import { IconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { EmailAddressChange } from '../../../../models/EmailAddress';

import './EmailAddressChangeSuccess.scss';

interface EmailAddressChangeSuccessProps {
    isAuthenticated: boolean;
    emailAddressChange: EmailAddressChange;
    onLoginClick: () => void;
    onLogoutClick: () => void;
    className?: string;
}

const EmailAddressChangeSuccess: FC<EmailAddressChangeSuccessProps> = ({
    isAuthenticated,
    emailAddressChange,
    onLoginClick,
    onLogoutClick,
    className = '',
}): ReactElement => (
    <div className={`email-address-change-success ${className}`}>
        <h2 className="email-address-change-success__title">
            {trans('containers.confirmEmailAddressChange.confirmed.title')}
        </h2>

        <p className="email-address-change-success__description">
            {trans('containers.confirmEmailAddressChange.confirmed.description', {
                emailAddress: emailAddressChange.newEmailAddress,
            })}
        </p>

        {isAuthenticated ? (
            <IconButton
                icon="logout"
                text={trans('containers.confirmEmailAddressChange.button.logout')}
                onClick={onLogoutClick}
                className="email-address-change-success__button"
            />
        ) : (
            <IconButton
                icon="login"
                text={trans('containers.confirmEmailAddressChange.button.login')}
                onClick={onLoginClick}
                className="email-address-change-success__button"
            />
        )}
    </div>
);

export default EmailAddressChangeSuccess;
