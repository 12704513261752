import React, { FC, ReactElement, ReactNode } from 'react';

import classnames from 'classnames';
import { createFilter, OnChangeValue } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';

import './Select.scss';

export interface SelectOption {
    value: any;
    label: string;
    type?: string;
}

type SelectComponents = CreatableProps<SelectOption, boolean, any>['components'];

export interface SelectProps {
    error?: boolean;
    name?: string;
    selected?: SelectOption[];
    options: SelectOption[];
    onSelected: (options: SelectOption[]) => void;
    onBlur?: () => void;
    multiSelect?: boolean;
    clearable?: boolean;
    className?: string;
    disabled?: boolean;
    isLoading?: boolean;
    placeholder?: string;
    components?: SelectComponents;
    closeMenuOnSelect?: boolean;
    onCreateOption?: (inputValue: string) => void;
    getNewOptionData?: (inputValue: string, optionLabel: ReactNode) => void;
}

// TODO: Refactor this package-component by a custom one, that relies on native behaviour
const Select: FC<SelectProps> = ({
    selected,
    options,
    onSelected,
    onBlur,
    onCreateOption,
    getNewOptionData,
    placeholder,
    components,
    name,
    clearable = false,
    disabled = false,
    multiSelect = false,
    isLoading = false,
    error = false,
    closeMenuOnSelect = true,
    className = '',
}): ReactElement => {
    const selectClassName = classnames('select', className, {
        'select--error': error,
    });

    const handleChange = (value: OnChangeValue<SelectOption, boolean>): void => {
        if (Array.isArray(value)) {
            onSelected(value);
        } else if (value) {
            // @ts-ignore
            onSelected([value]);
        } else {
            onSelected([]);
        }
    };

    return (
        <Creatable
            name={name}
            components={components}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={multiSelect}
            isClearable={clearable}
            classNamePrefix="select"
            className={selectClassName}
            options={options}
            onChange={handleChange}
            value={selected}
            onBlur={onBlur}
            isDisabled={disabled}
            isLoading={isLoading}
            placeholder={placeholder}
            filterOption={createFilter({ ignoreAccents: false })}
            onCreateOption={onCreateOption}
            isValidNewOption={() => typeof onCreateOption !== 'undefined'}
            backspaceRemovesValue={false}
            // @ts-ignore
            getNewOptionData={getNewOptionData}
        />
    );
};

export default Select;
