import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { SchoolExperience } from '../../../_old/app_talentz/models/SchoolExperience';
import { FormOption } from '../../../types';
import { CheckboxList } from '../..';

export interface InternshipTypeOption extends FormOption {
    educationLevel: string;
}

interface InternshipTypesFilterProps {
    label: string;
    hideLabel?: boolean;
    value: string[];
    schoolExperience?: SchoolExperience;
    options: InternshipTypeOption[];
    tabIndex?: number;
    onChange: (value: string[]) => void;
    className?: string;
}

const InternshipTypesFilter: FC<InternshipTypesFilterProps> = ({
    label,
    hideLabel,
    value,
    schoolExperience,
    options,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => {
    const [internshipTypeOptions, setInternshipTypeOptions] = useState<FormOption[]>(options);

    useEffect((): void => {
        if (!schoolExperience) {
            setInternshipTypeOptions(options);
            return;
        }

        const filteredOptions = options.filter(option => (
            option?.educationLevel === schoolExperience.education_level_id
        ));

        setInternshipTypeOptions(filteredOptions);
    }, [schoolExperience, options]);

    return (
        <div className={`internship-types-filter ${className}`}>
            <CheckboxList
                label={label}
                hideLabel={hideLabel}
                name="internship-types"
                options={internshipTypeOptions}
                value={value}
                tabIndex={tabIndex}
                onChange={onChange}
            />
        </div>
    );
};

export default InternshipTypesFilter;
