import React, { FC, ReactElement, useEffect } from 'react';

import { useToggle } from 'react-use';

import { Checkbox } from '../../../../components';
import { Col, Row } from '../../../styledComponents/components/Grid';
import { Styles } from './CheckboxWide.styles';

interface CheckboxWideProps {
    id: string;
    name: string;
    paragraph: string;
    onCheckboxChange: (checked: boolean) => void;
    value?: boolean;
    className?: string;
}

const CheckboxWide: FC<CheckboxWideProps> = ({
    paragraph,
    onCheckboxChange,
    value = false,
    className = '',
}): ReactElement => {
    const [checked, toggleChecked] = useToggle(value);

    useEffect((): void => {
        onCheckboxChange(checked);
    }, [checked]);

    useEffect((): void => {
        toggleChecked(value);
    }, [value]);

    return (
        <Styles>
            <Row mb="md" className={`checkbox-wide ${className}`}>
                <Col width={1}>
                    <Checkbox
                        label={paragraph}
                        checked={checked}
                        onChange={toggleChecked}
                    />
                </Col>
            </Row>
        </Styles>
    );
};

export default CheckboxWide;
