import { socketReconnectDelay } from '../../constants';
import { trans } from '../../helpers/trans';
import { Chat } from '../../models/Chat';
import { ChatMessage } from '../../models/ChatMessages';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { createChatApiCall, getChatsApiCall } from '../../services/ChatService';
import { initializeChatSocket } from '../../services/WebSocketService';
import { ReducerGetter, TypedDispatch } from '..';
import { broadcastChatMessage } from '../chatMessages/chatMessagesActions';
import { addNegativeToast } from '../toast/toastActions';
import {
    setActiveChat,
    setChatConnections,
    setChats,
    setError,
    setIsLoading,
} from './chats';

export const clearChats = () => (dispatch: TypedDispatch): void => {
    dispatch(setChats([]));
};

export const fetchChats = (isCandidate: boolean) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { chatsReducer } = getState();
        const { activeChat, chatConnections } = chatsReducer;

        const chatsResponse = await getChatsApiCall();

        if (!isFetchResultSuccessful(chatsResponse)) {
            console.error('[fetchChats]', chatsResponse.error);
            dispatch(setError(chatsResponse.error));
            return;
        }

        const chats = chatsResponse.data;

        dispatch(setChats(chats));

        if (!activeChat && chats.length > 0) {
            dispatch(setActiveChat(chats[0]));
        }

        const existingConnectionUuids = chatConnections.map(connection => connection.uuid);
        const chatsToConnect = chats.filter(chat => !existingConnectionUuids.includes(chat.uuid));

        const newChatConnections = chatsToConnect.map(chat => ({
            uuid: chat.uuid,
            socket: initializeChatSocket(chat, isCandidate),
        }));

        dispatch(setChatConnections([
            ...chatConnections,
            ...newChatConnections,
        ]));
    } catch (error) {
        console.error('[fetchChats]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createChat = (isCandidate: boolean, recipientUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const createChatResponse = await createChatApiCall(isCandidate, recipientUuid);

        if (!isFetchResultSuccessful(createChatResponse)) {
            console.error('[createChat]', createChatResponse.error);
            dispatch(setError(createChatResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return;
        }

        const newChat = createChatResponse.data;

        dispatch(setActiveChat(newChat));
        dispatch(fetchChats(isCandidate));
    } catch (error) {
        console.error('[createChat]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const resetChatConnection = (chatUuid: string, chatMessage: ChatMessage) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { chatsReducer } = getState();
    const { chatConnections, chats } = chatsReducer;

    const chatConnectionToReset = chatConnections.find(chatConnection => chatConnection.uuid === chatUuid);
    const chatToReset = chats.find(chat => chat.uuid === chatUuid);

    if (chatConnectionToReset && chatToReset) {
        const remainingChatConnections = chatConnections.filter(chatConnection => chatConnection.uuid !== chatUuid);
        const isCandidate = chatMessage.author.uuid === chatToReset.candidate.uuid;

        const newChatConnection = {
            uuid: chatUuid,
            socket: initializeChatSocket(chatToReset, isCandidate),
        };

        dispatch(setChatConnections([
            newChatConnection,
            ...remainingChatConnections,
        ]));

        setTimeout((): void => {
            dispatch(broadcastChatMessage(newChatConnection, chatMessage));
        }, socketReconnectDelay);
    }
};

export const setChatLatestMessage = (chatUuid: string, messageText: string) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { chatsReducer } = getState();
    const { chats, activeChat } = chatsReducer;

    const chatToUpdate = chats.find(chat => chat.uuid === chatUuid);

    if (chatToUpdate) {
        const latestChat: Chat = {
            ...chatToUpdate,
            isRead: chatUuid === activeChat?.uuid,
            latestMessage: messageText,
        };

        const remainingChats = chats.filter(chat => chat.uuid !== chatUuid);

        dispatch(setChats([
            latestChat,
            ...remainingChats,
        ]));
    }
};

export const setChatReadStatus = (chatUuid: string) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { chatsReducer } = getState();
    const { chats } = chatsReducer;

    const updatedChats = chats.map(chat => {
        if (chat.uuid === chatUuid) {
            return { ...chat, isRead: true };
        }

        return chat;
    });

    dispatch(setChats(updatedChats));
};

export const setChatReportedStatus = (chatUuid: string, isReported: boolean) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const { chatsReducer } = getState();
    const { chats, activeChat } = chatsReducer;

    const updatedChats = chats.map(chat => {
        if (chat.uuid === chatUuid) {
            return { ...chat, isReported };
        }

        return chat;
    });

    dispatch(setChats(updatedChats));

    if (activeChat) {
        const updatedActiveChat = updatedChats.find(chat => chat.uuid === activeChat.uuid);

        if (updatedActiveChat) {
            dispatch(setActiveChat(updatedActiveChat));
        }
    }
};
