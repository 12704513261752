import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 52.2" className={`icon ${className}`}>
        <path d="M50,0l50,44.4l-6.9,7.7L50,13.8L6.9,52.2L0,44.4L50,0z" />
    </svg>
);

export default IconChevronUp;
