import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Avatar, VacancyControls } from '../../../../../compositions';
import { getAgeInDays } from '../../../../../helpers/date';
import { trans } from '../../../../../helpers/trans';
import { Application } from '../../../../../models/Applications';
import { Company } from '../../../../../models/Company';
import { VacanciesInfo } from '../../../../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../../../../models/NewestVacancy';
import { RoleType } from '../../../../../models/User';
import { RoutePaths } from '../../../../../routes';
import { VacancyHeaderSkeletons } from './skeletons';
import { VacancyHeaderCover } from './subcomponents';

import './VacancyHeader.scss';

interface VacancyHeaderProps {
    isLoading: boolean;
    userRole: RoleType;
    userFromCompany: boolean;
    company?: Company;
    vacancy?: NewestVacancy;
    isFavourite?: boolean;
    favouriteIsLoading?: boolean;
    onFavouriteVacancy: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    applications?: Application[];
    applicationsIsLoading?: boolean;
    onFetchApplications?: (vacancyUuid: string) => void;
    onClearApplications?: () => void;
    onArchiveVacancy?: (vacancyUuid: string, applicationUuids: string[]) => void;
    onUnarchiveVacancy?: (vacancyUuid: string) => void;
    onDeleteVacancy?: (vacancyUuid: string) => void;
    className?: string;
}

const VacancyHeader: FC<VacancyHeaderProps> = ({
    isLoading,
    userRole,
    company,
    userFromCompany,
    vacancy,
    isFavourite,
    favouriteIsLoading,
    onFavouriteVacancy,
    applications,
    applicationsIsLoading,
    onFetchApplications,
    onClearApplications,
    onArchiveVacancy,
    onUnarchiveVacancy,
    onDeleteVacancy,
    className = '',
}): ReactElement => (
    <header className={`vacancy-header ${className}`}>
        {isLoading && (
            <VacancyHeaderSkeletons />
        )}

        {!isLoading && company && (
            <VacancyHeaderCover company={company} />
        )}

        {!isLoading && vacancy && (
            <div className="vacancy-header__information-wrapper">
                <Avatar
                    src={vacancy.company.logo}
                    alt={trans('vacancyDetail.header.logoAlt', { company: vacancy.company.name })}
                    fallbackString={vacancy.company.name}
                    className="vacancy-header__avatar"
                />

                <div className="vacancy-header__text-wrapper">
                    <h1 className="vacancy-header__title">
                        {vacancy.title}
                    </h1>

                    <Link
                        to={RoutePaths.companyProfile(vacancy.company.uuid)}
                        className="vacancy-header__company-link"
                    >
                        {trans('vacancyDetail.header.atCompany', {
                            company: vacancy.company.name,
                            city: vacancy.address.city || vacancy.company.city,
                        })}
                    </Link>

                    <p className="vacancy-header__publication-date">
                        {getAgeInDays(vacancy.publicationDate)}
                    </p>
                </div>

                {vacancy && (
                    <VacancyControls
                        isArchived={vacancy.isArchived}
                        userRole={userRole}
                        userFromCompany={userFromCompany}
                        vacancy={vacancy}
                        isFavourite={isFavourite}
                        favouriteIsLoading={favouriteIsLoading}
                        onFavouriteClick={onFavouriteVacancy}
                        applications={applications}
                        applicationsIsLoading={applicationsIsLoading}
                        onFetchApplications={onFetchApplications}
                        onClearApplications={onClearApplications}
                        onArchiveClick={onArchiveVacancy}
                        onUnarchiveClick={onUnarchiveVacancy}
                        onDeleteClick={onDeleteVacancy}
                        className="vacancy-header__controls"
                    />
                )}
            </div>
        )}
    </header>
);

export default VacancyHeader;
