import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCalendar: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 90" className={`icon ${className}`}>
        <path d="M32.1,47.1h5.7l-1.6,3.1l-0.2,0.4v5.6c2-0.4,3.6-0.1,4.7,0.5c0.1,0,0.2,0.1,0.2,0.1c1.4,0.8,2,2.2,1.6,3.7c-0.3,1-0.9,1.7-1.8,2.1c-0.4,0.2-0.9,0.3-1.4,0.3c-0.8,0-1.7-0.1-2.7-0.5c-1-0.4-2.1-0.9-3-1.4c-0.2-0.1-0.4-0.2-0.5-0.3l-2.9,6.1c1,0.9,2.2,1.6,3.5,2.1c0.1,0.1,0.3,0.1,0.4,0.2c1.8,0.7,3.8,1,5.6,0.9c1.3-0.1,2.5-0.3,3.7-0.8c2.9-1.2,5.2-3.6,6-7c1.2-4.7-1-9.1-4.8-11.4c-0.1-0.1-0.2-0.1-0.4-0.2L49.2,40H32.1V47.1z" />
        <path d="M69.9,70h-15v-7.5h3.8v-15h-3.8V40h11.3v22.5h3.8V70z" />
        <path fillRule="evenodd" d="M100,90H0V0h100V90z M10,30v50h80V30H10z M10,22.5V10h80v12.5H10z" />
    </svg>
);

export default IconCalendar;
