import React from 'react';

import { trans } from '../../../../../../../helpers/trans';
import Container from '../../../../../../styledComponents/components/Container';
import { Row } from '../../../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../../../styledComponents/components/Typography';
import { Image } from './style';

const TeamContentBottom = () => (
    <Container pt={['lg', 'lg', 'lg', 'lg', 'xl']}>
        <Container maxWidth="1200px" centered>
            <Row flexDirection="column" maxWidth="630px" mb="xl">
                <Heading size="xl">
                    {trans('infoPage.team.contentBlock2.title')}
                </Heading>
                <Paragraph size="sm">
                    {trans('infoPage.team.contentBlock2.text')}
                </Paragraph>
            </Row>
            <Row mb="xl">
                <Image height="60%" src="images/team-connect.jpg" alt={trans('infoPage.team.contentBlock2.imageAlt')} />
            </Row>
        </Container>
    </Container>
);

export default TeamContentBottom;
