import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../../helpers/trans';
import arrayEquals from '../../../helpers/arrayEquals';
import {
    Label,
    ListLabel,
    ListLabelText,
    Native,
    TextLabel,
    Wrapper,
} from '../../../styledComponents/components/BlockInputList';
import Loader from '../../Loader';
import Tooltip from '../../Tooltip';
import { Error, OptionalLabel } from './style';

class BlockRadioList extends Component {
    constructor() {
        super();

        this.state = {
            selected: null,
            showErrors: true,
        };
    }

    componentDidMount() {
        this.props.selected && this.setSelectedFromProps(this.props.selected);
    }

    componentDidUpdate(prevProps, prevState) {
        (prevProps.selected !== this.props.selected) && this.props.selected
        && this.setSelectedFromProps(this.props.selected);

        (prevState.selected !== this.state.selected) && !this.state.selected
        && this.showErrors(true);

        (!arrayEquals(prevProps.errors, this.props.errors) && !prevState.showErrors)
        && this.showErrors();

        (prevProps.options && !arrayEquals(prevProps.options, this.props.options))
        && this.setSelectedFromProps(this.props.selected);
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    clear() {
        this.setState({
            selected: null,
            showErrors: true,
        });
    }

    showErrors(toggle = false) {
        this.setState({ showErrors: toggle });
    }

    setSelectedFromProps(selected) {
        this.setState({ selected });
    }

    onChange = e => {
        this.setState({ selected: e.target.value });
        this.showErrors(false);
        this.props.onChange(e);
    };

    render() {
        const {
            selected,
            showErrors,
        } = this.state;

        const {
            label,
            name,
            options,
            optionsLoading,
            optional,
            required,
            keyVal,
            errors,
            disabled,
            tooltip,
            tooltipId,
            tooltipAvailable,
            labelClassName,
            wrapperClassName,
            className,
        } = this.props;

        return (
            <div className={`block-radio-list ${className}`}>
                {label && (
                    <ListLabel>
                        <ListLabelText>
                            {label}
                            {required && '*'}
                        </ListLabelText>
                        {optional && <OptionalLabel>{trans('forms.optional')}</OptionalLabel>}
                        {(tooltip && tooltipAvailable) && (
                            <Tooltip content={tooltip} id={tooltipId || tooltip} />
                        )}
                    </ListLabel>
                )}
                <Wrapper id={`block-radio-list-wrapper-${name}`} className={wrapperClassName} errors={errors && showErrors}>
                    {optionsLoading && (
                        <Loader justifyContent="flex-start" marginTop={10} noText />
                    )}
                    {options && options.map(option => (
                        <Label
                            key={`${name}-${option[keyVal[1]]}`}
                            className={`block-radio-list-label-${name} ${labelClassName}`}
                            id={`radio-label-${name}-${option[keyVal[1]]}`}
                            htmlFor={`${name}-${option[keyVal[1]]}`}
                        >
                            <Native
                                type="radio"
                                name={name}
                                id={`${name}-${option[keyVal[1]]}`}
                                checked={option[keyVal[1]] === selected}
                                value={option[keyVal[1]]}
                                onChange={this.onChange}
                                disabled={disabled}
                            />
                            <TextLabel
                                data-hj-suppress
                                checked={option[keyVal[1]] === selected}
                                disabled={disabled}
                                className="block-radio-list__label-text"
                            >
                                {option[keyVal[0]]}
                            </TextLabel>
                        </Label>
                    ))}
                </Wrapper>
                <Error errors={!!errors && showErrors}>
                    {(!!errors && showErrors) && <span key={`${name} - ${errors[0]}`}>{errors[0]}<br /></span>}
                </Error>
            </div>
        );
    }
}

BlockRadioList.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    optionsLoading: PropTypes.bool,
    selected: PropTypes.string,
    onChange: PropTypes.func,
    optional: PropTypes.bool,
    keyVal: PropTypes.array,
    errors: PropTypes.array,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipId: PropTypes.string,
    tooltipAvailable: PropTypes.bool,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
};

BlockRadioList.defaultProps = {
    optionsLoading: false,
    required: false,
    selected: null,
    label: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    optional: false,
    keyVal: ['name', 'uuid'],
    errors: null,
    disabled: false,
    tooltip: null,
    tooltipId: null,
    tooltipAvailable: true,
    labelClassName: '',
    wrapperClassName: '',
    className: '',
};

export default BlockRadioList;
