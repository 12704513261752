import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CompetenciesCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { CompetenciesFormData } from '../../../models/Competencies';
import { EscoOccupation } from '../../../models/Esco';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCandidate, fetchCandidate, patchCandidate } from '../../../store/candidate/candidateActions';
import { fetchCompetencies } from '../../../store/competencies/competenciesActions';
import { clearEscoSuggestions, fetchEscoOccupations, fetchSuggestedCompetencies } from '../../../store/escoOccupations/escoOccupationsActions';

interface ConnectedCompetenciesCardProps {
    activationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedCompetenciesCard: FC<ConnectedCompetenciesCardProps> = ({
    activationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const competencies = useTypedSelector(state => state.competenciesReducer.competencies);

    const escoOccupations = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const suggestionsIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const suggestedCompetencies = useTypedSelector(state => state.escoOccupationsReducer.suggestedCompetencies);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    useEffect((): () => void => {
        const candidateId = candidateUuid || person?.uuid;

        if (shouldFetchCandidate && candidateId) {
            dispatch(fetchCandidate(candidateId));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());

            dispatch(clearEscoSuggestions());
        };
    }, []);

    useEffect((): void => {
        if (isEditable) {
            if (competencies.length === 0) dispatch(fetchCompetencies());
            if (escoOccupations.length === 0) dispatch(fetchEscoOccupations());
        }
    }, [isEditable]);

    const handleSelectOccupation = (occupation: EscoOccupation): void => {
        dispatch(fetchSuggestedCompetencies(occupation.id));
    };

    const handleClearSuggestions = (): void => {
        dispatch(clearEscoSuggestions());
    };

    const handleEditCompetencies = (competenciesFormData: CompetenciesFormData): void => {
        if (candidate) {
            dispatch(patchCandidate({
                uuid: candidate.uuid,
                competencies: competenciesFormData.competencies,
            }));
        }
    };

    return (
        <CompetenciesCard
            activationRef={activationRef}
            isLoading={isLoading}
            suggestionsIsLoading={suggestionsIsLoading}
            isEditable={isEditable}
            competencies={candidate?.competencies}
            competencyOptions={competencies}
            suggestedCompetencies={suggestedCompetencies}
            occupationOptions={escoOccupations}
            onSelectOccupation={handleSelectOccupation}
            onClearSuggestions={handleClearSuggestions}
            onEdit={handleEditCompetencies}
            className={className}
        />
    );
};

export default ConnectedCompetenciesCard;
