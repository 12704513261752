import React, { FC, ReactElement } from 'react';

import { ApplicationAppointment, ApplicationAppointmentFormData } from '../../../models/ApplicationAppointments';
import { ApplicationInvite, ApplicationInviteStatus } from '../../../models/ApplicationInvites';
import { ApplicationRejectionFormData } from '../../../models/ApplicationRejections';
import { Application, ApplicationStatus } from '../../../models/Applications';
import {
    ApplicationStatusAccepted,
    ApplicationStatusDeclined,
    ApplicationStatusHired,
    ApplicationStatusInvited,
    ApplicationStatusInviteRejected,
    ApplicationStatusInviteSent,
    ApplicationStatusWaiting,
} from './subcomponents';

interface ApplicationStatusCardProps {
    isLoading: boolean;
    isCandidate?: boolean;
    application?: Application;
    applicationInvite?: ApplicationInvite;
    onAddApplicationInvite: (applicationUuid: string, formData: ApplicationAppointmentFormData) => void;
    onAcceptApplicationInvite: (appointment: ApplicationAppointment, inviteUuid: string) => void;
    onDeclineApplicationInvite?: (applicationInvite: ApplicationInvite) => void;
    onRejectApplication?: (applicationUuid: string, formData: ApplicationRejectionFormData) => void;
    className?: string;
}

const ApplicationStatusCard: FC<ApplicationStatusCardProps> = ({
    isLoading,
    isCandidate,
    application,
    applicationInvite,
    onAddApplicationInvite,
    onAcceptApplicationInvite,
    onDeclineApplicationInvite,
    onRejectApplication,
    className = '',
}): ReactElement => {
    const applicationStatusIsAccepted = application?.status === ApplicationStatus.accepted;
    const applicationStatusIsInvited = application?.status === ApplicationStatus.invited;
    const statusIsInvite = (applicationStatusIsInvited || applicationStatusIsAccepted) && applicationInvite;

    const isInviteFromCompany = statusIsInvite && !applicationInvite?.isFromApplicant && !isCandidate;
    const isInviteFromApplicant = statusIsInvite && applicationInvite?.isFromApplicant && isCandidate;

    const inviteStatusIsWaiting = statusIsInvite && applicationInvite?.status === ApplicationInviteStatus.waiting;
    const inviteStatusIsAccepted = statusIsInvite && applicationInvite?.status === ApplicationInviteStatus.accepted;
    const inviteStatusIsRejected = statusIsInvite && applicationInvite?.status === ApplicationInviteStatus.rejected;

    const hasSentInvite = isInviteFromCompany || isInviteFromApplicant;

    return (
        <div className={`application-status-card ${className}`}>
            {inviteStatusIsWaiting && (
                hasSentInvite ? (
                    <ApplicationStatusInviteSent
                        isCandidate={isCandidate}
                        application={application}
                        activeInvite={applicationInvite}
                    />
                ) : (
                    <ApplicationStatusInvited
                        isLoading={isLoading}
                        isCandidate={isCandidate}
                        application={application}
                        activeInvite={applicationInvite}
                        onAcceptInvite={onAcceptApplicationInvite}
                        onChangeInvite={onAddApplicationInvite}
                        onDeclineInvite={onDeclineApplicationInvite}
                        onRejectApplication={onRejectApplication}
                    />
                )
            )}

            {inviteStatusIsAccepted && (
                <ApplicationStatusAccepted
                    isLoading={isLoading}
                    isCandidate={isCandidate}
                    application={application}
                    activeInvite={applicationInvite}
                    onChangeInvite={onAddApplicationInvite}
                    onDeclineInvite={onDeclineApplicationInvite}
                    onRejectApplication={onRejectApplication}
                />
            )}

            {inviteStatusIsRejected && (
                <ApplicationStatusInviteRejected
                    isLoading={isLoading}
                    isCandidate={isCandidate}
                    application={application}
                    activeInvite={applicationInvite}
                    onChangeInvite={onAddApplicationInvite}
                    onRejectApplication={onRejectApplication}
                />
            )}

            {application?.status === ApplicationStatus.waiting && (
                <ApplicationStatusWaiting
                    isLoading={isLoading}
                    isCandidate={isCandidate}
                    application={application}
                    onInvite={onAddApplicationInvite}
                    onRejection={onRejectApplication}
                />
            )}

            {application?.status === ApplicationStatus.declined && (
                <ApplicationStatusDeclined isCandidate={isCandidate} application={application} />
            )}

            {application?.status === ApplicationStatus.hired && (
                <ApplicationStatusHired isCandidate={isCandidate} />
            )}
        </div>
    );
};

export default ApplicationStatusCard;
