import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconStaticHamburger: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 66.7" className={`icon ${className}`}>
        <rect width="100" height="11.1" />
        <rect y="27.8" width="100" height="11.1" />
        <rect y="55.6" width="100" height="11.1" />
    </svg>
);

export default IconStaticHamburger;
