import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCameraAdd: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 85" className={`icon ${className}`}>
        <path d="M10,30V20H0V10h10V0h10v10h10v10H20v10H10z" />
        <path d="M100,85V20H80l-7.5-10H40v10h27.5L75,30h15v45H20V40H10v45H100z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M55,35c-8.3,0-15,6.7-15,15s6.7,15,15,15s15-6.7,15-15S63.3,35,55,35z M47.5,50c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5S47.5,54.1,47.5,50z" />
    </svg>
);

export default IconCameraAdd;
