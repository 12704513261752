import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { transformToWorkRemoteOption, WorkRemoteOption, WorkRemoteResource } from '../models/WorkRemote';

export const getWorkRemoteOptionsApiCall = async (): Promise<FetchResult<WorkRemoteOption[], string>> => {
    try {
        const workRemoteResponse = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/remote-working`);

        if (!workRemoteResponse.ok) {
            return {
                status: workRemoteResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await workRemoteResponse.json();

        if (!isResourceCollectionDocument<WorkRemoteResource>(doc)) {
            return {
                status: workRemoteResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const workRemoteOptions = doc.data.map(transformToWorkRemoteOption);

        return {
            status: workRemoteResponse.status,
            type: FetchResultType.Success,
            data: workRemoteOptions,
        };
    } catch (error) {
        console.error('[getWorkRemoteOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
