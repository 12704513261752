import { trans } from '../../helpers/trans';

export default function validatePhoneNumber(value: string): string[] {
    if (!value) {
        return [trans('validation.phone.format')];
    }

    const digits = value.match(/\d/g);

    if (digits && digits.length >= 10) {
        return [];
    }

    return [trans('validation.phone.format')];
}
