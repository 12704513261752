export const sortByCreatedAtDateAsc = <Interface extends { createdAt: Date }>(entry: Interface, comparisonEntry: Interface): number => (
    entry.createdAt.getTime() - comparisonEntry.createdAt.getTime()
);

export const sortByCreatedAtDateDesc = <Interface extends { createdAt: Date }>(entry: Interface, comparisonEntry: Interface): number => (
    comparisonEntry.createdAt.getTime() - entry.createdAt.getTime()
);

export const sortByStartDate = <Interface extends { startDate: Date }>(entry: Interface, comparisonEntry: Interface): number => (
    entry.startDate.getTime() - comparisonEntry.startDate.getTime()
);

export const sortByEndDate = <Interface extends { endDate: Date }>(entry: Interface, comparisonEntry: Interface): number => (
    comparisonEntry.endDate.getTime() - entry.endDate.getTime()
);
