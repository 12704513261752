import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/form/TextInput';
import { validateEmail } from '../../../../helpers/validation';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { Heading, Paragraph } from '../../../../styledComponents/components/Typography';

class PasswordForgetForm extends Component {
    constructor() {
        super();

        this.state = {
            filledForm: false,
        };
    }

    onEmailChange = e => {
        this.setState({
            filledForm: validateEmail(e.currentTarget.value),
        });
    };

    onSubmit = e => {
        e.preventDefault();
        const data = serialize(e.currentTarget, { hash: true });
        this.props.onSubmit(data);
    };

    render() {
        const { errors, loading } = this.props;
        const { filledForm } = this.state;
        return (
            <form id="password-forget-form" onSubmit={this.onSubmit}>
                <Heading as="h2" size="xl" mb="md">{trans('forms.passwordForget.title')}</Heading>
                <Paragraph mb="md">{trans('forms.passwordForget.subText')}</Paragraph>
                <Row>
                    <Col mb="md" width={1}>
                        <TextInput
                            errors={errors.email}
                            label={trans('forms.user.email.label')}
                            name="email"
                            placeholder={trans('forms.user.email.placeholder')}
                            icon="mail"
                            onChange={this.onEmailChange}
                        />
                    </Col>
                </Row>
                <Row flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} width={1} alignItems="center" justifyContent="flex-end">
                    <Col mb="md">
                        <Button
                            disabled={!filledForm || loading}
                            isLoading={loading}
                            type="submit"
                            id="password-forget-submit"
                        >
                            {trans('forms.passwordForget.buttonText')}
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}

PasswordForgetForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

PasswordForgetForm.defaultProps = {
    errors: {},
};

export default PasswordForgetForm;
