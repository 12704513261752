import { addDays, differenceInDays } from 'date-fns';

import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { validateRequiredProperty, validateRequiredString } from '../../../_old/app_talentz/services/ValidationService';
import { maxLongTextLength, maxVacancyLifetimeInDays } from '../../../constants';
import { formatDate } from '../../../helpers/date';
import { trans } from '../../../helpers/trans';
import { PlaceInternshipVacancyFormValues } from '../../../services/PlaceVacancyService';
import { validateMaxStringLength } from '../../../services/ValidationService';

export function validateInternshipVacancyDataStep(formValues: PlaceInternshipVacancyFormValues, checkAll = false): ValidationErrors {
    const internshipVacancyLifetimeInDays = maxVacancyLifetimeInDays;
    const errors: ValidationErrors = {};

    const sectorErrors: (string | null)[] = [validateRequiredString('sector', formValues.sectorId)];

    errors.title = validateRequiredProperty('title', formValues.title, checkAll);
    errors.address = validateRequiredProperty('address', (formValues.address && formValues.address.address), checkAll);
    errors.publicationDate = validateRequiredProperty('publication_date', formValues.publicationDate, checkAll);
    errors.expirationDate = validateRequiredProperty('expiration_date', formValues.expirationDate, checkAll);
    errors.educationLevel = validateRequiredProperty('education_level', formValues.educationLevelId, checkAll);
    errors.internshipType = validateRequiredProperty('internship_type', formValues.internshipTypeId, checkAll);
    errors.grades = validateRequiredProperty('grades', formValues.gradeIds, checkAll);
    errors.fieldsOfStudy = validateRequiredProperty('fields_of_study', formValues.fieldsOfStudyIds, checkAll);
    errors.periodFrom = validateRequiredProperty('period_from', formValues.periodFrom, checkAll);
    errors.periodTill = validateRequiredProperty('period_till', formValues.periodTill, checkAll);
    errors.description = validateRequiredProperty('description', formValues.description, checkAll);

    const description = validateMaxStringLength('vacancyText', formValues.description, maxLongTextLength);
    errors.description = description ? [description] : [];

    errors.sectors = sectorErrors.filter(error => error !== null) as string[];
    errors.workRemotePreference = validateRequiredProperty('work_remote_preference', formValues.workRemotePreferenceId, checkAll);

    if (formValues.minCompensation === null && formValues.maxCompensation !== null) {
        errors.compensation = [trans('forms.vacancy.grossSalary.minValue.error')];
    } else if ((formValues.minCompensation !== null && formValues.maxCompensation === null)
        || (formValues.minCompensation !== null && formValues.maxCompensation !== null && formValues.maxCompensation < formValues.minCompensation)) {
        errors.compensation = [trans('forms.vacancy.grossSalary.maxValue.error')];
    }

    if (formValues.publicationDate && formValues.expirationDate) {
        const maxExpireDate = addDays(formValues.publicationDate, internshipVacancyLifetimeInDays);
        const dayDiffExpireDate = differenceInDays(maxExpireDate, formValues.expirationDate);

        if (formValues.expirationDate.getTime() <= formValues.publicationDate.getTime()) {
            errors.expirationDate = [
                trans('forms.vacancy.expirationDate.errors.beforePublishDate'),
            ];
        }

        if (dayDiffExpireDate < 0) {
            errors.expirationDate = [
                trans('forms.vacancy.expirationDate.errors.afterLifetime', {
                    internshipVacancyLifetimeInDays,
                    date: formatDate(maxExpireDate),
                }),
            ];
        }
    }

    if (formValues.periodFrom && formValues.periodTill) {
        if (formValues.periodTill.getTime() <= formValues.periodFrom.getTime()) {
            errors.periodFrom = [
                trans('forms.vacancy.period.from.errors.beforePeriodTill'),
            ];
        }
    }

    Object.keys(errors).forEach(errorKey => {
        if (!errors[errorKey].length) {
            delete errors[errorKey];
        }
    });

    return errors;
}
