import React, { useEffect, useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';

import { Loader, MonthYearInput } from '../../../../../compositions';
import { maxEmploymentYears } from '../../../../../constants';
import { trans } from '../../../../../helpers/trans';
import { RoleType } from '../../../../../models/User';
import BlockRadioList from '../../../../components/form/BlockRadioList/BlockRadioList';
import CheckboxWide from '../../../../components/form/CheckboxWide/CheckboxWide';
import ReactSelect from '../../../../components/form/ReactSelect/ReactSelect';
import TextInput from '../../../../components/form/TextInput';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import { EducationLevel } from '../../../models/SchoolExperience';
import { CURRENT_YEAR, getISODateFormat } from '../../../services/DateService';

// Dit kutformulier mag helemaal opnieuw. Niet getest en veel 'bugfixes' hebben alleen maar voor meer bugs gezorgd
const EducationFormAssets = props => {
    const {
        errors,
        onChange,
        schoolExperience,
        disabledFormNames,
        userRole,
    } = props;

    const isStudent = userRole === RoleType.student;

    const [isLoading, setIsLoading] = useState(true);
    const [optionsLoading, setOptionsLoading] = useState(true);
    const [selectedEducationLevel, setSelectedEducationLevel] = useState(null);
    const [educationLevelOptions, setEducationLevelOptions] = useState(null);
    const [formValues, setFormValues] = useState({
        field_of_study_id: null,
        learningPathSlug: 'mbo',
        start_date: `${CURRENT_YEAR}-09-01`,
        end_date: `${CURRENT_YEAR + 4}-06-01`,
    });
    const [showCustomEducationLevelField, setShowCustomEducationLevelField] = useState(schoolExperience?.educationLevel?.manual);
    const [showCustomSchoolField, setShowCustomSchoolField] = useState(schoolExperience?.school?.manual);
    const [showCustomFieldOfStudyField, setShowCustomFieldOfStudyField] = useState(schoolExperience?.field_of_study?.manual);
    const CUSTOM_EDUCATION_LEVEL_UUID = 'education_level_different';

    const selectedEducationLevelOption = educationLevelOptions && educationLevelOptions.filter(option => option.uuid === selectedEducationLevel)[0];

    useEffect(() => {
        if (showCustomEducationLevelField) {
            setShowCustomSchoolField(true);
            setShowCustomFieldOfStudyField(true);

            return;
        }

        setShowCustomSchoolField(schoolExperience?.school?.manual);
        setShowCustomFieldOfStudyField(schoolExperience?.field_of_study?.manual);
    }, [showCustomEducationLevelField]);

    function setFormValueFor(name, value) {
        const newFormValues = { ...formValues };
        newFormValues[name] = value;
        setFormValues(newFormValues);
        onChange(newFormValues);
    }

    function setFormDateValueFor(name, value) {
        const convertedValue = getISODateFormat(value);
        setFormValueFor(name, convertedValue);
    }

    function getEducationLevelOptions() {
        axios.get('education-levels').then(options => {
            setEducationLevelOptions(options.data.data);
        });
    }

    function getOptionsForMBO(educationLevelUuid) {
        return axios.all([
            axios.get(`education-levels/${educationLevelUuid}/fields-of-study`),
            axios.get(`education-levels/${educationLevelUuid}/learning-paths`),
            axios.get(`schools/education-levels/${educationLevelUuid}`),
        ]).then(axios.spread((fieldsOfStudy, learningPaths, schools) => (
            educationLevelOptions.map(option => {
                if (option.uuid === educationLevelUuid) {
                    const newOption = { ...option };
                    newOption.fieldsOfStudy = fieldsOfStudy.data.data;
                    newOption.learningPaths = learningPaths.data.data;
                    newOption.schools = schools.data.data.filter(school => !school.manual);

                    return newOption;
                }

                return option;
            })
        )));
    }

    function getOptionsForHBO(educationLevelUuid) {
        return axios.all([
            axios.get(`education-levels/${educationLevelUuid}/fields-of-study`),
            axios.get(`schools/education-levels/${educationLevelUuid}`),
        ]).then(axios.spread((fieldsOfStudy, schools) => (
            educationLevelOptions.map(option => {
                if (option.uuid === educationLevelUuid) {
                    const newOption = { ...option };
                    newOption.fieldsOfStudy = fieldsOfStudy.data.data;
                    newOption.schools = schools.data.data.filter(school => !school.manual);

                    return newOption;
                }

                return option;
            })
        )));
    }

    async function getOptionsOfSelectedEducationLevel() {
        if (selectedEducationLevelOption && selectedEducationLevelOption.fieldsOfStudy) {
            return;
        }

        setOptionsLoading(true);

        if (selectedEducationLevelOption && selectedEducationLevelOption.slug === 'mbo') {
            const response = await getOptionsForMBO(selectedEducationLevelOption.uuid);

            setEducationLevelOptions(response);
            setOptionsLoading(false);
            isLoading && setIsLoading(false);

            return;
        }

        if (selectedEducationLevelOption && selectedEducationLevelOption.slug === EducationLevel.Hbo) {
            const response = await getOptionsForHBO(selectedEducationLevelOption.uuid);

            setEducationLevelOptions(response);
            setOptionsLoading(false);
            isLoading && setIsLoading(false);

            return;
        }

        setOptionsLoading(false);
        isLoading && setIsLoading(false);
    }

    function onEducationLevelChange(educationLevelId) {
        const preservedValues = {
            uuid: formValues.uuid || undefined,
            start_date: formValues.start_date || undefined,
            end_date: formValues.end_date || undefined,
            field_of_study_id: formValues.field_of_study?.manual
                ? formValues?.field_of_study?.uuid || formValues?.field_of_study_id
                : undefined,
            field_of_study_name: formValues.field_of_study?.manual
                ? formValues.field_of_study?.name || formValues?.field_of_study_name
                : undefined,
        };

        const newFormValues = {
            education_level_id: educationLevelId === CUSTOM_EDUCATION_LEVEL_UUID ? null : educationLevelId,
            education_level_name: null,
            school_id: null,
            school_name: null,
            learning_path_id: null,
            field_of_study_id: null,
            field_of_study_name: null,
            learningPathSlug: educationLevelId === CUSTOM_EDUCATION_LEVEL_UUID ? 'different' : educationLevelOptions.find(option => option.uuid === educationLevelId)?.slug,
            ...preservedValues,
        };

        if (educationLevelId === CUSTOM_EDUCATION_LEVEL_UUID || educationLevelOptions.find(option => option.uuid === educationLevelId) === undefined) {
            setFormValues({
                ...formValues,
                education_level_id: null,
                learning_path_id: null,
            });

            setShowCustomEducationLevelField(true);
            setIsLoading(false);
            setOptionsLoading(false);

            return;
        }

        setFormValueFor('education_level_id', educationLevelId);
        setFormValueFor('education_level_name', null);
        setShowCustomEducationLevelField(false);

        // School experience is already given, we should not reset the form values
        if (selectedEducationLevel === null && (schoolExperience?.education_level_id)) {
            if (educationLevelOptions.find(option => option.uuid === educationLevelId)) {
                setFormValues({
                    ...schoolExperience,
                    learningPathSlug: educationLevelOptions.find(option => option.uuid === educationLevelId).slug,
                });
            } else {
                setFormValues({
                    ...schoolExperience,
                    learningPathSlug: educationLevelOptions.find(option => option.uuid === 'education_level_different').slug,
                });
            }

            onChange(schoolExperience);
        } else {
            setFormValues(newFormValues);
            onChange(newFormValues);
        }

        setSelectedEducationLevel(educationLevelId);
    }

    /**
     * Filters out all education level options exepct for the one that is selected
     * This is mostly used for an invited user (SVS)
     *
     * @returns
     */
    function getFilteredEducationLevelOptions() {
        if (!disabledFormNames.includes('education_level_id')) {
            return educationLevelOptions;
        }

        if (!selectedEducationLevelOption.uuid) {
            return educationLevelOptions;
        }

        return educationLevelOptions.filter(option => option.uuid === selectedEducationLevelOption.uuid);
    }

    // first get education level options
    useEffect(() => {
        getEducationLevelOptions();
    }, []);

    // then set the selected education level by user input of default
    useEffect(() => {
        if (educationLevelOptions) {
            onEducationLevelChange(schoolExperience ? schoolExperience.education_level_id : educationLevelOptions[0].uuid);
        }
    }, [!!educationLevelOptions]);

    // get all options for the selected education level
    useEffect(() => {
        if (selectedEducationLevel) {
            getOptionsOfSelectedEducationLevel();
        }
    }, [selectedEducationLevel]);

    useEffect(() => {
        if (schoolExperience) {
            setFormValues({
                ...schoolExperience,
                education_level_name: schoolExperience?.education_level?.name,
            });
        }
    }, [schoolExperience?.uuid]);

    function onCustomFieldOfStudyChecked(checked) {
        if (checked === showCustomFieldOfStudyField) {
            return;
        }

        setShowCustomFieldOfStudyField(checked);

        if (checked) {
            setFormValueFor('field_of_study_name', schoolExperience?.field_of_study?.manual ? schoolExperience.field_of_study.name : null);
            setFormValueFor('field_of_study_id', null);
            return;
        }

        let fieldOfStudyId = null;

        if (schoolExperience && schoolExperience.field_of_study && !schoolExperience.field_of_study.manual) {
            fieldOfStudyId = schoolExperience.field_of_study.uuid;
        }

        setFormValueFor('field_of_study_name', null);
        setFormValueFor('field_of_study_id', fieldOfStudyId);
    }

    const schoolExperienceColumn = isStudent ? [1, 1, 1, 1, 2 / 3] : 1;
    const schoolExperiencePadding = isStudent ? [0, 0, 0, 0, 'sm'] : 'none';
    const fieldOfStudyColumnWidth = selectedEducationLevelOption && selectedEducationLevelOption.learningPaths && isStudent ? [2 / 3] : 1;
    const fieldOfStudyColumnPaddingLeft = selectedEducationLevelOption && selectedEducationLevelOption.learningPaths && isStudent ? [0, 0, 0, 0, 'sm'] : 0;

    return isLoading ? (
        <Loader />
    ) : (
        <>
            {showCustomEducationLevelField && (
                <Row mb="md">
                    <Col width={1}>
                        <TextInput
                            errors={errors['school_experiences.education_level_id' || errors.education_level_id]}
                            label="Niveau handmatig invoeren"
                            placeholder={trans('forms.schoolExperience.educationLevel.label')}
                            name="education_level_id"
                            defaultValue={schoolExperience?.education_level?.manual ? schoolExperience.education_level.name : null}
                            onChange={value => setFormValueFor('education_level_name', value.currentTarget.value)}
                            required
                        />
                    </Col>
                </Row>
            )}
            <Row flexDirection={['column', 'column', 'column', 'column', 'row']}>
                {isStudent && educationLevelOptions && (
                    <Col mb="md" width={[1, 1, 1, 1, 1 / 3]}>
                        <BlockRadioList
                            errors={errors['school_experiences.education_level_id' || errors.education_level_id]}
                            options={getFilteredEducationLevelOptions()}
                            label={trans('forms.schoolExperience.educationLevel.label')}
                            name="education_level_id"
                            selected={selectedEducationLevelOption.uuid}
                            disabled={optionsLoading || disabledFormNames.includes('education_level_id')}
                            onChange={e => onEducationLevelChange(e.currentTarget.value)}
                            required
                        />
                    </Col>
                )}
                {(isStudent || !showCustomEducationLevelField) && (
                    <Col mb="md" width={schoolExperienceColumn} pl={schoolExperiencePadding}>
                        <ReactSelect
                            id="school"
                            errors={errors['school_experiences.school_id'] || errors.school_id}
                            label={trans('forms.schoolExperience.school.label')}
                            placeholder={trans('forms.schoolExperience.school.placeholder')}
                            name="school_id"
                            disabled={showCustomSchoolField || disabledFormNames.includes('school_id')}
                            options={selectedEducationLevelOption?.schools}
                            optionsLoading={optionsLoading}
                            selected={(!showCustomSchoolField && !schoolExperience?.school?.manual) ? formValues.school_id : null}
                            onChange={value => setFormValueFor('school_id', value)}
                            required
                        />
                    </Col>
                )}
            </Row>

            {showCustomSchoolField && (
                <Row mb="md">
                    <Col width={1}>
                        <TextInput
                            errors={errors['school_experiences.school_name'] || errors.school_name}
                            label={trans('forms.schoolExperience.school.custom.label')}
                            placeholder={trans('forms.schoolExperience.school.custom.placeholder')}
                            name="school_name"
                            defaultValue={schoolExperience?.school?.manual ? schoolExperience.school.name : null}
                            onChange={value => setFormValueFor('school_name', value.currentTarget.value)}
                            required
                        />
                    </Col>
                </Row>
            )}
            <Row flexDirection={['column', 'column', 'column', 'column', 'row']}>
                {isStudent && selectedEducationLevelOption.learningPaths && (
                    <Col mb="md" width={1 / 3}>
                        <BlockRadioList
                            errors={errors['school_experiences.learning_path_id'] || errors.learning_path_id}
                            options={selectedEducationLevelOption.learningPaths}
                            label={trans('forms.schoolExperience.learningPath.label')}
                            name="learning_path_id"
                            selected={formValues.learning_path_id}
                            tooltip={trans('forms.schoolExperience.learningPath.toolTip')}
                            tooltipId="internship_types_tooltip"
                            onChange={e => setFormValueFor('learning_path_id', e.currentTarget.value)}
                            required
                        />
                    </Col>
                )}
                {(isStudent || !showCustomEducationLevelField) && (
                    <Col mb="md" width={fieldOfStudyColumnWidth} pl={fieldOfStudyColumnPaddingLeft}>
                        {selectedEducationLevelOption?.fieldsOfStudy && (
                            <ReactSelect
                                id="field_of_study"
                                errors={errors['school_experiences.field_of_study_id'] || errors.field_of_study_id}
                                label={trans('forms.schoolExperience.fieldOfStudy.label')}
                                placeholder={trans('forms.schoolExperience.fieldOfStudy.placeholder')}
                                name="field_of_study_id"
                                disabled={showCustomFieldOfStudyField}
                                options={selectedEducationLevelOption.fieldsOfStudy}
                                optionsLoading={optionsLoading}
                                selected={(!showCustomFieldOfStudyField && !schoolExperience?.field_of_study?.manual) ? formValues.field_of_study_id : null}
                                onChange={value => setFormValueFor('field_of_study_id', value)}
                                required
                            />
                        )}
                    </Col>
                )}
            </Row>
            {showCustomFieldOfStudyField && (
                <Row mb="md">
                    <Col width={1}>
                        <TextInput
                            errors={errors['school_experiences.field_of_study_name'] || errors.field_of_study_name}
                            label={trans('forms.schoolExperience.field_of_study.custom.label')}
                            placeholder={trans('forms.schoolExperience.field_of_study.custom.placeholder')}
                            name="field_of_study_name"
                            defaultValue={schoolExperience?.field_of_study?.manual ? schoolExperience.field_of_study.name : null}
                            onChange={value => setFormValueFor('field_of_study_name', value.currentTarget.value)}
                            required
                        />
                    </Col>
                </Row>
            )}
            {!showCustomEducationLevelField && (
                <CheckboxWide
                    id="custom-field-of-study-checkbox"
                    name="custom-field-of-study-checkbox"
                    paragraph={trans('forms.schoolExperience.field_of_study.custom.checkbox')}
                    onCheckboxChange={checked => onCustomFieldOfStudyChecked(checked)}
                    value={showCustomFieldOfStudyField}
                />
            )}
            <Row flexDirection={['column', 'column', 'column', 'column', 'row']} alignItems="flex-start">
                <Col mb="md" width={[1, 1, 1, 1 / 2]} pr={[0, 0, 0, 'sm']}>
                    <MonthYearInput
                        label={trans('forms.schoolExperience.start_date')}
                        name="start-date"
                        required
                        value={formValues.start_date
                            ? new Date(formValues.start_date)
                            : undefined}
                        minYear={new Date().getFullYear() - maxEmploymentYears}
                        maxYear={new Date().getFullYear()}
                        error={errors['school_experiences.start_date'] || errors.start_date}
                        onChange={date => setFormDateValueFor('start_date', date)}
                    />
                </Col>
                <Col mb="md" width={[1, 1, 1, 1 / 2]} pr={0}>
                    <MonthYearInput
                        label={trans('forms.schoolExperience.end_date')}
                        name="end-date"
                        required
                        value={formValues.end_date
                            ? new Date(formValues.end_date)
                            : undefined}
                        minYear={formValues.start_date
                            ? new Date(formValues.start_date).getFullYear()
                            : new Date().getFullYear()}
                        maxYear={formValues.start_date
                            ? new Date(formValues.start_date).getFullYear() + maxEmploymentYears
                            : new Date().getFullYear() + maxEmploymentYears}
                        error={errors['school_experiences.end_date'] || errors.end_date}
                        onChange={date => setFormDateValueFor('end_date', date)}
                    />
                </Col>
            </Row>
        </>
    );
};

EducationFormAssets.propTypes = {
    schoolExperience: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    disabledFormNames: PropTypes.arrayOf(PropTypes.string),
    userRole: PropTypes.string,
};

EducationFormAssets.defaultProps = {
    schoolExperience: null,
    errors: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    disabledFormNames: [],
    userRole: null,
};

export default EducationFormAssets;
