import React, { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Page } from '../../components';
import { RoleInfoBenefits, RoleInfoHeader, RoleInfoSteps } from '../../compositions';
import { ConnectedRegisterModal, ConnectedRegistrationCallToAction } from '../../connectors';
import { Testimonials } from '../../containers';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { useLegacySelector } from '../../store';

import './InfoStudent.scss';

const InfoStudent: FC = (): ReactElement => {
    const navigate = useNavigate();
    const [registrationModalIsOpen, setRegistrationModalIsOpen] = useState(false);

    const role = useLegacySelector(state => state.legacyUser.role);

    const template = RoleType.student;
    const userRole = role ?? RoleType.none;

    const openRegistrationModal = (): void => setRegistrationModalIsOpen(true);
    const closeRegistrationModal = (): void => setRegistrationModalIsOpen(false);

    const handleCallToActionClick = (): void => {
        if (userRole === template) {
            navigate(RoutePaths.internships());

            return;
        }

        openRegistrationModal();
    };

    const navigateToInternships = (): void => navigate(RoutePaths.internships());

    return (
        <Page className="info-student-page">
            <Helmet>
                <title>{trans('infoPage.student.metadata.title')}</title>
                <meta property="og:title" content={createMetadataTitle(trans('infoPage.student.metadata.title'))} />
                <meta property="og:description" content={trans('infoPage.student.metadata.description')} />
                <meta name="description" content={trans('infoPage.student.metadata.description')} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/studenten`} />
            </Helmet>

            <RoleInfoHeader
                role={userRole}
                template={template}
                onCallToActionClick={navigateToInternships}
            />

            <RoleInfoBenefits
                template={template}
                className="info-student-page__benefits"
            />

            <Testimonials
                title={trans('infoPage.student.testimonials.title')}
                description={trans('infoPage.student.testimonials.description')}
                buttonLabel={trans('infoPage.student.testimonials.buttonLabel')}
                testimonial={{
                    poster: './images/info/student-testimonial.jpg',
                    label: trans('infoPage.student.testimonials.card.label'),
                    quote: trans('infoPage.student.testimonials.card.quote'),
                    author: trans('infoPage.student.testimonials.card.author'),
                }}
                onCallToActionClick={handleCallToActionClick}
                className="info-student-page__testimonials"
            />

            <RoleInfoSteps
                role={userRole}
                template={template}
                onCallToActionClick={handleCallToActionClick}
                className="info-student-page__steps"
            />

            <ConnectedRegistrationCallToAction
                template={template}
                onCallToActionClick={handleCallToActionClick}
                className="info-student-page__registration-call-to-action"
            />

            {registrationModalIsOpen && (
                <ConnectedRegisterModal
                    role={template}
                    onClose={closeRegistrationModal}
                />
            )}
        </Page>
    );
};

export default InfoStudent;
