import { Resource } from '../japi/types/Resource';

export interface SkillsResource extends Resource {
    type: 'skills';
    id: string;
    attributes: {
        name: string;
        slug: string;
        isSelectable: boolean;
        createdAt: string;
        updatedAt: string;
    };
}

export enum SkillType {
    skill = 'skill',
    esco = 'esco-skill',
}

export interface Skill {
    id: string;
    type: SkillType;
    name: string;
    slug: string;
    isSelectable: boolean;
}

export interface SkillsFormData {
    skills: Skill[];
    escoSkills: Skill[];
}

export const transformToSkill = (
    skillsResource: SkillsResource,
    type: SkillType,
): Skill => ({
    id: skillsResource.id,
    type,
    name: skillsResource.attributes.name,
    slug: skillsResource.attributes.slug,
    isSelectable: skillsResource.attributes.isSelectable,
});
