import axios from 'axios';
import { Dispatch } from 'redux';

import { RoleType } from '../../../../models/User';
import { LegacyReducers } from '../../../../store/reducers';
import { getUserProfiles } from '../legacyUser/legacyUserActions';
import { setIsEmpty } from '../userPerson/userPerson';
import { setData, setErrors, setLoading } from './userProfile';

export function createProfile(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { legacyUser } = getState();

        const url = legacyUser?.role === RoleType.student
            ? '/students'
            : '/employees';

        if (data.phone_number) {
            data.phone_number = data.phone_number.replace(/ /g, '');
        }

        try {
            const response = await axios.post(url, data);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            dispatch(setIsEmpty(false));

            return Promise.resolve(response.data.data);
        } catch (error: any) {
            console.error('[createProfile]', error);

            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function updateProfile(data: any) {
    return async (dispatch: Dispatch<any>, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { legacyUser, userPerson } = getState();

        const url = legacyUser.role === RoleType.student
            ? '/students'
            : '/employees';

        if (data.phone_number) {
            data.phone_number = data.phone_number.replace(/ /g, '');
        }

        try {
            await axios.patch(`${url}/${userPerson?.data?.uuid}`, data);
            await dispatch(getUserProfiles());

            dispatch(setLoading(false));

            return Promise.resolve();
        } catch (error: any) {
            console.error('[updateProfile]', error);

            dispatch(setErrors(error.response.data.errors));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}
