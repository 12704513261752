import * as prismic from '@prismicio/client';

import { trans } from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { PrismicDocument, PrismicSearchResponse, PrismicType } from '../models/Prismic';

interface PrismicOverviewApiCallConfig {
    type: string;
    category: string;
    page: number;
    pageSize: number;
}

const apiEndpoint = 'https://talentznl.cdn.prismic.io/api/v2';
const accessToken = 'MC5ZRzI2dmhFQUFDSUFUOVhI.ailoCFww77-9CO-_vSxAMO-_ve-_vRowABsR77-977-9ee-_vVjvv70eRe-_vSNo77-977-9';

export const getPrismicCategoriesApiCall = async (): Promise<FetchResult<string[], string>> => {
    try {
        const connection = prismic.createClient(apiEndpoint, { accessToken });
        const categories = await connection.getTags();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: categories,
        };
    } catch (error) {
        console.error('[getPrismicCategoriesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getPrismicOverviewApiCall = async (config: PrismicOverviewApiCallConfig): Promise<FetchResult<PrismicSearchResponse, string>> => {
    try {
        const connection = prismic.createClient(apiEndpoint, { accessToken });

        const predicates = [
            config.type && prismic.predicate.at('document.type', config.type),
            config.category && prismic.predicate.at('document.tags', [config.category]),
        ];

        const response = await connection.get({
            predicates,
            page: config.page,
            pageSize: config.pageSize,
            orderings: [{
                field: 'document.first_publication_date',
                direction: 'desc',
            }],
        });

        return {
            status: 200,
            type: FetchResultType.Success,
            data: response as PrismicSearchResponse,
        };
    } catch (error) {
        console.error('[getPrismicOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getPrismicDetailApiCall = async (type: PrismicType, slug: string): Promise<FetchResult<PrismicDocument, string>> => {
    try {
        const connection = await prismic.createClient(apiEndpoint, { accessToken });
        const response = await connection.getByUID(type, slug);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: response as PrismicDocument,
        };
    } catch (error) {
        console.error('[getPrismicDetailApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
