import React, { FC, ReactElement } from 'react';

import { trans } from '../../helpers/trans';

import './StepHeader.scss';

interface StepHeaderProps {
    title: string;
    currentStep: number;
    totalSteps: number;
}

const StepHeader: FC<StepHeaderProps> = ({
    currentStep,
    totalSteps,
    title,
}): ReactElement => (
    <header className="step-header">
        <div className="step-header__step-indicator">
            {trans('placeVacancyPage.steps.title')} {currentStep}/{totalSteps}
        </div>

        <h1 className="step-header__title">{title}</h1>
    </header>
);

export default StepHeader;
