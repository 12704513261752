import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton } from '../../../components';
import {
    AddingCard,
    IconButton,
    Modal,
    ModalContent,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { SchoolExperience, SchoolExperienceFormData } from '../../../models/NewSchoolExperience';
import { SchoolExperienceForm } from '../..';
import { SchoolExperienceFormOptions } from '../../@forms/SchoolExperienceForm/SchoolExperienceForm';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';
import { SchoolExperiencesCardSkeletons } from './skeletons';
import { SchoolExperienceItem } from './subcomponents';

import './SchoolExperiencesCard.scss';

interface SchoolExperiencesCardProps extends SchoolExperienceFormOptions {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    schoolExperiences?: SchoolExperience[];
    onAddSchoolExperience: (schoolExperienceFormData: SchoolExperienceFormData) => void;
    onEditSchoolExperience: (schoolExperienceFormData: SchoolExperienceFormData) => void;
    onDeleteSchoolExperience: (schoolExperience: SchoolExperience) => void;
    className?: string;
}

const SchoolExperiencesCard: FC<SchoolExperiencesCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    schoolExperiences = [],
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions,
    schoolOptions,
    fieldOfStudyOptions,
    onAddSchoolExperience,
    onEditSchoolExperience,
    onDeleteSchoolExperience,
    className = '',
}): ReactElement => {
    const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);

    const openAddModal = (): void => setAddModalIsOpen(true);
    const closeAddModal = (): void => setAddModalIsOpen(false);

    const handleAddSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData): void => {
        onAddSchoolExperience(schoolExperienceFormData);
        closeAddModal();
    };

    const schoolExperienceFormOptions = {
        educationLevelOptions,
        educationSubLevelOptions,
        learningPathOptions,
        fieldOfStudyOptions,
        schoolOptions,
    };

    return (
        <AddingCard
            title={trans('userProfile.schoolExperiences.title')}
            isEditable={isEditable && schoolExperiences.length > 0}
            item={trans('basic.education')}
            onAddClick={openAddModal}
            className={`school-experiences-card ${className}`}
        >
            <ProgressRefButton ref={activationRef} onClick={openAddModal} />

            {isLoading && (
                <SchoolExperiencesCardSkeletons
                    amount={2}
                    className="school-experiences-card__skeletons"
                />
            )}

            {!isLoading && schoolExperiences.length === 0 && (
                <div className="school-experiences-card__null-state">
                    <p className="school-experiences-card__null-state-description">
                        {trans('userProfile.schoolExperiences.nullState')}
                    </p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('userProfile.content.add')}
                            onClick={openAddModal}
                            className="school-experiences-card__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && schoolExperiences.length > 0 && (
                <ul className="school-experiences-card__education-list">
                    {schoolExperiences.map(schoolExperience => (
                        <SchoolExperienceItem
                            {...schoolExperienceFormOptions}
                            key={schoolExperience.id}
                            isEditable={isEditable}
                            schoolExperience={schoolExperience}
                            onEdit={onEditSchoolExperience}
                            onDelete={onDeleteSchoolExperience}
                            className="school-experiences-card__education-item"
                        />
                    ))}
                </ul>
            )}

            {addModalIsOpen && (
                <Modal onClose={closeAddModal}>
                    <ModalContent title={trans('forms.new.schoolExperience.title.add')}>
                        <SchoolExperienceForm
                            {...schoolExperienceFormOptions}
                            onCancel={closeAddModal}
                            onSubmit={handleAddSchoolExperience}
                        />
                    </ModalContent>
                </Modal>
            )}
        </AddingCard>
    );
};

export default SchoolExperiencesCard;
