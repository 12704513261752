import axios from 'axios';

import { JobPreferencesApiParams, transformToJobPreferences } from '../models/JobPreferences';
import { Response, ResponseType } from '../models/Response';
import { JobVacanciesFilterParams, transformFiltersToJobPreferencesRequest } from '../store/jobVacancies/jobVacancies';

export async function updateJobPreferences(
    preferences: JobVacanciesFilterParams,
    jobPreferencesSetId: string,
): Promise<Response<JobPreferencesApiParams>> {
    try {
        const response = await axios.put(`/job-preferences/${jobPreferencesSetId}`, {
            ...transformFiltersToJobPreferencesRequest(preferences),
        });

        return {
            status: response.status,
            type: ResponseType.Success,
            data: transformToJobPreferences(response.data.data),
        };
    } catch (error) {
        return {
            status: 500,
            error,
            type: ResponseType.Error,
        };
    }
}
