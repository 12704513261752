import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    Disclaimer,
    FormActionButtons,
    TextInput,
    YouTubeEmbed,
} from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { getFullYouTubeUrl, retrieveYouTubeVideoId } from '../../../helpers/youTube';
import { YouTubeFormData } from '../../../models/YouTube';
import { FormProps } from '../../../types';
import { validateYouTubeFormData, YouTubeFormErrors } from './validations';

import './YouTubeForm.scss';

interface YouTubeFormProps extends FormProps<YouTubeFormData> {
    embedId?: string;
    className?: string;
}

const YouTubeForm: FC<YouTubeFormProps> = ({
    embedId = '',
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<YouTubeFormErrors>({});

    const [videoId, setVideoId] = useState<string>(embedId);
    const [videoUrl, setVideoUrl] = useState<string>(embedId ? getFullYouTubeUrl(embedId) : '');

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            embedId: videoId,
        };

        const [errors, hasErrors] = validateYouTubeFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const handleChange = (url: string): void => {
        const id = retrieveYouTubeVideoId(url);

        setVideoId(id || '');
        setVideoUrl(url);
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`youtube-form ${className}`}>
            <TextInput
                label={trans('forms.youTube.input.url')}
                required
                value={videoUrl}
                error={formErrors.embedId}
                onChange={handleChange}
                className="youtube-form__input"
            />

            {videoId && (
                <YouTubeEmbed
                    embedId={videoId}
                    title={trans('forms.youTube.preview.title')}
                    className="youtube-form__preview"
                />
            )}

            <Disclaimer
                title={trans('forms.youTube.disclaimer.title')}
                description={trans('forms.youTube.disclaimer.description')}
                className="youtube-form__disclaimer"
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="youtube-form__action-buttons"
            />
        </form>
    );
};

export default YouTubeForm;
