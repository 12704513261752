import React, { useState } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { Checkbox, ErrorLabel } from '../../../../../components';
import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/form/TextInput';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import OrRule from '../../../../styledComponents/components/OrRule';
import PreviousButton from '../../../../styledComponents/components/PreviousButton';
import { FakeLink, Heading, Paragraph } from '../../../../styledComponents/components/Typography';

const RegisterUser = props => {
    const [hasAcceptedToc, setHasAcceptedToc] = useState(false);

    const {
        standAlone,
        errors,
        loading,
        onPrev,
        userType,
    } = props;

    const onSubmit = e => {
        e.preventDefault();
        const data = serialize(e.currentTarget, { hash: true });
        props.onSubmit({
            ...data,
            toc: hasAcceptedToc ? String(hasAcceptedToc) : undefined,
        });
    };

    const renderLoginParagraph = () => (
        <Paragraph size="md" mb="xl">
            {trans('forms.register.alreadyRegistered')}&nbsp;
            <FakeLink id="register-email-to-login" onClick={props.onLoginClick}>
                {trans('forms.user.login')}
            </FakeLink>
        </Paragraph>
    );

    return (
        <form id="register-user-form" onSubmit={onSubmit}>
            <Heading as="h2" size="xl" standAlone={standAlone}>
                {userType ? (
                    trans(`forms.register.callToAction.${userType}.title`)
                ) : (
                    trans('forms.register.title')
                )}
            </Heading>
            {standAlone && renderLoginParagraph()}
            <Row mb="sm" width={1}>
                <Button
                    width={1}
                    hasIcon
                    iconLeft
                    iconSize={20}
                    icon="linkedin-letters"
                    color="linkedIn"
                    type="button"
                    id="register-email-linkedin-button"
                    onClick={() => props.getLinkedInConnectUrl()}
                >
                    {trans('basic.linkedin')}
                </Button>
            </Row>
            <Row mb="lg" width={1}>
                <Paragraph dangerouslySetInnerHTML={{
                    __html: trans('forms.registerLinkedIn.terms.text', {
                        terms: `<a class="text-link" href="/algemene-voorwaarden" target="_blank">${trans('forms.register.terms.termsLabel')}</a>`,
                        privacy: `<a class="text-link" href="/privacy-statement" target="_blank">${trans('forms.register.terms.privacyLabel')}</a>`,
                    }),
                }}
                />
            </Row>
            <OrRule>{trans('basic.or')}</OrRule>
            <input type="hidden" name="user_type" value={userType} />
            <Row>
                <Col mb="md" mt="lg" width={1}>
                    <TextInput
                        errors={errors.email}
                        id="email"
                        name="email"
                        placeholder={trans('forms.user.email.placeholder')}
                        icon="mail"
                        type="email"
                    />
                </Col>
            </Row>
            <Row>
                <Col mb="xl" width={1}>
                    <Checkbox
                        label={trans('forms.register.termsAndConditions.text')}
                        checked={hasAcceptedToc}
                        onChange={setHasAcceptedToc}
                    />

                    <Row mt="sm">
                        <ErrorLabel text={errors.toc} />
                    </Row>

                    <Row mt="sm">
                        <Col>
                            <a className="text-link" href="/algemene-voorwaarden" target="_blank">
                                {trans('forms.register.terms.termsLabel')}
                            </a>
                        </Col>
                        <Col ml="sm">
                            <a className="text-link" href="/privacy-statement" target="_blank">
                                {trans('forms.register.terms.privacyLabel')}
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} width={1} alignItems="center" justifyContent="flex-end">
                <Col mb="md">
                    { onPrev && (
                        <PreviousButton id="register-email-previous" type="button" onClick={onPrev} icon="backward" iconMt={-2} borderless>
                            {trans('forms.user.previous')}
                        </PreviousButton>
                    )}
                    <Button id="register-email-submit" isLoading={loading}>
                        {trans('forms.user.register')}
                    </Button>
                </Col>
            </Row>
            {!standAlone && (
                <Row justifyContent="flex-end">
                    <Paragraph>
                        {trans('forms.register.alreadyRegistered')}&nbsp;
                        <FakeLink id="register-email-to-login" onClick={props.onLoginClick}>
                            {trans('forms.user.login')}
                        </FakeLink>
                    </Paragraph>
                </Row>
            )}
        </form>
    );
};

RegisterUser.propTypes = {
    standAlone: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onLoginClick: PropTypes.func.isRequired,
    getLinkedInConnectUrl: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    onPrev: PropTypes.func,
    userType: PropTypes.string.isRequired,
};

RegisterUser.defaultProps = {
    standAlone: false,
    errors: {},
    onPrev: null,
};

export default RegisterUser;
