import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCircleExclamation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <g>
            <rect x="46.2" y="25" width="7.5" height="35" />
            <circle cx="50" cy="71.2" r="3.8" />
        </g>
        <path d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M10,50c0-22.1,17.9-40,40-40c22.1,0,40,17.9,40,40c0,22.1-17.9,40-40,40C27.9,90,10,72.1,10,50z" />
    </svg>
);

export default IconCircleExclamation;
