/* eslint-disable camelcase */
import { RoleType } from '../../../models/User';
import validatePhoneNumber from '../../helpers/validatePhoneNumber';
import validateZipcode from '../../helpers/zipcodeHelper';
import PropertyTypes from '../models/PropertyTypes';
import { fromISODate } from './DateService';
import { validateDateForOutOfBounds, validatePropertyForType, validateRequiredProperty } from './ValidationService';

interface RawSchoolExperiencesData {
    field_of_study_id: string;
    school_id: string;
    school_name?: string;
    education_level_id: string;
    education_level_name?: string;
    field_of_study_name?: string;
    learning_path_id: string;
    start_date: string;
    end_date?: string;
    learningPathSlug: string;
}

export interface RawPersonData {
    gender?: string;
    first_name?: string;
    insertion?: string;
    last_name?: string;
    birthday?: string;
    phone_number?: string;
    avatar?: string;
    video?: string;
    address?: string;
    street_number?: string;
    extension?: string;
    zipcode?: string;
    latitude?: string;
    longitude?: string;
    province?: string;
    city?: string;
    school_experiences?: RawSchoolExperiencesData[];
}

export interface ValidationErrors {
    [key: string]: string[];
}

export function validatePerson(role: RoleType, data: RawPersonData, validateEmpty?: boolean): ValidationErrors {
    const errors: ValidationErrors = {
        gender: [],
        first_name: [],
        last_name: [],
        address: [],
        street_number: [],
        zipcode: [],
        latitude: [],
        longitude: [],
        phone_number: [],
    };

    errors.gender = validateRequiredProperty('gender', data.gender, validateEmpty);
    errors.first_name = validateRequiredProperty('first_name', data.first_name, validateEmpty);
    errors.last_name = validateRequiredProperty('last_name', data.last_name, validateEmpty);
    errors.street_number = validateRequiredProperty('street_number', data.street_number, validateEmpty);
    errors.zipcode = validateRequiredProperty('zipcode', data.zipcode, validateEmpty);
    errors.city = validateRequiredProperty('city', data.city, validateEmpty);

    if (data.phone_number) {
        errors.phone_number = validatePhoneNumber(data.phone_number);
    }

    if (data.street_number) {
        errors.street_number.push(...validatePropertyForType('street_number', data.street_number, PropertyTypes.Number));
    }

    if (!!data.zipcode && validateZipcode(data.zipcode)) {
        errors['zipcode'] = validateZipcode(data.zipcode);
    }

    if (data.school_experiences && data.school_experiences.length) {
        data.school_experiences.forEach(school_experience => {
            errors['school_experiences.start_date'] = validateRequiredProperty('school_experiences.start_date', school_experience.start_date, validateEmpty);
            errors['school_experiences.end_date'] = validateRequiredProperty('school_experiences.end_date', school_experience.end_date, validateEmpty);

            if (school_experience.learningPathSlug === 'mbo' && !school_experience.education_level_name) {
                errors['school_experiences.learning_path_id'] = validateRequiredProperty('school_experiences.learning_path_id', school_experience.learning_path_id, validateEmpty);
            }

            if (school_experience.education_level_name) {
                errors['school_experiences.education_level_name'] = validateRequiredProperty('school_experiences.education_level_name', school_experience.education_level_name, validateEmpty);
            } else {
                errors['school_experiences.education_level_id'] = validateRequiredProperty('school_experiences.education_level_id', school_experience.education_level_id, validateEmpty);
            }

            if (school_experience.school_name) {
                errors['school_experiences.school_name'] = validateRequiredProperty('school_experiences.school_name', school_experience.school_name, validateEmpty);
            } else {
                errors['school_experiences.school_id'] = validateRequiredProperty('school_experiences.school_id', school_experience.school_id, validateEmpty);
            }

            if (school_experience.field_of_study_name) {
                errors['school_experiences.field_of_study_name'] = validateRequiredProperty('school_experiences.field_of_study_name', school_experience.field_of_study_name, validateEmpty);
            } else {
                errors['school_experiences.field_of_study_id'] = validateRequiredProperty('school_experiences.field_of_study_id', school_experience.field_of_study_id, validateEmpty);
            }

            if (school_experience.start_date && school_experience.end_date) {
                const startDate = fromISODate(school_experience.start_date);
                const endDate = fromISODate(school_experience.end_date);

                errors['school_experiences.end_date'] = validateDateForOutOfBounds(startDate, endDate, 'school_experiences.start_date', 'school_experiences.end_date');
            }
        });
    }

    Object.keys(errors).forEach(errorKey => {
        if (!errors[errorKey].length) {
            delete errors[errorKey];
        }
    });

    return errors;
}
/* eslint-enable camelcase */
