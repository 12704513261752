/* eslint-disable camelcase */
export interface InternshipPreferencesApiParams {
    uuid: string;
    compensation: MatchPropertyTemp;
    education_level: MatchPropertyTemp;
    field_of_study: MatchPropertyTemp;
    has_sbb_approval: boolean;
    travel_distance: MatchPropertyTemp;
    internship_types: MatchPropertyTemp[];
    amount_of_employees: MatchPropertyTemp[];
    grade: MatchPropertyTemp;
    zipcode?: string;
}

export interface InternshipPreferencesApiRequestParams {
    amount_of_employees: string[];
    apply_types: string[];
    compensation: string;
    grade_id?: string;
    internship_types: string[];
    has_sbb_approval?: boolean;
    travel_distance: string;
    zipcode?: string;
}

export interface InternshipPreferences {
    uuid: string;
    compensation: MatchPropertyTemp;
    education_level: MatchPropertyTemp;
    field_of_study: MatchPropertyTemp;
    has_sbb_approval: boolean;
    travel_distance: MatchPropertyTemp;
    internship_types: MatchPropertyTemp[];
    amount_of_employees: MatchPropertyTemp[];
    grade: MatchPropertyTemp;
    zipcode?: string;
}

export interface MatchPropertyTemp {
    uuid: string;
    name: string;
    value?: string;
    slug?: string;
    is_matched?: boolean;
}

export function transformToInternshipPreferences(params: InternshipPreferencesApiParams): InternshipPreferences {
    return {
        ...params,
    };
}
/* eslint-enable camelcase */
