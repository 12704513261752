import React, { FC, ReactElement } from 'react';

import { Spinner } from '../../components';
import { trans } from '../../helpers/trans';

import './Loader.scss';

export interface LoaderProps {
    hideText?: boolean;
    text?: string;
    className?: string;
    spinnerClassName?: string;
}

const Loader: FC<LoaderProps> = ({
    hideText = false,
    text = trans('actions.waiting.moment.form'),
    className = '',
    spinnerClassName = '',
}): ReactElement => (
    <div className={`loader ${className}`}>
        <Spinner className={spinnerClassName} />
        {!hideText && <p className="loader__text">{text}</p>}
    </div>
);

export default Loader;
