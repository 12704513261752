import React, { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Page } from '../../components';
import { RoleInfoBenefits, RoleInfoHeader, RoleInfoSteps } from '../../compositions';
import { ConnectedRegisterModal, ConnectedRegistrationCallToAction } from '../../connectors';
import { Testimonials } from '../../containers';
import { trans } from '../../helpers/trans';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { useLegacySelector } from '../../store';
import { CompanyOverviewTab } from '../../types/pageTabs';
import { CompanyUsps } from './subcomponents';

import './InfoCompany.scss';

const InfoCompany: FC = (): ReactElement => {
    const navigate = useNavigate();
    const [registrationModalIsOpen, setRegistrationModalIsOpen] = useState(false);

    const role = useLegacySelector(state => state.legacyUser.role);

    const template = RoleType.company;
    const userRole = role ?? RoleType.none;

    const openRegistrationModal = (): void => setRegistrationModalIsOpen(true);
    const closeRegistrationModal = (): void => setRegistrationModalIsOpen(false);

    const handleCallToActionClick = (): void => {
        if (userRole === template) {
            navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));

            return;
        }

        openRegistrationModal();
    };

    return (
        <Page className="info-company-page">
            <Helmet>
                <title>{trans('infoPage.company.metadata.title')}</title>
                <meta property="og:title" content={createMetadataTitle(trans('infoPage.company.metadata.title'))} />
                <meta property="og:description" content={trans('infoPage.company.metadata.description')} />
                <meta name="description" content={trans('infoPage.company.metadata.description')} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/werkgevers`} />
            </Helmet>

            <RoleInfoHeader
                role={userRole}
                template={template}
                onCallToActionClick={openRegistrationModal}
            />

            <RoleInfoBenefits
                template={template}
                className="info-company-page__benefits"
            />

            <CompanyUsps className="info-company-page__usps" />

            <Testimonials
                title={trans('infoPage.company.testimonials.title')}
                description={trans('infoPage.company.testimonials.description')}
                buttonLabel={trans('infoPage.company.testimonials.buttonLabel')}
                testimonial={{
                    poster: './images/info/company-testimonial.jpg',
                    logo: './images/work_logo.png',
                    label: trans('infoPage.company.testimonials.card.label'),
                    quote: trans('infoPage.company.testimonials.card.quote'),
                    author: trans('infoPage.company.testimonials.card.author'),
                }}
                onCallToActionClick={handleCallToActionClick}
                className="info-company-page__testimonials"
            />

            <RoleInfoSteps
                role={userRole}
                template={template}
                onCallToActionClick={handleCallToActionClick}
                className="info-company-page__steps"
            />

            <ConnectedRegistrationCallToAction
                template={template}
                onCallToActionClick={handleCallToActionClick}
                className="info-company-page__registration-call-to-action"
            />

            {registrationModalIsOpen && (
                <ConnectedRegisterModal
                    role={template}
                    onClose={closeRegistrationModal}
                />
            )}
        </Page>
    );
};

export default InfoCompany;
