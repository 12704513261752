import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton } from '../../../../../components';
import { IconButton, Modal, ModalContent } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { SectorsFormData } from '../../../../../models/Sectors';
import { FormOption } from '../../../../../types';
import { PreferredSectorsForm } from '../../../..';
import { ActivationRef } from '../../../ProfileProgress/ProfileProgress';
import { CandidatePreferencesCardSkeletons } from '../../skeletons';

import './PreferredSectors.scss';

interface PreferredSectorsProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    preferredSectors: FormOption[];
    sectorOptions: FormOption[];
    onEdit: (preferredSectorsFormData: SectorsFormData) => void;
    className?: string;
}

const PreferredSectors: FC<PreferredSectorsProps> = ({
    activationRef,
    isLoading,
    isEditable,
    preferredSectors,
    sectorOptions,
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditPreferredSectors = (preferredSectorsFormData: SectorsFormData): void => {
        onEdit(preferredSectorsFormData);
        closeEditModal();
    };

    return (
        <div className={`preferred-sectors ${className}`}>
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            <h3 className="preferred-sectors__title">
                {trans('candidateProfile.preferredSectors.title')}
            </h3>

            {isLoading && (
                <CandidatePreferencesCardSkeletons
                    amount={2}
                    className="preferred-sectors__skeletons"
                />
            )}

            {!isLoading && preferredSectors.length === 0 && (
                <div className="preferred-sectors__null-state">
                    <p>{trans('candidateProfile.preferredSectors.nullState')}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('userProfile.content.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="preferred-sectors__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && preferredSectors.length > 0 && (
                <ul className="preferred-sectors__list">
                    {preferredSectors.map(sector => (
                        <li key={sector.value} className="preferred-sectors__list-item">
                            {sector.label}
                        </li>
                    ))}
                </ul>
            )}

            {isEditable && preferredSectors.length > 0 && (
                <IconButton
                    icon="pencil"
                    text={trans('actions.edit')}
                    hideLabel
                    disabled={isLoading}
                    onClick={openEditModal}
                    className="preferred-sectors__edit-button"
                />
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.preferredSectors.modal.title')}>
                        <PreferredSectorsForm
                            sectors={preferredSectors}
                            sectorOptions={sectorOptions}
                            onCancel={closeEditModal}
                            onSubmit={handleEditPreferredSectors}
                        />
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default PreferredSectors;
