import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getAmountOfEmployeesOptionsApiCall } from '../../services/AmountOfEmployeesService';
import { TypedDispatch } from '..';
import { setAmountOfEmployeesOptions, setError, setIsLoading } from './amountOfEmployees';

export const fetchAmountOfEmployeesOptions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const amountOfEmployeesOptionsResponse = await getAmountOfEmployeesOptionsApiCall();

        if (!isFetchResultSuccessful(amountOfEmployeesOptionsResponse)) {
            dispatch(setError(amountOfEmployeesOptionsResponse.error));
            return;
        }

        dispatch(setAmountOfEmployeesOptions(amountOfEmployeesOptionsResponse.data));
    } catch (error) {
        console.error('[fetchAmountOfEmployeesOptions]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
