import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getVacancyApplicationsApiCall } from '../../services/VacancyApplicationsService';
import { getVacancyApiCall } from '../../services/VacancyService';
import { TypedDispatch } from '..';
import {
    setActiveVacancy,
    setApplications,
    setError,
    setIsLoading,
} from './vacancyApplications';

export const clearVacancyApplications = () => (dispatch: TypedDispatch): void => {
    dispatch(setActiveVacancy(undefined));
    dispatch(setApplications([]));
};

export const fetchVacancyApplications = (vacancyUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyResponse = await getVacancyApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(vacancyResponse)) {
            console.error('[fetchVacancyApplications]', vacancyResponse.error);
            dispatch(setError(vacancyResponse.error));

            return;
        }

        const vacancy = vacancyResponse.data;
        const applicationsResponse = await getVacancyApplicationsApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(applicationsResponse)) {
            console.error('[fetchVacancyApplications]', applicationsResponse.error);
            dispatch(setError(applicationsResponse.error));

            return;
        }

        dispatch(setActiveVacancy(vacancy));
        dispatch(setApplications(applicationsResponse.data));
    } catch (error) {
        console.error('[fetchVacancyApplications]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
