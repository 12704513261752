import React, {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { Address } from '../../_old/app_talentz/models/Address';
import { StepProgressIndicator } from '../../components';
import { Loader } from '../../compositions';
import { maxEmploymentHoursPerWeek, otherFormOptionKey } from '../../constants';
import { Competency } from '../../models/Competencies';
import { EditableVacancy } from '../../models/EditableVacancy';
import { EducationSubLevel } from '../../models/EducationLevels';
import { EscoOccupation } from '../../models/Esco';
import { FieldOfStudy } from '../../models/FieldsOfStudy';
import { Sector } from '../../models/Sectors';
import { Skill, SkillType } from '../../models/Skills';
import { PlaceJobVacancyFormValues, transformJobVacancyToPlaceJobVacancyFormValues } from '../../services/PlaceVacancyService';
import { FormOption, MinMaxValue, SalaryTimeframe } from '../../types';
import createEmptyPlaceJobVacancyFormValues from './helpers/createEmptyPlaceJobVacancyFormValues';
import { JobVacancyDataStep, JobVacancySkillsStep } from './steps';

import './PlaceJobVacancySteps.scss';

interface PlaceJobVacancyStepsProps {
    isLoading?: boolean;
    step?: number;
    vacancy?: EditableVacancy;
    address?: Address;
    maxAmountOfSteps?: number;
    sectorOptions: Sector[];
    educationLevelOptions: FormOption[];
    educationSubLevelOptions: EducationSubLevel[];
    fieldsOfStudyOptions: FieldOfStudy[];
    jobTypeOptions: FormOption[];
    workRemoteOptions: FormOption[];
    suggestedSkills: Skill[];
    suggestedCompetencies: Competency[];
    escoOccupationOptions: EscoOccupation[];
    skillOptions: Skill[];
    skillsError: string;
    competencyOptions: Competency[];
    competenciesError: string;
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onCreateSkillOption: (value: string) => void;
    onStepChange: (step: number) => void;
    onCreate: (values: PlaceJobVacancyFormValues) => void;
    onEdit: (values: PlaceJobVacancyFormValues) => void;
}

const PlaceJobVacancySteps: FC<PlaceJobVacancyStepsProps> = ({
    isLoading = false,
    step = 1,
    maxAmountOfSteps = 2,
    vacancy,
    address,
    sectorOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    fieldsOfStudyOptions,
    jobTypeOptions,
    workRemoteOptions,
    escoOccupationOptions,
    suggestedSkills,
    suggestedCompetencies,
    skillOptions,
    skillsError,
    competencyOptions,
    competenciesError,
    onSelectOccupation,
    onCreateSkillOption,
    onStepChange,
    onCreate,
    onEdit,
}): ReactElement => {
    const [formValues, setFormValues] = useState<PlaceJobVacancyFormValues>(createEmptyPlaceJobVacancyFormValues(address));
    const [newSkillLabel, setNewSkillLabel] = useState<string>('');

    const [educationLevelId, setEducationLevelId] = useState<string>(otherFormOptionKey);
    const [educationSubLevelId, setEducationSubLevelId] = useState<string>();
    const [fieldsOfStudy, setFieldsOfStudy] = useState<FieldOfStudy[]>([]);
    const [employmentHourRange, setEmploymentHourRange] = useState<MinMaxValue>({ min: 0, max: maxEmploymentHoursPerWeek });
    const [jobTypeId, setJobTypeId] = useState<string>('');
    const [workRemotePreferenceId, setWorkRemotePreferenceId] = useState<string>('');
    const [escoOccupations, setEscoOccupations] = useState<EscoOccupation[]>([]);

    const [skills, setSkills] = useState<Skill[]>([]);
    const [competencies, setCompetencies] = useState<Competency[]>([]);

    const [salaryTimeframe, setSalaryTimeframe] = useState<SalaryTimeframe>(SalaryTimeframe.hour);

    useEffect((): void => {
        if (!vacancy) return;

        setEducationLevelId(vacancy.educationLevels[0] || otherFormOptionKey);
        setEducationSubLevelId(vacancy.educationLevels[1]);
        setEmploymentHourRange(vacancy.employmentHourRange);
        setJobTypeId(vacancy.vacancyType);
        setWorkRemotePreferenceId(vacancy.workRemotePreference);
    }, [vacancy]);

    useEffect((): void => {
        if (!vacancy) return;

        if (fieldsOfStudyOptions && fieldsOfStudy.length === 0) {
            const vacancyFieldsOfStudy = fieldsOfStudyOptions.filter(fieldOfStudy => vacancy.fieldsOfStudy.includes(fieldOfStudy.id));

            const orderedVacancyFieldsOfStudy = vacancy.fieldsOfStudy.map(fieldOfStudyId => {
                const selectedFieldOfStudy = vacancyFieldsOfStudy.filter(fieldOfStudy => fieldOfStudy.id === fieldOfStudyId);

                return selectedFieldOfStudy[0];
            }).filter(Boolean);

            setFieldsOfStudy(orderedVacancyFieldsOfStudy);
        }

        if (escoOccupationOptions && escoOccupations.length === 0) {
            const vacancyOccupations = escoOccupationOptions.filter(occupation => vacancy.escoOccupations.includes(occupation.id));

            const orderedVacancyOccupations = vacancy.escoOccupations.map(occupationId => {
                const selectedOccupation = vacancyOccupations.filter(occupation => occupation.id === occupationId);

                return selectedOccupation[0];
            }).filter(Boolean);

            setEscoOccupations(orderedVacancyOccupations);
        }

        if (skillOptions && skills.length === 0) {
            const vacancySkills = skillOptions.filter(skill => vacancy.skills.includes(skill.id));
            const vacancyEscoSkills = skillOptions.filter(skill => vacancy.escoSkills.includes(skill.id));

            setSkills([...vacancySkills, ...vacancyEscoSkills]);
        }

        if (competencyOptions && competencies.length === 0) {
            const vacancyCompetencies = competencyOptions.filter(competency => vacancy.competencies.includes(competency.id));

            setCompetencies(vacancyCompetencies);
        }
    }, [vacancy, escoOccupationOptions, skillOptions, competencyOptions]);

    useEffect((): void => {
        if (!vacancy) return;

        if (vacancy?.minCompensation > 0 && vacancy?.maxCompensation > 0) {
            setSalaryTimeframe(SalaryTimeframe.month);
        }

        setFormValues(transformJobVacancyToPlaceJobVacancyFormValues({
            ...vacancy,
            educationLevels: vacancy.educationLevels.length > 0
                ? vacancy.educationLevels
                : [educationLevelId],
        }, address));
    }, [vacancy, address]);

    useEffect((): void => {
        setSkills(suggestedSkills);
        setCompetencies(suggestedCompetencies);
    }, [suggestedSkills, suggestedCompetencies]);

    // Add newly added option to list of selected skills
    useEffect((): void => {
        if (newSkillLabel) {
            const newSkill = skillOptions.find(option => option.name.toLowerCase() === newSkillLabel.toLowerCase());

            if (newSkill) {
                setSkills([...skills, newSkill]);
            }

            setNewSkillLabel('');
        }
    }, [skillOptions]);

    const handleOnChange = useCallback((values: Partial<PlaceJobVacancyFormValues>) => {
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            ...values,
        }));
    }, []);

    const handleEducationLevelChange = (educationLevel: FormOption): void => {
        setEducationLevelId(educationLevel.value);
        setFieldsOfStudy([]);

        setFormValues({
            ...formValues,
            educationLevelIds: [educationLevel.value],
        });
    };

    const handleEducationSubLevelChange = (educationSubLevel?: FormOption): void => {
        setEducationSubLevelId(educationSubLevel?.value);

        if (educationSubLevel) {
            setFormValues({
                ...formValues,
                educationLevelIds: [educationLevelId, educationSubLevel.value],
            });
        }
    };

    const handleEmploymentHourRangeChange = (employmentValue: MinMaxValue): void => {
        setEmploymentHourRange(employmentValue);

        setFormValues({
            ...formValues,
            employmentHourRange: employmentValue,
        });
    };

    const handleJobTypeChange = (jobType: string): void => {
        setJobTypeId(jobType);

        setFormValues({
            ...formValues,
            jobTypeId: jobType,
        });
    };

    const handleWorkRemotePreferenceChange = (workRemotePreference: string): void => {
        setWorkRemotePreferenceId(workRemotePreference);

        setFormValues({
            ...formValues,
            workRemotePreferenceId: workRemotePreference,
        });
    };

    const handleOccupationsChange = (occupations: EscoOccupation[]): void => {
        setEscoOccupations(occupations);

        // Use first occupation to fetch suggestions for
        onSelectOccupation(occupations[0]);
    };

    const handleCreateNewSkill = (skill: string): void => {
        setNewSkillLabel(skill);
        onCreateSkillOption(skill);
    };

    const handleNextClick = useCallback((): void => {
        onStepChange(step + 1);
    }, [step, onStepChange]);

    const handlePreviousClick = useCallback((): void => {
        onStepChange(step - 1);
    }, [step, onStepChange]);

    const handleSubmit = (): void => {
        const values: PlaceJobVacancyFormValues = {
            ...formValues,
            educationLevelIds: educationSubLevelId
                ? [educationLevelId, educationSubLevelId]
                : [educationLevelId === otherFormOptionKey ? [] : educationLevelId].flat(),
            fieldsOfStudyIds: fieldsOfStudy.map(fieldOfStudy => fieldOfStudy.id),
            employmentHourRange,
            jobTypeId,
            workRemotePreferenceId,
            escoOccupationIds: escoOccupations.map(occupation => occupation.id),
            skillIds: skills
                .filter(skill => skill.type === SkillType.skill)
                .map(skill => skill.id),
            escoSkillIds: skills
                .filter(skill => skill.type === SkillType.esco)
                .map(skill => skill.id),
            competencyIds: competencies.map(competency => competency.id),
        };

        if (!vacancy) {
            onCreate(values);
        } else {
            onEdit({
                ...vacancy,
                ...values,
            });
        }
    };

    return (
        <div className="place-job-vacancy-steps">
            {isLoading && <Loader className="place-job-vacancy-steps__loader" />}

            <StepProgressIndicator stepAmount={maxAmountOfSteps} currentStep={step} />

            {step === 1 && (
                <JobVacancyDataStep
                    isLoading={isLoading}
                    isEditing={!!vacancy}
                    values={formValues}
                    educationLevelId={educationLevelId}
                    educationSubLevelId={educationSubLevelId}
                    fieldsOfStudy={fieldsOfStudy}
                    occupations={escoOccupations}
                    salaryTimeframe={salaryTimeframe}
                    educationLevelOptions={educationLevelOptions}
                    educationSubLevelOptions={educationSubLevelOptions}
                    fieldsOfStudyOptions={fieldsOfStudyOptions}
                    jobTypeOptions={jobTypeOptions}
                    workRemoteOptions={workRemoteOptions}
                    sectorOptions={sectorOptions}
                    occupationOptions={escoOccupationOptions}
                    onChange={handleOnChange}
                    onEducationLevelChange={handleEducationLevelChange}
                    onEducationSubLevelChange={handleEducationSubLevelChange}
                    onFieldOfStudyChange={setFieldsOfStudy}
                    onEmploymentHourRangeChange={handleEmploymentHourRangeChange}
                    onJobTypeChange={handleJobTypeChange}
                    onWorkRemotePreferenceChange={handleWorkRemotePreferenceChange}
                    onOccupationsChange={handleOccupationsChange}
                    onSalaryTimeframeChange={setSalaryTimeframe}
                    onNextClick={handleNextClick}
                />
            )}

            {step === 2 && (
                <JobVacancySkillsStep
                    isLoading={isLoading}
                    skills={skills}
                    competencies={competencies}
                    skillOptions={skillOptions}
                    skillsError={skillsError}
                    competencyOptions={competencyOptions}
                    competenciesError={competenciesError}
                    onChangeSkills={setSkills}
                    onChangeCompetencies={setCompetencies}
                    onCreateSkillOption={handleCreateNewSkill}
                    onPreviousClick={handlePreviousClick}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
};

export default PlaceJobVacancySteps;
