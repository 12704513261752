/* eslint-disable camelcase */
export interface MatchProperty {
    uuid: string;
    name: string;
    value?: string;
    slug?: string;
    is_matched?: boolean;
}

export function transformToPropertyWithMatch(property: MatchProperty, matchingProperties?: string[]): MatchProperty {
    const propertyWithMatch: MatchProperty = { ...property };

    if (typeof matchingProperties === 'undefined' || matchingProperties.length === 0) {
        propertyWithMatch.is_matched = false;
        return propertyWithMatch;
    }

    propertyWithMatch.is_matched = matchingProperties.includes(property.uuid);
    return propertyWithMatch;
}
/* eslint-enable camelcase */
