import { retrieveRelationshipIds } from '../helpers/japi';
import { Resource } from '../japi/types/Resource';
import { SearchableOption } from '../types';

export interface FieldsOfStudyResource extends Resource {
    type: 'fields-of-study';
    id: string;
    attributes: {
        createdAt: string;
        manual: boolean;
        name: string;
        slug: string;
        updatedAt: string;
    };
}

export interface FieldOfStudy {
    id: string;
    name: string;
    slug: string;
    relatedEducationLevelIds?: string[];
}

export const transformResourceToFieldOfStudy = (
    fieldOfStudyResource: FieldsOfStudyResource,
): FieldOfStudy => {
    const relatedEducationLevelIds = retrieveRelationshipIds<FieldsOfStudyResource>(fieldOfStudyResource, 'educationLevel');

    return {
        id: fieldOfStudyResource.id,
        name: fieldOfStudyResource.attributes.name,
        slug: fieldOfStudyResource.attributes.slug,
        relatedEducationLevelIds,
    };
};

export const transformFieldOfStudyToSearchableOption = (
    fieldOfStudy: FieldOfStudy,
): SearchableOption => ({
    label: fieldOfStudy.name,
    value: fieldOfStudy.id,
});
