import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getVacancyApiCall } from '../../services/VacancyService';
import { TypedDispatch } from '..';
import {
    setError,
    setIsLoading,
    setStatusCode,
    setVacancy,
} from './vacancyDetail';

export const clearVacancy = () => (dispatch: TypedDispatch): void => {
    dispatch(setVacancy(undefined));
    dispatch(setStatusCode(undefined));
};

export const fetchVacancy = (vacancyUuid: string, userFromCompany?: boolean) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyResponse = await getVacancyApiCall(vacancyUuid, userFromCompany);

        if (!isFetchResultSuccessful(vacancyResponse)) {
            console.error('[fetchVacancy]', vacancyResponse.error);
            dispatch(setStatusCode(vacancyResponse.status));
            dispatch(setError(vacancyResponse.error));
            return;
        }

        dispatch(setVacancy(vacancyResponse.data));
    } catch (error) {
        console.error('[fetchVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
