import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ProfileProgress } from '../../../containers';
import { ActivationRef, ProfileStep, ProfileStepId } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { trans } from '../../../helpers/trans';
import useTimeout from '../../../hooks/useTimeout';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany } from '../../../store/company/companyActions';

interface ConnectedCompanyProgressProps {
    activationRefs: Record<string, ActivationRef>;
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyProgress: FC<ConnectedCompanyProgressProps> = ({
    activationRefs,
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [progressSteps, setProgressSteps] = useState<ProfileStep[]>([]);
    const [profileProgressIsCompleted, setProfileProgressIsCompleted] = useState<boolean>(false);
    const [shouldReveal, setShouldReveal] = useState<boolean>(true);

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);

    const hideDelay = 7500;
    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    useEffect((): void => {
        if (userFromCompany && company) {
            const companyProgressSteps = [
                {
                    order: 0,
                    isCompleted: !!company.logo,
                    stepId: ProfileStepId.avatar,
                    activationRef: activationRefs[ProfileStepId.avatar],
                    title: trans('companyProfile.progress.step.avatar.title'),
                    description: trans('companyProfile.progress.step.avatar.description'),
                    callToAction: trans('companyProfile.progress.step.avatar.callToAction'),
                },
                {
                    order: 1,
                    isCompleted: !!company.description,
                    stepId: ProfileStepId.about,
                    activationRef: activationRefs[ProfileStepId.about],
                    title: trans('companyProfile.progress.step.about.title'),
                    description: trans('companyProfile.progress.step.about.description'),
                    callToAction: trans('companyProfile.progress.step.about.callToAction'),
                },
                {
                    order: 2,
                    isCompleted: company.coreValues.length > 0,
                    stepId: ProfileStepId.coreValues,
                    activationRef: activationRefs[ProfileStepId.coreValues],
                    title: trans('companyProfile.progress.step.coreValues.title'),
                    description: trans('companyProfile.progress.step.coreValues.description'),
                    callToAction: trans('companyProfile.progress.step.coreValues.callToAction'),
                },
                {
                    order: 3,
                    isCompleted: !!company.funFacts,
                    stepId: ProfileStepId.funFacts,
                    activationRef: activationRefs[ProfileStepId.funFacts],
                    title: trans('companyProfile.progress.step.funFacts.title'),
                    description: trans('companyProfile.progress.step.funFacts.description'),
                    callToAction: trans('companyProfile.progress.step.funFacts.callToAction'),
                },
                {
                    order: 4,
                    isCompleted: !!company.coverImage,
                    stepId: ProfileStepId.coverImage,
                    activationRef: activationRefs[ProfileStepId.coverImage],
                    title: trans('companyProfile.progress.step.coverImage.title'),
                    description: trans('companyProfile.progress.step.coverImage.description'),
                    callToAction: trans('companyProfile.progress.step.coverImage.callToAction'),
                },
                {
                    order: 5,
                    isCompleted: !!company.coverVideo,
                    stepId: ProfileStepId.youTube,
                    activationRef: activationRefs[ProfileStepId.youTube],
                    title: trans('companyProfile.progress.step.youTube.title'),
                    description: trans('companyProfile.progress.step.youTube.description'),
                    callToAction: trans('companyProfile.progress.step.youTube.callToAction'),
                },
            ];

            setProgressSteps(companyProgressSteps);

            const isCompleted = companyProgressSteps.every((step => step.isCompleted));
            setProfileProgressIsCompleted(isCompleted);

            if (!isCompleted) {
                setShouldReveal(true);
            }

            if (isFirstRender) {
                setShouldReveal(!isCompleted);
                setIsFirstRender(false);
            }
        }
    }, [company]);

    useTimeout((): void => {
        if (profileProgressIsCompleted) {
            setShouldReveal(false);
        }
    }, hideDelay, [profileProgressIsCompleted]);

    const handleFinishProgress = (): void => {
        setShouldReveal(false);
    };

    const handleCallToActionClick = (stepId: ProfileStepId): void => {
        const selectedStep = progressSteps.find(step => step.stepId === stepId);

        if (selectedStep?.activationRef?.current) {
            selectedStep.activationRef.current.click();
        }
    };

    return shouldReveal && userFromCompany && progressSteps.length > 0 ? (
        <ProfileProgress
            title={trans('companyProfile.progress.title')}
            steps={progressSteps}
            finishedTitle={trans('companyProfile.progress.finished.title')}
            finishedDescription={trans('companyProfile.progress.finished.description')}
            onCallToActionClick={handleCallToActionClick}
            onFinishClick={handleFinishProgress}
            className={className}
        />
    ) : null;
};

export default ConnectedCompanyProgress;
