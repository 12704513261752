import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../../helpers/trans';
import { Competency } from '../../../../../models/Competencies';
import { IconButton } from '../../../..';

import './CompetencyHighlight.scss';

interface CompetencyHighlightProps {
    competency: Competency;
    onClose?: () => void;
    className?: string;
}

const CompetencyHighlight: FC<CompetencyHighlightProps> = ({
    competency,
    onClose,
    className = '',
}): ReactElement => (
    <div className={`competency-highlight ${className}`}>
        {onClose && (
            <IconButton
                icon="arrow-left"
                text={trans('forms.competencies.moreInfo.backButtonLabel')}
                onClick={onClose}
                className="competency-highlight__back-button"
            />
        )}

        <h2 className="competency-highlight__title">
            {competency.name}
        </h2>

        <p className="competency-highlight__description">
            {competency.description}
        </p>

        <ul className="competency-highlight__example-list">
            {competency.behavioralExamples.map(example => (
                <li key={example} className="competency-highlight__example-item">
                    {example}
                </li>
            ))}
        </ul>
    </div>
);

export default CompetencyHighlight;
