import React, { FC, ReactElement } from 'react';

import Card from '../../../../styledComponents/components/Card';
import { Styles } from './styles';

interface SmallCardWithImageProps {
    imageSrc: string;
    altImage?: string;
    className?: string;
}

const SmallCardWithImage: FC<SmallCardWithImageProps> = ({
    imageSrc,
    altImage,
    className = '',
}): ReactElement => (
    <Styles>
        <Card className={`small-card-with-image ${className}`}>
            <img className="small-card-with-image__image" src={imageSrc} alt={altImage} />
        </Card>
    </Styles>
);

export default SmallCardWithImage;
