import { convertFileToBase64 } from '../helpers/file';
import { Resource } from '../japi/types/Resource';

export interface FilesResource extends Resource {
    type: 'files';
    id: string;
    attributes: {
        uuid: string;
        filesize: number;
        mimetype: string;
        name: string;
        path: string;
    };
}

export interface FileRequest {
    name: string;
    data: string;
}

export interface FileModel {
    uuid: string;
    name: string;
    mimetype: string;
    filesize: number;
    path: string;
}

export interface FileFormData {
    file?: File;
}

export const transformToFileModel = (
    fileResource: FilesResource,
): FileModel => ({
    uuid: fileResource.id,
    name: fileResource.attributes.name,
    mimetype: fileResource.attributes.mimetype,
    filesize: fileResource.attributes.filesize,
    path: fileResource.attributes.path,
});

export const transformToFileRequest = async (
    file: File,
): Promise<FileRequest> => {
    const base64File = await convertFileToBase64(file);

    return {
        name: file.name,
        data: base64File,
    };
};
