import styled, { css } from 'styled-components';
import {
    alignContent,
    alignItems,
    alignSelf,
    display,
    flex,
    flexDirection,
    flexWrap,
    height,
    justifyContent,
    maxWidth,
    minHeight,
    minWidth,
    order,
    overflow,
    position,
    space,
    textAlign,
    width,
} from 'styled-system';

export const Col = styled('div')(
    {
        boxSizing: 'border-box',
    },
    space,
    display,
    width,
    maxWidth,
    minWidth,
    height,
    position,
    overflow,
    flex,
    flexDirection,
    alignSelf,
    alignItems,
    justifyContent,
    alignContent,
    textAlign,
    order,
    props => props.css,
);

Col.displayName = 'Col';

Col.propTypes = {
    ...space.propTypes,
    ...width.propTypes,
    ...position.propTypes,
    ...height.propTypes,
};

export const Row = styled(Col)(props => css`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    width: 100%;
    
    ${props.borderColor && css`
        border-bottom: 1px solid ${props.theme.colors[props.borderColor]};
        
        &:last-child {
            border-bottom: 0;
        }
    `}
    
    ${width}
    ${maxWidth}
    ${height}
    ${minHeight}
    ${flexWrap}
    ${display}
    ${flexDirection}
    ${alignItems}
    ${alignContent}
    ${justifyContent}
    ${position}
`);

Row.displayName = 'Row';

Row.propTypes = {
    ...flexWrap.propTypes,
    ...flexDirection.propTypes,
    ...alignItems.propTypes,
    ...height.propTypes,
    ...justifyContent.propTypes,
};
