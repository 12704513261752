import { useEffect } from 'react';

import { dataLayerPush } from '../helpers/analytics';
import { filterObjectOnFilledValues } from '../helpers/object';
import useQuery from './useQuery';

const useMailAnalytics = (): void => {
    const query = useQuery();

    const { ref, mid, pid } = Object.fromEntries(query);

    useEffect((): void => {
        const dataLayer = {
            event: 'mailLink',
            mailRef: ref,
            mailId: mid,
            personId: pid,
        };

        const filteredDataLayer = filterObjectOnFilledValues(dataLayer);

        dataLayerPush(filteredDataLayer);
    }, [ref, mid, pid]);
};

export default useMailAnalytics;
