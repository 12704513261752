import { FC, PropsWithChildren, ReactPortal } from 'react';

import { createPortal } from 'react-dom';

import { isSSR } from '../../helpers/environment';

const RootPortal: FC<PropsWithChildren> = ({ children }): ReactPortal | null => {
    if (isSSR) {
        return null;
    }

    const portalRoot = document.getElementById('portal-root');

    return portalRoot && createPortal(
        children,
        portalRoot,
    );
};

export default RootPortal;
