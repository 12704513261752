import React, {
    FC,
    PropsWithChildren,
    ReactElement,
    TransitionEvent,
    useRef,
} from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../components';
import { trans } from '../../helpers/trans';
import { Loader } from '..';

import './Sidebar.scss';

interface SidebarProps {
    isLoading?: boolean;
    isExpanded: boolean;
    title: string;
    onClose: () => void;
    className?: string;
}

const Sidebar: FC<PropsWithChildren<SidebarProps>> = ({
    isLoading,
    isExpanded,
    title,
    onClose,
    className = '',
    children,
}): ReactElement => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const handleEntryFocus = (event: TransitionEvent<HTMLDivElement>): void => {
        const targetsWrapper = event.target === wrapperRef.current;

        if (closeButtonRef.current && targetsWrapper && isExpanded) {
            closeButtonRef.current.focus();
        }
    };

    const sidebarClassNames = classNames('sidebar', {
        'sidebar--is-expanded': isExpanded,
    }, className);

    return (
        <section className={sidebarClassNames}>
            <div
                ref={wrapperRef}
                className="sidebar__wrapper"
                onTransitionEnd={handleEntryFocus}
            >
                <header className="sidebar__header">
                    <h1 className="sidebar__title">
                        {title}
                    </h1>

                    {isLoading && (
                        <Loader
                            hideText
                            className="sidebar__loader"
                            spinnerClassName="sidebar__loader-spinner"
                        />
                    )}

                    <Button
                        ref={closeButtonRef}
                        text={trans('actions.close')}
                        tabIndex={isExpanded ? 0 : -1}
                        onClick={onClose}
                        className="sidebar__close-button"
                    >
                        <Icon name="cross" className="sidebar__close-button-icon" />
                    </Button>
                </header>

                <div className="sidebar__child-wrapper">
                    {isExpanded && children}
                </div>
            </div>
        </section>
    );
};

export default Sidebar;
