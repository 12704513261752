import React, { FC, ReactElement, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Card, LinkButton, SafeHtml } from '../../../components';
import { maxShortTextLength } from '../../../constants';
import { formatAppointment, getAgeInDays } from '../../../helpers/date';
import { sanitizeCustomHtml } from '../../../helpers/sanitizeHtml';
import { trimToMaxLength } from '../../../helpers/string';
import { trans } from '../../../helpers/trans';
import { ApplicationAppointmentStatus } from '../../../models/ApplicationAppointments';
import { Application, ApplicationStatus } from '../../../models/Applications';
import { RoutePaths } from '../../../routes';
import { MessageState } from '../../../types';
import { Avatar, LinkIconButton, Tag } from '../..';

import './ApplicationCard.scss';

interface ApplicationCardProps {
    isCandidate?: boolean;
    application: Application;
    className?: string;
}

const ApplicationCard: FC<ApplicationCardProps> = ({
    isCandidate,
    application,
    className = '',
}): ReactElement => {
    const { isAnonymous } = application.vacancy;

    const avatar = isCandidate ? {
        src: application.vacancy.companyLogo,
        alt: trans('compositions.applicationCard.logoAlt', {
            company: application.vacancy.companyName,
        }),
        fallbackString: application.vacancy.companyName,
    } : {
        src: application.applicant.avatar,
        alt: trans('compositions.applicationCard.avatarAlt', {
            name: application.applicant.fullName,
        }),
        fallbackString: application.applicant.fullName,
    };

    const applicationPath = isCandidate
        ? RoutePaths.application(application.uuid)
        : RoutePaths.myVacancyApplication(application.vacancy.uuid, application.applicant.uuid, application.uuid);

    const chatPath = isCandidate
        ? RoutePaths.candidateOverviewMessages(application.vacancy.companyUuid)
        : RoutePaths.companyOverviewMessages(application.applicant.uuid);

    const title = isCandidate
        ? application.vacancy.title
        : application.applicant.fullName;

    const subTitle = isCandidate
        ? trans('compositions.applicationCard.atCompany', {
            company: application.vacancy.companyName,
            city: application.vacancy.companyCity,
        })
        : getAgeInDays(application.createdAt);

    const sanitizedMotivation = useMemo((): string => {
        const motivationPreview = trimToMaxLength(application.motivation, maxShortTextLength);

        return sanitizeCustomHtml(motivationPreview, {
            allowedTags: ['p', 'br'],
            allowedAttributes: {},
        });
    }, [application]);

    const applicationStatusIcon = useMemo((): string | undefined => {
        if (application.status === ApplicationStatus.hired) return 'like';
        if (application.status === ApplicationStatus.declined) return 'dislike';

        return undefined;
    }, [application]);

    const applicationStatusState = useMemo((): MessageState | undefined => {
        if ([ApplicationStatus.accepted, ApplicationStatus.hired].includes(application.status)) {
            return MessageState.positive;
        }

        if (application.status === ApplicationStatus.invited) return MessageState.warning;
        if (application.status === ApplicationStatus.declined) return MessageState.negative;

        return undefined;
    }, [application.status]);

    const applicationStatusLabel = useMemo((): string => {
        let status = application.status.toLowerCase();
        const activeInvite = application.invites?.[0];

        if (application.status === ApplicationStatus.invited && activeInvite) {
            status = activeInvite.isFromApplicant
                ? 'invitedByCandidate'
                : 'invited';
        }

        return isCandidate
            ? trans(`compositions.applicationCard.status.candidate.${status}`)
            : trans(`compositions.applicationCard.status.company.${status}`);
    }, [application]);

    const activeAppointment = application.status === ApplicationStatus.accepted && application.invites[0]
        ? application.invites[0].appointments
            .find(appointment => appointment.status === ApplicationAppointmentStatus.accepted)
        : undefined;

    const [appointmentDate, appointmentTimes] = activeAppointment
        ? formatAppointment(activeAppointment.startDate, activeAppointment.endDate).split(',')
        : [];

    return (
        <div className={`application-card ${className}`}>
            <Card className="application-card__card">
                <Avatar
                    isAnonymous={!isCandidate && isAnonymous}
                    src={avatar.src}
                    alt={avatar.alt}
                    fallbackString={avatar.fallbackString}
                    className="application-card__avatar"
                />

                <div className="application-card__text-wrapper">
                    <div className="application-card__title-wrapper">
                        <Link to={applicationPath} className="application-card__link">
                            <h2 className="application-card__title">
                                {!isCandidate && isAnonymous
                                    ? trans('basic.anonymousCandidate')
                                    : title}
                            </h2>
                        </Link>
                    </div>

                    <p className="application-card__subtitle">
                        {subTitle}
                    </p>

                    <SafeHtml
                        html={sanitizedMotivation}
                        className="application-card__motivation"
                    />
                </div>

                <div className="application-card__button-wrapper">
                    <LinkButton
                        to={applicationPath}
                        text={trans('compositions.applicationCard.button.viewApplication')}
                        className="application-card__button"
                    />

                    {(isCandidate || !isAnonymous) && (
                        <LinkIconButton
                            icon="send"
                            text={trans('actions.sendMessage')}
                            to={chatPath}
                            className="application-card__button application-card__button--for-chat"
                        />
                    )}
                </div>

                <div className="application-card__status-wrapper">
                    <h3 className="application-card__status-title">
                        {trans('compositions.applicationCard.status.title')}
                    </h3>

                    <Tag
                        icon={applicationStatusIcon}
                        status={applicationStatusState}
                        text={applicationStatusLabel}
                        className="application-card__status-tag"
                    />

                    {activeAppointment && (
                        <div className="application-card__active-appointment">
                            <p className="application-card__active-appointment-date">
                                {appointmentDate}
                            </p>
                            <p className="application-card__active-appointment-times">
                                {appointmentTimes}
                            </p>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default ApplicationCard;
