/* eslint-disable camelcase */
import { MatchProperty, transformToPropertyWithMatch } from './MatchProperty';
import { Person, PersonApiResponseParams, transformPerson } from './Person';
import { transformToUser, User } from './User';
import { VacancyType } from './VacancyType';

export interface MatchingCandidateApiResponseParams extends PersonApiResponseParams {
    match_details?: MatchingCandidateMatchDetails;
    invited_at?: string | null;
    ignored_at?: string | null;
}

export interface MatchingCandidateMatchDetails {
    match_percentage: number;
    matching_attributes: MatchingCandidateMatchingAttributes;
}

export interface MatchingCandidateMatchingAttributes {
    compensation: boolean;
    gross_salary: boolean;
    field_of_study?: string[];
    skills: string[];
    competencies: string[];
    amount_of_employees: string[];
    // internship
    grades?: string[];
    internship_types?: string[];
    skills_to_learn?: string[];
    competencies_to_learn?: string[];
    // job
    job_types?: string[];
    employments?: string[];
    profession?: string[];
    education_level?: string[];
}

export interface MatchingCandidate extends Person {
    matching?: number;
    properties: MatchingCandidateProperties;
    invited_at?: string;
    ignored_at?: string;
    groups: any[];
    user: User;
}

export interface MatchingCandidateProperties {
    amount_of_employees?: MatchProperty[];
    field_of_study?: MatchProperty;
    skills?: MatchProperty[];
    competencies?: MatchProperty[];
    // internship specific
    skills_to_learn?: MatchProperty[];
    competencies_to_learn?: MatchProperty[];
    grade?: MatchProperty;
    compensation?: MatchProperty;
    // job specific
    gross_salary?: MatchProperty;
    employments?: MatchProperty[];
    profession?: MatchProperty;
    education_level?: MatchProperty;
}

function transformToProperties(person: MatchingCandidateApiResponseParams, vacancyType: string, showToLearn: boolean): MatchingCandidateProperties {
    if (vacancyType === VacancyType.internship || showToLearn) {
        return {
            compensation: person.internship_preferences_set.compensation,
            field_of_study: person.school_experiences.length > 0 ? person.school_experiences[0].field_of_study : undefined,
            skills: person.skills,
            skills_to_learn: person.skills_to_learn,
            competencies: person.competencies,
            competencies_to_learn: person.competencies_to_learn,
            amount_of_employees: person.internship_preferences_set.amount_of_employees,
            grade: person.school_experiences.length > 0 ? person.school_experiences[0].grade : undefined,
        };
    }

    return {
        gross_salary: person.job_preferences_set.gross_salary,
        field_of_study: person.school_experiences.length > 0 ? person.school_experiences[0].field_of_study : undefined,
        skills: person.skills,
        competencies: person.competencies,
        profession: person.job_preferences_set.profession,
        amount_of_employees: person.job_preferences_set.amount_of_employees,
        education_level: person.school_experiences.length > 0 ? person.school_experiences[0].education_level : undefined,
    };
}

function transformToPropertiesWithMatch(person: MatchingCandidateApiResponseParams, vacancyType: string, showToLearn: boolean): MatchingCandidateProperties {
    if (vacancyType === VacancyType.internship || showToLearn) {
        return {
            compensation: { ...person.internship_preferences_set.compensation, is_matched: person.match_details?.matching_attributes?.compensation },
            field_of_study: person.school_experiences.length > 0 ? transformToPropertyWithMatch(person.school_experiences[0].field_of_study, person.match_details?.matching_attributes?.field_of_study) : undefined,
            skills: person.skills.map(skill => transformToPropertyWithMatch(skill, person.match_details?.matching_attributes?.skills)),
            skills_to_learn: person.skills_to_learn.map(skillToLearn => transformToPropertyWithMatch(skillToLearn, person.match_details?.matching_attributes?.skills_to_learn)),
            competencies: person.competencies?.map(competency => transformToPropertyWithMatch(competency, person.match_details?.matching_attributes?.competencies)),
            competencies_to_learn: person.competencies_to_learn?.map(competencyToLearn => transformToPropertyWithMatch(competencyToLearn, person.match_details?.matching_attributes?.competencies_to_learn)),
            amount_of_employees: person.internship_preferences_set.amount_of_employees.map((amount: any) => transformToPropertyWithMatch(amount, person.match_details?.matching_attributes?.amount_of_employees)),
            grade: transformToPropertyWithMatch(person.internship_preferences_set.grade, person.match_details?.matching_attributes?.grades),
        };
    }

    return {
        gross_salary: { ...person.job_preferences_set.gross_salary, is_matched: person.match_details?.matching_attributes?.gross_salary },
        field_of_study: person.school_experiences.length > 0 ? transformToPropertyWithMatch(person.school_experiences[0].field_of_study, person.match_details?.matching_attributes?.field_of_study) : undefined,
        profession: transformToPropertyWithMatch(person.job_preferences_set.profession, person.match_details?.matching_attributes?.profession),
        skills: person.skills.map(skill => transformToPropertyWithMatch(skill, person.match_details?.matching_attributes?.skills)),
        competencies: person.competencies?.map(competency => transformToPropertyWithMatch(competency, person.match_details?.matching_attributes?.competencies)),
        amount_of_employees: person.job_preferences_set.amount_of_employees.map((amount: any) => transformToPropertyWithMatch(amount, person.match_details?.matching_attributes?.amount_of_employees)),
        education_level: person.school_experiences.length > 0 ? transformToPropertyWithMatch(person.school_experiences[0].education_level, person.match_details?.matching_attributes?.education_level) : undefined,
    };
}

export function transformMatchingCandidate(
    person: MatchingCandidateApiResponseParams,
    vacancyType: string = VacancyType.internship,
    showToLearn = false,
    forApplication = false,
): MatchingCandidate {
    return {
        ...transformPerson(person),
        user: transformToUser(person.user),
        matching: person.match_details?.match_percentage || undefined,
        properties: !person.match_details ? transformToProperties(person, vacancyType, showToLearn) : transformToPropertiesWithMatch(person, vacancyType, showToLearn),
        invited_at: person.invited_at ? person.invited_at : undefined,
        ignored_at: person.ignored_at ? person.ignored_at : undefined,
        groups: vacancyType === VacancyType.internship
            ? [
                [
                    'internship_types',
                    'grade',
                    'travel_distance',
                    ...(!forApplication) ? ['compensation'] : [],
                    'amount_of_employees',
                ],
                [
                    'field_of_study',
                ],
            ]
            : [
                [
                    'education_level',
                    'job_types',
                    'amount_of_employees',
                    ...(!forApplication) ? ['gross_salary'] : [],
                    'profession',
                ],
                ['employments'],
                ['field_of_study'],
            ],
    };
}
/* eslint-enable camelcase */
