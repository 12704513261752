import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { CompetenciesResource, Competency, transformToCompetency } from '../models/Competencies';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getCompetenciesApiCall = async (): Promise<FetchResult<Competency[], string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/public/competencies`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<CompetenciesResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const competencies = doc.data.map(transformToCompetency);
        const sortedCompetencies = competencies.sort((competency, comparisonCompetency) => competency.name.localeCompare(comparisonCompetency.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedCompetencies,
        };
    } catch (error) {
        console.error('[getCompetenciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
