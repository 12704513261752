import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../../components';
import {
    ButtonList,
    CompetenciesSelector,
    FilterButton,
    Modal,
    SearchableValueInput,
    SubmitButton,
} from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { CompetenciesFormData, Competency } from '../../../../models/Competencies';
import { EscoOccupation, transformEscoOccupationToSearchableOption } from '../../../../models/Esco';
import { SearchableOption } from '../../../../types';
import { TalentMatchingInputWrapper } from '..';

import './TalentMatchingCompetencies.scss';

interface TalentMatchingCompetenciesProps {
    isLoading: boolean;
    isValidCandidate: boolean;
    isToLearn?: boolean;
    value: Competency[];
    competencyOptions: Competency[];
    occupationOptions?: EscoOccupation[];
    onSelectOccupation?: (occupation: EscoOccupation) => void;
    onSubmitToProfile: (competenciesFormData: CompetenciesFormData) => void;
    onSubmit: (competenciesFormData: CompetenciesFormData) => void;
    className?: string;
}

const TalentMatchingCompetencies: FC<TalentMatchingCompetenciesProps> = ({
    isLoading,
    isValidCandidate,
    isToLearn,
    value,
    competencyOptions,
    occupationOptions = [],
    onSelectOccupation,
    onSubmitToProfile,
    onSubmit,
    className = '',
}): ReactElement => {
    const [formModalIsOpen, setFormModalIsOpen] = useState<boolean>(false);
    const [competencies, setCompetencies] = useState<Competency[]>(value);
    const [occupation, setOccupation] = useState<string>('');

    const openFormModal = (): void => setFormModalIsOpen(true);
    const closeFormModal = (): void => setFormModalIsOpen(false);

    useEffect((): void => {
        setCompetencies(value);
    }, [value]);

    const handleChangeOccupation = (occupationValue: SearchableOption): void => {
        setOccupation(occupationValue.label);

        const selectedOccupation = occupationValue.value
            ? occupationOptions.find(option => option.id === occupationValue.value)
            : undefined;

        if (selectedOccupation && onSelectOccupation) {
            onSelectOccupation(selectedOccupation);
            closeFormModal();
        }
    };

    const handleSubmitToProfile = (): void => {
        onSubmitToProfile({ competencies });

        closeFormModal();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({ competencies });

        closeFormModal();
    };

    const buttonLabel = isToLearn
        ? trans('basic.competenciesToLearn')
        : trans('basic.competencies');

    const translationPrefix = isToLearn
        ? 'vacancies.overview.talentMatch.competenciesToLearn'
        : 'vacancies.overview.talentMatch.competencies';

    return (
        <div className={`talent-matching-competencies ${className}`}>
            <h3 className="talent-matching-competencies__title">
                {trans(`${translationPrefix}.buttonLabel`)}
            </h3>

            <FilterButton
                activeFilters={value.length}
                text={buttonLabel}
                onClick={openFormModal}
                className="talent-matching-competencies__button"
            />

            {formModalIsOpen && (
                <Modal onClose={closeFormModal}>
                    <form onSubmit={handleSubmit}>
                        <h1 className="talent-matching-competencies__modal-title">
                            {trans(`${translationPrefix}.title`)}
                        </h1>

                        <p className="talent-matching-competencies__modal-description">
                            {trans(`${translationPrefix}.description`)}
                        </p>

                        <TalentMatchingInputWrapper
                            title={trans(`${translationPrefix}.input.title`)}
                            description={trans(`${translationPrefix}.input.description`)}
                            className="talent-matching-competencies__input-wrapper"
                        >
                            <CompetenciesSelector
                                label={trans('forms.competencies.input.search')}
                                value={competencies}
                                options={competencyOptions}
                                onChange={setCompetencies}
                            />
                        </TalentMatchingInputWrapper>

                        {onSelectOccupation && (
                            <TalentMatchingInputWrapper
                                title={trans(`${translationPrefix}.suggestion.title`)}
                                description={trans(`${translationPrefix}.suggestion.description`)}
                                className="talent-matching-competencies__input-wrapper"
                            >
                                <SearchableValueInput
                                    isSearchable
                                    label={trans('forms.occupation.input.position')}
                                    value={occupation}
                                    options={occupationOptions.map(transformEscoOccupationToSearchableOption)}
                                    placeholder={trans('forms.occupation.placeholder.position')}
                                    onChange={handleChangeOccupation}
                                />
                            </TalentMatchingInputWrapper>
                        )}

                        <ButtonList className="talent-matching-competencies__button-wrapper">
                            <SubmitButton
                                isLoading={isLoading}
                                icon="search"
                                text={trans('actions.search')}
                            />

                            {isValidCandidate && (
                                <Button
                                    text={trans('actions.saveToProfileSearch')}
                                    onClick={handleSubmitToProfile}
                                    disabled={competencies.length < 3}
                                />
                            )}

                            <Button
                                text={trans('actions.cancel')}
                                onClick={closeFormModal}
                                className="talent-matching-competencies__cancel-button"
                            />
                        </ButtonList>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default TalentMatchingCompetencies;
