import React, { FC, FormEvent, ReactElement } from 'react';

import { SchoolExperience } from '../../../../_old/app_talentz/models/SchoolExperience';
import {
    AmountOfEmployeesFilter,
    ApplyTypesFilter,
    CompensationFilter,
    DistanceFilter,
    GradesFilter,
    IconButton,
    InternshipEducationLevelsFilter,
    InternshipTypesFilter,
    SbbApprovalFilter,
    SectorsFilter,
    Sidebar,
    SubmitButton,
    WorkRemotePreferenceFilter,
} from '../../../../compositions';
import { InternshipTypeOption } from '../../../../compositions/@filters/InternshipTypesFilter/InternshipTypesFilter';
import { trans } from '../../../../helpers/trans';
import { InternshipVacancyOverviewFilterValues } from '../../../../models/VacancyOverview';
import { WorkRemoteOption } from '../../../../models/WorkRemote';
import { FormOption } from '../../../../types';

import './InternshipVacancyOverviewFilterSidebar.scss';

interface InternshipVacancyOverviewFilterSidebarProps {
    isLoading: boolean;
    isExpanded: boolean;
    isSbbUser: boolean;
    hasLocation: boolean;
    filterValues: InternshipVacancyOverviewFilterValues;
    schoolExperience?: SchoolExperience;
    applyTypeOptions: FormOption[];
    sectorOptions: FormOption[];
    educationLevelOptions: FormOption[];
    workRemoteOptions: WorkRemoteOption[];
    internshipTypeOptions: InternshipTypeOption[];
    gradeOptions: FormOption[];
    amountOfEmployeesOptions: FormOption[];
    sbbApprovalOptions: FormOption[];
    onChange: (values: Partial<InternshipVacancyOverviewFilterValues>) => void;
    onReset: () => void;
    onSubmit: () => void;
    onClose: () => void;
    className?: string;
}

const InternshipVacancyOverviewFilterSidebar: FC<InternshipVacancyOverviewFilterSidebarProps> = ({
    isLoading,
    isExpanded,
    isSbbUser,
    hasLocation,
    filterValues,
    schoolExperience,
    applyTypeOptions,
    sectorOptions,
    educationLevelOptions,
    workRemoteOptions,
    internshipTypeOptions,
    gradeOptions,
    amountOfEmployeesOptions,
    sbbApprovalOptions,
    onChange,
    onReset,
    onSubmit,
    onClose,
    className = '',
}): ReactElement => {
    const handleDistanceChange = (value: number): void => {
        onChange({ distance: value });
    };

    const handleSbbApprovalChange = (value: string[]): void => {
        onChange({ sbbApproval: value });
    };

    const handleApplyTypeChange = (value: string): void => {
        onChange({ applyType: value });
    };

    const handleSectorsChange = (value: string[]): void => {
        onChange({ sectors: value });
    };

    const handleEducationLevelsChange = (value: string): void => {
        onChange({ educationLevels: [value] });
    };

    const handleWorkRemotePreferenceChange = (value: string[]): void => {
        onChange({ workRemotePreference: value });
    };

    const handleInternshipTypesChange = (value: string[]): void => {
        onChange({ internshipTypes: value });
    };

    const handleGradeChange = (value: string): void => {
        onChange({ grade: value });
    };

    const handleAmountOfEmployeesChange = (value: string[]): void => {
        onChange({ amountOfEmployees: value });
    };

    const handleCompensationChange = (value: number): void => {
        onChange({ compensation: value });
    };

    const handleResetClick = (): void => {
        onClose();
        onReset();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onClose();
        onSubmit();
    };

    return (
        <Sidebar
            isLoading={isLoading}
            isExpanded={isExpanded}
            title={trans('vacancies.filters.title')}
            onClose={onClose}
            className={`internship-vacancy-overview-filter-sidebar ${className}`}
        >
            <form onSubmit={handleSubmit} className="internship-vacancy-overview-filter-sidebar__form">
                <div className="internship-vacancy-overview-filter-sidebar__property-wrapper">
                    {hasLocation && (
                        <DistanceFilter
                            label={trans('vacancies.filters.property.distance')}
                            value={filterValues.distance}
                            onChange={handleDistanceChange}
                            className="internship-vacancy-overview-filter-sidebar__property"
                        />
                    )}

                    {isSbbUser && (
                        <SbbApprovalFilter
                            label={trans('vacancies.filters.property.sbbApproval')}
                            value={filterValues.sbbApproval}
                            options={sbbApprovalOptions}
                            onChange={handleSbbApprovalChange}
                            className="internship-vacancy-overview-filter-sidebar__property"
                        />
                    )}

                    <ApplyTypesFilter
                        label={trans('vacancies.filters.property.applyAnonymous')}
                        value={filterValues.applyType}
                        options={applyTypeOptions}
                        onChange={handleApplyTypeChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <SectorsFilter
                        label={trans('vacancies.filters.property.sector')}
                        value={filterValues.sectors}
                        sectors={sectorOptions}
                        onChange={handleSectorsChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <InternshipEducationLevelsFilter
                        label={trans('vacancies.filters.property.educationLevel')}
                        value={filterValues.educationLevels.length > 0 ? filterValues.educationLevels[0] : ''}
                        options={educationLevelOptions}
                        onChange={handleEducationLevelsChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <WorkRemotePreferenceFilter
                        label={trans('vacancies.filters.property.workRemote')}
                        value={filterValues.workRemotePreference}
                        workRemoteOptions={workRemoteOptions}
                        onChange={handleWorkRemotePreferenceChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <InternshipTypesFilter
                        label={trans('vacancies.filters.property.internshipType')}
                        value={filterValues.internshipTypes}
                        schoolExperience={schoolExperience}
                        options={internshipTypeOptions}
                        onChange={handleInternshipTypesChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <GradesFilter
                        label={trans('vacancies.filters.property.grade')}
                        value={filterValues.grade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <AmountOfEmployeesFilter
                        label={trans('vacancies.filters.property.amountOfEmployees')}
                        value={filterValues.amountOfEmployees}
                        options={amountOfEmployeesOptions}
                        onChange={handleAmountOfEmployeesChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />

                    <CompensationFilter
                        label={trans('vacancies.filters.property.compensation')}
                        value={filterValues.compensation}
                        onChange={handleCompensationChange}
                        className="internship-vacancy-overview-filter-sidebar__property"
                    />
                </div>

                <footer className="internship-vacancy-overview-filter-sidebar__footer">
                    <SubmitButton
                        isLoading={isLoading}
                        icon="search"
                        text={trans('actions.search')}
                    />
                    <IconButton
                        icon="refresh"
                        text={trans('actions.reset')}
                        disabled={isLoading}
                        onClick={handleResetClick}
                        className="internship-vacancy-overview-filter-sidebar__reset-button"
                    />
                </footer>
            </form>
        </Sidebar>
    );
};

export default InternshipVacancyOverviewFilterSidebar;
