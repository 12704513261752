import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconMail: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 70" className={`icon ${className}`}>
        <path d="M100,0v70H0V0H100z M10,25v35h80V25L50,45.6L10,25z M90,16.3V10H10v6.3l40,20.6L90,16.3z" />
    </svg>
);

export default IconMail;
