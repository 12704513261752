import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PersonSettings } from '../../models/PersonSettings';

export interface UserPersonSettingsState {
    loading: boolean;
    data: PersonSettings | null;
    errors: Record<string, unknown>;
}

const initialState: UserPersonSettingsState = {
    loading: false,
    data: null,
    errors: {},
};

const userPersonSettingsSlice = createSlice({
    name: 'userPersonSettings',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): UserPersonSettingsState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setData(state, action: PayloadAction<PersonSettings | null>): UserPersonSettingsState {
            return {
                ...state,
                data: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): UserPersonSettingsState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setData,
    setErrors,
} = userPersonSettingsSlice.actions;

export default userPersonSettingsSlice;
