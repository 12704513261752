import React, {
    FC,
    FormEvent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { AddressFormValues } from '../../../../_old/app_talentz/models/Address';
import { MatchProperty } from '../../../../_old/app_talentz/models/MatchProperty';
import { ValidationErrors } from '../../../../_old/app_talentz/models/ValidationErrors';
import TextEditor from '../../../../_old/components/form/TextEditor';
import { isEmptyObject } from '../../../../_old/helpers/objectHelper';
import { Card } from '../../../../components';
import { SelectOption } from '../../../../components/@inputs/Select/Select';
import {
    BlockRadioList,
    CheckboxList,
    DateInput,
    InformationBox,
    LocationCheck,
    RadioList as NewRadioList,
    SalaryInput,
    SearchableMultiValueInput,
    SelectInput,
    StepFooter,
    StepHeader,
    TextInput,
} from '../../../../compositions';
import { maxVacancyLifetimeInDays } from '../../../../constants';
import { trans } from '../../../../helpers/trans';
import { FieldOfStudy, transformFieldOfStudyToSearchableOption } from '../../../../models/FieldsOfStudy';
import { SbbRecognition } from '../../../../models/SbbTrainingCompany';
import { Sector } from '../../../../models/Sectors';
import { Grade, InternshipType, sectorsToSelectBoxOptions } from '../../../../models/VacancyOptions';
import { RoutePaths } from '../../../../routes';
import { PlaceInternshipVacancyFormValues } from '../../../../services/PlaceVacancyService';
import { FormOption, SalaryTimeframe, SearchableOption } from '../../../../types';
import { CompanyOverviewTab } from '../../../../types/pageTabs';
import { retrieveEducationLevelSpecificOptions } from '../../../@forms/SchoolExperienceForm/helpers';
import { validateInternshipVacancyDataStep } from '../../../PlaceJobVacancySteps/helpers/placeInternshipVacancyStepsValidator';
import PublicationAndExpirationDates from '../../../PublicationAndExpirationDates/PublicationAndExpirationDates';
import { animateToInternshipErrorElement, convertGradesToCheckboxListOptions } from '../../helpers';

import './InternshipVacancyDataStep.scss';

interface InternshipVacancyDataStepProps {
    isEditing?: boolean;
    isLoading: boolean;
    values: PlaceInternshipVacancyFormValues;
    educationLevelId: string;
    internshipTypeId: string;
    fieldsOfStudy: FieldOfStudy[];
    maxVacancyLifeTimeInDays?: number;
    educationLevelOptions: MatchProperty[];
    internshipTypeOptions: InternshipType[];
    fieldsOfStudyOptions: FieldOfStudy[];
    gradeOptions: Grade[];
    sectorOptions: Sector[];
    workRemoteOptions: FormOption[];
    sbbRecognitions: SbbRecognition[];
    onEducationLevelChange: (educationLevelUuid: string) => void;
    onInternshipTypeChange: (internshipTypeUuid: string) => void;
    onFieldOfStudyChange: (fieldOfStudyOptions: FieldOfStudy[]) => void;
    onWorkRemotePreferenceChange: (workRemotePreference: string) => void;
    onChange: (values: Partial<PlaceInternshipVacancyFormValues>) => void;
    onNextClick: () => void;
}

const InternshipVacancyDataStep: FC<InternshipVacancyDataStepProps> = ({
    isEditing = false,
    isLoading,
    values,
    maxVacancyLifeTimeInDays = maxVacancyLifetimeInDays,
    educationLevelId,
    internshipTypeId,
    fieldsOfStudy,
    educationLevelOptions,
    internshipTypeOptions,
    fieldsOfStudyOptions,
    gradeOptions,
    sectorOptions,
    workRemoteOptions,
    sbbRecognitions,
    onEducationLevelChange,
    onInternshipTypeChange,
    onFieldOfStudyChange,
    onWorkRemotePreferenceChange,
    onChange,
    onNextClick,
}): ReactElement => {
    const navigate = useNavigate();

    const [errors, setErrors] = useState<ValidationErrors>({});
    const [isSubmittedOnce, setSubmittedOnce] = useState(false);

    const [fieldsOfStudyValue, setFieldsOfStudyValue] = useState<SearchableOption[]>([]);

    const stepHeaderTitle = isEditing
        ? trans('placeVacancyPage.internship.dataStep.editTitle')
        : trans('placeVacancyPage.internship.dataStep.title');

    const applyTypeOptions = [
        { label: trans('forms.vacancy.applyRegular.label'), value: 'false' },
        { label: trans('forms.vacancy.applyAnonymously.label'), value: 'true' },
    ];

    const isMbo = useMemo((): boolean => (
        educationLevelOptions.find(option => option.uuid === educationLevelId)?.name === 'MBO'
    ), [educationLevelOptions, educationLevelId]);

    const educationLevelInternshipTypeOptions = useMemo((): InternshipType[] => (
        internshipTypeOptions.filter(option => option.educationLevel.uuid === educationLevelId)
    ), [internshipTypeOptions, educationLevelId]);

    const educationLevelFieldOfStudyOptions = useMemo((): SearchableOption[] => {
        if (fieldsOfStudyOptions.length === 0) return [];

        const filteredEducationFieldsOfStudy = retrieveEducationLevelSpecificOptions<FieldOfStudy>(fieldsOfStudyOptions, educationLevelId);

        const sbbFieldOfStudyNames = sbbRecognitions.map(recognition => recognition.description);
        const sbbFieldsOfStudy = filteredEducationFieldsOfStudy
            .filter(fieldOfStudy => sbbFieldOfStudyNames.includes(fieldOfStudy.name))
            .map(transformFieldOfStudyToSearchableOption)
            .map(fieldOfStudy => ({ ...fieldOfStudy, secondaryLabel: trans('basic.sbbApproved.fieldOfStudy') }));

        const nonSbbFieldsOfStudy = filteredEducationFieldsOfStudy
            .filter(fieldOfStudy => !sbbFieldOfStudyNames.includes(fieldOfStudy.name))
            .map(transformFieldOfStudyToSearchableOption);

        return [...sbbFieldsOfStudy, ...nonSbbFieldsOfStudy];
    }, [fieldsOfStudyOptions, educationLevelId, sbbRecognitions]);

    const validateForm = (): ValidationErrors => {
        const validationErrors = validateInternshipVacancyDataStep({
            ...values,
            educationLevelId,
            internshipTypeId,
            fieldsOfStudyIds: fieldsOfStudyValue.map(fieldOfStudy => fieldOfStudy.value),
        }, true);

        setErrors(validationErrors);

        return validationErrors;
    };

    // Re validate all values when the user corrects itself
    useEffect((): void => {
        if (isSubmittedOnce) {
            validateForm();
        }
    }, [values]);

    useEffect((): void => {
        setFieldsOfStudyValue(fieldsOfStudy.map(transformFieldOfStudyToSearchableOption));
    }, [fieldsOfStudy]);

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmittedOnce(true);
        const validationErrors = validateForm();

        if (isEmptyObject(validationErrors)) {
            onNextClick();
        } else {
            animateToInternshipErrorElement(validationErrors);
        }
    }, [onNextClick, validateForm]);

    const handleApplyTypeChange = useCallback((applyAnonymous: string) => {
        onChange({
            ...values,
            applyAnonymous: applyAnonymous === 'true',
        });
    }, [values, onChange]);

    const handleTitleChange = useCallback((title: string) => {
        onChange({
            title,
        });
    }, [onChange]);

    const handleZipCodeCheckChange = useCallback((address: AddressFormValues | null) => {
        onChange({
            address,
        });
    }, [onChange]);

    const handlePublicationDateChange = (date: Date | null) => {
        onChange({
            publicationDate: date,
        });
    };

    const handleExpirationDateChange = (date: Date | null) => {
        onChange({
            expirationDate: date,
        });
    };

    const handleFieldsOfStudyChange = (fieldsOfStudyValues: SearchableOption[]): void => {
        setFieldsOfStudyValue(fieldsOfStudyValues);

        const selectedFieldsOfStudyList = fieldsOfStudyValues.map(fieldOfStudy => fieldOfStudy.value);

        const selectedFieldsOfStudy = fieldsOfStudyValues.length
            ? fieldsOfStudyOptions.filter(option => selectedFieldsOfStudyList.includes(option.id))
            : [];

        const orderedSelectedFieldsOfStudy = selectedFieldsOfStudyList.map(fieldOfStudyValue => {
            const selectedFieldOfStudy = selectedFieldsOfStudy.filter(fieldOfStudy => fieldOfStudy.id === fieldOfStudyValue);

            return selectedFieldOfStudy[0];
        }).filter(Boolean);

        if (selectedFieldsOfStudy.length) {
            onFieldOfStudyChange(orderedSelectedFieldsOfStudy);
        }
    };

    const handleGradeChange = useCallback((gradeIds: string[]) => {
        onChange({
            gradeIds,
        });
    }, [onChange]);

    const handlePeriodFromChange = useCallback((periodFrom: Date | null) => {
        onChange({
            periodFrom,
        });
    }, [onChange]);

    const handlePeriodTillChange = useCallback((periodTill: Date | null) => {
        onChange({
            periodTill,
        });
    }, [onChange]);

    const handleDescriptionChange = useCallback((description: string) => {
        onChange({
            description,
        });
    }, [onChange]);

    const handleSectorChange = useCallback((options: SelectOption[]) => {
        const sectorIds = options.map(option => option.value);
        onChange({
            sectorId: sectorIds[0] || '',
        });
    }, [onChange]);

    const handleCompensationChange = (min: number | '', max: number | '') => {
        onChange({
            minCompensation: min,
            maxCompensation: max,
        });
    };

    useEffect((): void => {
        if (educationLevelOptions.length > 0 && !educationLevelId) {
            onEducationLevelChange(educationLevelOptions[0].uuid);
        }
    }, [educationLevelOptions, internshipTypeOptions]);

    const handleBackToOverviewClick = (): void => {
        navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));
    };

    return (
        <form onSubmit={handleSubmit} className="internship-vacancy-data-step">
            <Helmet>
                <title>{stepHeaderTitle}</title>
            </Helmet>

            <Card className="internship-vacancy-data-step__card">
                <StepHeader
                    title={stepHeaderTitle}
                    currentStep={1}
                    totalSteps={2}
                />

                <section className="internship-vacancy-data-step__form-elements">
                    <h2>
                        {trans('placeVacancyPage.steps.applicationType')}
                    </h2>

                    <InformationBox
                        descriptions={[
                            trans('forms.vacancy.applyIntro.description'),
                            trans('forms.vacancy.applyAnonymously.information'),
                        ]}
                        className="internship-vacancy-data-step__apply-anonymous-disclaimer"
                    />

                    <NewRadioList
                        label={trans('forms.vacancy.applyIntro.label')}
                        name="apply_anonymous"
                        onChange={handleApplyTypeChange}
                        options={applyTypeOptions}
                        value={values.applyAnonymous.toString()}
                        disabled={isLoading || isEditing}
                        required
                        className="internship-vacancy-data-step__apply-anonymous"
                    />
                </section>
            </Card>

            <Card className="internship-vacancy-data-step__card">
                <h2 className="internship-vacancy-data-step__sub-title">
                    {trans('forms.vacancy.generalData')}
                </h2>
                <section className="internship-vacancy-data-step__form-elements">
                    <TextInput
                        id="title"
                        label={trans('forms.vacancy.title.label')}
                        placeholder={trans('forms.vacancy.title.placeholder')}
                        value={values.title}
                        onChange={handleTitleChange}
                        disabled={isLoading}
                        error={errors.title && errors.title[0]}
                        className="internship-vacancy-data-step__form-input"
                        required
                    />

                    <LocationCheck
                        error={errors.address && errors.address[0]}
                        userAddress={values.address}
                        disabled={isLoading}
                        onChange={handleZipCodeCheckChange}
                    />

                    <SelectInput
                        id="sector"
                        onSelected={handleSectorChange}
                        options={sectorsToSelectBoxOptions(sectorOptions)}
                        className="internship-vacancy-data-step__form-input"
                        label={trans('forms.vacancy.job.sector.label')}
                        placeholder={trans('forms.vacancy.job.sector.placeholder')}
                        value={values.sectorId}
                        disabled={isLoading}
                        error={errors.sectors && errors.sectors[0]}
                        required
                        clearable
                    />

                    <PublicationAndExpirationDates
                        isLoading={isLoading}
                        isEditing={isEditing}
                        id="publication-date"
                        expirationDate={values.expirationDate}
                        publicationDate={values.publicationDate}
                        expirationDateErrors={errors.expirationDate}
                        onExpirationChange={handleExpirationDateChange}
                        onPublicationChange={handlePublicationDateChange}
                        maxVacancyLifeTimeInDays={maxVacancyLifeTimeInDays}
                        publicationDateErrors={errors.publicationDate}
                        className="internship-vacancy-data-step__form-input"
                    />

                    <div className="internship-vacancy-data-step__form-row">
                        <BlockRadioList
                            id="education-level"
                            label={trans('forms.vacancy.educationLevel.label')}
                            name="education_level"
                            value={educationLevelId}
                            required
                            options={educationLevelOptions}
                            error={errors.educationLevel?.[0]}
                            onChange={onEducationLevelChange}
                            className="internship-vacancy-data-step__form-col"
                        />

                        <BlockRadioList
                            id="internship-type"
                            label={trans('forms.vacancy.internship.type.label')}
                            name="internship_types"
                            value={internshipTypeId}
                            required
                            options={educationLevelInternshipTypeOptions}
                            error={errors.internshipType?.[0]}
                            tooltip={isMbo ? trans('forms.vacancy.internship.type.tooltip') : undefined}
                            tooltipId={isMbo ? 'internship-types-tooltip' : undefined}
                            onChange={onInternshipTypeChange}
                            className="internship-vacancy-data-step__form-col"
                        />
                    </div>

                    {educationLevelFieldOfStudyOptions.length > 0 && (
                        <SearchableMultiValueInput
                            isSearchable
                            showResultsOnClick
                            id="fields-of-study"
                            label={trans('forms.vacancy.fieldOfStudy.label')}
                            value={fieldsOfStudyValue}
                            required
                            disabled={isLoading || !educationLevelId}
                            error={errors.fieldsOfStudy && errors.fieldsOfStudy[0]}
                            options={educationLevelFieldOfStudyOptions}
                            placeholder={trans('forms.vacancy.fieldOfStudy.placeholder')}
                            onChange={handleFieldsOfStudyChange}
                            className="internship-vacancy-data-step__form-input"
                        />
                    )}

                    <CheckboxList
                        id="grades"
                        label={trans('forms.vacancy.grades.label')}
                        name="grades[]"
                        options={convertGradesToCheckboxListOptions(gradeOptions)}
                        value={values.gradeIds}
                        disabled={isLoading}
                        onChange={handleGradeChange}
                        error={errors.grades?.[0]}
                        className="internship-vacancy-data-step__form-input"
                        required
                    />

                    <div className="internship-vacancy-data-step__form-row">
                        <DateInput
                            label={trans('forms.vacancy.period.from.label')}
                            errors={errors.periodFrom}
                            value={values.periodFrom}
                            onChange={handlePeriodFromChange}
                            disabled={isLoading}
                            minDate={values.periodFrom ? values.periodFrom : new Date()}
                            className="internship-vacancy-data-step__form-col"
                            required
                        />

                        <DateInput
                            label={trans('forms.vacancy.period.till.label')}
                            errors={errors.periodTill}
                            value={values.periodTill}
                            onChange={handlePeriodTillChange}
                            disabled={isLoading}
                            minDate={values.periodTill ? values.periodTill : new Date()}
                            className="internship-vacancy-data-step__form-col"
                            required
                        />
                    </div>

                    <NewRadioList
                        label={trans('forms.vacancy.workRemote.label')}
                        name="work-remote-preference"
                        required
                        options={workRemoteOptions}
                        value={values.workRemotePreferenceId}
                        disabled={isLoading}
                        error={errors.workRemotePreference && errors.workRemotePreference[0]}
                        onChange={onWorkRemotePreferenceChange}
                        className="internship-vacancy-data-step__form-input internship-vacancy-data-step__work-remote-preference"
                    />
                </section>
            </Card>

            <Card className="internship-vacancy-data-step__card">
                <h2 className="internship-vacancy-data-step__sub-title">
                    {trans('forms.vacancy.salary.title')}
                </h2>

                <SalaryInput
                    id="compensation"
                    label={trans('forms.salary.input.compensation')}
                    ctaText={trans('forms.salary.cta.internship')}
                    timeframe={SalaryTimeframe.month}
                    minValue={values.minCompensation}
                    maxValue={values.maxCompensation}
                    disabled={isLoading}
                    error={errors.compensation && errors.compensation[0]}
                    onMinMaxChange={handleCompensationChange}
                />
            </Card>

            <Card className="internship-vacancy-data-step__card">
                <h2 className="internship-vacancy-data-step__sub-title">{trans('forms.vacancy.description.title')}</h2>
                <TextEditor
                    id="description"
                    name="description"
                    label={trans('forms.vacancy.description.label')}
                    placeholder={trans('forms.vacancy.job.description.jobPlaceholder')}
                    onChange={handleDescriptionChange}
                    defaultValue={values.description}
                    disabled={isLoading}
                    errors={errors.description}
                    required
                />
            </Card>

            <Card className="internship-vacancy-data-step__card">
                <StepFooter
                    loading={isLoading}
                    backButtonText={trans('placeVacancyPage.steps.backTo.myVacancies')}
                    onBackButtonClick={handleBackToOverviewClick}
                />
            </Card>
        </form>
    );
};

export default InternshipVacancyDataStep;
