import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconBell: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 82.1 100" className={`icon ${className}`}>
        <path d="M41,100c5.6,0,10.3-4.6,10.3-10.3H30.8C30.8,95.4,35.4,100,41,100z M71.8,69.2V43.6c0-15.7-8.4-28.9-23.1-32.4V7.7C48.7,3.4,45.3,0,41,0s-7.7,3.4-7.7,7.7v3.5c-14.7,3.5-23.1,16.6-23.1,32.4v25.6L0,79.5v5.1h82.1v-5.1L71.8,69.2z M61.5,74.4h-41V43.6c0-12.7,7.7-23.1,20.5-23.1s20.5,10.4,20.5,23.1V74.4z" />
    </svg>
);

export default IconBell;
