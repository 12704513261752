import React, {
    CSSProperties,
    FC,
    Fragment,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { Button, Icon, RootPortal } from '../../components';
import { trans } from '../../helpers/trans';
import useTimeout from '../../hooks/useTimeout';
import { Toast as ToastModel, ToastPosition } from '../../models/Toast';
import { MessageState } from '../../types';

import './Toast.scss';

interface ToastProps extends ToastModel {
    shouldClose?: boolean;
    isListItem?: boolean;
    hideCloseButton?: boolean;
    onClose?: () => void;
    className?: string;
}

const Toast: FC<PropsWithChildren<ToastProps>> = ({
    shouldClose,
    isListItem,
    hideCloseButton,
    revealDuration,
    transitionDuration = 300,
    position = ToastPosition.topRight,
    state,
    title,
    description,
    onClose,
    className = '',
    children,
}): ReactElement => {
    // This delay is needed for the isRevealed transition to occur consistently
    const isRenderedDelay = 10;

    const [isRevealed, setIsRevealed] = useState<boolean>(false);

    const [inCloseTransition, setInCloseTransition] = useState<boolean>(false);
    const [revealDurationEnded, setRevealDurationEnded] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<boolean>(false);

    useTimeout((): void => {
        setIsRevealed(true);
    }, isRenderedDelay);

    useEffect((): void => {
        if (shouldClose) {
            setInCloseTransition(true);
        }
    }, [shouldClose]);

    useEffect((): () => void => {
        let timer: NodeJS.Timeout;

        if (inCloseTransition && onClose) {
            setIsRevealed(false);
            timer = setTimeout(onClose, transitionDuration);
        }

        return (): void => {
            setIsRevealed(true);
            clearTimeout(timer);
        };
    }, [inCloseTransition, onClose, transitionDuration]);

    useTimeout((): void => {
        if (revealDuration) {
            setRevealDurationEnded(true);
        }
    }, revealDuration, [revealDuration]);

    useEffect((): void => {
        if (revealDurationEnded && !isHovering) {
            setInCloseTransition(true);
        }
    }, [revealDurationEnded, isHovering]);

    const handleMouseEnter = (): void => setIsHovering(true);
    const handleMouseLeave = (): void => setIsHovering(false);

    const handleClose = (): void => setInCloseTransition(true);

    const cssVariables = {
        '--toast-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    const toastClassNames = classNames('toast', {
        'toast--position-top': position.startsWith('top-'),
        'toast--position-bottom': position.startsWith('bottom-'),
        'toast--position-left': position.endsWith('-left'),
        'toast--position-right': position.endsWith('-right'),
        [`toast--${state}`]: state,
        'toast--is-revealed': isRevealed,
    }, className);

    const ToastWrapperComponent = isListItem
        ? Fragment
        : RootPortal;

    return (
        <ToastWrapperComponent>
            <section
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={cssVariables}
                className={toastClassNames}
            >
                {state && (
                    <div className="toast__icon-wrapper">
                        {state === MessageState.positive && <Icon name="circle-check" className="toast__icon toast__icon--positive" />}
                        {state === MessageState.warning && <Icon name="circle-info" className="toast__icon toast__icon--warning" />}
                        {state === MessageState.negative && <Icon name="circle-exclamation" className="toast__icon toast__icon--negative" />}
                    </div>
                )}

                <div className="toast__message-wrapper">
                    <h2 className="toast__title">
                        {title}
                    </h2>

                    {description && (
                        <p className="toast__description">
                            {description}
                        </p>
                    )}

                    {children}
                </div>

                {!hideCloseButton && (
                    <Button
                        text={trans('actions.close')}
                        onClick={handleClose}
                        className="toast__close-button"
                    >
                        <Icon name="cross" className="toast__close-icon" />
                    </Button>
                )}
            </section>
        </ToastWrapperComponent>
    );
};

export default Toast;
