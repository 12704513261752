import React, { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './BlogRendererSkeletons.scss';

interface BlogRendererSkeletonsProps {
    className?: string;
}

const BlogRendererSkeletons: FC<BlogRendererSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const skeletonParagraphs = generateIdArray(4);

    return (
        <div className={`blog-renderer-skeletons ${className}`}>
            {skeletonParagraphs.map(paragraph => {
                const skeletonLines = generateIdArray(5);

                return (
                    <div key={paragraph} className="blog-renderer-skeletons__paragraph">
                        <Skeleton className="blog-renderer-skeletons__subtitle" />

                        {skeletonLines.map(line => (
                            <Skeleton key={line} className="blog-renderer-skeletons__text" />
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default BlogRendererSkeletons;
