import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconSkill: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 41 42" className={`icon ${className}`}>
        <path d="M40.9 35.6473L26.9904 21.738L40.1149 8.61353L33.4076 1.90618L20.2438 14.9916L6.09757 0.845215L0.0151367 6.92764L14.1433 21.0558L2.14286 32.9849L1.97507 33.1517L0.569677 41.6923L8.97635 39.7524L20.9081 27.8206L34.8172 41.73L40.9 35.6473ZM4.15464 33.0493L28.9071 8.44421L33.5604 13.0975L8.88149 37.7763L4.15464 33.0493ZM38.0443 8.61353L34.5957 12.0622L29.9455 7.41197L33.4045 3.97372L38.0443 8.61353ZM14.0735 18.9154L15.8047 17.1841L14.7694 16.1488L13.0382 17.8801L10.8707 15.7126L12.5105 14.0727L11.4752 13.0374L9.83536 14.6773L7.5764 12.4181L9.21622 10.7783L8.18092 9.74301L6.5411 11.3828L4.28197 9.12387L5.92179 7.48405L4.88649 6.44875L3.24667 8.08857L2.08574 6.92764L6.09757 2.91581L19.2054 16.0238L15.1817 20.0236L14.0735 18.9154ZM3.27897 34.2443L7.60156 38.5668L2.36913 39.7744L3.27897 34.2443ZM25.9551 22.7733L38.8292 35.6474L34.8172 39.6594L30.6304 35.4727L32.2912 33.812L31.256 32.7768L29.5953 34.4375L27.3362 32.1784L28.9969 30.5177L27.9618 29.4826L26.301 31.1433L24.0419 28.8842L25.7026 27.2234L24.6675 26.1883L23.0068 27.849L21.9433 26.7855L25.9551 22.7733Z" fill="#242424" />
        <path d="M34.5498 35.8879L32.8112 37.6265L33.8464 38.6617L35.585 36.9231L34.5498 35.8879Z" fill="#242424" />
    </svg>
);

export default IconSkill;
