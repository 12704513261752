import { retrieveUniqueValues } from '../../../../helpers/array';
import { Application } from '../../../../models/Applications';

export const getUniqueApplications = (applications: Application[]): Application[] => {
    const uniqueApplicants = retrieveUniqueValues(applications.map(application => application.applicant.uuid));

    return uniqueApplicants
        .map(applicantUuid => applications.find(application => application.applicant.uuid === applicantUuid))
        .filter(application => application && application.uuid) as Application[];
};
