import { isValidDateString } from '../helpers/date';
import { Resource } from '../japi/types/Resource';

export interface EmailAddressChangeResource extends Resource {
    type: 'user-identifier-changes';
    id: string;
    attributes: {
        oldIdentifier: string;
        newIdentifier: string;
        clientIp?: string;
        createdAt: string;
        confirmedAt: string;
    };
}

export interface EmailAddressChange {
    uuid: string;
    isConfirmed: boolean;
    oldEmailAddress: string;
    newEmailAddress: string;
    createdAt: Date;
    confirmedAt?: Date;
}

export interface EmailAddressChangeFormData {
    newEmailAddress: string;
    password: string;
}

export interface EmailAddressChangeRequest {
    data: {
        type: 'user-identifier-changes';
        attributes: {
            newIdentifier: string;
            password: string;
        };
    };
}

export const transformToEmailAddressChange = (
    emailAddressChangeResource: EmailAddressChangeResource,
): EmailAddressChange => {
    const createdAt = isValidDateString(emailAddressChangeResource.attributes.createdAt)
        ? new Date(emailAddressChangeResource.attributes.createdAt)
        : new Date();

    const confirmedAt = isValidDateString(emailAddressChangeResource.attributes.confirmedAt)
        ? new Date(emailAddressChangeResource.attributes.confirmedAt)
        : undefined;

    return {
        uuid: emailAddressChangeResource.id,
        isConfirmed: !!emailAddressChangeResource.attributes.confirmedAt,
        oldEmailAddress: emailAddressChangeResource.attributes.oldIdentifier,
        newEmailAddress: emailAddressChangeResource.attributes.newIdentifier,
        createdAt,
        confirmedAt,
    };
};

export const transformToEmailAddressChangeRequest = (formData: EmailAddressChangeFormData): EmailAddressChangeRequest => ({
    data: {
        type: 'user-identifier-changes',
        attributes: {
            newIdentifier: formData.newEmailAddress,
            password: formData.password,
        },
    },
});
