import React, { FC, PropsWithChildren, ReactElement } from 'react';

import './Page.scss';

interface PageProps {
    className?: string;
}

const Page: FC<PropsWithChildren<PageProps>> = ({
    className = '',
    children,
}): ReactElement => (
    <section className={`page ${className}`}>
        {children}
    </section>
);

export default Page;
