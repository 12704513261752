import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton, Skeleton } from '../../../components';
import {
    DetailCard,
    IconButton,
    Modal,
    ModalContent,
    Tag,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { CoreValue, CoreValuesFormData, transformCoreValueToSearchableOption } from '../../../models/CoreValue';
import { SearchableOption } from '../../../types';
import { CoreValuesForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';

import './CoreValuesCard.scss';

interface CoreValuesCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable?: boolean;
    userFromCompany: boolean;
    coreValues?: CoreValue[];
    coreValueOptions?: SearchableOption[];
    onEdit?: (coreValuesFormData: CoreValuesFormData) => void;
    className?: string;
}

const CoreValuesCard: FC<CoreValuesCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    userFromCompany,
    coreValues = [],
    coreValueOptions = [],
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const handleEditCoreValues = (coreValuesFormData: CoreValuesFormData): void => {
        if (onEdit) {
            onEdit(coreValuesFormData);
        }

        closeEditModal();
    };

    const nullState = userFromCompany
        ? trans('companyProfile.coreValues.nullState.company')
        : trans('companyProfile.coreValues.nullState.candidate');

    return (
        <>
            <DetailCard
                isLoading={isLoading}
                isEditable={isEditable && coreValues.length > 0}
                title={trans('companyProfile.coreValues.title')}
                onEditClick={openEditModal}
                className={`core-values-card ${className}`}
            >
                <ProgressRefButton ref={activationRef} onClick={openEditModal} />

                {isLoading && (
                    <div className="core-values-card__skeleton-wrapper">
                        <Skeleton className="core-values-card__skeleton" />
                        <Skeleton className="core-values-card__skeleton" />
                    </div>
                )}

                {!isLoading && coreValues.length === 0 && (
                    <div className="core-values-card__null-state">
                        <p>{nullState}</p>

                        {isEditable && (
                            <IconButton
                                icon="plus"
                                text={trans('actions.add')}
                                disabled={isLoading}
                                onClick={openEditModal}
                                className="core-values-card__null-state-button"
                            />
                        )}
                    </div>
                )}

                {!isLoading && coreValues.length > 0 && (
                    <ul className="core-values-card__list">
                        {coreValues.map(coreValue => (
                            <li key={coreValue.uuid} className="core-values-card__item">
                                <Tag text={coreValue.name} />
                            </li>
                        ))}
                    </ul>
                )}
            </DetailCard>

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('companyProfile.coreValues.modal.title')}>
                        <CoreValuesForm
                            coreValues={coreValues.map(transformCoreValueToSearchableOption)}
                            coreValueOptions={coreValueOptions}
                            onCancel={closeEditModal}
                            onSubmit={handleEditCoreValues}
                        />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default CoreValuesCard;
