import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 52.2 100" className={`icon ${className}`}>
        <path d="M52.2,50L7.7,100L0,93.1L38.3,50L0,6.9L7.7,0L52.2,50z" />
    </svg>
);

export default IconChevronRight;
