import { Chat } from '../../../models/Chat';
import { ChatMessage } from '../../../models/ChatMessages';
import { PersonInfo, transformCompanyInfoToPersonInfo } from '../../../models/info/PersonInfo';

export const retrieveInterlocutor = (chat: Chat, isCandidate: boolean): PersonInfo => {
    if (isCandidate) {
        return transformCompanyInfoToPersonInfo(chat.company);
    }

    return chat.candidate;
};

export const getMessageGroupPosition = (messages: ChatMessage[], index: number): boolean[] => {
    const message = messages[index];
    const prevMessage = messages[index - 1];
    const nextMessage = messages[index + 1];

    const author = message.author.uuid;

    const isFirst = author !== prevMessage?.author.uuid;
    const isLast = author !== nextMessage?.author.uuid;

    return [isFirst, isLast];
};
