import React, { useRef } from 'react';

import Slider from 'react-slick';

import { trans } from '../../../../../../../helpers/trans';
import Container from '../../../../../../styledComponents/components/Container';
import GreyFooter from '../../../../../../styledComponents/components/GreyFooter';
import { Row } from '../../../../../../styledComponents/components/Grid';
import Icon from '../../../../../../styledComponents/components/Icon';
import { Tablet } from '../../../../../../styledComponents/components/MediaQueries';
import Section from '../../../../../../styledComponents/components/Section';
import { Heading, Paragraph } from '../../../../../../styledComponents/components/Typography';
import TeamMember from '../TeamMember';
import { Carousel, SliderButton, SliderNavigation } from './style';

const TeamSlider = () => {
    const slider = useRef(undefined);

    const next = () => slider.current && slider.current.slickNext();
    const previous = () => slider.current && slider.current.slickPrev();

    return (
        <Section>
            <Container maxWidth="1200px" centered pt="lg">
                <Row flexDirection="column" maxWidth="800px" mb="xl">
                    <Heading size="xl" mb="md">
                        {trans('infoPage.team.members.title')}
                    </Heading>
                    <Paragraph fontType="subHeading" size="md">
                        {trans('infoPage.team.members.text')}
                    </Paragraph>
                </Row>
            </Container>
            <GreyFooter mt={-350} height={420}>
                <Container maxWidth="1200px" centered>
                    <Carousel>
                        <Slider
                            ref={slider}
                            slidesToShow={1}
                            slidesToScroll={1}
                            swipeToSlide
                            infinite={false}
                            arrows={false}
                            dots
                            variableWidth
                            responsive={[
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1,
                                    },
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                    },
                                },
                            ]}
                        >
                            <TeamMember teamMemberName="Rick Slijkhuis" teamMemberFunction="Front-End Developer" teamMemberPhoto="images/TeamMembers/Rick.png" />
                            <TeamMember teamMemberName="Melissa Mackaaij" teamMemberFunction="UI/UX Designer" teamMemberPhoto="images/TeamMembers/Melissa.png" />
                            <TeamMember teamMemberName="Humphrey Keijner" teamMemberFunction="Financieel Directeur" teamMemberPhoto="images/TeamMembers/Hum.png" />
                            <TeamMember teamMemberName="Ramon Tromp" teamMemberFunction="Sales Manager" teamMemberPhoto="images/TeamMembers/Ramon.png" />
                            <TeamMember teamMemberName="Martijn Apeldoorn" teamMemberFunction="Founder" teamMemberPhoto="images/TeamMembers/Martijn.png" />
                            <TeamMember teamMemberName="Robert Slippens" teamMemberFunction="Creatieve Geest" teamMemberPhoto="images/TeamMembers/Robert.png" />
                        </Slider>
                    </Carousel>
                </Container>
            </GreyFooter>
            <Tablet>
                <Container centered position="relative" mb="40px">
                    <SliderNavigation>
                        <SliderButton prev mr="xs" onClick={previous}>
                            <Icon size={20} kind="arrow-left" />
                        </SliderButton>
                        <SliderButton onClick={next}>
                            <Icon size={20} kind="arrow-right" />
                        </SliderButton>
                    </SliderNavigation>
                </Container>
            </Tablet>
        </Section>
    );
};

export default TeamSlider;
