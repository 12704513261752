import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useEffectOnce } from 'react-use';

import { BlogOverview } from '../../containers';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { fetchBlogCategories, fetchBlogCategorySizes, fetchBlogOverview } from '../../store/blog/blogActions';

interface ConnectedBlogOverviewProps {
    category: string;
    className?: string;
}

const ConnectedBlogOverview: FC<ConnectedBlogOverviewProps> = ({
    category,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.blogReducer.isLoading);
    const totalPages = useTypedSelector(state => state.blogReducer.totalPages);
    const totalResults = useTypedSelector(state => state.blogReducer.totalResults);
    const categories = useTypedSelector(state => state.blogReducer.categories);
    const categorySizes = useTypedSelector(state => state.blogReducer.categorySizes);
    const blogArticles = useTypedSelector(state => state.blogReducer.blogArticles);

    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffectOnce((): void => {
        if (categories.length === 0) {
            dispatch(fetchBlogCategories());
        }
    });

    useEffect((): void => {
        if (categories.length > 0) {
            dispatch(fetchBlogCategorySizes(categories));
        }
    }, [dispatch, categories]);

    useEffect((): void => {
        dispatch(fetchBlogOverview({
            category,
            page: 1,
        }));
    }, [dispatch, category]);

    const handlePaginationChange = (page: number): void => {
        setCurrentPage(page);

        dispatch(fetchBlogOverview({
            page,
            category,
        }));
    };

    return (
        <BlogOverview
            isLoading={isLoading}
            totalPages={totalPages}
            currentPage={currentPage}
            totalResults={totalResults}
            categories={categories}
            categorySizes={categorySizes}
            currentCategory={category}
            blogArticles={blogArticles}
            onPaginationChange={handlePaginationChange}
            className={className}
        />
    );
};

export default ConnectedBlogOverview;
