import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import TextEditor from '../../../_old/components/form/TextEditor';
import { FormActionButtons } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { trans } from '../../../helpers/trans';
import { FunFactsFormData } from '../../../models/FunFacts';
import { FormProps } from '../../../types';
import { FunFactsFormErrors, validateFunFactsFormData } from './validations';

import './FunFactsForm.scss';

interface FunFactsFormProps extends FormProps<FunFactsFormData> {
    funFacts: string;
    className?: string;
}

const FunFactsForm: FC<FunFactsFormProps> = ({
    funFacts,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<FunFactsFormErrors>({});

    const [funFactsContent, setFunFactsContent] = useState<string>(funFacts);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            funFacts: funFactsContent,
        };

        const [errors, hasErrors] = validateFunFactsFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`fun-facts-form ${className}`}>
            <TextEditor
                name="fun-facts"
                label={trans('forms.funFacts.input.funFacts')}
                placeholder={trans('forms.funFacts.placeholder.funFacts')}
                defaultValue={funFactsContent}
                errors={formErrors.funFacts ? [formErrors.funFacts] : undefined}
                onChange={setFunFactsContent}
            />

            <FormActionButtons
                submitIcon="check"
                onCancelClick={onCancel}
                className="fun-facts-form__action-buttons"
            />
        </form>
    );
};

export default FunFactsForm;
