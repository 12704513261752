import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Pagination as PaginationModel } from '../../_old/app_talentz/models/Pagination';
import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { ErrorLabel } from '../../components';
import { VacancyCard } from '../../compositions';
import { Application } from '../../models/Applications';
import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../models/NewestVacancy';
import { RoleType } from '../../models/User';
import { Pagination } from '..';
import { VacancyOverviewSkeletons } from './skeletons';
import { VacancyOverviewCompleteRegistration, VacancyOverviewNullState } from './subcomponents';

import './VacancyOverview.scss';

interface VacancyOverviewProps {
    isGrid?: boolean;
    isLoading: boolean;
    isAuthor?: boolean;
    isArchived?: boolean;
    isSbbUser?: boolean;
    showCompleteRegistration?: boolean;
    skeletonAmount?: number;
    userRole?: RoleType;
    userFieldsOfStudy?: string[];
    vacancies: NewestVacancy[];
    overviewType?: VacancyType;
    pagination?: PaginationModel;
    error: string;
    favouriteIds?: string[];
    favouriteIsLoading?: boolean;
    onFavouriteVacancy?: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    applications?: Application[];
    applicationsIsLoading?: boolean;
    onFetchApplications?: (vacancyUuid: string) => void;
    onClearApplications?: () => void;
    onArchiveVacancy?: (vacancyUuid: string, applicationUuids: string[]) => void;
    onUnarchiveVacancy?: (vacancyUuid: string) => void;
    onDeleteVacancy?: (vacancyUuid: string) => void;
    onPaginationChange?: (pageNumber: number) => void;
    className?: string;
}

const VacancyOverview: FC<VacancyOverviewProps> = ({
    isGrid,
    isLoading,
    isAuthor,
    isArchived,
    isSbbUser,
    showCompleteRegistration,
    skeletonAmount = 6,
    userRole,
    userFieldsOfStudy,
    vacancies = [],
    overviewType,
    error,
    pagination,
    favouriteIds,
    favouriteIsLoading,
    onFavouriteVacancy,
    applications,
    applicationsIsLoading,
    onFetchApplications,
    onClearApplications,
    onArchiveVacancy,
    onUnarchiveVacancy,
    onDeleteVacancy,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const vacancyCardListClassName = classNames('vacancy-overview__vacancy-card-list', {
        'vacancy-overview__vacancy-card-list--is-grid': isGrid,
        'vacancy-overview__vacancy-card-list--is-from-author': isAuthor,
    });

    const vacancyCardClassName = classNames('vacancy-overview__vacancy-card', {
        'vacancy-overview__vacancy-card--is-grid': isGrid,
    });

    return (
        <div className={`vacancy-overview ${className}`}>
            {isLoading && (
                <VacancyOverviewSkeletons
                    isGrid={isGrid}
                    amount={skeletonAmount}
                    className={vacancyCardListClassName}
                />
            )}

            {!isLoading && error && (
                <ErrorLabel text={error} className="vacancy-overview__error" />
            )}

            {!isLoading && !error && vacancies.length === 0 && (
                <VacancyOverviewNullState
                    overviewType={overviewType}
                    className="vacancy-overview__null-state"
                />
            )}

            {!isLoading && vacancies.length > 0 && (
                <section className={vacancyCardListClassName}>
                    {showCompleteRegistration && (
                        <VacancyOverviewCompleteRegistration
                            userRole={userRole}
                            className="vacancy-overview__main-cta"
                        />
                    )}

                    {vacancies.map(vacancy => (
                        <VacancyCard
                            key={vacancy.uuid}
                            isSmall={isGrid}
                            isAuthor={isAuthor}
                            isArchived={isArchived}
                            isSbbUser={isSbbUser}
                            userRole={userRole}
                            userFieldsOfStudy={userFieldsOfStudy}
                            vacancy={vacancy}
                            favouriteIds={favouriteIds}
                            favouriteIsLoading={favouriteIsLoading}
                            onFavouriteVacancy={onFavouriteVacancy}
                            applications={applications}
                            applicationsIsLoading={applicationsIsLoading}
                            onFetchApplications={onFetchApplications}
                            onClearApplications={onClearApplications}
                            onArchiveVacancy={onArchiveVacancy}
                            onUnarchiveVacancy={onUnarchiveVacancy}
                            onDeleteVacancy={onDeleteVacancy}
                            className={vacancyCardClassName}
                        />
                    ))}
                </section>
            )}

            {!isLoading && !error && pagination && (
                <Pagination
                    amountOfPages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    onChange={onPaginationChange}
                    className="vacancy-overview__pagination"
                />
            )}
        </div>
    );
};

export default VacancyOverview;
