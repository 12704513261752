import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedPlaceJobVacancy } from '../../connectors';

const PlaceJobVacancyPage: FC = (): ReactElement => (
    <Page>
        <ConnectedPlaceJobVacancy />
    </Page>
);

export default PlaceJobVacancyPage;
