import React, { FC, ReactElement } from 'react';

import { IconButton, LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';
import { CompanySettingsTab, UserSettingsTab } from '../../../../types/pageTabs';

import './EmailAddressChangeFailure.scss';

interface EmailAddressChangeFailureProps {
    isAuthenticated: boolean;
    isCandidate: boolean;
    onLoginClick: () => void;
    className?: string;
}

const EmailAddressChangeFailure: FC<EmailAddressChangeFailureProps> = ({
    isAuthenticated,
    isCandidate,
    onLoginClick,
    className = '',
}): ReactElement => {
    const settingsPath = isCandidate
        ? RoutePaths.userSettings(UserSettingsTab.account)
        : RoutePaths.companySettings(CompanySettingsTab.account);

    return (
        <div className={`email-address-change-failure ${className}`}>
            <h2 className="email-address-change-failure__title">
                {trans('containers.confirmEmailAddressChange.error.title')}
            </h2>

            <p className="email-address-change-failure__description">
                {trans('containers.confirmEmailAddressChange.error.description')}
            </p>

            {isAuthenticated ? (
                <LinkIconButton
                    to={settingsPath}
                    icon="arrow-right"
                    iconPos="right"
                    text={trans('containers.confirmEmailAddressChange.button.toSettings')}
                    className="email-address-change-failure__button"
                />
            ) : (
                <IconButton
                    icon="login"
                    text={trans('containers.confirmEmailAddressChange.button.login')}
                    onClick={onLoginClick}
                    className="email-address-change-failure__button"
                />
            )}
        </div>
    );
};

export default EmailAddressChangeFailure;
