/* eslint-disable camelcase */
import { School } from './SchoolInvitation';

export interface UserApiResponseParams {
    uuid: string;
    email: string;
    activated: number;
    permissions: any[];
    person_id: string;
    connection_providers: any[];
    roles: any[];
    notification_subscriptions: any[];
    app_updates: any[];
    tenant?: {
        uuid: string;
    };
    reference_requests: any[];
    school: School | null;
}

export interface User {
    uuid: string;
    email: string;
    activation_token?: string;
    activated: number;
    permissions: any[];
    person_id: string;
    connection_providers: any[];
    roles: any[];
    notification_subscriptions: any[];
    app_updates: any[];
    tenant?: {
        uuid: string;
    };
    referenceRequests: any[];
    school: School | null;
}

export function transformToUser(userParams: UserApiResponseParams): User {
    return {
        ...userParams,
        uuid: userParams.uuid,
        activated: userParams.activated,
        app_updates: userParams.app_updates,
        connection_providers: userParams.connection_providers,
        email: userParams.email,
        notification_subscriptions: userParams.notification_subscriptions,
        permissions: userParams.permissions,
        person_id: userParams.person_id,
        roles: userParams.roles,
        tenant: userParams.tenant,
        referenceRequests: userParams.reference_requests,
        school: userParams.school,
    };
}
/* eslint-enable camelcase */
