import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { JobExperiencesCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { JobExperience, JobExperienceFormData } from '../../../models/JobExperience';
import { RoutePaths } from '../../../routes';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import {
    addCandidateJobExperience,
    clearCandidate,
    clearCandidateSuccess,
    deleteCandidateJobExperience,
    editCandidateJobExperience,
    fetchCandidate,
} from '../../../store/candidate/candidateActions';

interface ConnectedJobExperiencesCardProps {
    activationRef?: ActivationRef;
    shouldFetchCandidate?: boolean;
    candidateUuid?: string;
    className?: string;
}

const ConnectedJobExperiencesCard: FC<ConnectedJobExperiencesCardProps> = ({
    activationRef,
    shouldFetchCandidate,
    candidateUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateNeedsUpdate = useTypedSelector(state => state.candidateReducer.isSuccessful);

    const isEditable = candidateUuid
        ? candidateUuid === person?.uuid
        : location.pathname === RoutePaths.userProfile();

    const fetchCandidateData = (): void => {
        const candidateId = candidateUuid || person?.uuid;

        if (candidateId) {
            dispatch(fetchCandidate(candidateId));
        }
    };

    useEffect((): () => void => {
        if (shouldFetchCandidate) fetchCandidateData();

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchCandidate) dispatch(clearCandidate());
        };
    }, []);

    useEffect((): void => {
        if (shouldFetchCandidate && candidateNeedsUpdate) {
            fetchCandidateData();
            dispatch(clearCandidateSuccess());
        }
    }, [candidateNeedsUpdate]);

    const handleAddJobExperience = (jobExperienceFormData: JobExperienceFormData): void => {
        dispatch(addCandidateJobExperience(jobExperienceFormData));
    };

    const handleEditJobExperience = (jobExperienceFormData: JobExperienceFormData): void => {
        dispatch(editCandidateJobExperience(jobExperienceFormData));
    };

    const handleDeleteJobExperience = (jobExperience: JobExperience): void => {
        dispatch(deleteCandidateJobExperience(jobExperience));
    };

    return (
        <JobExperiencesCard
            activationRef={activationRef}
            isLoading={isLoading}
            isEditable={isEditable}
            jobExperiences={candidate?.jobExperiences}
            onAddJobExperience={handleAddJobExperience}
            onEditJobExperience={handleEditJobExperience}
            onDeleteJobExperience={handleDeleteJobExperience}
            className={className}
        />
    );
};

export default ConnectedJobExperiencesCard;
