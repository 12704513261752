import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

import Icon from '../../../styledComponents/components/Icon';

export const Select = styled(ReactSelect)(props => css`
    width: 100%;
    
    .rs__control {
        box-shadow: ${props.theme.shadow()};
        border: 1px solid ${props.theme.colors.grey};
        border-radius: 0;
        padding: 0 ${props.theme.space.sm};
        transition: background-color 200ms ease-in-out;
    
        ${props.errors && css`
            border: 2px solid ${props.theme.colors.alert};
        `}
        
        &--is-focused,
        &:hover {
            border: 1px solid ${props.theme.colors.grey}; 
            ${props.errors && css`
                border: 2px solid ${props.theme.colors.alert};
            `}
        }
    }
    
    .rs__input {
        width: 100%;
        pointer-events: none;
    }

    .rs__indicator-separator {
        display: none;
        
        ${(props.isClearable && props.value) && css`
            display: block;
            margin-right: 7px;
        `}
    }
    
    .rs__indicator.rs__clear-indicator {
        margin-top: 0;
    }
    
    .rs__value-container {
        padding: 0;
        line-height: 44px;
        cursor: pointer;
        
        & div:last-child {
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }
    
    .rs__placeholder { 
        maring: 0;
    }

    .rs__indicator {
        ${props.customIcon && css`
            margin-top: -15px;
        `}
    }
    
    .rs__menu {
        border-radius: 0;
        box-shadow: ${props.theme.shadow()};
        margin-top: 1px;
        border: 1px solid ${props.theme.colors.grey};
    }
`);

export const OptionalLabel = styled.span`
    float: right;
    color: ${props => props.theme.colors.muted};
    font-weight: normal;
    font-size: 12px;
`;

export const InputLoader = styled('div')(props => css`
    position: absolute;
    display: flex;
    z-index: 2;
    right: ${props.theme.space.xl};
    align-self: center;
`);

export const Label = styled('label')(props => css`
    display: block;
    ${props.theme.fonts.bold};
    margin-bottom: 5px;
    font-size: .875rem;
    color: ${props.theme.colors.text};
`);

export const SelectIcon = styled(Icon)`
    position: absolute;
    right: 15px;
`;

export const Error = styled('p')`
    color: ${props => props.theme.colors.alert};
    font-size: 12px;
    line-height: 1; 
    overflow: hidden; 
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: height 0.15s ease-out,
                padding 0.15s ease-out;
    
    ${props => props.errors && `
        height: ${props.height || '22px'};
        padding-top: 5px;
        padding-bottom: 5px;
    `}
`;
