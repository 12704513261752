import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { hotjarEvent } from '../../../../../helpers/hotjar';
import { trans } from '../../../../../helpers/trans';
import { RoleType } from '../../../../../models/User';
import { RoutePaths } from '../../../../../routes';
import { LegacyReducers } from '../../../../../store/reducers';
import MultiStepOverlay from '../../../../components/MultiStepOverlay';
import { RawPersonData } from '../../../services/PersonService';
import { webPushSubscribe } from '../../../store/notifications/notificationsActions';
import { registerPerson, setPasswordAndAuthenticate } from '../../../store/register/registerActions';
import { updatePersonSettings } from '../../../store/userPersonSettings/userPersonSettingsActions';
import PasswordForm from '../../components/forms/PasswordForm';
import PersonDataForm from '../../components/forms/PersonDataForm';
import RegisterNotificationsForm, { RegisterNotificationsFormSettings } from '../../components/forms/RegisterNotificationsForm';

interface Props {
    completeStep: number;
    preFillData: any;
    loading: boolean;
    errors: any;
    setPasswordAndAuthenticate: (data: any) => void;
    registerPerson: (data: RawPersonData) => void;
    notificationsLoading: boolean;
    webPushSubscribe: () => Promise<void>;
    updatePersonSettings: (notificationSettings: RegisterNotificationsFormSettings) => Promise<void>;
    userRole: RoleType;
}

const RegisterPerson = (props: Props) => {
    const navigate = useNavigate();

    const {
        loading,
        preFillData,
        completeStep,
        errors,
        notificationsLoading,
        userRole,
    } = props;

    const handleUpdateNotificationsSettings = async (notificationSettings: RegisterNotificationsFormSettings) => {
        try {
            await props.updatePersonSettings(notificationSettings);
            await props.webPushSubscribe();

            navigate(RoutePaths.userProfile(), {
                state: { firstVisit: true },
            });
        } catch (error) {
            console.error(error);

            navigate(RoutePaths.userProfile(), {
                state: { firstVisit: true },
            });
        }
    };

    const handlePasswordSubmit = (data: any) => {
        props.setPasswordAndAuthenticate(data);

        hotjarEvent('vpv', `/registreren/profiel/${userRole}`);
    };

    function handlePersonDataSubmit(data: RawPersonData) {
        props.registerPerson(data);

        hotjarEvent('vpv', `/registreren/notificaties/${userRole}`);
    }

    useEffect((): void => {
        hotjarEvent('vpv', `/registreren/wachtwoord/${userRole}`);
    }, []);

    const registrationSteps = [
        {
            id: 'register-person-password',
            message: {
                title: trans('basic.password.introTitle'),
                body: trans('basic.password.introText'),
            },
            component: (
                <>
                    <Helmet>
                        <title>Registreren</title>
                        <meta name="description" content="Doorloop nu het registratie proces en vul je profiel zo volledig mogelijk in. Hierdoor match je met de perfecte stage op TalentZ!" />
                    </Helmet>
                    <PasswordForm
                        onSubmit={handlePasswordSubmit}
                        loading={loading}
                    />
                </>
            ),
        },
        {
            id: 'register-person-data',
            message: {
                title: trans('basic.registerUserProfile.introTitle'),
                body: trans('basic.registerUserProfile.introText'),
            },
            component: (
                <PersonDataForm
                    person={preFillData}
                    onSubmit={(data: RawPersonData) => handlePersonDataSubmit(data)}
                    loading={loading}
                    errors={errors}
                    userRole={userRole}
                />
            ),
        },
        {
            id: 'register-person-notifications',
            message: {
                title: trans('basic.subscribeToNotifications.introTitle'),
                body: trans('basic.subscribeToNotifications.introText'),
            },
            component: (
                <RegisterNotificationsForm
                    isLoading={notificationsLoading}
                    onSubmit={handleUpdateNotificationsSettings}
                />
            ),
        },
    ];

    const registerStep = registrationSteps[completeStep];

    return (
        <MultiStepOverlay
            id="register-person"
            step={props.completeStep}
            message={registerStep.message}
        >
            {registerStep && registerStep.component}
        </MultiStepOverlay>
    );
};

export default connect(
    (state: LegacyReducers) => ({
        loading: state.register.loading,
        errors: state.register.errors,
        completeStep: state.register.completeStep,
        preFillData: state.userPerson.preFillData,
        notificationsLoading: state.notifications.loading,
        userRole: state.legacyUser.role,
    }),
    dispatch => bindActionCreators({
        setPasswordAndAuthenticate,
        webPushSubscribe,
        registerPerson,
        updatePersonSettings,
    }, dispatch),
    // @ts-ignore
)(RegisterPerson);
