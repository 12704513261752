import React, { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Button, Card } from '../../../components';
import { trans } from '../../../helpers/trans';
import useHandleClickOutside from '../../../hooks/useHandleClickOutside';
import { ExpandableFilterButton } from './subcomponents';

import './ExpandableFilter.scss';

interface ExpandableFilterProps {
    isActive?: boolean;
    label: string;
    selectedFilter?: string;
    disabled?: boolean;
    onSave: () => void;
    className?: string;
    children: (isExpanded: boolean) => ReactElement;
}

const ExpandableFilter: FC<ExpandableFilterProps> = ({
    isActive,
    label,
    selectedFilter,
    disabled,
    onSave,
    className = '',
    children,
}): ReactElement => {
    const [filterIsExpanded, toggleFilterIsExpanded] = useToggle(false);

    const filterCardRef = useRef(null);

    const closeFilter = (): void => toggleFilterIsExpanded(false);

    const handleSave = (): void => {
        onSave();
        closeFilter();
    };

    useHandleClickOutside(filterCardRef, closeFilter);

    const cardClassNames = classNames('expandable-filter__card', {
        'expandable-filter__card--is-expanded': filterIsExpanded,
    });

    return (
        <div ref={filterCardRef} className={`expandable-filter ${className}`}>
            <ExpandableFilterButton
                isActive={isActive}
                isExpanded={filterIsExpanded}
                text={label}
                selectedFilter={selectedFilter}
                disabled={disabled}
                onClick={toggleFilterIsExpanded}
                className="expandable-filter__button"
            />

            <Card className={cardClassNames}>
                <div className="expandable-filter__filter-wrapper">
                    {children(filterIsExpanded)}
                </div>

                <div className="expandable-filter__card-button-wrapper">
                    <Button
                        text={trans('actions.applyFilter')}
                        tabIndex={filterIsExpanded ? 0 : -1}
                        onClick={handleSave}
                        className="expandable-filter__save-button"
                    />
                    <Button
                        text={trans('actions.cancel')}
                        tabIndex={filterIsExpanded ? 0 : -1}
                        onClick={closeFilter}
                        className="expandable-filter__cancel-button"
                    />
                </div>
            </Card>
        </div>
    );
};

export default ExpandableFilter;
