import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';

import { ValidationErrors } from '../../../../_old/app_talentz/models/ValidationErrors';
import { isEmptyObject } from '../../../../_old/helpers/objectHelper';
import { Card, Icon } from '../../../../components';
import {
    CompetenciesSelector,
    SkillsSelector,
    StepFooter,
    StepHeader,
} from '../../../../compositions';
import { scrollIntoView } from '../../../../helpers/scroll';
import { trans } from '../../../../helpers/trans';
import { Competency } from '../../../../models/Competencies';
import { Skill } from '../../../../models/Skills';
import { validateInternshipVacancySkillsStep } from '../../validations';

import './InternshipVacancySkillsStep.scss';

interface InternshipVacancySkillsStepProps {
    isLoading: boolean;
    skills: Skill[];
    skillsToLearn: Skill[];
    competencies: Competency[];
    competenciesToLearn: Competency[];
    skillOptions: Skill[];
    skillsError: string;
    competencyOptions: Competency[];
    competenciesError: string;
    onChangeSkills: (skills: Skill[]) => void;
    onChangeSkillsToLearn:(skills: Skill[]) => void;
    onChangeCompetencies: (competencies: Competency[]) => void;
    onChangeCompetenciesToLearn: (competencies: Competency[]) => void;
    onCreateSkillOption: (value: string) => void;
    onCreateSkillToLearnOption: (value: string) => void;
    onPreviousClick: () => void;
    onSubmit: () => void;
}

const InternshipVacancySkillsStep: FC<InternshipVacancySkillsStepProps> = ({
    isLoading,
    skills,
    skillsToLearn,
    competencies,
    competenciesToLearn,
    skillOptions,
    skillsError,
    competencyOptions,
    competenciesError,
    onChangeSkills,
    onChangeSkillsToLearn,
    onChangeCompetencies,
    onChangeCompetenciesToLearn,
    onCreateSkillOption,
    onCreateSkillToLearnOption,
    onPreviousClick,
    onSubmit,
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<ValidationErrors>({});

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const validationErrors = validateInternshipVacancySkillsStep({
            skills,
            skillsToLearn,
            competencies,
            competenciesToLearn,
        });

        setFormErrors(validationErrors);

        if (isEmptyObject(validationErrors)) {
            onSubmit();
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="internship-vacancy-skills-step">
            <Helmet>
                <title>{trans('placeVacancyPage.internship.skillsStep.title')}</title>
            </Helmet>

            <Card className="internship-vacancy-skills-step__card">
                <StepHeader
                    title={trans('placeVacancyPage.internship.skillsStep.title')}
                    currentStep={2}
                    totalSteps={2}
                />

                <p className="internship-vacancy-skills-step__description">
                    {trans('placeVacancyPage.internship.skillsStep.description')}
                </p>
            </Card>

            <Card className="internship-vacancy-skills-step__card">
                <div className="internship-vacancy-skills-step__sub-title-wrapper">
                    <Icon name="skill" className="internship-vacancy-skills-step__sub-title-icon" />
                    <h2 className="internship-vacancy-skills-step__sub-title">
                        {trans('placeVacancyPage.internship.skillsStep.skills.title')}
                    </h2>
                </div>

                <SkillsSelector
                    label={trans('placeVacancyPage.internship.skillsStep.skills.label')}
                    value={skills}
                    options={skillOptions}
                    error={skillsError}
                    validationError={formErrors.skills && formErrors.skills[0]}
                    onChange={onChangeSkills}
                    onCreateNewSkill={onCreateSkillOption}
                    className="internship-vacancy-skills-step__skills"
                />

                <SkillsSelector
                    label={trans('placeVacancyPage.internship.skillsStep.skillsToLearn.label')}
                    value={skillsToLearn}
                    options={skillOptions}
                    error={skillsError}
                    validationError={formErrors.skillsToLearn && formErrors.skillsToLearn[0]}
                    onChange={onChangeSkillsToLearn}
                    onCreateNewSkill={onCreateSkillToLearnOption}
                    className="internship-vacancy-skills-step__skills-to-learn"
                />
            </Card>

            <Card className="internship-vacancy-skills-step__card">
                <div className="internship-vacancy-skills-step__sub-title-wrapper">
                    <Icon name="competency" className="internship-vacancy-skills-step__sub-title-icon" />
                    <h2 className="internship-vacancy-skills-step__sub-title">
                        {trans('placeVacancyPage.internship.skillsStep.competencies.title')}
                    </h2>
                </div>

                <CompetenciesSelector
                    highlightInModal
                    label={trans('placeVacancyPage.internship.skillsStep.competencies.label')}
                    value={competencies}
                    options={competencyOptions}
                    error={competenciesError}
                    validationError={formErrors.competencies && formErrors.competencies[0]}
                    onChange={onChangeCompetencies}
                    className="internship-vacancy-skills-step__competencies"
                />

                <CompetenciesSelector
                    highlightInModal
                    label={trans('placeVacancyPage.internship.skillsStep.competenciesToLearn.label')}
                    value={competenciesToLearn}
                    options={competencyOptions}
                    error={competenciesError}
                    validationError={formErrors.competenciesToLearn && formErrors.competenciesToLearn[0]}
                    onChange={onChangeCompetenciesToLearn}
                    className="internship-vacancy-skills-step__competencies-to-learn"
                />
            </Card>

            <Card className="internship-vacancy-skills-step__card">
                <StepFooter
                    loading={isLoading}
                    backButtonText={trans('placeVacancyPage.steps.backTo.dataStep')}
                    nextButtonText={trans('placeVacancyPage.steps.finish')}
                    onBackButtonClick={onPreviousClick}
                />
            </Card>
        </form>
    );
};

export default InternshipVacancySkillsStep;
