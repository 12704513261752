import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrowHorizontal: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 56.3" className={`icon ${className}`}>
        <polygon points="16.8,33.2 50.1,33.2 50.1,33.2 83.2,33.2 69.6,50.1 77.5,56.3 100,28.2 77.5,0 69.6,6.3 83.2,23.2 49.9,23.2 49.9,23.2 16.8,23.2 30.4,6.3 22.5,0 0,28.2 22.5,56.3 30.4,50.1" />
    </svg>
);

export default IconArrowHorizontal;
