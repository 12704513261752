import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPlay: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 93.7 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M93.7,50L0,100V0L93.7,50z M10.3,17.2v65.5L71.8,50L10.3,17.2z" />
    </svg>
);

export default IconPlay;
