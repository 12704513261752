import axios from 'axios';
import { Dispatch } from 'redux';

import {
    internshipFilterOptionsFetchSuccess,
    internshipFilterOptionsLoading,
    InternshipVacanciesFilterParams,
    setFilterParams,
} from './internshipVacancies';

export function updateInternshipVacanciesFilterParams(params: InternshipVacanciesFilterParams) {
    return (dispatch: Dispatch) => {
        dispatch(setFilterParams({
            ...params,
            s: params.s && params.s.length >= 3
                ? params.s
                : '',
            location: params.location && params.location.length >= 3
                ? params.location
                : '',
        }));
    };
}

export function fetchFilterOptions() {
    return async (dispatch: Dispatch) => {
        dispatch(internshipFilterOptionsLoading(true));

        try {
            return axios.all([
                axios.get('/internship-types?only=uuid,name'),
                axios.get('/grades?only=uuid,name'),
                axios.get('/education-levels?only=uuid,name'),
            ])
                .then(axios.spread((internshipTypes, grades, educationLevels) => {
                    const options = {
                        internshipTypes: internshipTypes.data.data,
                        grades: grades.data.data,
                        educationLevels: educationLevels.data.data,
                    };

                    dispatch(internshipFilterOptionsLoading(false));
                    dispatch(internshipFilterOptionsFetchSuccess(options));
                    return true;
                }))
                .catch(error => Promise.reject(error.response.data));
        } catch (error) {
            console.error('[fetchFilterOptions]', error);

            dispatch(internshipFilterOptionsLoading(false));
            return false;
        }
    };
}
