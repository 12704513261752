import React, { PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';
import { LinkProps, NavLink } from 'react-router-dom';

import './SubMenuItem.scss';

export interface SubMenuItemProps<Tab> extends LinkProps {
    key: Tab;
    isActive: boolean;
    label: string;
}

const SubMenuItem = <Tab extends string>({
    isActive,
    to,
    label,
    className = '',
}: PropsWithChildren<SubMenuItemProps<Tab>>): ReactElement => {
    const subMenuItemClassNames = classNames('sub-menu-item', {
        'sub-menu-item--is-active': isActive,
    }, className);

    return (
        <li className={subMenuItemClassNames}>
            <NavLink
                to={to}
                className="sub-menu-item__link"
            >
                {label}
            </NavLink>
        </li>
    );
};

export default SubMenuItem;
