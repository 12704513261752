import React, { FC, ReactElement } from 'react';

import { Button } from '../../../components';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { ButtonList, Disclaimer, IconButton } from '../..';

import './VacancyUnarchiveModal.scss';

interface VacancyUnarchiveModalProps {
    vacancy: NewestVacancy;
    onCancel: () => void;
    onConfirm: () => void;
    className?: string;
}

const VacancyUnarchiveModal: FC<VacancyUnarchiveModalProps> = ({
    vacancy,
    onCancel,
    onConfirm,
    className = '',
}): ReactElement => (
    <div className={`vacancy-unarchive-modal ${className}`}>
        <p className="vacancy-unarchive-modal__description">
            {trans('myVacancies.modal.unarchive.description', {
                name: vacancy.title,
            })}
        </p>

        <Disclaimer
            title={trans('myVacancies.modal.unarchive.disclaimer.title')}
            description={trans('myVacancies.modal.unarchive.disclaimer.description')}
            className="vacancy-unarchive-modal__disclaimer"
        />

        <ButtonList className="vacancy-unarchive-modal__button-list">
            <IconButton
                icon="unarchive"
                text={trans('actions.unarchive')}
                onClick={onConfirm}
            />
            <Button
                text={trans('actions.cancel')}
                onClick={onCancel}
                className="vacancy-unarchive-modal__cancel-button"
            />
        </ButtonList>
    </div>
);

export default VacancyUnarchiveModal;
