import React, { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

const LogoLLOCompact: FC<SvgLogoProps> = ({ className = '' }): ReactElement => {
    const colors = {
        green: '#01bc9c',
        lightGreen: '#11cccc',
    };

    return (
        <svg viewBox="0 0 1000 794.4" className={`${className}`}>
            <path fill={colors.green} d="M81.3,587.7h113.8l-81.3,206.7H0L81.3,587.7z" />
            <path fillOpacity="0.1" d="M195.1,587.7H81.3L40.6,691.1h113.8L195.1,587.7z" />
            <path fill={colors.lightGreen} d="M81.3,587.7h113.8l81.3,206.7H162.6L81.3,587.7z" />
            <path fillOpacity="0.1" d="M162.6,794.4h113.8l-40.7-103.4H122L162.6,794.4z" />
            <path fill={colors.green} d="M313.6,377.5h162.6l-151,417H162.6L313.6,377.5z" />
            <path fillOpacity="0.1" d="M476.2,377.5H313.6l-76.1,210.2h163.2L476.2,377.5z" />
            <path fill={colors.lightGreen} d="M313.6,377.5h220.7l139.4,417H453L313.6,377.5z" />
            <path fillOpacity="0.1" d="M453,794.4h220.7l-69.1-206.7H383.9L453,794.4z" />
            <path fill={colors.green} d="M662,174.2h-94.1L830.4,0L1000,174.2h-94.1L696.9,794.4H453L662,174.2z" />
            <path fillOpacity="0.1" d="M453,794.4h243.9l140.5-417H593.5L453,794.4z" />
        </svg>
    );
};

export default LogoLLOCompact;
