import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MatchProperty } from '../../_old/app_talentz/models/MatchProperty';
import { Grade, InternshipType } from '../../models/VacancyOptions';

export type VacancyOptionsState = Readonly<{
    loading: boolean;
    educationLevels: MatchProperty[];
    internshipTypes: InternshipType[];
    grades: Grade[];
}>;

const initialState: VacancyOptionsState = {
    loading: false,
    educationLevels: [],
    internshipTypes: [],
    grades: [],
};

const vacancyOptionsSlice = createSlice({
    initialState,
    name: 'vacancyOptionsReducer',
    reducers: {
        setVacancyOptionsLoading(state: VacancyOptionsState, action: PayloadAction<boolean>): VacancyOptionsState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setEducationLevelOptions(state: VacancyOptionsState, action: PayloadAction<MatchProperty[]>): VacancyOptionsState {
            return {
                ...state,
                educationLevels: action.payload,
            };
        },
        setInternshipTypeOptions(state: VacancyOptionsState, action: PayloadAction<InternshipType[]>): VacancyOptionsState {
            return {
                ...state,
                internshipTypes: action.payload,
            };
        },
        setGradeOptions(state: VacancyOptionsState, action: PayloadAction<Grade[]>): VacancyOptionsState {
            return {
                ...state,
                grades: action.payload,
            };
        },
    },
});

export const {
    setVacancyOptionsLoading,
    setEducationLevelOptions,
    setInternshipTypeOptions,
    setGradeOptions,
} = vacancyOptionsSlice.actions;

export default vacancyOptionsSlice;
