import React, { FC, ReactElement, useState } from 'react';

import { Button } from '../../../../../components';
import { DetailCard, Modal, ModalContent } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { ApplicationAppointmentFormData } from '../../../../../models/ApplicationAppointments';
import { ApplicationRejectionFormData } from '../../../../../models/ApplicationRejections';
import { Application } from '../../../../../models/Applications';
import { ApplicationRejectionForm, AppointmentForm } from '../../../..';

import './ApplicationStatusWaiting.scss';

interface ApplicationStatusWaitingProps {
    isLoading: boolean;
    isCandidate?: boolean;
    application: Application;
    onInvite?: (applicationUuid: string, formData: ApplicationAppointmentFormData) => void;
    onRejection?: (applicationUuid: string, formData: ApplicationRejectionFormData) => void;
    className?: string;
}

const ApplicationStatusWaiting: FC<ApplicationStatusWaitingProps> = ({
    isLoading,
    isCandidate,
    application,
    onInvite,
    onRejection,
    className = '',
}): ReactElement => {
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState<boolean>(false);
    const [rejectionModalIsOpen, setRejectionModalIsOpen] = useState<boolean>(false);

    const openInviteModal = (): void => setInviteModalIsOpen(true);
    const closeInviteModal = (): void => setInviteModalIsOpen(false);

    const openRejectionModal = (): void => setRejectionModalIsOpen(true);
    const closeRejectionModal = (): void => setRejectionModalIsOpen(false);

    const title = isCandidate
        ? trans('containers.applicationStatusCard.waiting.title.candidate')
        : trans('containers.applicationStatusCard.waiting.title.company');

    const description = isCandidate
        ? trans('containers.applicationStatusCard.waiting.description.candidate', {
            company: application?.vacancy.companyName,
        })
        : trans('containers.applicationStatusCard.waiting.description.company');

    const handleInvite = (formData: ApplicationAppointmentFormData): void => {
        closeInviteModal();

        if (onInvite) {
            onInvite(application.uuid, formData);
        }
    };

    const handleRejection = (formData: ApplicationRejectionFormData): void => {
        closeRejectionModal();

        if (onRejection) {
            onRejection(application.uuid, formData);
        }
    };

    return (
        <DetailCard
            title={title}
            className={`application-status-waiting ${className}`}
        >
            <p className="application-status-waiting__description">
                {description}
            </p>

            {!isCandidate && (
                <div className="application-status-waiting__button-wrapper">
                    <Button
                        text={trans('containers.applicationStatusCard.waiting.button.invite')}
                        disabled={isLoading}
                        onClick={openInviteModal}
                        className="application-status-waiting__button"
                    />

                    <Button
                        text={trans('containers.applicationStatusCard.waiting.button.reject')}
                        disabled={isLoading}
                        onClick={openRejectionModal}
                        className="application-status-waiting__button application-status-waiting__button--for-rejection"
                    />
                </div>
            )}

            {inviteModalIsOpen && (
                <Modal onClose={closeInviteModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.waiting.inviteModal.title')}>
                        <AppointmentForm
                            onSubmit={handleInvite}
                            onCancel={closeInviteModal}
                        />
                    </ModalContent>
                </Modal>
            )}

            {rejectionModalIsOpen && (
                <Modal onClose={closeRejectionModal}>
                    <ModalContent title={trans('containers.applicationStatusCard.waiting.rejectionModal.title')}>
                        <ApplicationRejectionForm
                            onSubmit={handleRejection}
                            onCancel={closeRejectionModal}
                        />
                    </ModalContent>
                </Modal>
            )}
        </DetailCard>
    );
};

export default ApplicationStatusWaiting;
