import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPhone: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 94.4" className={`icon ${className}`}>
        <path d="M94.4,62.2c-7.8,0-15.6-1.7-22.8-3.9l-3.9-1.7L56.1,73.9C43.3,67.2,32.2,57.2,24.4,45L40,30l-1.7-3.3c-2.8-7.2-4.4-12.2-5.6-21.7l-0.6-5H0c0,51.7,41.7,93.9,93.3,94.4h6.7V62.2H94.4z M11.7,11.1h10.6c1.1,6.7,2.2,11.1,4.4,16.1L18.3,35C15.6,27.2,12.8,19.4,11.7,11.1z M88.9,83.3c-7.8-0.6-15.6-2.2-22.2-4.4l5.6-8.3c5.6,1.7,11.1,2.8,16.7,3.3V83.3z" />
    </svg>
);

export default IconPhone;
