import { Pagination } from '../_old/app_talentz/models/Pagination';
import { generateFullName } from '../helpers/name';
import { trans } from '../helpers/trans';
import { Resource } from '../japi/types/Resource';
import { MinMaxValue } from '../types';
import { SeekMatchesSchoolExperience, SeekMatchesSchoolExperienceResponse, transformToSeekMatchesSchoolExperience } from './OldSchoolExperience';
import { RoleType } from './User';

export interface VacancyMatchingCandidateResource extends Resource {
    type: string;
    id: string;
    attributes: {
        interested: boolean;
        isAnonymous: boolean;
        isLloRegistered: boolean;
        elasticScore: number;
        fullName: {
            first: string;
            insertion: string;
            last: string;
        };
        city: string;
        personalCatchPhrase?: string;
        personalMessage: string;
        profilePicture: string;
        roleType: string;
        employmentHourRange: MinMaxValue;
        schoolExperiences: SeekMatchesSchoolExperienceResponse[];
        drivingLicenses: string[];
    };
}

export interface VacancyMatchProperties {
    employmentPreference: MinMaxValue;
    educationLevels: SeekMatchesSchoolExperience[];
    driversLicenses: string[];
}

export interface VacancyMatchingCandidate {
    isAnonymous: boolean;
    isLLOCandidate: boolean;
    isInteresting: boolean;
    uuid: string;
    fullName: string;
    city?: string;
    personalCatchPhrase?: string;
    profilePicture: string;
    role: RoleType;
    schoolExperience?: SeekMatchesSchoolExperience;
    properties: VacancyMatchProperties;
}

export interface VacancyMatchesOverview {
    candidates: VacancyMatchingCandidate[];
    pagination?: Pagination;
}

export const transformToVacancyMatchingCandidate = (
    vacancyMatchingCandidateResource: VacancyMatchingCandidateResource,
): VacancyMatchingCandidate => {
    const fullName = vacancyMatchingCandidateResource.attributes.isAnonymous
        ? trans('basic.anonymousCandidate')
        : generateFullName({
            firstName: vacancyMatchingCandidateResource.attributes.fullName.first,
            lastNamePrefix: vacancyMatchingCandidateResource.attributes.fullName.insertion,
            lastName: vacancyMatchingCandidateResource.attributes.fullName.last,
        });

    const schoolExperience = vacancyMatchingCandidateResource.attributes.schoolExperiences && vacancyMatchingCandidateResource.attributes.schoolExperiences.length > 0
        ? transformToSeekMatchesSchoolExperience(vacancyMatchingCandidateResource.attributes.schoolExperiences[0])
        : undefined;

    return {
        isAnonymous: vacancyMatchingCandidateResource.attributes.isAnonymous,
        isLLOCandidate: vacancyMatchingCandidateResource.attributes.isLloRegistered,
        isInteresting: vacancyMatchingCandidateResource.attributes.interested,
        uuid: vacancyMatchingCandidateResource.id,
        fullName,
        city: vacancyMatchingCandidateResource.attributes.city || undefined,
        personalCatchPhrase: vacancyMatchingCandidateResource.attributes.personalCatchPhrase || undefined,
        profilePicture: vacancyMatchingCandidateResource.attributes.profilePicture
            ? `${process.env.REACT_APP_API_V1_URL}/storage/avatars/${vacancyMatchingCandidateResource.attributes.profilePicture}`
            : '',
        role: vacancyMatchingCandidateResource.attributes.roleType as RoleType,
        schoolExperience,
        properties: {
            employmentPreference: vacancyMatchingCandidateResource.attributes.employmentHourRange,
            educationLevels: vacancyMatchingCandidateResource.attributes.schoolExperiences.map(transformToSeekMatchesSchoolExperience),
            driversLicenses: vacancyMatchingCandidateResource.attributes.drivingLicenses,
        },
    };
};
