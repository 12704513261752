import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../components';

import './Disclaimer.scss';

interface DisclaimerProps {
    isSerious?: boolean;
    title: string;
    description: string;
    className?: string;
}

const Disclaimer: FC<DisclaimerProps> = ({
    isSerious,
    title,
    description,
    className = '',
}): ReactElement => {
    const iconWrapperClassNames = classNames('disclaimer__icon-wrapper', {
        'disclaimer__icon-wrapper--is-serious': isSerious,
    });

    return (
        <div className={`disclaimer ${className}`}>
            <div className={iconWrapperClassNames}>
                <Icon name="exclamation" className="disclaimer__icon" />
            </div>

            <div className="disclaimer__text-wrapper">
                <h3 className="disclaimer__title">{title}</h3>
                <p className="disclaimer__description">{description}</p>
            </div>
        </div>
    );
};

export default Disclaimer;
