import axios from 'axios';
import { Dispatch } from 'redux';

import { trans } from '../../../../helpers/trans';
import { TypedDispatch } from '../../../../store';
import { LegacyReducers } from '../../../../store/reducers';
import { addPositiveToast } from '../../../../store/toast/toastActions';
// eslint-disable-next-line import/no-cycle
import { authenticateUser, getUserWithProfile, setUserToken } from '../legacyUser/legacyUserActions';
import {
    setCompletePasswordForgetStep,
    setCompleteStep,
    setErrors,
    setLoading,
    setResetPasswordData,
    setResetPasswordToken,
    setStep,
} from './resetPassword';

/**
 * Initiate the reset password flow and sends an email
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function resetPasswordEmail(data: any) {
    return (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        data.callback_url = '/?password-token=';

        return axios.post('/authentication/passport/reset/password', data)
            .then(() => {
                dispatch(setStep(1));
                dispatch(setLoading(false));
            })
            .catch(error => {
                dispatch(setErrors(error.response.data.errors));
                dispatch(setLoading(false));
            });
    };
}

/**
 * Get user by token received in url query params
 *
 * @param token
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function getUserByPasswordToken(token: string) {
    return (dispatch: Dispatch) => {
        dispatch(setLoading(true));

        return axios.get(`/authentication/passport/reset/password/${token}`).then(
            response => {
                dispatch(setResetPasswordData(response.data.data));
                dispatch(setResetPasswordToken(null));
                dispatch(setCompleteStep(1));
                dispatch(setLoading(false));
            },
            error => {
                if (error.response.status < 500) {
                    dispatch(setCompleteStep(0));
                    dispatch(setLoading(false));
                }
            },
        );
    };
}

/**
 * Reset password complete step.
 *
 * @param data
 * @returns {function(*, *): Promise<AxiosResponse<any> | never>}
 */
export function resetPasswordComplete(data: any) {
    return (dispatch: TypedDispatch, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { resetPassword } = getState();
        data.token = resetPassword?.resetPasswordData?.token;

        return axios.post(`/authentication/passport/reset/password/${data.token}`, data)
            .then(response => {
                dispatch(setUserToken(response.data.data));
                dispatch(getUserWithProfile());

                if (resetPassword.resetPasswordData) {
                    dispatch(authenticateUser({
                        username: resetPassword.resetPasswordData.email,
                        password: data.password,
                    }, true));
                }

                dispatch(setCompletePasswordForgetStep(1));
                dispatch(setLoading(false));

                dispatch(addPositiveToast({
                    title: trans('forms.passwordForget.successToast.title'),
                    description: trans('forms.passwordForget.successToast.description'),
                }));
            })
            .catch(error => {
                dispatch(setErrors(error.response.data.errors));
                dispatch(setLoading(false));
            });
    };
}

export function updatePassword(data: any) {
    return (dispatch: TypedDispatch) => {
        dispatch(setLoading(true));

        return axios.put('/authentication/passport/password', data)
            .then(response => {
                dispatch(setLoading(false));

                dispatch(addPositiveToast({
                    title: trans('basic.toast.success'),
                    description: trans('basic.passwordResetSuccess'),
                }));

                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(setErrors(error.response.data.errors));
                dispatch(setLoading(false));

                return Promise.reject(error.response);
            });
    };
}
