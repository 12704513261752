import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import './MatchingAnimation.scss';

interface MatchingAnimationProps {
    animationIsDisabled?: boolean;
    animationIsPaused?: boolean;
    className?: string;
}

const MatchingAnimation: FC<MatchingAnimationProps> = ({
    animationIsDisabled,
    animationIsPaused,
    className = '',
}): ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect((): void => {
        if (!videoRef?.current) {
            return;
        }

        animationIsPaused ? videoRef.current.pause() : videoRef.current.play();
    }, [animationIsPaused]);

    return (
        <div className={`matching-animation ${className}`}>
            <div className="matching-animation__sizer">
                {animationIsDisabled ? (
                    <img
                        alt="matching animation fallback"
                        src="/images/matching-animation-fallback.png"
                        className="matching-animation__img"
                    />
                ) : (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                        autoPlay
                        loop
                        muted
                        ref={videoRef}
                        className="matching-animation__video"
                    >
                        <source src="/videos/matching-animation.mp4" type="video/webm" />
                        <source src="/videos/matching-animation.webm" type="video/webm" />
                    </video>
                )}
            </div>
        </div>
    );
};

export default MatchingAnimation;
