import { EmploymentPreferenceFormData } from '../../../../models/Employment';
import {
    FormErrors,
    FormValidation,
    OptionalFormValidation,
    validateForm,
    validateNumberRangeEnd,
    validateNumberRangeStart,
} from '../../../../services/ValidationService';

export type EmploymentPreferenceFormErrors = FormErrors<EmploymentPreferenceFormData>;

export const validateEmploymentPreferenceFormData = (
    formData: EmploymentPreferenceFormData,
    optionalValidation: OptionalFormValidation<EmploymentPreferenceFormData>,
): FormValidation<EmploymentPreferenceFormErrors> => {
    const errors: EmploymentPreferenceFormErrors = {
        min: optionalValidation.min
            ? validateNumberRangeStart('minAmountOfHours', formData.min, formData.max)
            : undefined,
        max: optionalValidation.max
            ? validateNumberRangeEnd('maxAmountOfHours', formData.max, formData.min)
            : undefined,
    };

    return validateForm<EmploymentPreferenceFormErrors>(errors);
};
