import React, { FC, ReactElement, useEffect } from 'react';

import { CompanyAboutCard } from '../../../containers';
import { ActivationRef } from '../../../containers/@profile/ProfileProgress/ProfileProgress';
import { AboutFormData } from '../../../models/About';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../../store';
import { clearCompany, fetchCompany, patchCompany } from '../../../store/company/companyActions';

interface ConnectedCompanyAboutCardProps {
    activationRef?: ActivationRef;
    shouldFetchCompany?: boolean;
    companyUuid?: string;
    className?: string;
}

const ConnectedCompanyAboutCard: FC<ConnectedCompanyAboutCardProps> = ({
    activationRef,
    shouldFetchCompany,
    companyUuid,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const person = useLegacySelector(state => state.userPerson.data);

    const company = useTypedSelector(state => state.companyReducer.company);
    const isLoading = useTypedSelector(state => state.companyReducer.isLoading);

    const userFromCompany = !!(person && company && (company.uuid === person.employee_profile?.company_id));

    useEffect((): () => void => {
        const companyId = companyUuid || person?.employee_profile?.company_id;

        if (shouldFetchCompany && companyId) {
            dispatch(fetchCompany(companyId));
        }

        // Clear company data from store when component unmounts
        return (): void => {
            if (shouldFetchCompany) dispatch(clearCompany());
        };
    }, []);

    const handleEditAboutCompany = (aboutFormData: AboutFormData): void => {
        if (company) {
            dispatch(patchCompany({
                uuid: company.uuid,
                description: aboutFormData.description,
            }));
        }
    };

    return (
        <CompanyAboutCard
            activationRef={activationRef}
            isLoading={isLoading}
            isEditable={userFromCompany}
            userFromCompany={userFromCompany}
            description={company?.description}
            onEdit={handleEditAboutCompany}
            className={className}
        />
    );
};

export default ConnectedCompanyAboutCard;
