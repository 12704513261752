import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../../../components';
import { ButtonProps } from '../../../../../components/@buttons/Button/Button';

import './ExpandableFilterButton.scss';

interface ExpandableFilterButtonProps extends ButtonProps {
    isExpanded?: boolean;
    isActive?: boolean;
    selectedFilter?: string;
    className?: string;
}

const ExpandableFilterButton: FC<ExpandableFilterButtonProps> = ({
    isExpanded,
    isActive,
    text,
    selectedFilter,
    className = '',
    ...buttonProps
}): ReactElement => {
    const buttonClassNames = classNames('expandable-filter-button', {
        'expandable-filter-button--is-active': isActive || isExpanded,
    }, className);

    const iconClassNames = classNames('expandable-filter-button__icon', {
        'expandable-filter-button__icon--is-expanded': isExpanded,
    });

    return (
        <Button
            {...buttonProps}
            text={text}
            className={buttonClassNames}
        >
            <span className="expandable-filter-button__label">
                {selectedFilter ? `${text} (${selectedFilter})` : text}
            </span>
            <Icon name="chevron-down" className={iconClassNames} />
        </Button>
    );
};

export default ExpandableFilterButton;
