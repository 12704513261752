import React, { FC, ReactElement, useEffect } from 'react';

import { ApplicationMatchCard } from '../../../containers';
import { Application, ApplicationStatus } from '../../../models/Applications';
import { Candidate } from '../../../models/Candidate';
import { useTypedDispatch, useTypedSelector } from '../../../store';
import { clearApplication, fetchApplication, matchApplication } from '../../../store/application/applicationActions';
import { archiveVacancy } from '../../../store/myVacancies/myVacanciesActions';

interface ConnectedApplicationMatchCardProps {
    shouldFetchApplication?: boolean;
    applicationUuid?: string;
    candidate?: Candidate;
    className?: string;
}

const ConnectedApplicationMatchCard: FC<ConnectedApplicationMatchCardProps> = ({
    shouldFetchApplication,
    applicationUuid,
    candidate,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.applicationReducer.isLoading);
    const application = useTypedSelector(state => state.applicationReducer.application);

    useEffect((): () => void => {
        if (shouldFetchApplication && applicationUuid) {
            dispatch(fetchApplication(applicationUuid));
        }

        // Clear candidate data from store when component unmounts
        return (): void => {
            if (shouldFetchApplication) dispatch(clearApplication());
        };
    }, []);

    const handleMatchApplication = async (application: Application, shouldArchive: boolean): Promise<void> => {
        await dispatch(matchApplication(application.uuid));

        if (shouldArchive) {
            dispatch(archiveVacancy(application.vacancy.uuid, [application.uuid]));
        }
    };

    return application && application.status !== ApplicationStatus.hired ? (
        <ApplicationMatchCard
            isLoading={isLoading}
            application={application}
            candidate={candidate}
            onMatchApplication={handleMatchApplication}
            className={className}
        />
    ) : null;
};

export default ConnectedApplicationMatchCard;
