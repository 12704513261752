import React from 'react';

import PropTypes from 'prop-types';

import Icon from '../../styledComponents/components/Icon';
import ToolTip from '../Tooltip/Tooltip.new';
import StyledButton from './style';

const Button = props => {
    const {
        borderless,
        children,
        className,
        icon,
        iconMt,
        iconSize,
        inline,
        isLoading,
        type,
        id,
        pulsing,
        large,
        small,
        hideIcon,
        disabled,
        toolTip,
        iconOnly,
        ...rest
    } = props;

    const typeIsSubmit = type === 'submit' && !hideIcon;
    let loaderKind = (typeIsSubmit && !isLoading) ? 'check' : icon;
    loaderKind = isLoading ? 'loader' : loaderKind;

    return (
        <>
            <StyledButton
                id={id}
                isPulsing={pulsing}
                isInline={inline}
                isLarge={large}
                isSmall={small}
                isBorderless={borderless}
                className={className}
                disabled={isLoading || disabled}
                type={type}
                hasIcon={typeIsSubmit || icon || isLoading}
                data-for={`${id}-tooltip`}
                data-tip
                iconOnly={iconOnly}
                {...rest}
            >
                {(typeIsSubmit || icon || isLoading) && (
                    <Icon
                        size={iconSize}
                        kind={loaderKind}
                        mt={iconMt}
                        hasLoader={isLoading}
                    />
                )}
                {children}
            </StyledButton>
            {toolTip && (
                <ToolTip
                    content={toolTip}
                    id={`${id}-tooltip`}
                    place="bottom"
                    className="button__tooltip"
                />
            )}
        </>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.node,
    ]),
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    iconSize: PropTypes.number,
    iconMt: PropTypes.number,
    iconOnly: PropTypes.bool,
    iconLeft: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string,
    mb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
    ]),
    mr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
    ]),
    mt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
    ]),
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    color: PropTypes.string,
    p: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
    ]),
    pl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
    ]),
    pr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
    ]),
    height: PropTypes.string,
    isTransparent: PropTypes.bool,
    borderless: PropTypes.bool,
    noUpperCase: PropTypes.bool,
    as: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    to: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,

    inline: PropTypes.bool,
    pulsing: PropTypes.bool,
    className: PropTypes.string,
    hideIcon: PropTypes.bool,
    justifyContent: PropTypes.string,
    toolTip: PropTypes.string,
};

Button.defaultProps = {
    children: null,
    icon: null,
    iconSize: 18,
    iconMt: 0,
    iconOnly: false,
    iconLeft: false,
    isLoading: false,
    disabled: false,
    large: false,
    small: false,
    type: null,
    id: null,
    mb: null,
    mr: null,
    mt: null,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMouseDown: () => {},
    color: null,
    p: null,
    pl: null,
    pr: null,
    height: null,
    isTransparent: false,
    borderless: false,
    noUpperCase: false,
    as: null,
    to: null,
    href: null,
    target: null,
    rel: null,

    inline: false,
    pulsing: false,
    className: '',
    hideIcon: false,
    justifyContent: null,
    toolTip: null,
};

export default Button;
