import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { trans } from '../../helpers/trans';
import { AddressFormErrors, AddressFormValues } from '../../services/AddressService';
import { Loader, TextInput } from '..';

import './AddressInput.scss';

interface AddressInputProps {
    isLoading: boolean;
    addressNotFound: boolean;
    formValues: AddressFormValues;
    errors?: AddressFormErrors;
    onChange: (formValues: AddressFormValues) => void;
    onSubmit: () => void;
    className?: string;
}

const AddressInput: FC<AddressInputProps> = ({
    isLoading,
    addressNotFound,
    formValues,
    errors = {},
    onChange,
    onSubmit,
    className = '',
}): ReactElement => {
    const [initialized, setInitialized] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);

    const {
        city,
        zipcode,
        streetNumber,
        extension,
        address,
    } = formValues;

    useEffect((): void => {
        if (formValues.zipcode && formValues.streetNumber && formValues.address && formValues.city) {
            setInitialized(true);
        }
    }, [formValues]);

    useEffect((): void => {
        if (initialized) {
            setCanSubmit(true);
        }
    }, [zipcode, streetNumber, extension]);

    const handleZipcodeChange = (value: string): void => onChange({
        ...formValues,
        zipcode: value,
    });

    const handleStreetNumberChange = (value: string): void => onChange({
        ...formValues,
        streetNumber: value,
    });

    const handleExtensionChange = (value: string): void => onChange({
        ...formValues,
        extension: value,
    });

    const handleBlur = () => {
        if (formValues.zipcode && formValues.streetNumber && canSubmit) {
            onSubmit();
            setCanSubmit(false);
        }
    };

    return (
        <div className={`address-input ${className}`}>
            <div className="address-input__wrapper">
                <TextInput
                    required
                    label={trans('forms.zipcodeCheck.zipcode.label')}
                    placeholder={trans('forms.zipcodeCheck.zipcode.placeholder')}
                    value={zipcode}
                    error={errors.zipcode}
                    onChange={handleZipcodeChange}
                    onBlur={handleBlur}
                    className="address-input__input"
                />

                <TextInput
                    required
                    label={trans('forms.zipcodeCheck.streetNumber.label')}
                    placeholder={trans('forms.zipcodeCheck.streetNumber.placeholder')}
                    value={streetNumber}
                    error={errors.streetNumber}
                    onChange={handleStreetNumberChange}
                    onBlur={handleBlur}
                    className="address-input__input"
                />

                <TextInput
                    label={trans('forms.zipcodeCheck.streetNumberExtension.label')}
                    placeholder={trans('forms.zipcodeCheck.streetNumberExtension.placeholder')}
                    value={extension}
                    error={errors.extension}
                    onChange={handleExtensionChange}
                    onBlur={handleBlur}
                    className="address-input__input"
                />
            </div>

            {isLoading && <Loader className="address-input__loader" />}

            {!isLoading && addressNotFound && (
                <p className="address-input__error-label">
                    {trans('forms.zipcodeCheck.addressNotFound')}
                </p>
            )}

            {initialized && !isLoading && !addressNotFound && (
                <div className="address-input__result-wrapper">
                    <h2 className="address-input__result-title">
                        {trans('forms.zipcodeCheck.addressFound')}
                    </h2>

                    <ul className="address-input__result-list">
                        <li className="address-input__result">
                            <h2 className="address-input__result-label">
                                {trans('forms.zipcodeCheck.streetName.label')}
                            </h2>

                            <p className="address-input__result-value">
                                {address}
                            </p>
                        </li>

                        <li className="address-input__result">
                            <h2 className="address-input__result-label">
                                {trans('forms.zipcodeCheck.city.label')}
                            </h2>

                            <p className="address-input__result-value">
                                {city}
                            </p>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AddressInput;
