import React, { FC, ReactElement } from 'react';

import { Button, Icon } from '../../../components';
import { ButtonProps } from '../../../components/@buttons/Button/Button';
import { trans } from '../../../helpers/trans';

import './FilterButton.scss';

interface FilterButtonProps extends Omit<ButtonProps, 'text'> {
    hasIcon?: boolean;
    activeFilters?: number;
    text?: string;
}

const FilterButton: FC<FilterButtonProps> = ({
    hasIcon,
    activeFilters,
    text = trans('actions.filter'),
    className = '',
    ...buttonProps
}): ReactElement => (
    <Button
        {...buttonProps}
        text={text}
        className={`filter-button ${className}`}
    >
        {hasIcon && (
            <Icon name="filter" className="filter-button__icon" />
        )}

        <span className="filter-button__label">
            {text}
        </span>

        {activeFilters ? (
            <div className="filter-button__active-filter-count">
                {activeFilters}
            </div>
        ) : null}
    </Button>
);

export default FilterButton;
