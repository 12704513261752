import React, { useEffect, useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';

import { trans } from '../../../../../helpers/trans';
import TextInput from '../../../../components/form/TextInput';
import { removeSpacesFromString, sanitizeTextInput } from '../../../../helpers/formHelper';
import { Box } from '../../../../styledComponents/components/Box';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import Icon from '../../../../styledComponents/components/Icon';
import { Paragraph } from '../../../../styledComponents/components/Typography';

const ZipCodeCheck = ({
    errors,
    userAddress,
    onChange,
    onBlur,
}) => {
    const [addressNotFound, setAddressNotFound] = useState(false);
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [address, setAddress] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [streetNumber, setStreetNumber] = useState(null);
    const [streetNumberExtension, setStreetNumberExtension] = useState(null);

    const requestAddress = () => {
        setLoadingAddress(true);
        return axios.get(`addresses/search/${zipCode}/${streetNumber}/${streetNumberExtension || ''}`);
    };

    const handleChange = () => {
        const newAddress = { ...address };

        if (newAddress.longitude && newAddress.latitude) {
            newAddress.longitude = newAddress.longitude.toString();
            newAddress.latitude = newAddress.latitude.toString();
        }

        onChange({
            ...newAddress,
        });
    };

    const onZipCodeChange = e => {
        const value = sanitizeTextInput(removeSpacesFromString(e.currentTarget.value));
        // TODO: Normally this is a very bad idea to modify event values (also in name of purity)
        // But due to the mechanics of having 2 truths (this state and the upper component state)
        // its hard to make sure values stay consistent (and across containers). This should be fixed by the refactor
        e.currentTarget.value = value;
        onBlur(e);
        setZipCode(value);
    };

    const onStreetNumberChange = e => {
        onBlur(e);
        setStreetNumber(e.currentTarget.value);
    };

    const onStreetNumberExtensionChange = e => setStreetNumberExtension(e.currentTarget.value);

    useEffect(() => {
        if (userAddress) {
            !address && setAddress(userAddress);
            !zipCode && setZipCode(userAddress.zipcode);
            !streetNumber && setStreetNumber(userAddress.street_number);
            !streetNumberExtension && setStreetNumberExtension(userAddress.extension);
        }
    }, [userAddress]);

    useEffect(() => {
        handleChange();
    }, [address]);

    useEffect(() => {
        const zipCodeCharacters = (zipCode && zipCode.length >= 6);
        const streetNumberCharacters = (streetNumber && streetNumber.length > 0);

        if (zipCodeCharacters && streetNumberCharacters) {
            requestAddress()
                .then(result => {
                    setLoadingAddress(false);
                    setAddressNotFound(false);
                    setAddress(result.data.data);
                })
                .catch(() => {
                    setLoadingAddress(false);
                    setAddressNotFound(true);
                    setAddress({
                        street_number: streetNumber,
                        zipcode: zipCode,
                        extension: streetNumberExtension,
                    });
                });
        }
    }, [zipCode, streetNumber, streetNumberExtension]);

    return (
        <>
            <Row>
                <Col mb="md" width={3 / 6}>
                    <TextInput
                        id="zipcode"
                        name="zipcode"
                        required
                        label={trans('forms.zipcodeCheck.zipcode.label')}
                        placeholder={trans('forms.zipcodeCheck.zipcode.placeholder')}
                        defaultValue={address && address.zipcode}
                        onInit={cleave => setZipCode(cleave)}
                        onBlur={onZipCodeChange}
                        errors={errors.zipcode}
                        disabled={loadingAddress}
                        autoComplete="postal-code"
                    />
                </Col>
                <Col mb="md" width={[1 / 4, 1 / 4, 2 / 6]} pl="sm">
                    <TextInput
                        id="street_number"
                        name="street_number"
                        required
                        label={trans('forms.zipcodeCheck.streetNumber.label')}
                        placeholder={trans('forms.zipcodeCheck.streetNumber.placeholder')}
                        defaultValue={address && address.street_number}
                        onBlur={onStreetNumberChange}
                        errors={errors.street_number}
                        disabled={loadingAddress}
                    />
                </Col>
                <Col mb="md" width={[1 / 4, 1 / 4, 1 / 6]} pl="sm">
                    <TextInput
                        id="street_number_extension"
                        label="Toevoeging"
                        placeholder={trans('forms.zipcodeCheck.streetNumberExtension.placeholder')}
                        name="street_number_extension"
                        defaultValue={address && address.extension}
                        onBlur={onStreetNumberExtensionChange}
                        errors={errors.street_number_extension}
                        disabled={loadingAddress}
                    />
                </Col>
            </Row>
            {(loadingAddress || (zipCode && streetNumber)) && (
                <Box mb="md" backgroundColor={addressNotFound && !loadingAddress ? 'alert' : null}>
                    {loadingAddress
                        ? (
                            <Row>
                                <Col width={2 / 3} alignSelf="center">
                                    {trans('forms.zipcodeCheck.searching')}
                                </Col>
                                <Col width={1 / 3} ml="auto" textAlign="right">
                                    <Icon kind="loader" color="transparent" />
                                </Col>
                            </Row>
                        )
                        : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {addressNotFound || !((address && address.address) && (address && address.city)) ? (
                                    <Row>
                                        <Col width={1}>
                                            <Paragraph color={addressNotFound && !loadingAddress ? 'white' : 'text'}>
                                                {trans('forms.zipcodeCheck.addressNotFound')}
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                ) : (
                                    <>
                                        <Row>
                                            <Col mb="sm" width={1}>
                                                <Paragraph size="rg">
                                                    {trans('forms.zipcodeCheck.addressFound')}
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col width={1 / 2}>
                                                <TextInput
                                                    name="address_preview"
                                                    errors={errors.address}
                                                    id="address"
                                                    required
                                                    label={trans('forms.zipcodeCheck.streetName.label')}
                                                    defaultValue={(address && address.address) || ''}
                                                    disabled
                                                />
                                            </Col>
                                            <Col pl="sm" width={1 / 2}>
                                                <TextInput
                                                    name="city_preview"
                                                    id="city"
                                                    required
                                                    label={trans('forms.zipcodeCheck.city.label')}
                                                    defaultValue={(address && address.city) || ''}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                        <input
                                            type="hidden"
                                            value={(address && address.address) || ''}
                                            name="address"
                                        />
                                        <input
                                            type="hidden"
                                            value={(address && address.city) || ''}
                                            name="city"
                                        />
                                        <input
                                            type="hidden"
                                            value={(address && address.province) || ''}
                                            name="province"
                                        />
                                        <input
                                            type="hidden"
                                            name="latitude"
                                            value={(address && address.latitude) || 0}
                                        />
                                        <input
                                            type="hidden"
                                            name="longitude"
                                            value={(address && address.longitude) || 0}
                                        />
                                    </>
                                )}
                            </>
                        )
                    }
                </Box>
            )}
        </>
    );
};

ZipCodeCheck.propTypes = {
    errors: PropTypes.object,
    userAddress: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

ZipCodeCheck.defaultProps = {
    errors: {},
    userAddress: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBlur: () => {},
};

export default ZipCodeCheck;
