import React, { FC, ReactElement } from 'react';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';

import { siteUrl } from '../../constants';
import { dataLayerPush } from '../../helpers/analytics';
import { isNotSSR } from '../../helpers/environment';
import { RoleType } from '../../models/User';

import './ShareList.scss';

interface ShareListProps {
    userRole?: RoleType;
    hasEmail?: boolean;
    titleText?: string;
    bodyText?: string;
    url?: string;
    className?: string;
    itemClassName?: string;
}

const ShareList: FC<ShareListProps> = ({
    userRole,
    hasEmail,
    titleText,
    bodyText,
    url,
    className = '',
    itemClassName = '',
}): ReactElement => {
    const currentPage = isNotSSR ? window.location.href : '';
    const shareUrl = url || currentPage;

    const mediaList = [
        {
            key: 'linkedIn',
            button: LinkedinShareButton,
            icon: LinkedinIcon,
            title: titleText,
            summary: bodyText,
            source: siteUrl,
        },
        {
            key: 'facebook',
            button: FacebookShareButton,
            icon: FacebookIcon,
        },
        {
            key: 'twitter',
            button: TwitterShareButton,
            icon: TwitterIcon,
            title: titleText,
        },
        {
            key: 'whatsApp',
            button: WhatsappShareButton,
            icon: WhatsappIcon,
            title: titleText,
            separator: '\n\n',
        },
        (hasEmail ? {
            key: 'email',
            button: EmailShareButton,
            icon: EmailIcon,
            subject: titleText,
            body: bodyText,
            separator: '\n\n',
        } : undefined),
    ];

    return (
        <ul className={`share-list ${className}`}>
            {mediaList.map(media => {
                if (!media) return null;

                const { button, icon, ...mediaProps } = media;

                const Button = button;
                const Icon = icon;

                const handleShareClick = (): void => {
                    if (userRole === RoleType.company) {
                        dataLayerPush({
                            event: 'clickShareSocialEmployee',
                            shareType: media.key,
                        });
                    }
                };

                return (
                    <li key={media.key} className={`share-list__item ${itemClassName}`}>
                        <Button
                            {...mediaProps}
                            url={shareUrl}
                            onClick={handleShareClick}
                            className="share-list__button"
                        >
                            <Icon size={40} />
                        </Button>
                    </li>
                );
            })}
        </ul>
    );
};

export default ShareList;
