import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { Button, Icon } from '../../../../components';
import { Avatar } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';

import './FavouriteItem.scss';

interface FavouriteItemProps {
    isLoading: boolean;
    uuid: string;
    type: VacancyType;
    title: string;
    companyCity: string;
    companyLogo?: string;
    companyName: string;
    onRemoveClick: (id: string) => void;
    className?: string;
}

const FavouriteItem: FC<FavouriteItemProps> = ({
    isLoading,
    uuid,
    type,
    title,
    companyCity,
    companyLogo,
    companyName,
    onRemoveClick,
    className = '',
}): ReactElement => {
    const vacancyLink = type === VacancyType.internship
        ? RoutePaths.internshipDetail(uuid)
        : RoutePaths.jobDetail(uuid);

    const handleRemoveFavourite = (): void => onRemoveClick(uuid);

    const buttonClassNames = classNames('favourite-item__remove-button', {
        'favourite-item__remove-button--is-loading': isLoading,
    });

    return (
        <li className={`favourite-item ${className}`}>
            <div className="favourite-item__content-wrapper">
                <Avatar
                    src={companyLogo}
                    alt={trans('favourites.item.companyLogoAlt', {
                        company: companyName,
                    })}
                    fallbackString={companyName}
                    className="favourite-item__logo"
                />

                <div className="favourite-item__text-wrapper">
                    <Link to={vacancyLink} className="favourite-item__link">
                        <h2 data-hj-suppress="true" className="favourite-item__title">
                            {title}
                        </h2>
                    </Link>

                    <p data-hj-suppress="true" className="favourite-item__company">
                        {trans('favourites.item.companyInfo', {
                            company: companyName,
                            city: companyCity,
                        })}
                    </p>
                </div>
            </div>

            <Button
                text={trans('favourites.removeFavourite')}
                disabled={isLoading}
                onClick={handleRemoveFavourite}
                className={buttonClassNames}
            >
                <Icon name="heart-solid" className="favourite-item__remove-icon" />
            </Button>
        </li>
    );
};

export default FavouriteItem;
