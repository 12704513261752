import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { trans } from '../../helpers/trans';
import { sumFieldsOfStudy, sumGrades } from '../../helpers/vacancy';
import { VacancyProperties } from '../../models/NewestVacancy';
import { Tag } from '..';

import './VacancyPropertyTags.scss';

interface VacancyPropertyTagsProps {
    isAnonymous?: boolean;
    isSbbUser?: boolean;
    type: VacancyType;
    vacancyUuid: string;
    properties: VacancyProperties;
    userFieldsOfStudy?: string[];
    className?: string;
}

const VacancyPropertyTags: FC<VacancyPropertyTagsProps> = ({
    isAnonymous,
    isSbbUser,
    type,
    vacancyUuid,
    properties,
    userFieldsOfStudy,
    className = '',
}): ReactElement => {
    const defaultProperties: string[] = [
        properties.vacancyTypeName,
        properties.amountOfEmployees,
        properties.educationLevels.join(', '),
        properties.employmentHourRange,
        properties.compensation,
        properties.sector,
    ];

    const internshipProperties: string[] = [
        sumGrades(properties.grades),
        sumFieldsOfStudy(
            isSbbUser,
            properties.fieldsOfStudy,
            properties.approvedFieldsOfStudy,
            userFieldsOfStudy,
        ),
    ];

    const propertyList = type === VacancyType.internship
        ? [...defaultProperties, ...internshipProperties]
        : defaultProperties;

    const filteredProperties = propertyList.filter(Boolean);

    return (
        <ul className={`vacancy-property-tags ${className}`}>
            {isAnonymous && (
                <li className="vacancy-property-tags__tag-item">
                    <Tag text={trans('basic.applyAnonymous')} />
                </li>
            )}

            {filteredProperties.map(property => (
                <li key={`${vacancyUuid}-${property}`} className="vacancy-property-tags__tag-item">
                    <Tag text={property} />
                </li>
            ))}
        </ul>
    );
};

export default VacancyPropertyTags;
