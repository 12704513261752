import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { ChatMessage } from '../../models/ChatMessages';
import { AsyncReduxState } from '../../types';

export type ChatMessagesState = AsyncReduxState<{
    isReconnecting: boolean;
    messages: ChatMessage[];
}>;

const initialState: ChatMessagesState = {
    ...initialAsyncReduxState,
    isReconnecting: false,
    messages: [],
};

const chatMessagesSlice = createSlice({
    name: 'chatMessagesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ChatMessagesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ChatMessagesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsReconnecting(state, action: PayloadAction<boolean>): ChatMessagesState {
            return {
                ...state,
                isReconnecting: action.payload,
            };
        },
        setMessages(state, action: PayloadAction<ChatMessage[]>): ChatMessagesState {
            return {
                ...state,
                messages: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setIsReconnecting,
    setMessages,
} = chatMessagesSlice.actions;

export default chatMessagesSlice;
