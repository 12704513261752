import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { initialAsyncReduxState } from '../../constants';
import { NewestVacancy } from '../../models/NewestVacancy';
import { defaultJobVacancyOverviewFilterValues, JobVacancyOverviewFilterValues } from '../../models/VacancyOverview';
import { AsyncReduxState } from '../../types';

export type JobVacancyOverviewState = AsyncReduxState<{
    activeFilterValues: JobVacancyOverviewFilterValues;
    jobVacancies: NewestVacancy[];
    pagination?: Pagination;
}>;

const initialState: JobVacancyOverviewState = {
    ...initialAsyncReduxState,
    activeFilterValues: defaultJobVacancyOverviewFilterValues(),
    jobVacancies: [],
    pagination: undefined,
};

export const jobVacancyOverviewSlice = createSlice({
    name: 'jobVacancyOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): JobVacancyOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): JobVacancyOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<JobVacancyOverviewFilterValues>): JobVacancyOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setVacancies(state, action: PayloadAction<NewestVacancy[]>): JobVacancyOverviewState {
            return {
                ...state,
                jobVacancies: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination | undefined>): JobVacancyOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveFilterValues,
    setVacancies,
    setPagination,
} = jobVacancyOverviewSlice.actions;

export default jobVacancyOverviewSlice;
