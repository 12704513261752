import { ValidationErrors } from '../../../_old/app_talentz/models/ValidationErrors';
import { Competency } from '../../../models/Competencies';
import { Skill } from '../../../models/Skills';
import { validateMinimumArrayLength } from '../../../services/ValidationService';

interface InternshipVacancySkillsStepValues {
    skills: Skill[];
    skillsToLearn: Skill[];
    competencies: Competency[];
    competenciesToLearn: Competency[];
}

export const validateInternshipVacancySkillsStep = (formValues: InternshipVacancySkillsStepValues): ValidationErrors => {
    const {
        skills,
        skillsToLearn,
        competencies,
        competenciesToLearn,
    } = formValues;

    const skillsValidation = validateMinimumArrayLength('skills', skills, 3);
    const skillsToLearnValidation = validateMinimumArrayLength('skills', skillsToLearn, 3);
    const competenciesValidation = validateMinimumArrayLength('competencies', competencies, 3);
    const competenciesToLearnValidation = validateMinimumArrayLength('competencies', competenciesToLearn, 3);

    return {
        ...(skillsValidation ? { skills: [skillsValidation] } : {}),
        ...(skillsToLearnValidation ? { skillsToLearn: [skillsToLearnValidation] } : {}),
        ...(competenciesValidation ? { competencies: [competenciesValidation] } : {}),
        ...(competenciesToLearnValidation ? { competenciesToLearn: [competenciesToLearnValidation] } : {}),
    };
};
