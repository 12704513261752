/* eslint-disable camelcase */
import { EscoSkillsMatchProperty, transformToEscoSkillWithMatch } from '../../../models/Esco';
import { NewestVacancy } from '../../../models/NewestVacancy';
import transformApiCompensationToInternshipVacancyCompensation from '../../helpers/transformApiCompensationToInternshipVacancyCompensation';
import { transformLegacyVacancyToNewestVacancy } from './JobVacancy';
import { MatchProperty, transformToPropertyWithMatch } from './MatchProperty';
import { transformVacancy, Vacancy, VacancyApiParams } from './Vacancy';
import { VacancyType } from './VacancyType';

export interface InternshipVacancyApiParams extends VacancyApiParams {
    match_details?: InternshipVacancyMatchDetails;
    internship_vacancy: InternshipVacancyDetailApiParams;
    type: VacancyType.internship;
}

export interface InternshipVacancy extends Vacancy {
    matching?: number;
    properties: InternshipVacancyProperties;
    property_groups: any[];
    period: any[];
    type: VacancyType.internship;
}

export interface InternshipVacancyDetailApiParams {
    grades: MatchProperty[];
    compensation: {
        is_matched?: boolean;
        min: MatchProperty;
        max: MatchProperty;
    } | null;
    internship_type: MatchProperty;
    period: any[];
    sbb_approved: boolean;
}

export interface InternshipVacancyMatchDetails {
    match_percentage: number;
    matching_attributes: InternshipVacancyMatchingAttributes;
}

export interface InternshipVacancyMatchingAttributes {
    field_of_study: string[];
    skills: string[];
    skills_to_learn: string[];
    competencies: string[];
    competencies_to_learn: string[];
    amount_of_employees: string[];
    grades: string[];
    internship_types?: string[];
    compensation?: boolean;
    education_levels?: string[];
    esco_skills?: string[];
}

export interface InternshipVacancyProperties {
    amount_of_employees: MatchProperty;
    fields_of_study: MatchProperty[];
    compensation: {
        is_matched?: boolean;
        min: MatchProperty;
        max: MatchProperty;
        name: string;
    } | null;
    grades: MatchProperty[];
    skills: MatchProperty[];
    skills_to_learn: MatchProperty[];
    competencies: MatchProperty[];
    competencies_to_learn: MatchProperty[];
    internship_type: MatchProperty;
    education_levels: MatchProperty[];
    esco_skills: EscoSkillsMatchProperty[];
    sector: {
        name: string;
        slug: string;
    };
    sbb_approved: boolean;
}

export function getInternshipVacancyProperties(vacancy: InternshipVacancyApiParams): InternshipVacancyProperties {
    return {
        amount_of_employees: vacancy.company.amount_of_employees,
        fields_of_study: vacancy.fields_of_study,
        compensation: transformApiCompensationToInternshipVacancyCompensation(vacancy.internship_vacancy.compensation),
        grades: vacancy.internship_vacancy.grades,
        skills: vacancy.skills,
        skills_to_learn: vacancy.skills_to_learn,
        competencies: vacancy.competencies,
        competencies_to_learn: vacancy.competencies_to_learn,
        internship_type: vacancy.internship_vacancy.internship_type,
        education_levels: vacancy.education_levels,
        esco_skills: vacancy.esco_skills || [],
        sector: vacancy.sector,
        sbb_approved: vacancy.internship_vacancy.sbb_approved || false,
    };
}

export function getInternshipVacancyPropertiesWithMatch(vacancy: InternshipVacancyApiParams): InternshipVacancyProperties {
    return {
        amount_of_employees: transformToPropertyWithMatch(vacancy.company.amount_of_employees, vacancy.match_details?.matching_attributes?.amount_of_employees),
        fields_of_study: vacancy.fields_of_study.map(fieldOfStudy => transformToPropertyWithMatch(fieldOfStudy, vacancy.match_details?.matching_attributes?.field_of_study)),
        skills: vacancy.skills.map(skill => transformToPropertyWithMatch(skill, vacancy.match_details?.matching_attributes?.skills)),
        skills_to_learn: vacancy.skills_to_learn.map(skillToLearn => transformToPropertyWithMatch(skillToLearn, vacancy.match_details?.matching_attributes?.skills_to_learn)),
        competencies: vacancy.competencies.map(competency => transformToPropertyWithMatch(competency, vacancy.match_details?.matching_attributes?.competencies)),
        competencies_to_learn: vacancy.competencies_to_learn.map(competencyToLearn => transformToPropertyWithMatch(competencyToLearn, vacancy.match_details?.matching_attributes?.competencies_to_learn)),
        grades: vacancy.internship_vacancy.grades.map(grade => transformToPropertyWithMatch(grade, vacancy.match_details?.matching_attributes?.grades)),
        compensation: transformApiCompensationToInternshipVacancyCompensation(vacancy.internship_vacancy.compensation),
        internship_type: transformToPropertyWithMatch(vacancy.internship_vacancy.internship_type, vacancy.match_details?.matching_attributes?.internship_types),
        education_levels: vacancy.education_levels.map(level => transformToPropertyWithMatch(level, vacancy.match_details?.matching_attributes?.education_levels)),
        esco_skills: transformToEscoSkillWithMatch(vacancy.esco_skills, vacancy.match_details?.matching_attributes?.esco_skills) || [],
        sector: vacancy.sector,
        sbb_approved: vacancy.internship_vacancy.sbb_approved || false,
    };
}

export function transformInternshipVacancy(vacancy: InternshipVacancyApiParams): InternshipVacancy {
    const properties = !vacancy.match_details ? getInternshipVacancyProperties(vacancy) : getInternshipVacancyPropertiesWithMatch(vacancy);
    const compensationValue = properties.compensation && properties.compensation.min.value;

    return {
        ...transformVacancy(vacancy),
        type: VacancyType.internship,
        properties,
        matching: vacancy.match_details?.match_percentage || undefined,
        property_groups: [
            [
                'education_levels',
                'internship_type',
                'travel_distance',
                ...(compensationValue && compensationValue !== '0') ? ['compensation'] : [],
                'amount_of_employees',
            ],
            [
                'grades',
            ],
            [
                'fields_of_study',
            ],
        ],
        period: vacancy.internship_vacancy.period,
    };
}

export function transformInternshipVacancyToNewestVacancy(resource: InternshipVacancyApiParams): NewestVacancy {
    const vacancy = transformLegacyVacancyToNewestVacancy(resource);
    const compensation = transformApiCompensationToInternshipVacancyCompensation(resource.internship_vacancy.compensation);

    return {
        ...vacancy,
        type: VacancyType.internship,
        compensation: {
            min: compensation?.min?.value ? parseInt(compensation?.min?.value, 10) : 0,
            max: compensation?.max?.value ? parseInt(compensation?.max?.value, 10) : 0,
        },
        properties: {
            ...vacancy.properties,
            compensation: compensation ? compensation.name : '',
            grades: resource.internship_vacancy.grades.map(grade => grade.name),
        },
    };
}
/* eslint-enable camelcase */
