import React from 'react';

import PropTypes from 'prop-types';

import { trans } from '../../../../helpers/trans';
import ButtonFooter from '../../../components/form/ButtonFooter';
import { Heading, Link, Paragraph } from '../../../styledComponents/components/Typography';

const BrowserNotSupported = ({ close }) => {
    const openChromeWindow = () => {
        if (typeof window !== 'undefined') {
            window.open(trans('basic.browserNotSupported.button.chromeLink'), '_blank');
        }
    };

    return (
        <>
            <Heading>
                {trans('basic.browserNotSupported.title')}
            </Heading>
            <Paragraph mb="lg">
                {trans('basic.browserNotSupported.paragraph.left')}&nbsp;
                <Link
                    href="https://www.google.com/chrome/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Chrome
                </Link>
                &nbsp;of&nbsp;
                <Link
                    href={trans('basic.browserNotSupported.button.safariLink')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Safari
                </Link>
                &nbsp;{trans('basic.browserNotSupported.paragraph.right')}
            </Paragraph>
            <ButtonFooter
                onCancel={close}
                cancelLabel={trans('actions.close')}
                onSubmit={() => openChromeWindow()}
                saveLabel={trans('basic.browserNotSupported.button.label')}
            />
        </>
    );
};

BrowserNotSupported.propTypes = {
    close: PropTypes.func.isRequired,
};

export default BrowserNotSupported;
