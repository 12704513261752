// TODO: Despite apparent refactor, this reducer/slice needs to be refactored, and removed from '_old' entirely.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ResetPassword } from '../../models/ResetPassword';

export interface ResetPasswordState {
    loading: boolean;
    step: number | null;
    completeStep: number | null;
    completePasswordForgetStep: number | null;
    resetPasswordData: ResetPassword | null;
    resetPasswordToken: string | null;
    errors: Record<string, unknown>;
}

const initialState: ResetPasswordState = {
    loading: false,
    step: null,
    completeStep: null,
    completePasswordForgetStep: null,
    resetPasswordData: null,
    resetPasswordToken: null,
    errors: {},
};

const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): ResetPasswordState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setStep(state, action: PayloadAction<number | null>): ResetPasswordState {
            return {
                ...state,
                step: action.payload,
            };
        },
        setCompleteStep(state, action: PayloadAction<number | null>) :ResetPasswordState {
            return {
                ...state,
                completeStep: action.payload,
            };
        },
        setCompletePasswordForgetStep(state, action: PayloadAction<number | null>) :ResetPasswordState {
            return {
                ...state,
                completePasswordForgetStep: action.payload,
            };
        },
        setResetPasswordData(state, action: PayloadAction<ResetPassword | null>): ResetPasswordState {
            return {
                ...state,
                resetPasswordData: action.payload,
            };
        },
        setResetPasswordToken(state, action: PayloadAction<string | null>): ResetPasswordState {
            return {
                ...state,
                resetPasswordToken: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<Record<string, unknown>>): ResetPasswordState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setStep,
    setCompleteStep,
    setCompletePasswordForgetStep,
    setResetPasswordData,
    setResetPasswordToken,
    setErrors,
} = resetPasswordSlice.actions;

export default resetPasswordSlice;
