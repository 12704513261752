import { BreadcrumbList, JobPosting, WithContext } from 'schema-dts';

import { VacancyType } from '../../../../../../_old/app_talentz/models/VacancyType';
import ucFirst from '../../../../../../_old/helpers/ucFirst';
import { fallbackAvatar, fulltimeThreshold, siteUrl } from '../../../../../../constants';
import { trans } from '../../../../../../helpers/trans';
import { Company } from '../../../../../../models/Company';
import { NewestVacancy } from '../../../../../../models/NewestVacancy';
import { RoutePaths } from '../../../../../../routes';

enum WorkRemoteName {
    none = 'Nee, niet thuis',
    fully = 'Ja, volledig thuis',
    partly = 'Ja, gedeeltelijk thuis',
}

const getVacancyEmploymentType = (vacancy: NewestVacancy): string => {
    if (vacancy.employmentHourRange.min >= fulltimeThreshold) {
        return 'FULL_TIME';
    }

    return 'PART_TIME';
};

export const generateStructuredJobPosting = (vacancy: NewestVacancy, company: Company): WithContext<JobPosting> => {
    const vacancyTitle = ucFirst(vacancy.title);
    const vacancyAddress = vacancy.address || company.address;

    const workFullyRemote = vacancy.properties.workingRemoteName === WorkRemoteName.fully;

    return {
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        title: vacancyTitle,
        description: vacancy.description,
        identifier: {
            '@type': 'PropertyValue',
            name: company.name,
            value: company.name,
        },
        datePosted: new Date(vacancy.publicationDate).toISOString(),
        validThrough: new Date(vacancy.expirationDate).toISOString(),
        employmentType: vacancy.type === VacancyType.job
            ? getVacancyEmploymentType(vacancy)
            : 'INTERN',
        hiringOrganization: {
            '@type': 'Organization',
            name: company.name,
            sameAs: company.website,
            logo: company.logo || fallbackAvatar,
        },
        jobLocation: {
            '@type': 'Place',
            address: {
                '@type': 'PostalAddress',
                streetAddress: `${vacancyAddress.street} ${vacancyAddress.houseNumber}`,
                addressLocality: vacancyAddress.city,
                addressRegion: vacancyAddress.province,
                postalCode: vacancyAddress.postalCode,
                addressCountry: 'NL',
            },
        },
        applicantLocationRequirements: {
            '@type': 'Country',
            sameAs: 'https://www.wikidata.org/wiki/Q55',
            name: 'Netherlands',
        },
        jobLocationType: workFullyRemote
            ? 'TELECOMMUTE'
            : '',
        baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'EUR',
            value: vacancy.grossHourlyWageInCents.max ? {
                '@type': 'QuantitativeValue',
                minValue: vacancy.grossHourlyWageInCents.min / 100,
                maxValue: vacancy.grossHourlyWageInCents.max / 100,
                unitText: 'HOUR',
            } : {
                '@type': 'QuantitativeValue',
                minValue: vacancy.compensation.min,
                maxValue: vacancy.compensation.max,
                unitText: 'MONTH',
            },
        },
    };
};

export const generateStructuredBreadcrumbs = (vacancy: NewestVacancy): WithContext<BreadcrumbList>[] => {
    const vacancyOverviewLabel = vacancy.type === VacancyType.internship
        ? trans('navigation.items.internshipVacancies')
        : trans('navigation.items.jobVacancies');

    const vacancyOverviewPage = vacancy.type === VacancyType.internship
        ? RoutePaths.internships()
        : RoutePaths.jobs();

    const vacancyDetailPage = vacancy.type === VacancyType.internship
        ? RoutePaths.internshipDetail(vacancy.uuid)
        : RoutePaths.jobDetail(vacancy.uuid);

    const companyProfilePage = RoutePaths.companyProfile(vacancy.company.uuid);

    return [
        {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [{
                '@type': 'ListItem',
                position: 1,
                name: vacancyOverviewLabel,
                item: `${siteUrl}/${vacancyOverviewPage}`,
            }, {
                '@type': 'ListItem',
                position: 2,
                name: vacancy.title,
                item: `${siteUrl}/${vacancyDetailPage}`,
            }],
        },
        {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [{
                '@type': 'ListItem',
                position: 1,
                name: vacancy.company.name,
                item: `${siteUrl}/${companyProfilePage}`,
            }, {
                '@type': 'ListItem',
                position: 2,
                name: vacancy.title,
                item: `${siteUrl}/${vacancyDetailPage}`,
            }],
        },
    ];
};
