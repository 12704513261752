import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton } from '../../../../../components';
import { IconButton, Modal, ModalContent } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { EmploymentPreferenceFormData } from '../../../../../models/Employment';
import { MinMaxValue } from '../../../../../types';
import { EmploymentPreferenceForm } from '../../../..';
import { ActivationRef } from '../../../ProfileProgress/ProfileProgress';
import { CandidatePreferencesCardSkeletons } from '../../skeletons';

import './EmploymentPreferences.scss';

interface EmploymentPreferencesProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    employmentPreference: MinMaxValue;
    onEdit: (employmentPreferenceFormData: EmploymentPreferenceFormData) => void;
    className?: string;
}

const EmploymentPreferences: FC<EmploymentPreferencesProps> = ({
    activationRef,
    isLoading,
    isEditable,
    employmentPreference,
    onEdit,
    className = '',
}): ReactElement => {
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

    const openEditModal = (): void => setEditModalIsOpen(true);
    const closeEditModal = (): void => setEditModalIsOpen(false);

    const hasEmploymentPreference = employmentPreference.max > 0;

    const handleEditEmploymentPreference = (employmentPreferenceFormData: EmploymentPreferenceFormData): void => {
        onEdit(employmentPreferenceFormData);
        closeEditModal();
    };

    return (
        <div className={`employment-preferences ${className}`}>
            <ProgressRefButton ref={activationRef} onClick={openEditModal} />

            <h3 className="employment-preferences__title">
                {trans('candidateProfile.employmentPreferences.title')}
            </h3>

            {isLoading && (
                <CandidatePreferencesCardSkeletons
                    amount={1}
                    className="employment-preferences__skeletons"
                />
            )}

            {!isLoading && !hasEmploymentPreference && (
                <div className="employment-preferences__null-state">
                    <p>{trans('candidateProfile.employmentPreferences.nullState')}</p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('userProfile.content.add')}
                            disabled={isLoading}
                            onClick={openEditModal}
                            className="employment-preferences__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && hasEmploymentPreference && (
                <p className="employment-preferences__amount-of-hours">
                    {employmentPreference.min === employmentPreference.max ? (
                        trans('candidateProfile.employmentPreferences.fixedAmountOfHours', {
                            amount: String(employmentPreference.min),
                        })
                    ) : (
                        trans('candidateProfile.employmentPreferences.amountOfHours', {
                            min: String(employmentPreference.min),
                            max: String(employmentPreference.max),
                        })
                    )}
                </p>
            )}

            {isEditable && hasEmploymentPreference && (
                <IconButton
                    icon="pencil"
                    text={trans('actions.edit')}
                    hideLabel
                    disabled={isLoading}
                    onClick={openEditModal}
                    className="employment-preferences__edit-button"
                />
            )}

            {editModalIsOpen && (
                <Modal onClose={closeEditModal}>
                    <ModalContent title={trans('candidateProfile.employmentPreferences.modal.title')}>
                        <EmploymentPreferenceForm
                            employmentPreference={employmentPreference}
                            onCancel={closeEditModal}
                            onSubmit={handleEditEmploymentPreference}
                        />
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default EmploymentPreferences;
