import { FC, ReactElement, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { LEGACY_TOKEN_KEY } from '../../../../helpers/authorizedFetch';
import { hotjarEvent } from '../../../../helpers/hotjar';
import { RoleType } from '../../../../models/User';
import { RoutePaths } from '../../../../routes';
import { useLegacyDispatch, useLegacySelector } from '../../../../store';
import getQueryParam from '../../../helpers/getQueryParam';
import SessionStorageType from '../../constants/SessionStorageType';
import {
    authenticateLinkedInUser,
    authenticateLLOUser,
    getUserWithAccessToken,
    setUserToken,
} from '../../store/legacyUser/legacyUserActions';
import { setActivationToken } from '../../store/register/register';
import { setResetPasswordToken } from '../../store/resetPassword/resetPassword';

interface AuthenticationProps {
    children: (isLoading: boolean, userRole: RoleType) => ReactElement;
}

const Authentication: FC<AuthenticationProps> = ({
    children,
}): ReactElement => {
    const legacyDispatch = useLegacyDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as { type?: string; from?: string };
    const [localStorageToken] = useLocalStorage(LEGACY_TOKEN_KEY);

    const userIsLoading = useLegacySelector(state => state.legacyUser.loading);
    const logoutIsLoading = useLegacySelector(state => state.legacyUser.logoutLoading);
    const socialMediaIsLoading = useLegacySelector(state => state.register.loadingSocialMedia);
    const socialMediaLoginIsLoading = useLegacySelector(state => state.legacyUser.loadingSocialMedia);

    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const storeTokenData = useLegacySelector(state => state.legacyUser.tokenData);
    const personIsEmpty = useLegacySelector(state => state.userPerson.isEmpty);
    const userRole = useLegacySelector(state => state.legacyUser.role);

    const checkForLLOLoginCode = (): void => {
        const isLoggingIn = !!getQueryParam('llo-login', location.search);
        const code = getQueryParam('code', location.search);
        const sessionState = getQueryParam('session_state', location.search);

        if (isLoggingIn && code) {
            legacyDispatch(authenticateLLOUser(code, sessionState, navigate));
        }
    };

    const checkForLinkedInLoginCode = (): void => {
        const isLoggingIn = !!getQueryParam('linkedin-login', location.search);
        const code = getQueryParam('code', location.search);

        if (isLoggingIn && code) {
            legacyDispatch(authenticateLinkedInUser(code, navigate));
        }
    };

    const checkForActivationToken = (): void => {
        const token = getQueryParam('activation-token', location.search);

        if (token) {
            legacyDispatch(setActivationToken(token));

            hotjarEvent('trigger', 'register_activation');

            navigate(RoutePaths.registration(), {
                state: { token },
            });
        }
    };

    const checkForPasswordResetToken = (): void => {
        const token = getQueryParam('password-token', location.search);

        if (token) {
            legacyDispatch(setResetPasswordToken(token));

            navigate(RoutePaths.altResetPassword(), {
                state: { token },
            });
        }
    };

    const checkForOauthTokenDataAndGetUser = (): void => {
        if (localStorageToken) {
            legacyDispatch(setUserToken(localStorageToken));
            legacyDispatch(getUserWithAccessToken());
        }
    };

    useEffect((): void => {
        checkForLLOLoginCode();
        checkForLinkedInLoginCode();
        checkForActivationToken();
        checkForPasswordResetToken();
        checkForOauthTokenDataAndGetUser();
    }, []);

    useEffect((): void => {
        if (!localStorageToken && !personIsEmpty) {
            // Redirect the user after login to its last known location
            const redirectUri = sessionStorage.getItem(SessionStorageType.RedirectUri);
            sessionStorage.removeItem(SessionStorageType.RedirectUri);

            if (redirectUri && redirectUri !== RoutePaths.home()) {
                if (redirectUri !== location.pathname) {
                    navigate(redirectUri);
                }

                return;
            }

            const previousLocation = locationState?.type === 'unauthenticated' && locationState.from;

            // Just redirect to the standard pages
            if (userRole === RoleType.student) {
                navigate(previousLocation || RoutePaths.internships());
            }

            if (userRole === RoleType.jobSeeker) {
                navigate(previousLocation || RoutePaths.jobs());
            }

            if (userRole === RoleType.company) {
                navigate(previousLocation || RoutePaths.companyOverview());
            }
        }
    }, [localStorageToken, personIsEmpty]);

    const isLoading = [
        userIsLoading,
        socialMediaIsLoading,
        socialMediaLoginIsLoading,
        logoutIsLoading,
    ].some(Boolean);

    let role = RoleType.none;

    if (!isLoading && storeTokenData && isAuthenticated) {
        role = userRole ?? RoleType.none;
    }

    return children(isLoading, role);
};

export default Authentication;
