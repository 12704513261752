import React, { FC, ReactElement } from 'react';

import { Button } from '../../../components';
import { trans } from '../../../helpers/trans';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { ButtonList, Disclaimer, IconButton } from '../..';

import './VacancyDeleteModal.scss';

interface VacancyDeleteModalProps {
    vacancy: NewestVacancy;
    onCancel: () => void;
    onConfirm: () => void;
    className?: string;
}

const VacancyDeleteModal: FC<VacancyDeleteModalProps> = ({
    vacancy,
    onCancel,
    onConfirm,
    className = '',
}): ReactElement => (
    <div className={`vacancy-delete-modal ${className}`}>
        <p className="vacancy-delete-modal__description">
            {trans('myVacancies.modal.delete.description', {
                name: vacancy.title,
            })}
        </p>

        <Disclaimer
            isSerious
            title={trans('myVacancies.modal.delete.disclaimer.title')}
            description={trans('myVacancies.modal.delete.disclaimer.description')}
            className="vacancy-delete-modal__disclaimer"
        />

        <ButtonList className="vacancy-delete-modal__button-list">
            <IconButton
                icon="delete"
                text={trans('actions.delete')}
                onClick={onConfirm}
                className="vacancy-delete-modal__delete-button"
            />
            <Button
                text={trans('actions.cancel')}
                onClick={onCancel}
                className="vacancy-delete-modal__cancel-button"
            />
        </ButtonList>
    </div>
);

export default VacancyDeleteModal;
