import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../_old/app_talentz/models/VacancyType';
import { trans } from '../../../helpers/trans';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import { Application, ApplicationFormData, ApplicationVacancyIds } from '../../../models/Applications';
import { Candidate } from '../../../models/Candidate';
import { Company } from '../../../models/Company';
import { VacanciesInfo } from '../../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../../models/NewestVacancy';
import { RoleType } from '../../../models/User';
import {
    RelatedVacancies,
    VacancyApplyAnonymousCard,
    VacancyApplyCard,
    VacancyAuthenticationCard,
    VacancyAwaitingResponseCard,
    VacancyCompanyCard,
    VacancyCompleteRegistrationCard,
    VacancyDescriptionCard,
    VacancyMetaCard,
    VacancyPropertiesCard,
    VacancyRecruitmentCard,
    VacancyShareCard,
    VacancyToHaveCard,
    VacancyToLearnCard,
} from '../..';
import { VacancyBreadcrumbs, VacancyHeader, VacancyHelmet } from './subcomponents';

import './VacancyDetail.scss';

interface VacancyDetailProps {
    isLoading: boolean;
    authIsLoading: boolean;
    isValidUser: boolean;
    isSbbUser: boolean;
    candidate?: Candidate;
    userRole: RoleType;
    vacancy?: NewestVacancy;
    company?: Company;
    userFromCompany: boolean;
    //
    relatedVacancies: NewestVacancy[];
    relatedVacanciesIsLoading: boolean;
    relatedVacanciesError: string;
    //
    candidateApplications: ApplicationVacancyIds[];
    applicationFormIsLoading: boolean;
    applicationFormSentSuccessfully: boolean;
    onSubmitApplication: (applicationFormData: ApplicationFormData) => void;
    //
    favouriteIsLoading: boolean;
    isFavourite?: boolean;
    favouriteIds: string[];
    onFavouriteVacancy: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    //
    archiveApplications?: Application[];
    archiveApplicationsIsLoading?: boolean;
    onFetchArchiveApplications?: (vacancyUuid: string) => void;
    onClearArchiveApplications?: () => void;
    onArchiveVacancy?: (vacancyUuid: string, applicationUuids: string[]) => void;
    onUnarchiveVacancy?: (vacancyUuid: string) => void;
    onDeleteVacancy?: (vacancyUuid: string) => void;
    //
    onRegistrationClick: (userType: RoleType) => void;
    onLoginClick: () => void;
    className?: string;
}

const VacancyDetail: FC<VacancyDetailProps> = ({
    isLoading,
    authIsLoading,
    isValidUser,
    isSbbUser,
    candidate,
    userRole,
    userFromCompany,
    vacancy,
    company,
    //
    relatedVacancies,
    relatedVacanciesIsLoading,
    relatedVacanciesError,
    //
    candidateApplications,
    applicationFormIsLoading,
    applicationFormSentSuccessfully,
    onSubmitApplication,
    //
    isFavourite,
    favouriteIds,
    favouriteIsLoading,
    onFavouriteVacancy,
    //
    archiveApplications,
    archiveApplicationsIsLoading,
    onFetchArchiveApplications,
    onClearArchiveApplications,
    onArchiveVacancy,
    onUnarchiveVacancy,
    onDeleteVacancy,
    //
    onRegistrationClick,
    onLoginClick,
    className = '',
}): ReactElement => {
    const { isTabletPortrait, isMobile } = useDeviceWidth();
    const isSmallScreen = isTabletPortrait || isMobile;

    const vacancyHasInternshipType = vacancy?.type === VacancyType.internship;
    const vacancyIsAnonymous = !!vacancy?.isAnonymous;

    const candidateApplication = vacancy && candidateApplications.find(application => application.vacancyUuid === vacancy.uuid);

    const userIsCandidate = userRole === RoleType.student || userRole === RoleType.jobSeeker;
    const userIsValidCandidate = userIsCandidate && isValidUser;
    const userIsInvalidCandidate = userIsCandidate && !isValidUser;
    const userIsUnauthenticated = userRole === RoleType.none;
    const userIsAwaitingResponse = userIsCandidate && !!candidateApplication;
    const userMayApply = !userIsAwaitingResponse && userIsValidCandidate;

    return (
        <div className={`vacancy-detail ${className}`}>
            {vacancy && company && (
                <VacancyHelmet vacancy={vacancy} company={company} />
            )}

            <VacancyHeader
                isLoading={isLoading}
                userRole={userRole}
                userFromCompany={userFromCompany}
                company={company}
                vacancy={vacancy}
                isFavourite={isFavourite}
                favouriteIsLoading={favouriteIsLoading}
                onFavouriteVacancy={onFavouriteVacancy}
                applications={archiveApplications}
                applicationsIsLoading={archiveApplicationsIsLoading}
                onFetchApplications={onFetchArchiveApplications}
                onClearApplications={onClearArchiveApplications}
                onArchiveVacancy={onArchiveVacancy}
                onUnarchiveVacancy={onUnarchiveVacancy}
                onDeleteVacancy={onDeleteVacancy}
            />

            <div className="vacancy-detail__breadcrumb-wrapper">
                <VacancyBreadcrumbs
                    isLoading={isLoading}
                    vacancy={vacancy}
                />
            </div>

            <div className="vacancy-detail__content-wrapper">
                <main className="vacancy-detail__main">
                    <VacancyDescriptionCard
                        isLoading={isLoading}
                        vacancy={vacancy}
                        className="vacancy-detail__card"
                    />

                    <VacancyToHaveCard
                        isLoading={isLoading}
                        candidate={candidate}
                        competencies={vacancy?.competencies}
                        skills={vacancy?.skills}
                        className="vacancy-detail__card"
                    />

                    {vacancyHasInternshipType && (
                        <VacancyToLearnCard
                            isLoading={isLoading}
                            candidate={candidate}
                            competencies={vacancy?.competenciesToLearn}
                            skills={vacancy?.skillsToLearn}
                            className="vacancy-detail__card"
                        />
                    )}

                    <VacancyMetaCard
                        isLoading={isLoading}
                        favourites={vacancy?.meta.favourites}
                        views={vacancy?.meta.views}
                        publicationDate={vacancy?.publicationDate}
                        className="vacancy-detail__card"
                    />

                    {!isSmallScreen && (
                        <RelatedVacancies
                            isLoading={relatedVacanciesIsLoading}
                            userRole={userRole}
                            title={trans('vacancyDetail.relatedVacancies.title')}
                            vacancies={relatedVacancies}
                            error={relatedVacanciesError}
                            favouriteIsLoading={favouriteIsLoading}
                            favouriteIds={favouriteIds}
                            onFavouriteVacancy={onFavouriteVacancy}
                            className="vacancy-detail__vacancy-overview"
                        />
                    )}
                </main>

                <aside className="vacancy-detail__sidebar">
                    {userFromCompany && (
                        <VacancyRecruitmentCard
                            isLoading={isLoading}
                            vacancy={vacancy}
                            className="vacancy-detail__card"
                        />
                    )}

                    {vacancyIsAnonymous && vacancy && (
                        <VacancyApplyAnonymousCard
                            userRole={userRole}
                            vacancy={vacancy}
                            className="vacancy-detail__card"
                        />
                    )}

                    {userMayApply && vacancy && candidate && (
                        <VacancyApplyCard
                            isLoading={isLoading}
                            isAnonymous={vacancyIsAnonymous}
                            vacancy={vacancy}
                            candidate={candidate}
                            formIsLoading={applicationFormIsLoading}
                            formSentSuccessfully={applicationFormSentSuccessfully}
                            onSubmitApplication={onSubmitApplication}
                            className="vacancy-detail__card"
                        />
                    )}

                    {!authIsLoading && userIsInvalidCandidate && (
                        <VacancyCompleteRegistrationCard
                            className="vacancy-detail__card"
                        />
                    )}

                    {userIsUnauthenticated && vacancy && (
                        <VacancyAuthenticationCard
                            vacancy={vacancy}
                            onRegistrationClick={onRegistrationClick}
                            onLoginClick={onLoginClick}
                            className="vacancy-detail__card"
                        />
                    )}

                    {userIsAwaitingResponse && candidateApplication && vacancy && (
                        <VacancyAwaitingResponseCard
                            applicationUuid={candidateApplication.applicationUuid}
                            vacancy={vacancy}
                            className="vacancy-detail__card"
                        />
                    )}

                    <VacancyPropertiesCard
                        isLoading={isLoading}
                        isSbbUser={isSbbUser}
                        properties={vacancy?.properties}
                        className="vacancy-detail__card"
                    />

                    {!userFromCompany && (
                        <VacancyCompanyCard
                            isLoading={isLoading}
                            company={company}
                            className="vacancy-detail__card"
                        />
                    )}

                    <VacancyShareCard
                        userRole={userRole}
                        vacancy={vacancy}
                        isFavourite={isFavourite}
                        favouriteIsLoading={favouriteIsLoading}
                        onFavouriteVacancy={onFavouriteVacancy}
                        className="vacancy-detail__card"
                    />
                </aside>

                {isSmallScreen && (
                    <RelatedVacancies
                        isLoading={relatedVacanciesIsLoading}
                        userRole={userRole}
                        title={trans('vacancyDetail.relatedVacancies.title')}
                        vacancies={relatedVacancies}
                        error={relatedVacanciesError}
                        favouriteIsLoading={favouriteIsLoading}
                        favouriteIds={favouriteIds}
                        onFavouriteVacancy={onFavouriteVacancy}
                        className="vacancy-detail__vacancy-overview"
                    />
                )}
            </div>
        </div>
    );
};

export default VacancyDetail;
