import React, { CSSProperties, FC, ReactElement } from 'react';

import { RootPortal } from '../../components';
import { Toast } from '../../compositions';
import { Toast as ToastModel, ToastPosition } from '../../models/Toast';

import './ToastList.scss';

interface ToastListProps {
    toasts: ToastModel[];
    position?: ToastPosition;
    onToastClose: (toastId: string) => void;
    className?: string;
}

const ToastList: FC<ToastListProps> = ({
    toasts,
    position = ToastPosition.topRight,
    onToastClose,
    className = '',
}): ReactElement => {
    const positionStyle = {
        top: position.startsWith('top-') && 0,
        bottom: position.startsWith('bottom-') && 0,
        left: position.endsWith('-left') && 0,
        right: position.endsWith('-right') && 0,
    } as CSSProperties;

    return (
        <RootPortal>
            <ul style={positionStyle} className={`toast-list ${className}`}>
                {toasts.map(toast => {
                    const handleClose = (): void => onToastClose(toast.id);

                    return (
                        <li key={toast.id} className="toast-list__item">
                            <Toast
                                {...toast}
                                isListItem
                                position={position}
                                onClose={handleClose}
                                className="toast-list__toast"
                            />
                        </li>
                    );
                })}
            </ul>
        </RootPortal>
    );
};

export default ToastList;
