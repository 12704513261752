import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getCompanyApiCall } from '../../services/CompanyService';
import { TypedDispatch } from '..';
import { setCompany, setError, setIsLoading } from './user';

export const fetchUserCompany = (uuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getCompanyApiCall(uuid);

        if (!isFetchResultSuccessful(response)) {
            console.error('[fetchUserCompany]', response.error);
            dispatch(setError(response.error));
            return;
        }

        dispatch(setCompany(response.data));
    } catch (error) {
        console.error('[fetchUserCompany]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
