// TODO: Despite apparent refactor, this reducer/slice needs to be refactored, and removed from '_old' entirely.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    loading: boolean;
    appUpdates: unknown[];
    errors: unknown;
}

const initialState: AppState = {
    loading: false,
    appUpdates: [],
    errors: null,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppUpdates(state, action: PayloadAction<unknown[]>): AppState {
            return {
                ...state,
                appUpdates: action.payload,
            };
        },
        setErrors(state, action: PayloadAction<unknown>): AppState {
            return {
                ...state,
                errors: action.payload,
            };
        },
    },
});

export const {
    setAppUpdates,
    setErrors,
} = appSlice.actions;

export default appSlice;
