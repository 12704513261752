import React, {
    ChangeEvent,
    DragEvent,
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useEffectOnce } from 'react-use';

import { Icon, ProgressRefButton } from '../../../components';
import { IconButton, Modal, ModalContent } from '../../../compositions';
import { getImageFileMeta } from '../../../helpers/file';
import { trans } from '../../../helpers/trans';
import { FileFormData } from '../../../models/File';
import { ImageValidationOptions } from '../../../models/ImageMeta';
import { ActivationRef } from '../../@profile/ProfileProgress/ProfileProgress';
import { ImageCropper } from './subcomponents';
import { validateImageMetaFormData } from './validations';

import './ImageDropForm.scss';

interface ImageDropFormProps {
    activationRef?: ActivationRef;
    deleteButtonRef?: ActivationRef;
    hasControls?: boolean;
    shouldCrop?: boolean;
    hasCropBoundaries?: boolean;
    label: string;
    value: string;
    acceptedFileTypes?: string[];
    cropAspectRatio?: number;
    validationOptions?: ImageValidationOptions;
    renderOptions?: Cropper.GetCroppedCanvasOptions;
    onSubmit: (fileDropFormData: FileFormData) => void;
    onError?: (error: string) => void;
    className?: string;
}

const ImageDropForm: FC<ImageDropFormProps> = ({
    activationRef,
    deleteButtonRef,
    hasControls,
    shouldCrop,
    hasCropBoundaries,
    label,
    value,
    acceptedFileTypes = ['image/jpeg', 'image/png'],
    cropAspectRatio,
    validationOptions,
    renderOptions,
    onSubmit,
    onError,
    className = '',
}): ReactElement => {
    const [imageSrc, setImageSrc] = useState<string>('');
    const [isDragging, setIsDragging] = useState<boolean>(false);

    // Rerender state for input when user changes/deletes input, so the same image can be uploaded again
    const [updateCount, setUpdateCount] = useState<number>(1);

    const [cropModalIsOpen, setCropModalIsOpen] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const openCropModal = (file: File): void => {
        const url = URL.createObjectURL(file);

        setImageSrc(url);
        setCropModalIsOpen(true);
    };

    const closeCropModal = (): void => setCropModalIsOpen(false);

    const handleFileUpload = async (fileList?: FileList | null): Promise<void> => {
        setUpdateCount(updateCount + 1);

        if (fileList && fileList.length) {
            const file = fileList[0];

            if (acceptedFileTypes.includes(file.type)) {
                const formData = await getImageFileMeta(file);

                const [errors, hasErrors] = validateImageMetaFormData(formData, validationOptions);

                if (hasErrors) {
                    if (onError) {
                        const errorValues = Object.values(errors).filter(Boolean);
                        onError(errorValues[0] || '');
                    }

                    return;
                }

                if (onError) onError('');

                if (shouldCrop) {
                    openCropModal(file);
                } else {
                    onSubmit({ file });
                }
            } else {
                const accepted = acceptedFileTypes.join(', ');

                if (onError) {
                    onError(trans('errors.fileNotAccepted', { accepted }));
                }
            }
        }
    };

    const handleEditClick = (): void => {
        if (inputRef.current) inputRef.current.click();
    };

    const handleDeleteClick = (): void => {
        setUpdateCount(updateCount + 1);

        setImageSrc(value);
        onSubmit({ file: undefined });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        handleFileUpload(event.currentTarget.files);
    };

    const handleDragEnter = (event: DragEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setIsDragging(false);

        handleFileUpload(event.dataTransfer.files);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => event.preventDefault();

    const dropIndicatorClassNames = classNames('image-drop-form__drop-indicator', {
        'image-drop-form__drop-indicator--is-visible': !value || isDragging,
    });

    useEffectOnce((): () => void => {
        document.addEventListener('dragover', () => setIsDragging(true));
        document.addEventListener('dragleave', () => setIsDragging(false));

        return (): void => {
            document.removeEventListener('dragover', () => setIsDragging(true));
            document.removeEventListener('dragleave', () => setIsDragging(false));
        };
    });

    return (
        <form
            onSubmit={handleSubmit}
            onDragOver={handleDragEnter}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragLeave}
            onDrop={handleDrop}
            className={`image-drop-form ${className}`}
        >
            <ProgressRefButton ref={activationRef} onClick={handleEditClick} />
            <ProgressRefButton ref={deleteButtonRef} onClick={handleDeleteClick} />

            <div className={dropIndicatorClassNames}>
                <Icon name="camera-add" className="image-drop-form__drop-indicator-icon" />
            </div>

            {value && (
                <div className="image-drop-form__image-wrapper">
                    <img
                        src={value}
                        alt={label}
                        className="image-drop-form__image"
                    />
                </div>
            )}

            <label className="image-drop-form__label">
                <input
                    key={updateCount}
                    ref={inputRef}
                    type="file"
                    accept={acceptedFileTypes.join(',')}
                    onChange={handleChange}
                    className="image-drop-form__input"
                />
            </label>

            {hasControls && (
                <ul className="image-drop-form__control-list">
                    <li className="image-drop-form__control-item">
                        <IconButton
                            icon="camera"
                            hideLabel
                            text={trans('actions.edit')}
                            onClick={handleEditClick}
                            className="image-drop-form__control-button"
                        />
                    </li>
                    <li className="image-drop-form__control-item">
                        <IconButton
                            icon="delete"
                            hideLabel
                            text={trans('actions.delete')}
                            disabled={!value}
                            onClick={handleDeleteClick}
                            className="image-drop-form__control-button"
                        />
                    </li>
                </ul>
            )}

            {cropModalIsOpen && (
                <Modal onClose={closeCropModal} cardClassName="image-drop-form__crop-modal-card">
                    <ModalContent title={trans('forms.imageDrop.crop.title')}>
                        <p className="image-drop-form__crop-modal-instructions">
                            {trans('forms.imageDrop.crop.instructions')}
                        </p>

                        <ImageCropper
                            hasBoundaries={hasCropBoundaries}
                            src={imageSrc}
                            aspectRatio={cropAspectRatio}
                            validationOptions={validationOptions}
                            renderOptions={renderOptions}
                            onCancel={closeCropModal}
                            onSubmit={onSubmit}
                            className="image-drop-form__cropper"
                        />
                    </ModalContent>
                </Modal>
            )}
        </form>
    );
};

export default ImageDropForm;
