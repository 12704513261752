import React, { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel, Radio } from '../../../components';
import { otherFormOptionKey } from '../../../constants';
import { trans } from '../../../helpers/trans';
import { FormOption } from '../../../types';

import './RadioList.scss';

interface RadioListProps {
    maySelectOther?: boolean;
    otherOptionLabel?: string;
    id?: string;
    label: string;
    hideLabel?: boolean;
    name: string;
    options: FormOption[];
    required?: boolean;
    optional?: boolean;
    value: string;
    tabIndex?: number;
    disabled?: boolean;
    error?: string;
    onChange: (value: string) => void;
    className?: string;
}

const RadioList: FC<RadioListProps> = ({
    maySelectOther,
    otherOptionLabel = trans('basic.radio.other'),
    id,
    label,
    hideLabel = false,
    required = false,
    optional = false,
    name,
    options,
    value,
    tabIndex,
    disabled,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const handleOnRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const radioListClassNames = classNames('radio-list', {
        'radio-list--has-error': !!error,
    }, className);

    const otherOption = {
        label: otherOptionLabel,
        value: otherFormOptionKey,
    };

    return (
        <label
            id={id}
            aria-label={hideLabel ? label : undefined}
            className={radioListClassNames}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    optional={optional}
                    className="radio-list__label"
                />
            )}

            {options.map(option => (
                <Radio
                    key={option.value}
                    name={name}
                    option={option}
                    selected={value}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    onChange={handleOnRadioChange}
                    className="radio-list__item"
                />
            ))}

            {maySelectOther && (
                <Radio
                    key={otherFormOptionKey}
                    name={name}
                    option={otherOption}
                    selected={value}
                    tabIndex={tabIndex}
                    onChange={handleOnRadioChange}
                    className="radio-list__item"
                />
            )}

            {error && (
                <ErrorLabel text={error} className="radio-list__error" />
            )}
        </label>
    );
};

export default RadioList;
