import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { ApplicationAppointmentsResource, transformToApplicationAppointment } from '../models/ApplicationAppointments';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getApplicationAppointmentApiCall = async (appointmentUuid: string): Promise<FetchResult<any, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/application-appointments/${appointmentUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ApplicationAppointmentsResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const applicationAppointment = transformToApplicationAppointment(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: applicationAppointment,
        };
    } catch (error) {
        console.error('[getApplicationAppointmentApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
