import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconApplicant: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 55.4 100" className={`icon ${className}`}>
        <path d="M40.9,30.1l8.8-16L40.9,0H15.8L6.6,14.1l9.2,16L0,76.3L27.8,100l27.6-23.7L40.9,30.1z M22.4,9.3h11.3l4,6.3l-4,7.2H22.4l-4.1-7.2L22.4,9.3z M13,73l12.3-40.3H31L42.3,73L27.8,86.6L13,73z" />
    </svg>
);

export default IconApplicant;
