import axios from 'axios';
import { Dispatch } from 'redux';

import { trans } from '../../../../helpers/trans';
import { LegacyReducers } from '../../../../store/reducers';
import { addNegativeToast, addPositiveToast } from '../../../../store/toast/toastActions';
import { checkForWebPushSubscription } from '../notifications/notificationsActions';
import { setData, setErrors, setLoading } from './userPersonSettings';

export function getPersonSettings() {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userPerson } = getState();

        try {
            if (!userPerson.data?.person_settings_id) {
                throw new Error('Person has no person_settings_id');
            }

            const response = await axios.get(`/person-settings/${userPerson.data.person_settings_id}`);

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            return Promise.resolve(response.data.data);
        } catch (error) {
            console.error('[getPersonSettings]', error);

            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            return Promise.reject(error);
        }
    };
}

export function updatePersonSettings(data: any) {
    return async (dispatch: Dispatch, getState: () => LegacyReducers) => {
        dispatch(setLoading(true));

        const { userPerson } = getState();

        try {
            if (!userPerson?.data?.person_settings_id) {
                throw new Error('Person has no person_settings_id');
            }

            const response = await axios.patch(`/person-settings/${userPerson.data.person_settings_id}`, data);

            if (response.data.data.notifications) {
                checkForWebPushSubscription();
            }

            dispatch(setData(response.data.data));
            dispatch(setLoading(false));

            // @ts-ignore
            dispatch(addPositiveToast({
                title: trans('basic.toast.success'),
                description: trans('candidateProfile.toast.savedChanges.success'),
            }));

            return Promise.resolve(response.data.data);
        } catch (error) {
            dispatch(setErrors(error as Record<string, unknown>));
            dispatch(setLoading(false));

            // @ts-ignore
            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
            }));

            return Promise.reject(error);
        }
    };
}
