import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Link } from 'react-router-dom';

import { Person } from '../../_old/app_talentz/models/Person';
import { MenuItem } from '../../connectors/ConnectedMenu/helpers';
import { trans } from '../../helpers/trans';
import { Company } from '../../models/Company';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import {
    CompleteRegistrationToast,
    DesktopNavigation,
    EnvironmentLogo,
    MobileNavigation,
} from './subcomponents';

import './Menu.scss';

interface MenuProps {
    authIsLoading: boolean;
    isValidUser: boolean;
    showMobileMenu: boolean;
    role: RoleType;
    person?: Person;
    company?: Company;
    mainItems: MenuItem[];
    informationItems: MenuItem[];
    extraInformationItems: MenuItem[];
    iconItems: MenuItem[];
    profileItems: MenuItem[];
    sessionItems: MenuItem[];
    mobileShortcutItem: MenuItem;
    className?: string;
}

const Menu: FC<MenuProps> = ({
    authIsLoading,
    isValidUser,
    showMobileMenu,
    role,
    person,
    company,
    mainItems,
    informationItems,
    extraInformationItems,
    iconItems,
    profileItems,
    sessionItems,
    mobileShortcutItem,
    className = '',
}): ReactElement => {
    const [showCompleteRegistrationToast, setShowCompleteRegistrationToast] = useState<boolean>(false);

    useEffect((): void => {
        const incompleteProfile = !isValidUser && role !== RoleType.none;
        const isRegistrationPage = window?.location
            ? window.location.pathname.includes('/registreren')
            : false;

        setShowCompleteRegistrationToast(incompleteProfile && !isRegistrationPage);
    }, [isValidUser, role]);

    return (
        <section className={`menu ${className}`}>
            <div className="menu__wrapper">
                <Link
                    id="navigation-home-logo"
                    aria-label={trans('basic.logoLinkLabel')}
                    to={RoutePaths.home()}
                    className="menu__logo-wrapper"
                >
                    <EnvironmentLogo className="menu__logo" />
                </Link>

                {showMobileMenu ? (
                    <MobileNavigation
                        authIsLoading={authIsLoading}
                        isValidUser={isValidUser}
                        mainItems={mainItems}
                        informationItems={extraInformationItems}
                        iconItems={iconItems}
                        sessionItems={sessionItems}
                        shortcutItem={mobileShortcutItem}
                        className="menu__mobile-navigation"
                    />
                ) : (
                    <DesktopNavigation
                        authIsLoading={authIsLoading}
                        isValidUser={isValidUser}
                        role={role}
                        person={person}
                        company={company}
                        mainItems={mainItems}
                        informationItems={informationItems}
                        extraInformationItems={extraInformationItems}
                        iconItems={iconItems}
                        profileItems={profileItems}
                        sessionItems={sessionItems}
                        className="menu__desktop-navigation"
                    />
                )}
            </div>

            {!authIsLoading && showCompleteRegistrationToast && (
                <CompleteRegistrationToast role={role} />
            )}
        </section>
    );
};

export default Menu;
