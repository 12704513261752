import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconRefresh: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="-15 -15 130 130" className={`icon ${className}`}>
        <path d="M85.3,14.7C76.2,5.6,63.8,0,50,0C22.3,0,0,22.4,0,50c0,27.6,22.3,50,49.9,50c23.3,0,42.8-15.9,48.3-37.5h-13c-5.1,14.6-19,25-35.3,25c-20.7,0-37.5-16.8-37.5-37.5c0-20.7,16.8-37.5,37.5-37.5c10.4,0,19.6,4.3,26.4,11.1L56.2,43.8H100V0L85.3,14.7z" />
    </svg>
);

export default IconRefresh;
