import React, { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

const LogoEndeavour: FC<SvgLogoProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 500 41" className={`logo ${className}`}>
        <path d="M28.75,32.56H8.66v-8.32h17.25c0.8,0,1.44-0.64,1.44-1.44v-4.88c0-0.8-0.64-1.44-1.44-1.44H8.66V8.44h19.8c0.8,0,1.44-0.64,1.44-1.44V2.12c0-0.8-0.64-1.44-1.44-1.44H1.44C0.64,0.69,0,1.33,0,2.13v36.76c0,0.8,0.64,1.44,1.44,1.44h27.31c0.8,0,1.44-0.64,1.44-1.44v-4.88C30.19,33.21,29.55,32.56,28.75,32.56z" />
        <path d="M90.42,2.12v36.77c0,0.79-0.64,1.43-1.43,1.43h-5.28c-0.44,0-0.87-0.2-1.14-0.56L63.8,15.13v23.76c0,0.79-0.64,1.43-1.43,1.43h-5.74c-0.79,0-1.43-0.64-1.43-1.43V2.12c0-0.79,0.64-1.43,1.43-1.43h5.9c0.44,0,0.87,0.21,1.14,0.56l18.15,23.84V2.12c0-0.79,0.64-1.43,1.43-1.43h5.74C89.77,0.69,90.42,1.33,90.42,2.12z" />
        <path d="M131.16,0.69h-14.03c-0.79,0-1.43,0.64-1.43,1.43v36.77c0,0.79,0.64,1.43,1.43,1.43h14.03c12.46,0,21.07-8.67,21.07-19.82v-0.11C152.23,9.24,143.62,0.69,131.16,0.69z M143.11,20.61c0,7.03-4.82,11.84-11.95,11.84h-6.74V8.55h6.74c7.14,0,11.95,4.93,11.95,11.95V20.61z" />
        <path d="M205.98,32.56h-20.09v-8.32h17.26c0.79,0,1.43-0.64,1.43-1.44v-4.89c0-0.79-0.64-1.43-1.43-1.43h-17.26V8.44h19.8c0.79,0,1.43-0.64,1.43-1.43V2.12c0-0.79-0.64-1.43-1.43-1.43h-27.04c-0.79,0-1.43,0.64-1.43,1.43v36.77c0,0.79,0.64,1.43,1.43,1.43h27.32c0.79,0,1.43-0.64,1.43-1.43V34C207.42,33.21,206.77,32.56,205.98,32.56z" />
        <path d="M254.42,1.27c-0.23-0.53-0.75-0.87-1.32-0.87h-6.15c-0.58,0-1.09,0.35-1.32,0.87l-15.77,37.05c-0.4,0.95,0.29,2,1.32,2h5.76c0.59,0,1.11-0.35,1.33-0.9l1.27-3.12l5.12-12.58h-0.01l1.78-4.33l3.49-8.52l5.27,12.85h-0.01l5.12,12.58l1.27,3.12c0.22,0.54,0.75,0.9,1.33,0.9h5.98c1.03,0,1.72-1.05,1.32-2L254.42,1.27z" />
        <path d="M322.96,0.69h-6.28c-0.6,0-1.14,0.37-1.35,0.94l-10.02,26.98L295.3,1.63c-0.21-0.56-0.75-0.94-1.35-0.94h-6.51c-1.02,0-1.71,1.03-1.33,1.97l14.87,37.05c0.22,0.54,0.75,0.9,1.33,0.9h5.76c0.59,0,1.11-0.36,1.33-0.9l14.87-37.05C324.68,1.71,323.98,0.69,322.96,0.69z" />
        <path d="M363.6,0c-12.23,0-21.12,9.23-21.12,20.5v0.11c0,11.27,8.78,20.39,21.01,20.39c12.23,0,21.12-9.23,21.12-20.5v-0.11C384.61,9.12,375.83,0,363.6,0z M375.48,20.62c0,6.79-4.88,12.34-11.9,12.34c-7.03,0-12.01-5.66-12.01-12.46V20.4c0-6.79,4.88-12.34,11.9-12.34c7.03,0,12.01,5.66,12.01,12.46V20.62z" />
        <path d="M440.83,0.69h-5.85c-0.79,0-1.43,0.64-1.43,1.43v21.27c0,6.28-3.23,9.52-8.55,9.52c-5.33,0-8.55-3.34-8.55-9.8V2.12c0-0.79-0.64-1.43-1.43-1.43h-5.85c-0.79,0-1.43,0.64-1.43,1.43v21.22c0,11.67,6.51,17.61,17.16,17.61s17.39-5.89,17.39-17.9V2.12C442.27,1.33,441.62,0.69,440.83,0.69z" />
        <path d="M492.48,27.6l7.17,10.48c0.65,0.95-0.03,2.24-1.19,2.24h-6.71c-0.48,0-0.92-0.24-1.19-0.63l-8.07-12.05h-6.85v11.25c0,0.79-0.64,1.43-1.43,1.43h-5.86c-0.79,0-1.43-0.64-1.43-1.43V2.12c0-0.79,0.64-1.43,1.43-1.43h16.69c5.04,0,8.95,1.42,11.56,4.02c2.21,2.21,3.4,5.33,3.4,9.06v0.11c0,5.72-2.76,9.54-6.95,11.62C492.26,25.89,491.99,26.87,492.48,27.6z M491.16,14.22c0-3.74-2.6-5.66-6.86-5.66h-8.66v11.38h8.83c4.25,0,6.68-2.26,6.68-5.61L491.16,14.22L491.16,14.22z" />
    </svg>
);

export default LogoEndeavour;
