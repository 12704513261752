import { VacancyType } from '../_old/app_talentz/models/VacancyType';
import { MinMaxValue } from '../types';
import { defaultFullEmploymentPreference } from './Employment';

export interface VacancyOverviewFilterValues {
    pageNumber: number;
    pageSize: number;
    what: string;
    where: string;
    applyType: string;
    sectors: string[];
    educationLevels: string[];
    workRemotePreference: string[];
    amountOfEmployees: string[];
    distance: number;
    province: string;
    postalCode: string;
    skills: string[];
    competencies: string[];
}

export interface InternshipVacancyOverviewFilterValues extends VacancyOverviewFilterValues {
    internshipTypes: string[];
    grade: string;
    compensation: number;
    sbbApproval: string[];
    skillsToLearn: string[];
    competenciesToLearn: string[];
}

export interface JobVacancyOverviewFilterValues extends VacancyOverviewFilterValues {
    jobTypes: string[];
    employmentHourRange: MinMaxValue;
    salary: number;
}

export interface CompanyVacancyOverviewFilterValues extends VacancyOverviewFilterValues {
    companyUuids: string[];
}

const defaultVacancyOverviewFilterValues = {
    pageNumber: 1,
    pageSize: 10,
    what: '',
    where: '',
    applyType: '',
    sectors: [],
    educationLevels: [],
    workRemotePreference: [],
    amountOfEmployees: [],
    distance: 0,
    province: '',
    postalCode: '',
    skills: [],
    competencies: [],
};

export const defaultInternshipVacancyOverviewFilterValues = (): InternshipVacancyOverviewFilterValues => ({
    ...defaultVacancyOverviewFilterValues,
    internshipTypes: [],
    grade: '',
    compensation: 0,
    sbbApproval: [],
    skillsToLearn: [],
    competenciesToLearn: [],
});

export const defaultJobVacancyOverviewFilterValues = (): JobVacancyOverviewFilterValues => ({
    ...defaultVacancyOverviewFilterValues,
    jobTypes: [],
    employmentHourRange: defaultFullEmploymentPreference(),
    salary: 0,
});

export const defaultCompanyVacancyOverviewFilterValues = (): CompanyVacancyOverviewFilterValues => ({
    ...defaultVacancyOverviewFilterValues,
    pageSize: 4,
    companyUuids: [],
});

export const defaultRelatedVacancyOverviewFilterValues = (): VacancyOverviewFilterValues => ({
    ...defaultVacancyOverviewFilterValues,
    // Says 5 here, but list will contain 4, because the vacancy
    // where related vacancies are searched for, will be filtered off.
    pageSize: 5,
});

interface VacancyOverviewFilterParams {
    'page[number]': number;
    'page[size]': number;
    'filter[s]'?: string;
    'filter[location]'?: string;
    'filter[zipcode]'?: string;
    'filter[province]'?: string;
    'filter[apply_types][]'?: string;
    'filter[sectors][]'?: string;
    'filter[education_levels][]'?: string;
    'filter[remoteWorkingOptions]'?: string;
    'filter[amount_of_employees][]'?: string;
    'filter[travel_distance]'?: string;
    'filter[skills][]'?: string;
    'filter[competencies][]'?: string;
}

export interface InternshipVacancyOverviewFilterParams extends VacancyOverviewFilterParams {
    'filter[vacancy_type]': VacancyType.internship;
    'filter[internship_types][]'?: string;
    'filter[grade]'?: string;
    'filter[compensation]'?: string;
    'filter[sbb_approval]'?: boolean;
    'filter[skills_to_learn][]'?: string;
    'filter[competencies_to_learn][]'?: string;
}

interface JobVacancyOverviewFilterParams extends VacancyOverviewFilterParams {
    'filter[vacancy_type]': VacancyType.job;
    'filter[job_types][]'?: string;
    'filter[employmentHourRange]'?: string;
    'filter[gross_salary]'?: string;
}

interface CompanyVacancyOverviewFilterParams extends VacancyOverviewFilterParams {
    'filter[companies][]'?: string;
}

export const transformToVacancyOverviewFilterParams = (
    filterValues: VacancyOverviewFilterValues,
): VacancyOverviewFilterParams => ({
    'page[number]': filterValues.pageNumber,
    'page[size]': filterValues.pageSize,
    'filter[s]': filterValues.what || undefined,
    'filter[location]': filterValues.where || undefined,
    'filter[zipcode]': filterValues.postalCode || undefined,
    'filter[province]': filterValues.province || undefined,
    'filter[apply_types][]': filterValues.applyType || undefined,
    'filter[sectors][]': filterValues.sectors?.join('&filter[sectors][]=') || undefined,
    'filter[education_levels][]': filterValues.educationLevels?.join('&filter[education_levels][]=') || undefined,
    'filter[remoteWorkingOptions]': filterValues.workRemotePreference?.join(',') || undefined,
    'filter[amount_of_employees][]': filterValues.amountOfEmployees?.join('&filter[amount_of_employees][]=') || undefined,
    'filter[travel_distance]': filterValues.distance
        ? String(filterValues.distance)
        : undefined,
    'filter[skills][]': filterValues.skills?.join('&filter[skills][]=') || undefined,
    'filter[competencies][]': filterValues.competencies?.join('&filter[competencies][]=') || undefined,
});

export const transformToInternshipVacancyOverviewFilterParams = (
    filterValues: InternshipVacancyOverviewFilterValues,
): InternshipVacancyOverviewFilterParams => {
    const filterParams = transformToVacancyOverviewFilterParams(filterValues);

    return {
        ...filterParams,
        'filter[vacancy_type]': VacancyType.internship,
        'filter[internship_types][]': filterValues.internshipTypes?.join('&filter[internship_types][]=') || undefined,
        'filter[grade]': filterValues.grade || undefined,
        'filter[compensation]': filterValues.compensation
            ? String(filterValues.compensation)
            : undefined,
        'filter[sbb_approval]': filterValues.sbbApproval.length > 0 || undefined,
        'filter[skills_to_learn][]': filterValues.skillsToLearn?.join('&filter[skills_to_learn][]=') || undefined,
        'filter[competencies_to_learn][]': filterValues.competenciesToLearn?.join('&filter[competencies_to_learn][]=') || undefined,
    };
};

export const transformToJobVacancyOverviewFilterParams = (
    filterValues: JobVacancyOverviewFilterValues,
): JobVacancyOverviewFilterParams => {
    const filterParams = transformToVacancyOverviewFilterParams(filterValues);

    return {
        ...filterParams,
        'filter[vacancy_type]': VacancyType.job,
        'filter[job_types][]': filterValues.jobTypes?.join('&filter[job_types][]=') || undefined,
        'filter[employmentHourRange]': filterValues.employmentHourRange
            ? `${filterValues.employmentHourRange.min},${filterValues.employmentHourRange.max}`
            : undefined,
        'filter[gross_salary]': filterValues.salary
            ? String(filterValues.salary)
            : undefined,
    };
};

export const transformToCompanyVacancyOverviewFilterParams = (
    filterValues: CompanyVacancyOverviewFilterValues,
): CompanyVacancyOverviewFilterParams => {
    const filterParams = transformToVacancyOverviewFilterParams(filterValues);

    return {
        ...filterParams,
        'filter[companies][]': filterValues.companyUuids.join('&filter[companies][]='),
    };
};
