import React, {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { SubmitButton, TextInput } from '../../../compositions';
import { sanitizeAllHtml } from '../../../helpers/sanitizeHtml';
import { trans } from '../../../helpers/trans';

import './ChatMessageForm.scss';

interface ChatMessageFormProps {
    isDisabled: boolean;
    onSubmit: (message: string) => void;
    className?: string;
}

const ChatMessageForm: FC<ChatMessageFormProps> = ({
    isDisabled,
    onSubmit,
    className = '',
}): ReactElement => {
    const [messageToSend, setMessageToSend] = useState<string>('');

    const inputRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const sanitizedMessage = sanitizeAllHtml(messageToSend);

        onSubmit(sanitizedMessage);

        if (inputRef.current) {
            setMessageToSend('');
            inputRef.current.focus();
        }
    };

    return (
        <form onSubmit={handleSubmit} className={`chat-message-form ${className}`}>
            <TextInput
                ref={inputRef}
                label={trans('forms.chatMessage.input.message')}
                hideLabel
                value={messageToSend}
                placeholder={trans('forms.chatMessage.input.message')}
                disabled={isDisabled}
                onChange={setMessageToSend}
                className="chat-message-form__input"
                inputClassName="chat-message-form__input-field"
            />

            <SubmitButton
                icon="send"
                text={trans('forms.chatMessage.button.send')}
                hideLabel
                disabled={!messageToSend.trim() || isDisabled}
                className="chat-message-form__send-button"
            />
        </form>
    );
};

export default ChatMessageForm;
