import React, { createRef, FC, ReactElement } from 'react';

import { BlogBreadcrumbs, ScrollProgress, ShareList } from '../../compositions';
import { trans } from '../../helpers/trans';
import { BlogArticle } from '../../models/Blog';
import { BlogContentSkeletons } from './skeletons';
import { BlogHeader, BlogRenderer } from './subcomponents';

import './BlogContent.scss';

interface BlogContentProps {
    isLoading: boolean;
    content?: BlogArticle;
    className?: string;
}

const BlogContent: FC<BlogContentProps> = ({
    isLoading,
    content,
    className = '',
}): ReactElement => {
    const blogRef = createRef<HTMLElement>();

    if (isLoading || !content) {
        return (
            <BlogContentSkeletons className={`blog-content ${className}`} />
        );
    }

    return (
        <article ref={blogRef} className={`blog-content ${className}`}>
            <ScrollProgress<HTMLElement>
                refElement={blogRef}
                className="blog-content__scroll-progress"
            />

            <BlogBreadcrumbs
                category={content.categories[0]}
                blogTitle={content.title}
                className="blog-content__breadcrumbs"
            />

            <BlogHeader
                content={content}
                className="blog-content__header"
            />

            <div className="blog-content__body">
                <div className="blog-content__content-wrapper">
                    <BlogRenderer
                        content={content}
                        className="blog-content__renderer"
                    />

                    <ShareList
                        hasEmail
                        titleText={trans('share.blog.title')}
                        bodyText={trans('share.blog.body', { title: content.title })}
                        className="blog-content__share"
                        itemClassName="blog-content__share-item"
                    />
                </div>
            </div>
        </article>
    );
};

export default BlogContent;
