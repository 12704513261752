import React, { FC, ReactElement } from 'react';

import { Icon, Skeleton } from '../../../../components';

import './ProfileControlsSkeletons.scss';

interface ProfileControlsSkeletonsProps {
    className?: string;
}

const ProfileControlsSkeletons: FC<ProfileControlsSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`profile-controls-skeletons ${className}`}>
        <div className="profile-controls-skeletons__avatar" />

        <Skeleton className="profile-controls-skeletons__name" />

        <Icon name="vertical-ellipsis" className="profile-controls-skeletons__toggle-icon" />
    </div>
);

export default ProfileControlsSkeletons;
