import React, { FC, ReactElement } from 'react';

import { Button, Logo } from '../../../components';
import { trans } from '../../../helpers/trans';

import './LLOButton.scss';

interface LLOButtonProps {
    onClick: () => void;
    className?: string;
}

const LLOButton: FC<LLOButtonProps> = ({
    onClick,
    className = '',
}): ReactElement => (
    <Button
        text={trans('forms.login.llo')}
        onClick={onClick}
        className={`llo-button ${className}`}
    >
        <Logo name="llo-compact" className="llo-button__icon" />

        {trans('forms.login.llo')}
    </Button>
);

export default LLOButton;
