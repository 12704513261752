import React, {
    FC,
    KeyboardEvent,
    PointerEvent,
    ReactElement,
    RefObject,
    useState,
} from 'react';

import classNames from 'classnames';

import { clamp } from '../../../../../helpers/number';

import './RangeInputHandle.scss';

interface RangeInputHandleProps {
    parentRef: RefObject<HTMLDivElement>;
    crossedMidPoint?: boolean;
    label: string;
    min: number;
    max: number;
    clampMin: number;
    clampMax: number;
    step: number;
    value: number;
    tabIndex?: number;
    disabled?: boolean;
    rangeSize: number;
    hiddenMarginPercentage?: number;
    valueFormat?: (value: number) => string;
    onChange: (value: number) => void;
    className?: string;
}

const RangeInputHandle: FC<RangeInputHandleProps> = ({
    parentRef,
    crossedMidPoint = false,
    label,
    min,
    max,
    clampMin,
    clampMax,
    step,
    value,
    tabIndex,
    disabled,
    rangeSize,
    hiddenMarginPercentage = 0,
    valueFormat,
    onChange,
    className = '',
}): ReactElement => {
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const minMaxDiff = max - min;
    const hiddenMargin = Math.round((minMaxDiff / 100) * hiddenMarginPercentage);

    const handleDragStart = (event: PointerEvent<HTMLDivElement>): void => {
        event.currentTarget.setPointerCapture(event.pointerId);
        setIsDragging(true);
    };

    const handleDragEnd = (event: PointerEvent<HTMLDivElement>): void => {
        event.currentTarget.releasePointerCapture(event.pointerId);
        setIsDragging(false);
    };

    const handleDrag = (event: PointerEvent<HTMLDivElement>): void => {
        event.preventDefault();
        const handle = event.currentTarget;
        const bar = parentRef.current;

        if (handle.hasPointerCapture(event.pointerId) && bar) {
            const draggingPos = event.clientX - bar.getBoundingClientRect().left;
            const draggingPercentage = draggingPos / bar.offsetWidth;

            const newValue = Math.round(min + (minMaxDiff * draggingPercentage));
            const steppedValue = Math.round(newValue / step) * step;
            const clampedValue = clamp(steppedValue, clampMin, clampMax);

            onChange(clampedValue);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
        const minusKeys = ['ArrowLeft', 'ArrowDown'];
        const plusKeys = ['ArrowRight', 'ArrowUp'];

        let newValue = value;

        if (minusKeys.includes(event.key)) {
            newValue = value - step;
        }

        if (plusKeys.includes(event.key)) {
            newValue = value + step;
        }

        const steppedValue = Math.round(newValue / step) * step;
        const clampedValue = clamp(steppedValue, clampMin, clampMax);

        onChange(clampedValue);
    };

    const rangeInputHandleClassNames = classNames('range-input-handle', {
        'range-input-handle--is-active': isDragging,
        'range-input-handle--is-disabled': !!disabled,
        'range-input-handle--crossed-mid-point': crossedMidPoint,
        'range-input-handle--is-hidden': rangeSize < hiddenMargin && !crossedMidPoint,
    }, className);

    const formattedValue = valueFormat ? valueFormat(value) : value;

    return (
        <div
            role="slider"
            aria-label={label}
            aria-valuemin={clampMin}
            aria-valuemax={clampMax}
            aria-valuenow={value}
            data-value={formattedValue}
            tabIndex={tabIndex}
            onPointerDown={handleDragStart}
            onPointerMove={handleDrag}
            onPointerUp={handleDragEnd}
            onKeyDown={handleKeyDown}
            className={rangeInputHandleClassNames}
        />
    );
};

export default RangeInputHandle;
