import React, { FC, ReactElement, useState } from 'react';

import { CheckboxGroup } from '@createnl/grouped-checkboxes';
import { CheckboxChange } from '@createnl/grouped-checkboxes/dist/CheckboxGroup';
import classNames from 'classnames';

import { CheckAllInGroupCheckbox, GroupedCheckbox } from '../../../components';
import { FormOption } from '../../../types';

import './CheckboxGroupList.scss';

interface CheckboxGroupListProps {
    groupOption: FormOption;
    name: string;
    options: FormOption[];
    selectedOptions?: FormOption[];
    tabIndex?: number;
    onChange: (selected: CheckboxChange[]) => void;
    className?: string;
}

const CheckboxGroupList: FC<CheckboxGroupListProps> = ({
    groupOption,
    name,
    options,
    selectedOptions = [],
    tabIndex,
    onChange,
    className = '',
}): ReactElement => {
    const [groupedChecked, setGroupChecked] = useState<boolean>(false);

    const selectedCheckboxes = selectedOptions.map(option => ({
        ...option,
        disabled: false,
        checked: true,
    }));

    const handleChange = (checkboxes: CheckboxChange[]): void => {
        setGroupChecked(checkboxes.every(checkbox => checkbox.checked));
        onChange(checkboxes);
    };

    const subItemWrapperClassNames = classNames('checkbox-group-list__sub-item-wrapper', {
        'checkbox-group-list__sub-item-wrapper--is-hidden': selectedOptions.length === 0,
    });

    return (
        <div className={`checkbox-group-list ${className}`}>
            <CheckboxGroup onChange={handleChange}>
                <CheckAllInGroupCheckbox
                    name={name}
                    option={groupOption}
                    checked={groupedChecked}
                    tabIndex={tabIndex}
                    className="checkbox-group-list__item"
                />

                {options.length > 0 && (
                    <div className={subItemWrapperClassNames}>
                        {options.map(option => (
                            <GroupedCheckbox
                                key={option.value}
                                name={name}
                                option={option}
                                checked={!!selectedCheckboxes.find(checkbox => checkbox.value === option.value)?.checked}
                                tabIndex={tabIndex}
                                className="checkbox-group-list__item"
                            />
                        ))}
                    </div>
                )}
            </CheckboxGroup>
        </div>
    );
};

export default CheckboxGroupList;
