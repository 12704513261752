import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { generateIdArray } from '../../../../../helpers/array';

import './ProgressStepIndicator.scss';

interface ProgressStepIndicatorProps {
    amountOfSteps: number;
    amountOfCompletedSteps: number;
    className?: string;
}

const ProgressStepIndicator: FC<ProgressStepIndicatorProps> = ({
    amountOfSteps,
    amountOfCompletedSteps,
    className = '',
}): ReactElement => {
    const finishedSteps = generateIdArray(amountOfCompletedSteps);
    const unfinishedSteps = generateIdArray(amountOfSteps - amountOfCompletedSteps);

    return (
        <ul className={`progress-step-indicator ${className}`}>
            {finishedSteps.map((step, index) => {
                const isLastType = index === finishedSteps.length - 1;
                const stepClassNames = classNames('progress-step-indicator__step', {
                    'progress-step-indicator__step--is-finished': !isLastType,
                    'progress-step-indicator__step--just-finished': isLastType,
                });

                return (
                    <li key={step} className={stepClassNames} />
                );
            })}

            {unfinishedSteps.map(step => (
                <li key={step} className="progress-step-indicator__step" />
            ))}
        </ul>
    );
};

export default ProgressStepIndicator;
