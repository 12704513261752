import React, { FC, ReactElement } from 'react';

import { LinkButton } from '../../../components';
import { DetailCard, FixedFooter } from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { RoutePaths } from '../../../routes';
import { UserSettingsTab } from '../../../types/pageTabs';

import './VacancyCompleteRegistrationCard.scss';

interface VacancyCompleteRegistrationProps {
    className?: string;
}

const VacancyCompleteRegistrationCard: FC<VacancyCompleteRegistrationProps> = ({
    className = '',
}): ReactElement => (
    <DetailCard
        title={trans('vacancyDetail.completeRegistration.title')}
        className={`vacancy-complete-registration-card ${className}`}
    >
        <p className="vacancy-complete-registration-card__description">
            {trans('vacancyDetail.completeRegistration.description')}
        </p>

        <LinkButton
            to={RoutePaths.userSettings(UserSettingsTab.personalData)}
            text={trans('vacancyDetail.completeRegistration.button.completeRegistration')}
            className="vacancy-complete-registration-card__button"
        />

        <FixedFooter onlySmallScreen>
            <LinkButton
                to={RoutePaths.userSettings(UserSettingsTab.personalData)}
                text={trans('vacancyDetail.completeRegistration.button.completeRegistration')}
                className="vacancy-complete-registration-card__fixed-footer-button"
            />
        </FixedFooter>
    </DetailCard>
);

export default VacancyCompleteRegistrationCard;
