import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCurrency: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M79,21C71.5,8.5,58,0,42.5,0C19,0,0,19,0,42.5C0,58,8.5,71.5,21,79c7.5,12.5,21,21,36.5,21C81,100,100,81,100,57.5C100,42,91.5,28.5,79,21z M15,60c-3-5-5-11-5-17.5C10,24.5,24.5,10,42.5,10C49,10,55,12,60,15h-2.5C34,15,15,34,15,57.5V60z M57.5,90C39.5,90,25,75.5,25,57.5S39.5,25,57.5,25S90,39.5,90,57.5S75.5,90,57.5,90z" />
        <path d="M68,70l4,6.5c-3,2-7.5,3.5-12,3.5c-9,0-16.5-5-20-12.5h-5V60h2.5v-2.5H35V50h4c3-9,11.5-15,21-15c4.5,0,9,1.5,12.5,3.5L68,45c-2.5-1.5-5-2.5-8-2.5c-5.5,0-10.5,3-13,7.5h13v7.5H45V60h15v7.5H49c2.5,3,6.5,5,11,5C63,72.5,66,71.5,68,70z" />
    </svg>
);

export default IconCurrency;
