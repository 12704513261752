/* eslint-disable arrow-body-style */
import {
    isAfter,
    isBefore,
    isEqual,
    isValid,
} from 'date-fns';

export const stringContainsValue = (string?: string): boolean => {
    if (!string) {
        return false;
    }

    return string.trim().length > 0;
};

export const stringMatchesRegEx = (string: string, regEx: RegExp, flag?: string): boolean => {
    const regExp = new RegExp(regEx, flag);

    return regExp.test(string);
};

export const numberDoesNotExceedComparison = (number: number, comparisonNumber: number): boolean => {
    return number <= comparisonNumber;
};

export const numberDoesNotPrecedeComparison = (number: number, comparisonNumber: number): boolean => {
    return number >= comparisonNumber;
};

export const arrayHasMinimumLength = <T>(array: T[], minimumLength: number): boolean => {
    return array.length >= minimumLength;
};

export const dateIsValid = (date: Date): boolean => {
    return isValid(date);
};

export const dateEqualsComparison = (date: Date, comparisonDate: Date): boolean => {
    return isEqual(date, comparisonDate);
};

export const dateIsEqualOrBeforeComparison = (date: Date, comparisonDate: Date): boolean => {
    return isEqual(date, comparisonDate) || isBefore(date, comparisonDate);
};

export const dateIsEqualOrAfterComparison = (date: Date, comparisonDate: Date): boolean => {
    return isEqual(date, comparisonDate) || isAfter(date, comparisonDate);
};

/* eslint-enable arrow-body-style */
