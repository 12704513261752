import React, { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { fetchStudentForVacancy } from '../../../_old/app_talentz/store/students/studentsActions';
import { fetchVacancyForCompany, ignoreStudent, inviteStudent } from '../../../_old/app_talentz/store/vacancy/vacancyActions';
import { CandidateMatchCard } from '../../../containers';
import { RouteParams } from '../../../routes';
import { useLegacyDispatch, useLegacySelector } from '../../../store';

interface ConnectedCandidateMatchCardParams extends RouteParams {
    vacancyUuid?: string;
    candidateUuid?: string;
}

interface ConnectedCandidateMatchCardProps {
    className?: string;
}

const ConnectedCandidateMatchCard: FC<ConnectedCandidateMatchCardProps> = ({
    className = '',
}): ReactElement => {
    const legacyDispatch = useLegacyDispatch();
    const { vacancyUuid = '', candidateUuid = '' } = useParams<ConnectedCandidateMatchCardParams>();

    const vacancyIsLoading = useLegacySelector(state => state.vacancy.loading);
    const vacancy = useLegacySelector(state => state.vacancy.vacancy);

    const candidateIsLoading = useLegacySelector(state => state.students.loading);
    const matchingCandidate = useLegacySelector(state => state.students.currentStudent);

    useEffect((): void => {
        if (vacancy) {
            legacyDispatch(fetchStudentForVacancy(vacancy, candidateUuid));
        } else {
            legacyDispatch(fetchVacancyForCompany(vacancyUuid));
        }
    }, [vacancy]);

    useEffect((): void => {
        if (vacancy && matchingCandidate?.uuid !== candidateUuid) {
            legacyDispatch(fetchStudentForVacancy(vacancy, candidateUuid));
        }
    }, [matchingCandidate, vacancy]);

    const handleInviteCandidate = (): void => {
        legacyDispatch(inviteStudent(vacancyUuid, matchingCandidate));
    };

    const handleIgnoreCandidate = (): void => {
        legacyDispatch(ignoreStudent(vacancyUuid, matchingCandidate));
    };

    // TODO: Refactor this (once API is done) with logic of other profile connector cards
    // TODO: Apply new translations
    return (
        <CandidateMatchCard
            isLoading={vacancyIsLoading || candidateIsLoading}
            candidate={matchingCandidate}
            onInviteCandidate={handleInviteCandidate}
            onIgnoreCandidate={handleIgnoreCandidate}
            className={className}
        />
    );
};

export default ConnectedCandidateMatchCard;
