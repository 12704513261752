import React, { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { NotificationsSidebar } from '../../containers';
import { NotificationStatus } from '../../models/Notifications';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import {
    deleteNotification,
    fetchNotifications,
    toggleExpandNotifications,
    updateNotificationListStatus,
    updateNotificationStatus,
} from '../../store/notifications/notificationsActions';

interface ConnectedNotificationsSidebarProps {
    className?: string;
}

const ConnectedNotificationsSidebar: FC<ConnectedNotificationsSidebarProps> = ({
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();
    const location = useLocation();

    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const role = useLegacySelector(state => state.legacyUser.role);

    const isExpanded = useTypedSelector(state => state.notificationsReducer.isExpanded);
    const isLoading = useTypedSelector(state => state.notificationsReducer.isLoading);
    const notifications = useTypedSelector(state => state.notificationsReducer.notifications);

    const userRole = role || RoleType.none;
    const allowedRoles = [RoleType.student, RoleType.jobSeeker, RoleType.company];
    const allowedToHaveNotifications = allowedRoles.includes(userRole);
    const isValidUser = isAuthenticated && allowedToHaveNotifications;

    useEffect((): void => {
        if (isValidUser && notifications.length === 0) {
            dispatch(fetchNotifications());
        }
    }, [isValidUser]);

    useEffect((): void => {
        if (isValidUser && isExpanded) {
            dispatch(fetchNotifications());
            dispatch(updateNotificationListStatus(NotificationStatus.unread));
        }
    }, [isValidUser, isExpanded]);

    useEffect((): void => {
        if (isValidUser) {
            dispatch(toggleExpandNotifications(false));
        }
    }, [isValidUser, location]);

    const handleSelectNotification = (id: string): void => {
        dispatch(updateNotificationStatus(id, NotificationStatus.read));
    };

    const handleRemoveNotification = (id: string): void => {
        dispatch(deleteNotification(id));
    };

    const handleClose = (): void => {
        dispatch(toggleExpandNotifications(false));
    };

    return isValidUser ? (
        <NotificationsSidebar
            isLoading={isLoading}
            isExpanded={isExpanded}
            notifications={notifications}
            onSelectNotification={handleSelectNotification}
            onRemoveNotification={handleRemoveNotification}
            onClose={handleClose}
            className={className}
        />
    ) : null;
};

export default ConnectedNotificationsSidebar;
