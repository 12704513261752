import React, { FC, ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { ApplicationAppointment } from '../../models/ApplicationAppointments';
import { Application } from '../../models/Applications';
import { RoutePaths } from '../../routes';
import { StatusCode } from '../../types';
import { VideoAppointmentSkeletons } from './skeletons';
import { Jitsi } from './subcomponents';

interface VideoAppointmentProps {
    isLoading: boolean;
    isCandidate: boolean;
    application?: Application;
    appointmentStatusCode?: StatusCode;
    appointment?: ApplicationAppointment;
    className?: string;
}

const VideoAppointment: FC<VideoAppointmentProps> = ({
    isLoading,
    isCandidate,
    application,
    appointmentStatusCode,
    appointment,
    className = '',
}): ReactElement => {
    if (appointmentStatusCode === StatusCode.notFound) {
        return <Navigate to={RoutePaths.error404()} />;
    }

    if (appointmentStatusCode === StatusCode.server) {
        return <Navigate to={RoutePaths.error500()} />;
    }

    return (
        <section className={`video-appointment ${className}`}>
            {isLoading && (
                <VideoAppointmentSkeletons />
            )}

            {!isLoading && application && appointment && (
                <Jitsi
                    isCandidate={isCandidate}
                    application={application}
                    appointment={appointment}
                />
            )}
        </section>
    );
};

export default VideoAppointment;
