import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedConfirmEmailAddressChange } from '../../connectors';
import { trans } from '../../helpers/trans';
import { RouteParams } from '../../routes';

import './ConfirmEmailAddressChange.scss';

interface ConfirmEmailAddressChangeParams extends RouteParams {
    confirmationKey?: string;
}

const ConfirmEmailAddressChange: FC = (): ReactElement => {
    const { confirmationKey } = useParams<ConfirmEmailAddressChangeParams>();

    return (
        <Page className="confirm-email-address-change-page">
            <Helmet>
                <title>{trans('pages.confirmEmailAddressChange.meta.title')}</title>
                <meta name="description" content={trans('pages.confirmEmailAddressChange.meta.description')} />
            </Helmet>

            <div className="confirm-email-address-change-page__wrapper">
                <ConnectedConfirmEmailAddressChange
                    confirmationKey={confirmationKey}
                    className="confirm-email-address-change-page__card"
                />
            </div>
        </Page>
    );
};

export default ConfirmEmailAddressChange;
