import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '../../components';
import { RoleType } from '../../models/User';
import { RoutePaths } from '../../routes';
import { CompanyOverviewTab } from '../../types/pageTabs';
import { roleInfoStepsContent } from './content';

import './RoleInfoSteps.scss';

interface RoleInfoStepsProps {
    role: RoleType;
    template: RoleType;
    onCallToActionClick: (role: RoleType) => void;
    className?: string;
}

const RoleInfoSteps: FC<RoleInfoStepsProps> = ({
    role,
    template,
    onCallToActionClick,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const [userIsAuthenticated, setUserIsAuthenticated] = useState<boolean>(false);

    useEffect((): void => {
        setUserIsAuthenticated(role !== RoleType.none && role === template);
    }, [role, template]);

    const content = roleInfoStepsContent(template);

    const handleCallToActionClick = (): void => {
        if (userIsAuthenticated) {
            if (template === RoleType.student) navigate(RoutePaths.internships());
            if (template === RoleType.jobSeeker) navigate(RoutePaths.jobs());
            if (template === RoleType.company) navigate(RoutePaths.companyOverviewTab(CompanyOverviewTab.vacancies));

            return;
        }

        onCallToActionClick(role);
    };

    return (
        <section className={`role-info-steps ${className}`}>
            <div className="role-info-steps__wrapper">
                <header className="role-info-steps__header">
                    <h1 className="role-info-steps__title">
                        {content.title}
                    </h1>
                    <p className="role-info-steps__subtitle">
                        {content.description}
                    </p>
                </header>

                <ul className="role-info-steps__step-list">
                    {content.steps.map(step => (
                        <li key={step.title} className="role-info-steps__step">
                            <div className="role-info-steps__step-wrapper">
                                <span className="role-info-steps__step-category">
                                    {step.category}
                                </span>
                                <h2 className="role-info-steps__step-title">
                                    {step.title}
                                </h2>
                                <p className="role-info-steps__step-description">
                                    {step.description}
                                </p>

                                {step.buttonLabel && (
                                    <Button
                                        text={step.buttonLabel}
                                        onClick={handleCallToActionClick}
                                        className="role-info-steps__step-button"
                                    />
                                )}
                            </div>
                            <div className="role-info-steps__step-wrapper">
                                <img
                                    src={step.imageDesktop.src}
                                    alt={step.imageDesktop.alt}
                                    className="role-info-steps__image role-info-steps__image--desktop"
                                />
                                <img
                                    src={step.imageMobile.src}
                                    alt={step.imageMobile.alt}
                                    className="role-info-steps__image role-info-steps__image--mobile"
                                />
                                <div className="role-info-steps__image-backdrop" />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default RoleInfoSteps;
