import React, { FC, FormEvent, ReactElement } from 'react';

import {
    AmountOfEmployeesFilter,
    ApplyTypesFilter,
    DistanceFilter,
    EducationLevelsFilter,
    EmploymentFilter,
    GrossSalaryFilter,
    IconButton,
    JobTypesFilter,
    SectorsFilter,
    Sidebar,
    SubmitButton,
    WorkRemotePreferenceFilter,
} from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../../../models/EducationLevels';
import { JobVacancyOverviewFilterValues } from '../../../../models/VacancyOverview';
import { WorkRemoteOption } from '../../../../models/WorkRemote';
import { FormOption, MinMaxValue } from '../../../../types';

import './JobVacancyOverviewFilterSidebar.scss';

interface JobVacancyOverviewFilterSidebarProps {
    isLoading: boolean;
    isExpanded: boolean;
    hasLocation: boolean;
    filterValues: JobVacancyOverviewFilterValues;
    applyTypeOptions: FormOption[];
    sectorOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    workRemoteOptions: WorkRemoteOption[];
    jobTypeOptions: FormOption[];
    amountOfEmployeesOptions: FormOption[];
    onChange: (values: Partial<JobVacancyOverviewFilterValues>) => void;
    onReset: () => void;
    onSubmit: () => void;
    onClose: () => void;
    className?: string;
}

const JobVacancyOverviewFilterSidebar: FC<JobVacancyOverviewFilterSidebarProps> = ({
    isLoading,
    isExpanded,
    hasLocation,
    filterValues,
    applyTypeOptions,
    sectorOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    workRemoteOptions,
    jobTypeOptions,
    amountOfEmployeesOptions,
    onChange,
    onReset,
    onSubmit,
    onClose,
    className = '',
}): ReactElement => {
    const handleDistanceChange = (value: number): void => {
        onChange({ distance: value });
    };

    const handleApplyTypeChange = (value: string): void => {
        onChange({ applyType: value });
    };

    const handleSectorsChange = (value: string[]): void => {
        onChange({ sectors: value });
    };

    const handleEducationLevelsChange = (value: string[]): void => {
        onChange({ educationLevels: value });
    };

    const handleWorkRemotePreferenceChange = (value: string[]): void => {
        onChange({ workRemotePreference: value });
    };

    const handleJobTypesChange = (value: string[]): void => {
        onChange({ jobTypes: value });
    };

    const handleEmploymentHourRangeChange = (value: MinMaxValue): void => {
        onChange({ employmentHourRange: value });
    };

    const handleAmountOfEmployeesChange = (value: string[]): void => {
        onChange({ amountOfEmployees: value });
    };

    const handleSalaryChange = (value: number): void => {
        onChange({ salary: value });
    };

    const handleResetClick = (): void => {
        onClose();
        onReset();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onClose();
        onSubmit();
    };

    return (
        <Sidebar
            isLoading={isLoading}
            isExpanded={isExpanded}
            title={trans('vacancies.filters.title')}
            onClose={onClose}
            className={`job-vacancy-overview-filter-sidebar ${className}`}
        >
            <form onSubmit={handleSubmit} className="job-vacancy-overview-filter-sidebar__form">
                <div className="job-vacancy-overview-filter-sidebar__property-wrapper">
                    {hasLocation && (
                        <DistanceFilter
                            label={trans('vacancies.filters.property.distance')}
                            value={filterValues.distance}
                            onChange={handleDistanceChange}
                            className="job-vacancy-overview-filter-sidebar__property"
                        />
                    )}

                    <ApplyTypesFilter
                        label={trans('vacancies.filters.property.applyAnonymous')}
                        value={filterValues.applyType}
                        options={applyTypeOptions}
                        onChange={handleApplyTypeChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <SectorsFilter
                        label={trans('vacancies.filters.property.sector')}
                        value={filterValues.sectors}
                        sectors={sectorOptions}
                        onChange={handleSectorsChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <EducationLevelsFilter
                        label={trans('vacancies.filters.property.educationLevel')}
                        name="education-levels"
                        value={filterValues.educationLevels}
                        educationLevels={educationLevelOptions}
                        educationSubLevels={educationSubLevelOptions}
                        onChange={handleEducationLevelsChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <WorkRemotePreferenceFilter
                        label={trans('vacancies.filters.property.workRemote')}
                        value={filterValues.workRemotePreference}
                        workRemoteOptions={workRemoteOptions}
                        onChange={handleWorkRemotePreferenceChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <JobTypesFilter
                        label={trans('vacancies.filters.property.jobType')}
                        value={filterValues.jobTypes}
                        options={jobTypeOptions}
                        onChange={handleJobTypesChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <EmploymentFilter
                        label={trans('vacancies.filters.property.employment')}
                        value={filterValues.employmentHourRange}
                        onChange={handleEmploymentHourRangeChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <AmountOfEmployeesFilter
                        label={trans('vacancies.filters.property.amountOfEmployees')}
                        value={filterValues.amountOfEmployees}
                        options={amountOfEmployeesOptions}
                        onChange={handleAmountOfEmployeesChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />

                    <GrossSalaryFilter
                        label={trans('vacancies.filters.property.grossSalary')}
                        value={filterValues.salary}
                        onChange={handleSalaryChange}
                        className="job-vacancy-overview-filter-sidebar__property"
                    />
                </div>

                <footer className="job-vacancy-overview-filter-sidebar__footer">
                    <SubmitButton
                        isLoading={isLoading}
                        icon="search"
                        text={trans('actions.search')}
                    />
                    <IconButton
                        icon="refresh"
                        text={trans('actions.reset')}
                        disabled={isLoading}
                        onClick={handleResetClick}
                        className="job-vacancy-overview-filter-sidebar__reset-button"
                    />
                </footer>
            </form>
        </Sidebar>
    );
};

export default JobVacancyOverviewFilterSidebar;
