import React, { FC, ReactElement } from 'react';

import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { NewestVacancy } from '../../models/NewestVacancy';
import { RoleType } from '../../models/User';
import { RecentRoleVacanciesList, RecentVacanciesList } from './subcomponents';

import './RecentVacancies.scss';

interface RecentVacanciesProps {
    isLoading?: boolean;
    userRole: RoleType;
    userFieldsOfStudy?: string[];
    internshipVacancies?: NewestVacancy[];
    jobVacancies?: NewestVacancy[];
    totalVacancies: number;
    error: string;
    favouriteIds: string[];
    favouriteIsLoading: boolean;
    onFavouriteClick: (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean) => void;
    className?: string;
}

const RecentVacancies: FC<RecentVacanciesProps> = ({
    isLoading = false,
    userRole,
    userFieldsOfStudy,
    jobVacancies,
    internshipVacancies,
    totalVacancies,
    error,
    favouriteIds,
    favouriteIsLoading,
    onFavouriteClick,
    className = '',
}): ReactElement => (
    <section className={`recent-vacancies ${className}`}>
        {userRole === RoleType.student || userRole === RoleType.jobSeeker ? (
            <RecentRoleVacanciesList
                isLoading={isLoading}
                userRole={userRole}
                userFieldsOfStudy={userFieldsOfStudy}
                vacancies={userRole === RoleType.student ? internshipVacancies : jobVacancies}
                error={error}
                favouriteIds={favouriteIds}
                favouriteIsLoading={favouriteIsLoading}
                onFavouriteClick={onFavouriteClick}
            />
        ) : (
            <RecentVacanciesList
                isLoading={isLoading}
                jobVacancies={jobVacancies}
                internshipVacancies={internshipVacancies}
                totalVacancies={totalVacancies}
                error={error}
            />
        )}
    </section>
);

export default RecentVacancies;
