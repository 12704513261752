import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Icon, TableCell, TableRow } from '../../../../components';
import { trans } from '../../../../helpers/trans';
import { SbbRecognition } from '../../../../models/SbbTrainingCompany';
import { IconButton } from '../../..';
import { SbbSubRecognitionCollection } from '..';

import './SbbRecognitionTableRow.scss';

interface SbbRecognitionTableRowProps {
    recognition: SbbRecognition;
    className?: string;
}

const SbbRecognitionTableRow: FC<SbbRecognitionTableRowProps> = ({
    recognition,
    className = '',
}): ReactElement => {
    const [isExpanded, toggleIsExpanded] = useToggle(false);

    const sbbRecognitionTableRowClassNames = classNames('sbb-recognition-table-row', {
        'sbb-recognition-table-row--has-sub-recognitions': recognition.subRecognitions.length > 0,
    }, className);

    return (
        <>
            <TableRow className={sbbRecognitionTableRowClassNames}>
                <TableCell className="sbb-recognition-table-row__cell sbb-recognition-table-row__cell--crebo">
                    <Icon name="document" className="sbb-recognition-table-row__icon" />
                    {recognition.crebo}
                </TableCell>
                <TableCell className="sbb-recognition-table-row__cell sbb-recognition-table-row__cell--title">
                    {recognition.description}
                </TableCell>
                <TableCell className="sbb-recognition-table-row__cell sbb-recognition-table-row__cell--level">
                    {recognition.level}
                </TableCell>

                <TableCell className="sbb-recognition-table-row__cell sbb-recognition-table-row__cell--date" />

                <TableCell className="sbb-recognition-table-row__cell sbb-recognition-table-row__cell--has-expand-button">
                    {recognition.subRecognitions.length > 0 && (
                        <IconButton
                            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                            text={trans(`userProfile.sbb.${isExpanded ? 'hideDetails' : 'revealDetails'}`)}
                            hideLabel
                            onClick={toggleIsExpanded}
                            className="sbb-recognition-table-row__expand-button"
                        />
                    )}
                </TableCell>
            </TableRow>

            {isExpanded && recognition.subRecognitions.map(subRecognition => (
                <SbbSubRecognitionCollection
                    key={subRecognition.crebo}
                    subRecognition={subRecognition}
                />
            ))}
        </>
    );
};

export default SbbRecognitionTableRow;
