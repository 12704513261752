import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { encodeSearchQuery } from '../../../../helpers/search';
import { trans } from '../../../../helpers/trans';
import { Province } from '../../../../models/Provinces';
import { Sector } from '../../../../models/Sectors';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexCategory, VacancyIndexColumnList, VacancyIndexLink } from '..';

import './VacancyIndexSectorProvince.scss';

interface VacancyIndexSectorProvinceProps {
    isLoading: boolean;
    vacancyType: VacancyType;
    sector: Sector;
    provinceOptionColumns: Province[][];
    className?: string;
}

const VacancyIndexSectorProvince: FC<VacancyIndexSectorProvinceProps> = ({
    isLoading,
    vacancyType,
    sector,
    provinceOptionColumns,
    className = '',
}): ReactElement => (
    <VacancyIndexCategory
        isLoading={isLoading}
        title={trans('vacancyIndex.category.sector.province', {
            sector: sector.name,
        })}
        className={`vacancy-index-sector-province ${className}`}
    >
        <VacancyIndexColumnList>
            {provinceOptionColumns.map(column => {
                const columnIndex = column.map(province => province.slug).join('-');

                return (
                    <li key={columnIndex}>
                        <ul className="vacancy-index-sector-province__province-list">
                            {column.map(province => {
                                const params = new URLSearchParams();
                                params.set('sector', sector.slug);

                                const searchQuery = encodeSearchQuery({
                                    province: province.slug,
                                });

                                const route = vacancyType === VacancyType.internship
                                    ? `${RoutePaths.internshipsQuery(searchQuery)}?${String(params)}`
                                    : `${RoutePaths.jobsQuery(searchQuery)}?${String(params)}`;

                                const encodedSector = encodeURIComponent(sector.slug);
                                const encodedProvince = encodeURIComponent(province.slug);

                                const cityRoute = vacancyType === VacancyType.internship
                                    ? RoutePaths.internshipVacancyIndexSector(encodedSector, encodedProvince)
                                    : RoutePaths.jobVacancyIndexSector(encodedSector, encodedProvince);

                                return (
                                    <li key={province.slug} className="vacancy-index-sector-province__province">
                                        <VacancyIndexLink to={route} label={province.name} />

                                        <VacancyIndexLink
                                            to={cityRoute}
                                            label={trans('vacancyIndex.button.selectCity')}
                                            className="vacancy-index-sector-province__city-link"
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </VacancyIndexColumnList>
    </VacancyIndexCategory>
);

export default VacancyIndexSectorProvince;
