import React, { FC, ReactElement } from 'react';

import { Button, Icon, Spinner } from '../../../components';
import { ButtonProps } from '../../../components/@buttons/Button/Button';

import './SubmitButton.scss';

interface SubmitButtonProps extends ButtonProps {
    isLoading?: boolean;
    icon?: string;
    hideLabel?: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = ({
    isLoading,
    icon,
    text,
    hideLabel,
    disabled,
    className = '',
    ...submitButtonProps
}): ReactElement => (
    <Button
        {...submitButtonProps}
        type="submit"
        text={text}
        disabled={isLoading || disabled}
        className={`submit-button ${className}`}
    >
        {isLoading ? (
            <Spinner className="submit-button__icon" />
        ) : (
            icon && <Icon name={icon} className="submit-button__icon" />
        )}
        {!hideLabel && text}
    </Button>
);

export default SubmitButton;
