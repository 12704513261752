import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { KvkBaseProfile, KvkBaseProfileResource, transformToKvkBaseProfile } from '../models/KvkBaseProfile';

export const getKvkBaseProfileApiCall = async (kvkNumber: string): Promise<FetchResult<KvkBaseProfile | undefined, string>> => {
    try {
        const includes = ['eigenaar', 'hoofdvestiging'].join(',');
        const response: Response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/kvk-basisprofiel/${kvkNumber}?include=${includes}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<KvkBaseProfileResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const kvkBaseProfile = transformToKvkBaseProfile(doc, doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: kvkBaseProfile,
        };
    } catch (error) {
        console.error('[getKvkBaseProfileApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
