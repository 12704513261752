import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { EducationLevel } from '../../models/EducationLevels';
import { EscoOccupation } from '../../models/Esco';
import { FieldOfStudy } from '../../models/FieldsOfStudy';
import { Province } from '../../models/Provinces';
import { Sector } from '../../models/Sectors';
import { VacancyIndexAggregation } from '../../models/VacancyIndex';
import { AsyncReduxState } from '../../types';

export type VacancyIndexState = AsyncReduxState<{
    aggregation: VacancyIndexAggregation;
    activeEducationLevels: EducationLevel[];
    activeLetters: string[];
    activeFieldsOfStudy: FieldOfStudy[];
    activeOccupations: EscoOccupation[];
    activeSectors: Sector[];
    activeProvinces: Province[];
    activeCities: string[];
}>;

const initialState: VacancyIndexState = {
    ...initialAsyncReduxState,
    aggregation: {},
    activeEducationLevels: [],
    activeLetters: [],
    activeFieldsOfStudy: [],
    activeOccupations: [],
    activeSectors: [],
    activeProvinces: [],
    activeCities: [],
};

export const vacancyIndexSlice = createSlice({
    name: 'vacancyIndexReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacancyIndexState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacancyIndexState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setAggregation(state, action: PayloadAction<VacancyIndexAggregation>): VacancyIndexState {
            return {
                ...state,
                aggregation: action.payload,
            };
        },
        setActiveEducationLevels(state, action: PayloadAction<EducationLevel[]>): VacancyIndexState {
            return {
                ...state,
                activeEducationLevels: action.payload,
            };
        },
        setActiveLetters(state, action: PayloadAction<string[]>): VacancyIndexState {
            return {
                ...state,
                activeLetters: action.payload,
            };
        },
        setActiveFieldsOfStudy(state, action: PayloadAction<FieldOfStudy[]>): VacancyIndexState {
            return {
                ...state,
                activeFieldsOfStudy: action.payload,
            };
        },
        setActiveOccupations(state, action: PayloadAction<EscoOccupation[]>): VacancyIndexState {
            return {
                ...state,
                activeOccupations: action.payload,
            };
        },
        setActiveSectors(state, action: PayloadAction<Sector[]>): VacancyIndexState {
            return {
                ...state,
                activeSectors: action.payload,
            };
        },
        setActiveProvinces(state, action: PayloadAction<Province[]>): VacancyIndexState {
            return {
                ...state,
                activeProvinces: action.payload,
            };
        },
        setActiveCities(state, action: PayloadAction<string[]>): VacancyIndexState {
            return {
                ...state,
                activeCities: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setAggregation,
    setActiveEducationLevels,
    setActiveLetters,
    setActiveFieldsOfStudy,
    setActiveOccupations,
    setActiveSectors,
    setActiveProvinces,
    setActiveCities,
} = vacancyIndexSlice.actions;

export default vacancyIndexSlice;
