import { Dispatch } from 'redux';

import { JobVacanciesFilterParams, setFilterParams } from './jobVacancies';

/**
 * @returns {function(*): Promise<void>}
 */
export function updateJobVacanciesFilterParams(params: JobVacanciesFilterParams) {
    return (dispatch: Dispatch) => {
        dispatch(setFilterParams({
            ...params,
            s: params.s && params.s.length >= 3
                ? params.s
                : '',
            location: params.location && params.location.length >= 3
                ? params.location
                : '',
        }));
    };
}
