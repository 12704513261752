import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AccordionSection from './AccordionSection';

class Accordion extends Component {
    constructor(props) {
        super(props);

        const openSections = {};

        this.state = { openSections };
    }

    onClick = label => {
        const {
            props: { allowMultipleOpen },
            state: { openSections },
        } = this;

        const isOpen = !!openSections[label];

        this.setState({
            openSections: {
                ...(allowMultipleOpen && openSections),
                [label]: !isOpen,
            },
        });
    };

    render() {
        const {
            onClick,
            props: { children },
            state: { openSections },
        } = this;

        return (
            <dl>
                {children.map(child => (
                    <AccordionSection
                        isOpen={!!openSections[child.props.label]}
                        label={child.props.label}
                        onClick={onClick}
                        key={child.props.label}
                    >
                        {child}
                    </AccordionSection>
                ))}
            </dl>
        );
    }
}

Accordion.propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object),
};

Accordion.defaultProps = {
    allowMultipleOpen: false,
    children: null,
};

export default Accordion;
