/* eslint-disable camelcase */
export enum PrismicType {
    blog = 'blog',
}

export enum PrismicSliceType {
    seo = 'seo',
    text = 'text',
    image = 'image',
}

export interface PrismicAlternateLanguage {
    id: string;
    uid?: string;
    type: string;
    lang: string;
}

export interface PrismicDocument<Template = any> {
    id: string;
    uid: string;
    url?: string;
    type: string;
    href: string;
    tags: string[];
    slugs: string[];
    lang?: string;
    alternate_languages: PrismicAlternateLanguage[];
    first_publication_date: string | null;
    last_publication_date: string | null;
    data: Template;
}

export interface PrismicSearchResponse<Template = any> {
    page: number;
    results_per_page: number;
    results_size: number;
    total_results_size: number;
    total_pages: number;
    next_page: string;
    prev_page: string;
    results: PrismicDocument<Template>[];
}

export interface PrismicLinkResolver {
    id: string;
    uid: string;
    type: string;
    tags: string[];
    lang: string;
    isBroken: boolean;
}

export interface PrismicImage {
    url: string;
    alt: string;
    dimensions: {
        width: number;
        height: number;
    };
}

export interface PrismicSlice<Template = any> {
    items: unknown;
    primary: Template;
    slice_label: unknown;
    slice_type: PrismicSliceType;
}

export interface PrismicSliceSeo {
    seo_title: string;
    seo_description: string;
    seo_keywords: string;
    seo_image: PrismicImage;
}

export interface PrismicBlogTemplate {
    title: string;
    intro: string;
    read_time: number;
    thumbnail: PrismicImage;
    poster: PrismicImage;
    body: PrismicSlice[];
}
/* eslint-enable camelcase */
