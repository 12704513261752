import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { VacancyOverview } from '../../containers';
import { scrollToTop } from '../../helpers/scroll';
import { VacanciesInfo } from '../../models/info/VacanciesInfo';
import { RoleType } from '../../models/User';
import { useLegacySelector, useTypedDispatch, useTypedSelector } from '../../store';
import { patchFavourites } from '../../store/candidate/candidateActions';
import { fetchJobVacancyOverview, fetchMatchingJobVacancyOverview } from '../../store/jobVacancyOverview/jobVacancyOverviewActions';

interface ConnectedJobVacancyOverviewProps {
    isTalentMatching: boolean;
    onPaginationChange: (pageNumber: number) => void;
    className?: string;
}

const ConnectedJobVacancyOverview: FC<ConnectedJobVacancyOverviewProps> = ({
    isTalentMatching,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const [favouriteIds, setFavouriteIds] = useState<string[]>([]);

    const authIsLoading = useLegacySelector(state => state.legacyUser.loading);
    const isAuthenticated = useLegacySelector(state => state.legacyUser.authenticated);
    const role = useLegacySelector(state => state.legacyUser.role);
    const person = useLegacySelector(state => state.userPerson.data);

    const isLoading = useTypedSelector(state => state.jobVacancyOverviewReducer.isLoading);
    const error = useTypedSelector(state => state.jobVacancyOverviewReducer.error);
    const activeFilterValues = useTypedSelector(state => state.jobVacancyOverviewReducer.activeFilterValues);
    const vacancies = useTypedSelector(state => state.jobVacancyOverviewReducer.jobVacancies);
    const pagination = useTypedSelector(state => state.jobVacancyOverviewReducer.pagination);

    const favouritesIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const userRole = role || RoleType.none;
    const isValidUser = isAuthenticated && !!person;
    const isCandidate = userRole === RoleType.jobSeeker || userRole === RoleType.student;

    useEffect((): void => {
        if (isTalentMatching) {
            dispatch(fetchMatchingJobVacancyOverview());
        } else {
            dispatch(fetchJobVacancyOverview());
        }

        scrollToTop();
    }, [activeFilterValues]);

    useEffect((): void => {
        if (candidate) {
            setFavouriteIds(candidate.favourites.map(favourite => favourite.uuid));
        }
    }, [candidate]);

    const handleFavouriteClick = (favouriteVacancy: VacanciesInfo, shouldAddFavourite: boolean): void => {
        if (candidate) {
            const newFavourites = shouldAddFavourite
                ? [...candidate.favourites, favouriteVacancy]
                : candidate.favourites.filter(favourite => favourite.uuid !== favouriteVacancy.uuid);

            dispatch(patchFavourites(candidate.uuid, newFavourites));
        }
    };

    return (
        <VacancyOverview
            isLoading={isLoading}
            showCompleteRegistration={!authIsLoading && isCandidate && !isValidUser}
            error={error}
            userRole={userRole}
            userFieldsOfStudy={candidate?.schoolExperiences.map(schoolExperience => schoolExperience.fieldOfStudy)}
            vacancies={vacancies}
            overviewType={VacancyType.job}
            pagination={pagination}
            favouriteIds={favouriteIds}
            favouriteIsLoading={favouritesIsLoading}
            onFavouriteVacancy={handleFavouriteClick}
            onPaginationChange={onPaginationChange}
            className={className}
        />
    );
};

export default ConnectedJobVacancyOverview;
