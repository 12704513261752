import styled, { css } from 'styled-components';

import { Link } from '../../../../../../styledComponents/components/Typography';

export const Image = styled.img`
    position: relative;
    display: block;
    width: 100%;
    z-index: 1;
`;

export const GreyBlock = styled.div(props => css`
    position: relative;
    width: 100%;
    height: 200px;
    margin-top: -150px;
    background-color: ${props.theme.colors.background};
    
    @media ${props.theme.mediaQueries.laptop} {
        width: calc(50% - 100px);
        height: 300px;
        margin-top: -200px;
    }
`);

export const ContactLink = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`;
