import React, { FC, ReactElement, useState } from 'react';

import {
    ConfirmModal,
    DetailCard,
    IconButton,
    Modal,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { Application } from '../../../models/Applications';

import './ApplicationDeleteCard.scss';

interface ApplicationDeleteCardProps {
    isLoading: boolean;
    application?: Application;
    onDeleteClick: (applicationUuid: string) => void;
    className?: string;
}

const ApplicationDeleteCard: FC<ApplicationDeleteCardProps> = ({
    isLoading,
    application,
    onDeleteClick,
    className = '',
}): ReactElement => {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    const openDeleteModal = (): void => setDeleteModalIsOpen(true);
    const closeDeleteModal = (): void => setDeleteModalIsOpen(false);

    const handleDeleteApplication = (): void => {
        if (application) {
            onDeleteClick(application.uuid);
            closeDeleteModal();
        }
    };

    return (
        <DetailCard
            title={trans('containers.applicationDeleteCard.title')}
            className={`application-delete-card ${className}`}
        >
            <IconButton
                icon="delete"
                text={trans('containers.applicationDeleteCard.buttonLabel')}
                disabled={isLoading || !application}
                onClick={openDeleteModal}
                className="application-delete-card__delete-button"
            />

            {deleteModalIsOpen && (
                <Modal onClose={closeDeleteModal}>
                    <ConfirmModal
                        isNegative
                        title={trans('containers.applicationDeleteCard.modal.title')}
                        description={trans('containers.applicationDeleteCard.modal.description')}
                        confirmLabel={trans('actions.delete')}
                        onCancel={closeDeleteModal}
                        onConfirm={handleDeleteApplication}
                    />
                </Modal>
            )}
        </DetailCard>
    );
};

export default ApplicationDeleteCard;
