import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { CoreValue } from '../../models/CoreValue';
import { AsyncReduxState } from '../../types';

export type CoreValuesState = AsyncReduxState<{
    coreValues: CoreValue[];
}>;

const initialState: CoreValuesState = {
    ...initialAsyncReduxState,
    coreValues: [],
};

export const coreValuesSlice = createSlice({
    name: 'coreValuesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CoreValuesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CoreValuesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCoreValues(state, action: PayloadAction<CoreValue[]>): CoreValuesState {
            return {
                ...state,
                coreValues: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCoreValues,
} = coreValuesSlice.actions;

export default coreValuesSlice;
