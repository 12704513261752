import React, { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Pagination } from '../../_old/app_talentz/models/Pagination';
import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { createMetadataTitle } from '../../_old/app_talentz/services/MetadataService';
import { Skeleton } from '../../components';
import { IconButton } from '../../compositions';
import { isCreboNumber } from '../../helpers/string';
import { trans } from '../../helpers/trans';
import { TalentMatchingCallToAction } from './subcomponents';

import './VacancyOverviewHeader.scss';

interface VacancyOverviewHeaderProps {
    isTalentMatching?: boolean;
    isLoading: boolean;
    hasActiveFilters: boolean;
    showTalentMatchingQuote?: boolean;
    type: VacancyType;
    what: string;
    province?: string;
    where: string;
    pagination?: Pagination;
    talentMatchingToggle: (isActive: boolean) => void;
    className?: string;
}

const VacancyOverviewHeader: FC<VacancyOverviewHeaderProps> = ({
    isTalentMatching,
    isLoading,
    hasActiveFilters,
    showTalentMatchingQuote,
    type,
    what,
    province,
    where,
    pagination,
    talentMatchingToggle,
    className = '',
}): ReactElement => {
    const allResultsTitle = type === VacancyType.internship
        ? trans('vacancies.allInternships')
        : trans('vacancies.allJobs');

    const resultAmount = pagination?.total;
    const filteredResultsTitle = resultAmount === 1
        ? `${resultAmount} ${trans(`basic.searched.${type}`)}`
        : `${resultAmount} ${trans(`basic.searched.${type}s`)}`;

    const resultsTitle = hasActiveFilters && pagination
        ? filteredResultsTitle
        : allResultsTitle;

    const whatQueryContent = type === VacancyType.internship && isCreboNumber(what)
        ? trans('basic.searched.forCreboNumber', { searchTerm: what })
        : trans('basic.searched.for', { searchTerm: what });

    const whatQuery = what && whatQueryContent;
    const provinceQuery = province && trans('basic.searched.inProvince', { province });
    const whereQuery = where && trans('basic.searched.near', { residence: where });

    const helmetTitleType = type === VacancyType.internship
        ? trans('vacancies.overview.header.meta.title.internships')
        : trans('vacancies.overview.header.meta.title.jobs');

    const helmetTitle = what || where
        ? [helmetTitleType, what, provinceQuery, whereQuery].join(' ')
            .replace(/'/g, '')
        : undefined;

    const enableTalentMatching = (): void => talentMatchingToggle(true);
    const disableTalentMatching = (): void => talentMatchingToggle(false);

    return (
        <header className={`vacancy-overview-header ${className}`}>
            {helmetTitle && (
                <Helmet>
                    <title>{helmetTitle}</title>
                    <meta property="og:title" content={createMetadataTitle(helmetTitle)} />
                </Helmet>
            )}

            <div className="vacancy-overview-header__title-wrapper">
                {isLoading ? (
                    <Skeleton className="vacancy-overview-header__skeleton" />
                ) : (
                    <h1 className="vacancy-overview-header__title">
                        {isTalentMatching
                            ? trans('vacancies.talentMatches')
                            : resultsTitle}
                    </h1>
                )}

                {(what || where) && pagination && (
                    <p className="vacancy-overview-header__query">
                        {[trans('basic.searched.searched'), whatQuery, provinceQuery, whereQuery].join(' ')}
                    </p>
                )}
            </div>

            <div className="vacancy-overview-header__button-wrapper">
                {isTalentMatching ? (
                    <IconButton
                        icon="chevron-left"
                        text={trans('vacancies.overview.talentMatch.backToSearch')}
                        onClick={disableTalentMatching}
                        className="vacancy-overview-header__back-button"
                    />
                ) : (
                    <TalentMatchingCallToAction
                        showTalentMatchingQuote={showTalentMatchingQuote}
                        text={trans('vacancies.overview.talentMatch.buttonLabel')}
                        onClick={enableTalentMatching}
                    />
                )}
            </div>
        </header>
    );
};

export default VacancyOverviewHeader;
