import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import { LLOButton } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/form/TextInput';
import { Col, Row } from '../../../../styledComponents/components/Grid';
import OrRule from '../../../../styledComponents/components/OrRule';
import { FakeLink, Heading, Paragraph } from '../../../../styledComponents/components/Typography';

class LoginForm extends Component {
    constructor() {
        super();

        this.state = {
            emailValue: '',
            passwordValue: '',
        };
    }

    onSubmit = e => {
        e.preventDefault();
        const data = serialize(e.currentTarget, { hash: true });
        this.props.onSubmit(data);
    };

    render() {
        const { errors, loading } = this.props;
        const { emailValue, passwordValue } = this.state;

        return (
            <form id="login-form" onSubmit={this.onSubmit}>
                <Heading as="h2" size="xl">{trans('forms.login.title')}</Heading>
                <Row mb="lg" width={1}>
                    <Button
                        width={1}
                        hasIcon
                        iconLeft
                        iconSize={20}
                        icon="linkedin-letters"
                        color="linkedIn"
                        type="button"
                        id="login-linkedin"
                        onClick={() => this.props.loginWithLinkedIn()}
                    >
                        {trans('basic.linkedin')}
                    </Button>
                </Row>
                <OrRule>{trans('basic.or')}</OrRule>
                <Row mt="lg">
                    <Col mb="lg" width={1}>
                        <TextInput
                            onChange={e => this.setState({ emailValue: e.currentTarget.value })}
                            errors={errors.email}
                            type="email"
                            name="username"
                            placeholder={trans('forms.user.email.placeholder')}
                            icon="mail"
                            id="login-mail"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col mb="sm" width={1}>
                        <TextInput
                            onChange={e => this.setState({ passwordValue: e.currentTarget.value })}
                            type="password"
                            errors={errors.authentication || errors.password}
                            name="password"
                            placeholder={trans('forms.user.password.placeholder')}
                            icon="password"
                            id="login-password"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col mb="xl" widt={1}>
                        <FakeLink
                            id="login-password-forget"
                            type="button"
                            onClick={() => this.props.onPasswordForgetClick()}
                        >
                            {trans('forms.passwordForget.title')}
                        </FakeLink>
                    </Col>
                </Row>
                <Row
                    flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']}
                    width={1}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Col mb="md">
                        <Button
                            disabled={(!emailValue || !passwordValue || loading)}
                            isLoading={loading}
                            id="login-submit"
                        >
                            {trans('forms.user.login')}
                        </Button>
                    </Col>
                </Row>
                <Row justifyContent="flex-end" mb="lg">
                    <Paragraph>
                        {trans('forms.register.noAccount')}&nbsp;
                        <FakeLink
                            id="login-to-register"
                            type="button"
                            onClick={this.props.onRegisterClick}
                        >
                            {trans('actions.register')}
                        </FakeLink>
                    </Paragraph>
                </Row>

                <OrRule>{trans('basic.or')}</OrRule>

                <Row mt="lg" width={1}>
                    <LLOButton onClick={() => this.props.loginWithLLO()} />
                </Row>
            </form>
        );
    }
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loginWithLinkedIn: PropTypes.func.isRequired,
    loginWithLLO: PropTypes.func.isRequired,
    onRegisterClick: PropTypes.func.isRequired,
    onPasswordForgetClick: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
    errors: {},
};

export default LoginForm;
