import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconSearch: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M64.7,0c-19.3,0-35,15.7-35,35c0,7.6,2.5,15,7.1,21.1L0,92.9l7.1,7.1l36.9-36.9c15.5,11.5,37.5,8.2,49-7.3s8.2-37.5-7.3-49C79.5,2.4,72.2,0,64.7,0L64.7,0z M39.7,35c0-13.8,11.2-25,25-25s25,11.2,25,25s-11.2,25-25,25S39.7,48.8,39.7,35z" />
    </svg>
);

export default IconSearch;
