import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../_old/app_talentz/models/VacancyType';
import { LinkButton } from '../../components';
import { divideArrayInColumns } from '../../helpers/array';
import { trans } from '../../helpers/trans';
import { Province } from '../../models/Provinces';
import { Sector } from '../../models/Sectors';
import { RoutePaths } from '../../routes';
import { VacancyIndexCity, VacancyIndexProvince, VacancyIndexSector } from './subcomponents';

import './VacancyIndex.scss';

interface HomeVacancyIndexProps {
    isLoading: boolean;
    vacancyType?: VacancyType;
    provinceOptions: Province[];
    cityOptions: string[];
    sectorOptions: Sector[];
    className?: string;
}

const HomeVacancyIndex: FC<HomeVacancyIndexProps> = ({
    isLoading,
    vacancyType = VacancyType.job,
    provinceOptions,
    cityOptions,
    sectorOptions,
    className = '',
}): ReactElement => {
    const provinceOptionColumns = divideArrayInColumns(provinceOptions, 4);
    const cityOptionColumns = divideArrayInColumns(cityOptions, 4);
    const sectorOptionColumns = divideArrayInColumns(sectorOptions, 5);

    const categoryProps = {
        isLoading,
        vacancyType,
        className: 'vacancy-index__category',
    };

    const allVacanciesPath = vacancyType === VacancyType.internship
        ? RoutePaths.internshipVacancyIndex()
        : RoutePaths.jobVacancyIndex();

    return (
        <div className={`vacancy-index ${className}`}>
            <VacancyIndexProvince
                {...categoryProps}
                shouldSearchDirectly
                provinceOptionColumns={provinceOptionColumns}
            />

            <VacancyIndexCity
                {...categoryProps}
                cityOptionColumns={cityOptionColumns}
            />

            <VacancyIndexSector
                {...categoryProps}
                shouldSearchDirectly
                sectorOptionColumns={sectorOptionColumns}
            />

            <div className="vacancy-index__all-vacancies-wrapper">
                <LinkButton
                    to={allVacanciesPath}
                    text={trans('home.vacancyIndex.buttonLabel')}
                    className="vacancy-index__all-vacancies-button"
                />
            </div>
        </div>
    );
};

export default HomeVacancyIndex;
