import { authorizedFetch } from '../helpers/authorizedFetch';
import { trans } from '../helpers/trans';
import isResourceDocument from '../japi/guards/isResourceDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { SbbCompanyInfo, SbbTrainingCompanyResource, transformToSbbCompanyInfo } from '../models/SbbTrainingCompany';

export const getSbbCompanyInfoApiCall = async (uuid: string): Promise<FetchResult<SbbCompanyInfo, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/sbb-training-company/${uuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<SbbTrainingCompanyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const company = transformToSbbCompanyInfo(doc.data);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: company,
        };
    } catch (error) {
        console.error('[getSbbCompanyInfoApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postSbbCompanyInfoApiCall = async (uuid: string): Promise<FetchResult<SbbCompanyInfo, string>> => {
    try {
        const response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/sbb-training-company/${uuid}`, {
            method: 'POST',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<SbbTrainingCompanyResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const company = transformToSbbCompanyInfo(doc.data);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: company,
        };
    } catch (error) {
        console.error('[postSbbCompanyInfoApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
