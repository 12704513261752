import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconInformation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M45,25h10v10H45V25z M45,45h10v30H45V45z M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M50,90c-22,0-40-17.9-40-40c0-22,18-40,40-40c22.1,0,40,18,40,40C90,72.1,72.1,90,50,90z" />
    </svg>
);

export default IconInformation;
