import { FAQPage, WithContext } from 'schema-dts';

import { FaqItemTranslationModel } from '../app_talentz/models/FaqItemTranslationModel';

export const getGoogleFaqJsonForFaqPage = (translations: FaqItemTranslationModel[]): WithContext<FAQPage> => {
    const mainEntity: FAQPage['mainEntity'] = translations.map(translation => ({
        '@type': 'Question',
        name: translation.title,
        acceptedAnswer: {
            '@type': 'Answer',
            text: translation.text,
        },
    }));

    return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity,
    };
};
