import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../../../helpers/trans';
import { RoleType } from '../../../../../../models/User';
import IconButton from '../../../../../components/IconButton';
import ucFirst from '../../../../../helpers/ucFirst';
import { Col, Row } from '../../../../../styledComponents/components/Grid';
import Line from '../../../../../styledComponents/components/Line';
import { Laptop, MaxLaptop } from '../../../../../styledComponents/components/MediaQueries';
import { FakeLink, Heading, Paragraph } from '../../../../../styledComponents/components/Typography';
import {
    AnonymousHeading,
    AnonymousParagraph,
    CreateAccountHeading,
    UserTypeHeading,
    UserTypeParagraph,
} from './style';

interface SelectUserTypeProps {
    onSelect: (type: string) => void;
    onLoginClick: () => void;
    noLinkedInUser: boolean;
    applyAnonymous: boolean;
}

const SelectUserType: FC<SelectUserTypeProps> = ({
    onSelect,
    onLoginClick,
    noLinkedInUser,
    applyAnonymous,
}): ReactElement => (
    <>
        {applyAnonymous && (
            <>
                <AnonymousHeading as="h2" size="xl">{trans('basic.applyAnonymous')}</AnonymousHeading>
                <AnonymousParagraph mb="lg">
                    {trans('register.applyAnonymous.body')}
                </AnonymousParagraph>
                <Line mb="lg" color="greyLight" />
            </>
        )}
        {noLinkedInUser
            ? (
                <>
                    <Heading as="h2" size="xl" mb="sm">{trans('register.userType.noLinkedInUser.title')}</Heading>
                    <Paragraph mb="lg">
                        {trans('register.userType.noLinkedInUser.body')}
                    </Paragraph>
                </>
            )
            : (
                <>
                    <CreateAccountHeading as="h2" size="lg" mb="sm">{trans('register.userType.title')}</CreateAccountHeading>
                    <UserTypeParagraph mb="xl">
                        {trans('forms.register.alreadyRegistered')}&nbsp;
                        <FakeLink id="register-to-login-link" onClick={onLoginClick}>
                            {trans('forms.user.login')}
                        </FakeLink>
                    </UserTypeParagraph>
                    <UserTypeHeading as="h2" size="lg" mb="sm">{trans('register.userType.form.title')}</UserTypeHeading>
                </>
            )
        }
        <Row justifyContent="center" flexDirection="row">
            <Col width={[0.5, 0.5, 0.33]} mr="xs" my={[0, 0, 0, 0, 'xs']}>
                <IconButton onClick={() => onSelect(RoleType.student)} kind="student">{ucFirst(trans('basic.student'))}</IconButton>
            </Col>
            <Col width={[0.5, 0.5, 0.33]} ml="xs" my={[0, 0, 0, 0, 'xs']}>
                <IconButton onClick={() => onSelect(RoleType.jobSeeker)} kind="employee">{ucFirst(trans('basic.jobSeeker'))}</IconButton>
            </Col>
            <Laptop>
                <Line vertical mx="md" color="muted" />
                <Col width={[0.5, 0.5, 0.33]} my="xs">
                    <IconButton onClick={() => onSelect(RoleType.company)} kind="company_2">{ucFirst(trans('basic.company'))}</IconButton>
                </Col>
            </Laptop>
        </Row>
        <MaxLaptop>
            <Row alignItems="center" justifyContent="center" flexDirection={['column', 'column', 'column', 'column', 'row']}>
                <Line my="sm" width={1} color="muted" />
                <Col width={[0.5, 0.5, 0.33]} mx="xs">
                    <IconButton onClick={() => onSelect(RoleType.company)} kind="company">{ucFirst(trans('basic.company'))}</IconButton>
                </Col>
            </Row>
        </MaxLaptop>
    </>
);

export default SelectUserType;
