import React, { FC, ReactElement } from 'react';

import { Button, Icon } from '../../components';
import { convertBytesToLabel } from '../../helpers/file';
import { FileModel } from '../../models/File';
import { downloadFileFromApi } from '../../services/FileService';

import './FileList.scss';

interface FileListProps {
    files: FileModel[];
    className?: string;
}

const FileList: FC<FileListProps> = ({
    files,
    className = '',
}): ReactElement => (
    <ul className={`file-list ${className}`}>
        {files.map(file => {
            const handleDownloadFile = (): void => {
                downloadFileFromApi(file);
            };

            return (
                <li key={file.uuid} className="file-list__item">
                    <Button
                        text={file.name}
                        onClick={handleDownloadFile}
                        className="file-list__button"
                    >
                        <Icon name="paperclip" className="file-list__icon" />

                        <span className="file-list__name">
                            {file.name}
                        </span>

                        <span className="file-list__size">
                            {convertBytesToLabel(file.filesize)}
                        </span>
                    </Button>
                </li>
            );
        })}
    </ul>
);

export default FileList;
