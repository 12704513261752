import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { Card } from '../../../../components';
import { LinkIconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';
import { NewestVacancy } from '../../../../models/NewestVacancy';
import { RoutePaths } from '../../../../routes';

import './VacancyApplicantsMatchesCta.scss';

interface VacancyApplicantsMatchesCtaProps {
    activeVacancy: NewestVacancy;
    className?: string;
}

const VacancyApplicantsMatchesCta: FC<VacancyApplicantsMatchesCtaProps> = ({
    activeVacancy,
    className = '',
}): ReactElement => (
    <Card className={`vacancy-applicants-matches-cta ${className}`}>
        <div className="vacancy-applicants-matches-cta__text-wrapper">
            <h3 className="vacancy-applicants-matches-cta__title">
                {trans('containers.vacancyApplicants.matchesCta.title')}
            </h3>

            <p className="vacancy-applicants-matches-cta__description">
                {activeVacancy.type === VacancyType.internship
                    ? trans('containers.vacancyApplicants.matchesCta.description.internship')
                    : trans('containers.vacancyApplicants.matchesCta.description.job')}
            </p>
        </div>

        <LinkIconButton
            icon="arrow-right"
            iconPos="right"
            to={RoutePaths.companyOverviewMatches(activeVacancy.uuid)}
            text={trans('containers.vacancyApplicants.matchesCta.button.seekMatches')}
            className="vacancy-applicants-matches-cta__button"
        />
    </Card>
);

export default VacancyApplicantsMatchesCta;
