import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPaperclip: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 55 100" className={`icon ${className}`}>
        <path d="M45,33.1V75c0,8.3-6.7,15-15,15h-5c-8.3,0-15-6.7-15-15V20c0-5.5,4.5-10,10-10s10,4.5,10,10v52.5c0,1.4-1.1,2.5-2.5,2.5S25,73.9,25,72.5V20H15v52.5C15,79.4,20.6,85,27.5,85S40,79.4,40,72.5V20C40,9,31,0,20,0S0,9,0,20v55c0,13.8,11.2,25,25,25h5c13.8,0,25-11.2,25-25V33.1H45z" />
    </svg>
);

export default IconPaperclip;
