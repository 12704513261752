import React, { FC, ReactElement } from 'react';

import Icon from '../Icon/Icon';

import './Spinner.scss';

interface SpinnerProps {
    className?: string;
}

const Spinner: FC<SpinnerProps> = ({ className = '' }): ReactElement => (
    <Icon name="loader" className={`spinner ${className}`} />
);

export default Spinner;
