import React, { FC, ReactElement } from 'react';

import { retrieveUniqueValues } from '../../../../../helpers/array';
import { VacancyMatchProperties } from '../../../../../models/VacancyMatches';
import { Tag } from '../../../..';
import { retrieveDriversLicenses, retrieveEmploymentType } from './helpers';

import './CandidatePropertyTags.scss';

interface CandidatePropertyTagsProps {
    candidateUuid: string;
    properties: VacancyMatchProperties;
    className?: string;
}

const CandidatePropertyTags: FC<CandidatePropertyTagsProps> = ({
    candidateUuid,
    properties,
    className = '',
}): ReactElement => {
    const employmentPreference = retrieveEmploymentType(properties.employmentPreference);

    const educationLevels = properties.educationLevels.map(educationLevel => educationLevel.educationLevel);
    const uniqueEducationLevels = retrieveUniqueValues(educationLevels).filter(Boolean);

    const driversLicenses = retrieveDriversLicenses(properties.driversLicenses);

    const propertyList: string[] = [
        employmentPreference,
        uniqueEducationLevels.join(', '),
        ...driversLicenses,
    ];

    const filteredProperties = propertyList.filter(Boolean);

    return (
        <ul className={`candidate-property-tags ${className}`}>
            {filteredProperties.map(property => (
                <li key={`${candidateUuid}-${property}`} className="candidate-property-tags__tag-item">
                    <Tag text={property} />
                </li>
            ))}
        </ul>
    );
};

export default CandidatePropertyTags;
