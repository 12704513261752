import { darken, rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import {
    display,
    height,
    letterSpacing,
    space,
    width,
} from 'styled-system';

import isBrightColor from '../../helpers/isBrightColor';

const pulse = color => keyframes`
    0% {
        box-shadow: 0 0 0 0 ${rgba(color, 0.8)};
    }
    100% {
        box-shadow: 0 0 0 12px ${rgba(color, 0)};
    }
`;

const getBorderLessStyle = props => css`
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: ${props.theme.colors[props.color || 'primary']};
    
    svg {
        fill: ${props.theme.colors[props.color || 'primary']};
    }
    
    &:hover {
        color: ${darken(0.15, props.theme.colors[props.color || 'primary'])};
        
        svg {
            fill: ${darken(0.15, props.theme.colors[props.color || 'primary'])};
        }
    }
    
    &:disabled, &:disabled:hover {
        color: ${props.theme.colors.muted}; 
        pointer-events: none;
        
        svg {
            fill: ${props.theme.colors.muted};
        }
    }
`;

const getOutlineStyle = props => css`
    background-color: white;
    color: ${props.theme.colors[props.color || 'primary']};
    border-color: ${props.theme.colors[props.color || 'primary']};
    border-width: 2px;
    
    &:disabled {
        background-color: white;
    }
    
    svg {
        fill: ${props.theme.colors[props.color || 'primary']};
    }
    
    &:hover {
        background-color: white;
        border-color: ${darken(0.02, props.theme.colors[props.color || 'primary'])};
        color: ${darken(0.02, props.theme.colors[props.color || 'primary'])};
    }
    
    &:active {
        transform: translateY(1px);
        box-shadow: ${props.theme.shadow('sm')};
    }
    
    &:disabled, &:disabled:hover {
        color: ${props.theme.colors.muted}; 
        pointer-events: none;
        border-color: ${props.theme.colors.muted}; 
        
        svg {
            fill: ${props.theme.colors.muted};
        }
    }
`;

const getDefaultStyle = props => css`
    &:hover {
        background-color: ${darken(props.isTransparent ? 0.85 : 0.02, props.theme.colors[props.color || 'action'])};
        border-color: ${darken(0.02, props.theme.colors[props.color || 'action'])};
    }
    
    &:active {
        transform: translateY(1px);
        box-shadow: ${props.theme.shadow('sm')};
    }

    &:disabled, &:disabled:hover {
        background-color: ${props.theme.colors.grey};
        border: 2px solid ${props.theme.colors.grey};
        color: ${props.theme.colors.muted}; 
        pointer-events: none;
        
        svg {
            fill: ${props.theme.colors.muted};
        }
    }
`;

const getBackgroundColor = props => {
    if (props.isTransparent) {
        return 'transparent';
    }

    return props.theme.colors[props.color || 'action'];
};

const getColor = props => {
    if (props.isTransparent) {
        return props.color;
    }

    return props.theme.colors[isBrightColor(props.theme.colors[props.color || 'action']) ? 'black' : 'white'];
};

export default styled.button(props => css` 
    ${props.theme.fonts.button};
    position: relative;
    display: ${props.isInline ? 'inline-flex' : 'flex'};
    flex-direction: row;
    align-items: center;
    justify-content: ${props.justifyContent || 'center'};
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: ${props.noUpperCase ? '' : 'uppercase'};
    letter-spacing: ${props.noUpperCase ? 0 : '0.07em'};
    white-space: nowrap;
    overflow: hidden;
    width: ${props.width || 'auto'};
    height: 40px;
    padding: 0 ${props.theme.space[props.hasIcon ? 'sm' : 'lg']} 0 ${props.theme.space[props.hasIcon ? 'sm' : 'lg']};
    border-radius: 2px;
    white-space: wrap;
    transition: 
        background-color 0.15s ease-out,
        color 0.15s ease-out,
        border 0.15s ease-out;
    
    background-color: ${getBackgroundColor(props)};
    color: ${getColor(props)};
    border: 2px solid ${props.theme.colors[props.color || 'action']};
    
    svg {
        margin-right: ${props.hasIcon ? props.theme.space.sm : 0};
        fill: ${props.theme.colors[isBrightColor(props.theme.colors[props.color || 'action']) ? 'black' : 'white']};
    }
    
    ${props.isBorderless && getBorderLessStyle(props)}
    ${props.outline && getOutlineStyle(props)}
    ${(!props.isBorderless && !props.outline) && getDefaultStyle(props)}
    
    ${props.iconLeft && css`
        & svg {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            padding: 0 10px;
            border-right: 1px solid ${props.theme.colors.grey};
        }
        
        &:before {
            content: '';
            width: 50px;
        }
    `}
    
    ${props.iconOnly && css`
        padding: 0 ${props.theme.space.xs};
        
        svg {
            margin-right: 0;
        }
    `}

    ${props.isLarge && css`
        font-size: ${props.theme.fontSizes.rg};
        padding: 0 15px;
    `}

    ${props.isSmall && css`
        height: 34px;
        font-size ${props.theme.fontSizes.xs};
        padding: 0 ${props.theme.space.xxs};
    `}
    
    ${props.isPulsing && css`
        animation: ${pulse(props.theme.colors[props.color || 'action'])} 1.5s ease-out infinite;
    `}
    
    @media ${props.theme.mediaQueries.smallTablet} {
        font-size: ${props.theme.fontSizes.rg};

        ${props.isSmall && css`
            height: 34px;
            font-size: ${props.theme.fontSizes.xs};
            padding: 0 ${props.theme.space[props.hasIcon ? 'sm' : 'lg']}
        `}
    }
    
    @media ${props.theme.mediaQueries.laptop} {
        ${props.isLarge && css`
            height: auto;
            padding: 18px 23px;
            font-size: 16px;
            line-height: 1;
        `}
        ${space}
    }
    
    @media ${props.theme.mediaQueries.maxTablet} {
        ${props.mobileIcon && css`
            width: 40px;
            padding: 0 9px;
            line-height: 0;
            
            span {
                display: none;
            }
            
            svg {
                position: absolute;
                margin-right: 0;
            }
        `}
        ${space}
    }
`, space, width, display, height, letterSpacing);
