import React, { FC, ReactElement } from 'react';

import { IconButton } from '../../../../compositions';
import { trans } from '../../../../helpers/trans';

import './CarouselArrowControls.scss';

interface CarouselArrowControlsProps {
    slideAmount: number;
    currentSlide: number;
    onPrevClick: () => void;
    onNextClick: () => void;
    className?: string;
}

const CarouselArrowControls: FC<CarouselArrowControlsProps> = ({
    slideAmount,
    currentSlide,
    onPrevClick,
    onNextClick,
    className = '',
}): ReactElement | null => {
    if (slideAmount <= 1) return null;

    return (
        <ul className={`carousel-arrow-controls ${className}`}>
            <li className="carousel-arrow-controls__item">
                <IconButton
                    icon="arrow-left"
                    text={trans('actions.previous')}
                    hideLabel
                    disabled={currentSlide === 0}
                    onClick={onPrevClick}
                    className="carousel-arrow-controls__button"
                />
            </li>
            <li className="carousel-arrow-controls__item">
                <IconButton
                    icon="arrow-right"
                    text={trans('actions.next')}
                    hideLabel
                    disabled={currentSlide === slideAmount - 1}
                    onClick={onNextClick}
                    className="carousel-arrow-controls__button"
                />
            </li>
        </ul>
    );
};

export default CarouselArrowControls;
