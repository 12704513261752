import React, { FC, ReactElement } from 'react';

import { SafeHtml } from '../../../../../components';
import { DetailCard } from '../../../../../compositions';
import { trans } from '../../../../../helpers/trans';
import { Application } from '../../../../../models/Applications';

import './ApplicationStatusDeclined.scss';

interface ApplicationStatusDeclinedProps {
    isCandidate?: boolean;
    application: Application;
    className?: string;
}

const ApplicationStatusDeclined: FC<ApplicationStatusDeclinedProps> = ({
    isCandidate,
    application,
    className = '',
}): ReactElement => {
    const hasRejections = application.rejections.length > 0;
    const activeRejection = application.rejections?.[0];

    const description = isCandidate
        ? trans('containers.applicationStatusCard.declined.description.candidate')
        : trans('containers.applicationStatusCard.declined.description.company');

    return (
        <DetailCard
            title={trans('containers.applicationStatusCard.declined.title')}
            className={`application-status-declined ${className}`}
        >
            <p className="application-status-declined__description">
                {description}
            </p>

            {hasRejections ? (
                <SafeHtml
                    html={activeRejection.message || trans('containers.applicationStatusCard.declined.noMessage')}
                    className="application-status-declined__message"
                />
            ) : (
                <p className="application-status-declined__no-message">
                    {trans('containers.applicationStatusCard.declined.noMessage')}
                </p>
            )}
        </DetailCard>
    );
};

export default ApplicationStatusDeclined;
