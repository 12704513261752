import React from 'react';

import { Blog, BlogDetail } from '../../pages';

export const blogRoutePaths = {
    blog: () => '/blog',
    blogCategory: (category = ':category') => `/blog/${category}`,
    blogDetail: (category = ':category', slug = ':slug') => `/blog/${category}/${slug}`,
};

export const blogRoutes = [
    {
        path: blogRoutePaths.blog(),
        element: <Blog />,
        children: [
            {
                path: ':category',
                element: <Blog />,
            },
        ],
    },
    {
        path: blogRoutePaths.blogDetail(),
        element: <BlogDetail />,
    },
];
