// TODO: Despite apparent refactor, this reducer/slice needs to be refactored, and removed from '_old' entirely.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MatchingCandidate } from '../../models/MatchingCandidate';

export interface StudentsState {
    loading: boolean;
    currentStudent?: MatchingCandidate;
}

const initialState: StudentsState = {
    loading: false,
    currentStudent: undefined,
};

const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>): StudentsState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setCurrentStudent(state, action: PayloadAction<MatchingCandidate | undefined>): StudentsState {
            return {
                ...state,
                currentStudent: action.payload,
            };
        },
    },
});

export const {
    setLoading,
    setCurrentStudent,
} = studentsSlice.actions;

export default studentsSlice;
