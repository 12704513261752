import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLogin: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 90" className={`icon ${className}`}>
        <path d="M90,80H50v10h40c5.5,0,10-4.5,10-10V10c0-5.5-4.5-10-10-10H50v10h40V80z" />
        <polygon points="45,20 38,27 51,40 0,40 0,50 51,50 38,63 45,70 70,45" />
    </svg>
);

export default IconLogin;
