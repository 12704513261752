import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { SafeHtml } from '../../../../components';
import { formatDate } from '../../../../helpers/date';
import { trans } from '../../../../helpers/trans';
import { BlogArticle } from '../../../../models/Blog';
import { renderBlogContentAsHtml } from '../../helpers';

import './BlogRenderer.scss';

interface BlogRendererProps {
    content: BlogArticle;
    className?: string;
}

const BlogRenderer: FC<BlogRendererProps> = ({
    content,
    className = '',
}): ReactElement => {
    const createdAt = formatDate(content.createdAt, 'd MMMM, yyyy');

    return (
        <div className={`blog-renderer ${className}`}>
            <p className="blog-renderer__created-date">
                {trans('blog.detail.createdAt', { createdAt })}
            </p>

            <p className="blog-renderer__intro">
                {content.intro}
            </p>

            <div className="blog-renderer__body">
                {content.body.map((slice, index) => {
                    const key = `${slice.slice_type}-${index}`;
                    const htmlContent = renderBlogContentAsHtml(slice);

                    const sliceClassNames = classNames('blog-renderer__slice', {
                        [`blog-renderer__slice--${slice.slice_type}`]: slice.slice_type,
                    });

                    return (
                        <SafeHtml
                            key={key}
                            html={htmlContent}
                            className={sliceClassNames}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default BlogRenderer;
