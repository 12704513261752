import { InternshipVacancyDetailApiParams, InternshipVacancyProperties } from '../app_talentz/models/InternshipVacancy';
import formatCompensationOrSalary from './formatCompensationOrSalary';

export default function transformApiCompensationToInternshipVacancyCompensation(
    compensation: InternshipVacancyDetailApiParams['compensation'],
): InternshipVacancyProperties['compensation'] | null {
    if (!compensation) {
        return null;
    }

    return {
        ...compensation,
        name: formatCompensationOrSalary(compensation.min, compensation.max),
    };
}
