import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconLock: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 80 100" className={`icon ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M25,69.9c0-8.3,6.7-15,15-15s15,6.7,15,15c0,8.3-6.7,15-15,15S25,78.2,25,69.9z M40,62.4c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5c0,0,0,0,0,0c4.1,0,7.5-3.4,7.5-7.5S44.1,62.4,40,62.4z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40,0C26.2,0,15,11.2,15,25v15H0v60h80V40H65V25C65,11.2,53.8,0,40,0z M55,40V25c0-8.3-6.7-15-15-15s-15,6.7-15,15v15H55z M10,50v40h60V50H10z" />
    </svg>
);

export default IconLock;
