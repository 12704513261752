import { isValidDateString } from '../helpers/date';
import { SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';

export interface ApplicationRejectionsResource extends Resource {
    type: 'application-rejections';
    id: string;
    attributes: {
        content: string;
        createdAt: string;
        updatedAt: string;
    };
}

export interface ApplicationRejectionRequest {
    data: {
        type: 'application-rejections';
        attributes: {
            content: string;
        };
        relationships: {
            application: SingleRelationship<'applications'>;
        };
    };
}

export interface ApplicationRejection {
    uuid: string;
    message: string;
    createdAt: Date;
}

export interface ApplicationRejectionFormData {
    message: string;
}

export const transformToApplicationRejection = (
    applicationRejectionResource: ApplicationRejectionsResource,
): ApplicationRejection => {
    const createdAt = isValidDateString(applicationRejectionResource.attributes.createdAt)
        ? new Date(applicationRejectionResource.attributes.createdAt)
        : new Date();

    return {
        uuid: applicationRejectionResource.id,
        message: applicationRejectionResource.attributes.content,
        createdAt,
    };
};

export const transformToApplicationRejectionRequest = (applicationUuid: string, formData: ApplicationRejectionFormData): ApplicationRejectionRequest => ({
    data: {
        type: 'application-rejections',
        attributes: {
            content: formData.message,
        },
        relationships: {
            application: {
                data: {
                    id: applicationUuid,
                    type: 'applications',
                },
            },
        },
    },
});
