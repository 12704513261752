import React, { FC, ReactElement, useState } from 'react';

import { ProgressRefButton } from '../../../components';
import {
    AddingCard,
    IconButton,
    Modal,
    ModalContent,
} from '../../../compositions';
import { trans } from '../../../helpers/trans';
import { JobExperience, JobExperienceFormData } from '../../../models/JobExperience';
import { JobExperienceForm } from '../..';
import { ActivationRef } from '../ProfileProgress/ProfileProgress';
import { JobExperiencesCardSkeletons } from './skeletons';
import { JobExperienceItem } from './subcomponents';

import './JobExperiencesCard.scss';

interface JobExperiencesCardProps {
    activationRef?: ActivationRef;
    isLoading: boolean;
    isEditable: boolean;
    jobExperiences?: JobExperience[];
    onAddJobExperience: (jobExperienceFormData: JobExperienceFormData) => void;
    onEditJobExperience: (jobExperienceFormData: JobExperienceFormData) => void;
    onDeleteJobExperience: (jobExperience: JobExperience) => void;
    className?: string;
}

const JobExperiencesCard: FC<JobExperiencesCardProps> = ({
    activationRef,
    isLoading,
    isEditable,
    jobExperiences = [],
    onAddJobExperience,
    onEditJobExperience,
    onDeleteJobExperience,
    className = '',
}): ReactElement => {
    const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);

    const openAddModal = (): void => setAddModalIsOpen(true);
    const closeAddModal = (): void => setAddModalIsOpen(false);

    const handleAddJobExperience = (jobExperienceFormData: JobExperienceFormData): void => {
        onAddJobExperience(jobExperienceFormData);
        closeAddModal();
    };

    return (
        <AddingCard
            title={trans('candidateProfile.jobExperiences.title')}
            isEditable={isEditable && jobExperiences.length > 0}
            item={trans('basic.jobExperience')}
            onAddClick={openAddModal}
            className={`job-experiences-card ${className}`}
        >
            <ProgressRefButton ref={activationRef} onClick={openAddModal} />

            {isLoading && (
                <JobExperiencesCardSkeletons
                    amount={2}
                    className="job-experiences-card__skeletons"
                />
            )}

            {!isLoading && jobExperiences.length === 0 && (
                <div className="job-experiences-card__null-state">
                    <p className="job-experiences-card__null-state-description">
                        {trans('candidateProfile.jobExperiences.nullState')}
                    </p>

                    {isEditable && (
                        <IconButton
                            icon="plus"
                            text={trans('userProfile.content.add')}
                            onClick={openAddModal}
                            className="job-experiences-card__null-state-button"
                        />
                    )}
                </div>
            )}

            {!isLoading && jobExperiences.length > 0 && (
                <ul className="job-experiences-card__job-list">
                    {jobExperiences.map(jobExperience => (
                        <JobExperienceItem
                            key={jobExperience.id}
                            isEditable={isEditable}
                            jobExperience={jobExperience}
                            onEdit={onEditJobExperience}
                            onDelete={onDeleteJobExperience}
                            className="job-experiences-card__job-item"
                        />
                    ))}
                </ul>
            )}

            {addModalIsOpen && (
                <Modal onClose={closeAddModal}>
                    <ModalContent title={trans('forms.jobExperience.title.add')}>
                        <JobExperienceForm
                            onCancel={closeAddModal}
                            onSubmit={handleAddJobExperience}
                        />
                    </ModalContent>
                </Modal>
            )}
        </AddingCard>
    );
};

export default JobExperiencesCard;
