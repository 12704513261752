import React from 'react';

import { Helmet } from 'react-helmet-async';

import { trans } from '../../../../../helpers/trans';
import Page from '../../../../styledComponents/components/Page';
import { createMetadataTitle } from '../../../services/MetadataService';
import TeamAmbassador from './components/TeamAmbassador/TeamAmbassador';
import TeamContent from './components/TeamContent';
import TeamContentBottom from './components/TeamContentBottom';
import TeamCTA from './components/TeamContentCTA';
import TeamHeader from './components/TeamHeader';
import TeamSlider from './components/TeamSlider';

const AboutUsPage = () => (
    <Page color="white" display="block" pb={0}>
        <Helmet>
            <title>{trans('infoPage.team.metadata.title')}</title>
            <meta property="og:title" content={createMetadataTitle(trans('infoPage.team.metadata.title'))} />
            <meta property="og:description" content={trans('infoPage.team.metadata.description')} />
            <meta name="description" content={trans('infoPage.team.metadata.description')} />
            <link rel="canonical" href={`${process.env.REACT_APP_URL}/over-ons`} />
        </Helmet>
        <TeamHeader />
        <TeamContent />
        <TeamSlider />
        <TeamContentBottom />
        <TeamAmbassador />
        <TeamCTA />
    </Page>
);

export default AboutUsPage;
