import React, {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Address as OldAddress } from '../../_old/app_talentz/models/Address';
import { CompanyKVKFinder, SubmitButton } from '../../compositions';
import CompanyDataForm from '../../compositions/CompanyDataForm/CompanyDataForm';
import EmployeeDataForm from '../../compositions/EmployeeDataForm/EmployeeDataForm';
import { trans } from '../../helpers/trans';
import { KvkBaseProfile } from '../../models/KvkBaseProfile';
import { KvkSearchResult } from '../../models/KvkSearchResult';
import {
    CompanyDataFormValues,
    generateEmptyCompanyDataFormValues,
    transformAddressToCompanyDataFormValues,
    transformRegisterCompanyApiErrorsToCompanyFormErrors,
} from '../../services/CompanyService';
import {
    EmployeeDataFormValues,
    generateEmptyEmployeeDataFormValues,
    transformRegisterCompanyApiErrorsToEmployeeDataFormErrors,
} from '../../services/EmployeeService';
import {
    RegisterCompanyApiErrors,
    RegisterCompanyApiValues,
    transformFormValuesToRegisterCompanyApiValues,
} from '../../services/RegisterCompanyService';
import { FormOption } from '../../types';

import './RegisterCompanyContainer.scss';

interface RegisterCompanyContainerProps {
    isLoading: boolean;
    kvkSearchResultsIsLoading: boolean;
    kvkBaseProfileIsLoading: boolean;
    addressIsLoading: boolean;
    kvkSearchResults: KvkSearchResult[];
    kvkBaseProfile?: KvkBaseProfile;
    address?: OldAddress;
    errors: RegisterCompanyApiErrors;
    amountOfEmployeesOptions: FormOption[];
    onKvkSearchSubmit: (tradeName: string, location: string) => void;
    onKvkSearchResultSelect: (kvkSearchResult: KvkSearchResult) => void;
    onAddressInput: (formValues: CompanyDataFormValues) => void;
    onSubmit: (data: RegisterCompanyApiValues) => void;
    className?: string;
}

const RegisterCompanyContainer: FC<RegisterCompanyContainerProps> = ({
    isLoading,
    kvkSearchResultsIsLoading,
    kvkBaseProfileIsLoading,
    addressIsLoading,
    kvkSearchResults,
    kvkBaseProfile,
    address,
    errors,
    amountOfEmployeesOptions,
    onKvkSearchSubmit,
    onKvkSearchResultSelect,
    onAddressInput,
    onSubmit,
    className = '',
}): ReactElement => {
    const [selectedKvkSearchResult, setSelectedKvkSearchResult] = useState<KvkSearchResult>();

    const [companyFormValues, setCompanyFormValues] = useState<CompanyDataFormValues>(generateEmptyCompanyDataFormValues());
    const [employeeFormValues, setEmployeeFormValues] = useState<EmployeeDataFormValues>(generateEmptyEmployeeDataFormValues());

    const companyFormErrors = transformRegisterCompanyApiErrorsToCompanyFormErrors(errors);
    const employeeFormErrors = transformRegisterCompanyApiErrorsToEmployeeDataFormErrors(errors);

    const handleKvkSearchSubmit = (tradeName: string, location: string): void => {
        setSelectedKvkSearchResult(undefined);

        onKvkSearchSubmit(tradeName, location);
    };

    const handleKvkSearchResultSelect = (kvkSearchResult: KvkSearchResult): void => {
        setSelectedKvkSearchResult(kvkSearchResult);
        onKvkSearchResultSelect(kvkSearchResult);
    };

    const handleAddressInput = (): void => {
        onAddressInput(companyFormValues);
    };

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        onSubmit(transformFormValuesToRegisterCompanyApiValues(companyFormValues, employeeFormValues));
    };

    useEffect((): void => {
        if (kvkBaseProfile) {
            const newCompanyFormValues = {
                ...companyFormValues,
                companyName: kvkBaseProfile.name,
                kvkNumber: kvkBaseProfile.kvkNumber,
                zipcode: kvkBaseProfile.postalCode || '',
                streetNumber: kvkBaseProfile.houseNumber || '',
            };

            setCompanyFormValues(newCompanyFormValues);
            onAddressInput(newCompanyFormValues);
        }
    }, [kvkBaseProfile]);

    useEffect((): void => {
        if (address) {
            const updatedFormValues = transformAddressToCompanyDataFormValues(address, companyFormValues);

            setCompanyFormValues(updatedFormValues);
        }
    }, [address]);

    return (
        <div className={`register-company-container ${className}`}>
            <h2 className="register-company-container__title">
                {trans('forms.register.employee.title')}
            </h2>

            <p className="register-company-container__intro">
                {trans('forms.register.employee.intro')}
            </p>

            <CompanyKVKFinder
                isLoading={kvkSearchResultsIsLoading}
                searchResults={kvkSearchResults}
                onKvkSearchSubmit={handleKvkSearchSubmit}
                onKvkSearchResultSelect={handleKvkSearchResultSelect}
                className="register-company-container__kvk-selector"
            />

            {selectedKvkSearchResult && (
                <form onSubmit={handleSubmit} className="register-company-container__company-data-form">
                    <CompanyDataForm
                        errors={companyFormErrors}
                        formValues={companyFormValues}
                        amountOfEmployeesOptions={amountOfEmployeesOptions}
                        addressLoading={addressIsLoading || kvkBaseProfileIsLoading}
                        addressNotFound={!address}
                        onChange={setCompanyFormValues}
                        onAddressInputSubmit={handleAddressInput}
                    />

                    <EmployeeDataForm
                        errors={employeeFormErrors}
                        formValues={employeeFormValues}
                        onChange={setEmployeeFormValues}
                    />

                    <SubmitButton
                        isLoading={isLoading}
                        text={trans('actions.next')}
                        disabled={!address}
                        className="register-company-container__submit-button"
                    />
                </form>
            )}
        </div>
    );
};

export default RegisterCompanyContainer;
