import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { LearningPath } from '../../models/LearningPaths';
import { AsyncReduxState } from '../../types';

export type LearningPathsState = AsyncReduxState<{
    learningPaths: LearningPath[];
}>;

const initialState: LearningPathsState = {
    ...initialAsyncReduxState,
    learningPaths: [],
};

export const learningPathsSlice = createSlice({
    name: 'learningPathsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): LearningPathsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): LearningPathsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setLearningPaths(state, action: PayloadAction<LearningPath[]>): LearningPathsState {
            return {
                ...state,
                learningPaths: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setLearningPaths,
} = learningPathsSlice.actions;

export default learningPathsSlice;
