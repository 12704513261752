import React, { FC, ReactElement } from 'react';

import { VacancyType } from '../../../../_old/app_talentz/models/VacancyType';
import { alphabetNumber } from '../../../../constants';
import { trans } from '../../../../helpers/trans';
import { RoutePaths } from '../../../../routes';
import { VacancyIndexBreadcrumb } from '..';

import './VacancyIndexBreadcrumbList.scss';

interface VacancyIndexBreadcrumbListProps {
    vacancyType: VacancyType;
    educationLevel?: string;
    letter?: string;
    fieldOfStudy?: string;
    occupation?: string;
    sector?: string;
    province?: string;
    educationLevelLabel?: string;
    fieldOfStudyLabel?: string;
    occupationLabel?: string;
    sectorLabel?: string;
    provinceLabel?: string;
    className?: string;
}

const VacancyIndexBreadcrumbList: FC<VacancyIndexBreadcrumbListProps> = ({
    vacancyType,
    educationLevel = '',
    letter = '',
    fieldOfStudy = '',
    occupation = '',
    sector = '',
    province = '',
    educationLevelLabel = educationLevel,
    fieldOfStudyLabel = fieldOfStudy,
    occupationLabel = occupation,
    sectorLabel = sector,
    provinceLabel = province,
    className = '',
}): ReactElement => {
    const allVacanciesLabel = vacancyType === VacancyType.internship
        ? trans('vacancyIndex.breadcrumbs.allInternships')
        : trans('vacancyIndex.breadcrumbs.allJobs');

    const alphabetLabel = letter === alphabetNumber
        ? trans('vacancyIndex.breadcrumbs.number', { number: alphabetNumber })
        : trans('vacancyIndex.breadcrumbs.letter', { letter });

    const allVacanciesRoute = vacancyType === VacancyType.internship
        ? RoutePaths.internshipVacancyIndex()
        : RoutePaths.jobVacancyIndex();

    const sectorRoute = vacancyType === VacancyType.internship
        ? RoutePaths.internshipVacancyIndexSector(sector)
        : RoutePaths.jobVacancyIndexSector(sector);

    const encodedEducationLevel = encodeURIComponent(educationLevel);
    const encodedLetter = encodeURIComponent(letter);
    const encodedFieldOfStudy = encodeURIComponent(fieldOfStudy);
    const encodedOccupation = encodeURIComponent(occupation);
    const encodedSector = encodeURIComponent(sector);
    const encodedProvince = encodeURIComponent(province);

    return (
        <ul className={`vacancy-index-breadcrumb-list ${className}`}>
            <VacancyIndexBreadcrumb
                to={allVacanciesRoute}
                label={allVacanciesLabel}
            />

            {encodedEducationLevel && (
                <VacancyIndexBreadcrumb
                    to={encodedLetter && RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel)}
                    label={educationLevelLabel}
                />
            )}

            {vacancyType === VacancyType.internship && encodedLetter && (
                <VacancyIndexBreadcrumb
                    to={encodedFieldOfStudy && RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel, encodedLetter)}
                    label={alphabetLabel}
                />
            )}

            {vacancyType === VacancyType.job && encodedLetter && (
                <VacancyIndexBreadcrumb
                    to={encodedOccupation && RoutePaths.jobVacancyIndexOccupation(encodedLetter)}
                    label={alphabetLabel}
                />
            )}

            {encodedFieldOfStudy && (
                <VacancyIndexBreadcrumb
                    to={encodedProvince && RoutePaths.internshipVacancyIndexEducationLevel(encodedEducationLevel, encodedLetter, encodedFieldOfStudy)}
                    label={fieldOfStudyLabel}
                />
            )}

            {encodedOccupation && (
                <VacancyIndexBreadcrumb
                    to={encodedProvince && RoutePaths.jobVacancyIndexOccupation(encodedLetter, encodedOccupation)}
                    label={occupationLabel}
                />
            )}

            {encodedSector && (
                <VacancyIndexBreadcrumb
                    to={encodedProvince && sectorRoute}
                    label={sectorLabel}
                />
            )}

            {encodedProvince && (
                <VacancyIndexBreadcrumb label={provinceLabel} />
            )}
        </ul>
    );
};

export default VacancyIndexBreadcrumbList;
