import React, { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../components';
import { MessageState } from '../../types';

import './ModalContent.scss';

interface ModalContentProps {
    title: string;
    titleIcon?: string;
    messageState?: MessageState;
    className?: string;
}

const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
    title,
    titleIcon = '',
    messageState = MessageState.positive,
    className = '',
    children,
}): ReactElement => {
    const modalContentClassNames = classNames('modal-content', {
        [`modal-content--is-${messageState}`]: messageState,
    }, className);

    return (
        <div className={modalContentClassNames}>
            <header className="modal-content__header">
                {titleIcon && (
                    <div className="modal-content__icon-wrapper">
                        <Icon name={titleIcon} className="modal-content__icon" />
                    </div>
                )}
                <h1 className="modal-content__title">{title}</h1>
            </header>

            <div className="modal-content__content-wrapper">
                {children}
            </div>
        </div>
    );
};

export default ModalContent;
