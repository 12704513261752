/* eslint-disable camelcase */
import { capatalizeFirstCharacter } from '../_old/helpers/textHelpers';
import { slugifyString } from '../helpers/string';
import { MultipleRelationship, SingleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { SearchableOption } from '../types';
import { Skill, SkillType } from './Skills';

export interface EscoSkillsResource extends Resource {
    type: 'esco-skills';
    id: string;
    attributes: {
        name: string;
    };
}

export interface EscoOccupationSkillsResource extends Resource {
    type: 'esco-occupation-skills';
    id: string;
    attributes: {
        optional: boolean;
    };
    relationships?: {
        skill: SingleRelationship<'esco-skills'>;
    };
}

export interface EscoSkillViewModel {
    id: string;
    occupationSkillId?: string;
    name: string;
    optional?: boolean;
}

export interface EscoOccupationsResource extends Resource {
    type: 'esco-occupations';
    id: string;
    attributes: {
        name: string;
        escoUri: string;
    };
    relationships?: {
        escoOccupationSkills: MultipleRelationship<'esco-occupation-skills'>;
    };
}

export interface EscoOccupation {
    id: string;
    name: string;
    slug: string;
    escoUri: string;
}

export const transformToEscoOccupation = (
    escoOccupationsResource: EscoOccupationsResource,
): EscoOccupation => ({
    id: escoOccupationsResource.id,
    name: escoOccupationsResource.attributes.name,
    slug: slugifyString(escoOccupationsResource.attributes.name),
    escoUri: escoOccupationsResource.attributes.escoUri,
});

export function transformToEscoSkillViewModel(
    escoSkillsResource: EscoSkillsResource,
    escoOccupationSkill?: EscoOccupationSkillsResource,
): EscoSkillViewModel {
    return {
        id: escoSkillsResource.id,
        name: capatalizeFirstCharacter(escoSkillsResource.attributes.name),
        occupationSkillId: escoOccupationSkill?.id,
        optional: escoOccupationSkill?.attributes.optional,
    };
}

export const transformToEscoSkill = (
    escoSkillsResource: EscoSkillsResource,
): Skill => ({
    id: escoSkillsResource.id,
    type: SkillType.esco,
    name: escoSkillsResource.attributes.name,
    slug: slugifyString(escoSkillsResource.attributes.name),
    isSelectable: true,
});

export interface EscoSkillsMatchProperty {
    id: string;
    name: string;
    value?: string;
    slug?: string;
    is_matched?: boolean;
}

export function transformToEscoSkillWithMatch(matchingProperties: EscoSkillViewModel[], property?: string[]): EscoSkillsMatchProperty[] {
    if (typeof matchingProperties === 'undefined') {
        return [];
    }

    const propertyWithMatch: EscoSkillsMatchProperty[] = matchingProperties.map((prop: EscoSkillViewModel) => {
        const isMatched = property?.includes(prop.id);

        return { ...prop, is_matched: isMatched };
    });

    return propertyWithMatch;
}

export const transformEscoOccupationToSearchableOption = (
    escoOccupation: EscoOccupation,
): SearchableOption => ({
    label: escoOccupation.name,
    value: escoOccupation.id,
});
/* eslint-enable camelcase */
